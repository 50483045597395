import React from "react";
import { Route, Switch } from "react-router-dom";

import Documents from "../../../../components/pages/Documents";
import TemplatedBill from "../../../../components/pages/Documents/Bills/Bill/TemplatedBill";
import { ACT_LISTS_PATH, BILLS_PATH, PACKING_LISTS_PATH } from "../../../../components/pages/Documents/constants";
import { checkTabPermission } from "../../../../components/pages/Documents/utils/checkTabPermission";
import TemplatedPackingList from "../../../../components/pages/PackingList/TemplatedPackingList";
import ServiceAct from "components/pages/ServiceAct/ServiceAct";

import ForbiddenPage from "../ForbiddenPage/ForbiddenPage";

const DocumentsRoutes = ({ match, documentsPermissions, billPermissions, packingListPermissions }) => {
  return (
    <Switch>
      {documentsPermissions.viewBillsList && (
        <Route
          exact
          path={`${match.path}/${BILLS_PATH}/:idBill`}
          render={(props) => <TemplatedBill {...props} permissions={billPermissions} />}
        />
      )}
      {documentsPermissions.viewPackingLists && (
        <Route
          exact
          path={`${match.path}/${PACKING_LISTS_PATH}/:packingListId`}
          render={(props) => <TemplatedPackingList {...props} permissions={packingListPermissions} />}
        />
      )}
      {documentsPermissions.viewPackingLists && (
        <Route exact path={`${match.path}/${ACT_LISTS_PATH}/:actId`} render={(props) => <ServiceAct {...props} />} />
      )}
      <Route
        path={match.path}
        render={(props) =>
          checkTabPermission(props.match.params.tabPath, documentsPermissions) ? (
            <Documents {...props} permissions={documentsPermissions} />
          ) : (
            <ForbiddenPage />
          )
        }
      />
      <ForbiddenPage />
    </Switch>
  );
};

export default React.memo(DocumentsRoutes);
