import axios from "axios";

export const moveShift = (shiftId: number, days: number) => {
  return axios.post(`/shift/${shiftId}/action/`, {
    action: 'move',
    days
  });
}

export const cancelShift = (shiftId: number) => {
  return axios.post(`/shift/${shiftId}/action/`, { action: 'cancel' });
}
