import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import ConfirmBlock from "./components/ConfirmBlock/ConfirmBlock";
import MoreButton from "./components/MoreButton/MoreButton";
import PlanMimsTableHeader from "./components/TableHeader/PlanMimsTableHeader";
import PlanMimsTableRow from "./components/TableRow/PlanMimsTableRow";
import { filterRestPlannedMims } from "components/pages/Manufacturing/components/modals/ManufacturingModal/PlanEditing/utils";

import { Spinner } from "../../../../shared/ui/atoms/Spinner/Spinner";
import AddButton from "../../../../shared/ui/controls/AddButton/AddButton";
import EmptyPlaceholder from "../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import Expandable from "../../atoms/_TODO/Expandable/Expandable";

import { ISerializedMimsInPlan } from "types/interfaces/Mims";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useMimsPlanList } from "../../../../hooks/useMimsPlanList";

import materials from "images/icons/navigation/materials.svg";

import styles from "./AddingListInModal.module.scss";

interface IProps {
  addedMims: ISerializedMimsInPlan[];
  selectedMims: ISerializedMimsInPlan[];
  onAccept: () => boolean;
  onDecline: () => boolean;
  onChangeCount: (id: number, value: string, type: "added" | "selected") => void;
  onDelete: (id: number) => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, item: ISerializedMimsInPlan) => void;
  selectedIds: number[];
  addedIds: number[];
  expenditureId: number;
  presettedPercentage?: number;
  onAddPresettedMims?: (mims: ISerializedMimsInPlan[]) => void;
  wholeWorkCount?: number;
  defaultMimsList?: ISerializedMimsInPlan[];
  isWorkGroup?: boolean;
}

const AddPlanMimsInModal: React.FC<IProps> = ({
  addedMims,
  selectedMims,
  onAccept,
  onDecline,
  onChangeCount,
  onDelete,
  onSelect,
  selectedIds,
  addedIds,
  expenditureId,
  presettedPercentage,
  onAddPresettedMims,
  wholeWorkCount,
  defaultMimsList,
  isWorkGroup,
}) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const { allMims, isLoading, loadMoreHandler, count, filterHandler, presettedPlanMims } = useMimsPlanList({
    objectId: Number(objectId),
    expId: expenditureId,
    presettedPercentage,
    defaultMimsList,
    isWorkGroup,
  });

  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if (!presettedPlanMims?.length || isWorkGroup) return;
    onAddPresettedMims?.(presettedPlanMims);
  }, [presettedPlanMims, isWorkGroup]);

  const allMimsWithoutAdded = useMemo(() => {
    return filterRestPlannedMims(allMims, addedIds);
  }, [allMims, addedIds]);

  const canAddMoreMims = addedMims?.length < count;

  const withStopAdding = React.useCallback((cb: () => boolean) => {
    return () => cb?.() && setIsAdding(false);
  }, []);

  return (
    <Expandable title={`Машины и Механизмы: ${addedMims?.length || "-"}`} className={styles.wrapper}>
      {!!addedMims?.length && (
        <div className={styles.table}>
          <PlanMimsTableHeader />
          {addedMims?.map((el) => (
            <PlanMimsTableRow
              key={el.id + el.count}
              mim={el}
              canDelete
              onChangeCount={(e) => onChangeCount(el.id, e.target.value, "added")}
              onDelete={() => onDelete(el.id)}
              canBeChecked={false}
              isPrefilled
              wholeWorkCount={wholeWorkCount}
            />
          ))}
        </div>
      )}
      {canAddMoreMims && <div className={styles.subtitle}>Добавить {!!addedMims?.length ? "еще " : ""}МиМ</div>}
      {canAddMoreMims && !isAdding && <AddButton onClick={() => setIsAdding(true)} />}
      {canAddMoreMims && isAdding && (
        <ConfirmBlock
          count={selectedMims?.length}
          onAccept={withStopAdding(onAccept)}
          onDecline={withStopAdding(onDecline)}
        />
      )}
      {canAddMoreMims && isAdding && (
        <div className={cn(styles.table, styles.bordered)}>
          <PlanMimsTableHeader onFiltersChange={isWorkGroup ? undefined : filterHandler} className={styles.header} />
          {isLoading && <Spinner />}
          {!!(!isLoading && allMims?.length) &&
            allMimsWithoutAdded.map((el) => (
              <PlanMimsTableRow
                key={el.id}
                mim={el}
                onChangeCount={(e) => onChangeCount(el.id, e.target.value, "selected")}
                onCheck={onSelect}
                isSelected={selectedIds.includes(el.id)}
                wholeWorkCount={wholeWorkCount}
              />
            ))}
          {!isLoading && !allMims?.length && <EmptyPlaceholder img={materials} />}
          <MoreButton
            isLoading={isLoading}
            allCount={count}
            existingCount={allMims?.length}
            onLoadMore={loadMoreHandler}
          />
        </div>
      )}
    </Expandable>
  );
};

export default React.memo(AddPlanMimsInModal);
