import cn from "classnames";
import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";

import styles from "./EmptyPlaceholder.module.scss";

export interface IEmptyPlaceholderProps {
  className?: string;
  children?: ReactNode;
  img?: string;
  svgJsx?: ReactNode;
  svgNative?: ReactNode;
  text?: string;
  fullHeight?: boolean;
}

const EmptyPlaceholder = ({
  className,
  children,
  img,
  svgJsx,
  svgNative,
  text = "Нет сведений",
  fullHeight = true,
}: IEmptyPlaceholderProps) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [offset, setOffset] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (!divRef.current || !fullHeight) return;
    setOffset(divRef.current.getBoundingClientRect().top);
  }, []);

  return (
    <div
      className={cn(styles.container, className)}
      ref={divRef}
      style={fullHeight ? { height: `calc(100vh - ${offset}px - 2.2rem)` } : {}}
    >
      <span className={styles.text}>{text}</span>
      <div className={styles.imgWrapper}>
        {img && <img src={img} className={styles.img} alt="иконка пустого контента" />}
        {svgJsx || null}
        {svgNative || null}
      </div>
      {children || <div className={styles.btnHolder} />}
    </div>
  );
};

export default React.memo(EmptyPlaceholder);
