import cn from "classnames";
import { filter, memoize } from "lodash";
import React, { SetStateAction } from "react";

import SearchSelectFilter from "components/UI/atoms/_TODO/SearchSelectFilter";
import { tasksListFilterType } from "components/pages/Tasks/model/types";

import Sorting from "shared/ui/controls/Sorting/Sorting";
import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";
import TableFilterTextInput from "shared/ui/inputs/TableFilterTextInput/TableFilterTextInput";

import { TASKS_PRIORITY_OPTIONS, TASKS_STATUSES_OPTIONS, TASK_PRIORITY, TASK_STATUSES } from "../../constants";

import styles from "./TaskTableHead.module.scss";

interface Iprops {
  onChangeFilters: (name: keyof tasksListFilterType, value: tasksListFilterType[typeof name]) => void;
  className?: string;
  setFilterFull: React.Dispatch<SetStateAction<{}>>;
  filter: tasksListFilterType;
  isAllObjects?: boolean;
}

function TaskTableHead({ onChangeFilters, className, setFilterFull, filter, isAllObjects }: Iprops) {
  const setFilter = React.useCallback(
    (key: keyof tasksListFilterType, value: tasksListFilterType[typeof key]) => onChangeFilters(key, value),
    [onChangeFilters]
  );
  const partialSetFilter = React.useMemo(
    () =>
      memoize((key: keyof tasksListFilterType) => (value: tasksListFilterType[typeof key]) => setFilter(key, value)),
    [setFilter]
  );

  return (
    <TableReusableHead className={cn(styles.head, className)}>
      <TableReusableHeaderCell>
        №
        {!isAllObjects && (
          <Sorting
            isDown={filter.primary_ordering === "number"}
            onClick={() => {
              if (filter.primary_ordering === "number") {
                setFilter("primary_ordering", "");
              } else {
                setFilter("primary_ordering", "number");
              }
            }}
          />
        )}
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>
        Наименование задачи
        <TableFilterTextInput title="Наименование задачи" setFilter={partialSetFilter("search")} />
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>
        Исполнитель
        <TableFilterTextInput title="Исполнитель" setFilter={partialSetFilter("executor_name")} />
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        isCentered
        filterType="datesRange"
        rangeEndField="created_end"
        rangeStartField="created_start"
        onChangeFilter={(key, value) => setFilter(key as keyof tasksListFilterType, value)}
        setFilter={setFilterFull}
      >
        Создана
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        isCentered
        filterType="datesRange"
        rangeEndField="deadline_end"
        rangeStartField="deadline_start"
        onChangeFilter={(key, value) => setFilter(key as keyof tasksListFilterType, value)}
        setFilter={setFilterFull}
      >
        Дата сдачи
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>
        Статус{/* @ts-ignore */}
        <SearchSelectFilter title="Статус" filterName={TASKS_STATUSES_OPTIONS} setFilter={partialSetFilter("status")} />
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>
        Приоритет{/* @ts-ignore */}
        <SearchSelectFilter
          title="Приоритет"
          filterName={TASKS_PRIORITY_OPTIONS}
          setFilter={partialSetFilter("priority")}
        />
      </TableReusableHeaderCell>
      <div />
      <div />
    </TableReusableHead>
  );
}

export default React.memo(TaskTableHead);
