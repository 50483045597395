import React, { useEffect, useState } from "react";

import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";
import { apiGetTicketHistory } from "redux/modules/common/building/shedulesApi";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { Spinner } from "../../../../../../../../../../../shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "../../../../../../../../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import HistoryItem from "./HistoryItem";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./HistoryModal.module.scss";

interface IProps {
  expenditure: IExpendituresInJournalFulfillment["items"][0];
  isOpen: boolean;
  handleCloseModal: () => void;
}

const HistoryModal: React.FC<IProps> = ({ expenditure, isOpen, handleCloseModal }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const objectId = useObjectId();

  const loadEx = async (objectId: string, expenditureId: number) => {
    setIsLoading(true);
    const ex = await apiGetTicketHistory(objectId, expenditureId);
    await setData(ex?.results);
    setIsLoading(false);
  };

  useEffect(() => {
    isOpen && loadEx(objectId, expenditure?.expenditure?.expenditure_id!);
  }, [isOpen]);

  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={handleCloseModal} className={styles.sliderClassName}>
        <div className={styles.container}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>История выполнения</div>
            <div className={styles.justification}>{expenditure?.expenditure?.justification}</div>
          </div>
          <div className={styles.subTitle}>{expenditure?.expenditure?.name}</div>
          <div className={styles.content}>
            {isLoading && <Spinner />}
            {/* @ts-ignore */}
            {!isLoading && data?.length ? data?.map((item) => <HistoryItem key={item.id} data={item} />) : null}
            {!isLoading && !data?.length /* @ts-ignore */ ? (
              <EmptyPlaceholder text="Нет сведений" img={jurnalIcon} size="small" />
            ) : null}
          </div>
        </div>
      </SliderModal>
    </>
  );
};

export default HistoryModal;
