import axios from "axios";

import { IBuildingSection, IGetBuildingSectionsParams } from "./types";
import { IListResponseData } from "types/ListResponse";

export const buildingApi = {
  getSections: (objectId: string, params: IGetBuildingSectionsParams) => {
    return axios.get<IListResponseData<IBuildingSection>>(`/building/${objectId}/estimate/sections/`, {
      params: {
        ...params,
        limit: 500,
      },
    });
  },
};
