import React from "react";

export default function BillsIcon({ color = "#000", width = "23" }) {
  return (
    <svg width={width} height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.32769 3.1067C8.28997 3.01574 8.22519 2.93858 8.14213 2.88568C8.05907 2.83278 7.96176 2.80671 7.86338 2.811C7.765 2.81529 7.67032 2.84974 7.59219 2.90967C7.51405 2.96959 7.45624 3.0521 7.42658 3.14601C7.39693 3.23991 7.39686 3.34066 7.42641 3.43459C7.45596 3.52853 7.51369 3.6111 7.59176 3.67111C7.66983 3.73112 7.76447 3.76567 7.86285 3.77007C7.96122 3.77447 8.05857 3.7485 8.14169 3.6957C8.23645 3.63457 8.3069 3.54227 8.34086 3.43473C8.37482 3.32719 8.37016 3.21117 8.32769 3.1067Z"
        fill={color}
      />
      <path
        d="M11.0145 21.9428H1.74549C1.37674 21.9422 1.02324 21.7955 0.762491 21.5348C0.501743 21.274 0.355021 20.9205 0.354492 20.5518L0.354492 4.90877C0.355021 4.54001 0.501743 4.18651 0.762491 3.92576C1.02324 3.66502 1.37674 3.5183 1.74549 3.51777H4.80449C4.91942 2.78357 5.29287 2.11459 5.85752 1.63146C6.42217 1.14832 7.14086 0.882812 7.88399 0.882812C8.62713 0.882813 9.34581 1.14832 9.91046 1.63146C10.4751 2.11459 10.8486 2.78357 10.9635 3.51777H14.0215C14.3902 3.5183 14.7437 3.66502 15.0045 3.92576C15.2652 4.18651 15.412 4.54001 15.4125 4.90877V12.2708H14.4545V4.90877C14.4542 4.7948 14.4088 4.68559 14.3283 4.605C14.2477 4.52442 14.1385 4.47903 14.0245 4.47877H12.6715V6.29877C12.6715 6.42634 12.6208 6.54868 12.5306 6.63888C12.4404 6.72909 12.3181 6.77977 12.1905 6.77977H3.57549C3.44792 6.77977 3.32558 6.72909 3.23537 6.63888C3.14517 6.54868 3.09449 6.42634 3.09449 6.29877V4.48177H1.74549C1.63153 4.48203 1.52231 4.52742 1.44173 4.608C1.36114 4.68859 1.31576 4.7978 1.31549 4.91177V20.5518C1.31576 20.6657 1.36114 20.7749 1.44173 20.8555C1.52231 20.9361 1.63153 20.9815 1.74549 20.9818H11.0145V21.9418V21.9428ZM4.05449 4.48177V5.81877H11.7085V4.48177H10.5195C10.3924 4.48021 10.271 4.42903 10.1811 4.33916C10.0912 4.24929 10.04 4.12785 10.0385 4.00077C10.0388 3.42887 9.81189 2.88027 9.40778 2.4756C9.00367 2.07092 8.45539 1.8433 7.88349 1.84277C7.31412 1.85028 6.77019 2.07987 6.36764 2.48261C5.96508 2.88535 5.73574 3.42939 5.72849 3.99877C5.72694 4.12585 5.67576 4.24729 5.58589 4.33716C5.49602 4.42703 5.37458 4.47821 5.24749 4.47977L4.05449 4.48177ZM11.3125 13.5818C11.4324 13.3104 11.6096 13.0682 11.8319 12.8718C12.0543 12.6754 12.3164 12.5294 12.6005 12.4438C12.0513 12.66 11.5959 13.0629 11.3145 13.5818H11.3125Z"
        fill={color}
      />
      <path
        d="M6.20546 9.47665H12.7165C12.9095 9.47665 13.0665 9.26165 13.0665 8.99565C13.0665 8.72965 12.9095 8.51465 12.7165 8.51465H6.20546C6.01246 8.51465 5.85547 8.72965 5.85547 8.99565C5.85547 9.26165 6.01646 9.47665 6.20546 9.47665Z"
        fill={color}
      />
      <path
        d="M6.32138 12.3741H11.9604C12.084 12.3684 12.2007 12.3154 12.2862 12.2259C12.3717 12.1364 12.4195 12.0174 12.4195 11.8936C12.4195 11.7698 12.3717 11.6508 12.2862 11.5613C12.2007 11.4718 12.084 11.4187 11.9604 11.4131H6.32138C6.19774 11.4187 6.08102 11.4718 5.99552 11.5613C5.91002 11.6508 5.8623 11.7698 5.8623 11.8936C5.8623 12.0174 5.91002 12.1364 5.99552 12.2259C6.08102 12.3154 6.19774 12.3684 6.32138 12.3741Z"
        fill={color}
      />
      <path
        d="M9.29948 14.4482H6.25247C6.13993 14.4689 6.03819 14.5283 5.96494 14.6161C5.89168 14.704 5.85156 14.8148 5.85156 14.9293C5.85156 15.0437 5.89168 15.1545 5.96494 15.2423C6.03819 15.3302 6.13993 15.3896 6.25247 15.4102H9.29447C9.40701 15.3896 9.50876 15.3302 9.58202 15.2423C9.65527 15.1545 9.69538 15.0437 9.69538 14.9293C9.69538 14.8148 9.65527 14.704 9.58202 14.6161C9.50876 14.5283 9.40701 14.4689 9.29447 14.4482H9.29948Z"
        fill={color}
      />
      <path d="M2.16895 8.99551L3.07495 9.90151L5.08195 7.89551" stroke={color} />
      <path d="M2.16895 12.2504L3.07495 13.1564L5.08195 11.1504" stroke={color} />
      <path d="M2.16895 15.0248L3.07495 15.9308L5.08195 13.9248" stroke={color} />
      <path
        d="M16.5002 24.6449C19.7034 24.6449 22.3002 22.0482 22.3002 18.8449C22.3002 15.6417 19.7034 13.0449 16.5002 13.0449C13.2969 13.0449 10.7002 15.6417 10.7002 18.8449C10.7002 22.0482 13.2969 24.6449 16.5002 24.6449Z"
        stroke={color}
        strokeWidth="1.4"
      />
      <path
        d="M16.8717 14.9759H15.1307V18.8929H14.2607V19.3279H15.1307V20.4279H14.2607V20.8629H15.1307V21.9399H15.5657V20.8679H17.0887V20.4329H15.5667V19.3329H16.8527C16.8647 19.3329 16.9377 19.3399 17.0527 19.3399C17.708 19.3594 18.3487 19.1445 18.8597 18.7339C19.0708 18.5312 19.2356 18.2854 19.3431 18.0133C19.4506 17.7411 19.4983 17.449 19.4827 17.1569C19.4983 16.8647 19.4506 16.5726 19.3431 16.3005C19.2356 16.0283 19.0708 15.7826 18.8597 15.5799C18.2956 15.1376 17.5866 14.9221 16.8717 14.9759ZM18.5717 18.4059C18.0833 18.7699 17.4788 18.9431 16.8717 18.8929H15.5667V15.4109H16.8917C17.4905 15.3643 18.0858 15.5371 18.5667 15.8969C18.7322 16.0594 18.8608 16.2555 18.944 16.472C19.0271 16.6885 19.0629 16.9204 19.0487 17.1519C19.0629 17.383 19.0274 17.6146 18.9446 17.8309C18.8618 18.0472 18.7336 18.2432 18.5687 18.4059H18.5717Z"
        fill={color}
        stroke={color}
        strokeWidth="0.4"
      />
    </svg>
  );
}
