import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { findBottomTopBounds } from "./utils";

export interface IUseChartSliceProps {
  initialData: any[];
  xAxisKey: string;
}

export const useChartSlice = ({ initialData, xAxisKey }: IUseChartSliceProps) => {
  const defaultBottomTopBounds = useMemo(() => findBottomTopBounds(initialData, xAxisKey), [initialData, xAxisKey]);

  const offset = (typeof defaultBottomTopBounds[1] === "number" ? defaultBottomTopBounds[1] : 0) / 20;

  const [defaultBoundaries, setDefaultBoundaries] = useState(() => ({
    left: "dataMin",
    right: "dataMax",
    top: `dataMax + ${offset}`,
    bottom: "dataMin",
  }));

  const [state, setState] = useState<any>(() => ({
    data: [...initialData],
    refAreaLeft: "",
    refAreaRight: "",
    ...defaultBoundaries,
  }));

  useEffect(() => {
    const updatedBounds = {
      left: "dataMin",
      right: "dataMax",
      top: `dataMax + ${offset}`,
      bottom: "dataMin",
    };
    setDefaultBoundaries(updatedBounds); /* @ts-ignore */
    setState((prevState) => ({
      ...prevState,
      ...updatedBounds,
    }));
  }, [offset]);

  useEffect(() => {
    /* @ts-ignore */
    setState((prevState) => ({ ...prevState, data: [...initialData] }));
  }, [initialData]);

  const getAxisYDomain = useCallback(
    (from: string | undefined, to: string | undefined) => {
      if (!from || !to) return [defaultBoundaries.bottom, defaultBoundaries.top];

      const fromIndex = initialData.findIndex((x) => x.date === from);
      const toIndex = initialData.findIndex((x) => x.date === to);
      const refData = initialData.slice(fromIndex, toIndex + 1);

      const [bottom, top] = findBottomTopBounds(refData, xAxisKey);

      const offset = typeof top === "number" ? top / 20 : 0;

      return [Math.max(0, (bottom | 0) - offset || bottom), Math.max(0, (top | 0) + offset) || top];
    },
    [xAxisKey, initialData, defaultBoundaries]
  );

  const zoom = () => {
    let { refAreaLeft, refAreaRight } = state;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      /* @ts-ignore */
      setState((prevState) => ({
        ...prevState,
        refAreaLeft: "",
        refAreaRight: "",
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    // yAxis domain
    const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight);
    /* @ts-ignore */
    setState((prevState) => ({
      ...prevState,
      refAreaLeft: "",
      refAreaRight: "",
      data: prevState.data.slice(),
      left: refAreaLeft,
      right: refAreaRight,
      bottom,
      top,
    }));
  };

  const zoomOut = () => {
    /* @ts-ignore */
    setState((prevState) => ({
      ...prevState,
      data: prevState.data.slice(),
      refAreaLeft: "",
      refAreaRight: "",
      ...defaultBoundaries,
    }));
  };
  /* @ts-ignore */
  const onMouseMove = (e) => {
    if (!state.refAreaLeft) return; /* @ts-ignore */
    setState((prevState) => ({ ...prevState, refAreaRight: e?.activeLabel }));
  };
  /* @ts-ignore */
  const onMouseDown = (e) => {
    /* @ts-ignore */
    setState((prevState) => ({ ...prevState, refAreaLeft: e?.activeLabel }));
  };

  const isZoomed = useMemo(
    () =>
      !_.isEqual(
        defaultBoundaries,
        Object.fromEntries(Object.entries(defaultBoundaries).map(([k, _]) => [k, state[k]]))
      ),
    [state, defaultBoundaries]
  );

  return { ...state, zoom, zoomOut, onMouseDown, onMouseMove, isZoomed };
};
