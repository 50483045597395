import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { getPlanFactSummaryExtraCosts } from "../model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";

import { useTypedParams } from "utils/hooks/useTypedParams";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const usePlanFactExtraCosts = () => {
  const dispatch = useDispatch();
  const { objectId } = useTypedParams();

  const loadingKey = generateStorageKey({ objectId, type: "extraCosts" });
  const extraCostsData = useTypedSelector((state) => state.planFactReducer.planFactSummaryReducer.extraCosts[objectId]);
  const isExtraCostsLoading = useTypedSelector(
    (state) => state.planFactReducer.planFactSummaryReducer.loadings[loadingKey]
  );
  const extraCosts = extraCostsData?.list;

  useEffect(() => {
    if (extraCostsData?.list && Date.now() - (extraCostsData?.loadedAt ?? 0) < 60_000) return;
    dispatch(getPlanFactSummaryExtraCosts(objectId));
  }, [objectId, extraCostsData]);

  const extraCostsCount = useTypedSelector(
    (state) => state.planFactReducer.planFactSummaryReducer.extraCosts[objectId]
  )?.count;

  return {
    extraCosts,
    isExtraCostsLoading,
    extraCostsCount,
    objectId,
  };
};
