import { ISetDiscountCostResponse } from "./api";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import ISection from "../../../../../types/interfaces/Section";
import { EEstimateStatesIds } from "../../ProHandler/enums";
import { EHandlerLevels, ESimpleHandlerMode, IEditingExpenditure, ISimpleHandlerState } from "../types";

const initialState: ISimpleHandlerState = {
  mode: ESimpleHandlerMode.VIEW,
  estimateStateId: null,

  isSectionAdding: false,

  addingExpenditures: [],
  creationFormLoadingIds: [],

  editingExpenditures: {},
  editingLoadingExpendituresIds: [],

  sections: null,
  activeSection: null,

  subSections: null,
  activeSubSection: null,

  sectionsAreLoading: false,
  subSectionsAreLoading: false,

  expenditures: null,
  expendituresAreLoading: false,

  checkedItems: [],

  isItemsStateChanging: false,

  deletingExpendituresIds: [],

  newSections: null,
  confirmedSections: null,

  newSubSections: null,
  confirmedSubSections: null,

  invalidateKey: 0,
};

const simpleHandlerSlice = createSlice({
  name: "simpleHandler",
  initialState,
  reducers: {
    setMode: (state, { payload }: PayloadAction<ESimpleHandlerMode>) => {
      state.mode = payload;
    },
    setEstimateStateId: (state, { payload }: PayloadAction<EEstimateStatesIds | null>) => {
      state.estimateStateId = payload;
    },

    setSectionsAreLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.sectionsAreLoading = payload;
    },
    setSections: (state, { payload }: PayloadAction<ISection[] | null>) => {
      state.sections = payload;
      state.sectionsAreLoading = false;
    },
    setActiveSection: (state, { payload }: PayloadAction<ISection | null>) => {
      state.activeSection = payload;
    },
    addSection: (state, { payload }: PayloadAction<ISection>) => {
      if (state.sections) {
        state.sections.push(payload);
      } else {
        state.sections = [payload];
      }
    },
    replaceSection: (state, { payload }: PayloadAction<{ data: ISection; status?: "new" | "confirmed" }>) => {
      if (payload.status === "new") {
        if (state.newSections) {
          const idx = state.newSections.findIndex((s) => s.id === payload.data.id);
          if (idx !== -1) {
            state.newSections[idx] = payload.data;
          }
        }
      } else if (payload.status === "confirmed") {
        if (state.confirmedSections) {
          const idx = state.confirmedSections.findIndex((s) => s.id === payload.data.id);
          if (idx !== -1) {
            state.confirmedSections[idx] = payload.data;
          }
        }
      } else {
        if (state.sections) {
          const idx = state.sections.findIndex((s) => s.id === payload.data.id);
          if (idx !== -1) {
            state.sections[idx] = payload.data;
          }
        }
      }
    },
    replaceSubSection: (state, { payload }: PayloadAction<{ data: ISection; status?: "new" | "confirmed" }>) => {
      if (payload.status === "new") {
        if (state.newSubSections) {
          const idx = state.newSubSections.findIndex((s) => s.id === payload.data.id);
          if (idx !== -1) {
            state.newSubSections[idx] = payload.data;
          }
        }
      } else if (payload.status === "confirmed") {
        if (state.confirmedSubSections) {
          const idx = state.confirmedSubSections.findIndex((s) => s.id === payload.data.id);
          if (idx !== -1) {
            state.confirmedSubSections[idx] = payload.data;
          }
        }
      } else {
        if (state.subSections) {
          const idx = state.subSections.findIndex((s) => s.id === payload.data.id);
          if (idx !== -1) {
            state.subSections[idx] = payload.data;
          }
        }
      }
    },
    deleteSection: (state, { payload }: PayloadAction<ISection["id"]>) => {
      if (state.sections) {
        state.sections = state.sections.filter((s) => s.id !== payload);
      }
    },
    deleteSubSection: (state, { payload }: PayloadAction<ISection["id"]>) => {
      if (state.subSections) {
        state.subSections = state.subSections.filter((s) => s.id !== payload);
      }
    },

    setSectionMarkupCost: (state, { payload }: PayloadAction<{ id: ISection["id"]; markup_cost: string }>) => {
      if (state.sections) {
        const idx = state.sections.findIndex((s) => s.id === payload.id) ?? -1;
        if (idx !== -1) {
          state.sections[idx].markup_cost = payload.markup_cost;
        }
      }
    },
    setSectionEstimatedCost: (state, { payload }: PayloadAction<{ id: ISection["id"]; estimated_cost: string }>) => {
      if (state.sections) {
        const idx = state.sections.findIndex((s) => s.id === payload.id) ?? -1;
        if (idx !== -1) {
          state.sections[idx].indicators.estimated_cost = payload.estimated_cost;
        }
      }
    },
    updateSectionDiscountCost: (state, { payload }: PayloadAction<ISetDiscountCostResponse>) => {
      if (state.sections) {
        const idx = state.sections.findIndex((s) => s.id === payload.id) ?? -1;
        if (idx !== -1) {
          state.sections[idx].indicators.estimated_cost = payload.estimated_cost;
          state.sections[idx].indicators.estimate_amount = payload.estimate_amount;
          state.sections[idx].indicators.discount_cost = payload.discount_cost;
          state.sections[idx].indicators.discount_percent = payload.discount_percent;
        }
      }
    },

    setSubSectionsAreLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.subSectionsAreLoading = payload;
    },
    setSubSections: (state, { payload }: PayloadAction<ISection[] | null>) => {
      state.subSections = payload;
      state.subSectionsAreLoading = false;
    },
    setActiveSubSection: (state, { payload }: PayloadAction<ISection | null>) => {
      state.activeSubSection = payload;
    },
    addSubSection: (state, { payload }: PayloadAction<ISection>) => {
      if (state.subSections) {
        state.subSections.push(payload);
      } else {
        state.subSections = [payload];
      }
    },

    setSubSectionMarkupCost: (state, { payload }: PayloadAction<{ id: ISection["id"]; markup_cost: string }>) => {
      if (state.subSections) {
        const idx = state.subSections.findIndex((s) => s.id === payload.id) ?? -1;
        if (idx !== -1) {
          state.subSections[idx].markup_cost = payload.markup_cost;
        }
      }
    },
    setSubSectionEstimatedCost: (state, { payload }: PayloadAction<{ id: ISection["id"]; estimated_cost: string }>) => {
      if (state.subSections) {
        const idx = state.subSections.findIndex((s) => s.id === payload.id) ?? -1;
        if (idx !== -1) {
          state.subSections[idx].indicators.estimated_cost = payload.estimated_cost;
        }
      }
    },
    updateSubSectionDiscountCost: (state, { payload }: PayloadAction<ISetDiscountCostResponse>) => {
      if (state.subSections) {
        const idx = state.subSections.findIndex((s) => s.id === payload.id) ?? -1;
        if (idx !== -1) {
          state.subSections[idx].indicators.estimated_cost = payload.estimated_cost;
          state.subSections[idx].indicators.estimate_amount = payload.estimate_amount;
          state.subSections[idx].indicators.discount_cost = payload.discount_cost;
          state.subSections[idx].indicators.discount_percent = payload.discount_percent;
        }
      }
    },

    setExpendituresAreLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.expendituresAreLoading = payload;
    },
    setExpenditures: (state, { payload }: PayloadAction<any[] | null>) => {
      state.expenditures = payload;
    },
    setExpenditureMarkup: (
      state,
      { payload }: PayloadAction<{ id: number; markup_cost: string; markup_percent: string }>
    ) => {
      if (state.expenditures) {
        const idx = state.expenditures.findIndex((e) => e.id === payload.id);

        if (idx !== -1) {
          state.expenditures[idx].markup_cost = payload.markup_cost;
          state.expenditures[idx].markup_percent = payload.markup_percent;
        }
      }
    },
    setExpenditureDiscount: (state, { payload }: PayloadAction<ISetDiscountCostResponse>) => {
      if (state.expenditures) {
        const idx = state.expenditures.findIndex((e) => e.id === payload.id);

        if (idx !== -1) {
          state.expenditures[idx].discount_cost = payload.discount_cost;
          state.expenditures[idx].discount_percent = payload.discount_percent;
          state.expenditures[idx].estimated_cost = payload.estimated_cost;
          state.expenditures[idx].discount_percent = payload.discount_percent;
          state.expenditures[idx].indicators.estimate_amount = payload.estimate_amount;
          state.expenditures[idx].price = payload.price ?? "0";
          state.expenditures[idx].price_source = payload.price_source ?? "0";

          if (state.activeSubSection && state.activeSubSection.id === payload.section?.id) {
            state.activeSubSection.indicators.estimated_cost = payload.section?.estimated_cost;
            state.activeSubSection.indicators.estimate_amount = payload.section?.estimate_amount;
            state.activeSubSection.indicators.discount_cost = payload.section?.discount_cost;
            state.activeSubSection.indicators.discount_percent = payload.section?.discount_percent;
          }
        }
      }
    },

    setExpenditureEstimatedCost: (state, { payload }: PayloadAction<{ id: number; estimated_cost: string }>) => {
      if (state.expenditures) {
        const idx = state.expenditures.findIndex((e) => e.id === payload.id);

        if (idx !== -1) {
          state.expenditures[idx].estimated_cost = payload.estimated_cost;
        }
      }
    },

    setIsSectionAdding: (state, { payload }: PayloadAction<boolean>) => {
      state.isSectionAdding = payload;
    },

    toggleCheckedItem: (state, { payload }: PayloadAction<{ id: number; name: string }>) => {
      const idx = state.checkedItems.findIndex((item) => item.id === payload.id);
      if (idx !== -1) {
        state.checkedItems.splice(idx, 1);
      } else {
        state.checkedItems.push(payload);
      }
    },

    clearCheckedItems: (state) => {
      state.checkedItems = [];
    },
    checkAllItems: (state, { payload }: PayloadAction<EHandlerLevels>) => {
      if (payload === EHandlerLevels.SECTIONS) {
        if (state.estimateStateId === EEstimateStatesIds.LOCALE) {
          state.checkedItems = state.confirmedSections?.map((s) => ({ id: s.id, name: s.name })) ?? [];
        } else {
          state.checkedItems = state.sections?.map((s) => ({ id: s.id, name: s.name })) ?? [];
        }
      } else if (payload === EHandlerLevels.SUBSECTIONS) {
        if (state.estimateStateId === EEstimateStatesIds.LOCALE) {
          state.checkedItems = state.confirmedSubSections?.map((s) => ({ id: s.id, name: s.name })) ?? [];
        } else {
          state.checkedItems = state.subSections?.map((s) => ({ id: s.id, name: s.name })) ?? [];
        }
      } else {
        state.checkedItems = state.expenditures?.map((s) => ({ id: s.id, name: s.name })) ?? [];
      }
    },

    setIsItemsStateChanging: (state, { payload }: PayloadAction<boolean>) => {
      state.isItemsStateChanging = payload;
    },

    createAddingExpenditure: (state) => {
      state.addingExpenditures.push(Math.random().toString());
    },

    removeAddingExpenditure: (state, { payload }: PayloadAction<string>) => {
      state.addingExpenditures = state.addingExpenditures.filter((v) => v !== payload);
    },

    invalidate: (state) => {
      state.invalidateKey += 1;
    },

    addEditingExpenditure: (state, { payload }: PayloadAction<IEditingExpenditure>) => {
      state.editingExpenditures[payload.id] = payload;
    },

    removeEditingExpenditure: (state, { payload }: PayloadAction<number>) => {
      delete state.editingExpenditures[payload];
    },

    clearEditingExpenditures: (state) => {
      state.editingExpenditures = {};
    },

    clearAddingExpenditures: (state) => {
      state.addingExpenditures = [];
    },

    addCreationFormLoadingId: (state, { payload }: PayloadAction<string>) => {
      state.creationFormLoadingIds.push(payload);
    },

    removeCreationFormLoadingId: (state, { payload }: PayloadAction<string>) => {
      state.creationFormLoadingIds = state.creationFormLoadingIds.filter((v) => v !== payload);
    },

    addEditingLoadingExpenditureId: (state, { payload }: PayloadAction<number>) => {
      state.editingLoadingExpendituresIds.push(payload);
    },

    removeEditingLoadingExpenditureId: (state, { payload }: PayloadAction<number>) => {
      state.editingLoadingExpendituresIds = state.editingLoadingExpendituresIds.filter((id) => id !== payload);
    },

    addDeletingExpenditureId: (state, { payload }: PayloadAction<number>) => {
      state.deletingExpendituresIds.push(payload);
    },

    removeDeletingExpenditureId: (state, { payload }: PayloadAction<number>) => {
      state.deletingExpendituresIds = state.deletingExpendituresIds.filter((id) => id !== payload);
    },

    setNewSections: (state, { payload }: PayloadAction<ISection[] | null>) => {
      state.newSections = payload;
      state.sectionsAreLoading = false;
    },
    setConfirmedSections: (state, { payload }: PayloadAction<ISection[] | null>) => {
      state.confirmedSections = payload;
      state.sectionsAreLoading = false;
    },

    setNewSubSections: (state, { payload }: PayloadAction<ISection[] | null>) => {
      state.newSubSections = payload;
      state.sectionsAreLoading = false;
    },
    setConfirmedSubSections: (state, { payload }: PayloadAction<ISection[] | null>) => {
      state.confirmedSubSections = payload;
      state.sectionsAreLoading = false;
    },

    reset: () => {
      return initialState;
    },
  },
});

export const simpleHandlerActions = simpleHandlerSlice.actions;
export default simpleHandlerSlice.reducer;
