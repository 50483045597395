import { IActingAction } from "./actions";
import {
  deselectActingSectionsAndExpenditures,
  selectActingExpenditure,
  selectActingSection,
  setActingExpenditures,
  setActingSections,
} from "./dedicatedReducers";

import { IActingState } from "./types";

export const actingInitialState: IActingState = {
  invalidateKey: 1,
  estimates: {},
  sections: {},
  expenditures: {},
  ks2: {},
  intersectingExpenditures: [],
  detailedKs2: {},
  selectedKs2Ids: {},
  detailedKs3: {},
  ks2byEstimate: {},
};

export default (state = actingInitialState, action: IActingAction): IActingState => {
  const { type, payload } = action;
  switch (type) {
    case "acting/INVALIDATE_CACHE":
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    case "acting/SET_ESTIMATES":
      return {
        ...state,
        estimates: {
          ...state.estimates,
          [payload.key]: {
            results: payload.estimates,
            isLoading: false,
          },
        },
      };
    case "acting/SET_ESTIMATES_LOADING":
      return {
        ...state,
        estimates: {
          ...state.estimates,
          [payload.key]: {
            ...(state.estimates[payload.key] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "acting/SET_SECTIONS":
      return setActingSections(state, action);
    case "acting/SET_SECTIONS_LOADING":
      return {
        ...state,
        sections: {
          ...state.sections,
          [payload.key]: {
            ...(state.sections[payload.key] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "acting/SET_EXPENDITURES":
      return setActingExpenditures(state, action);
    case "acting/SET_EXPENDITURES_LOADING":
      return {
        ...state,
        expenditures: {
          ...state.expenditures,
          [payload.key]: {
            ...(state.expenditures[payload.key] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "acting/SELECT_SECTION":
      return selectActingSection(state, action);
    case "acting/SELECT_EXPENDITURE":
      return selectActingExpenditure(state, action);
    case "acting/SET_KS_LIST":
      return {
        ...state,
        ks2: {
          ...state.ks2,
          [payload.key]: {
            results: payload.ks2list,
            isLoading: false,
          },
        },
      };
    case "acting/SET_KS_LIST_LOADING":
      return {
        ...state,
        ks2: {
          ...state.ks2,
          [payload.key]: {
            ...(state.ks2[payload.key] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "acting/SET_INTERSECTING_EXPENDITURES":
      return {
        ...state,
        intersectingExpenditures: payload,
      };
    case "acting/DESELECT_ALL":
      return deselectActingSectionsAndExpenditures(state, action);
    case "acting/SET_DETAILDES_KS2":
      return {
        ...state,
        detailedKs2: {
          ...state.detailedKs2,
          [payload.id]: payload,
        },
      };
    case "acting/SELECT_KS2_ID":
      return {
        ...state,
        selectedKs2Ids: {
          ...state.selectedKs2Ids,
          [payload.buildingId]: payload.status
            ? [...(state.selectedKs2Ids[payload.buildingId] || []), payload.ksId]
            : (state.selectedKs2Ids[payload.buildingId] || []).filter((el) => el !== payload.ksId),
        },
      };
    case "acting/DE_SELECT_ALL_KS2_IDS":
      return {
        ...state,
        selectedKs2Ids: {
          ...state.selectedKs2Ids,
          [payload]: [],
        },
      };
    case "acting/SET_DETAILDES_KS3":
      return {
        ...state,
        detailedKs3: {
          ...state.detailedKs3,
          [payload.id]: payload,
        },
      };
    case "acting/SET_KS2_LIST_BY_ESTIMATE":
      return {
        ...state,
        ks2byEstimate: {
          ...state.ks2byEstimate,
          [payload.estimateId]: { results: payload.list, isLoading: false },
        },
      };
    case "acting/SET_KS2_LIST_BY_ESTIMATE_LOADING":
      return {
        ...state,
        ks2byEstimate: {
          ...state.ks2byEstimate,
          [payload.estimateId]: { ...(state.ks2byEstimate[payload.estimateId] || []), isLoading: payload.status },
        },
      };
    case "acting/SET_DETAILED_KS2_IS_LOADING":
      return {
        ...state,
        detailedKs2: {
          ...state.detailedKs2,
          [payload.ks2Id]: {
            ...(state.detailedKs2[payload.ks2Id] || {}),
            isLoading: payload.status,
          },
        },
      };
    default:
      return state;
  }
};
