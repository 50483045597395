import cn from "classnames";
import React from "react";

import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Label from "../Label/Label";

import styles from "./LabeledItem.module.scss";

interface IProps {
  className?: string;
  children: React.ReactNode;
  label?: React.ReactNode;
  error?: string;
  isDefaultHeight?: boolean;
  additionalHeader?: React.ReactNode;
}

const LabeledItem: React.FC<IProps> = ({
  children,
  className,
  label,
  error,
  isDefaultHeight = true,
  additionalHeader,
}) => {
  return (
    <div className={cn(styles.container, className)}>
      {!!label && (
        <div className={styles.header}>
          <Label>{label}</Label>
          {additionalHeader}
        </div>
      )}
      <div className={cn(styles.content, { [styles.defaultHeight]: isDefaultHeight })}>{children}</div>
      {error && <ErrorMessage className={styles.error}>{error}</ErrorMessage>}
    </div>
  );
};

export default LabeledItem;
