import React from "react";

import SubTableHeader from "./SubTableHeader/SubTableHeader";
import SubTableRow from "./SubTableRow/SubTableRow";
import { useSubTableMovings } from "./useSubTableMovings";
import Spinner from "shared/ui/atoms/Spinner/Spinner";

interface IProps {
  movingId: number;
  stockId: string;
  measure: number;
}

const SubTableMovings: React.FC<IProps> = ({ movingId, stockId, measure = 1000 }) => {
  const { isLoading, products } = useSubTableMovings(stockId, movingId);

  if (!movingId) return null;

  return (
    <>
      <SubTableHeader />
      {isLoading && <Spinner />}
      {products?.map((el) => (
        <SubTableRow
          key={el.product}
          name={el.product}
          measureMoney={measure}
          measureProduct={el.measure}
          count={el.count}
          amount={el.amount}
        />
      ))}
    </>
  );
};

export default SubTableMovings;
