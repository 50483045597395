import cn from "classnames";
import React from "react";

import { IJournalFulFillmentGroup } from "redux/modules/common/building/journal/journalExecution/types";

import JournalExecFiles from "../../../JournalExecTicketV2/components/JournalExecFiles/JournalExecFiles";

import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";
import { IGroupServiceDetailInJournalExec } from "types/interfaces/Groups";
import { IStockUsingMims } from "types/interfaces/Mims";

import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./RelatedMims.module.scss";

interface IProps {
  data: IJournalFulFillmentGroup["services"][0];
}

const RelatedMims: React.FC<IProps> = ({ data }) => {
  const computedConfirmedPrice = Number(data?.amount) || 0;

  if (!data) return null;

  return (
    <div className={styles.container}>
      <div className={cn(styles.rowItem, styles.nameBlock)}>
        <div className={styles.name} title={data?.name}>
          {data?.name}
        </div>
        {/* <JournalExecFiles
          name={data?.name!}
          expenditureId={data?.expenditure_id}
          expenditureType={data?.expenditure_type}
          isExpired={data?.attachments?.has_expired!}
          count={data?.attachments?.count!}
        /> */}
      </div>
      <div className={cn(styles.rowItem, styles.centeredContent)}>{data.measure}</div>
      <div className={cn(styles.rowItem, styles.centeredContent)}>{data.count}</div>
      <div className={cn(styles.rowItem, styles.centeredContent)} />
      <div className={cn(styles.rowItem, styles.centeredContent)} />
      <div className={cn(styles.rowItem, styles.price)}>
        <div>{computedConfirmedPrice ? splitThousands(computedConfirmedPrice, false, false) : "-"}</div>
        <div style={{ minWidth: "1.25rem" }} />
      </div>
    </div>
  );
};

export default React.memo(RelatedMims);
