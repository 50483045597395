import React from "react";

export default function Stick({}) {
  return (
    <svg
      width="6"
      height="16"
      viewBox="0 0 6 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1V15C0 15.5523 0.447715 16 1 16C1.55228 16 2 15.5523 2 15V1Z"
        fill="white"
      />
      <path
        d="M6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1V15C4 15.5523 4.44772 16 5 16C5.55228 16 6 15.5523 6 15V1Z"
        fill="white"
      />
    </svg>

  )
}
