import React from "react";
import styles from './index.module.scss';
import cn from "classnames"

export const typeName = {
  payment: 'Оплата',
  supply: 'Поставка',
  task: "Задача"
};

export const EventPlateNew = ({count, type, activeCount, events, className, isCollapsed}) => {
  const isEventPrepay = events && events.find(event => event?.payment?.payment_term?.payment_type === 'prepay');

  return(
    <div
      className={cn(styles.container, className, {
        [styles.task]: type === 'task',
        [styles.payment]: type === 'payment',
        [styles.supply]: type === 'supply',
        [styles.prepay]: isEventPrepay,
        [styles.collapsed]: isCollapsed
      })}
      title={isEventPrepay && "Есть предоплата"}
    >
      <span className={styles.count}>{activeCount}/{count}</span>
      <span className={styles.title}>{typeName[type]}
        {isEventPrepay && <span className={styles.prepay}></span>}
      </span>
    </div>
  );
};
