import cn from "classnames";
import React from "react";

import styles from "./Radio.module.scss";

export interface IProps {
  className?: string;
  onClick?: () => void;
  isActive: boolean;
  isDisabled?: boolean;
}

const Radio: React.FC<IProps> = ({ className, onClick, isActive, isDisabled }) => {
  return (
    <div
      onClick={onClick}
      className={cn(styles.circle, className, {
        [styles.active]: isActive,
        [styles.disabled]: isDisabled,
      })}
    />
  );
};

export default React.memo(Radio);
