import React from "react";

import styles from "./PairOfInfoItems.module.scss";

type propsType = {
  label1: string;
  value1: React.ReactNode;
  label2: string;
  value2: React.ReactNode;
};

function PairOfInfoItems({ value1, value2, label1, label2 }: propsType) {
  return (
    <div className={styles.pair}>
      <div className={styles.info}>
        <b>{label1}</b>
        <span>{value1}</span>
      </div>
      <div className={styles.info}>
        <b>{label2}</b>
        <span>{value2}</span>
      </div>
    </div>
  );
}

export default PairOfInfoItems;
