import axios from 'axios'

export const saveExcel = async (id, number) => {
    try {
        const response = await axios.get(`/purchaser/invoices/${id}/reports/excel/`, {
            responseType: 'blob'
        })

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Счет №${number}.xlsx`)
        document.body.appendChild(link)
        link.click()
    } catch (e) {
        throw e
    }
}