import React from "react";

export default function ArrowRoundedRightBigIcon({width='23', color='#E6E6E6'}) {
  return (
    <svg 
      width={width}
      height="19" 
      viewBox="0 0 23 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M13.5087 0.9352C13.3508 0.784073 13.14 0.700786 12.9215 0.703175C12.703 0.705564 12.494 0.793445 12.3395 0.947987C12.185 1.10253 12.0971 1.31144 12.0947 1.52999C12.0923 1.74853 12.1756 1.95932 12.3267 2.11721L18.6567 8.4482H1.83672C1.72417 8.44431 1.61198 8.46313 1.50686 8.50351C1.40173 8.5439 1.30581 8.60505 1.22482 8.68329C1.14382 8.76154 1.07941 8.85529 1.03542 8.95896C0.991423 9.06263 0.96875 9.17408 0.96875 9.2867C0.96875 9.39932 0.991423 9.51078 1.03542 9.61444C1.07941 9.71811 1.14382 9.81186 1.22482 9.89011C1.30581 9.96836 1.40173 10.0295 1.50686 10.0699C1.61198 10.1103 1.72417 10.1291 1.83672 10.1252H18.6517L12.3217 16.4562C12.244 16.5338 12.1824 16.6259 12.1403 16.7273C12.0982 16.8287 12.0766 16.9374 12.0766 17.0472C12.0766 17.157 12.0982 17.2657 12.1403 17.3671C12.1824 17.4685 12.244 17.5606 12.3217 17.6382C12.4001 17.7171 12.4937 17.7793 12.5969 17.8211C12.7 17.8629 12.8105 17.8833 12.9217 17.8812C13.1458 17.8815 13.3611 17.7943 13.5217 17.6382L21.2777 9.88221C21.3563 9.80354 21.4183 9.70989 21.46 9.60683C21.5018 9.50377 21.5224 9.39338 21.5207 9.2822C21.5205 9.05827 21.4334 8.84315 21.2777 8.68219L13.5087 0.9352Z" 
        fill={color}
        stroke={color}
      />
    </svg>
  )
}
