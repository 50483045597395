import cn from "classnames";
import React, { useEffect, useState } from "react";

import {
  IAosrDetail,
  IAosrFactProduct,
  IPackingItemDocument,
} from "../../../../../../../../../../redux/modules/common/building/journal/aosr/types";

import Icon from "../../../../../../../../../../_LEGACY/UI/_LEGACY_Icon/Icon";
import AosrMaterialRow from "./components/AosrMaterialRow";

import { MATERIAL_SOURCES } from "../constants";

import editIcon from "../../../../../../../../../../images/icons/editIcon.svg";

import styles from "./AosrMaterials.module.scss";

export interface IAosrMaterialsProps {
  materials: IAosrDetail["materials"];
  canEdit?: boolean; //
  className?: string;
  onRemoveMaterial: (id: number) => void;
  onAddMaterial: (material: IAosrDetail["materials"][0]) => void;
  isOpen?: boolean;
  isViewMode?: boolean;
  source: MATERIAL_SOURCES;
}

const AosrMaterials: React.FC<IAosrMaterialsProps> = ({
  canEdit = true,
  className,
  onRemoveMaterial,
  onAddMaterial,
  isOpen,
  source,
  materials,
  isViewMode,
}) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const toggleEditMode = React.useCallback(() => canEdit && setIsEditMode((prevState) => !prevState), [canEdit]);

  useEffect(() => {
    if (!isOpen) setIsEditMode(false);
  }, [isOpen]);

  const existingMaterials = React.useMemo(() => {
    return (materials || []).filter((el) => !el.was_deleted);
  }, [materials]);

  const deletedMaterials = React.useMemo(() => {
    return (materials || []).filter((el) => el.was_deleted);
  }, [materials]);

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.header}>
        <span>{`Материалы: ${existingMaterials?.length}`}</span>
        {canEdit && <Icon icon={editIcon} className={styles.editIcon} onClick={toggleEditMode} />}
      </div>
      {existingMaterials?.map((material, index) => {
        return (
          <AosrMaterialRow
            key={`${material.material_id}_${material?.material_name}_${index}`}
            material={material}
            source={source}
            isBlueBg={index % 2 == 0}
            isEdit={isEditMode}
            onRemove={() => onRemoveMaterial(material.material_id)}
          />
        );
      })}
      {!isViewMode && !!deletedMaterials?.length && (
        <React.Fragment>
          <div className={styles.splitLine} />
          {deletedMaterials.map((material, index) => {
            return (
              <AosrMaterialRow
                key={`${material.material_id}_removed_${index}`}
                material={material}
                source={source}
                isBlueBg={index % 2 == 0}
                isEdit={isEditMode}
                onAdd={() => onAddMaterial(material)}
                type={"removed"}
              />
            );
          })}
        </React.Fragment>
      )}
    </div>
  );
};

export default React.memo(AosrMaterials);
