import { message } from "antd";
import axios from "axios";
import { compose } from "redux";

import { setRequisitionInfoAction } from "../actions";
import { infoDataSelector } from "../reducer";

import { errorCatcher } from "../../../../../../utils/helpers/errorCatcher";

export const completeRequisition = () => (dispatch, getState) => {
  const requisition = infoDataSelector(getState());
  if (!requisition) return;

  return axios
    .post(`/requisition/${requisition.id}/complete/`)
    .then((response) => {
      message.info("Заявка успешно завершена");
      compose(dispatch, setRequisitionInfoAction)(response.data);
    })
    .catch(errorCatcher);
};
