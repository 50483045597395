import { useEffect, useState } from "react";

import { REQUISITION_STATUS } from "../../../../constants/constant";
import { TABS_LINKS } from "../constants";

const getRequisitionsStatusesByTab = (activeTab) => {
  if (activeTab === TABS_LINKS.NEW_REQUISITIONS) {
    return [REQUISITION_STATUS.DRAFT, REQUISITION_STATUS.NEW];
  } else if (activeTab === TABS_LINKS.IN_WORK_REQUISITIONS) {
    return [REQUISITION_STATUS.PROCESSING_REQ];
  } else if (activeTab === TABS_LINKS.COMPLETED_REQUISITIONS) {
    return [REQUISITION_STATUS.COMPLETED];
  }
  return [REQUISITION_STATUS.PROCESSING_REQ];
};

const useRequisitionsStatusesByActiveTabId = (activeTab) => {
  const [requisitionsStatuses, setRequisitionsStatuses] = useState([]);

  useEffect(() => {
    setRequisitionsStatuses(getRequisitionsStatusesByTab(activeTab));
  }, [activeTab]);

  return requisitionsStatuses;
};

export default useRequisitionsStatusesByActiveTabId;
