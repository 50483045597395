import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IBillDetailed } from "./types";

interface BillsState {
  isLoading: Record<string, boolean>;
  invalidateKey: number;
  detailedBills: Record<string, IBillDetailed | null>;
  /* projectsLists: Record<string, IPropjectInList[]>;
  projectsListsCounts: Record<string, number>;
  projectsDetailed: Record<string, IDetailedProject>; */
}

const initialState: BillsState = {
  isLoading: {},
  invalidateKey: 0,
  detailedBills: {},
  /* projectsLists: {},
  projectsListsCounts: {},
  projectsDetailed: {}, */
};

export const billsSlice = createSlice({
  name: "bills",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      const { key, status } = action.payload;
      state.isLoading[key] = status;
    },
    invalidateKey: (state) => {
      state.invalidateKey++;
    },
    setDetailed: (state, { payload }: PayloadAction<{ key: string; data: IBillDetailed }>) => {
      state.detailedBills[payload.key] = payload.data;
    },
  },
});

export const billsActions = billsSlice.actions;

export default billsSlice.reducer;
