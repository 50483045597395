import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { objectDetailSelector, isObjectDetailLoadingSelector, getDetail } from "redux/modules/common/building/object/nowObject";
import {
  ks6aIsLoadingListSelector,
  ks6aListSelector,
  ks6aYearSelector,
  loadKs6aList,
  setKs6aFileUrl,
} from "redux/modules/common/ks6a/ks6a";

import Ks6aHeader from "./components/Ks6aHeader/Ks6aHeader";
import Ks6aTable, { IKs6aTableProps } from "./components/Ks6aTable/Ks6aTable";

import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import journalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./Ks6a.module.scss";

export interface IKs6aProps extends IKs6aTableProps {
  buildingId: number;
  year: number;
}

const Ks6a: React.FC<IKs6aProps> = ({ buildingId }) => {
  const dispatch = useDispatch();
  const year = useSelector(ks6aYearSelector);
  const list = useSelector(ks6aListSelector);
  const isLoadingList = useSelector(ks6aIsLoadingListSelector);
  const building = useSelector(objectDetailSelector);
  const buildingIsLoading = useSelector(isObjectDetailLoadingSelector);

  useEffect(() => {
    if (!buildingId || !year) return;
    dispatch(getDetail(buildingId));
    dispatch(loadKs6aList(buildingId, year));
    dispatch(setKs6aFileUrl(buildingId, year));
  }, [buildingId, year]);

  if (isLoadingList || buildingIsLoading) return <Spinner />;
  if (!list || !building) return null;

  return (
    <div className={styles.container}>
      {list.length === 0 ? (
        <EmptyPlaceholder img={journalIcon} className={styles.empty} />
      ) : (
        <>
          <Ks6aHeader
            year={year}
            docNumber={1}
            status={{ name: "Формирование", flow: "in" }}
            organisationName={building.entity?.name}
          />
          <Ks6aTable year={year} />
        </>
      )}
    </div>
  );
};

export default Ks6a;
