import { message } from "antd";
import axios from "axios";
import { compose } from "redux";

import {
  addDocumentsAction,
  addPackingListFileAction,
  deletePackingListFileAction,
  loadDocumentsAction,
  setDocumentsAction,
  setMoreDocumentAction,
  setPackingListAcceptingAction,
  setPackingListAction,
  setPackingListErrorAction,
  setPackingListIsNotFoundAction,
  setPackingListLoadingAction,
} from "./actions";

import { DEFAULT_LIMIT_DOCUMENT } from "./constants";

import { errorCatcher } from "../../../../utils/helpers/errorCatcher";

export const sendToPurchaser = (id, callback = null) => {
  return () => {
    axios.post(`/purchaser/packinglists/${id}/shipment/`).then((response) => callback?.(response.data));
  };
};

export const acceptPackingList = (id, data, callback = null) => {
  return (dispatch) => {
    dispatch(setPackingListAcceptingAction(true));
    axios
      .post(`/purchaser/packinglists/${id}/accept/`, data)
      .then((response) => {
        if (callback) callback(response.data);
        compose(dispatch, setPackingListAction)(response.data);
        message.success("УПД успешно принята");
      })
      .catch(errorCatcher)
      .finally(() => dispatch(setPackingListAcceptingAction(false)));
  };
};

export const acceptProviderPackingList = (id, callback = null) => {
  return (dispatch) => {
    axios.post(`/provider/packinglists/${id}/confirm/`).then((response) => {
      callback?.(response.data);
      message.success("Изменения в УПД успешно приняты");
    });
  };
};

export const loadDocumentsV2 = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (entityId, isPurchaser, paginationParams, filterParams, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        limit: DEFAULT_LIMIT_DOCUMENT,
        ordering: sorting,
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch, getState) => {
      dispatch(loadDocumentsAction());
      let prefix = getState().auth?.user?.account_type || "pcr";
      axios.get(`/purchaser/packinglists/`, config).then((response) => dispatch(setDocumentsAction(response.data)));
    };
  };
})();

export const addLoadDocument = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (entityId, paginationParams, filterParams = null, sorting = null) => {
    if (ge_cancel) ge_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        limit: DEFAULT_LIMIT_DOCUMENT,
      },
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    return (dispatch, getState) => {
      axios.get(`/purchaser/packinglists/`, config).then(
        (res) => {
          dispatch(addDocumentsAction(res.data));
        },
        (err) => {
          console.error(err);
        }
      );
    };
  };
})();

export const loadDocuments = (params) => {
  const config = {
    params,
  };

  return (dispatch) =>
    axios
      .get(`/purchaser/packinglists/`, config)
      .then((response) => dispatch(setDocumentsAction(response.data)), errorCatcher);
};

export const loadPackingListV2 = (docId, isWithoutLoader = false) => {
  return (dispatch) => {
    !isWithoutLoader && dispatch(setPackingListLoadingAction(true));

    axios
      .get(`/purchaser/packinglists/${docId}/`)
      .then((response) => dispatch(setPackingListAction(response.data)))
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch(setPackingListIsNotFoundAction(true));
        } else {
          errorCatcher(error);
          dispatch(setPackingListErrorAction());
        }
      });
  };
};

export const changePackingListDate = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;

  return (packingListId, data) => {
    if (ge_cancel) ge_cancel();

    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    return (dispatch) => {
      axios
        .patch(`/purchaser/packinglists/${packingListId}/change-date/`, { delivery_date: data }, config)
        .then((response) => {
          compose(dispatch, setPackingListAction)(response.data);
          message.success("Дата поставки успешно изменена");
        })
        .catch(errorCatcher);
    };
  };
})();

export const loadMoreDocuments = (params) => {
  const config = { params: { ...params, limit: DEFAULT_LIMIT_DOCUMENT } };

  return (dispatch) => {
    axios
      .get(`/purchaser/packinglists/`, config)
      .then((response) => dispatch(setMoreDocumentAction(response.data)))
      .catch(errorCatcher);
  };
};

export const addFileInPackingList = (packingListId, file) => (dispatch) => {
  const formData = new FormData();
  formData.append("files", file);

  message.info("Загрузка");

  axios
    .post(`/purchaser/packinglists/${packingListId}/files/`, formData)
    .then((response) => {
      compose(dispatch, addPackingListFileAction)(response.data[0]);
      message.success("Файл успешно прикреплен");
    })
    .catch(errorCatcher);
};

export const deleteFileFromPackingList = (packingListId, removedFileId) => (dispatch) => {
  axios
    .delete(`/purchaser/packinglists/${packingListId}/files/${removedFileId}/`)
    .then(() => {
      compose(dispatch, deletePackingListFileAction)(removedFileId);
      message.success("Файл успешно удален");
    })
    .catch(errorCatcher);
};
