import { combineReducers } from "redux";

import contacts from "./contacts/contactsSlice";
import contractors from "./contractors/slice";
import customers from "./customers/slice";
import invites from "./invites/invitesSlice";
import suppliers from "./suppliers/suppliersSlice";

export default combineReducers({
  suppliers,
  contacts,
  invites,
  contractors,
  customers,
});
