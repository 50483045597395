import cn from "classnames";
import React from "react";

import { percentLabel } from "../../../../../utils/formatters/percentLabel";

import styles from "./ProgressBar.module.scss";

export interface IProps {
  completed: number;
  className?: string;
  fillerClassName?: string;
  displayValue?: string;
}

const ProgressBar: React.FC<IProps> = ({ completed, className, fillerClassName, displayValue }) => {
  const percent = Number(completed);
  const percentToDisplay = displayValue || percentLabel(percent);

  return (
    <div className={styles.blockStyles}>
      <span className={cn(styles.labelStyles, className)}>{percentToDisplay}</span>
      <div className={styles.containerStyles}>
        <div className={cn(styles.fillerStyles, fillerClassName, className)} style={{ width: `${percent}%` }}></div>
      </div>
    </div>
  );
};

export default React.memo(ProgressBar);
