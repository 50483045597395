import React, { FC, useMemo } from "react";

import InstructionsSection from "../InstructionsSection/InstructionsSection";

import { IPermissionsTree } from "features/permissions/model/types";

import styles from "./InstructionsSectionsList.module.scss";

interface IProps {
  title: string;
  items: IPermissionsTree[];
  onClick: (perm: string) => void;
  directAlias?: string;
}

const InstructionsSectionsList: FC<IProps> = ({ title, items, onClick, directAlias }) => {
  return (
    <div className={styles.root}>
      <h3>{title}</h3>
      <div className={styles.grid}>
        {items.map((el) => (
          <InstructionsSection
            key={el.name}
            name={el.name || title}
            onClick={() => {
              onClick(directAlias ?? el.permissions?.[0]?.alias);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default InstructionsSectionsList;
