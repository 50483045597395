import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  MATERIALS_TYPES,
  deleteSection,
  getSpecificationsExpenditures,
  getSpecificationsSections,
  getSpecificationsSubSections,
  isLoadingSpecificationSelector,
  specificationExpendituresSelector,
  specificationSectionsSelector,
  specificationSubSectionsSelector,
} from "../../../../../redux/modules/common/building/materials/specification";

import Expenditure from "./components/Expenditure/Expenditure";
import Section from "./components/Section";
import SubSection from "./components/SubSection";

import { Spinner } from "../../../../../shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "../../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import BottomSliderMaterials from "../BottomSliderMaterials";
import Chapter from "../Chapter";
import ButtonBack from "shared/ui/controls/ButtonBack/ButtonBack";

import { ADDITIONAL, SPECIFICATION } from "../../constants";

import usePermission from "../../../../../hooks/usePermission";

import materialsIcon from "../../../../../images/icons/navigation/materials.svg";

import styles from "./Specification.module.scss";

const Specification = ({
  objectId,
  setTitleBtn,
  setIdSubsection,
  titleBtn,
  setAddPosition,
  canEdit = true,
  setActiveSectionId,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSpecificationSelector);
  const specificationSections = useSelector(specificationSectionsSelector);
  const specificationSubSections = useSelector(specificationSubSectionsSelector);
  const specificationSubExpenditures = useSelector(specificationExpendituresSelector);

  const [hasCategory, setHasCategory] = useState(true);
  const [isVisibleSection, setIsVisibleSection] = useState(true);
  const [isVisibleExpenditures, setIsVisibleExpenditures] = useState(false);
  const [isVisibleSubSection, setIsVisibleSubSection] = useState(false);
  const [positionEditModalOpen, setPositionEditModalOpen] = useState(false);
  const [wasAutoExpanded, setWasAutoExpanded] = useState(false);

  const visibleSection = isVisibleSection && specificationSections.results && specificationSections.results.length > 0;
  const visibleExpenditure = isVisibleExpenditures && specificationSubExpenditures?.products;

  useEffect(() => {
    dispatch(getSpecificationsSections(objectId, SPECIFICATION));
    setTitleBtn("Добавить раздел");
    setIsVisibleSection(true);
    setIsVisibleExpenditures(false);
  }, [objectId]);

  useEffect(() => {
    setWasAutoExpanded(false);
    setIsVisibleSubSection(false);
    setIsVisibleExpenditures(false);
  }, []);

  useEffect(() => {
    if (isVisibleSubSection || wasAutoExpanded || isLoading) return;
    if (specificationSections?.results?.length === 1) {
      setWasAutoExpanded(true);
      blockOpenSubSections(specificationSections.results[0]);
    }
  }, [isLoading, specificationSections.results, isVisibleSubSection, wasAutoExpanded]);

  const additionalProducts = useMemo(() => {
    if (!specificationSubExpenditures?.products || specificationSubExpenditures.products?.length === 0) return [];

    return specificationSubExpenditures.products.filter((item) => item.type === ADDITIONAL);
  }, [specificationSubExpenditures]);

  const outOfEstimateProducts = useMemo(() => {
    if (!specificationSubExpenditures?.products || specificationSubExpenditures.products?.length === 0) return [];

    return specificationSubExpenditures.products.filter((item) => item.type === MATERIALS_TYPES.OUT_OF_ESTIMATE);
  }, [specificationSubExpenditures]);

  const specificationProducts = useMemo(() => {
    if (!specificationSubExpenditures?.products || specificationSubExpenditures.products?.length === 0) return [];

    return specificationSubExpenditures.products.filter((item) => item.type === SPECIFICATION);
  }, [specificationSubExpenditures]);

  const loadSubSection = (subSectionId) => {
    dispatch(getSpecificationsSubSections(objectId, SPECIFICATION, subSectionId));
  };

  const loadExpenditures = (subSectionId) => {
    dispatch(getSpecificationsExpenditures(objectId, SPECIFICATION, subSectionId));
  };

  const blockOpenSubSections = (item) => {
    if (item.subsections_count === 0 && item.products_count !== 0) {
      loadExpenditures(item.id);
      setTitleBtn("Добавить позицию");
      setIsVisibleSection(false);
      setIsVisibleExpenditures(true);
      setIsVisibleSubSection(false);
      setHasCategory(false);
    }

    if (item.subsections_count !== 0) {
      loadSubSection(item.id);
      setIdSubsection(item.id);
      setTitleBtn("Добавить подраздел");
      setIsVisibleSection(false);
      setIsVisibleSubSection(true);
      setIsVisibleExpenditures(false);
    }

    if (item.subsections_count === 0 && item.products_count === 0) {
      loadSubSection(item.id);
      setTitleBtn("Добавить подраздел");
      setIsVisibleSection(false);
      setIsVisibleSubSection(true);
      setIsVisibleExpenditures(false);
    }
  };

  const blockOpenExpenditure = (item) => {
    setActiveSectionId && setActiveSectionId(item.id);
    loadExpenditures(item.id);
    setTitleBtn("Добавить позицию");
    setIsVisibleSubSection(false);
    setIsVisibleExpenditures(true);
  };

  const closePositionEditModal = () => {
    dispatch(deleteSection(objectId));
    setPositionEditModalOpen(false);
  };

  const backToSections = () => {
    setTitleBtn("Добавить раздел");
    setIsVisibleSubSection(false);
    setIsVisibleSection(true);
    setIdSubsection(null);
  };

  const changePositionState = () => {
    setTitleBtn("Добавить подраздел");
    setAddPosition(false);

    if (hasCategory === false) {
      setIsVisibleExpenditures(false);
      setIsVisibleSection(true);
      setHasCategory(true);
    } else {
      setIsVisibleExpenditures(false);
      setIsVisibleSection(false);
      setIsVisibleSubSection(true);
    }
  };

  return (
    <>
      <BottomSliderMaterials isOpen={positionEditModalOpen} closeFunc={closePositionEditModal}>
        <Chapter objId={objectId} close={closePositionEditModal} />
      </BottomSliderMaterials>
      <div
        className={cn(styles.container, {
          [styles.active]: isVisibleExpenditures,
        })}
      >
        {isLoading ? (
          <Spinner isFixed />
        ) : (
          <>
            {specificationSections.results && specificationSections.results.length === 0 ? (
              <EmptyPlaceholder img={materialsIcon} />
            ) : (
              <>
                {visibleSection &&
                  specificationSections.results.map(
                    (item) =>
                      item.subsections_count > 0 && (
                        <Section
                          key={item.id}
                          titleBtn={titleBtn}
                          objectId={objectId}
                          data={item}
                          blockOpenSubSections={blockOpenSubSections}
                          canEdit={canEdit}
                          count={item.products_count}
                        />
                      )
                  )}
              </>
            )}
            {isVisibleSubSection && !!specificationSections?.results?.length && (
              <>
                <div className={styles.containerBtn}>
                  <ButtonBack className={styles.button} onClick={backToSections} />
                  <span>{specificationSubSections?.name}</span>
                </div>
                {specificationSubSections?.subsections_count === 0 ? (
                  <EmptyPlaceholder img={materialsIcon} />
                ) : (
                  <>
                    {specificationSubSections?.subsections?.map((subSection) => (
                      <div key={subSection.id} onClick={() => setAddPosition(true)}>
                        <SubSection
                          key={subSection.id}
                          objectId={objectId}
                          data={subSection}
                          setPositionEditModalOpen={setPositionEditModalOpen}
                          blockOpenExpenditure={blockOpenExpenditure}
                          canEdit={canEdit}
                          count={subSection.products_count}
                        />
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
            {visibleExpenditure && (
              <>
                <div className={styles.containerBtnActive}>
                  <ButtonBack className={styles.button} onClick={() => changePositionState()} />
                  <span>{specificationSubExpenditures?.name}</span>
                </div>
                {specificationSubExpenditures.products.length === 0 ? (
                  <EmptyPlaceholder img={materialsIcon} />
                ) : (
                  <div className={styles.content}>
                    {specificationProducts?.length > 0 && (
                      <div key="specificationProducts">
                        <Expenditure
                          objectId={objectId}
                          data={specificationProducts}
                          typeUrl={SPECIFICATION}
                          canEdit={canEdit}
                          isRetOwnMatCheckboxVisible
                        />
                      </div>
                    )}
                    {additionalProducts?.length > 0 && (
                      <div key="additionalProducts" className={styles.additional}>
                        <p>Допы:</p>
                        <Expenditure
                          objectId={objectId}
                          data={additionalProducts}
                          typeUrl={ADDITIONAL}
                          canEdit={canEdit}
                          isRetOwnMatCheckboxVisible={false}
                        />
                      </div>
                    )}
                    {outOfEstimateProducts?.length > 0 && (
                      <div key="outOfEstimateProducts" className={styles.additional}>
                        <p>Вне сметы:</p>
                        <Expenditure
                          objectId={objectId}
                          data={outOfEstimateProducts}
                          typeUrl={SPECIFICATION}
                          canEdit={canEdit}
                          isRetOwnMatCheckboxVisible={false}
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default Specification;
