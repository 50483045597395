export const prepareTableData = (data) => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    itn: item.itn,
    iec: item.iec,
    managerName: item.contact_full_name,
    managerNumber: item.contact_phone,
    managerEmail: item.contact_email,
    managerFullName: item.contact_manager
      ? item.contact_manager.last_name + " " + item.contact_manager.first_name + " " + item.contact_manager.middle_name
      : "",
  }));
};
