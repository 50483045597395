import cn from "classnames";
import moment from "moment";
import React, { useCallback, useState } from "react";

import { MonthArray, YearsArray } from "shared/const/dates";
import Dropdown from "shared/ui/atoms/Dropdown/Dropdown";
import Placeholder from "shared/ui/atoms/Placeholder/Placeholder";

import styles from "./MonthSelect.module.scss";

interface IProps {
  date: moment.Moment;
  onChange: (date: moment.Moment) => void;
}

const years = YearsArray(1);

const formatNameMonth = (date: string) => {
  return date.charAt(0).toUpperCase() + date.slice(1);
};

const MonthSelect: React.FC<IProps> = ({ date = moment(), onChange }) => {
  const [isDirty, setIsDirty] = useState(false);

  const year = date.year();
  const monthIndex = date.month();

  const changeDate = (year: number, monthIndex: number) => {
    setIsDirty(true);
    onChange(moment(new Date(year, monthIndex, 1)));
  };

  return (
    <Dropdown
      placeholder={isDirty ? formatNameMonth(date?.format("MMMM YYYY")) : <Placeholder>Выберите</Placeholder>}
      icon={
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 2.5C6 2.22386 6.22386 2 6.5 2H7.5C7.77614 2 8 2.22386 8 2.5V4.00001H16V2.5C16 2.22386 16.2239 2 16.5 2H17.5C17.7761 2 18 2.22386 18 2.5V4.00001H18.5C20.433 4.00001 22 5.56701 22 7.50001V18.5C22 20.433 20.433 22 18.5 22H5.5C3.567 22 2 20.433 2 18.5V7.50001C2 5.56701 3.567 4.00001 5.5 4.00001H6V2.5ZM4 18.5V9H20V18.5C20 19.3284 19.3284 20 18.5 20H5.5C4.67157 20 4 19.3284 4 18.5ZM13.5 14C13.2239 14 13 14.2239 13 14.5V17.5C13 17.7761 13.2239 18 13.5 18H17.5C17.7761 18 18 17.7761 18 17.5V14.5C18 14.2239 17.7761 14 17.5 14H13.5Z"
            fill="#4FB1EB"
          />
        </svg>
      }
      classNameContent={styles.contentWrapper}
    >
      <div className={styles.months}>
        {MonthArray.map((el) => (
          <div
            key={el.id}
            className={cn(styles.month, { [styles.active]: el.id === monthIndex && isDirty })}
            onClick={() => changeDate(year, el.id)}
          >
            {el.label}
          </div>
        ))}
      </div>
      <div className={styles.years}>
        {years.map((el) => (
          <div
            key={el.id}
            className={cn(styles.month, { [styles.active]: el.id === year && isDirty })}
            onClick={() => changeDate(el.id, monthIndex)}
          >
            {el.label}
          </div>
        ))}
      </div>
    </Dropdown>
  );
};

export default MonthSelect;
