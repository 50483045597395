import React, { MouseEventHandler, useContext, useEffect, useRef, useState } from "react";

import { IDraggedArrow, RELATION_TYPES } from "redux/modules/common/chart/types";

import Icon from "../../../../../../../_LEGACY/UI/_LEGACY_Icon/Icon";
import { ChartContext } from "components/pages/Chart/Chart";
import { CHART_Z_INDEX } from "components/pages/Chart/components/ChartInterval/const";
import { CHART_ARROW_CONFIG } from "components/pages/Chart/constants";

import { IDiagramIntervalLink, createIntervalOriginType } from "../useIntervalLinks";
import { createPortal } from "react-dom";
import Xarrow from "react-xarrows";

import diagramAddLinkCircle from "../../../../../../../images/diagramAddLinkCircle.svg";

import styles from "./ConnectPointsWrapper.module.scss";

export interface IConnectPointsWrapperProps {
  draggedArrow: IDraggedArrow | null;
  intervalId: number;
  arrowId: string;
  isAddingArrowAllowed?: boolean;
  hover: boolean;
  position: {
    x?: number;
    y?: number;
  };
  handleDragStart: (e: MouseEvent, origin: createIntervalOriginType, relationType: RELATION_TYPES) => void;
  handleIntervalClick: MouseEventHandler;
  handleDragging: MouseEventHandler;
  projectId: number;
  projectArrows: IDiagramIntervalLink[];
  isLinkingEnabled: boolean;
  onHoverIntervalWithDraggedLink: MouseEventHandler;
}

const ConnectPointsWrapper: React.FC<IConnectPointsWrapperProps> = ({
  intervalId,
  arrowId,
  isAddingArrowAllowed,
  hover,
  position,
  handleDragStart,
  handleIntervalClick,
  handleDragging,
  draggedArrow,
  isLinkingEnabled,
  onHoverIntervalWithDraggedLink,
}) => {
  const { containerRef } = useContext(ChartContext);
  const [draggedArrowContainer, setDraggedArrowContainer] = useState(containerRef?.current);
  useEffect(() => {
    setDraggedArrowContainer(containerRef?.current);
  }, [containerRef?.current]);
  const refStartPoint = useRef<HTMLDivElement>(null);
  const refEndPoint = useRef<HTMLDivElement>(null);

  const checkIsAddPointShown = (origin: "start" | "end") => {
    if (!isLinkingEnabled || !isAddingArrowAllowed) return false;
    const isCurrentOriginDragged =
      draggedArrow && origin === draggedArrow.origin && draggedArrow.intervalId === intervalId;
    return isCurrentOriginDragged || (hover && !draggedArrow);
  };

  const isStartPointShown = checkIsAddPointShown("start");
  const isEndPointShown = checkIsAddPointShown("end");

  return (
    <>
      <div
        className={styles.arrowAcceptElement}
        onMouseUp={handleIntervalClick}
        onDragEnd={handleIntervalClick}
        onMouseMove={onHoverIntervalWithDraggedLink}
      />
      {isEndPointShown && (
        // tail
        <div
          style={{
            left: draggedArrow ? 0 : "100%",
            top: draggedArrow ? 0 : "50%",
            transform: draggedArrow ? `translate(${position.x}px, ${position.y}px)` : "translate(-50%, -50%)",
            pointerEvents: draggedArrow ? "none" : "auto",
          }}
          draggable
          /* @ts-ignore */
          onDragStart={(e) => handleDragStart(e, "end", RELATION_TYPES.oh)}
          /* @ts-ignore */
          onDrag={handleDragging}
          /* @ts-ignore */
          onDragEnd={handleIntervalClick}
          /* @ts-ignore */
          onClick={(e) => handleDragStart(e, "end", RELATION_TYPES.oh)}
          ref={refEndPoint}
          className={styles.connectPoint}
        >
          <Icon icon={diagramAddLinkCircle} />
        </div>
      )}
      {draggedArrow && draggedArrow.intervalId === intervalId && draggedArrowContainer
        ? createPortal(
            <>
              <Xarrow
                {...CHART_ARROW_CONFIG}
                start={arrowId}
                end={isEndPointShown ? refEndPoint : refStartPoint}
                startAnchor={draggedArrow.origin === "start" ? "right" : "left"}
                zIndex={CHART_Z_INDEX.DRAGGED_ARROW}
                relationType={draggedArrow.relationType}
              />
            </>,
            draggedArrowContainer
          )
        : null}
      {isStartPointShown && (
        // head
        <div
          style={{
            left: 0,
            top: draggedArrow ? 0 : "50%",
            transform: draggedArrow ? `translate(${position.x}px, ${position.y}px)` : "translate(-50%, -50%)",
            pointerEvents: draggedArrow ? "none" : "auto",
            // zIndex: CHART_Z_INDEX.DRAGGED_ARROW,
          }}
          draggable /* @ts-ignore */
          onDragStart={(e) => handleDragStart(e, "start", RELATION_TYPES.hh)}
          /* @ts-ignore */
          onDrag={handleDragging}
          /* @ts-ignore */
          onDragEnd={handleIntervalClick}
          /* @ts-ignore */
          onClick={(e) => handleDragStart(e, "start", RELATION_TYPES.hh)}
          ref={refStartPoint}
          className={styles.connectPoint}
        >
          <Icon icon={diagramAddLinkCircle} />
        </div>
      )}
    </>
  );
};

// <div
//   style={{
//     left: 0,
//     top: 0,
//     transform: `translate(${position.x}px, ${position.y}px)`,
//     pointerEvents: "none",
//     zIndex: CHART_Z_INDEX.DRAGGED_ARROW,
//   }}
//   draggable
//   /* @ts-ignore */
//   onDrag={withTmpRelationType(handleDragging, RELATION_TYPES.oh)}
//   /* @ts-ignore */
//   onDragEnd={withTmpRelationType(handleIntervalClick, undefined)}
//   ref={refPoint}
//   className={styles.connectPoint}
// >
//   <Icon icon={diagramAddLinkCircle} />
// </div>;

export default React.memo(ConnectPointsWrapper);
