import moment from "moment";

class WeeksInYear {
  constructor(year) {
    const weeksBlank = Array.from({ length: moment().year(year).weeksInYear() });

    this.weeks = weeksBlank.map((_, i) => ({
      number: i + 1,
      range: WeeksInYear.getRangeByWeekNumber(year, i + 1, false)
    }));
  }

  static getRangeByWeekNumber(year, weekNumber, fromCurrentWeekday = true) {
    const weekByWeekNumber = moment().year(year).week(weekNumber);
    return [
      fromCurrentWeekday ? moment(weekByWeekNumber) : moment(weekByWeekNumber).startOf("week"),
      moment(weekByWeekNumber).endOf("week")
    ];
  }

  getWeeksGroupByMiddle = (middleWeekNumber, weeksCount) => {
    const middleWeekIndex = this.weeks.findIndex((week) => week.number === middleWeekNumber);
    if (middleWeekIndex + weeksCount > this.weeks.length) return this.getWeeksGroupByEnd(middleWeekNumber, weeksCount);
    if (middleWeekIndex < Math.floor(weeksCount / 2)) return this.getWeeksGroupByStart(middleWeekIndex, weeksCount);

    const startWeekIndex = middleWeekIndex - Math.floor(weeksCount / 2);
    return this.getWeeksGroupByStart(startWeekIndex, weeksCount);
  };

  getWeeksGroupByEnd = (endWeekNumber, weeksCount) => {
    const endWeekIndex = this.weeks.findIndex((week) => week.number === endWeekNumber);
    const startWeekIndex = endWeekIndex - weeksCount + 1;
    return this.getWeeksGroupByStart(startWeekIndex, weeksCount);
  };

  getWeeksGroupByStart = (startWeekIndex, weeksCount) => {
    return this.weeks.slice(startWeekIndex, startWeekIndex + weeksCount);
  };
}

export default WeeksInYear;