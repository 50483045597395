import moment from "moment";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import ControlsBar from "./components/ControlsBar/ControlsBar";
import CreateTasksList from "./components/CreateTasksList/CreateTasksList";
import TasksIndicators from "./components/TasksIndicators/TasksIndicators";
import TasksListHeader from "./components/TasksListHeader/TasksListHeader";
import TasksModal from "./components/TasksModal/TasksModal";
import { tasksRouteParamsType } from "components/pages/Tasks/model/types";

import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import ShowMoreButton from "shared/ui/controls/ShowMoreButton";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { TASK_LISTS_TYPES } from "./constants";

import { useTasksFilter } from "./hooks/useTasksFilter";
import { useTasksLists } from "./hooks/useTasksListsData";

import taskIcon from "images/icons/navigation/tasksIcon.svg";

function TasksLists() {
  const { objectId } = useParams<tasksRouteParamsType>();
  const { filters, filtersHandler, dateRangeHandler } = useTasksFilter();
  const { tasksLists, isTasksLoading, loadMoreTasksListsHandler } = useTasksLists(objectId!);

  const [isCreatingNewList, setIsCreatingNewList] = useState(false);
  const [isIndicatorsOpen, setIsIndicatorsOpen] = useState(true);

  const creatingNewListSwitcher = useCallback(() => {
    setIsCreatingNewList((prev) => !prev);
  }, []);

  return (
    <TemplateBase>
      <TasksIndicators isOpen={isIndicatorsOpen} setIsOpen={setIsIndicatorsOpen} type={TASK_LISTS_TYPES.LISTS} />
      <ControlsBar
        onDateChange={dateRangeHandler}
        filters={filters}
        onChangeFilters={filtersHandler}
        variant="lists"
        onAddNew={creatingNewListSwitcher}
      />
      {isCreatingNewList && <CreateTasksList onClose={creatingNewListSwitcher} />}
      {isTasksLoading && <Spinner />}
      {!isTasksLoading && !tasksLists.results.length && (
        <EmptyPlaceholder text="Нет данных в разделе списков задач." img={taskIcon}>
          <ButtonBase onClick={creatingNewListSwitcher} primary>
            Новый список
          </ButtonBase>
        </EmptyPlaceholder>
      )}
      {!isTasksLoading &&
        !!tasksLists.results.length &&
        tasksLists.results.map((el) => (
          <TasksListHeader
            key={el.id}
            name={el.list_name}
            id={el.id}
            user={el.user}
            count={el.tasks_count}
            deadline_end={filters.deadline_end || moment().startOf("month").add(1, "month").format("YYYY-MM-DD")}
            deadline_start={filters.deadline_start || moment().startOf("month").format("YYYY-MM-DD")}
            building_id={objectId}
          />
        ))}
      <ShowMoreButton
        handleAdd={loadMoreTasksListsHandler}
        showedCount={tasksLists.results.length}
        allCount={tasksLists.count}
        isExists={!isTasksLoading}
      />
      <TasksModal presetBuildingId={objectId ? +objectId : 0} />
    </TemplateBase>
  );
}

export default TasksLists;
