import React from "react";

import { IPlanRelation } from "redux/modules/common/chart/types";

import { useRelationEditing } from "components/pages/Chart/components/ChartInterval/withLinking/useRelationEditing";

import TableReusableRow from "../../../../../../../../../shared/ui/dataDisplay/TableReusable/TableReusableRow";
import InputBase from "shared/ui/inputs/InputBase";

import { RELATION_TYPES, RelationType } from "../../../../../../constants";
import { IDisplayedRelation, RelationDirectionType, RelationsDirection } from "../../types";

import { ReactComponent as DeleteIcon } from "images/icons/delete.svg";

import styles from "./DisplayedRelation.module.scss";

export interface IDisplayedRelationProps {
  relation: IDisplayedRelation;
  isEditMode?: boolean;
  projectId: number;
  type: RelationType;
  direction: RelationDirectionType;
}

const DisplayedRelation: React.FC<IDisplayedRelationProps> = ({ relation, isEditMode, projectId, type, direction }) => {
  const {
    deleteArrowConfirmModal,
    openDeleteArrowModal,
    handleDelayDayInputKeyDown,
    handleDelayDayInputChange,
    delayDaySubmitHandler,
    dynamicRelationDelayDay,
  } = useRelationEditing({ relation: relation as IPlanRelation, projectId });

  const displayedExpenditureName =
    direction === RelationsDirection.to
      ? relation.from_expenditure_name ?? relation.from_group_name
      : relation.to_expenditure_name ?? relation.to_group_name;

  const displayedSectionName =
    direction === RelationsDirection.to ? relation.from_section_name : relation.to_section_name;

  return (
    <TableReusableRow className={styles.displayedRelation} containerClassName={styles.tableRow}>
      <span className={styles.displayedExpenditureName}>{displayedExpenditureName}</span>
      <div className={styles.displayedRelationBreak}>
        {isEditMode && type === RELATION_TYPES.oh ? (
          <InputBase /* @ts-ignore */
            value={dynamicRelationDelayDay}
            onChange={handleDelayDayInputChange}
            className={styles.breakInputContainer}
            classNameInput={styles.breakInput}
            onBlur={delayDaySubmitHandler} /* @ts-ignore */
            onKeyDown={handleDelayDayInputKeyDown}
          />
        ) : (
          <span className={styles.breakValue}>{relation.delay_day}</span>
        )}
      </div>
      <div className={styles.displayedRelationSection}>
        <span className={styles.relationSectionLabel}>Раздел:</span>
        <span>{displayedSectionName}</span>
      </div>
      {isEditMode && <DeleteIcon onClick={openDeleteArrowModal} className={styles.deleteIcon} />}
      {deleteArrowConfirmModal}
    </TableReusableRow>
  );
};

export default React.memo(DisplayedRelation);
