import { applyMiddleware, compose, createStore } from "redux";

import { createChartInitialState } from "redux/modules/common/chart/reducer";

import { blueprintsQuery } from "pages/Estimate/Blueprints/model/blueprintsQuery";

import { RootState, rootReducer } from "./rootReducer";
// @ts-ignore
import persistState from "redux-localstorage";
import thunk from "redux-thunk";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export type InferPropertiesTypes<T> = T extends { [key: string]: infer U } ? U : never;

export type IActions<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<InferPropertiesTypes<T>>;

const middlewares = [thunk, blueprintsQuery.middleware];
const composeEnhancers =
  process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares),
  persistState(["auth", "breadcrumbs", "chart"], {
    serialize: (subset: Partial<RootState>) => {
      try {
        return JSON.stringify({
          ...subset,
          chart: {
            treeState: subset.chart?.treeState,
            scrollState: subset.chart?.scrollState,
            viewMode: subset.chart?.viewMode,
            actions: subset.chart?.actions,
          },
        });
      } catch (e) {
        return "";
      }
    },
    merge: (initialState: RootState, persistedState: RootState) => {
      try {
        return {
          ...initialState,
          chart: {
            ...createChartInitialState(),
            ...persistedState.chart,
          },
        };
      } catch (e) {
        return initialState;
      }
    },
  })
);
// @ts-ignore
const store = createStore(rootReducer, {}, enhancer);

export default store;
