import Validator from "./Validator";

class TermsValidator extends Validator {
  constructor(paymentTerms, deliveryTerms, onError) {
    super(onError);
    this.paymentTerms = paymentTerms;
    this.deliveryTerms = deliveryTerms;
  }

  validate() {
    this.validatePaymentTerms();
    this.validateDeliveryTerms();

    if (this.errors.size !== 0 && this.onError) this.onError(this.errors);
  }

  validatePaymentTerms() {
    const totalPercent = this.paymentTerms.reduce((acc, term) => acc + parseFloat(term.percent), 0);

    if (this.paymentTerms.some((term) => !term.payment_type) || totalPercent < 100)
      this.errors.add("Укажите условия оплаты");

    if (this.paymentTerms.some((term) => term.payment_type === "delay" && !term.delay_days)) {
      this.errors.add("Укажите количество дней в условиях оплаты");
    }
  }

  validateDeliveryTerms() {
    if (this.deliveryTerms.filter((term) => term.confirmed).length === 0) this.errors.add("Укажите условия доставки");

    if (
      this.deliveryTerms.some((term) => term.term_type === "paid" && term.confirmed && (!term.cost || +term.cost === 0))
    ) {
      this.errors.add("Укажите корректную цену платной доставки");
    }
  }
}

export default TermsValidator;
