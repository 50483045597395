import React, { useCallback } from "react";
import cn from "classnames";

import { CHAPTERS } from "../../constants";
import { ChaptersEnum } from "../../enums";

import styles from "./Chapter.module.scss";

interface IChapterProps {
  chapter: typeof CHAPTERS[number];
  isActive?: boolean;
  onClick?: (chapterId: ChaptersEnum) => void;
}

const Chapter = ({ chapter, isActive, onClick }: IChapterProps) => {
  const handleOnClick = useCallback(() => {
    if (onClick) onClick(chapter.id);
  }, [chapter.id, onClick]);

  return (
    <div className={cn(styles.chapter, { [styles.active]: isActive })} onClick={handleOnClick}>
      {`${chapter.id}. ${chapter.name}`}
    </div>
  );
};

export default React.memo(Chapter);
export type { IChapterProps };
