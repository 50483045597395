import cn from "classnames";
import React, { useContext } from "react";

import { IChartFactResourceCompletion } from "redux/modules/common/chart/types";

import MaterialStatusBlock from "../../../Manufacturing/components/Month/components/InfoPopup/components//MaterialStatusBlock/MaterialStatusBlock";
import StatusBlock from "../../../Manufacturing/components/Month/components/InfoPopup/components/StatusBlock/StatusBlock";
import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import { ChartContext } from "../../Chart";

import {
  INTERVAL_NAME_MAP,
  INTERVAL_TYPES,
  REMARKS_COLOR,
  WORK_STATUSES,
  getWorkStatus,
} from "../../../Manufacturing/constants";

import { AlertIcon } from "../../../../../images/icons/AlertIcon";

import styles from "./ChartIntervalPopup.module.scss";

const getHighestMaterialStatus = (
  materialCompletion: IChartFactResourceCompletion
): keyof IChartFactResourceCompletion => {
  if (+materialCompletion.to_pay > 0) return "to_pay";
  if (+materialCompletion.accepted > 0) return "accepted";
  if (+materialCompletion.issued > 0) return "issued";
  if (+materialCompletion.on_stock > 0) return "on_stock";
  if (+materialCompletion.ordered > 0) return "ordered";
  if (+materialCompletion.todo > 0) return "todo";
  return "todo";
};

export interface IChartIntervalPopupProps {
  data: any;
  openModal: () => void;
  type: INTERVAL_TYPES;
  /** Отображаемая дата начала-окончания отрезка */
  intervalDates: string;
  /** Количество замечаний */
  remarksCount?: number;
  /** Необходим ли сдвиг плана */
  hasShift?: boolean;
  /** Открывает модальное окно сдвига плана */
  openShiftModal?: () => void;
  children?: React.ReactNode;
}

const ChartIntervalPopup = React.forwardRef(
  (
    {
      data,
      type,
      intervalDates,
      openModal,
      children,
      remarksCount,
      openShiftModal,
      hasShift = false,
    }: IChartIntervalPopupProps,
    ref
  ) => {
    const { contentRef } = useContext(ChartContext);
    const workStatus = type === INTERVAL_TYPES.work && data ? getWorkStatus(data) : WORK_STATUSES.default;

    const isMaterialOrMim = [
      INTERVAL_TYPES.material,
      INTERVAL_TYPES.equipment,
      INTERVAL_TYPES.machine,
      INTERVAL_TYPES.transport,
    ].includes(type);

    const materialStatus = isMaterialOrMim ? getHighestMaterialStatus(data) : "";

    const measure = data?.measure || data?.expenditure?.measure || data?.group?.measure || "";

    const isWorkIndicatorsShown = type === INTERVAL_TYPES.work && workStatus !== WORK_STATUSES.default;

    const isMaterialStatusesShown = isMaterialOrMim && materialStatus;

    const workIndicators = isWorkIndicatorsShown && {
      completed: Number(data.completed),
      accepted: Number(data.accepted),
      confirmed: Number(data.confirmed),
      todo: Number(data.todo),
      to_pay: Number(data.to_pay),
    };

    const materialIndicators = isMaterialStatusesShown && {
      plans: Number(data.count),
      ordered: Number(data.ordered),
      on_stock: Number(data.on_stock),
      issued: Number(data.issued),
      accepted: Number(data.accepted),
      todo: Number(data.todo),
      to_pay: Number(data.to_pay),
    };

    return (
      <PopoverOverlay
        // @ts-ignore
        ref={ref}
        openType="hover"
        className={styles.popoverContainer}
        placement="bottom"
        // @ts-ignore
        popoverBorderColor={isMaterialOrMim ? `${materialStatus}Material` : workStatus}
        usePortal
        portalContainer={(contentRef?.current as HTMLElement) || undefined}
        content={
          <div className={styles.container}>
            <div>
              <div className={styles.header}>
                <span>{INTERVAL_NAME_MAP[type]}</span>
                <span className={styles.intervalDates}>{intervalDates}</span>
              </div>
              {workIndicators && (
                <div className={cn(styles.item, styles.statuses)}>
                  {Object.entries(workIndicators).map(
                    ([key, value]) =>
                      // @ts-ignore
                      value > 0 && <StatusBlock measure={measure} count={value} status={key} key={`${key}_${value}`} />
                  )}
                </div>
              )}
              {materialIndicators && (
                <div className={cn(styles.item, styles.statuses)}>
                  {Object.entries(materialIndicators).map(
                    ([key, value]) =>
                      value > 0 && (
                        // @ts-ignore
                        <MaterialStatusBlock measure={measure} count={value} status={key} key={`${key}_${value}`} />
                      )
                  )}
                </div>
              )}

              {!!remarksCount && (
                <div className={cn(styles.item, styles.remarks)}>
                  <span>
                    <AlertIcon fill={REMARKS_COLOR} width={16} /> Замечания:
                  </span>
                  <span className={styles.textPrimary}>{remarksCount}</span>
                </div>
              )}

              {hasShift && (
                <div className={styles.shift} onClick={openShiftModal}>
                  Требуется
                  <br />
                  корректировка плана
                </div>
              )}

              <div className={styles.buttons}>
                <div onClick={openModal} className={styles.cardButton}>
                  Посмотреть
                </div>
              </div>
            </div>
          </div>
        }
      >
        {children}
      </PopoverOverlay>
    );
  }
);

export default React.memo(ChartIntervalPopup);
