import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import {
  useAttachFileToDrawingMutation,
  useDeleteDrawingMutation,
  useDeleteFileFromDrawingMutation,
  useEditDrawingMutation,
  useGetDrawingByIdQuery,
} from "../../model/blueprintsQuery";
import BlueprintForm from "./BlueprintForm";
import BlueprintInfo from "./BlueprintInfo";
import ConfirmModal from "entities/ConfirmationModal/ConfirmModal";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import ExpandableFileInput from "shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";

import { ICreateDrawingReq, IDrawing, IDrawingSet } from "../../model/types";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";

import styles from "./BlueprintsModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  canEdit: boolean;
  drawing: IDrawing;
  isDefaultEditing: boolean;
  parent?: IDrawingSet;
  canDelete: boolean;
}

const BlueprintsModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  drawing,
  isDefaultEditing,
  parent,
  canEdit,
  canDelete,
}) => {
  const [isEditing, setIsEditing] = useState(isDefaultEditing);
  useEffect(() => {
    setIsEditing(isDefaultEditing);
  }, [isDefaultEditing]);

  const buildingId = useObjectId();

  const { data, isLoading } = useGetDrawingByIdQuery({ buildingId, drawingSetId: drawing.id });
  const [deleteFile, {}] = useDeleteFileFromDrawingMutation();
  const [attachFile, { isLoading: isFileLoading }] = useAttachFileToDrawingMutation();

  const onDeleteFile = (fileId: number) => {
    deleteFile({ buildingId, fileId, drawingSetId: drawing.id });
  };

  const onUploadFile = (file: any) => {
    getArrayFilesInBase64([file]).then(([fileB64]) => {
      attachFile({
        buildingId,
        drawingSetId: drawing.id,
        file: { originalname: fileB64.name, file: fileB64.file as string } as any,
      });
    });
  };

  const fileInput = useMemo(() => {
    return (
      <ExpandableFileInput
        files={data?.files ?? []}
        onUploadFileDirectly={onUploadFile}
        onRemoveFileDirectly={onDeleteFile}
        isLoading={isFileLoading}
        canDeleteFiles={isEditing}
        isFileViewer
      />
    );
  }, [data, onUploadFile, isFileLoading, onDeleteFile, isEditing]);

  const [editDrawing, { isLoading: isEditingSetLoading, isSuccess }] = useEditDrawingMutation();

  const onEdit = (
    vals: Omit<ICreateDrawingReq, "buildingId" | "drawing_set" | "sheet_numbers"> & { sheet_numbers: string }
  ) => {
    const sheet_numbers = typeof vals.sheet_numbers === "string" ? vals.sheet_numbers?.split(",") : vals.sheet_numbers;
    editDrawing({ buildingId, ...vals, sheet_numbers: sheet_numbers as any, drawingSetId: drawing.id });
  };

  const [deleteSet, { isLoading: isDeleteSetLoading }] = useDeleteDrawingMutation();
  const [isDeletingModal, setIsDeletingModal] = useState(false);
  const onDeleteSet = () => {
    setIsDeletingModal(false);
    deleteSet({ buildingId, drawingSetId: drawing.id });
  };

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
      onClose();
    }
  }, [isSuccess]);

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      name={isEditing ? "Редактировать чертеж" : drawing.title}
      customHeadline={
        !isEditing && !!data?.updated_at ? (
          <div className={styles.updatedAt}>Изменено:&nbsp;{moment(data?.updated_at).format("DD.MM.YYYY HH:mm")}</div>
        ) : undefined
      }
    >
      {isLoading && <Spinner />}
      {!isEditing && !isLoading && (
        <BlueprintInfo
          detailData={data!}
          bottomControls={
            <BottomControls isDoubleBtns isExists={!isEditing && !isLoading}>
              {canDelete && (
                <ButtonBase secondary onClick={() => setIsDeletingModal(true)} isLoading={isDeleteSetLoading}>
                  Удалить чертеж
                </ButtonBase>
              )}
              {canEdit && (
                <ButtonBase primary onClick={() => setIsEditing(true)}>
                  Редактировать
                </ButtonBase>
              )}
            </BottomControls>
          }
          fileInput={fileInput}
          parent={parent}
        />
      )}
      {isEditing && !isLoading && (
        <BlueprintForm
          onSubmit={onEdit}
          bottomControls={
            <BottomControls isDoubleBtns isExists>
              <ButtonBase secondary onClick={() => setIsEditing(false)}>
                Отменить
              </ButtonBase>
              <ButtonBase primary type="submit" isLoading={isEditingSetLoading}>
                Сохранить
              </ButtonBase>
            </BottomControls>
          }
          fileInput={fileInput}
          initialValue={data}
        />
      )}
      <ConfirmModal
        isOpen={isDeletingModal}
        onClose={() => setIsDeletingModal(false)}
        variant="secondary"
        action={onDeleteSet}
        acceptButtonText="Удалить"
        title="Подтвердите действие"
      >
        Вы действительно хотите удалить чертеж?
      </ConfirmModal>
    </ModalContainer>
  );
};

export default BlueprintsModal;
