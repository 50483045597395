import cn from "classnames";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { unbindSigner } from "../../model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";
import ConfirmModal from "entities/ConfirmationModal/ConfirmModal";
import EmployeeElem from "shared/ui/dataDisplay/EmployeeElem/EmployeeElem";

import { SIGNERS_TYPES_LABELS } from "../../constants";
import { SignerTypes } from "../../model/types";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./SignatorElem.module.scss";

interface IProps {
  onClick: () => void;
  type: SignerTypes;
  name: string;
  isEmpty: boolean;
  id?: number;
  objectId: string;
}

const SignatorElem: React.FC<IProps> = ({ onClick, type, name, isEmpty, id, objectId }) => {
  const deletingPendingKey = generateStorageKey({ type: "unbindSigner", id });
  const { isLoading, wasDeletedSigners } = useTypedSelector((state) => state.signatorsSlice);
  const deletePending = !!id && (isLoading[deletingPendingKey] || wasDeletedSigners.includes(id));

  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <>
      <EmployeeElem
        className={cn({ [styles.elem]: true, [styles.empty]: isEmpty })}
        employeName={name}
        content={SIGNERS_TYPES_LABELS[type]}
        onClick={() => onClick?.()}
        canAdd={isEmpty}
        onAdd={() => onClick?.()}
        canDelete={!isEmpty && !deletePending}
        onDelete={() => setShowConfirm(true)}
        isLoading={deletePending}
        classNameContent={styles.info}
      />
      <ConfirmModal
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        variant="secondary"
        action={() => {
          dispatch(unbindSigner(objectId, id!));
          setShowConfirm(false);
        }}
        acceptButtonText="Удалить"
      >
        Удалить подписанта?
      </ConfirmModal>
    </>
  );
};

export default SignatorElem;
