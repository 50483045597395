import React, { useMemo, useState } from "react";

import InputFiles from "../../../../UI/atoms/_TODO/InputFiles/InputFiles";

import FileViewer from "widgets/FileViewer/FileViewer";

import { TASK_MODAL_TYPES } from "../../constants";
import { ItaskFile } from "../../model/types";

import { useTaskFiles } from "../../hooks/useTaskFiles";

import downloadFileByUrl from "../../../../../utils/helpers/downloadFileByUrl";
import { stopEventPropagation } from "../../../../../utils/helpers/stopEventPropagation";

import styles from "./TaskFiles.module.scss";

export interface ITaskFilesProps {
  files: ItaskFile[];
  taskId?: number;
  variant: TASK_MODAL_TYPES;
}

const TaskFiles: React.FC<ITaskFilesProps> = ({ files, taskId, variant }) => {
  const {
    files: filesAddBuffer,
    uploadFilesHandler,
    deleteFileHandler,
    isPending,
  } = useTaskFiles({
    taskId,
    variant,
  });

  const preparedFiles = useMemo(() => files && files.map((x) => ({ ...x, name: x.file_name })), [files]);

  const filesToShow = variant === TASK_MODAL_TYPES.ADD ? filesAddBuffer : preparedFiles;

  if (!files) return null;

  const canExtractFiles = variant !== TASK_MODAL_TYPES.ADD;
  const canUploadFiles = variant !== TASK_MODAL_TYPES.VIEW;
  const canDeleteFiles = variant !== TASK_MODAL_TYPES.VIEW;

  const getFilesHandler = (e: React.MouseEvent) => {
    stopEventPropagation(e);
    preparedFiles?.forEach((el) => {
      downloadFileByUrl(el.file, el.name || el.file_name);
    });
  };

  const serializedFiles = useMemo(() => {
    return filesToShow?.map((el) => ({
      ...el,
      name: (el as any).file_name,
      id: el.id ?? Math.random(),
      link: (el as any).link ?? el.file,
      file: el.file as string,
    }));
  }, [filesToShow]);

  const [fileViewerIndex, setFileViewerIndex] = useState(-1);

  return (
    <div className={styles.taskFiles}>
      <div className={styles.filesHead}>
        <span>Список вложений: {files?.length}</span>
        {canExtractFiles && !!files.length && <div onClick={getFilesHandler}>Выгрузить</div>}
      </div>
      <InputFiles /* @ts-expect-error */
        value={filesToShow} /* @t2s-expect-error */
        //setValue={handleSetFiles} /* @ts-2expect-error */
        uploadFilesDirectly={(file: File) => uploadFilesHandler([file] as any)}
        removeFileDirectly={deleteFileHandler}
        permissions={{
          addFiles: canUploadFiles,
          deleteFiles: canDeleteFiles,
        }}
        canExpand={undefined}
        disabled={undefined}
        _TODO_onDirectlyClick={setFileViewerIndex}
        isLoading={isPending}
      />
      <FileViewer
        startIndex={fileViewerIndex}
        isOpen={fileViewerIndex !== -1}
        onClose={() => setFileViewerIndex(-1)}
        files={serializedFiles}
      />
    </div>
  );
};

export default TaskFiles;
