import React from "react";

import { ReactComponent as CalendarBlue } from "images/icons/calendarBlue.svg";

import styles from "./JournalTicketModal.module.scss";

interface IProps {
  day: string;
  time?: string;
}

const JournalDay: React.FC<IProps> = ({ day, time }) => {
  return (
    <div className={styles.date}>
      <div className={styles.day}>{day}</div>
      <CalendarBlue />
      {time && <div>в {time}</div>}
    </div>
  );
};

export default React.memo(JournalDay);
