import React from "react";

import AuditDatePicker from "./AuditDatePicker/AuditDatePicker";
import AuditTypeSwitcher from "./AuditTypeSwitcher/AuditTypeSwitcher";
import MeasureBtn from "features/financeMeasure/MeasureBtn/MeasureBtn";

import styles from "./AuditHeadline.module.scss";

function AuditHeadline() {
  return (
    <div className={styles.headline}>
      <AuditDatePicker />
      <AuditTypeSwitcher className={styles.switcher} />
      <MeasureBtn className={styles.measureBtn} />
    </div>
  );
}

export default React.memo(AuditHeadline);
