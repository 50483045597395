import { ConfigProvider, message } from "antd";
import React, { FC, PropsWithChildren } from "react";

import ru_RU from "antd/lib/locale-provider/ru_RU";

message.config({
  duration: 5,
});

const AntdProvider: FC<PropsWithChildren> = ({ children }) => {
  return <ConfigProvider locale={ru_RU}>{children}</ConfigProvider>;
};

export default AntdProvider;
