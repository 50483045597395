import { summaryUtils } from "pages/Finance/Summary/model/utils";
import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IForecastObjectResponse, IForecastProjectListResponse } from "./types";

import { forecastUtils } from "./utils";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

interface IState {
  dataTree: IFinanceTreeItem[];
  loadings: Record<string, boolean>;
  totals: Record<string, IFinanceTreeItem[]>;
}

const initialState: IState = {
  dataTree: [],
  loadings: {},
  totals: {},
};

const financeForecastSlice = createSlice({
  name: "financeForecast",
  initialState,
  reducers: {
    setProjects: (state, { payload }: PayloadAction<IForecastProjectListResponse[]>) => {
      const oldTree = [...state.dataTree];
      const context = { oldTree };
      state.dataTree = payload.map((el) => summaryUtils.mapProjectData(el, context));
    },
    closeItem: (state, { payload }: PayloadAction<IFinanceTreeItem>) => {
      const oldTree = [...state.dataTree];
      state.dataTree = summaryUtils.closeTree(oldTree, payload)!;
    },
    openItem: (state, { payload }: PayloadAction<IFinanceTreeItem>) => {
      const oldTree = [...state.dataTree];
      state.dataTree = summaryUtils.openTree(oldTree, payload)!;
    },
    setObject: (
      state,
      {
        payload: { data, objectId, projectId, year },
      }: PayloadAction<{ data: IForecastObjectResponse; projectId: number; objectId: number; year: number }>
    ) => {
      state.dataTree = forecastUtils.setObject(state.dataTree, data, projectId, objectId);
      const key = generateStorageKey({ type: "object", id: objectId });
      state.totals[key] = summaryUtils.extractObjectTotal(data, year);
    },
    setProject: (
      state,
      {
        payload: { data, projectId, year },
      }: PayloadAction<{ data: IForecastProjectListResponse[]; projectId: number; year: number }>
    ) => {
      state.dataTree = summaryUtils.setProject(state.dataTree, data, projectId);
      const key = generateStorageKey({ type: "project", id: projectId });
      state.totals[key] = summaryUtils.extractProjectTotal(data, year);
    },
    setLoading: (state, { payload: { key, status } }: PayloadAction<{ key: string; status: boolean }>) => {
      state.loadings[key] = status;
    },
    setAnyExtras: (
      state,
      {
        payload: { data, objectId, projectId },
      }: PayloadAction<{ data: IForecastObjectResponse; projectId: number; objectId: number }>
    ) => {
      state.dataTree = forecastUtils.updateExtras(state.dataTree, data, projectId, objectId);
    },
  },
});

export const forecastActions = financeForecastSlice.actions;
export default financeForecastSlice.reducer;
