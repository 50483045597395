import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  actingExpendituresSelector,
  actingSectionsSelector,
} from "redux/modules/common/building/journal/acting/selectors";
import {
  IExpenditureInActing,
  ISectionInActing,
  ISelectedActingIds,
  ISelectedSectionInActing,
} from "redux/modules/common/building/journal/acting/types";

import { IRouterParamsWithObjectId } from "types/routerTypes";

interface IProps {
  estimateId: number;
}

export const useSelectedForKs2 = ({ estimateId }: IProps) => {
  const sections = useSelector(actingSectionsSelector)[estimateId]?.results || [];
  const expenditures = useSelector(actingExpendituresSelector);

  const selectedCount = React.useMemo(() => {
    return sections?.reduce((prev, cur) => {
      if (cur.isSelected) {
        return prev + cur.count_expenditures;
      } else {
        return (
          prev +
          (expenditures[cur.id]?.results || [])?.reduce((prev, cur) => {
            if (cur.isSelected) {
              return prev + 1;
            } else {
              return prev;
            }
          }, 0)
        );
      }
    }, 0);
  }, [sections]);

  const selectedSections: ISelectedSectionInActing[] = React.useMemo(
    () =>
      sections
        .map((el) => {
          if (el.isSelected) {
            if (!expenditures[el.id]?.results?.length) {
              return {
                ...el,
                isNeedLoad: true,
              };
            } else {
              return {
                ...el,
                expenditures: expenditures[el.id]?.results,
              };
            }
          } else {
            if (!expenditures[el.id]?.results?.length || expenditures[el.id]?.results?.every((el) => !el.isSelected)) {
              return null;
            } else {
              return {
                ...el,
                expenditures: expenditures[el.id]?.results?.filter((el) => !!el.isSelected),
              };
            }
          }
        })
        .filter((el) => el !== null) as ISelectedSectionInActing[],
    [sections, expenditures]
  );

  const selectedIds = React.useMemo(() => {
    return selectedSections.reduce<ISelectedActingIds>(
      (prev, cur) => {
        if (!cur.expenditures?.length && cur.isNeedLoad) {
          return { ...prev, section_ids: [...prev.section_ids, cur.id] };
        } else {
          return {
            ...prev,
            expenditure_ids: [
              ...prev.expenditure_ids,
              ...(cur.expenditures || []).filter((el) => el.isSelected).map((el) => el.id),
            ],
          };
        }
      },
      { section_ids: [], expenditure_ids: [] }
    );
  }, [selectedSections]);

  return {
    selectedCount,
    selectedSections: selectedSections as ISelectedSectionInActing[],
    selectedIds,
  };
};
