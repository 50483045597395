import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { postsApi } from "../../redux/modules/common/building/posts/postsApi";

import { IOption } from "../../components/UI/atoms/Select";
import { MAX_RANK } from "../../components/pages/Settings/components/Positions/components/PositionModal/constants";

import { IPostDetail, IPostRank } from "../../types/interfaces/Posts";
import { IRouterParamsWithObjectId } from "../../types/routerTypes";

import intToRoman from "../../utils/formatters/intToRoman";
import { errorCatcher } from "../../utils/helpers/errorCatcher";

const spawnExecutor: () => IExecutor = () => ({
  id: Math.random(),
  post: 0,
  rank: 0 /* @ts-ignore */,
  count: undefined,
  isNew: true,
});

export interface IUseExecutorsProps {
  isOpen: boolean;
  defaultExecutors?: IExecutor[];
}

export interface IExecutor {
  id: number;
  post: number;
  rank: number;
  count: number;
  isNew?: boolean;
  post_title?: string;
  rank_display?: string;
}

const useExecutors = ({ isOpen, defaultExecutors }: IUseExecutorsProps) => {
  const [allPosts, setAllPosts] = useState<IPostDetail[]>();
  const { objectId } = useParams<IRouterParamsWithObjectId>();

  useEffect(() => {
    if (!isOpen) return;
    postsApi
      .getAllPosts()
      .then(({ data }) => {
        setAllPosts(data.results);
      })
      .catch(errorCatcher);
  }, [isOpen]);

  const [executors, setExecutors] = useState(defaultExecutors || []);

  useEffect(() => {
    if (!defaultExecutors?.length) return;
    setExecutors(defaultExecutors);
  }, [defaultExecutors]);

  const onDelete = useCallback((id: number) => {
    setExecutors((prevState) => prevState.filter((executor) => executor.id !== id));
  }, []);

  const onEdit = useCallback((id: number, payload: Partial<IExecutor>) => {
    setExecutors((prevState) =>
      prevState.map((executor) => {
        if (executor.id === id) return { ...executor, ...payload };
        return executor;
      })
    );
  }, []);

  const onAddExecutor = useCallback(() => {
    setExecutors((prevState) => [...prevState, spawnExecutor()]);
  }, []);

  const {
    availablePosts,
    availableRanksByPosts,
  }: { availablePosts: IOption[]; availableRanksByPosts: Record<number, IOption[]> } = useMemo(() => {
    const takenPostsIds: Record<number, number> = {};
    const takenRanksByPosts: Record<number, number[]> = {};
    const baseRanksOptions: IOption[] = new Array(MAX_RANK)
      .fill(0)
      .map((_, index) => ({ id: index + 1, name: intToRoman(index + 1) }));

    executors.forEach((executor) => {
      if (!executor.post) return;
      takenPostsIds[executor.post] = (takenPostsIds[executor.post] || 0) + 1;
      if (!executor.rank) return;
      takenRanksByPosts[executor.post] = [...(takenRanksByPosts[executor.post] || []), executor.rank];
    });

    const postsCandidates =
      allPosts?.filter((post) => !takenPostsIds[post.id] || takenPostsIds[post.id] < MAX_RANK) || [];
    const rankCandidates: Record<number, IOption[]> = Object.fromEntries(
      postsCandidates?.map((post) => {
        const postRanksNames = post.ranks.map((rank) => rank.rank_display);
        const takenRanks = new Set(postRanksNames || []);
        return [post.id, baseRanksOptions.filter((rankOption) => takenRanks.has(rankOption.name as string))];
        // const takenRanks = new Set(takenRanksByPosts[post.id] || []);
        // return [post.id, baseRanksOptions.filter((rankOption) => !takenRanks.has(+rankOption.id))];
      }) || []
    );
    return {
      availablePosts: postsCandidates?.map((post) => ({ id: post.id, name: post.title })),
      availableRanksByPosts: rankCandidates,
    };
  }, [allPosts, executors]);

  const existingRanksByPostsIds: Record<number, IPostRank[]> = useMemo(() => {
    return Object.fromEntries(allPosts?.map((post) => [post.id, post.ranks]) || []);
  }, [allPosts]);

  const createSectionExecutors = useCallback(
    async ({ planId }: { planId: number }) => {
      const addCandidates =
        executors?.filter((x) =>
          !!defaultExecutors ? defaultExecutors?.findIndex((executor) => x.id === executor.id) === -1 : true
        ) || [];
      let executorsWithPostRanks = await Promise.allSettled(
        addCandidates.map(async (executor) => {
          const currentRank = existingRanksByPostsIds[executor.post]?.find((rank) => rank.rank === executor.rank);
          if (!currentRank) {
            const newRank = await postsApi
              .createPostRank(executor.post, { rank: executor.rank, type: "month", stake: "0" })
              .then(({ data }) => data)
              .catch(errorCatcher); //@ts-ignore
            return { ...executor, post_rank: newRank?.id };
          }
          return { ...executor, post_rank: currentRank.id };
        })
      ); //@ts-ignore
      executorsWithPostRanks = executorsWithPostRanks.map((r) => r.value);
      await Promise.allSettled(
        executorsWithPostRanks.map((executor) =>
          postsApi
            .attachExecutorToSectionPlan({
              objectId, //@ts-ignore
              payload: { count: executor.count, post_rank: executor.post_rank, plan: planId },
            })
            .catch(errorCatcher)
        )
      );
    },
    [executors, existingRanksByPostsIds, objectId, defaultExecutors]
  );

  const deleteSectionExecutors = useCallback(async () => {
    const removeCandidates =
      defaultExecutors?.filter((x) => executors.findIndex((executor) => executor.id === x.id) === -1) || [];
    await Promise.allSettled(
      removeCandidates.map((x) =>
        postsApi.detachExecutorFromSectionPlan({ objectId, postIdInPlan: x.id }).catch(errorCatcher)
      )
    );
  }, [defaultExecutors, executors, objectId]);

  const validateExecutors = () => executors.every((x) => !x.isNew);

  return {
    executors,
    onDelete,
    onEdit,
    onAddExecutor,
    availablePosts,
    availableRanksByPosts,
    createSectionExecutors,
    deleteSectionExecutors,
    validateExecutors,
  };
};

export default useExecutors;
