import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { handlerIndexesInvalidateKeySelector, handlerIndexesLoadingSelector } from "../../model/selectors";
import { createHandlerIndex, deleteHandlerIndex, editHandlerIndex } from "../../model/thunks";
import HandlerIndexForm from "../HandlerIndexForm/HandlerIndexForm";
import HandlerIndexesRow from "./HandlerIndexesRow";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import Modal from "shared/ui/modal/Modal";

import { ICreateIndex, IHandlerIndex } from "../../model/types";
import { IIdAndName } from "types/idAndName";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./HandlerIndexesModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  section: IIdAndName;
  indexes: IHandlerIndex[] | null;
  isLoading: boolean;
  allAmount: string;
  defaultAmount: string;
}

const HandlerIndexesModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  section,
  indexes,
  isLoading,
  allAmount,
  defaultAmount,
}) => {
  const dispatch = useDispatch();
  const objectId = useObjectId(3);
  const invalidateKey = useSelector(handlerIndexesInvalidateKeySelector);
  const key = generateStorageKey({ type: "pending", buildingId: objectId });
  const isPending = useSelector(handlerIndexesLoadingSelector)[key];
  const isEmpty = !isLoading && !indexes?.length;

  const createHandler = (vals: ICreateIndex) => {
    dispatch(
      createHandlerIndex(objectId, {
        ...vals,
        section: String(section.id),
        is_mtr: vals.is_mtr ?? false,
        is_smr: vals.is_smr ?? false,
      })
    );
  };

  const [isAdding, setIsAdding] = useState(false);

  const onRemove = (indexId: number) => {
    dispatch(deleteHandlerIndex(objectId, indexId));
  };

  useEffect(() => {
    setIsAdding(false);
  }, [invalidateKey]);

  return (
    <Modal
      title="Примененные индексы-дефляторы"
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
      closeOnClickOutside={false}
    >
      <div className={styles.subtitle}>ЛСР: {section?.name}</div>
      {!isEmpty && (
        <div className={styles.totalsRow}>
          <div className={styles.total}>
            <div>С учетом индексов:</div>
            <span>{transformDigitToFinancial(allAmount, { withCurrencySign: true, withFloat: true })}</span>
          </div>
          <div className={styles.total}>
            <div>Исходная стоимость:</div>
            <span>{transformDigitToFinancial(defaultAmount, { withCurrencySign: true, withFloat: true })}</span>
          </div>
        </div>
      )}
      {!isEmpty && (
        <div className={cn(styles.tableHeader, styles.cols)}>
          <div>Название</div>
          <div className={styles.indexHeader}>Индекс</div>
          <div className={styles.centered}>Применен к</div>
          <div>Основание</div>
          <div>Применил</div>
        </div>
      )}
      <div className={styles.table}>
        {isLoading && (
          <div className={styles.spinner}>
            <Spinner isStatic />
          </div>
        )}
        {indexes?.map((el) => (
          <HandlerIndexesRow values={el} key={el.id} onRemove={onRemove} isPending={isPending} />
        ))}
        {isEmpty && <div className={styles.empty}>К ЛСР нет примененных коэффициентов</div>}
      </div>
      {isAdding && (
        <HandlerIndexForm onSubmit={createHandler} onCancel={() => setIsAdding(false)} isPending={isPending} />
      )}
      <div className={styles.add}>
        {!isAdding && <AddButton isWide text="Добавить индекс" textPosition="left" onClick={() => setIsAdding(true)} />}
      </div>
    </Modal>
  );
};

export default HandlerIndexesModal;
