import React from "react";
import { useDispatch } from "react-redux";

import { deleteTasksList, editTasksList } from "components/pages/Tasks/model/thunks";

type propsType = {
  user: number;
  id: number;
  list_name: string;
  successEditCallback?: () => void;
  successDeleteCallback?: () => void;
};

export const useTasksListEdit = ({ user, id, list_name, successEditCallback, successDeleteCallback }: propsType) => {
  const dispatch = useDispatch();
  const [name, setName] = React.useState(list_name);

  React.useEffect(() => {
    setName(list_name);
  }, [list_name]);

  const editNameHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const editTasksListHandler = React.useCallback(() => {
    /* @ts-ignore */
    dispatch(editTasksList({ id, user, list_name: name }, successEditCallback));
  }, [name, id, user]);

  const deleteTasksListHandler = React.useCallback(
    (deleteTasks: boolean) => {
      dispatch(deleteTasksList(id, () => {}, deleteTasks));
    },
    [id]
  );

  return {
    editNameHandler,
    editTasksListHandler,
    deleteTasksListHandler,
  };
};
