import cn from "classnames";
import React from "react";

import Portal from "shared/ui/atoms/Portal";

import useEscapeHandler from "../../../../../utils/hooks/useEscapeHandler";

import styles from "./BottomSliderMaterials.module.scss";

//TODO wtf is it?

const BottomSliderMaterials = ({ isOpen, closeFunc, title, children, className, parentSelector }) => {
  useEscapeHandler(closeFunc);
  return (
    <>
      <Portal>
        {isOpen && <div className={styles.shadow} onClick={closeFunc}></div>}
        <div
          className={cn(styles.container, className, {
            [styles.active]: isOpen,
          })}
        >
          <div className={cn(styles.content)}>
            <button onClick={closeFunc} type="button" className="close" />
            {children}
          </div>
        </div>
      </Portal>
    </>
  );
};

export default BottomSliderMaterials;
