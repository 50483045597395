import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { ISignator, ISignerInCommonList } from "./types";
import { IEmployee } from "types/personsTypes";

interface SignatorsState {
  isLoading: Record<string, boolean>;
  invalidateKey: number;
  signatorsByBuildings: Record<string, ISignator[] | null>;
  allSignators: ISignerInCommonList[];
  employees: Record<string, IEmployee[]>;
  wasDeletedSigners: number[];
}

const initialState: SignatorsState = {
  isLoading: {},
  invalidateKey: 0,
  signatorsByBuildings: {},
  allSignators: [],
  employees: {},
  wasDeletedSigners: [],
};

const signatorsSlice = createSlice({
  name: "signators",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      const { key, status } = action.payload;
      state.isLoading[key] = status;
    },
    invalidateKey: (state) => {
      state.invalidateKey++;
    },
    setSignatorsByBuilding: (state, action: PayloadAction<{ key: string; signators: ISignator[] }>) => {
      const { key, signators } = action.payload;
      state.signatorsByBuildings[key] = signators;
    },
    setAllSignators: (state, action: PayloadAction<ISignerInCommonList[]>) => {
      const signators = action.payload;
      state.allSignators = signators;
    },
    setEmployeesByBuilding: (state, action: PayloadAction<{ key: string; employees: IEmployee[] }>) => {
      const { key, employees } = action.payload;
      state.employees[key] = employees;
    },
    markAsDeleted: (state, action: PayloadAction<number>) => {
      state.wasDeletedSigners.push(action.payload);
    },
    clearDeleted: (state) => {
      state.wasDeletedSigners = [];
    },
  },
});

export const signatorsActions = signatorsSlice.actions;

export default signatorsSlice.reducer;
