import { initChartActions, initChartTreeFilters } from "redux/modules/common/chart/reducer";
import { ChartActions, IChartStatePatch } from "redux/modules/common/chart/types";

import { CHART_TREE_LVL } from "../constants";

export const CHART_CONFIG_CONSTRUCTING: IChartStatePatch = {
  filters: {
    ...initChartTreeFilters(),
    skipBranchLvl: {
      [CHART_TREE_LVL.EXPENDITURE]: true,
    },
  },
  actions: {
    ...initChartActions(),
    [ChartActions.plans_editing_enabled]: false,
    [ChartActions.linking_editing_enabled]: false,
  },
};
