import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Switch} from 'antd'
import cn from 'classnames'

import styles from './LabeledSwitch.module.scss'
import {REFS} from './constants'

const LabeledSwitch = (props) => {
  const {defaultValue, label, value, labelRight, size, onChange} = props

  const [val, setVal] = useState(defaultValue)

  const handleChange = (val) => {
    setVal(val)
    onChange(val)
  }

  const handleLabelClick = () => {
    const newValue = value !== undefined ? value : val
    handleChange(!newValue)
  }

  return (
    <div className={styles.switchBlock}>
      <span onClick={handleLabelClick} className={cn(styles.text, styles[size])}>
        {label}
      </span>
      <Switch
        {...this.props}
        checked={value !== undefined ? value : this.state.val}
        onChange={handleChange}
      />
      {labelRight && (
        <span onClick={handleLabelClick} className={styles.textRight}>
          {labelRight}
        </span>
      )}
    </div>
  )
}

LabeledSwitch.propTypes = {
  defaultValue: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  labelRight: PropTypes.string,
  size: PropTypes.oneOf(Object.values(REFS.SIZES))
}

LabeledSwitch.defaultProps = {
  defaultValue: false,
  value: undefined,
  labelRight: null,
  size: REFS.SIZES.S
}

LabeledSwitch.REFS = REFS

export default LabeledSwitch
