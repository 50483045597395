import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getStocksList } from "./model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";
import MenuItem from "features/templates/NavigationBar/MenuItem";
import { useTranslation } from "react-i18next";

import { useLocationArray } from "utils/hooks/useLocationArray";

import warehouses from "images/icons/navigation/warehouses.svg";

const StocksProcurementsButton = () => {
  const dispatch = useDispatch();
  const locationArray = useLocationArray();
  const { t } = useTranslation();

  const { stocks } = useTypedSelector((state) => state.stocksProcurements);

  useEffect(() => {
    dispatch(getStocksList());
  }, []);

  if (!stocks?.length) {
    return null;
  }

  return (
    <MenuItem
      /* @ts-ignore */
      title={t("Склад")}
      link={`/purchases/stocks/${stocks?.[0]?.id}/receipt`}
      icon={warehouses}
      isDirectlyActive={locationArray.includes("stocks")}
    />
  );
};

export default StocksProcurementsButton;
