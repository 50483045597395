import React, { useMemo } from "react";

import {
  analyticsEventsActiveTabSelector,
  analyticsEventsAllSelector,
  analyticsEventsOverdueSelector,
  analyticsLoadingsSelector,
} from "../../../../../../../redux/modules/common/analytics/selectors";

import { useTypedSelector } from "../../../../../../../app/store/typedUseSelector";
import { Spinner } from "../../../../../../../shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "../../../../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import AnalyticsEvent, { IAnalyticsEventProps } from "./AnalyticsEvent";

import { analyticsEventStatusSwitcher, formatEventDate, sortEvents } from "../../constants";

import analyticsIcon from "../../../../../../../images/icons/navigation/analyticsIcon.svg";

import styles from "./AnalyticsEventsBody.module.scss";

export interface IAnalyticsEventsBodyProps {
  openTask: IAnalyticsEventProps["openTask"];
  addToRequisition: IAnalyticsEventProps["addToRequisition"];
}

const AnalyticsEventsBody: React.FC<IAnalyticsEventsBodyProps> = ({ openTask, addToRequisition }) => {
  const eventsAll = useTypedSelector(analyticsEventsAllSelector);
  const eventsOverdue = useTypedSelector(analyticsEventsOverdueSelector);
  const tab = useTypedSelector(analyticsEventsActiveTabSelector);
  const isLoading = useTypedSelector(analyticsLoadingsSelector)["events"];

  const sortedEvents = useMemo(() => {
    if (tab === "overdue") return [...eventsOverdue].sort(sortEvents);
    return [...eventsAll].sort(sortEvents);
  }, [eventsAll, eventsOverdue, tab]);

  if (isLoading) return <Spinner />;

  if (!sortedEvents.length)
    return <EmptyPlaceholder fullHeight={false} img={analyticsIcon} className={styles.emptyPlaceholder} />;

  return (
    <div className={styles.body}>
      {sortedEvents.map((e, index, arr) => (
        <React.Fragment key={index}>
          {(index === 0 || e.date !== arr[index - 1].date) && (
            <span className={styles.eventDate}>{formatEventDate(e.date)}</span>
          )}
          <AnalyticsEvent
            event={e} /* @ts-ignore */
            statusSwitcher={analyticsEventStatusSwitcher}
            openTask={openTask}
            addToRequisition={addToRequisition}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default React.memo(AnalyticsEventsBody);
