import { CHART_VIEW_MODE } from "redux/modules/common/chart/types";

const WEEKEND_COLOR = "rgba(235, 235, 235, 0.5)";
const WEEK_ODD_COLOR = WEEKEND_COLOR;
const BORDER_COLOR = "#dedede";

export const makeBackground = ({
  firstWeekend,
  unitMultiplier,
  chartViewMode,
}: {
  firstWeekend: number;
  unitMultiplier: number;
  chartViewMode: CHART_VIEW_MODE;
}) => {
  if (chartViewMode === CHART_VIEW_MODE.WEEKS) {
    return `repeating-linear-gradient(90deg, transparent 0, transparent ${7 * unitMultiplier}rem, ${WEEK_ODD_COLOR} ${
      7 * unitMultiplier
    }rem, ${WEEK_ODD_COLOR} ${14 * unitMultiplier}rem)`;
  }
  if (chartViewMode === CHART_VIEW_MODE.MONTHS) {
    return `repeating-linear-gradient(90deg, transparent 0, transparent ${unitMultiplier}rem, ${WEEK_ODD_COLOR} ${unitMultiplier}rem, ${WEEK_ODD_COLOR} ${
      2 * unitMultiplier
    }rem), linear-gradient(90deg, white calc(${unitMultiplier}rem - 1px), transparent 1%) ${unitMultiplier}rem center / ${unitMultiplier}rem ${unitMultiplier}rem,
    linear-gradient(${BORDER_COLOR} 3px, transparent 1%) top / 6px 6px`;
  }
  return `repeating-linear-gradient(90deg, ${WEEKEND_COLOR} ${
    (firstWeekend - 1) * unitMultiplier
  }rem, ${WEEKEND_COLOR} ${(firstWeekend + 1) * unitMultiplier}rem, transparent ${
    (firstWeekend + 1) * unitMultiplier
  }rem, transparent ${(firstWeekend + 6) * unitMultiplier}rem),
linear-gradient(90deg, white calc(${unitMultiplier}rem - 1px), transparent 1%) ${
    (firstWeekend - 1) * unitMultiplier
  }rem center / ${unitMultiplier}rem ${unitMultiplier}rem,
linear-gradient(${BORDER_COLOR} 3px, transparent 1%) top / 6px 6px`;
};
