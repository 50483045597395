import cn from "classnames";
import React, { useEffect, useState } from "react";

import { CSSTransition } from "react-transition-group";

import MinusBoxIcon from "images/icons/MinusBoxIcon";
import PlusBoxIcon from "images/icons/PlusBoxIcon";

import styles from "./index.module.scss";

const HideBlock = ({
  title,
  children,
  titleClassName = "",
  activeTitleClassName = "",
  titleStyle = {},
  bodyClassName = "",
  disabled = false,
  renderHidden = false,
  arrowEnabled = true,
  titleRightBlock = null,
  isHiddenDefault = true,
  onOpenAction = () => {},
  timeout = 20,
}) => {
  const [isHidden, setIsHidden] = useState(isHiddenDefault);

  useEffect(() => {
    setIsHidden(isHiddenDefault);
  }, [isHiddenDefault]);

  const toggle = async () => {
    await setIsHidden(!isHidden);
    await onOpenAction(isHidden);
  };

  return (
    <>
      {title && (
        <div className={cn(styles.titleContainer)}>
          <div
            onClick={() => !disabled && toggle()}
            className={cn(styles.title, titleClassName, {
              [activeTitleClassName]: !isHidden,
            })}
            style={{
              cursor: disabled || !children ? "default" : "pointer",
              ...titleStyle,
            }}
          >
            {!disabled && arrowEnabled && (
              <div className={styles.icon}>{isHidden ? <PlusBoxIcon /> : <MinusBoxIcon />}</div>
            )}
            {typeof title === "function" ? title(isHidden) : <span title={title}>{title}</span>}
          </div>
          {titleRightBlock?.(isHidden)}
        </div>
      )}
      <CSSTransition in={!isHidden} timeout={timeout} unmountOnExit>
        <div className={`${styles.hiddenBlock} ${bodyClassName}`}>{children}</div>
      </CSSTransition>
      {renderHidden && isHidden && (
        <div
          style={{
            display: "none",
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default React.memo(HideBlock);
