import React from "react";

import { IAook } from "redux/modules/common/building/journal/aook/types";

interface IProps {
  currentMaterials: any[]; //IAook["all_materials"];
}

export const useCreateAookMaterials = ({ currentMaterials }: IProps) => {
  const [allMaterials, setAllMaterials] = React.useState(currentMaterials);

  React.useEffect(() => {
    setAllMaterials(currentMaterials?.map((el) => ({ ...el, was_deleted: false })));
  }, [currentMaterials]);

  const deleteMaterial = React.useCallback((id: number) => {
    setAllMaterials((prev) => {
      return prev?.map((el) => {
        if (el.material_id == id) {
          return { ...el, was_deleted: true };
        } else {
          return el;
        }
      });
    });
  }, []);

  const addMaterial = React.useCallback((material: IProps["currentMaterials"][0]) => {
    setAllMaterials((prev) => {
      if (prev?.findIndex((el) => el.material_id === material.material_id) === -1) {
        return [...prev, material];
      }
      return prev?.map((el) => {
        if (el.material_id === material.material_id) {
          return { ...el, was_deleted: false };
        } else {
          return el;
        }
      });
    });
  }, []);

  return {
    allMaterials,
    deleteMaterial,
    addMaterial,
  };
};
