import cn from "classnames";
import React, { useCallback, useEffect, useState } from "react";

import DynamicInput from "./BaseDynamicInput";
import { AutosizeInputProps } from "react-input-autosize";

import styles from "./DynamicTitleInput.module.scss";

export const SIZES = { SMALL: "small", NORMAL: "normal", BIG: "big" } as const;

export interface IProps extends Omit<AutosizeInputProps, "size" | "onChange"> {
  title: string;
  //TODO rename to onBlur
  onChange: (value: string) => void;
  //TODO rename to onChange
  onDirectlyChange?: (value: string) => void;
  size: "small" | "normal" | "big";
  placeholder?: string;
  isDisabled?: boolean;
}

const DynamicTitleInput: React.FC<IProps> = ({
  title,
  onChange,
  size = SIZES.NORMAL,
  placeholder = "Введите название",
  isDisabled,
  onDirectlyChange,
  ...restProps
}) => {
  const [changedName, setChangedName] = useState("");

  const handleSetChangedName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChangedName(event.target.value);
      onDirectlyChange?.(event.target.value);
    },
    [onDirectlyChange]
  );
  const onBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  useEffect(() => {
    setChangedName(title);
  }, [title]);

  return (
    <DynamicInput
      {...restProps}
      placeholder={placeholder}
      className={cn("autosize-input", styles.dynamicInput, styles[size])}
      maxLength={100}
      value={changedName}
      onChange={handleSetChangedName}
      onBlur={onBlur}
      disabled={isDisabled}
    />
  );
};

export default React.memo(DynamicTitleInput);
