import { LOCALIZATION_CONFIG } from "../../constants/localization";
import { CURRENCY_ENUM } from "types/enums/CurrencyEnum";

export const getCurrencyShortName = () => {
  switch (LOCALIZATION_CONFIG.currency) {
    case CURRENCY_ENUM.RUBLE:
      return "руб";
    case CURRENCY_ENUM.TENGE:
      return "тн";
    default:
      return "руб";
  }
};
