import cn from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { hideNotification, readNotification } from "redux/modules/_TODO/notifications";

import formatNotificationMessage from "../FormatNotificationMessage/FormatNotificationMessage";
import NotificationDeleted from "../NotificationDeleted/NotificationDeleted";

import { INotification } from "../../types/types";

import closeNoty from "images/icons/closeNotyItem.svg";

import styles from "./NotificationItem.module.scss";

export interface IProps {
  notification: INotification;
  notificationDeletedStatus: Record<number, boolean>;
  setNotificationDeletedStatus: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
}

const NotificationItem: React.FC<IProps> = ({
  notification,
  notificationDeletedStatus,
  setNotificationDeletedStatus,
}) => {
  const dispatch = useDispatch();

  const toggleNotificationHandler = useCallback(
    (event: React.MouseEvent, isNotificationDeleted: boolean) => {
      event.stopPropagation();

      setNotificationDeletedStatus((prev: Record<number, boolean>) => ({
        ...prev,
        [notification.id]: isNotificationDeleted,
      }));
      dispatch(hideNotification(notification.id, isNotificationDeleted));
    },
    [dispatch, notification.id, setNotificationDeletedStatus]
  );

  const readHandler = useCallback(() => {
    if (!notification.is_read) {
      dispatch(readNotification(notification.id));
    }
  }, [dispatch, notification.id, notification.is_read]);

  return (
    <div className={styles.noteItem} onClick={readHandler}>
      <div className={cn({ [styles.displayNone]: notificationDeletedStatus[notification.id] })}>
        {!notification.is_read && <div className={styles.readCheck} />}
        <div className={styles.innerNoteItem}>
          <div className={styles.dateFlexAndCloseBtn}>
            <div className={styles.objectFlexAndDate}>
              <div className={styles.flexObjectText}>
                <div className={styles.objectCircle}></div>
                <div className={styles.messageInner}>{formatNotificationMessage(notification)}</div>
              </div>
              <div className={styles.dateReceived}>{moment(notification.created_at).format("DD.MM.YY")}</div>
            </div>
            <div onClick={(event) => toggleNotificationHandler(event, true)} className={styles.closeNotyBtn}>
              <img src={closeNoty} alt="иконка удаления" />
            </div>
          </div>
        </div>
      </div>

      {notificationDeletedStatus[notification.id] && <NotificationDeleted handleClick={toggleNotificationHandler} />}
    </div>
  );
};

export default React.memo(NotificationItem);
