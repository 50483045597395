import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { IPlanFactResponse } from "../../../../../../redux/modules/_TODO/constructing/finance/planFact/types";

import SectionRow from "../../../PlanFactList/components/PlanFactSectionRow/PlanFactSectionRow";
import Expenditure from "./components/PlanFactExpenditure/PlanFactExpenditure";
import { planFactUtils } from "pages/PlanFact/PlanFactSummary/utils";

import { useFinanceMeasure } from "../../../../../../features/financeMeasure";

import { LOCALIZATION_CONFIG } from "../../../../../../constants/localization";

import { useLocationArray } from "utils/hooks/useLocationArray";

import { formatNumberWithMeasure } from "../../../../../../utils/formatters/formatNumberWithMeasure";

import triangleRight from "images/icons/planfact/tirangleRight.svg";

import styles from "./SubSectionRow.module.scss";

export interface IPlanFactSubSectionRowProps {
  subsection: IPlanFactResponse["sections"][number]["subsections"][number];
  isOutOfEstimate?: boolean;
}

const SubSectionRow: React.FC<IPlanFactSubSectionRowProps> = ({ subsection, isOutOfEstimate = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const locationArr = useLocationArray();

  useEffect(() => {
    const { subSectionId } = planFactUtils.getRouteIds(locationArr);
    if (+subSectionId! === subsection.id) {
      setIsOpen(true);
    }
  }, [locationArr]);

  const { measure } = useFinanceMeasure();

  const [isExpandedDifference, setIsExpandedDifference] = useState<boolean>(false);
  const toggleDifferenceView = () => setIsExpandedDifference((prevState) => !prevState);

  const plan = formatNumberWithMeasure(subsection?.plan, measure);
  const fact = formatNumberWithMeasure(subsection?.fact, measure);
  const budget = formatNumberWithMeasure(subsection?.budget, measure);

  const displayedExpenditureList = useMemo(() => {
    return subsection?.items; //?.sort((a, b) => parseFloat(a.number) - parseFloat(b.number));
  }, [subsection?.items]);

  if (!displayedExpenditureList?.length) return null;

  return (
    <div
      className={cn(styles.container, {
        [styles.active]: isOpen,
      })}
    >
      {!isOutOfEstimate && (
        <div
          className={cn(styles.header)}
          onClick={() =>
            setIsOpen((prev) => {
              if (!prev) {
                const { subSectionId } = planFactUtils.getRouteIds(locationArr);
                history.replace("/" + locationArr.filter((el) => el !== subSectionId).join("/") + "/" + subsection.id);
              } else {
                history.replace("/" + locationArr.slice(0, locationArr.length - 1).join("/"));
              }
              return !prev;
            })
          }
        >
          <div className={styles.field1} title={subsection?.name}>
            {subsection?.name}
          </div>
          <div className={styles.headerField}>
            <div className={styles.headerPrice1}>{plan}</div>
            <div className={styles.headerText}>План</div>
          </div>
          <div className={styles.headerField}>
            <div className={styles.headerPrice}>{fact}</div>
            <div className={styles.headerText}>Факт</div>
          </div>
          <div className={styles.headerField}>
            <div className={styles.headerPrice3}>{budget}</div>
            <div className={styles.headerText}>Бюджет</div>
          </div>
        </div>
      )}
      {isOutOfEstimate && <SectionRow section={subsection} />}
      {isOpen && (
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <div className={styles.leftSide}>
              <div className={styles.field1}>№</div>
              <div className={styles.field2}> Наименование</div>
              {!isExpandedDifference && <div className={styles.field3}>Ед. изм.</div>}
            </div>
            <div className={cn(styles.rightSide, { [styles.headingWithExpandedDifference]: isExpandedDifference })}>
              <div className={styles.top}>
                <div className={styles.fieldP}>План</div>
                <div className={styles.fieldF}>Факт</div>
                <div className={styles.fieldD} onClick={toggleDifferenceView}>
                  Разница{" "}
                  <img src={triangleRight} alt=">" className={cn({ [styles.leftTriangle]: isExpandedDifference })} />
                </div>
              </div>
              <div className={cn(styles.bottomFields, { [styles.withExpandedDifference]: isExpandedDifference })}>
                {!isExpandedDifference && <div className={styles.titleField1}>Кол-во</div>}
                <div className={styles.titleField2}>Цена за ед.</div>
                <div className={cn(styles.titleField3, styles.withRightBorder)}>Стоимость</div>
                {!isExpandedDifference && <div className={styles.titleField4}>Кол-во</div>}
                <div className={styles.titleField5}>Средняя цена</div>
                <div className={cn(styles.titleField3, styles.withRightBorder)}>Стоимость</div>
                <div className={styles.titleField6}>%</div>
                {isExpandedDifference && <div className={styles.titleField7}>{LOCALIZATION_CONFIG.currency}</div>}
              </div>
            </div>
          </div>
          {displayedExpenditureList?.map((expenditure) => (
            <Expenditure
              key={expenditure?.expenditure?.id ?? expenditure?.group?.id}
              expenditure={expenditure}
              isExpandedDifference={isExpandedDifference}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(SubSectionRow);
