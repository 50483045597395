import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartActions } from "redux/modules/common/chart/actions";
import {
  chartCheckpointsMarksSelector,
  chartScrollTouchedYearsSelector,
  chartViewModeSelector,
} from "redux/modules/common/chart/selectors";
import { CHART_VIEW_MODE } from "redux/modules/common/chart/types";

import { ChartDateLineDays } from "./_days/ChartDateLineDays";
import { ChartDateLineMonths } from "./_months/ChartDateLineMonths";
import { ChartDateLineWeeks } from "./_weeks/ChartDateLineWeeks";
import { createPortal } from "react-dom";

import { useChartUnitMultiplier } from "../../hooks/useChartUnitMultiplier";

import { makeBackground } from "./ChartDateLine.utils/makeBackground";
import { monthMarkers } from "./ChartDateLine.utils/monthMarkers";
import { unitOffsetDays } from "./ChartDateLine.utils/unitOffsetDays";
import { unitOffsetMonths } from "./ChartDateLine.utils/unitOffsetMonths";
import { unitOffsetWeeks } from "./ChartDateLine.utils/unitOffsetWeeks";
import { remToPx } from "utils/remToPx";

import styles from "./ChartDateLine.module.scss";

export interface IChartDateLineProps {
  containerRef: React.RefObject<HTMLElement>;
}

type lineRefType = Record<number | string, HTMLDivElement>;

interface ChartLineUpperProps {
  lineRef: React.RefObject<lineRefType>;
}

const ChartDateLineUpper: React.FC<ChartLineUpperProps> = ({ lineRef }) => {
  const touchedYears = useSelector(chartScrollTouchedYearsSelector);
  const chartViewMode = useSelector(chartViewModeSelector);
  const chartCheckpointsMarks = useSelector(chartCheckpointsMarksSelector);

  return (
    <>
      {touchedYears.map((y, index) => {
        if (chartViewMode === CHART_VIEW_MODE.MONTHS) {
          const monthsOffset = unitOffsetMonths(touchedYears, index);
          return (
            <ChartDateLineMonths
              key={y}
              year={y}
              ref={lineRef}
              unitOffset={monthsOffset}
              checkpointsMarks={chartCheckpointsMarks.yearWeeks}
            />
          );
        }
        if (chartViewMode === CHART_VIEW_MODE.WEEKS) {
          const weeksOffset = unitOffsetWeeks(touchedYears, index);
          return (
            <ChartDateLineWeeks
              key={y}
              year={y}
              ref={lineRef}
              unitOffset={weeksOffset}
              checkpointsMarks={chartCheckpointsMarks.yearWeeks}
            />
          );
        }
        const daysOffset = unitOffsetDays(touchedYears, index);

        return (
          <ChartDateLineDays
            key={y}
            year={y}
            ref={lineRef}
            unitOffset={daysOffset}
            checkpointsMarks={chartCheckpointsMarks.days}
          />
        );
      })}
    </>
  );
};

const ChartDateLine: React.FC<IChartDateLineProps> = ({ containerRef }) => {
  const dispatch = useDispatch();
  const touchedYears = useSelector(chartScrollTouchedYearsSelector);
  const lineRef = useRef<lineRefType>({});
  const unitMultiplier = useChartUnitMultiplier();
  const chartViewMode = useSelector(chartViewModeSelector);

  useEffect(() => {
    if (!touchedYears) return;
    const REM = remToPx(1);
    touchedYears.forEach((y) => {
      if (!lineRef.current[y]) return;
      const startMonthElements = Array.from(lineRef.current[y].querySelectorAll(`.startMonth`));
      if (startMonthElements.length > 0) {
        dispatch(chartActions.setScrollMarkers(monthMarkers(startMonthElements, REM), y));
      }
    });
  }, [lineRef.current, touchedYears, unitMultiplier, chartViewMode]);

  const bgWidthRem = useMemo(() => {
    if (chartViewMode === CHART_VIEW_MODE.MONTHS) {
      return unitOffsetMonths(touchedYears, touchedYears.length) * unitMultiplier;
    }
    if (chartViewMode === CHART_VIEW_MODE.WEEKS) {
      return unitOffsetWeeks(touchedYears, touchedYears.length) * unitMultiplier;
    }
    return unitOffsetDays(touchedYears, touchedYears.length) * unitMultiplier;
  }, [touchedYears, unitMultiplier, chartViewMode]);

  const firstWeekend = useMemo(() => {
    const firstTouchedYear = touchedYears?.[0];
    if (!firstTouchedYear) return 1;
    for (let d = 1; d <= 7; d++) {
      const weekday = new Date(firstTouchedYear, 0, d).getDay();
      if (weekday === 6) return d;
      if (weekday === 0) return d - 1;
    }
    return 1;
  }, [touchedYears?.[0]]);

  const [bgContainer, setBgContainer] = useState(containerRef.current);

  useEffect(() => {
    setBgContainer(containerRef.current);
  }, [containerRef.current]);

  return (
    <>
      <div className={styles.lineContainer}>
        <ChartDateLineUpper lineRef={lineRef} />
      </div>
      {bgContainer
        ? createPortal(
            <div
              className={styles.bgGradient}
              style={{
                background: makeBackground({
                  firstWeekend,
                  unitMultiplier,
                  chartViewMode,
                }),
                width: `${bgWidthRem}rem`,
              }}
            />,
            bgContainer
          )
        : null}
    </>
  );
};

export default React.memo(ChartDateLine);
