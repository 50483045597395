import cn from "classnames";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import styles from "./TimeEdit.module.scss";

interface IProps {
  onSubmit: (hours: string, minutes: string) => void;
  defaultValue?: string;
  isPending?: boolean;
  className?: string;
  isClearOnEdit?: boolean;
}

const TimeEdit: React.FC<IProps> = ({ defaultValue, onSubmit, isPending, className, isClearOnEdit = true }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isHoursInFocus, setIsHoursInFocus] = useState(false);
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const minutesRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!defaultValue) return;
    setHours(String(moment(defaultValue).format("HH")));
    setMinutes(String(moment(defaultValue).format("mm")));
  }, [defaultValue]);

  useEffect(() => {
    if (!isClearOnEdit || !isEditing) return;
    setHours("");
    setMinutes("");
  }, [isEditing, isClearOnEdit]);

  useEffect(() => {
    if (hours?.length === 2 && isHoursInFocus && minutes?.length < 2) {
      minutesRef.current?.focus();
    }
  }, [hours, isHoursInFocus, minutes]);

  useEffect(() => {
    const enterHandler = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        submit();
      }
    };

    minutesRef.current?.addEventListener("keydown", enterHandler);
    return () => {
      minutesRef.current?.removeEventListener("keydown", enterHandler);
    };
  }, []);

  const minutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(+e.target.value) || e.target.value.length > 2) return;
    if (+e.target.value > 59) {
      setMinutes("59");
      return;
    }
    setMinutes(e.target.value);
  };

  const hoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(+e.target.value) || e.target.value.length > 2) return;
    if (+e.target.value > 59) {
      setHours("59");
      return;
    }
    setHours(e.target.value);
  };

  const submit = () => {
    const isValid = !isNaN(+hours) && !isNaN(+minutes) && +hours > -1 && +hours < 24 && +minutes > -1 && +minutes < 60;
    if (!isValid) return;
    setIsEditing(false);
    onSubmit(hours, minutes);
  };

  return (
    <div className={cn(styles.container, className)}>
      <input
        value={hours}
        onFocus={() => setIsHoursInFocus(true)}
        onBlur={() => setIsHoursInFocus(false)}
        onChange={hoursChange}
        disabled={isPending || !isEditing}
      />
      :
      <input value={minutes} ref={minutesRef} onChange={minutesChange} disabled={isPending || !isEditing} />
      {!isEditing && (
        <button onClick={() => setIsEditing(true)} disabled={isPending}>
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.78574 3.21426L10 2L8 0L6.78574 1.21426L8.78574 3.21426Z" fill="#A5A5A5" />
            <path d="M8.21426 3.78574L6.21426 1.78574L0 8V10H2L8.21426 3.78574Z" fill="#A5A5A5" />
          </svg>
        </button>
      )}
      {isEditing && (
        <button onClick={submit} disabled={isPending}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.60581 8.79368L1.46056 5.93022L0.787354 6.66968L4.70255 10.2341L10.8223 2.94088L10.0562 2.2981L4.60581 8.79368Z"
              fill="#A5A5A5"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default TimeEdit;
