import cn from "classnames";
import React from "react";

import styles from "./BottomControls.module.scss";

type propsType = {
  children?: React.ReactNode;
  isExists?: boolean;
  isDoubleBtns?: boolean;
  isEmpty?: boolean;
  className?: string;
  wrapperClassName?: string;
  isAbsolute?: boolean;
};

function BottomControls({
  children,
  isExists,
  isDoubleBtns,
  isEmpty,
  className,
  wrapperClassName,
  isAbsolute,
}: propsType) {
  if (!isExists) return null;

  return (
    <>
      <div
        className={cn(styles.controls, className, { [styles.doubleBtns]: isDoubleBtns, [styles.absolute]: isAbsolute })}
      >
        {children}
        {isEmpty && <div className={styles.empty} />}
      </div>
      <div className={cn(styles.controlsWrapper, wrapperClassName)}></div>
    </>
  );
}

export default BottomControls;
