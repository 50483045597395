import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";

import { IdNameLabelType } from "types/IdNameLabelType";

import styles from "./RequisitionsTableHead.module.scss";

interface Iprops {
  objectId?: string;
  onChangeFilter: (name: string, value: string) => void;
  projects: IdNameLabelType[];
}

const RequisitionsTableHead: React.FC<Iprops> = ({ objectId, onChangeFilter, projects }) => {
  return (
    <TableReusableHead className={styles.row} isWithEar isSticky>
      <TableReusableHeaderCell filterField="number" filterType="text" onChangeFilter={onChangeFilter} filterTitle="№">
        №
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="name"
        filterType="text"
        onChangeFilter={onChangeFilter}
        filterTitle="Наименование"
      >
        Наименование
      </TableReusableHeaderCell>
      {!objectId ? (
        <TableReusableHeaderCell
          filterField="building_id"
          filterType="select"
          onChangeFilter={onChangeFilter}
          filterOptions={projects}
          filterTitle="Проект"
        >
          Проект
        </TableReusableHeaderCell>
      ) : (
        <div />
      )}
      <TableReusableHeaderCell isCentered>Позиции</TableReusableHeaderCell>
      <TableReusableHeaderCell
        isCentered
        rangeEndField="created_at_before"
        rangeStartField="created_at_after"
        filterType="datesRange"
        onChangeFilter={onChangeFilter}
        filterTitle="Создана"
      >
        Создана
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="executor"
        filterType="text"
        onChangeFilter={onChangeFilter}
        filterTitle="Исполнитель"
      >
        Исполнитель
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>Согласования</TableReusableHeaderCell>
      <TableReusableHeaderCell>Статус</TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default RequisitionsTableHead;
