import React, { FC, memo } from "react";
import { useSelector } from "react-redux";

import { estimateStateIdSelector } from "../../../../model/selectors";
import { CommonSubSectionsListView } from "./ui/CommonSubSectionsListView/CommonSubSectionsListView";
import { LocaleStateSubSectionsListView } from "./ui/LocaleStateSubSectionsListView/LocaleStateSubSectionsListView";

import { EEstimateStatesIds } from "../../../../../ProHandler/enums";

import { useDefineActiveSection } from "./hooks/useDefineActiveSection";

/**
 * Список подразделов
 * В зависимости от активного стейта имеет разные виды
 */
export const SubSectionsList = memo(() => {
  const estimateStateId = useSelector(estimateStateIdSelector);

  useDefineActiveSection();

  if (estimateStateId === EEstimateStatesIds.LOCALE) {
    return <LocaleStateSubSectionsListView />;
  } else {
    return <CommonSubSectionsListView />;
  }
});

SubSectionsList.displayName = "SubSectionsList";
