import { noop } from "lodash";
import React, { ReactNode, SetStateAction, useMemo, useState } from "react";

import { TableOslaLocal } from "_LEGACY/UI/_LEGACY_TableOslaCustom/TableOslaLocal";
import { TableHeader } from "_LEGACY/UI/_LEGACY_TotoRowTable/TableHeader/TableHeader";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";

import ButtonBase from "shared/ui/controls/ButtonBase";
import Checkbox from "shared/ui/inputs/Checkbox/Checkbox";
import { InputSearchRound } from "shared/ui/inputs/InputSearchRound/InputSearchRound";
import SliderModal, { IBaseModalProps } from "shared/ui/modal/SliderModal/SliderModal";

import { IEmployee } from "types/personsTypes";

import styles from "./EmployeeModal.module.scss";

interface IProps extends IBaseModalProps {
  title?: ReactNode;
  buttonText?: ReactNode;
  employees: IEmployee[];
  selectedIds: number[];
  setSelectedIds: React.Dispatch<SetStateAction<number[]>>;
  isMultiSelect?: boolean;
  onSubmit: () => void;
}

const EmployeeModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  title = "Сотрудники",
  buttonText = "Добавить",
  employees,
  selectedIds,
  isMultiSelect,
  setSelectedIds,
  onSubmit,
}) => {
  const [search, setSearch] = useState("");

  const onRowClick = (row: IEmployee) => {
    if (isMultiSelect) {
      //TODO
      //setSelectedIds((prev) => prev);
    } else {
      if (selectedIds.includes(row.id)) {
        setSelectedIds([]);
      } else {
        setSelectedIds([row.id]);
      }
    }
  };

  const displayingEmployees = useMemo(() => {
    const searchLowerCase = search.toLowerCase();
    return employees?.filter((el) => {
      return (
        el.first_name.toLowerCase().includes(searchLowerCase) || el.last_name.toLowerCase().includes(searchLowerCase)
      );
    });
  }, [employees, search]);

  const checkboxFormatter = (_: unknown, row: IEmployee) => {
    return (
      <div>
        <Checkbox onCheck={() => onRowClick(row)} checked={selectedIds.includes(row.id)} />
      </div>
    );
  };

  return (
    <SliderModal isOpen={isOpen} closeHandler={onClose} className={styles.slider}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div className={styles.searchBlock}>
          <InputSearchRound
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
            className={styles.inputSearchClassName}
          />
          <ButtonBase primary onClick={onSubmit} disabled={!selectedIds.length}>
            {buttonText}
          </ButtonBase>
        </div>
      </div>

      <div className={styles.tableWrapper}>
        <TableOslaLocal
          data={displayingEmployees}
          keyProp={"id"}
          onRowClick={onRowClick}
          style={{ userSelect: "none" }}
          pagination={false}
        >
          <TableHeader formatter={checkboxFormatter} cellStyle={{ width: "10%" }}>
            {/* {!returnOneData && (
              <Checkbox
              //TODO
                onChange={usedEmployees?.length > 0 && this.massCheckHandler}
                checked={usedEmployees?.length > 0 && usedEmployees.every((item) => this.isChecked(item))}
              />
            )} */}
          </TableHeader>
          <TableHeader
            cellStyle={{ width: "45%" }}
            formatter={nameFormatter}
            style={{ whiteSpace: "nowrap", fontWeight: "500", fontSize: "1rem", color: "#000" }}
          >
            Сотрудник
          </TableHeader>
          <TableHeader
            cellStyle={{ width: "45%" }}
            data={"position"}
            style={{ whiteSpace: "nowrap", fontWeight: "500", fontSize: "1rem", color: "#000" }}
            formatter={positionFormatter}
          >
            Должность
          </TableHeader>
        </TableOslaLocal>
      </div>
    </SliderModal>
  );
};

export default EmployeeModal;

function nameFormatter(_: unknown, row: IEmployee) {
  return <div className={styles.cell}>{row.last_name + " " + row.first_name + " " + row.middle_name}</div>;
}

function positionFormatter(_: unknown, row: IEmployee) {
  return (
    row.roles?.map((type, index) => (
      <div className={styles.cell} key={index}>
        {type.name}
      </div>
    )) || ""
  );
}
