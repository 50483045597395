import classNames from "classnames";
import React, { useMemo, useState } from "react";

import SignatorElem from "../SignatorElem/SignatorElem";

import { ISignator, SignerTypes } from "../../model/types";

import getShortFullName from "utils/formatters/getShortFullName";

import styles from "./SignatorsRow.module.scss";

interface IProps {
  title: string;
  onClick: (type: SignerTypes) => void;
  types: SignerTypes[];
  signers: ISignator[] | null;
  objectId: string;
}

const SignatorsRow: React.FC<IProps> = ({ title, onClick, types, signers, objectId }) => {
  const [expanded, setExpanded] = useState(false);

  const thisSignersLength = useMemo(() => {
    return signers?.filter((el) => types.includes(el.type))?.length ?? 0;
  }, [types, signers]);

  const thisSigners = useMemo(() => {
    return types.map((type) => {
      const signer = signers?.find((el) => el.type === type);
      return {
        type,
        signer,
      };
    });
  }, [types, signers]);

  return (
    <>
      <div
        className={classNames(styles.header, { [styles.expanded]: expanded })}
        onClick={() => setExpanded((p) => !p)}
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 5L8.00002 11L14.5 5" stroke="#EBEBEB" strokeWidth="3" />
        </svg>
        <div className={styles.title}>
          {title}: {thisSignersLength}/{types.length}
        </div>
      </div>
      {expanded && (
        <div className={styles.content}>
          {thisSigners.map((el) => (
            <SignatorElem
              key={el.type}
              name={el?.signer?.first_name ? getShortFullName(el.signer) : "Не указан"}
              onClick={() => onClick(el.type)}
              type={el.type}
              isEmpty={!el.signer}
              objectId={objectId}
              id={el.signer?.id}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default SignatorsRow;
