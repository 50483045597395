import { ADD_FILE, DELETE_FILE, GET_FILES_REQUEST, GET_FILES_SUCCESS, RESET_TO_INITIAL } from "./constants";

const initialState = {
  isLoading: true,
  list: []
};

const filesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILE:
      return { ...state, list: [...state.list, action.payload] };
    case DELETE_FILE:
      return { ...state, list: state.list.filter(item => item.id !== action.payload) };
    case GET_FILES_REQUEST:
      return { ...state, isLoading: true };
    case GET_FILES_SUCCESS:
      return { ...state, isLoading: false, list: action.payload };
    case RESET_TO_INITIAL:
      return initialState;
    default:
      return state;
  }
};

export default filesReducer;