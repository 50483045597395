import { useQueryParams } from "../../../../../../../utils/hooks/useQueryParams";

export const useOpenFactApprove = () => {
  const isNeedOpenFactApprove = useQueryParams('openFactApprove');
  const openSectionId = useQueryParams('sectionId');
  const openFactId = useQueryParams('fact_id');
  const openFactType = useQueryParams('fact_type') as "work" | "group" | null;
  const openYear = useQueryParams('year');
  const openMonth = useQueryParams('month');

  const needOpenFact = !!(isNeedOpenFactApprove
    && openSectionId
    && openFactId
    && openFactType
    && openYear
    && openMonth);

  return {
    needOpenFact,
    openSectionId,
    openFactId,
    openFactType,
    openYear,
    openMonth
  }
}
