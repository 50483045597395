import React, { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";

import OpeningInfo from "../../../../../_LEGACY/UI/_LEGACY_Purchases_components/OpeningInfo";
import StatusFormatter from "../../../../../_LEGACY/UI/_LEGACY_Purchases_components/StatusFormatter/StatusFormatter";
import ProviderContacts from "./components/ProviderContacts/ProviderContacts";

import { NavLinkIcon } from "../../../../../shared/ui/controls/NavLinkIcon/NavLinkIcon";

import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { getLocalizedDate } from "../../../../../utils/formatters/getLocalizedDate";

import calendarBlueIcon from "../../../../../images/icons/calendarBlue.svg";

import styles from "./index.module.scss";

//@ts-ignore
const Info = ({ order, isSimplified }) => {
  const urlPrefix = useUrlModuleWithObjectId();

  return (
    <div className={styles.info}>
      <div className={styles.title}>Заказ №{order.number}</div>
      <div className={styles.mainInfo}>
        <div className={styles.mainInfoBlock}>
          <div>от:</div>
          <div>
            {getLocalizedDate(order.created_at)}
            <img className={styles.calendarIcon} src={calendarBlueIcon} alt="Иконка календаря" />
          </div>
        </div>
        {!isSimplified && (
          <>
            <div className={styles.mainInfoBlock}>
              <div>заявка:</div>
              <NavLinkIcon
                to={`/${urlPrefix}/requisitions/in-work/${order.requisition.id}`}
                title={`№ ${order.requisition.number || "ожидается"}`}
              />
            </div>
            {order.invoice && (
              <div className={styles.mainInfoBlock}>
                <div>счет:</div>
                <NavLinkIcon
                  to={`/${urlPrefix}/documents/bills/${order.invoice.id}`}
                  title={`№ ${order.invoice.number || "ожидается"}`}
                />
              </div>
            )}
          </>
        )}
      </div>
      <Grid>
        <Row>
          <Item>
            <div className={styles.titleRow}>Статус:</div>
            <div>{StatusFormatter(order.status_name, { flow: "in" })}</div>
          </Item>
          <Item>
            <div className={styles.titleRow}>Заказчик:</div>
            <div>
              <OpeningInfo info={order.executor_purchaser} title="Контакты заказчика" />
            </div>
          </Item>
        </Row>
        <Row>
          <Item>
            <div className={styles.titleRow}>Поставщик:</div>
            <div>
              <ProviderContacts orderId={order.id} info={order.executor_provider} />
            </div>
          </Item>
          {!isSimplified && order.packinglists && order.packinglists.length !== 0 && (
            <Item>
              <div className={styles.titleRow}>УПД:</div>
              <div>
                {order.packinglists.map((packingList: any) => (
                  <NavLinkIcon
                    to={`/${urlPrefix}/documents/packing-lists/${packingList.id}`}
                    title={`${packingList.number ? `УПД № ${packingList.number}` : "УПД"}`}
                    key={packingList.id}
                  />
                ))}
              </div>
            </Item>
          )}
        </Row>
        <Row>
          <Item>
            <div className={styles.titleRow}>Адрес доставки:</div>
            <div>{order.building?.address || "Не указан"}</div>
          </Item>
          {!isSimplified && order.acts && order.acts.length !== 0 && (
            <Item>
              <div className={styles.titleRow}>Акт:</div>
              <div>
                {order.acts.map((act: any) => (
                  <NavLinkIcon
                    to={`/${urlPrefix}/documents/service-acts/${act.id}`}
                    title={`${act.number ? `Акт № ${act.number}` : "Акт"}`}
                    key={act.id}
                  />
                ))}
              </div>
            </Item>
          )}
        </Row>
      </Grid>
    </div>
  );
};

export default React.memo(Info);

function Grid({ children }: PropsWithChildren) {
  return <div className={styles.grid}>{children}</div>;
}

function Row({ children }: PropsWithChildren) {
  return <div className={styles.row}>{children}</div>;
}

function Item({ children }: PropsWithChildren) {
  return <div className={styles.item}>{children}</div>;
}
