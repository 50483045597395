import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  checkedItemsSelector,
  confirmedSectionsSelector,
  confirmedSubSectionsSelector,
  estimateStateIdSelector,
  expendituresSelector,
  sectionsSelector,
  subSectionsSelector,
} from "../../../model/selectors";
import { simpleHandlerActions } from "../../../model/slice";

import { EEstimateStatesIds } from "../../../../ProHandler/enums";
import { EHandlerLevels } from "../../../types";

import { useHandlerLevel } from "../../../hooks/useHandlerLevel";

export const useCheckAll = () => {
  const dispatch = useDispatch();

  const level = useHandlerLevel();
  const sections = useSelector(sectionsSelector);
  const confirmedSections = useSelector(confirmedSectionsSelector);
  const confirmedSubSections = useSelector(confirmedSubSectionsSelector);

  const subSections = useSelector(subSectionsSelector);
  const expenditures = useSelector(expendituresSelector);
  const checkedItems = useSelector(checkedItemsSelector);

  const activeEstimateStateId = useSelector(estimateStateIdSelector);

  const isAllChecked = useMemo(() => {
    const isAllSectionsChecked = level === EHandlerLevels.SECTIONS && checkedItems.length === sections?.length;

    const isAllConfirmedSectionsChecked =
      level === EHandlerLevels.SECTIONS &&
      activeEstimateStateId === EEstimateStatesIds.LOCALE &&
      checkedItems.length === confirmedSections?.length;

    const isAllSubSectionsChecked = level === EHandlerLevels.SUBSECTIONS && checkedItems.length === subSections?.length;

    const isAllConfirmedSubSectionsChecked =
      level === EHandlerLevels.SECTIONS &&
      activeEstimateStateId === EEstimateStatesIds.LOCALE &&
      checkedItems.length === confirmedSubSections?.length;

    const isAllExpendituresChecked =
      level === EHandlerLevels.EXPENDITURES && checkedItems.length === expenditures?.length;

    return (
      isAllSectionsChecked ||
      isAllSubSectionsChecked ||
      isAllExpendituresChecked ||
      isAllConfirmedSectionsChecked ||
      isAllConfirmedSubSectionsChecked
    );
  }, [level, sections, subSections, expenditures, checkedItems, activeEstimateStateId]);

  const isIndeterminate = useMemo(() => {
    return !!(checkedItems.length && !isAllChecked);
  }, [checkedItems, isAllChecked]);

  const onCheckboxClick = useCallback(() => {
    if (isAllChecked) {
      dispatch(simpleHandlerActions.clearCheckedItems());
    } else {
      if (activeEstimateStateId !== EEstimateStatesIds.PRODUCTION) {
        dispatch(simpleHandlerActions.checkAllItems(level));
      }
    }
  }, [isAllChecked, level, activeEstimateStateId]);

  const checked = !!checkedItems.length;

  return {
    isAllChecked,
    isIndeterminate,
    onCheckboxClick,
    checked,
  };
};
