import { useCallback, useState } from "react";

export interface IUseConfirmationModalProps {
  onRemoveDirectly?: () => void;
  onRemove?: () => void;
  onOpenConfirmModal?: () => void;
}

export const useConfirmationModal = ({
  onRemoveDirectly,
  onRemove,
  onOpenConfirmModal,
}: IUseConfirmationModalProps) => {
  const [isOpenRemoveConfirmationModal, setIsOpenRemoveConfirmationModal] = useState(false);

  const openRemoveConfirmationModal = useCallback(() => {
    if (onRemoveDirectly) {
      onRemoveDirectly();
    } else {
      onOpenConfirmModal?.();
      setIsOpenRemoveConfirmationModal(true);
    }
  }, []);
  const closeRemoveConfirmationModal = useCallback(() => setIsOpenRemoveConfirmationModal(false), []);

  const confirmRemove = useCallback(() => {
    if (onRemove) onRemove();
    closeRemoveConfirmationModal();
  }, [onRemove, closeRemoveConfirmationModal]);

  return {
    openRemoveConfirmationModal,
    isOpenRemoveConfirmationModal,
    confirmRemove,
    closeRemoveConfirmationModal,
  };
};
