import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { dropNonSignificantZeros } from "../../../../../utils/formatters/dropNonSignificantZeros";
import {
  parseTransformedNumber,
  parseTransformedString,
  transformDigitToFinancial,
} from "utils/formatters/transformDigitToFinancial";

/* @ts-ignore */
const formatPercentValue = (value) =>
  //@ts-ignore
  transformDigitToFinancial(parseTransformedString(value), { withFloat: true, dropZeros: true, log: true });
/* @ts-ignore */
const useClosureDatesDoubleInput = ({ amountSource, discountCost, discountPercent, editClosureDate }) => {
  const [changedDiscountCost, setChangedDiscountCost] = useState(
    transformDigitToFinancial(discountCost, { withFloat: true, dropZeros: true }) || "0"
  );
  const [changedDiscountPercent, setChangedDiscountPercent] = useState(() =>
    dropNonSignificantZeros(discountPercent, 8)
  );

  const onChangeDiscountPercent = useCallback(
    /* @ts-ignore */
    (value) => {
      let valueWithoutPercent = parseTransformedNumber(value); /* @ts-ignore */
      if (isNaN(+valueWithoutPercent) || +valueWithoutPercent >= 101 || +valueWithoutPercent < 0) return;

      const isBadLastSign = [".", "0"].includes(value?.at(-1));
      if (isBadLastSign) {
        setChangedDiscountPercent(value);
        editClosureDate({ user_percent: value });
      } else {
        const procVal = Number(value);
        if (!isNaN(procVal)) {
          const newFormattedPercent = dropNonSignificantZeros(procVal, 8);
          setChangedDiscountPercent(newFormattedPercent);
          //@ts-ignore
          editClosureDate({ user_percent: newFormattedPercent.toString() });
        }
      }
      /* @ts-ignore */
      const beatifiedNewDiscountCost = transformDigitToFinancial((valueWithoutPercent * +amountSource) / 100, {
        withFloat: true,
        dropZeros: true,
      });
      setChangedDiscountCost(beatifiedNewDiscountCost);
    },
    [amountSource]
  );

  const onChangeDiscountCost = useCallback(
    /* @ts-ignore */
    (value) => {
      /* @ts-ignore */
      let beautifiedValue = transformDigitToFinancial(parseTransformedString(value), {
        withFloat: true,
        dropZeros: true,
      });
      const parsedNumber = parseTransformedNumber(beautifiedValue);
      /* @ts-ignore */
      if (isNaN(+parsedNumber) || +parsedNumber >= amountSource + 1 || +parsedNumber < 0) {
        return;
      }

      const isPointLastSign = value?.at(-1) === ".";
      if (isPointLastSign) {
        setChangedDiscountCost(beautifiedValue);
      } else {
        //@ts-ignore
        const procVal = Number(beautifiedValue?.replaceAll(" ", ""));
        if (!isNaN(procVal)) {
          setChangedDiscountCost(beautifiedValue);
        } else {
          setChangedDiscountCost("0");
        }
      }
      /* @ts-ignore */
      const newPercentProc = (+parsedNumber * 100) / amountSource;
      if (!isNaN(newPercentProc)) {
        const newFormattedPercent = dropNonSignificantZeros(newPercentProc, 8);
        setChangedDiscountPercent(newFormattedPercent);
        editClosureDate({ user_percent: newFormattedPercent });
      }
    },
    [amountSource]
  );

  return { changedDiscountCost, changedDiscountPercent, onChangeDiscountPercent, onChangeDiscountCost };
};

export default useClosureDatesDoubleInput;
