import cn from "classnames";
import React, { PropsWithChildren, useState } from "react";

import styles from "./HideLine.module.scss";

export interface IHideLineProps extends PropsWithChildren {
  contentClassName?: string;
  containerClassName?: string;
  containerOpenClassName?: string;
  buttonClassName?: string;
  buttonOpenClassName?: string;
  outerIsOpen?: boolean;
  setOuterIsOpen?: (_: boolean) => void;
}

const HideLine: React.FC<IHideLineProps> = ({
  contentClassName,
  containerClassName,
  children,
  containerOpenClassName,
  buttonClassName,
  buttonOpenClassName,
  outerIsOpen,
  setOuterIsOpen,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(outerIsOpen !== undefined ? outerIsOpen : true);

  const toggleOpen = () => {
    setOuterIsOpen?.(!isOpen);
    setIsOpen((prev) => !prev);
  };

  return (
    <div
      className={cn(
        styles.container,
        { [styles.open]: isOpen, [containerOpenClassName || ""]: isOpen },
        containerClassName
      )}
    >
      <button
        onClick={toggleOpen}
        className={cn(styles.btn, { [styles.activeBtn]: isOpen, [buttonOpenClassName || ""]: isOpen }, buttonClassName)}
      >
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.592072 9L6.13306 4.5L0.592072 0" fill="black" />
        </svg>
      </button>
      <div className={cn(styles.content, contentClassName)}>{children}</div>
    </div>
  );
};

export default React.memo(HideLine);
