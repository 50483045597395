import { IUiIndicatorsAction } from "./actions";

import { MODULES_ENUM } from "../../../../types/enums/ModulesEnum";
import { IUiIndicatorsInitialState } from "./types";

import { pushIndicator, removeIndicators, setIndicators } from "./utils";

const initialIndicatorsInState = () => ({ indicators: [], indicatorsCount: 0 });

const initialState: IUiIndicatorsInitialState = {
  isLoading: false,
  invalidateKey: -1,
  [MODULES_ENUM.CONSTRUCTING]: initialIndicatorsInState(),
  [MODULES_ENUM.OBJECTS]: {},
  [MODULES_ENUM.PURCHASES]: initialIndicatorsInState(),
  [MODULES_ENUM.TASKS]: initialIndicatorsInState(),
};

export default (state = initialState, action: IUiIndicatorsAction): IUiIndicatorsInitialState => {
  const { payload, type } = action;

  switch (type) {
    case "uiIndicators/SET_INDICATORS":
      return setIndicators(state, payload.indicators, payload.count, payload.module, payload.buildingId);
    case "uiIndicators/SET_INDICATORS_LOADING":
      return {
        ...state,
        isLoading: payload,
      };
    case "uiIndicators/DELETE_INDICATOR":
      return removeIndicators(state, payload.indicatorId, payload.modules, payload.buildingId);
    case "uiIndicators/PUSH_INDICATOR":
      return pushIndicator(state, payload);
    case "uiIndicators/INVALIDATE_KEY":
      return { ...state, invalidateKey: payload };
    default:
      return state;
  }
};
