import { IAnalyticsSelectedDates, IAnalyticsSelectedScope } from "./types";

export const constructAnalyticsParams = ({
  currentSelectedScope,
  currentSelectedDates,
}: {
  currentSelectedScope: IAnalyticsSelectedScope;
  currentSelectedDates: IAnalyticsSelectedDates;
}) => {
  const base = {
    building_id: currentSelectedScope.project,
    section_id: currentSelectedScope.section || currentSelectedScope.lsr || undefined,
  };
  if (currentSelectedDates.allProject === 1) return { ...base, all: 1 };
  return { ...base, start_at: currentSelectedDates.start_at, end_at: currentSelectedDates.end_at };
};

export const makeProjectDatesKey = (objectId: number, sectionId: number | null) =>
  !!sectionId ? `${objectId}_${sectionId}` : `${objectId}`;
