import React from 'react'
import styles from "./SubTableHeader.module.scss"
import cn from "classnames"

function SubTableHeader() {
    return (
        <div className={styles.tableHeader}>
            <div className={cn(styles.rowItem, styles.fieldNumber)}>
                <div className={styles.dot}></div>
            </div>
            <div className={cn(styles.rowItem, styles.fieldTitle)}>
                <div className={styles.title}>Номенклатура</div>
            </div>
            <div className={cn(styles.rowItem, styles.center)}>
                Ед. изм
            </div>
            <div className={cn(styles.rowItem, styles.center)}>
                Количество
            </div>
            <div className={cn(styles.rowItem, styles.center)}>
                Стоимость
            </div>
        </div>
    )
}

export default SubTableHeader