import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { sectionsLoadingByKeySelector, sectionsSelector } from "redux/modules/common/building/sections/sections";

import { IBuildingPropsPermissions } from "components/pages/Building/Building";
import Section from "components/pages/Building/components/Section/Section";

import Spinner from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import ISection from "types/interfaces/Section";

import estimateIcon from "images/icons/navigation/estimateIcon.svg";

interface IProps {
  permissions: IBuildingPropsPermissions;
  endOfMonthFormattedDate: string;
  startOfMonthFormattedDate: string;
}

const ConstructionControlObjectSections: React.FC<IProps> = ({
  permissions,
  endOfMonthFormattedDate,
  startOfMonthFormattedDate,
}) => {
  const history = useHistory();
  const { tab, objectId, projectId } = useParams<any>();
  const sectionsFromSelector: ISection[] = useSelector(sectionsSelector);
  const rawSectionsAreLoading: boolean | undefined = (useSelector(sectionsLoadingByKeySelector) as any)?.[
    objectId as string
  ];
  const sectionsAreLoading = rawSectionsAreLoading === undefined ? true : rawSectionsAreLoading;

  const sections = useMemo(() => {
    return (sectionsFromSelector || []).filter((el) => {
      if (el.name === "Вне сметы") return true;
      return !!el.subsection_count;
    });
  }, [sectionsFromSelector]);

  return (
    <>
      {!sectionsAreLoading && !sections?.length && <EmptyPlaceholder img={estimateIcon} />}
      {sectionsAreLoading && <Spinner />}
      {sections?.map((section, index) => {
        const canShareSection = false; //canSharing && !section.providers?.length;
        if (section.hidden_status === 1) return;
        return (
          <div
            key={section.id}
            onClick={() => {
              history.push(`/construction-control/projects/${projectId}/object/${objectId}/${tab}/${section.id}/`);
            }}
          >
            {/* @ts-expect-error */}
            <Section
              section={section}
              canSharing={canShareSection}
              canCheck={false}
              activeTab={tab}
              filters={{}}
              dateEnd={endOfMonthFormattedDate}
              dateStart={startOfMonthFormattedDate}
              permissions={permissions}
              canAddPlan={false}
              canViewPlanData={tab === "plan"}
              refetchLsrPlan={() => {}}
            />
          </div>
        );
      })}
    </>
  );
};

export default ConstructionControlObjectSections;
