import { message } from "antd";
import cn from "classnames";
import moment from "moment";
import React, { MouseEvent, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  IJournalAcceptanceTicketItem,
  IJournalAcceptanceTickets,
} from "redux/modules/common/building/journal/acceptance/types";
import { journalGroupDetailInfoSelector } from "redux/modules/common/building/journal/selectors";
import { confirmGroup, getGroupDetailInfo, patchTicket } from "redux/modules/common/building/journal/thunks";

import ApprovingCell from "./components/ApprovingCell/ApprovingCell";
import JournalAcceptCountCell from "./components/JournalAcceptCountCell/JournalAcceptCountCell";
import RemarksCell from "./components/RemarksCell/RemarksCell";
import MatchingIcon, { MatchingStatusesEnum } from "_LEGACY/UI/MatchingIcon/MatchingIcon";
import AddRelationToProduct from "components/UI/_TODO/AddRelationToProduct";
import ExpenditureGroupModal from "components/UI/_TODO/ExpenditureGroupModal/ui/ExpenditureGroupModal";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import BlueLabel from "../../../../../../../shared/ui/dataDisplay/BlueLabel/BlueLabel";
import JournalTicketModal from "../../JournalTicketModal/JournalTicketModal";
import JournalTIcketAccept from "../JournalTicketAccept/JournalTIcketAccept";
import { IMatchingInfo } from "../JournalTickets/JournalTickets";
import ProductInfo from "../ProductInfo";
import { useCheckIsConstructingControl } from "features/constructingControl";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { ExpenditureTypeEnum } from "../../../../../../../types/enums/ExpenditureTypeEnum";
import { DEFAULT_ENTITY_ID } from "../../constants";
import { ITicketApproval, ITicketJournalDelivery } from "types/interfaces/Tickets";

import { useOpenFactApprove } from "../../JournalTicketModal/hooks/useOpenFactApprove";

import getShortFullName from "utils/formatters/getShortFullName";

import styles from "./JournalDeliveryRow.module.scss";

type TicketAccepting = { isModalOpen: boolean; approvals: ITicketApproval[] };
export interface IJournalDeliveryRowProps {
  ticket: IJournalAcceptanceTicketItem;
  haveApproveTicketPermission: boolean;
  currentUserId: number;
  approve: (expenditureId: number, isGroup: boolean) => void;
  accept: (ticket: any, count: string) => void;
  canAcceptTicketWithoutApprove?: boolean;
  hideApprovals: boolean;
  sectionId: number;
  contractorId?: number;
}

const JournalDeliveryRow: React.FC<IJournalDeliveryRowProps> = ({
  ticket,
  haveApproveTicketPermission,
  currentUserId,
  approve,
  accept,
  canAcceptTicketWithoutApprove,
  hideApprovals,
  sectionId,
  contractorId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const isGroup = ticket?.type === "group";
  const ticketItem = ticket?.expenditure ?? ticket?.group;
  const itemId = (ticketItem as any)?.expenditure_id ?? (ticketItem as any)?.group_id;
  const noApprovals = Number(ticketItem?.total_approvals) < Number(ticketItem?.confirmed_approvals);
  const isOutOfEstimate = ticket?.expenditure?.is_default || !ticket?.expenditure?.expenditure_id;
  const isShared = !!contractorId && contractorId !== DEFAULT_ENTITY_ID;

  const getTicketMatchingStatus = (item: typeof ticketItem) => {
    if (isGroup) return MatchingStatusesEnum.group;
    if (isOutOfEstimate) return MatchingStatusesEnum.outOfEstimate;
    if (!ticket?.expenditure?.is_after_related) return MatchingStatusesEnum.auto;
    return MatchingStatusesEnum.manual;
  };

  const onApprove = useCallback(() => {
    if (ticketItem?.count_remarks_in_work !== 0) {
      message.error("Необходимо устранить замечания");
      return;
    }
    approve(ticketItem?.id, !!isGroup);
  }, [approve, isGroup, ticketItem?.id, ticketItem?.count_remarks_in_work]);

  const [isModalOpened, setIsModalOpened] = React.useState(false);
  const [isProductInfo, setIsProductInfo] = React.useState(false);
  const isDirectlyCantEditInProductInfo = !ticketItem?.is_after_related;
  const [isGroupInfo, setIsGroupInfo] = React.useState(false);
  const groupDetailInfo = useSelector(journalGroupDetailInfoSelector);

  const matchingClickHandler = (event?: MouseEvent) => {
    event?.stopPropagation();
    if (isGroup) {
      dispatch(getGroupDetailInfo(objectId, (ticket.group as any)?.group_id));
      setIsGroupInfo(true);
    } else {
      if (isOutOfEstimate) {
        setIdAddRelation({ id: ticket?.expenditure?.id!, editable: true });
      } else {
        setIsProductInfo(true);
      }
    }
  };

  const [idAddRelation, setIdAddRelation] = React.useState<IMatchingInfo | null>(null);
  const bindHandler = (objectId: string, idAddRelation: any) => {
    return (inner_id: number) => {
      dispatch(patchTicket(objectId, idAddRelation, { expenditure_id: inner_id }, undefined, "Продукт сопоставлен"));
    };
  };

  const [isAcceptingModal, setIsAcceptingModal] = React.useState(false);
  const [ticketAccepting, setTicketAccepting] = React.useState<TicketAccepting>({ isModalOpen: false, approvals: [] });
  const handleAcceptTicket = () => {
    isGroup
      ? dispatch(confirmGroup(objectId, ticketItem.id, { confirmed_count: (ticketAccepting as any).count }))
      : dispatch(patchTicket(objectId, ticketItem.id, { count_accepted_work: (ticketAccepting as any).count }));
    setIsAcceptingModal(false);
  };

  const onAccept = useCallback(
    (count: string) => {
      if (!ticketItem) return;
      setIsAcceptingModal(true);
      setTicketAccepting({ isModalOpen: true, ...ticketItem, count: isShared ? ticketItem.count : count } as any);
    },
    [itemId, ticketItem, isShared]
  );

  const isFullyAproved = !ticketItem.total_approvals || ticketItem.total_approvals === ticketItem.confirmed_approvals;

  const { needOpenFact, openFactId } = useOpenFactApprove();

  useEffect(() => {
    /* @ts-ignore */
    if (needOpenFact && +openFactId === +ticketItem.id) {
      setIsModalOpened(true);
    }
  }, [needOpenFact, openFactId, ticketItem.id]);

  const handleTicketModalClose = useCallback((withReplace = false) => {
    setIsModalOpened(false);
    history.replace({ search: "" });
  }, []);

  return (
    <>
      <TableReusableRow
        className={cn(styles.row, { [styles.noApprovals]: hideApprovals })}
        onClick={() => setIsModalOpened(true)}
        isHoverable={false}
        containerClassName={styles.rowContainer}
      >
        <>
          <TableReusableCell isNoBreak>{String(ticket.number)}</TableReusableCell>
          <TableReusableCell>
            <div
              className={cn(styles.roundLabel, {
                [styles.redLabel]: !ticketItem?.is_confirmed,
              })}
            ></div>
          </TableReusableCell>
          <TableReusableCell className={styles.nameCell}>
            <div className={styles.titleContainer}>
              <div className={styles.title} title={ticketItem?.name}>
                {ticketItem?.name}
              </div>
              {isGroup && (
                <BlueLabel onClick={matchingClickHandler} className={styles.blueLabel} blueFont={undefined}>
                  {`Расценок: ${
                    (ticketItem as IJournalAcceptanceTickets["intervals"][0]["group"])?.count_expenditures
                  }`}
                </BlueLabel>
              )}
            </div>
            <MatchingIcon
              className={styles.matching}
              onClick={matchingClickHandler}
              status={getTicketMatchingStatus(ticketItem)}
            />
            <div className={styles.divider}></div>
          </TableReusableCell>
          <TableReusableCell isNoBreak>
            {getShortFullName(ticketItem?.author)}
            <div className={styles.divider}></div>
          </TableReusableCell>
          <TableReusableCell>
            {moment(ticketItem?.created_at).format("DD.MM.YY")}
            <div className={styles.divider}></div>
          </TableReusableCell>
          <TableReusableCell isNoBreak>
            <span>{`${ticketItem?.count} ${ticketItem?.measure || ""}`}</span>
            <div className={styles.divider}></div>
          </TableReusableCell>
          {!hideApprovals && (
            <>
              <TableReusableCell className={styles.centered}>
                {ticketItem?.total_approvals !== 0 && (
                  <>
                    <RemarksCell ticket={ticket} isGroup={!!isGroup} isShared={isShared} />
                    <div className={styles.divider}></div>
                  </>
                )}
              </TableReusableCell>
              <TableReusableCell className={styles.centered}>
                {ticketItem?.total_approvals !== 0 && (
                  <ApprovingCell
                    isCurrentUserApprove={ticketItem?.user_approval}
                    currentUserId={currentUserId}
                    approve={onApprove}
                    isGroup={isGroup}
                    ticketId={ticketItem?.id!}
                    totalApproves={ticketItem?.total_approvals}
                    confirmedApproves={ticketItem?.confirmed_approvals}
                  />
                )}
                <div className={styles.divider}></div>
              </TableReusableCell>
            </>
          )}

          {(isFullyAproved || canAcceptTicketWithoutApprove) && (
            <TableReusableCell isCentered={isShared}>
              <JournalAcceptCountCell
                haveApproveTicketPermission={haveApproveTicketPermission}
                ticket={ticket}
                objectId={objectId}
                isGroup={isGroup}
                onAccept={onAccept}
                isShared={isShared}
              />
            </TableReusableCell>
          )}
        </>
      </TableReusableRow>
      {isModalOpened && (
        <JournalTicketModal
          isOpen={isModalOpened}
          onClose={handleTicketModalClose}
          ticketData={ticket}
          type={ticket.type}
          sectionId={sectionId}
          onApprove={onApprove}
        />
      )}
      <ProductInfo
        isOpen={isProductInfo}
        closeModal={() => setIsProductInfo(false)}
        objectId={objectId}
        chosenMatchingProduct={ticket as any}
        openRelationToProduct={() => {
          setIdAddRelation({ id: ticket?.expenditure?.id!, editable: true });
          setIsProductInfo(false);
        }}
        directlyCantEdit={isDirectlyCantEditInProductInfo}
      />
      {isGroupInfo && groupDetailInfo && (
        <ExpenditureGroupModal
          isOpen={isGroupInfo}
          onClose={() => setIsGroupInfo(false)}
          group={groupDetailInfo}
          isShowButtons={false}
        />
      )}
      {/* @ts-ignore */}
      <AddRelationToProduct
        key={idAddRelation?.id}
        idAddRelation={idAddRelation}
        handleClose={() => setIdAddRelation(false as any)}
        objectId={objectId}
        onSubmit={bindHandler(objectId!, idAddRelation?.id) as any}
        updateProducts={undefined as any}
        type={undefined}
        typeForFilter={ExpenditureTypeEnum.work}
      />
      <JournalTIcketAccept
        isOpen={isAcceptingModal}
        ticketId={itemId}
        onAccept={handleAcceptTicket}
        onClose={() => setIsAcceptingModal(false)}
      />
    </>
  );
};

export default React.memo(JournalDeliveryRow);
