import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { projectsV2IsLoadingSelector, projectsV2ListSelector } from "pages/Projects/model/selectors";
import { getProjectsList } from "pages/Projects/model/thunks";

import { getConstructionControlObjects } from "../ConstructionControlProjects/model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const useConstructionControlRedirectToObject = () => {
  const { objectId, projectId } = useParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();

  const projectsKey = generateStorageKey({ projectId: 0 } as any);
  const isProjectsLoading = useSelector(projectsV2IsLoadingSelector)[projectsKey];
  const projects = useSelector(projectsV2ListSelector)[projectsKey];

  useEffect(() => {
    dispatch(getProjectsList({}));
  }, []);

  const objects = useTypedSelector((state) => state.constructionControlProjectsReducer.objects)[projectId];
  const isLoadingObjects = useTypedSelector((state) => state.constructionControlProjectsReducer.loadings)[projectId];
  useEffect(() => {
    dispatch(getConstructionControlObjects(projectId));
  }, [projectId]);

  useEffect(() => {
    //pushing to first available object
    if (objectId !== "0") return;
    if (!projects?.length) return;
    const projectIdCandidate = projectId !== "0" ? projectId : projects[0].id;
    if (!objects?.length) {
      history.push(`/construction-control/journal/${projectIdCandidate}/0/acceptance`);
      return;
    }
    const objectIdCandidate = objects[0].building;
    history.push(`/construction-control/journal/${projectIdCandidate}/${objectIdCandidate}/acceptance`);
  }, [projectId, objectId, objects, projects]);

  return {
    showSpinner: isProjectsLoading || isLoadingObjects,
    showPlaceholder: !isProjectsLoading && !isLoadingObjects && !objects?.length,
    objects,
    isLoadingObjects,
    isProjectsLoading,
  };
};
