import { TASK_LISTS_TYPES, TASK_MODAL_TYPES } from "components/pages/Tasks/constants";

import {
  ADD_COMMENT_TO_TASK,
  ADD_FILE_ID_TO_TASK_MODAL_BUFFER,
  ADD_NEW_TASK_BY_CERTAIN_BUILDING,
  ADD_NEW_TASK_BY_COMMON_BUILDINGS_LIST,
  ADD_NEW_TASK_IN_TASKS_LIST,
  CLOSE_TASK_MODAL,
  CREATE_TASKS_LIST,
  DELETE_TASKS_LIST,
  DELETE_TASK_BY_CERTAIN_BUILDING,
  DELETE_TASK_BY_COMMON_BUILDINGS_LIST,
  DELETE_TASK_IN_TASKS_LIST,
  FILTER_TASKS_BY_SECTION,
  OPEN_TASK_MODAL,
  SET_CERTAIN_TASK,
  SET_CERTAIN_TASK_LOADING,
  SET_IS_TASK_SUBMITTED,
  SET_MORE_TASKS_BY_ALL_BUILDINGS,
  SET_MORE_TASKS_IN_LIST,
  SET_TASKS,
  SET_TASKS_BY_ALL_BUILDINGS,
  SET_TASKS_FIELDS,
  SET_TASKS_FILTERS,
  SET_TASKS_IN_LIST,
  SET_TASKS_LOADING,
  SET_TASKS_MORE,
  SET_TASK_COMMENT_STATE,
  SET_TASK_MODAL_FILE_ID_BUFFER,
  SET_TASK_MODAL_PLACEMENT_TYPE,
  SET_TASK_MODAL_TYPE,
  UPDATE_CREATED_TASKS_LIST_ID,
  UPDATE_TASKS_LIST,
  UPDATE_TASK_BY_CERTAIN_BUILDING,
  UPDATE_TASK_BY_COMMON_BUILDINGS_LIST,
  UPDATE_TASK_IN_TASKS_LIST,
} from "./tasks";

import {
  ICertainTaskResponse,
  ITaskComment,
  IcreateTask,
  ItaskResponse,
  IupdateTask,
  TaskPlacementType,
  listOfTaskListsType,
  tasksFieldsType,
  tasksListFilterType,
} from "./types";
import { IListResponseData } from "types/ListResponse";
import { IFile } from "types/interfaces/Files";

export const setTasksByBuilding = (
  tasks: ItaskResponse[],
  count: number,
  type: TASK_LISTS_TYPES,
  building_id: string
) => ({
  type: SET_TASKS,
  payload: {
    tasks,
    count,
    type,
    building_id,
  },
});

export const addMoreTasksByBuilding = (
  tasks: ItaskResponse[],
  count: number,
  type: TASK_LISTS_TYPES,
  building_id: string
) => ({
  type: SET_TASKS_MORE,
  payload: {
    tasks,
    count,
    type,
    building_id,
  },
});

export const setTasksLoading = (status: boolean) => ({
  type: SET_TASKS_LOADING,
  payload: status,
});

export const setTasksByAllBuildings = (
  tasks: ItaskResponse[],
  count: number,
  type: TASK_LISTS_TYPES,
  next: string | null
) => ({
  type: SET_TASKS_BY_ALL_BUILDINGS,
  payload: {
    tasks,
    count,
    type,
    next,
  },
});

export const addMoreTasksByAllBuildings = (
  tasks: ItaskResponse[],
  count: number,
  type: TASK_LISTS_TYPES,
  next: string | null
) => ({
  type: SET_MORE_TASKS_BY_ALL_BUILDINGS,
  payload: {
    tasks,
    count,
    type,
    next,
  },
});

export const setTasksByList = (data: IListResponseData<ItaskResponse>, listId: number) => ({
  type: SET_TASKS_IN_LIST,
  payload: {
    data,
    listId,
  },
});

export const addMoreTasksByList = (data: ItaskResponse[], listId: number) => ({
  type: SET_MORE_TASKS_IN_LIST,
  payload: {
    data,
    listId,
  },
});

export const updateTasksListInfo = (data: listOfTaskListsType) => ({
  type: UPDATE_TASKS_LIST,
  payload: data,
});

export const deleteOneTaskList = (id: number) => ({
  type: DELETE_TASKS_LIST,
  payload: id,
});

export const createOneTaskList = (data: Omit<listOfTaskListsType, "tasks_count">) => ({
  type: CREATE_TASKS_LIST,
  payload: data,
});

export const updateCreatedTaskListId = (oldId: number, newId: number) => ({
  type: UPDATE_CREATED_TASKS_LIST_ID,
  payload: { oldId, newId },
});

export const openTaskModal = ({
  modalType,
  placementType,
  listType,
  id,
  list_id,
}: {
  modalType: TASK_MODAL_TYPES;
  placementType: TaskPlacementType;
  listType?: TASK_LISTS_TYPES;
  id?: number;
  list_id?: number;
}) => ({
  type: OPEN_TASK_MODAL,
  payload: {
    modalType,
    taskId: id,
    placementType,
    listType,
    list_id,
  },
});

export const closeTaskModal = () => ({
  type: CLOSE_TASK_MODAL,
  payload: null,
});

export const setTaskModalType = (newModalType: TASK_MODAL_TYPES) => ({
  type: SET_TASK_MODAL_TYPE,
  payload: newModalType,
});

export const deleteOneTaskInTasksList = (id: number, list_id: number) => ({
  type: DELETE_TASK_IN_TASKS_LIST,
  payload: { id, list_id },
});

export const deleteOneTaskInAllBuildings = (id: number, type: TASK_LISTS_TYPES) => ({
  type: DELETE_TASK_BY_COMMON_BUILDINGS_LIST,
  payload: { id, type },
});

export const deleteOneTaskInCertainBuilding = (id: number, type: TASK_LISTS_TYPES, building_id: number) => ({
  type: DELETE_TASK_BY_CERTAIN_BUILDING,
  payload: { id, type, building_id },
});

export const setCertainTask = (data: ICertainTaskResponse) => ({
  type: SET_CERTAIN_TASK,
  payload: data,
});

export const setCertainTaskLoading = (id: number, status: boolean) => ({
  type: SET_CERTAIN_TASK_LOADING,
  payload: { id, status },
});

export const setTasksFields = (fields: tasksFieldsType) => ({
  type: SET_TASKS_FIELDS,
  payload: fields,
});

export const addNewTaskInTasksList = (data: IcreateTask, list_id: number) => ({
  type: ADD_NEW_TASK_IN_TASKS_LIST,
  payload: { data, list_id },
});

export const addNewTaskInAllBuildings = (data: IcreateTask, type: TASK_LISTS_TYPES) => ({
  type: ADD_NEW_TASK_BY_COMMON_BUILDINGS_LIST,
  payload: { data, type },
});

export const addNewTaskInCertainBuilding = (data: IcreateTask, type: TASK_LISTS_TYPES, building_id: number) => ({
  type: ADD_NEW_TASK_BY_CERTAIN_BUILDING,
  payload: { data, type, building_id },
});

export const updateTaskInTasksList = (data: IupdateTask, list_id: number) => ({
  type: UPDATE_TASK_IN_TASKS_LIST,
  payload: { data, list_id },
});

export const updateTaskInAllBuildings = (data: IupdateTask, type: TASK_LISTS_TYPES, currentUserId: number) => ({
  type: UPDATE_TASK_BY_COMMON_BUILDINGS_LIST,
  payload: { data, type, currentUserId },
});

export const updateTaskInCertainBuilding = (
  data: IupdateTask,
  type: TASK_LISTS_TYPES,
  building_id: number,
  currentUserId: number
) => ({
  type: UPDATE_TASK_BY_CERTAIN_BUILDING,
  payload: { data, type, building_id, currentUserId },
});

export const addCommentToTask = (text: string, task: number, creator_name: string, id: number) => ({
  type: ADD_COMMENT_TO_TASK,
  payload: { text, task, creator_name, id },
});

export const setTaskCommentsState = (task: number, comments: ITaskComment[]) => ({
  type: SET_TASK_COMMENT_STATE,
  payload: { task, comments },
});

export const filterTasksBySectionActionCreator = (
  data: IListResponseData<ItaskResponse>,
  section_id: string,
  building_id: string,
  type: TASK_LISTS_TYPES
) => ({
  type: FILTER_TASKS_BY_SECTION,
  payload: {
    data,
    section_id,
    building_id,
    type,
  },
});
export const addFileIdToTaskModalBuffer = (fileId: number) => ({
  type: ADD_FILE_ID_TO_TASK_MODAL_BUFFER,
  payload: fileId,
});

export const setTaskModalFileIdBuffer = (fileIds: number[]) => ({
  type: SET_TASK_MODAL_FILE_ID_BUFFER,
  payload: fileIds,
});

export const setModalPlacementType = (type: TaskPlacementType) => ({
  type: SET_TASK_MODAL_PLACEMENT_TYPE,
  payload: type,
});

export const setTaskSubmitted = (isSubmitted: boolean, taskId: number) => ({
  type: SET_IS_TASK_SUBMITTED,
  payload: {
    isSubmitted,
    taskId,
  },
});

export const setTaskFilters = (filters: tasksListFilterType) => ({
  type: SET_TASKS_FILTERS,
  payload: filters,
});

export const persistNewTaskFiles = (files: IFile[]) => ({
  type: "tasks/PERSIST_NEW_TASK_FILES",
  payload: files,
});

export const unPersistNewTaskFiles = (fileId: number) => ({
  type: "tasks/UNPERSIST_NEW_TASK_FILE",
  payload: fileId,
});

export const clearPersistedNewTaskFiles = () => ({
  type: "tasks/CLEAR_PERSISTED_NEW_TASK_FILE",
  payload: true,
});
