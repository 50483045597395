import cn from "classnames";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";

import { NavLinkIcon } from "shared/ui/controls/NavLinkIcon/NavLinkIcon";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { IPackingListInList } from "types/interfaces/PackingList";

import { useCheckIsProductionModule } from "../../hooks/useCheckIsProductionModule";
import { useUrlModule } from "utils/hooks/useUrlModule";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./PackingListsRow.module.scss";

interface Iprops {
  packingList: IPackingListInList;
}

const PackingListsRow: React.FC<Iprops> = ({ packingList }) => {
  const history = useHistory();
  const urlAdapter = useUrlModuleWithObjectId();
  const isInProduction = useCheckIsProductionModule();

  const rowClickHandler = () => {
    history.push(`/${urlAdapter}/documents/packing-lists/${packingList.id}`);
  };

  return (
    <TableReusableRow className={cn(styles.row, { [styles.inProduction]: isInProduction })} onClick={rowClickHandler}>
      <TableReusableCell>
        {packingList.number ? sliceTextByConstraint(String(packingList.number), 8) : "Формируется"}
      </TableReusableCell>
      <TableReusableCell>
        <NavLinkIcon
          to={`/${urlAdapter}/orders/${packingList.order.id}`}
          title={sliceTextByConstraint(String(packingList.order.number), 8)}
          className={styles.orderLink}
        />
      </TableReusableCell>
      {!isInProduction && <TableReusableCell isNoBreak>{packingList.building.name}</TableReusableCell>}
      <TableReusableCell isNoBreak>{packingList.provider.name}</TableReusableCell>
      <TableReusableCell isCentered>
        {packingList.created_at ? moment(packingList.created_at).format("DD.MM.YYYY") : "---"}
      </TableReusableCell>
      <TableReusableCell isRighted>{transformDigitToFinancial(packingList.amount_total)}</TableReusableCell>
      <TableReusableCell>{packingList.status_name}</TableReusableCell>
    </TableReusableRow>
  );
};

export default PackingListsRow;
