import axios from "axios";

import { IPermissionSimplified } from "./types";

export const permissionsApi = {
  getPermissions: (userId: number, include_guides?: 1 | null) => {
    return axios.get(`/permissions_v2/user/${userId}/`, { params: { include_guides } });
  },
  getPermissionsList: (userId: number) => {
    return axios.get<IPermissionSimplified[]>(`/permissions_v2/user/${userId}/list/`);
  },
  updatePermissions: (userId: number, permissions: IPermissionSimplified[]) => {
    return axios.post(`/permissions_v2/user/${userId}/update/`, { permissions });
  },
};
