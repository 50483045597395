import React from "react";
import { useDispatch } from "react-redux";

import { tasksByListLoadingSelector, tasksByListSelector } from "components/pages/Tasks/model/selectors";
import { loadMoreTasksByList, loadTasksByList } from "components/pages/Tasks/model/thunks";
import { tasksListFilterType } from "components/pages/Tasks/model/types";

import { useTypedSelector } from "app/store/typedUseSelector";

type propsType = {
  listId: number;
  filters: tasksListFilterType;
};

export const useCurrentTasksList = ({ listId, filters }: propsType) => {
  const dispatch = useDispatch();
  const tasksInLists = useTypedSelector(tasksByListSelector);
  const tasksInListsLoading = useTypedSelector(tasksByListLoadingSelector);

  React.useEffect(() => {
    dispatch(loadTasksByList({ ...filters, lists: listId }));
  }, [listId, filters]);

  const tasksByCurrentList = React.useMemo(() => {
    return tasksInLists[listId] || { results: [], count: 0 };
  }, [listId, tasksInLists]);

  const isCurrentTasksLoading = React.useMemo(() => {
    return Boolean(tasksInListsLoading[listId]);
  }, [tasksInListsLoading, listId]);

  const loadMoreTasksByListHandler = React.useCallback(() => {
    dispatch(loadMoreTasksByList({ ...filters, offset: tasksByCurrentList.results.length, lists: listId }));
  }, [tasksByCurrentList, listId]);

  return {
    tasksByCurrentList,
    isCurrentTasksLoading,
    loadMoreTasksByListHandler,
  };
};
