export const percentsFormatter = (value: number, upperBound?: number) => {
  upperBound ||= 1;
  if (Number(value) === 0) return "0%";

  if (Number(value) >= 999) return "999%";

  if (!value || isNaN(Number(value))) return "-%";

  if (Number(value) > 0 && Number(value) < upperBound) return `<${upperBound}%`;

  return `${+value.toFixed(2)}%`;
};
