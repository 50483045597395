import styles from "./Sorting.module.scss";

export const SortingIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.0002 1.94531L8.52775 5.43611L7.47266 6.50231L5.7502 4.7978V13.0005H4.2502V4.7978L2.52775 6.50231L1.47266 5.43611L5.0002 1.94531Z"
      fill="#A4A4A4"
      className={styles.first}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0002 14.0547L14.5277 10.5639L13.4727 9.49769L11.7502 11.2022V2.99954H10.2502V11.2022L8.52775 9.49769L7.47266 10.5639L11.0002 14.0547Z"
      fill="#A4A4A4"
      className={styles.second}
    />
  </svg>
);
