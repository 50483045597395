import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { userSelector } from "redux/modules/_TODO/auth";
import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";

import { ROLES_ALIASES } from "constants/rolesAliases";

const useRedirectToDefaultPage = () => {
  const user = useSelector(userSelector);
  const breadcrumbsProjects = useSelector(objectsBreadcrumbsSelector);
  const history = useHistory();

  const redirectUrl = useMemo(() => {
    switch (user.roles?.[0]?.alias) {
      case ROLES_ALIASES.SUPER_ADMIN:
      case ROLES_ALIASES.ADMIN:
        return "/constructing/settings/profiles";
      case ROLES_ALIASES.ACCOUNTANT:
        return "/constructing/events/calendar";
      case ROLES_ALIASES.CEO:
        return "/constructing/manufacturing/";
      case ROLES_ALIASES.CHIEF_ENGINEER:
        return "/constructing/projects";
      case ROLES_ALIASES.MANUFACTURING_DIRECTOR:
        return "/constructing/manufacturing/";
      case ROLES_ALIASES.SUPPLY_DIRECTOR:
        return "/purchases/requisitions/new";
      case ROLES_ALIASES.ESTIMATOR:
        return "/constructing/projects";
      case ROLES_ALIASES.SUPPLY_SPECIALIST:
        return "/purchases/requisitions";
      case ROLES_ALIASES.CHIEF_OF_FINANCIAL:
      case ROLES_ALIASES.ECONOMIST:
        return "/constructing/finance/0/audit";
      case ROLES_ALIASES.EMPLOYEE:
        return `/tasks/`;
    }

    if (!breadcrumbsProjects?.results?.length) return "/objects/0/";

    const toManufacturingProjectPath = `/objects/${breadcrumbsProjects.results[0].id}`;

    switch (user.roles[0]?.alias) {
      case ROLES_ALIASES.CHIEF_PROJECT:
        return `${toManufacturingProjectPath}/estimate`;
      case ROLES_ALIASES.HR_OFFICER:
        return `${toManufacturingProjectPath}/workers/turnout`;
      case ROLES_ALIASES.STOREKEEPER:
        return `${toManufacturingProjectPath}/stocks/income`;
      case ROLES_ALIASES.HEAD_OF_THE_SITE:
      case ROLES_ALIASES.ECONOMIST_ON_THE_OBJECT:
        return `${toManufacturingProjectPath}/manufacturing/`;
      case ROLES_ALIASES.FOREMAN:
        return `${toManufacturingProjectPath}/journal`;
      case ROLES_ALIASES.PTO_ENGINEER:
      case ROLES_ALIASES.SUPERVISION:
        return `${toManufacturingProjectPath}/journal/done`;
    }

    return `/tasks/`;
  }, [breadcrumbsProjects.results, user.roles]);

  useEffect(() => {
    if (history.location.pathname !== "/" || !redirectUrl) return;
    history.replace(redirectUrl);
  }, [redirectUrl, history]);
};

export default useRedirectToDefaultPage;
