import _ from "lodash";

import { ISectionTree } from "types/interfaces/Section";

export const filterSectionsBySearch = (sections: ISectionTree[], search: string) => {
  if (!sections) return [];
  const sectionsCopy = _.cloneDeep(sections);
  const filteredSections = sectionsCopy.filter((section) => {
    const filteredSubSections = [...section.subsections].filter((subSection) => {
      const filteredExpenditures = [...subSection.expenditures].filter((expenditure) => {
        return expenditure.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
      if (!!filteredExpenditures.length) {
        subSection.expenditures = filteredExpenditures;
        return true;
      } else {
        return false;
      }
    });
    if (!!filteredSubSections.length) {
      section.subsections = filteredSubSections;
      return true;
    } else {
      return false;
    }
  });

  return filteredSections;
};

export const findEstimateTarget = (sections: ISectionTree[], expenditureId: number | undefined) => {
  if (!sections || !expenditureId) return null;
  let result = null;
  sections.forEach((section) => {
    section.subsections.forEach((subSection) => {
      const exp = subSection.expenditures.find((expenditure) => expenditure.id === expenditureId);
      if (!!exp) {
        result = { sectionId: section.id, subsectionId: subSection.id, expenditureId };
      }
    });
  });

  return result;
};
