import cn from "classnames";
import React, { SetStateAction } from "react";

import { FileViewerIcons } from "../assets/icons";

import styles from "./FileViewerZoom.module.scss";

interface IProps {
  zoom: number;
  setZoom: React.Dispatch<SetStateAction<number>>;
}

const FileViewerZoom: React.FC<IProps> = ({ zoom, setZoom }) => {
  const increaseZoom = (e: React.MouseEvent) => {
    setZoom((prev) => {
      if (prev >= 1) {
        return prev + 1;
      } else {
        return prev + 0.1;
      }
    });
  };

  const decreaseZoom = (e: React.MouseEvent) => {
    setZoom((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev - 0.1;
      }
    });
  };

  return (
    <div className={styles.container}>
      <div onClick={decreaseZoom} className={cn(styles.control, { [styles.disabled]: zoom <= 0.5 })}>
        {FileViewerIcons.ZoomOut}
      </div>
      <div className={"cn(styles.minus)"}>{FileViewerIcons.Zoom}</div>
      <div onClick={increaseZoom} className={cn(styles.control, { [styles.disabled]: zoom >= 5 })}>
        {FileViewerIcons.ZoomIn}
      </div>
    </div>
  );
};

export default FileViewerZoom;
