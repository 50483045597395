import {
  IFileInStorage,
  IFileStorageState,
  IFolderInStorage,
  IMergedItemInStorage,
} from "./types";

export const mergeFilesAndFolders = (
  files: IFileInStorage[] = [],
  folders: IFolderInStorage[] = []
): IMergedItemInStorage[] => {
  const transformedFiles: IMergedItemInStorage[] = files.map((el) => ({
    name: el.originalname,
    id: el.id,
    link: el.file,
  }));
  const transformedFolders: IMergedItemInStorage[] = folders.map((el) => ({
    name: el.title,
    id: el.id,
    isFolder: true,
    filesCount: el.count_files,
    foldersCount: el.count_subsections,
  }));
  return [...transformedFolders, ...transformedFiles];
};

export const removeItemFromStorage = (
  itemId: number,
  storageSection: IFileStorageState["byFolderId"],
  isFolder: boolean
) => {
  if (isFolder) {
    const filteredStorage: IFileStorageState["byFolderId"] = {};
    for (let key in storageSection) {
      filteredStorage[key] = {
        ...storageSection[key],
        items: storageSection[key].items.filter((el) => {
          if (!el.isFolder) return true;
          if (el.id === itemId) {
            return false;
          } else {
            return true;
          }
        }),
        foldersCount:
          storageSection[key].foldersCount > 1
            ? storageSection[key].foldersCount - 1
            : 0,
      };
    }
    return filteredStorage;
  } else {
    const filteredStorage: IFileStorageState["byFolderId"] = {};
    for (let key in storageSection) {
      filteredStorage[key] = {
        ...storageSection[key],
        items: storageSection[key].items.filter((el) => {
          if (el.isFolder) return true;
          if (el.id === itemId) {
            return false;
          } else {
            return true;
          }
        }),
        recentFiles: (storageSection[key].recentFiles || []).filter(
          (el) => el.id !== itemId
        ),
        filesCount:
          storageSection[key].filesCount > 1
            ? storageSection[key].filesCount - 1
            : 0,
      };
    }
    return filteredStorage;
  }
};

export const renameItemInStorage = (
  itemId: number,
  storageSection: IFileStorageState["byFolderId"],
  isFolder: boolean,
  newName: string
) => {
  if (isFolder) {
    const filteredStorage: IFileStorageState["byFolderId"] = {};
    for (let key in storageSection) {
      filteredStorage[key] = {
        ...storageSection[key],
        items: storageSection[key].items.map((el) => {
          if (!el.isFolder) return el;
          if (el.id === itemId) {
            return { ...el, name: newName };
          } else {
            return el;
          }
        }),
      };
    }
    return filteredStorage;
  } else {
    const filteredStorage: IFileStorageState["byFolderId"] = {};
    for (let key in storageSection) {
      filteredStorage[key] = {
        ...storageSection[key],
        items: storageSection[key].items.map((el) => {
          if (el.isFolder) return el;
          if (el.id === itemId) {
            return { ...el, name: newName };
          } else {
            return el;
          }
        }),
        recentFiles: (storageSection[key].recentFiles || []).map((el) => {
          if (el.id === itemId) {
            return { ...el, name: newName };
          } else {
            return el;
          }
        }),
      };
    }
    return filteredStorage;
  }
};
