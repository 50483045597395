import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { IDetailedKs2InDocs } from "redux/modules/common/building/documents/ksList/types";
import { aosrActsSelector } from "redux/modules/common/building/journal/aosr/selectors";
import { getAosr } from "redux/modules/common/building/journal/aosr/thunks";
import {
  expenditureAttachmentsSelector,
  ks2ExpendituresAttachmentsSelector,
  passportsInvalidateKeySelector,
} from "redux/modules/common/passportsAndCertificates/selectors";
import {
  getExpendituresAttachments,
  getKs2ExpenditureAttachments,
} from "redux/modules/common/passportsAndCertificates/thunks";
import { DocumentsTypesEnum } from "redux/modules/common/passportsAndCertificates/types";

import ExpenditureKs2FilesModal from "components/modals/ExpenditureFilesModal/FilesModalKs2Expenditure";
import { checkExpenditureAttachmentIsWarn } from "components/modals/PassportsAndSertificates/utils";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import AosrExistingModal from "components/pages/Journal/components/JournalDone/components/JournalExecTicket/components/Aosr/AosrExistingModal/AosrExistingModal";

import ExpandPillTrigger from "shared/ui/controls/ExpandPillTrigger/ExpandPillTrigger";
import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import StatusElement from "shared/ui/dataDisplay/StatusElement/StatusElement";
import { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";
import { ITicketInJournalDone } from "types/interfaces/Tickets";

import { useQueryParams } from "utils/hooks/useQueryParams";

import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";
import { splitThousands } from "utils/formatters/splitThousands";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./KS2PageRowContent.module.scss";

interface IProps {
  expenditure: IDetailedKs2InDocs["items"][0]["expenditures"][0];
  sectionId: number;
}

const KS2PageSubRow: React.FC<IProps> = ({ expenditure, sectionId }) => {
  const isWork = expenditure.expenditure_type === ExpenditureTypeEnum.work;
  const ks2Id = +useQueryParams("ks2Id")!;
  const objectId = useObjectId();
  const dispatch = useDispatch();
  const [isFilesRow, setIsFilesRow] = React.useState(false);
  const [isFilesModal, setIsFilesModal] = React.useState(false);
  const attachments = useSelector(ks2ExpendituresAttachmentsSelector)[`${ks2Id}_${expenditure?.expenditure_id}`];
  const attachmentsForResources = useSelector(expenditureAttachmentsSelector)[expenditure?.expenditure_id];
  const invalidateKey = useSelector(passportsInvalidateKeySelector);

  const toggleFilesRow = React.useCallback(() => {
    setIsFilesRow((prev) => !prev);
  }, []);

  const toggleFilesModal = React.useCallback(() => {
    setIsFilesModal((prev) => !prev);
  }, []);

  const attachmentsCount = expenditure.docs_count?.active + expenditure.docs_count?.expired;

  React.useEffect(() => {
    if (!isFilesRow) return;
    if (isWork) {
      dispatch(getKs2ExpenditureAttachments(objectId, ks2Id, expenditure.expenditure_id));
    } else {
      dispatch(getExpendituresAttachments(expenditure.expenditure_id));
    }
  }, [isFilesRow, expenditure.expenditure_id, invalidateKey, ks2Id, objectId]);

  const selectedAttachments = React.useMemo(() => {
    if (!attachments && isWork) return [];
    if (!isWork && !attachmentsForResources?.results) return [];
    const attachmentsArr = [];
    for (let key in attachments) {
      attachmentsArr.push(attachments[key]);
    }
    if (isWork) {
      return attachmentsArr;
    } else {
      if (!Array.isArray(attachmentsForResources)) {
        return attachmentsForResources?.results ?? [];
      } else {
        return attachmentsForResources;
      }
    }
  }, [attachments, expenditure, attachmentsForResources, isWork]);

  React.useEffect(() => {
    if (!isFilesRow) return;
    //if (!attachments?.aosr?.length) return;
    //dispatch(getAosr(objectId, expenditure.expenditure_id));
  }, [objectId, expenditure.expenditure_id, isFilesRow, attachments]);
  const aosr = useSelector(aosrActsSelector)[expenditure.expenditure_id];
  const [isAosrModal, setIsAosrModal] = React.useState(false);

  const toggleAosrModal = React.useCallback(() => {
    setIsAosrModal((prev) => !prev);
  }, []);

  const closeAosrModal = React.useCallback(() => {
    setIsAosrModal(false);
  }, []);

  if (!expenditure) return null;

  const isShowClipBtn = [
    ExpenditureTypeEnum.material,
    ExpenditureTypeEnum.work,
    ExpenditureTypeEnum.equipment,
  ].includes(expenditure.expenditure_type);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.row}>
          <TableReusableCell>{expenditure.row_number}</TableReusableCell>
          <TableReusableCell isNoBreak>{expenditure.expenditure_name}</TableReusableCell>
          <TableReusableCell isCentered>
            {expenditure.count} {expenditure.expenditure_measure}
          </TableReusableCell>
          <TableReusableCell className={styles.right}>
            {splitThousands(expenditure.amount, false, false)}
          </TableReusableCell>
          <div></div>
          <TableReusableCell isCentered>
            {isShowClipBtn && <FilesClipButton onClick={toggleFilesModal} />}
          </TableReusableCell>
          <TableReusableCell isCentered onClick={stopEventPropagation}>
            {!!attachmentsCount && (
              <ExpandPillTrigger
                isExpanded={isFilesRow}
                toggleExpand={toggleFilesRow}
                variant={!!expenditure.docs_count?.expired ? "error" : "success"}
                label={`${attachmentsCount}`}
              />
            )}
          </TableReusableCell>
        </div>
        {isFilesRow && (
          <div className={styles.filesRow}>
            {/* @ts-ignore */}
            {attachments?.aosr?.map((el) => (
              <StatusElement
                key={el.id}
                status={!!el.docs?.length ? "success" : "fail"}
                text={`Акт АОСР`}
                onClick={() => toggleAosrModal()}
              />
            ))}
            {selectedAttachments?.map((el) => (
              <StatusElement
                key={el.id}
                onClick={toggleFilesModal}
                status={checkExpenditureAttachmentIsWarn(el, true) as "fail"}
                dateEnd={el?.end_at}
                text={
                  el.type === DocumentsTypesEnum.file ? sliceTextByConstraint(el.originalname, 30) : el.type_display
                }
              />
            ))}
          </div>
        )}
      </div>
      <ExpenditureKs2FilesModal
        isOpen={isFilesModal}
        onClose={toggleFilesModal}
        expenditureId={expenditure.expenditure_id}
        expenditureType={expenditure.expenditure_type}
        ks2Id={ks2Id}
        expenditureName={expenditure.expenditure_name}
      />
      {aosr && (
        <AosrExistingModal
          isOpen={isAosrModal && !!aosr} /* @ts-ignore */
          aosr={aosr}
          onClose={closeAosrModal}
          expenditure={{ name: expenditure.expenditure_name, id: expenditure.expenditure_id } as ITicketInJournalDone}
          sectionId={sectionId}
        />
      )}
    </>
  );
};

export default React.memo(KS2PageSubRow);
