import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSections,
  isLoadingSectionSelector,
  materialSectionsSelector,
} from "redux/modules/common/building/materials/materials";

import Section from "./../components/Section";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import materialsIcon from "images/icons/navigation/materials.svg";

interface IProps {
  onClick: (sectionId: number) => void;
}

const SectionsLevel: React.FC<IProps> = ({ onClick }) => {
  const objectId = useObjectId();
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSectionSelector);
  const materialSections = useSelector(materialSectionsSelector);

  useEffect(() => {
    dispatch(getSections(objectId));
  }, [objectId]);

  if (isLoading) return <Spinner />;

  return materialSections.length === 0 ? (
    <EmptyPlaceholder img={materialsIcon} />
  ) : (
    <>
      {materialSections?.map((item: any) => (
        <div key={item.id}>
          <Section blockOpenSubSections={() => onClick(item.id)} data={item} />
        </div>
      ))}
    </>
  );
};

export default SectionsLevel;
