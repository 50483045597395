import moment from "moment";
import React from "react";

import Actions from "shared/ui/controls/Actions/Actions";
import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import Avatar from "shared/ui/dataDisplay/Avatar/Avatar";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { TASK_PRIORITY, TASK_STATUSES } from "../../constants";
import { ItaskResponse } from "../../model/types";
import { personFullNameWithId } from "types/personsTypes";

import getShortFullName from "utils/formatters/getShortFullName";

import MessageIcon from "images/icons/MessageIcon";

import styles from "./TaskRow.module.scss";

export interface ItaskRowProps extends Pick<ItaskResponse, "task_files"> {
  number: number;
  name: string;
  avatar?: string;
  executor: personFullNameWithId;
  dateStart: string;
  dateEnd: string;
  status: keyof typeof TASK_STATUSES;
  priority: keyof typeof TASK_PRIORITY;
  commentsCount: number;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
  id: number;
  handleOpen: () => void;
  isHidden?: boolean;
}

const TaskRow: React.FC<ItaskRowProps> = ({
  number,
  name,
  avatar,
  executor,
  dateStart,
  dateEnd,
  status,
  priority,
  commentsCount,
  onDelete,
  onEdit,
  id,
  handleOpen,
  isHidden,
  task_files,
}) => {
  const editHandler = React.useCallback(() => {
    onEdit(id);
  }, [id, onEdit]);

  const removeHandler = React.useCallback(() => {
    onDelete(id);
  }, [id, onEdit]);

  if (isHidden) return null;

  return (
    <TableReusableRow className={styles.row} isHoverable onClick={handleOpen}>
      <TableReusableCell isNoBreak>{number}</TableReusableCell>
      <TableReusableCell isNoBreak>{name}</TableReusableCell>
      <TableReusableCell>
        <Avatar person={executor} img={avatar} />
      </TableReusableCell>
      <TableReusableCell isNoBreak>{getShortFullName(executor)}</TableReusableCell>
      <TableReusableCell isCentered>{moment(dateStart).format("DD/MM/YYYY")}</TableReusableCell>
      <TableReusableCell isCentered>{moment(dateEnd).format("DD/MM/YYYY")}</TableReusableCell>
      <TableReusableCell>{TASK_STATUSES[status]}</TableReusableCell>
      <TableReusableCell>{TASK_PRIORITY[priority]}</TableReusableCell>
      <TableReusableCell isCentered>
        <FilesClipButton className={styles.files} count={task_files?.length ?? 0} />
      </TableReusableCell>
      <TableReusableCell isCentered>
        <div className={styles.comment}>
          <MessageIcon width="28" />
          {!!commentsCount && <div className={styles.commentCount}>{commentsCount}</div>}
        </div>
      </TableReusableCell>
      <Actions canEdit canRemove onEdit={editHandler} onRemove={removeHandler} className={styles.actions} />
    </TableReusableRow>
  );
};

export default React.memo(TaskRow);
