import React from "react";

function ThreeDotsMenuIcon({ width = "38", ...props }) {
  return (
    <svg
      width={width}
      height="8"
      viewBox="0 0 38 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.0405 7.74902C32.1343 7.74902 30.583 6.17969 30.583 4.25C30.583 2.31934 32.1343 0.748047 34.0405 0.748047C35.9482 0.748047 37.5005 2.31934 37.5005 4.25C37.5005 6.17969 35.9482 7.74902 34.0405 7.74902ZM19 7.74902C17.0923 7.74902 15.5405 6.17969 15.5405 4.25C15.5405 2.31934 17.0923 0.748047 19 0.748047C20.9062 0.748047 22.4575 2.31934 22.4575 4.25C22.4575 6.17969 20.9062 7.74902 19 7.74902ZM3.95752 7.74902C2.05127 7.74902 0.5 6.17969 0.5 4.25C0.5 2.31934 2.05127 0.748047 3.95752 0.748047C5.86523 0.748047 7.41699 2.31934 7.41699 4.25C7.41699 6.17969 5.86523 7.74902 3.95752 7.74902Z"
        fill="#707070"
      />
    </svg>
  );
}

export default React.memo(ThreeDotsMenuIcon);
