import { IPositionModalContext } from "./PositionModal";
import { IPositionInModal } from "./useEditPositions";

import { RANK_TYPE_PRIORITIES } from "./constants";

export const spawnPosition: () => IPositionInModal = () => ({ id: Math.random(), isNew: true });

export const sortPositionRanks = (a: IPositionInModal, b: IPositionInModal) =>
  (a.rank! - b.rank!) * 1000 + (a.rank! === b.rank ? RANK_TYPE_PRIORITIES[a.type!] - RANK_TYPE_PRIORITIES[b.type!] : 0);

export const checkUnsavedPositions = (
  unsavedChanges: IPositionModalContext["unsavedChanges"],
  positions: IPositionInModal[]
) => {
  const existingPosistionsDict =
    (positions.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id!]: true,
      };
    }, {}) as any) ?? {};

  let res = false;

  for (let key in unsavedChanges) {
    if (!!unsavedChanges[key] && !!existingPosistionsDict[key]) {
      res = true;
    }
  }

  return res;
};
