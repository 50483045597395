import React, { useCallback, useEffect, useRef, useState } from "react";

import Select from "components/UI/atoms/Select";

import SettingsIcon from "./SettingsIcon";

import { ANALYTICS_SELECTING_MONTHS_OPTIONS } from "../../../../constants";

import useOnClickOutside from "../../../../../../../hooks/useOnClickOutside";

import styles from "./DatesSettingsPopup.module.scss";

export interface IDatesSettingsPopupProps {
  selectedMonthsCount: number;
  onSelectMonthsCount: (_: number) => void;
  disabled?: boolean;
}

const DatesSettingsPopup: React.FC<IDatesSettingsPopupProps> = ({
  selectedMonthsCount,
  onSelectMonthsCount,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const closeHandler = useCallback(() => setIsOpen(false), []);
  const clickOutside = useRef<HTMLDivElement>(null);

  useOnClickOutside(clickOutside, closeHandler);

  useEffect(() => {
    if (!disabled) return;
    setIsOpen(false);
  }, [disabled]);

  return (
    <div className={styles.wrapper} ref={clickOutside}>
      <div className={styles.btn} onClick={() => !disabled && setIsOpen((prev) => !prev)} aria-disabled={disabled}>
        <SettingsIcon />
      </div>
      {isOpen && (
        <div className={styles.popup}>
          <div className={styles.title}>Настройки отображения</div>
          <div className={styles.item}>
            <div>Период:</div>
            <Select
              options={ANALYTICS_SELECTING_MONTHS_OPTIONS}
              value={selectedMonthsCount || 1}
              onChange={onSelectMonthsCount as any}
              className={styles.select}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(DatesSettingsPopup);
