import axios from "axios";
import cn from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { passportsActions } from "redux/modules/common/passportsAndCertificates/actions";
import { passportsApi } from "redux/modules/common/passportsAndCertificates/api";
import {
  attachDocumentToExpenditure,
  attachFileToExpenditure,
} from "redux/modules/common/passportsAndCertificates/thunks";

import CommonFilesModal from "components/modals/ExpenditureFilesModal/CommonFilesModal";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { getAttachmentName } from "components/pages/KSPage/utils";
import { IStockFile, useStockFiles } from "components/pages/Stock/hooks/useStockFIles";

import { useTypedSelector } from "app/store/typedUseSelector";
import ConfirmModal from "entities/ConfirmationModal/ConfirmModal";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ExpandPillTrigger from "shared/ui/controls/ExpandPillTrigger/ExpandPillTrigger";
import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import DateCell from "shared/ui/dataDisplay/DateCell/DateCell";
import StatusElement from "shared/ui/dataDisplay/StatusElement/StatusElement";
import { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import FileViewer from "widgets/FileViewer/FileViewer";

import { useTypedParams } from "utils/hooks/useTypedParams";

import { formatNumberWithMeasure } from "../../../../../../../../utils/formatters/formatNumberWithMeasure";
import downloadFile from "utils/helpers/download";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import { ReactComponent as DownloadIcon } from "images/download.svg";
import CrossInCircle from "images/icons/CrossInCircle";

import styles from "./index.module.scss";

const ProductItem = ({ data, onClick, measure, stockId, expId }) => {
  const { invalidateKey, isPending } = useTypedSelector((state) => state.passports);
  const [isOpen, setIsOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const {
    isLoading,
    data: files,
    buildingFilesIds,
    resourceFilesIds,
  } = useStockFiles({
    isOpen: isOpen || isModal,
    productId: data.id,
    stockId,
    invalidateKey,
  });

  const [fileViewerIndex, setFileViewerIndex] = useState(-1);

  //file
  const [isDelete, setIsDelete] = useState(null);
  const objectId = useObjectId();

  const [isDeletePending, setIsDeletePending] = useState(false);
  const deleteDocument = async (externalId) => {
    const id = externalId || isDelete?.id;
    const isResource = resourceFilesIds?.includes(id);

    setIsDeletePending(true);
    if (isResource) {
      await axios.delete(`/estimate/documents/resource_attachments/${expId}/${id}/`);
    } else {
      await axios.delete(`/building/${objectId}/materials/${data.id}/files/${id}`);
    }
    dispatch(passportsActions.invalidateKey());
    setIsDeletePending(false);
    setIsDelete(null);
  };

  const downloadHandler = (file) => {
    downloadFile(file.file, file.name);
  };

  const dispatch = useDispatch();
  const onSubmit = (vals) => {
    dispatch(attachDocumentToExpenditure(vals, expId));
  };

  const onUpload = (vals) => {
    dispatch(attachFileToExpenditure(expId, vals));
  };

  return (
    <div>
      <div className={styles.container} onClick={onClick}>
        <div className={styles.field1}>
          <div className={styles.dot}></div>
        </div>
        <div className={styles.field2} title={data?.name}>
          {data?.name}
        </div>
        <div className={styles.field3}>{data?.provider?.name}</div>
        <div className={styles.field4}>
          <DateCell date={moment(data?.packinglist?.delivery_date).format("DD.MM.YYYY")} />
        </div>
        <div className={styles.field4}>{data?.count}</div>
        <div className={styles.field6}>{formatNumberWithMeasure(data?.amount, measure)}</div>
        <div className={styles.filesCount} onClick={stopEventPropagation}>
          <FilesClipButton
            onClick={() => {
              setIsModal(true);
            }}
          />{" "}
          {!!data?.count_files && (
            <ExpandPillTrigger label={data?.count_files || 0} toggleExpand={() => setIsOpen((prev) => !prev)} />
          )}
        </div>
      </div>
      {isOpen && (
        <div className={styles.filesRow}>
          {isLoading && <Spinner isStatic isSmallGray className={styles.spinner} />}
          {files?.map((file, i) => (
            <StatusElement
              key={file.id}
              status="success"
              text={file.name}
              onClick={() => {
                setFileViewerIndex(i);
              }}
            />
          ))}
          <FileViewer
            files={files}
            isOpen={fileViewerIndex > -1}
            onClose={() => setFileViewerIndex(-1)}
            startIndex={fileViewerIndex}
          />
        </div>
      )}
      <CommonFilesModal
        isPending={isLoading || isPending || isDeletePending}
        type="expenditure"
        expenditureType="material"
        onSubmit={onSubmit}
        onUpload={onUpload}
        fileTypeForFiltering="Файл"
        isOpen={isModal}
        onDirectlyDelete={deleteDocument}
        onClose={() => setIsModal(false)}
        name={data?.name}
        attachments={files}
        renderFileRow={(file) => (
          <div className={styles.tableRow} key={file.id}>
            <TableReusableCell isNoBreak>{file.type} </TableReusableCell>
            <TableReusableCell isNoBreak>{file.name} </TableReusableCell>
            <TableReusableCell className={styles.files} onClick={() => downloadHandler(file)}>
              <DownloadIcon />
            </TableReusableCell>
            <TableReusableCell>
              <div onClick={() => setIsDelete(file)} className={styles.delete}>
                <CrossInCircle />
              </div>
            </TableReusableCell>
          </div>
        )}
        customTableHead={
          <div className={styles.tableHead}>
            <div>Тип</div>
            <div>Имя</div>
          </div>
        }
      />
      <ConfirmModal
        isOpen={isDelete}
        onClose={() => setIsDelete(null)}
        action={() => deleteDocument()}
        variant="secondary"
        title="Подтвердите удаление"
      />
    </div>
  );
};

export default ProductItem;
