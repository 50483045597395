import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { actingActions } from "redux/modules/common/building/journal/acting/actions";
import { actingApi } from "redux/modules/common/building/journal/acting/api";
import {
  actingExpendituresSelector,
  actingIntersectingExpendituresSelector,
  actingInvalidateKeySelector,
  actingSectionsSelector,
} from "redux/modules/common/building/journal/acting/selectors";
import {
  IExpenditureInActing,
  ISectionInActing,
  ISelectedSectionInActing,
} from "redux/modules/common/building/journal/acting/types";

import JournalExcludeKsModal from "../JournalExcludeKsModal/JournalExcludeKsModal";
import JournalActingModalContainer from "./JournalActingModalContainer";
import JournalKs2ModalEditingRow from "./Rows/JournalKs2ModalRow/JournalKs2ModalEditingRow";
import JournalKs2ModalRow from "./Rows/JournalKs2ModalRow/JournalKs2ModalRow";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useCreateKs2 } from "../hooks/useCreateKs2";
import { useEditKs2 } from "../hooks/useEditKs2";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  ks2Id: number;
}

const JournalEditKs2Modal: React.FC<IProps> = ({ isOpen, onClose, ks2Id }) => {
  const dispatch = useDispatch();
  const cacheKey = useSelector(actingInvalidateKeySelector);
  const { objectId } = useParams<IRouterParamsWithObjectId>();

  const {
    submitHandler,
    actData,
    actNumberHandler,
    dateEndHandler,
    dateStartHandler,
    deleteExpenditure,
    expendituresInKs,
    expendituresCount,
    isShowSubmit,
  } = useEditKs2(ks2Id, isOpen);

  React.useEffect(() => {
    onClose();
  }, [cacheKey]);

  const downloadReport = React.useCallback(() => {
    actingApi.getKs2Report(
      objectId,
      ks2Id,
      `КС-2 №${actData.number} от ${moment(actData.dateStart).format("DD.MM.YYYY")}.xlsx`
    );
  }, [objectId, ks2Id, actData.number]);

  return (
    <>
      <JournalActingModalContainer
        title="Редактирование акта КС-2"
        isOpen={isOpen}
        onClose={onClose}
        count={expendituresCount || 0}
        actNumber={actData.number}
        onChangeAct={actNumberHandler}
        dateEnd={actData.dateEnd}
        dateStart={actData.dateStart}
        onChangeDateStart={dateStartHandler}
        onChangeDateEnd={dateEndHandler}
        onSubmit={submitHandler}
        isEdit
        isShowSubmit={isShowSubmit}
        onDownload={downloadReport}
      >
        {expendituresInKs?.map((el) => (
          <JournalKs2ModalEditingRow key={el.section_id} section={el} onDirectlyRemoveExpenditure={deleteExpenditure} />
        ))}
      </JournalActingModalContainer>
    </>
  );
};

export default React.memo(JournalEditKs2Modal);
