import axios from "axios";

import { IAosrDoc } from "../aosr/types";
import { IAookDoc, ICreateAook } from "./types";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import downloadFile from "utils/helpers/download";

export const aookApi = {
  createDraft: (data: ICreateAook, buildingId: string) => {
    return axios.post(`/building/${buildingId}/ticketacts/aook/create_draft/`, data);
  },
  editAook: (data: Partial<ICreateAook>, buildingId: string, aookId: number) => {
    return axios.patch(`/building/${buildingId}/ticketacts/aook/${aookId}/`, data);
  },
  createDoc: async (buildingId: string, aookId: number, doc: IAookDoc) => {
    return axios.post(`/building/${buildingId}/ticketacts/aook/${aookId}/docs/`, doc);
  },
  getDetailedAook: (buildingId: string, aookId: number) => {
    return axios.get(`/building/${buildingId}/ticketacts/aook/${aookId}/`);
  },
  downloadAook: (buildingId: string, aookId: number, actName: string) => {
    downloadFile(
      `${axios.defaults.baseURL}/building/${buildingId}/ticketacts/aook/${aookId}/download/`,
      `${actName}.xlsx`,
      "POST"
    );
  },
  getDocs: (buildingId: string, aookId: number) => {
    return axios.get(`/building/${buildingId}/ticketacts/aook/${aookId}/docs/`);
  },
  closeAook: (buildingId: string, aookId: number, file: string) => {
    return axios.post(`/building/${buildingId}/ticketacts/aook/${aookId}/close/`, { confirmed_file: file });
  },
  deleteDoc: (buildingId: string, aookId: number, docId: number) => {
    return axios.delete(`/v1/building/${buildingId}/ticketacts/aook/${aookId}/docs/${docId}/`);
  },
};
