import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { objectsBreadcrumbsSelector } from "../../../../redux/modules/common/building/objects";
import { eventsSelector, getEvents, isLoadingEventsSelector } from "../../../../redux/modules/purchaser/events";

import EventsList from "./components/EventsList/EventsList";
import Select from "components/UI/atoms/Select";

import EmptyPlaceholder from "../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import { eventsBtnSvg } from "../CalendarHeader/eventsBtnSvg";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import { CALENDAR_MODAL_TABS, EVENT_TYPES, calendarModalTabs } from "./constants";

import { queryParamsFormatter } from "../../../../utils/formatters/queryParamsFormatter";

import deleteBtnSelected from "../../../../images/icons/deleteBtnSelected.png";

import styles from "./index.module.scss";

const SliderModalCalendar = ({ isOpen, closeHandler, dateBeforeDefault, dateAfterDefault }) => {
  const dispatch = useDispatch();
  const projects = useSelector(objectsBreadcrumbsSelector);

  const events = useSelector(eventsSelector);
  const isEventsLoading = useSelector(isLoadingEventsSelector);

  const [tab, setTab] = useState(CALENDAR_MODAL_TABS.ALL_EVENTS);
  const [objSelect, setObjSelect] = useState(null);
  const [dateAfter, setDateAfter] = useState(dateAfterDefault);
  const [dateBefore, setDateBefore] = useState(dateBeforeDefault);
  const [filteredEventTypesIds, setFilteredEventTypesIds] = useState([]);
  const [showType, setShowType] = useState(null);

  const objSelectArr = () =>
    projects?.results.map((el) => ({
      id: el.id,
      name: el.name,
    })) || [];

  const newObj = [...objSelectArr()];

  const newTypesHandler = () =>
    EVENT_TYPES.map((event) => {
      if (filteredEventTypesIds.includes(event.id)) {
        return null;
      } else {
        return {
          id: event.id,
          name: event.name,
          label: event.name,
        };
      }
    }).filter((event) => event) || [];

  const newTypesArray = [
    {
      id: 0,
      name: `Типы событий: ${filteredEventTypesIds.length}/${EVENT_TYPES.length}`,
      label: `Типы событий: ${filteredEventTypesIds.length}/${EVENT_TYPES.length}`,
      isHidden: true,
    },
    ...newTypesHandler(),
  ];

  const displaySelectedEvents = (id) => {
    const foundEvent = EVENT_TYPES.find((eventType) => eventType.id === id);
    return !!foundEvent ? foundEvent.name : null;
  };

  const handleEventsSelect = (val) => {
    if (filteredEventTypesIds.includes(val)) {
    } else {
      const newArr = filteredEventTypesIds.slice(0);
      newArr.push(val);
      setFilteredEventTypesIds(newArr);
      setShowType(newTypesArray[0].label);
    }
  };

  const deleteSelectedItem = (val) => {
    const newArr = filteredEventTypesIds.filter((item) => item !== val);
    setFilteredEventTypesIds(newArr);
  };

  const resetStatesToInitial = () => {
    setTab(CALENDAR_MODAL_TABS.ALL_EVENTS);
    setObjSelect(null);
    setFilteredEventTypesIds([]);
  };

  useEffect(() => {
    if (tab === CALENDAR_MODAL_TABS.ALL_EVENTS) setFilteredEventTypesIds([]);
  }, [tab]);

  useEffect(() => {
    setDateAfter(dateAfterDefault);
    setDateBefore(dateBeforeDefault);
  }, [dateAfterDefault, dateBeforeDefault]);

  useEffect(() => {
    if (!isOpen) return;
    dispatch(
      getEvents(
        queryParamsFormatter(
          {
            date_after: moment(dateAfter).format("YYYY-MM-DD"),
            date_before: moment(dateBefore).format("YYYY-MM-DD"),
            building_id: objSelect === "Наименование объекта" ? undefined : objSelect,
            events: !!filteredEventTypesIds.length ? filteredEventTypesIds[0] : undefined,
            detail: true,
          },
          filteredEventTypesIds,
          "events"
        )
      )
    );
  }, [dateAfter, dateBefore, objSelect, filteredEventTypesIds]);

  useEffect(() => {
    if (isOpen) return;
    setTimeout(resetStatesToInitial, 500);
  }, [isOpen]);

  const eventsByTypesList = useCallback(() => {
    if (filteredEventTypesIds.length === 0) {
      return <EmptyPlaceholder svgJsx={eventsBtnSvg} text={"Выберите тип события"} className={styles.placeholder} />;
    }

    return <EventsList events={events} isEventsLoading={isEventsLoading} />;
  }, [events, filteredEventTypesIds.length, isEventsLoading]);

  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={() => closeHandler()}>
        <div className={styles.fixedHeader}>
          <header className={styles.headerContent}>
            <div className={styles.container2}>
              <div className={styles.eventsWithDataRange}>
                <div className={styles.boxTopTitle}>События за</div>
                <div className={styles.calendarRange}>
                  <CalendarRange
                    defaultDateStart={dateAfter}
                    setDefaultDateStart={setDateAfter}
                    defaultDateEnd={dateBefore}
                    setDefaultDateEnd={setDateBefore}
                    classNamePopup={styles.calendarPopup}
                  />
                </div>
              </div>
              <div className={styles.dataAndObjectFilters}>
                <div className={styles.selectCode}>
                  <Select
                    className={styles.select}
                    value={objSelect}
                    options={newObj}
                    onChange={(val) => setObjSelect(val)}
                    placeholder="Проект"
                  />
                </div>
                <div className={styles.boxTopDateCount}>Всего: {events?.length}</div>
              </div>
              <div className={styles.tabsContainer}>
                <TabBarNotLinks
                  isOnlyTabsView
                  tabs={calendarModalTabs}
                  onClick={setTab}
                  activeId={tab}
                  className={styles.tabs}
                />
              </div>
              {tab === CALENDAR_MODAL_TABS.EVENTS_BY_TYPES && (
                <div style={{ width: "100%" }}>
                  <div className={styles.typesTitle}>Укажите типы событий:</div>
                  <div className={styles.selectedBlock}>
                    {filteredEventTypesIds.map((item) => (
                      <div key={item} className={styles.selectedItem}>
                        <div className={styles.selectedItemText}>{displaySelectedEvents(item)}</div>
                        <div onClick={() => deleteSelectedItem(item)} style={{ cursor: "pointer" }}>
                          <img src={deleteBtnSelected} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>

                  <Select
                    id={styles.eventsSelect}
                    className={styles.select}
                    value={showType}
                    options={newTypesArray}
                    onChange={(val) => handleEventsSelect(val)}
                    placeholder={`Типы событий: ${filteredEventTypesIds.length}/${EVENT_TYPES.length}`}
                    disabled={filteredEventTypesIds.length === EVENT_TYPES.length}
                  />
                </div>
              )}
            </div>
          </header>
        </div>
        <div className={styles.positionTypesEvents}>
          {tab === CALENDAR_MODAL_TABS.EVENTS_BY_TYPES && eventsByTypesList()}
        </div>
        <div className={styles.scrollEventsPosition}>
          {tab === CALENDAR_MODAL_TABS.ALL_EVENTS && <EventsList events={events} isEventsLoading={isEventsLoading} />}
        </div>
      </SliderModal>
    </>
  );
};

export default React.memo(SliderModalCalendar);
