import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ksListInvalidateKeySelector, ksListsSelector } from "redux/modules/common/building/documents/ksList/selectors";
import { getKsLists, getMoreKs3inList } from "redux/modules/common/building/documents/ksList/thunks";

import KS2Page from "components/pages/KSPage/KS2Page";
import KS3Page from "components/pages/KSPage/KS3Page";

import KsListHead from "./KsListRow/KsListHead";
import KsListRow from "./KsListRow/KsListRow";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ShowMoreButton from "shared/ui/controls/ShowMoreButton";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { useLocationArray } from "utils/hooks/useLocationArray";
import { useQueryParams } from "utils/hooks/useQueryParams";

import { hasOnlyPaginationFilters } from "../../../../utils/hasOnlyPaginationFilters";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

interface IProps {}

const KsList: React.FC<IProps> = ({}) => {
  const dispatch = useDispatch();
  const locationArr = useLocationArray();
  const paginationRefreshLimit = useRef(10);

  const ks2Id = useQueryParams("ks2Id");
  const ks3Id = useQueryParams("ks3Id");

  const [filters, setFilters] = useState<Record<string, string>>({});

  const isWithFilters = useMemo(() => !hasOnlyPaginationFilters(filters), [filters]);

  const objectId = useMemo(() => {
    const candidate = locationArr[1];
    if (isNaN(+candidate)) {
      return "0";
    } else {
      return candidate;
    }
  }, [locationArr]);

  const ksList = useSelector(ksListsSelector)[objectId];
  const invalidateKey = useSelector(ksListInvalidateKeySelector);

  useEffect(() => {
    dispatch(getKsLists(objectId, { ...filters, limit: paginationRefreshLimit.current }));
  }, [objectId, invalidateKey, filters]);

  const loadMore = useCallback(() => {
    paginationRefreshLimit.current = paginationRefreshLimit.current + 10;
    dispatch(getMoreKs3inList(objectId, { ...filters, offset: ksList?.results?.length }));
  }, [filters, ksList?.results?.length]);

  const changeFilter = useCallback((name: string, value: string) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  }, []);

  const isEmpty = !ksList?.isLoading && !ksList?.results?.length;

  if (!isWithFilters && ksList?.isLoading) return <Spinner />;

  return (
    <>
      {ks2Id && <KS2Page />}
      {ks3Id && <KS3Page />}

      <div data-testid="page_docs_kses">
        {((isEmpty && isWithFilters) || !isEmpty) && <KsListHead onFilterChange={changeFilter} />}
        {ksList?.isLoading && <Spinner />}
        {isEmpty && <EmptyPlaceholder img={jurnalIcon} />}
        {ksList?.results?.map((el) => (
          <KsListRow key={el.id} act={el} />
        ))}
        <ShowMoreButton
          isExists={!ksList?.isLoading}
          showedCount={ksList?.results?.length}
          allCount={ksList?.count}
          handleAdd={loadMore}
        />
      </div>
    </>
  );
};

export default React.memo(KsList);
