import { ESTIMATE_STATES_IDS } from "./constants";

export enum ChaptersEnum {
  FIRST = 1,
  SECOND,
  THIRD,
  FOURTH,
  FIFTH,
  SIXTH,
  SEVENTH,
  EIGHTH,
  NINTH,
  TENTH,
  ELEVENTH,
  TWELFTH,
}

export enum EEstimateStatesIds {
  DRAFT = "draft",
  LOCALE = "locale",
  OBJECT = "object",
  CONSOLIDATE = "consolidate",
  PRODUCTION = "production",
}

export enum EstimateItemsStatusesEnum {
  NEW = "new",
  CONFIRMED = "confirmed",
  CANCELED = "canceled",
}
