import React, { useMemo } from "react";

import Spinner from "shared/ui/atoms/Spinner/Spinner";

import { CHART_LOADING_MESSAGES } from "../../constants";

import styles from "./ChartLockedSpacer.module.scss";

export const ChartLockedSpacer = () => {
  const messages = useMemo(() => CHART_LOADING_MESSAGES.sort(() => (Math.random() > 0.5 ? 1 : -1)), []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Spinner isStatic />
        <div className={styles.message}>{messages[Math.floor(Date.now() / 5000) % messages.length]}</div>
      </div>
    </div>
  );
};
