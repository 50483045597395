import {
  DocumentsTypesEnum,
  DocumentsTypesNamesEnum,
  DocumentsTypesValues,
} from "../../../redux/modules/common/passportsAndCertificates/types";

import { EXPENDITURE_TYPES } from "constants/constant";
import { IExpenditure } from "types/interfaces/Expenditure";

export const PASSPORT = "passport";
export const CERTIFICATE = "certificate";

export const passportsTypesOptions = [
  { id: PASSPORT, name: "Паспорт качества" },
  { id: CERTIFICATE, name: "Сертификат соответствия" },
];

export const passportYearsLengthOptions = [
  { id: 1, name: "1 год" },
  { id: 2, name: "2 года" },
  { id: 3, name: "3 года" },
  { id: 4, name: "4 года" },
  { id: 5, name: "5 лет" },
];

export enum PassportsDisplayTypes {
  passport = "Паспорт",
  certificate = "Сертификат",
}

export const documentTypesOptionsByExpenditureType = {
  [EXPENDITURE_TYPES.MATERIAL]: [
    { id: DocumentsTypesEnum.test_report, name: DocumentsTypesNamesEnum.test_report },
    { id: DocumentsTypesEnum.quality_passport, name: DocumentsTypesNamesEnum.quality_passport },
    { id: DocumentsTypesEnum.certificate_of_conformity, name: DocumentsTypesNamesEnum.certificate_of_conformity },
    { id: DocumentsTypesEnum.file, name: DocumentsTypesNamesEnum.file },
  ],
  [EXPENDITURE_TYPES.EQUIPMENT]: [
    { id: DocumentsTypesEnum.test_report, name: DocumentsTypesNamesEnum.test_report },
    { id: DocumentsTypesEnum.passport, name: DocumentsTypesNamesEnum.passport },
    {
      id: DocumentsTypesEnum.installation_and_operating_instruction,
      name: DocumentsTypesNamesEnum.installation_and_operating_instruction,
    },
    { id: DocumentsTypesEnum.warranty_card, name: DocumentsTypesNamesEnum.warranty_card },
    { id: DocumentsTypesEnum.file, name: DocumentsTypesNamesEnum.file },
  ],
  [EXPENDITURE_TYPES.WORK]: [
    { id: DocumentsTypesEnum.executive_scheme, name: DocumentsTypesNamesEnum.executive_scheme },
    { id: DocumentsTypesEnum.executive_drawing, name: DocumentsTypesNamesEnum.executive_drawing },
    { id: DocumentsTypesEnum.file, name: DocumentsTypesNamesEnum.file },
  ],
  ks2: [
    { id: DocumentsTypesEnum.executive_scheme, name: DocumentsTypesNamesEnum.executive_scheme },
    { id: DocumentsTypesEnum.executive_drawing, name: DocumentsTypesNamesEnum.executive_drawing },
    { id: DocumentsTypesEnum.special_journal, name: DocumentsTypesNamesEnum.special_journal },
    { id: DocumentsTypesEnum.act_of_test_and_acceptance, name: DocumentsTypesNamesEnum.act_of_test_and_acceptance },
    { id: DocumentsTypesEnum.statement, name: DocumentsTypesNamesEnum.statement },
    { id: DocumentsTypesEnum.passport, name: DocumentsTypesNamesEnum.passport },
    {
      id: DocumentsTypesEnum.confirmation_of_specialists_qualifications,
      name: DocumentsTypesNamesEnum.confirmation_of_specialists_qualifications,
    },
    { id: DocumentsTypesEnum.file, name: DocumentsTypesNamesEnum.file },
  ],
  section: [
    { id: DocumentsTypesEnum.executive_scheme, name: DocumentsTypesNamesEnum.executive_scheme },
    { id: DocumentsTypesEnum.executive_drawing, name: DocumentsTypesNamesEnum.executive_drawing },
    { id: DocumentsTypesEnum.act_of_test_and_acceptance, name: DocumentsTypesNamesEnum.act_of_test_and_acceptance },
    { id: DocumentsTypesEnum.statement, name: DocumentsTypesNamesEnum.statement },
    { id: DocumentsTypesEnum.passport, name: DocumentsTypesNamesEnum.passport },
    { id: DocumentsTypesEnum.aook, name: DocumentsTypesNamesEnum.aook },
    {
      id: DocumentsTypesEnum.non_destructive_test_protocol,
      name: DocumentsTypesNamesEnum.non_destructive_test_protocol,
    },
    { id: DocumentsTypesEnum.file, name: DocumentsTypesNamesEnum.file },
  ],
  betonIncome: [{ id: DocumentsTypesEnum.quality_passport, name: DocumentsTypesNamesEnum.quality_passport }],
  betonExpense: [
    { id: DocumentsTypesEnum.quality_passport, name: DocumentsTypesNamesEnum.quality_passport },
    { id: DocumentsTypesEnum.certificate_of_conformity, name: DocumentsTypesNamesEnum.certificate_of_conformity },
  ],
  betonExpenseFinal: [
    { id: DocumentsTypesEnum.quality_passport, name: DocumentsTypesNamesEnum.quality_passport },
    { id: DocumentsTypesEnum.certificate_of_conformity, name: DocumentsTypesNamesEnum.certificate_of_conformity },
    { id: DocumentsTypesEnum.test_report, name: DocumentsTypesNamesEnum.test_report },
  ],
};
