import moment from "moment";

import { DocumentsTypesEnum, IAttachmentInExpenditure } from "redux/modules/common/passportsAndCertificates/types";

import { EXPENDITURE_TYPES } from "constants/constant";

type namesDictType = Record<string, boolean | undefined>;

export const getInitialDocType = {
  [EXPENDITURE_TYPES.MATERIAL]: DocumentsTypesEnum.test_report,
  [EXPENDITURE_TYPES.EQUIPMENT]: DocumentsTypesEnum.test_report,
  ks2: DocumentsTypesEnum.executive_scheme,
  ["betonIncome"]: DocumentsTypesEnum.quality_passport,
  ["betonExpense"]: DocumentsTypesEnum.quality_passport,
  ["betonExpenseFinal"]: DocumentsTypesEnum.test_report,
};

export const checkFieldExistInExpenditureAttachments = (docType: DocumentsTypesEnum, fieldName: string) => {
  switch (docType) {
    case DocumentsTypesEnum.test_report:
      const testReportFields: namesDictType = {
        number: true,
        date: true,
      };
      return testReportFields[fieldName];
    case DocumentsTypesEnum.quality_passport:
      const qualityPassportsFields: namesDictType = {
        number: true,
        date: true,
        range: true,
      };
      return qualityPassportsFields[fieldName];
    case DocumentsTypesEnum.certificate_of_conformity:
      const certificateFields: namesDictType = {
        number: true,
        date: false,
        start_at: true,
        end_at: true,
      };
      return certificateFields[fieldName];
    case DocumentsTypesEnum.special_journal:
      const specJournalFields: namesDictType = {
        number: true,
        title: true,
      };
      return specJournalFields[fieldName];
    case DocumentsTypesEnum.act_of_test_and_acceptance:
      const actOfTestFields: namesDictType = {
        title: true,
        date: true,
      };
      return actOfTestFields[fieldName];
    case DocumentsTypesEnum.passport:
      const passportFields: namesDictType = {
        title: true,
        number: true,
        date: true,
      };
      return passportFields[fieldName];
    case DocumentsTypesEnum.statement:
      return fieldName === "title";
    case DocumentsTypesEnum.confirmation_of_specialists_qualifications:
      return fieldName === "title";
    case DocumentsTypesEnum.aook:
      const aookFields: namesDictType = {
        number: true,
        date: true,
      };
      return aookFields[fieldName];
    case DocumentsTypesEnum.non_destructive_test_protocol:
      const nonDestrTestProtocolFields: namesDictType = {
        number: true,
        date: true,
      };
      return nonDestrTestProtocolFields[fieldName];
    case DocumentsTypesEnum.warranty_card:
      const warrantyFields: namesDictType = {
        number: true,
      };
      return warrantyFields[fieldName];
  }
  return false;
};

export const checkExpenditureAttachmentIsWarn = (document: IAttachmentInExpenditure, isTextResult?: boolean) => {
  if (
    document.type === DocumentsTypesEnum.quality_passport ||
    document.type === DocumentsTypesEnum.certificate_of_conformity
  ) {
    // + 1 для включения даты старта
    const diff = moment(document.end_at).diff(moment(), "days") + 1;

    if (diff + 1 <= 30) {
      return isTextResult ? "fail" : true;
    }
  }
  return isTextResult ? "success" : false;
};
