import Axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { IMimsInAdding } from "types/interfaces/Mims";

import { errorCatcher } from "utils/helpers/errorCatcher";

export interface IProps {
  objectId: string | number;
  newSectionId?: number | null;
  expWorkId?: number | null;
  defaultMimsList?: IMimsInAdding[];
  isWorkGroup?: boolean;
  groupId?: number;
  externalMimsData?: {
    allMims: any[];
    isLoading: boolean;
    loadMoreHandler: () => void;
    count: number;
    filterHandler: () => void;
  };
}

export const useMimsList = ({
  objectId,
  newSectionId,
  expWorkId,
  defaultMimsList,
  isWorkGroup,
  groupId,
  externalMimsData,
}: IProps) => {
  const [allMims, setAllMims] = useState<IMimsInAdding[]>(defaultMimsList || []);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(defaultMimsList?.length || 0);

  const [filters, setFilters] = useState<object | null>(null);

  React.useEffect(() => {
    if (!defaultMimsList) return;
    setAllMims(defaultMimsList);
    setCount(defaultMimsList?.length);
  }, [defaultMimsList]);

  const getMimsParams = useMemo(() => {
    const params = {
      ...filters,
      section_id: newSectionId,
      related_work_id: expWorkId,
      limit: 10,
    };

    for (const key of Object.keys(params)) {
      // @ts-ignore
      if (!params[key]) {
        // @ts-ignore
        delete params[key];
      }
    }
    return params;
  }, [filters, newSectionId, expWorkId]);

  const fetchMims = useCallback(() => {
    if (!!externalMimsData) return;
    if (isWorkGroup) {
      if (!groupId) return;
      setIsLoading(true);

      Axios.get(`/building/${objectId}/groups/${groupId}/facts/products/`)
        .then((response) => {
          setAllMims(response.data?.services);
          setCount(response.data?.services?.length);
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);

      Axios.get(`/building/${objectId}/estimate/ticket/mims/`, {
        params: getMimsParams,
      })
        .then((response) => {
          setAllMims(response.data.results);
          setCount(response.data.count);
        })
        .finally(() => setIsLoading(false));
    }
  }, [objectId, filters, newSectionId, expWorkId, isWorkGroup, groupId, externalMimsData]);

  useEffect(() => {
    fetchMims();
  }, [fetchMims]);

  const filterHandler = (value: React.MouseEvent, fieldName: string) => {
    setFilters((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const loadMoreHandler = () => {
    if (isWorkGroup) return;
    Axios.get(`/building/${objectId}/estimate/ticket/mims/`, {
      params: { ...getMimsParams, offset: allMims.length },
    }).then((response) => setAllMims((prev) => [...prev, ...response.data.results]));
  };

  return {
    allMims: externalMimsData?.allMims ?? allMims,
    isLoading: externalMimsData?.isLoading ?? isLoading,
    count: externalMimsData?.count ?? count,
    filterHandler: externalMimsData?.filterHandler ?? filterHandler,
    loadMoreHandler: externalMimsData?.loadMoreHandler ?? loadMoreHandler,
    fetchMims,
  };
};
