import cn from "classnames";
import React, { useRef, useState } from "react";

import useOnClickOutside from "hooks/useOnClickOutside";
import useEscapeHandler from "utils/hooks/useEscapeHandler";

import ArrowSmallUpGrey from "images/icons/ArrowSmallUpGrey";
import Line from "images/icons/Line";

import styles from "./index.module.scss";

export type SelectedOption = string | number | { label: string };

interface Iprops {
  children?: React.ReactNode;
  selectedOption?: SelectedOption;
  titleClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  selectClassName?: string;
  selectClassNameOpen?: string;
  optionsBlockClassName?: string;
  rotate?: boolean;
  lastEl?: boolean;
}

const SelectMenu: React.FC<Iprops> = ({
  children,
  selectedOption,
  titleClassName,
  placeholder = "Выберите",
  disabled = false,
  selectClassName,
  selectClassNameOpen,
  optionsBlockClassName,
  rotate,
  lastEl,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const setToggle = () => setIsOpen(!isOpen);

  const getLabel = (selectedOption?: SelectedOption, placeholder?: string) => {
    if (selectedOption && (typeof selectedOption === "string" || typeof selectedOption === "number")) {
      return selectedOption;
    } else if (selectedOption && typeof selectedOption === "object" && "label" in selectedOption) {
      return selectedOption.label;
    }
    return placeholder;
  };

  useEscapeHandler(() => setIsOpen(false));
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div
      className={cn(styles.select, selectClassName, {
        [styles.isOpen]: isOpen && !disabled,
        [styles.disabled]: disabled,
        [selectClassNameOpen!]: isOpen,
      })}
      onClick={setToggle}
      ref={ref}
    >
      <span className={cn(styles.title, titleClassName)}>{getLabel(selectedOption, placeholder)}</span>
      {children ? (
        <div style={{ marginRight: "1.25rem" }}>
          {rotate ? (
            <ArrowSmallUpGrey rotate={isOpen ? "0" : "180"} />
          ) : (
            <ArrowSmallUpGrey rotate={isOpen ? "180" : "90"} />
          )}
        </div>
      ) : (
        ""
      )}
      {lastEl ? "" : <Line width={"0.125rem"} height={"2.313rem"} />}
      {!disabled && children ? (
        <div
          className={cn(styles.optionsBlock, {
            [styles.isOpen]: isOpen,
            [optionsBlockClassName!]: isOpen,
          })}
        >
          {children}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default React.memo(SelectMenu);
