import { message } from "antd";
import { Dispatch } from "redux";

import { billsApi } from "./api";
import { billsActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const getBillDetailed = (id: string) => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().billsV2.detailedBills[id]) {
    dispatch(billsActions.setIsLoading({ key: id, status: true }));
  }
  billsApi
    .getDetailed(id)
    .then(({ data }) => {
      dispatch(billsActions.setDetailed({ key: id, data }));
    })
    .finally(() => {
      dispatch(billsActions.setIsLoading({ key: id, status: false }));
    });
};

export const changeBillDate = (invoiceId: string, date: string) => (dispatch: Dispatch) => {
  billsApi
    .changeBillDate(invoiceId, date)
    .then((resp) => {
      message.success("Дата счета успешно изменена");
      dispatch(billsActions.invalidateKey());
    })
    .catch(errorCatcher);
};

export const changePaymentDate = (invoiceId: string, paymentId: number, date: string) => (dispatch: Dispatch) => {
  billsApi
    .changePaymentDate(invoiceId, paymentId, date)
    .then((resp) => {
      message.success("Дата платежа успешно изменена");
      dispatch(billsActions.invalidateKey());
    })
    .catch(errorCatcher);
};

export const saveBillNumber = (invoiceId: string, number: number) => (dispatch: Dispatch) => {
  billsApi
    .saveBillNumber(invoiceId, number)
    .then(() => {
      dispatch(billsActions.invalidateKey());
      message.success("Номер успешно сохранен");
    })
    .catch(errorCatcher);
};

export const attachFilesToBill = (invoiceId: string, files: File[]) => (dispatch: Dispatch) => {
  if (!files) return;
  message.success("Загрузка");
  billsApi
    .attachFilesToBill(invoiceId, files)
    .then((resp) => {
      message.success("Файл прикреплен");
      dispatch(billsActions.invalidateKey());
    })
    .catch(errorCatcher);
};

export const deleteFileFromBill = (invoiceId: string, idFile: number) => (dispatch: Dispatch) => {
  billsApi
    .deleteFileFromBill(invoiceId, idFile)
    .then(() => {
      message.success("Файл удален");
      dispatch(billsActions.invalidateKey());
    })
    .catch(errorCatcher);
};

export const payPayment = (invoiceId: string, paymentId: number) => (dispatch: Dispatch) => {
  billsApi
    .payPayment(invoiceId, paymentId)
    .then((resp) => {
      message.success("Отмечено оплаченным");
      dispatch(billsActions.invalidateKey());
    })
    .catch(errorCatcher);
};

export const addPaymentFile = (invoiceId: string, paymentId: number, file: File) => (dispatch: Dispatch) => {
  if (!file) return;
  billsApi
    .addPaymentFile(invoiceId, paymentId, file)
    .then((response) => {
      message.success("Файл прикреплен");
      dispatch(billsActions.invalidateKey());
    })
    .catch(errorCatcher);
};
