import cn from "classnames";
import React, { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";

import { ChartCheckpointsMarks } from "redux/modules/common/chart/types";

import { ChartContext } from "components/pages/Chart/Chart";
import { DAYS_ABBRS } from "components/pages/Chart/constants";
import { useChartUnitMultiplier } from "components/pages/Chart/hooks/useChartUnitMultiplier";

import { openViewCheckpointModalList } from "widgets/ViewCheckpoint/model/thunks";

import { generateWeeksForLine } from "../ChartDateLine.utils/generateWeeksForLine";
import { weekDatesRange } from "../ChartDateLine.utils/weekDatesRange";

import styles from "./ChartDateLineWeeks.module.scss";

export interface IWeeksLineProps {
  year: number;
  unitOffset: number;
  checkpointsMarks: ChartCheckpointsMarks["yearWeeks"];
}

export const ChartDateLineWeeks = React.forwardRef(({ year, unitOffset, checkpointsMarks }: IWeeksLineProps, ref) => {
  const dispatch = useDispatch();
  const weeks = useMemo(() => generateWeeksForLine(+year), [year]);
  const unitMultiplier = useChartUnitMultiplier();

  const { projectId } = useContext(ChartContext);

  const openCheckpointModal = (week: number) => () => {
    const { startDate, endDate } = weekDatesRange({ year, week });
    dispatch(openViewCheckpointModalList({ startDate, endDate, objectId: projectId }));
  };

  return (
    <>
      <div
        className={styles.weeksLine}
        ref={(r) => {
          // @ts-ignore
          if (!ref.current) return;
          // @ts-ignore
          ref.current[year] = r;
        }}
        style={{ left: `${!!unitOffset ? unitOffset * unitMultiplier : 0}rem` }}
      >
        {weeks?.map((week, index) => {
          const checkpointKey = `${year}-${index + 1}`;
          return (
            <React.Fragment key={index}>
              <div
                className={cn(styles.weekCaptionBlock, {
                  startMonth: week.startMonth,
                  [styles.hasCheckpoint]: checkpointsMarks[checkpointKey],
                })}
                style={{
                  left: `${index * 7 * unitMultiplier}rem`,
                  width: `${7 * unitMultiplier}rem`,
                }}
                data-year={year}
                data-month={week.month}
                data-date={week.startDate}
                onClick={checkpointsMarks[checkpointKey] ? openCheckpointModal(index + 1) : undefined}
              >
                <span>Неделя {index + 1}</span>
                <span className={styles.weekInterval}>{week.label}</span>
              </div>
              {week.today !== undefined && (
                <div
                  className={styles.currentWeekDay}
                  style={{
                    left: `${(index * 7 + week.today) * unitMultiplier}rem`,
                  }}
                >
                  <span>{DAYS_ABBRS[week.today]}</span>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
});
