import { useEffect } from "react";

import { useTypedSelector } from "app/store/typedUseSelector";

export const useMe = () => {
  const { user } = useTypedSelector((state) => state.auth);

  return user as IUserPartial;
};

type IUserPartial = {
  key: string;
  welcome_tour: boolean;
  roles: {
    id: number;
    name: string;
    alias: string;
  }[];
  general_entity: any;
  public_modules: ["PURCHASE", "PRODUCTION", "CONSTRUCTION"];
  my_entities: [];
  approval: Record<
    string,
    {
      is_responsible: false;
      is_pto: false;
      is_payment_decision_employee: false;
      is_timesheet_responsible: false;
      is_stock_responsible: false;
      is_estimate_responsible: false;
      is_requisition_approval: false;
      is_order_approval: false;
      is_payment_approval: false;
      is_timesheet_approval: false;
      is_planconfirm_approval: false;
      is_ticket_approval: false;
    }
  >;
  id: number;
  account_type: "pcr";
  email: string;
  full_name: string;
  tariff: "PROFESSIONAL";
  address: string;
  tariff_size: number;
  used_size: string;
  max_buildings_count: number;
  active_buildings_count: number;
  available_type_projects: "business_pro";
  language: "en";
  first_name: string;
  last_name: string;
  middle_name: string;
  is_blocked: false;
  full_access: true;
  is_staff: false;
  position: string;
  system_modules: {
    tasks: {
      sections: {
        viewer: true;
        my_tasks: true;
        all_tasks: true;
        list_tasks: true;
        created_tasks: true;
      };
      is_enable: true;
    };
    control: {
      sections: {
        graph: false;
        projects: false;
        analytics: false;
        documents: false;
        financial: false;
        contr_agent: false;
      };
      is_enable: false;
    };
    purchase: {
      sections: {
        graph: false;
        orders: false;
        documents: false;
        contr_agent: false;
        applications: false;
      };
      is_enable: false;
    };
    production: {
      sections: {
        beton: false;
        graph: false;
        stock: false;
        journal: false;
        projects: false;
        documents: false;
        employees: false;
        plan_fact: false;
        resources: false;
        applications: false;
      };
      is_enable: false;
    };
  };
};
