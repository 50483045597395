import { message } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";

import { isAbleToCreateProjectSelector } from "redux/modules/_TODO/auth";

import Positions from "./components/Positions/Positions";
import Profiles from "./components/Profile";
import Users from "./components/Users";
import Projects from "pages/Projects/Projects";

import TabBarNotLinks from "../../../shared/ui/controls/TabBar/TabBarNotLinks";
import ProjectSettings from "./ProjectsSettings/ProjectSettings";
import ProjectsV2Settings from "./ProjectsV2Settings/ProjectsV2Settings";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import ButtonBase from "shared/ui/controls/ButtonBase";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

import { useLocationArray } from "utils/hooks/useLocationArray";
import { useUrlModule } from "utils/hooks/useUrlModule";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import styles from "./Settings.module.scss";

const Settings = ({ match }) => {
  const history = useHistory();
  const matchTab = useRouteMatch(`${match.path}/:tab`);
  const tab = matchTab && matchTab.params.tab;
  //const rootPath = useUrlModule();
  const fullRootPath = useUrlModuleWithObjectId();
  // const { objectId, projectId } = useParams();
  const locationArr = useLocationArray();

  // const isConstructing = rootPath === "constructing";

  const switchTab = (tabName) => history.push(`${match.url}/${tabName}`);

  const onCreateProject = () => {
    history.push(`/${fullRootPath}/settings/project/new/passport`);
  };

  const isAbleToCreateProject = useSelector(isAbleToCreateProjectSelector);

  const handleCreateProjectClick = React.useCallback(() => {
    const projectId = locationArr.filter((el) => !isNaN(+el)).at(-1);
    if (!isAbleToCreateProject) {
      message.error("Вы достигли максимального кол-ва проектов");
      return;
    }
    history.push(`/${fullRootPath}/settings/object/${projectId}/new/passport`);
  }, [isAbleToCreateProject, locationArr]);

  const backHandler = () => {
    history.push(`/${fullRootPath}/settings/projects`);
  };

  return (
    <TemplateBase>
      <div className={styles.container}>
        <ListPageHeaderTemplate>
          <TabBarNotLinks
            activeId={tab}
            onClick={switchTab}
            className={styles.tabs}
            tabs={[
              { id: "profiles", text: "Профиль" },
              { id: "users", text: "Сотрудники" },
              { id: "projects", text: "Проекты" },
              { id: "positions", text: "Должности" },
            ]}
          />
          <Route
            exact
            path={`${match.path}/projects`}
            render={() => (
              <ButtonBase secondary onClick={onCreateProject}>
                Создать проект
              </ButtonBase>
            )}
          />
          <Route
            exact
            path={`${match.path}/objects/:projectId`}
            render={() => (
              <>
                <BackNavigationBar onBack={backHandler} title="Проекты" />
                <ButtonBase buttonPrimaryEmpty className={styles.addButtonClass} onClick={handleCreateProjectClick}>
                  Создать объект
                </ButtonBase>
              </>
            )}
          />
        </ListPageHeaderTemplate>

        <Switch>
          <Route exact path={match.path}>
            <Redirect to={`${match.url}/profiles`} />
          </Route>
          <Route exact path={`${match.path}/profiles`} component={Profiles} />
          <Route exact path={`${match.path}/users`} component={Users} />
          <Route exact path={`${match.path}/positions`} component={Positions} />

          <Route exact path={`${match.path}/projects`} render={() => <ProjectsV2Settings path={match.path} />} />
          <Route exact path={`${match.path}/objects/:projectId`} render={() => <ProjectSettings path={match.path} />} />
        </Switch>
      </div>
    </TemplateBase>
  );
};

export default Settings;
