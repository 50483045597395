import OpportunImgSvg from './img/opportun-img.svg';
import SitesPgMob from './img/sites-pg-mob.svg';

export const OpportunitySection = () => (
  <section className="opportun__main" id="opportunities">
    <div className="container">
      <div className="opportun__flex">
        <div className="opportun__info">
          <h1>Возможности для производства</h1>
          <p>Платформа обеспечивает управление процессом СМР от планирования
            производства до формирования исполнительной документации заказчику</p>
          <button data-val="modal" className="open_modal button">Запросить демонстрацию</button>
        </div>
        <div className="opportun__photo">
          <img src={OpportunImgSvg} alt="image" />
        </div>
      </div>
    </div>
    <div className="opportun-mob__pic">
      <img src={SitesPgMob} alt="image" />
    </div>
  </section>
)