import axios from "axios";
import { createSelector } from "reselect";

import { messageErrorHandler } from "utils/helpers/errorHandler";

const moduleName = "bills";

export const GET_LIST = `${moduleName}/GET_LIST`;
export const SET_LIST = `${moduleName}/SET_LIST`;
export const SET_MORE_BILLS = `${moduleName}/SET_MORE_BILLS`;
export const SET_LIST_LOADING = `${moduleName}/SET_LIST_LOADING`;

export const DEFAULT_LIMIT_BILL = 10;

const initialState = {
  list: null,
  listLoading: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LIST:
      return {
        ...state,
        openedBill: null,
        openedBillLoading: true,
      };

    case SET_LIST:
      return {
        ...state,
        list: payload,
      };
    case SET_MORE_BILLS:
      return {
        ...state,
        list: {
          ...state?.list,
          results: [...state?.list?.results, ...payload?.results],
        },
      };

    case SET_LIST_LOADING:
      return {
        ...state,
        listLoading: payload,
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const billsListSelector = createSelector(stateSelector, (state) => state.list);
export const billsListLoadingSelector = createSelector(stateSelector, (state) => state.listLoading);

const setList = (list) => ({
  type: SET_LIST,
  payload: list,
});

export function apiGetLoadBills(config) {
  return axios.get(`/purchaser/invoices/`, config);
}

export const loadBills = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (paginationParams, filterParams, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        limit: DEFAULT_LIMIT_BILL,
        ordering: sorting,
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };
    return async (dispatch, getState) => {
      if (!getState().bills?.list?.results?.length) {
        dispatch({ type: SET_LIST_LOADING, payload: true });
      }

      try {
        const { data } = await apiGetLoadBills(config);
        await dispatch(setList(data));
      } catch (error) {
        messageErrorHandler(error?.response?.data?.errors);
      } finally {
        dispatch({ type: SET_LIST_LOADING, payload: false });
      }
    };
  };
})();

export const loadMoreBills = (params) => {
  const config = { params: { ...params, limit: DEFAULT_LIMIT_BILL } };

  return (dispatch) => {
    axios
      .get(`purchaser/invoices`, config)
      .then((response) =>
        dispatch({
          type: SET_MORE_BILLS,
          payload: response.data,
        })
      )
      .catch((error) => console.error(error));
  };
};
