export const sanitizeObject = <T extends Record<string, unknown>>(obj: T, excludingType: unknown) => {
  const filteredEntries = Object.entries(obj).filter(([key, value]) => {
    if (typeof excludingType === "string") {
      return typeof value !== typeof excludingType;
    }
    if (excludingType === null) {
      return value !== null;
    }
    return true;
  });

  return Object.fromEntries(filteredEntries) as Partial<T>;
};
