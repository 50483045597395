import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useTypedSelector } from "app/store/typedUseSelector";

import { summaryUtils } from "./utils";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const useSummaryTreeSelector = () => {
  const { objectId, projectId } = useParams<Record<"objectId" | "projectId", string>>();
  const { dataTree, loadings, totals } = useTypedSelector((state) => state.financeReducer.summary);

  const displayingTree = useMemo(() => {
    if (projectId === "0") {
      return dataTree;
    }
    if (objectId === "0" && projectId !== "0") {
      return summaryUtils.extractTreeByProject(dataTree, +projectId);
    }
    return summaryUtils.extractTreeByObject(dataTree, +projectId, +objectId);
  }, [dataTree, objectId, projectId]);

  const tableData = useMemo(() => {
    return summaryUtils.extractTableData(displayingTree);
  }, [displayingTree]);

  const showSpinner = useMemo(() => {
    if (projectId === "0") {
      return loadings["all"];
    }
    if (objectId === "0" && projectId !== "0") {
      return loadings[generateStorageKey({ projectId, type: "project" })];
    }
    return loadings[generateStorageKey({ buildingId: objectId, type: "object" })];
  }, [objectId, projectId, loadings]);

  const displayingTotals = useMemo(() => {
    if (projectId === "0") {
      return null;
    }
    if (objectId === "0" && projectId !== "0") {
      return totals[generateStorageKey({ type: "project", id: projectId })];
    }
    return totals[generateStorageKey({ type: "object", id: objectId })];
  }, [totals, projectId, objectId]);

  return {
    displayingTree,
    tableData,
    showSpinner,
    displayingTotals,
  };
};
