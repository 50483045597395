import cn from "classnames";
import React, { MouseEventHandler } from "react";

import { SortingIcon } from "./SortingIcon";

import styles from "./Sorting.module.scss";

interface IProps {
  isTop?: boolean;
  isDown?: boolean;
  onClick: MouseEventHandler;
}

const Sorting: React.FC<IProps> = ({ isDown, isTop, onClick }) => {
  return (
    <div onClick={onClick} className={cn({ [styles.root]: true, [styles.top]: isTop, [styles.down]: isDown })}>
      <SortingIcon />
    </div>
  );
};

export default Sorting;
