export const WORK_STATUSES = [
  { id: "Я", name: "Я", description: "Явка" },
  {
    id: "НН",
    name: "НН", description: "Неявка",
    withoutCount: true
  },
  { id: "ОТ", name: "ОТ", description: "Отпуск", withoutCount: true },
  {
    id: "Б",
    name: "Б",
    description: "Больничный",
    withoutCount: true
  },
  { id: "К", name: "К", description: "Командировка" },
  { id: "Н", name: "Н", description: "Продолжительность работы в ночное время" },
  { id: "РВ", name: "РВ", description: "Продолжительность работы в выходные и нерабочие праздничные дни" },
  { id: "С", name: "С", description: "Продолжительность сверхурочной работы" },
  { id: "ВМ", name: "ВМ", description: "Продолжительность работы вахтовым методом" },
  { id: "ПК", name: "ПК", description: "Повышение квалификации с отрывом от работы" },
  { id: "ПМ", name: "ПМ", description: "Повышение квалификации с отрывом от работы в другой местности" },
  { id: "ОД", name: "ОД", description: "Ежегодный дополнительный оплачиваемый отпуск", withoutCount: true },
  {
    id: "У",
    name: "У",
    description: "Дополнительный отпуск в связи с обучением с сохранением среднего заработка работникам, совмещающим работу с обучением",
    withoutCount: true
  },
  {
    id: "УВ",
    name: "УВ",
    description: "Сокращенная продолжительность рабочего времени для обучающихся без отрыва от производства с частичным сохранением заработной платы"
  },
  {
    id: "УД",
    name: "УД", description: "Дополнительный отпуск в связи с обучением без сохранения заработной платы",
    withoutCount: true
  },
  {
    id: "Р",
    name: "Р",
    description: "Отпуск по беременности и родам (отпуск в связи с усыновлением новорожденного ребенка)",
    withoutCount: true
  },
  {
    id: "ОЖ",
    name: "ОЖ", description: "Отпуск по уходу за ребенком до достижения им возраста трех лет",
    withoutCount: true
  },
  {
    id: "ДО",
    name: "ДО",
    description: "Отпуск без сохранения заработной платы, предоставляемый работнику по разрешению работодателя",
    withoutCount: true
  },
  {
    id: "ОЗ",
    name: "ОЗ",
    description: "Отпуск без сохранения заработной платы при условиях, предусмотренных действующим законодательством Российской Федерации",
    withoutCount: true
  },
  {
    id: "ДБ",
    name: "ДБ", description: "Ежегодный дополнительный отпуск без сохранения заработной платы",
    withoutCount: true
  },
  {
    id: "Т",
    name: "Т",
    description: "Временная нетрудоспособность без назначения пособия в случаях, предусмотренных законодательством",
    withoutCount: true
  },
  {
    id: "ЛЧ",
    name: "ЛЧ",
    description: "Сокращенная продолжительность рабочего времени против нормальной продолжительности рабочего дня в случаях, предусмотренных законодательством"
  },
  {
    id: "ПВ",
    name: "ПВ",
    description: "Время вынужденного прогула в случае признания увольнения, перевода на другую работу или отстранения от работы незаконными с восстановлением на прежней работе",
    withoutCount: true
  },
  {
    id: "ПР",
    name: "ПР",
    description: "Прогулы (отсутствие на рабочем месте без уважительных причин в течение времени, установленного законодательством)",
    withoutCount: true
  },
  {
    id: "НС",
    name: "НС",
    description: "Продолжительность работы в режиме неполного рабочего времени по инициативе работодателя в случаях, предусмотренных законодательством"
  },
  {
    id: "В",
    name: "В", description: "Выходные дни (еженедельный отпуск) и нерабочие праздничные дни",
    withoutCount: true
  },
  {
    id: "ОВ",
    name: "ОВ", description: "Дополнительные выходные дни (оплачиваемые)",
    withoutCount: true
  },
  {
    id: "НВ",
    name: "НВ", description: "Дополнительные выходные дни (без сохранения заработной платы)",
    withoutCount: true
  },
  {
    id: "ЗБ",
    name: "ЗБ", description: "Забастовка (при условиях и в порядке, предусмотренных законом)",
    withoutCount: true
  },
  {
    id: "РП",
    name: "РП", description: "Время простоя по вине работодателя"
  },
  {
    id: "НП",
    name: "НП", description: "Время простоя по причинам, не зависящим от работодателя и работника"
  },
  {
    id: "ВП",
    name: "ВП", description: "Время простоя по вине работника"
  },
  {
    id: "НО",
    name: "НО",
    description: "Отстранение от работы (недопущение к работе) с оплатой (пособием) в соответствии с законодательством",
    withoutCount: true
  },
  {
    id: "НЗ",
    name: "НЗ", description: "Время приостановки работы в случае задержки выплаты заработной платы"
  },
  {
    id: "НБ",
    name: "НБ",
    description: "Отстранение от работы (недопущение к работе) по причинам, предусмотренным законодательством, без начисления заработной платы",
    withoutCount: true
  }
];