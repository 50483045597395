import React, { useState } from "react";
import { Field } from "react-final-form";

import Select, { IOption } from "components/UI/atoms/Select";

import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import InputBase from "shared/ui/inputs/InputBase";

import { composeFieldValidators, required } from "utils/formHelpers/validations";

import styles from "./LabeledSwitchingItem.module.scss";

interface IProps {
  label: string;
  fieldName: string;
  options: IOption[];
}

const LabeledSwitchingItem: React.FC<IProps> = ({ label, fieldName, options }) => {
  const [isSelect, setIsSelect] = useState(true);

  return (
    <LabeledItem
      label={label}
      isDefaultHeight={false}
      additionalHeader={
        <div className={styles.btn} onClick={() => setIsSelect((p) => !p)}>
          {true && (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 2H7V7H2V9H7V14H9V9H14V7H9V2Z" fill="#4FB1EB" />
            </svg>
          )}
          {isSelect ? "новый" : "выбрать"}
        </div>
      }
    >
      <Field
        name={fieldName}
        validate={composeFieldValidators(required())}
        render={({ input, meta }) => {
          if (!isSelect) {
            return <InputBase meta={meta} input={input} className={styles.select} />;
          }

          return (
            <Select
              containerClassName={styles.select}
              value={input.value}
              options={options}
              onChange={(_, name) => {
                input.onChange(name);
              }}
              onOpen={() => {
                input.onFocus();
                input.onBlur();
              }}
              meta={meta}
            />
          );
        }}
      />
    </LabeledItem>
  );
};

export default LabeledSwitchingItem;
