import React, { useCallback } from 'react';
import cn from "classnames";

import styles from "./IndicatorsWrapper.module.scss";


interface Iprops {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  containerClassName?: string;
  contentClassName?: string;
}

function IndicatorsWrapper({
  isOpen,
  setIsOpen,
  children,
  containerClassName,
  contentClassName
}: Iprops) {
  const openCloseSwitcher = useCallback(() => {
    setIsOpen(prev => !prev)
  }, [setIsOpen]);

  return (
    <div className={cn(styles.container, containerClassName, { [styles.open]: isOpen })}>
      <button onClick={openCloseSwitcher} className={cn(styles.btn, { [styles.activeBtn]: isOpen })}>
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.592072 9L6.13306 4.5L0.592072 0" fill="black" />
        </svg>
      </button>
      <div className={cn(styles.content, contentClassName)}>
        {children}
      </div>
    </div>
  )
}

export default React.memo(IndicatorsWrapper);