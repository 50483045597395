import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { actingDetailedKs2Selector } from "redux/modules/common/building/journal/acting/selectors";
import { getDetailedKs2 } from "redux/modules/common/building/journal/acting/thunks";

import JournalActingExpenditure from "../common/JournalActingExpenditure/JournalActingExpenditure";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { LOCALIZATION_CONFIG } from "constants/localization";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import styles from "./JournalKs2Level.module.scss";

interface IProps {
  ks2Id: number;
}

const JournalKs2Content: React.FC<IProps> = ({ ks2Id }) => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const ks2data = useSelector(actingDetailedKs2Selector)[ks2Id];

  React.useEffect(() => {
    dispatch(getDetailedKs2(objectId, ks2Id));
  }, [ks2Id, objectId]);

  return (
    <>
      <TableReusableRow isHoverable={false} containerClassName={styles.head} className={styles.headRow}>
        <TableReusableCell className={styles.number} isRighted>
          №
        </TableReusableCell>
        <TableReusableCell isCentered>Наименование</TableReusableCell>
        <TableReusableCell isCentered>Ед.изм.</TableReusableCell>
        <TableReusableCell isCentered>Сдано</TableReusableCell>
        <TableReusableCell isCentered>Остаток</TableReusableCell>
        <TableReusableCell isCentered>К сдаче</TableReusableCell>
        <TableReusableCell isRighted>Стоимость, {LOCALIZATION_CONFIG.currency} </TableReusableCell>
      </TableReusableRow>
      {ks2data?.items?.map((section) =>
        section.expenditures.map((el, i) => (
          <JournalActingExpenditure
            sectionName={section.section_name} //@ts-ignore
            expenditure={el}
            isFirstInParent={i === 0}
            type="ks2"
          />
        ))
      )}
    </>
  );
};

export default JournalKs2Content;
