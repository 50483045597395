import axios from "axios";

export function apiGetProduct(expenditureId) {
  return axios
    .get(`/requisition/${expenditureId}/products/`)
    .then(
      ({ data }) => data,
      (error) => {
        console.error(error);
      }
    )
    .catch((e) => console.error(e));
}
