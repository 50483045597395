import React from "react";

import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import JournalExecRow from "./components/JournalExecRow/JournalExecRow";

import styles from "./JournalExecTicketV2.module.scss";

interface IProps {
  ticket: IExpendituresInJournalFulfillment["items"][0];
  sectionId: number;
  month: number;
  year: number;
  stockId: number;
}

const JournalExecTicketV2: React.FC<IProps> = ({ ticket, sectionId, month, year, stockId }) => {
  return (
    <div>
      <JournalExecRow ticket={ticket} sectionId={sectionId} month={month} year={year} stockId={stockId} />
    </div>
  );
};

export default JournalExecTicketV2;
