import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import {
  getOrders,
  ordersDataSelector,
  ordersLoadingSelector,
} from "../../../../../redux/modules/common/building/requisition/reducer";

import Comparison from "./components/Comparison/Comparison";

import { Spinner } from "../../../../../shared/ui/atoms/Spinner/Spinner";
import Order from "../Order";
import OrdersTabs from "../OrdersTabs";

import {
  COMPLETED,
  NEW,
  PAYMENT_DECISION,
  PCR_NEED_APPROVE,
  PCR_REJECTED,
  PVR_NEED_APPROVE,
  PVR_REJECTED,
  WAITING_PAYMENT,
  WAITING_SHIPMENT,
} from "../../../../../constants/constant";

import useArrayItemsChecker from "../../../../../hooks/useArrayItemsChecker";

import styles from "./index.module.scss";

const EMPTY_ARRAY = [];

const Orders = ({ isPayment, requisitionId, permissions, isSimplified, isShownComparison }) => {
  const dispatch = useDispatch();
  const orders = useSelector(ordersDataSelector);
  const ordersAreLoading = useSelector(ordersLoadingSelector);

  const toComparisonOrdersChecker = useArrayItemsChecker(orders || EMPTY_ARRAY, "id");

  const [tab, setTab] = useState(isPayment ? "awaitPayment" : "active");
  const [openingOrderId, setOpeningOrderId] = useState(null);

  const newOrders = useMemo(() => {
    if (!orders) return [];
    return orders.filter((order) => order.status === PCR_NEED_APPROVE);
  }, [orders]);

  const awaitOrders = useMemo(() => {
    if (!orders) return [];
    return orders.filter((order) => [NEW, PVR_NEED_APPROVE].indexOf(order.status) !== -1);
  }, [orders]);

  const declineOrders = useMemo(() => {
    if (!orders) return [];
    return orders.filter((order) => [PVR_REJECTED, PCR_REJECTED].indexOf(order.status) !== -1);
  }, [orders]);

  const awaitPaymentOrders = useMemo(() => {
    if (!orders) return [];
    return orders.filter((order) => order.status === PAYMENT_DECISION);
  }, [orders]);

  const paymentDoneOrders = useMemo(() => {
    if (!orders) return [];
    return orders.filter((order) => [WAITING_SHIPMENT, COMPLETED, WAITING_PAYMENT].indexOf(order.status) !== -1);
  }, [orders]);

  const handleOpeningOrder = useCallback(
    (orderId) => setOpeningOrderId((prevState) => (prevState === orderId ? null : orderId)),
    []
  );

  const toComparisonOrders = useMemo(() => {
    if (!orders) return [];
    return orders.filter((order) => toComparisonOrdersChecker.items[order.id]);
  }, [orders, toComparisonOrdersChecker.items]);

  useEffect(() => setTab(isPayment ? "awaitPayment" : "active"), [isPayment]);

  useEffect(() => {
    if (ordersAreLoading || orders) return;
    compose(dispatch, getOrders)(requisitionId);
  }, [requisitionId]);

  if (ordersAreLoading || !orders) return <Spinner />;

  return (
    <div className={styles.container}>
      <OrdersTabs
        awaitPaymentOrders={awaitPaymentOrders}
        paymentDoneOrders={paymentDoneOrders}
        isPayment={isPayment}
        tab={tab}
        setTab={setTab}
        newOrders={newOrders}
        awaitOrders={awaitOrders}
        declineOrders={declineOrders}
      />
      {tab === "awaitPayment" &&
        awaitPaymentOrders.map((order) => (
          <Order
            order={order}
            isOpen={openingOrderId === order.id}
            handleOpening={handleOpeningOrder}
            permissions={permissions}
            isSimplified={isSimplified}
            isChecked={toComparisonOrdersChecker.items[order.id]}
            onCheck={toComparisonOrdersChecker.checkOnce}
            key={order.id}
          />
        ))}
      {tab === "paymentDone" &&
        paymentDoneOrders.map((order) => (
          <Order
            order={order}
            isOpen={openingOrderId === order.id}
            handleOpening={handleOpeningOrder}
            permissions={permissions}
            isSimplified={isSimplified}
            isChecked={toComparisonOrdersChecker.items[order.id]}
            onCheck={toComparisonOrdersChecker.checkOnce}
            key={order.id}
          />
        ))}
      {tab === "active" &&
        newOrders.map((order) => (
          <Order
            order={order}
            isOpen={openingOrderId === order.id}
            handleOpening={handleOpeningOrder}
            permissions={permissions}
            isSimplified={isSimplified}
            isChecked={toComparisonOrdersChecker.items[order.id]}
            onCheck={toComparisonOrdersChecker.checkOnce}
            key={order.id}
          />
        ))}
      {tab === "await" &&
        awaitOrders.map((order) => (
          <Order
            order={order}
            isOpen={openingOrderId === order.id}
            handleOpening={handleOpeningOrder}
            permissions={permissions}
            isSimplified={isSimplified}
            isChecked={toComparisonOrdersChecker.items[order.id]}
            onCheck={toComparisonOrdersChecker.checkOnce}
            key={order.id}
          />
        ))}
      {tab === "decline" &&
        declineOrders.map((order) => (
          <Order
            order={order}
            isOpen={openingOrderId === order.id}
            handleOpening={handleOpeningOrder}
            permissions={permissions}
            isSimplified={isSimplified}
            isChecked={toComparisonOrdersChecker.items[order.id]}
            onCheck={toComparisonOrdersChecker.checkOnce}
            key={order.id}
          />
        ))}
      {isShownComparison && <Comparison orders={toComparisonOrders} />}
    </div>
  );
};

export default React.memo(Orders);
