import React, { memo } from "react";

import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import ListRow from "./_internal/ListRow";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./_internal/ListRow.module.scss";

interface IProps {
  data: IFinanceTreeItem[];
  onClickItem: (subTree: any) => void;
  renderExtraControl?: any;
  loadings?: Record<string, boolean>;
  getIsWithBorderTop?: (row: IFinanceTreeItem) => boolean;
}

const List: React.FC<IProps> = (props) => {
  const { data, onClickItem, getIsWithBorderTop, renderExtraControl, loadings } = props;

  return (
    <>
      {data?.map((section) => {
        return (
          <React.Fragment key={section.key}>
            <ListRow
              data={section}
              depth={section.depth}
              handleListItemClick={onClickItem}
              renderExtraControl={renderExtraControl}
              loadings={loadings}
              getIsWithBorderTop={getIsWithBorderTop}
            />
            {section.isOpen && (
              <List
                loadings={loadings}
                data={section.children!}
                onClickItem={onClickItem}
                renderExtraControl={renderExtraControl}
                getIsWithBorderTop={getIsWithBorderTop}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default memo(List);
