import cn from "classnames";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";

import LabeledSwitch from "components/UI/atoms/_TODO/LabeledSwitch";
import JournalActing from "components/pages/Journal/components/JournalActing/JournalActing";
import Remarks from "components/pages/Journal/components/Remarks/Remarks";

import { useConstructionControlRedirectToObject } from "../common/useConstructionControlRedirectToObject";
import ConstructionControlJournalAcceptance from "./ConstructionControlJournalAcceptance/ConstructionControlJournalAcceptance";
import ConstructionControlJournalExecution from "./ConstructionControlJournalExecution/ConstructionControlJournalExecution";
import CustomBreadCrumbsMenu from "entities/CustomBreadcrumbs/CustomBreadCrumbsMenu";
import CustomBreadcrumbs from "entities/CustomBreadcrumbs/CustomBreadcrumbs";
import BreadcrumbsObjectSelect from "features/templates/BreadcrumbsSelects/BreadcrumbsObjectSelect";
import BreadcrumbsProjectSelect from "features/templates/BreadcrumbsSelects/BreadcrumbsProjectSelect";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TabBar from "shared/ui/controls/TabBar/TabBar";
import MonthsYearsSlider from "shared/ui/inputs/MonthsYearsSlider/MonthsYearsSlider";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

import { LAPTOP_WIDTH } from "constants/dimensions";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./ConstructionControlJournal.module.scss";

const ConstructionControlJournal = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [date, setDate] = useState(moment());
  const { projectId, objectId } = useParams<Record<string, string>>();

  const { objects, showPlaceholder, showSpinner, isLoadingObjects } = useConstructionControlRedirectToObject();

  const objectsOptions = useMemo(() => {
    if (objects) {
      return [
        { id: 0, name: "Все объекты", link: `/construction-control/journal/${projectId}/0/acceptance` },
        ...objects.map((el) => ({
          id: el.building,
          name: el.name,
          link: `/construction-control/journal/${projectId}/${el.building}/acceptance`,
        })),
      ];
    }
    return [];
  }, [objects]);

  const [isGroupedByKs2, setIsGroupedByKs2] = useState(false);

  return (
    <TemplateBase
      breadcrumbs={
        <CustomBreadcrumbs title="Стройконтроль" subtitle="Журнал учета">
          <BreadcrumbsProjectSelect
            constructLink={({ projectId }) => `/construction-control/journal/${projectId}/0/acceptance`}
            customFirstOption={{
              id: 0,
              name: "Все проекты",
              link: `/construction-control/journal/0/0/acceptance`,
            }}
          />
          <CustomBreadCrumbsMenu
            selectedOption={objectsOptions?.find((el) => el.id === +objectId)?.name}
            disabled={projectId === "0" || isLoadingObjects}
            options={objectsOptions}
          />
        </CustomBreadcrumbs>
      }
    >
      <ListPageHeaderTemplate className={cn(styles.headerTemplate)}>
        <header className={cn(styles.header)}>
          <TabBar
            tabs={[
              { link: `/construction-control/journal/${projectId}/${objectId}/acceptance`, text: "Приемка" },
              { link: `/construction-control/journal/${projectId}/${objectId}/execution`, text: "Выполнение" },
              {
                link: `/construction-control/journal/${projectId}/${objectId}/remarks`,
                text: "Выберите",
                isDesktopHidden: true,
                isHidden: !pathname.includes("remarks"),
              },
              { link: `/construction-control/journal/${projectId}/${objectId}/acting`, text: "Актирование" },
            ]}
            className={styles.tabs}
          />
          <MonthsYearsSlider className={styles.dateSlider} date={date} onChange={setDate} />
          {
            <>
              <Route
                path={`/construction-control/journal/:projectId/:objectId/execution`}
                render={() => (
                  <ButtonBase
                    className={cn(styles.button, styles.remarksButton)}
                    //small={window.innerWidth <= LAPTOP_WIDTH}
                    secondary
                    onClick={() => history.push(`/construction-control/journal/${projectId}/${objectId}/remarks`)}
                  >
                    Замечания
                  </ButtonBase>
                )}
              />
              <Route
                path={`/construction-control/journal/:projectId/:objectId/remarks`}
                render={() => (
                  <ButtonBase
                    className={cn(styles.button, styles.remarksButton)}
                    //small={window.innerWidth <= LAPTOP_WIDTH}
                    primary
                  >
                    Замечания
                  </ButtonBase>
                )}
              />
              <Route
                path="/construction-control/journal/:projectId/:objectId/acting"
                render={() => (
                  /* @ts-expect-error */
                  <LabeledSwitch
                    label="Группировать по КС-2"
                    value={isGroupedByKs2}
                    onChange={setIsGroupedByKs2}
                    className={styles.switchGroupBy}
                  />
                )}
              />
            </>
          }
        </header>
      </ListPageHeaderTemplate>
      {showPlaceholder && <EmptyPlaceholder img={jurnalIcon} text="wewewe" />}
      {showSpinner && <Spinner />}
      <Switch>
        {objectId !== "0" && (
          <Route
            path={`/construction-control/journal/:projectId/:objectId/acceptance`}
            render={() => <ConstructionControlJournalAcceptance year={date.year()} month={date.month() + 1} />}
          />
        )}
        {objectId !== "0" && (
          <Route
            path={`/construction-control/journal/:projectId/:objectId/execution`}
            render={() => <ConstructionControlJournalExecution year={date.year()} month={date.month() + 1} />}
          />
        )}
        {objectId !== "0" && (
          <Route path="/construction-control/journal/:projectId/:objectId/remarks" render={() => <Remarks />} />
        )}
        {objectId !== "0" && (
          <Route
            path="/construction-control/journal/:projectId/:objectId/acting"
            render={() => <JournalActing isGroupedByKs2={isGroupedByKs2} />}
          />
        )}
        <Redirect exact to={`/construction-control/journal/${projectId}/${objectId}/acceptance`} />
      </Switch>
    </TemplateBase>
  );
};

export default ConstructionControlJournal;
