import React from "react";

export interface ICrossInCircleRoundedProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  fill?: string;
}

const CrossInCircleRounded: React.FC<ICrossInCircleRoundedProps> = ({ fill = "#535353", ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.004" height="17.996" viewBox="0 0 18.004 17.996" {...props}>
      <path
        d="M24203.139-19596.424a8.929,8.929,0,0,1-2.639-6.365,8.925,8.925,0,0,1,2.639-6.357,8.947,8.947,0,0,1,6.363-2.637,8.968,8.968,0,0,1,6.363,2.637,8.941,8.941,0,0,1,2.639,6.357,8.946,8.946,0,0,1-2.639,6.365,8.943,8.943,0,0,1-6.363,2.637A8.923,8.923,0,0,1,24203.139-19596.424Zm.449-12.271a8.309,8.309,0,0,0-2.449,5.906,8.3,8.3,0,0,0,2.449,5.914,8.3,8.3,0,0,0,5.914,2.451,8.294,8.294,0,0,0,5.908-2.451,8.3,8.3,0,0,0,2.443-5.914,8.29,8.29,0,0,0-2.443-5.906,8.3,8.3,0,0,0-5.908-2.443A8.3,8.3,0,0,0,24203.588-19608.7Zm9.531,11.293-3.605-3.605-3.506,3.508a.927.927,0,0,1-1.307,0,.927.927,0,0,1,0-1.307l3.506-3.508-3.6-3.6a.909.909,0,0,1,0-1.283.912.912,0,0,1,1.283,0l3.6,3.605,3.508-3.5a.907.907,0,0,1,1.3,0,.916.916,0,0,1,0,1.307l-3.5,3.5,3.6,3.609a.9.9,0,0,1,0,1.277.905.905,0,0,1-.643.268A.913.913,0,0,1,24213.119-19597.4Z"
        transform="translate(-24200.5 19611.783)"
        fill={fill}
      />
    </svg>
  );
};

export default React.memo(CrossInCircleRounded);
