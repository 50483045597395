import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadStockDetail, saveStock, stockDetailSelector, stocksLoadingSaveSelector } from "../../../../../redux/modules/common/building/stocks";

export const EMPLOYEES_TYPES = {
  RESPONSIBLE: "responsible",
  PAYMENT_DECISION: "paymentDecisionEmployee",
  PTO: "pto",
  TIMESHEET_RESPONSIBLE: "timesheetResponsible",
  STOCK_RESPONSIBLE: 'stockResponsible',
  ESTIMATE_RESPONSIBLE: "estimateResponsible"
};

export const useProjectEmployees = (urlId, isNew, object, isReinitializing) => {

  const dispatch = useDispatch()
  const stockData = useSelector(stockDetailSelector)

  const [employees, setEmployees] = React.useState(null);
  const [pto_employee, setPto_employee] = React.useState(null);
  const [responsible_employee, setResponsible_employee] = React.useState(null);
  const [payment_decision_employee, setPayment_decision_employee] = React.useState(null);
  const [timesheetResponsibleEmployee, setTimesheetResponsibleEmployee] = React.useState(null);
  const [stockResponsibleEmployee, setStockResponsibleEmployee] = React.useState(null)
  const [estimateResponsibleEmployee, setEstimateResponsibleEmployee] = React.useState(null)
  const [isDirty, setIsDirty] = React.useState(false);

  useEffect(() => {
    isReinitializing && object?.stock_id && dispatch(loadStockDetail(object?.stock_id))
  }, [object?.stock_id, isReinitializing])

  React.useEffect(() => {
    if (isNew) setEmployees([]);
    if (isNew || !urlId || !object) return;

    setEmployees(object.employees);
    setPto_employee(object.pto_employee);
    setResponsible_employee(object.responsible_employee);
    setPayment_decision_employee(object.payment_decision_employee);
    setTimesheetResponsibleEmployee(object.timesheet_responsible);
    setEstimateResponsibleEmployee(object.responsible_estimate);
    stockData?.responsible && setStockResponsibleEmployee(stockData?.responsible)
  }, [urlId, isNew, object, stockData]);

  React.useEffect(() => {
    if (!isNew) return;
    const filledData = JSON.parse(window.sessionStorage.getItem("projectData") || "{}");
    const tempData = {
      employee_ids: employees?.map(el => el.id) || [],
      payment_decision_employee_id: payment_decision_employee?.id || null,
      responsible_employee_id: responsible_employee?.id || null,
      pto_id: pto_employee?.id || null,
      timesheet_responsible: timesheetResponsibleEmployee?.id || null,
      stock_responsible: stockResponsibleEmployee?.id || null
    };
    const dataForCreating = { ...filledData, ...tempData };
    window.sessionStorage.setItem("projectData", JSON.stringify(dataForCreating));
  }, [isNew, employees, pto_employee, responsible_employee, payment_decision_employee, timesheetResponsibleEmployee, stockResponsibleEmployee]);

  const deleteEmployeeHandler = (id, type) => {
    setIsDirty(true);
    if (!type) {
      if (!Array.isArray(employees)) return;
      const index = employees.findIndex(el => el.id === id);
      if (index < 0) return;
      const tempArr = [...employees];
      tempArr.splice(index, 1);
      setEmployees(tempArr);
      return;
    }

    if (type === EMPLOYEES_TYPES.PAYMENT_DECISION) {
      setPayment_decision_employee(null);
    } else if (type === EMPLOYEES_TYPES.PTO) {
      setPto_employee(null);
    } else if (type === EMPLOYEES_TYPES.RESPONSIBLE) {
      setResponsible_employee(null);
    } else if (type === EMPLOYEES_TYPES.TIMESHEET_RESPONSIBLE) {
      setTimesheetResponsibleEmployee(null);
    } else if (type === EMPLOYEES_TYPES.STOCK_RESPONSIBLE) {
      setStockResponsibleEmployee(null)
    } else if (type === EMPLOYEES_TYPES.ESTIMATE_RESPONSIBLE) {
      setEstimateResponsibleEmployee(null)
    }
  };

  const addEmployeeHandler = (employeesArr, type) => {
    setIsDirty(true);
    const filteredArr = employeesArr.filter(el => employees.findIndex(emp => el.id === emp.id) < 0);
    setEmployees((prev) => {
      if (Array.isArray(prev)) {
        return [...prev, ...filteredArr];
      } else {
        return [employeesArr];
      }
    });

    if (type === EMPLOYEES_TYPES.PAYMENT_DECISION) {
      setPayment_decision_employee(employeesArr[0]);
    } else if (type === EMPLOYEES_TYPES.PTO) {
      setPto_employee(employeesArr[0]);
    } else if (type === EMPLOYEES_TYPES.RESPONSIBLE) {
      setResponsible_employee(employeesArr[0]);
    } else if (type === EMPLOYEES_TYPES.TIMESHEET_RESPONSIBLE) {
      setTimesheetResponsibleEmployee(employeesArr[0]);
    } else if (type === EMPLOYEES_TYPES.STOCK_RESPONSIBLE) {
      setStockResponsibleEmployee(employeesArr[0])
    } else if (type === EMPLOYEES_TYPES.ESTIMATE_RESPONSIBLE) {
      setEstimateResponsibleEmployee(employeesArr[0])
    }
  };

  const getValues = () => ({
    employee_ids: employees?.map(el => el.id) || [],
    payment_decision_employee_id: payment_decision_employee?.id || null,
    responsible_employee_id: responsible_employee?.id || null,
    pto_id: pto_employee?.id || null,
    timesheet_responsible_id: timesheetResponsibleEmployee?.id || null,
    stock_responsible_id: stockResponsibleEmployee?.id || null,
    responsible_stock_id: stockResponsibleEmployee?.id || null,
    responsible_estimate_id: estimateResponsibleEmployee?.id || null
  });

  const stocksEmployeeHandler = ({type, employeesId}) => {
    if (type !== EMPLOYEES_TYPES.STOCK_RESPONSIBLE || !object?.stock_id || !employeesId) return
    dispatch(saveStock(object?.stock_id, { responsible_id: employeesId }))
  }

  return {
    employees,
    pto_employee,
    responsible_employee,
    payment_decision_employee,
    isDirty,
    timesheetResponsibleEmployee,
    stockResponsibleEmployee,
    deleteEmployeeHandler,
    addEmployeeHandler,
    getValues,
    stocksEmployeeHandler,
    estimateResponsibleEmployee,
    stockData
  };
};