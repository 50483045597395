import React from "react";

const InfoCircle: React.FC<{ className?: string }> = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.309"
      height="18.309"
      viewBox="0 0 18.309 18.309"
      className={className}
      {...props}
    >
      <g id="Group_18058" data-name="Group 18058" transform="translate(-871 -108)">
        <g
          id="Ellipse_48574"
          data-name="Ellipse 48574"
          transform="translate(871 108)"
          fill="none"
          stroke="#000"
          strokeWidth="1"
        >
          <circle cx="9.154" cy="9.154" r="9.154" stroke="none" />
          <circle cx="9.154" cy="9.154" r="8.654" fill="none" />
        </g>
        <path
          id="Path_5801"
          data-name="Path 5801"
          d="M2.344-1.7H1.422V-8.044h.922Zm.19-8.789a.6.6,0,0,1-.2.468.655.655,0,0,1-.448.183.655.655,0,0,1-.448-.183.6.6,0,0,1-.2-.468.6.6,0,0,1,.2-.468.655.655,0,0,1,.448-.183.655.655,0,0,1,.448.183A.6.6,0,0,1,2.534-10.485Z"
          transform="translate(878.271 123.571)"
          stroke="#000"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default React.memo(InfoCircle);
