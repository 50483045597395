import React from "react";

import { ChaptersEnum } from "../../enums";
import { CHAPTERS } from "../../constants";
import Chapter from "../Chapter/Chapter";

import styles from "./ChooseChapter.module.scss";

interface IChooseChapterProps {
  chosenChapter: ChaptersEnum | null;
  onChoseChapter: (chapterId: ChaptersEnum) => void;
}

const ChooseChapter = ({ chosenChapter, onChoseChapter }: IChooseChapterProps) => {
  return (
    <div>
      <p className={styles.title}>Укажите главу сводного сметного расчета:</p>
      <div className={styles.list}>
        {CHAPTERS.map((chapter) => (
          <Chapter
            chapter={chapter}
            onClick={onChoseChapter}
            isActive={chapter.id === chosenChapter}
            key={chapter.id}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(ChooseChapter);
export type { IChooseChapterProps };
