import { useEffect, useState } from "react";

import { ESTIMATE_STATES_IDS } from "../constants";
import { EstimateStateId } from "../types";

export enum PricingModes {
  view = "view",
  budget = "budget",
  estimatedPrice = "estimatedPrice",
}

export const PRICING_MODES_LOCALES = {
  [PricingModes.view]: "Просмотр",
  [PricingModes.budget]: "Бюджет",
  [PricingModes.estimatedPrice]: "Расчетная цена",
};

export const PRICING_MODES_OPTIONS = Object.entries(PRICING_MODES_LOCALES).map(([mode, locale]) => ({
  id: mode,
  name: locale,
}));

const usePricingMode = ({ activeEstimateStateId }: { activeEstimateStateId: EstimateStateId }) => {
  const [activePricingMode, setActivePricingMode] = useState(PricingModes.view);

  useEffect(() => {
    setActivePricingMode(PricingModes.view);
  }, [activeEstimateStateId]);

  return { activePricingMode, setActivePricingMode };
};

export default usePricingMode;
