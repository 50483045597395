import { message } from "antd";
import moment, { Moment } from "moment";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { auditActions } from "../../../model/slice";
import { useTypedSelector } from "app/store/typedUseSelector";

/* import { auditDatesSelector } from "../../../../../../redux/modules/common/constructing/finance/audit/selectors";
import { setAuditDates } from "../../../../../../redux/modules/common/constructing/finance/audit/thunks"; */
import Calendar from "shared/ui/inputs/Calendar/Calendar";

import styles from "./AuditDatePicker.module.scss";

const DatePicker = () => {
  const dispatch = useDispatch();
  const { dateEnd, dateStart } = useTypedSelector((state) => state.financeReducer.audit);

  const startMoment = useMemo(() => moment(dateStart), [dateStart]);
  const endMoment = useMemo(() => moment(dateEnd), [dateEnd]);

  const handleSetDateStart = (date: Moment) => {
    const startMomentCandidate = moment(date);
    if (startMomentCandidate.isAfter(endMoment)) {
      message.error("Начало периода должно предшествовать его окончанию");
      return;
    }
    dispatch(auditActions.setStart(startMomentCandidate.format("YYYY-MM-DD")));
  };

  const handleSetDateEnd = (date: Moment) => {
    const endMomentCandidate = moment(date);
    if (endMomentCandidate.isBefore(startMoment)) {
      message.error("Окончание периода должно следовать за его началом");
      return;
    }
    dispatch(auditActions.setEnd(endMomentCandidate.format("YYYY-MM-DD")));
  };

  return (
    <div className={styles.datePicker}>
      <div className={styles.datePicker__text}>*За период:</div>
      <span className={styles.gap}>с</span>
      <Calendar value={startMoment} setValue={handleSetDateStart} />
      <span className={styles.gap}>по</span>
      <Calendar value={endMoment} setValue={handleSetDateEnd} />
    </div>
  );
};

export default React.memo(DatePicker);
