import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IV2SummaryObject, IV2SummaryObjectResponse, IV2SummaryProject, IV2SummaryProjectsListResponse } from "./types";

import { summaryUtils } from "./utils";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

interface IState {
  dataTree: IFinanceTreeItem[];
  loadings: Record<string, boolean>;
  totals: Record<string, IFinanceTreeItem[]>;
}

const initialState: IState = {
  dataTree: [],
  loadings: {},
  totals: {},
};

const financeSummarySlice = createSlice({
  name: "financeSummary",
  initialState,
  reducers: {
    setProjects: (state, { payload }: PayloadAction<IV2SummaryProjectsListResponse>) => {
      const oldTree = [...state.dataTree];
      const context = { oldTree };
      state.dataTree = payload.map((el) => summaryUtils.mapProjectData(el, context));
    },
    closeItem: (state, { payload }: PayloadAction<IFinanceTreeItem>) => {
      const oldTree = [...state.dataTree];
      state.dataTree = summaryUtils.closeTree(oldTree, payload)!;
    },
    openItem: (state, { payload }: PayloadAction<IFinanceTreeItem>) => {
      const oldTree = [...state.dataTree];
      state.dataTree = summaryUtils.openTree(oldTree, payload)!;
    },
    setObject: (
      state,
      {
        payload: { data, objectId, projectId, year },
      }: PayloadAction<{ data: IV2SummaryObjectResponse; projectId: number; objectId: number; year: number }>
    ) => {
      state.dataTree = summaryUtils.setObject(state.dataTree, data, projectId, objectId);
      const key = generateStorageKey({ type: "object", id: objectId });
      state.totals[key] = summaryUtils.extractObjectTotal(data, year);
    },
    setProject: (
      state,
      {
        payload: { data, projectId, year },
      }: PayloadAction<{ data: IV2SummaryProjectsListResponse; projectId: number; year: number }>
    ) => {
      state.dataTree = summaryUtils.setProject(state.dataTree, data, projectId);
      const key = generateStorageKey({ type: "project", id: projectId });
      state.totals[key] = summaryUtils.extractProjectTotal(data, year);
    },
    setLoading: (state, { payload: { key, status } }: PayloadAction<{ key: string; status: boolean }>) => {
      state.loadings[key] = status;
    },
  },
});

export const financeSummaryActions = financeSummarySlice.actions;
export default financeSummarySlice.reducer;
