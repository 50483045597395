import React from "react";

import { splitThousands } from "../../../../../../utils/formatters/splitThousands";

import styles from "../Products.module.scss";

export const UP = "up";
export const DW = "dw";

export interface IPriceRow {
  price_changed: string;
  price: number;
  start_price: number;
}

export const PriceColumnCell = (_cellData: any, row: IPriceRow) => {
  const showStatus = () => {
    if (row.price_changed === UP) return <span className="change-icon change-icon_up" />;
    if (row.price_changed === DW) return <span className="change-icon change-icon_down" />;
  };

  return (
    <div>
      <div>{splitThousands(row.price, false, false)}</div>
      <div title={`Стартовая цена: ${row.start_price}`}>{showStatus()}</div>
    </div>
  );
};

export default PriceColumnCell;
