import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { userSelector } from "redux/modules/_TODO/auth";

import { permissionsUserSelector } from "features/permissions/model/selectors";

const usePermission = (alias: string): boolean => {
  const user = useSelector(userSelector);
  const permissions = useSelector(permissionsUserSelector);

  const isPermissed = useMemo(() => {
    const foundPerm = permissions[user?.id]?.find((el) => el.alias === alias);
    if (foundPerm?.state === "on") return true;
    return false;
  }, [alias, permissions, user?.id]);

  return isPermissed;
};

export default usePermission;
