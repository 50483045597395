import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { userSelector } from "redux/modules/_TODO/auth";
import { objectDetailSelector } from "redux/modules/common/building/object/nowObject";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { IListResponseData } from "types/ListResponse";
import { IEmployee } from "types/personsTypes";

export interface IProps {
  selectedWorker: Partial<IEmployee> | null;
  isTableOpen: boolean;
  isShared?: boolean;
  intervalId?: number;
}

export const useRemarkForm = ({ selectedWorker, isTableOpen, isShared, intervalId }: IProps) => {
  const objectId = useObjectId();
  const objectInfo = useSelector(objectDetailSelector);
  const userInfo = useSelector(userSelector);
  const userId: number = userInfo?.id;
  const [loadedUsers, setLoadedUsers] = useState<IEmployee[]>([]);

  const isShowSelectTableIcon = !selectedWorker && !isTableOpen;
  const isShowWorkersTable = !selectedWorker && isTableOpen;
  const isShowSelectedWorkers = selectedWorker && !isTableOpen;

  const filteredWorkers = useMemo(() => {
    if (!isShared) {
      return objectInfo?.employees?.filter((item) => item.id !== userId);
    } else {
      return loadedUsers;
    }
  }, [isShared, objectInfo, loadedUsers]);

  useEffect(() => {
    if (!isShared) return;
    axios
      .get<IListResponseData<{ id: number; full_name: string }>>(
        `/journal/${objectId}/shared_acceptance/remark_responsible_users/${intervalId}/`
      )
      .then(({ data }) => {
        setLoadedUsers(data.results as any);
      });
  }, [isShared, objectId, intervalId]);

  return { isShowSelectTableIcon, isShowWorkersTable, isShowSelectedWorkers, filteredWorkers };
};
