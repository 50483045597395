import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  checkedItemsIdsSelector,
  estimateStateIdSelector,
  isItemsStateChangingSelector,
} from "../../../model/selectors";
import { moveItemsBetweenStates } from "../../../model/thunks";

import { EEstimateStatesIds } from "../../../../ProHandler/enums";

import { useHandlerLevel } from "../../../hooks/useHandlerLevel";

const NEXT_STATE = {
  [EEstimateStatesIds.DRAFT]: EEstimateStatesIds.LOCALE,
  [EEstimateStatesIds.LOCALE]: EEstimateStatesIds.PRODUCTION,
} as const;

export const useChangeItemsState = () => {
  const dispatch = useDispatch();

  const checkedItemsIds = useSelector(checkedItemsIdsSelector);
  const estimateStateId = useSelector(estimateStateIdSelector);

  const isItemsEstimateStateChanging = useSelector(isItemsStateChangingSelector);

  const level = useHandlerLevel();

  const { buildingId, projectId } = useParams<{ buildingId?: string; projectId?: string }>();

  const onChangeItemsState = useCallback(
    ({}) => {
      if (
        estimateStateId &&
        buildingId &&
        [EEstimateStatesIds.DRAFT, EEstimateStatesIds.LOCALE].includes(estimateStateId)
      ) {
        const payload = {
          from_state: estimateStateId,
          ids: checkedItemsIds,
          state: NEXT_STATE[estimateStateId as EEstimateStatesIds.DRAFT | EEstimateStatesIds.LOCALE],
        };
        dispatch(moveItemsBetweenStates(buildingId, payload, level));
      }
    },
    [buildingId, checkedItemsIds, estimateStateId]
  );

  return {
    onChangeItemsState,
    isItemsEstimateStateChanging,
  };
};
