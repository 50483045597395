import moment from "moment";
import { Dispatch } from "redux";

import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";

import { CHART_CONFIG_CONSTRUCTING } from "components/pages/Chart/configs/constructing";
import { CHART_CONFIG_OBJECTS } from "components/pages/Chart/configs/objects";
import { CHART_CONFIG_PURCHASES } from "components/pages/Chart/configs/purchases";
import { CHART_TREE_LVL } from "components/pages/Chart/constants";

import { editGroupPlanInterval, editPlanInterval, editSectionPlan } from "../building/processApi";
import { IntervalMapper, MATCH_STRATEGY } from "./IntervalMapper";
import { chartActions } from "./actions";
import { chartApi } from "./api";
import { initCheckpoinsMarks } from "./reducer";
import {
  chartActionsSelector,
  chartLoadedIntervalsByYearsSelector,
  chartProjectIdsSelector,
  chartScrollTouchedYearsSelector,
  stateSelector as chartStateSelector,
  chartTabSelector,
} from "./selectors";
import { RootState } from "app/store/rootReducer";
import { Checkpoint } from "widgets/AddCheckpoint";

import type { IProjectTreeResponse } from "../building/manufacturing/types";
import {
  CHART_LOADERS,
  CHART_TABS,
  ChartActions,
  ChartCheckpointsMarks,
  IChartPersistValues,
  IChartState,
  IChartTree,
  INTERVAL_MAPPING_TYPES,
  IPlanRelationCore,
} from "./types";

import { urlModulesType } from "utils/hooks/useUrlModule";

import { getCheckpointKeys, patchTreeOnLoad, treeTypesByTab } from "./utils";
import { errorCatcher } from "utils/helpers/errorCatcher";

const getChartStrategy = (chartState: IChartState) =>
  chartState.actions.show_expenditures_in_tree !== undefined ? MATCH_STRATEGY.ALL : MATCH_STRATEGY.SECTIONS_ONLY;

let isLoadingTree: Record<number, boolean> = Object.create(null);

export const loadChartTree = ({ tab, projectId, index }: { tab: CHART_TABS; projectId: number; index: number }) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    if (isLoadingTree[projectId]) {
      return Promise.reject();
    }
    isLoadingTree[projectId] = true;
    const state = getState();
    const chartState = chartStateSelector(state);
    const signal = chartState.controller.signal;
    const strategy = getChartStrategy(chartState);

    return chartApi
      .getTree(projectId, treeTypesByTab(tab), signal, strategy)
      .then(({ data }) => {
        const tree = data as unknown as IChartTree & IProjectTreeResponse;
        patchTreeOnLoad(tree);
        dispatch(chartActions.addTree({ tab, tree, index }));
      })
      .catch(errorCatcher)
      .finally(() => {
        isLoadingTree[projectId] = false;
      });
  };
};

// Можно подгружать частями, не за весь год сразу
export const loadChartIntervals =
  ({ tab, projectId, year, force }: { tab: CHART_TABS; projectId: number; year: number; force?: boolean }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const chartState = chartStateSelector(state);
    const signal = chartState.controller.signal;
    const hasTree =
      // @ts-ignore
      chartState.tab && chartState.trees[chartState.tab]?.children?.findIndex((t) => t.id == projectId) > -1;
    const loadedIntervalsByYears = chartLoadedIntervalsByYearsSelector(state);

    if ((!force && loadedIntervalsByYears[tab][projectId]?.[year]) || !hasTree) {
      return;
    }
    // Тут можно добавить промежуточный стейт "загружается", чтобы если упал запрос перезапросить
    dispatch(chartActions.setIsLoadedIntervalsByYear({ tab, projectId, year, isLoaded: true }));

    const yearMoment = moment().year(year);
    const startDate = yearMoment.startOf("year").format("YYYY-MM-DD");
    const endDate = yearMoment.endOf("year").format("YYYY-MM-DD");
    const strategy = getChartStrategy(chartState);

    if (tab === CHART_TABS.WORK) {
      await Promise.allSettled([
        chartApi
          .getFactIntervalsWorks(projectId, startDate, endDate, signal)
          .then(({ data }) =>
            new IntervalMapper({ strategy, type: INTERVAL_MAPPING_TYPES.FACT_WORKS }).mapIntervals({
              data,
              dispatch,
              tab,
              signal,
              projectId,
            })
          )
          .catch(errorCatcher),
        chartApi
          .getPlanIntervalsWorks(projectId, startDate, endDate, signal)
          .then(({ data }) =>
            new IntervalMapper({ strategy, type: INTERVAL_MAPPING_TYPES.PLAN_WORKS }).mapIntervals({
              data,
              dispatch,
              tab,
              signal,
              projectId,
            })
          )
          .catch(errorCatcher),
        chartApi
          .getPlanIntervalsSections(projectId, startDate, endDate, signal)
          .then(({ data }) =>
            new IntervalMapper({ strategy, type: INTERVAL_MAPPING_TYPES.PLAN_SECTIONS }).mapIntervals({
              data,
              dispatch,
              tab,
              signal,
              projectId,
            })
          )
          .catch(errorCatcher),
        chartApi
          .getPlanRelations({ building_id: projectId, start_date: startDate, end_date: endDate, signal })
          .then(({ data }) => {
            const fromIntervalsById = {};
            const fromGroupsById = {};
            // @ts-ignore
            data.results.forEach((relation) => {
              if (relation.from_interval) {
                // @ts-ignore
                fromIntervalsById[relation.from_interval] = (fromIntervalsById[relation.from_interval] || []).concat(
                  relation
                );
              }
              if (relation.from_group) {
                // @ts-ignore
                fromGroupsById[relation.from_group] = (fromGroupsById[relation.from_group] || []).concat(relation);
              }
            });
            dispatch(chartActions.addRelations({ tab, fromIntervalsById, fromGroupsById }));
          })
          .catch(errorCatcher),
      ]);
    }
    if (
      tab === CHART_TABS.RESOURCES ||
      tab === CHART_TABS.MATERIALS ||
      tab === CHART_TABS.EQUIPMENT ||
      tab === CHART_TABS.MIM
    ) {
      const intervalTypes = treeTypesByTab(tab);
      await Promise.allSettled([
        chartApi
          .getPlanIntervalsResources(projectId, intervalTypes, startDate, endDate, signal)
          .then(({ data }) =>
            new IntervalMapper({ strategy, type: INTERVAL_MAPPING_TYPES.PLAN_RESOURCES }).mapIntervals({
              data,
              dispatch,
              tab,
              signal,
              projectId,
            })
          )
          .catch(errorCatcher),
        chartApi
          .getFactIntervalsResources(projectId, intervalTypes, startDate, endDate, signal)
          .then(({ data }) =>
            new IntervalMapper({ strategy, type: INTERVAL_MAPPING_TYPES.FACT_RESOURCES }).mapIntervals({
              data,
              dispatch,
              tab,
              signal,
              projectId,
            })
          )
          .catch(errorCatcher),
      ]);
    }
    //@ts-ignore
    dispatch(loadChartCheckpoints({ objectId: projectId, startDate, endDate, signal }));
    dispatch(chartActions.setLoader(CHART_LOADERS.BLURRED, false));
  };

export const loadChartCheckpoints =
  ({
    objectId,
    startDate,
    endDate,
    signal,
  }: {
    objectId: number | string;
    startDate: string;
    endDate: string;
    signal?: AbortSignal;
  }) =>
  async (dispatch: Dispatch) => {
    await chartApi
      .getCheckpoints({ objectId, startDate, endDate, signal })
      .then(({ data }) => {
        const hasCheckpoints = initCheckpoinsMarks();
        data.results.forEach((checkpoint: Checkpoint) => {
          const { checkpointKeydays, checkpointKeyyearWeeks } = getCheckpointKeys(checkpoint.check_point_date);
          hasCheckpoints.days[checkpointKeydays] ||= 0;
          hasCheckpoints.days[checkpointKeydays]++;
          hasCheckpoints.yearWeeks[checkpointKeyyearWeeks] ||= 0;
          hasCheckpoints.yearWeeks[checkpointKeyyearWeeks]++;
        });
        dispatch(chartActions.addCheckpointsMarks(hasCheckpoints));
      })
      .catch(errorCatcher);
  };

/** count может быть как + так и - */
export const chartMarkCheckpointDate = (checkpointDate: string, count: number) => (dispatch: Dispatch) => {
  const hasCheckpoints = initCheckpoinsMarks();
  const { checkpointKeydays, checkpointKeyyearWeeks } = getCheckpointKeys(checkpointDate);
  hasCheckpoints.days[checkpointKeydays] = count;
  hasCheckpoints.yearWeeks[checkpointKeyyearWeeks] = count;
  dispatch(chartActions.addCheckpointsMarks(hasCheckpoints));
};

export const updateChartActions =
  ({ name, value }: { name: ChartActions; value: boolean }) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    // XOR режимов редактирования графика
    if (name === ChartActions.linking_editing_enabled || name === ChartActions.plans_editing_enabled) {
      const diagramFilters = chartActionsSelector(getState());
      if (
        name === ChartActions.linking_editing_enabled &&
        value &&
        diagramFilters[ChartActions.plans_editing_enabled]
      ) {
        dispatch(chartActions.updateAction({ name: ChartActions.plans_editing_enabled, value: false }));
      } else if (
        name === ChartActions.plans_editing_enabled &&
        value &&
        diagramFilters[ChartActions.linking_editing_enabled]
      ) {
        dispatch(chartActions.updateAction({ name: ChartActions.linking_editing_enabled, value: false }));
      }
    }
    if (name === ChartActions.show_expenditures_in_tree && value !== undefined) {
      dispatch(
        chartActions.updateTreeFilter({
          filter: "skipBranchLvl",
          value: {
            [CHART_TREE_LVL.EXPENDITURE]: !value,
          },
        })
      );
    }

    // Редактирование планов доступно только в режиме "Дни"
    // if (name === ChartActions.plans_editing_enabled && value) {
    //   const chartViewMode = chartViewModeSelector(getState());
    //   if (chartViewMode !== MONTH && chartViewMode !== HALF_MONTH) {
    //     dispatch(setChartViewMode(MONTH));
    //   }
    // }
    dispatch(chartActions.updateAction({ name, value }));
  };

export const addRelation =
  (
    projectId: number,
    { from_interval, from_group, to_interval, to_group, related_type }: IPlanRelationCore,
    successCallback?: () => void
  ) =>
  async (dispatch: Dispatch) => {
    const tempId = Math.random();
    dispatch(
      chartActions.addArrow({
        arrow: { from_interval, from_group, to_interval, to_group, related_type, id: tempId },
      })
    );
    const newRelationResponse = await chartApi
      .createPlanRelation(projectId, {
        from_interval,
        to_interval,
        related_type,
        from_group,
        to_group,
      })
      .catch(errorCatcher);
    if (!newRelationResponse) {
      dispatch(
        chartActions.deleteArrow({
          arrow: { id: tempId, from_interval, from_group, to_interval, to_group, related_type },
        })
      );
    } else {
      dispatch(
        chartActions.updateArrow({
          arrow: { id: tempId, from_interval, from_group, to_interval, to_group, related_type },
          data: newRelationResponse.data,
        })
      );
      dispatch(chartActions.setJustAddedArrow(newRelationResponse.data));
      successCallback?.();
    }
  };

export const editChartIntervalLength =
  ({
    projectId,
    intervalId,
    expenditureId,
    isGroup,
    isSectionPlan,
    dateStart,
    dateEnd,
    successCallback,
    failCallback,
  }: {
    projectId: number | string;
    intervalId: number;
    expenditureId: number;
    isGroup?: boolean;
    isSectionPlan?: boolean;
    dateStart: string;
    dateEnd: string;
    successCallback?: () => void;
    failCallback?: () => void;
  }) =>
  (dispatch: Dispatch) => {
    dispatch(chartActions.setLoader(CHART_LOADERS.BLURRED, true));
    let intervalEditPromise = Promise.resolve();

    if (isSectionPlan) {
      // @ts-ignore
      intervalEditPromise = editSectionPlan(projectId.toString(), intervalId, {
        start_at: dateStart,
        end_at: dateEnd,
      }).catch(errorCatcher);
    } else if (isGroup) {
      // @ts-ignore
      intervalEditPromise = editGroupPlanInterval({
        buildingId: Number(projectId),
        intervalId,
        data: { start_at: dateStart, end_at: dateEnd },
      }).catch(errorCatcher);
    } else {
      // @ts-ignore
      intervalEditPromise = editPlanInterval({
        buildingId: Number(projectId),
        intervalId,
        expenditureId,
        data: { start_at: dateStart, end_at: dateEnd },
      }).catch(errorCatcher);
    }

    intervalEditPromise
      .then(() => {
        // @ts-ignore
        dispatch(updateChartHash());
        successCallback?.();
      }, failCallback)
      .catch((e) => {
        errorCatcher(e);
        dispatch(chartActions.setLoader(CHART_LOADERS.BLURRED, false));
      });
  };

export const shiftChartInterval =
  ({
    intervalId,
    isGroup,
    days,
    successCallback,
    failCallback,
  }: {
    intervalId: number;
    isGroup?: boolean;
    days: number;
    successCallback?: () => void;
    failCallback?: () => void;
  }) =>
  (dispatch: Dispatch) => {
    dispatch(chartActions.setLoader(CHART_LOADERS.BLURRED, true));
    chartApi
      .shiftInterval(intervalId, days, isGroup ? "plan_group" : "plan_work")
      .then(() => {
        // @ts-ignore
        dispatch(updateChartHash());
        successCallback?.();
      }, failCallback)
      .catch((e) => {
        errorCatcher(e);
        dispatch(chartActions.setLoader(CHART_LOADERS.BLURRED, false));
      });
  };

export const toggleTreeOpen = (treeId: IChartTree["_id"], newOpenState: boolean) => (dispatch: Dispatch) => {
  if (newOpenState) {
    dispatch(chartActions.addOpenedTree(treeId));
  } else {
    dispatch(chartActions.removeOpenedTree(treeId));
  }
};

export const dropChart =
  (module: urlModulesType, persistValues?: IChartPersistValues) => (dispatch: Dispatch, getState: () => RootState) => {
    const chartState = chartStateSelector(getState());
    try {
      chartState.controller.abort();
    } catch {}
    dispatch(chartActions.dropChart(persistValues));
    // @ts-ignore
    dispatch(configureChart(module));
  };

export const configureChart = (module: urlModulesType) => (dispatch: Dispatch) => {
  if (module === "constructing") {
    dispatch(chartActions.patchState(CHART_CONFIG_CONSTRUCTING));
  }
  if (module === "objects") {
    dispatch(chartActions.patchState(CHART_CONFIG_OBJECTS));
  }
  if (module === "purchases") {
    dispatch(chartActions.patchState(CHART_CONFIG_PURCHASES));
  }
};

export const updateChartHash = () => (dispatch: Dispatch) => {
  dispatch(chartActions.setLoader(CHART_LOADERS.BLURRED, true));
  dispatch(chartActions.updateHash());
};

export const reloadChart =
  ({
    needTreeLoading,
    forceProjectIds,
    indexShift = 0,
  }: {
    needTreeLoading: boolean;
    forceProjectIds?: number[];
    indexShift?: number;
  }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const projectIds = forceProjectIds || chartProjectIdsSelector(state);
    const tab = chartTabSelector(state);
    const touchedYears = chartScrollTouchedYearsSelector(state);
    if (!tab || !projectIds.length) return;
    await Promise.allSettled(
      projectIds.map((id: number, index: number) =>
        (needTreeLoading
          ? // @ts-ignore
            dispatch(loadChartTree({ tab, projectId: id, index: index + indexShift }))
          : Promise.resolve()
        ).then(() => {
          touchedYears.forEach((y) =>
            dispatch(
              // @ts-ignore
              loadChartIntervals({
                tab,
                projectId: id,
                year: y,
                force: needTreeLoading,
              })
            )
          );
        })
      )
    );
  };

export const askMoreChartProjects = () => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const projectIds = chartProjectIdsSelector(state);
  const allProjects = objectsBreadcrumbsSelector(state);
  if (projectIds.length >= allProjects.length) return;
  dispatch(chartActions.incrementProjectSliceIndex());
};

export const setChartProjectIds = (projectIds: number[]) => (dispatch: Dispatch) => {
  if (projectIds.length > 1) {
    // @ts-ignore
    dispatch(updateChartActions({ name: ChartActions.show_expenditures_in_tree, value: undefined }));
  } else {
    // @ts-ignore
    dispatch(updateChartActions({ name: ChartActions.show_expenditures_in_tree, value: true }));
  }
  dispatch(chartActions.setProjectIds(projectIds));
};
