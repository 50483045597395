import moment from "moment";
import React from "react";

import JournalDay from "components/UI/_TODO/WorkOrMaterialsModals/JournalTicketModal/JournalDay";
import InfoItem from "components/UI/_TODO/WorkOrMaterialsModals/components/InfoItem/InfoItem";
import PairOfInfoItems from "components/UI/_TODO/WorkOrMaterialsModals/components/PairOfInfoItems/PairOfInfoItems";

import { IDrawingDetailed, IDrawingSet } from "../../model/types";

import getShortFullName from "utils/formatters/getShortFullName";

import styles from "./BlueprintsModal.module.scss";

interface IProps {
  fileInput: React.ReactNode;
  bottomControls: React.ReactNode;
  detailData: IDrawingDetailed;
  parent?: IDrawingSet;
}

const BlueprintInfo: React.FC<IProps> = ({ detailData, bottomControls, fileInput, parent }) => {
  return (
    <>
      <div className={styles.grid}>
        <InfoItem label="Название комплекта:" value={parent?.title} />
        <InfoItem label="Организация, разработавшая чертеж:" value={parent?.organization} />
        <PairOfInfoItems
          label1="Шифр:"
          label2="Номера листов:"
          value1={parent?.code!}
          value2={detailData?.sheet_numbers.join(", ")}
        />
        <PairOfInfoItems
          label1="Загрузил:"
          label2="&nbsp;"
          value1={
            <JournalDay
              day={moment(detailData?.created_at).format("DD/MM/YYYY")}
              time={detailData?.created_at?.includes("T") ? moment(detailData?.created_at).format("HH:mm") : undefined}
            />
          }
          value2={getShortFullName(detailData?.author)}
        />
      </div>
      {fileInput}
      {bottomControls}
    </>
  );
};

export default BlueprintInfo;
