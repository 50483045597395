import React, { useMemo } from "react";

import { Spinner } from "shared/ui/atoms/Spinner/Spinner";

import { IHandlerIndex } from "../../model/types";
import { IIdAndName } from "types/idAndName";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./HandlerIndexesPopover.module.scss";

interface IProps {
  isLoading: boolean;
  onClick: React.MouseEventHandler;
  indexes: IHandlerIndex[] | null;
  defaultAmount: string;
}

const HandlerIndexesPopover: React.FC<IProps> = ({ isLoading, onClick, indexes, defaultAmount }) => {
  const isEmpty = !indexes?.length;

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.header}>Индексы-дефляторы</div>
      {isLoading && (
        <div className={styles.spinner}>
          <Spinner isSmall isStatic />
        </div>
      )}
      {!isLoading && isEmpty && (
        <div className={styles.empty}>Индексы на смету не назначены. Нажмите, чтобы добавить новый</div>
      )}
      <div className={styles.table}>
        {indexes?.map((el) => (
          <div className={styles.row} key={el.id}>
            <div className={styles.name} title={el.title}>
              {el.title}
            </div>
            <div className={styles.count} title={el.value}>
              {el.value}
            </div>
          </div>
        ))}
      </div>

      {!isEmpty && (
        <div className={styles.footer}>
          <div>Без учета индексов:</div>
          <div>{transformDigitToFinancial(defaultAmount)}</div>
        </div>
      )}
    </div>
  );
};

export default HandlerIndexesPopover;
