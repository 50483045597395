import moment from "moment";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import {
  betonExpenseDetailSelector,
  betonIncomeLoadingSelector,
  betonInvalidateKeySelector,
  betonIsPendingSelector,
} from "redux/modules/common/building/beton/selectors";
import {
  addBetonExpenseComment,
  editBetonExpense,
  getBetonExpenseDetail,
} from "redux/modules/common/building/beton/thunks";
import { IBetonExpenseItem, IEditBetonExpenseRequest } from "redux/modules/common/building/beton/types";
import {
  passportsInvalidateKeySelector,
  passportsPendingSelector,
} from "redux/modules/common/passportsAndCertificates/selectors";
import {
  attachDocumentToExpenditure,
  deletePassportDocument,
} from "redux/modules/common/passportsAndCertificates/thunks";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import InfoItem from "components/UI/_TODO/WorkOrMaterialsModals/components/InfoItem/InfoItem";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import Select from "components/UI/atoms/Select";
import Expandable from "components/UI/atoms/_TODO/Expandable/Expandable";
import PassportForm from "components/modals/PassportsAndSertificates/PassportForm/PassportForm";
import PassportsTable from "components/modals/PassportsAndSertificates/PassportsTable/PassportsTable";
import { betonSolidificationMethodsOptions } from "components/pages/Beton/constants";
import { getInitialValuesBetonExpense } from "components/pages/Beton/utils";

import BetonExpenseStatus from "../BetonExpenseStatus/BetonExpenseStatus";
import BetonExpenseComments from "./../BetonExpenseComments/BetonExpenseComments";
import BetonExpenseDetailsBtn from "./../BetonExpenseDetailsBtn/BetonExpenseDetailsBtn";
import formatStringByPattern from "format-string-by-pattern";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import Calendar from "shared/ui/inputs/Calendar/Calendar";
import InputBase from "shared/ui/inputs/InputBase";

import {
  VIEW_MANUFACTURING_BETON_OUTCOMES_ADD_DETAILS,
  VIEW_MANUFACTURING_BETON_OUTCOMES_ADD_DOCS,
  VIEW_MANUFACTURING_BETON_OUTCOMES_EDIT_DETAILS,
} from "../../../../../../constants/permissions/manufacturingPermissions";

import usePermission from "../../../../../../hooks/usePermission";

import {
  composeFieldValidators,
  matchPattern,
  mustBeDate,
  mustBeNumber,
  required,
} from "utils/formHelpers/validations";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./BetonExpenseModal.module.scss";

interface IProps {
  item: IBetonExpenseItem;
  isOpen: boolean;
  onClose: () => void;
  stockId: number;
}

const BetonExpenseModal: React.FC<IProps> = ({ item, isOpen, onClose, stockId }) => {
  const dispatch = useDispatch();
  const invalidateKey = useSelector(betonInvalidateKeySelector);
  const detailItem = useSelector(betonExpenseDetailSelector)[item.id];
  const isPending = useSelector(betonIsPendingSelector)[item.id];
  const isLoading = useSelector(betonIncomeLoadingSelector)[generateStorageKey({ stockId, betonId: item.id })];
  const isDocumentsPending = useSelector(passportsPendingSelector);
  const documentsInvalidateKey = useSelector(passportsInvalidateKeySelector);

  const haveViewAddDetails = usePermission(VIEW_MANUFACTURING_BETON_OUTCOMES_ADD_DETAILS);
  const haveViewEditDetails = usePermission(VIEW_MANUFACTURING_BETON_OUTCOMES_EDIT_DETAILS);
  const haveViewAddDocs = usePermission(VIEW_MANUFACTURING_BETON_OUTCOMES_ADD_DOCS);

  useEffect(() => {
    if (!isOpen || !stockId) return;
    dispatch(getBetonExpenseDetail(stockId, item.id));
  }, [stockId, isOpen, item.id, invalidateKey, documentsInvalidateKey]);

  const createComment = (message: string) => {
    dispatch(addBetonExpenseComment(stockId, item.id, message));
  };

  const editBeton = (vals: Omit<IEditBetonExpenseRequest, "beton_date"> & { date: moment.Moment; time: string }) => {
    const [hours, minutes] = vals.time.split(":");
    const beton_date = moment(vals.date)
      .set("hours", +hours)
      .set("minutes", +minutes)
      .toISOString();
    const data: IEditBetonExpenseRequest = {
      ...vals,
      beton_date,
    };
    dispatch(editBetonExpense(stockId, item.id, data));
  };

  const attachDoc = (vals: any) => {
    dispatch(attachDocumentToExpenditure({ ...vals, stock_using: item.id }, detailItem?.expenditure_id!));
  };

  const deleteDoc = (docId: number) => {
    dispatch(deletePassportDocument(detailItem?.expenditure_id!, docId));
  };

  const [isDocForm, setIsDocForm] = useState(false);

  const [isDetailFormDisabled, setIsDetailFormDisabled] = useState(false);

  React.useEffect(() => {
    let flag = true;
    if (detailItem) {
      flag = detailItem.status !== "in_work" || !haveViewAddDetails;
    }
    setIsDetailFormDisabled(flag);
  }, [detailItem, haveViewAddDetails, haveViewEditDetails]);

  return (
    <ModalContainer
      name={item.name}
      customHeadline={
        <div className={styles.headline}>
          <div className={styles.time}>Передан: {moment(item.created_at).format("DD.MM.YYYY HH:mm")}</div>
          <BetonExpenseStatus status={item.status} />
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <div className={styles.grid}>
            <InfoItem label="Объем:" value={item.count} />
            {/*<InfoItem label="Ось, отметка:" value="АА-11" />*/}
            <InfoItem label="Ответственный:" value={item.user ?? item.worker} />
            <InfoItem label="Расценка:" value={detailItem?.exp_work_name ?? ""} className={styles.exp} />
          </div>
          <Form
            onSubmit={editBeton}
            initialValues={getInitialValuesBetonExpense(detailItem)}
            render={({ handleSubmit, dirty, hasValidationErrors }) => (
              <form
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Expandable
                  additionalHeadline={
                    <div className={styles.expandableHeader}>
                      <span>Детали заливки:</span>
                      {detailItem?.status !== "completed" && (
                        <BetonExpenseDetailsBtn
                          isPending={isPending}
                          hasPermission={
                            haveViewEditDetails || (detailItem?.status === "in_work" && haveViewAddDetails)
                          }
                          hasValidationErrors={hasValidationErrors}
                          hasSavedData={detailItem?.status !== "in_work"}
                          isDirty={dirty}
                          isDisabled={isDetailFormDisabled}
                          onSwitchMode={() => setIsDetailFormDisabled((prev) => !prev)}
                        />
                      )}
                    </div>
                  }
                >
                  <div className={styles.detailForm}>
                    <div className={styles.detailFormGrid}>
                      <Field
                        name="date"
                        className={styles.date}
                        validate={composeFieldValidators(required(), mustBeDate())}
                        render={({ input, meta }) => (
                          <>
                            <Calendar
                              value={input.value ? moment(input.value, "YYYY-MM-DD") : (undefined as any)}
                              setValue={(date) => input.onChange(moment(date))}
                              error={meta.error}
                              label="Окончание работ:"
                              format={"DD.MM.YYYY"}
                              placeholder="__.__.____"
                              containerClassName={styles.date}
                              disabled={isDetailFormDisabled}
                              parsingFormat="YYYY-MM-DD"
                              onBlur={input.onBlur}
                              onFocus={input.onFocus}
                              touched={!isDetailFormDisabled && meta.touched}
                            />
                          </>
                        )}
                      />
                      <Field
                        name="time"
                        label="&nbsp;"
                        component={InputBase as any}
                        parse={formatStringByPattern("99:99")}
                        placeholder="__:__"
                        validate={composeFieldValidators(required())}
                        disabled={isDetailFormDisabled}
                        icon={
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 3.7998C16.5288 3.7998 20.2001 7.47107 20.2001 11.9998C20.2001 16.5285 16.5288 20.1998 12 20.1998C7.47131 20.1998 3.80005 16.5285 3.80005 11.9998C3.80005 7.47107 7.47131 3.7998 12 3.7998ZM22.2001 11.9998C22.2001 6.3665 17.6334 1.7998 12 1.7998C6.36674 1.7998 1.80005 6.3665 1.80005 11.9998C1.80005 17.6331 6.36674 22.1998 12 22.1998C17.6334 22.1998 22.2001 17.6331 22.2001 11.9998ZM13 8.3998C13 7.84752 12.5523 7.3998 12 7.3998C11.4478 7.3998 11 7.84752 11 8.3998V11.9998C11 12.7173 11.4042 13.3733 12.0444 13.6962L15.1428 15.2892C15.634 15.5417 16.2369 15.3482 16.4894 14.857C16.7419 14.3659 16.5484 13.763 16.0573 13.5105L13 11.9387V8.3998Z"
                              fill="#4FB1EB"
                            />
                          </svg>
                        }
                      />
                      <Field
                        name="beton_method"
                        label={
                          <div>
                            Метод
                            <br /> выдерживания:
                          </div>
                        }
                        component={Select as any}
                        validate={composeFieldValidators(required())}
                        options={betonSolidificationMethodsOptions}
                        disabled={isDetailFormDisabled}
                      />
                      <Field
                        name="beton_t"
                        label={
                          <div>
                            Температура
                            <br /> бетона
                          </div>
                        }
                        component={InputBase as any}
                        validate={composeFieldValidators(required(), mustBeNumber)}
                        placeholder="0"
                        icon={<div>t°C</div>}
                        disabled={isDetailFormDisabled}
                      />
                      <Field
                        name="beton_env_t"
                        label={
                          <div>
                            Температура
                            <br /> среды
                          </div>
                        }
                        component={InputBase as any}
                        validate={composeFieldValidators(required(), mustBeNumber)}
                        placeholder="0"
                        icon={<div>t°C</div>}
                        disabled={isDetailFormDisabled}
                      />
                    </div>
                  </div>
                </Expandable>
              </form>
            )}
          />
          <Expandable title={`Документы: ${detailItem?.attachments?.length}`}>
            <PassportForm
              onSubmit={attachDoc}
              isPending={isDocumentsPending}
              setIsOpen={setIsDocForm}
              isOpen={isDocForm}
              expenditureType={item.status === "solidification" ? "betonExpenseFinal" : "betonExpense"}
              canAdd={detailItem?.status !== "completed" && haveViewAddDocs}
            />
            <PassportsTable
              docs={detailItem?.attachments}
              onDirectlyDelete={deleteDoc}
              canDelete={detailItem?.status !== "completed"}
            />
          </Expandable>
          <BetonExpenseComments isPending={isPending} onAddComment={createComment} comments={detailItem?.comments} />
        </>
      )}
    </ModalContainer>
  );
};

export default BetonExpenseModal;
