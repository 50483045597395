import { useCallback, useEffect, useMemo, useState } from "react";

const buildCheckerItems = (relatesByKeys, defaultChecked) => {
  const items = {};
  Object.keys(relatesByKeys).forEach((key) => (items[key] = !!defaultChecked[key]));
  return items;
};

const checkAllRelatesNotChecked = (checkerItems, relates) =>
  relates.length !== 0 && relates.every((key) => !checkerItems[key]);
const checkSomeRelatesIsChecked = (checkerItems, relates) =>
  relates.length !== 0 && relates.some((key) => checkerItems[key]);

const getTargetKeyNeighbours = (relatesByKeys, targetKey) => {
  const targetKeyNeighbours = {};

  Object.entries(relatesByKeys).forEach(([key, relates]) => {
    if (relates.indexOf(targetKey) !== -1) targetKeyNeighbours[key] = relates;
  });

  return targetKeyNeighbours;
};

const changeCheckedByKeyNeighboursIfAllSimilar = (relatesByKeys, targetKey, checkerItems) => {
  const targetKeyNeighbours = getTargetKeyNeighbours(relatesByKeys, targetKey);
  const updatedCheckerItems = { ...checkerItems };

  Object.entries(targetKeyNeighbours).forEach(([relatedKey, relates]) => {
    if (checkSomeRelatesIsChecked(checkerItems, relates)) {
      updatedCheckerItems[relatedKey] = true;
      return;
    }

    if (checkAllRelatesNotChecked(checkerItems, relates)) updatedCheckerItems[relatedKey] = false;
  });

  return updatedCheckerItems;
};

const useRelatesChecker = (relatesByKeys, defaultChecked) => {
  const [items, setItems] = useState(buildCheckerItems(relatesByKeys, defaultChecked));

  const changeChecked = useCallback(
    (key, isChecked) =>
      setItems((prevState) => {
        let nextState = { ...prevState, [key]: isChecked };

        if (relatesByKeys[key]) {
          relatesByKeys[key].forEach((relatedKey) => (nextState[relatedKey] = isChecked));
        }

        nextState = changeCheckedByKeyNeighboursIfAllSimilar(relatesByKeys, key, nextState);
        return nextState;
      }),
    [relatesByKeys]
  );

  const checkedItems = useMemo(
    () =>
      Object.entries(items)
        .filter(([_, isChecked]) => isChecked)
        .map(([key]) => key),
    [items]
  );

  useEffect(() => {
    setItems(buildCheckerItems(relatesByKeys, defaultChecked));
  }, [relatesByKeys, defaultChecked]);

  return { items, changeChecked, checkedItems };
};

export default useRelatesChecker;
