import moment from "moment";
import React from "react";

import { calendar } from "./calendar";
import { marker } from "./marker";

import { sliceTextByConstraint } from "../../../../../../utils/formatters/sliceTextByConstraint";
import { splitThousands } from "../../../../../../utils/formatters/splitThousands";

import styles from "./MaterialInfoItem.module.scss";

function MaterialsInfoItem({ date, name, count, measure, amount }) {
  return (
    <div className={styles.container}>
      <div className={styles.marker}>{marker}</div>
      <div className={styles.row1}>
        <div className={styles.cell}>
          <div className={styles.date}>{date ? moment(date).format("DD/MM/YYYY") : "--/--/----"}</div>
          {calendar}
          <div className={styles.time}>в {date ? moment(date).format("HH:mm") : "--:--"}</div>
        </div>
        <div className={styles.cell}>
          <div className={styles.label}>Принял:</div>
          <div className={styles.value}>{sliceTextByConstraint(name, 16)}</div>
        </div>
      </div>
      <div className={styles.row2}>
        <div className={styles.cell}>
          <div className={styles.label}>Кол-во:</div>
          <div className={styles.value} title={`${count || 0} (${measure})`}>
            {count || 0} ({sliceTextByConstraint(measure, 4)})
          </div>
        </div>
        <div className={styles.cell}>
          <div className={styles.label}>Стоимость по смете:</div>
          <div className={styles.value}>{splitThousands(amount)}</div>
        </div>
      </div>
    </div>
  );
}

export default MaterialsInfoItem;
