import { USER_TYPES } from "../../constants/constant";

export const getUserType = (authEntityType: string) => {
  return USER_TYPES.PURCHASER;
  switch (authEntityType) {
    case "pcr":
      return USER_TYPES.PURCHASER;
    case "pvr":
      return USER_TYPES.PROVIDER;
    default:
      return null;
  }
};
