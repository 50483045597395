export const isFinanceFooter = (objectId: string, projectId?: string) => {
  if (!projectId || +projectId === 0) return false;
  return true;
};

export const isQuartalColumns = (objectId: string, projectId?: string) => {
  if (!projectId || +projectId === 0) return false;
  //if (!objectId || +objectId === 0) return false;
  return true;
};
