import styles from './ManufacturingModal.module.scss'

export const MAIN_PLAN_TAB = "mainPlanTab";
export const RELATIONS_PLAN_TAB = "relationsPlanTab";

export const PLAN_TABS = [
  {
    id: MAIN_PLAN_TAB,
    text: "Основные сведения",
    className: styles.relationsTab,
  },
  {
    id: RELATIONS_PLAN_TAB,
    text: "Связи",
    className: styles.relationsTab,
  },
];
