import React from "react";

export default function Check({color = '#fff', width = '19'}) {
  return (
    <svg 
      width={width}
      height="18" 
      viewBox="0 0 19 18" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M1.27832 9.99991L7.66932 16.3909L17.8693 1.23291" 
        stroke={color} 
        strokeWidth="1.6"
      />
    </svg>
  )
}