import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { RequestModal } from "../RequestModal/RequestModal";
import { OpportunitySection } from "./OpportunitySection/OpportunitySection";
import { PlanningSection } from "./PlanningSection/PlanningSection";
import { ProductionPlanningSection } from "./ProductionPlanningSection/ProductionPlanningSection";
import { SupplyResourcesSection } from "./SupplyResourcesSection/SupplyResourcesSection";
import { ExecutionWorksSection } from "./ExecutionWorksSection/ExecutionWorksSection";
import { FormationDocumentationSection } from "./FormationDocumentationSection/FormationDocumentationSection";
import { AdditionalFeaturesSection } from "./AdditionalFeaturesSection/AdditionalFeaturesSection";
import { LandingPagesRoutesEnum } from "../../lib/landingPagesRoutesEnum";


export const ForSitePage = () => {
  return (
    <Fragment>
      <OpportunitySection />
      <PlanningSection />
      <ProductionPlanningSection />
      <SupplyResourcesSection />
      <ExecutionWorksSection />
      <FormationDocumentationSection />
      <AdditionalFeaturesSection />
      <Footer />
      <RequestModal />
    </Fragment>
  )
}