/**
 * Stock status
 **/
import { getCurrencyShortName } from "utils/helpers/getCurrencyShortName";

export const STOCK_STATUS = {
  DRAFT: "draft",
  LOCALE: "locale",
  OBJECT: "object",
  CONSOLIDATE: "consolidate",
  PRODUCTION: "production",
};

/**
 * Stock type array
 */

export const stockArrayType = [
  { name: "locale", label: "Локальный сметный расчет" },
  { name: "object", label: "Объектная смета" },
  { name: "consolidate", label: "Сводный сметный расчет" },
  { name: "production", label: "В производство" },
];

/**
 * Handler status sections
 */

export const SECTIONS_STATUS = {
  NEW: "new",
  CONFIRMED: "confirmed",
  CANCELED: "canceled",
};

/**
 * Measure Type
 */
export const measures = ["шт", "кг", "м", "м2", "м3", "л", "мп", "км", "уп", "т", "компл"];
/**
 * MeasureMaterials Type
 */
const activeCurrency = getCurrencyShortName();

export const measuresMaterials = [
  { id: "шт", name: "шт", label: "шт" },
  { id: activeCurrency, name: activeCurrency, label: activeCurrency },
  { id: "10 шт.", name: "10 шт.", label: "10 шт." },
  { id: "100 шт.", name: "100 шт.", label: "100 шт." },
  { id: "кг", name: "кг", label: "кг" },
  { id: "м", name: "м", label: "м" },
  { id: "м2", name: "м2", label: "м2" },
  { id: "м3", name: "м3", label: "м3" },
  { id: "л", name: "л", label: "л" },
  { id: "мп", name: "мп", label: "мп" },
  { id: "км", name: "км", label: "км" },
  { id: "уп", name: "уп", label: "уп" },
  { id: "т", name: "т", label: "т" },
  { id: "компл.", name: "компл.", label: "компл." },
];
/**
 * MeasureMaterials Type
 */
export const materialsType = [
  { id: 1, name: "Расходный материал", label: "Расходный материал" },
  { id: 2, name: "Инструмент", label: "Инструмент" },
  { id: 3, name: "Обеспечение", label: "Обеспечение" },
  { id: 4, name: "Фассоный элемент", label: "Фассоный элемент" },
];

export const expenditureTypeMaterials = [
  { id: "material", name: "материалы" },
  { id: "work", name: "работа" },
];
/**
 * Handler menu
 */
export const handlerMenu = [
  { id: 1, name: "draft", label: "Черовик" },
  { id: 2, name: "locale", label: "Объектная смета" },
  { id: 3, name: "object", label: "Объектная смета" },
  { id: 4, name: "consolidate", label: "Объектная смета" },
  { id: 5, name: "production", label: "В производство" },
];

/**
 * Tariff type
 */

export const tariffTypeArray = () => {
  return [
    { id: "BASIC", name: "Базовый" },
    { id: "STANDARD", name: "Стандарт" },
    { id: "PROFESSIONAL", name: "Проф." },
  ];
};

/**
 * Account type
 */

export const accountTypeArray = () => {
  return [
    { id: 1, name: "pcr", label: "Закупщик" },
    { id: 2, name: "pvr", label: "Поставщик" },
  ];
};

/**
 * Vat list
 */

export const vatTypeArray = () => {
  return [
    { id: "YES", name: "С НДС", label: "С НДС" },
    { id: "NO", name: "Без НДС", label: "Без НДС" },
  ];
};

export const newVatTypeArray = [
  { id: "YES", value: "YES", name: "С НДС", label: "С НДС" },
  { id: "NO", value: "NO", name: "Без НДС", label: "Без НДС" },
];
/**
 * Select Status type
 */

export const statusTypeArray = [
  { id: true, name: "Оплачен" },
  { id: false, name: "Ожидает оплаты" },
];

export const statusTypeArrays = [
  { id: 1, name: "confirmed", label: "Подтвержденно" },
  { id: 2, name: "awaiting_confirmation", label: "Ожидает подтвержденно" },
];

export const typeTypeArrays = [
  { id: 1, name: "production", label: "Производство" },
  { id: 2, name: "recycling", label: "Утилизация" },
];
/**
 * Select Status type Documents
 */

export const statusBillsArray = [
  { id: "paid", name: "Оплачен", label: "Оплачен" },
  { id: "waiting_payment", name: "Ожидает оплаты", label: "Ожидает оплаты" },
  { id: "partly_paid", name: "Частично оплачен", label: "Частично оплачен" },
];

export const statusPackingListArray = [
  { id: "waiting_shipment", name: "Ожидает отгрузки", label: "Ожидает отгрузки" },
  { id: "accepted", name: "Принята", label: "Принята" },
];

export const statusActsArray = [
  { id: "waiting_completion", name: "Ожидает выполнения", label: "Ожидает выполнения" },
  { id: "accepted", name: "Принят", label: "Принят" },
];

/**
 * Organization type
 */

export const entityTypeArray = () => {
  return [
    { id: "IE", name: "IE", label: "ИП" },
    { id: "OOO", name: "OOO", label: "ООО" },
    { id: "OAO", name: "OAO", label: "ОАО" },
    { id: "ZAO", name: "ZAO", label: "ЗАО" },
    { id: "NAO", name: "NAO", label: "НАО" },
    { id: "PAO", name: "PAO", label: "ПАО" },
  ];
};

/**
 * OrdersRegistry type
 */

export const orderStatusesType = {
  inWorkOrders: [
    { id: "new", name: "Новый", label: "Новый" },
    { id: "pcr_need_approve", name: "Подтверждение закупщика", label: "Подтверждение закупщика" },
    // { id: "pvr_need_approve", name: "Подтверждение поставщика", label: "Подтверждение поставщика" },
    { id: "pcr_rejected", name: "Отклонен закупщиком", label: "Отклонен закупщиком" },
    // { id: "pvr_rejected", name: "Отклонен поставщиком", label: "Отклонен поставщиком" },
    { id: "payment_decision", name: "Решение по оплате", label: "Решение по оплате" },
  ],
  acceptedOrders: [
    { id: "waiting_payment", name: "Ожидает оплаты", label: "Ожидает оплаты" },
    { id: "waiting_shipment", name: "Ожидает отгрузки", label: "Ожидает отгрузки" },
  ],
  completedOrders: [{ id: "completed", name: "Исполнен", label: "Исполнен" }],
};

/**
 * Interval type
 */

export const INTERVAL_TYPE = {
  FACT_INTERVAL: "factinterval",
  PLAN_INTERVAL: "planinterval",
};

/**
 * Status Approved Material
 */
export const statusApprovedMaterial = [
  {
    name: "process",
    color: "#888888",
  },
  {
    name: "wait",
    color: "#4FB2ED",
  },
  {
    name: "close",
    color: "#8FE532",
  },
];

/**
 * Section status
 */
export const SECTION_STATUS_TYPE = {
  ACCEPTED: "accepted",
  CONFIRMED: "confirmed",
  INVESTED: "invested",
};

/**
 * Fact interval status
 */
export const FACT_INTERVAL_TYPE = {
  NEW: "new",
  ACCEPTED: "accepted",
  CONFIRMED: "confirmed",
  PAYED: "payed",
};

/**
 * Action menu Type
 */

export const MENU_ACTION_TYPE = {
  UNLOAD: "unload",
  FIT_ALL_MONTH: "fit_all_month",
  SHOW_CLOSE_TO_COMPETION: "show_close_to_completion",
  SHOW_ALL_EXPIRED: "show_all_expired",
  SHOW_MORE_PLAN_SEGMENTS: "show_more_plan_segments",
  SHOW_MORE_LINKS_BETWEEN_WORKS: "show_more_links_between_works",
  DEFAULT_VIEW: "default_view",
};

/**
 * Action menu Array
 */

export const menuActionArray = [
  // {
  //   id: 0,
  //   name: MENU_ACTION_TYPE.UNLOAD,
  //   label: 'Выгрузить в MS Excel'
  // },
  // {
  //   id: 1,
  //   name: MENU_ACTION_TYPE.FIT_ALL_MONTH,
  //   label: 'Уместить весь месяц в видимой области'
  // },
  {
    id: 2,
    name: MENU_ACTION_TYPE.SHOW_CLOSE_TO_COMPETION,
    label: "Показать со сроком близким к завершению",
  },
  {
    id: 3,
    name: MENU_ACTION_TYPE.SHOW_ALL_EXPIRED,
    label: "Показать все просроченные",
  },
  {
    id: 4,
    name: MENU_ACTION_TYPE.SHOW_MORE_PLAN_SEGMENTS,
    label: "Показать еще плановые отрезки",
  },
  // {
  //   id: 5,
  //   name: MENU_ACTION_TYPE.SHOW_MORE_LINKS_BETWEEN_WORKS,
  //   label: 'Показать еще связи между работами'
  // },
  {
    id: 6,
    name: MENU_ACTION_TYPE.DEFAULT_VIEW,
    label: "Вид по умолчанию",
  },
];

/**
 * Month array
 */
// todo legacy delete
export const MonthArray = [
  {
    id: 0,
    name: "Jan",
    label: "Январь",
  },
  {
    id: 1,
    name: "Feb",
    label: "Февраль",
  },
  {
    id: 2,
    name: "Mar",
    label: "Март",
  },
  {
    id: 3,
    name: "Apr",
    label: "Апрель",
  },
  {
    id: 4,
    name: "May",
    label: "Май",
  },
  {
    id: 5,
    name: "Jun",
    label: "Июнь",
  },
  {
    id: 6,
    name: "Jul",
    label: "Июль",
  },
  {
    id: 7,
    name: "Aug",
    label: "Август",
  },
  {
    id: 8,
    name: "Sep",
    label: "Сентябрь",
  },
  {
    id: 9,
    name: "Okt",
    label: "Октябрь",
  },
  {
    id: 10,
    name: "Nov",
    label: "Ноябрь",
  },
  {
    id: 11,
    name: "Dec",
    label: "Декабрь",
  },
];

export const MonthAndQuarterArray = [
  {
    id: 0,
    name: "Jan",
    label: "Январь",
  },
  {
    id: 1,
    name: "Feb",
    label: "Февраль",
  },
  {
    id: 2,
    name: "Mar",
    label: "Март",
  },
  {
    id: "q1",
    name: "q1",
    label: "I квартал",
  },
  {
    id: 3,
    name: "Apr",
    label: "Апрель",
  },
  {
    id: 4,
    name: "May",
    label: "Май",
  },
  {
    id: 5,
    name: "Jun",
    label: "Июнь",
  },
  {
    id: "q2",
    name: "q2",
    label: "II квартал",
  },
  {
    id: 6,
    name: "Jul",
    label: "Июль",
  },
  {
    id: 7,
    name: "Aug",
    label: "Август",
  },
  {
    id: 8,
    name: "Sep",
    label: "Сентябрь",
  },
  {
    id: "q3",
    name: "q3",
    label: "III квартал",
  },
  {
    id: 9,
    name: "Okt",
    label: "Октябрь",
  },
  {
    id: 10,
    name: "Nov",
    label: "Ноябрь",
  },
  {
    id: 11,
    name: "Dec",
    label: "Декабрь",
  },
  {
    id: "q4",
    name: "q4",
    label: "IV квартал",
  },
  {
    id: "year",
    name: "year",
    label: "Итого за год",
  },
];
/**
 * Status shedule type
 */

export const STATUS_TYPE_SHEDULE_TYPE = {
  DONE_ACCEPTED: "doneAccepted",
  ACCEPTED_PAID: "acceptedPaid",
  EXECUTION_PAID: "executionPaid",
};

/**
 * Status shedule type array
 */

export const sheduleStatusArray = [
  {
    id: 0,
    name: STATUS_TYPE_SHEDULE_TYPE.DONE_ACCEPTED,
    label: "Выполнено - принято",
  },
  {
    id: 1,
    name: STATUS_TYPE_SHEDULE_TYPE.ACCEPTED_PAID,
    label: "Принято - оплачено",
  },
  {
    id: 2,
    name: STATUS_TYPE_SHEDULE_TYPE.EXECUTION_PAID,
    label: "Выполнения принятого - оплаченного",
  },
];

/**
 * Estimate type
 */

export const EXPENDITURE_TYPES = {
  MATERIAL: "material",
  WORK: "work",
  EQUIPMENT: "equipment",
  WORKER: "worker",
  MACHINE: "machine",
  TRANSPORT: "transport",
};

export const EXPENDITURE_TYPES_NAMES = {
  [EXPENDITURE_TYPES.MATERIAL]: "Материал",
  [EXPENDITURE_TYPES.WORK]: "Работа",
  [EXPENDITURE_TYPES.EQUIPMENT]: "Оборудование",
  [EXPENDITURE_TYPES.WORKER]: "Рабочие",
  [EXPENDITURE_TYPES.MACHINE]: "Машины",
  [EXPENDITURE_TYPES.TRANSPORT]: "Транспорт",
};

/**
 * Estimate type array
 */

export const sheduleTypeArray = [
  {
    id: 0,
    name: EXPENDITURE_TYPES.WORK,
    label: "Работы",
  },
  {
    id: 1,
    name: EXPENDITURE_TYPES.MATERIAL,
    label: "Материалы",
  },
  {
    id: 2,
    name: EXPENDITURE_TYPES.EQUIPMENT,
    label: "Оборудование",
  },
];

/**
 * Date type
 */

export const DATE_TYPE = {
  DAYS: "days",
  WEEKS: "weeks",
  MONTH: "months",
};

export const dateTypeArray = [
  {
    id: 0,
    name: DATE_TYPE.DAYS,
    label: "Дни",
  },
  {
    id: 1,
    name: DATE_TYPE.WEEKS,
    label: "Недели",
  },
  {
    id: 2,
    name: DATE_TYPE.MONTH,
    label: "Месяцы",
  },
];

/**
 * Payment type
 */
export const PAYMENT_TYPE = {
  DELAY: "delay", //Отсрочка
  PREPAY: "prepay", //Предоплата
  UPON_SHIPMENT: "upon_shipment", //По факту отгрузки
  UPON_COMPLETION: "upon_completion",
};

/**
 * Requisition status
 */

export const REQUISITION_STATUS = {
  NEW: "new",
  DRAFT: "draft",
  CREATING: "creating",
  PROCESSING_REQ: "process_req",
  PROCESSING_ORDER: "process_order",
  AGREEMENT: "agreement",
  PAYMENT: "payment",
  WAITING_DELIVERY: "waiting_delivery",
  COMPLETED: "completed",
};

export const statusRequisitionTypeArray = [
  { id: 1, name: REQUISITION_STATUS.PROCESSING_REQ, label: "Обработка" },
  { id: 2, name: REQUISITION_STATUS.COMPLETED, label: "Исполнена" },
  { id: 3, name: REQUISITION_STATUS.DRAFT, label: "Черновик" },
];

/**
 * Events event
 */
export const eventSelectArr = [
  { id: "events", name: "Все события" },
  { id: "supply", name: "Поставка" },
  { id: "payment", name: "Оплата" },
  { id: "task", name: "Задача" },
];
/**
 * Events date
 */
export const periodSelectArr = [
  { id: "month", name: "month", label: "Месяц" },
  { id: "week", name: "week", label: "Неделя" },
  { id: "year", name: "year", label: "Год" },
];
/**
 * Order status
 */

export const NEW = "new",
  PVR_NEED_APPROVE = "pvr_need_approve",
  PCR_NEED_APPROVE = "pcr_need_approve",
  PVR_REJECTED = "pvr_rejected",
  PCR_REJECTED = "pcr_rejected",
  PAYMENT_DECISION = "payment_decision",
  PAYMENT_DECISION_LOCALED = "Решение по оплате",
  WAITING_PAYMENT = "waiting_payment",
  WAITING_SHIPMENT = "waiting_shipment",
  COMPLETED = "completed";

/**
 * Agreement type
 */
export const AGREEMENT_TYPE = {
  ORDER_AGREE: "orders_agree",
  ORDER_VIEW: "order_view",
};

/**
 * Status
 */
export const STATUS_TYPE = {
  IN_WORK: "in_work",
  COMPLETED: "completed",
  CLOSED: "closed",
  EXPIRED: "expired",
};

export const statusObject = {
  in_work: "В работе",
  completed: "Готово",
  closed: "Завершено",
  expired: "Просрочено",
};

export const statusArray = [
  {
    id: 0,
    name: "in_work",
    label: "В работе",
  },
  {
    id: 1,
    name: "completed",
    label: "Готово",
  },
  {
    id: 2,
    name: "closed",
    label: "Завершено",
  },
  {
    id: 3,
    name: "expired",
    label: "Просрочено",
  },
];

export const priorityObjectArray = [
  {
    id: "gray",
    name: "gray",
    label: "Низкая важность",
  },
  {
    id: "yellow",
    name: "yellow",
    label: "Средней важности",
  },
  {
    id: "red",
    name: "red",
    label: "Важно",
  },
];

export const priority = {
  red: "Важно",
  yellow: "Средней важности",
  gray: "Низкая важность",
};

export const priorityObject = [
  {
    id: 0,
    color: "gray",
    label: "Низкая важность",
  },
  {
    id: 1,
    color: "yellow",
    label: "Средней важности",
  },
  {
    id: 2,
    color: "red",
    label: "Важно",
  },
];

export const priorityNameArray = ["gray", "yellow", "red"];

export const priorityArray = ["Низкая важность", "Средней важности", "Важно"];

export const repeatArray = [
  {
    id: 0,
    name: "cancel",
    label: "Не повторять",
    day: "cancel",
  },
  {
    id: 1,
    name: "every_day",
    label: "Каждый день",
    day: 1,
  },
  {
    id: 7,
    name: "every_week",
    label: "Каждую неделю",
    day: 7,
  },
  {
    id: 30,
    name: "every_month",
    label: "Каждый месяц",
    day: 30,
  },
];

export const notificationArray = [
  {
    id: null,
    name: "cancel",
    label: "Не уведомлять",
  },
  {
    id: 1,
    name: "for_one_hour",
    label: "За час",
  },
  {
    id: 24,
    name: "for_one_day",
    label: "За день",
  },
  {
    id: 168,
    name: "for_one_week",
    label: "За неделю",
  },
];

export const USER_TYPES = {
  PURCHASER: "purchaser",
  PROVIDER: "provider",
};

// todo legacy delete
export const YEAR_SELECT_MIN_YEAR = 2020;
// todo legacy delete
export const YEAR_SELECT_UPPER_BOUND_FROM_CURRENT_YEAR = 10;

export const excludedMessageWords = ["`close_date_id` "];
