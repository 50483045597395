import cn from "classnames";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";

import { Spinner } from "../../../../../shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "../../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import Draggable from "react-draggable";

import analyticsIcon from "../../../../../images/icons/navigation/analyticsIcon.svg";

import styles from "./Island.module.scss";

export interface IIslandElement {
  islandParentRef?: React.RefObject<HTMLElement>;
}

export interface IIslandProps extends PropsWithChildren, IIslandElement {
  heading: React.ReactElement;
  className?: string;
  isEmpty?: boolean;
  isLoading?: boolean;
  headingClassName?: string;
  isFullScreen?: boolean;
}

const Island: React.FC<IIslandProps> = ({
  heading,
  children,
  className,
  islandParentRef,
  isEmpty,
  isLoading,
  headingClassName,
  isFullScreen,
}) => {
  return (
    <Draggable offsetParent={islandParentRef?.current || undefined} bounds={"parent"} disabled>
      <div className={cn(styles.island, className, { [styles.islandFullScreen]: isFullScreen })}>
        <div className={cn(styles.heading, headingClassName)}>{heading}</div>
        <div className={styles.content}>
          {isLoading ? (
            <Spinner />
          ) : isEmpty ? (
            <EmptyPlaceholder fullHeight={false} img={analyticsIcon} className={styles.emptyPlaceholder} />
          ) : (
            <>{children}</>
          )}
        </div>
      </div>
    </Draggable>
  );
};

export default React.memo(Island);
