import cn from "classnames";
import React, { ChangeEvent, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../shared/ui/atoms/Spinner/Spinner";
import InputBase from "../../../shared/ui/inputs/InputBase";
import { actions, selectors } from "../model";
import { createOutOfEstimateSection } from "../model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";

import styles from "./CreateOutOfEstimateSection.module.scss";

interface IProps {
  parentId?: number;
  onCreateCallback?: () => void;
}

export const CreateOutOfEstimateSection = memo<IProps>(({ parentId, onCreateCallback }) => {
  const dispatch = useDispatch();

  const name = useSelector(selectors.getName);
  const isCreating = useSelector(selectors.getIsCreating);

  useEffect(() => {
    dispatch(actions.setParentId(parentId));
  }, [parentId]);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => dispatch(actions.setName(e.target.value));

  const onCancel = () => dispatch(actions.clear());

  const onCreate = () => {
    dispatch(createOutOfEstimateSection(onCreateCallback));
  };

  return (
    <div className={styles.container}>
      <InputBase
        className={styles.input}
        value={name}
        onChange={onNameChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onCreate();
          }
        }}
      />
      <button className={cn(styles.close, styles.button)} onClick={onCancel} disabled={isCreating}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.05025 1.63664C2.65972 1.24612 2.02656 1.24612 1.63603 1.63664C1.24551 2.02717 1.24551 2.66033 1.63603 3.05086L6.58579 8.00061L1.63605 12.9504C1.24552 13.3409 1.24552 13.974 1.63605 14.3646C2.02657 14.7551 2.65973 14.7551 3.05026 14.3646L8 9.41482L12.9497 14.3646C13.3403 14.7551 13.9734 14.7551 14.364 14.3646C14.7545 13.974 14.7545 13.3409 14.364 12.9504L9.41421 8.00061L14.364 3.05086C14.7545 2.66033 14.7545 2.02717 14.364 1.63664C13.9734 1.24612 13.3403 1.24612 12.9498 1.63664L8 6.5864L3.05025 1.63664Z"
            fill="#949494"
          />
        </svg>
      </button>
      <button disabled={isCreating} className={cn(styles.button, styles.save)} onClick={onCreate}>
        {isCreating ? (
          <Spinner isStatic className={styles.spinnerContainer} spinnerClassName={styles.spinner} />
        ) : (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.25198 9.86993L2.8888 6.50682C2.40301 6.02103 1.6154 6.02104 1.12961 6.50683C0.643815 6.99262 0.643818 7.78025 1.12961 8.26604L5.55335 12.6897C5.94388 13.0802 6.57704 13.0802 6.96756 12.6897L14.914 4.74333C15.4021 4.2552 15.4021 3.46378 14.914 2.97565C14.4258 2.48751 13.6344 2.48751 13.1463 2.97564L6.25198 9.86993Z"
              fill="#949494"
            />
          </svg>
        )}
      </button>
    </div>
  );
});

CreateOutOfEstimateSection.displayName = "CreateOutOfEstimateSection";
