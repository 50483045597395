import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useRouteMatch } from "react-router-dom";

import { simpleHandlerAPI } from "../../../../../model/api";
import {
  activeSectionSelector,
  activeSubSectionSelector,
  estimateStateIdSelector,
} from "../../../../../model/selectors";
import { simpleHandlerActions } from "../../../../../model/slice";

import { EEstimateStatesIds } from "../../../../../../ProHandler/enums";

export const useDefineActiveSubSection = () => {
  const dispatch = useDispatch();
  const activeSection = useSelector(activeSectionSelector);
  const activeSubSection = useSelector(activeSubSectionSelector);
  const estimateStateId = useSelector(estimateStateIdSelector);

  const match = useRouteMatch();
  const sectionIdMatch = useRouteMatch<{ sectionId: string }>(`${match.path}/:sectionId`);
  const subSectionIdMatch = useRouteMatch<{ subsectionId: string }>(`${match.path}/:sectionId/:subsectionId`);

  const { buildingId } = useParams<{ buildingId?: string }>();

  useEffect(() => {
    // Если нет выбранных активных подраздела и раздела, они подгружаются и сохраняются
    if (!activeSection && !activeSubSection && subSectionIdMatch && sectionIdMatch && buildingId && estimateStateId) {
      simpleHandlerAPI
        .getSection(buildingId, subSectionIdMatch.params.subsectionId, estimateStateId)
        .then((loadedSection) => {
          if (loadedSection) {
            dispatch(simpleHandlerActions.setActiveSubSection(loadedSection));
          }
        });

      simpleHandlerAPI
        .getSection(buildingId, sectionIdMatch.params.sectionId, estimateStateId)
        .then((loadedSection) => {
          if (loadedSection) {
            dispatch(simpleHandlerActions.setActiveSection(loadedSection));
          }
        });
    }
  }, [activeSection, activeSubSection, estimateStateId, buildingId, sectionIdMatch, subSectionIdMatch]);
};
