import React, { useCallback, useState } from "react";

const useAnalyticsFullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const toggleFullScreen = useCallback(() => setIsFullScreen((prevState) => !prevState), []);

  return {
    isFullScreen,
    toggleFullScreen,
  };
};
export default useAnalyticsFullScreen;
