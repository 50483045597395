import { memoize } from "lodash";
import moment from "moment";
import React, { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { userSelector } from "../../../../../../../redux/modules/_TODO/auth";
import {
  addTimesheetApprover,
  approveTimesheet,
  deleteTimesheetApprover,
  timesheetApprovalSelector,
  timesheetApproversLoadingSelector,
  timesheetApproversSelector,
} from "../../../../../../../redux/modules/common/building/object/nowObject";

import Approvers, { TYPES } from "../../../../../../../_LEGACY/UI/_LEGACY_Purchases_components/Approvers";
import month from "../../../../../Manufacturing/components/Month/Month";

import ApprovalFooter from "./ApprovalFooter";

import { IEmployee } from "../../../../../../../types/personsTypes";
import { STATUSES } from "../../constants";
import { VIEW_MANUFACTURING_WORKERS_TIMESHEET_ADD_APPROVERS } from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";

import { stringifyArgs } from "../../../../../../../utils/helpers/stringifyArgs";
import { serializeTimesheetApprovers } from "../../utils/serializeTimesheetApprovers";

import styles from "./Approval.module.scss";

interface IApprovalProps {
  year: number;
  monthNumber: number;
  buildingId: string;
  employees: IEmployee[];
}

const Approval: FC<IApprovalProps> = (props) => {
  const { year, monthNumber, buildingId, employees } = props;

  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const timesheetApproval = useSelector(timesheetApprovalSelector);
  const timesheetApprovers = useSelector(timesheetApproversSelector);
  const timesheetApproversAreLoading = useSelector(timesheetApproversLoadingSelector);

  const serializedTimesheetApprovers = useMemo(() => {
    if (!timesheetApproval || !timesheetApprovers) return [];
    return serializeTimesheetApprovers([...timesheetApprovers], [...timesheetApproval.approvaltimesheetusers_set]);
  }, [timesheetApprovers, timesheetApproval]);

  const approvers = useMemo(
    () => ({
      data: serializedTimesheetApprovers,
      areLoading: timesheetApproversAreLoading,
    }),
    [serializedTimesheetApprovers, timesheetApproversAreLoading]
  );

  const deleteEmployee = useCallback(
    (deletedEmployeeId: number) =>
      compose(dispatch, deleteTimesheetApprover)({ buildingId, year, month: monthNumber }, deletedEmployeeId),
    [buildingId, year, monthNumber]
  );

  const userIsApproverAndNotApprove = useMemo(() => {
    if (approvers.areLoading) return false;
    if (!approvers.data) return false; /* @ts-ignore */
    return approvers.data.some((approver) => approver.employee.id === user.id && !approver.is_approved);
  }, [approvers]);

  const memoizedDeleteEmployee = useMemo(
    () => memoize((deletedEmployeeId) => () => deleteEmployee(deletedEmployeeId), stringifyArgs),
    [deleteEmployee]
  );

  const addEmployees = useCallback(
    (addedEmployees: IEmployee[]) =>
      addedEmployees.forEach((employee) =>
        compose(dispatch, addTimesheetApprover)({ buildingId, year, month: monthNumber }, employee.id)
      ),
    [buildingId, year, monthNumber]
  );

  const approve = useCallback(
    () => compose(dispatch, approveTimesheet)({ buildingId, year, month: monthNumber }),
    [buildingId, year, monthNumber]
  );

  const haveAddApproverPermission = usePermission(VIEW_MANUFACTURING_WORKERS_TIMESHEET_ADD_APPROVERS);

  const isApproveButtonDisplayed = useMemo(() => {
    const monthCondition = moment()
      .startOf("month")
      .isAfter(
        moment()
          .year(+year)
          .month(+monthNumber - 1)
          .startOf("month"),
        "day"
      );

    return timesheetApproval.status === STATUSES.ACTIVED && userIsApproverAndNotApprove && monthCondition;
  }, [timesheetApproval.status, userIsApproverAndNotApprove, year, monthNumber]);

  return (
    <>
      <Approvers
        className={styles.approvers}
        type={TYPES.timesheet}
        approvedElements={approvers.data}
        handleDeleteEmployee={memoizedDeleteEmployee}
        handleAddEmployees={addEmployees}
        employees={employees}
        canAddApprovers={timesheetApproval.status === STATUSES.ACTIVED && haveAddApproverPermission}
        canDeleteApprovers={timesheetApproval.status === STATUSES.ACTIVED && haveAddApproverPermission}
        baseReceivedToApproveDate={timesheetApproval?.date}
      />
      <ApprovalFooter approve={approve} isApproveButtonDisplayed={isApproveButtonDisplayed} />
    </>
  );
};

export default React.memo(Approval);
