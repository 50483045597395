//@ts-nocheck
import { IPreparedMonthDataKey } from "./../../../../common/model/interfaces";

export const getTableViewWidthPercent = (tableElement) => {
  const tableInnerElement = tableElement.querySelector(".ant-table-tbody");
  const widthPercent = (tableElement.offsetWidth / tableInnerElement.scrollWidth) * 100;
  return widthPercent;
};

export const scrollTable = (tableElement, scrollPosition, isNotTable) => {
  if (!tableElement) return;
  if (isNotTable) {
    const scrollTo = tableElement.scrollWidth * (scrollPosition / 100);
    tableElement.scroll(scrollTo, 0);
    return;
  }
  const tableInnerElement = tableElement.querySelector(".ant-table-content");
  if (!tableInnerElement) return;
  const scrollTo = tableInnerElement.scrollWidth * (scrollPosition / 100);
  tableInnerElement.scroll(scrollTo, 0);
};

export const getFinanceTableKey = (
  key: string | number,
  type: "plan" | "fact" | "diff",
  year?: number
): IPreparedMonthDataKey => {
  let formattedKey = key;
  if (typeof key === "number") {
    formattedKey = String(+key + 1);
  }
  if (!["q1", "q2", "q3", "q4", "year"].includes(key)) {
    formattedKey = `m${formattedKey}`;
  }
  // TODO_V2_FINANCE YEAR
  if (!["q1", "q2", "q3", "q4", "year"].includes(key)) {
    return `${formattedKey}_${type}_${year}` as IPreparedMonthDataKey;
  }
  return `${formattedKey}_${type}` as IPreparedMonthDataKey;
};
