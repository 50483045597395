import { IActions } from "app/store/store";

import {
  IJournalAcceptanceSection,
  IJournalAcceptanceSharedContractors,
  IJournalAcceptanceTickets,
  IJournalHistoryEditing,
} from "./types";
import { ITicketApproval, ITicketDetailed, IUpdateSimpleResourcesPayload } from "types/interfaces/Tickets";

export const journalAcceptanceActions = {
  setSections: (key: string, sections: IJournalAcceptanceSection[]) =>
    ({
      type: "journalAcceptance/SET_SECTIONS",
      payload: { key, sections },
    } as const),
  setSectionsIsLoading: (key: string, status: boolean) =>
    ({
      type: "journalAcceptance/SET_SECTIONS_IS_LOADING",
      payload: { key, status },
    } as const),
  setTickets: (key: string, tickets: IJournalAcceptanceTickets) =>
    ({
      type: "journalAcceptance/SET_TICKETS",
      payload: { key, tickets },
    } as const),
  setTicketsIsLoading: (key: string, status: boolean) =>
    ({
      type: "journalAcceptance/SET_TICKETS_IS_LOADING",
      payload: { key, status },
    } as const),
  setApprovals: (ticketId: number, approvals: ITicketApproval[]) =>
    ({ type: "journalAcceptance/SET_APPROVALS", payload: { ticketId, approvals } } as const),
  setEditingHistory: (ticketId: string, history: IJournalHistoryEditing[]) =>
    ({
      type: "journalAcceptance/SET_HISTORY",
      payload: { ticketId, history },
    } as const),
  setEditingHistoryLoading: (ticketId: string, status: boolean) =>
    ({
      type: "journalAcceptance/SET_HISTORY_LOADING",
      payload: { ticketId, status },
    } as const),
  setSharedContractors: (buildingId: string, contractors: IJournalAcceptanceSharedContractors[]) =>
    ({
      type: "journalAcceptance/SET_SHARED_CONTRACTORS",
      payload: { buildingId, contractors },
    } as const),
  setDetailedTicket: (ticketId: string, ticket: ITicketDetailed) =>
    ({
      type: "journalAcceptance/SET_DETAILED_TICKET",
      payload: { ticketId, ticket },
    } as const),
  updateTicketSimpleResources: (ticketId: string, payload: IUpdateSimpleResourcesPayload) =>
    ({
      type: "journalAcceptance/UPDATE_TICKET_SIMPLE_RESOURCES",
      payload: { ticketId, ...payload },
    } as const),
};

export type IJournalAcceptanceAction = IActions<typeof journalAcceptanceActions>;
