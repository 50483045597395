import React, { useEffect, useRef, useState } from "react";

const useFormFocus = (submit, isDisabled = false) => {
  const inputRootRef = useRef(null);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const enterKeyHandler = (e, index) => {
      if (e.key === "Enter" || e.keyCode === 13) {
        if (index === fields.length - 1) {
          submit && submit();
        } else if (index < fields.length - 1) {
          fields[index + 1].focus();
        }
      }
    };

    const inputRoot = inputRootRef?.current;
    if (inputRoot) {
      const candidates = Array.from(
        inputRoot?.querySelectorAll('input:not([type="hidden"]):not([hidden]):not([disabled])')
      );
      !fields.length && candidates.length && setFields(candidates);
      if (fields.length) {
        fields[0].focus();
        fields.map((x, idx) => {
          !x.isWatching && x.addEventListener("keypress", (e) => enterKeyHandler(e, idx));
          x.isWatching = true;
          return x;
        });
      }
    }

    return () => {
      fields.map((x, idx) => {
        x.removeEventListener("keypress", (e) => enterKeyHandler(e, idx));
        return x;
      });
    };
  }, [inputRootRef.current, submit, fields]);

  if (isDisabled) return null;

  return inputRootRef;
};

export default useFormFocus;
