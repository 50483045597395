import moment from "moment";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";

import { userSelector } from "../../../../redux/modules/_TODO/auth";

import { useTypedSelector } from "../../../../app/store/typedUseSelector";
import { createTask } from "../model/thunks";

import { IIdAndName } from "../../../../types/idAndName";
import { TASK_LISTS_TYPES } from "../constants";
import { IcreateTask, TaskPlacementType } from "../model/types";

interface IProps {
  placementType: TaskPlacementType;
  listType?: TASK_LISTS_TYPES;
  list_id?: number;
  onClearPersistance: () => void;
}

interface IcreateFormValues extends Omit<IcreateTask, "executor" | "viewers"> {
  executor: string;
  viewers: number[];
  approves: number[];
}

export const useCreateTask = ({ placementType, listType, list_id, onClearPersistance }: IProps) => {
  const dispatch = useDispatch();
  const [building, setBuilding] = useState<IIdAndName>({
    id: 0,
    name: "",
  });
  const [section_id, setSectionId] = useState<number | undefined>();
  const [executor, setExecutor] = useState<string>("");
  const [viewers, setViewers] = useState<string>("");
  const creator = useTypedSelector(userSelector);

  const clearLocalState = useCallback(() => {
    setBuilding({
      id: 0,
      name: "",
    });
    setSectionId(undefined);
    setExecutor("");
    setViewers("");
    //onClearPersistance();
  }, []);

  const onBuildingChange = useCallback((building: IIdAndName) => {
    setBuilding(building);
    setExecutor("");
    setViewers("");
    //onClearPersistance();
  }, []);

  const onSubmit = (values: IcreateFormValues) => {
    const { name, description, priority, deadline, executor, viewers, remember_task, repeat_task, approves } = values;
    let momentDeadline = moment(deadline);

    const data: IcreateTask = {
      name,
      description,
      priority,
      deadline: momentDeadline.toISOString(),
      building: building?.id,
      section: section_id,
      executor: +executor,
      viewers: viewers ?? [],
      creator: creator.id,
      lists: list_id,
      approves,
    };
    if (remember_task) data["remember_task"] = [remember_task] as any;
    if (repeat_task) data["repeat_task"] = repeat_task;
    compose(
      dispatch,
      createTask
    )({
      /* @ts-ignore */
      data,
      variant: placementType,
      building_id: data.building,
      type: listType,
      list_id: data.lists,
    });
    clearLocalState();
  };

  return {
    onSubmit,
    building,
    setBuilding: onBuildingChange,
    section_id,
    setSectionId,
    executor,
    setExecutor,
    viewers,
    setViewers,
  };
};
