import { getExpenditureAmount } from "./getExpenditureAmount";

import { IExpenditure } from "types/interfaces/Expenditure";
import ISection from "types/interfaces/Section";

import { isEveryObjectValuesIsNull } from "utils/checkers/isEveryObjectValuesIsNull";

export const getAddingToApproveAmount = (estimateItems: (IExpenditure | ISection)[]) => {
  if (
    !Array.isArray(estimateItems)
    && (estimateItems as {dataTransfer: DataTransfer})?.dataTransfer
  ) return;
  if (isEveryObjectValuesIsNull(estimateItems)) return;

  return estimateItems.reduce((acc, estimateItem) => {
    if ((estimateItem as IExpenditure).expenditure_type) return acc + +getExpenditureAmount(estimateItem);
    return acc + +(estimateItem as ISection)?.indicators?.current_estimate_amount;
  }, 0);
};
