import cn from "classnames";
import React, { useCallback, useState } from "react";

import AddEmployeeSlide from "../../__trash/AddEmployeeSlide";
import EmployeesElem from "../../__trash/EmployeesElem";

import CircleAddIcon from "../../../../images/icons/CircleAddIcon";
import chainLineIcon from "../../../../images/icons/chain-line.svg";

import styles from "./index.module.scss";

const TYPES = {
  order: "order",
  payment: "payment",
  requisition: "requisition",
  timesheet: "timesheet",
  ks6a: "ks6a",
};

const EMPTY_CALLBACK = () => {};

const Approvers = (props) => {
  const {
    type,
    approvedElements,
    handleDeleteEmployee = EMPTY_CALLBACK,
    handleAddChainEmployees = EMPTY_CALLBACK,
    handleAddEmployees = EMPTY_CALLBACK,
    canChain,
    canAddApprovers,
    employees,
    className,
    canDeleteApprovers,
    baseReceivedToApproveDate,
  } = props;

  const [isEmployeesModalShown, setIsEmployeesModalShown] = useState(false);

  const hideEmployeesModal = useCallback(() => setIsEmployeesModalShown(false), []);

  const openEmployeesModal = useCallback(() => setIsEmployeesModalShown(true), []);

  const hideModalAndHandleAddEmployees = useCallback(
    (...args) => {
      hideEmployeesModal();
      handleAddEmployees(...args);
    },
    [hideEmployeesModal, handleAddEmployees]
  );

  const checkIsShownNotApprovedChainLine = (employee, nextEmployee) => {
    if (canChain || (nextEmployee.is_approved && employee.is_approved)) return false;
    return +nextEmployee.position === employee.position + 1 && +employee.position > 0;
  };

  return (
    <div className={cn(styles.approval, className)}>
      <header className={styles.header}>
        <h2 className={styles.headerText}>
          {type === TYPES.order && "Согласование заказа:"}
          {type === TYPES.payment && "Согласование оплаты:"}
          {type === TYPES.requisition && "Согласование заявки:"}
          {type === TYPES.timesheet && "Согласование табеля:"}
          {type === TYPES.ks6a && "Согласование КС-6а:"}
        </h2>
        {canAddApprovers && (
          <button onClick={openEmployeesModal}>
            <CircleAddIcon />
          </button>
        )}
      </header>
      <div className={styles.elements}>
        {approvedElements.map((element, index) => {
          const nextEmployee = approvedElements[index + 1];
          const prevEmployee = index > 0 ? approvedElements[index - 1] : undefined;

          return (
            <div className={styles.elementContainer} key={element.id}>
              <EmployeesElem
                className={styles.element}
                employees={employees}
                employee={element.employee || element}
                deleteEmployee={handleDeleteEmployee(element.id)}
                addChainEmployee={handleAddChainEmployees(approvedElements, element.id)}
                nextEmployee={nextEmployee}
                isCanChain={canChain}
                index={index}
                isApproved={element.is_approved}
                approvedAt={element.approved_at}
                isShownRole={false}
                isShownApproveDate={element.is_approved && element.approved_at}
                editPermission={!element.is_default && canDeleteApprovers}
                receivedToApproveDate={element?.position > 1 ? prevEmployee?.approved_at : baseReceivedToApproveDate}
              />
              {nextEmployee && checkIsShownNotApprovedChainLine(element, nextEmployee) && !!element.position && (
                <img className={styles.chainIcon} src={chainLineIcon} alt="-" />
              )}
              {nextEmployee && nextEmployee.is_approved && element.is_approved && !!element.position && (
                <div className={styles.approvedChain} />
              )}
            </div>
          );
        })}
      </div>
      {canAddApprovers && (
        <AddEmployeeSlide
          employees={employees}
          returnOneData={false}
          isOpen={isEmployeesModalShown}
          addEmployees={hideModalAndHandleAddEmployees}
          closeAddPEmployeeBlock={hideEmployeesModal}
        />
      )}
    </div>
  );
};

export default React.memo(Approvers);
export { TYPES };
