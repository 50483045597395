import React, { FC, memo } from "react";
import { useSelector } from "react-redux";

import { estimateStateIdSelector } from "../../../../model/selectors";
import { CommonSectionsListView } from "./ui/CommonSectionsListView/CommonSectionsListView";
import { SectionsListInLocaleStateView } from "./ui/SectionsListInLocaleStateView/SectionsListInLocaleStateView";

import { EEstimateStatesIds } from "../../../../../ProHandler/enums";

/**
 * Список разделов (секций, ЛСР)
 * В зависимости от активного стейта имеет разный вид
 */
export const SectionsList: FC = memo(() => {
  const estimateStateId = useSelector(estimateStateIdSelector);

  if (estimateStateId === EEstimateStatesIds.LOCALE) {
    return <SectionsListInLocaleStateView />;
  } else {
    return <CommonSectionsListView />;
  }
});

SectionsList.displayName = "SectionsList";
