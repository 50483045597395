import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import Select from "components/UI/atoms/Select";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { getDisplayWeeksCountForWindowWidth } from "components/pages/Workers/components/Turnout/utils/getDisplayWeeksCountForWindowWidth";
import {
  planFactVolumeDaysCountSelector,
  planFactVolumeIsAllExpandedSelector,
  planFactVolumeSectionsLoadingSelector,
  planFactVolumeSectionsSelector,
} from "pages/PlanFact/PlanFactVolume/model/selectors";
import { getPlanFactVolumeSections } from "pages/PlanFact/PlanFactVolume/model/thunks";

import NewPlanFactVolumeSection from "./NewPlanFactVolumeSection/NewPlanFactVolumeSection";
import PlanFactVolumeSection from "./PlanFactVolumeSection/PlanFactVolumeSection";
import { planFactVolumeActions } from "./model/actions";
import PlanFactVolumeTableHead from "./ui/PlanFactVolumeTableHead/PlanFactVolumeTableHead";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import WeeksSlider from "shared/ui/inputs/WeeksSlider/WeeksSlider";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { PLAN_FACT_VOLUME_ITEMS_CONSTRAINT, daysInWeekCountOptions } from "./constants";
import { VIEW_PLAN_FACT_VOLUME } from "constants/permissions/manufacturingPermissions";
import { ROUTES } from "constants/routes";

import usePermission from "hooks/usePermission";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import planFact from "images/icons/navigation/plan-fact.svg";

import styles from "./PlanFactVolume.module.scss";

interface IProps {
  date: Moment;
  setDate: React.Dispatch<React.SetStateAction<Moment>>;
}

const PlanFactVolume: React.FC<IProps> = ({ date, setDate }) => {
  const objectId = useObjectId();
  const week = date.week();
  const year = date.year();
  const dispatch = useDispatch();
  const history = useHistory();

  const setDaysCount = (count: 5 | 7) => {
    dispatch(planFactVolumeActions.setDaysCount(count));
  };

  const daysCount = useSelector(planFactVolumeDaysCountSelector);

  React.useEffect(() => {
    dispatch(
      getPlanFactVolumeSections(
        objectId,
        date.startOf("week").format("YYYY-MM-DD"),
        date.endOf("week").format("YYYY-MM-DD"),
        daysCount
      )
    );
  }, [objectId, date, daysCount]);

  const setWeek = React.useCallback(
    (week: number) => {
      const newDate = moment(date);
      newDate.week(week);
      setDate(newDate);
    },
    [setDate, date]
  );

  const [weekWasChanged, setWeekWasChanged] = useState(false);

  useEffect(() => {
    setWeekWasChanged(true);
    setTimeout(() => {
      setWeekWasChanged(false);
    }, 200);
  }, [date]);

  const viewVolumePermission = usePermission(VIEW_PLAN_FACT_VOLUME);

  const startAt = date.startOf("week").format("YYYY-MM-DD");
  const endAt = date.endOf("week").format("YYYY-MM-DD");
  const key = generateStorageKey({ buildingId: objectId, startAt, endAt });
  const lsrs = useSelector(planFactVolumeSectionsSelector)[key];
  const isLsrsLoading = useSelector(planFactVolumeSectionsLoadingSelector)[key];
  const isAllExpanded = useSelector(planFactVolumeIsAllExpandedSelector);

  const showLoader = weekWasChanged || isLsrsLoading;

  const rowClickHandler = React.useCallback(
    (lsrId: number) => {
      history.push(`/objects/${objectId}/plan-fact/volume/${lsrId}`);
    },
    [objectId]
  );

  if (!viewVolumePermission) {
    return <Redirect to={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_FINANCE} />;
  }

  return (
    <>
      <div className={styles.head}>
        <div className={styles.flex}>
          <WeeksSlider
            className={styles.slider}
            year={year}
            activeWeekNumber={week}
            setActiveWeekNumber={setWeek}
            displayWeeksCount={getDisplayWeeksCountForWindowWidth(window.innerWidth)}
          />
          <Select options={daysInWeekCountOptions} onChange={setDaysCount as any} value={daysCount} />
        </div>
        {isAllExpanded && <PlanFactVolumeTableHead daysCount={daysCount} startAt={startAt} />}
      </div>
      {showLoader && (
        <div className={styles.spinner}>
          <Spinner isStatic />
        </div>
      )}
      {!showLoader && !lsrs?.length && <EmptyPlaceholder img={planFact} />}
      {!showLoader &&
        !isAllExpanded &&
        lsrs?.map((el) => (
          <PlanFactVolumeSection key={el.id} name={el.name} onClick={rowClickHandler} sectionId={el.id} />
        ))}
      {!showLoader &&
        isAllExpanded &&
        lsrs?.map((lsr, lsrIndex) =>
          lsr.subsections.map((section, sectionIndex) => {
            const index = lsrIndex + sectionIndex;
            return (
              <NewPlanFactVolumeSection
                key={section.id + date.format()}
                name={section.name}
                parentName={lsr.name}
                date={date}
                daysCount={daysCount}
                subsectionId={String(section.id)}
                isPreLoaded={index < PLAN_FACT_VOLUME_ITEMS_CONSTRAINT}
              />
            );
          })
        )}
    </>
  );
};

export default React.memo(PlanFactVolume);
