import React, { FC } from "react";

import LabeledSwitch from "../../../../atoms/_TODO/LabeledSwitch";

import styles from "./ForContractorSwitcher.module.scss";

interface IProps {
  onChange: (name: string, value: boolean) => void;
  value: boolean;
}

export const ForContractorSwitcher: FC<IProps> = ({ onChange, value }) => {
  return (
    <div className={styles.forContractorField}>
      <span>Выполнение:</span>
      <LabeledSwitch
        label="Своими силами"
        labelRight="Подрядчиком"
        onChange={(v: boolean) => onChange("for_contractor", v)}
        value={value}
      />
    </div>
  );
};
