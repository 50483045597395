import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

import { extractPlanFactBudget } from "./utils";

const stateSelector = (state: RootState) => state.constructing.finance.planFact;

export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);

export const planFactBudgetsSelector = createSelector(stateSelector, (state) => extractPlanFactBudget(state.tree));
export const planFactSectionsSelector = createSelector(stateSelector, (state) => state.tree?.sections);

export const planFactSelectedDatesSelector = createSelector(stateSelector, (state) => state.selectedDates);
