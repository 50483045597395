import _ from "lodash";
import moment from "moment";

import { ICertainTaskResponse, IupdateTask } from "./model/types";

export const compareUpdatingTask = (oldTask: ICertainTaskResponse, newTask: IupdateTask) => {
  const oldTaskValues = {
    name: oldTask.name,
    description: oldTask.description ?? "",
    priority: oldTask.priority,
    status: oldTask.status,
    deadline: moment(oldTask.deadline).toISOString(),
    section: oldTask.section ?? 0,
    executor: oldTask.executor,
    viewers: oldTask.viewers,
    creator: oldTask.creator,
    remember_task: oldTask.remember_task ?? "",
    repeat: oldTask.repeat_task ?? "",
  };

  const newTaskValues = {
    name: newTask.name,
    description: newTask.description ?? "",
    priority: newTask.priority,
    status: newTask.status,
    deadline: moment(newTask.deadline).toISOString(),
    section: newTask.section ?? 0,
    executor: newTask.executor,
    viewers: newTask.viewers,
    creator: newTask.creator,
    remember_task: newTask.remember_task ?? "",
    repeat: newTask.repeat_task ?? "",
  };

  return _.isEqual(oldTaskValues, newTaskValues);
};
