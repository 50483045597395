import React, { FC } from "react";

import styles from "./InstructionsSection.module.scss";

interface IProps {
  name: string;
  onClick: () => void;
}

const InstructionsSection: FC<IProps> = ({ name, onClick }) => {
  return (
    <div className={styles.root} onClick={onClick}>
      <div>{name}</div>
      <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 18L9.759 9.24097L1 0.481995" stroke="black" />
      </svg>
    </div>
  );
};

export default InstructionsSection;
