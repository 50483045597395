import Axios from "axios"
import React from "react"
import { getStockEventsPackinglist } from "../../../../../../redux/modules/common/building/stocksApi"

export const useSubTablePacking = (packinglist) => {
    const [items, setItems] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(false)
    React.useEffect(() => {
        if (!packinglist) return
        setIsLoading(true)
        getStockEventsPackinglist(packinglist)
            .then(resp => setItems(resp.data.items))
            .finally(() => setIsLoading(false))
    }, [packinglist])

    return {
        items,
        isLoading
    }
}