import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import AxesAndMarksForm from "./AxesAndMarksForm";
import { createAxes } from "./model/thunks";
import AxesAndMarksInfo from "./ui/AxesAndMarksInfo/AxesAndMarksInfo";

import { DEFAULT_CREATE_AXES_PARAMS } from "./constants";
import { ICreateAxesArg } from "./model/types";

import { axesUtils } from "./utils";

interface IProps {
  isOpen: boolean;
  planOrFact: "plan" | "fact";
  groupOrWork: "group" | "work";
  triggerForSave: boolean;
  expenditureId: number;
  expenditureIds?: number[];
}

const CreateAxesAndMarks: React.FC<IProps> = ({
  isOpen = true,
  planOrFact,
  groupOrWork,
  triggerForSave,
  expenditureId,
  expenditureIds,
}) => {
  const dispatch = useDispatch();
  const buildingId = useObjectId();
  const [savingAxes, setSavingAxes] = useState<ICreateAxesArg | null>(null);

  const onSubmit = (vals: ICreateAxesArg) => {
    setSavingAxes(vals);
  };

  const onSave = () => {
    if (!savingAxes) return;
    const key = `${planOrFact}_${groupOrWork}`;
    const data: ICreateAxesArg = {
      ...DEFAULT_CREATE_AXES_PARAMS,
      [key]: expenditureId,
      drawing: savingAxes.drawing,
      axis: savingAxes.axis,
    };
    dispatch(createAxes(buildingId, data));
    setSavingAxes(null);
  };

  useEffect(() => {
    if (triggerForSave && !!expenditureId) {
      onSave();
    }
  }, [triggerForSave, expenditureId]);

  if (savingAxes) {
    return <AxesAndMarksInfo info={axesUtils.getInfoForPreview(savingAxes)} />;
  }

  return <AxesAndMarksForm expenditureIds={expenditureIds} isOpen={isOpen} onSubmit={onSubmit} />;
};

export default CreateAxesAndMarks;
