import cn from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import {
  addWorkerCountWork,
  changeWorkerCountWork,
  workersUpdatingTurnoutSelector,
} from "redux/modules/common/building/workers";

import Select, { COLORS, SIZES } from "components/UI/atoms/Select";

import { WORK_STATUSES } from "./constants";

import { checkIsDateToday } from "../../utils/checkIsDateToday";
import { checkCountAndStatusWasSet } from "./utils/checkCountAndStatusWasSet";
import { checkCountOrStatusWasChange } from "./utils/checkCountOrStatusWasChange";
import { checkWorkStatusIsWithoutCount } from "./utils/checkWorkStatusIsWithoutCount";
import { serializeWorkerWork } from "./utils/serializeWorkerWork";

import blueArrowIcon from "images/icons/arrowSelectSmallBlue.svg";

import styles from "./WorkDayCell.module.scss";

const INITIAL_WORK = {};

export const DEFAULT_NUMBER_WORKING_HOURS = 8;

const WorkDayCell = ({ workDate, buildingId, workerId, work = INITIAL_WORK, isDisabled, isWorkerInStaff }) => {
  const dispatch = useDispatch();
  const isUpdating = useSelector(workersUpdatingTurnoutSelector)[work.id];
  const [changedWork, setChangedWork] = compose(useState, serializeWorkerWork)(work);

  const workCountOptions = useRef(
    Array.from(Array(13).keys())
      .map((item) => ({
        id: item,
        name: item,
      }))
      .filter((item) => item.id !== 0)
  );

  const changedWorkStatusIsWithoutCount = useMemo(
    () => checkWorkStatusIsWithoutCount(WORK_STATUSES, changedWork.status),
    [changedWork.status]
  );

  const isAfterToday = workDate.isAfter(moment(), "date");

  const onChangeWorkStatus = useCallback(
    (changedStatus) => setChangedWork((prevState) => ({ ...prevState, status: changedStatus })),
    []
  );

  const onChangeWorkCount = useCallback(
    (changedCount) => setChangedWork((prevState) => ({ ...prevState, count: changedCount })),
    []
  );

  const workDateIsToday = checkIsDateToday(workDate);
  const statusWasSet = !!changedWork.status;

  useEffect(() => {
    compose(setChangedWork, serializeWorkerWork)(work);
  }, [work]);

  useEffect(() => {
    if (changedWork?.status !== "Я" || changedWork?.count || !isWorkerInStaff) return;

    setChangedWork((prevState) => ({ ...prevState, count: DEFAULT_NUMBER_WORKING_HOURS }));
  }, [changedWork?.status]);

  useEffect(() => {
    const statusAndCountWasSet = checkCountAndStatusWasSet(
      changedWork.status,
      changedWork.count,
      changedWorkStatusIsWithoutCount
    );
    const statusOrCountChanged = checkCountOrStatusWasChange(work, changedWork, changedWorkStatusIsWithoutCount);
    if (!statusAndCountWasSet || !statusOrCountChanged) return;

    const isExist = changedWork.id !== undefined;
    const changedWorkCount = changedWorkStatusIsWithoutCount ? 0 : changedWork.count;

    const workToRequest = isExist
      ? {
          id: changedWork.id,
          count: changedWorkCount,
          status: changedWork.status,
        }
      : {
          ...changedWork,
          count: changedWorkCount,
          worker: workerId,
          work_date: moment(workDate).format("YYYY-MM-DD"),
        };
    compose(dispatch, isExist ? changeWorkerCountWork : addWorkerCountWork)(buildingId, workToRequest);
  }, [changedWork, work.count, work.status, changedWorkStatusIsWithoutCount]);

  return (
    <div className={cn(styles.workDay, { [styles.blue]: workDateIsToday })}>
      {!isAfterToday ? (
        <>
          <Select
            containerClassName={styles.selectContainer}
            color={COLORS.BLUE}
            selectClassName={styles.select}
            options={WORK_STATUSES}
            placeholder=""
            size={SIZES.SMALL}
            value={changedWork.status}
            onChange={onChangeWorkStatus}
            disabled={isDisabled || isUpdating}
            icon={blueArrowIcon}
            hideMoreThanOptions={5}
          />
          {!changedWorkStatusIsWithoutCount && (
            <Select
              containerClassName={styles.selectContainer}
              color={COLORS.BLUE}
              selectClassName={styles.select}
              options={workCountOptions.current}
              onChange={onChangeWorkCount}
              placeholder=""
              size={SIZES.SMALL}
              value={parseInt(changedWork.count, 0)}
              disabled={isDisabled || !statusWasSet || isUpdating}
              icon={blueArrowIcon}
            />
          )}
        </>
      ) : (
        <>
          <div className={styles.whiteBlock} />
          <div className={styles.whiteBlock} />
        </>
      )}
    </div>
  );
};

export default React.memo(WorkDayCell);
