import React from "react";

const Check: React.FC<{ className?: string; color?: string }> = ({ className, color = "#000000" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.984"
      height="13.735"
      viewBox="0 0 16.984 13.735"
      className={className}
    >
      <path
        d="M6.021,13.393.352,7.877a1.15,1.15,0,0,1,0-1.656,1.225,1.225,0,0,1,1.7,0l4.742,4.613L14.819.469A1.228,1.228,0,0,1,16.506.235a1.155,1.155,0,0,1,.237,1.641L8.053,13.086a1.146,1.146,0,0,1-.308.283.274.274,0,0,1-.025.023,1.206,1.206,0,0,1-.848.342A1.219,1.219,0,0,1,6.021,13.393Z"
        fill={color}
      />
    </svg>
  );
};

export default React.memo(Check);
