import axios from "axios";

export const getFinanceWarranties = async (buildingId: number) => {
  const result = await axios.get(`/buildings/${buildingId}/finances/warranties/`);
  return result.data.results;
};

export const getFinanceProjectAdvancePayments = async (buildingId: number) => {
  const result = await axios.get(`/buildings/${buildingId}/finances/advance_payments/project/`);
  return result.data;
};

export const getFinanceAdvancePayments = async (buildingId: number) => {
  const result = await axios.get(`/buildings/${buildingId}/finances/advance_payments/`);
  return result.data;
};

export const postFinanceWarranties = async (buildingId: number, body: { percent: string; delay: number }) => {
  return await axios.post(`/buildings/${buildingId}/finances/warranties/`, body);
};

export const postFinanceAdvancePayment = async (buildingId: number, body: { amount: string; date: string }) => {
  return await axios.post(`/buildings/${buildingId}/finances/advance_payments/`, body);
};

export const postFinanceProjectAdvancePayments = async (buildingId: number, body: { percent: string }) => {
  return await axios.post(`/buildings/${buildingId}/finances/advance_payments/project/`, body);
};

export const patchFinanceWarranties = async (
  buildingId: number,
  itemId: number,
  body: { percent: string; delay: number }
) => {
  return await axios.patch(`/buildings/${buildingId}/finances/warranties/${itemId}`, body);
};

export const patchFinanceAdvancePayment = async (
  buildingId: number,
  itemId: number,
  body: { amount: string; date: string }
) => {
  return await axios.patch(`/buildings/${buildingId}/finances/advance_payments/${itemId}`, body);
};

export const deleteFinanceWarranties = async (buildingId: number, itemId: number) => {
  return await axios.delete(`/buildings/${buildingId}/finances/warranties/${itemId}`);
};

export const deleteFinanceAdvancePayment = async (buildingId: number, itemId: number) => {
  return await axios.delete(`/buildings/${buildingId}/finances/advance_payments/${itemId}`);
};
