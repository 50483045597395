import React from 'react';
import { Limiter } from './Limiter';
import { Paginator } from './Paginator';

export class Controls extends React.Component {
  state = {
    count: this.props.pagination.count || 30,
    limiter: {
      selectedValue: this.props.pagination.params.limit,
      values: this.props.limiter.values,
    },
    paginator: {
      maxValue: this.props.paginator.maxValue,
      offset: this.props.pagination.params.offset,
    },
  };

  static getDerivedStateFromProps(props, state) {
    return {
      count: props.pagination.count,
      limiter: {
        selectedValue: props.pagination.params.limit,
        values: props.limiter.values,
      },
      paginator: {
        maxValue: props.paginator.maxValue,
        offset: props.pagination.params.offset,
      },
    };
  }

  limitChangeProxy = val => {
    this.setState(
      {
        limiter: {
          ...this.state.limiter,
          selectedValue: val,
        },
      },
      () => this.props.limitChange(val)
    );
  };

  pageChangeProxy = val => {
    this.setState(
      {
        paginator: {
          ...this.state.paginator,
          offset: val * this.state.limiter.selectedValue,
        },
      },
      () => this.props.pageChange(val)
    );
  };

  render() {
    const { pagination, customLimiter, customPaginator, ignorePageChange, controlsNoPadding } = this.props;

    const { limiter, count, paginator } = this.state;

    return (
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        paddingLeft: controlsNoPadding ? 0 : '1rem',
        paddingRight: controlsNoPadding ? 0 : '1rem',
        paddingBottom: controlsNoPadding ? 0 : '1rem',
      }} className="custom-table__controls">
        <div>
          <Limiter
            limitChange={this.limitChangeProxy}
            values={limiter.values}
            selectedValue={pagination.params.limit}
            customLimiter={customLimiter}
          />
        </div>
        <div>
          <Paginator
            ignorePageChange={ignorePageChange}
            pagesCount={Math.ceil(count / limiter.selectedValue)}
            maxValue={paginator.maxValue}
            nowPage={Math.floor(paginator.offset / limiter.selectedValue) + 1}
            changeOffset={this.pageChangeProxy}
            customPaginator={customPaginator}
          />
        </div>
      </div>
    );
  }
}
