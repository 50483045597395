import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";

import {
  parseTransformedNumber,
  parseTransformedString,
  transformDigitToFinancial,
} from "../../../../../../../../../../../../utils/formatters/transformDigitToFinancial";

const formatPercentValue = (value: string | number) =>
  transformDigitToFinancial(parseTransformedString(value)!, { withFloat: true, dropZeros: true });

interface IArgs {
  amountSource: number;
  cost: number;
  percent: number;
  changeCost: (v: string) => void;
}

export const useMarkupDoubleInput = ({
  amountSource,
  cost: initialCost,
  percent: initialPercent,
  changeCost,
}: IArgs) => {
  const [cost, setCost] = useState(
    () => transformDigitToFinancial(initialCost, { withFloat: true, dropZeros: true }) || "0"
  );
  const [percent, setPercent] = useState(() => formatPercentValue(initialPercent));

  const debouncedChangeCost = useMemo(() => debounce((cost: string) => changeCost(cost), 500), [changeCost]);

  const onChangePercent = useCallback(
    (value: string | number) => {
      let valueWithoutPercent = parseTransformedNumber(value) ?? 0;
      if (isNaN(+valueWithoutPercent) || +valueWithoutPercent >= 100 || +valueWithoutPercent < 0) return;

      // @ts-ignore
      // из-за этого работает точка в числах процента
      setPercent(parseTransformedString(value));

      const beautifiedNewCost = transformDigitToFinancial((valueWithoutPercent * +amountSource) / 100, {
        withFloat: true,
        dropZeros: true,
      });
      setCost(beautifiedNewCost);
      debouncedChangeCost(parseTransformedNumber(beautifiedNewCost)!.toString());
    },
    [debouncedChangeCost, amountSource]
  );

  const onChangeCost = useCallback(
    (value: string | number) => {
      let beautifiedValue = transformDigitToFinancial(parseTransformedString(value)!, {
        withFloat: true,
        dropZeros: true,
      });
      const parsedNumber = parseTransformedNumber(beautifiedValue) ?? 0;

      if (parsedNumber === null || isNaN(+parsedNumber) || +parsedNumber < 0) return;

      debouncedChangeCost(parsedNumber.toString());
      setPercent(formatPercentValue(((+parsedNumber * 100) / +amountSource).toString()));
      setCost(beautifiedValue);
    },
    [debouncedChangeCost, amountSource]
  );

  return { changedCost: cost, changedPercent: percent, onChangePercent: onChangePercent, onChangeCost: onChangeCost };
};
