import cn from "classnames";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import PopoverOverlay from "../../../../components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import styles from "./index.module.scss";

const MenuItem = React.forwardRef(
  (
    {
      isExists = true,
      link,
      title,
      icon,
      jsxIcon,
      className = "",
      exact = false,
      isDirectlyActive,
      isBottom = false,
      isWithoutUnderline = false,
      onClick,
      children,
      isTooltipHidden = false,
      isWithIndicator = false,
    },
    ref
  ) => {
    const linkRef = useRef(null);
    const contentRef = useRef(null);
    ref = ref || contentRef;
    const [isOpenTooltip, setIsOpenTooltip] = useState(false);

    const openTooltip = useCallback(() => setIsOpenTooltip(true), []);
    const closeTooltip = useCallback(() => setIsOpenTooltip(false), []);

    useEffect(() => {
      if (!isOpenTooltip) return;
      const listenToCloseTooltip = debounce(
        (e) => !(ref.current && e.target instanceof Node && ref.current.contains(e.target)) && closeTooltip(),
        20
      );
      window.addEventListener("mousemove", listenToCloseTooltip);
      window.addEventListener("touchmove", listenToCloseTooltip);
      return () => {
        window.removeEventListener("mousemove", listenToCloseTooltip);
        window.removeEventListener("touchmove", listenToCloseTooltip);
      };
    }, [isOpenTooltip, ref.current, closeTooltip]);

    if (!isExists) return null;

    const Content = () => (
      <div
        className={cn(
          styles.container,
          {
            [styles.bottom]: isBottom && !isWithoutUnderline,
            [styles.left]: !isBottom && !isWithoutUnderline,
            [styles.withIndicator]: isWithIndicator,
          },
          className
        )}
        data-testid={`nav_${title}`}
        onClick={onClick}
        onMouseEnter={openTooltip}
        onTouchStart={openTooltip}
        onMouseLeave={closeTooltip}
        onTouchEnd={closeTooltip}
        onTouchCancel={closeTooltip}
        ref={ref}
      >
        {jsxIcon ? jsxIcon : <img src={icon} alt={title} className={styles.icon} />}
        {children}
      </div>
    );

    const withLink = (Component) => {
      if (!link) return <Component />;
      return (
        <NavLink
          to={link}
          activeClassName={styles.active}
          exact={exact}
          className={cn({ [styles.active]: isDirectlyActive })}
          ref={linkRef}
        >
          <Component />
        </NavLink>
      );
    };

    return (
      <PopoverOverlay
        isOpen={isOpenTooltip && !isTooltipHidden}
        placement={isBottom ? "bottom" : "right"}
        popoverBorderColor={"primary"}
        portalClassName={cn(styles.tooltipPortal, {
          [styles.isRightPortal]: !isBottom,
          [styles.isBottomPortal]: isBottom,
        })}
        content={
          <div
            className={styles.tooltip}
            onClick={() => linkRef?.current?.click?.() || onClick?.()}
            onMouseLeave={closeTooltip}
            onTouchEnd={closeTooltip}
            onTouchCancel={closeTooltip}
          >
            {title}
          </div>
        }
      >
        {withLink(Content)}
      </PopoverOverlay>
    );
  }
);

export default React.memo(MenuItem);
