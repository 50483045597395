import cn from "classnames";
import React from "react";

import { TELEGRAM_MESSAGE_LINK, VK_GROUP_LINK, WHATSAPP_MESSAGE_LINK } from "../../../constants";

import tgIcon from "../../../../../../images/homepage/contact/telegram.webp";
import vkIcon from "../../../../../../images/homepage/contact/vk.webp";
import waIcon from "../../../../../../images/homepage/contact/whatsapp.webp";

import styles from "./SocialLinks.module.scss";

export interface ISocialLinksProps {
  className?: string;
}

const SocialLinks: React.FC<ISocialLinksProps> = ({ className }) => (
  <ul className={cn(styles.links, className)}>
    <li>
      <a href={VK_GROUP_LINK} rel={"noopener noreferrer"} target="_blank" className={styles.socialLink}>
        <img src={vkIcon} alt="VK" />
      </a>
    </li>
    <li>
      <a href={TELEGRAM_MESSAGE_LINK} rel={"noopener noreferrer"} target="_blank" className={styles.socialLink}>
        <img src={tgIcon} alt="Telegram" />
      </a>
    </li>
    <li>
      <a href={WHATSAPP_MESSAGE_LINK} rel={"noopener noreferrer"} target="_blank" className={styles.socialLink}>
        <img src={waIcon} alt="Whatsapp" />
      </a>
    </li>
  </ul>
);

export default React.memo(SocialLinks);
