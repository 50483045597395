import React from 'react'

function CheckIcon() {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 16.5064C6.41775 16.5064 4.87103 16.0372 3.55544 15.1581C2.23985 14.2791 1.21447 13.0296 0.608967 11.5678C0.00346625 10.106 -0.15496 8.49748 0.153721 6.94563C0.462403 5.39378 1.22433 3.96832 2.34315 2.8495C3.46197 1.73068 4.88743 0.968751 6.43928 0.660069C7.99113 0.351387 9.59966 0.509814 11.0615 1.11531C12.5233 1.72082 13.7727 2.7462 14.6518 4.06179C15.5308 5.37738 16 6.9241 16 8.50635C15.9976 10.6274 15.154 12.6608 13.6542 14.1606C12.1545 15.6603 10.121 16.504 8 16.5064ZM4.28 8.46035L3.289 9.45035L6.821 12.9824L13.979 5.82335L12.989 4.83335L6.822 11.0064L4.28 8.46035Z" fill="white" />
        </svg>
    )
}

export default CheckIcon