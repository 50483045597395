import Security1Svg from "./img/security1.svg";
import Security2Svg from "./img/security2.svg";
import Security3Svg from "./img/security3.svg";
import Security4Svg from "./img/security4.svg";

export const SafetySection = () => (
  <section className="safety__fon" id="safety">
    <div className="container">
      <h2>Безопасность</h2>
      <div className="safety__flex">
        <div className="safety__box">
          <strong>Непрерывность бизнеса и аварийное восстановление</strong>
          <ul>
            <li>Непрерывность бизнеса и аварийное восстановление</li>
            <li>Ведение лог-журналов</li>
          </ul>
          <div className="safety__photo">
            <img src={Security1Svg} alt="image" />
          </div>
        </div>
        <div className="safety__box">
          <strong>Обеспечение защиты сервера от взлома</strong>
          <ul>
            <li>Дата-центры находятся в Российской Федерации</li>
            <li>Серверы защищены от всех типов DDoS-атак</li>
          </ul>
          <div className="safety__photo">
            <img src={Security2Svg} alt="image" />
          </div>
        </div>
        <div className="safety__box">
          <strong>Регулярное тестирование на стандарты качества</strong>
          <ul>
            <li>Соответствие стандарту NIST 800-95</li>
            <li>Соответствие требованиям 152-ФЗ</li>
          </ul>
          <div className="safety__photo">
            <img src={Security3Svg} alt="image" />
          </div>
        </div>
        <div className="safety__box">
          <strong>Безопасность аккаунта</strong>
          <ul>
            <li>Политики паролей</li>
            <li>Шифрование при передаче и хранении данных</li>
            <li>Анонимный доступ</li>
          </ul>
          <div className="safety__photo">
            <img src={Security4Svg} alt="image" />
          </div>
        </div>
      </div>
      <span className="title-bot">Работайте в безопасной среде</span>
    </div>
  </section>
)