import { compose } from "redux";
import axios from "axios";

import { getFilesRequestAction, getFilesSuccessAction } from "../actions";

const getFiles = (orderId, userType) => async (dispatch) => {
  dispatch(getFilesRequestAction());

  await axios.get(`/${userType}/orders/${orderId}/files/`).then((response) => {
    compose(dispatch, getFilesSuccessAction)(response.data.results);
  });
};

export { getFiles };