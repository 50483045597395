import React from "react";

import { IDetailedKs2InActing } from "redux/modules/common/building/journal/acting/types";

import Actions from "shared/ui/controls/Actions/Actions";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./JournalKs3ModalRow.module.scss";

interface IProps {
  isFirst?: boolean;
  parentName?: string;
  expenditure: IDetailedKs2InActing["items"][0]["expenditures"][0];
}

const JournalKs3ModalSubRow: React.FC<IProps> = ({ isFirst, expenditure, parentName }) => {
  return (
    <>
      {isFirst && <div className={styles.expGroupHeader}>{parentName}</div>}
      <TableReusableRow isHoverable={false} className={styles.expRow} containerClassName={styles.expRowWrapper}>
        <TableReusableCell>{expenditure.row_number}</TableReusableCell>
        <TableReusableCell isNoBreak>{expenditure.name}</TableReusableCell>
        <TableReusableCell isNoBreak>
          {expenditure.todo_count} {expenditure.measure}
        </TableReusableCell>
        <TableReusableCell isRighted>{splitThousands(expenditure.amount)}</TableReusableCell>
      </TableReusableRow>
    </>
  );
};

export default React.memo(JournalKs3ModalSubRow);
