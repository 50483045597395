import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

export const stateSelector = (state: RootState) => state.sendMultiplePlansState;

export const multiplePlanApprovingsSelector = createSelector(stateSelector, (state) => state.sections);
export const multiplePlanApprovingsCountsSelector = createSelector(stateSelector, (state) => state.counts);
export const multiplePlanApprovingsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const multiplePlanApprovingsInvalidateKeySelector = createSelector(
  stateSelector,
  (state) => state.invalidateKey
);
