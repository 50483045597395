import React, { useEffect, useState } from "react";

import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import ModalContainer from "../components/ModalContainer/ModalContainer";

import TabBarNotLinks from "../../../../../shared/ui/controls/TabBar/TabBarNotLinks";
import JournalDoneMaterials from "./JournalDoneMaterials";
import JournalDoneWorks from "./JournalDoneWorks";

import styles from "./JournalDoneModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  data: IExpendituresInJournalFulfillment["items"][0];
  stockId: number;
}

function JournalDoneModal({ isOpen, onClose, data, stockId }: IProps) {
  const [activeTab, setActiveTab] = useState<"works" | "materials">("works");
  const [isTabsExist, setIsTabsExist] = useState(true);
  const ticketItem = data.expenditure;

  useEffect(() => {
    if (!isOpen) {
      setIsTabsExist(true);
      setActiveTab("works");
    }
  }, [isOpen]);

  return (
    <ModalContainer
      isOpen={isOpen && !!data}
      onClose={onClose}
      name={ticketItem?.name}
      justification={ticketItem?.justification}
    >
      {isTabsExist && (
        <TabBarNotLinks
          tabs={[
            { text: "Работы", id: "works" },
            { text: "Материалы", id: "materials" },
          ]}
          onClick={setActiveTab as any}
          activeId={activeTab}
          className={styles.tabs}
          isOnlyTabsView
        />
      )}
      {isOpen && activeTab === "works" && <JournalDoneWorks data={data} />}
      {isOpen && (
        <JournalDoneMaterials
          data={data}
          isOpen={isOpen}
          onHideTabs={setIsTabsExist}
          isExist={activeTab === "materials"}
          stockId={stockId}
        />
      )}
    </ModalContainer>
  );
}

export default JournalDoneModal;
