import { PostRankType } from "../../../../../../../types/interfaces/Posts";

export enum POSITION_MODAL_TITLES {
  new = "Создание должности",
  edit = "Редактирование должности",
}

export enum POSITION_STAKES {
  hour = "час",
  shift = "смену",
  month = "месяц",
}

// low to high
export const RANK_TYPE_PRIORITIES: Record<PostRankType, number> = {
  hour: 0,
  shift: 1,
  month: 2,
};

export const MAX_RANK = 12;

export const STAKES_OPTIONS: PostRankType[] = ["hour", "shift", "month"];
