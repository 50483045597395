import { Dispatch } from "redux";

import { autoCompleteOutOfEstimateActions } from "./autoCompleteOutOfEstimate";
import { autoCompleteOutOfEstimateApi } from "./autoCompleteOutOfEstimateApi";

import { IGetAutoCompleteOutOfEstimateReq } from "./types";

export const getOutOfEstimateHelpExpenditures =
  (props: IGetAutoCompleteOutOfEstimateReq & { key: string }) => (dispatch: Dispatch) => {
    const { key, ...req } = props;
    dispatch(autoCompleteOutOfEstimateActions.setLoading({ key: props.key, status: true }));
    autoCompleteOutOfEstimateApi
      .getExpenditures(req)
      .then(({ data }) => {
        dispatch(autoCompleteOutOfEstimateActions.setExpenditures({ key: props.key, exps: data.results }));
      })
      .finally(() => {
        dispatch(autoCompleteOutOfEstimateActions.setLoading({ key: props.key, status: false }));
      });
  };
