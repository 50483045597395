import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SuppliersState {
  isLoading: Record<string, boolean>;
  invalidateKey: number;
  detailSuppliers: Record<string, any>;
  suppliersList: Record<string, any>;
}

const initialState: SuppliersState = {
  isLoading: {},
  invalidateKey: 0,
  detailSuppliers: {},
  suppliersList: {},
};

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      const { key, status } = action.payload;
      state.isLoading[key] = status;
    },
    invalidateKey: (state) => {
      state.invalidateKey++;
    },
    setSupplier: (state, action: PayloadAction<{ key: string; data: any }>) => {
      const { key, data } = action.payload;
      state.detailSuppliers[key] = data;
    },
    setSuppliersList: (state, action: PayloadAction<{ key: string; data: any }>) => {
      const { key, data } = action.payload;
      state.detailSuppliers[key] = data;
    },
    setMoreSuppliersList: (state, action: PayloadAction<{ key: string; data: any }>) => {
      const { key, data } = action.payload;
      state.detailSuppliers[key].results = [...state.detailSuppliers[key].results, ...data.results];
    },
  },
});

export const suppliersActions = suppliersSlice.actions;

export default suppliersSlice.reducer;
