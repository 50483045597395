import cn from "classnames";
import React, { useState } from "react";

import {
  IPlanFactExpenditureInner,
  IPlanFactExpenditureV2,
} from "redux/modules/_TODO/constructing/finance/planFact/types";

import { isFactMoreThanPlanChecker } from "../../PlanFactExpenditure";
import FactAcceptedMaterial from "../FactAcceptedMaterial/FactAcceptedMaterial";
import FactWork from "../FactWork/FactWork";

import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";
import { percentsFormatter } from "utils/percentsFormatter";

import GroupByIcon from "images/icons/GroupByIcon";

import styles from "./../../PlanFactExpenditure.module.scss";
import localStyles from "./PlanFactGroupSubExpenditure.module.scss";

interface IProps {
  item: IPlanFactExpenditureV2 & {
    works: IPlanFactExpenditureInner[];
    materials: IPlanFactExpenditureInner[];
    services: IPlanFactExpenditureInner[];
  };
  isExpandedDifference: boolean;
}

const PlanFactGroupSubExpenditure: React.FC<IProps> = ({ item, isExpandedDifference }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasMaterials = !!item?.materials?.length;
  const hasWorks = !!item?.works?.length;
  const hasMims = !!item?.services?.length;

  const isFieldGroup = hasMaterials || hasWorks || hasMims;

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.notFieldGroup, localStyles.fieldGroup, {
          [styles.fieldGroup]: isFieldGroup,
          [styles.open]: isOpen,
        })}
      >
        <div className={cn(styles.icon)} onClick={() => setIsOpen(!isOpen)}>
          {isFieldGroup && <GroupByIcon color={isOpen ? "#fff" : "#4FB2ED"} />}
        </div>
        {/* <div className={styles.line} /> */}
      </div>
      <div className={styles.content}>
        <div className={cn(styles.leftSide, localStyles.leftSide)}>
          <div className={cn(styles.field1, localStyles.field1)}>{item?.number}</div>
          <div className={cn(styles.field2, localStyles.field2)} title={item?.name}>
            {item?.name}
          </div>
          <div className={styles.field3}>{!isExpandedDifference && item?.measure}</div>
        </div>
        <div className={cn(styles.rightSide, { [styles.withExpandedDifference]: isExpandedDifference })}>
          {!isExpandedDifference && (
            <div className={styles.fieldRight1}>{dropNonSignificantZeros(item?.plan_count)!}</div>
          )}
          <div className={styles.fieldRight2}>
            {transformDigitToFinancial(item?.price!, {
              withFloat: true,
              dropZeros: true,
            })}
          </div>
          <div className={styles.fieldRight3}>
            {transformDigitToFinancial(item?.plan_amount!, {
              withFloat: true,
              dropZeros: true,
            })}
          </div>
          {!isExpandedDifference && (
            <div
              className={cn({
                [styles.fieldEmpty]: !item?.fact_count,
                [styles.warning]: isFactMoreThanPlanChecker(item?.plan_count!, item?.fact_count!),
              })}
            >
              {item?.fact_count || "-"}
            </div>
          )}
          <div
            className={cn(styles.fieldRight5, {
              [styles.fieldEmpty]: !item?.fact_avg_price,
              [styles.warning]: isFactMoreThanPlanChecker(item?.price!, item?.fact_avg_price!),
            })}
          >
            {transformDigitToFinancial(item?.fact_avg_price!, {
              withFloat: true,
              dropZeros: true,
            }) || "-"}
          </div>
          <div
            className={cn(styles.fieldRight6, {
              [styles.fieldEmpty]: !item?.fact_amount,
              [styles.warning]: isFactMoreThanPlanChecker(item?.plan_amount!, item?.fact_amount!),
            })}
          >
            {transformDigitToFinancial(item?.fact_amount!, {
              withFloat: true,
              dropZeros: true,
            }) || "-"}
          </div>
          <div className={cn(styles.fieldRight7, { [styles.warning]: parseFloat(item?.diff_amount_percent!) < 0 })}>
            {percentsFormatter(parseFloat(item?.diff_amount_percent!)).replaceAll(/%/g, "")}
          </div>
          {isExpandedDifference && (
            <div className={cn(styles.fieldRight8, { [styles.warning]: parseFloat(item?.diff_amount!) < 0 })}>
              {transformDigitToFinancial(item?.diff_amount!, { withFloat: true, dropZeros: true })}
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <>
          {hasMaterials && (
            <>
              <div className={styles.materialBlock}>
                <div className={styles.countLabel}>Материалы:</div>
              </div>
              {item?.materials?.map((material, index) => (
                <div className={localStyles.subItem} key={index}>
                  <FactAcceptedMaterial material={material} key={index} isExpandedDifference={isExpandedDifference} />
                </div>
              ))}
            </>
          )}
          {hasWorks && (
            <>
              <div className={styles.materialBlock}>
                <div className={styles.countLabel}>Работы:</div>
              </div>
              {item?.works.map((work, index) => (
                <div className={localStyles.subItem} key={index}>
                  <FactWork work={work} key={index} isExpandedDifference={isExpandedDifference} />
                </div>
              ))}
            </>
          )}
          {hasMims && (
            <>
              <div className={styles.materialBlock}>
                <div className={styles.countLabel}>МиМ:</div>
              </div>
              {item?.services.map((service, index) => (
                <div className={localStyles.subItem} key={index}>
                  <FactAcceptedMaterial material={service} isExpandedDifference={isExpandedDifference} />
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PlanFactGroupSubExpenditure;
