import cn from "classnames";
import React from "react";

import {
  REMARK_STATUSES,
  RemarkStatus as RemarkStatusType,
} from "../../../../../../../redux/modules/common/building/journal/journalExecution/types";

import styles from "./RemarkStatus.module.scss";

interface IProps {
  status: RemarkStatusType;
  className?: string;
}

const RemarkStatus: React.FC<IProps> = ({ status, className }) => {
  return (
    <div className={cn(styles.container, styles[status], className)}>
      <div className={styles.marker} />
      {REMARK_STATUSES[status]}
    </div>
  );
};

export default React.memo(RemarkStatus);
