import { splitThousands } from "./splitThousands";

function valueInThousandsOrMillions(value: number, measure: number, isAbs: boolean) {
  let numericValue: number | string = (value / measure).toFixed(2);
  //@ts-ignore
  if (isNaN(numericValue)) return "0.00";
  //@ts-ignore
  if (isAbs && isNaN(Math.abs(numericValue))) return "0.00";

  if (isAbs) {
    //@ts-ignore
    numericValue = Math.abs(numericValue);
  }

  const res = numericValue ? splitThousands(numericValue, false, false) : "0.00";
  return res;
}

export default valueInThousandsOrMillions;
