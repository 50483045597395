import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  checkIntersectingKs2,
  createKs2,
  createKsAfterExcluding,
} from "redux/modules/common/building/journal/acting/thunks";
import { ISelectedActingIds, ISelectedSectionInActing } from "redux/modules/common/building/journal/acting/types";

import { initialCreateKsData } from "../constants";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import { checkCreatingKs2IsDirty } from "./utils";

export interface IActData {
  number: string;
  dateStart: string;
  dateEnd: string;
}

export const useCreateKs2 = (
  selectedSections: ISelectedSectionInActing[],
  selectedIds: ISelectedActingIds,
  isOpen: boolean
) => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const [actData, setActData] = React.useState<IActData>(initialCreateKsData);

  const actNumberHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setActData((prev) => ({ ...prev, number: e.target.value }));
  }, []);

  const dateStartHandler = React.useCallback((date: string) => {
    setActData((prev) => ({ ...prev, dateStart: date }));
  }, []);

  const dateEndHandler = React.useCallback((date: string) => {
    setActData((prev) => ({ ...prev, dateEnd: date }));
  }, []);

  const submitHandler = React.useCallback(() => {
    dispatch(
      createKsAfterExcluding(objectId, {
        title: actData.number,
        start_at: moment(actData.dateStart).format("YYYY-MM-DD"),
        end_at: moment(actData.dateEnd).format("YYYY-MM-DD"),
        ...selectedIds,
      })
    );
  }, [actData, objectId, selectedIds]);

  const submitAfterExcluding = React.useCallback(() => {
    dispatch(
      createKsAfterExcluding(objectId, {
        title: actData.number,
        start_at: moment(actData.dateStart).format("YYYY-MM-DD"),
        end_at: moment(actData.dateEnd).format("YYYY-MM-DD"),
        ...selectedIds,
      })
    );
  }, [actData, objectId, selectedIds]);

  const isShowSubmit = checkCreatingKs2IsDirty(actData, selectedIds.section_ids, selectedIds.expenditure_ids);

  React.useEffect(() => {
    if (!isOpen) {
      setActData({ ...initialCreateKsData });
    }
  }, [isOpen]);

  return {
    actData,
    actNumberHandler,
    dateEndHandler,
    dateStartHandler,
    submitHandler,
    submitAfterExcluding,
    isShowSubmit,
  };
};
