import { match, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";
import Building from "types/interfaces/Building";

type Params = { [key: string]: string };

const useValidateCurrentBuildingAvailable = <T extends Params>(match: match<{ objectId: string } & T>) => {
  const currentBuildingId = match.params.objectId;
  const projects: { results: Building[] } = useSelector(objectsBreadcrumbsSelector);
  const history = useHistory();

  const buildUrlToProject = (projectId: Building["id"]) => {
    return match.path
      .split("/")
      .map((part) => {
        const splitPart = part.split("");
        if (splitPart.shift() === ":") return part === ":objectId" ? projectId : match.params[splitPart.join("")];
        return part;
      })
      .join("/");
  };

  useEffect(() => {
    if (
      !projects ||
      projects.results.length === 0 ||
      projects.results.some((project) => project.id === +currentBuildingId)
    )
      return;
    history.replace(buildUrlToProject(projects.results[0].id));
  }, [projects, currentBuildingId, history]);
};

export default useValidateCurrentBuildingAvailable;
