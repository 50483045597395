import cn from "classnames";
import React from "react";

import { CloseIcon } from "_LEGACY/UI/_LEGACY_Purchases_components/CommentPopup/CloseIcon";

import { DeleteIcon } from "shared/ui/modal/Modal/DeleteIcon";

import styles from "./JournalExcludeItem.module.scss";

interface IProps {
  name: string;
  subName?: string;
  isApproved?: boolean;
  onApprove: () => void;
  onDelete: () => void;
}

const JournalExcludeItem: React.FC<IProps> = ({ isApproved, onDelete, onApprove, name, subName }) => {
  return (
    <div className={cn(styles.container, { [styles.approved]: isApproved })}>
      <div className={styles.delete} onClick={onDelete}>
        <DeleteIcon />
      </div>
      <h4 className={styles.title}>Наименование расценки:</h4>
      <p className={styles.text}>{name}</p>
      <h4 className={styles.title}>Содержится в акте:</h4>
      <p className={styles.text}>КС-2 №{subName}</p>
      <div className={styles.approve} onClick={onApprove}>
        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.38697 13.846L1.05897 8.33003C0.84622 8.10685 0.727539 7.81036 0.727539 7.50203C0.727539 7.1937 0.84622 6.8972 1.05897 6.67403C1.16179 6.56507 1.28579 6.47827 1.42335 6.41894C1.56092 6.35961 1.70915 6.32901 1.85897 6.32901C2.00878 6.32901 2.15702 6.35961 2.29458 6.41894C2.43215 6.47827 2.55614 6.56507 2.65897 6.67403L7.11597 11.287L14.657 0.922027C14.7434 0.799588 14.8538 0.695963 14.9815 0.617423C15.1091 0.538883 15.2514 0.48706 15.3997 0.465091C15.5479 0.443122 15.6991 0.451464 15.8441 0.489611C15.989 0.527759 16.1247 0.594919 16.243 0.687027C16.4839 0.879519 16.6414 1.15735 16.6829 1.46289C16.7244 1.76843 16.6468 2.07823 16.466 2.32803L8.29697 13.539C8.21887 13.6503 8.12089 13.7463 8.00797 13.822C8.00078 13.8302 7.9931 13.8378 7.98497 13.845C7.88188 13.9534 7.75777 14.0397 7.62022 14.0985C7.48266 14.1573 7.33456 14.1874 7.18497 14.187C7.03587 14.1867 6.88837 14.1563 6.75126 14.0977C6.61416 14.0392 6.49026 13.9535 6.38697 13.846Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

export default React.memo(JournalExcludeItem);
