import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";

import Icon from "../../../../../../../_LEGACY/UI/_LEGACY_Icon/Icon";
import PopoverOverlay from "../../../../../../UI/_TODO/PopoverOverlay/PopoverOverlay";
import RelationsHeader from "./components/RelationsHeader/RelationsHeader";

import TableReusableHead, {
  TableReusableHeaderCell,
} from "../../../../../../../shared/ui/dataDisplay/TableReusable/TableReusableHead";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import { InputSearchRound } from "shared/ui/inputs/InputSearchRound/InputSearchRound";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { RELATION_TYPES, RelationType } from "../../../../constants";
import { IDisplayedRelation, RelationDirectionType, RelationsDirection } from "./types";

import useOnClickOutside from "../../../../../../../hooks/useOnClickOutside";

import scheduleIcon from "../../../../../../../images/icons/navigation/scheduleIcon.svg";
import editIcon from "images/icons/editIcon.svg";
import relationHHFrom from "images/icons/relationHHFrom.svg";
import relationHHTo from "images/icons/relationHHTo.svg";
import relationHOFrom from "images/icons/relationHOFrom.svg";
import relationHOTo from "images/icons/relationHOTo.svg";
import relationOHFrom from "images/icons/relationOHFrom.svg";
import relationOHTo from "images/icons/relationOHTo.svg";
import relationOOFrom from "images/icons/relationOOFrom.svg";
import relationOOTo from "images/icons/relationOOTo.svg";

import styles from "./IntervalRelationsContent.module.scss";

export interface IIntervalRelationsContentProps {
  hasEditPermission?: boolean;
  intervalId: number;
  projectId: number;
  relationsFromCurrentInterval: IDisplayedRelation[];
  relationsToCurrentInterval: IDisplayedRelation[];
  intervalStartAt: string;
  intervalEndAt: string;
  isEditRelationsByDefault?: boolean;
  isIntervalGroup?: boolean;
  onAddRelationCallback?: () => void;
}

export interface IAddingRelationInModal {
  type: RelationType;
  direction: RelationDirectionType;
}

export interface IAddRelationBlock {
  direction: RelationDirectionType;
  type: RelationType;
  label: string;
  icon: string;
  relations: IDisplayedRelation[];
}

const IntervalRelationsContent: React.FC<IIntervalRelationsContentProps> = ({
  hasEditPermission,
  intervalId,
  projectId,
  relationsFromCurrentInterval,
  relationsToCurrentInterval,
  intervalStartAt,
  intervalEndAt,
  isEditRelationsByDefault,
  isIntervalGroup = false,
  onAddRelationCallback,
}) => {
  const isWithoutRelations = relationsToCurrentInterval?.length === 0 && relationsFromCurrentInterval?.length === 0;
  const [isEditMode, setIsEditMode] = useState((isWithoutRelations || isEditRelationsByDefault) && hasEditPermission);

  const [addingRelationParams, setAddingRelationParams] = useState<IAddingRelationInModal | null>(null);

  const toggleEditMode = useCallback(
    () => !isWithoutRelations && setIsEditMode((prevState) => !prevState),
    [isWithoutRelations]
  );

  const addRelationBlocks: IAddRelationBlock[] = useMemo(() => {
    return [
      {
        direction: RelationsDirection.from,
        type: RELATION_TYPES.oh,
        label: "Начнется после окончания работы",
        icon: relationOHFrom,
        relations: relationsFromCurrentInterval?.filter((x) => x.related_type === RELATION_TYPES.oh),
      },
      {
        direction: RelationsDirection.to,
        type: RELATION_TYPES.oh,
        label: "Окончится перед началом работы",
        icon: relationOHTo,
        relations: relationsToCurrentInterval?.filter((x) => x.related_type === RELATION_TYPES.oh),
      },
      {
        direction: RelationsDirection.from,
        type: RELATION_TYPES.hh,
        label: "Начнется до начала работы",
        icon: relationHHFrom,
        relations: relationsFromCurrentInterval?.filter((x) => x.related_type === RELATION_TYPES.hh),
      },
      {
        direction: RelationsDirection.to,
        type: RELATION_TYPES.hh,
        label: "Начнется после начала работы",
        icon: relationHHTo,
        relations: relationsToCurrentInterval?.filter((x) => x.related_type === RELATION_TYPES.hh),
      },
      {
        direction: RelationsDirection.from,
        type: RELATION_TYPES.ho,
        label: "Окончится после начала работы",
        icon: relationHOFrom,
        relations: relationsFromCurrentInterval?.filter((x) => x.related_type === RELATION_TYPES.ho),
      },
      {
        direction: RelationsDirection.to,
        type: RELATION_TYPES.ho,
        label: "Начнется до начала работы",
        icon: relationHOTo,
        relations: relationsToCurrentInterval?.filter((x) => x.related_type === RELATION_TYPES.ho),
      },
      {
        direction: RelationsDirection.to,
        type: RELATION_TYPES.oo,
        label: "Окончится после окончания работы",
        icon: relationOOTo,
        relations: relationsToCurrentInterval?.filter((x) => x.related_type === RELATION_TYPES.oo),
      },
      {
        direction: RelationsDirection.from,
        type: RELATION_TYPES.oo,
        label: "Окончится до окончания работы",
        icon: relationOOFrom,
        relations: relationsFromCurrentInterval?.filter((x) => x.related_type === RELATION_TYPES.oo),
      },
    ].sort((a, b) => (a.type > b.type ? 1 : 0));
  }, [relationsFromCurrentInterval, relationsToCurrentInterval]);

  const [isAddRelationPopupOpen, setIsAddRelationPopupOpen] = useState<boolean>(false);
  const [relationTypeSearchValue, setRelationTypeSearchValue] = useState("");

  const openAddRelationPopup = useCallback(() => setIsAddRelationPopupOpen(true), []);
  const closeAddRelationPopup = useCallback(() => {
    setIsAddRelationPopupOpen(false);
    setRelationTypeSearchValue("");
  }, []);
  const onSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setRelationTypeSearchValue(e.target?.value),
    []
  );
  const popoverRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(popoverRef, closeAddRelationPopup);

  const filteredBlocksInPopup = useMemo(() => {
    let candidates = addRelationBlocks;
    if (relationTypeSearchValue)
      candidates = candidates.filter((block) => block.label.includes(relationTypeSearchValue));
    return candidates;
  }, [addRelationBlocks, relationTypeSearchValue, addingRelationParams, isAddRelationPopupOpen]);

  const filteredBlocksInModal = useMemo(() => {
    return addRelationBlocks.filter(
      (block) =>
        block.relations?.length ||
        (addingRelationParams?.type === block.type && addingRelationParams?.direction === block.direction)
    );
  }, [addRelationBlocks, addingRelationParams]);

  const handleAddTypeChoose = useCallback(
    (block: IAddRelationBlock) => {
      setAddingRelationParams({ type: block.type, direction: block.direction });
      closeAddRelationPopup();
    },
    [setAddingRelationParams, closeAddRelationPopup]
  );

  return (
    <>
      {!!filteredBlocksInModal?.length && (
        <TableReusableHead className={styles.relationsTableHeader}>
          <TableReusableHeaderCell className={styles.relationsTableIntervalName}>
            Наименование работы
          </TableReusableHeaderCell>
          {!addingRelationParams && (
            <TableReusableHeaderCell className={styles.relationsTableIntervalBreak}>
              Перерыв, дни
              {hasEditPermission && <Icon icon={editIcon} className={styles.tableEditIcon} onClick={toggleEditMode} />}
            </TableReusableHeaderCell>
          )}
        </TableReusableHead>
      )}
      <div className={styles.relationsContent}>
        {filteredBlocksInModal.map((block, index) => {
          return (
            <React.Fragment key={index}>
              <RelationsHeader
                block={block}
                isEditMode={hasEditPermission && isEditMode}
                canAddRelation={hasEditPermission}
                projectId={projectId}
                intervalId={intervalId}
                intervalStartAt={intervalStartAt}
                intervalEndAt={intervalEndAt}
                addingRelationParams={addingRelationParams}
                setAddingRelationParams={setAddingRelationParams}
                isIntervalGroup={isIntervalGroup}
                onAddRelationCallback={onAddRelationCallback}
              />
            </React.Fragment>
          );
        })}
        {!filteredBlocksInModal?.length && (
          <EmptyPlaceholder
            text={"Вы еще не создали связи. Свяжите работы на графике или нажмите «Добавить новую связь»"}
            img={scheduleIcon}
            fullHeight={false}
            className={styles.emptyPlaceholder}
          />
        )}
        <PopoverOverlay
          popoverBorderColor="default"
          placement="bottom"
          isDisabled={!!addingRelationParams}
          content={
            <div className={styles.addRelationContent} ref={popoverRef}>
              <div className={styles.searchBlock}>
                <label>Укажите тип связи:</label>
                <InputSearchRound
                  onChange={onSearchChange}
                  value={relationTypeSearchValue}
                  placeholder={"Поиск"}
                  className={styles.searchInput}
                />
              </div>
              <div className={styles.resultsBlock}>
                {filteredBlocksInPopup.map((block, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && block.type !== filteredBlocksInPopup[index - 1]?.type && (
                      <div className={styles.line} />
                    )}
                    <span key={block.label} onClick={() => handleAddTypeChoose(block)}>
                      {block.label}
                    </span>
                  </React.Fragment>
                ))}
              </div>
            </div>
          }
          isOpen={isAddRelationPopupOpen}
          portalClassName={styles.addRelationPortal}
          className={styles.addRelationPopover}
        >
          <AddButton
            text={"Добавить новую связь"}
            onClick={openAddRelationPopup}
            className={styles.addRelationButton}
            isDisabled={!!addingRelationParams}
          />
        </PopoverOverlay>
      </div>
    </>
  );
};

export default React.memo(IntervalRelationsContent);
