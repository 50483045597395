import React from "react";

export default function PlusFillCircleIcon({color = '#4FB2ED', width = '34', ...props}) {
  return (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={width}
    viewBox="0 0 34 34"
    {...props}
  >
    <g id="Сгруппировать_7078" data-name="Сгруппировать 7078" transform="translate(-614 -9)">
      <path 
        id="Контур_5871" 
        data-name="Контур 5871" 
        d="M17,0A17,17,0,1,1,0,17,17,17,0,0,1,17,0Z" 
        transform="translate(614 9)" 
        fill={color}/>
      <path id="Объединение_193" data-name="Объединение 193" d="M-16891.48-8080.484v-7.037h-7.039a1.482,1.482,0,0,1-1.48-1.48,1.482,1.482,0,0,1,1.48-1.48h7.039v-7.036A1.483,1.483,0,0,1-16890-8099a1.483,1.483,0,0,1,1.48,1.484v7.036h7.041a1.482,1.482,0,0,1,1.48,1.48,1.482,1.482,0,0,1-1.48,1.48h-7.041v7.037a1.479,1.479,0,0,1-1.48,1.48A1.479,1.479,0,0,1-16891.48-8080.484Z" transform="translate(17521 8115.003)" fill="#fff"/>
    </g>
  </svg>
  )
}