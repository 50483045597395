import cn from "classnames";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";

import InputSelect from "../../../../../../../_LEGACY/UI/__trash/InputSelect";
import Select from "components/UI/atoms/Select";

import Calendar from "../../../../../../../shared/ui/inputs/Calendar/Calendar";
import InputBase from "../../../../../../../shared/ui/inputs/InputBase";
import PriorityRange from "../../../PriorityRange/PriorityRange";
import TaskApproversAdd from "../../../TaskApprovers/TaskApproversAdd";
import TasksPersonsSelect from "../../../TaskPersonsSelect/TasksPersonsSelect";
import { TimePicker } from "shared/ui/inputs/TimePicker/TimePicker";

import { IdNameLabelType } from "../../../../../../../types/IdNameLabelType";

import { useTaskObjectsSectionsUsers } from "../../../../hooks/useTaskObjectsSectionsUsers";

import {
  composeFieldValidators,
  maxLength,
  minLength,
  required,
} from "../../../../../../../utils/formHelpers/validations";

import styles from "./ModalAddTask.module.scss";

const ModalAddTaskMainFields: React.FC<any> = ({
  presetBuildingId,
  building,
  setBuilding,
  section_id,
  setSectionId,
  isVisible = true,
}) => {
  const { objects, section, users, approvers, setFilter } = useTaskObjectsSectionsUsers({
    building_id: presetBuildingId || building.id,
  });

  useEffect(() => {
    if (!objects || !presetBuildingId) return;
    presetBuildingId &&
      building.id !== presetBuildingId &&
      setBuilding(objects.find((x: any) => x.id === presetBuildingId) || { id: presetBuildingId, name: "" });
  }, [presetBuildingId, objects]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const inputs = containerRef.current?.querySelectorAll("input");
    inputs?.forEach((input) => {
      input.addEventListener("keypress", (e) => {
        if (e.key.toLowerCase() === "enter") {
          e.preventDefault();
        }
      });
    });
  }, []);

  return (
    <div
      ref={containerRef}
      className={cn(styles.fields, {
        [styles.isVisible]: isVisible,
      })}
    >
      <Field
        name={"name"}
        label={"*Название"}
        placeholder={"Название новой задачи"}
        component={InputBase}
        validate={composeFieldValidators(
          required(),
          maxLength(255, "Не более 255 символов"),
          minLength(1, "Введите название задачи")
        )}
        className={styles.outView}
      />
      <Field
        name={"description"}
        validate={maxLength(255, "Не более 255 символов")}
        render={({ input, meta }) => (
          <div className={styles.outView}>
            <label className={styles.label}>Описание задачи</label>
            <TextareaAutosize {...input} placeholder={"Описание задачи"} minRows={3} />
            {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
          </div>
        )}
      />
      <div className={styles.pair}>
        <Field
          name={"deadline"}
          validate={required()}
          render={({ input, meta }) => (
            <div className={styles.outView}>
              <label className={styles.label}>*Контрольная дата</label>
              <Calendar
                value={input.value || meta.initial}
                setValue={(v) => input.onChange(moment(v).add(10, "hours"))}
                classNameSelect={""}
                classNameOptions={styles.calendarOptions}
                label={input.label}
                placeholder={moment().format("DD/MM/YY")}
                format={"DD/MM/YY"}
              />
              {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
            </div>
          )}
        />
        <Field
          name={"deadline"}
          render={({ input, meta }) => (
            <div className={styles.outView}>
              <label className={styles.label}>Время</label>
              <TimePicker deadline={input.value || meta.initial} setValue={input.onChange} />
              {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
            </div>
          )}
        />
      </div>
      <Field
        name={"priority"}
        render={({ input, meta }) => (
          <>
            <PriorityRange
              onChange={input.onChange}
              value={input.value}
              defaultValue={meta.initial}
              className={styles.range}
              label={"*Приоритет:"}
            />
            {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
          </>
        )}
      />
      {!presetBuildingId && (
        <Field
          name={"building"}
          validate={required()}
          render={({ input, meta }) => (
            <div className={styles.outView}>
              <InputSelect
                className={styles.fieldInput}
                optionsBlockClassName={styles.options}
                name={"building"}
                label={"*Проект"}
                placeholder={"Введите или укажите наименование проекта"}
                meta={meta}
                defaultValue={undefined}
                selectedAction={undefined}
                input={{
                  ...input,
                  onChange: (value: IdNameLabelType) => {
                    input.onChange(value);
                    setBuilding(objects.find((x: IdNameLabelType) => x.name?.toString() === value.toString()));
                  },
                  value: building.id,
                }}
                onClick={(value: IdNameLabelType) => setBuilding(objects.find((x: IdNameLabelType) => x.id === value))}
                options={objects}
              />
            </div>
          )}
        />
      )}
      <Field
        name={"section"}
        value={section_id}
        render={({ input, meta }) => (
          <div className={styles.outView}>
            <Select
              className={styles.fieldInput}
              label={"Раздел"}
              placeholder={"Введите или укажите наименование раздела"}
              meta={meta}
              value={input.value}
              input={{
                ...input,
                onChange: (value: number) => {
                  input.onChange(value);
                  setSectionId(value);
                },
                value: section_id,
              }}
              options={section}
            />
          </div>
        )}
      />
      <Field
        name={"executor"}
        validate={required()}
        multiple
        render={({ input, meta }) => (
          <TasksPersonsSelect
            name={"executor"}
            input={{
              ...input,
              onChange: (value: string) => {
                input.onChange(value);
              },
            }}
            options={users}
            label={"*Исполнители"}
            placeholder={"Введите или укажите ФИО исполнителя"}
            meta={meta}
            isSingleSelect
            value={input.value}
          />
        )}
      />
      <Field
        name={"viewers"}
        render={({ input, meta }) => (
          <TasksPersonsSelect
            name={"viewers"}
            input={{
              ...input,
              onChange: (value: string) => {
                input.onChange(value);
              },
            }}
            options={users}
            label={"Контролеры"}
            placeholder={"Введите или укажите ФИО контролера"}
            meta={meta}
            value={input.value ?? []}
          />
        )}
      />
      <Field
        name="approves"
        render={({ input, meta }) => (
          <TaskApproversAdd
            setFilter={(v) => setFilter({ approvers: v })}
            onDelete={(id) => {
              const newIds = input.value.filter((el: number) => +el !== id);
              input.onChange(newIds);
            }}
            addedApprovers={input.value}
            allApprovers={approvers}
            onAccept={input.onChange}
          />
        )}
      />
    </div>
  );
};

export default React.memo(ModalAddTaskMainFields);
