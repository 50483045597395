import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IConstructionControlObject } from "./types";

interface ConstructionControlProjectsState {
  invalidationKey: number;
  loadings: Record<string, boolean>;
  objects: Record<string, IConstructionControlObject[]>;
}

export const initialState: ConstructionControlProjectsState = {
  invalidationKey: 0,
  loadings: {},
  objects: {},
};

const constructionControlProjectsSlice = createSlice({
  name: "constructionControlProjects",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    },
    setObjects: (state, action: PayloadAction<{ objects: IConstructionControlObject[]; key: string }>) => {
      const { objects, key } = action.payload;
      state.objects[key] = objects;
    },
  },
});

export const constructionControlProjectsActions = constructionControlProjectsSlice.actions;

export const constructionControlProjectsReducer = constructionControlProjectsSlice.reducer;
