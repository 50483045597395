import update from "immutability-helper";

const setSuppliesMeasure = (supplies, measure) =>
  supplies.map((supply) =>
    update(supply, {
      measure: { $set: measure },
    })
  );

export const syncOfferSuppliesMeasure = (offers) =>
  offers.map((offer) =>
    update(offer, {
      supplies: { $set: setSuppliesMeasure(offer.supplies, offer.measure) },
    })
  );
