import { IActions } from "app/store/store";

export const projectActions = {
  invalidateKey: () =>
    ({
      type: "project/INVALIDATE_KEY",
      payload: true,
    } as const),
};

export type IProjectActions = IActions<typeof projectActions>;
