import { useRef } from 'react';
import moment from 'moment';

import RangeSumNew from './RangeSumNew/RangeSumNew';

import styles from './index.module.scss';


const CalendarBottom = ({
  setTargetDay,
  targetDay,
  targetMonth,
  targetYear,
  periodSelect,
  timeLine,
  building_id,
  events
}) => {
  const frame = useRef(null);

  const blocks = [
    { name: 'I квартал', range: ['01-01', '03-31'] },
    { name: 'II квартал', range: ['04-01', '06-30'] },
    { name: 'III квартал', range: ['07-01', '09-30'] },
    { name: 'IV квартал', range: ['10-01', '12-31'] },
  ];

  const days = [];
  let dayMod =
    periodSelect === 'all' || document.body.offsetWidth <= 768
      ? 0
      : -1 * (targetDay.isoWeekday() - 1);

  let renderDaysAmount =
    periodSelect === 'all'
      ? 31
      : moment({
          month: targetMonth,
          year: targetYear,
        }).daysInMonth() + Math.abs(dayMod);

  renderDaysAmount =
    renderDaysAmount +
    (document.body.offsetWidth <= 768 ? 3 - (renderDaysAmount % 3) : 7 - (renderDaysAmount % 7));

  while (days.length < renderDaysAmount) {
    const newDate = moment({
      day: targetDay.date(),
      month: targetDay.month(),
      year: targetYear,
    }).add(dayMod, 'days');
    days.push(newDate);
    dayMod += 1;
  }

  const getWeeks = (renderDays) => {
    const weeks = [];

    for (let i = 0; i < Math.floor(+renderDays.length / 7); i++) {
      weeks.push({
        range: [renderDays[i * 7], renderDays[i * 7 + 6]],
      });
    }

    return weeks;
  };

  const getDaysInYear = (() => {
    let timeLineComponent = null;
    let prevYear = null;
    let prevTimeLine = null;

    return () => {
      const year = targetYear;

      if (prevYear === year && prevTimeLine === timeLine) {
        return timeLineComponent;
      }

      const day = 31;
      const month = 12 - 1;
      const date = moment({ day, month, year });
      const days = [];

      for (let i = 1; i <= date.dayOfYear(); i++) {
        days.push(i);
      }

      timeLineComponent = days.map((day, index) => (
        <div key={index} onClick={() => moveFrameTo(day - 15)} className={`${styles.eventDay}`}>
          {timeLine
            .filter(
              (event) =>
                moment(event.date).dayOfYear() === day && moment(event.date).year() === year
            )
            .map((event, index) => (
              <div
                key={index}
                className={styles.eventDayInner}
                style={{
                  backgroundColor: eventColorSelect(event.event_type),
                }}
              />
            ))}
        </div>
      ));

      prevTimeLine = timeLine;
      prevYear = year;
      return timeLineComponent;
    };
  })();

  const moveFrameTo = (day) => {
    const daysInYear = getDaysInYear().length;
    const modifiedDay = Math.min(Math.max(1, day), daysInYear - 29);
    const x = (modifiedDay - 1) / daysInYear;
    let newTarget = moment(new Date());

    newTarget = moment({
      day: newTarget.date(),
      month: newTarget.month(),
      year: targetYear,
    });

    newTarget.add(modifiedDay - newTarget.dayOfYear(), 'days');
    frame.current.style.left = `${x * 100}%`;
    setTargetDay(newTarget);
  };

  const toMonth = (index) => {
    moveFrameTo(moment({ day: 1, month: index, year: targetYear }).dayOfYear());
  };

  const eventColorSelect = (type) => {
    switch (type) {
      case 'payment':
        return '#a866ee';

      case 'supply':
        return '#ee9226';

      default:
        return 'transparent';
    }
  };

  return (
    <div className={styles.controls}>
      {periodSelect === 'all' && (
        <div className={styles.eventsRow}>
          <div className={styles.eventDays}>{getDaysInYear()}</div>
          <div
            ref={(el) => (frame.current = el)}
            className={styles.frame}
            style={{
              left: '0px',
              transition: 'left 0.2s ease-in-out',
            }}
          />
        </div>
      )}
      <div>
        {periodSelect === 'all' && (
          <div className={styles.monthsContainer}>
            {moment.monthsShort().map((month, index) => (
              <div key={index} onClick={() => toMonth(index)}>
                {month}
              </div>
            ))}
          </div>
        )}
        <div className={styles.blocksContainer}>
          {periodSelect === 'all' &&
            blocks.map((block, index) => (
              <div className={styles.block} key={index}>
                <div className={styles.blockName}>{block.name}</div>
                <RangeSumNew
                  isPurchaser={'purchaser'}
                  range={[`${targetYear}-${block.range[0]}`, `${targetYear}-${block.range[1]}`]}
                />
              </div>
            ))}
          {periodSelect !== 'all' &&
            getWeeks(days).map((week, index) => (
              <div className={styles.block} key={index}>
                <div className={styles.weekRange}>
                  {week.range[0].format('DD.MM')}
                  <span> - </span>
                  {week.range[1].format('DD.MM')}
                </div>
                <RangeSumNew
                  isPurchaser={'purchaser'}
                  range={[week.range[0].format('YYYY-MM-DD'), week.range[1].format('YYYY-MM-DD')]}
                  building_id={building_id}
                  events={events}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarBottom;
