import { message } from "antd";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";

import { approveSectionPlan, editSectionPlan } from "redux/modules/common/building/processApi";
import { ISectionPlan } from "redux/modules/common/building/processTypes";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const useEditSectionPlan = (
  planData: ISectionPlan | null,
  dataRefetcher: () => void,
  onClose: () => void,
  projectId: string,
  onEditCallback?: () => void
) => {
  const [dateStart, setDateStart] = React.useState(planData?.start_at);
  const [dateEnd, setDateEnd] = React.useState(planData?.end_at);
  const { objectId: paramsObjectId } = useParams<IRouterParamsWithObjectId>();
  const objectId = projectId || paramsObjectId;

  const saveHandler = React.useCallback(() => {
    if (!planData?.id) return;
    editSectionPlan(objectId, planData.id, {
      start_at: moment(dateStart).format("YYYY-MM-DD"),
      end_at: moment(dateEnd).format("YYYY-MM-DD"),
    })
      .then(() => {
        dataRefetcher();
        onClose();
        message.success("Успешно отредактировано");
        onEditCallback?.();
      })
      .catch(errorCatcher);
  }, [dateEnd, dateStart, planData?.id, objectId, dataRefetcher, onClose]);

  const approveHandler = React.useCallback(() => {
    if (!planData?.id) return;
    approveSectionPlan(objectId, planData.id)
      .then(() => {
        dataRefetcher();
        onClose();
        message.success("Успешно согласовано");
      })
      .catch(errorCatcher);
  }, [planData?.id, objectId, dataRefetcher, onClose]);

  return {
    dateEnd,
    dateStart,
    setDateEnd,
    setDateStart,
    saveHandler,
    approveHandler,
  };
};
