import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import Select from "components/UI/atoms/Select";
import { IDetailedProject } from "pages/Projects/model/types";
import type { ConstructionControlSuffix } from "pages/Projects/model/types";

import { attachConstrControlToBuilding, getConstControlAccounts } from "../../model/thunks";
import ProjectSettingsControlEmployee from "./ProjectSettingsControlEmployee/ProjectSettingsControlEmployee";
import ProjectSettingsControlModal from "./ProjectSettingsControlModal/ProjectSettingsControlModal";

import styles from "./ProjectSettingsControlTab.module.scss";

interface IProps {
  objectId: string;
  project?: IDetailedProject;
}

const ProjectSettingsControlTab: React.FC<IProps> = ({ objectId, project }) => {
  const dispatch = useDispatch();

  const [type, setType] = useState<ConstructionControlSuffix | null>(null);

  return (
    <div>
      {/* <Select
        isLoading={false}
        classNameLabel={styles.label}
        label="Аккаунт стройконтроля"
        className={styles.select}
        options={options}
        onChange={onAttachAccount}
      /> */}
      <div className={styles.grid}>
        <div className={styles.row}>
          <ProjectSettingsControlEmployee
            type={"cc_journal_acceptance"}
            title="Приемка"
            project={project!}
            onClick={setType}
            items={project?.cc_journal_acceptance}
          />
        </div>
        <div className={styles.row}>
          <ProjectSettingsControlEmployee
            onClick={setType}
            type={"cc_journal_performance"}
            title="Выполнение"
            project={project!}
            items={project?.cc_journal_performance}
          />
        </div>
        <div className={styles.row}>
          <ProjectSettingsControlEmployee
            onClick={setType}
            type={"cc_journal_acting"}
            title="Актирование"
            project={project!}
            items={project?.cc_journal_acting}
          />
        </div>
      </div>
      <ProjectSettingsControlModal project={project!} type={type!} isOpen={!!type} onClose={() => setType(null)} />
    </div>
  );
};

export default ProjectSettingsControlTab;
