import cn from "classnames";
import React, { useMemo } from "react";

import { Spinner } from "../../atoms/Spinner/Spinner";

import { personFullNameWithId } from "types/personsTypes";

import getShortFullName from "utils/formatters/getShortFullName";

import CrossIcon from "images/icons/CrossIcon";

import styles from "./EmployeeElem.module.scss";

interface IProps {
  employeName: string | personFullNameWithId;
  content?: React.ReactNode;
  className?: string;
  canDelete?: boolean;
  onDelete?: () => void;
  onClick?: () => void;
  canAdd?: boolean;
  onAdd?: () => void;
  isLoading?: boolean;
  classNameContent?: string;
  disabled?: boolean;
}

const EmployeeElem: React.FC<IProps> = ({
  employeName,
  content,
  className,
  canAdd,
  canDelete,
  onAdd,
  onClick,
  onDelete,
  isLoading,
  classNameContent,
  disabled,
}) => {
  const displayingName = useMemo(() => {
    if (typeof employeName === "string") return employeName;
    return getShortFullName(employeName);
  }, [employeName]);

  if (isLoading) {
    return (
      <div className={cn(styles.container, className)}>
        <Spinner isStatic isSmallGray />
      </div>
    );
  }

  return (
    <div className={cn(styles.container, className, disabled && styles.disabled)}>
      <div className={styles.blueArea}>
        {/* {isShownEmployeePosition && <div className={styles.positionCount}>{employee.position}</div>} */}
        {canDelete && (
          <div className={styles.delete} onClick={onDelete}>
            <CrossIcon width="12" color="#fff" />
          </div>
        )}
        {/* {isApproved && (
          <div>
            <CheckBoldIcon width="12" color="#fff" />
          </div>
        )} */}
        {canAdd && (
          <div className={styles.delete} onClick={onAdd}>
            <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_5049_56561)">
                <path d="M7 2H5V7H0V9H5V14H7V9H12V7H7V2Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_5049_56561">
                  <rect width="12" height="12" fill="white" transform="translate(0 2)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        )}
      </div>
      <div className={cn(styles.info, classNameContent)} onClick={onClick}>
        <div className={styles.name}>{displayingName}</div>
        {!!content && <div className={styles.type}>{content}</div>}
      </div>
      {/* {isShownAddChain && (
        <div className={styles.addIcon} onClick={() => setIsOpen(true)}>
          <PlusIcon color="#4FB2ED" width="14" />
        </div>
      )}
      {isShownChainLine && <div className={styles.chainLine} />} */}
    </div>
  );
};

export default EmployeeElem;
