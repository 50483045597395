import axios from 'axios';

export function apiGetSections(objectId) {
  return axios.get(`/building/${objectId}/materials/estimate/items/`);
}
export function apiGetSubSections(objectId, Id) {
  return axios.get(`building/${objectId}/materials/estimate/items/${Id}/`);
}
export function apiUpdateExpenditure(objectId, Id, config) {
  return axios.patch(`/building/${objectId}/materials/estimate/items/${Id}/`, config);
}
