import React, { FC, memo, useCallback, useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import EmptyMessage from "../../../../../../../ProHandler/components/EmptyMessage/EmptyMessage";

import Spinner from "../../../../../../../../../../shared/ui/atoms/Spinner/Spinner";
import {
  activeSectionSelector,
  checkedItemsIdsSelector,
  estimateStateIdSelector,
  invalidateKeySelector,
  modeSelector,
  subSectionsAreLoadingSelector,
  subSectionsSelector,
} from "../../../../../../model/selectors";
import { simpleHandlerActions } from "../../../../../../model/slice";
import { loadSubSections } from "../../../../../../model/thunks";
import { CommonSectionView } from "../../../SectionsList/ui/Section/CommonSectionView/CommonSectionView";

import ISection from "../../../../../../../../../../types/interfaces/Section";
import { EEstimateStatesIds } from "../../../../../../../ProHandler/enums";

import { useSectionActions } from "../../../SectionsList/hooks/useSectionActions";
import { useDefineActiveSection } from "../../hooks/useDefineActiveSection";

export const CommonSubSectionsListView: FC = memo(() => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const sectionMatch = useRouteMatch(`${match.url}/:sectionId`);
  const history = useHistory();

  const mode = useSelector(modeSelector);
  const sections = useSelector(subSectionsSelector);
  const estimateStateId = useSelector(estimateStateIdSelector);
  const activeSection = useSelector(activeSectionSelector);
  const checkedItemsIds = useSelector(checkedItemsIdsSelector);
  const subSectionAreLoading = useSelector(subSectionsAreLoadingSelector);
  const invalidateKey = useSelector(invalidateKeySelector);

  const { buildingId } = useParams<{ buildingId?: string }>();

  useDefineActiveSection();

  useEffect(() => {
    if (buildingId && estimateStateId && activeSection) {
      dispatch(loadSubSections(buildingId, activeSection.id, estimateStateId));
    }
  }, [estimateStateId, buildingId, activeSection, invalidateKey]);

  const onSectionClick = useCallback(
    (subsection: ISection) => {
      if (sectionMatch) {
        batch(() => {
          dispatch(simpleHandlerActions.setActiveSubSection(subsection));
          dispatch(simpleHandlerActions.clearCheckedItems());
        });
        history.push(`${sectionMatch.url}/${subsection.id}`);
      }
    },
    [history, sectionMatch]
  );

  const onSectionCheck = useCallback((section: ISection) => {
    dispatch(simpleHandlerActions.toggleCheckedItem({ id: section.id, name: section.name }));
  }, []);

  const { getSectionActions } = useSectionActions();

  if (subSectionAreLoading) {
    return <Spinner isStatic />;
  }

  // Если секции не null и пустой массив – значит успешно прошел запрос
  // и вернулся пустой массив
  if (sections && sections.length === 0) {
    return <EmptyMessage message={`Чтобы продолжить, добавьте подраздел сметы`} />;
  }

  if (sections && sections.length) {
    return (
      <>
        {sections.map((section) => (
          <CommonSectionView
            key={section.id}
            onClick={onSectionClick}
            section={section}
            estimateStateId={estimateStateId!}
            onCheck={onSectionCheck}
            checked={checkedItemsIds.includes(section.id)}
            mode={mode}
            actions={getSectionActions(section)}
            isCheckboxVisible={estimateStateId !== EEstimateStatesIds.PRODUCTION}
          />
        ))}
      </>
    );
  }

  return null;
});
