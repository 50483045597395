import React from "react";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import Avatar from "shared/ui/dataDisplay/Avatar/Avatar";

import { IWorker } from "types/interfaces/Workers";

import getShortFullName from "utils/formatters/getShortFullName";

import styles from "./Workers.module.scss";

interface IProps {
  workers: IWorker[];
}

const WorkersPlanFactVolume: React.FC<IProps> = ({ workers }) => {
  const isMoreWorkers = workers?.length > 2;

  if (!workers?.length) return null;

  return (
    <PopoverOverlay
      popoverBorderColor="primary"
      content={
        <div className={styles.popup}>
          {workers?.map((el) => (
            <div className={styles.worker} key={el.id}>
              <Avatar person={el} className={styles.bordered} />
              <div className={styles.texts}>
                <div>{getShortFullName(el)}</div>
                <div>
                  {/* @ts-ignore */}
                  {el.post} ({el.post_qualification})
                </div>
              </div>
            </div>
          ))}
        </div>
      }
      placement="bottom"
    >
      <div className={styles.trigger}>
        <Avatar person={workers[0]} className={styles.bordered} />
        {workers[1] && (
          <div className={styles.avatar}>
            <Avatar person={workers[1]} className={styles.bordered} />
          </div>
        )}
        {isMoreWorkers && <span className={styles.dots}>...</span>}
        {isMoreWorkers && <div className={styles.count}>{workers.length}</div>}
        {isMoreWorkers && (
          <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.65869 0.804688L5.26869 5.19467L0.878691 0.804688" fill="black" />
          </svg>
        )}
      </div>
    </PopoverOverlay>
  );
};

export default WorkersPlanFactVolume;
