import { IActions } from "app/store/store";

import { IBetonExpenseDetail, IBetonExpenseItem, IBetonIncomeDetail, IBetonIncomeItem } from "./types";
import { IListResponseData } from "types/ListResponse";

export const betonActions = {
  setIncome: (stockId: number, income: IListResponseData<IBetonIncomeItem>) =>
    ({
      type: "beton/SET_INCOME",
      payload: { stockId, income },
    } as const),
  setIncomeLoading: (objectId: string, status: boolean) =>
    ({
      type: "beton/SET_INCOME_LOADING",
      payload: { objectId, status },
    } as const),
  invalidateKey: () =>
    ({
      type: "beton/INVALIDATE_KEY",
      payload: true,
    } as const),
  setExpense: (stockId: number, expense: IListResponseData<IBetonExpenseItem>) =>
    ({
      type: "beton/SET_EXPENSE",
      payload: { stockId, expense },
    } as const),
  setExpenseDetail: (betonId: number, detail: IBetonExpenseDetail) =>
    ({
      type: "beton/SET_EXPENSE_DETAIL",
      payload: { betonId, detail },
    } as const),
  setIncomeDetail: (betonId: number, detail: IBetonIncomeDetail) =>
    ({
      type: "beton/SET_INCOME_DETAIL",
      payload: { betonId, detail },
    } as const),
  setIsPending: (betonId: number, status: boolean) =>
    ({
      type: "beton/SET_PENDING",
      payload: { betonId, status },
    } as const),
};

export type IBetonActions = IActions<typeof betonActions>;
