import { message } from "antd";
import axios from "axios";
import moment from "moment";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { objectDetailSelector } from "redux/modules/common/building/object/nowObject";

import { IFile } from "types/interfaces/Files";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import downloadFile from "utils/helpers/download";
import { errorCatcher } from "utils/helpers/errorCatcher";

export const useExportImportWorkers = (buildingId: number | string, getWorkers: () => void) => {
  const objectInfo = useSelector(objectDetailSelector);

  const [isSelectStatusClose, setIsSelectStatusClose] = useState(false);

  const handleImportWorkers = useCallback(
    (files: IFile[]) => {
      setIsSelectStatusClose(true);

      getArrayFilesInBase64(files).then((arrayFilesInBase64) => {
        axios
          .post(`/building/${buildingId}/workers/xlsx/upload/`, ...arrayFilesInBase64)
          .then(() => {
            message.success("Сотрудники загружены");
            getWorkers();
          })
          .catch(errorCatcher);
      });
    },
    [buildingId, getWorkers]
  );

  const handleExportWorkers = useCallback(() => {
    downloadFile(
      `${axios.defaults.baseURL}/building/${buildingId}/workers/xlsx/`,
      `${objectInfo?.name}-сотрудники-${moment().format("DD/MM/YYYY")}.xlsx`,
      "POST"
    ).catch(errorCatcher);
  }, [buildingId, objectInfo?.name]);

  return {
    isSelectStatusClose,
    setIsSelectStatusClose,
    handleImportWorkers,
    handleExportWorkers,
  };
};
