import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from "react";

import ManagePermissionsModal from "./components/ManagePermissionsModal/ManagePermissionsModal";
import Checkbox from "_LEGACY/UI/__TODO/Checkbox/Checkbox";
import { AddButton } from "_LEGACY/UI/__trash/AddButton/AddButton";

import { INormalizedPermissionsTree } from "./../../utils/permissionsUtils";

import styles from "./PermissionsSettings.module.scss";

interface IProps {
  canEdit: boolean;
  allPermissions: INormalizedPermissionsTree | null;
  allPermissionsCount: number;
  checkedUserPermissions: Record<string, boolean>;
  onChangeCheckedUserPermissions: any;
  userFullAccess: boolean;
  onCheckUserFullAccess: any;
  isPending?: boolean;
  userApprover: boolean;
  onCheckUserApprover: ChangeEventHandler<HTMLInputElement>;
}

const PermissionsSettings: React.FC<IProps> = ({
  canEdit,
  allPermissions,
  allPermissionsCount,
  checkedUserPermissions,
  onChangeCheckedUserPermissions,
  userFullAccess,
  onCheckUserFullAccess,
  isPending,
  userApprover,
  onCheckUserApprover,
}) => {
  const [isManagePermissionsModalOpen, setIsManagePermissionsModalOpen] = useState(false);
  const openManagePermissionsModal = useCallback(() => setIsManagePermissionsModalOpen(true), []);
  const closeManagePermissionsModal = useCallback(() => setIsManagePermissionsModalOpen(false), []);

  const checkedUserPermissionsCount = useMemo(() => {
    return Object.entries(checkedUserPermissions).filter(
      ([alias, isChecked]) => isChecked && !alias.toLowerCase().startsWith("fake")
    ).length;
  }, [checkedUserPermissions]);

  return (
    <div className={styles.permissionsContainer}>
      {/* @ts-ignore */}
      <AddButton className={styles.addButton} onClick={openManagePermissionsModal} disabled={isPending}>
        Настройка прав доступов ({checkedUserPermissionsCount} из {allPermissionsCount})
      </AddButton>
      <Checkbox checked={userFullAccess} onChange={onCheckUserFullAccess} disabled={!canEdit}>
        Доступ ко всем проектам
      </Checkbox>
      <br />
      <Checkbox checked={userApprover} onChange={onCheckUserApprover} disabled={!canEdit}>
        Участвует в согласовании
      </Checkbox>
      <ManagePermissionsModal
        permissions={allPermissions}
        checkedPermissions={checkedUserPermissions}
        onChangeChecked={onChangeCheckedUserPermissions}
        isOpen={isManagePermissionsModalOpen}
        onClose={closeManagePermissionsModal}
        canEdit={canEdit}
      />
    </div>
  );
};

export default React.memo(PermissionsSettings);
