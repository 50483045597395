import { message } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { changeExpenditureAction } from "../../../../../../redux/modules/common/building/sections/sections";
import { getLiteEstimateSectionsTree } from "../../../../../../redux/modules/common/building/sections/sectionsApi";

import BottomControls from "../../../WorkOrMaterialsModals/components/BottomControls/BottomControls";

import { Spinner } from "../../../../../../shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "../../../../../../shared/ui/controls/ButtonBase";
import { InputSearchRound } from "../../../../../../shared/ui/inputs/InputSearchRound/InputSearchRound";
import SliderModal from "../../../../../../shared/ui/modal/SliderModal/SliderModal";
import AddRelationModalSections, { IEstimateTarget } from "./AddRelationModalSections/AddRelationModalSections";

import { ExpenditureType } from "../../../../../../types/enums/ExpenditureTypeEnum";
import { ISectionTree } from "types/interfaces/Section";

import { errorCatcher } from "../../../../../../utils/helpers/errorCatcher";
import { filterSectionsByExpenditureType, filterSectionsBySearch, findEstimateTargets } from "./utils";

import styles from "./AddRelationsModal.module.scss";

interface IRelationOriginInfo {
  id: number;
  estimate_expenditures?: number[];
  expenditure_type?: ExpenditureType;
}

interface IProps {
  relationOriginInfo: IRelationOriginInfo | null;
  updateExpenditure: (newEstimateRelations: number[]) => void;
  handleClose: () => void;
  objectId: string;
  type?: string;
}

interface ILinkOutOfEstimateResponse {
  estimate_expenditures: number[];
}

const AddRelationsModal: React.FC<IProps> = ({
  relationOriginInfo,
  updateExpenditure,
  handleClose,
  objectId,
  type,
}) => {
  const dispatch = useDispatch();
  const [sections, setSections] = useState<ISectionTree[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [selectedEstimateTargets, setSelectedEstimateTargets] = useState<IEstimateTarget[]>([]);
  const [isBinding, setIsBinding] = useState(false);

  const addSelectedEstimateTarget = (target: IEstimateTarget) => {
    const existing = selectedEstimateTargets.find(
      (s) =>
        s.sectionId === target.sectionId &&
        s.subsectionId === target.subsectionId &&
        s.expenditureId === target.expenditureId
    );

    if (!existing) {
      setSelectedEstimateTargets((prev) => [...prev, target]);
    }
  };

  const removeSelectedEstimateTarget = (target: IEstimateTarget) => {
    setSelectedEstimateTargets((prev) =>
      prev.filter(
        (s) =>
          s.sectionId !== target.sectionId ||
          s.subsectionId !== target.subsectionId ||
          s.expenditureId !== target.expenditureId
      )
    );
  };

  const handleCloseModal = () => {
    handleClose?.();
    setSearch("");
    setSelectedEstimateTargets([]);
  };

  const handleSubmit = useCallback(() => {
    if (!selectedEstimateTargets.length) return;
    const relationsIds = selectedEstimateTargets.map((s) => s.expenditureId).filter((e) => e !== undefined) as number[];

    setIsBinding(true);
    axios
      .post<ILinkOutOfEstimateResponse>(
        `/building/${objectId}/estimate/link_out_of_estimate/${relationOriginInfo?.id}/`,
        {
          expenditures: relationsIds,
        }
      )
      .then((response) => {
        message.success("Продукт сопоставлен");
        const toChange = { id: relationOriginInfo?.id, estimate_expenditures: response.data.estimate_expenditures };
        dispatch(changeExpenditureAction(toChange));
        handleCloseModal();
      })
      .catch(errorCatcher)
      .finally(() => setIsBinding(false));
  }, [handleCloseModal, relationOriginInfo?.id, objectId, selectedEstimateTargets, type, updateExpenditure]);

  const onSelectExpenditure = useCallback((estimateTarget: IEstimateTarget, isSelect: boolean) => {
    if (isSelect) {
      addSelectedEstimateTarget(estimateTarget);
    } else {
      removeSelectedEstimateTarget(estimateTarget);
    }
  }, []);

  const searchedSections = useMemo(() => filterSectionsBySearch(sections, search), [sections, search]);

  const onChangeSearchInput = useCallback((e: any) => {
    setSearch(e.target.value);
  }, []);

  const debouncedOnChangeSearchInput = useMemo(() => debounce(onChangeSearchInput, 300), [onChangeSearchInput]);

  useEffect(() => {
    if (!relationOriginInfo) return;
    setIsLoading(true);

    getLiteEstimateSectionsTree(objectId)
      .then((data) => {
        if (relationOriginInfo.expenditure_type) {
          setSections(filterSectionsByExpenditureType(data.sections, relationOriginInfo.expenditure_type));
        } else {
          setSections(data.sections);
        }

        const targets = findEstimateTargets(data.sections, relationOriginInfo?.estimate_expenditures ?? []);
        if (targets.length) {
          setSelectedEstimateTargets(targets);
        }
      })
      .catch(errorCatcher)
      .finally(() => {
        setIsLoading(false);
      });
  }, [objectId, relationOriginInfo?.id]);

  return (
    <SliderModal isOpen={!!relationOriginInfo?.id} closeHandler={handleCloseModal} className={styles.sliderClassName}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>Связь со сметой</div>
          </div>
          <div className={styles.searchBlock}>
            <InputSearchRound onChange={debouncedOnChangeSearchInput} className={styles.inputClassName} />
            <ButtonBase
              className={styles.btn}
              primary
              onClick={handleSubmit}
              disabled={!selectedEstimateTargets.length || isBinding}
              isLoading={isBinding}
            >
              Связать
            </ButtonBase>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            {!isLoading ? (
              <AddRelationModalSections
                selectedEstimateTargets={selectedEstimateTargets}
                sections={searchedSections}
                opened={!!search.length}
                onCheckExpenditure={onSelectExpenditure}
              />
            ) : (
              <Spinner isStatic />
            )}
          </div>
        </div>
        <BottomControls isEmpty isExists />
      </div>
    </SliderModal>
  );
};

export default React.memo(AddRelationsModal);
