import React from "react";

import JournalExecSections from "./components/JournalExecSections/JournalExecSections";
import JournalExecTickets from "./components/JournalExecTickets/JournalExecTickets";

import { useFoldersNavigation } from "hooks/navigation/useFoldersNavigation";

interface IProps {
  month: number;
  year: number;
}

const JournalExecution: React.FC<IProps> = ({ month, year }) => {
  const { backHandler, openFolderHandler, currentFolderId, foldersDepth } = useFoldersNavigation("section");

  return (
    <>
      {!currentFolderId && <JournalExecSections onOpenSection={openFolderHandler} month={month} year={year} />}
      {currentFolderId && (
        <JournalExecTickets onBack={backHandler} month={month} year={year} sectionId={currentFolderId} />
      )}
    </>
  );
};

export default React.memo(JournalExecution);
