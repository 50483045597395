import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { projectsV2ListSelector } from "pages/Projects/model/selectors";
import { getProjectsList } from "pages/Projects/model/thunks";

import CustomBreadCrumbsMenu from "entities/CustomBreadcrumbs/CustomBreadCrumbsMenu";

import { IIdAndName } from "types/idAndName";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

interface IProps<Params extends { projectId: string | number } = { projectId: string | number }> {
  constructLink: (params: Params) => string;
  customFirstOption?: IIdAndName & { link: string };
}

const BreadcrumbsProjectSelect: React.FC<IProps> = ({ constructLink, customFirstOption }) => {
  const { projectId } = useParams<{ projectId: string }>();

  const dispatch = useDispatch();
  const key = generateStorageKey({ projectId: 0 });
  const projects = useSelector(projectsV2ListSelector)[key];

  const activeProjectName = useMemo(
    () => projects?.find((o: IIdAndName) => +o.id == +projectId!)?.name || "Все проекты",
    [projects, projectId]
  );

  useEffect(() => {
    if (projects?.length) return;
    dispatch(getProjectsList({}));
  }, [projects]);

  const options = useMemo(() => {
    const candidate: IIdAndName[] = projects ?? [];
    const result = candidate.map((el) => ({ ...el, link: constructLink({ projectId: el.id }) }));
    if (customFirstOption) {
      return [customFirstOption, ...result];
    }
    return result.filter((el) => el.id !== +projectId!);
  }, [constructLink, customFirstOption, projects, projectId]);

  const activeObjectName = useMemo(() => {
    const candidate: IIdAndName[] = projects ?? [];
    return candidate.find((o: IIdAndName) => +o.id == +projectId!)?.name || "Все проекты";
  }, [projectId, projects]);

  return <CustomBreadCrumbsMenu options={options} selectedOption={activeObjectName} />;
};

export default React.memo(BreadcrumbsProjectSelect);
