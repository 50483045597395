import React from "react";

export const CloseIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17Z"
        fill="#4FB2ED"
      />
      <path
        d="M11.3859 12.7999L8.54888 9.96788L5.71188 12.7999C5.61904 12.8931 5.50875 12.967 5.38732 13.0176C5.26589 13.0681 5.13569 13.0943 5.00415 13.0945C4.87262 13.0947 4.74233 13.0691 4.62072 13.0189C4.49911 12.9688 4.38856 12.8952 4.29538 12.8024C4.20221 12.7095 4.12824 12.5993 4.07769 12.4778C4.02714 12.3564 4.001 12.2262 4.00077 12.0947C4.00054 11.9631 4.02621 11.8328 4.07633 11.7112C4.12646 11.5896 4.20004 11.4791 4.29288 11.3859L7.13088 8.54888L4.29388 5.71288C4.10571 5.52471 4 5.2695 4 5.00338C4 4.73727 4.10571 4.48206 4.29388 4.29388C4.48206 4.10571 4.73727 4 5.00338 4C5.2695 4 5.52471 4.10571 5.71288 4.29388L8.54888 7.13088L11.3859 4.29388C11.4791 4.20104 11.5896 4.12746 11.7112 4.07733C11.8328 4.02721 11.9631 4.00154 12.0947 4.00177C12.2262 4.002 12.3564 4.02814 12.4778 4.07869C12.5993 4.12924 12.7095 4.20321 12.8024 4.29638C12.8952 4.38956 12.9688 4.50011 13.0189 4.62172C13.0691 4.74333 13.0947 4.87362 13.0945 5.00515C13.0943 5.13669 13.0681 5.26689 13.0176 5.38832C12.967 5.50975 12.8931 5.62004 12.7999 5.71288L9.96788 8.54888L12.7999 11.3859C12.8954 11.4781 12.9716 11.5885 13.024 11.7105C13.0764 11.8325 13.104 11.9637 13.1051 12.0965C13.1063 12.2293 13.081 12.3609 13.0307 12.4838C12.9804 12.6067 12.9062 12.7184 12.8123 12.8123C12.7184 12.9062 12.6067 12.9804 12.4838 13.0307C12.3609 13.081 12.2293 13.1063 12.0965 13.1051C11.9637 13.104 11.8325 13.0764 11.7105 13.024C11.5885 12.9716 11.4781 12.8954 11.3859 12.7999Z"
        fill="white"
      />
    </svg>
  );
};
