import { IOption } from "components/UI/atoms/Select";

export enum actTypesEnum {
  aosr = "aosr",
  aook = "aook",
}

export const actTypesOptions: IOption[] = [
  { id: actTypesEnum.aosr, name: "Акт освидетельствования скрытых работ" },
  { id: actTypesEnum.aook, name: "Aкт освидетельствования ответственных конструкций" },
];

export const getActTypesOptions = (isAosrExists: boolean) => {
  const res: IOption[] = [];
  if (!isAosrExists) {
    res.push({ id: actTypesEnum.aosr, name: "Акт освидетельствования скрытых работ" });
  }
  res.push({ id: actTypesEnum.aook, name: "Aкт освидетельствования ответственных конструкций" });

  return res;
};
