import React, { PropsWithChildren, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import {
  isLoadingSelector,
  planFactBudgetsSelector,
  planFactSectionsSelector,
} from "../../../../redux/modules/_TODO/constructing/finance/planFact/selectors";

import ForbiddenPage from "../../../../app/routes/components/ForbiddenPage/ForbiddenPage";
import PlanFactIndicators from "./components/PlanFactIndicators/PlanFactIndicators";
import PlanFactSectionRow from "./components/PlanFactSectionRow/PlanFactSectionRow";

import EmptyPlaceholder from "../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import PlanFactExtraCostItem from "../PlanFactExtraCosts/PlanFactExtraCostItem/PlanFactExtraCostItem";
import { usePlanFactExtraCosts } from "../PlanFactExtraCosts/usePlanFactExtraCosts";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";

import {
  VIEW_PLAN_FACT_FINANCE,
  VIEW_PLAN_FACT_VOLUME,
} from "../../../../constants/permissions/manufacturingPermissions";
import { ROUTES } from "../../../../constants/routes";

import usePermission from "../../../../hooks/usePermission";
import { useTypedParams } from "utils/hooks/useTypedParams";

import replaceAll from "utils/helpers/replaceAll";

import planFact from "images/icons/navigation/plan-fact.svg";

import styles from "./PlanFactList.module.scss";

const PlanFactList: React.FC<PropsWithChildren> = ({ children }) => {
  const isLoading = useSelector(isLoadingSelector);

  const sections = useSelector(planFactSectionsSelector);
  const budgets = useSelector(planFactBudgetsSelector);

  const { objectId } = useTypedParams();

  const viewVolumePermission = usePermission(VIEW_PLAN_FACT_VOLUME);
  const viewFinancePermission = usePermission(VIEW_PLAN_FACT_FINANCE);

  const { isExtraCostsLoading, extraCosts, extraCostsCount } = usePlanFactExtraCosts();

  if (!viewFinancePermission && viewVolumePermission) {
    return <Redirect to={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_VOLUME} />;
  } else if (!viewFinancePermission) {
    return <ForbiddenPage />;
  }

  const showSpinner = isExtraCostsLoading || isLoading;

  return (
    <div className={styles.listContainer}>
      {children}
      <PlanFactIndicators planFactBudgets={budgets} />
      {showSpinner && <Spinner />}
      {!showSpinner && sections?.length
        ? sections?.map((section) => {
            return (
              <Link
                key={section?.id}
                to={replaceAll(ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_FINANCE_ITEM, {
                  ":objectId": objectId,
                  ":sectionId": section.id,
                })}
              >
                <PlanFactSectionRow section={section} />
              </Link>
            );
          })
        : null}
      {!!extraCosts?.length && !showSpinner && <PlanFactExtraCostItem count={extraCostsCount} />}
      {!showSpinner && !sections?.length ? <EmptyPlaceholder img={planFact} /> : null}
    </div>
  );
};

export default React.memo(PlanFactList);
