import moment from "moment";
import React from "react";

import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import Checkbox, { LabelPlacement } from "shared/ui/inputs/Checkbox/Checkbox";

import { IMultipleApprovingItem, IMultipleApprovingPlans } from "../model/types";

import { multiplePlansUtils } from "../utils";

import styles from "./SendMultiplePlansForApproveModal.module.scss";

interface IProps {
  section: IMultipleApprovingPlans["sections"][number];
  checkedIdsDict: Record<string, boolean>;
  onCheck: (item: IMultipleApprovingItem) => void;
}

const SendMultiplePlansSection: React.FC<IProps> = ({ section, checkedIdsDict, onCheck }) => {
  return (
    <>
      {section.subsections.map((subsection) => (
        <React.Fragment key={subsection.id}>
          {!!subsection.items.length && (
            <div className={styles.parentRow}>
              <span>{section.name}&nbsp;/</span>&nbsp;{subsection.name}
            </div>
          )}
          {subsection.items.map((el) => (
            <TableReusableRow className={styles.thead} isSmall isHoverable={false} isFont13={false}>
              <TableReusableCell>
                <Checkbox
                  checked={checkedIdsDict[el.plan_id]}
                  onCheck={() => onCheck(el)}
                  labelPlacement={LabelPlacement.RIGHT}
                />
              </TableReusableCell>

              <TableReusableCell>{multiplePlansUtils.filterNumber(el.number)}</TableReusableCell>
              <TableReusableCell isNoBreak>{el.name}</TableReusableCell>
              <TableReusableCell isCentered>
                {el.count}&nbsp;{el.measure}
              </TableReusableCell>
              <TableReusableCell isCentered>
                {moment(el.plan_start).format("DD.MM.YYYY")} - {moment(el.plan_end).format("DD.MM.YYYY")}
              </TableReusableCell>
            </TableReusableRow>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

export default SendMultiplePlansSection;
