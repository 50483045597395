import { message } from "antd";
import axios from "axios";
import { compose } from "redux";

import { deleteEmployeeAction } from "../actions";

import { MESSAGES } from "../constants";

import { errorCatcher } from "../../../../../../utils/helpers/errorCatcher";

const deleteEmployee = (employeeId, options) => (dispatch) =>
  axios
    .delete(`/${options.userType}/orders/${options.orderId}/${options.approvalType}/employees/${employeeId}/`)
    .then(() => {
      compose(dispatch, deleteEmployeeAction)(employeeId, options.approvalType);
      message.success(MESSAGES[options.approvalType].DELETED);
    })
    .catch(errorCatcher);

export { deleteEmployee };
