import moment from "moment";

import { IJournalHistoryEditing } from "redux/modules/common/building/journal/acceptance/types";

import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";

export const getHistoryItemDiffValue = (
  diff: IJournalHistoryEditing["diff"][number],
  allDiffs: IJournalHistoryEditing["diff"],
  type: "old" | "new"
) => {
  if (diff.field === "start_at") {
    const endItem = allDiffs.find((el) => el.field === "end_at");
    return `${moment(diff[type]).format("DD.MM.YYYY")} - ${moment(endItem?.[type]).format("DD.MM.YYYY")}`;
  }

  return dropNonSignificantZeros(diff[type]) as string;
};

export const getHistoryFactEditingDate = (date: string) => {
  return `${moment(date).format("DD.MM.YYYY")} в ${moment(date).format("HH:mm")}`;
};

export const getHistoryPlanEditingDate = (item: IJournalHistoryEditing) => {
  return `${moment(item.date).format("DD.MM.YYYY")} в ${moment(item.date).format("HH:mm")} (${
    item.change_type === "manual" ? "Р" : "А"
  })`;
};
