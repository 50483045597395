import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  analyticsCachedTreesSelector,
  analyticsScopeSelector,
} from "../../../../../../../redux/modules/common/analytics/selectors";
import { loadAnalyticsProjectTree } from "../../../../../../../redux/modules/common/analytics/thunks";
import { objectsBreadcrumbsSelector } from "../../../../../../../redux/modules/common/building/objects";

import Select from "../../../../../../UI/atoms/Select";

import HideLine from "../../../../../../../shared/ui/layout/HideLine/HideLine";
import useAnalyticsScopeChange from "../../../../useAnalyticsScopeChange";
import cross from "./cross.svg";

import styles from "./AnalyticsScopeSelect.module.scss";

const AnalyticsScopeSelect: React.FC = () => {
  const dispatch = useDispatch();
  const selectedScope = useSelector(analyticsScopeSelector);
  const objects = useSelector(objectsBreadcrumbsSelector);
  const trees = useSelector(analyticsCachedTreesSelector);
  const currentTree = !!selectedScope.project ? trees[selectedScope.project] : undefined;

  const { onChangeProject, onChangeLsr, onChangeSection } = useAnalyticsScopeChange();

  useEffect(() => {
    if (currentTree || !selectedScope.project) return;
    dispatch(loadAnalyticsProjectTree(selectedScope.project));
  }, [currentTree, selectedScope.project]);

  const projectsOptions = useMemo(
    /* @ts-ignore */
    () => objects?.results?.map((x) => ({ id: x.id, name: x.name, label: x.name })) || [],
    [objects]
  );
  const lsrOptions = useMemo(
    () => currentTree?.sections?.map((x) => ({ id: x.id, name: x.name, label: x.name })) || [],
    [currentTree?.sections]
  );
  const sectionOptions = useMemo(
    () =>
      currentTree?.sections /* @ts-ignore */
        ?.find((x) => +x.id === +selectedScope?.lsr)
        ?.subsections?.map((x) => ({ id: x.id, name: x.name, label: x.name })) || [],
    [currentTree?.sections, selectedScope?.lsr]
  );

  return (
    <HideLine
      contentClassName={styles.blocks}
      containerClassName={styles.container}
      buttonClassName={styles.button}
      buttonOpenClassName={styles.buttonOpen}
      containerOpenClassName={styles.containerOpen}
    >
      <div className={styles.selectBlock}>
        <span>Проект:</span>
        <Select
          options={projectsOptions} /* @ts-ignore */
          value={selectedScope.project} /* @ts-ignore */
          onChange={onChangeProject}
          className={styles.select}
          disabled={!projectsOptions?.length}
        />
        <div className={styles.clearBtn}>
          {/* @ts-ignore */}
          {!!selectedScope.project && <img src={cross} alt={"×"} onClick={() => onChangeProject(null)} />}
        </div>
      </div>
      <div className={styles.selectBlock}>
        <span>ЛСР:</span>
        <Select
          options={lsrOptions} /* @ts-ignore */
          value={selectedScope.lsr} /* @ts-ignore */
          onChange={onChangeLsr}
          className={styles.select}
          disabled={!lsrOptions?.length}
        />
        <div className={styles.clearBtn}>
          {/* @ts-ignore */}
          {!!selectedScope.lsr && <img src={cross} alt={"×"} onClick={() => onChangeLsr(null)} />}
        </div>
      </div>
      <div className={styles.selectBlock}>
        <span>Раздел:</span>
        <Select
          options={sectionOptions} /* @ts-ignore */
          value={selectedScope.section} /* @ts-ignore */
          onChange={onChangeSection}
          className={styles.select}
          disabled={!sectionOptions?.length}
        />
        <div className={styles.clearBtn}>
          {/* @ts-ignore */}
          {!!selectedScope.section && <img src={cross} alt={"×"} onClick={() => onChangeSection(null)} />}
        </div>
      </div>
    </HideLine>
  );
};

export default React.memo(AnalyticsScopeSelect);
