import React from "react";

import Stock from "components/pages/Stock/Stock";

import StocksBreadcrumbs from "./ui/StocksBreadcrumbs/StocksBreadcrumbs";
import CustomBreadcrumbs from "entities/CustomBreadcrumbs/CustomBreadcrumbs";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";

const generateTabRoute = (basePath: string, stockId: string, objectId: string) => (tab: string) =>
  `/${basePath}/stocks/${stockId}/${tab}`;

const StocksProcurements = () => {
  return (
    <Stock
      customBreadCrumbs={
        <CustomBreadcrumbs title="Закупки" subtitle="Склад">
          <StocksBreadcrumbs />
        </CustomBreadcrumbs>
      }
      generateTabRoute={generateTabRoute}
    />
  );
};

export default StocksProcurements;
