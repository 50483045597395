import { RelationType } from "../../../../constants";

export interface IDisplayedRelation {
  id: number;
  delay_day: number | null;
  from_interval: number | null;
  from_group: number | null;
  to_interval: number | null;
  to_group: number | null;
  from_expenditure_name: string | null;
  from_group_name: string | null;
  to_expenditure_name: string | null;
  to_group_name: string | null;
  from_section_name: string;
  to_section_name: string;
  related_type: RelationType;
}

export enum RelationsDirection {
  from = "from",
  to = "to",
}

export type RelationDirectionType = keyof typeof RelationsDirection;

export interface IIntervalToConnect {
  id: number;
  expenditure_name: string;
}
