import usePermission from "./usePermission";
import { VIEW_MANUFACTURING_SECTION } from "../constants/permissions/manufacturingPermissions";
import { useOpenPlanApprove } from "../components/pages/Building/hooks/useOpenPlanApprove";
import { useHistory, useParams } from "react-router-dom";
import { useObjectId } from "../components/pages/Documents/hooks/useObjectId";
import { useEffect, useState } from "react";


export const useRedirectForApprovePlan = () => {
  const haveViewManufacturingPermission = usePermission(VIEW_MANUFACTURING_SECTION);
  const history = useHistory();
  const objectId = useObjectId();

  const { isNeedToOpenPlan } = useOpenPlanApprove();

  useEffect(() => {
    if (!objectId || objectId === "0") return;
    if (!isNeedToOpenPlan) return;

    if (!haveViewManufacturingPermission) {
      history.replace(`/constructing/projects/${objectId}/${history.location.search}`);
    }
  }, [isNeedToOpenPlan, haveViewManufacturingPermission, objectId]);
};