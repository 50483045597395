import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IConstControlAccForObject } from "./types";

interface SettingsObjectState {
  invalidationKey: number;
  loadings: Record<string, boolean>;
  constrControlAvailableAccounts: Record<string, IConstControlAccForObject[]>;
}

export const initialState: SettingsObjectState = {
  invalidationKey: 0,
  loadings: {},
  constrControlAvailableAccounts: {},
};

const settingsObjectSlice = createSlice({
  name: "settingsObject",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    },
    setConstrControlAccounts: (state, action: PayloadAction<{ data: IConstControlAccForObject[]; key: string }>) => {
      const { data, key } = action.payload;
      state.constrControlAvailableAccounts[key] = data;
    },
  },
});

export const settingsObjectActions = settingsObjectSlice.actions;

export const settingsObjectReducer = settingsObjectSlice.reducer;
