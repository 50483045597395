import axios from "axios";

import { ILearningItem, ILearningSublists } from "./types";
import { IListResponseData } from "types/ListResponse";

export const learningApi = {
  get: () => {
    return axios.get<IListResponseData<ILearningItem>>("/education/user_tasks/custom/", { params: { limit: 500 } });
  },
  getInstructionsRedirects: (id: number) => {
    //??? перенести в инструкции
    return axios.get(`/permissions_v2/user/${id}/urlpatterns`);
  },
  getSublists: () => {
    return axios.get<ILearningSublists>(`/education/user_tasks/list/`);
  },
};
