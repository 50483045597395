import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { useGetDrawingsQuery } from "pages/Estimate/Blueprints/model/blueprintsQuery";

import DisplayAxesAndMarks from "../DisplayAxesAndMarks/DisplayAxesAndMarks";
import AxesAndMarksForm from "widgets/AxesAndMarks/AxesAndMarksForm";
import { axesSelector } from "widgets/AxesAndMarks/model/selectors";
import { createAxes, editAxes, getAxes } from "widgets/AxesAndMarks/model/thunks";

import { DEFAULT_CREATE_AXES_PARAMS } from "widgets/AxesAndMarks/constants";
import { ICreateAxesArg, ICreateAxesForm } from "widgets/AxesAndMarks/model/types";

import { generateStorageKey } from "utils/helpers/generateStorageKey";
import { axesUtils } from "widgets/AxesAndMarks/utils";

interface IProps {
  id: number;
  planOrFact: "plan" | "fact";
  workOrGroup: "work" | "group";
  expenditureIds?: number[];
  setIsDirty?: (status: boolean) => void;
}

const EditAxesAndMarks: React.FC<IProps> = ({ id, planOrFact, workOrGroup, expenditureIds, setIsDirty = () => {} }) => {
  const dispatch = useDispatch();
  const buildingId = useObjectId();
  const paramKey = `${planOrFact}_${workOrGroup}` as "fact_group";
  const key = generateStorageKey({ [paramKey]: id });
  const axes = useSelector(axesSelector)[key];
  const [isEditing, setIsEditing] = useState(true);

  const { data: drawingsData } = useGetDrawingsQuery({ buildingId } as any);
  const drawing = useMemo(() => {
    return drawingsData?.results?.find(({ id }) => +id === +axes?.[0]?.drawing!);
  }, [drawingsData, axes]);

  const data = useMemo(() => {
    return axesUtils.prepareInitialValues(axes, drawing);
  }, [axes, drawing]);

  const [deletedItems, setDeletedItems] = useState<number[]>([]);
  const [editedItems, setEditedItems] = useState<number[]>([]);

  /*
    OSLA-5128 если при создании оси не был указан mark_end, ему присваивается такое же значение как и mark_start.
    при дальнейшем редактировании, если mark_start === mark_end, мы считаем что указан только mark_start и 
    показываем один инпут только для него. Если мы изменим его в инпуте и сохраним, то изменится только mark_start.
    на предотвращение этого и направлен следующий код:
   */
  const [itemsWithEqualMarks, setItemsWithEqualMarks] = useState<number[]>([]);

  const onSplitInput = (key: string) => {
    const [id] = axesUtils.extractIdFromFormKey(key);
    setItemsWithEqualMarks((prev) => prev.filter((el) => +el !== +id));
  };

  useEffect(() => {
    const value = data?.axis;
    if (!value) return;
    const res: number[] = [];
    for (let key in value) {
      if (value[key].mark_start === value[key].mark_end) {
        res.push(value[key].id!);
      }
    }
    setItemsWithEqualMarks(res);
  }, [data]);
  // ^OSLA-5128 вот до сюда

  useEffect(() => {
    dispatch(getAxes(buildingId, planOrFact, workOrGroup, id));
  }, [buildingId, planOrFact, workOrGroup]);

  const onSubmit = (values: ICreateAxesArg) => {
    const factId = axes?.[0]?.id;
    setIsEditing(false);
    setIsDirty?.(false);
    if (factId) {
      dispatch(
        editAxes({
          buildingId,
          planOrFact,
          workOrGroup,
          id: factId,
          data: values,
          editedItems,
          deletedItems,
          itemsWithEqualMarks,
        })
      );
    } else {
      const key = `${planOrFact}_${workOrGroup}`;
      const payload: ICreateAxesArg = {
        ...DEFAULT_CREATE_AXES_PARAMS,
        [key]: id,
        drawing: values.drawing,
        axis: values.axis,
      };
      dispatch(createAxes(buildingId, payload));
    }
  };

  if (!isEditing) {
    return (
      <DisplayAxesAndMarks
        id={id}
        planOrFact={planOrFact}
        workOrGroup={workOrGroup}
        customHeader={<div onClick={() => setIsEditing(true)}>Редактировать</div>}
      />
    );
  }

  return (
    <AxesAndMarksForm
      isNotEmpty={!!data}
      isOpen
      onSubmit={onSubmit}
      initialValues={data}
      key={String(data)}
      onDeleteAxis={(id) => {
        setDeletedItems((p) => [...p, id]);
        setIsDirty?.(true);
      }}
      onMarkEdited={(id) => {
        setEditedItems((p) => [...p, id]);
        setIsDirty?.(true);
      }}
      expenditureIds={expenditureIds}
      onAddNew={() => setIsDirty?.(true)}
      onCancel={() => {
        setIsDirty?.(false);
        setIsEditing(false);
      }}
      onSplitInput={onSplitInput as any}
    />
  );
};

export default EditAxesAndMarks;
