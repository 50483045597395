import { message } from "antd";
import cn from "classnames";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";

import ModalAddTaskExtraFields from "./ModalAddTaskExtraFields";
import ModalAddTaskMainFields from "./ModalAddTaskMainFields";
import { useTypedSelector } from "app/store/typedUseSelector";
import { finalFormPersister } from "shared/lib/formUtils/finalFormPersist";

import { EXTRA_MODAL_TAB, MAIN_MODAL_TAB } from "../../../../constants";
import { ADD_TASK_INITIALS } from "../../../../constants";

import { useCreateTask } from "../../../../hooks/useCreateTask";
import { useTasksModal } from "../../../../hooks/useTasksModal";

import styles from "./ModalAddTask.module.scss";

export interface IModalAddTaskProps {
  formRef: React.RefObject<HTMLFormElement>;
  activeTab: string;
  presetBuildingId: number;
  handleCloseModal: () => void;
  isHidden?: boolean;
}

const ModalAddTask: React.FC<IModalAddTaskProps> = ({
  formRef,
  activeTab,
  presetBuildingId,
  handleCloseModal,
  isHidden,
}) => {
  const { placementType, listType, list_id, submission, isOpen } = useTasksModal();
  const { invalidateKey } = useTypedSelector((state) => state.tasks);

  const [requestClose, setRequestClose] = useState<boolean>(false);

  useEffect(() => {
    if (!submission?.isSubmitted && submission?.taskId !== -1 && requestClose) handleCloseModal();
  }, [submission, requestClose]);

  const submitHandler = (vals: any) => {
    onSubmit(vals);
  };

  const initialData = useMemo(() => {
    return {
      ...ADD_TASK_INITIALS,
      deadline: moment().toISOString(),
    };
  }, [isOpen]);

  useEffect(() => {
    setRequestClose(false);
  }, [invalidateKey]);

  const { persistDecorator, clear } = finalFormPersister({
    name: "form_createTask",
  });

  const { onSubmit, building, setBuilding, section_id, setSectionId } = useCreateTask({
    placementType,
    listType,
    list_id,
    onClearPersistance: clear,
  });

  const [forceRerenderKey, setForceRerenderKey] = useState(1);

  return (
    <Form
      key={forceRerenderKey}
      onSubmit={submitHandler}
      initialValues={initialData}
      decorators={[persistDecorator]}
      render={({ form, handleSubmit }) => (
        <form
          className={cn(styles.form, { [styles.isHidden]: isHidden })}
          ref={formRef}
          onReset={() => form.restart()}
          onSubmit={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (form.getState().valid) {
              await handleSubmit();
              setRequestClose(true);
              clear();
              form.restart();
              setForceRerenderKey((prev) => prev + 1);
            } else {
              message.warn("Заполните обязательные поля формы");
            }
          }}
        >
          <ModalAddTaskMainFields
            presetBuildingId={presetBuildingId}
            building={building}
            setBuilding={setBuilding}
            section_id={section_id}
            setSectionId={setSectionId}
            isVisible={activeTab === MAIN_MODAL_TAB}
          />
          <ModalAddTaskExtraFields isVisible={activeTab === EXTRA_MODAL_TAB} />
        </form>
      )}
    />
  );
};
export default ModalAddTask;
