import moment from "moment";
import React, { useCallback, useRef, useState } from "react";

import { Spinner } from "../../../../shared/ui/atoms/Spinner/Spinner";
import Button from "../../../../shared/ui/controls/ButtonBase";
import { AddIcon } from "./AddIcon";
import { CloseIcon } from "./CloseIcon";
import { Popover } from "@blueprintjs/core";

import MessageIcon from "../../../../images/icons/MessageIcon";

import styles from "./CommentPopup.module.scss";

const CommentPopup = ({ comments, commentsAreLoading, onCreateComment, children, canAdd = true }) => {
  const commentsPopupRef = useRef();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [addNewCommentIsOpen, setAddNewCommentIsOpen] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [commentValue, setCommentValue] = useState("");

  const openAddNewComment = useCallback(() => setAddNewCommentIsOpen(true), []);

  const saveCommentHandler = useCallback(() => {
    onCreateComment(commentValue);

    setCommentValue("");
    setShowButtons(false);
    setAddNewCommentIsOpen(false);
  }, [onCreateComment, commentValue]);

  const textFieldHandler = (event) => {
    const innerText = event.target.value;
    if (!innerText) {
      setShowButtons(false);
      setCommentValue("");
    } else {
      setShowButtons(true);
      setCommentValue(innerText);
    }
  };

  const clearTextFieldHandler = () => {
    setCommentValue("");
    setShowButtons(false);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const popoverContent = () => {
    return (
      <div className={styles.commentPopup}>
        <div className={styles.commentPopup__text}>
          <div>Комментарии: {comments.length}</div>
        </div>
        {commentsAreLoading && <Spinner isSmall />}
        <div ref={commentsPopupRef} className={styles.listFixedComments}>
          {comments.map((comment) => (
            <div key={comment.id} className={styles.comment}>
              <div className={styles.commentText}>{comment.comment}</div>
              <div className={styles.forRelative}>
                <div className={styles.additionalData}>
                  <div
                    className={styles.messageSender}
                    title={`${comment.author.last_name} ${comment.author.first_name} ${comment.author.middle_name}`}
                  >
                    {comment.author.last_name} {comment.author.first_name[0]}.{comment.author.middle_name[0]}.
                  </div>
                  <div className={styles.dataAndTime}>
                    <div className={styles.sendDate}>{moment(comment.created_at).format("DD.MM.YY")}</div>
                    <div className={styles.sendTime}>{moment(comment.created_at).format("HH:mm")}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {addNewCommentIsOpen && (
          <div className={styles.commentField}>
            <div className={styles.closeIcon} onClick={clearTextFieldHandler}>
              <CloseIcon />
            </div>
            <textarea
              placeholder="Нет комментариев"
              value={commentValue}
              onChange={textFieldHandler}
              maxLength={510}
              className={styles.textArea}
            />
          </div>
        )}
        {canAdd && !addNewCommentIsOpen && (
          <div className={styles.addComments}>
            <div className={styles.positionMessage} onClick={openAddNewComment}>
              <MessageIcon />
              <AddIcon className={styles.addIcon} />
            </div>
            <div className={styles.addCommentsText}>Добавить комментарий</div>
          </div>
        )}
        {showButtons && (
          <div className={styles.commentPopup__buttons}>
            <Button secondary small className={styles.commentPopup__button} onClick={closePopover}>
              Отменить
            </Button>
            <Button primary small className={styles.commentPopup__button} onClick={saveCommentHandler}>
              Сохранить
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <Popover
      content={popoverContent()}
      popoverClassName={styles.popupBlock}
      isOpen={isPopoverOpen}
      onInteraction={setIsPopoverOpen}
      interactionKind="click"
    >
      {children}
    </Popover>
  );
};

export default React.memo(CommentPopup);
