import $ from "jquery";
import { useEffect } from "react";

import Sites4Svg from "./img/sites4.png";
import Sites5Svg from "./img/sites5.png";
import Sites6Svg from "./img/sites6.png";
import SitesMob4Svg from "./img/sites-mob4.png";
import SitesMob5Svg from "./img/sites-mob5.png";
import SitesMob6Svg from "./img/sites-mob6.png";

export const SupplyResourcesSection = () => {
  useEffect(() => {
    addActiveClassTow("reviews-min-text-tow", "active-href");
    changeCaseBlockTow(this, "reviews-min-text-tow", "reviews-min-tow", "active-href", "click-reviews-tow");
    $(".click-reviews-tow").on("click", function () {
      changeActiveClassWithClickTow(this, "reviews-min-text-tow", "active-href");
      changeCaseBlockTow(this, "reviews-min-text-tow", "reviews-min-tow", "active-href", "click-reviews-tow");
    }); /* @ts-ignore */
    function addActiveClassTow(parent_menu, active_class) {
      let prt = $("." + parent_menu);
      let prt_childrens = $(prt).children();
      let prt_child_li = $(prt_childrens).children();
      let first_child = $(prt_child_li)[0];
      if (!$(first_child).hasClass(active_class)) {
        !$(first_child).addClass(active_class);
      }
    } /* @ts-ignore */
    function changeActiveClassWithClickTow($this, parent_block, active_class) {
      let prt = $($this).parents("." + parent_block);
      let prt_child = $(prt).find("li");
      $(prt_child).each(function () {
        $(this).removeClass(active_class);
      });
      $($this).addClass(active_class);
    } /* @ts-ignore */
    function changeCaseBlockTow($this, case_menu, case_block, active_class, menu_link) {
      let case_menu_block = $("." + case_menu);
      let case_block_sub = $("." + case_block);
      let case_block_child = $(case_block_sub).children();
      $(case_block_child).each(function () {
        $(this).css({ display: "none", height: 0 });
      });
      if ($($this).hasClass(menu_link)) {
        let this_attr = $($this).attr("data-catalog");
        $(case_block_child).each(function () {
          if ($(this).attr("data-catalog") == this_attr) {
            $(this).css({ display: "block", height: "100%" });
          }
        });
      } else {
        let active_find = $(case_menu_block).find("." + active_class);
        let active_find_attr = $(active_find).attr("data-catalog");
        $(case_block_child).each(function () {
          if ($(this).attr("data-catalog") == active_find_attr) {
            $(this).css({ display: "block", height: "100%" });
          }
        });
      }
    }
  }, []);

  return (
    <section className="supply-resources sites-block-silver" id="supply-resources">
      <div className="container">
        <div className="sites-block__title">
          <h2>Снабжение ресурсами</h2>
          <div className="reviews-min-text-tow menu-sites-blocks">
            <ul>
              <li className="click-reviews-tow active-href" data-catalog="planning">
                Планирование
              </li>
              <li className="click-reviews-tow" data-catalog="supply">
                Поставка
              </li>
              <li className="click-reviews-tow" data-catalog="control">
                Контроль
              </li>
            </ul>
          </div>
        </div>
        <div className="reviews-min-tow">
          <div className="reviews-min-block-js" data-catalog="planning">
            <div className="sites-block__flex">
              <div className="sites-block__photo">
                <img src={Sites4Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob4Svg} className="sites-pic-mob" alt="image" />
              </div>
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>Вы повышаете контроль наличия ресурсов на объекте</li>
                    <li>На платформе организовано взаимодействие с отделами снабжения, планирования и ПТО</li>
                    <li>Руководители анализируют сметную стоимость и контролируют ход закупки необходимых ресурсов</li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Автоматический подбор ресурсов при планировании работ</li>
                    <li>Автоматическое обновление графика ресурсов</li>
                    <li>Планирование поставки до начала работ</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="reviews-min-block-js" data-catalog="supply">
            <div className="sites-block__flex">
              <div className="sites-block__photo">
                <img src={Sites5Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob5Svg} className="sites-pic-mob" alt="image" />
              </div>
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>Вы согласуете заявку, заказ и оплату онлайн с любого устройства</li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Формирование заявки из графика производства работ</li>
                    <li>Автоматическое формирование календаря поставок и платежей</li>
                    <li>Контроль поставки и отслеживание даты</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="reviews-min-block-js" data-catalog="control">
            <div className="sites-block__flex">
              <div className="sites-block__photo">
                <img src={Sites6Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob6Svg} className="sites-pic-mob" alt="image" />
              </div>
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>Вы контролируете входные цены даже при небольших закупках до 10 тысяч рублей</li>
                    <li>Вы контролируете себестоимость ресурсов для каждого вида работ</li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Автоматический расчет разницы между сметной и закупочной стоимостью</li>
                    <li>Контроль прихода и расхода материалов благодаря складскому учету</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className="title-bot">Получайте ресурсы на объект вовремя</span>
      </div>
    </section>
  );
};
