import cn from "classnames";
import React, { useState } from "react";

import { IPlanFactResponse } from "../../../../../../../../redux/modules/_TODO/constructing/finance/planFact/types";

import FactAcceptedMaterial from "./components/FactAcceptedMaterial/FactAcceptedMaterial";
import FactWork from "./components/FactWork/FactWork";
import GroupIcon from "./components/GroupIcon";
import PlanFactGroupSubExpenditure from "./components/PlanFactGroupSubExpenditure/PlanFactGroupSubExpenditure";

import { EXPENDITURE_TYPES_NAMES } from "constants/constant";

import { transformDigitToFinancial } from "../../../../../../../../utils/formatters/transformDigitToFinancial";
import { percentsFormatter } from "../../../../../../../../utils/percentsFormatter";
import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";

import GroupByIcon from "images/icons/GroupByIcon";

import styles from "./PlanFactExpenditure.module.scss";

export const isFactMoreThanPlanChecker = (plan: number | string, fact: number | string) =>
  parseFloat(String(fact)) > parseFloat(String(plan));

export interface IPlanFactExpenditureProps {
  expenditure: IPlanFactResponse["sections"][number]["subsections"][number]["items"][number];
  isExpandedDifference: boolean;
}

const PlanFactExpenditure: React.FC<IPlanFactExpenditureProps> = ({ expenditure, isExpandedDifference }) => {
  const [isOpen, setIsOpen] = useState(false);

  const item = expenditure.expenditure ?? expenditure.group;

  const hasMaterials = !!expenditure.expenditure?.materials?.length;
  const hasWorks = !!expenditure.expenditure?.works?.length;
  const hasMims = !!expenditure.expenditure?.services?.length;
  const isGroup = !!expenditure.group;

  const isFieldGroup = hasMaterials || hasWorks || hasMims;

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.notFieldGroup, {
          [styles.fieldGroup]: isFieldGroup || isGroup,
          [styles.open]: isOpen,
          [styles.isTriggerForGroup]: isGroup,
        })}
      >
        <div className={cn(styles.icon)} onClick={() => setIsOpen(!isOpen)}>
          {isFieldGroup && <GroupByIcon color={isOpen ? "#fff" : "#4FB2ED"} />}
          {isGroup && <GroupIcon color={isOpen ? "#fff" : "#A26DD0"} />}
        </div>
        <div className={styles.line} />
      </div>
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <div className={styles.field1}>{item?.number}</div>
          <div className={styles.field2} title={item?.name}>
            {item?.name}
          </div>
          <div className={styles.field3}>{!isExpandedDifference && item?.measure}</div>
        </div>
        <div className={cn(styles.rightSide, { [styles.withExpandedDifference]: isExpandedDifference })}>
          {!isExpandedDifference && (
            <div className={styles.fieldRight1}>{dropNonSignificantZeros(item?.plan_count)!}</div>
          )}
          <div className={styles.fieldRight2}>
            {transformDigitToFinancial(item?.price!, {
              withFloat: true,
              dropZeros: true,
            })}
          </div>
          <div className={styles.fieldRight3}>
            {transformDigitToFinancial(item?.plan_amount!, {
              withFloat: true,
              dropZeros: true,
            })}
          </div>
          {!isExpandedDifference && (
            <div
              className={cn({
                [styles.fieldEmpty]: !item?.fact_count,
                [styles.warning]: isFactMoreThanPlanChecker(item?.plan_count!, item?.fact_count!),
              })}
            >
              {item?.fact_count || "-"}
            </div>
          )}
          <div
            className={cn(styles.fieldRight5, {
              [styles.fieldEmpty]: !item?.fact_avg_price,
              [styles.warning]: isFactMoreThanPlanChecker(item?.price!, item?.fact_avg_price!),
            })}
          >
            {transformDigitToFinancial(item?.fact_avg_price!, {
              withFloat: true,
              dropZeros: true,
            }) || "-"}
          </div>
          <div
            className={cn(styles.fieldRight6, {
              [styles.fieldEmpty]: !item?.fact_amount,
              [styles.warning]: isFactMoreThanPlanChecker(item?.plan_amount!, item?.fact_amount!),
            })}
          >
            {transformDigitToFinancial(item?.fact_amount!, {
              withFloat: true,
              dropZeros: true,
            }) || "-"}
          </div>
          <div className={cn(styles.fieldRight7, { [styles.warning]: parseFloat(item?.diff_amount_percent!) < 0 })}>
            {percentsFormatter(parseFloat(item?.diff_amount_percent!)).replaceAll(/%/g, "")}
          </div>
          {isExpandedDifference && (
            <div className={cn(styles.fieldRight8, { [styles.warning]: parseFloat(item?.diff_amount!) < 0 })}>
              {transformDigitToFinancial(item?.diff_amount!, { withFloat: true, dropZeros: true })}
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <>
          {hasMaterials && (
            <>
              <div className={styles.materialBlock}>
                <div className={styles.countLabel}>Материалы:</div>
              </div>
              {expenditure.expenditure?.materials?.map((material, index) => (
                <FactAcceptedMaterial material={material} key={index} isExpandedDifference={isExpandedDifference} />
              ))}
            </>
          )}
          {hasWorks && (
            <>
              <div className={styles.materialBlock}>
                <div className={styles.countLabel}>Работы:</div>
              </div>
              {expenditure.expenditure?.works.map((work, index) => (
                <FactWork work={work} key={index} isExpandedDifference={isExpandedDifference} />
              ))}
            </>
          )}
          {hasMims && (
            <>
              <div className={styles.materialBlock}>
                <div className={styles.countLabel}>МиМ:</div>
              </div>
              {expenditure.expenditure?.services.map((service, index) => (
                <FactAcceptedMaterial material={service} key={index} isExpandedDifference={isExpandedDifference} />
              ))}
            </>
          )}
          {isGroup && (
            <>
              {expenditure.group?.expenditures.map((item, index) => (
                <PlanFactGroupSubExpenditure item={item} key={index} isExpandedDifference={isExpandedDifference} />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(PlanFactExpenditure);
