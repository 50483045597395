import cn from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  passportsAndCertificatesSelector,
  passportsInvalidateKeySelector,
  passportsPendingSelector,
} from "redux/modules/common/passportsAndCertificates/selectors";
import { getPassportsAndCertificates } from "redux/modules/common/passportsAndCertificates/thunks";

import CertificateForm from "./PassportForm/CertificateForm";
import PassportForm from "./PassportForm/PassportForm";
import PassportsRow from "./PassportsTable/PassportsRow";
import PassportsTableHead from "./PassportsTable/PassportsTableHead";
import AddButton from "shared/ui/controls/AddButton/AddButton";

import { CERTIFICATE, PASSPORT } from "./constants";

import styles from "./PassportsAndSertificates.module.scss";

interface IProps {
  tableClassName?: string;
  productId: number;
}

const PassportsAndSertificates: React.FC<IProps> = ({ productId, tableClassName }) => {
  const dispatch = useDispatch();
  const isPending = useSelector(passportsPendingSelector);
  const [type, setType] = React.useState<string | number>("");
  const invalidateKey = useSelector(passportsInvalidateKeySelector);
  const docs = useSelector(passportsAndCertificatesSelector)[productId];

  React.useEffect(() => {
    setType("");
    dispatch(getPassportsAndCertificates(productId));
  }, [invalidateKey, productId]);

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Документы:</h3>
      {!type && <AddButton textPosition="left" onClick={() => setType(PASSPORT)} text="Добавить" />}
      {type === PASSPORT /* @ts-ignore */ && (
        <PassportForm onChangeType={setType} type={type} productId={productId} isPending={isPending} />
      )}
      {type === CERTIFICATE && (
        <CertificateForm onChangeType={setType} type={type} productId={productId} isPending={isPending} />
      )}
      {!!docs?.documents?.length && (
        <div className={cn(styles.table, tableClassName)}>
          <PassportsTableHead />
          {docs?.documents?.map((el /* @ts-ignore */) => (
            <PassportsRow key={el.id} document={el} />
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(PassportsAndSertificates);
