import { IExpenditure } from "types/interfaces/Expenditure";
import ISection from "types/interfaces/Section";

export type WriteOffProductId = number;
export type Products = Record<WriteOffProductId, IWriteOffProduct>;
export type Target = { section?: ISection; subsection?: ISection; expenditure?: IExpenditure };

export interface IWriteOffProduct {
  target: Target;
  count: number;
  isSelected: boolean;
}

class WriteOffProducts {
  readonly items: Products;

  constructor(items: Products) {
    this.items = items;
  }

  changeCount(productId: WriteOffProductId, count: IWriteOffProduct["count"]) {
    if (!this.items[productId]) return;

    this.items[productId].count = count;
  }

  changeTarget(productId: WriteOffProductId, target: Target) {
    if (!this.items[productId]) return;

    this.items[productId].target = target;
  }

  select(productId: WriteOffProductId) {
    if (!this.items[productId]) return;

    this.items[productId].isSelected = true;
  }

  unSelect(productId: WriteOffProductId) {
    if (!this.items[productId]) return;

    this.items[productId].isSelected = false;
  }

  getSelectedCount() {
    return Object.entries(this.items).reduce((acc, [_, product]) => {
      if (product.isSelected) return acc + 1;
      return acc;
    }, 0);
  }
}

export default WriteOffProducts;
