import React from "react";

interface IContureProps {
  color?: string;
  fill?: string;
  width?: string | number;
}

export default function Conture({ color = "#4FB2ED", fill = "transparent", width }: IContureProps) {
  return (
    <svg width={width} height="16.124" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.05469 1.01562H18.3682L11.4854 7.89746V16.4829L8.76074 14.9536V7.90088L2.05469 1.01562Z"
        fill={fill}
        stroke={color}
      />
    </svg>
  );
}
