//@ts-nocheck
import { isEqual, keyBy, merge } from "lodash";
import moment from "moment";

import { IntervalType } from "../../../../../components/pages/Manufacturing/constants";
import { ManufacturingTabsType } from "../../../../../components/pages/Manufacturing/types";

import { getInitialMaterialData, getInitialMimesData, getInitialWorksData, initialState } from "./manufacturing";

import { IProjectTreeResponse } from "./types";

const concatArrayField = (a: any[], b: any[]) => merge(b || [], a || []);
const concatArrayFieldByKey = (a: any[], b: any[], key: string) =>
  Object.values(merge(keyBy(a || [], key), keyBy(b || [], key)));

export const concatStateWorkField = (fieldName: string, state, payload) => {
  if (!!payload.year) {
    return {
      ...state,
      [fieldName]: {
        ...state[fieldName],
        [payload.year]: {
          plans: concatArrayField(state[fieldName]?.[payload.year]?.plans, payload?.plans),
          works: concatArrayField(state[fieldName]?.[payload.year]?.works, payload?.works),
          sections: concatArrayField(state[fieldName]?.[payload.year]?.sections, payload?.sections),
          planned_sections: concatArrayField(
            state[fieldName]?.[payload.year]?.planned_sections,
            payload?.planned_sections
          ),
          groupPlans: concatArrayField(state[fieldName]?.[payload.year]?.groupPlans, payload?.groupPlans),
          groupWorks: concatArrayField(state[fieldName]?.[payload.year]?.groupWorks, payload?.groupWorks),
        },
      },
    };
  }

  return {
    ...state,
    [fieldName]: {
      ...state[fieldName],
      plans: concatArrayField(state[fieldName].plans, payload?.plans),
      works: concatArrayField(state[fieldName].works, payload?.works),
      sections: concatArrayField(state[fieldName].sections, payload?.sections),
      planned_sections: concatArrayField(state[fieldName].planned_sections, payload?.planned_sections),
      groupPlans: concatArrayField(state[fieldName].groupPlans, payload?.groupPlans),
      groupWorks: concatArrayField(state[fieldName].groupWorks, payload?.groupWorks),
    },
  };
};

export const concatStateMaterialField = (fieldName: string, state, payload) => {
  if (!!payload.year) {
    return {
      ...state,
      [fieldName]: {
        ...state[fieldName],
        [payload.year]: {
          accepted: concatArrayFieldByKey(state[fieldName]?.[payload.year]?.accepted, payload?.accepted, "id"),
          on_stock: concatArrayFieldByKey(state[fieldName]?.[payload.year]?.on_stock, payload?.on_stock, "id"),
          payed: concatArrayFieldByKey(state[fieldName]?.[payload.year]?.payed, payload?.payed, "id"),
          to_paid: concatArrayFieldByKey(state[fieldName]?.[payload.year]?.to_paid, payload?.to_paid, "id"),
          plans: concatArrayFieldByKey(state[fieldName]?.[payload.year]?.plans, payload?.plans, "id"),
          purchases: concatArrayFieldByKey(state[fieldName]?.[payload.year]?.purchases, payload?.purchases, "id"),
          stockless: concatArrayFieldByKey(state[fieldName]?.[payload.year]?.stockless, payload?.stockless, "id"),
        },
      },
    };
  }
  return {
    ...state,
    [fieldName]: {
      ...state[fieldName],
      accepted: concatArrayFieldByKey(state[fieldName].accepted, payload?.accepted, "id"),
      on_stock: concatArrayFieldByKey(state[fieldName].on_stock, payload?.on_stock, "id"),
      payed: concatArrayFieldByKey(state[fieldName].payed, payload?.payed, "id"),
      to_paid: concatArrayFieldByKey(state[fieldName].to_paid, payload?.to_paid, "id"),
      plans: concatArrayFieldByKey(state[fieldName].plans, payload?.plans, "id"),
      purchases: concatArrayFieldByKey(state[fieldName].purchases, payload?.purchases, "id"),
      stockless: concatArrayFieldByKey(state[fieldName].stockless, payload?.stockless, "id"),
    },
  };
};

export const concatStateMimesField = (fieldName, state, payload) => {
  if (!!payload.year) {
    return {
      ...state,
      [fieldName]: {
        ...state[fieldName],
        [payload.year]: {
          accepted: concatArrayFieldByKey(state[fieldName]?.[payload.year]?.accepted, payload?.accepted, "id"),
          on_stock: concatArrayFieldByKey(state[fieldName]?.[payload.year]?.on_stock, payload?.on_stock, "id"),
          to_paid: concatArrayFieldByKey(state[fieldName]?.[payload.year]?.to_paid, payload?.to_paid, "id"),
          plans: concatArrayFieldByKey(state[fieldName]?.[payload.year]?.plans, payload?.plans, "id"),
          purchases: concatArrayFieldByKey(state[fieldName]?.[payload.year]?.purchases, payload?.purchases, "id"),
          stockless: concatArrayFieldByKey(state[fieldName]?.[payload.year]?.stockless, payload?.stockless, "id"),
        },
      },
    };
  }
  return {
    ...state,
    [fieldName]: {
      ...state[fieldName],
      accepted: concatArrayFieldByKey(state[fieldName].accepted, payload?.accepted, "id"),
      on_stock: concatArrayFieldByKey(state[fieldName].on_stock, payload?.on_stock, "id"),
      to_paid: concatArrayFieldByKey(state[fieldName].to_paid, payload?.to_paid, "id"),
      plans: concatArrayFieldByKey(state[fieldName].plans, payload?.plans, "id"),
      purchases: concatArrayFieldByKey(state[fieldName].purchases, payload?.purchases, "id"),
      stockless: concatArrayFieldByKey(state[fieldName].stockless, payload?.stockless, "id"),
    },
  };
};

export const markYearMonth = (fieldName, state, payload) => {
  return {
    ...state,
    [fieldName]: {
      ...state[fieldName],
      [payload.projectId]: {
        ...(state[fieldName][payload.projectId] ? state[fieldName][payload.projectId] : {}),
        [payload.dataType]: state[fieldName][payload.projectId]?.hasOwnProperty(payload.dataType)
          ? state[fieldName][payload.projectId][payload.dataType].concat(payload.fetchedDates)
          : payload.fetchedDates,
      },
    },
  };
};

export const dropChartMonths = (state) => {
  return {
    ...state,
    loadedConstructingChartData: {},
    loadedManufacturingChartData: {},
    plan: getInitialWorksData(),
    materials: getInitialMaterialData(),
    mimes: getInitialMimesData(),
    projectData: getInitialWorksData(),
    materialData: getInitialMaterialData(),
    mimesData: getInitialMimesData(),
    projectWeekData: {},
    materialWeekData: {},
    mimesWeekData: {},
    weekPlan: { [moment().year()]: getInitialWorksData() },
    weekMaterials: { [moment().year()]: getInitialMaterialData() },
    weekMimes: { [moment().year()]: getInitialMimesData() },
    diagramIntervalLinks: {
      isBeingDragged: {},
      arrows: {},
      highlightedArrows: {},
      highlightedIntervals: {},
      hash: null,
    },
    projectEstimate: new Map(),
  };
};

export const getNearestYearsMonths = (year: number | string, month: string | number) => {
  const currentMoment = moment()
    .year(+year)
    .month(+month - 1)
    .date(1);
  const nextMoment = moment(currentMoment).add(1, "months");
  const prevMoment = moment(currentMoment).subtract(1, "months");
  return {
    nextYear: +nextMoment.year(),
    nextMonth: +nextMoment.month() + 1,
    prevYear: +prevMoment.year(),
    prevMonth: +prevMoment.month() + 1,
  };
};

const mergeMims = (acc, r) => {
  r?.forEach((p) => {
    const idx = acc.findIndex((accPlan) => accPlan.id === p.id);

    if (idx === -1) {
      acc.push(p);
    } else {
      if (p.orderrequest_set) {
        acc[idx]?.orderrequest_set.push(...p.orderrequest_set);
      } else if (p.accepted) {
        acc[idx]?.accepted.push(...p.accepted);
      } else if (p.planned_count_intervals) {
        acc[idx]?.planned_count_intervals.push(...p.planned_count_intervals);
      }
    }
  });

  return acc;
};

const mergeMaterials = (acc, r) => {
  r?.forEach((p) => {
    const idx = acc.findIndex((accPlan) => accPlan.id === p.id);

    if (idx === -1) {
      acc.push(p);
    } else {
      if (p.orderrequest_set) {
        acc[idx]?.orderrequest_set.push(...p.orderrequest_set);
      } else if (p.packingitems) {
        acc[idx]?.packingitems.push(...p.packingitems);
      } else if (p.using_data) {
        acc[idx]?.using_data.push(...p.using_data);
      } else if (p.planned_works) {
        acc[idx].planned_works?.push(...p.planned_works);
      }
    }
  });

  return acc;
};

export const pushMimesToAcc = (acc, mimes) => {
  acc.accepted = mergeMims(acc.accepted, mimes?.accepted);
  acc.on_stock = mergeMims(acc.on_stock, mimes?.on_stock);
  acc.to_paid = mergeMims(acc.to_paid, mimes?.to_paid);
  acc.plans = mergeMims(acc.plans, mimes?.plans);
  acc.purchases = mergeMims(acc.purchases, mimes?.purchases);
  acc.stockless = mergeMims(acc.stockless, mimes?.stockless);
};

export const pushMaterialsToAcc = (acc, materials) => {
  acc.accepted = mergeMaterials(acc.accepted, materials?.accepted);
  acc.on_stock = mergeMaterials(acc.on_stock, materials?.on_stock);
  acc.to_paid = mergeMaterials(acc.to_paid, materials?.to_paid);
  acc.plans = mergeMaterials(acc.plans, materials?.plans);
  acc.purchases = mergeMaterials(acc.purchases, materials?.purchases);
  acc.stockless = mergeMaterials(acc.stockless, materials?.stockless);
};

export const pushWorksToAcc = (acc, works) => {
  // if (works?.plans) acc["plans"] = concatArrayField(acc.plans, works.plans);
  if (works?.sections) acc["sections"] = concatArrayField(acc.sections, works.sections);
  // if (works?.works) acc["works"] = concatArrayField(acc.works, works.works);
  if (works?.planned_sections) acc["planned_sections"] = concatArrayField(acc.planned_sections, works.planned_sections);
  // if (works?.groupPlans) acc["groupPlans"] = concatArrayField(acc.groupPlans, works.groupPlans);
  // if (works?.groupWorks) acc["groupWorks"] = concatArrayField(acc.groupWorks, works.groupWorks);

  if (works?.plans) acc["plans"] = concatArrayFieldByKey(acc.plans, works.plans, "id");
  // if (works?.sections) acc["sections"] = concatArrayField(acc.sections, works.sections);
  if (works?.works) acc["works"] = concatArrayFieldByKey(acc.works, works.works, "id");
  // if (works?.planned_sections) acc["planned_sections"] = concatArrayFieldByKey(acc.planned_sections, works.planned_sections, 'id');
  if (works?.groupPlans) acc["groupPlans"] = concatArrayFieldByKey(acc.groupPlans, works.groupPlans, "id");
  if (works?.groupWorks) acc["groupWorks"] = concatArrayFieldByKey(acc.groupWorks, works.groupWorks, "id");
};

export const addIntervalLinkHelper = (state, linkPayload) => {
  const currentArrowsByProjectId = state.diagramIntervalLinks.arrows[linkPayload.projectId];
  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      arrows: {
        ...state.diagramIntervalLinks.arrows,
        [linkPayload.projectId]: currentArrowsByProjectId
          ? Array.from(new Set(currentArrowsByProjectId.concat(linkPayload.arrows)))
          : [].concat(linkPayload.arrows),
      },
    },
  };
};

export const setArrowsHelper = (state, linkPayload) => {
  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      arrows: {
        ...state.diagramIntervalLinks.arrows,
        [linkPayload.projectId]: linkPayload.arrows,
      },
    },
  };
};

export const deleteArrowsHelper = (state, linkPayload) => {
  const currentArrowsByProjectId = state.diagramIntervalLinks.arrows[linkPayload.projectId];
  if (!currentArrowsByProjectId) return state;
  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      arrows: {
        ...state.diagramIntervalLinks.arrows,
        [linkPayload.projectId]: currentArrowsByProjectId.filter((x) => x.id !== linkPayload.arrowId),
      },
    },
  };
};

export const isArrowDraggedHelper = (state, payload) => {
  const prevBeingDragged = state.diagramIntervalLinks.isBeingDragged[payload.intervalId];
  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      isBeingDragged: {
        ...state.diagramIntervalLinks.isBeingDragged,
        [payload.intervalId]: {
          ...payload,
          projectId: payload.projectId || prevBeingDragged.projectId,
          startDate: payload.startDate || prevBeingDragged.startDate,
        },
      },
    },
  };
};

export const updateArrowHelper = (state, linkPayload) => {
  const currentArrowsByProjectId = state.diagramIntervalLinks.arrows[linkPayload.projectId];
  if (!currentArrowsByProjectId) return state;
  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      arrows: {
        ...state.diagramIntervalLinks.arrows,
        [linkPayload.projectId]: currentArrowsByProjectId.map((x) => {
          if (x.id === linkPayload.arrowId) {
            return {
              ...linkPayload.data,
              // to_interval: linkPayload.data?.to_group && !linkPayload.data?.to_interval
              //   ? linkPayload.data?.to_group
              //   : linkPayload.data?.to_interval,
              // from_interval: linkPayload.data?.from_group && !linkPayload.data?.from_interval
              //   ? linkPayload.data?.from_group
              //   : linkPayload.data?.from_interval,
            };
          }
          return x;
        }),
      },
    },
  };
};

export const getRelationsForBulkCreate = ({ intervalId, isIntervalGroup, relationCandidates, type, direction }) => {
  if (direction === "from") {
    return relationCandidates.map((candidate) => ({
      from_interval: isIntervalGroup ? null : +intervalId,
      to_interval: candidate.isGroup ? null : +candidate.id,
      related_type: type,
      from_group: isIntervalGroup ? +intervalId : null,
      to_group: candidate.isGroup ? +candidate.id : null,
    }));
  }
  if (direction === "to") {
    return relationCandidates.map((candidate) => ({
      to_interval: isIntervalGroup ? null : +intervalId,
      from_interval: candidate.isGroup ? null : +candidate.id,
      related_type: type,
      from_group: candidate.isGroup ? +candidate.id : null,
      to_group: isIntervalGroup ? +intervalId : null,
    }));
  }
  return [];
};

const mapProjectArrows = (projectArrows, arrowsCandidatesSet, intervalsCandidatesSet) => {
  const newArrowsCandidatesSet = new Set(arrowsCandidatesSet);
  const newIntervalsCandidatesSet = new Set(intervalsCandidatesSet);

  projectArrows.forEach((x) => {
    if (intervalsCandidatesSet.has(x.from_interval) || intervalsCandidatesSet.has(x.to_interval)) {
      newArrowsCandidatesSet.add(x.id);
      newIntervalsCandidatesSet.add(x.from_interval);
      newIntervalsCandidatesSet.add(x.to_interval);
    }
  });

  if (
    isEqual(newArrowsCandidatesSet, arrowsCandidatesSet) &&
    isEqual(newIntervalsCandidatesSet, intervalsCandidatesSet)
  ) {
    return {
      newArrowsCandidatesSet,
      newIntervalsCandidatesSet,
    };
  }

  return mapProjectArrows(
    [...projectArrows].filter((x) => !newArrowsCandidatesSet.has(x.id)),
    newArrowsCandidatesSet,
    newIntervalsCandidatesSet
  );
};

export const highlightArrow = (state, { intervalId, projectId }) => {
  const projectArrows =
    (state.diagramIntervalLinks.arrows[projectId] && [...state.diagramIntervalLinks.arrows[projectId]]) || [];
  let arrowsCandidatesSet = new Set();
  let intervalsCandidatesSet = new Set([intervalId]);

  const { newArrowsCandidatesSet, newIntervalsCandidatesSet } = mapProjectArrows(
    projectArrows,
    arrowsCandidatesSet,
    intervalsCandidatesSet
  );

  const arrowsCandidates = Object.fromEntries(Array.from(newArrowsCandidatesSet, (x) => [x, true]));
  const intervalsCandidates = Object.fromEntries(Array.from(newIntervalsCandidatesSet, (x) => [x, true]));

  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      highlightedArrows: Object.assign({}, state.diagramIntervalLinks.highlightedArrows, arrowsCandidates),
      highlightedIntervals: Object.assign({}, state.diagramIntervalLinks.highlightedIntervals, intervalsCandidates),
    },
  };
};

export const dropHighlightedElements = (state) => {
  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      highlightedArrows: {},
      highlightedIntervals: {},
    },
  };
};

export const expenditureTypesByTab: Record<ManufacturingTabsType, IntervalType[]> = {
  work: ["work"],
  resources: ["material", "equipment", "machine", "transport"],
  material: ["material"],
  equipment: ["equipment"],
  mims: ["machine", "transport"],
};

export const fillDetailedCountExpenditures = (tree: IProjectTreeResponse): IProjectTreeResponse => {
  let outOfEstimateWorksCount = 0;

  tree.sections.forEach((section) => {
    let sectionIsOutOfEstimate = false;

    if (section.is_default) {
      outOfEstimateWorksCount += section.count_expenditures.work;
      sectionIsOutOfEstimate = true;
    }

    let sectionOutOfEstimateWorks = 0;

    section.subsections.forEach((subsection) => {
      if (subsection.is_default) {
        sectionOutOfEstimateWorks += subsection.count_expenditures.work;
        if (!sectionIsOutOfEstimate) {
          outOfEstimateWorksCount += subsection.count_expenditures.work;
        }
      }
    });

    section.count_expenditures.estimate = {
      work: section.count_expenditures.work - sectionOutOfEstimateWorks,
    };

    section.count_expenditures.not_estimate = {
      work: sectionOutOfEstimateWorks,
    };
  });

  tree.count_expenditures.estimate = { work: tree.count_expenditures.work - outOfEstimateWorksCount };
  tree.count_expenditures.not_estimate = { work: outOfEstimateWorksCount };

  return tree;
};
