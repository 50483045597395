import axios from "axios";

import { IGetAutoCompleteOutOfEstimateReq, IHelpExpanditure } from "./types";
import { IListResponseData } from "types/ListResponse";

export const autoCompleteOutOfEstimateApi = {
  getExpenditures: (params: IGetAutoCompleteOutOfEstimateReq) => {
    return axios.get<IListResponseData<IHelpExpanditure>>(`/help/manualy/expenditures/`, { params: { ...params, limit: 500 } });
  },
};
