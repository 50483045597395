import cn from "classnames";
import React from "react";

import styles from "./ProgressBar.module.scss";

export interface IProps {
  completed: number;
  className?: string;
  direction?: "column" | "row-reverse";
  containerClassName?: string;
}

const ProgressBar: React.FC<IProps> = ({ completed, className, direction, containerClassName }) => {
  return (
    <div
      className={cn(styles.blockStyles, containerClassName, {
        [styles.column]: direction === "column",
        [styles.reverse]: direction === "row-reverse",
      })}
    >
      <span className={cn(styles.labelStyles, className)}>{`${completed}%`}</span>
      <div className={styles.containerStyles}>
        <div
          className={cn(styles.fillerStyles, className, "progressBar")}
          style={{ width: `${completed >= 100 ? "100" : completed}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
