import classNames from "classnames";
import React, { FC, ReactNode, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import ReactMarkdown from "markdown-to-jsx";

import styles from "./HidebleText.module.scss";

interface IProps {
  charsCount?: number;
  description?: string;
}

const HidebleText: FC<IProps> = ({ charsCount = 400, description = "" }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const displayedHtml = useMemo(() => {
    if (description.length < charsCount) {
      return description;
    }
    if (open) return description + `<span class="${styles.more}">&nbsp;Скрыть</span>`;
    let res = clearHtml(description.substring(0, charsCount));
    if (res.length < description.length) {
      res = res + "...";
    }
    return res + `<span class="${styles.more}">&nbsp;Читать еще</span>`;
  }, [description, charsCount, open]);

  useEffect(() => {
    const target = ref.current?.querySelector<HTMLSpanElement>(`.${styles.more}`);
    target?.addEventListener("click", () => {
      setOpen((prev) => !prev);
    });
  }, [displayedHtml]);

  return (
    <div ref={ref} style={{ overflow: "hidden" }} className={classNames(styles.root, !open && "markdownClosed")}>
      <ReactMarkdown>{displayedHtml as string}</ReactMarkdown>

      {/* <div
        className={styles.more}
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        {open ? "Скрыть" : "Читать еще"}
      </div> */}
    </div>
  );
};

export default HidebleText;

function clearHtml(input: string) {
  let isClosed = false;
  for (let i = input.length; i > 0; i--) {
    if (input[i] === ">") {
      isClosed = true;
    }
    if (input[i] === "<" && !isClosed) {
      return input.substring(0, i);
    }
  }
  return input;
}
