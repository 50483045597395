import cn from "classnames";
import React, { FC, useEffect, useMemo, useRef } from "react";

import ButtonArrow from "shared/ui/controls/ButtonArrow/ButtonArrow";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

import { IPermissionExtended, IPermissionsTree } from "features/permissions/model/types";

import { useInstructionsBottom } from "../../hooks/useInstructionsBottom";

import styles from "./InstructionsBottom.module.scss";

interface IProps {
  siblings?: IPermissionsTree[];
  fullActivePermission?: IPermissionExtended;
  setActivePerm?: (perm: string) => void;
}

const InstructionBottomSlider: FC<IProps> = ({ siblings, fullActivePermission, setActivePerm }) => {
  const swiperRef = useRef<SwiperRef>(null);

  const slides = useInstructionsBottom({ fullActivePermission });

  const count = useMemo(() => {
    return (
      slides?.reduce((acc, curr) => {
        return acc + curr.filter((el) => el.showing_in_instructions).length;
      }, 0) ?? 0
    );
  }, [slides]);

  if (count < 3) {
    return (
      <>
        {/* <div className={styles.subtitle}>Вкладка {fullActivePermission?.parentName}</div> */}
        <div className={styles.gallery}>
          <div className={styles.flex}>
            {slides?.map((g, groupIndex) =>
              g?.map((el, itemIndex) => (
                <SwiperSlide key={el.title + itemIndex + groupIndex}>
                  <Slide name={el.title} video={el.videos?.[0]?.url} alias={el.alias} setActivePerm={setActivePerm} />
                </SwiperSlide>
              ))
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {/*  <div className={styles.subtitle}>Вкладка {fullActivePermission?.parentName}</div> */}

      <div className={styles.gallery}>
        {count > 3 && <ButtonArrow className={styles.left} onClick={() => swiperRef.current?.swiper.slidePrev()} />}
        {count > 3 && (
          <ButtonArrow
            className={styles.right}
            direction="right"
            onClick={() => swiperRef.current?.swiper.slideNext()}
          />
        )}
        <Swiper
          ref={swiperRef}
          noSwiping={true}
          noSwipingClass={"no-swipe"}
          className={cn("plat-ben__slider")}
          slidesPerView={3}
          navigation={{ nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" }}
          spaceBetween={16}
        >
          {slides?.map((g, groupIndex) =>
            g?.map((el, itemIndex) => (
              <SwiperSlide key={el.title + itemIndex + groupIndex + Math.random()}>
                <Slide name={el.title} video={el.videos?.[0]?.url} alias={el.alias} setActivePerm={setActivePerm} />
              </SwiperSlide>
            ))
          )}
        </Swiper>
      </div>
    </>
  );
};

export default InstructionBottomSlider;

interface ISlideProps {
  name?: string;
  video?: string;
  setActivePerm?: (perm: string) => void;
  alias: string;
}

function Slide({ name, video, setActivePerm, alias }: ISlideProps) {
  return (
    <>
      <div className={styles.slide}>
        <div className={styles.slideTitle} onClick={() => setActivePerm?.(alias)}>
          {name}
        </div>
        <div
          className={styles.videoWrapper}
          onClick={() => {
            !video && setActivePerm?.(alias);
          }}
        >
          {video && <video controls src={video} width="100%" />}
          {!video && (
            <div className={styles.inWork}>
              <svg width="68" height="54" viewBox="0 0 68 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M54.6654 0H12.6151C5.64797 0 0 4.48776 0 10.0237V43.436C0 48.972 5.64797 53.4597 12.6151 53.4597H54.6654C61.6325 53.4597 67.2805 48.972 67.2805 43.436V10.0237C67.2805 4.48776 61.6325 0 54.6654 0Z"
                  fill="#4FB1EB"
                />
                <path
                  d="M49.0536 24.7207C49.6971 25.0129 50.232 25.4351 50.604 25.9446C50.9761 26.454 51.172 27.0325 51.172 27.6215C51.172 28.2105 50.9761 28.789 50.604 29.2984C50.232 29.8079 49.6971 30.2301 49.0536 30.5224L26.4145 40.8027C25.775 41.0931 25.0507 41.2449 24.3142 41.243C23.5776 41.2411 22.8546 41.0855 22.2175 40.7917C21.5804 40.498 21.0516 40.0764 20.684 39.5693C20.3164 39.0621 20.123 38.4871 20.1229 37.9018L20.1229 17.3412C20.123 16.7559 20.3164 16.1809 20.684 15.6738C21.0516 15.1666 21.5804 14.745 22.2175 14.4513C22.8546 14.1576 23.5776 14.0019 24.3142 14C25.0507 13.9981 25.775 14.1499 26.4145 14.4403L49.0536 24.7207Z"
                  fill="white"
                />
              </svg>
              <span> Видео в разработке</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
