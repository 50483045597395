import cn from "classnames";
import React, { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useParams, useRouteMatch } from "react-router-dom";

import ExcelProductsUpload from "../../../../../../_LEGACY/UI/ExcelProductsUpload/ExcelProductsUpload";
import Select from "../../../../../../components/UI/atoms/Select";
import CreateSectionInput from "../../../ProHandler/components/CreateSection/components/CreateSectionInput/CreateSectionInput";
import ImportDocumentation from "../../../ProHandler/components/ImportExtraordinaryDocumentation/ImportDocumentation";

import Spinner from "../../../../../../shared/ui/atoms/Spinner/Spinner";
import ButtonBack from "../../../../../../shared/ui/controls/ButtonBack/ButtonBack";
import Checkbox, { LabelPlacement } from "../../../../../../shared/ui/inputs/Checkbox/Checkbox";
import { loadAggregations } from "../../../Aggregations/model/thunks";
import {
  activeSubSectionSelector,
  checkedItemsIdsSelector,
  estimateStateIdSelector,
  isSectionAddingSelector,
  modeSelector,
  sectionsAreLoadingSelector,
} from "../../model/selectors";
import { simpleHandlerActions } from "../../model/slice";
import { AddButton } from "./ui/AddButton/AddButton";

import { EEstimateStatesIds } from "../../../ProHandler/enums";
import { EHandlerLevels, ESimpleHandlerMode } from "../../types";

import { useCheckUploadStatus } from "../../../ProHandler/hooks/useCheckUploadStatus";
import { useHandlerLevel } from "../../hooks/useHandlerLevel";
import { useStatusFromQueryParams } from "../ItemsList/ui/SubSectionsList/hooks/useStatusFromQueryParams";
import { useBackHandler } from "./hooks/useBackHandler";
import { useChangeItemsState } from "./hooks/useChangeItemsState";
import { useCheckAll } from "./hooks/useCheckAll";

import SelectArrowIcon from "shared/assets/icons/SelectArrowIcon.svg";

import styles from "./NavigationBar.module.scss";

interface IProps {
  sectionsAreEmpty?: boolean;
  onSubmitCreateSection?: (name: string) => void;
  onSubmitCreateOutOfEstimateSection?: (name: string) => void;
}

export const NavigationBar: FC<IProps> = ({
  sectionsAreEmpty,
  onSubmitCreateSection,
  onSubmitCreateOutOfEstimateSection,
}) => {
  const dispatch = useDispatch();
  const { buildingId, projectId } = useParams<{ buildingId: string; projectId: string }>();

  const level = useHandlerLevel();

  const sectionsAreLoading = useSelector(sectionsAreLoadingSelector);
  const isSectionAdding = useSelector(isSectionAddingSelector);
  const checkedItemsIds = useSelector(checkedItemsIdsSelector);

  const setIsSectionAdding = useCallback((v: boolean) => {
    dispatch(simpleHandlerActions.setIsSectionAdding(v));
  }, []);

  const mode = useSelector(modeSelector);
  const estimateStateId = useSelector(estimateStateIdSelector);

  const match = useRouteMatch();

  const { checkUploadStatus, isUploading, beginUploading } = useCheckUploadStatus({
    buildingId,
    onComplete: () => {
      if (buildingId) {
        dispatch(loadAggregations(buildingId));
        dispatch(simpleHandlerActions.invalidate());
      }
    },
  });

  const onModeChange = useCallback((v: ESimpleHandlerMode) => {
    dispatch(simpleHandlerActions.setMode(v));
    dispatch(simpleHandlerActions.clearCheckedItems());
  }, []);

  const activeSubSection = useSelector(activeSubSectionSelector);
  const isDefault = !!activeSubSection?.is_default;

  const onAddButtonClick = useCallback(() => {
    if (level === EHandlerLevels.EXPENDITURES) {
      dispatch(simpleHandlerActions.createAddingExpenditure());
    } else {
      setIsSectionAdding(true);
    }
  }, [level]);

  const onSectionAddingInputClose = useCallback(() => {
    setIsSectionAdding(false);
  }, []);

  const availableModeSelectOptions = useMemo(() => {
    const result = [];

    if (estimateStateId === EEstimateStatesIds.DRAFT) {
      // черновик
      result.push(modeSelectOptions[ESimpleHandlerMode.VIEW], modeSelectOptions[ESimpleHandlerMode.MARKUP]);
    } else if (estimateStateId === EEstimateStatesIds.PRODUCTION) {
      // производство
      result.push(...Object.values(modeSelectOptions));
    }

    // Иначе в сметном расчете оставляем result пустым
    // и не показываем select

    return result;
  }, [estimateStateId]);

  const { onChangeItemsState: onMoveItemsClick, isItemsEstimateStateChanging } = useChangeItemsState();

  const { backTitle, onBack } = useBackHandler();

  const { onCheckboxClick, isAllChecked, isIndeterminate, checked } = useCheckAll();

  const status = useStatusFromQueryParams();
  const isNewInLocalState = status === "new" && estimateStateId === EEstimateStatesIds.LOCALE;

  const showMoveButton =
    mode === ESimpleHandlerMode.VIEW && estimateStateId !== EEstimateStatesIds.PRODUCTION && !isNewInLocalState;

  const showImportEstimateButton = level === EHandlerLevels.SECTIONS && estimateStateId === EEstimateStatesIds.DRAFT;

  const isCheckboxVisible =
    mode === ESimpleHandlerMode.VIEW && estimateStateId !== EEstimateStatesIds.PRODUCTION && !isNewInLocalState;

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.leftSide}>
          <div className={styles.back}>
            <ButtonBack className={styles.button} onClick={onBack} />
            <span className={styles.backTitle} title={backTitle} onClick={onBack}>
              {backTitle}
            </span>
          </div>
        </div>

        <div className={styles.rightSide}>
          <Route exact path={[`${match.path}/:sectionId`, `${match.path}/:sectionId/:subsectionId`, match.path]}>
            {!sectionsAreLoading &&
              [EEstimateStatesIds.DRAFT, EEstimateStatesIds.PRODUCTION].includes(estimateStateId!) && (
                <>
                  {level === EHandlerLevels.EXPENDITURES &&
                  estimateStateId === EEstimateStatesIds.PRODUCTION &&
                  !isDefault ? null : (
                    <AddButton
                      className={styles.addButton}
                      text={addSectionButtonText[level as keyof typeof addSectionButtonText]}
                      onClick={onAddButtonClick}
                    />
                  )}
                  {estimateStateId === EEstimateStatesIds.PRODUCTION && level === EHandlerLevels.SECTIONS && (
                    <ImportDocumentation refreshSections={() => {}} className={styles.importDocumentationButton} />
                  )}
                </>
              )}
          </Route>
          {showImportEstimateButton && (
            <div className={styles.uploadFile}>
              <ExcelProductsUpload
                className={styles.uploadButton}
                api={`/building/${buildingId}/estimates/`}
                // @ts-ignore
                postCallback={checkUploadStatus}
                isUploading={isUploading}
                beginUploading={beginUploading}
                noMessage
              />
            </div>
          )}
          {!!availableModeSelectOptions.length && (
            <Select
              options={availableModeSelectOptions}
              value={mode}
              onChange={(v) => onModeChange(v as ESimpleHandlerMode)}
              icon={SelectArrowIcon}
              selectClassName={styles.modeSelect}
              classNameOption={styles.modeSelectOption}
              iconClassName={styles.modeSelectIcon}
            />
          )}
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.leftSide}>
          {isCheckboxVisible && (
            <>
              <Checkbox
                checked={checked}
                onCheck={onCheckboxClick}
                indeterminate={isIndeterminate}
                labelPlacement={LabelPlacement.RIGHT}
                className={styles.checkbox}
              >
                Выбрать все
              </Checkbox>
              <span className={styles.checkedCount}>Выбрано: {checkedItemsIds.length}</span>
            </>
          )}
        </div>

        <div className={styles.rightSide}>
          {showMoveButton && (
            <button
              className={cn(styles.moveItemsButton, {
                [styles.disabled]: !checkedItemsIds.length || isItemsEstimateStateChanging,
              })}
              disabled={!checkedItemsIds.length || isItemsEstimateStateChanging}
              onClick={onMoveItemsClick}
            >
              <span className={styles.text}>
                {estimateStateId === EEstimateStatesIds.DRAFT ? "В сметный расчет" : "В производство"}
              </span>
              {isItemsEstimateStateChanging && (
                <Spinner
                  isStatic
                  isSmall
                  className={styles.moveItemsSpinnerContainer}
                  // TODO: исправить
                  // @ts-ignore
                  spinnerClassName={styles.moveItemsSpinner}
                />
              )}
            </button>
          )}
        </div>
      </div>

      {isSectionAdding && (
        <div className={cn(styles.row, { [styles.withBorderBottom]: !sectionsAreEmpty })}>
          <CreateSectionInput
            onSubmit={
              estimateStateId === EEstimateStatesIds.PRODUCTION
                ? onSubmitCreateOutOfEstimateSection
                : onSubmitCreateSection
            }
            close={onSectionAddingInputClose}
            placeholder={
              level === EHandlerLevels.SUBSECTIONS ? "Введите название подраздела" : "Введите название раздела"
            }
            className={styles.createSectionInput}
          />
        </div>
      )}
    </div>
  );
};

const modeSelectOptions = {
  [ESimpleHandlerMode.VIEW]: { id: ESimpleHandlerMode.VIEW, name: "Режим просмотра" },
  [ESimpleHandlerMode.MARKUP]: { id: ESimpleHandlerMode.MARKUP, name: "Режим наценки" },
  [ESimpleHandlerMode.BUDGET]: { id: ESimpleHandlerMode.BUDGET, name: "Режим бюджета" },
  [ESimpleHandlerMode.ESTIMATED_PRICE]: { id: ESimpleHandlerMode.ESTIMATED_PRICE, name: "Режим расчетной цены" },
};

const addSectionButtonText: Record<EHandlerLevels, string> = {
  [EHandlerLevels.SECTIONS]: "Добавить раздел",
  [EHandlerLevels.SUBSECTIONS]: "Добавить подраздел",
  [EHandlerLevels.EXPENDITURES]: "Добавить позицию",
};
