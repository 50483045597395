import moment from "moment";

import { weeksInYear } from "components/pages/Chart/utils/weeksInYear";

const nowMoment = moment();

export const generateWeeksForLine = (year: number) => {
  const weeks = [];

  const wInYear = weeksInYear(year);

  for (let w = 1; w <= wInYear; w++) {
    const weekMoment = moment().year(year).week(w);
    const startOfWeekMoment = moment(weekMoment).isoWeekday(1);
    const endOfWeekMoment = moment(weekMoment).isoWeekday(7);
    const isStartMonth = w === 1 || startOfWeekMoment.date() === 1 || startOfWeekMoment.date() > endOfWeekMoment.date();

    weeks.push({
      label: `${startOfWeekMoment.format("DD.MM")} - ${endOfWeekMoment.format("DD.MM")} ${year}`,
      startMonth: isStartMonth,
      today: nowMoment.isBetween(startOfWeekMoment, endOfWeekMoment, "day", "[]")
        ? nowMoment.isoWeekday() - 1
        : undefined,
      month: endOfWeekMoment.month(),
      startDate: startOfWeekMoment.date(),
    });
  }
  return weeks;
};
