import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";

import Icon from "_LEGACY/UI/_LEGACY_Icon/Icon";

import arrowDownSelect from "images/icons/arrowDownSelect.svg";

import styles from "./index.module.scss";

// norefs

const InputSelect = ({
  options = [],
  defaultValue,
  name,
  placeholder = "Введите или укажите ФИО сотрудника",
  selectedAction,
  input,
  onClick,
  className,
  optionsBlockClassName,
  label,
  disabled = false,
  meta,
  icon = arrowDownSelect,
}) => {
  // const { error, touched, valid } = meta;
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options.find((item) => input && item?.name === input.value));
  const [value, setValue] = useState();
  const [filteredOptions, setFilteredOptions] = useState(options);
  useEffect(() => {
    setFilteredOptions(options);
    setValue(options.find((item) => item.id === defaultValue || item.id === input?.value)?.name);
  }, [defaultValue, options]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
    setFilteredOptions(options.filter((item) => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1));
  };

  const handleSelect = (item) => {
    input && input.onChange(item.name);
    setSelectedOption(item);
    setValue(item.name);
    if (onClick) onClick(item.id);
  };

  const setToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {label && <label>{label}</label>}
      <div
        className={cn(styles.select, className, {
          [styles.isOpen]: isOpen && !disabled,
          [styles.disabled]: disabled,
        })}
        onClick={setToggle}
        ref={wrapperRef}
      >
        <input type="" placeholder={placeholder} value={value || ""} onChange={handleChange} disabled={disabled} />
        {!disabled && <Icon icon={icon} className={cn(styles.arrow, { [styles.arrowReverse]: isOpen })} />}
        {!disabled && (
          <div
            className={cn(styles.optionsBlock, optionsBlockClassName, {
              [styles.isOpen]: isOpen,
            })}
          >
            {filteredOptions &&
              filteredOptions.length > 0 &&
              filteredOptions.map((item) => (
                <div key={item.id} className={styles.option} onClick={() => handleSelect(item)}>
                  {item.label}
                </div>
              ))}
            {(!filteredOptions || (filteredOptions && filteredOptions.length === 0)) && (
              <div className={styles.option}>Нет данных</div>
            )}
          </div>
        )}
      </div>
      {meta?.touched && meta?.error && <div className={cn(styles.errorMessage)}>{meta.error}</div>}
    </>
  );
};

export default InputSelect;
