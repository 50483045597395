import cn from "classnames";
import React, { useMemo, useState } from "react";

import ConfirmBlock from "components/UI/_TODO/AddingListInModal/components/ConfirmBlock/ConfirmBlock";
import Expandable from "components/UI/atoms/_TODO/Expandable/Expandable";

import TaskApproversRow from "./TaskApproversRow/TaskApproversRow";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";
import Checkbox from "shared/ui/inputs/Checkbox/Checkbox";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { tasksFieldsType } from "../../model/types";

import workersIcon from "images/icons/navigation/timesheetIcon.svg";

import styles from "./TaskApprovers.module.scss";

interface IProps {
  allApprovers: tasksFieldsType["approvers"];
  addedApprovers: number[];
  isLoading?: boolean;
  onAccept: (ids: number[]) => void;
  onDelete: (id: number) => void;
  setFilter: (name: string) => void;
}

const TaskApproversAdd: React.FC<IProps> = ({
  allApprovers,
  addedApprovers,
  isLoading,
  onAccept,
  onDelete,
  setFilter,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const onDecline = () => {
    setSelectedIds([]);
    setIsAdding(false);
  };

  const acceptHandler = () => {
    onAccept(selectedIds);
    setSelectedIds([]);
  };

  const added = useMemo(() => {
    return allApprovers?.filter((el) => addedApprovers.includes(el.id));
  }, [allApprovers, addedApprovers]);

  if (!allApprovers?.length) {
    return null;
  }

  return (
    <>
      {!!added?.length && (
        <div className={cn(styles.table, styles.border2ed)}>
          {added?.map((el, i) => (
            <TaskApproversRow
              canDelete
              canBeChecked={false}
              isSelected={false}
              onCheck={() => {}}
              key={el.id}
              name={el.last_name + " " + el.first_name}
              id={el.id}
              number={i + 1}
              onDelete={onDelete}
            />
          ))}
        </div>
      )}

      {!isLoading && (
        <>
          {!isAdding && (
            <AddButton
              className={styles.addBtn}
              text="Согласование"
              textPosition="left"
              onClick={() => setIsAdding(true)}
            />
          )}
        </>
      )}
      {isAdding && (
        <ConfirmBlock
          count={selectedIds?.length}
          onAccept={acceptHandler}
          onDecline={onDecline}
          onCommonClick={() => setIsAdding(false)}
        />
      )}

      {isAdding && (
        <div className={cn(styles.table, styles.bordered)}>
          <div className={styles.header}>
            <div></div>
            <div style={{ justifyContent: "center" }}>№</div>
            <TableReusableHeaderCell
            //filterField="approvers"
            //filterType="text"
            //onChangeFilter={(_, value) => setFilter(value)}
            >
              ФИО
            </TableReusableHeaderCell>
          </div>
          {!!(!isLoading && allApprovers?.length) &&
            allApprovers
              ?.filter((el) => !addedApprovers.includes(el.id))
              .map((el, i) => (
                <TaskApproversRow
                  canDelete={false}
                  canBeChecked
                  isSelected={selectedIds.includes(el.id)}
                  onCheck={(e, id) => {
                    if (e.target.checked) {
                      setSelectedIds((prev) => [...prev, id]);
                    } else {
                      setSelectedIds((prev) => prev.filter((el) => el !== id));
                    }
                  }}
                  key={el.id}
                  name={el.last_name + " " + el.first_name}
                  id={el.id}
                  number={i + 1}
                  onDelete={onDelete}
                />
              ))}
          {!!(!isLoading && !allApprovers?.length) && <EmptyPlaceholder img={workersIcon} />}
        </div>
      )}
    </>
  );
};

export default TaskApproversAdd;
