import { FC, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { activeSubSectionSelector, estimateStateIdSelector, invalidateKeySelector } from "../../../../model/selectors";
import { loadExpenditures } from "../../../../model/thunks";
import { CommonExpendituresList } from "./ui/CommonExpendituresList/CommonExpendituresList";
import { ExpendituresListInLocaleStateView } from "./ui/ExpendituresListInLocaleStateView/ExpendituresListInLocaleStateView";

import { EEstimateStatesIds } from "../../../../../ProHandler/enums";

import { useDefineActiveSubSection } from "./hooks/useDefineActiveSubSection";

export const ExpendituresList: FC = memo(() => {
  useDefineActiveSubSection();

  const dispatch = useDispatch();

  const { buildingId } = useParams<{ buildingId: string }>();

  const estimateStateId = useSelector(estimateStateIdSelector);
  const activeSubSection = useSelector(activeSubSectionSelector);
  const invalidateKey = useSelector(invalidateKeySelector);

  useEffect(() => {
    if (buildingId && activeSubSection && estimateStateId) {
      dispatch(loadExpenditures(buildingId, activeSubSection.id, estimateStateId));
    }
  }, [buildingId, activeSubSection?.id, estimateStateId, invalidateKey]);

  if (estimateStateId === EEstimateStatesIds.LOCALE) {
    return <ExpendituresListInLocaleStateView />;
  } else {
    return <CommonExpendituresList />;
  }
});

ExpendituresList.displayName = "ExpendituresList";
