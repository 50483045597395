import cn from "classnames";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import LabeledSwitch from "components/UI/atoms/_TODO/LabeledSwitch";

import { auditActions } from "../../../model/slice";
import { useTypedSelector } from "app/store/typedUseSelector";

/* 
import { auditTypeSelector } from "../../../../../../redux/modules/common/constructing/finance/audit/selectors";
import { toggleAuditType } from "../../../../../../redux/modules/common/constructing/finance/audit/thunks"; */
import styles from "./AuditTypeSwitcher.module.scss";

export interface IAuditTypeSwitcherProps {
  className?: string;
}

const AuditTypeSwitcher: React.FC<IAuditTypeSwitcherProps> = ({ className }) => {
  const dispatch = useDispatch();
  const auditType = useTypedSelector((state) => state.financeReducer.audit.type);

  const isSwitchActive = auditType === "forecast";

  const toggleSwitch = useCallback(() => {
    dispatch(auditActions.setType(auditType === "forecast" ? "summary" : "forecast"));
  }, [auditType]);

  return (
    <div className={cn(styles.container, className)}>
      <span>Аудит: </span>
      <LabeledSwitch label={"сводной"} labelRight={"прогноза"} value={isSwitchActive} onChange={toggleSwitch} />
    </div>
  );
};

export default React.memo(AuditTypeSwitcher);
