import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { objectDetailSelector } from "../../../../../../../../../redux/modules/common/building/object/nowObject";
import { userSelector } from "redux/modules/_TODO/auth";

import { IActions } from "../../../../../../../../../components/UI/_TODO/EstimateSection/EstimateSection";

import { estimateStateIdSelector } from "../../../../../model/selectors";
import { deleteSection, patchSection, simpleHandlerModel } from "../../../../../model/thunks";

import ISection from "../../../../../../../../../types/interfaces/Section";
import { EEstimateStatesIds, EstimateItemsStatusesEnum } from "../../../../../../ProHandler/enums";

import { useIsOpenedInApproveForm } from "../../../../../hooks/useIsOpenedInApproveForm";

export const useSectionActions = () => {
  const dispatch = useDispatch();

  const building = useSelector(objectDetailSelector);
  const user = useSelector(userSelector);
  const estimateStateId = useSelector(estimateStateIdSelector);

  const isOpenedInApproveForm = useIsOpenedInApproveForm();

  const [isLoadingByKey, setIsLoadingByKey] = useState<Record<number, boolean>>({});

  const setIsSectionLoading = useCallback((section: ISection, value: boolean) => {
    setIsLoadingByKey((prev) => ({ ...prev, [section.id]: value }));
  }, []);

  const submitEditSectionName = (name: string, section: ISection, status?: "new" | "confirmed") => {
    dispatch(patchSection(String(building!.id), section.current_state, { id: section.id, name }, status));
  };

  const handleDeleteSection = (section: ISection) => {
    dispatch(
      deleteSection({
        buildingId: String(building!.id),
        sectionId: section.id,
        estimateState: estimateStateId!,
        isSubSection: !!(section.parent ?? section.parent_id),
        isDefault: section.is_default,
      })
    );
  };

  const handleConfirmSection = useCallback(
    (section: ISection) => {
      if (building && estimateStateId) {
        setIsSectionLoading(section, true);
        dispatch(
          simpleHandlerModel.updateItemStatus({
            itemId: section.id,
            status: EstimateItemsStatusesEnum.CONFIRMED,
            callback: () => setIsSectionLoading(section, false),
          })
        );
      }
    },
    [building?.id, estimateStateId]
  );

  const handleCancelSection = useCallback(
    (section: ISection) => {
      if (building && estimateStateId) {
        setIsSectionLoading(section, true);
        dispatch(
          simpleHandlerModel.updateItemStatus({
            itemId: section.id,
            status: EstimateItemsStatusesEnum.CANCELED,
            callback: () => setIsSectionLoading(section, false),
          })
        );
      }
    },
    [building?.id, estimateStateId]
  );

  const statusFromQueryParams = new URL(window.location.href).searchParams.get("status");

  const getSectionActions = useCallback(
    (section: ISection, isDirectlyCanConfirm?: boolean): Partial<IActions> | undefined => {
      const isDraft = estimateStateId === EEstimateStatesIds.DRAFT;
      const isProduction = estimateStateId === EEstimateStatesIds.PRODUCTION;
      const isLoading = isLoadingByKey[section.id];

      const userIsResponsibleEmployee = user?.id === building?.responsible_estimate?.id;

      if (!isDraft && !userIsResponsibleEmployee && !isOpenedInApproveForm) return;
      if (section?.is_default) {
        return {
          canEdit: !isOpenedInApproveForm ? true : !isOpenedInApproveForm,
          onSaveEditName: (changedName: string) => submitEditSectionName(changedName, section),
          canRemove: isProduction && !isOpenedInApproveForm,
          onRemove: () => handleDeleteSection(section),
        };
      }

      const sectionIsInDraft = isDraft && section.current_state === "";

      const canConfirm =
        typeof isDirectlyCanConfirm === "boolean"
          ? isDirectlyCanConfirm
          : (isOpenedInApproveForm || userIsResponsibleEmployee) && section.status === "new" && !isDraft;

      return {
        onConfirm: () => handleConfirmSection(section),
        onCancel: () => handleCancelSection(section),
        onSaveEditName: (changedName: string, status?: "new" | "confirmed") =>
          submitEditSectionName(changedName, section, status),
        canEdit: isProduction ? section.current_state !== EEstimateStatesIds.LOCALE : !isOpenedInApproveForm,
        onRemove: () => handleDeleteSection(section),
        isLoading,
        disabled: isLoading,
        canRemove:
          !isOpenedInApproveForm &&
          sectionIsInDraft &&
          userIsResponsibleEmployee &&
          (section.status === "confirmed" || section.added_manually),
        canConfirm,
      };
    },
    [
      estimateStateId,
      user,
      building?.id,
      handleConfirmSection,
      handleCancelSection,
      submitEditSectionName,
      handleDeleteSection,
      statusFromQueryParams,
      isOpenedInApproveForm,
    ]
  );

  return {
    getSectionActions,
  };
};
