import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  createRemarkIsLoadingSelector,
  createRemarkIsSuccessSelector,
} from "redux/modules/common/building/journal/journalExecution/selectors";
import {
  postJournalDeliveryCreateRemark,
  postJournalExecutionCreateRemark,
} from "redux/modules/common/building/journal/journalExecution/thunks";
import { getDetail } from "redux/modules/common/building/object/nowObject";

import { ICreateAndEditRemarkFieldValues } from "../types/types";
import { IFile, IFilesBase64Format } from "types/interfaces/Files";
import { IEmployee } from "types/personsTypes";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import getShortFullName from "utils/formatters/getShortFullName";

export interface IProps {
  formRef: React.RefObject<HTMLFormElement | null>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  expenditureId: number;
  isGroup?: boolean;
  isDelivery: boolean;
  isShared?: boolean;
}

export const useCreateRemarkModal = ({
  formRef,
  isOpen,
  setIsOpen,
  expenditureId,
  isGroup,
  isDelivery,
  isShared,
}: IProps) => {
  const dispatch = useDispatch();
  const params = useParams<IRouterParamsWithObjectId>();
  const buildingId = params.objectId;

  const isLoading = useSelector(createRemarkIsLoadingSelector);
  const isSuccess = useSelector(createRemarkIsSuccessSelector);

  const [selectedWorker, setSelectedWorker] = useState<IEmployee | null>(null);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [isResetFiles, setIsResetFiles] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);

  const resetFormWithTimeout = () => {
    setTimeout(() => {
      if (!formRef.current) return;

      formRef.current.dispatchEvent(new Event("reset", { cancelable: true, bubbles: true }));
      setIsResetFiles(true);
      setFiles([]);
      setSelectedWorker(null);
    }, 500);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getDetail(buildingId));
      setIsResetFiles(false);
    }
    resetFormWithTimeout();
  }, [isOpen]);

  useEffect(() => {
    if (!isSuccess) return;

    resetFormWithTimeout();
    setIsOpen(false);
  }, [isSuccess]);

  const createRemark = (values: ICreateAndEditRemarkFieldValues, arrayFilesInBase64?: IFilesBase64Format[]) => {
    if (!selectedWorker) return;

    if (isDelivery) {
      dispatch(
        postJournalDeliveryCreateRemark(buildingId, {
          ...values,
          resolve_date: moment(values.resolve_date).format("YYYY-MM-DD"),
          work_interval_id: isGroup ? null : expenditureId,
          group_interval_id: isGroup ? expenditureId : null,
          responsible: selectedWorker.id,
          files: arrayFilesInBase64,
        })
      );
    } else {
      dispatch(
        postJournalExecutionCreateRemark(buildingId, {
          ...values,
          resolve_date: moment(values.resolve_date).format("YYYY-MM-DD"),
          estimate_expenditure: isGroup ? null : expenditureId,
          estimate_group: isGroup ? expenditureId : null,
          responsible: selectedWorker.id,
          files: arrayFilesInBase64,
        })
      );
    }
  };

  const onSubmit = async (values: ICreateAndEditRemarkFieldValues) => {
    getArrayFilesInBase64(files)
      .then((arrayFilesInBase64) => {
        createRemark(values, arrayFilesInBase64);
      })
      .catch(() => {
        createRemark(values);
      });
  };

  const handleOpenTable = () => {
    setIsTableOpen(true);
  };

  const handleCloseTable = () => {
    setIsTableOpen(false);
    setSelectedWorker(null);
  };

  const handleRemoveSelected = () => {
    setSelectedWorker(null);
  };

  const handleSelectWorker = (worker: IEmployee) => {
    const shortFullName = worker.full_name
      ? getShortFullName(worker.full_name)
      : getShortFullName(worker.last_name, worker.first_name, worker.middle_name);

    setSelectedWorker({ ...worker, short_full_name: shortFullName });
    setIsTableOpen(false);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return {
    files,
    setFiles,
    isResetFiles,
    onSubmit,
    selectedWorker,
    closeModal,
    handleOpenTable,
    handleCloseTable,
    handleRemoveSelected,
    handleSelectWorker,
    isLoading,
    isSuccess,
    isTableOpen,
  };
};
