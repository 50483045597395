import moment from "moment";
import React, { useEffect, useMemo } from "react";

import ClosureDatePlate from "./components/ClosureDatePlate/ClosureDatePlate";
import ClosureDatePlateWithPlanPercents from "./components/ClousureDatePlateWithPlanPercents/ClosureDatePlateWithPlanPercents";

import AddButton from "../../controls/AddButton/AddButton";
import useClosureDates from "./useClosureDates";

import styles from "./ClosureDates.module.scss";

export interface IClosureDatesProps
  extends Partial<
      Pick<ReturnType<typeof useClosureDates>, "addNewClosureDate" | "editClosureDate" | "removeClosureDate">
    >,
    Pick<ReturnType<typeof useClosureDates>, "closureDates"> {
  canEdit: boolean;
  budgetData: string;
  canSave: boolean;
  isNew_close_dates: boolean;
  variant?: "inRow";
  title?: string;
}

const ClosureDates: React.FC<IClosureDatesProps> = ({
  closureDates,
  addNewClosureDate,
  editClosureDate,
  removeClosureDate,
  canEdit,
  budgetData,
  canSave,
  isNew_close_dates,
  variant = "inRow",
  title,
}) => {
  const filteredClosureDates = useMemo(() => {
    return closureDates.sort((first, second) => {
      const firstMoment = moment(first.payment_date, "YYYY-MM-DD");
      const secondMoment = moment(second.payment_date, "YYYY-MM-DD");
      return firstMoment.diff(secondMoment);
    });
  }, [closureDates]);

  const hasOldClosureDates = useMemo(() => {
    return !!closureDates.filter((date) => date.strategy === 1).length;
  }, [closureDates]);

  return (
    <>
      {!filteredClosureDates.length && canEdit && (
        <div>
          <AddButton
            text={variant === "inRow" ? "Плановые даты закрытия:" : "Добавить дату закрытия"}
            onClick={addNewClosureDate}
            className={variant === "inRow" ? "" : styles.addClosureDateButton}
            textPosition={variant === "inRow" ? "left" : "right"}
          />
        </div>
      )}
      {!!filteredClosureDates.length && (
        <div className={styles.closureDatesHead}>
          <span>
            Добавленные дни закрытия: <b>{filteredClosureDates.length}</b>
          </span>
          {canEdit && (
            <AddButton
              text={"Добавить еще"}
              textPosition={"left"}
              className={styles.addMoreDatesBtn}
              onClick={addNewClosureDate}
            />
          )}
        </div>
      )}
      {!!filteredClosureDates.length && (
        <div className={isNew_close_dates && !hasOldClosureDates ? styles.datesFieldsWithPercents : styles.datesFields}>
          {filteredClosureDates.map((date, index) =>
            date.strategy === 1 ? (
              <ClosureDatePlate
                date={date}
                key={date.id}
                canEdit={canEdit}
                removeClosureDate={removeClosureDate}
                editClosureDate={editClosureDate}
              />
            ) : (
              <ClosureDatePlateWithPlanPercents
                budget={budgetData}
                date={date}
                key={date.id}
                canEdit={canEdit}
                removeClosureDate={removeClosureDate}
                editClosureDate={editClosureDate}
                closureDates={closureDates}
                isLastIndex={index === filteredClosureDates.length - 1}
                canSave={canSave}
              />
            )
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(ClosureDates);
