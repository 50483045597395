import { message } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { Popover } from "@blueprintjs/core";
import ButtonBase from "shared/ui/controls/ButtonBase";

import useOnClickOutside from "hooks/useOnClickOutside";

import forkImg from "images/icons/code.svg";

import styles from "./ForkProducts.module.scss";

interface IProps {
  // TODO any
  disabledDate?: any;
  // TODO any
  onFork: (count: string, date: any) => void;
  maxCount?: number;
}

const ForkProduct: React.FC<IProps> = ({ disabledDate, onFork, maxCount = 999999 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState("");

  const cleanup = () => {
    setCount("");
  };

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  useEffect(() => {
    cleanup();
  }, [disabledDate]);

  const changeCount = (value: string) => {
    if (value.match(/^[0-9]+(\.\d{0,2})?$/) || value === "") {
      setCount(value);
    }
  };

  const fork = () => {
    if (parseFloat(count) <= maxCount - 0.01) {
      onFork(count, moment(disabledDate));
      cleanup();
      onClose();
    } else {
      message.error("Значение больше доступного");
    }
  };

  const contentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(contentRef, onClose);

  return (
    <>
      <Popover
        isOpen={isOpen}
        usePortal={false}
        interactionKind={"click"}
        disabled={false}
        content={
          <div className={styles.popover} ref={contentRef}>
            <div className={styles.title}>Кол-во:</div>
            <input
              value={count}
              onChange={(e) => changeCount(e.target.value)}
              onBlur={(e) => {
                !isNaN(parseFloat(e.target.value)) && changeCount(parseFloat(e.target.value || "0").toFixed(2));
              }}
              onKeyDown={(e) => {
                if (e.key.toLowerCase() === "enter") {
                  fork();
                }
              }}
            />
            <ButtonBase primary small className={styles.btn} onClick={fork}>
              Разделить
            </ButtonBase>
          </div>
        }
      >
        <img onClick={isOpen ? onClose : onOpen} className={styles.forkIcon} alt={"разделить"} src={forkImg} />
      </Popover>
    </>
  );
};

export default ForkProduct;
