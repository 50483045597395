import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  journalFulfillmentSectionsLoadingSelector,
  journalFulfillmentSectionsSelector,
  sectionsInJournalExecSelector,
} from "redux/modules/common/building/journal/journalExecution/selectors";
import { getJournalExecutionSections } from "redux/modules/common/building/journal/journalExecution/thunks";
import { getJournalFullfillmentSections } from "redux/modules/common/building/journal/journalExecution/thunksV2";
import { ISectionsFilterJournalExec } from "redux/modules/common/building/journal/journalExecution/types";
import { generateJornalTicketSectionKey } from "redux/modules/common/building/journal/utils";

import JournalExecSection from "./JournalExecSection/JournalExecSection";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./JournalExecSections.module.scss";

interface IProps {
  onOpenSection: (sectionId: number) => void;
  month: number;
  year: number;
}

const JournalExecSections: React.FC<IProps> = ({ onOpenSection, month, year }) => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const key = generateStorageKey({ buildingId: objectId, year, month });
  const sectionsV2 = useSelector(journalFulfillmentSectionsSelector)[key];
  const isLoading = useSelector(journalFulfillmentSectionsLoadingSelector)[key];

  React.useLayoutEffect(() => {
    dispatch(getJournalFullfillmentSections(objectId, year, month));
  }, [objectId, month, year]);

  return (
    <div className={styles.container}>
      {isLoading && <Spinner />}
      {!isLoading &&
        sectionsV2?.map((el) => <JournalExecSection onOpenSection={onOpenSection} key={el.id} section={el} />)}
      {!isLoading && !sectionsV2?.length && <EmptyPlaceholder img={jurnalIcon} />}
    </div>
  );
};

export default React.memo(JournalExecSections);
