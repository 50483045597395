import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ordersListSelector } from "redux/modules/common/orders/selectors";
import { getOrdersList, getOrdersListMore } from "redux/modules/common/orders/thunks";

import OrdersRow from "./OrdersRow/OrdersRow";
import OrdersTableHead from "./OrdersTableHead/OrdersTableHead";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ShowMoreButton from "shared/ui/controls/ShowMoreButton";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

import { OrdersListTabsNamesEnum, ordersListTabStatuses } from "./constants";
import { IOrderInList } from "types/interfaces/Order";

import { hasOnlyPaginationFilters } from "../../../utils/hasOnlyPaginationFilters";

import graphIcon from "images/icons/navigation/graphIcon.svg";

function OrdersList() {
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState(OrdersListTabsNamesEnum.inWorkOrders);
  const keyByStatuses = ordersListTabStatuses[tab].join(",");
  const orders = useSelector(ordersListSelector)[keyByStatuses];

  const [filters, setFilters] = React.useState({});
  const isWithFilters = useMemo(() => !hasOnlyPaginationFilters(filters), [filters]);

  React.useEffect(() => {
    dispatch(getOrdersList(ordersListTabStatuses[tab], filters));
  }, [filters, tab]);

  const loadMoreHandler = () => {
    dispatch(getOrdersListMore(ordersListTabStatuses[tab], filters, orders?.orders.length));
  };

  const tabClickHandler = (tab: string) => {
    setTab(tab as OrdersListTabsNamesEnum);
  };

  const hasNextPage = orders?.count > orders?.orders?.length;

  const changeFiltersHandler = (name: string, value: string) => {
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isEmpty = !orders?.isLoading && !orders?.orders?.length;

  return (
    <TemplateBase dataTestId="page_orders">
      <ListPageHeaderTemplate>
        <TabBarNotLinks
          activeId={tab}
          onClick={tabClickHandler}
          tabs={[
            { text: "В работе", id: "inWorkOrders" },
            { text: "Одобрены", id: "acceptedOrders" },
            { text: "Исполнены", id: "completedOrders" },
          ]}
        />
      </ListPageHeaderTemplate>
      {((isEmpty && isWithFilters) || !isEmpty) && <OrdersTableHead onChangeFilter={changeFiltersHandler} tab={tab} />}
      {!orders?.isLoading && orders?.orders.map((el: IOrderInList) => <OrdersRow key={el.id} order={el} />)}
      {isEmpty && <EmptyPlaceholder text="Нет заказов" img={graphIcon} />}
      {orders?.isLoading && <Spinner />}
      <ShowMoreButton
        isExists={hasNextPage && !orders?.isLoading}
        handleAdd={loadMoreHandler}
        allCount={orders?.count}
        showedCount={orders?.orders?.length}
      />
    </TemplateBase>
  );
}

export default OrdersList;
