import React from 'react';
import cn from "classnames";

import styles from "./index.module.scss";
//meta: { touched, valid }
const Textarea = ({ input, ...rest }) => (
  <textarea
    className={cn(styles.input)}
    {...input}
    {...rest}
  />
)

export default Textarea;