import cn from "classnames";
import { divide } from "lodash";
import moment, { Moment } from "moment";
import React, { useCallback, useContext, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartActions } from "redux/modules/common/chart/actions";
import { chartScrollMonthSelector, chartScrollYearSelector } from "redux/modules/common/chart/selectors";
import { chartMarkCheckpointDate } from "redux/modules/common/chart/thunks";
import { CHART_VIEW_MODE } from "redux/modules/common/chart/types";

import { useRem } from "components/pages/Manufacturing/hooks/useRem";

import { ChartContext } from "../../Chart";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";
import ContentWithCount from "shared/ui/dataDisplay/ContentWithCount/ContentWithCount";
import MonthsYearsSlider from "shared/ui/inputs/MonthsYearsSlider/MonthsYearsSlider";
import { AddCheckpoint, Checkpoint } from "widgets/AddCheckpoint";
import SendMultiplePlansForApprove from "widgets/SendMultiplePlansForApprove/SendMultiplePlansForApprove";

import { useChartUnitMultiplier } from "../../hooks/useChartUnitMultiplier";
import { useUrlModule } from "utils/hooks/useUrlModule";

import { useChartTabs } from "./ChartControls.utils/useChartTabs";
import { useChartViewModeTabs } from "./ChartControls.utils/useChartViewModeTabs";

import { ReactComponent as AddCheckpointIcon } from "images/icons/blueDoneSvg.svg";
import { ReactComponent as ApproveIcon } from "images/icons/required-day-icon-blue.svg";

import styles from "./ChartControls.module.scss";

export interface IChartControlsProps {
  handleDateChange: (newDate: Moment) => void;
  projectId: string;
}

const YEAR_SELECTED_MONTHS_COUNT_DEFAULT = 6;
const AVERAGE_WEEKS_IN_MONTH = 4.345;

const ChartControls: React.FC<IChartControlsProps> = ({ handleDateChange, projectId }) => {
  const dispatch = useDispatch();
  const { chartTabs, tab, setTab } = useChartTabs();
  const { chartViewModeTabs, chartViewMode, setChartViewMode } = useChartViewModeTabs();
  const year = useSelector(chartScrollYearSelector);
  const month = useSelector(chartScrollMonthSelector);

  const module = useUrlModule();

  const unitMultiplier = useChartUnitMultiplier();
  const [yearSelectedMonths, setYearSelectedMonths] = useState(YEAR_SELECTED_MONTHS_COUNT_DEFAULT);
  const { REM } = useRem();
  const { contentRef } = useContext(ChartContext);

  useLayoutEffect(() => {
    if (chartViewMode !== CHART_VIEW_MODE.MONTHS || !contentRef?.current) return;
    // 35% занимает дерево расценок, такой же отступ у IntersectionObserver скролла графика
    const visibleMonthsWidthPx = contentRef.current.clientWidth * 0.65;
    setYearSelectedMonths(
      Math.round(visibleMonthsWidthPx / (AVERAGE_WEEKS_IN_MONTH * unitMultiplier * REM)) ||
        YEAR_SELECTED_MONTHS_COUNT_DEFAULT
    );
  }, [unitMultiplier, REM, contentRef?.current]);

  const selectedDate = useMemo(
    () =>
      moment()
        .year(+year)
        .month(
          chartViewMode === CHART_VIEW_MODE.MONTHS && month > yearSelectedMonths
            ? month - yearSelectedMonths + 1
            : month
        )
        .date(1),
    [year, month, chartViewMode, yearSelectedMonths]
  );

  const yearViewLeftArrowHandler = useCallback(() => {
    if (selectedDate.month() === 0) {
      handleDateChange(
        moment()
          .year(selectedDate.year() - 1)
          .month(12 - yearSelectedMonths)
          .date(1)
      );
    } else {
      handleDateChange(moment(selectedDate).subtract(1, "month"));
    }
  }, [selectedDate, yearSelectedMonths]);

  const yearViewRightArrowHandler = useCallback(() => {
    if (selectedDate.month() <= 11 - yearSelectedMonths) {
      handleDateChange(moment(selectedDate).add(1, "month"));
    } else {
      handleDateChange(
        moment()
          .year(selectedDate.year() + 1)
          .month(0)
          .date(1)
      );
    }
  }, [selectedDate, yearSelectedMonths]);

  const markCheckpointDate = useCallback((checkpoint: Checkpoint) => {
    dispatch(chartMarkCheckpointDate(checkpoint.check_point_date, 1));
  }, []);

  const isViewModeOnlySelect = chartViewModeTabs.length > 2;
  const isTabsOnlySelect = chartTabs.length > 2;

  return (
    <div className={styles.controls}>
      <TabBarNotLinks tabs={chartTabs} activeId={tab || ""} onClick={setTab} isOnlySelectView={isTabsOnlySelect} />
      <MonthsYearsSlider
        date={selectedDate}
        onChange={handleDateChange}
        className={styles.datePicker}
        selectingMonthsCount={chartViewMode === CHART_VIEW_MODE.MONTHS ? yearSelectedMonths : undefined}
        onDirectlyClickLeftArrow={chartViewMode === CHART_VIEW_MODE.MONTHS ? yearViewLeftArrowHandler : undefined}
        onDirectlyClickRightArrow={chartViewMode === CHART_VIEW_MODE.MONTHS ? yearViewRightArrowHandler : undefined}
      />
      {module === "constructing" || module === "objects" ? (
        <SendMultiplePlansForApprove
          noMargin
          render={(count) => (
            <ContentWithCount
              count={count}
              isCountDisplayed={count > 0}
              containerClassName={styles.approvePlansContainer}
              absoluteContainerClassName={styles.approvePlansCount}
            >
              <ApproveIcon className={styles.controlIcon} />
            </ContentWithCount>
          )}
        />
      ) : null}
      <AddCheckpoint objectId={projectId} successCallback={markCheckpointDate}>
        <AddCheckpointIcon title={"Создание контрольной точки"} className={cn(styles.controlIcon, styles.checkIcon)} />
      </AddCheckpoint>
      <TabBarNotLinks
        tabs={chartViewModeTabs}
        activeId={chartViewMode}
        onClick={setChartViewMode}
        containerClassName={styles.viewTabsContainer}
        className={!isViewModeOnlySelect ? styles.viewTabs : undefined}
        isOnlySelectView={isViewModeOnlySelect}
      />
    </div>
  );
};

export default React.memo(ChartControls);
