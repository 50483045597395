import axios from "axios"
import { IPlanFactVolumeExpenditure, IPlanFactVolumeSection } from "./types"

export const planFactVolumeApi = {
    getSections: (objectId: string, startAt: string, endAt: string) => {
        return axios.get<IPlanFactVolumeSection[]>(`/building/${objectId}/planfact/plan_fact_volumes/sections/`, {
            params: {
                start_at: startAt,
                end_at: endAt
            }
        })
    },
    getExpenditures: (objectId: string, startAt: string, endAt: string, sectionId: string, daysCount: number) => {
        return axios.get<IPlanFactVolumeExpenditure>(`/building/${objectId}/planfact/plan_fact_volumes/expenditures/`, {
            params: {
                start_at: startAt,
                end_at: endAt,
                section_id: sectionId, 
                days: daysCount
            }
        })
    }
}