import moment from "moment";

import { ISummaryAction } from "./actions";

import { SCROLL_EVENT_TARGETS } from "./constants";
import { ISummaryInitialState } from "./types";

const initialState: ISummaryInitialState = {
  tableScrollPosition: {
    percent: 0,
    eventFrom: SCROLL_EVENT_TARGETS.TABLE,
  },
  tableViewWidthPercent: 0,
  tableHighlighting: {
    positive: false,
    negative: true,
  },
  year: moment().year(),
};

export const summaryScrollReducer = (state = initialState, action: ISummaryAction): ISummaryInitialState => {
  const { type, payload } = action;
  switch (type) {
    case "constructing/finance/summary_SET_TABLE_VIEW_WIDTH_PERCENT":
      return {
        ...state,
        tableViewWidthPercent: payload,
      };
    case "constructing/finance/summary_SET_TABLE_HIGHLIGHTING": {
      return { ...state, tableHighlighting: { ...state.tableHighlighting, ...payload } };
    }
    case "constructing/finance/summary_SET_TABLE_SCROLL_POSITION": {
      return { ...state, tableScrollPosition: { ...state.tableScrollPosition, ...payload } };
    }
    case "constructing/finance/summary_SET_YEAR": {
      return { ...state, year: payload };
    }

    default:
      return state;
  }
};
