import { getNormalizedAookPermit } from "components/pages/Journal/components/JournalDone/components/JournalExecTicket/components/Aook/utils";

import { AookPermitsEnum, IAook, ICreateAook } from "./types";

import { sanitizeObject } from "utils/formatters/sanitizers";

export const prepareDetailedAookForInitialForm = (aook?: IAook): IAook => {
  if (!aook) return {} as IAook;
  if (aook.permit === AookPermitsEnum.use) {
    return {
      ...aook,
      permit_partially: "",
      permit_condition: "",
    };
  }
  if (aook.permit === AookPermitsEnum.partially) {
    return {
      ...aook,
      permit_condition: "",
    };
  }
  if (aook.permit === AookPermitsEnum.on_condition) {
    return {
      ...aook,
      permit_partially: "",
    };
  }
  return {
    ...aook,
  };
};

export const prepareAookForEdit = (data: Partial<ICreateAook>): Partial<ICreateAook> => {
  if (!data.additional) {
    data.additional = '';
  }

  const sanitizedData = sanitizeObject(data, null);
  const normalizedPermit = getNormalizedAookPermit(data);
  const preparedData = { ...sanitizedData, ...normalizedPermit };
  delete preparedData.docs;
  if (preparedData.permit === AookPermitsEnum.use) {
    return {
      ...preparedData,
      permit_partially: undefined,
      permit_condition: undefined,
    };
  }
  if (preparedData.permit === AookPermitsEnum.partially) {
    return {
      ...preparedData,
      permit_condition: undefined,
    };
  }
  if (preparedData.permit === AookPermitsEnum.on_condition) {
    return {
      ...preparedData,
      permit_partially: undefined,
    };
  }
  return preparedData;
};
