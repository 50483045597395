import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { ITaskComment } from "components/pages/Tasks/model/types";

import { deleteTaskComment, editTaskComment } from "../../model_v2/thunks";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import Actions from "shared/ui/controls/Actions/Actions";
import InputBase from "shared/ui/inputs/InputBase";

import { useTasksModal } from "../../hooks/useTasksModal";

import styles from "./TaskComments.module.scss";

export const renderTextWithLinks = (text: string, onClose?: () => void) => {
  const host = window.location.host;
  return text.split(" ").map((el, i, arr) => {
    const isLink = (str: string) => str?.startsWith("http");
    if (isLink(el)) {
      if (el.includes(host)) {
        const startLocalLinkIndex = el.indexOf(host) + host.length;
        const localLink = el.substring(startLocalLinkIndex);
        return (
          <Link onClick={onClose} to={localLink}>
            {el}
          </Link>
        );
      }
      return (
        <a href={el} target="_blank">
          {el}
        </a>
      );
    } else {
      if (isLink(arr[i + 1])) {
        return ` ${el} `;
      }
      return ` ${el}`;
    }
  });
};

function TaskOneComment({
  date,
  creator_name,
  text,
  id,
  created_at,
  isPending,
}: Pick<ITaskComment, "creator_name" | "date" | "text" | "id" | "created_at"> & { isPending?: boolean }) {
  const { onClose } = useTasksModal();
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [commentText, setCommentText] = useState(() => text);

  const showActions = useMemo(() => {
    if (isPending) return false;
    if (isEditing) return false;
    return moment().diff(moment(created_at)) / 60_000 < 30;
  }, [created_at, isPending, isEditing, id]);

  useEffect(() => {
    setCommentText(text);
  }, [text]);

  return (
    <div className={styles.comment}>
      <div className={styles.head}>
        <div className={styles.name}>{creator_name}</div>
        <div className={styles.date}>
          {moment(date).format("DD.MM.YY")} {moment(date).format("HH:mm")}
        </div>
      </div>
      {!isEditing && <div className={styles.content}>{renderTextWithLinks(commentText, onClose)}</div>}
      {isEditing && (
        <div className={styles.edit}>
          <InputBase
            classNameInput={styles.editInput}
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            onEnter={() => {
              dispatch(editTaskComment(id, { text: commentText }));
              setIsEditing(false);
            }}
            icon={
              <Actions
                canSave
                canRemove
                onRemoveDirectly={() => {
                  setCommentText(text);
                  setIsEditing(false);
                }}
                onSave={() => {
                  dispatch(editTaskComment(id, { text: commentText }));
                  setIsEditing(false);
                }}
              />
            }
          />
        </div>
      )}
      {isPending && (
        <div className={styles.pending}>
          <Spinner isSmallGray isStatic />
        </div>
      )}
      {showActions && (
        <div className={styles.actions}>
          <Actions
            canEdit
            canRemove
            onEdit={() => {
              setIsEditing(true);
            }}
            onRemove={() => {
              dispatch(deleteTaskComment(id));
            }}
          />
        </div>
      )}
    </div>
  );
}

export default TaskOneComment;
