import { Dispatch } from "redux";

import { tasksV2Api } from "./api";
import { tasksV2Actions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const deleteTaskComment = (id: number) => (dispatch: Dispatch, getState: () => RootState) => {
  dispatch(tasksV2Actions.setPending({ key: String(id), status: true }));
  tasksV2Api
    .deleteComment(id)
    .then(() => {
      dispatch(tasksV2Actions.invalidateKey());
    })
    .catch(errorCatcher);
};

export const editTaskComment =
  (id: number, data: { text: string }) => (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(tasksV2Actions.setPending({ key: String(id), status: true }));
    tasksV2Api
      .patchComment(id, data)
      .catch(errorCatcher)
      .finally(() => {
        dispatch(tasksV2Actions.invalidateKey());
      });
  };
