const getAddedFiles = (prevFilesIds, nextFiles) => {
  const addedFiles = [];
  nextFiles.forEach((file) => {
    if (prevFilesIds[file.id]) return;
    addedFiles.push(file);
  });
  return addedFiles;
};

const getDeletedFiles = (nextFilesIds, prevFiles) => {
  const deletedFiles = [];
  prevFiles.forEach((file) => {
    if (nextFilesIds[file.id]) return;
    deletedFiles.push(file);
  });
  return deletedFiles;
};

export const getAddedAndDeletedFiles = (prevFiles, nextFiles) => {
  const prevFilesIds = {};
  const nextFilesIds = {};
  prevFiles.forEach((file) => prevFilesIds[file.id] = true);
  nextFiles.forEach((file) => nextFilesIds[file.id] = true);

  return { addedFiles: getAddedFiles(prevFilesIds, nextFiles), deletedFiles: getDeletedFiles(nextFilesIds, prevFiles) };
};