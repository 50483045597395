import React from "react";

import Icon from "../../../../../../_LEGACY/UI/_LEGACY_Icon/Icon";

import fullscreenEnterIcon from "images/icons/fullscreen-enter.svg";
import fullscreenExitIcon from "images/icons/fullscreen-exit.svg";

import styles from "./FullScreenBtn.module.scss";

export interface IFullScreenBtnProps {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
}

const FullScreenBtn: React.FC<IFullScreenBtnProps> = ({ isFullScreen, toggleFullScreen }) => {
  return (
    <div className={styles.btn} onClick={toggleFullScreen}>
      <Icon icon={isFullScreen ? fullscreenExitIcon : fullscreenEnterIcon} />
    </div>
  );
};

export default React.memo(FullScreenBtn);
