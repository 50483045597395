import { memo, useCallback } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import {
  activeSectionSelector,
  checkedItemsIdsSelector,
  subSectionsSelector,
} from "../../../../../../../model/selectors";
import { simpleHandlerActions } from "../../../../../../../model/slice";
import { ConfirmationSectionView } from "../../../../SectionsList/ui/Section/ConfirmationSectionView/ConfirmationSectionView";

import ISection from "../../../../../../../../../../../types/interfaces/Section";
import { EstimateItemsStatusesEnum } from "../../../../../../../../ProHandler/enums";

import { useQueryParams } from "../../../../../../../../../../../utils/hooks/useQueryParams";
import { useSectionActions } from "../../../../SectionsList/hooks/useSectionActions";

/**
 * Список разделов, которые можно подтвердить в стейте
 * локального сметного расчета (и при query параметре ?status=new)
 */
export const NewStatusLocaleStateSubSectionsList = memo(() => {
  const dispatch = useDispatch();
  const sections = useSelector(subSectionsSelector);
  const activeSection = useSelector(activeSectionSelector);
  const checkedItemsIds = useSelector(checkedItemsIdsSelector);

  const match = useRouteMatch();
  const history = useHistory();

  const stateFromQuery = useQueryParams("state");
  const tokenFromQuery = useQueryParams("token");

  const onSectionClick = useCallback(
    (subSection: ISection) => {
      if (activeSection) {
        batch(() => {
          dispatch(simpleHandlerActions.setActiveSubSection(subSection));
          dispatch(simpleHandlerActions.clearCheckedItems());
        });

        const url = `${match.url}/${activeSection.id}/${subSection.id}`;
        const query = new URLSearchParams();

        query.set("status", EstimateItemsStatusesEnum.NEW);
        if (stateFromQuery) query.set("state", stateFromQuery);
        if (tokenFromQuery) query.set("token", tokenFromQuery);

        history.push(`${url}?${query.toString()}`);
      }
    },
    [history, activeSection, stateFromQuery, tokenFromQuery]
  );

  const { getSectionActions } = useSectionActions();

  const onSectionCheck = useCallback((section: ISection) => {
    dispatch(simpleHandlerActions.toggleCheckedItem({ id: section.id, name: section.name }));
  }, []);

  if (!sections) return null;

  return (
    <>
      {sections.map((section) => (
        <ConfirmationSectionView
          key={section.id}
          section={section}
          actions={getSectionActions(section)}
          onClick={onSectionClick}
          onCheck={onSectionCheck}
          checked={checkedItemsIds.includes(section.id)}
          isCheckboxVisible={false}
        />
      ))}
    </>
  );
});
