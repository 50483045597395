import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAggregationData, IAggregationState } from "./types";


const initialState: IAggregationState = {
  aggregation: null,
  isLoading: true,
  invalidateKey: 0,
};


const aggregationsSlice = createSlice({
  name: "aggregations",
  initialState,
  reducers: {
    setAggregations: (state, { payload }: PayloadAction<IAggregationData>) => {
      state.aggregation = payload;
      state.isLoading = false;
    },
    invalidateKey: (state) => {
      state.invalidateKey += 1;
    }
  }
});

export const { actions: aggregationsActions } = aggregationsSlice;
export default aggregationsSlice.reducer;
