import cn from "classnames";
import React, { ReactNode } from "react";

import { HTMLDivProps } from "@blueprintjs/core";

import styles from "./index.module.scss";

interface IProps extends Omit<HTMLDivProps, "children" | "className"> {
  children: ReactNode;
  className?: string;
  blueFont?: boolean;
}

const BlueLabel: React.FC<IProps> = ({ children, className, blueFont, ...props }) => {
  if (!children) return null;

  return (
    <div className={cn(styles.container, className, { [styles.blueFont]: blueFont })} {...props}>
      {children}
    </div>
  );
};

export default React.memo(BlueLabel);
