import React from 'react'
import styles from "./SettingsTable.module.scss"

function SettingsTable({ children }) {
    return (
        <div className={styles.table}>
            {children}
        </div>
    )
}

export default SettingsTable

