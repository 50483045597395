import React from "react";

import useEscapeHandler from "../../../../utils/hooks/useEscapeHandler";

import styles from "./AlertPopup.module.scss";

function AlertPopup({ onClose, children, className, title, btns }) {
  useEscapeHandler(onClose);

  return (
    <div className={styles.container}>
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={`${styles.modal} ${className || ""}`}>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.close}
          onClick={onClose}
        >
          <path
            d="M0.84375 13.8478L6.48075 8.18876L0.84375 2.53073L3.26875 0.0966797L8.90575 5.75471L14.5437 0.0966797L16.9688 2.53073L11.3298 8.18876L16.9678 13.8478L14.5437 16.2808L8.90575 10.6228L3.26875 16.2808L0.84375 13.8478Z"
            fill="#707070"
          />
        </svg>
        {title && <h2 className={styles.title}>{title}</h2>}
        {children}
        {btns && <div className={styles.btns}>{btns}</div>}
      </div>
    </div>
  );
}

export default AlertPopup;
