import React from "react";
import { useCreateTasksList } from "../../hooks/useCreateTasksList";
import EditTasksLists from "../EditTasksLists/EditTasksLists";

export interface ICreateTasksListProps {
  onClose: () => void;
}

const CreateTasksList: React.FC<ICreateTasksListProps> = ({ onClose }) => {
  const { setNameHandler, createTasksListHandler, name } = useCreateTasksList(onClose);

  return (
    <EditTasksLists
      tasksCount={0}
      name={name}
      onNameChange={setNameHandler}
      onClose={onClose}
      onSave={createTasksListHandler}
      isNew
      listId={Math.random()}
    />
  );
};

export default React.memo(CreateTasksList);
