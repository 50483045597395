import axios from "axios";

import { IConstructionControlObject } from "./types";
import { IListResponseData } from "types/ListResponse";

export const constructionControlProjectsApi = {
  getObjects: (projectId: string) => {
    return axios.get<IListResponseData<IConstructionControlObject>>(`/construction_control/projects/`, {
      params: { project_id: projectId /* construction_control: true, page: 1, limit: 100  */ },
    });
  },
  accept: (objectId: number) => {
    return axios.post(`/construction_control/building/${objectId}/accept/`);
  },
  reject: (objectId: number) => {
    return axios.post(`/construction_control/building/${objectId}/decline/`);
  },
};
