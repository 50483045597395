import React from "react";

function PlusIcon({ color = "#fff", width = "21.562", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="21.122"
      viewBox="0 0 21.562 21.122"
      {...props}
    >
      <path
        className="a"
        fill={color}
        d="M9.344,19.685V11.969H1.409a1.408,1.408,0,1,1,0-2.816H9.344V1.437a1.437,1.437,0,1,1,2.875,0V9.153h7.935a1.408,1.408,0,1,1,0,2.816H12.218v7.716a1.437,1.437,0,0,1-2.875,0Z"
      />
    </svg>
  );
}

export default React.memo(PlusIcon);