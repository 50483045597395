const BASE_PREFIX = "approval";

const ADD_EMPLOYEES = `${BASE_PREFIX}/addEmployees`;
const DELETE_EMPLOYEE = `${BASE_PREFIX}/deleteEmployee`;
const GET_EMPLOYEES_REQUEST = `${BASE_PREFIX}/getEmployeesRequest`;
const GET_EMPLOYEES_SUCCESS = `${BASE_PREFIX}/getEmployeesSuccess`;
const RESET_TO_INITIAL = `${BASE_PREFIX}/resetToInitial`;

const APPROVAL_TYPES = {
  payment: "payment-approval",
  order: "approval"
};

const MESSAGES = {
  [APPROVAL_TYPES.order]: {
    APPROVED: "Заказ согласован",
    UNAPPROVED: "Согласование заказа отменено",
    ADDED: "Согласующий заказа успешно добавлен",
    DELETED: "Согласующий заказа успешно удален",
  },
  [APPROVAL_TYPES.payment]: {
    APPROVED: "Оплата согласована",
    UNAPPROVED: "Согласование оплаты отменено",
    ADDED: "Согласующий оплаты успешно добавлен",
    DELETED: "Согласующий оплаты успешно удален",
  }
};

export {
  ADD_EMPLOYEES,
  DELETE_EMPLOYEE,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  APPROVAL_TYPES,
  RESET_TO_INITIAL,
  MESSAGES
};