import { message } from "antd";
import Axios from "axios";
import cn from "classnames";
import { memoize, partial } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { commentsLoadingSelector, commentsSelector } from "../../../../../redux/modules/_TODO/comments/reducer";
import { createStockProductComment } from "../../../../../redux/modules/_TODO/comments/thunks/createStockProductComment";
import { getStockProductComments } from "../../../../../redux/modules/_TODO/comments/thunks/getStockProductComments";
import { fetchAddFile, fetchDeleteFile, fetchGetFiles } from "../../../../../redux/modules/_TODO/stockFiles/fetchFiles";
import {
  createUsing,
  loadStockProducts,
  stockDetailLoadingSelector,
  stockProductsSelector,
} from "../../../../../redux/modules/common/building/stocks";
import { loadWorkers, workersSelector } from "../../../../../redux/modules/common/building/workers";

import { ProductCommentsFormatter } from "../../../../../_LEGACY/UI/_LEGACY_Purchases_components/ProductCommentsFormatter/ProductCommentsFormatter";
import { TableOslaLocal } from "../../../../../_LEGACY/UI/_LEGACY_TableOslaCustom/TableOslaLocal";
import { TableHeader } from "../../../../../_LEGACY/UI/_LEGACY_TotoRowTable/TableHeader/TableHeader";
import Checkbox from "../../../../../_LEGACY/UI/__TODO/Checkbox/Checkbox";
import AddRelationToProduct from "../../../../UI/_TODO/AddRelationToProduct";
import ProductFilesModal from "../../../Requisition/components/ProductFilesModal/ProductFilesModal";
import ConfirmDeliveryToProduction from "./components/ConfirmDeliveryToProduction/ConfirmDeliveryToProduction";
import ProductMatching from "./components/ProductMatching/ProductMatching";
import Select from "components/UI/atoms/Select";
import { getStocksList, moveToStock } from "pages/Stocks/model/thunks";

import ButtonBase from "../../../../../shared/ui/controls/ButtonBase/index";
import InputBase, { INPUT_BASE_VARIANTS, VALUE_TYPES } from "../../../../../shared/ui/inputs/InputBase";
import EmptyPlaceholder from "../../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import { useTypedSelector } from "app/store/typedUseSelector";
import TableFilterTextInput from "shared/ui/inputs/TableFilterTextInput/TableFilterTextInput";

import {
  VIEW_MANUFACTURING_STOCKS_DELETE_PRODUCT_FILES,
  VIEW_MANUFACTURING_STOCKS_UTILIZATION,
} from "../../../../../constants/permissions/manufacturingPermissions";

import usePermission from "../../../../../hooks/usePermission";
import useWriteOffProducts from "./hooks/useWriteOffProducts";

import { stringifyArgs } from "../../../../../utils/helpers/stringifyArgs";
import { serializeFiles } from "../../../../../utils/serializeFiles";
import { getProductMatchingStatus } from "../../utils/getProductMatchingStatus";
import { defineWorkerId } from "./utils";

import warehouses from "../../../../../images/icons/navigation/warehouses.svg";

import styles from "./WriteOff.module.scss";

const getMatchingType = (type) => {
  if (type === "service") return "services";
  if (type === "out_of_estimate") return "out-of-estimate";
  return type;
};

const getProductTargetPath = (target) => {
  let path = "";
  if (target.section) path += `${target.section.name}`;
  if (target.subsection) path += `/${target.subsection.name}`;
  if (target.expenditure) path += `/${target.expenditure.name}`;
  return path;
};

const getProductsByIds = (products) => {
  const productsByIds = {};
  products.forEach((product) => (productsByIds[product.id] = product));
  return productsByIds;
};

const buildProductsToConfirmDelivery = (writeOffProducts, stockProducts, acceptedProducts) => {
  const stockProductsByIds = getProductsByIds(stockProducts);

  return Object.entries(writeOffProducts)
    .filter(([_, product]) => product?.isSelected)
    .map(([productId, product]) => ({
      id: productId,
      name: stockProductsByIds[productId]?.product_building?.name,
      isConfirm: !!acceptedProducts[productId],
      writeOffTarget: getProductTargetPath(product.target),
      matchingStatus: getProductMatchingStatus(stockProductsByIds[productId]),
    }));
};

const getLastProductTargetId = (target) => {
  if (target.expenditure) return target.expenditure.id;
  if (target.subsection) return target.subsection.id;
  if (target.section) return target.section.id;
};

export const WriteOff = ({ stockDetailId, objectId, objectDetail }) => {
  const dispatch = useDispatch();
  const stockProducts = useSelector(stockProductsSelector);
  const isLoading = useSelector(stockDetailLoadingSelector);
  const workers = useSelector(workersSelector);
  const filesData = useSelector((state) => state.stockFilesReducer.files);
  const [acceptedWriteOffsIds, setAcceptedWraiteOffIds] = React.useState({});
  const productsComments = useSelector(commentsSelector);
  const productsCommentsAreLoading = useSelector(commentsLoadingSelector);

  const writeOffProducts = useWriteOffProducts(stockProducts?.results);

  const [writeOffUserId, setWriteOffUserId] = useState(null);
  const [filters, setFilters] = useState({
    limit: 100,
    offset: 0,
    name: "",
    count_min: 0.0001,
  });
  const [sections, setSections] = useState([]);

  const [isOpenConfirmDeliveryToProduction, setIsOpenConfirmDeliveryToProduction] = useState(false);
  const [productWithChangedWriteOffTarget, setProductWithChangedWriteOffTarget] = useState(null);

  const haveUtilizationPermission = usePermission(VIEW_MANUFACTURING_STOCKS_UTILIZATION);
  const haveDeletingFilesPermission = usePermission(VIEW_MANUFACTURING_STOCKS_DELETE_PRODUCT_FILES);

  const workersAndEmployees = useMemo(() => {
    if (!workers || !objectDetail) return [];
    return [
      ...objectDetail.employees.map((el) => ({ ...el, id: `user_${el.id}` })),
      ...workers.results.map((el) => ({ ...el, id: `worker_${el.id}` })),
    ];
  }, [objectDetail, workers]);

  const materials = useMemo(() => {
    /* const result = [{ id: "production", name: "Производство" }];
    if (haveUtilizationPermission) result.push({ id: "recycling", name: "Утилизацию" }); */
    return [
      { id: "production", name: "Производство" },
      { id: "move", name: "Переместить" },
    ];
  }, [haveUtilizationPermission]);

  const deleteFilesHandler = (stockProductId) => (receivedFiles) => {
    dispatch(fetchDeleteFile(stockDetailId, stockProductId, receivedFiles.id));
  };

  const fetchSaveFiles = (stockProductId) => (receivedFiles) => {
    receivedFiles.forEach((file) => {
      dispatch(fetchAddFile(file, stockDetailId, stockProductId));
    });
  };

  const loadSection = async (objectId) => {
    Axios.get(`/building/${objectId}/estimate/sections/`).then((resp) => {
      let sections = resp.data.results;
      const reqs = sections.map((el) =>
        Axios.get(`/building/${objectId}/estimate/sections/`, { params: { parent: el.id } })
      );
      Promise.all(reqs).then((responses) => {
        responses.forEach((el) => {
          sections = [...sections, ...el.data.results];
        });
        setSections(sections);
      });
    });
  };

  const loadProducts = () => {
    dispatch(loadStockProducts(stockDetailId, filters, { limit: 100 }));
  };

  const stockDetailResults = useMemo(
    () =>
      stockProducts?.results
        ?.filter((item) => item?.product_building?.name.toLowerCase().indexOf(filters?.name?.toLowerCase()) !== -1)
        .filter((product) => !!Number(product.count)),
    [filters?.name, stockProducts?.results]
  );

  const changeFilters = (value, name) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const newWorkers =
    (workersAndEmployees?.length > 0 &&
      workersAndEmployees?.map((employee) => ({
        ...employee,
        name: `${employee.last_name} ${employee.first_name} ${employee.middle_name}`,
      }))) ||
    [];

  const partialOnChangeProductCount = (productId) => (e) => {
    writeOffProducts.changeCount(productId, e.target.value);
  };

  const memoizedPartialOnChangeProductCount = useMemo(
    () => memoize(partialOnChangeProductCount, stringifyArgs),
    [writeOffProducts.changeCount]
  );

  const countInputFormatter = (_, product) => {
    if (!writeOffProducts.items[product.id]) return null;
    return (
      <div>
        <InputBase
          variant={INPUT_BASE_VARIANTS.SECONDARY}
          classNameInput={styles.countInput}
          value={writeOffProducts.items[product.id].count}
          valueType={VALUE_TYPES.NUMBER}
          onChange={memoizedPartialOnChangeProductCount(product.id)}
        />
      </div>
    );
  };

  const getSections = () => {
    return sections.map((item) => ({ id: item.id, name: item.name }));
  };

  const confirmHandler = useCallback(() => {
    if (!stockProducts) return;
    let isValid = true;

    const stockProductsByIds = getProductsByIds(stockProducts.results);
    const selectedProducts = Object.entries(writeOffProducts.items).filter(
      ([_, expenditure]) => expenditure?.isSelected
    );

    const someProductsHaveNotCount = selectedProducts.some(([_, product]) => +product.count === 0 || !product.count);
    const someProductsHaveMoreCountThanInStock = selectedProducts.some(
      ([productId, product]) => product.count > +stockProductsByIds[productId].count
    );

    if (someProductsHaveNotCount) {
      message.warning("Укажите кол-во к выдаче");
      isValid = false;
    }
    if (!writeOffUserId) {
      message.warning("Выберите получателя");
      isValid = false;
    }
    if (someProductsHaveMoreCountThanInStock) {
      message.warning("Кол-во к выдаче больше, чем имеется на складе");
      isValid = false;
    }

    if (isValid) setIsOpenConfirmDeliveryToProduction(true);
  }, [writeOffProducts, writeOffUserId, stockProducts]);

  const closeConfirmDeliveryToProduction = useCallback(() => {
    setIsOpenConfirmDeliveryToProduction(false);
    setAcceptedWraiteOffIds({});
  }, []);

  const handleCreateProductComment = useCallback(
    (productId, comment) => compose(dispatch, createStockProductComment)(stockDetailId, productId, comment),
    [stockDetailId]
  );

  const partialHandleCreateProductComment = useMemo(
    () => memoize((productId) => partial(handleCreateProductComment, productId), stringifyArgs),
    [handleCreateProductComment]
  );

  const getProductsComments = useCallback(
    (productId) => compose(dispatch, getStockProductComments)(stockDetailId, productId),
    [stockDetailId]
  );

  const resetChangedProduct = useCallback(() => setProductWithChangedWriteOffTarget(null), []);

  const partialOnCheckProduct = (productId) => (e) => {
    if (e.target.checked) {
      writeOffProducts.select(productId);
    } else {
      writeOffProducts.unSelect(productId);
    }
  };

  const memoizedPartialOnCheckProduct = useMemo(
    () => memoize(partialOnCheckProduct, stringifyArgs),
    [writeOffProducts]
  );

  const partialOnChangeProductTarget = (productId) => (selectedSectionId) => {
    const sectionsByIds = {};
    const target = {};
    sections.forEach((section) => (sectionsByIds[section.id] = section));

    if (sectionsByIds[selectedSectionId].parent_id) {
      target.subsection = sectionsByIds[selectedSectionId];
      target.section = sectionsByIds[target.subsection.parent_id];
    } else {
      target.section = sectionsByIds[selectedSectionId];
    }

    writeOffProducts.changeTarget(productId, target);
  };

  const memoizedPartialOnChangeProductTarget = useMemo(
    () => memoize(partialOnChangeProductTarget, stringifyArgs),
    [writeOffProducts]
  );

  const expendituresToConfirmDeliveryToProduction = useMemo(() => {
    if (!stockProducts || !writeOffProducts.items) return [];
    return buildProductsToConfirmDelivery(writeOffProducts.items, stockProducts.results, acceptedWriteOffsIds);
  }, [stockProducts, writeOffProducts, acceptedWriteOffsIds]);

  const onChangeProductWriteOffTarget = useCallback(
    (productId) => {
      if (!stockProducts) return;

      const stockProductsByIds = getProductsByIds(stockProducts.results);
      setProductWithChangedWriteOffTarget(stockProductsByIds[productId]);
    },
    [stockProducts]
  );

  useEffect(() => {
    loadSection(objectId);
  }, [objectId]);

  useEffect(() => {
    if (stockDetailId) loadProducts();
    if (objectId) compose(dispatch, loadWorkers)(objectId, { limit: 1000 });
  }, [stockDetailId, objectId, filters]);

  const isEmpty = !isLoading && !Object.values(writeOffProducts.items || {}).length;

  const confirmExpenditure = (id, status) => {
    setAcceptedWraiteOffIds((prev) => ({
      ...prev,
      [id]: status,
    }));
  };

  const [matched, setMatched] = React.useState({});

  const rematchHandler = (id) => {
    setProductWithChangedWriteOffTarget(id);
  };

  const submitChangeProductWriteOffTarget = useCallback(
    (productId, estimateTarget) => {
      setMatched((prev) => ({
        ...prev,
        [productWithChangedWriteOffTarget.id]: true,
      }));
      writeOffProducts.changeTarget(productWithChangedWriteOffTarget.id, estimateTarget);
    },
    [productWithChangedWriteOffTarget?.id, writeOffProducts]
  );

  const [destination, setDestination] = useState(materials[0].id);

  const isMove = destination === "move";

  const { stocks } = useTypedSelector((state) => state.stocksProcurements);
  useEffect(() => {
    if (!isMove) return;
    dispatch(getStocksList());
  }, [isMove]);

  const handleWriteOffToProduction = useCallback(
    (confirmedProductsIds) => {
      const confirmedProducts = confirmedProductsIds.map((productId) => ({
        id: productId,
        count: writeOffProducts.items[productId].count,
        estimate_item_id: getLastProductTargetId(writeOffProducts.items[productId].target),
      }));

      if (confirmedProducts.some((product) => !product.estimate_item_id)) {
        message.error("Выберите назначение списания");
        return;
      }

      const definedUserOrWorker = defineWorkerId(writeOffUserId ?? "");

      if (isMove) {
        dispatch(
          moveToStock(stockDetailId, { to_stock_id: writeOffUserId, products: confirmedProducts }, loadProducts)
        );
      } else {
        compose(dispatch, createUsing)(
          stockDetailId,
          {
            ...definedUserOrWorker,
            products: confirmedProducts,
          },
          loadProducts
        );
      }

      closeConfirmDeliveryToProduction();
    },
    [
      workersAndEmployees,
      writeOffProducts,
      stockDetailId,
      closeConfirmDeliveryToProduction,
      writeOffUserId,
      loadProducts,
      isMove,
    ]
  );

  return (
    <div>
      <header className={styles.writeHeader}>
        <div className={`${styles.headerText} && ${styles.headerText1}`}>
          Выбрано позиций: {writeOffProducts.selectedCount}
        </div>
        <div className={`${styles.headerSelect} && ${styles.headerSelect1}`}>
          <div className={styles.headerText}>Передать в:</div>
          <Select
            options={materials}
            defaultValue={destination}
            onChange={(v) => setDestination(v)}
            className={styles.chosenSelect}
          />
        </div>
        <div className={`${styles.headerSelect} && ${styles.headerSelect2}`}>
          <div className={styles.headerText}>{isMove ? "Куда:" : "Кому:"}</div>
          <Select
            options={isMove ? stocks : newWorkers}
            onChange={setWriteOffUserId}
            placeholder={isMove ? "Выберите склад" : "Выберите сотрудника"}
            className={styles.getterSelect}
            value={writeOffUserId}
          />
        </div>
        <div>
          <ButtonBase onClick={confirmHandler} disabled={writeOffProducts.selectedCount <= 0} medium primary>
            Выдать: {writeOffProducts.selectedCount}
          </ButtonBase>
        </div>
      </header>
      <main>
        {isEmpty ? (
          <EmptyPlaceholder img={warehouses} />
        ) : (
          <TableOslaLocal
            data={stockDetailResults}
            pagination={false}
            rowClass={styles.tableRowClass}
            tableClass={cn(styles.table)}
            headerClass={styles.tableHeaderClass}
            isLoading={isLoading || !writeOffProducts.items}
            emptyPlaceholder={<EmptyPlaceholder img={warehouses} />}
          >
            <TableHeader
              formatter={(_, product) => (
                <Checkbox
                  checked={writeOffProducts.items[product.id]?.isSelected}
                  onChange={memoizedPartialOnCheckProduct(product.id)}
                />
              )}
              cellStyle={{ width: "3%", textAlign: "center" }}
            ></TableHeader>
            <TableHeader
              cellStyle={{ width: "3%" }}
              formatter={(cell) => <div className={styles.numberValue}>{cell}</div>}
              numberGeneration
            >
              <div className={styles.number}>
                <div className={styles.title}>№</div>
              </div>
            </TableHeader>
            <TableHeader
              cellStyle={{
                width: "32%",
              }}
              data={"product_building"}
              formatter={(cell) => cell.name}
            >
              <div className={styles.number}>
                <div className={styles.title}>Наименование</div>
                <TableFilterTextInput title="Наименование" setFilter={(value) => changeFilters(value, "name")} />
              </div>
            </TableHeader>
            <TableHeader data={"count"} cellStyle={{ textAlign: "center", width: "7%" }}>
              <div className={styles.number}>
                <div className={styles.title}> В наличии</div>
              </div>
            </TableHeader>
            <TableHeader
              cellStyle={{ textAlign: "center", width: "11%" }}
              formatter={(_, row) => row?.product_building?.measure}
            >
              <div className={styles.number}>
                <div className={cn(styles.title, styles.center)}> Ед.изм.</div>
              </div>
            </TableHeader>
            <TableHeader
              cellStyle={{
                width: "12%",
              }}
              formatter={countInputFormatter}
            >
              <div className={styles.number}>
                <div className={styles.title}>Выдать</div>
              </div>
            </TableHeader>
            <TableHeader
              cellStyle={{ width: "15%", textAlign: "center" }}
              formatter={(_, product) => (
                <Select
                  options={getSections()}
                  onChange={memoizedPartialOnChangeProductTarget(product.id)}
                  className={styles.classNameSelect}
                  disabled={product.product_building?.estimate_expenditure}
                  placeholder={product?.product_building?.estimate_expenditure?.section?.name || "Выберите"}
                />
              )}
            >
              Куда
            </TableHeader>
            <TableHeader
              data={"expenditure_id"}
              cellStyle={{ width: "8%" }}
              formatter={(cell, product) => (
                <ProductMatching product={product} onEditProductMatching={rematchHandler} matchedMap={matched} />
              )}
            />
            <TableHeader
              cellStyle={{ width: "6%" }}
              formatter={(_, product) => (
                <ProductCommentsFormatter
                  product={product}
                  productComments={productsComments[product.id]}
                  productsCommentsAreLoading={productsCommentsAreLoading}
                  createProductComment={partialHandleCreateProductComment(product.id)}
                  getProductComments={getProductsComments}
                />
              )}
            />
            <TableHeader
              data="count_files"
              cellStyle={{ width: "6%", textAlign: "center" }}
              formatter={(cell, row) => (
                <ProductFilesModal
                  filesCount={cell}
                  files={serializeFiles(filesData[row.id] || [])}
                  uploadFilesCallback={fetchSaveFiles(row.id)}
                  deleteFilesCallback={deleteFilesHandler(row.id)}
                  fetchFilesCallback={() => dispatch(fetchGetFiles(stockDetailId, row.id))}
                  stockId={stockDetailId}
                  productId={row.id}
                  permissions={{ addFiles: true, deleteFiles: haveDeletingFilesPermission }}
                  isFileViewer
                />
              )}
            ></TableHeader>
          </TableOslaLocal>
        )}

        {isOpenConfirmDeliveryToProduction && (
          <ConfirmDeliveryToProduction
            onSubmit={handleWriteOffToProduction}
            onCancel={closeConfirmDeliveryToProduction}
            expenditures={expendituresToConfirmDeliveryToProduction}
            onChangeProductWriteOffTarget={onChangeProductWriteOffTarget}
            onConfirm={confirmExpenditure}
          />
        )}
        {productWithChangedWriteOffTarget && (
          <AddRelationToProduct
            idAddRelation={productWithChangedWriteOffTarget}
            type={getMatchingType(productWithChangedWriteOffTarget?.product_building?.type)}
            handleClose={resetChangedProduct}
            objectId={objectId}
            onSubmit={submitChangeProductWriteOffTarget}
          />
        )}
      </main>
    </div>
  );
};
