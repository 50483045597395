import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { journalAcceptanceApprovalsSelector } from "redux/modules/common/building/journal/acceptance/selectors";
import { getAcceptanceApprovals } from "redux/modules/common/building/journal/acceptance/thunks";

import PopoverOverlay from "../../../../../../../../UI/_TODO/PopoverOverlay/PopoverOverlay";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import DropdownArrow from "../../../../../../../../../shared/ui/atoms/DropdownArrow/DropdownArrow";
import Avatar from "../../../../../../../../../shared/ui/dataDisplay/Avatar/Avatar";

import { ITicketApproval } from "../../../../../../../../../types/interfaces/Tickets";

import { stopEventPropagation } from "../../../../../../../../../utils/helpers/stopEventPropagation";

import blackApproveIcon from "../../../../../../../../../images/icons/blackCheckIcon";
import blueApproveIcon from "images/icons/blueCheckIcon";
import fillBlueApproveIcon from "images/icons/filledBlueCheckIcon";

import styles from "./ApprovingCell.module.scss";

interface IProps {
  isCurrentUserApprove: boolean;
  currentUserId: number;
  approve: () => void;
  ticketId: number;
  isGroup: boolean;
  totalApproves: number;
  confirmedApproves: number;
}

const ApprovingCell: React.FC<IProps> = ({
  isCurrentUserApprove,
  currentUserId,
  approve,
  ticketId,
  isGroup,
  totalApproves,
  confirmedApproves,
}) => {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const approvals = useSelector(journalAcceptanceApprovalsSelector)[ticketId];

  const loadApprovals = () => {
    dispatch(getAcceptanceApprovals(objectId, ticketId, isGroup ? "group" : "expenditure"));
  };

  const confirmedApprovalsCount = useMemo(() => {
    return approvals?.filter((approver) => approver.is_confirmed).length ?? 0;
  }, [approvals]);

  const handlePopover = useCallback((isOpen: boolean) => {
    setPopoverIsOpen(isOpen);
  }, []);

  const hasWaitingApprovals = confirmedApprovalsCount < approvals?.length!;
  const hasExistingApprovals =
    confirmedApprovalsCount !== 0 && !(confirmedApprovalsCount === 1 && isCurrentUserApprove);

  const popover = (
    <div className={styles.popup}>
      <header className={styles.popupHeader}>
        <h6 className={styles.popupTitle}>Согласования</h6>
        {isCurrentUserApprove && (
          <div className={styles.popupYourApprove}>
            Вы согласовали работу
            {fillBlueApproveIcon}
          </div>
        )}
      </header>
      {(hasExistingApprovals || hasWaitingApprovals) && (
        <div className={styles.popupBody}>
          {hasExistingApprovals && (
            <div>
              <span className={styles.approvalsTitle}>
                Согласовано: <span className={styles.approvalsCount}>{confirmedApprovalsCount}</span>
              </span>
              <div className={styles.approversList}>
                {approvals
                  ?.filter((approver) => approver.is_confirmed && approver.user.id !== currentUserId)
                  ?.map((approver) => (
                    <div className={styles.approver}>
                      <div>
                        <Avatar className={styles.avatar} person={approver.user.name} />
                        <span>{approver.user.name}</span>
                      </div>

                      {fillBlueApproveIcon}
                    </div>
                  ))}
              </div>
            </div>
          )}
          {hasWaitingApprovals && (
            <div>
              <span className={styles.approvalsTitle}>
                Ожидание согласования:{" "}
                <span className={styles.approvalsCount}>{approvals?.length! - confirmedApprovalsCount}</span>
              </span>
              <div className={styles.approversList}>
                {approvals
                  ?.filter((approver) => !approver.is_confirmed)
                  ?.map((approver) => (
                    <div className={styles.approver}>
                      <div>
                        <Avatar className={styles.avatar} person={approver.user.name} />
                        <span>{approver.user.name}</span>
                      </div>
                      {blueApproveIcon}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );

  const getIcon = () => {
    if (totalApproves === confirmedApproves) return fillBlueApproveIcon;
    if (!isCurrentUserApprove) return blackApproveIcon;
    if (isCurrentUserApprove) return blueApproveIcon;
  };

  const onApprove = useCallback(() => {
    if (isCurrentUserApprove) return;
    approve();
  }, [approve, isCurrentUserApprove]);

  return (
    <div className={styles.approving} onClick={stopEventPropagation}>
      <span className={cn({ [styles.userApproveButton]: !isCurrentUserApprove })} onClick={onApprove}>
        {getIcon()}
      </span>
      <PopoverOverlay
        isOpen={popoverIsOpen}
        content={popover}
        openType="click"
        popoverBorderColor="default"
        placement="bottom"
        onInteraction={handlePopover}
        onOpen={loadApprovals}
      >
        <div className={styles.countContainer}>
          <span className={styles.count}>{`${confirmedApproves}/${totalApproves}`}</span>
          <DropdownArrow isOpen={popoverIsOpen} withBackground />
        </div>
      </PopoverOverlay>
    </div>
  );
};

export default React.memo(ApprovingCell);
