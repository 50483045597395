import React from "react";

import { IPlanFactResponse } from "../../../../../../redux/modules/_TODO/constructing/finance/planFact/types";

import { useFinanceMeasure } from "../../../../../../features/financeMeasure";
import Amount from "shared/ui/dataDisplay/Amount/Amount";

import { formatNumberWithMeasure } from "../../../../../../utils/formatters/formatNumberWithMeasure";
import { getPlanFactSectionItemsCount } from "../../utils";

import styles from "./PlanFactSectionRow.module.scss";

export interface IPlanFactSectionRowProps {
  section: IPlanFactResponse["sections"][number] | IPlanFactResponse["sections"][number]["subsections"][number];
}

const PlanFactSectionRow: React.FC<IPlanFactSectionRowProps> = ({ section }) => {
  const { measure } = useFinanceMeasure();

  const plan = formatNumberWithMeasure(section.plan, measure);
  const fact = formatNumberWithMeasure(section.fact, measure);
  const budget = formatNumberWithMeasure(section.budget, measure);

  const itemsCount = getPlanFactSectionItemsCount(section);

  if (!itemsCount) return null;

  return (
    <div className={styles.container}>
      <div className={styles.name}>{section?.name}</div>
      {/* <div className={styles.valueName}>План</div>
      <div className={styles.value}>{plan}</div>
      <div className={styles.valueName}>Факт</div>
      <div className={styles.value}>{fact}</div> */}
      <div className={styles.amount}>
        <Amount title="План" value={plan} titleClassName={styles.amountTitle} />
      </div>
      <div className={styles.amount}>
        <Amount title="Факт" value={fact} titleClassName={styles.amountTitle} />
      </div>
      <div className={styles.amount}>
        <Amount title="Бюджет" value={budget} titleClassName={styles.amountTitle} />
      </div>
      <div className={styles.divider} />
      <div className={styles.objectCount}>Объектов {itemsCount}</div>

      {/* <div className={styles.valueName}>Бюджет</div>
      <div className={styles.balance}>{budget}</div> */}
    </div>
  );
};

export default React.memo(PlanFactSectionRow);
