import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSpecificationsSections,
  setSpecificationSubSections,
  specificationSectionsSelector,
} from "redux/modules/common/building/materials/specification";

import Materials from "./components/Materials/Materials";
import NewProductForm from "./components/NewProductForm/NewProductForm";
import { Header, Tab } from "_LEGACY/UI/__trash/Tabs_2";

import { Spinner } from "../../../../shared/ui/atoms/Spinner/Spinner";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import { MODAL_TABS_TITLES, PRODUCT_TYPES } from "./constants";

import styles from "./AddProductsModal.module.scss";

interface IProps {
  isOpen: boolean;
  objectId: string;
  closeAddProductsBlock: any;
  addProducts: any;
  permissions: any;
}

const AddProductsModal: React.FC<IProps> = ({ isOpen, objectId, closeAddProductsBlock, addProducts, permissions }) => {
  const dispatch = useDispatch();

  const specificationSections = useSelector(specificationSectionsSelector);

  const [tab, setTab] = useState(PRODUCT_TYPES.SPECIFICATION);
  const [products, setProducts] = useState<any[]>([]);

  const haveSomeAddPermission = permissions.viewAddAdditionalProducts || permissions.viewAddOutOfEstimateProducts;

  const handleAddProducts = (newProduct: any) => {
    const addedProducts = (newProduct?.length && newProduct) || products;

    if (addedProducts.some((product: any) => !product.required_count || +product.required_count <= 0)) {
      const errMsg =
        tab === PRODUCT_TYPES.SERVICE ? "Введите кол-во выбранных услуг" : "Введите кол-во выбранных материалов";
      message.error(errMsg);
      return;
    }

    addProducts(addedProducts);
    closeAddProductsBlock();
    setProducts([]);
  };

  const changeSetProducts = (val: any) => {
    setProducts([...products, val]);
  };

  useEffect(() => {
    if (!objectId) return;
    dispatch(setSpecificationSubSections({}));
    dispatch(
      getSpecificationsSections(objectId, tab === PRODUCT_TYPES.NEW_PRODUCT ? PRODUCT_TYPES.SPECIFICATION : tab)
    );
  }, [objectId, tab]);

  const handleChangeTab = useCallback((name: string) => {
    dispatch(setSpecificationSubSections({}));
    setTab(name);
  }, []);

  return (
    <SliderModal isOpen={isOpen} closeHandler={closeAddProductsBlock} className={styles.slider}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Header>
            <Tab
              name={PRODUCT_TYPES.SPECIFICATION}
              title={MODAL_TABS_TITLES.MATERIALS}
              setTab={handleChangeTab}
              activeTab={tab}
              className={styles.tab}
            />
            <Tab
              name={PRODUCT_TYPES.SERVICE}
              title={MODAL_TABS_TITLES.SERVICE}
              setTab={handleChangeTab}
              activeTab={tab}
              className={styles.tab}
            />
            {haveSomeAddPermission && (
              <Tab
                name={PRODUCT_TYPES.NEW_PRODUCT}
                title={MODAL_TABS_TITLES.NEW_PRODUCT}
                setTab={handleChangeTab}
                activeTab={tab}
                className={styles.tab}
              />
            )}
          </Header>
        </div>
        {specificationSections?.results ? (
          <div>
            {tab === PRODUCT_TYPES.SPECIFICATION && (
              <Materials
                sections={specificationSections?.results}
                handleAddProducts={handleAddProducts}
                products={products}
                setProducts={setProducts}
                type={PRODUCT_TYPES.SPECIFICATION}
                objectId={objectId}
              />
            )}
            {tab === PRODUCT_TYPES.SERVICE && (
              <Materials
                sections={specificationSections?.results}
                handleAddProducts={handleAddProducts}
                products={products}
                setProducts={setProducts}
                type={PRODUCT_TYPES.SERVICE}
                objectId={objectId}
              />
            )}
            {tab === PRODUCT_TYPES.NEW_PRODUCT && (
              <>
                <NewProductForm
                  sections={specificationSections?.results}
                  objectId={objectId}
                  handleAddProducts={handleAddProducts}
                  permissions={permissions}
                />
                <Materials
                  products={products}
                  setProducts={setProducts}
                  sections={specificationSections?.results}
                  handleAddProducts={handleAddProducts}
                  //changeSetProducts={changeSetProducts}
                  //permissions={permissions}
                  type={PRODUCT_TYPES.SPECIFICATION}
                  objectId={objectId}
                />
              </>
            )}
          </div>
        ) : (
          <Spinner isFixed />
        )}
      </div>
    </SliderModal>
  );
};

export default React.memo(AddProductsModal);
