import moment from "moment";
import React from "react";

import { Spinner } from "../../../../../../shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "../../../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import { eventsBtnSvg } from "../../../CalendarHeader/eventsBtnSvg";
import EventItem from "../EventItem/EventItem";

import { calendarEventStatusSwitcher } from "../../constants";

import styles from "../../index.module.scss";

const EventsList = ({ events, isEventsLoading }) => {
  const groupedEvents = React.useMemo(() => {
    return events.reduce((acc, event) => {
      const dateArr = acc.find((item) => item.date === event.date);
      if (dateArr) {
        dateArr.events.push(event);
      } else {
        acc.push({
          date: event.date,
          events: [event],
        });
      }
      return acc;
    }, []);
  }, [events]);

  if (isEventsLoading)
    return (
      <div className={styles.container}>
        <Spinner isFixed />
      </div>
    );

  if (events.length === 0)
    return (
      <EmptyPlaceholder svgJsx={eventsBtnSvg} text={"Нет событий"} className={styles.eventsListEmptyPlaceholder} />
    );

  return (
    <div className={styles.container}>
      {groupedEvents.map(
        (item) =>
          item.date && (
            <React.Fragment key={item.date}>
              <>
                <div className={styles.sliderDayTitle}>
                  События на <b>{moment(item.date).format("DD.MM.YY")}</b>
                </div>
                {item.events.map((event, i) => (
                  <EventItem event={event} key={event.date + i} statusSwitcher={calendarEventStatusSwitcher} />
                ))}
              </>
            </React.Fragment>
          )
      )}
    </div>
  );
};

export default React.memo(EventsList);
