import React from "react";

import styles from "./MonthsWork.module.scss";

const MonthsWork = ({
  firstHalfMonthCountDays,
  firstHalfMonthSumHours,
  secondHalfMonthCountDays,
  secondHalfMonthSumHours,
  monthCountDays,
  monthSumHours,
}) => {
  return (
    <div className={styles.monthBlocks}>
      <div className={styles.firstColumn}>
        <div className={styles.monthValueBlock}>
          <div className={styles.monthValue}>{firstHalfMonthCountDays}</div>
          <div className={styles.monthValue}>{firstHalfMonthSumHours}</div>
          <div className={styles.monthValue}>{secondHalfMonthCountDays}</div>
          <div className={styles.monthValue}>{secondHalfMonthSumHours}</div>
        </div>
      </div>
      <div className={styles.secondColumn}>
        <div className={styles.monthValueBlock}>
          <div className={styles.monthValue1}>{monthCountDays}</div>
          <div className={styles.monthValue1}>{monthSumHours}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MonthsWork);
