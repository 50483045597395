import React from "react";

import { IAosrDetail } from "redux/modules/common/building/journal/aosr/types";

export const useAosrMaterialsV2 = (
  initialMaterials: IAosrDetail["materials"],
  selectedMaterials?: number[],
  isInExistingAct?: boolean
) => {
  const [materials, setMaterials] = React.useState(initialMaterials);

  React.useEffect(() => {
    if (!isInExistingAct) {
      const initials = !!selectedMaterials
        ? initialMaterials.filter((el) => selectedMaterials?.includes(el.id))
        : initialMaterials;
      setMaterials(initials);
    } else {
      const initials = !!selectedMaterials
        ? initialMaterials.map((el) => {
            if (selectedMaterials?.includes(el.id)) {
              return el;
            } else {
              return { ...el, was_deleted: true };
            }
          })
        : initialMaterials;
      setMaterials(initials);
    }
  }, [initialMaterials, selectedMaterials, isInExistingAct]);

  const removeMaterial = React.useCallback((id: number) => {
    setMaterials((prev) =>
      prev.map((el) => {
        if (el.material_id === id) {
          return { ...el, was_deleted: true };
        } else {
          return el;
        }
      })
    );
  }, []);

  const addMaterial = React.useCallback((material: IAosrDetail["materials"][0]) => {
    setMaterials((prev) => {
      if (prev.findIndex((el) => el.material_id === material.material_id) === -1) {
        return [...prev, material];
      }
      return prev.map((el) => {
        if (el.material_id === material.material_id) {
          return { ...el, was_deleted: false };
        } else {
          return el;
        }
      });
    });
  }, []);

  return { materials, addMaterial, removeMaterial };
};
