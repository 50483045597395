import React, { useState } from "react";
import { useDispatch } from "react-redux";

import JournalSections from "./components/JournalSections/JournalSections";
import JournalTickets from "./components/JournalTickets/JournalTickets";
import ProductIntervalCard from "./components/ProductIntervalCard";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { useFoldersNavigation } from "hooks/navigation/useFoldersNavigation";

import styles from "./JournalDelivery.module.scss";

interface Iprops {
  isOpen: boolean;
  year: number;
  month: number;
  isShowAddPlan: boolean;
  setIsShowAddPlan: any;
  onClickAddPlan: never;
}

const JournalDelivery: React.FC<Iprops> = ({
  isOpen,
  year,
  month,
  isShowAddPlan,
  setIsShowAddPlan,
  onClickAddPlan,
}) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const { currentFolderId, openFolderHandler, backHandler } = useFoldersNavigation("sectionId");
  const {
    currentFolderId: currentContractorId,
    openFolderHandler: openContractorHandler,
    backHandler: backFromContractor,
  } = useFoldersNavigation("contractorId");
  const [activeContractorName, setActiveContractorName] = useState("");

  const clickOnContractor = (id: number, name: string) => {
    openContractorHandler(id);
    setActiveContractorName(name);
  };

  if (!isOpen) return null;

  return (
    <div className={styles.container}>
      {!currentFolderId && (
        <JournalSections
          month={month}
          year={year}
          onSectionClick={openFolderHandler}
          onClickAddPlan={onClickAddPlan}
          onContractorsClick={clickOnContractor}
          currentContractorId={currentContractorId}
          onBackFromSection={backFromContractor}
          sectionId={currentFolderId}
          activeContractorName={activeContractorName}
        />
      )}
      {currentFolderId && (
        <JournalTickets
          month={month}
          year={year}
          onBack={backHandler}
          sectionId={currentFolderId}
          contractorId={currentContractorId}
          activeContractorName={activeContractorName}
        />
      )}
      <ProductIntervalCard isOpen={isShowAddPlan} handleClose={() => setIsShowAddPlan(false)} objectId={objectId} />
    </div>
  );
};

export default JournalDelivery;
