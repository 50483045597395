import { message } from "antd";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";

import { IupdateTask } from "components/pages/Tasks/model/types";

import ModalEditTaskExtraFields from "./ModalEditTaskExtraFields";
import ModalEditTaskMainFields from "./ModalEditTaskMainFields";
import { useTypedSelector } from "app/store/typedUseSelector";
import { finalFormPersister } from "shared/lib/formUtils/finalFormPersist";

import { ADD_TASK_INITIALS, EXTRA_MODAL_TAB, MAIN_MODAL_TAB } from "../../../../constants";

import { useTasksModal } from "../../../../hooks/useTasksModal";
import { useUpdateTask } from "../../../../hooks/useUpdateTask";

import styles from "../ModalAddTask/ModalAddTask.module.scss";

export interface IModalEditTaskProps {
  formRef: React.RefObject<HTMLFormElement>;
  activeTab: string;
  handleCloseModal: () => void;
  onSave: () => void;
}

const ModalEditTask: React.FC<IModalEditTaskProps> = ({ formRef, activeTab, handleCloseModal, onSave }) => {
  const { placementType, listType, list_id, taskId, submission } = useTasksModal();
  const {
    onSubmit,
    building,
    section_id,
    setSectionId,
    executor,
    setExecutor,
    viewers,
    setViewers,
    certainTask,
    isTaskStatusEditable,
  } = useUpdateTask({ taskId, placementType, listType, list_id, onSuccess: onSave });

  const [requestClose, setRequestClose] = useState<boolean>(false);

  useEffect(() => {
    if (!submission?.isSubmitted && submission?.taskId !== -1 && requestClose) handleCloseModal();
  }, [submission, requestClose]);

  const invalidateKey = useTypedSelector((state) => state.tasks.invalidateKey);

  const { persistDecorator, clear } = finalFormPersister<IupdateTask>({
    name: `form_editTask_${taskId}`,
  });

  return (
    <Form
      key={invalidateKey}
      onSubmit={onSubmit}
      decorators={[persistDecorator]}
      initialValues={(certainTask as unknown as IupdateTask) || (ADD_TASK_INITIALS as unknown as IupdateTask)}
      render={({ form, handleSubmit }) => (
        <form
          className={styles.form}
          ref={formRef}
          onSubmit={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (form.getState().valid) {
              clear();
              await handleSubmit();
              form.restart();
              setRequestClose(true);
            } else {
              message.warn("Заполните обязательные поля формы");
            }
          }}
        >
          <ModalEditTaskMainFields
            building={building}
            section_id={section_id}
            setSectionId={setSectionId}
            isVisible={activeTab === MAIN_MODAL_TAB}
            executor={executor}
            setExecutor={setExecutor}
            viewers={viewers}
            setViewers={setViewers}
            isTaskStatusEditable={isTaskStatusEditable}
          />
          {/* @ts-ignore */}
          <ModalEditTaskExtraFields certainTask={certainTask} isVisible={activeTab === EXTRA_MODAL_TAB} />
        </form>
      )}
    />
  );
};

export default ModalEditTask;
