import { useEffect, useState } from "react";

import {
  deleteIntervalFile,
  getIntervalData,
  getIntervalFiles,
  getIntervalsList,
  postIntervalFile,
} from "redux/modules/common/building/processApi";
import {
  IIntervalListItemResponse,
  IPlanIntervalResponse,
  IntervalModulesType,
} from "redux/modules/common/building/processTypes";

import { simpleResourcesAPI } from "../../../../../../features/simpleResources/lib/api";

import { errorCatcher } from "utils/helpers/errorCatcher";

interface IProps {
  isOpen: boolean;
  buildingId: number;
  expenditureId: number;
  dateStart: string;
  dateEnd: string;
  defaultOpenedIntervalId?: string | null;
}

const activeModule = "fact-intervals";

export const useFactModalData = ({
  isOpen,
  buildingId,
  expenditureId,
  dateEnd,
  dateStart,
  defaultOpenedIntervalId = null,
}: IProps) => {
  const [list, setList] = useState<IIntervalListItemResponse[] | null>(null);
  const [intervalId, setIntervalId] = useState<null | number>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IPlanIntervalResponse | null>(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      getIntervalsList({
        buildingId,
        expenditureId,
        dateEnd,
        dateStart,
        activeModule,
      }).then((resp) => {
        setList(resp.data);
        setIsLoading(false);
      });
    }
  }, [isOpen, expenditureId, buildingId]);

  const intervalClickHandler = (id: number) => {
    setIsLoading(true);
    setIntervalId(id);
    getIntervalFiles({
      buildingId,
      expenditureId,
      intervalId: id,
      activeModule,
    }).then((resp) => {
      setFiles(resp.data.results);
    });
    intervalDataFetcher(id);
  };

  useEffect(() => {
    if (list && list.length === 1) {
      setIsLoading(true);
      setIntervalId(list[0].id);
      getIntervalFiles({
        buildingId,
        expenditureId,
        intervalId: list[0].id,
        activeModule,
      }).then((resp) => {
        setFiles(resp.data.results);
      });
      intervalDataFetcher(list[0].id);
    }

    if (list && list.length > 1 && defaultOpenedIntervalId) {
      setIsLoading(true);
      setIntervalId(+defaultOpenedIntervalId);
      getIntervalFiles({ buildingId, expenditureId, intervalId: +defaultOpenedIntervalId, activeModule }).then(
        (resp) => {
          setFiles(resp.data.results);
        }
      );
      intervalDataFetcher(+defaultOpenedIntervalId);
    }
  }, [list, defaultOpenedIntervalId]);

  const intervalDataFetcher = (intervalId: number) => {
    getIntervalData({
      buildingId,
      expenditureId,
      intervalId,
      activeModule,
    })
      .then(async (resp) => {
        const simpleResourcesResponse = await simpleResourcesAPI.getAll({
          buildingId,
          fact_work_id: resp.data.id,
        });
        /* @ts-ignore */
        setData({ ...resp.data, simpleResources: simpleResourcesResponse?.results ?? [] });
      })
      .catch(errorCatcher)
      .finally(() => setIsLoading(false));
  };

  const clearIntervalDataHandler = () => {
    setData(null);
    setIntervalId(null);
    setList(null);
    setFiles([]);
  };

  const backToIntervalsListHandler = () => {
    setData(null);
    setIntervalId(null);
  };

  const fileUploadHandler = (file: File) => {
    postIntervalFile({ buildingId, expenditureId, activeModule, intervalId: data?.id!, file }).then((resp) => {
      getIntervalFiles({ buildingId, expenditureId, intervalId: data?.id!, activeModule }).then((resp) => {
        setFiles(resp.data.results);
      });
    });
  };

  const fileRemoveHandler = (fileId: number) => {
    deleteIntervalFile({ buildingId, expenditureId, activeModule, intervalId: data?.id!, fileId }).then((resp) => {
      getIntervalFiles({ buildingId, expenditureId, intervalId: data?.id!, activeModule }).then((resp) => {
        setFiles(resp.data.results);
      });
    });
  };

  return {
    fileRemoveHandler,
    fileUploadHandler,
    clearIntervalDataHandler,
    intervalDataFetcher,
    intervalClickHandler,
    backToIntervalsListHandler,
    list,
    intervalId,
    isLoading,
    data,
    files,
  };
};
