import React from "react";

const GroupIcon = ({ color }: { color: string }) => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.8385 2.48934C10.0041 2.41305 10.1931 2.53405 10.1931 2.71641V5.64053C10.6203 5.42743 11.1514 5.16198 11.6931 4.89098V2.71641C11.6931 1.43986 10.3704 0.592863 9.21096 1.12692L3.70578 3.66264C2.02177 4.4383 0.942993 6.12291 0.942993 7.97698V17.2833C0.942993 18.5461 2.23976 19.3932 3.39616 18.8858L6.98679 17.2714C6.61433 16.9283 6.28403 16.4967 6.02994 16.057L2.79345 17.5122C2.62825 17.5847 2.44299 17.4637 2.44299 17.2833V7.97698C2.44299 6.70841 3.18111 5.55578 4.33332 5.02506L9.8385 2.48934ZM15.057 6.64854C15.057 5.49888 13.8493 4.74932 12.8191 5.25954L9.7563 6.77634C8.47882 7.409 7.67053 8.71127 7.67053 10.1368V15.0026C7.67053 16.1523 8.87817 16.9018 9.90842 16.3916L12.9712 14.8748C14.2487 14.2422 15.057 12.9399 15.057 11.5143V6.64854Z"
        fill={color}
      />
    </svg>
  );
};

export default GroupIcon;
