import {
  ADD_EMPLOYEES,
  DELETE_EMPLOYEE,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  RESET_TO_INITIAL
} from "./constants";

const addEmployeesAction = (payload, approvalType) => ({ type: ADD_EMPLOYEES, payload, approvalType });
const deleteEmployeeAction = (payload, approvalType) => ({ type: DELETE_EMPLOYEE, payload, approvalType });
const getEmployeesRequestAction = (approvalType) => ({ type: GET_EMPLOYEES_REQUEST, approvalType });
const getEmployeesSuccessAction = (payload, approvalType) => ({ type: GET_EMPLOYEES_SUCCESS, payload, approvalType });
const resetToInitialAction = () => ({ type: RESET_TO_INITIAL });

export {
  addEmployeesAction,
  deleteEmployeeAction,
  getEmployeesSuccessAction,
  getEmployeesRequestAction,
  resetToInitialAction
};
