import cn from "classnames";
import React, { FC } from "react";

import { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import styles from "./PlanfactVolumeExpenditure.module.scss";

interface IProps {
  plan: any;
  fact: any;
}

const PlanfactVolumeDayCell: FC<IProps> = ({ plan, fact }) => {
  const commonLength = String(plan).length + String(fact).length;

  const isOneLine = commonLength < 7;

  if (isOneLine) {
    return (
      <TableReusableCell isFont13={false} className={cn(styles.bordered, styles.date, styles.dayOneLine)}>
        {plan}&nbsp;
        <span className={styles.blue}>{fact}</span>
      </TableReusableCell>
    );
  }

  return (
    <TableReusableCell isFont13={false} className={cn(styles.bordered, styles.date, styles.dayTwoLines)}>
      {plan}
      <br />/<br />
      <span className={styles.blue}>{fact}</span>
    </TableReusableCell>
  );
};

export default PlanfactVolumeDayCell;
