import cn from "classnames";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ksListInvalidateKeySelector,
  ksSublistSelector,
} from "redux/modules/common/building/documents/ksList/selectors";
import { getKsSublist, sendFileToKsPay, signKs3 } from "redux/modules/common/building/documents/ksList/thunks";

import ButtonBase from "shared/ui/controls/ButtonBase";
import DownloadButton from "shared/ui/controls/DownloadButton/DownloadButton";
import FileRow from "shared/ui/dataDisplay/FileRow/FileRow";
import Checkbox from "shared/ui/inputs/Checkbox/Checkbox";
import Modal from "shared/ui/modal/Modal";
import FileViewer from "widgets/FileViewer/FileViewer";

import { IFile } from "types/interfaces/Files";

import { splitThousands } from "utils/formatters/splitThousands";
import downloadFile from "utils/helpers/download";

import PaperClipIcon from "images/icons/PaperClipIcon";

import styles from "./UploadKsModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  ks3Id: number;
}

const UploadKsModal: React.FC<IProps> = ({ isOpen, onClose, ks3Id }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const downloadRef = React.useRef<HTMLAnchorElement>(null);
  const dispatch = useDispatch();
  const act = useSelector(ksSublistSelector)[ks3Id];
  const invalidateKey = useSelector(ksListInvalidateKeySelector);

  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(() => {
    if (!isOpen) return;
    dispatch(getKsSublist(ks3Id));
  }, [ks3Id, invalidateKey, isOpen]);

  const innerActsList = `КС-3 №${act?.title}, ${act?.ks2_acts?.map((el) => `КС-2 №${el.title}`).join(", ")}`;

  const attachFile = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.[0]) return;
      dispatch(sendFileToKsPay(ks3Id, e.target.files[0]));
    },
    [ks3Id]
  );

  const approveKs = React.useCallback(() => {
    setIsChecked(true);
    dispatch(signKs3(ks3Id));
  }, [ks3Id]);

  React.useEffect(() => {
    if (act?.is_signed) {
      setIsChecked(true);
    }
  }, [act]);

  const [isFileViewer, setIsFileViewer] = useState(false);

  if (!act) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Подтвердите сдачу документов" className={styles.modal}>
      <div className={styles.content}>
        <p className={styles.subtitle}>Прикрепите документы, подписанные заказчиком и отметьте комплект подписанным</p>
        <div className={styles.row}>
          <div className={styles.pair}>
            <strong>Отчетный период:</strong>
            {`${moment(act.start_at).format("DD.MM.YYYY")} - ${moment(act.end_at).format("DD.MM.YYYY")}`}
          </div>
          <div className={styles.pair}>
            <strong>Сумма:</strong>
            {splitThousands(act.amount)}
          </div>
        </div>
        <div className={styles.expenditures}>
          <strong>Документы в комплекте:</strong>
          {innerActsList}
        </div>
        {!act.file && (
          <ButtonBase secondary onClick={() => inputRef.current?.click()} className={styles.upload}>
            <PaperClipIcon />
            Прикрепить файл
          </ButtonBase>
        )}
        {act.file && (
          <FileRow
            download={() => setIsFileViewer(true)}
            file={{ name: act?.filename } as IFile}
            className={styles.fileRow}
          />
        )}
        {act.file && <a className={styles.fileInput} download href={act.file} ref={downloadRef} />}
        <input ref={inputRef} type="file" className={styles.fileInput} onChange={attachFile} />
        <div className={cn(styles.approveRow, { [styles.disabled]: !act.file })} onClick={approveKs}>
          <Checkbox checked={isChecked} onCheck={approveKs} />
          <span>Отметить подписанным</span>
        </div>
      </div>
      <FileViewer
        files={[{ name: act?.filename!, link: act?.file! } as any]}
        isOpen={isFileViewer}
        onClose={() => setIsFileViewer(false)}
      />
    </Modal>
  );
};

export default React.memo(UploadKsModal);
