const getDeepChildPermissions = (permission) => {
  let child = { [permission.name]: [] };
  if (!permission.children) return child;

  permission.children.forEach((children) => {
    child[permission.name].push(children.name);

    const deepChildPermissions = getDeepChildPermissions(children);
    child[permission.name].push(...Object.values(deepChildPermissions).flat())
    child = { ...deepChildPermissions, ...child };
  });

  return child;
};

export const buildRelatesPermissions = (permissions) => {
  let relatedPermissions = {};

  permissions.forEach((permission) => (
    relatedPermissions = { ...relatedPermissions, ...getDeepChildPermissions(permission) }
  ));

  return relatedPermissions;
};
