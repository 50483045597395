import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IConstrControlAcc } from "./types";
import { IIdAndName } from "types/idAndName";

interface State {
  invalidationKey: number;
  isLoading: Record<string, boolean>;
  constrControlAccounts: IIdAndName[];
}

export const initialState: State = {
  invalidationKey: 0,
  isLoading: {},
  constrControlAccounts: [],
};

const settingsProjectSlice = createSlice({
  name: "settingsProject",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string | number }>) => {
      const { status, key } = action.payload;
      state.isLoading[key] = status;
    },
    setConstrContrAccounts: (state, action: PayloadAction<IConstrControlAcc[]>) => {
      state.constrControlAccounts = action.payload.map((el) => ({ id: el.account_id, name: el.name }));
    },
  },
});

export const settingsProjectActions = settingsProjectSlice.actions;

export const settingsProjectReducer = settingsProjectSlice.reducer;
