import moment from "moment";
import React, { useCallback, useEffect } from "react";

import Week from "./components/Week/Week";

import WeeksSlider from "shared/ui/inputs/WeeksSlider/WeeksSlider";

import { getDisplayWeeksCountForWindowWidth } from "./utils/getDisplayWeeksCountForWindowWidth";

import styles from "./Turnout.module.scss";

const Turnout = ({
  buildingId,
  activeDate,
  setActiveDate,
  changeFilters,
  loadMore,
  workersPaginationLimit,
  startOfWeekDate,
}) => {
  const activeWeekNumber = activeDate.week();
  const year = activeDate.year();

  const setActiveWeekNumber = useCallback(
    (updatedActiveWeekNumber) =>
      setActiveDate((prevState) => {
        const JSONChangedDate = prevState.week(updatedActiveWeekNumber).toJSON();
        return moment(JSONChangedDate);
      }),
    [setActiveDate]
  );

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <WeeksSlider
          className={styles.slider}
          year={year}
          activeWeekNumber={activeWeekNumber}
          setActiveWeekNumber={setActiveWeekNumber}
          displayWeeksCount={getDisplayWeeksCountForWindowWidth(window.innerWidth)}
        />
      </header>
      <Week
        buildingId={buildingId}
        startOfWeekDate={startOfWeekDate}
        changeFilters={changeFilters}
        loadMore={loadMore}
      />
    </div>
  );
};

export default React.memo(Turnout);
