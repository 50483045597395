import cn from "classnames";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import HelpModal from "../ui/HelpModal/HelpModal";
import { confirmedIcon } from "./assets/confirmedIcon";
import { processIcon } from "./assets/processIcon";
import { learningActions } from "./model/learningSlice";
import { getLearning, getLearningSublists } from "./model/thunks";
import LearningContent from "./ui/LearningContent/LearningContent";
import LearningSidebar from "./ui/LearningSidebar/LearningSidebar";
import LearningVideoModal from "./ui/LearningVIdeoModal/LearningVideoModal";
import ErrorBoundary from "app/ErrorBoundary";
import { useTypedSelector } from "app/store/typedUseSelector";
import ReactMarkdown from "markdown-to-jsx";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBack from "shared/ui/controls/ButtonBack/ButtonBack";
import ProgressBar from "shared/ui/dataDisplay/ProgressBar/ProgressBar";

import { ILearningItem } from "./model/types";

import styles from "./Learning.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  openedKey: number;
  onOpen: () => void;
}

const Learning: React.FC<IProps> = ({ isOpen, onClose, openedKey, onOpen }) => {
  const dispatch = useDispatch();
  const data = useTypedSelector((state) => state.learning.data);
  const isLoading = useTypedSelector((state) => state.learning.isLoading);

  const confirmedPercents = useMemo(() => {
    if (!data?.count) return 0;
    const percent = data.count / 100;

    const completedCount = data?.results?.filter((el) => el.is_confirmed)?.length ?? 0;
    const res = Math.round(completedCount / percent);

    if (res > 100) return 100;
    return res;
  }, [data]);

  const [activeLesson, setActiveLesson] = useState<null | ILearningItem>(null);

  useEffect(() => {
    if (!data) return;
    setActiveLesson((prev) => {
      if (!!prev) {
        return prev;
      } else {
        return data.results?.filter((el) => !el.confirmed_at)?.[0] ?? data.results?.[0];
      }
    });
  }, [data]);

  useEffect(() => {
    if (isOpen) {
      dispatch(getLearning());
      dispatch(getLearningSublists());
    }
  }, [isOpen]);

  const onExpand = () => {
    dispatch(getLearning());
    dispatch(getLearningSublists());
  };

  const isVideo = useTypedSelector((state) => state.learning.isVideoModal);

  const setIsVideo = (status: boolean) => {
    dispatch(learningActions.setIsVideoModal(status));
  };

  const lists = useTypedSelector((state) => state.learning.lists);

  return (
    <>
      <HelpModal openedKey={openedKey} onExpand={onExpand} isOpen={isOpen} onClose={onClose} className={styles.modal}>
        <div className={styles.grid}>
          <LearningSidebar
            setActiveLesson={setActiveLesson}
            activeLesson={activeLesson!}
            confirmedPercents={confirmedPercents}
            items={data?.results}
            lists={lists}
          />
          <LearningContent
            item={activeLesson!}
            onOpenVideo={() => {
              onClose();
              setIsVideo(true);
            }}
          />
        </div>
      </HelpModal>
      <LearningVideoModal
        onOpenLearning={() => {
          onOpen();
          setIsVideo(false);
        }}
        isOpen={isVideo}
        onClose={() => setIsVideo(false)}
        item={activeLesson!}
      />
    </>
  );

  /* return (
    <HelpModal
      openedKey={openedKey}
      onExpand={onExpand}
      isOpen={isOpen}
      onClose={onClose}
      navHeader={
        <>
          <div>Обучение Osla</div>
          <ProgressBar completed={confirmedPercents} direction="row-reverse" containerClassName={styles.progress} />
        </>
      }
      navContent={
        <>
          {data?.results.map((el, i) => (
            <div
              key={el.key}
              className={cn(styles.item, {
                [styles.active]: el.key === activeLesson?.key,
                [styles.confirmed]: el.is_confirmed,
                [styles.disabled]: el.isDisabled,
              })}
              onClick={() => {
                setActiveLesson(data.results.find((item) => item.key === el.key) ?? null);
              }}
            >
              {el.menuTitle}
              {el.is_confirmed && confirmedIcon}
              {!el.is_confirmed && !el.isDisabled && processIcon}
            </div>
          ))}
        </>
      }
      contentHeader={
        <>
          <span className={styles.title}>
            {activeLesson?.menuTitle}&nbsp;<span>/</span>&nbsp;
            {activeLesson?.edu_data.title}
          </span>
          <div className={styles.subtitle}></div>
        </>
      }
      content={
        <div className={styles.content}>
          {isLoading && <Spinner />}
          {activeLesson?.edu_data.videos?.map((el, i) => (
            <div key={i} className={styles.video}>
              <video src={el.url} controls muted={false} controlsList="nodownload" />
            </div>
          ))}
          <ErrorBoundary>
            <ReactMarkdown>{activeLesson?.edu_data.description as string}</ReactMarkdown>
          </ErrorBoundary>
          <div className={styles.bottom}>
            <div className={cn(styles.prev, { [styles.btnDisabled]: prevLesson?.isDisabled })}>
              {prevLesson && <ButtonBack onClick={() => setActiveLesson(prevLesson)} />}
              {prevLesson?.menuTitle}
            </div>
            <div className={cn(styles.next, { [styles.btnDisabled]: nextLesson?.isDisabled })}>
              {nextLesson && <ButtonBack onClick={() => setActiveLesson(nextLesson)} />}
              {nextLesson?.menuTitle}
            </div>
          </div>
        </div>
      }
    ></HelpModal>
  ); */
};

export default Learning;
