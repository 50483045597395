import cn from "classnames";
import React from "react";

import useEscapeHandler from "../../../../../../../../utils/hooks/useEscapeHandler";

import styles from "./MiniModal.module.scss";

export const MiniModal = ({ visible, setVisible, children, ...props }) => {
  useEscapeHandler(() => setVisible(false));
  return (
    <div {...props} className={cn(styles.miniModal, { [styles.active]: visible })} onClick={() => setVisible(!visible)}>
      <div
        className={cn(styles.miniModalContent, { [styles.activeContent]: visible })}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};
