import { message } from "antd";
import { Dispatch } from "redux";

import { stocksApi } from "./api";
import { stocksActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { IStockMoving } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const getStocksList = () => (dispatch: Dispatch, getState: () => RootState) => {
  stocksApi.getList().then(({ data }) => {
    dispatch(stocksActions.setStocks(data.results));
  });
};

export const moveToStock =
  (stockId: string, moving: IStockMoving, sucessCallback?: () => void) => (dispatch: Dispatch) => {
    stocksApi
      .createMoving(stockId, moving)
      .then(() => {
        message.success("Перемещение успешно произведено");
        sucessCallback?.();
      })
      .catch(errorCatcher);
  };

export const getStockMovingProducts =
  (stockId: string, movingId: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = String(movingId);
    if (!getState().stocksProcurements.products[key]) {
      dispatch(stocksActions.setIsLoading({ key, status: true }));
    }
    stocksApi
      .getMovingProducts(stockId, movingId)
      .then(({ data }) => {
        dispatch(stocksActions.setProducts({ key, data: data.results }));
      })
      .finally(() => {
        dispatch(stocksActions.setIsLoading({ key, status: false }));
      });
  };
