import { SignerTypes } from "./model/types";

export const SIGNERS_TYPES_LABELS: Record<SignerTypes, string> = {
  aook_1: "Представитель застройщика по вопросам строительного контроля",
  aook_2: "Представитель лица, осуществляющего строительство",
  aook_3: "Представитель лица, осуществляющего строительство, по вопросам строительного контроля",
  aook_4: "Представитель лица, осуществляющего подготовку проектной документации",
  aook_5: "Представитель лица, выполнившего конструкции, подлежащие освидетельствованию",
  aook_6: "Представитель иных лиц",
  aosr_1: "Представитель застройщика по вопросам строительного контроля",
  aosr_2: "Представитель лица, осуществляющего строительство",
  aosr_3: "Представитель лица, осуществляющего строительство, по вопросам строительного контроля",
  aosr_4: "Представитель лица, осуществляющего подготовку проектной документации",
  aosr_5: "Представитель иных лиц",
  ks2_accepted: "Заказчик",
  ks2_passed: "Подрядчик",
  ks3_provider: "Подрядчик",
  ks3_purchaser: "Заказчик",
};
