import cn from "classnames";
import React from "react";

import ExpandableIcon from "../../../../../images/icons/ExpandableIcon";

import styles from "./Expandable.module.scss";

//TODO заменить additionalHeadline на customHeadline, additionalHeadline оставить для добавления контента в справый колнец хедера

export interface IExpandableProps {
  children: React.ReactNode;
  defaultExpanded?: boolean;
  className?: string;
  title?: string;
  additionalHeadline?: React.ReactNode;
  contentClassName?: string;
}

function Expandable({
  children,
  defaultExpanded = true,
  className,
  title,
  additionalHeadline,
  contentClassName,
}: IExpandableProps) {
  const [isExpanded, setIsExpanded] = React.useState(defaultExpanded);

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.head} onClick={() => setIsExpanded((prev) => !prev)}>
        <ExpandableIcon className={cn(styles.svg, { [styles.activeSvg]: isExpanded })} />
        {additionalHeadline ? additionalHeadline : <span className={styles.title}>{title}</span>}
      </div>
      {isExpanded && <div className={cn(styles.content, contentClassName)}>{children}</div>}
    </div>
  );
}

export default Expandable;
