import React from "react";

import { IFile } from "types/interfaces/Files";

import styles from "./FileViewPdf.module.scss";

interface IProps {
  file: IFile;
}

const FileViewPdf: React.FC<IProps> = ({ file }) => {
  return (
    <div className={styles.container}>
      <embed src={file.link} width="100%" height="100%" type="application/pdf" />
    </div>
  );
};

export default FileViewPdf;
