import React from "react";

import { IModal } from "../../types/types";

import { useModalDisplaying } from "../../hooks/useModalDisplaying";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import closeBtn from "images/icons/closeModalBtn.svg";

import styles from "./Modal.module.scss";

const Modal = ({ children, isVisible, setIsVisible }: IModal) => {
  const { closeModal, rootClasses } = useModalDisplaying({ isVisible, setIsVisible, styles });

  return (
    <div className={rootClasses.join(" ")} onClick={closeModal}>
      <div className={styles.myModalContent} onClick={stopEventPropagation}>
        <div className={styles.arrowPointer}></div>
        <div className={styles.closeModalBtn} onClick={closeModal}>
          <img src={closeBtn} alt="х" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default React.memo(Modal);
