import cn from "classnames";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartActionsSelector, chartDraggedArrowSelector } from "redux/modules/common/chart/selectors";
import { updateChartHash } from "redux/modules/common/chart/thunks";
import { ChartModalType, IChartPlanSection, IChartPlanWork } from "redux/modules/common/chart/types";

import ShiftModal from "../../../../Manufacturing/components/modals/ShiftsModal/ShiftsModal";
import ManufacturingPlanSectionModal from "components/modals/ManufacturingPlanSectionModal/ManufacturingPlanSectionModal";
import BraceTooltip from "components/pages/Manufacturing/components/Month/components/Plan/components/BraceTooltip/BraceTooltip";
import { ManufacturingPlanModal } from "components/pages/Manufacturing/components/modals/ManufacturingPlanModal/ManufacturingPlanModal";
import { INTERVAL_TYPES } from "components/pages/Manufacturing/constants";
import { getIntervalDatesLabel } from "components/pages/Manufacturing/utils";

import type { IChartIntervalProps } from "../ChartInterval.typings";
import { CHART_Z_INDEX } from "../const";
import { useChartIntervalIntersection } from "../useChartIntervalIntersection";
import { useChartIntervalSize } from "../useChartIntervalSize";

import intervalStyles from "../ChartInterval.module.scss";
import styles from "./withPlan.module.scss";

export interface IChartPlanIntervalProps
  extends Omit<
    IChartIntervalProps,
    "start" | "end" | "id" | "openModal" | "closeModal" | "intervalData" | "popupType"
  > {
  plan: IChartPlanSection | IChartPlanWork;
  modalType: ChartModalType;
  isSectionPlan?: boolean;
  onAddArrowCallback?: () => void;
  id?: string;
}

function withPlan(Base: React.FC<IChartIntervalProps>) {
  return ({ plan, modalType, isSectionPlan, ...props }: IChartPlanIntervalProps) => {
    const forwardRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenShiftModal, setIsOpenShiftModal] = useState(false);
    const draggedArrow = useSelector(chartDraggedArrowSelector);

    const chartDiagramActions = useSelector(chartActionsSelector);

    const isPlansInteractionMode =
      chartDiagramActions.linking_editing_enabled || chartDiagramActions.plans_editing_enabled;

    const resetChartHash = useCallback(() => {
      dispatch(updateChartHash());
    }, []);

    const openModal = useCallback(() => {
      setIsOpenModal(true);
    }, []);

    const closeModal = useCallback(() => {
      setIsOpenModal(false);
    }, []);

    const openShiftModal = useCallback(() => {
      setIsOpenShiftModal(true);
    }, []);

    const closeShiftModal = useCallback(() => {
      setIsOpenShiftModal(false);
    }, []);

    // @ts-ignore
    const isGroup = plan.type === "group";
    // @ts-ignore
    const hasShift = plan.has_shifts;
    // @ts-ignore
    const intervalData = plan.type ? plan[plan.type] : plan;
    const intervalSectionId = intervalData.cs_id || intervalData.ps_id;
    const expenditureId = isGroup ? intervalData.group_id : intervalData.exp_id || intervalSectionId;
    const canAcceptArrow = draggedArrow && draggedArrow.intervalId !== intervalData.id;

    const isIntersecting = useChartIntervalIntersection({ intervalRef: forwardRef, recheck: isPlansInteractionMode });

    const braceTooltipRef = useRef<HTMLDivElement>(null);

    const moveBraceTooltip = (e: MouseEvent) => {
      requestAnimationFrame(() => {
        if (!braceTooltipRef?.current || isOpenModal || !forwardRef.current) return;
        const bounds = forwardRef.current.getBoundingClientRect();
        const x = Math.max(bounds.left + 8, Math.min(e.clientX, bounds.right - 8));
        braceTooltipRef.current.style.left = "0px";
        braceTooltipRef.current.style.transform = `translateX(calc(${x}px - 75%))`;
      });
    };

    const intervalDates = useMemo(() => getIntervalDatesLabel(plan.start, plan.end), [plan.start, plan.end]);

    const { widthRem, leftRem } = useChartIntervalSize({
      start: plan.start,
      end: plan.end,
    });

    const zIndex = (() => {
      if (hasShift) return CHART_Z_INDEX.CRITICAL_PLAN;
      if (isSectionPlan) return CHART_Z_INDEX.SECTION_PLAN;
      return props.zIndex ?? CHART_Z_INDEX.BASE;
    })();

    const planBraceStyles = {
      transform: `translateX(calc(${(leftRem || 0) + 0.25}rem + 7px))`,
      width: `calc(${(widthRem || 0) - 0.5}rem - 14px)`,
      zIndex: zIndex,
    };

    return (
      <Base
        {...props}
        forwardRef={forwardRef}
        // @ts-ignore
        plan={plan}
        isSectionPlan={isSectionPlan}
        // @ts-ignore
        id={isGroup ? `group_${plan.group?.id}` : `work_${plan.expenditure?.id || plan.id}`}
        start={plan.start}
        end={plan.end}
        openModal={openModal}
        closeModal={closeModal}
        // @ts-ignore
        intervalData={isGroup ? plan.group : plan.expenditure}
        popupType={INTERVAL_TYPES.plan}
        zIndex={zIndex}
        hasShift={hasShift}
        openShiftModal={openShiftModal}
        className={cn(props.className, styles.intervalWrapper)}
        beforeContent={
          <>
            {props.beforeContent}
            {isIntersecting && !isPlansInteractionMode && (
              <div
                className={styles.planBrace}
                onClick={openModal}
                style={planBraceStyles}
                // @ts-ignore
                onMouseMove={moveBraceTooltip}
              >
                <BraceTooltip label={`Плановый диапазон: ${intervalDates}`} ref={braceTooltipRef}>
                  <div className={styles.spacer} />
                </BraceTooltip>
              </div>
            )}
          </>
        }
        children={
          <>
            {props.children}
            <div
              className={cn(styles.planBg, intervalStyles.ticketShape, {
                [styles.hasShift]: hasShift,
                [styles.canAcceptArrow]: canAcceptArrow,
              })}
            />
          </>
        }
        afterContent={
          <>
            {props.afterContent}
            {isOpenModal && !isSectionPlan && (
              <ManufacturingPlanModal
                isOpen
                onClose={closeModal}
                dateStart={plan.start}
                dateEnd={plan.end}
                data={intervalData}
                expenditureId={expenditureId}
                sectionId={intervalSectionId}
                // @ts-ignore
                modalType={modalType}
                // updatePlanStatus={updatePlanStatus}
                onEditCallback={resetChartHash}
                onAddToJournalCallback={resetChartHash}
                isGroupPlan={isGroup}
                projectId={props.projectId.toString()}
              />
            )}
            {isOpenModal && isSectionPlan && (
              <ManufacturingPlanSectionModal
                isOpen
                onClose={closeModal}
                objectId={props.projectId.toString()}
                intervaldata={intervalData}
                sectionId={intervalSectionId}
                modalType={modalType}
                onEditCallback={resetChartHash}
                onAddToJournalCallback={resetChartHash}
              />
            )}
            {isOpenShiftModal && (
              <ShiftModal
                isOpened
                onClose={closeShiftModal}
                objectId={props.projectId.toString()}
                planId={intervalData?.id}
                lsrPlanId={intervalData?.ps_id}
                sectionPlanId={intervalData?.cs_id}
                openedFromPlanClick
                isGroup={isGroup}
                refreshChart={resetChartHash}
              />
            )}
          </>
        }
      />
    );
  };
}

export default withPlan;
