import classNames from "classnames";
import cn from "classnames";
import React, { FC, useEffect, useRef, useState } from "react";

import Select, { IOption } from "components/UI/atoms/Select";

import InstructionBottomSlider from "./InstructionBottomSlider";
import InstructionBottomSublist from "./InstructionBottomSublist/InstructionBottomSublist";

import { IPermissionExtended, IPermissionsTree } from "features/permissions/model/types";

import { ReactComponent as ArrowRigth } from "shared/assets/icons/ArrowLeft-small.svg";

import styles from "./InstructionsBottom.module.scss";

const options = [
  { id: "list", name: "Список" },
  { id: "gallery", name: "Галерея" },
] as const;

interface IProps {
  fullActivePermission?: IPermissionExtended;
  siblings?: IPermissionsTree[];
  setActivePerm?: (perm: string) => void;
}

const InstructionsBottom: FC<IProps> = ({ fullActivePermission, siblings, setActivePerm }) => {
  const [open, setOpen] = useState(true);
  const [view, setView] = useState<(typeof options)[number]["id"]>("list");

  const isHidden = !siblings?.reduce<number>((acc, cur) => {
    return (
      acc +
      cur.groups?.reduce((a, c) => {
        return a + c?.permissions?.length;
      }, 0)
    );
  }, 0);

  if (isHidden) return null;

  return (
    <div className={cn(styles.root, !open && styles.rootClosed)}>
      <div className={classNames(styles.expand, !open && styles.closed)} onClick={() => setOpen((p) => !p)}>
        <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L10.5 10.5L20 1" stroke="black" stroke-width="2" />
        </svg>
      </div>
      <div className={cn(styles.expandable, !open && styles.closed)}>
        <header className={styles.header}>
          <h3>Смотрите также</h3>
          <Select
            className={styles.select}
            options={options as unknown as IOption[]}
            onChange={setView as any}
            value={view}
          />
        </header>

        {view === "list" && (
          <>
            {siblings?.map((group) => (
              <InstructionBottomSublist key={group.name} group={group} setActivePerm={setActivePerm} />
            ))}
          </>
        )}
        {view === "gallery" && (
          <InstructionBottomSlider
            setActivePerm={setActivePerm}
            fullActivePermission={fullActivePermission}
            siblings={siblings}
          />
        )}
      </div>
    </div>
  );
};

export default InstructionsBottom;
