import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

import { IServiceActsInitialState } from "./types";

export const stateSelector = (state: RootState): IServiceActsInitialState => state.serviceActs;
export const serviceActsListSelector = createSelector(stateSelector, (state) => state.serviceActsList);
export const serviceActSelector = createSelector(stateSelector, (state) => state.serviceAct);
export const isServiceActsListLoadingSelector = createSelector(stateSelector, (state) => state.isActsListLoading);
export const isServiceActLoadingSelector = createSelector(stateSelector, (state) => state.isActLoading);
export const isServiceActNotFoundSelector = createSelector(stateSelector, (state) => state.isActNotFound);
export const serviceActFilesSelector = createSelector(stateSelector, (state) => state.files);
export const isAcceptingServiceActSelector = createSelector(stateSelector, (state) => state.isAcceptingAct);
