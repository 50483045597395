import PlatBen1Svg from "./img/plat-ben1.svg";
import PlatBen2Svg from "./img/plat-ben2.svg";
import PlatBen3Svg from "./img/plat-ben3.svg";
import PlatBen4Svg from "./img/plat-ben4.svg";
import PlatBen5Svg from "./img/plat-ben5.svg";
import PlatBen6Svg from "./img/plat-ben6.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useEffect, useState } from "react";
import cn from "classnames";

export const BenefitsSection = () => {
  const [isSwipable, setIsSwipable] = useState(false);

  useEffect(() => {
    const match = window.matchMedia('(max-width: 990px)');
    if (match.matches) {
      setIsSwipable(true);
    }
  }, [])

  return (
    <section className="plat-ben" id="benefits">
      <div className="container">
        <div className="plat-ben__main">
          <h2>Преимущества платформы</h2>
          <p>Если вы подключите к платформе всех субподрядчиков, вы получите:</p>
          <div className="plat-ben__block">
            <Swiper
              noSwiping={true}
              noSwipingClass={"no-swipe"}
              className={cn("plat-ben__slider", {"no-swipe": !isSwipable})}
              modules={[ Pagination ]}
              slidesPerView={3}
              grid={{ rows: 2 }}
              pagination={{
                el: ".swiper-pagination",
                clickable: true
              }}
              breakpoints={{
                "1199": {
                  slidesPerView: 3,
                  slidesPerGroup: 1
                },
                "991": {
                  slidesPerView: 3,
                  slidesPerGroup: 1
                },
                "760": {
                  slidesPerView: 2,
                  slidesPerGroup: 1,
                  grid: {
                    rows: 1
                  }
                },
                "480": {
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                  grid: {
                    rows: 1
                  }
                },
                "320": {
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                  grid: {
                    rows: 1
                  }
                }
              }}
            >
                <SwiperSlide>
                  <div className="plat-ben__box">
                    <span>Прогноз расходов по запланированным работам и материалам</span>
                    <img src={PlatBen1Svg} alt="image" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="plat-ben__box">
                    <span>Данные о расходах и выполнении работ в режиме реального времени</span>
                    <img src={PlatBen2Svg} alt="image" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="plat-ben__box">
                    <span>Предоставление заказчику актуальной сметы на основе реальных расходов</span>
                    <img src={PlatBen3Svg} alt="image" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="plat-ben__box">
                    <span>Данные для формирования управленческой отчетности</span>
                    <img src={PlatBen4Svg} alt="image" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="plat-ben__box">
                    <span>Полный комплект документации для передачи заказчику</span>
                    <img src={PlatBen5Svg} alt="image" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="plat-ben__box">
                    <span>Возможность контролировать расходы по каждой расценке</span>
                    <img src={PlatBen6Svg} alt="image" />
                  </div>
                </SwiperSlide>
            </Swiper>
            <div className="swiper-pagination"></div>
          </div>
          <span className="title-bot">Повышайте управляемость процессов</span>
        </div>
      </div>
    </section>

  );
};