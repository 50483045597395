import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";

import {
  addOutOfEstimateExpenditure,
  changeExpenditure,
  createExpenditure,
} from "../../../../../../../../../../redux/modules/common/building/sections/sections";

import ExpenditureForm from "../../../../../../../../../../components/UI/_TODO/ExpenditureForm/ExpenditureForm";

import { loadAggregations } from "../../../../../../../Aggregations/model/thunks";
import Modal from "shared/ui/modal/Modal";

import { ESTIMATE_STATES_IDS, OUT_OF_ESTIMATE_REQUIRED_FIELDS } from "../../../../../../constants";

import styles from "./ManageExpenditureModal.module.scss";

const serializeInitialValues = ({ draft_count, estimate_measure, measure, ...values }) => ({
  ...values,
  count: values.count, // || draft_count,
  estimate_measure: estimate_measure || measure,
  measure: measure || estimate_measure,
});

export const VARIANTS = {
  EDIT: "edit",
  ADD: "add",
};

const ManageExpenditureModal = ({
  isOpen,
  onClose,
  buildingId,
  sectionId,
  activeEstimateStateId,
  variant,
  initialValues,
  isOutOfEstimate,
  isDraft = false,
}) => {
  const dispatch = useDispatch();

  const submitAddExpenditureForm = useCallback(
    (rawValues) => {
      let values;
      if (isDraft) {
        values = { ...rawValues, draft_count: rawValues.count };
      } else {
        values = rawValues;
      }

      onClose();
      if (variant === VARIANTS.ADD) {
        if (isOutOfEstimate) {
          compose(dispatch, addOutOfEstimateExpenditure)(buildingId, { ...values, section_id: sectionId });
          return;
        }
        compose(dispatch, createExpenditure)(buildingId, sectionId, values, activeEstimateStateId).then(() => {
          dispatch(loadAggregations(buildingId));
        });
        return;
      }

      compose(dispatch, changeExpenditure)(
        {
          building: buildingId,
          section: sectionId,
          estimateState:
            activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION ? values.current_state : activeEstimateStateId,
        },
        values,
        isOutOfEstimate,
        buildingId
      );
    },
    [onClose, sectionId, activeEstimateStateId, variant, buildingId, isOutOfEstimate]
  );

  const serializedInitialValues = useMemo(
    () => (initialValues ? serializeInitialValues(initialValues) : null),
    [initialValues]
  );

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      title={`${variant === VARIANTS.ADD ? "Добавить" : "Редактировать"} позицию`}
    >
      <div className={styles.content}>
        <ExpenditureForm
          submit={submitAddExpenditureForm}
          initialValues={serializedInitialValues}
          isEstimate
          requiredFields={isOutOfEstimate ? OUT_OF_ESTIMATE_REQUIRED_FIELDS : undefined}
          isWithNumberField
          isDefault={variant === VARIANTS.ADD || initialValues?.added_manually}
        />
      </div>
    </Modal>
  );
};

export default React.memo(ManageExpenditureModal);
