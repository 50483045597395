import React from "react";

import ProgressBar from "shared/ui/dataDisplay/ProgressBar/ProgressBar";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import styles from "./PlanFactVolumeSection.module.scss";

interface IProps {
  name: string;
  percents?: number;
  onClick: (sectionId: number) => void;
  sectionId: number;
}

const PlanFactVolumeSection: React.FC<IProps> = ({ name, percents, onClick, sectionId }) => {
  const clickHandler = React.useCallback(() => {
    onClick(sectionId);
  }, [sectionId, onClick]);

  const isPercents = percents !== undefined && percents !== null;

  return (
    <TableReusableRow onClick={clickHandler} className={styles.row} isWhiteSvgOnHover>
      <TableReusableCell isNoBreak>{name}</TableReusableCell>
      <TableReusableCell isNoBreak>
        {isPercents && <ProgressBar completed={Math.floor(percents)} containerClassName={styles.progress} />}
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default React.memo(PlanFactVolumeSection);
