import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { chartActionsSelector, chartTabSelector, chartTreeSelector } from "redux/modules/common/chart/selectors";
import { CHART_TABS, IChartTree } from "redux/modules/common/chart/types";
import { findChartTreeNode } from "redux/modules/common/chart/utils";

import { useRem } from "components/pages/Manufacturing/hooks/useRem";

import { useChartCreatePlanModal } from "./useChartCreatePlanModal";

import { getCorrespondingDateElement, getCorrespondingTreeElement } from "../utils/getElementsFromPointer";

export const useChartPointerCreatePlan = (calendarRef: React.RefObject<HTMLDivElement | null>) => {
  const tab = useSelector(chartTabSelector);
  const chartTree = useSelector(chartTreeSelector);
  const chartActions = useSelector(chartActionsSelector);

  const { REM } = useRem();
  const [activeTreeNode, setActiveTreeNode] = useState<IChartTree | undefined>(undefined);
  const [activeDate, setActiveDate] = useState("");

  const selectTreeNode = useCallback(
    (uid: string) => {
      const node = findChartTreeNode(uid, chartTree);
      setActiveTreeNode(node || undefined);
    },
    [chartTree]
  );

  const { createPlanModal, openCretePlanModalOpen } = useChartCreatePlanModal({
    tree: activeTreeNode,
    defaultDate: activeDate,
  });

  useEffect(() => {
    if (tab !== CHART_TABS.WORK || !chartActions.plans_editing_enabled) return;
    const scrollContainer = calendarRef.current?.parentElement;
    if (!scrollContainer) return;
    const openPlanModal = (e: MouseEvent) => {
      if (e.target !== scrollContainer) return;
      const correspondingTreeUid = getCorrespondingTreeElement(e, REM)?.getAttribute("data-chart-tree-uid");

      const correspondingDateElement = getCorrespondingDateElement(e, REM);

      if (!correspondingTreeUid || !correspondingDateElement) return;

      const correspondingYear = correspondingDateElement.getAttribute("data-year");
      const correspondingMonth = correspondingDateElement.getAttribute("data-month");
      const correspondingDate =
        parseInt(correspondingDateElement.getAttribute("data-date") || correspondingDateElement.textContent || "") || 1;

      if (correspondingYear === null || correspondingMonth === null || correspondingDate === null) return;

      const correspondingFormattedDate = moment()
        .year(+correspondingYear)
        .month(+correspondingMonth)
        .date(correspondingDate)
        .format("YYYY-MM-DD");

      setActiveDate(correspondingFormattedDate);
      selectTreeNode(correspondingTreeUid);

      openCretePlanModalOpen();
    };

    scrollContainer.addEventListener("dblclick", openPlanModal);

    return () => {
      scrollContainer.removeEventListener("dblclick", openPlanModal);
    };
  }, [calendarRef.current, selectTreeNode, openCretePlanModalOpen, tab, REM, chartActions.plans_editing_enabled]);

  return { createPlanModal };
};
