import React from "react";

import ButtonBase from "../ButtonBase";

import download from "../../../../images/download.svg";

import styles from "./DownloadButton.module.scss";

export interface IProps {
  onDownload: () => void;
  className?: string;
}

const DownloadButton: React.FC<IProps> = ({ className, onDownload }) => {
  return (
    <ButtonBase className={className} onClick={onDownload} quaternary type="button">
      Скачать
      <img className={styles.downloadIcon} alt="Иконка скачать" src={download} />
    </ButtonBase>
  );
};

export default React.memo(DownloadButton);
