import cn from "classnames";
import React from "react";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import styles from "./Popup.module.scss";

interface IProps {
  count: string;
  countCompleted: string;
  percents: string;
}

const Popup: React.FC<IProps> = ({ count, countCompleted, percents }) => {
  return (
    <PopoverOverlay
      content={
        <div className={styles.content}>
          <div className={styles.percents}>Выполнено {percents}% </div>
          <div className={styles.flex}>
            <div className={styles.item}>
              <div className={cn(styles.value, styles.plan)}>{count}</div>
              <div className={styles.title}>Итого по проекту</div>
            </div>
            <div className={styles.item}>
              <div className={cn(styles.value, styles.fact)}>{countCompleted}</div>
              <div className={styles.title}>Итого выполнено</div>
            </div>
          </div>
        </div>
      }
      openType="hover"
      placement="bottom"
      popoverBorderColor="primary"
    >
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.svg}
      >
        <path
          d="M10 20.0391C15.5228 20.0391 20 15.5619 20 10.0391C20 4.51621 15.5228 0.0390625 10 0.0390625C4.47715 0.0390625 0 4.51621 0 10.0391C0 15.5619 4.47715 20.0391 10 20.0391Z"
          fill="#4FB1EB"
        />
        <path
          d="M10.5442 15.0383H9.45622V7.55034H10.5442V15.0383ZM10.7682 4.67034C10.7712 4.77433 10.7512 4.87768 10.7097 4.97308C10.6682 5.06847 10.6063 5.15357 10.5282 5.22234C10.3862 5.35903 10.1973 5.4363 10.0002 5.43834C9.80311 5.4363 9.61424 5.35903 9.47222 5.22234C9.39417 5.15357 9.33221 5.06847 9.29073 4.97308C9.24925 4.87768 9.22928 4.77433 9.23222 4.67034C9.22928 4.56636 9.24925 4.46301 9.29073 4.36761C9.33221 4.27221 9.39417 4.18711 9.47222 4.11834C9.61424 3.98166 9.80311 3.90439 10.0002 3.90234C10.1973 3.90439 10.3862 3.98166 10.5282 4.11834C10.6063 4.18711 10.6682 4.27221 10.7097 4.36761C10.7512 4.46301 10.7712 4.56636 10.7682 4.67034Z"
          fill="white"
        />
      </svg>
    </PopoverOverlay>
  );
};

export default Popup;
