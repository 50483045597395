import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ICreateOutOfEstimateSectionState } from "../types";

const initialState: ICreateOutOfEstimateSectionState = {
  isVisible: false,
  name: "",
  parentId: null,
  isCreating: false,
};

const slice = createSlice({
  name: "features/createOutOfEstimateSection",
  initialState,
  reducers: {
    open: (state, action: PayloadAction<{ parentId?: number } | undefined>) => {
      state.isVisible = true;
      state.parentId = action?.payload?.parentId ?? null;
    },

    setIsCreating: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreating = payload;
    },

    setName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },

    setParentId: (state, { payload }: PayloadAction<number | undefined>) => {
      state.parentId = payload ?? null;
    },

    clear: () => initialState,
  },
});

export const { reducer, actions } = slice;
