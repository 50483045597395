import React, { useCallback } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

const RadioLookingAsCheckbox = ({ checked, onChange, children, disabled }) => {
  const onChangeIfNotDisabled = useCallback((e) => {
    if (disabled) return;
    onChange(e);
  }, [onChange, disabled])

  return (
    <div className={styles.radioLabel} onClick={onChangeIfNotDisabled}>
      <div className={cn(styles.radio, { [styles.disabled]: disabled })}>
        {checked && (
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.49023 7.81004L6.50723 12.8173L14.5072 0.94043" stroke="#707070" strokeWidth="1.6"/>
          </svg>
        )}
      </div>
      {children && (
        <p className={styles.text}>{children}</p>
      )}
      <input className={styles.hidden} type="radio" checked={checked}/>
    </div>
  );
};

export default React.memo(RadioLookingAsCheckbox);