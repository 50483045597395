import React, { useEffect } from "react";

import { IKs2InActingList } from "redux/modules/common/building/journal/acting/types";

import JournalActingModalContainer from "./JournalActingModalContainer";
import JournalKs3ModalRow from "./Rows/JournalKs3ModalRow/JournalKs3ModalRow";

import { useCreateKs3 } from "../hooks/useCreateKs3";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  estimateId?: number;
}

const JournalKs3Modal: React.FC<IProps> = ({ isOpen, onClose, estimateId }) => {
  const {
    selectedKs2Ids,
    actData,
    actNumberHandler,
    dateEndHandler,
    dateStartHandler,
    submitHandler,
    selectedKsList,
    deselectHandler,
    isShowSubmit,
  } = useCreateKs3(isOpen, estimateId?.toString());

  useEffect(() => {
    if (!selectedKs2Ids.length) {
      onClose();
    }
  }, [selectedKs2Ids]);

  return (
    <>
      <JournalActingModalContainer
        title="Формирование акта КС-3"
        isOpen={isOpen}
        onClose={onClose}
        count={selectedKs2Ids.length}
        actNumber={actData.number}
        onChangeAct={actNumberHandler}
        dateEnd={actData.dateEnd}
        dateStart={actData.dateStart}
        onChangeDateEnd={dateEndHandler}
        onChangeDateStart={dateStartHandler}
        onSubmit={submitHandler}
        isShowSubmit={isShowSubmit}
      >
        {selectedKsList?.map((el: IKs2InActingList) => (
          <JournalKs3ModalRow key={el.id} ks2={el} onDeselectKs2={deselectHandler} />
        ))}
      </JournalActingModalContainer>
    </>
  );
};

export default React.memo(JournalKs3Modal);
