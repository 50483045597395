import cn from "classnames";
import React from "react";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./BetonExpenseDetailsBtn.module.scss";

interface IProps {
  isDirty: boolean;
  hasValidationErrors: boolean;
  hasSavedData: boolean;
  isPending: boolean;
  hasPermission: boolean;
  onSwitchMode: () => void;
  isDisabled: boolean;
}

const BetonExpenseDetailsBtn: React.FC<IProps> = ({
  isDirty,
  hasSavedData,
  hasValidationErrors,
  hasPermission,
  isPending,
  onSwitchMode,
  isDisabled,
}) => {
  if (!isDirty && !hasSavedData) {
    return (
      <div className={cn(styles.btn, styles.define)}>
        уточните детали
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM9 3V9.5H7L7 3H9ZM7 13H9V11H7V13Z"
            fill="#EF9D1C"
          />
        </svg>
      </div>
    );
  }
  if (!hasPermission) {
    return null;
  }
  if (!isDirty && hasSavedData && isDisabled) {
    return (
      <button
        className={styles.btn}
        type="button"
        disabled={isPending}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSwitchMode();
        }}
      >
        Изменить{" "}
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 5L11 2L9.58578 3.41422L12.5858 6.41422L14 5Z" fill="#4FB1EB" />
          <path d="M11.8787 7.12132L8.87868 4.12132L2 11V14H5L11.8787 7.12132Z" fill="#4FB1EB" />
        </svg>
      </button>
    );
  }
  return (
    <button
      disabled={hasValidationErrors || isPending || !isDirty}
      className={styles.btn}
      type="submit"
      onClick={stopEventPropagation}
    >
      Сохранить{" "}
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.79891 10.4174L3.12402 7.07938L1.7793 8.55982L6.99394 13.2964L14.7331 4.07317L13.201 2.7876L6.79891 10.4174Z"
          fill="#4FB1EB"
        />
      </svg>
    </button>
  );
};

export default BetonExpenseDetailsBtn;
