import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

export const stateSelector = (state: RootState) => state.permissionsV2;

export const permissionsTreeSelector = createSelector(stateSelector, (state) => state.tree);
export const permissionsDescrSelector = createSelector(stateSelector, (state) => state.descriptionsByAliases);
export const permissionsUserSelector = createSelector(stateSelector, (state) => state.userPermissionsList);
export const permissionsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const permissionsPendingSelector = createSelector(stateSelector, (state) => state.isPending);
export const permissionsInvalidateKeySelector = createSelector(stateSelector, (state) => state.invalidationKey);
