import cn from "classnames";
import React from "react";

import { groupIcon } from "./groupIcon";

import styles from "./ExpandGroup.module.scss";

interface IProps {
  isExpand: boolean;
  onExpand: () => void;
}

const ExpandGroup: React.FC<IProps> = ({ isExpand, onExpand }) => {
  return (
    <div className={cn(styles.btn, { [styles.expanded]: isExpand })} onClick={onExpand}>
      {groupIcon}
    </div>
  );
};

export default ExpandGroup;
