import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";

import Select from "components/UI/atoms/Select";

import ObjectSettingsControlEmployee from "./ObjectSettingsControlEmployee/ObjectSettingsControlEmployee";
import ObjectSettingsControlModal from "./ObjectSettingsControlModal/ObjectSettingsControlModal";

import styles from "./SettingsObjectConstructionControl.module.scss";

interface IProps {
  objectId: string;
}

const SettingsObjectConstructionControl: FC<IProps> = ({ objectId }) => {
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);

  return (
    <div>
      <div className={styles.grid}>
        <div className={styles.row}>
          <ObjectSettingsControlEmployee
            title="Приемка"
            objectId={objectId}
            onClick={() => {
              setIsModal(true);
            }}
          />
          <ObjectSettingsControlModal objectId={objectId} isOpen={isModal} onClose={() => setIsModal(false)} />
        </div>
      </div>
    </div>
  );
};

export default SettingsObjectConstructionControl;
