import cn from "classnames";
import React, { ReactNode } from "react";

import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import { listHoverHandler } from "../../listHoverHandler";
import Spinner from "shared/ui/atoms/Spinner/Spinner";

import { getIsControlsVisible, getRowMargin } from "../utils";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import MinusBoxIcon from "images/icons/MinusBoxIcon";
import PlusBoxIcon from "images/icons/PlusBoxIcon";

import styles from "./ListRow.module.scss";

interface IProps {
  data: IFinanceTreeItem;
  depth: number;
  handleListItemClick: (subTree: IFinanceTreeItem) => void;
  className?: string;
  renderExtraControl?: (row: IFinanceTreeItem) => ReactNode;
  getIsWithBorderTop?: (row: IFinanceTreeItem) => boolean;
  loadings?: Record<string, boolean>;
}

const ListRow: React.FC<IProps> = (props) => {
  const { data, depth, handleListItemClick, className, renderExtraControl, loadings, getIsWithBorderTop } = props;

  const { isNegative, isPositive } = diffChecker(data);

  //TODO_V2_FINANCE
  const dataId = data.key;

  const loadingKey = generateStorageKey({ type: data.type, buildingId: data.id });
  const isLoading = loadings?.[loadingKey];
  const isControlsVisible = getIsControlsVisible(data, isLoading);
  const showLeftSpaceHolder =
    !isLoading &&
    (data.type === "advance" ||
      data.type === "warranty" ||
      data.type === "extraExpenditure" ||
      String(data.number) === " ");
  const isExtra = data.type === "extraRoot";
  const isWithBorderTop = getIsWithBorderTop?.(data);

  return (
    <div
      className={cn(styles.listRow, className, "financeListRow", {
        [styles.listRow__border]: data.isOpen && data.key.includes("expenditure"),
        opened: data.isOpen,
        [styles.extra]: isExtra,
        [styles.borderTop]: isWithBorderTop,
      })}
      style={{ paddingLeft: getRowMargin(depth) }}
      onMouseEnter={() => listHoverHandler(dataId, true)}
      onMouseLeave={() => listHoverHandler(dataId, false)}
      data-row-id={dataId}
      onClick={() => {
        isControlsVisible && handleListItemClick(data);
      }}
    >
      {isControlsVisible && (
        /* @ts-ignore */
        <div className={styles.listRow__icon}>{data.isOpen ? <MinusBoxIcon /> : <PlusBoxIcon />}</div>
      )}
      {isLoading && <Spinner isStatic isSmallGray className={styles.spinner} />}

      {!isControlsVisible && !!data.number && (
        <span className={styles.expenditureNumber} title={String(data.number)}>
          {data.number}
        </span>
      )}
      {showLeftSpaceHolder && <div className={styles.numberHolder} />}
      <div
        className={cn(styles.listRow__titleWrapper, {
          [styles.positive]: isPositive,
          [styles.negative]: isNegative,
        })}
      >
        <span
          className={cn(styles.listRow__title, {
            [styles.active]: data.isOpen && data.id?.toString()?.includes("expenditure"),
            [styles.extraChildrenTitle]: isExtra && !isControlsVisible,
          })}
          title={data.name}
        >
          {data.name}
        </span>
      </div>
      {/*  {data.id?.toString()?.includes("expenditure") && (
        <span className={cn(styles.listRow__count, { [styles.active]: data.isOpen })}>{data.children?.length}</span>
      )} */}
      {!isLoading && data.type === "extraExpenditure" && renderExtraControl?.(data)}
    </div>
  );
};

export default ListRow;

const diffChecker = (data: IFinanceTreeItem) => {
  let isPositive;
  let isNegative;

  //TODO_V2_FINANCE
  /* if (data.audit_data) {
    if (
      numberPositiveChecker(data.audit_data.selected_period?.diff) ||
      numberPositiveChecker(data.audit_data.project?.diff) ||
      numberPositiveChecker(data.audit_data.from_start?.diff)
    ) {
      isPositive = true;
    }
    if (
      numberNegativeChecker(data.audit_data.selected_period?.diff) ||
      numberNegativeChecker(data.audit_data.project?.diff) ||
      numberNegativeChecker(data.audit_data.from_start?.diff)
    ) {
      isNegative = true;
    }
  } */

  /* if (data.data) {
    const value = data.data || {};
    if (numberPositiveChecker(value.plan - value.fact)) {
      isPositive = true;
    }
    if (numberNegativeChecker(value.plan - value.fact)) {
      isNegative = true;
    }
  } */
  /* if (data.forecast_data) {
    const value = data.forecast_data.at(-1) || {};
    if (numberPositiveChecker(value.plan - value.fact)) {
      isPositive = true;
    }
    if (numberNegativeChecker(value.plan - value.fact)) {
      isNegative = true;
    }
  } */

  return {
    isPositive,
    isNegative,
  };
};

const numberPositiveChecker = (num: string) => {
  if (isNaN(Number(num))) return false;
  if (Number(num) > 0) return true;
  return false;
};

const numberNegativeChecker = (num: string) => {
  if (isNaN(Number(num))) return false;
  if (Number(num) < 0) return true;
  return false;
};
