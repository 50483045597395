import Offc2Svg from "./img/offc2.png";
import OffcMob2Svg from "./img/offc-mob2.png";

export const CostSection = () => (
  <section className="cost-forecast" id="cost-forecast">
    <div className="container">
      <div className="analysis-fact__main">
        <div className="analysis-fact__flex">
          <div className="analysis-fact__photo">
            <img src={Offc2Svg} className="fact-pic-dec" alt="image" />
            <img src={OffcMob2Svg} className="fact-pic-mob" alt="image" />
          </div>
          <div className="analysis-fact__info">
            <h2>Прогноз расходов </h2>
            <div className="sites-block__text">
              <ul>
                <li>Вы автоматически получаете прогноз бюджета производственных расходов по проекту</li>
              </ul>
            </div>
            <div className="sites-block__hert">
              <ul>
                <li>Автоматическое формирование бюджета производственных расходов на основе графика производства работ
                </li>
                <li>Контроль расходов денежных средств и кассовых разрывов</li>
              </ul>
            </div>
          </div>
        </div>
        <span className="title-bot">Управляйте бюджетом с OSLA</span>
      </div>
    </div>
  </section>
)