import { IPlanFactItem, IPlanFactResponse } from "redux/modules/_TODO/constructing/finance/planFact/types";

export const isPlanFactSection = (
  section: IPlanFactResponse["sections"][number] | IPlanFactResponse["sections"][number]["subsections"][number]
): section is IPlanFactResponse["sections"][number] => {
  return !!(section as IPlanFactResponse["sections"][number]).subsections;
};

export const isPlanFactSubsection = (
  section: IPlanFactResponse["sections"][number] | IPlanFactResponse["sections"][number]["subsections"][number]
): section is IPlanFactResponse["sections"][number]["subsections"][number] => {
  return !!(section as IPlanFactResponse["sections"][number]["subsections"][number]).items;
};

export const getPlanFactSectionItemsCount = (
  section: IPlanFactResponse["sections"][number] | IPlanFactResponse["sections"][number]["subsections"][number]
) => {
  if (isPlanFactSection(section)) {
    return section.subsections.reduce<number>((acc, cur) => {
      return acc + cur.items.length;
    }, 0);
  } else if (isPlanFactSubsection(section)) {
    return section.items.length;
  }
};
