import cn from "classnames";
import React from "react";

import styles from "./ModalContentSplitter.module.scss";

export interface IModalContentSplitterProps {
  label: string;
  className?: string;
}

const ModalContentSplitter: React.FC<IModalContentSplitterProps> = ({ label, className }) => {
  return (
    <div className={cn(styles.container, className)}>
      <hr className={styles.line} />
      <span className={styles.label}>{label}</span>
      <hr className={styles.line} />
    </div>
  );
};

export default React.memo(ModalContentSplitter);
