import axios from "axios";

import { IForecastObjectResponse, IForecastProjectListResponse } from "./types";

import downloadFile from "utils/helpers/download";

export const forecastApi = {
  getList: () => {
    return axios.get<IForecastProjectListResponse[]>(`/tree/projects/finance_forecasts/`);
  },
  getProject: (projectId: string) => {
    return axios.get<IForecastProjectListResponse[]>(`/tree/projects/finance_forecasts/`, {
      params: { project_id: projectId },
    });
  },
  getObject: (objectId: number) => {
    return axios.get<IForecastObjectResponse>(`/tree/finance_forecasts/${objectId}/`);
  },
  downloadReport: (buildingId: number, reportName: string) => {
    downloadFile(`${axios.defaults.baseURL}/buildings/${buildingId}/finances/forecast/report/`, reportName, "POST");
  },
  downloadBDR: (buildings: number[], dateStart: string, dateEnd: string, reportName: string) => {
    return downloadFile(`${axios.defaults.baseURL}/bdr/export/`, reportName, "POST", {
      start_at: dateStart,
      end_at: dateEnd,
      buildings,
    });
  },
};
