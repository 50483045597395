import { IDrawing, IDrawingSet } from "pages/Estimate/Blueprints/model/types";

import { IAxesInList, ICreateAxesArg, ICreateAxesForm, ICreateAxis } from "./model/types";
import { IIdAndName } from "types/idAndName";

export const axesUtils = {
  extractAxisValuesFromForm: (form: ICreateAxesForm) => {
    const { axis } = form;
    const res: ICreateAxis[] = [];
    for (let key in axis) {
      const [id, isNew] = axesUtils.extractIdFromFormKey(key);
      const axisCandidate: ICreateAxis = {
        ...axis[key],
        mark_end: axis[key].mark_end || axis[key].mark_start,
        isNew: !!isNew,
      };
      res.push(axisCandidate);
    }
    return res;
  },
  prepareInitialValues: (data: IAxesInList[], drawing?: IDrawing): ICreateAxesForm | undefined => {
    if (!data?.[0]) return undefined;
    const newAxis = data[0].items.reduce<ICreateAxesForm["axis"]>((acc, cur) => {
      return {
        ...acc,
        [`n_${cur.id}`]: {
          axis: cur.axis,
          mark_start: cur.marks[0],
          mark_end: cur.marks[1] ?? cur.marks[0],
          id: cur.id,
          isMarksEqual: cur.marks[0] === cur.marks[1],
        },
      };
    }, {});
    return { ...data[0], drawing_set: drawing?.drawing_set, axis: newAxis };
  },
  extractIdFromFormKey: (key: string) => {
    const [_, id, isNew] = key.split("_");
    return [id, isNew] as const;
  },
  getInfoForPreview: (info: ICreateAxesArg): IAxesInList[] => {
    const items = Object.values(info.axis).map((el) => ({
      ...el,
      marks: [el.mark_start, el.mark_end],
    }));
    //@ts-ignore
    return [{ ...info, id: 0, items }];
  },
  getDrawingsSetsFromDrawings: (drawings?: IDrawing[]) => {
    const setsDict: Record<string, IIdAndName> = {};
    drawings?.forEach((el) => {
      setsDict[el.drawing_set] = {
        id: el.drawing_set,
        name: el.drawing_set_title,
      };
    });
    return Object.values(setsDict);
  },
  prepareAxisForEdit: ({
    axis,
    itemsWithEqualMarks,
    editedItems,
  }: {
    axis: ICreateAxis[];
    itemsWithEqualMarks: number[];
    editedItems: number[];
  }) => {
    const dictOfEquals = itemsWithEqualMarks.reduce<Record<string, boolean>>((acc, cur) => {
      return {
        ...acc,
        [cur]: true,
      };
    }, {});
    const dictOfEdited = editedItems.reduce<Record<string, boolean>>((acc, cur) => {
      return {
        ...acc,
        [cur]: true,
      };
    }, {});

    return axis
      .filter((el) => dictOfEdited[el.id!])
      .map((el) => {
        if (dictOfEquals[el.id!]) {
          return {
            ...el,
            mark_end: el.mark_start,
          };
        } else {
          return el;
        }
      });
  },
};
