import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

import { IJournalInitialState } from "./types";

export const stateSelector = (state: RootState): IJournalInitialState => state.journal;

export const journalGroupDetailInfoSelector = createSelector(stateSelector, (state) => state.groupDetailInfo);

export const journalTicketsInvalidateKeySelector = createSelector(stateSelector, (state) => state.invalidateKey);
