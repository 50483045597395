import React from 'react'
import styles from "./EmptyTasksList.module.scss"

function EmptyTasksList() {
  return (
    <div className={styles.empty}>
        Нет задач в данном списке
    </div>
  )
}

export default EmptyTasksList