import { useCallback, useEffect, useMemo, useState } from "react";

type ArrayItemKey = string | number | symbol;

interface IArrayItem {
  [key: ArrayItemKey]: any;
}

type CheckerItems = Record<IArrayItem[ArrayItemKey], boolean>;

interface ICheckAll {
  (isChecked: boolean): void;
}

interface ICheckOnce {
  (key: ArrayItemKey, isChecked: boolean): void;
}

interface IReset {
  (): void;
}

const getKeyIdentifier = (item: IArrayItem, key: ArrayItemKey, reserveKey?: ArrayItemKey) => {
  if (item?.[key] !== undefined) return item[key];
  if (reserveKey) return item[reserveKey];
};

const getInitialCheckerItems = (
  array: IArrayItem[],
  keyIdentifier: ArrayItemKey,
  reserveKeyIdentifier?: ArrayItemKey
) => {
  const initialCheckerItems: CheckerItems = {};

  array.forEach((item) => (initialCheckerItems[getKeyIdentifier(item, keyIdentifier, reserveKeyIdentifier)] = false));

  return initialCheckerItems;
};

const useArrayItemsChecker = (
  array: IArrayItem[],
  keyIdentifier: ArrayItemKey,
  reserveKeyIdentifier?: ArrayItemKey
) => {
  const [items, setItems] = useState(getInitialCheckerItems(array, keyIdentifier, reserveKeyIdentifier));

  const checkOnce: ICheckOnce = useCallback(
    (valueOfKeyIdentifier: IArrayItem[ArrayItemKey], isChecked: boolean) =>
      setItems((prevState) => ({
        ...prevState,
        [valueOfKeyIdentifier]: isChecked,
      })),
    []
  );

  const checkedCount = useMemo(() => Object.values(items).filter((item) => item).length, [items]);

  const checkAll: ICheckAll = useCallback(
    (isChecked) =>
      setItems((prevState) => {
        const updatedCheckerItems = { ...prevState };

        for (const checkerItemKey in updatedCheckerItems) {
          updatedCheckerItems[checkerItemKey] = isChecked;
        }

        return updatedCheckerItems;
      }),
    []
  );

  const reset: IReset = useCallback(
    () => setItems(getInitialCheckerItems(array, keyIdentifier, reserveKeyIdentifier)),
    [array, keyIdentifier, reserveKeyIdentifier]
  );

  const allCount = useMemo(() => Object.values(items).length, [items]);

  useEffect(() => {
    const initialCheckerItems = getInitialCheckerItems(array, keyIdentifier, reserveKeyIdentifier);

    setItems((prevState) => ({ ...initialCheckerItems, ...prevState }));
  }, [array, keyIdentifier, reserveKeyIdentifier]);

  return { items, checkedCount, checkOnce, checkAll, reset, allCount };
};

export default useArrayItemsChecker;
export type { IArrayItem, CheckerItems, ArrayItemKey, ICheckOnce, ICheckAll, IReset };
