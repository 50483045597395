const doubleInputIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.5 6C5.32843 6 6 5.32843 6 4.5C6 3.67157 5.32843 3 4.5 3C3.67157 3 3 3.67157 3 4.5C3 5.32843 3.67157 6 4.5 6ZM4.5 8C6.0855 8 7.42477 6.94576 7.85505 5.50002H9C10.1046 5.50002 11 6.39545 11 7.50002V16.5C11 18.7092 12.7909 20.5 15 20.5H16.1449C16.5752 21.9458 17.9145 23 19.5 23C21.433 23 23 21.433 23 19.5C23 17.567 21.433 16 19.5 16C17.9145 16 16.5752 17.0543 16.1449 18.5H15C13.8954 18.5 13 17.6046 13 16.5V7.50002C13 5.29088 11.2091 3.50002 9 3.50002H7.85507C7.4248 2.05427 6.08552 1 4.5 1C2.567 1 1 2.567 1 4.5C1 6.433 2.567 8 4.5 8ZM21 19.5C21 20.3284 20.3284 21 19.5 21C18.6716 21 18 20.3284 18 19.5C18 18.6716 18.6716 18 19.5 18C20.3284 18 21 18.6716 21 19.5Z"
      fill="#A4A4A4"
    />
  </svg>
);

export default doubleInputIcon;
