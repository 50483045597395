import axios from "axios";

import { addCommentActions } from "../actions";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

export const createPackingItemComment = (packingItemId, comment) => (dispatch) =>
  axios
    .post(`/purchaser/packinglists/packingitems/${packingItemId}/comments/`, { comment, item_id: packingItemId })
    .then((response) => dispatch(addCommentActions({ comment: response.data, itemId: packingItemId })))
    .catch(errorCatcher);
