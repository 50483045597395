import React from 'react'
import { apiGetProduct } from 'redux/modules/common/requisition/product/productApi';

export const useRequisitionRowProducts = (requisitionId: number) => {
    const [products, setProducts] = React.useState<any[]>([]);

    const loadProductsHandler = React.useCallback(
      () => apiGetProduct(requisitionId).then(setProducts),
      [requisitionId]
    );

    return {
        loadProductsHandler,
        products 
    }
}

