import { IIdAndName } from "../../../types/idAndName";

export type ApprovalType =
  | "is_responsible"
  | "is_pto"
  | "is_payment_decision_employee"
  | "is_timesheet_responsible"
  | "is_stock_responsible"
  | "is_estimate_responsible"
  | "is_requisition_approval"
  | "is_order_approval"
  | "is_payment_approval"
  | "is_timesheet_approval"
  | "is_planconfirm_approval"
  | "is_ticket_approval";

export type UserProjectApprovals = IIdAndName & Record<ApprovalType, boolean>;

export const mapApprovalsToObjects = (userProjectList: UserProjectApprovals[]) => {
  const approvals: Record<number, Record<ApprovalType, boolean>> = {};
  userProjectList.forEach((project) => {
    let approvalsOnProject: Record<ApprovalType, boolean> & Partial<IIdAndName> = Object.assign({}, project);
    delete approvalsOnProject.id;
    delete approvalsOnProject.name;
    approvals[project.id] = approvalsOnProject;
  });
  return approvals;
};
