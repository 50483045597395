import moment from "moment";

import { TASK_LISTS_TYPES, tasksAggregationSectionsEnum } from "components/pages/Tasks/constants";

import {
  addCommentToTask,
  addFileIdToTaskModalBuffer,
  addMoreTasksByList,
  addNewTaskInAllBuildings,
  addNewTaskInCertainBuilding,
  addNewTaskInTasksList,
  createOneTaskList,
  deleteOneTaskInAllBuildings,
  deleteOneTaskInCertainBuilding,
  deleteOneTaskInTasksList,
  deleteOneTaskList,
  filterTasksBySectionActionCreator,
  openTaskModal,
  setCertainTask,
  setCertainTaskLoading,
  setTaskModalFileIdBuffer,
  setTasksByAllBuildings,
  setTasksByBuilding,
  setTasksByList,
  updateCreatedTaskListId,
  updateTaskInAllBuildings,
  updateTaskInCertainBuilding,
  updateTaskInTasksList,
  updateTasksListInfo,
} from "./actions";

import { ITaskComment, ITasksInitialState, ItaskAggregationsParams, ItaskResponse } from "./types";
import { MODULES_ENUM } from "types/enums/ModulesEnum";

const EMPTY_RESULTS_LIST = { results: [], count: 0 };

export const updateTasksByBuilding = (
  state: ITasksInitialState,
  action: ReturnType<typeof setTasksByBuilding>
): ITasksInitialState => {
  const tasks = { ...state.tasks };
  const byBuilding = tasks[action.payload.building_id] || {};
  return {
    ...state,
    tasks: {
      ...state.tasks,
      [action.payload.building_id]: {
        ...byBuilding,
        [action.payload.type]: {
          results: action.payload.tasks,
          count: action.payload.count,
        },
      },
    },
  };
};

export const addMoreTasksByBuilding = (
  state: ITasksInitialState,
  action: ReturnType<typeof setTasksByBuilding>
): ITasksInitialState => {
  const tasks = { ...state.tasks };
  const byBuilding = tasks[action.payload.building_id] || {};
  const byType = byBuilding[action.payload.type] || EMPTY_RESULTS_LIST;
  return {
    ...state,
    tasks: {
      ...state.tasks,
      [action.payload.building_id]: {
        ...byBuilding,
        [action.payload.type]: {
          results: [...byType.results, ...action.payload.tasks],
          count: action.payload.count,
        },
      },
    },
  };
};

export const updateTasksByAllBuildings = (
  state: ITasksInitialState,
  action: ReturnType<typeof setTasksByAllBuildings>
): ITasksInitialState => {
  return {
    ...state,
    tasksByAllBuildings: {
      ...state.tasksByAllBuildings,
      [action.payload.type]: {
        results: action.payload.tasks,
        count: action.payload.count,
        next: action.payload.next,
      },
    },
  };
};

export const addMoreTasksByAllBuildings = (
  state: ITasksInitialState,
  action: ReturnType<typeof setTasksByAllBuildings>
): ITasksInitialState => {
  const tasks = { ...state.tasksByAllBuildings };
  const byType = tasks[action.payload.type] || EMPTY_RESULTS_LIST;
  return {
    ...state,
    tasksByAllBuildings: {
      ...state.tasksByAllBuildings,
      [action.payload.type]: {
        results: [...byType.results, ...action.payload.tasks],
        count: action.payload.count,
        next: action.payload.next,
      },
    },
  };
};

export const updateTasksByList = (
  state: ITasksInitialState,
  action: ReturnType<typeof setTasksByList>
): ITasksInitialState => {
  return {
    ...state,
    tasksInLists: {
      ...state.tasksInLists,
      [action.payload.listId]: action.payload.data,
    },
  };
};

export const updateMoreTasksByList = (
  state: ITasksInitialState,
  action: ReturnType<typeof addMoreTasksByList>
): ITasksInitialState => {
  const tasks = { ...state.tasksInLists };
  const byList = tasks[action.payload.listId] || EMPTY_RESULTS_LIST;
  return {
    ...state,
    tasksInLists: {
      ...state.tasksInLists,
      [action.payload.listId]: { ...byList, results: [...byList.results, ...action.payload.data] },
    },
  };
};

export const renewTasksListInfo = (
  state: ITasksInitialState,
  action: ReturnType<typeof updateTasksListInfo>
): ITasksInitialState => {
  const tempLists = state.tasksLists.results.map((el) => {
    if (el.id === action.payload.id) {
      return {
        ...el,
        list_name: action.payload.list_name,
      };
    } else {
      return el;
    }
  });
  return {
    ...state,
    tasksLists: {
      ...state.tasksLists,
      results: tempLists,
    },
  };
};

export const removeOneTasksList = (
  state: ITasksInitialState,
  action: ReturnType<typeof deleteOneTaskList>
): ITasksInitialState => {
  const tempLists = state.tasksLists.results.filter((el) => el.id !== action.payload);
  return {
    ...state,
    tasksLists: {
      ...state.tasksLists,
      results: tempLists,
      count: state.tasksLists.count - 1,
    },
  };
};

export const createTaskList = (
  state: ITasksInitialState,
  action: ReturnType<typeof createOneTaskList>
): ITasksInitialState => {
  const tempLists = [{ ...action.payload, tasks_count: 0 }, ...state.tasksLists.results];
  return {
    ...state,
    tasksLists: {
      ...state.tasksLists,
      results: tempLists,
      count: state.tasksLists.count + 1,
    },
  };
};

export const updateIdInCreatedTasksList = (
  state: ITasksInitialState,
  action: ReturnType<typeof updateCreatedTaskListId>
): ITasksInitialState => {
  const tempLists = state.tasksLists.results.map((el) => {
    if (el.id === action.payload.oldId) {
      return { ...el, id: action.payload.newId };
    } else {
      return el;
    }
  });
  return {
    ...state,
    tasksLists: {
      ...state.tasksLists,
      results: tempLists,
    },
  };
};

export const removeOneTaskFromTasksList = (
  state: ITasksInitialState,
  action: ReturnType<typeof deleteOneTaskInTasksList>
): ITasksInitialState => {
  const byList = state.tasksInLists[action.payload.list_id] || EMPTY_RESULTS_LIST;
  const newTasks = byList.results.filter((el) => el.id !== action.payload.id);

  const newLists = state.tasksLists.results.map((el) => {
    if (el.id === action.payload.list_id) {
      return {
        ...el,
        tasks_count: el.tasks_count - 1,
      };
    } else {
      return el;
    }
  });

  return {
    ...state,
    tasksLists: {
      ...state.tasksLists,
      results: newLists,
    },
    tasksInLists: {
      ...state.tasksInLists,
      [action.payload.list_id]: {
        ...byList,
        results: newTasks,
        count: byList.count - 1,
      },
    },
  };
};

export const removeOneTaskByAllBuildings = (
  state: ITasksInitialState,
  action: ReturnType<typeof deleteOneTaskInAllBuildings>
): ITasksInitialState => {
  const byType = state.tasksByAllBuildings[action.payload.type] || EMPTY_RESULTS_LIST;
  const newTasks = byType.results.filter((el) => el.id !== action.payload.id);
  return {
    ...state,
    tasksByAllBuildings: {
      ...state.tasksByAllBuildings,
      [action.payload.type]: {
        ...byType,
        results: newTasks,
        count: byType.count - 1,
      },
    },
  };
};

export const removeOneTaskByCertainBuilding = (
  state: ITasksInitialState,
  action: ReturnType<typeof deleteOneTaskInCertainBuilding>
): ITasksInitialState => {
  const byId = state.tasks[action.payload.building_id] || {};
  const byType = byId[action.payload.type] || EMPTY_RESULTS_LIST;
  const newTasks = byType.results.filter((el) => el.id !== action.payload.id);
  return {
    ...state,
    tasks: {
      ...state.tasks,
      [action.payload.building_id]: {
        ...byId,
        [action.payload.type]: {
          ...byType,
          results: newTasks,
          count: byType.count - 1,
        },
      },
    },
  };
};

export const setOneTask = (
  state: ITasksInitialState,
  action: ReturnType<typeof setCertainTask>
): ITasksInitialState => {
  return {
    ...state,
    certainTasks: {
      ...state.certainTasks,
      [action.payload.id]: { ...action.payload, task_comments: action.payload.task_comments.reverse() },
    },
  };
};

export const setOneTaskLoading = (
  state: ITasksInitialState,
  action: ReturnType<typeof setCertainTaskLoading>
): ITasksInitialState => {
  return {
    ...state,
    isCertainTaskLoading: {
      ...state.isCertainTaskLoading,
      [action.payload.id]: action.payload.status,
    },
  };
};

export const insertTaskFromTasksList = (
  state: ITasksInitialState,
  action: ReturnType<typeof addNewTaskInTasksList>
): ITasksInitialState => {
  const byList = state.tasksInLists[action.payload.list_id] || EMPTY_RESULTS_LIST;
  const newTasks = [action.payload.data, ...byList.results].sort((a, b) => a.building - b.building);
  const newResults = (state.tasksLists.results || []).map((el) => {
    if (el.id == action.payload.list_id) {
      return {
        ...el,
        tasks_count: el.tasks_count + 1,
      };
    } else return el;
  });
  return {
    ...state,
    tasksInLists: {
      ...state.tasksInLists,
      [action.payload.list_id]: {
        ...byList,
        results: newTasks,
        count: byList.count + 1,
      },
    },
    tasksLists: {
      ...state.tasksLists,
      results: newResults,
    },
  };
};

export const replaceTaskFromTasksList = (
  state: ITasksInitialState,
  action: ReturnType<typeof updateTaskInTasksList>
): ITasksInitialState => {
  const byList = state.tasksInLists[action.payload.list_id] || EMPTY_RESULTS_LIST;

  let updatedTasks = byList.results
    .map((x) => {
      if (x.id === action.payload.data.id)
        return { ...action.payload.data, number: x.number, executor_user: x.executor_user };
      return x;
    })
    .sort((a, b) => a.building - b.building);

  if (state.tasksFilters.status?.length)
    updatedTasks = updatedTasks.filter((x) => state.tasksFilters.status?.includes(x.status));

  return {
    ...state,
    tasksInLists: {
      ...state.tasksInLists,
      [action.payload.list_id]: {
        ...byList,
        results: updatedTasks,
      },
    },
  };
};

export const insertTaskByAllBuildings = (
  state: ITasksInitialState,
  action: ReturnType<typeof addNewTaskInAllBuildings>
): ITasksInitialState => {
  const byType = state.tasksByAllBuildings[action.payload.type] || EMPTY_RESULTS_LIST;
  const newTasks = [action.payload.data, ...byType.results].sort((a, b) => a.building - b.building);
  return {
    ...state,
    tasksByAllBuildings: {
      ...state.tasksByAllBuildings,
      [action.payload.type]: {
        ...byType,
        results: newTasks,
        count: byType.count + 1,
      },
    },
  };
};

export const replaceTaskByAllBuildings = (
  state: ITasksInitialState,
  action: ReturnType<typeof updateTaskInAllBuildings>
): ITasksInitialState => {
  const byType = state.tasksByAllBuildings[action.payload.type] || EMPTY_RESULTS_LIST;
  let updatedTasks = byType.results
    .map((x) => {
      if (x.id === action.payload.data.id)
        return { ...action.payload.data, number: x.number, executor_user: x.executor_user };
      return x;
    })
    .sort((a, b) => a.building - b.building);

  if (state.tasksFilters.status?.length)
    updatedTasks = updatedTasks.filter((x) => state.tasksFilters.status?.includes(x.status));

  if (action.payload.type === TASK_LISTS_TYPES.MY)
    updatedTasks = updatedTasks.filter((x) => x.executor === action.payload.currentUserId);
  if (action.payload.type === TASK_LISTS_TYPES.WATCHING)
    updatedTasks = updatedTasks.filter((x) => x?.viewers?.indexOf(action.payload.currentUserId) !== -1);

  return {
    ...state,
    tasksByAllBuildings: {
      ...state.tasksByAllBuildings,
      [action.payload.type]: {
        ...byType,
        results: updatedTasks,
      },
    },
  };
};

export const insertTaskByCertainBuilding = (
  state: ITasksInitialState,
  action: ReturnType<typeof addNewTaskInCertainBuilding>
): ITasksInitialState => {
  const byId = state.tasks[action.payload.building_id] || {};
  const byType = byId[action.payload.type] || EMPTY_RESULTS_LIST;
  const newTasks = [action.payload.data, ...byType.results].sort((a, b) => a.building - b.building);
  return {
    ...state,
    tasks: {
      ...state.tasks,
      [action.payload.building_id]: {
        ...byId,
        [action.payload.type]: {
          ...byType,
          results: newTasks,
          count: byType.count + 1,
        },
      },
    },
  };
};

export const replaceTaskByCertainBuilding = (
  state: ITasksInitialState,
  action: ReturnType<typeof updateTaskInCertainBuilding>
): ITasksInitialState => {
  const byId = state.tasks[action.payload.building_id] || {};
  const byType = byId[action.payload.type] || EMPTY_RESULTS_LIST;

  let updatedTasks = byType.results
    .map((x) => {
      if (x.id === action.payload.data.id)
        return { ...action.payload.data, number: x.number, executor_user: x.executor_user };
      return x;
    })
    .sort((a, b) => a.building - b.building);

  if (state.tasksFilters.status?.length)
    updatedTasks = updatedTasks.filter((x) => state.tasksFilters.status?.includes(x.status));

  if (action.payload.type === TASK_LISTS_TYPES.MY)
    updatedTasks = updatedTasks.filter((x) => x.executor === action.payload.currentUserId);

  if (action.payload.type === TASK_LISTS_TYPES.WATCHING)
    updatedTasks = updatedTasks.filter((x) => x?.viewers?.indexOf(action.payload.currentUserId) !== -1);

  return {
    ...state,
    tasks: {
      ...state.tasks,
      [action.payload.building_id]: {
        ...byId,
        [action.payload.type]: {
          ...byType,
          results: updatedTasks,
        },
      },
    },
  };
};

export const insertCommentToTask = (
  state: ITasksInitialState,
  action: ReturnType<typeof addCommentToTask>
): ITasksInitialState => {
  const byId = state.certainTasks[action.payload.task] || {};
  return {
    ...state,
    certainTasks: {
      ...state.certainTasks,
      [action.payload.task]: {
        ...byId,
        task_comments: [
          { ...action.payload, date: moment().format("YYYY-MM-DDTHH:mm:ss.869172Z") },
          ...byId.task_comments,
        ],
      },
    },
  };
};

export const insertTaskCommentState = (
  state: ITasksInitialState,
  action: {
    type: string;
    payload: {
      comments: ITaskComment[];
      task: number;
    };
  }
): ITasksInitialState => {
  const byId = state.certainTasks[action.payload.task] || {};
  return {
    ...state,
    certainTasks: {
      ...state.certainTasks,
      [action.payload.task]: {
        ...byId,
        task_comments: action.payload.comments,
      },
    },
  };
};

export const openModal = (state: ITasksInitialState, action: ReturnType<typeof openTaskModal>): ITasksInitialState => {
  const { modalType, taskId, placementType, listType, list_id } = action.payload;
  return {
    ...state,
    modal: {
      ...state.modal,
      isOpen: true,
      type: modalType,
      taskId: taskId as number,
      placementType,
      listType,
      list_id,
      fileIdBufferEdit: [],
      submission: { isSubmitted: false, taskId: -1 },
    },
  };
};

export const addFileIdToBuffer = (
  state: ITasksInitialState,
  action: ReturnType<typeof addFileIdToTaskModalBuffer>
): ITasksInitialState => {
  const newFileBuffer = state.modal.fileIdBufferEdit.concat([action.payload]);
  return {
    ...state,
    modal: {
      ...state.modal,
      fileIdBufferEdit: newFileBuffer,
    },
  };
};

export const setFileIdBuffer = (
  state: ITasksInitialState,
  action: ReturnType<typeof setTaskModalFileIdBuffer>
): ITasksInitialState => {
  return {
    ...state,
    modal: {
      ...state.modal,
      fileIdBufferEdit: action.payload,
    },
  };
};

export const filterTasksBySection = (
  state: ITasksInitialState,
  action: ReturnType<typeof filterTasksBySectionActionCreator>
): ITasksInitialState => {
  let copiedArr = state.tasksByAllBuildings[action.payload.type]
    ? [...state.tasksByAllBuildings[action.payload.type]!.results]
    : [];
  const index = copiedArr.findIndex((el) => +el.building === +action.payload.building_id);
  const tempTask: ItaskResponse = {
    ...copiedArr[index],
    isHidden: true,
  } as ItaskResponse;
  const filteredResponse = action.payload.data.results.filter((el) => +el.building === +action.payload.building_id);
  copiedArr = copiedArr.filter((el) => +el.building !== +action.payload.building_id);
  if (filteredResponse.length) {
    copiedArr.splice(index, 0, ...filteredResponse);
    copiedArr = copiedArr.filter((el) => !el.isHidden || +el.building !== +action.payload.building_id);
  } else {
    copiedArr.splice(index, 0, tempTask);
  }
  const lengthOfHiddenTasks = copiedArr.filter((el) => el.isHidden).length;
  return {
    ...state,
    tasksByAllBuildings: {
      ...state.tasksByAllBuildings,
      [action.payload.type]: {
        ...(state.tasksByAllBuildings[action.payload.type] || {}),
        results: copiedArr,
        count: action.payload.data.count + lengthOfHiddenTasks,
      },
    },
  };
};

export const generateTaskAggregationsParams = (): ItaskAggregationsParams => {
  const locationArray = window.location.pathname.split("/").filter((el) => !!el);
  const isTasksModule = locationArray[1] === MODULES_ENUM.TASKS;

  const section =
    locationArray[1] !== TASK_LISTS_TYPES.LISTS && isTasksModule
      ? locationArray[1]
      : tasksAggregationSectionsEnum.tasks_lists;
  const buildingInLocation = isTasksModule ? Number(locationArray[2]) : Number(locationArray[1]);
  const building_id = !isNaN(buildingInLocation!) && buildingInLocation !== 0 ? buildingInLocation : undefined;

  return {
    section: section as tasksAggregationSectionsEnum,
    building_id,
  };
};
