export default (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <g id="Group_17473" data-name="Group 17473" transform="translate(-1619.074 -37.26)">
            <g id="Union_665" data-name="Union 665" transform="translate(-22581.426 19649.043)" fill="none">
                <path d="M24213.5-19611.783a13,13,0,0,1,13,13,13,13,0,0,1-13,13,13,13,0,0,1-13-13A13,13,0,0,1,24213.5-19611.783Z" stroke="none"/>
                <path d="M 24213.5 -19610.783203125 C 24210.294921875 -19610.783203125 24207.28125 -19609.53515625 24205.015625 -19607.267578125 C 24202.748046875 -19605.001953125 24201.5 -19601.98828125 24201.5 -19598.783203125 C 24201.5 -19595.578125 24202.748046875 -19592.564453125 24205.015625 -19590.298828125 C 24207.28125 -19588.03125 24210.294921875 -19586.783203125 24213.5 -19586.783203125 C 24216.705078125 -19586.783203125 24219.71875 -19588.03125 24221.984375 -19590.298828125 C 24224.251953125 -19592.564453125 24225.5 -19595.578125 24225.5 -19598.783203125 C 24225.5 -19601.98828125 24224.251953125 -19605.001953125 24221.984375 -19607.267578125 C 24219.71875 -19609.53515625 24216.705078125 -19610.783203125 24213.5 -19610.783203125 M 24213.5 -19611.783203125 C 24220.6796875 -19611.783203125 24226.5 -19605.962890625 24226.5 -19598.783203125 C 24226.5 -19591.603515625 24220.6796875 -19585.783203125 24213.5 -19585.783203125 C 24206.3203125 -19585.783203125 24200.5 -19591.603515625 24200.5 -19598.783203125 C 24200.5 -19605.962890625 24206.3203125 -19611.783203125 24213.5 -19611.783203125 Z" stroke="none" fill="#03c3ff"/>
            </g>
            <g id="Path_8602" data-name="Path 8602" transform="translate(1510.539 -204.91)" fill="#fff">
                <path d="M 119.0355377197266 259.9081115722656 C 118.9863357543945 259.9080810546875 118.9452285766602 259.8806457519531 118.9133682250977 259.8264770507812 L 116.7226257324219 256.1036682128906 C 116.6607437133789 255.9985809326172 116.6882553100586 255.8490905761719 116.7802047729492 255.789794921875 C 116.8011169433594 255.7763214111328 116.8209381103516 255.7699432373047 116.8421859741211 255.7699432373047 C 116.8644332885742 255.7699432373047 116.9208755493164 255.7779235839844 116.9644165039062 255.8519134521484 L 118.3122482299805 258.1424865722656 L 119.0509567260742 259.3978881835938 L 119.9570770263672 258.2574157714844 L 126.123893737793 250.4955139160156 C 126.1617965698242 250.4477996826172 126.2020874023438 250.4377899169922 126.2292175292969 250.4377899169922 C 126.2577972412109 250.4377899169922 126.2842864990234 250.44873046875 126.310188293457 250.4712524414062 C 126.3969650268555 250.5466918945312 126.407844543457 250.7011108398438 126.3330688476562 250.7952880859375 L 119.1388549804688 259.850341796875 C 119.1075057983398 259.8898010253906 119.0747222900391 259.9081115722656 119.0355377197266 259.9081115722656 Z" stroke="none"/>
                <path d="M 119.0354309082031 260.9080810546875 C 118.6540832519531 260.9080505371094 118.2746124267578 260.7127685546875 118.051513671875 260.3336486816406 L 115.8607788085938 256.61083984375 C 115.5265121459961 256.0431518554688 115.6956634521484 255.2992553710938 116.2382888793945 254.9493713378906 C 116.7813415527344 254.5995635986328 117.4921188354492 254.7769775390625 117.8262329101562 255.3446960449219 L 119.1741027832031 257.6353454589844 L 125.3409271240234 249.8734436035156 C 125.7484130859375 249.3605194091797 126.476318359375 249.29052734375 126.9663238525391 249.7166137695312 C 127.45654296875 250.1427917480469 127.5236587524414 250.904052734375 127.1161651611328 251.4171752929688 L 119.9218292236328 260.4723815917969 C 119.6892471313477 260.7652893066406 119.3616714477539 260.9081420898438 119.0354309082031 260.9080810546875 Z" stroke="none" fill="#03c3ff"/>
            </g>
        </g>
    </svg>
);
