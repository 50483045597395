import React from "react";

import ProductRow from "./ProductRow/ProductRow";

import styles from "./ProductTable.module.scss";

const ProductTable = ({ section, type, products, setProducts }) => {
  return (
    <div className={styles.productTable}>
      <div className={styles.header}>
        <div className={styles.nameCol}>Наименование</div>
        <div className={styles.countCol}>Кол-во</div>
        <div className={styles.measureCol}>Ед. изм.</div>
        {type !== "out-of-estimate" && <div className={styles.projectCountCol}>По проекту</div>}
      </div>
      {section?.products?.map((item) => (
        <ProductRow data={item} type={type} products={products} setProducts={setProducts} key={item.id} />
      ))}
    </div>
  );
};

export default React.memo(ProductTable);
