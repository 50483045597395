import { summaryUtils } from "pages/Finance/Summary/model/utils";
import { generateFinanceKey } from "pages/Finance/common/model/commonFinanceUtils";
import { IFinanceTreeItem, IPreparedMonthData } from "pages/Finance/common/model/interfaces";

import { IForecastCountExps, IForecastObjectResponse } from "./types";

type expGroupsTypes = Exclude<keyof IForecastCountExps, "all" | "sections" | "subsections" | "groups">;

const expGroupsLabels: Record<expGroupsTypes, string> = {
  equipment: "Оборудование",
  machine: "Машины",
  material: "Материалы",
  transport: "Транспорт",
  work: "Работы",
};

export const forecastUtils = {
  setObject: (
    tree: IFinanceTreeItem[],
    data: IForecastObjectResponse,
    projectId: number,
    objectId: number
  ): IFinanceTreeItem[] => {
    const parentProjectIndex = tree.findIndex((el) => el.id === +projectId);
    const anyExtras = forecastUtils.mapAnyExtras(data);
    const sections = data.sections.map((section) => forecastUtils.mapSections(section, { object: data }));
    const children = [...sections, ...anyExtras];

    if (parentProjectIndex === -1) {
      return [
        ...tree,
        {
          id: projectId,
          name: data.name,
          type: "project",
          key: generateFinanceKey(projectId, "project"),
          depth: -2,
          shouldBeLoaded: true,
          isOpen: true, //oldProject?.isOpen || isOnlyOneProject,
          data: {}, //summaryUtils.getProjectData(project),
          children: [
            {
              id: objectId,
              name: data.name,
              type: "object",
              key: generateFinanceKey(objectId, "object"),
              depth: -1,
              isOpen: true, //oldObject?.isOpen || isOnlyOneObject,
              data: summaryUtils.getProjectData(data),
              children,
            },
          ],
        },
      ];
    } else {
      return tree.map((proj, i) => {
        if (i === parentProjectIndex) {
          return {
            ...proj,
            children: [
              ...(proj.children ?? []).map((obj) => {
                if (obj.id === objectId) {
                  return {
                    ...obj,
                    type: "object" as const,
                    depth: -1,
                    children,
                  };
                } else {
                  return obj;
                }
              }),
            ],
          };
        } else {
          return proj;
        }
      });
    }
  },

  mapSections: (
    section: IForecastObjectResponse["sections"][number],
    ctx: { object: IForecastObjectResponse }
  ): IFinanceTreeItem => {
    const { id, name } = section;

    return {
      id,
      name,
      type: "object",
      key: generateFinanceKey(id, "object"),
      depth: 0,
      isOpen: ctx.object.sections.length < 2,
      data: summaryUtils.getProjectData(section),
      children: section.subsections.map((el) => forecastUtils.mapSubsections(el, { section })),
    };
  },

  mapSubsections: (
    section: IForecastObjectResponse["sections"][number]["subsections"][number],
    ctx: { section: IForecastObjectResponse["sections"][number] }
  ): IFinanceTreeItem => {
    const { id, name } = section;

    const expsGroupedByTypes: Array<IFinanceTreeItem> = Object.entries(section.type_expenditures).map(
      ([type, expGroup]) => {
        const exps = expGroup?.expenditures.map<IFinanceTreeItem>((el) => ({
          id: el.id,
          name: el.name,
          depth: 4,
          data: summaryUtils.getProjectData(el),
          type: "expenditure",
          key: generateFinanceKey(el.id, type as "work"),
          number: +el.number,
        }));

        const groups = expGroup?.groups.map<IFinanceTreeItem>((group) => ({
          id: group.id,
          name: group.name,
          depth: 4,
          data: summaryUtils.getProjectData(group),
          type: "group",
          key: generateFinanceKey(group.id, type as "group"),
          children: group.expenditures.map<IFinanceTreeItem>((exp) => ({
            id: exp.id,
            name: exp.name,
            depth: 6,
            data: summaryUtils.getProjectData(exp),
            type: "expenditure",
            key: generateFinanceKey(exp.id, "expenditure"),
          })),
        }));

        return {
          id: section.id,
          name: expGroupsLabels[type as expGroupsTypes],
          key: generateFinanceKey(section.id, type as "work"),
          type: "expenditureList",
          depth: 3,
          data: summaryUtils.getProjectData(expGroup),
          children: [...exps, ...groups],
        };
      }
    );

    return {
      id,
      name,
      type: "object",
      key: generateFinanceKey(id, "object"),
      depth: 1,
      isOpen: ctx.section.subsections.length < 2,
      data: summaryUtils.getProjectData(section),
      children: expsGroupedByTypes,
    };
  },

  getDataFromAnyExtras: (extra: Pick<IForecastObjectResponse["advance_payments"], "q_data" | "m_data">) => {
    const acc: IPreparedMonthData = {};

    extra.m_data.forEach((el) => {
      acc[`m${el.month}_diff_${el.year}` as keyof IPreparedMonthData] = el.diff;
      acc[`m${el.month}_fact_${el.year}` as keyof IPreparedMonthData] = el.fact;
      acc[`m${el.month}_plan_${el.year}` as keyof IPreparedMonthData] = el.plan;
    });

    Object.entries(extra.q_data).forEach(([key, data]) => {
      // TODO_V2_FINANCE YEAR
      acc[`${key}_diff` as keyof IPreparedMonthData] = data.diff;
      acc[`${key}_fact` as keyof IPreparedMonthData] = data.fact;
      acc[`${key}_plan` as keyof IPreparedMonthData] = data.plan;
    });

    return acc;
  },

  mapAnyExtras: (object: IForecastObjectResponse): IFinanceTreeItem[] => {
    const advance: IFinanceTreeItem = {
      id: object.id,
      name: "Авансирование",
      key: generateFinanceKey(object.id, "advance"),
      depth: 0,
      type: "advance",
      data: forecastUtils.getDataFromAnyExtras(object.advance_payments),
    };

    const warranty: IFinanceTreeItem = {
      id: object.id,
      name: "Гарантийные обязательства",
      key: generateFinanceKey(object.id, "warranty"),
      depth: 0,
      type: "warranty",
      data: forecastUtils.getDataFromAnyExtras(object.warranties),
    };

    const extra: IFinanceTreeItem = {
      id: object.id,
      name: "Дополнительные расходы",
      key: generateFinanceKey(object.id, "extraRoot"),
      depth: 0,
      type: "extraRoot",
      data: summaryUtils.getProjectData(object.extra_costs),
      children: object.extra_costs.sections.map<IFinanceTreeItem>((section, sectionIndex) => ({
        id: sectionIndex,
        type: "extraSection",
        key: generateFinanceKey(sectionIndex, "extraSection"),
        name: section.name,
        data: summaryUtils.getProjectData(section),
        depth: 1,
        children: section.subsections.map<IFinanceTreeItem>((sub, subIndex) => ({
          id: subIndex,
          type: "extraSubsection",
          key: generateFinanceKey(+(sectionIndex + "" + subIndex), "extraSubsection"),
          name: sub.name,
          data: summaryUtils.getProjectData(sub),
          depth: 2,
          children: sub.costs.map<IFinanceTreeItem>((cost) => ({
            id: cost.id,
            name: cost.name,
            type: "extraExpenditure",
            key: generateFinanceKey(cost.id, "extraExpenditure"),
            data: summaryUtils.getProjectData(cost),
            depth: 3,
          })),
        })),
      })),
    };

    const res: IFinanceTreeItem[] = [];

    if (!!object.advance_payments.m_data.length) {
      res.push(advance);
    }

    if (!!object.warranties.m_data.length) {
      res.push(warranty);
    }

    if (!!object.extra_costs.data.length) {
      res.push(extra);
    }

    return res;
  },

  updateExtras: (
    tree: IFinanceTreeItem[],
    data: IForecastObjectResponse,
    projectId: number,
    objectId: number
  ): IFinanceTreeItem[] => {
    return tree.map((pr) => {
      if (pr.id === projectId) {
        return {
          ...pr,
          children: pr.children?.map((obj) => {
            if (obj.id === objectId) {
              const anyExtras = forecastUtils.mapAnyExtras(data);
              const sections = data.sections.map((section) => forecastUtils.mapSections(section, { object: data }));
              const children = [...sections, ...anyExtras];
              return { ...obj, children };
            } else {
              return obj;
            }
          }),
        };
      } else {
        return pr;
      }
    });
  },

  extractTreeByProject: (tree: IFinanceTreeItem[] | undefined, projectId: number): IFinanceTreeItem[] => {
    if (!tree) return [];
    return (
      tree
        .find((project) => project.id === projectId)
        ?.children?.map((obj) => ({
          ...obj,
          children: obj.children?.filter(
            (sec) => sec.type !== "extraRoot" && sec.type !== "advance" && sec.type !== "warranty"
          ),
        })) ?? []
    );
  },
};
