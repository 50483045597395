import { message } from "antd";
import axios, { AxiosResponse } from "axios";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { compose } from "redux";

import {
  IAddOurContractors,
  IBuildingContractor,
  IChangeFilter,
  IDeleteOurContractor,
} from "components/pages/Settings/EditProject/types";

import { ListResponse } from "types/ListResponse";
import IContactManager from "types/interfaces/ContactManager";
import IContractor from "types/interfaces/Contractor";

import { errorCatcher } from "utils/helpers/errorCatcher";

const defaultFilter = {
  limit: 10,
  offset: 0,
};

const serializeOurContractors = (
  contractors: (IBuildingContractor & { contact_manager: IContactManager })[]
): IContractor[] =>
  contractors.map(({ entity, ...contractor }) => ({
    ...contractor,
    entity_id: entity.id,
    itn: entity.itn,
    name: entity.name,
    iec: entity.iec,
    contact_manager: contractor.contact_manager,
  }));

const mergeContactManagerFrom = (allContractors: IContractor[], ourContractors: IBuildingContractor[]) => {
  const allContractorsDictionary: Record<IContractor["id"], IContractor> = {};
  allContractors.forEach((contractor) => (allContractorsDictionary[contractor.id] = contractor));

  return ourContractors.map((contractor) => ({
    ...contractor,
    contact_manager: allContractorsDictionary[contractor.entity?.id]
      ? allContractorsDictionary[contractor.entity.id].contact_manager
      : contractor.entity?.contact_manager,
  }));
};

const useProjectContractors = (buildingId: string) => {
  const [ourContractors, setOurContractors] = useState<IContractor[]>([]);
  const [ourContractorsCount, setOurContractorsCount] = useState(0);
  const [ourContractorsLoading, setOurContractorsLoading] = useState(true);
  const [ourContractorsFilter, setOurContractorsFilter] = useState(defaultFilter);

  const [allContractorsCount, setAllContractorsCount] = useState(0);
  const [availableForAddContractorsCount, setAvailableForAddContractorsCount] = useState(0);
  const [allContractorsFilter, setAllContractorsFilter] = useState({});
  const [allContractors, setAllContractors] = useState<IContractor[]>([]);
  const [availableForAddContractors, setAvailableForAddContractors] = useState<IContractor[]>([]);

  useEffect(() => {
    if (allContractors.length === 0) {
      setOurContractorsLoading(false);
      return;
    }
    setOurContractorsLoading(true);

    axios
      .get(`/building/${buildingId}/contractors/`, { params: ourContractorsFilter })
      .then((response) => {
        const contractorsWithMergedContactManager = mergeContactManagerFrom(allContractors, response.data.results);
        compose(setOurContractors, serializeOurContractors)(contractorsWithMergedContactManager);
        setOurContractorsCount(response.data.count);
      })
      .catch(errorCatcher)
      .finally(() => setOurContractorsLoading(false));
  }, [buildingId, ourContractorsFilter, allContractors]);

  useLayoutEffect(() => {
    axios.get("/contractors/", { params: allContractorsFilter }).then((response: ListResponse<IContractor>) => {
      setAllContractors(response.data.results);
      setAllContractorsCount(response.data.count);
    });
  }, [allContractorsFilter]);

  useEffect(() => {
    const ourContractorsDictionary: Record<IContractor["entity_id"], boolean> = {};
    ourContractors.forEach((contractor) => (ourContractorsDictionary[contractor.entity_id] = true));

    setAvailableForAddContractors(allContractors.filter((contractor) => !ourContractorsDictionary[contractor.id]));
  }, [ourContractors, allContractors]);

  useEffect(() => {
    setAvailableForAddContractorsCount(allContractorsCount - ourContractorsCount);
  }, [allContractorsCount, ourContractorsCount]);

  const filterHandler: IChangeFilter = (value, field) =>
    setOurContractorsFilter((prev) => ({
      ...prev,
      [field]: value,
    }));

  const loadMoreHandler = () =>
    setOurContractorsFilter((prev) => ({
      ...prev,
      offset: prev.offset + 10,
    }));

  const deleteOurContractor: IDeleteOurContractor = useCallback(
    (deletedContractorId) => {
      setOurContractors((prevState) => prevState.filter((contractor) => contractor.id !== deletedContractorId));
      setOurContractorsCount((prevState) => prevState - 1);

      axios
        .delete(`/building/${buildingId}/contractors/${deletedContractorId}/`)
        .then(() => message.success("Подрядчик удален"))
        .catch(errorCatcher);
    },
    [buildingId]
  );

  const changeAllContractorsFilter: IChangeFilter = useCallback(
    (value, name) =>
      setAllContractorsFilter((prevState) => ({
        ...prevState,
        [name]: value,
      })),
    []
  );

  const addOurContractors: IAddOurContractors = useCallback(
    async (addedContractorsIds) => {
      const addedContractors: IBuildingContractor[] = [];
      for (const contractorId of addedContractorsIds) {
        await axios
          .post(`/building/${buildingId}/contractors/`, { entity_id: contractorId })
          .then((response: AxiosResponse<IBuildingContractor>) => addedContractors.push(response.data));
      }

      const contractorsWithMergedContactManager = mergeContactManagerFrom(allContractors, addedContractors);
      setOurContractors((prevState) => [...prevState, ...serializeOurContractors(contractorsWithMergedContactManager)]);
    },
    [buildingId, allContractors]
  );

  return {
    availableForAddContractors,
    availableForAddContractorsCount,
    changeAllContractorsFilter,
    ourContractors,
    ourContractorsCount,
    ourContractorsLoading,
    filterHandler,
    deleteOurContractor,
    loadMoreHandler,
    addOurContractors,
  };
};

export default useProjectContractors;
