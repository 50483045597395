import { FC, memo } from "react";
import { ReactComponent as ArrowLeft } from "../../../../../../../shared/assets/icons/ArrowLeft.svg";
import styles from "../ShiftsModal.module.scss";

interface IBackButtonProps {
  onClick?: () => void;
}

const BackButton: FC<IBackButtonProps> = ({ onClick }) => (
  <div
    onClick={onClick}
    className={styles.back}
  >
    <ArrowLeft />
  </div>
)

export default memo(BackButton);
