import cn from "classnames";
import React, { ReactNode } from "react";

import NavigationBar from "../NavigationBar";
import Header from "features/templates/Header/Header";

import styles from "./TemplateBase.module.scss";

export interface ITemplateBaseProps {
  children: React.ReactNode;
  contentClassName?: string;
  dataTestId?: string | number;
  breadcrumbs?: ReactNode;
}

const TemplateBase: React.FC<ITemplateBaseProps> = ({
  children,
  contentClassName,
  dataTestId = "templateBase",
  breadcrumbs,
}) => {
  return (
    <>
      <Header breadcrumbs={breadcrumbs} />
      <div className={styles.container}>
        <NavigationBar />
        <div className={cn(styles.content, contentClassName)} data-testid={dataTestId}>
          {children}
        </div>
      </div>
    </>
  );
};

export default React.memo(TemplateBase);
