import { TaskPriority, TaskStatus } from "../../components/pages/Tasks/constants";

import { IProvider } from "../personsTypes";

import { IIdAndName } from "types/idAndName";

export enum EVENT_TYPES {
  payment = "payment",
  supply = "supply",
  task = "task",
  order = "order",
}

export type EventType = keyof typeof EVENT_TYPES;

export enum EVENT_STATUSES {
  waiting_shipment = "waiting_shipment",
  accepted = "accepted",
  waiting_payment = "waiting_payment",
}

export type EventStatus = keyof typeof EVENT_STATUSES;

export interface EventPaymentPackingList {
  id: number;
  number: string;
  status: string;
  status_name: string;
  delivery_date: string;
}

export interface EventPaymentTerm {
  id: number;
  payment_type: string;
  payment_type_name: string;
  percent: number;
  delay_days: number;
}

export interface EventPayment {
  id: number;
  amount: string;
  delivery_amount: string;
  total_amount: string;
  date: string;
  initial_date: string;
  paid: boolean;
  payment_date: string | null;
  comment: string;
  packinglist: EventPaymentPackingList;
  payment_term: EventPaymentTerm;
  file: string | null;
  file_name: string;
}

export interface Event {
  date: string;
  is_completed: boolean;
  event_type: EventType;
  resourcetype: string;
  status: EventStatus;
}

export interface PaymentEvent extends Event {
  payment: EventPayment;
  invoice: {
    id: number;
    number: string;
  };
  order: {
    id: number;
    number: string;
  };
}

export interface EventSupplyPackingList {
  id: number;
  number: string;
  provider: IProvider;
  delivery_cost: string;
}

export interface EventBuilding {
  id: number;
  name: string;
  address: string;
  post_code: string;
  city_name: string;
  region_name: string;
}

export interface SupplyEvent extends Event {
  packinglist: EventSupplyPackingList;
  building: EventBuilding;
  packinglist_amount: number;
}

export interface EventTask {
  id: number;
  name: string;
  description: string;
  status: TaskStatus;
  priority: TaskPriority;
  deadline: string;
  number: number;
  building_name: string;
}

export interface TaskEvent extends Event {
  task: EventTask;
  executor: IIdAndName;
}

export interface IEventProjects {
  id: number | string;
  name: string;
}
