import update from "immutability-helper";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { detailEmployeesSelector, getEmployees } from "../../../../redux/modules/common/building/object/nowObject";
import { entityIdSelector } from "redux/modules/_TODO/auth";
import {
  dataStateSelector,
  getUsersList,
  loadingStateSelector,
  paginationStateSelector,
  setPaginationState,
} from "redux/modules/common/settings/ourCompanyTabs/employees";

import LabeledSwitch from "_LEGACY/UI/__trash/LabeledSwitch";

import ButtonBase from "../../../../shared/ui/controls/ButtonBase";
import { TableOslaLocal } from "../../_LEGACY_TableOslaCustom/TableOslaLocal";
import { TableHeader } from "../../_LEGACY_TotoRowTable/TableHeader/TableHeader";
import Checkbox from "../../__TODO/Checkbox/Checkbox";
import { InputSearchRound } from "shared/ui/inputs/InputSearchRound/InputSearchRound";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import styles from "./index.module.scss";

class AddEmployee extends React.PureComponent {
  state = {
    items: [],
    multiSelectEvent: null,
    search: "",
  };

  componentDidMount() {
    const { buildingId, getEmployees, getUsersList, objectOnly } = this.props;
    if (objectOnly) {
      getEmployees(buildingId);
    } else {
      getUsersList(null, null, { limit: 1000 });
    }
  }

  componentDidUpdate(oldProps) {
    const { selectedEmployee } = this.props;
    if (oldProps.isOpen !== this.props.isOpen) {
      const newArray = selectedEmployee ? [selectedEmployee] : [];
      this.setState({ items: newArray });
    }
  }

  newItemMutation = (employee) => {
    const last_name = employee.last_name || "";
    const first_name = employee.first_name || "";
    const middle_name = employee.middle_name || "";
    return {
      id: employee.id,
      name: last_name + " " + first_name + " " + middle_name,
      type_name: employee.type_name,
      roles: employee.roles,
      is_obligatory: this.props.obligatoryValue,
    };
  };

  addItem = (chosenElem) => {
    const newItem = this.newItemMutation(chosenElem);
    this.setState({ items: [...this.state.items, newItem] });
  };

  deleteItem = (chosenElem) => {
    const newItems = this.state.items.filter((item) => item.id !== chosenElem.id);
    this.setState({ items: newItems });
  };

  massCheck = (val) => {
    const { items } = this.state;

    const { data, objectOnly, objectEmployees } = this.props;

    const nowData = objectOnly ? { results: objectEmployees } : data;

    let newItemsArr = [];

    if (val) {
      nowData.results.forEach((chosenElem) => {
        if (!this.isChecked(chosenElem)) {
          const newItem = this.newItemMutation(chosenElem);
          newItemsArr.push(newItem);
        }
      });
      newItemsArr = [...items, ...newItemsArr];
    } else {
      newItemsArr = items.filter((item) => !(nowData.results.find((row) => row.id === item.id) !== undefined));
    }

    this.setState({ items: newItemsArr });
  };

  setMultiSelect = (val) => this.setState({ multiSelectEvent: val });

  disableMultiSelect = () => this.setMultiSelect(null);

  isChecked = (row) => this.state.items.find((item) => item.id === row.id) !== undefined;

  checkHandler = (row) => {
    const { loadingState } = this.props;
    if (loadingState) return false;
    const { multiSelectEvent } = this.state;
    const checked = this.isChecked(row);
    const nowMultiSelectEvent = multiSelectEvent || (checked ? "uncheck" : "check");

    nowMultiSelectEvent === "uncheck" ? this.deleteItem(row) : this.addItem(row);
    !multiSelectEvent && this.setState({ multiSelectEvent: nowMultiSelectEvent });
  };
  checkData = (row) => {
    this.setState({ items: [this.newItemMutation(row)] });
  };
  checkboxFormatter = (cell, row) => {
    return (
      <div>
        <Checkbox checked={this.isChecked(row)} />
      </div>
    );
  };

  clearSelectedItemsArr = () => this.setState({ items: [] });

  addEmployeesHandler = () => {
    if (this.state.items.length > 0) {
      this.props.addEmployees(this.state.items);
      //message.success('Добавлено');
      this.clearSelectedItemsArr();
    }
  };

  massCheckHandler = (e) => this.massCheck(e.target.checked);

  nameFormatter = (cell, row) => (
    <div className={styles.cell}>{row.last_name + " " + row.first_name + " " + row.middle_name}</div>
  );

  positionFormatter = (cell, row) => {
    return (
      row.roles?.map((type, index) => (
        <div className={styles.cell} key={index}>
          {type.name}
        </div>
      )) || ""
    );
  };

  obligatorySwitchHandler = (row, val) => {
    this.setState((prevState) =>
      update(prevState, {
        items: {
          [prevState.items.indexOf(prevState.items.find((item) => item.id === row.id))]: {
            is_obligatory: { $set: val },
          },
        },
      })
    );
  };

  obligatorySwitchFormatter = (cell, row) => (
    <div
      onMouseEnter={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <LabeledSwitch
        disabled={!this.state.items.find((item) => item.id === row.id)}
        label={""}
        value={row.is_obligatory}
        onChange={(val) => this.obligatorySwitchHandler(row, val)}
      />
    </div>
  );

  render() {
    const {
      isOpen,
      closeAddPEmployeeBlock,
      objectOnly,
      returnOneData,
      data,
      objectEmployees,
      buttonText = "Добавить",
    } = this.props;
    const { multiSelectEvent, search } = this.state;

    const nowData = objectOnly ? { results: objectEmployees } : data;

    const dataEmployees = nowData?.results?.filter(
      (item) =>
        item.first_name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        item.last_name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        item.middle_name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );

    return (
      <SliderModal isOpen={isOpen} closeHandler={closeAddPEmployeeBlock} className={styles.sliderClassName}>
        <div className={styles.header}>
          <div className={styles.title}>Сотрудники</div>
          <div className={styles.searchBlock}>
            <InputSearchRound
              onChange={(e) => this.setState({ search: e.target.value })}
              className={styles.inputSearchClassName}
            />
            <ButtonBase primary onClick={this.addEmployeesHandler} disabled={this.state.items.length <= 0}>
              {buttonText}
            </ButtonBase>
          </div>
        </div>
        <div className={styles.tableWrapper} onMouseUp={this.disableMultiSelect} onMouseLeave={this.disableMultiSelect}>
          <TableOslaLocal
            data={dataEmployees}
            loadingState={false}
            keyProp={"id"}
            onRowMouseDown={!returnOneData && this.checkHandler}
            onRowMouseEnter={!returnOneData && (multiSelectEvent ? this.checkHandler : null)}
            onRowClick={returnOneData && this.checkData}
            style={{ userSelect: "none" }}
            pagination={false}
          >
            <TableHeader formatter={this.checkboxFormatter} cellStyle={{ width: "10%" }}>
              {!returnOneData && (
                <Checkbox
                  onChange={data && data.results.length > 0 && this.massCheckHandler}
                  checked={data && data.results.every((item) => this.isChecked(item))}
                />
              )}
            </TableHeader>
            <TableHeader
              formatter={this.nameFormatter}
              style={{ whiteSpace: "nowrap", fontWeight: "500", fontSize: "1rem", color: "#000" }}
              cellStyle={{ width: "45%" }}
            >
              Сотрудник
            </TableHeader>
            <TableHeader
              //data={"position"}
              style={{ whiteSpace: "nowrap", fontWeight: "500", fontSize: "1rem", color: "#000" }}
              formatter={this.positionFormatter}
              cellStyle={{ width: "45%" }}
            >
              Должность
            </TableHeader>
          </TableOslaLocal>
        </div>
      </SliderModal>
    );
  }
}

export default connect(
  (state) => ({
    entity: state.auth.entity,
    entityId: entityIdSelector(state),
    data: dataStateSelector(state),
    loadingState: loadingStateSelector(state),
    objectEmployees: detailEmployeesSelector(state),
  }),
  {
    paginationStateSelector,
    dataStateSelector,
    setPaginationState,
    getUsersList,
    getEmployees,
  }
)(AddEmployee);
