import cn from "classnames";
import { debounce } from "lodash";
import React, { InputHTMLAttributes } from "react";

import Icon from "_LEGACY/UI/_LEGACY_Icon/Icon";

import searchIcon from "images/icons/searchIcon.svg";

import styles from "./index.module.scss";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export const InputSearchRound: React.FC<IProps> = ({ className, onChange, ...props }) => {
  return (
    <div className={cn(styles.container, className)}>
      <input
        {...props}
        value={props.value}
        onChange={onChange}
        type="text"
        className={styles.searchInput}
        placeholder={props.placeholder || "Поиск"}
      />
      <Icon className={styles.searchIcon} icon={searchIcon} />
    </div>
  );
};
