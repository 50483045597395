import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useTypedSelector } from "app/store/typedUseSelector";
import { getObjectMastering } from "features/objectsList";

import { IObjectMastering } from "features/objectsList/model/types";

import { _TEMP_useBuildingDetailDataById } from "hooks/useBuildingDetailDataById";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const useObjectMastering = (objectId: string | number) => {
  const dispatch = useDispatch();
  const [endAt, setEndAt] = useState(moment().endOf("month").format("YYYY-MM-DD"));
  const key = generateStorageKey({ objectId, endAt });
  const { buildingData, isBuildingLoading } = _TEMP_useBuildingDetailDataById(String(objectId));

  const mastering: IObjectMastering | undefined = useTypedSelector((state) => state.objectsListReducer.masterings)[key];
  const isMasteringLoading = useTypedSelector((state) => state.objectsListReducer.loadings)[key];

  useEffect(() => {
    dispatch(getObjectMastering(String(objectId), endAt));
  }, [endAt, objectId]);

  const changeDate = (date: Moment) => {
    if (date.isBefore(moment(buildingData?.construction_date_start))) {
      setEndAt(moment(buildingData?.construction_date_start).format("YYYY-MM-DD"));
      return;
    }
    if (date.isAfter(moment(buildingData?.construction_date_end))) {
      setEndAt(moment(buildingData?.construction_date_end).format("YYYY-MM-DD"));
      return;
    }
    setEndAt(date.format("YYYY-MM-DD"));
  };

  return {
    mastering,
    isMasteringLoading: isBuildingLoading || isMasteringLoading,
    changeDate,
    endAt,
  };
};
