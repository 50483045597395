import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { compose } from "redux";

import {
  cacheUser,
  getUserByIdSelector,
  isUserLoadingSelector,
  loadUser,
} from "../../../../../../redux/modules/common/settings/ourCompanyTabs/employees";
import { getRoles, updateUser } from "redux/modules/_TODO/userRedux";

import { InputData } from "./components/InputData/InputData";
import { Navigation } from "./components/Navigation/Navigation";
import PermissionsSettings from "./components/PermissionsSettings/PermissionsSettings";

import {
  permissionsInvalidateKeySelector,
  permissionsPendingSelector,
  permissionsTreeSelector,
  permissionsUserSelector,
} from "features/permissions/model/selectors";
import { getCurrentUserPermissions, getPermissionsTree, updatePermissions } from "features/permissions/model/thunks";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { PermissionPropertiesEnum } from "./enums";
import { MATCHED_CHILD_PERMISSIONS } from "constants/permissions/matchedChildPermissions";

import useFormFocus from "../../../../../../utils/hooks/useFormFocus";
import { useQueryParams } from "../../../../../../utils/hooks/useQueryParams";
import useRelatesChecker from "hooks/useRelatesChecker";

import { buildRelatesPermissions } from "./utils/buildRelatesPermissions";
import { permissionsUtils } from "./utils/permissionsUtils";
import { isEqualObjects } from "utils/checkers/isEqualObjects";
import { extractChangedValues } from "utils/helpers/extractChangedValues/extractChangedValues";

import styles from "./UserPage.module.scss";

export const UserPage = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: userId } = useParams<any>();
  const isEditing = useQueryParams("edit") === "true";
  //@ts-ignore
  const user = useSelector((state) => getUserByIdSelector(state, userId));

  const userPermissions = useSelector(permissionsUserSelector)[userId];
  const allPermissions = useSelector(permissionsTreeSelector);
  const isUserLoading = useSelector(isUserLoadingSelector);
  const invalidatePermissionsKey = useSelector(permissionsInvalidateKeySelector);
  const isPending = useSelector(permissionsPendingSelector)[userId];

  const [headerData, setHeaderData] = useState(user);
  const [block, setBlock] = useState(user?.is_blocked);
  const [changedUser, setChangedUser] = useState<any>(user);
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (userId) {
      dispatch(loadUser(userId));
      dispatch(getPermissionsTree(userId));
    }

    dispatch(getRoles());
  }, [userId]);

  useEffect(() => {
    setHeaderData(user);
    setChangedUser(user);
  }, [user]);

  useEffect(() => {
    setBlock(user?.is_blocked);
  }, [user?.is_blocked]);

  useEffect(() => {
    if (!userId) return;
    dispatch(getCurrentUserPermissions(userId));
  }, [userId, invalidatePermissionsKey]);

  const onCheckUserFullAccess = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setChangedUser((prevState: any) => ({
        ...prevState,
        full_access: e.target.checked,
      })),
    []
  );

  const onCheckUserApprover = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setChangedUser((prevState: any) => ({
        ...prevState,
        is_approver: e.target.checked,
      })),
    []
  );
  ///////////////////
  const relatesPermissions = useMemo(() => buildRelatesPermissions(MATCHED_CHILD_PERMISSIONS), []);

  const defaultPermissions = useMemo(() => {
    if (!userPermissions) return {};

    const result: any = {};
    userPermissions.forEach((permission: any) => {
      result[permission.name] =
        permission.property === PermissionPropertiesEnum.optionalOn ||
        permission.property === PermissionPropertiesEnum.on;
    });
    return result;
  }, [userPermissions]);

  const relatesChecker = useRelatesChecker(relatesPermissions, defaultPermissions);

  ///////////////////

  const checkHavePermissionsChanges = useCallback(
    (checkedPermissions: any) =>
      Object.entries(checkedPermissions).some(([permission, isChecked]) => {
        const permissionIsOnAndNotDefault = isChecked && defaultPermissions[permission] === undefined;
        const permissionIsDefaultAndChanged =
          defaultPermissions[permission] !== undefined && defaultPermissions[permission] !== isChecked;
        return permissionIsOnAndNotDefault || permissionIsDefaultAndChanged;
      }),
    [defaultPermissions]
  );

  const filterNotChangedPermissions = useCallback(
    (checkedPermissions: any) => {
      const userPermissionsByNames: any = {};
      userPermissions?.forEach((permission: any) => (userPermissionsByNames[permission.name] = permission));

      const filteredPermissions: any = {};
      Object.entries(checkedPermissions).forEach(([name, isChecked]) => {
        if (!userPermissionsByNames[name]) return;

        const property = userPermissionsByNames[name].property;
        if (property === PermissionPropertiesEnum.on || property === PermissionPropertiesEnum.off) return;

        filteredPermissions[name] = isChecked;
      });
      return filteredPermissions;
    },
    [userPermissions]
  );

  const matchedWithUserAllPermissions = useMemo(() => {
    return allPermissions;
  }, [allPermissions]);

  const allPermsLength = useMemo(() => {
    return permissionsUtils.getCountWithoutFakes(allPermissions);
  }, [allPermissions]);

  const [permissionValues, setPpermissionValues] = useState<Record<string, boolean>>({});
  const [changedPermissionValues, setChangedPpermissionValues] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const res: Record<string, boolean> = {};
    userPermissions?.forEach((permission) => (res[permission.alias] = permission.state === "on" ? true : false));
    setPpermissionValues(res);
  }, [userPermissions]);

  const inputRootRef = useFormFocus();

  const saveUserChanges = useCallback(() => {
    if (Object.values(changedPermissionValues).length) {
      dispatch(updatePermissions(changedUser.id, changedPermissionValues));
    }

    const changedValues = extractChangedValues(user, changedUser);

    if (!Object.keys(changedValues).length) {
      return;
    }

    if (changedValues.roles) {
      changedValues.roles = changedValues.roles.map((role: any) => role.id);
    }

    if (changedValues.position) {
      changedValues.position = changedValues.position.trim();
    }

    //@ts-ignore
    compose(dispatch, updateUser)(changedUser.id, {
      ...changedValues, //@ts-ignore
    }).then((updatedUser) => {
      setHeaderData(updatedUser);
      dispatch(cacheUser(updatedUser));
    });
  }, [
    checkHavePermissionsChanges,
    relatesChecker.items,
    dispatch,
    changedUser,
    props.location.pathname,
    history,
    filterNotChangedPermissions,
    changedPermissionValues,
  ]);

  const isShowSaveButton = useMemo(
    () =>
      (!isEqualObjects(user, changedUser) || Object.values(changedPermissionValues).length) && isEditing && formValid,
    [changedUser, user, isEditing, formValid, changedPermissionValues]
  );

  useEffect(() => {
    setChangedPpermissionValues({});
  }, [invalidatePermissionsKey]);

  const permissionsWithChildrenAliases = useMemo(() => {
    return permissionsUtils.matchPermissionsWithChildrenAliases(matchedWithUserAllPermissions);
  }, [matchedWithUserAllPermissions]);

  const onChangeRelatesChecked = useCallback(
    (permissionName: string, isChecked: boolean) => {
      const childAliases = permissionsWithChildrenAliases[permissionName];
      setPpermissionValues((prev) => {
        const newVal = { ...prev };
        newVal[permissionName] = isChecked;
        childAliases?.forEach((childAlias) => {
          newVal[childAlias] = isChecked;
        });
        return newVal;
      });
      setChangedPpermissionValues((prev) => ({
        ...prev,
        [permissionName]: isChecked,
      }));
    },
    [relatesChecker.changeChecked, permissionsWithChildrenAliases]
  );

  const normalizedPermissions = useMemo(() => {
    return permissionsUtils.normalizeTree(matchedWithUserAllPermissions);
  }, [matchedWithUserAllPermissions]);

  if (/* userPermissionsAreLoading || */ isUserLoading)
    return (
      <TemplateBase>
        <Spinner />
      </TemplateBase>
    );

  return (
    <TemplateBase>
      <div className={styles.wrapper}>
        <Navigation
          block={block}
          setBlock={setBlock}
          innerInput={changedUser}
          setInnerInput={setChangedUser}
          isEditing={isEditing}
          headerData={headerData}
        />
        <div className={styles.container}>
          <div className={styles.innerData}>
            <div className={styles.text}>Основные сведения</div>
            <div className={styles.formContainer} ref={inputRootRef}>
              <InputData
                innerInput={changedUser}
                setInnerInput={setChangedUser}
                setFormValid={setFormValid}
                isEditable={isEditing}
              />
              <PermissionsSettings
                allPermissions={normalizedPermissions}
                allPermissionsCount={allPermsLength}
                checkedUserPermissions={permissionValues}
                onChangeCheckedUserPermissions={onChangeRelatesChecked}
                userFullAccess={changedUser?.full_access}
                onCheckUserFullAccess={onCheckUserFullAccess}
                canEdit={isEditing}
                isPending={isPending}
                userApprover={changedUser?.is_approver}
                onCheckUserApprover={onCheckUserApprover}
              />
            </div>

            <footer className={styles.footer}>
              {!!isShowSaveButton && (
                <ButtonBase onClick={saveUserChanges} disabled={!formValid} primary medium>
                  Сохранить
                </ButtonBase>
              )}
            </footer>
          </div>
        </div>
      </div>
    </TemplateBase>
  );
};
