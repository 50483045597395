import cn from "classnames";
import { memoize } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";

import { PaymentTerm } from "./PaymentTerm/PaymentTerm";

import { stringifyArgs } from "utils/helpers/stringifyArgs";

import { ReactComponent as IconPlus } from "./icons/IconPlus.svg";

import styles from "./PaymentTerms.module.scss";

export const enum PAYMENT_TYPE {
  DELAY = "delay", // Отсрочка
  PREPAY = "prepay", // Предоплата
  UPON_SHIPMENT = "upon_shipment", // По факту отгрузки
  UPON_COMPLETION = "upon_completion",
}

export const PAYMENT_TYPE_NAMES = {
  [PAYMENT_TYPE.DELAY]: "Отсрочка",
  [PAYMENT_TYPE.PREPAY]: "Предоплата",
  [PAYMENT_TYPE.UPON_COMPLETION]: "По факту",
};
/* @ts-ignore */
const calculateTotalPercent = (terms) =>
  /* @ts-ignore */
  terms.reduce((sum, item) => sum + parseFloat(item.percent), 0); /* @ts-ignore */
const checkAllTermsPercentsFill = (terms) => terms.every((term) => term.percent);

export const PAYMENT_TERMS = [
  { id: "prepay", name: "Предоплата", disabled: false },
  { id: "upon_completion", name: "По факту выполнения", disabled: false },
  { id: "delay", name: "Отсрочка", disabled: false },
];

const INITIAL_TERM = { payment_type: null, percent: 0 };
const EMPTY_TERMS: any[] = [];

export const PaymentTerms = ({
  selectedTerms = EMPTY_TERMS,
  changeSelectedTerms = () => {},
  className = "",
  withDaysTerms = [PAYMENT_TYPE.DELAY, PAYMENT_TYPE.PREPAY],
  disabledTerms = [PAYMENT_TYPE.UPON_SHIPMENT],
  isEditing = false,
}) => {
  const addInitialTerm = useCallback(
    /* @ts-ignore */
    () => changeSelectedTerms([...selectedTerms, INITIAL_TERM]),
    [changeSelectedTerms, selectedTerms]
  );

  const deleteTerm = useCallback(
    /* @ts-ignore */
    (deletedTermIndex) => changeSelectedTerms(selectedTerms.filter((_term, i) => i !== deletedTermIndex)),
    [selectedTerms, changeSelectedTerms]
  );

  const memoizedDeleteTerm = useMemo(
    () => memoize((deletedTermIndex) => () => deleteTerm(deletedTermIndex), stringifyArgs),
    [deleteTerm]
  );

  const changeTerm = useCallback(
    /* @ts-ignore */
    (changedTermIndex, field, value) =>
      changeSelectedTerms(
        /* @ts-ignore */
        selectedTerms.map((term, i) => (i === changedTermIndex ? { ...term, [field]: value } : term))
      ),
    [selectedTerms, changeSelectedTerms]
  );

  const onChangePercent = useCallback(
    /* @ts-ignore */
    (changedTermIndex, e) => {
      const v = +(e?.target?.value ?? e);
      if (isNaN(v)) return;
      const changedTerms = selectedTerms.map((term, index) =>
        index === changedTermIndex
          ? {
              ...term,
              percent: v,
            }
          : term
      );

      const totalPercent = calculateTotalPercent(changedTerms);

      if (totalPercent > 100) return; /* @ts-ignore */
      changeSelectedTerms(changedTerms);
    },
    [selectedTerms, changeSelectedTerms]
  );

  const isDelayAdded = useMemo(
    () => selectedTerms.some(({ payment_type }) => withDaysTerms.includes(payment_type)),
    [selectedTerms, withDaysTerms]
  );

  const isShownAddButton = useMemo(
    () => checkAllTermsPercentsFill(selectedTerms) && calculateTotalPercent(selectedTerms) < 100 && isEditing,
    [isEditing, selectedTerms]
  );

  useEffect(() => {
    if (selectedTerms.length !== 0) return;
    addInitialTerm();
  }, [addInitialTerm, selectedTerms.length]);

  return (
    <div className={cn(styles.paymentTerms, className)}>
      <header className={styles.header}>
        <h2 className={styles.headerText}>Условия оплаты:</h2>
        {isShownAddButton && (
          <div className={styles.addWrapper}>
            <span className={styles.warning}>Сумма условий должна быть равна 100%</span>
            <button className={styles.addButton} onClick={addInitialTerm} type="button">
              <IconPlus />
            </button>
          </div>
        )}
      </header>
      <div className={cn(styles.content, { [styles.editing]: isEditing })}>
        {selectedTerms.map((term, i) => (
          <PaymentTerm
            term={term}
            changeTerm={changeTerm}
            onDeleteTerm={memoizedDeleteTerm(i)}
            onChangePercent={onChangePercent} /* @ts-ignore */
            terms={PAYMENT_TERMS.filter((term) => !disabledTerms.includes(term.id))}
            withDaysTerms={withDaysTerms}
            canDeleteTerm={selectedTerms.length > 1}
            isEditing={isEditing}
            index={i}
            key={i}
          />
        ))}
      </div>
    </div>
  );
};
