import { useEffect, useRef } from "react";

export interface IUseChartDragProps {
  targetRef: React.RefObject<HTMLElement>;
}

export const useChartDrag = ({ targetRef }: IUseChartDragProps) => {
  const isDraggingActive = useRef(false);
  useEffect(() => {
    const target = targetRef.current?.parentElement;
    if (!target) return;
    const enableDragging = (e: PointerEvent) => {
      if (e.srcElement === target) {
        isDraggingActive.current = true;
        document.body.style.userSelect = "none";
        window.addEventListener("pointermove", watchMove);
      }
    };
    const watchMove = (e: PointerEvent) => {
      if (!isDraggingActive.current) return;
      // @ts-ignore
      target.scrollBy({ left: -e.movementX, top: -e.movementY, behavior: "instant" });
    };
    const disableDragging = () => {
      isDraggingActive.current = false;
      window.removeEventListener("pointermove", watchMove);
      document.body.style.userSelect = "";
    };
    target.addEventListener("pointerdown", enableDragging);
    window.addEventListener("pointerup", disableDragging);
    return () => {
      target.removeEventListener("pointerdown", enableDragging);
      window.removeEventListener("pointerup", disableDragging);
    };
  }, [targetRef.current]);
};
