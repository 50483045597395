import React from "react";

import ButtonBase from "../../../../shared/ui/controls/ButtonBase";
import Modal from "../../../../shared/ui/modal/Modal";

import styles from "./CancelOrderModal.module.scss";

const CancelOrderModal = ({ isOpen, closeModal, cancelOrder }) => {
  return (
    <Modal isOpen={isOpen} onClose={closeModal} title="Подтвердите действие" className={styles.cancelOrderModal}>
      <p className={styles.body}>Вы точно хотите отменить заказ? Вы не сможете восстановить этот заказ.</p>
      <footer className={styles.footer}>
        <ButtonBase className={styles.button} onClick={cancelOrder} medium secondary>
          Отменить заказ
        </ButtonBase>
        <ButtonBase className={styles.button} onClick={closeModal} medium>
          Продолжить работу
        </ButtonBase>
      </footer>
    </Modal>
  );
};

export default React.memo(CancelOrderModal);
