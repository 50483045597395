import React, { useCallback } from "react";
import styles from "./index.module.scss";

const Section = ({ data, onClick, type }) => {
  const handleOnClick = useCallback(() => onClick(data), [data, onClick]);

  return (
    <div className={styles.container} onClick={handleOnClick}>
      <span className={styles.title}>{data?.name}</span>
      <span className={styles.materialTitle}>{type !== "services" ? "Материалы:" : "Услуги:"}</span>
      <span className={styles.count}>{data?.products_count}</span>
    </div>
  );
};

export default React.memo(Section);