import cn from "classnames";
import React, { MouseEventHandler } from "react";

import { DividerType } from "../../ManufacturingModalDivider";

import styles from "./DividerExpandButton.module.scss";

export interface IDividerExpandButtonProps {
  text: string;
  onClick: MouseEventHandler;
  className?: string;
  isDefaultOpen?: boolean;
  type?: DividerType;
}

const DividerExpandButton = ({ text, onClick, className, isDefaultOpen, type }: IDividerExpandButtonProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(!!isDefaultOpen);

  const onButtonClick: MouseEventHandler = (e) => {
    setIsOpen((prevState) => !prevState);
    onClick?.(e);
  };

  return (
    <button className={cn(styles.button, type && styles[type], className)} onClick={onButtonClick}>
      <span className={styles.text}>{text}</span>
      <div className={cn(styles.arrow, { [styles.flippedArrow]: !isOpen })} />
    </button>
  );
};

export default React.memo(DividerExpandButton);
