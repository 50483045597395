import moment from "moment";
import React from "react";

import { IKs2InActingList } from "redux/modules/common/building/journal/acting/types";

import JournalRowIndicator from "../../../common/JournalRowIndicator/JournalRowIndicator";
import JournalKs2ModalRowContent from "./JournalKs3ModalRowContent";
import Actions from "shared/ui/controls/Actions/Actions";
import DateCell from "shared/ui/dataDisplay/DateCell/DateCell";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./JournalKs3ModalRow.module.scss";

interface IProps {
  isFirst?: boolean;
  ks2: IKs2InActingList;
  onDeselectKs2: (ks2Id: number) => void;
}

const JournalKs2ModalRow: React.FC<IProps> = ({ isFirst, ks2, onDeselectKs2 }) => {
  const deselectHandler = React.useCallback(() => {
    onDeselectKs2(ks2.id);
  }, [ks2]);

  return (
    <>
      {isFirst && (
        <TableReusableRow isHoverable={false} containerClassName={styles.parentNameWrapper}>
          {ks2.parent_section_name}
        </TableReusableRow>
      )}
      <TableReusableRow
        isHoverable={false}
        isExpandable
        isParentOfExpenditures
        className={styles.sectionRow}
        innerContent={<JournalKs2ModalRowContent ks2Id={ks2.id} />}
      >
        <TableReusableCell isNoBreak>КС № {ks2.title}</TableReusableCell>
        <TableReusableCell>
          <DateCell
            date={`${moment(ks2.start_at).format("DD.MM.YYYY")} - ${moment(ks2.end_at).format("DD.MM.YYYY")}`}
          />
        </TableReusableCell>
        <TableReusableCell>
          <JournalRowIndicator value={splitThousands(ks2.todo_amount)} title="К сдаче" />
        </TableReusableCell>
        <TableReusableCell isCentered className={styles.crossCell}>
          {/* @ts-ignore */}
          <Actions canRemove onRemove={deselectHandler} className={styles.actions} isThinCross />
        </TableReusableCell>
      </TableReusableRow>
    </>
  );
};

export default React.memo(JournalKs2ModalRow);
