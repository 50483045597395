import { Expenditure, IGroupTotals } from "../types";
import { ExpenditureTypeEnum } from "../../../../../types/enums/ExpenditureTypeEnum";

export const calculateTotalsFromExpenditures = (expenditures: Expenditure[]): IGroupTotals => {
  const retval = {
    all: 0,
    works: 0,
    materials: 0,
    equipments: 0,
    services: 0,
    rom_materials: 0,
    all_without_rom: 0,
  }

  expenditures.forEach((exp) => {
    const value = +exp.estimated_cost;

    retval.all += value;

    if (exp.ret_own_mat) {
      retval.rom_materials += value;
    } else {
      retval.all_without_rom += value;
    }

    if (exp.expenditure_type === ExpenditureTypeEnum.work) {
      retval.works += value;
    }

    if (exp.expenditure_type === ExpenditureTypeEnum.equipment) {
      retval.equipments += value;
    }

    if (exp.expenditure_type === ExpenditureTypeEnum.material) {
      retval.materials += value;
    }

    if ([ExpenditureTypeEnum.machine, ExpenditureTypeEnum.transport].includes(exp.expenditure_type)) {
      retval.services += value;
    }
  })


  return {
    all: retval.all.toString(),
    works: retval.works.toString(),
    materials: retval.materials.toString(),
    equipments: retval.equipments.toString(),
    services: retval.services.toString(),
    rom_materials: retval.rom_materials.toString(),
    all_without_rom: retval.all_without_rom.toString(),
  };
}