import React from "react";

export const AlertIcon = ({
  className,
  fill = "white",
  width = 16,
  ...props
}: {
  className?: string;
  fill?: string;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox={`0 0 ${width} ${width}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM9 3V9.5H7L7 3H9ZM7 13H9V11H7V13Z"
        fill={fill}
      />
    </svg>
  );
};
