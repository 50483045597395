import { useCountUp } from "react-countup";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

export const EffectSection = () => {
  const firstCounterRef = useRef(null);
  const secondCounterRef = useRef(null);
  const thirdCounterRef = useRef(null);

  const [isAnimated, setIsAnimated] = useState(false);

  const { ref, inView } = useInView();

  const {
    start: startFirstCounter,
  } = useCountUp({
    ref: firstCounterRef,
    start: 0,
    end: 13,
    duration: 1.3,
  });

  const {
    start: startSecondCounter,
  } = useCountUp({
    ref: secondCounterRef,
    start: 0,
    end: 7,
    duration: 1.3,
  });

  const {
    start: startThirdCounter,
  } = useCountUp({
    ref: thirdCounterRef,
    start: 0,
    end: 75,
    duration: 1.3,
  });

  useEffect(() => {
    if (inView && !isAnimated) {
      startFirstCounter();
      startSecondCounter();
      startThirdCounter();
      setIsAnimated(true);
    }
  }, [inView, isAnimated])


  return (
  <section className="implem__main" id="effect">
    <div className="container">
      <div className="implem__cnt">
        <h2>Эффект от внедрения OSLA</h2>
        <div className="implem__flex">
          <div className="implem__box">
            <div className="implem__number" ref={ref}>
              <small>до</small>
              <strong className="counter" ref={firstCounterRef} />
              <strong>%</strong>
            </div>
            <p>сокращаются сроки строительства</p>
          </div>
          <div className="implem__box">
            <div className="implem__number">
              <small>до</small>
              <strong className="counter" data-number="7" ref={secondCounterRef} />
              <strong>%</strong>
            </div>
            <p>сокращаются расходы на строительство</p>
          </div>
          <div className="implem__box">
            <div className="implem__number">
              <small>до</small>
              <strong className="counter" data-number="75" ref={thirdCounterRef} />
              <strong>%</strong>
            </div>
            <p>сокращается время на коммуникацию</p>
          </div>
        </div>
        <span className="title-bot">Постройте сквозное управление строительством вместе с OSLA</span>
      </div>
    </div>
  </section>
)}