import moment from "moment";

import { IShift, IShiftDetail, IShiftMaterialDetail, IShiftPlanWorkDetail, IShiftRelatedPlan } from "./types";

import { dropNonSignificantZeros } from "../../../../../../utils/formatters/dropNonSignificantZeros";

export interface ISerializedShiftPlan {
  parent_section: string;
  section: string;
  name: string;
  count: string;
  measure: string;
  start_at: string;
  end_at: string;
  plan_id: number;
  deactivated: boolean;
  not_enough: IShiftMaterialDetail[];
  related: IShiftRelatedPlan[];
}

const isShiftDetail = (shift: IShift | IShiftDetail): shift is IShiftDetail => {
  return shift.type === "group" ? "not_enough" in shift.plan_group : "not_enough" in shift.plan_work;
};

export const serializeShiftPlanData = (shift: IShift | IShiftDetail): ISerializedShiftPlan => {
  const isGroup = shift.type === "group";
  const isDetail = isShiftDetail(shift);

  const result = {
    parent_section: isGroup ? shift.plan_group.parent_section : shift.plan_work.parent_section,
    section: isGroup ? shift.plan_group.section : shift.plan_work.section,
    name: isGroup ? shift.plan_group.name : shift.plan_work.name,
    count: isGroup ? dropNonSignificantZeros(shift.plan_group.count) : dropNonSignificantZeros(shift.plan_work.count),
    measure: isGroup ? shift.plan_group.measure : shift.plan_work.measure,
    start_at: isGroup
      ? moment(shift.plan_group.start_at).format("DD.MM.YYYY")
      : moment(shift.plan_work.start_at).format("DD.MM.YYYY"),
    end_at: isGroup
      ? moment(shift.plan_group.end_at).format("DD.MM.YYYY")
      : moment(shift.plan_work.end_at).format("DD.MM.YYYY"),
    plan_id: isGroup ? shift.plan_group.id : shift.plan_work.id /* @ts-ignore */,
    deactivated: shift.deactivated ?? false,
    not_enough: [],
    related: [],
  };

  if (isDetail) {
    /* @ts-ignore */
    result.not_enough = isGroup ? shift.plan_group.not_enough : shift.plan_work.not_enough;
    /* @ts-ignore */
    result.related = isGroup ? shift.plan_group.related : shift.plan_work.related;
  }
  /* @ts-ignore */
  return result;
};

export const getDayLabel = (number: number) => {
  const titles = ["день", "дня", "дней"];
  const cases = [2, 0, 1, 1, 1, 2];
  return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
};

export const getShiftIdByPlanId = (shifts: Array<IShift | IShiftDetail>, planId: number) => {
  return (shifts ?? []).find((shift) => {
    if (shift.type === "work") {
      return shift.plan_work.id === planId;
    } else {
      return shift.plan_group.id === planId;
    }
  })?.id;
};
