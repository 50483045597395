import cn from "classnames";
import React, { useEffect } from "react";
import { Field } from "react-final-form";

import TaskCommentsContainer from "components/pages/Tasks/components/TaskComments/TaskCommentsContainer";
import { ICertainTaskResponse } from "components/pages/Tasks/model/types";

import RadioSquares from "../../../RadioSquares/RadioSquares";
import TaskFiles from "../../../TaskFiles/TaskFiles";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { TASKS_REMIND_OPTIONS, TASKS_REPEAT_OPTIONS, TASK_MODAL_TYPES } from "../../../../constants";

import MessageIcon from "images/icons/MessageIcon";

import styles from "../ModalAddTask/ModalAddTask.module.scss";

const ModalEditTaskExtraFields: React.FC<{
  isVisible?: boolean;
  certainTask: ICertainTaskResponse;
}> = ({ isVisible = true, certainTask }) => {
  const { id, task_files } = certainTask;

  return (
    <div
      className={cn(styles.fields, {
        [styles.isVisible]: isVisible,
      })}
    >
      <Field
        name={"remember_task"}
        allowNull
        render={({ input, meta }) => (
          <>
            <RadioSquares
              options={TASKS_REMIND_OPTIONS}
              label={"Напомнить"}
              defaultValue={meta.initial?.[0]}
              onClick={input.onChange}
              value={input.value?.[0] ?? input.value ?? "None"}
            />
          </>
        )}
      />
      <Field
        name={"repeat_task"}
        allowNull
        render={({ input, meta }) => (
          <>
            <RadioSquares
              options={TASKS_REPEAT_OPTIONS}
              label={"Повтор"}
              defaultValue={meta.initial}
              onClick={input.onChange}
              value={input.value ?? "None"}
            />
          </>
        )}
      />
      <TaskFiles files={task_files} taskId={id} variant={TASK_MODAL_TYPES.EDIT} />
      {/* <TaskCommentsContainer comments={task_comments || []} taskId={id} /> */}
    </div>
  );
};

export default ModalEditTaskExtraFields;
