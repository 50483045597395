import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IInterval } from "./types";

interface BuildingState {
  invalidationKey: number;
  loadings: Record<string, boolean>;
  intervals: Record<string, IInterval[]>;
}

export const initialState: BuildingState = {
  invalidationKey: 0,
  loadings: {},
  intervals: {},
};

const buildingSlice = createSlice({
  name: "building",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    },
    setIntervals: (state, action: PayloadAction<{ key: string; data: IInterval[] }>) => {
      const { key, data } = action.payload;
      state.intervals[key] = data;
    },
  },
});

export const buildingActions = buildingSlice.actions;

export const buildingReducer = buildingSlice.reducer;
