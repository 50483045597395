import Offc1Svg from "./img/offc1.png";
import OffcMob1Svg from "./img/offc-mob1.png";

export const PlanFactSection = () => (
  <section className="analysis-fact" id="plan-fact">
    <div className="container">
      <div className="analysis-fact__main">
        <div className="analysis-fact__flex">
          <div className="analysis-fact__photo">
            <img src={Offc1Svg} className="fact-pic-dec" alt="image" />
            <img src={OffcMob1Svg} className="fact-pic-mob" alt="image" />

          </div>
          <div className="analysis-fact__info">
            <h2>Анализ плана-факта</h2>
            <div className="sites-block__text">
              <ul>
                <li>Подрядчики фиксируют выполнение работ на платформе</li>
                <li>Руководители контролируют финансовый результат в режиме реального времени</li>
              </ul>
            </div>
            <div className="sites-block__hert">
              <ul>
                <li>Контроль фактически выполненных объемов и понесенных расходов по каждому виду работ за период</li>
                <li>Контроль выполнения недельного плана-графика</li>
              </ul>
            </div>
          </div>
        </div>
        <span className="title-bot">Контролируйте объемы и расходы</span>
      </div>
    </div>
  </section>
)