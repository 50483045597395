import React from 'react'
import cn from 'classnames'

import styles from './index.module.scss'

const Input = ({ className, classNameInput, value, setValue, ...rest }) => {
  const formattedValue = [undefined, null].includes(value) ? '' : value

  return (
    <div
      className={cn(styles.container, className, {
      })}
    >
      <input
        className={cn(styles.input, classNameInput, {
        })}
        value={formattedValue}
        onChange={(e) => setValue(e.target.value)}
        {...rest}
      />
    </div>
  )
}

export default Input
