import axios from "axios";

import { IAxesInList, ICreateAxis } from "./types";
import { IListResponseData } from "types/ListResponse";

export const axesApi = {
  getFacts: (buildingId: string, params: Record<string, string | number>) => {
    return axios.get<IListResponseData<IAxesInList>>(`/building/${buildingId}/drawings/facts/`, { params });
  },
  create: (buildingId: string, data: any) => {
    return axios.post(`/building/${buildingId}/drawings/facts/`, data);
  },
  createAxes: (buildingId: string, data: ICreateAxis, factId: number) => {
    return axios.post(`/building/${buildingId}/drawings/facts/${factId}/items/`, data);
  },
  edit: (buildingId: string, data: any, id: number) => {
    return axios.patch(`/building/${buildingId}/drawings/facts/${id}/`, data);
  },
  deleteItem: (buildingId: string, factId: number, id: number) => {
    return axios.delete(`/building/${buildingId}/drawings/facts/${factId}/items/${id}/`);
  },
  editItem: (buildingId: string, factId: number, id: number, data: any) => {
    return axios.patch(`/building/${buildingId}/drawings/facts/${factId}/items/${id}/`, data);
  },
};
