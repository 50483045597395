import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";

import { getDetail, objectDetailSelector } from "../../../redux/modules/common/building/object/nowObject";
import { setCurrentUser } from "redux/modules/_TODO/auth";

import ButtonBack from "../../../shared/ui/controls/ButtonBack/ButtonBack";
import Chat from "../../../widgets/Chat/ChatV2";
import { simpleHandlerActions } from "../../Handler/ui/SimpleHandler/model/slice";
import { ItemsList } from "../../Handler/ui/SimpleHandler/ui/ItemsList/ItemsList";
import { useTypedSelector } from "app/store/typedUseSelector";

import { EEstimateStatesIds } from "../../Handler/ui/ProHandler/enums";
import { chatRoomTypes } from "widgets/Chat/model/types";

import { useQueryParams } from "../../../utils/hooks/useQueryParams";
import { useApproveFormBackHandler } from "../hooks/useBackHandler";

import styles from "./SimplifiedApproveEstimateForm.module.scss";

interface IProps {
  projectId: string;
  buildingId: string;
  userId: string;
}

const SimplifiedApproveEstimateForm = memo<IProps>(({ projectId, buildingId, userId }) => {
  const dispatch = useDispatch();

  const state = useQueryParams("state")?.split("?")[0] as EEstimateStatesIds;

  const building = useTypedSelector(objectDetailSelector);

  useEffect(() => {
    dispatch(setCurrentUser());
  }, []);

  useEffect(() => {
    dispatch(getDetail(buildingId));
  }, [buildingId]);

  useEffect(() => {
    if (state) {
      dispatch(simpleHandlerActions.setEstimateStateId(state));
    } else {
      dispatch(simpleHandlerActions.setEstimateStateId(EEstimateStatesIds.LOCALE));
    }
  }, [state]);

  const { backTitle, onBack, isVisible: isBackButtonVisible } = useApproveFormBackHandler();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.paper}>
          <div className={styles.header}>
            <div className={styles.titlesGroup}>
              <span className={styles.title}>{building?.name}</span>
              <span className={styles.title}>{building?.entity_name} направил на утверждение смету</span>
            </div>
            <Chat
              room={chatRoomTypes.OBJECT_SHARED}
              building_id={+buildingId}
              title="Чат с заказчиком"
              subTitle="Чат с заказчиком"
              isStatic
              isDragDisable
            />
          </div>

          {isBackButtonVisible && (
            <div className={styles.back}>
              <ButtonBack className={styles.button} onClick={onBack} />
              <span className={styles.backTitle} title={backTitle} onClick={onBack}>
                {backTitle}
              </span>
            </div>
          )}

          <div className={styles.estimateContainer}>
            <ItemsList />
          </div>
        </div>
      </div>
    </>
  );
});

SimplifiedApproveEstimateForm.displayName = "SimplifiedApproveEstimateForm";

export default SimplifiedApproveEstimateForm;
