import React from "react";

import AutosizeInput, { AutosizeInputProps } from "react-input-autosize";

interface IProps extends AutosizeInputProps {}

export const DynamicInput: React.FC<IProps> = ({
  value,
  onChange,
  className,
  id,
  onBlur,
  ref,
  defaultValue,
  ...restProps
}) => {
  return (
    <AutosizeInput
      {...restProps}
      ref={ref as any}
      id={id}
      value={value}
      defaultValue={defaultValue}
      className={className}
      onChange={(e) => {
        /* this.setState({
          value: e.target.value,
        }); */
        onChange?.(e);
      }}
      onBlur={(e) => {
        /* if (e.target.value === "") {
          this.setState({
            value: value,
          });
        } */
        onBlur?.(e);
        //this.validate();
      }}
    />
  );
};

export default DynamicInput;
