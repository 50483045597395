import cn from "classnames";
import React, { useRef, useState } from "react";

import Actions from "shared/ui/controls/Actions/Actions";
import Checkbox from "shared/ui/inputs/Checkbox/Checkbox";

import styles from "./TaskApproversRow.module.scss";

interface IProps {
  number?: number;
  name: string;
  onCheck: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
  canBeChecked: boolean;
  canDelete: boolean;
  isSelected: boolean;
  id: number;
  onDelete: (id: number) => void;
}

const TaskApproversRow: React.FC<IProps> = ({
  name,
  number,
  onCheck,
  canBeChecked,
  canDelete,
  isSelected,
  id,
  onDelete,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const clickDrowningHandler = () => {
    ref.current?.click();
  };
  return (
    <div
      className={cn(styles.row, { [styles.selected]: isSelected, [styles.hover]: canBeChecked })}
      onClick={clickDrowningHandler}
    >
      {canBeChecked && (
        <div className={styles.checkbox}>
          <Checkbox onCheck={(e) => onCheck(e, id)} checked={isSelected}>
            <div ref={ref} />
          </Checkbox>
        </div>
      )}
      <div className={styles.number}>{number}</div>
      <div className={styles.name} title={name}>
        {name}
      </div>
      <div>
        {canDelete && <Actions canRemove={canDelete} onRemove={() => onDelete(id)} className={styles.delete} />}
      </div>
    </div>
  );
};

export default TaskApproversRow;
