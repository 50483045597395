export enum exportDocTypes {
    invoice = "Счет",
    packinglist = "УПД"
}

export const exportFilterTypesArray = [
    {
        id: "invoice",
        name: "Счет",
        label: "Счет"
    },
    {
        id: "packinglist",
        name: "УПД",
        label: "УПД",
    },
];

export const exportFilterStatusesArray = [
  {
    id: "processing",
    name: "Обработка",
    label: "Обработка",
  },
  {
    id: "need_processing",
    name: "Ожидание обработки",
    label: "Ожидание обработки",
  },
  {
    id: "complete",
    name: "Завершено",
    label: "Завершено",
  },
];