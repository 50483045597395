import { ICreateGroupValues } from "./types";

export const DEFAULT_BUILDING_FILTERS = {
  material: false,
  machine: false,
  transport: false,
  equipment: false,
};

export const PROGRESS = "progress";
export const PLAN = "plan";
export const DOCUMENTATION = "documentation";

export const TABS_IDS = [PROGRESS, PLAN, DOCUMENTATION];

export const OUT_OF_ESTIMATE_REQUIRED_FIELDS = ["name", "measure", "count", "expenditure_type"];

export const CREATE_GROUP_INITIALS: ICreateGroupValues = {
  name: "",
  measure: "",
  count: "",
  price: "",
  amount: "",
  for_contractor: false,
};
