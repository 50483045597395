import { Dispatch } from "redux";

import { settingsObjectApi } from "./api";
import { settingsObjectActions } from "./slice";
import { RootState } from "app/store/rootReducer";

export const getConstrControlAccountsForObject =
  (objectId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    settingsObjectApi
      .getConstrControlAccounts(objectId)
      .then(({ data }) => {
        dispatch(settingsObjectActions.setConstrControlAccounts({ key: objectId, data: data.results }));
      })
      .finally(() => {});
  };
