import { IFile } from "types/interfaces/Files";

export const prepareFiles = (files: IFile[]) => {
  if (!files) return [];
  return files.map((file) => {
    if (typeof file.file === "string") {
      return {
        id: file.id,
        file: {
          /* @ts-ignore */
          name: file.name || file.file_name,
          link: file.file,
        },
      };
    }
    return file;
  });
};
