import React from "react";

import styles from "./index.module.scss";

const Section = ({ data, blockOpenSubSections }) => {
  return (
    <div className={styles.container} onClick={() => blockOpenSubSections(data)}>
      <div className={styles.name}>{data?.name}</div>
      <div className={styles.count}>
        {/*<Line height='3rem'/>*/}
        <span>Материалы: </span>
        {data?.mims_count || data?.products_count || data?.expenditure_count}
      </div>
    </div>
  );
};

export default Section;
