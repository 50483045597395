import { IActions } from "../../../../app/store/store";

import { IProjectTreeResponse } from "../building/manufacturing/types";
import {
  AnalyticsTabsType,
  IAnalyticsProjectDatesData,
  IAnalyticsSelectedDates,
  IAnalyticsSelectedScope,
  IAnalyticsState,
} from "./types";
import { IAnalyticsBudgetData } from "./types/budget";
import { AnalyticsBudgetResourceType, IAnalyticsBudgetResourceData } from "./types/budgetResource";
import { IAnalyticsEvents, IAnalyticsEventsFilters } from "./types/events";
import { IAnalyticsFulfillmentData } from "./types/fulfillment";
import { IAnalyticsProgressData } from "./types/progress";

export const analyticsActions = {
  clearData: () =>
    ({
      type: "analytics/CLEAR_DATA",
      payload: {},
    } as const),
  setActiveTab: (newTab: AnalyticsTabsType) => ({ type: "analytics/SET_ACTIVE_TAB", payload: newTab } as const),
  setSelectedDates: (datesInfo: IAnalyticsSelectedDates) =>
    ({ type: "analytics/SET_SELECTED_DATES", payload: datesInfo } as const),
  changeSelectedScope: (newScope: Partial<IAnalyticsSelectedScope>) =>
    ({ type: "analytics/CHANGE_SELECTED_SCOPE", payload: newScope } as const),
  setBudgetResourceType: (newType: AnalyticsBudgetResourceType) =>
    ({ type: "analytics/BUDGET_RESOURCE_SET_TYPE", payload: newType } as const),
  setBudgetResourceData: (type: AnalyticsBudgetResourceType, data: IAnalyticsBudgetResourceData) =>
    ({ type: "analytics/BUDGET_RESOURCE_SET_DATA", payload: { type, data } } as const),
  setBudgetData: (data: IAnalyticsBudgetData[]) => ({ type: "analytics/SET_BUDGET_DATA", payload: data } as const),
  addTree: (objectId: number, data: IProjectTreeResponse) =>
    ({
      type: "analytics/ADD_TREE",
      payload: {
        objectId,
        data,
      },
    } as const),
  invalidateKey: () =>
    ({
      type: "analytics/INVALIDATE_KEY",
      payload: true,
    } as const),
  setEventsActiveTab: (newTab: IAnalyticsEvents["activeTab"]) =>
    ({ type: "analytics/SET_EVENTS_ACTIVE_TAB", payload: newTab } as const),
  changeEventsFilters: (payload: Partial<IAnalyticsEventsFilters>) =>
    ({ type: "analytics/CHANGE_EVENTS_FILTERS", payload } as const),
  setProgressData: (payload: IAnalyticsProgressData) => ({ type: "analytics/SET_PROGRESS_DATA", payload } as const),
  setFulfillmentData: (payload: IAnalyticsFulfillmentData) =>
    ({ type: "analytics/SET_FULFILLMENT_DATA", payload } as const),
  addProjectDates: (key: string, dates: IAnalyticsProjectDatesData) =>
    ({ type: "analytics/ADD_PROJECT_DATES", payload: { key, dates } } as const),
  setOverdueEvents: (events: IAnalyticsEvents["overdueEventsData"]) =>
    ({ type: "analytics/SET_OVERDUE_EVENTS", payload: events } as const),
  setAllEvents: (events: IAnalyticsEvents["allEventsData"]) =>
    ({ type: "analytics/SET_ALL_EVENTS", payload: events } as const),
  setLoading: (area: keyof IAnalyticsState["loadings"], isLoading: boolean) =>
    ({
      type: "analytics/SET_LOADING",
      payload: { area, isLoading },
    } as const),
};

export type IAnalyticsAction = IActions<typeof analyticsActions>;
