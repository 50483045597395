import { useCallback, useMemo } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { activeSectionSelector, activeSubSectionSelector, estimateStateIdSelector } from "../../../model/selectors";
import { simpleHandlerActions } from "../../../model/slice";

import { IHandlerMatchParams } from "../../../../../types/MatchParams";
import { EEstimateStatesIds } from "../../../../ProHandler/enums";

import { useStatusFromQueryParams } from "../../ItemsList/ui/SubSectionsList/hooks/useStatusFromQueryParams";

export const useBackHandler = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const match = useRouteMatch();
  const sectionIdMatch = useRouteMatch<IHandlerMatchParams>(`${match.path}/:sectionId`);
  const { buildingId, projectId } = useParams<{ buildingId?: string; projectId?: string }>();

  const activeSection = useSelector(activeSectionSelector);
  const activeSubSection = useSelector(activeSubSectionSelector);
  const estimateStateId = useSelector(estimateStateIdSelector);

  const status = useStatusFromQueryParams();

  const backTitle = useMemo(() => {
    if (activeSubSection) {
      return activeSubSection.name;
    }

    if (activeSection) {
      return activeSection.name;
    }

    if (sectionIdMatch) {
      return "";
    }

    return "Объекты";
  }, [activeSection, activeSubSection, sectionIdMatch]);

  const onBack = useCallback(() => {
    if (!buildingId || !projectId) return "";

    if (activeSubSection && activeSection) {
      // если находимся на уровне расценок
      batch(() => {
        dispatch(simpleHandlerActions.setActiveSubSection(null));
        dispatch(simpleHandlerActions.setExpenditures(null));
        dispatch(simpleHandlerActions.clearCheckedItems());
        dispatch(simpleHandlerActions.clearEditingExpenditures());
        dispatch(simpleHandlerActions.clearAddingExpenditures());
      });
      if (estimateStateId === EEstimateStatesIds.LOCALE && status) {
        // В локальном сметном расчете важно сохранить статус
        // при переходе назад на уровень подразделов
        history.push(`/constructing/handler/${projectId}/${buildingId}/${activeSection.id}?status=${status}`);
      } else {
        history.push(`/constructing/handler/${projectId}/${buildingId}/${activeSection.id}`);
      }
    } else if (activeSection) {
      // если находимся на уровне подразделов
      batch(() => {
        dispatch(simpleHandlerActions.setActiveSection(null));
        dispatch(simpleHandlerActions.setSubSections(null));
        dispatch(simpleHandlerActions.clearCheckedItems());
      });
      history.push(`/constructing/handler/${projectId}/${buildingId}`);
      return;
    } else {
      // если находимся на верхнем уровне c разделами
      dispatch(simpleHandlerActions.reset());
      history.push(`/constructing/projects/objects-list/${projectId}`);
    }
  }, [projectId, buildingId, activeSection, activeSubSection, estimateStateId, status]);

  return {
    backTitle,
    onBack,
  };
};
