import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { TableOslaLocal } from "_LEGACY/UI/_LEGACY_TableOslaCustom/TableOslaLocal";
import { TableHeader } from "_LEGACY/UI/_LEGACY_TotoRowTable/TableHeader/TableHeader";
import Checkbox from "_LEGACY/UI/__TODO/Checkbox/Checkbox";

import { assignEmployeeAsSigner, bindSigner, getAllSignators, getEmployeesForSigners } from "../../model/thunks";
import CreateSignatorForm from "./CreateSignatorForm/CreateSignatorForm";
import { useTypedSelector } from "app/store/typedUseSelector";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";
import { InputSearchRound } from "shared/ui/inputs/InputSearchRound/InputSearchRound";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import { SIGNERS_TYPES_LABELS } from "../../constants";
import { ISignerInCommonList, SignerTypes } from "../../model/types";

import getShortFullName from "utils/formatters/getShortFullName";
import { generateStorageKey } from "utils/helpers/generateStorageKey";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./SignatorsModal.module.scss";

const tabs = [
  { id: "Select", text: "Выбрать" },
  { id: "New", text: "Добавить" },
];

const nameFormatter = (_: never, row: ISignerInCommonList) => {
  const { last_name, first_name, middle_name } = row;

  return <div className={styles.cell}>{`${last_name} ${first_name} ${middle_name}`}</div>;
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  type: SignerTypes;
  objectId: string;
}

const SignatorsModal: React.FC<IProps> = ({ isOpen, onClose, type, objectId }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("Select");
  const {
    invalidateKey,
    isLoading: loadingsDict,
    allSignators,
    employees,
  } = useTypedSelector((state) => state.signatorsSlice);
  const isPendingCreating = loadingsDict["createAll"];
  const thisEmployees = employees[objectId];
  const employeeIsAssigningKey = generateStorageKey({ type: "assignManually" });
  const employeesLoading = loadingsDict[generateStorageKey({ type: "loadEmployees", buildingId: objectId })];
  const isLoading = loadingsDict["loadAll"];
  const employeeIsAssigning = loadingsDict[employeeIsAssigningKey];

  const showLoader = isPendingCreating || isLoading || employeesLoading;

  useEffect(() => {
    dispatch(getEmployeesForSigners(objectId));
  }, [invalidateKey, objectId]);

  const [checkedId, setCheckedId] = useState(0);
  const checkboxFormatter = (_: never, row: ISignerInCommonList) => {
    return (
      <div onClick={stopEventPropagation}>
        <Checkbox checked={row.id === checkedId} onChange={(e) => setCheckedId(row.id)} />
      </div>
    );
  };

  const filteredSigners = useMemo(() => {
    const formattedEmployees = (thisEmployees ?? []).map((el) => ({ ...el, position: el.roles?.[0]?.name }));
    return formattedEmployees.filter(
      (el) =>
        el.first_name.toLowerCase().includes(search.toLowerCase()) ||
        el.last_name.toLowerCase().includes(search.toLowerCase())
    );
  }, [allSignators, search, thisEmployees]);

  const submitCheckedSigner = () => {
    dispatch(assignEmployeeAsSigner(checkedId, objectId, type));
  };

  useEffect(() => {
    setCheckedId(0);
    setActiveTab("Select");
    onClose();
  }, [invalidateKey]);

  return (
    <SliderModal isOpen={isOpen} closeHandler={onClose} className={styles.sliderClassName}>
      <div className={styles.header}>
        <div className={styles.title}>Подписанты</div>
        <div className={styles.subtitle}>{SIGNERS_TYPES_LABELS[type]}</div>
        <TabBarNotLinks
          className={styles.tabs}
          tabs={tabs}
          activeId={activeTab}
          onClick={setActiveTab}
          isOnlyTabsView
        />
        {activeTab === "Select" && (
          <div className={styles.searchBlock}>
            <InputSearchRound
              placeholder="Поиск по сотрудникам"
              onChange={(e: any) => setSearch(e.target.value)}
              defaultValue={search}
              className={styles.inputSearchClassName}
            />
            <ButtonBase isLoading={employeeIsAssigning} primary onClick={submitCheckedSigner} disabled={!checkedId}>
              Добавить
            </ButtonBase>
          </div>
        )}
      </div>

      {activeTab === "Select" && (
        <div className={styles.tableWrapper}>
          <TableOslaLocal
            data={filteredSigners}
            isLoading={showLoader}
            keyProp={"id"}
            onRowClick={(row: ISignerInCommonList) => {
              setCheckedId(row.id);
            }}
            style={{ userSelect: "none" }}
            pagination={false}
            cellClassName={styles.tableCell}
          >
            <TableHeader formatter={checkboxFormatter} cellStyle={{ width: "10%" }}></TableHeader>
            <TableHeader
              cellStyle={{ width: "45%" }}
              formatter={nameFormatter}
              style={{ whiteSpace: "nowrap", fontWeight: "500", fontSize: "1rem", color: "#000" }}
            >
              Сотрудник
            </TableHeader>
            <TableHeader
              cellStyle={{ width: "45%" }}
              data={"position"}
              style={{ whiteSpace: "nowrap", fontWeight: "500", fontSize: "1rem", color: "#000" }}
            >
              Должность
            </TableHeader>
          </TableOslaLocal>
        </div>
      )}
      {activeTab !== "Select" && (
        <CreateSignatorForm type={type} objectId={objectId} onCancel={() => setActiveTab("Select")} />
      )}
    </SliderModal>
  );
};

export default SignatorsModal;
