import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { tasksListFilterType } from "components/pages/Tasks/model/types";

import TaskRowByAllObjects from "../TaskRowByAllObjects/TaskRowByAllObjects";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import ShowMoreButton from "shared/ui/controls/ShowMoreButton";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { TASK_LISTS_TYPES } from "../../constants";

import { useDeleteTask } from "../../hooks/useDeleteTask";
import { useTasksByAllBuildingsData } from "../../hooks/useTasksByAllBuildingsData";
import { useTasksModal } from "../../hooks/useTasksModal";

import taskIcon from "images/icons/navigation/tasksIcon.svg";

type propsType = {
  filters: tasksListFilterType;
  type: TASK_LISTS_TYPES;
  onChangeSection: (id: number) => void;
  setIsEmpty: (_: boolean) => void;
};

function TaskListAllObjects({ filters, type, onChangeSection, setIsEmpty }: propsType) {
  const { taskId } = useParams<{ taskId: string }>();

  const { tasksByType, isTasksLoading, loadMoreTasksHandler } = useTasksByAllBuildingsData({ type, filters });
  const { deleteTaskHandler } = useDeleteTask({ variant: "byAllBuildings", type });
  const { openAddModal, openEditModal, openViewModal, onClose } = useTasksModal();

  const [hiddenBuildingIds, setHiddenBuildingIds] = useState<number[]>([]);

  useEffect(() => {
    if (!taskId) return;

    handleOpenTask(Number(taskId));
  }, [taskId]);

  const expandHandler = useCallback(
    (id: number) => {
      setHiddenBuildingIds((prev) => {
        if (prev.includes(id)) {
          return prev.filter((el) => Number(el) !== Number(id));
        } else {
          return [...prev, id];
        }
      });
    },
    [setHiddenBuildingIds]
  );

  const handleOpenTask = useCallback(
    (id: number) => {
      openViewModal({
        placementType: "byAllBuildings",
        listType: type,
        id,
      });
    },
    [type]
  );

  const handleEditTask = useCallback(
    (id: number) => {
      openEditModal({
        placementType: "byAllBuildings",
        listType: type,
        id,
      });
    },
    [type]
  );

  const handleAddTask = useCallback(() => {
    openAddModal({
      placementType: "byAllBuildings",
      listType: type,
    });
  }, [type]);

  const isEmpty = !tasksByType?.results?.length;

  useEffect(() => {
    setIsEmpty(isEmpty);
    return () => {
      setIsEmpty(false);
    };
  }, [isEmpty]);

  if (isTasksLoading) {
    return <Spinner />;
  }

  return (
    <>
      {!isEmpty &&
        tasksByType.results.map((el, i, arr) => (
          <TaskRowByAllObjects
            key={el.id}
            {...el}
            dateEnd={el.deadline}
            dateStart={el.created_at}
            commentsCount={el.task_comments?.length || 0}
            executor={el.executor_user}
            onDelete={deleteTaskHandler}
            onEdit={handleEditTask}
            isExists={!hiddenBuildingIds.includes(el.building)}
            isFirstInObject={i === 0 || el.building !== arr[i - 1].building}
            building={el.building_obj}
            onExpand={expandHandler}
            handleOpen={() => handleOpenTask(el.id)}
            onChangeSection={onChangeSection}
            filters={filters}
          />
        ))}
      {tasksByType?.next && (
        <ShowMoreButton
          isExists={!!tasksByType?.next}
          handleAdd={loadMoreTasksHandler}
          allCount={tasksByType?.count}
          showedCount={tasksByType?.results?.length}
        />
      )}
      {isEmpty && (
        <EmptyPlaceholder text="Нет данных в разделе задач" img={taskIcon}>
          <ButtonBase primary onClick={handleAddTask}>
            Новая задача
          </ButtonBase>
        </EmptyPlaceholder>
      )}
    </>
  );
}

export default TaskListAllObjects;
