import cn from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { compose } from "redux";

import ButtonArrow from "../../controls/ButtonArrow/ButtonArrow";
import WeekBar from "./WeekBar/WeekBar";
import WeeksInYear from "./domain/WeeksInYear";

import styles from "./WeeksSlider.module.scss";

const LAST_WEEK_NUMBER = 1;

let activeWeekNumberUpdateWasByArrows = false;

interface IProps {
  className?: string;
  year: number;
  activeWeekNumber: number;
  setActiveWeekNumber: (week: number) => void;
  displayWeeksCount?: number;
}

const WeeksSlider: React.FC<IProps> = ({
  className,
  year,
  activeWeekNumber,
  setActiveWeekNumber,
  displayWeeksCount = 6,
}) => {
  const weeksInYearCount = moment().year(year).weeksInYear();
  const weeksInYear = useMemo(() => new WeeksInYear(year), [year]);
  //TODO
  //@ts-ignore
  const [weeks, setWeeks] = compose(useState, weeksInYear.getWeeksGroupByMiddle)(activeWeekNumber, displayWeeksCount);

  const activeNextWeek = useCallback(() => {
    if (activeWeekNumber === weeksInYearCount) return;

    const updatedActiveWeekNumber = activeWeekNumber + 1;
    activeWeekNumberUpdateWasByArrows = true;
    setActiveWeekNumber(updatedActiveWeekNumber);

    //@ts-ignore
    if (updatedActiveWeekNumber > weeks[weeks.length - 1]?.number)
      //@ts-ignore
      compose(setWeeks, weeksInYear.getWeeksGroupByEnd)(updatedActiveWeekNumber, displayWeeksCount);
    //@ts-ignore
  }, [activeWeekNumber, weeks[weeks.length - 1]?.number, displayWeeksCount, weeksInYear]);

  const activePrevWeek = useCallback(() => {
    if (activeWeekNumber === LAST_WEEK_NUMBER) return;

    const updatedActiveWeekNumber = activeWeekNumber - 1;
    activeWeekNumberUpdateWasByArrows = true;
    setActiveWeekNumber(updatedActiveWeekNumber);
    /* @ts-ignore */
    if (updatedActiveWeekNumber < weeks[0]?.number)
      //@ts-ignore
      compose(setWeeks, weeksInYear.getWeeksGroupByStart)(
        /* @ts-ignore */
        updatedActiveWeekNumber - 1,
        displayWeeksCount
      ); /* @ts-ignore */
  }, [activeWeekNumber, weeks[0]?.number, displayWeeksCount, weeksInYear]);

  useEffect(() => {
    if (activeWeekNumberUpdateWasByArrows) {
      activeWeekNumberUpdateWasByArrows = false;
      return;
    }
    //@ts-ignore
    compose(setWeeks, weeksInYear.getWeeksGroupByMiddle)(activeWeekNumber, displayWeeksCount);
  }, [activeWeekNumber, displayWeeksCount, weeksInYear]);

  return (
    <div className={cn(styles.weeksSlider, className)}>
      <ButtonArrow direction="left" onClick={activePrevWeek} />
      <div className={styles.weeks}>
        {/* @ts-ignore */}
        {weeks.map((week) => (
          <WeekBar
            className={styles.weekBar}
            number={week.number}
            range={week.range}
            isActive={activeWeekNumber === week.number}
            key={week.number}
          />
        ))}
      </div>
      <ButtonArrow direction="right" onClick={activeNextWeek} />
    </div>
  );
};

export default React.memo(WeeksSlider);
