import cn from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { compose } from "redux";

import { getOrder } from "redux/modules/_TODO/orderCard/orders";

import Checkbox from "_LEGACY/UI/__TODO/Checkbox/Checkbox";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { DEFAULT_ENTITY_TYPE } from "components/pages/Order/constants";

import Difference from "../../../../../shared/ui/dataDisplay/Difference/Difference";
import { useTypedSelector } from "app/store/typedUseSelector";
import { NavLinkIcon } from "shared/ui/controls/NavLinkIcon/NavLinkIcon";
import { Switch } from "shared/ui/inputs/Switch/Switch";

import { COMPLETED, WAITING_SHIPMENT } from "constants/constant";
import { NavLinkVariantsEnum } from "shared/ui/controls/NavLinkIcon/constants";
import { IOrder } from "types/interfaces/Order";

import { useUrlModule } from "../../../../../utils/hooks/useUrlModule";

import { transformDigitToFinancial } from "../../../../../utils/formatters/transformDigitToFinancial";
import { getUserType } from "utils/helpers/getUserType";

import ArrowSmallUpGrey from "images/icons/ArrowSmallUpGrey";

import styles from "./index.module.scss";

export const ESTIMATE_DIFFERENCE_TITLE = "Разница по смете";
export const SPECIFICATION_DIFFERENCE_TITLE = "Разница по спецификации";

const haveRequestsOffers = (requests: any) => requests.every((request: any) => request.offers.length !== 0);

interface IProps {
  isOrderOpen: boolean;
  order: IOrder;
  handleOrderOpening: any;
  isShownInvoiceDifference: boolean;
  isSimplified: boolean;
  isChecked: boolean;
  onCheck: any;
}

const OrderHeader = ({
  isOrderOpen,
  order,
  handleOrderOpening,
  isShownInvoiceDifference,
  isSimplified,
  isChecked,
  onCheck,
}: IProps) => {
  const isCompleted = order.status === COMPLETED || order.status === WAITING_SHIPMENT;
  const haveOffers = useMemo(() => haveRequestsOffers(order.body.requests), [order.body.requests]);
  const urlAdapter = useUrlModule();
  const objectId = useObjectId();
  const dispatch = useDispatch();

  const authEntityType = useTypedSelector((state) => state.auth.entity.type) || DEFAULT_ENTITY_TYPE;

  const [isSpecificationCalculation, setIsSpecificationCalculation] = useState(false);
  const [isDidMount, setIsDidMount] = useState(true);

  const urlPrefix = () => {
    if (urlAdapter !== "objects") return urlAdapter;
    return `${urlAdapter}/${objectId}`;
  };

  const check = useCallback((e: any) => onCheck(order.id, e.target.checked), [onCheck, order.id]);

  useEffect(() => {
    if (!order || isDidMount) {
      setIsDidMount(false);

      return;
    }

    //@ts-ignore
    compose(dispatch, getOrder)(order?.id, {
      userType: getUserType(authEntityType),
      withLoading: false,
      isSpecificationCalculation,
      isUpdateOrderInRequsition: true,
    });
  }, [isSpecificationCalculation]);

  const handleSpecificationChange = useCallback(() => {
    setIsSpecificationCalculation((prev) => !prev);
  }, [setIsSpecificationCalculation]);

  const amount = useMemo(() => {
    return order.including_vat && order.benefit.amount_plan
      ? order.benefit.amount_plan_diff
      : order.benefit.amount_plan_diff_nds;
  }, [order]);

  const specificationAmount = useMemo(() => {
    return order.including_vat && order.benefit.specification_amount_plan
      ? order.benefit.specification_amount_plan_diff
      : order.benefit.specification_amount_plan_diff_nds;
  }, [order]);

  return (
    <div className={styles.orderHeader}>
      <div
        className={cn(styles.buttonOpen, {
          [styles.completed]: isCompleted,
          [styles.haveOffers]: !isCompleted && haveOffers,
        })}
        onClick={() => handleOrderOpening(order.id)}
      >
        <ArrowSmallUpGrey color="#000" rotate={isOrderOpen ? "0" : "180"} />
      </div>
      <div className={styles.checkbox}>
        <Checkbox checked={isChecked} onChange={check} />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.topSide}>
          <div>
            Заказ:{" "}
            {!isSimplified ? (
              <NavLinkIcon
                to={`/${urlPrefix()}/orders/${order.id}`}
                variant={NavLinkVariantsEnum.SECONDARY}
                title={order.number}
              >
                {order.number}
              </NavLinkIcon>
            ) : (
              order.number
            )}
          </div>
          <div className={styles.status}>{order.status_name}</div>
          <div className={styles.divider} />
          <div className={styles.infoBlock}>
            <span className={styles.name}>Сумма:</span>
            <span>
              {transformDigitToFinancial(order.calculation.amount, { withFloat: true, withCurrencySign: true })}
            </span>
          </div>
          <div className={styles.divider} />
          <div className={styles.infoBlock}>
            <span className={styles.name}>Сумма по смете:</span>
            <span>
              {transformDigitToFinancial(order.benefit.amount_plan!, { withFloat: true, withCurrencySign: true })}
            </span>
          </div>
          {isShownInvoiceDifference && (
            <>
              <div className={styles.divider} />
              <div className={styles.infoBlock}>
                <span className={styles.name}>Разница:</span>
                <Difference amount={amount!} title={ESTIMATE_DIFFERENCE_TITLE} />
                {isShownInvoiceDifference && (
                  <Difference amount={specificationAmount!} withDivider title={SPECIFICATION_DIFFERENCE_TITLE} />
                )}
              </div>
            </>
          )}
        </div>
        <div className={styles.bottomSide}>
          <div className={styles.infoBlock} title={order.executor_provider?.last_name}>
            <span className={styles.name}>Поставщик:</span>
            <span className={styles.halfOpacity}>{order.executor_provider?.last_name}</span>
          </div>
          {!isSimplified && order.acts?.length !== 0 && (
            <>
              <div className={styles.divider} />
              <div className={cn(styles.infoBlock, styles.packingLists)}>
                <span className={styles.name}>Список актов:</span>
                {order.acts.map((act) => (
                  <div key={act.id} className={styles.act}>
                    <NavLinkIcon
                      to={`/${urlPrefix()}/documents/service-acts/${act.id}`}
                      title={moment(act.date).format("DD.MM.YYYY")}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {!isSimplified && order.packinglists?.length !== 0 && (
            <>
              <div className={styles.divider} />
              <div className={cn(styles.infoBlock, styles.packingLists)}>
                <span className={styles.name}>Список УПД:</span>
                {order.packinglists.map((packingList) => (
                  <div key={packingList.id} className={styles.packingList}>
                    <NavLinkIcon
                      to={`/${urlPrefix()}/documents/packing-lists/${packingList.id}`}
                      title={moment(packingList.delivery_date).format("DD.MM.YYYY")}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {!isSimplified && (
            <>
              <div className={styles.divider} />
              <div className={styles.infoBlock}>
                <span className={styles.name}>Счет:</span>
                {order.invoice ? (
                  <NavLinkIcon
                    to={`/${urlPrefix()}/documents/bills/${order.invoice.id}`}
                    title={order.invoice.number || "сформирован"}
                  />
                ) : (
                  <span className={styles.halfOpacity}>формируется</span>
                )}
              </div>
            </>
          )}
          {isShownInvoiceDifference && (
            <div className={styles.switcher}>
              <span>Подсчет по спецификации</span>
              <Switch checked={isSpecificationCalculation} onChange={handleSpecificationChange} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(OrderHeader);
