import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  deleteFileInStorage,
  getStorageCounts,
  renameFileInStorage,
  renameFolderInStorage,
} from "redux/modules/common/filesStorage/thunks";
import { deleteFolderInStorage } from "redux/modules/common/filesStorage/thunks";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import ThreeDots from "./assets/ThreeDots";
import ConfirmModal from "entities/ConfirmationModal/ConfirmModal";
import ButtonBase from "shared/ui/controls/ButtonBase";
import InputBase from "shared/ui/inputs/InputBase";

import { IRouterParamsWithObjectId } from "../../../../../types/routerTypes";

import { switchFileIcons } from "./utils";
import declOfNum from "utils/formatters/declOfNum";
import downloadFile from "utils/helpers/download";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./FileItem.module.scss";

interface IProps {
  name: string;
  isFolder?: boolean;
  link?: string;
  isNewFolder?: boolean;
  onCancelEditing?: () => void;
  onApproveEditing?: (name: string) => void;
  onOpenFolder?: (folderId: number, folderName: string) => void;
  id: number;
  filesCount?: number;
  foldersCount?: number;
  isEditable?: boolean;
  onFileClick?: () => void;
}

const FileItem: React.FC<IProps> = ({
  name,
  isFolder,
  link,
  isNewFolder,
  onCancelEditing,
  onApproveEditing,
  onOpenFolder,
  id,
  filesCount,
  foldersCount,
  isEditable = true,
  onFileClick,
}) => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isRenaming, setIsRenaming] = React.useState(isNewFolder);
  const [inputValue, setInputValue] = React.useState(name);

  const inputHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    [setInputValue]
  );

  const switchRenaming = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      isNewFolder && onCancelEditing?.();
      setIsRenaming((prev) => !prev);
    },
    [setIsRenaming, onCancelEditing, isNewFolder]
  );

  const iconSrc = React.useMemo(() => {
    return switchFileIcons(name, isFolder, link);
  }, [name, isFolder, link]);

  const openFolderHandler = React.useCallback(() => {
    if (!isFolder) return;
    onOpenFolder?.(id, name);
  }, [id, onOpenFolder, isFolder]);

  const downloadFileHandler = React.useCallback(() => {
    if (!link) return;
    downloadFile(link, name);
  }, [link, name]);

  const deleteHandler = React.useCallback(() => {
    if (isFolder) {
      dispatch(deleteFolderInStorage(id, objectId, () => setIsDeleting(false)));
    } else {
      dispatch(deleteFileInStorage(id, objectId, () => setIsDeleting(false)));
    }
  }, [id, isFolder, dispatch]);

  const approveNaming = React.useCallback(() => {
    if (!inputValue) return;
    if (isNewFolder) {
      onApproveEditing?.(inputValue);
    } else if (isFolder) {
      dispatch(renameFolderInStorage(id, inputValue));
    } else {
      dispatch(renameFileInStorage(id, inputValue));
    }
    setIsRenaming(false);
  }, [inputValue]);

  if (!id) return null;

  return (
    <>
      <div className={styles.container} onClick={openFolderHandler}>
        {isEditable && !isRenaming && (
          <div className={styles.controls} onClick={stopEventPropagation}>
            <PopoverOverlay
              popoverBorderColor="primary"
              placement="right"
              content={
                <div className={styles.popup}>
                  <div onClick={switchRenaming}>Переименовать</div>
                  {!isFolder && <div onClick={downloadFileHandler}>Скачать</div>}
                  <div onClick={() => setIsDeleting(true)}>Удалить</div>
                </div>
              }
            >
              <ThreeDots />
            </PopoverOverlay>
          </div>
        )}
        <div className={styles.icon} onClick={onFileClick}>
          {iconSrc}
        </div>
        {!isRenaming && (
          <div className={styles.name} title={name}>
            {name}
          </div>
        )}
        {!isRenaming && isFolder && !isNewFolder && (
          <div className={styles.count}>
            <div>{declOfNum(filesCount, ["файл", "файла", "файлов"])}</div>
            <div>{declOfNum(foldersCount, ["папка", "папки", "папок"])}</div>
          </div>
        )}
        {isRenaming && (
          <div className={styles.rename} onClick={stopEventPropagation}>
            {/* @ts-ignore */}
            <InputBase
              value={inputValue}
              onChange={inputHandler}
              className={styles.input}
              classNameInput={styles.inputInner}
            />
            <ButtonBase primary small className={styles.btn} onClick={approveNaming}>
              Применить
            </ButtonBase>
            <ButtonBase secondary small onClick={switchRenaming} className={styles.btn}>
              Отменить
            </ButtonBase>
          </div>
        )}
      </div>
      <ConfirmModal
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        title="Вы уверены, что хотите удалить?"
        variant="secondary"
        acceptButtonText="Удалить"
        action={deleteHandler}
      />
    </>
  );
};

export default React.memo(FileItem);
