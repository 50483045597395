import React from "react";
import { useDispatch } from "react-redux";

import { startLoadingModalAction, stopLoadingModalAction } from "redux/modules/common/building/manufacturing/actions";
import { getSectionPlan } from "redux/modules/common/building/processApi";
import { ISectionPlan } from "redux/modules/common/building/processTypes";

import { ManufacturingModalType } from "components/pages/Manufacturing/components/modals/ManufacturingModal/useManufacturingModal";

export const useGetSectionPlan = (
  objectId: string,
  sectionId: number,
  isOpen: boolean,
  modalType: ManufacturingModalType
) => {
  const dispatch = useDispatch();

  const [planData, setPlanData] = React.useState<ISectionPlan | null>(null);

  const getSectionPlanFetcher = React.useCallback(() => {
    dispatch(startLoadingModalAction(modalType));
    getSectionPlan(objectId, sectionId).then((resp) => {
      setPlanData(resp.data);
      dispatch(stopLoadingModalAction(modalType));
    });
  }, [objectId, sectionId]);

  React.useEffect(() => {
    if (!isOpen) return;
    getSectionPlanFetcher();
  }, [isOpen, getSectionPlanFetcher]);

  return {
    planData,
    getSectionPlanFetcher,
  };
};
