import cn from "classnames";
import React from "react";

import { IRequest } from "../../types";

import { transformPrice } from "../../utils";
import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";

import orderComparisonStyles from "../../OrderComparison.module.scss";
import orderRequestCardStyles from "../OrderRequestCard/OrderRequestCard.module.scss";

export interface IExpenditureInfoProps {
  request: IRequest;
  hasBlueBg?: boolean;
}

const ExpenditureInfo: React.FC<IExpenditureInfoProps> = ({ request, hasBlueBg }) => {
  const {
    name,
    estimate_price,
    estimate_count,
    estimate_measure,
    estimate_amount,
    is_default,
    measure: defaultMeasure,
    price,
  } = request.product_building?.estimate_expenditure || {};
  const { count, measure, name: productBuildingName } = request.product_building || {};
  return (
    <div
      className={cn(orderComparisonStyles.expenditureName, orderRequestCardStyles.card, {
        [orderComparisonStyles.blueBg]: hasBlueBg,
      })}
    >
      <span className={orderRequestCardStyles.bold}>{name || productBuildingName}</span>
      <div className={orderRequestCardStyles.block}>
        <div className={orderRequestCardStyles.blockName}>По проекту:</div>
      </div>
      <div className={orderRequestCardStyles.block}>
        <div className={orderRequestCardStyles.blockName}>Цена за ед.:</div>
        <div className={orderRequestCardStyles.bold}>{transformPrice(is_default ? price : estimate_price)}</div>
      </div>
      <div className={orderRequestCardStyles.block}>
        <div className={orderRequestCardStyles.blockName}>Количество:</div>
        <div className={orderRequestCardStyles.bold}>{`${dropNonSignificantZeros(estimate_count ?? count)} ${
          is_default ? defaultMeasure : estimate_measure ?? measure
        }`}</div>
      </div>
      <div className={orderRequestCardStyles.block}>
        <div className={orderRequestCardStyles.blockName}>Бюджет:</div>
        <div className={orderRequestCardStyles.bold}>{transformPrice(estimate_amount)}</div>
      </div>
    </div>
  );
};

export default ExpenditureInfo;
