import React from "react";

import ConfirmationModal from "../../../../../../entities/ConfirmationModal/ConfirmModal";

import DeleteIcon from "../../../../../../images/icons/DeleteIcon";

const DeleteOffer = ({ openModal, closeModal, isOpen, deleteOffer }) => {
  return (
    <>
      <DeleteIcon onClick={openModal} />
      <ConfirmationModal isOpen={isOpen} onClose={closeModal} action={deleteOffer}>
        Удалить предложение?
      </ConfirmationModal>
    </>
  );
};

export default React.memo(DeleteOffer);
