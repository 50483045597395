import React from "react";

import { useAuditTreeSelector } from "../../model/useAuditTreeSelector";
import Table from "./Table/Table";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import financeIcon from "images/icons/navigation/finance.svg";

import styles from "./TableSide.module.scss";

const TableSide = () => {
  const { showSpinner, tableData } = useAuditTreeSelector();

  if (showSpinner) return <Spinner isStatic />;

  if (!tableData?.length) return <EmptyPlaceholder img={financeIcon} />;

  return (
    <div className={styles.tableSide}>
      <Table data={tableData} />
    </div>
  );
};

export default React.memo(TableSide);
