import axios from 'axios';

export function apiGetImportList(objectId) {
  return axios.get(`/building/${objectId}/products/import/`);
}
export function apiPostImportList(objectId, config) {
  return axios.post(`/building/${objectId}/products/import/`, config);
}

export function apiDeleteImportList(objectId, Id) {
  return axios.delete(`building/${objectId}/products/import/${Id}/`);
}
