import cn from "classnames";
import React from "react";

import { IFile } from "types/interfaces/Files";

import { sliceTextByConstraint } from "../../../../utils/formatters/sliceTextByConstraint";
import { parseFileExtension } from "../../../../utils/parseFileFormat";

import anyFile from "../../../../images/file-extensions/anyfile.svg";
import CrossInCircle from "../../../../images/icons/CrossInCircle";
import docx from "../../../../images/icons/docx.svg";
import pdf from "../../../../images/icons/pdf.svg";
import xls from "../../../../images/icons/xls.svg";

import styles from "./FileRow.module.scss";

const getFileIcon = (fileName: string) => {
  const fileExtension = parseFileExtension(fileName);
  switch (fileExtension) {
    case "pdf":
      return pdf;
    case "xls":
      return xls;
    case "xlsx":
      return xls;
    case "docx":
      return docx;
    default:
      return anyFile;
  }
};

const MAX_FILE_NAME_LENGTH = 35;

export interface IProps {
  file: IFile;
  download?: (file: IFile) => void;
  remove?: (removedFileId: number) => void;
  canRemove?: boolean;
  isLoading?: boolean;
  canDownload?: boolean;
  onDirectlyRemove?: () => void;
  className?: string;
}

const FileRow: React.FC<IProps> = ({
  isLoading,
  file,
  download,
  remove,
  canRemove,
  canDownload = true,
  onDirectlyRemove,
  className,
}) => {
  const removeHandler = React.useCallback(() => {
    if (onDirectlyRemove) {
      onDirectlyRemove();
    } else {
      remove?.(file.id);
    }
  }, [onDirectlyRemove, remove, file.id]);

  const downloadHandler = React.useCallback(() => {
    canDownload && download?.(file);
  }, [canDownload, download, file]);

  return (
    <div className={cn(styles.file, { [styles.loading]: isLoading }, className)} title={file.name} key={file.id}>
      <span className={styles.fileBody} onClick={downloadHandler}>
        {/* @ts-ignore */}
        <img src={getFileIcon(file.name || file.originalname)} alt="Иконка файла" />
        {/* @ts-ignore */}
        <p className={styles.fileName}>{sliceTextByConstraint(file.name || file.originalname, MAX_FILE_NAME_LENGTH)}</p>
      </span>
      {canRemove && (
        <button onClick={removeHandler}>
          <CrossInCircle width="18" />
        </button>
      )}
    </div>
  );
};

export default React.memo(FileRow);
