import LogoSvg from "./img/logo.svg";
import { Link, useLocation } from "react-router-dom";
import { LandingPagesRoutesEnum } from "../../lib/landingPagesRoutesEnum";
import { FC, useEffect, useLayoutEffect, useState } from "react";
import $ from "jquery";
import cn from "classnames";

const getPage = (path: string) => {
  switch (path) {
    case LandingPagesRoutesEnum.home:
      return LandingPagesRoutesEnum.home;
    case LandingPagesRoutesEnum.forSite:
      return LandingPagesRoutesEnum.forSite;
    case LandingPagesRoutesEnum.forOffice:
      return LandingPagesRoutesEnum.forOffice;
    case LandingPagesRoutesEnum.privacyPolicy:
    default:
      return null;
  }
}

export const Header: FC = () => {
  const [activePage, setActivePage] = useState<string | null>(() => getPage(window.location.pathname))

  const location = useLocation();

  useLayoutEffect(() => {
    setActivePage(window.location.pathname);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const onSignInClick = () => {
    window.location.href = `${window.location.protocol}//app.${window.location.host}/`;
  };

  useEffect(() => {
      let time = 0;
      $('.open-menu').on('click', function () {
        $(this).toggleClass('close-menu');
        if ($(this).hasClass('close-menu')) {
          $('.menu-cnt').addClass('transition-menu');
          $('.menu-header-mobile').css({'height': '100%', transition: '0.3s'});
          $('body').addClass('body_fix');
        } else {
          $('.menu-cnt').addClass('menu-width');
          $('body').removeClass('body_fix');
          $('.menu-cnt').removeClass('transition-menu');
          time = 0;
        }
      });
      $('.header__menu ul li a').on('click', function () {
        if ($('.open-menu').hasClass('close-menu')) {
          $('.open-menu').removeClass('close-menu')
        }
        $('.menu-cnt').removeClass('transition-menu');
        $('.menu-cnt').css({height: '0%!important'});
        $('body').removeClass('body_fix');
        time = 0;
      })
  }, [])

  return (
    <header>
      <div className="container">
        <div className="header__main">
          <Link
            to={LandingPagesRoutesEnum.home}
            className="header__logo"
            onClick={() => {setActivePage(LandingPagesRoutesEnum.home)}}
          >
            <img src={LogoSvg} alt="logo"/>
          </Link>
          <div className="menu-cnt">
            <div className="header__menu">
              <ul>
                <li
                  className={cn({"active": activePage === LandingPagesRoutesEnum.home})}
                >
                  <Link to={LandingPagesRoutesEnum.home}>
                    Главная
                  </Link>
                </li>
                <li
                  className={cn({"active": activePage === LandingPagesRoutesEnum.forSite})}
                >
                  <Link to={LandingPagesRoutesEnum.forSite}>
                    Для площадки
                  </Link>
                </li>
                <li
                  className={cn({"active": activePage === LandingPagesRoutesEnum.forOffice})}
                >
                  <Link to={LandingPagesRoutesEnum.forOffice}>
                    Для офиса
                  </Link>
                </li>
                {/*<li><a href="#">Тарифы</a></li>*/}
                {/*<li><a href="#">О компании</a></li>*/}
              </ul>
            </div>
            <div className="header__contact">
              <a href="tel: +7 (812) 701-00-05" className="header__phone">+7 (812) 701-00-05</a>
              <button
                className="come-in"
                onClick={onSignInClick}
              >
                Войти
              </button>
            </div>
          </div>
          <div className="phone-for__planshet">
            <a href="#" className="header__phone">+7 (812) 701-00-05</a>
          </div>
          <div className="open-menu">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </header>
  )
}