import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  betonIncomeLoadingSelector,
  betonIncomeSelector,
  betonInvalidateKeySelector,
} from "redux/modules/common/building/beton/selectors";
import { getBetonIncome } from "redux/modules/common/building/beton/thunks";

import BetonIncomeHeader from "./components/BetonIncomeHeader/BetonIncomeHeader";
import BetonIncomeRow from "./components/BetonIncomeRow/BetonIncomeRow";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import ListWrapper from "shared/ui/layout/ListWrapper/ListWrapper";

import { VIEW_MANUFACTURING_BETON_TO_PRODUCTION } from "../../../../constants/permissions/manufacturingPermissions";

import usePermission from "../../../../hooks/usePermission";

import { getDateForBetonFilter } from "../utils";

import beton from "images/icons/navigation/beton.svg";

interface IProps {
  stockId?: number;
  isStockMismatching: boolean;
  month: number;
  year: number;
}

const BetonIncome: React.FC<IProps> = ({ stockId, isStockMismatching, month, year }) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const income = useSelector(betonIncomeSelector)[stockId ?? "-1"];
  const incomeIsLoading = useSelector(betonIncomeLoadingSelector)[objectId];
  const invalidateKey = useSelector(betonInvalidateKeySelector);
  const [filter, setFilter] = useState<Record<string, string>>({});

  const haveViewSendToProduction = usePermission(VIEW_MANUFACTURING_BETON_TO_PRODUCTION);

  useEffect(() => {
    if (isStockMismatching) return;
    if (!stockId) return;
    const dates = getDateForBetonFilter(year, month, "delivery_date");
    dispatch(getBetonIncome(stockId, objectId, { ...filter, ...dates }));
  }, [stockId, objectId, invalidateKey, filter, month, year]);

  return (
    <div>
      <ListWrapper
        header={
          <BetonIncomeHeader
            onChangeFilter={(name, value) => setFilter((prev) => ({ ...prev, [name]: value }))}
            haveViewSendToProduction={haveViewSendToProduction}
          />
        }
        isLoading={incomeIsLoading || isStockMismatching}
        data={income?.results}
        dataMapper={(el) => (
          <BetonIncomeRow
            key={el.id}
            item={el}
            stockId={stockId!}
            haveViewSendToProduction={haveViewSendToProduction}
          />
        )}
        filter={filter}
        placeholderProps={{ img: beton }}
      />
    </div>
  );
};

export default BetonIncome;
