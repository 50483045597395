import cn from "classnames";
import React from "react";

import TableFilterTextInput from "shared/ui/inputs/TableFilterTextInput/TableFilterTextInput";

import styles from "./TableHeader.module.scss";

interface IProps {
  onFiltersChange?: (value: string, type: string) => void;
  className?: string;
}

const PlanMaterialsTableHeader: React.FC<IProps> = ({ onFiltersChange, className }) => {
  return (
    <div className={cn(styles.header, styles.planHeader, className)}>
      <div />
      <div className={styles.name}>
        Материал
        {onFiltersChange && (
          <TableFilterTextInput title="Материал" setFilter={(value) => onFiltersChange(value, "estimate_item_name")} />
        )}
      </div>
      <div>Кол-во</div>
      <div className={styles.centered}>По проекту</div>
    </div>
  );
};

export default React.memo(PlanMaterialsTableHeader);
