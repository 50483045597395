import React from 'react';

export default function SaveImportList({ color = '#606060', width = '18' }) {
  return (
    <svg
      width={width}
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 21.0351C4.17432 21.0338 2.90332 20.5066 1.96593 19.5692C1.02853 18.6318 0.501324 17.3608 0.5 16.0351L0.5 8.25714C0.501588 6.93155 1.02888 5.6607 1.96622 4.72336C2.90356 3.78602 4.17441 3.25873 5.5 3.25714H5.84L5.812 5.68214C5.0283 5.7019 4.28313 6.02624 3.73462 6.58633C3.1861 7.14642 2.87739 7.8982 2.874 8.68214V15.6111C2.874 16.4068 3.19007 17.1699 3.75268 17.7325C4.31529 18.2951 5.07835 18.6111 5.874 18.6111H12.534C13.3296 18.6111 14.0927 18.2951 14.6553 17.7325C15.2179 17.1699 15.534 16.4068 15.534 15.6111V8.68114C15.5343 7.89849 15.2286 7.14672 14.6824 6.58624C14.1361 6.02577 13.3924 5.70097 12.61 5.68114L12.638 3.25514H12.908C14.2337 3.25646 15.5047 3.78367 16.4421 4.72107C17.3795 5.65846 17.9067 6.92946 17.908 8.25514V16.0351C17.9067 17.3608 17.3795 18.6318 16.4421 19.5692C15.5047 20.5066 14.2337 21.0338 12.908 21.0351H5.5ZM5.5 10.0351H8.1L8.045 2.35514C7.99193 1.91773 8.11346 1.47703 8.38322 1.12863C8.65298 0.780245 9.04923 0.552262 9.486 0.494141C9.92761 0.565974 10.3254 0.803117 10.5985 1.15745C10.8717 1.51179 10.9999 1.95678 10.957 2.40214V10.0351H13.5L9.5 17.0351L5.5 10.0351Z"
        fill={color}
      />
    </svg>
  );
}
