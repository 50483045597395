import React from "react";

const replecementIcon = (
  <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 27C20.6797 27 26.5 21.1797 26.5 14C26.5 6.8203 20.6797 1 13.5 1C6.3203 1 0.5 6.8203 0.5 14C0.5 21.1797 6.3203 27 13.5 27Z"
      fill="#E6F4FC"
      stroke="#4FB1EB"
      strokeWidth="0.3"
    />
    <path
      d="M12.7847 23.498V22.6074H17.2144L17.2227 11.5H14.2227L17.7227 7.5L21.2227 11.5H18.2227V23C18.2227 23.1326 18.1699 23.2597 18.0762 23.3535C17.9824 23.4473 17.8553 23.5 17.7227 23.5C17.4637 23.501 13.0287 23.476 12.7847 23.498ZM12.7847 23.498C12.7707 23.498 12.7707 23.501 12.7847 23.498V23.498ZM5.77344 16.498H8.77344V4.99805C8.77344 4.86544 8.82615 4.7383 8.91992 4.64453C9.01369 4.55076 9.14083 4.49805 9.27344 4.49805C9.53744 4.49805 13.9733 4.52305 14.2163 4.49805V5.38965H9.78369L9.77441 16.4902H12.7744L9.27441 20.4902L5.77344 16.498ZM14.2134 4.49805C14.2274 4.49805 14.2274 4.49805 14.2134 4.49805V4.49805Z"
      fill="#5A5A5A"
    />
  </svg>
);

export default replecementIcon;
