import { ManufacturingModalType } from "./useManufacturingModal";

export const nextManufacturingModalType = (currentModalType: ManufacturingModalType): ManufacturingModalType => {
  if (currentModalType === "building") return "lsr";
  if (currentModalType === "lsr") return "section";
  if (currentModalType === "section") return "expenditure";
  return "expenditure";
};

export const prevManufacturingModalType = (currentModalType: ManufacturingModalType): ManufacturingModalType => {
  if (currentModalType === "expenditure") return "section";
  if (currentModalType === "section") return "lsr";
  if (currentModalType === "lsr") return "building";
  return "building";
};

export interface IMimInChartModal {
  id: 172;
  service: {
    id: 54334;
    name: "Перевозка грузов автомобилями-самосвалами грузоподъемностью 10 т работающих вне карьера на расстояние: I класс груза до 1 км";
    measure: "т груза";
    type: "service";
    estimate_expenditure: {
      id: 1160013;
      type: "expenditure";
      confirmed: true;
      name: "Перевозка грузов автомобилями-самосвалами грузоподъемностью 10 т работающих вне карьера на расстояние: I класс груза до 1 км";
      expenditure_type: "transport";
      number: "15";
      justification: "ФССЦпг-03-21-01-001";
      estimate_count: "14.31";
      estimate_measure: "1 т груза";
      estimate_price: "2.91";
      estimate_amount: "41.64000000";
      count: "14.31";
      measure: "т груза";
      price: "2.90985325";
      section: {
        id: 1160001;
        type: "section";
        confirmed: true;
        name: "Установка стальных опор";
        expenditure_count: 0;
        subsection_count: 0;
        indicators: {
          draft_estimate_amount: "0.00";
          current_estimate_amount: "0.00";
          estimate_amount: "399880.88";
          amount_accepted: "0.00";
          invested: "0.00";
          work_completed: "0.00";
          completed_percent: "0.00";
          total_sum_using_amount: "0.00";
          to_payed_amount: "0.00";
          confirm_using_closed_amount: "0.00";
          confirm_sum_using_amount: "0.00";
          material_on_stock_amount: "55673.94";
          material_issued_amount: "55673.94";
          material_accepted_amount: "0.00";
          material_to_pay_amount: "0.00";
          estimate_amount_source: "399880.88";
          discount_cost: "0.00";
          discount_percent: "0.00";
          estimated_cost: "123460.01";
        };
      };
    };
    work_estimate_expenditure: {
      id: 1160008;
      type: "expenditure";
      confirmed: true;
      name: "Разработка грунта с погрузкой на автомобили-самосвалы экскаваторами с ковшом вместимостью: 0,65 (0,5-1) м3, группа грунтов 2";
      expenditure_type: "work";
      number: "14";
      justification: "ФЕР 01-01-013-08";
      estimate_count: "0.00795";
      estimate_measure: "1000 м3";
      estimate_price: "3111.64";
      estimate_amount: "29.83000000";
      count: "7.95";
      measure: "м3";
      price: "3.75220126";
      section: {
        id: 1160001;
        type: "section";
        confirmed: true;
        name: "Установка стальных опор";
        expenditure_count: 0;
        subsection_count: 0;
        indicators: {
          draft_estimate_amount: "0.00";
          current_estimate_amount: "0.00";
          estimate_amount: "399880.88";
          amount_accepted: "0.00";
          invested: "0.00";
          work_completed: "0.00";
          completed_percent: "0.00";
          total_sum_using_amount: "0.00";
          to_payed_amount: "0.00";
          confirm_using_closed_amount: "0.00";
          confirm_sum_using_amount: "0.00";
          material_on_stock_amount: "55673.94";
          material_issued_amount: "55673.94";
          material_accepted_amount: "0.00";
          material_to_pay_amount: "0.00";
          estimate_amount_source: "399880.88";
          discount_cost: "0.00";
          discount_percent: "0.00";
          estimated_cost: "123460.01";
        };
      };
    };
  };
  count: "14.31000000";
}
