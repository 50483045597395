import { throttle } from "lodash";
import React from "react";

import { Spinner } from "../../../../shared/ui/atoms/Spinner/Spinner";
import { CSSTransition } from "react-transition-group";

export class OslaLoader extends React.Component {
  int = null;

  state = {
    showFixedLoader: false,
    isStaticLoader: false,
    isFixedLoader: false,
  };

  sayScroll = throttle(() => {
    if (this.loadingBlock) {
      const nowState = this.loadingBlock.getBoundingClientRect().bottom < 0;
      if (this.state.showFixedLoader !== nowState) {
        this.setState({
          showFixedLoader: nowState,
        });
      }
    }
  }, 100);

  static getDerivedStateFromProps(props) {
    return {
      isStaticLoader: props.isStaticLoader,
      isFixedLoader: props.isFixedLoader,
    };
  }

  componentDidMount() {
    document.addEventListener("scroll", this.sayScroll, true);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.sayScroll, true);
  }

  render() {
    const { isStaticLoader, isFixedLoader } = this.state;

    const rowCoords = this.loadingBlock && this.loadingBlock.getBoundingClientRect();

    const rowCenter = this.loadingBlock && rowCoords.left + rowCoords.width / 2;

    return (
      <React.Fragment>
        <div
          ref={(loadingBlock) => (this.loadingBlock = loadingBlock)}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Spinner isStatic={isStaticLoader} isFixed={isFixedLoader} />
        </div>
        <CSSTransition
          in={this.state.showFixedLoader}
          timeout={200}
          classNames="loading-block-react-transition"
          unmountOnExit
        >
          <div className="loading-block" style={{ left: `${rowCenter}px` }}>
            <Spinner />
          </div>
        </CSSTransition>
      </React.Fragment>
    );
  }
}
