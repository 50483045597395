import axios from "axios";

import { IMultipleApprovingPlans } from "./types";

export const sendMultiplePlansStateApi = {
  get: (buildingId: string) => {
    return axios.get<IMultipleApprovingPlans>(`/building/${buildingId}/plan_approval_sent/`);
  },
  post: (buildingId: string, data: any) => {
    return axios.post<IMultipleApprovingPlans>(`/building/${buildingId}/plan_approval_sent/`, data);
  },
};
