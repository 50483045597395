import { useLocationArray } from "./useLocationArray";
import { useUrlModule } from "./useUrlModule";

export const useUrlModuleWithObjectId = (objectIdFallback?: string): string => {
  const urlModule = useUrlModule();
  const locationArray = useLocationArray();
  const objectId = locationArray[1];

  if (urlModule === "objects") {
    return `objects/${objectId || objectIdFallback}`;
  }

  return urlModule;
};
