import React from 'react';
import { classJoin } from '../utils/classJoin';
import { SortCaret } from '../SortCaret/SortCaret';

export const DESC = 'desc';
export const ASC = 'asc';

export class TableHeader extends React.Component {
  state = {
    nowSort: null,
  };

  componentDidMount() {
    const { disableSorting } = this.props;
    disableSorting.subscribe(this.disableSort);
  }

  changeSort = () => {
    const { nowSort } = this.state;

    const { sortCallback = null, disableSorting, changeSorting, data } = this.props;

    const defaultCallback = val => `${val === DESC ? '-' : ''}${data}`;

    disableSorting.disable(this.disableSort);

    if (nowSort === DESC) {
      changeSorting(sortCallback ? sortCallback(ASC) : defaultCallback(ASC));
      this.setState({ nowSort: ASC });
    } else {
      changeSorting(sortCallback ? sortCallback(DESC) : defaultCallback(DESC));
      this.setState({ nowSort: DESC });
    }
  };

  disableSort = () => this.setState({ nowSort: null });

  render() {
    const {
      headerClass,
      dataSort = false,
      customCaret,
      style = {},
      doMergeCellStyles = true,
      headerSortClass = null,
      rowSpan = null,
      colSpan = null,
      cellStyle
    } = this.props;

    const { nowSort } = this.state;

    const styleCaret = {
      border: '1px blue solid',
      color: 'blue',
      marginLeft: '5px',
      cursor: 'pointer',
    };

    const defaultElements = {
      descElement: <div style={styleCaret}>вниз</div>,
      ascElement: <div style={styleCaret}>вверх</div>,
      defaultElement: <div style={styleCaret}>пусто</div>,
    };

    const mergedStyle = {
      ...style,
      width: cellStyle?.width,
      maxWidth: cellStyle?.width,
      minWidth: cellStyle?.width,
    }

    return (
      <th
        onClick={dataSort ? this.changeSort : null}
        className={`${
          Array.isArray(headerClass) ? classJoin(headerClass) : headerClass
        } ${
          dataSort ? (Array.isArray(headerSortClass) ? classJoin(headerSortClass) : headerSortClass) : ''
        }`}
        style={doMergeCellStyles ? mergedStyle : style}
        rowSpan={rowSpan}
        colSpan={colSpan}
      >
        {this.props.children}
        {dataSort ? (
          <SortCaret elements={customCaret || defaultElements} nowSort={nowSort} />
        ) : null}
      </th>
    );
  }
}
