import cn from "classnames";
import React, { FC } from "react";

import ButtonBase from "../../../../../../../../../../shared/ui/controls/ButtonBase";

import ImportDraftIcon from "../../../../../../../../../../images/icons/ImportDraftIconLazy";

import styles from "./Draft.module.scss";

interface IProps {
  onCreateSection: () => void;
  className?: string;
  titleClassName?: string;
  buttonClassName?: string;
}

const Draft: FC<IProps> = ({ onCreateSection, className, titleClassName, buttonClassName }) => {
  return (
    <div className={cn(styles.draft, className)}>
      <div className={cn(styles.title, titleClassName)}>
        Можно сформировать смету из данных через загрузку xml файла или создать позиции вручную
      </div>
      <ImportDraftIcon />
      <ButtonBase className={cn(styles.button, buttonClassName)} primary onClick={onCreateSection}>
        Создать вручную
      </ButtonBase>
    </div>
  );
};

export default React.memo(Draft);
