import cn from "classnames";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { loadPaymentV2 } from "../../../../../redux/modules/purchaser/payments";

import ButtonBase from "../../../../../shared/ui/controls/ButtonBase";
import CalendarRange from "../../../../../shared/ui/inputs/CalendarRange/CalendarRange";

import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import useEscapeHandler from "../../../../../utils/hooks/useEscapeHandler";

import Conture from "../../../../../images/icons/Conture";

import styles from "./index.module.scss";

const SearchRangeDateFilter = ({ title, setFilterStart, setFilterEnd, last = false, setBothFilters }) => {
  const [isOpen, setIsOpen] = useState(false);
  useEscapeHandler(() => setIsOpen(false));

  const [mark, setMark] = useState(false);
  const [dateStart, setDateStart] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());

  const ref = useRef();

  const handleSaveValue = () => {
    setMark(true);
    if (setBothFilters) {
      setBothFilters(moment(dateStart).format("YYYY-MM-DD"), moment(dateEnd).format("YYYY-MM-DD"));
    } else {
      setFilterStart(moment(dateStart).format("YYYY-MM-DD"));
      setFilterEnd(moment(dateEnd).format("YYYY-MM-DD"));
    }

    setMark(true);
    setIsOpen(false);
  };

  const handleRemoveValue = () => {
    if (setBothFilters) {
      setBothFilters(null, null);
    } else {
      setFilterStart(null);
      setFilterEnd(null);
    }
    setDateStart(moment());
    setDateEnd(moment());
    setIsOpen(false);
    setMark(false);
  };

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div className={styles.container} ref={ref}>
      <div
        className={cn(styles.button, {
          [styles.active]: isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Conture />
        {mark && <div className={styles.redMark}></div>}
      </div>
      {isOpen && (
        <div className={cn(styles.modal, { [styles.modalLast]: last })}>
          <div className={styles.title}>{title}</div>
          <CalendarRange
            defaultDateStart={dateStart}
            setDefaultDateStart={setDateStart}
            defaultDateEnd={dateEnd}
            setDefaultDateEnd={setDateEnd}
            classNamePopup={cn({ [styles.lastCalendarPopup]: last })}
            onSubmit={() => {
              setIsOpen(false);
              handleSaveValue();
            }}
          />
          <div className={styles.actionBlock}>
            <ButtonBase secondary small onClick={handleRemoveValue}>
              Очистить
            </ButtonBase>
            &nbsp;&nbsp;&nbsp;
            <ButtonBase primary small onClick={handleSaveValue}>
              Применить
            </ButtonBase>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchRangeDateFilter;
