import moment from "moment";

export const MonthArray = [
  {
    id: 0,
    name: "Jan",
    label: "Январь",
  },
  {
    id: 1,
    name: "Feb",
    label: "Февраль",
  },
  {
    id: 2,
    name: "Mar",
    label: "Март",
  },
  {
    id: 3,
    name: "Apr",
    label: "Апрель",
  },
  {
    id: 4,
    name: "May",
    label: "Май",
  },
  {
    id: 5,
    name: "Jun",
    label: "Июнь",
  },
  {
    id: 6,
    name: "Jul",
    label: "Июль",
  },
  {
    id: 7,
    name: "Aug",
    label: "Август",
  },
  {
    id: 8,
    name: "Sep",
    label: "Сентябрь",
  },
  {
    id: 9,
    name: "Okt",
    label: "Октябрь",
  },
  {
    id: 10,
    name: "Nov",
    label: "Ноябрь",
  },
  {
    id: 11,
    name: "Dec",
    label: "Декабрь",
  },
];

export const YEAR_SELECT_MIN_YEAR = 2020;
export const YEAR_SELECT_UPPER_BOUND_FROM_CURRENT_YEAR = 10;

export const YearsArray = (yearsFutureOffset?: number, minYear?: number) => {
  const res = [];
  for (
    let i = moment().year() + (yearsFutureOffset ?? YEAR_SELECT_UPPER_BOUND_FROM_CURRENT_YEAR);
    i >= Math.min(+(minYear || YEAR_SELECT_MIN_YEAR), YEAR_SELECT_MIN_YEAR);
    i--
  ) {
    res.push({ id: i, name: i, label: i });
  }
  return res;
};
