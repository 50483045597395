import { IPlanFactVolumeAction } from "./actions";

import { PLAN_FACT_VOLUME_ALL_EXPANDED } from "../constants";
import { planFactVolumeState } from "./types";

const initialState: planFactVolumeState = {
  sections: {},
  expenditures: {},
  sectionsIsLoading: {},
  expendituresIsLoading: {},
  isAllExpanded: JSON.parse(localStorage.getItem(PLAN_FACT_VOLUME_ALL_EXPANDED) ?? "true"),
  daysCount: 7,
};

export default (state = initialState, action: IPlanFactVolumeAction): planFactVolumeState => {
  const { payload, type } = action;

  switch (type) {
    case "planFactVolume/SET_SECTIONS":
      return {
        ...state,
        sections: {
          ...state.sections,
          [payload.objectId]: payload.sections,
        },
      };
    case "planFactVolume/SET_SECTIONS_LOADING":
      return {
        ...state,
        sectionsIsLoading: {
          ...state.sectionsIsLoading,
          [payload.objectId]: payload.status,
        },
      };
    case "planFactVolume/SET_EXPENDITURES":
      return {
        ...state,
        expenditures: {
          ...state.expenditures,
          [payload.sectionId]: payload.expenditures,
        },
      };
    case "planFactVolume/SET_EXPENDITURES_LOADING":
      return {
        ...state,
        expendituresIsLoading: {
          ...state.expendituresIsLoading,
          [payload.sectionId]: payload.status,
        },
      };
    case "planFactVolume/SET_ALL_EXPANDED":
      return {
        ...state,
        isAllExpanded: payload,
      };
    case "planFactVolume/SET_DAYS_COUNT":
      return {
        ...state,
        daysCount: payload,
      };
    default:
      return state;
  }
};
