import React from 'react'
import styles from "./Legend.module.scss"
import cn from "classnames"

interface ILegendItemProps {
  text: string,
  color: string,
  border?: string,
}

const LegendItem: React.FC<ILegendItemProps> = ({ text, color, border }) => {
  const stylesObj = React.useMemo(() => {
    return {
      backgroundColor: color,
      borderColor: border ? border : color
    }
  }, [color, border])

  return (
    <div className={styles.item}>
      <div className={styles.indicator} style={stylesObj} />
      {text}
    </div>
  )
}

interface IProps {
  className?: string;
  options: ILegendItemProps[]
}

const Legend: React.FC<IProps> = ({
  className,
  options
}) => {
  return (
    <div className={cn(styles.legend, className)}>
      {options.map((el, i) => (
        <LegendItem {...el} key={i + el.color} />
      ))}
    </div>
  )
}

export default Legend