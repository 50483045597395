import cn from "classnames";
import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import SaveFileModal from "../../../../../../../../../../_LEGACY/UI/__trash/SaveFileModal/SaveFileModal";

import Checkbox from "../../../../../../../../../../shared/ui/inputs/Checkbox/Checkbox";
import { modeSelector } from "../../../../../../model/selectors";
import { simpleHandlerModel } from "../../../../../../model/thunks";
import { ExpenditureColumns } from "./ExpenditureColumns/ExpenditureColumns";

import { EXPENDITURE_TYPES_NAMES } from "../../../../../../../../../../constants/constant";
import { IExpenditure } from "types/interfaces/Expenditure";

import { ReactComponent as PaperClipIcon } from "shared/assets/icons/PaperClipIcon.svg";

import styles from "./Expenditure.module.scss";

interface IProps {
  expenditure: IExpenditure;
  onCheck?: (expenditure: IExpenditure, checked: boolean) => void;
  isChecked?: boolean;
  showCheckbox?: boolean;
  checked?: boolean;
  canConfirm?: boolean;
  onConfirm?: (id: number) => void;
  onCancel?: (id: number) => void;
  isStatusUpdating?: boolean;
}

export const Expenditure: FC<IProps> = ({
  expenditure,
  onCheck = () => {},
  isChecked = false,
  showCheckbox = false,
  canConfirm = false,
  onConfirm,
  onCancel,
  isStatusUpdating,
}) => {
  const dispatch = useDispatch();
  const { buildingId } = useParams<{ buildingId: string }>();

  const mode = useSelector(modeSelector);

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => onCheck(expenditure, e.target.checked);

  const [isFilesModalOpened, setIsFilesModalOpened] = useState(false);
  const updateFilesCallback = useCallback(() => {
    dispatch(simpleHandlerModel.invalidateExpendituresList());
  }, []);

  return (
    <>
      <div key={expenditure.id} className={cn(styles.container, styles[canConfirm ? "canConfirm" : mode])}>
        <div className={cn(styles.column, styles.numberColumn, !showCheckbox && styles.withoutCheckbox)}>
          <span className={styles.number}>{expenditure.number}</span>
          {showCheckbox && <Checkbox checked={isChecked} onCheck={handleCheck} />}
        </div>

        <div className={cn(styles.column, styles.titleColumn)}>
          <span className={styles.title}>{expenditure.name}</span>
          <div className={styles.info}>
            <div className={styles.type}>{EXPENDITURE_TYPES_NAMES[expenditure.expenditure_type]}</div>
            <button className={styles.files} onClick={() => setIsFilesModalOpened(true)}>
              <PaperClipIcon className={styles.fileIcon} />
              {!!expenditure.count_files && <span className={styles.number}>{expenditure.count_files}</span>}
            </button>
            {/*<div className={styles.budgetInfo}>₽</div>*/}
          </div>
        </div>

        <ExpenditureColumns
          expenditure={expenditure}
          canConfirm={canConfirm}
          onConfirm={onConfirm}
          onCancel={onCancel}
          isStatusUpdating={isStatusUpdating}
        />
      </div>

      <SaveFileModal
        isOpen={isFilesModalOpened}
        handleClose={() => setIsFilesModalOpened(false)}
        product={expenditure}
        objectId={buildingId}
        canDelete={true}
        canUpload={true}
        loadSectionExpenditures={updateFilesCallback}
      />
    </>
  );
};
