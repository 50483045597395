import React, { PropsWithChildren, createContext, useContext, useState } from "react";

import ConfirmModal, { IConfirmModalProps } from "./ConfirmModal";

const ConfirmationContext = createContext<{
  modalState: IConfirmModalProps | null;
  setModalState: React.Dispatch<React.SetStateAction<IConfirmModalProps | null>>;
}>({ modalState: null, setModalState: () => {} });

const ConfirmationModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [modalState, setModalState] = useState<IConfirmModalProps | null>(null);

  return (
    <ConfirmationContext.Provider value={{ modalState, setModalState }}>
      {children}
      {modalState && (
        <ConfirmModal
          {...modalState}
          onClose={() => setModalState((prev) => ({ ...(prev as IConfirmModalProps), isOpen: false }))}
        />
      )}
    </ConfirmationContext.Provider>
  );
};

export const useConfirmModal = () => {
  const { setModalState } = useContext(ConfirmationContext);

  const openConfirmModal = (props: IConfirmModalProps) => {
    setModalState(props);
  };

  const updateConfirmModal = (props: Partial<IConfirmModalProps>) => {
    setModalState((prev) => {
      if (prev) {
        return { ...prev, ...props };
      } else {
        return null;
      }
    });
  };

  const closeConfirmModal = () => {
    setModalState((prev) => {
      if (prev) {
        return { ...prev, isOpen: false };
      } else {
        return null;
      }
    });
  };

  return { openConfirmModal, updateConfirmModal, closeConfirmModal };
};

export default ConfirmationModalProvider;
