import moment from "moment";
import React, { useEffect, useState } from "react";

import { IBetonExpenseDetail } from "redux/modules/common/building/beton/types";

import Expandable from "components/UI/atoms/_TODO/Expandable/Expandable";

import InputBase from "shared/ui/inputs/InputBase";

import styles from "./BetonExpenseComments.module.scss";

interface IProps {
  comments?: IBetonExpenseDetail["comments"];
  onAddComment: (comment: string) => void;
  isPending: boolean;
}

const BetonExpenseComments: React.FC<IProps> = ({ comments, onAddComment, isPending }) => {
  const [newComment, setNewComment] = useState("");

  useEffect(() => {
    setNewComment("");
  }, [comments]);

  const addComment = () => {
    onAddComment(newComment);
  };

  return (
    <Expandable title={`Комментарии: ${comments?.length ?? 0}`} contentClassName={styles.content}>
      <InputBase
        isWithButton
        isButtonActive={!!newComment && !isPending}
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        onButtonClick={addComment}
      />
      {comments?.map((el) => (
        <div key={el.id} className={styles.comment}>
          <div className={styles.head}>
            <div>{el.user}</div>
            <div>{moment(el.created_at).format("DD.MM.YY HH:mm")}</div>
          </div>
          <div className={styles.message}>{el.message}</div>
        </div>
      ))}
    </Expandable>
  );
};

export default BetonExpenseComments;
