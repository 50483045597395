import React from "react";

/* @ts-ignore */
const AnalyticsChartTick: React.FC = ({ x, y, stroke, payload, tickFormatter }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={8} dx={0} textAnchor="end" fill="#808080" transform="rotate(-45)" fontSize={12}>
        {tickFormatter ? tickFormatter(payload.value) : payload.value}
      </text>
    </g>
  );
};

export default React.memo(AnalyticsChartTick);
