import cn from "classnames";
import React from "react";

import CheckIcon from "../CheckIcon/CheckIcon";

import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";
import { isNanChecker } from "utils/formatters/isNanChecker";
import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";
import { getShortPrice } from "utils/helpers/helpers";

import styles from "./MaterialBlockInWork.module.scss";

type propsType = {
  name: string;
  count: string;
  measure: string;
  amount: number;
  statusText?: string;
  statusColor?: string;
};

function MaterialBlockInWork({ name, count, measure, amount, statusColor = "green", statusText }: propsType) {
  return (
    <div className={styles.container}>
      {statusText && (
        <div className={cn(styles.status, styles[statusColor])}>
          <CheckIcon />
          {statusText}
        </div>
      )}
      <div className={styles.name} title={name}>
        {name}
      </div>
      <div className={styles.info} title={`Количество: ${isNanChecker(+count).toFixed(2)} (${measure})`}>
        <b>Количество: </b>
        <span>
          <>
            {dropNonSignificantZeros(count)} ({sliceTextByConstraint(measure, 4)})
          </>
        </span>
      </div>
      <div className={styles.info} title={`Стоимость: ${getShortPrice(amount)}`}>
        <b>Стоимость: </b>
        <span>{getShortPrice(amount)}</span>
      </div>
    </div>
  );
}

export default MaterialBlockInWork;
