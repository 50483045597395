import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { actingDetailedKs2Selector } from "redux/modules/common/building/journal/acting/selectors";
import { getDetailedKs2 } from "redux/modules/common/building/journal/acting/thunks";

import JournalKs2ModalSubRow from "./JournalKs3ModalSubRow";
import JournalKs3ModalSubRow from "./JournalKs3ModalSubRow";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { LOCALIZATION_CONFIG } from "constants/localization";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import styles from "./JournalKs3ModalRow.module.scss";

interface IProps {
  ks2Id: number;
}

const JournalKs3ModalRowContent: React.FC<IProps> = ({ ks2Id }) => {
  const detailedKs2 = useSelector(actingDetailedKs2Selector)[ks2Id];
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();

  React.useEffect(() => {
    dispatch(getDetailedKs2(objectId, ks2Id));
  }, [ks2Id, objectId]);

  return (
    <>
      <TableReusableRow
        isHoverable={false}
        className={styles.sectionExpHeader}
        containerClassName={styles.sectionExpHeaderWrapper}
      >
        <TableReusableCell>№</TableReusableCell>
        <TableReusableCell>Наименование расценки</TableReusableCell>
        <TableReusableCell>Кол-во</TableReusableCell>
        <TableReusableCell isRighted>Стоимость, {LOCALIZATION_CONFIG.currency} </TableReusableCell>
      </TableReusableRow>
      {detailedKs2?.items?.map((item) =>
        item.expenditures.map((el, i) => (
          <JournalKs3ModalSubRow
            key={el.expenditure_id}
            isFirst={i === 0}
            parentName={item.section_name}
            expenditure={el}
          />
        ))
      )}
    </>
  );
};

export default React.memo(JournalKs3ModalRowContent);
