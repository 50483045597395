import { useMemo } from "react";
import { EstimateItemsStatusesEnum } from "../../../../../../ProHandler/enums";


export const useStatusFromQueryParams = () => {
  const status = useMemo(() => {
    return new URL(window.location.href).searchParams.get("status") as EstimateItemsStatusesEnum | null;
  }, [window.location.href]);

  return status;
}