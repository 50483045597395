import { IIdAndName } from "types/idAndName";

export interface IgetChatMessagesParams {
  room: chatRoomTypes;
  building_id: number;
  item_id?: number;
}

export interface IchatInitialState {
  unreadValue: number;
  allMessages: IchatMessageResponse[];
  isLoading: boolean;
  maxCount: number;
  unreadCount: number;
  isChatV2Connected: boolean;
}

export interface IchatMessageResponse {
  id: number;
  room: chatRoomTypes;
  text: string;
  author: {
    id: number;
    full_name: string;
    roles: IIdAndName[];
    position: string;
  };
  is_read: boolean;
  created_at: string;
  building_id?: number;
}

export enum chatRoomTypes {
  SCHEDULE = "schedule",
  PROJECT = "project",
  OBJECT_SHARED = "object_shared",
  FACT_WORK = "fact_work",
  EMPLOYEES = "employees",
  REQUISITIONS = "requisitions",
  STOCK = "stock",
  REQUISITION = "specific_requisition",
  REQUISITION_SEND = "requisition",
  ORDER = "specific_order",
  ORDER_SEND = "order",
}
