import cn from "classnames";
import { memo } from "react";

import InputBase, { VALUE_TYPES } from "../../../../../../../../../shared/ui/inputs/InputBase";
import MeasureSelect from "entities/MeasureSelect/MeasureSelect";
import { Controller, useFormContext } from "react-hook-form";

import { IExpenditureFormState } from "../types";

import SelectArrow from "shared/assets/icons/SelectArrowIcon.svg";

import styles from "./ExpenditureRow.module.scss";

const transformOptions = { dropZeros: true, withFloat: true };

interface IProps {
  hasRightPadding?: boolean;
}

export const ExpenditureRow = memo<IProps>(({ hasRightPadding = false }) => {
  const { control, getValues, setValue } = useFormContext<IExpenditureFormState>();

  return (
    <div className={cn(styles.container, hasRightPadding && styles.hasRemovableResources)}>
      <div className={cn(styles.field, styles.name)}>
        <span className={styles.fieldTitle}>Наименование работы:</span>
        <Controller
          control={control}
          name="name"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <InputBase
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              className={styles.inputContainer}
              classNameInput={cn(styles.input, fieldState.error && styles.error)}
              placeholder="Введите наименование"
            />
          )}
        />
      </div>

      <div className={cn(styles.field, styles.mediumWidth)}>
        <span className={styles.fieldTitle}>Ед. Изм.:</span>
        <Controller
          control={control}
          name="measure"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <MeasureSelect
              value={field.value ?? ""}
              onChange={(v) => field.onChange(v)}
              containerClassName={styles.selectContainer}
              selectClassName={cn(styles.select, fieldState.error && styles.error)}
              placeholder={<span className={styles.placeholder}>Выберите</span>}
              icon={SelectArrow}
            />
          )}
        />
      </div>

      <div className={cn(styles.field, styles.mediumWidth)}>
        <span className={styles.fieldTitle}>Кол-во:</span>
        <Controller
          control={control}
          name="count"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <InputBase
              value={field.value?.toString() ?? ""}
              onChange={(e) => {
                field.onChange(e.target.value);

                const price = getValues("price");
                setValue("amount", (+e.target.value * +price).toString());
              }}
              valueType={VALUE_TYPES.NUMBER}
              className={styles.inputContainer}
              classNameInput={cn(styles.input, fieldState.error && styles.error)}
              placeholder="0"
              floatConstraint={8}
            />
          )}
        />
      </div>

      <div className={cn(styles.field, styles.largerWidth)}>
        <span className={styles.fieldTitle}>Цена за ед.:</span>
        <Controller
          control={control}
          name="price"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <InputBase
              value={field.value?.toString() ?? ""}
              onChange={(e) => {
                field.onChange(e.target.value);

                const count = getValues("count");
                setValue("amount", (+e.target.value * +count).toString());
              }}
              valueType={VALUE_TYPES.NUMBER}
              className={styles.inputContainer}
              classNameInput={cn(styles.input, fieldState.error && styles.error)}
              placeholder="00.00"
              icon={<span className={styles.inputIcon}>₽</span>}
              numberTransformOptions={transformOptions}
              floatConstraint={2}
            />
          )}
        />
      </div>

      <div className={cn(styles.field, styles.largerWidth)}>
        <span className={styles.fieldTitle}>Итого:</span>
        <Controller
          control={control}
          name="amount"
          render={({ field }) => (
            <InputBase
              value={field.value?.toString() ?? ""}
              onChange={(e) => {
                field.onChange(e.target.value);

                const count = getValues("count");

                setValue("price", (+e.target.value / +count).toString());
              }}
              valueType={VALUE_TYPES.PRICE}
              className={styles.inputContainer}
              classNameInput={styles.input}
              placeholder="00.00"
              icon={<span className={styles.inputIcon}>₽</span>}
              numberTransformOptions={transformOptions}
              floatConstraint={2}
            />
          )}
        />
      </div>
    </div>
  );
});

ExpenditureRow.displayName = "ExpenditureRow";
