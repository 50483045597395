import React, { useEffect } from "react";

import JournalSections from "components/pages/Journal/components/JournalDelivery/components/JournalSections/JournalSections";
import JournalTickets from "components/pages/Journal/components/JournalDelivery/components/JournalTickets/JournalTickets";

import { useFoldersNavigation } from "hooks/navigation/useFoldersNavigation";

import styles from "./ConstructionControlJournalAcceptance.module.scss";

interface IProps {
  year: number;
  month: number;
}

const ConstructionControlJournalAcceptance: React.FC<IProps> = ({ year, month }) => {
  const { currentFolderId, openFolderHandler, backHandler } = useFoldersNavigation("sectionId");
  const {
    currentFolderId: currentContractorId,
    openFolderHandler: openContractorHandler,
    backHandler: backFromContractor,
  } = useFoldersNavigation("contractorId");

  const clickOnContractor = (id: number, name: string) => {
    openContractorHandler(id);
    //setActiveContractorName(name);
  };

  return (
    <div className={styles.container}>
      {!currentFolderId && (
        <JournalSections
          month={month}
          year={year}
          onSectionClick={openFolderHandler}
          //onClickAddPlan={onClickAddPlan}
          onClickAddPlan={() => {}}
          onContractorsClick={clickOnContractor}
          currentContractorId={currentContractorId}
          onBackFromSection={backFromContractor}
          sectionId={currentFolderId}
          activeContractorName={"activeContractorName"}
        />
      )}
      {currentFolderId && (
        <JournalTickets
          month={month}
          year={year}
          onBack={backHandler}
          sectionId={currentFolderId}
          contractorId={currentContractorId}
          activeContractorName={"activeContractorName"}
        />
      )}
    </div>
  );
};

export default ConstructionControlJournalAcceptance;
