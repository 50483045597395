import { ExpenditureTypeEnum } from "../../../../../types/enums/ExpenditureTypeEnum";
import { IExpenditure } from "../../../../../types/interfaces/Expenditure";
import ISection from "../../../../../types/interfaces/Section";
import { EstimateStateId } from "../../ProHandler/types";

export enum EHandlerLevels {
  SECTIONS = "sections",
  SUBSECTIONS = "subsections",
  EXPENDITURES = "expenditures",
}

export interface IEditingExpenditure {
  id: number;
  name: string;
  measure: string;
  count: string;
  price: string;
  resources: Array<{
    id: number;
    type: ExpenditureTypeEnum;
    name: string;
    measure: string;
    count: string;
    price: string;
  }>;
}

export interface ISimpleHandlerState {
  mode: ESimpleHandlerMode;
  estimateStateId: EstimateStateId | null;

  sections: ISection[] | null;
  activeSection: ISection | null;
  sectionsAreLoading: boolean;

  subSections: ISection[] | null;
  activeSubSection: ISection | null;
  subSectionsAreLoading: boolean;

  expenditures: IExpenditure[] | null;
  expendituresAreLoading: boolean;

  isSectionAdding: boolean;

  addingExpenditures: string[];
  creationFormLoadingIds: string[];

  editingExpenditures: Record<number, IEditingExpenditure>;
  editingLoadingExpendituresIds: number[];

  checkedItems: { id: number; name: string }[];

  isItemsStateChanging: boolean;

  deletingExpendituresIds: number[];

  newSections: ISection[] | null;
  confirmedSections: ISection[] | null;

  newSubSections: ISection[] | null;
  confirmedSubSections: ISection[] | null;

  invalidateKey: number;
}

export enum ESimpleHandlerMode {
  VIEW = "view",
  MARKUP = "markup",
  BUDGET = "budget",
  ESTIMATED_PRICE = "estimatedPrice",
}

export interface ICreateOrUpdateExpenditureInDraftBody {
  id?: number;
  name: string;
  expenditure_type: ExpenditureTypeEnum;
  draft_count: string;
  measure: string;
  price: string;
  section_id: number;
  related_work_id?: number;
}

export interface ICreateOrUpdateDefaultExpenditureBody {
  id?: number;
  name: string;
  expenditure_type: ExpenditureTypeEnum;
  count: string;
  measure: string;
  price: string;
  section_id: number;
  related_work_id?: number;
}
