import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userSelector } from "redux/modules/_TODO/auth";

import HelpModal from "../ui/HelpModal/HelpModal";
import InstructionContent from "./ui/InstructionContent/InstructionContent";
import InstructionsHead from "./ui/InstructionsHead/InstructionsHead";
import InstructionsSectionsList from "./ui/InstructionsSectionsList/InstructionsSectionsList";
import {
  permissionsDescrSelector,
  permissionsInvalidateKeySelector,
  permissionsTreeSelector,
} from "features/permissions/model/selectors";
import { getPermissionsTree } from "features/permissions/model/thunks";

import { useInstructionsRedirect } from "./hooks/useInstructionsRedirect";

import { permissionsUtils } from "features/permissions/model/utils";

import styles from "./Instructions.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  openedKey: number;
}

const Instructions: React.FC<IProps> = ({ isOpen, onClose, openedKey }) => {
  const dispatch = useDispatch();
  const tree = useSelector(permissionsTreeSelector);
  const descriptions = useSelector(permissionsDescrSelector);
  const user = useSelector(userSelector);
  const invalidatePermissionsKey = useSelector(permissionsInvalidateKeySelector);

  const [activePerm, setActivePerm] = useState("");

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!user?.id) return;
    dispatch(getPermissionsTree(user.id, true));
  }, [user?.id, invalidatePermissionsKey]);

  const autoAlias = useInstructionsRedirect({ isOpen, onSelect: setActivePerm, userId: user?.id });

  const fullActivePermission = descriptions?.[activePerm];

  const searchedTree = useMemo(() => {
    if (!tree) return null;
    return permissionsUtils.searchInTree(tree, search);
  }, [tree, search]);

  const parentChainDisplay = useMemo(() => {
    if (!fullActivePermission?.parentChain) return [];
    return []; //fullActivePermission.parentChain.filter((el) => !!el).filter((el) => el !== fullActivePermission?.title);
  }, [fullActivePermission?.parentChain, fullActivePermission?.title]);

  const onClickAllPerms = () => {
    setActivePerm((p) => (!!p ? "" : autoAlias));
  };

  const [permHistory, setPermHistory] = useState<string[]>([]);

  const onChangePerm = (perm: string) => {
    setPermHistory((prev) => [...prev, activePerm]);
    setActivePerm(perm);
  };

  const onBack = () => {
    setActivePerm(permHistory.at(-1) ? permHistory.at(-1)! : "");
    setPermHistory((prev) => prev.filter((_, i, arr) => i !== arr.length - 1));
  };

  const mainItems = useMemo(() => {
    return searchedTree?.groups?.filter((el) => el.name !== "Настройки" && el.name !== "Верхнее меню");
  }, [searchedTree?.groups]);

  const secondaryItems = useMemo(() => {
    return searchedTree?.groups?.filter((el) => el.name === "Настройки" || el.name === "Верхнее меню");
  }, [searchedTree?.groups]);

  return (
    <HelpModal openedKey={openedKey} isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <InstructionsHead
        setActivePerm={onChangePerm}
        search={search}
        setSearch={setSearch}
        toggleActivePerm={onClickAllPerms}
        activePerm={activePerm}
        fullActivePermission={fullActivePermission}
      />
      {!activePerm && (
        <div className={styles.root}>
          {mainItems?.map((el) => (
            <InstructionsSectionsList key={el.name} title={el.name} items={el.groups} onClick={onChangePerm} />
          ))}
          <div className={styles.secondaryItems}>
            {secondaryItems?.map((el) => (
              <InstructionsSectionsList
                key={el.name}
                title={el.name}
                items={el.groups}
                onClick={onChangePerm}
                directAlias={el.name === "Верхнее меню" ? el.permissions?.[0]?.alias : undefined}
              />
            ))}
          </div>
        </div>
      )}
      {activePerm && (
        <InstructionContent
          descriptions={descriptions!}
          fullActivePermission={fullActivePermission}
          setActivePerm={onChangePerm}
          onBack={onBack}
          showBack={!!permHistory.length}
          backAlias={permHistory.at(-1)}
        />
      )}
    </HelpModal>
  );
};

export default Instructions;
