import cn from "classnames";
import React from "react";

import styles from "./FormItem.module.scss";

interface IProps {
  children: React.ReactNode;
  title: React.ReactNode;
  className?: string;
  isDefaultHeight?: boolean;
}

// TODO унифицировать с shared/ui/atoms/LabeledItem, применить во всех инпутах
const FormItem: React.FC<IProps> = ({ children, className, title, isDefaultHeight = true }) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.title}>{title}</div>
      <div className={cn(styles.content, { [styles.defaultHeight]: isDefaultHeight })}>{children}</div>
    </div>
  );
};

export default FormItem;
