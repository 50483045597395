import _ from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";

import { checkpointSelectsSelector } from "../model/selectors";
import { loadCheckpointSelects } from "../model/thunks";

import { IIdAndName } from "types/idAndName";

export interface IUseCheckpointSelects {
  objectId: string | number;
  isActive: boolean;
}

export interface ITaskObject extends IIdAndName {
  entity_name: string;
}

const CHECKPOINTS_OPTIONS_LENGTH_LIMIT = 200;

export const useCheckpointSelects = ({ objectId, isActive }: IUseCheckpointSelects) => {
  const dispatch = useDispatch();
  const objects = useSelector(objectsBreadcrumbsSelector);
  const { section: sections, users, approvers } = useSelector(checkpointSelectsSelector);
  const prevObjectId = useRef<string | number>();

  useEffect(() => {
    if (!isActive || !objectId || prevObjectId.current === objectId) return;
    dispatch(loadCheckpointSelects({ limit: CHECKPOINTS_OPTIONS_LENGTH_LIMIT, building_id: +objectId }));
    prevObjectId.current = objectId;
  }, [objectId, isActive]);

  const objectsMemoised = useMemo(
    () =>
      objects
        ? objects.results.map((x: ITaskObject) => ({
            id: x.id,
            name: x.name,
            label: x.name,
            entity_name: x.entity_name,
          }))
        : [],
    [objects]
  );
  const sectionsMemoised = useMemo(() => sections.map((x) => ({ ...x, label: x.name })), [sections]);
  const usersMemoised = useMemo(
    () =>
      users.map((x) => ({
        id: x.id,
        name: `${x.first_name} ${x.last_name}`,
        label: `${x.first_name} ${x.last_name}`,
      })),
    [users]
  );

  return {
    objects: objectsMemoised,
    sections: sectionsMemoised,
    users: usersMemoised,
    approvers,
  };
};
