import { useCallback, useEffect, useMemo, useState } from "react";

import WriteOffProducts, {
  IWriteOffProduct,
  Products,
  Target,
} from "components/pages/Stock/components/WriteOff/domain/WriteOffProducts";

import { IExpenditure } from "types/interfaces/Expenditure";
import ISection from "types/interfaces/Section";

export interface IProduct {
  id: number;
  sections: [string, string, string];
  product_building: { estimate_expenditure?: IExpenditure & { section: ISection } };
}

const initWriteOffProducts = (products: IProduct[]) => {
  const productsByIds: Products = {};
  products.forEach(
    (product) =>
      (productsByIds[product.id] = {
        count: 0,
        isSelected: false,
        target: {
          section: product?.product_building?.estimate_expenditure?.section,
          expenditure: product?.product_building?.estimate_expenditure,
        },
      })
  );
  return new WriteOffProducts(productsByIds);
};

const useWriteOffProducts = (products?: IProduct[]) => {
  const [writeOffProducts, setWriteOffProducts] = useState<WriteOffProducts | null>(null);

  const triggerUpdateWriteOffProducts = useCallback(
    () =>
      setWriteOffProducts((prevState) => {
        if (prevState) return new WriteOffProducts(prevState.items);
        return prevState;
      }),
    []
  );

  const select = useCallback(
    (productId: IProduct["id"]) => {
      if (!writeOffProducts) return;
      writeOffProducts.select(productId);
      triggerUpdateWriteOffProducts();
    },
    [writeOffProducts]
  );

  const unSelect = useCallback(
    (productId: IProduct["id"]) => {
      if (!writeOffProducts) return;
      writeOffProducts.unSelect(productId);
      triggerUpdateWriteOffProducts();
    },
    [writeOffProducts]
  );

  const selectedCount = useMemo(() => {
    if (!writeOffProducts) return 0;
    return writeOffProducts.getSelectedCount();
  }, [writeOffProducts]);

  const changeCount = useCallback(
    (productId: IProduct["id"], count: IWriteOffProduct["count"]) => {
      if (!writeOffProducts) return;
      writeOffProducts.changeCount(productId, count);
      triggerUpdateWriteOffProducts();
    },
    [writeOffProducts]
  );

  const changeTarget = useCallback(
    (productId: IProduct["id"], target: Target) => {
      if (!writeOffProducts) return;
      writeOffProducts.changeTarget(productId, target);
      triggerUpdateWriteOffProducts();
    },
    [writeOffProducts]
  );

  useEffect(() => {
    if (products) setWriteOffProducts(initWriteOffProducts(products));
  }, [products]);

  return {
    items: writeOffProducts ? writeOffProducts.items : null,
    select,
    unSelect,
    selectedCount,
    changeCount,
    changeTarget,
  };
};

export default useWriteOffProducts;
