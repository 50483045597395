import { NotificationGroupType } from "./types/types";

/* @ts-ignore */
export const NOTIFICATION_GROUP_NAMES: Record<NotificationGroupType, string> = {
  remarks: "Замечания",
  other: "Другие",
  order: "Заказ",
  payment: "Оплата",
  packinglist: "УПД",
  requisition: "Заявка",
  tasks_track: "Отслеживание задач",
  stock: "Склад",
};
