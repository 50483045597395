import cn from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import SignIn from "./AuthTabs/SignIn";
import SignUpCall from "./AuthTabs/SignUpCall";

import LogoImage from "images/authorization/auth_logo.svg";

import styles from "./authorization.module.scss";

function Authorization1() {
  const [activeTab, setActiveTab] = React.useState("signin");
  const auth = useSelector((state) => state.auth);

  if (auth.isAuthenticated) return <Redirect to="/" />;

  return (
    <main className="authorization">
      <div className={styles.header}>
        <Link to={"/"}>
          <img src={LogoImage} alt="OSLA" className={styles.logo} />
        </Link>
      </div>
      <div className={styles.container}>
        <div className={styles.tabbar}>
          <div
            className={cn(styles.tab, { [styles.activeTab]: activeTab === "signin" })}
            onClick={() => setActiveTab("signin")}
          >
            Вход
          </div>
          <div
            className={cn(styles.tab, { [styles.activeTab]: activeTab === "signup" })}
            onClick={() => setActiveTab("signup")}
          >
            Регистрация
          </div>
        </div>
        <div>
          {activeTab === "signin" && <SignIn />}
          {activeTab === "signup" && <SignUpCall />}
        </div>
      </div>
    </main>
  );
}

export default Authorization1;
