import { createSelector } from "reselect";

import { RootState } from "../../../../app/store/rootReducer";

import { MODULES_ENUM } from "../../../../types/enums/ModulesEnum";
import { IUiIndicatorsInitialState } from "./types";

export const stateSelector = (state: RootState): IUiIndicatorsInitialState => state.uiIndicators;

export const uiIndicatorsAreLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const uiIndicatorsInvalidateKeySelector = createSelector(stateSelector, (state) => state.invalidateKey);

export const objectsUIIndicatorsSelector = createSelector(stateSelector, (state) => state[MODULES_ENUM.OBJECTS]);
export const constructingUIIndicatorsSelector = createSelector(
  stateSelector,
  (state) => state[MODULES_ENUM.CONSTRUCTING]
);
export const purchasesUIIndicatorsSelector = createSelector(stateSelector, (state) => state[MODULES_ENUM.PURCHASES]);
export const tasksUIIndicatorsSelector = createSelector(stateSelector, (state) => state[MODULES_ENUM.TASKS]);
