import React, { useCallback, useEffect, useState } from "react";

import { chatApi } from "widgets/Chat/model/chatApi";

import { IEmployee } from "types/personsTypes";

import { useMe } from "hooks/useMe";

import { getMentionIndexInString, getMentionedSubsting } from "../utils";

export const useChatMentions = (building: number, inputRef: React.RefObject<HTMLTextAreaElement>) => {
  const [suggestedUsers, setSuggestedUsers] = useState<IEmployee[]>([]);
  const [mentionedSubstring, setMentionedSubstring] = useState("");
  const [mentionedIndex, setMentionedIndex] = useState(-1);
  const [mentions, setMentions] = useState<IEmployee[]>([]);

  const me = useMe();

  const inputMentionsHandler = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const mentionIndex = getMentionIndexInString(e.target.value, e.target.selectionStart);
    const substring = getMentionedSubsting(e.target.value, mentionIndex);
    const mentionedSubstr = mentionIndex !== -1 ? substring : "";
    setMentionedSubstring(mentionedSubstr);
    setMentionedIndex(mentionIndex);
    if (mentionIndex === -1) {
      setSuggestedUsers([]);
    }
  }, []);

  const selectUserHandler = useCallback(
    (user: IEmployee) => {
      if (!inputRef.current) return;

      let nowIndex = -1;
      const wordArr = inputRef.current.value.split(" ");
      const newValue = wordArr
        .map((word, index) => {
          if (word[0] === "@") {
            nowIndex += 1;
            if (nowIndex === mentionedIndex) {
              return `@${user.last_name}${index >= wordArr.length - 1 ? " " : ""}`;
            }
          }
          return word;
        })
        .join(" ");
      const newMentions = [...mentions];
      newMentions[mentionedIndex] = user;
      setMentions(newMentions);
      inputRef.current.value = newValue;
      setSuggestedUsers([]);
      setMentionedSubstring("");
      setMentionedIndex(-1);
      inputRef.current.focus();
    },
    [mentionedIndex, mentions]
  );

  useEffect(() => {
    if (mentionedIndex === -1) return;
    chatApi.getSuggectedUsers(building, mentionedSubstring).then(({ data }) => {
      setSuggestedUsers(data.filter((el) => el.id !== me.id));
    });
  }, [building, mentionedIndex, mentionedSubstring]);

  return {
    suggestedUsers,
    inputMentionsHandler,
    selectUserHandler,
    mentions,
    setMentions,
  };
};
