import { IFile, IFilePropertyAsObject, IFilesBase64Format } from "types/interfaces/Files";

export const getArrayFilesInBase64 = (files: IFile[] | File[]) =>
  new Promise<IFilesBase64Format[]>((resolve, reject) => {
    if (!files || files.length <= 0) {
      reject();
      return;
    }

    let arrayFilesInBase64: IFilesBase64Format[] = [];

    for (let index = 0; index < files.length; index++) {
      const file = (files[index] as IFile)?.file || files[index];
      const reader = new FileReader();

      reader.onload = () => {
        arrayFilesInBase64.push({
          name: (file as IFilePropertyAsObject).name,
          file: reader.result,
        });

        if (files.length === arrayFilesInBase64.length) {
          resolve(arrayFilesInBase64);
        }
      };

      reader.readAsDataURL(file as Blob);
    }
  });
