import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userSelector } from "redux/modules/_TODO/auth";
import {
  journalAcceptanceTicketsIsLoadingSelector,
  journalAcceptanceTicketsSelector,
} from "redux/modules/common/building/journal/acceptance/selectors";
import { getJournalAcceptanceTickets } from "redux/modules/common/building/journal/acceptance/thunks";
import { setGroupDetailInfo } from "redux/modules/common/building/journal/actions";
import { journalTicketsInvalidateKeySelector } from "redux/modules/common/building/journal/selectors";
import { approveTicket } from "redux/modules/common/building/journal/thunks";
import { generateJornalTicketSectionKey } from "redux/modules/common/building/journal/utils";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import JournalDeliveryRow from "../JournalDeliveryRow/JournalDeliveryRow";
import JournalDeliveryTableHead from "../JournalDeliveryTableHead/JournalDeliveryTableHead";
import { useCheckIsConstructingControl } from "features/constructingControl";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { DEFAULT_ENTITY_ID } from "../../constants";
import {
  CAN_ACCEPT_JOURNAL_DELIVERY_TICKETS_WITHOUT_APPROVE,
  VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_APPROVE_TICKET,
} from "constants/permissions/manufacturingPermissions";
import { ITicketApproval } from "types/interfaces/Tickets";

import { useJournalSharingContractorName } from "../../hooks/useJournalSharingContractorName";
import usePermission from "hooks/usePermission";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./JournalTickets.module.scss";

export interface IMatchingInfo {
  id: number;
  editable: boolean;
  expenditure_id?: number;
}

interface IProps {
  month: number;
  year: number;
  sectionId: number;
  onBack: () => void;
  contractorId?: number;
  activeContractorName: string;
}

type TicketAccepting = { isModalOpen: boolean; approvals: ITicketApproval[] };

const JournalTickets: React.FC<IProps> = ({ month, year, sectionId, onBack, contractorId }) => {
  const objectId = useObjectId();
  const dispatch = useDispatch();

  const key = generateJornalTicketSectionKey(objectId, year, month, sectionId);
  const ticketsV2 = useSelector(journalAcceptanceTicketsSelector)[key];
  const ticketsV2IsLoading = useSelector(journalAcceptanceTicketsIsLoadingSelector)[key];

  const invalidateKey = useSelector(journalTicketsInvalidateKeySelector);

  const user = useSelector(userSelector);

  const haveApproveTicketPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_APPROVE_TICKET);
  const canAcceptJournalDeliveryTicketsWithoutApprove = usePermission(
    CAN_ACCEPT_JOURNAL_DELIVERY_TICKETS_WITHOUT_APPROVE
  );

  const [filters, setFilters] = useState<Record<string, string | number>>({});
  const [isShow, setIsShow] = useState(true);
  const [isShowGroupModal, setIsShowGroupModal] = useState(false);
  const [ticketAccepting, setTicketAccepting] = useState<TicketAccepting>({ isModalOpen: false, approvals: [] });

  const handleSetFilter = (name: string, value: string) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    const params = { ...filters };
    const isShared = !!contractorId && contractorId !== DEFAULT_ENTITY_ID;

    if (!isShow) {
      params.is_confirmed = 0;
    }

    if (isShared) {
      params.entity_id = contractorId;
    }

    dispatch(getJournalAcceptanceTickets(objectId, sectionId, year, month, params));
  }, [objectId, month, year, sectionId, filters, isShow, invalidateKey, contractorId]);

  useEffect(() => {
    if (isShowGroupModal) return;

    dispatch(setGroupDetailInfo(null));
  }, [isShowGroupModal]);

  const isEmpty = !ticketsV2IsLoading && !ticketsV2?.intervals?.length;

  const handleApproveTicket = useCallback(
    async (ticketId: number, isGroup: boolean) => {
      dispatch(approveTicket(objectId, ticketId, isGroup));
    },
    [objectId]
  );

  const openTicketAcceptModal = useCallback(
    (ticketItem: any, count: string) => {
      if (!ticketItem) return;
      setTicketAccepting({ isModalOpen: true, ...ticketItem, count });
    },
    [ticketsV2]
  );

  const isConstrControl = useCheckIsConstructingControl();
  const hideApprovals = React.useMemo(() => {
    //if (isConstrControl) return true;
    return ticketsV2?.intervals?.every((el) => {
      const item = el.expenditure ?? el.group;
      if (!item?.total_approvals) {
        return true;
      }
    });
  }, [ticketsV2?.intervals, isConstrControl]);

  const activeContractorName = useJournalSharingContractorName({ year, month, contractorId });

  const subTitle = useMemo(() => {
    if (!ticketsV2?.parent_name) {
      return "";
    }
    if (!activeContractorName) {
      return ticketsV2.parent_name;
    }
    if (contractorId == -1) {
    }
    return `${ticketsV2?.parent_name} / ${activeContractorName}`;
  }, [activeContractorName, ticketsV2, contractorId]);

  return (
    <>
      <div className={styles.head}>
        <BackNavigationBar onBack={onBack} title={ticketsV2?.name} slashTitle={subTitle} className={styles.navbar} />
        <JournalDeliveryTableHead
          onChangeFilter={handleSetFilter}
          setIsShow={setIsShow}
          isShow={isShow}
          hideApprovals={hideApprovals}
        />
      </div>
      {!!ticketsV2IsLoading && <Spinner isFixed />}
      {ticketsV2?.intervals?.map((el, idx) => (
        <JournalDeliveryRow /* @ts-ignore */
          key={el.expenditure?.expenditure_id + idx}
          ticket={el}
          haveApproveTicketPermission={haveApproveTicketPermission}
          currentUserId={user.id}
          approve={handleApproveTicket}
          accept={openTicketAcceptModal}
          canAcceptTicketWithoutApprove={canAcceptJournalDeliveryTicketsWithoutApprove}
          hideApprovals={hideApprovals}
          sectionId={sectionId}
          contractorId={contractorId}
        />
      ))}
      {isEmpty && <EmptyPlaceholder text="Нет сведений" img={jurnalIcon} />}
    </>
  );
};

export default React.memo(JournalTickets);
