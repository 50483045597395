import { RootState } from "app/store/rootReducer";

import { IAcceptedGroupTicketActingInfo, IAcceptedTicketActingInfo } from "./types";

export const checkIsNeedSetLoading = (
  state: RootState,
  filters: Record<string, string | number>,
  key: string,
  field: "sections" | "sectionsWithRemarks" = "sections"
): boolean => {
  if (!state.journalExecution[field]?.[key]?.results?.length) {
    return true;
  }
  return false;
};

export const prepareDataForAcceptTicketToActing = (data: IAcceptedTicketActingInfo) => {
  return {
    work_count: data?.expenditure.sum_accepted_count || 0,
    materials: data.materials,
    services: data.services,
  };
};

export const prepareDataForAcceptGroupTicketToActing = (data: IAcceptedGroupTicketActingInfo) => ({
  group_count: data.expenditure.sum_accepted_count,
  materials: data.materials,
  services: data.services,
});
