import React, { useContext } from "react";

import PopoverOverlay from "../../../../../../../../UI/_TODO/PopoverOverlay/PopoverOverlay";
import { ChartContext } from "components/pages/Chart/Chart";

import styles from "./BraceTooltip.module.scss";

export interface IBraceTooltipProps {
  children: React.ReactNode;
  label: string;
}

const BraceTooltip = React.forwardRef<HTMLElement, IBraceTooltipProps>(({ children, label }, ref) => {
  const { contentRef } = useContext(ChartContext);
  return (
    <PopoverOverlay
      ref={ref}
      placement="top"
      popoverBorderColor="primary"
      openType="hover"
      className={styles.popoverContainer}
      portalClassName={styles.portal}
      content={<div className={styles.tooltip}>{label}</div>}
      usePortal
      portalContainer={(contentRef?.current as HTMLElement) || undefined}
    >
      {children}
    </PopoverOverlay>
  );
});

export default React.memo(BraceTooltip);
