import React from "react";

import TemplateSimple from "../../../_LEGACY/UI/_LEGACY_TemplateSimple/TemplateSimple";

import Requisition from "./Requisition";

const TemplatedRequisition = (props) => {
  return (
    <TemplateSimple>
      <Requisition {...props} />
    </TemplateSimple>
  );
};

export default React.memo(TemplatedRequisition);
