import axios from "axios";

import {
  IBetonExpenseDetail,
  IBetonExpenseItem,
  IBetonIncomeDetail,
  IBetonIncomeItem,
  IBetonToProduction,
  IEditBetonExpenseRequest,
} from "./types";
import { IListResponseData } from "types/ListResponse";

export const betonApi = {
  getIncome: (stockId: number, filter: Record<string, string | number>) => {
    return axios.get<IListResponseData<IBetonIncomeItem>>(`/beton/incoming/${stockId}/`, { params: { limit: 500, ...filter } });
  },
  getExpense: (stockId: number, filter: Record<string, string | number>) => {
    return axios.get<IListResponseData<IBetonExpenseItem>>(`/beton/outgoing/${stockId}/`, { params: { limit: 500, ...filter } });
  },
  toProduction: (stockId: number, betonId: number, values: IBetonToProduction) => {
    return axios.post(`/beton/incoming/${stockId}/${betonId}/to_production/`, values);
  },
  getExpenseDetail: (stockId: number, betonId: number) => {
    return axios.get<IBetonExpenseDetail>(`/beton/outgoing/${stockId}/${betonId}/`);
  },
  addComment: (stockId: number, betonId: number, message: string) => {
    return axios.post(`/beton/outgoing/${stockId}/${betonId}/comment/`, { message });
  },
  editBetonExpense: (stockId: number, betonId: number, data: IEditBetonExpenseRequest) => {
    return axios.patch(`/beton/outgoing/${stockId}/${betonId}/`, data);
  },
  getIncomeDetail: (stockId: number, betonId: number) => {
    return axios.get<IBetonIncomeDetail>(`/beton/incoming/${stockId}/${betonId}/`);
  },
  addIncomeComment: (stockId: number, betonId: number, message: string) => {
    return axios.post(`/beton/incoming/${stockId}/${betonId}/comment/`, { message });
  },
  editIncome: (stockId: number, betonId: number, data: Partial<IBetonIncomeDetail>) => {
    return axios.patch(`/beton/incoming/${stockId}/${betonId}/`, data);
  },
};
