//@ts-nocheck
import cn from "classnames";
import { memoize, partial } from "lodash";
import React, { useCallback } from "react";

import { getDayLabel } from "../../../../../../pages/Manufacturing/components/modals/ShiftsModal/utils";

import InputBase, { VALUE_TYPES } from "../../../../../../../shared/ui/inputs/InputBase";
import Select from "../../../../../atoms/Select";
import { PAYMENT_TYPE, PAYMENT_TYPE_NAMES } from "../PaymentTerms";
import DoubleInput, { Measure } from "shared/ui/inputs/DoubleInput/DoubleInput";

import { stringifyArgs } from "utils/helpers/stringifyArgs";

import { ReactComponent as DeleteIcon } from "../icons/DeleteIcon.svg";
import SelectIcon from "../icons/SelectIcon.png";

import styles from "./PaymentTerm.module.scss";

export const PaymentTerm = ({
  term,
  terms,
  changeTerm,
  onChangePercent,
  onDeleteTerm,
  index,
  canDeleteTerm,
  withDaysTerms,
  isEditing,
}) => {
  const onChangeTerms = useCallback(
    memoize((changedTermType) => (value) => changeTerm(changedTermType, "payment_type", value), stringifyArgs),
    [changeTerm]
  );

  const memoizedPartialValue = useCallback((callback, value) => memoize(partial(callback, value), stringifyArgs), []);

  return (
    <div className={cn(styles.paymentTerm, { [styles.editing]: isEditing })}>
      <div className={styles.fields}>
        {isEditing ? (
          <Select
            value={term.payment_type}
            className={styles.select}
            selectClassName={styles.selectInner}
            classNameOption={styles.selectOption}
            placeholder="Вариант"
            onChange={onChangeTerms(index)}
            disabled={!isEditing}
            options={terms}
            icon={SelectIcon}
          />
        ) : (
          <span className={styles.paymentType}>{PAYMENT_TYPE_NAMES[term.payment_type]}:</span>
        )}
        {!withDaysTerms.includes(term.payment_type) && (
          <>
            {isEditing ? (
              <InputBase
                className={styles.input}
                classNameInput={styles.inputInner}
                value={term.percent}
                valueType={VALUE_TYPES.NUMBER}
                disabled={!isEditing}
                onChange={memoizedPartialValue(onChangePercent, index)}
                icon={<span className={styles.inputIcon}>%</span>}
              />
            ) : (
              <div className={styles.percentWrapper}>
                <span>{term.percent}</span>
                <span>%</span>
              </div>
            )}
          </>
        )}
        {withDaysTerms.includes(term.payment_type) && (
          <>
            {isEditing ? (
              <DoubleInput
                variant="outline"
                className={styles.doubleInput}
                firstValue={term.delay_days}
                firstValueMeasure={"дней"}
                secondValue={term.percent}
                secondValueMeasure={Measure.percent}
                isFirstDisabled={!isEditing}
                isSecondDisabled={!isEditing}
                onChangeFirstValue={(v) => changeTerm(index, "delay_days", v)}
                onChangeSecondValue={memoizedPartialValue(onChangePercent, index)}
              />
            ) : (
              <>
                <div className={styles.percentWrapper}>
                  <span>{term.percent}</span>
                  <span>%</span>
                </div>
                <div className={styles.daysWrapper}>
                  <span>{term.payment_type === PAYMENT_TYPE.DELAY ? "через" : "за"}</span>
                  <span>{term.delay_days}</span>
                  <span>{getDayLabel(term.day)}</span>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className={styles.deleteButtonContainer}>
        {isEditing && canDeleteTerm && (
          <button className={styles.deleteButton} onClick={onDeleteTerm}>
            <DeleteIcon />
          </button>
        )}
      </div>
    </div>
  );
};
