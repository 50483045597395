import { message } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";

import { addCheckpointApi } from "./model/addCheckpointApi";
import { CheckpointForm } from "./ui/CheckpointForm/CheckpointForm";
import { fileUploadActions } from "entities/FileUpload/model/slice";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { Checkpoint, CheckpointEditValues } from "./types";
import { IFilesBase64Format } from "types/interfaces/Files";

import { useEditCheckpoint } from "./hooks/useEditCheckpoint";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import { errorCatcher } from "utils/helpers/errorCatcher";

export interface AddCheckpointProps {
  objectId: string;
  successCallback?: (checkpoint: Checkpoint) => void;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const AddCheckpoint: React.FC<AddCheckpointProps> = ({
  objectId,
  successCallback,
  children = "Добавить контрольную точку",
  disabled,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [isOpen, setOpen] = useState(false);

  const openCheckpointModal = useCallback(() => {
    setOpen(true);
  }, []);
  const closeCheckpointModal = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCreateButton = useCallback(() => {
    if (!formRef.current) return;
    formRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  }, [formRef.current]);

  const {
    object,
    section,
    objects,
    sections,
    users,
    selectObjectById,
    selectObjectByName,
    selectSectionById,
    fileStorageId,
    files,
  } = useEditCheckpoint({
    objectId,
    isActive: isOpen,
  });

  const onSubmit = useCallback(
    (values: Omit<CheckpointEditValues, "building" | "files">) => {
      if (!object) return Promise.reject();
      return (async () => {
        let filesBase64: IFilesBase64Format[] = [];
        if (files.length) {
          filesBase64 = await getArrayFilesInBase64(files).catch();
          // @ts-ignore
          filesBase64.forEach((f) => (f.originalname = f.name));
        }
        return addCheckpointApi
          .post({
            ...values,
            building: object.id,
            files: filesBase64,
          })
          .then(({ data }) => {
            message.success("Контрольная точка создана");
            dispatch(fileUploadActions.flushStorage(fileStorageId));
            successCallback?.(data);
          })
          .catch(errorCatcher);
      })();
    },
    [files, object, fileStorageId, successCallback]
  );

  return (
    <div>
      <ButtonBase secondary disabled={disabled} onClick={openCheckpointModal}>
        {children}
      </ButtonBase>
      <ModalContainer
        isOpen={isOpen}
        onClose={closeCheckpointModal}
        name={"Создание контрольной точки"}
        customHeadline={object?.name}
      >
        <CheckpointForm
          onSumbmit={onSubmit}
          defaultObjectId={objectId}
          object={object}
          section={section}
          selectObjectById={selectObjectById}
          selectObjectByName={selectObjectByName}
          selectSectionById={selectSectionById}
          sections={sections}
          objects={objects}
          users={users}
          fileStorageId={fileStorageId}
          formRef={formRef}
        />
        <BottomControls isExists isDoubleBtns>
          <ButtonBase secondary onClick={closeCheckpointModal}>
            Отменить
          </ButtonBase>
          <ButtonBase primary onClick={handleCreateButton}>
            <span>Создать</span>
          </ButtonBase>
        </BottomControls>
      </ModalContainer>
    </div>
  );
};
