import cn from "classnames";
import React, { ReactNode } from "react";

import styles from "./index.module.scss";

const StatusFormatter = (status: ReactNode, row: { flow: "in" | "out" | "end" }, className?: string) => (
  <div className={cn(styles.myOrdersStatus, className)} title={status as string}>
    <span
      className={cn(styles.status, {
        [styles.in]: row?.flow === "in",
        [styles.out]: row?.flow === "out",
        [styles.end]: row?.flow === "end",
      })}
    />
    <span>{status}</span>
  </div>
);

export default StatusFormatter;
