import React from "react";

import { errorCatcher } from "utils/helpers/errorCatcher";
import axios from "axios";

export const useMaterialsList = ({
  objectId,
  new_section_id,
  exp_work_id,
  defaultMaterialsList,
  isWorkGroup,
  groupId,
  isSkippingRequests,
}) => {
  const [allMaterials, setAllMaterials] = React.useState(defaultMaterialsList || []);
  const [isLoading, setIsLoading] = React.useState(false);
  const [count, setCount] = React.useState(defaultMaterialsList?.length || 0);

  const [filters, setFilters] = React.useState(null);

  const getMaterialsParams = React.useMemo(() => {
    const params = {
      ...filters,
      section_id: new_section_id,
      related_work_id: exp_work_id,
      for_tickets: true,
      limit: 10,
    };
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }
    return params;
  }, [filters, new_section_id, exp_work_id]);

  React.useEffect(() => {
    if (!defaultMaterialsList) return;
    setAllMaterials(defaultMaterialsList);
    setCount(defaultMaterialsList?.length);
  }, [defaultMaterialsList]);

  React.useEffect(() => {
    if (isSkippingRequests) return;
    if (isWorkGroup) {
      if (!groupId) return;
      setIsLoading(true);
      axios.get(`/building/${objectId}/groups/${groupId}/facts/products/`)
        .then((resp) => {
          const availableMaterials = resp.data.materials.filter(m => m.available_count !== '0');
          setAllMaterials(availableMaterials);
          setCount(availableMaterials.length);
        })
        .catch(errorCatcher)
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      axios.get(`/building/${objectId}/estimate/ticket/materials/`, { params: getMaterialsParams })
        .then((resp) => {
          const availableMaterials = resp.data.results.filter(m => m.available_count !== '0');
          setAllMaterials(availableMaterials);
          setCount(availableMaterials.length);
        })
        .catch(errorCatcher)
        .finally(() => setIsLoading(false));
    }
  }, [objectId, filters, new_section_id, exp_work_id, isWorkGroup, isSkippingRequests]);

  const filterHandler = (value, fieldName) => {
    setFilters((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const loadMoreHandler = () => {
    if (isWorkGroup) return;
    axios.get(`/building/${objectId}/estimate/ticket/materials/`, {
      params: { ...getMaterialsParams, offset: allMaterials.length },
    }).then((resp) => setAllMaterials((prev) => [...prev, ...resp.data.results]));
  };

  return {
    allMaterials,
    isLoading,
    count,
    filterHandler,
    loadMoreHandler,
  };
};
