import React from "react";

import RemarksBySection from "./components/RemarksBySection/RemarksBySection";
import SectionsWithRemarks from "./components/SectionsWithRemarks/SectionsWithRemarks";

import { useFoldersNavigation } from "../../../../../hooks/navigation/useFoldersNavigation";

import styles from "../JournalDelivery/JournalDelivery.module.scss";

export interface IRemarksProps {}

const Remarks: React.FC<IRemarksProps> = () => {
  const { currentFolderId, openFolderHandler, backHandler } = useFoldersNavigation("sectionId");
  return (
    <div className={styles.container}>
      {!currentFolderId && <SectionsWithRemarks onSectionClick={openFolderHandler} />}
      {currentFolderId && <RemarksBySection onBack={backHandler} sectionId={currentFolderId} />}
    </div>
  );
};

export default React.memo(Remarks);
