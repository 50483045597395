import { Table as AntTable } from "antd";
import React from "react";

import { TABLE_GROUPS } from "pages/Finance/_TODO/AUDIT_TABLE_GROUPS";

import { useFinanceMeasure } from "features/financeMeasure";

import { LOCALIZATION_CONFIG } from "constants/localization";

import styles from "./AuditTableHead.module.scss";

const { Column, ColumnGroup } = AntTable;

const AuditTableHead = () => {
  const { measureInTitle } = useFinanceMeasure();

  return (
    <AntTable dataSource={[]} size="small" scroll={{ x: 1100 }} pagination={false} className={styles.thead}>
      {TABLE_GROUPS.map((group, idx) => (
        <ColumnGroup className={styles.title} title={group.title} key={idx}>
          <Column
            key={`${group.fieldName}_plan`}
            title={`План ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
            dataIndex={`${group.fieldName}_plan`}
            ellipsis
          />
          <Column
            key={`${group.fieldName}_fact`}
            title={`Факт ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
            dataIndex={`${group.fieldName}_fact`}
            ellipsis
          />
          <Column
            key={`${group.fieldName}_delta`}
            title={`Итого ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
            dataIndex={`${group.fieldName}_delta`}
            ellipsis
          />
        </ColumnGroup>
      ))}
    </AntTable>
  );
};

export default AuditTableHead;
