import cn from "classnames";
import React from "react";

import { IJournalAcceptanceSection } from "redux/modules/common/building/journal/acceptance/types";

import JournalSubSection from "./JournalSubSection";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import commonJournalStyles from "../../../../../Journal.module.scss";
import styles from "./JournalSection.module.scss";

interface IProps {
  section: IJournalAcceptanceSection;
  month: number;
  year: number;
  onSectionClick: (sectionid: number) => void;
}

const JournalSection: React.FC<IProps> = ({ section, onSectionClick, year, month }) => {
  return (
    <>
      <TableReusableRow
        isHoverable={false}
        containerClassName={styles.parent}
        className={cn(commonJournalStyles.sectionParentRow, commonJournalStyles.gridColumns)}
      >
        <TableReusableCell className={styles.parentName} isNoBreak>
          {section.name}
        </TableReusableCell>
      </TableReusableRow>
      {section.subsections?.map((el) => (
        <JournalSubSection
          key={el.id}
          onSectionClick={onSectionClick}
          parentId={section.id}
          year={year}
          month={month}
          section={el}
        />
      ))}
    </>
  );
};

export default React.memo(JournalSection);
