export const ROUTES = {
  CONSTRUCTING_FINANCE: "/constructing/finance/:projectId/:objectId",
  CONSTRUCTING_FINANCE_AUDIT: "/constructing/finance/:projectId/:objectId/audit",
  CONSTRUCTING_FINANCE_SUMMARY: "/constructing/finance/:projectId/:objectId/summary",
  CONSTRUCTING_FINANCE_FORECAST: "/constructing/finance/:projectId/:objectId/forecast",
  CONSTRUCTING_FINANCE_PLAN_FACT: "/objects/:objectId/plan-fact/",
  CONSTRUCTING_FINANCE_PLAN_FACT_FINANCE: "/objects/:objectId/plan-fact/finance",
  CONSTRUCTING_FINANCE_PLAN_FACT_FINANCE_ITEM: "/objects/:objectId/plan-fact/finance/:sectionId",
  CONSTRUCTING_FINANCE_PLAN_FACT_VOLUME: "/objects/:objectId/plan-fact/volume",
  CONSTRUCTING_FINANCE_PLAN_FACT_VOLUME_ITEM: "/objects/:objectId/plan-fact/volume/:sectionId",
  CONSTRUCTING_FINANCE_PLAN_FACT_VOLUME_SUBITEM: "/objects/:objectId/plan-fact/volume/:sectionId/:subsectionId",

  CONSTRUCTING_OBJECTS: "/constructing/projects",

  COUNTERPARTIES: "/counterparties",
  CONSTRUCTING_COUNTERPARTIES: "/constructing/counterparties",
  PURCHASES_COUNTERPARTIES: "/purchases/counterparties",

  CONSTRUCTING_COUNTERPARTIES_CONTRACTORS_LIST: "/contractors",
  CONSTRUCTING_COUNTERPARTIES_CONTRACTORS_ITEM: "/contractors/:id",
  CONSTRUCTING_COUNTERPARTIES_SUPPLIERS_LIST: "/suppliers",
  CONSTRUCTING_COUNTERPARTIES_SUPPLIERS_ITEM: "/suppliers/:id",
  CONSTRUCTING_COUNTERPARTIES_CUSTOMERS_LIST: "/customers",
  CONSTRUCTING_COUNTERPARTIES_CUSTOMERS_ITEM: "/customers/:id",
  CONSTRUCTING_COUNTERPARTIES_CONTACTS_LIST: "/contacts",
  CONSTRUCTING_COUNTERPARTIES_CONTACTS_ITEM: "/contacts/:id",
  CONSTRUCTING_COUNTERPARTIES_INVITES: "/invites",
};
