import React from "react";

const GridIcon = (props) => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.5 14.8281C10.9696 14.8281 10.4608 14.6173 10.0857 14.2422C9.71062 13.8671 9.5 13.3586 9.5 12.8281V10.8281C9.5 10.2977 9.71062 9.78865 10.0857 9.41357C10.4608 9.0385 10.9696 8.82812 11.5 8.82812H13.5C14.0304 8.82812 14.5392 9.0385 14.9143 9.41357C15.2894 9.78865 15.5 10.2977 15.5 10.8281V12.8281C15.5 13.3586 15.2894 13.8671 14.9143 14.2422C14.5392 14.6173 14.0304 14.8281 13.5 14.8281H11.5ZM2.5 14.8281C1.96957 14.8281 1.46077 14.6173 1.08569 14.2422C0.710621 13.8671 0.5 13.3586 0.5 12.8281V10.8281C0.5 10.2977 0.710621 9.78865 1.08569 9.41357C1.46077 9.0385 1.96957 8.82812 2.5 8.82812H4.5C5.03043 8.82812 5.53923 9.0385 5.91431 9.41357C6.28938 9.78865 6.5 10.2977 6.5 10.8281V12.8281C6.5 13.3586 6.28938 13.8671 5.91431 14.2422C5.53923 14.6173 5.03043 14.8281 4.5 14.8281H2.5ZM11.5 6.82812C10.9696 6.82812 10.4608 6.61726 10.0857 6.24219C9.71062 5.86711 9.5 5.35856 9.5 4.82812V2.82812C9.5 2.29769 9.71062 1.78865 10.0857 1.41357C10.4608 1.0385 10.9696 0.828125 11.5 0.828125H13.5C14.0304 0.828125 14.5392 1.0385 14.9143 1.41357C15.2894 1.78865 15.5 2.29769 15.5 2.82812V4.82812C15.5 5.35856 15.2894 5.86711 14.9143 6.24219C14.5392 6.61726 14.0304 6.82812 13.5 6.82812H11.5ZM2.5 6.82812C1.96957 6.82812 1.46077 6.61726 1.08569 6.24219C0.710621 5.86711 0.5 5.35856 0.5 4.82812V2.82812C0.5 2.29769 0.710621 1.78865 1.08569 1.41357C1.46077 1.0385 1.96957 0.828125 2.5 0.828125H4.5C5.03043 0.828125 5.53923 1.0385 5.91431 1.41357C6.28938 1.78865 6.5 2.29769 6.5 2.82812V4.82812C6.5 5.35856 6.28938 5.86711 5.91431 6.24219C5.53923 6.61726 5.03043 6.82812 4.5 6.82812H2.5Z"
      fill="#707070" />
  </svg>
);

export default React.memo(GridIcon);