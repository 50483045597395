import React from "react";

import TableReusableHead, {
  TableReusableHeaderCell,
} from "../../../../../../../shared/ui/dataDisplay/TableReusable/TableReusableHead";

import { REMARK_STATUSES_OPTIONS } from "../../constants";

import styles from "./RemarksHeader.module.scss";

export interface IRemarksHeaderProps {
  onChangeFilter?: (name: string, value: string) => void;
}

const RemarksHeader: React.FC<IRemarksHeaderProps> = ({ onChangeFilter }) => {
  return (
    <TableReusableHead isSticky className={styles.remarksHeader}>
      <TableReusableHeaderCell
        filterType={"text"}
        filterTitle={"Название замечания"}
        filterField={"name"}
        onChangeFilter={onChangeFilter}
      >
        Название замечания
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterType={"text"}
        filterTitle={"Постановщик"}
        filterField={"author"}
        onChangeFilter={onChangeFilter}
      >
        Постановщик
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterTitle={"Контрольная дата"}
        filterType={"datesRange"}
        rangeEndField="resolve_date_before"
        rangeStartField="resolve_date_after"
        onChangeFilter={onChangeFilter}
      >
        Контрольная дата
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterOptions={REMARK_STATUSES_OPTIONS}
        filterType={"select"}
        filterTitle={"Статус"}
        filterField={"status"}
        onChangeFilter={onChangeFilter}
        isCentered
      >
        Статус
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterType={"text"}
        filterTitle={"Ответственный"}
        filterField={"responsible"}
        onChangeFilter={onChangeFilter}
        isLast
      >
        Ответственный
      </TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default React.memo(RemarksHeader);
