import { message } from "antd";

import { IEditAcceptanceTicket } from "redux/modules/common/building/journal/acceptance/types";

import { ITicketDetailed } from "types/interfaces/Tickets";

interface IProps {
  dateStart?: string;
  dateEnd?: string;
  count?: string;
  initialTicket?: ITicketDetailed | null;
  workers?: ITicketDetailed["workers"];
  materials?: ITicketDetailed["materials"];
  services?: ITicketDetailed["services"];
}

const checkWorkersWasEdited = (oldWorkers?: ITicketDetailed["workers"], newWorkers?: ITicketDetailed["workers"]) => {
  const oldKey = oldWorkers
    ?.map((el) => el.id + "_" + ((el as any).local_count ?? (el as any).count ?? (el as any).stake_count))
    .join("&");
  const newKey = newWorkers
    ?.map((el) => el.id + "_" + ((el as any).local_count ?? (el as any).count ?? (el as any).stake_count))
    .join("&");

  return oldKey !== newKey;
};

export const prepareAcceptanceTicketForEdit = ({
  dateEnd,
  dateStart,
  count,
  initialTicket,
  workers,
  materials,
  services,
}: IProps) => {
  const res: Partial<IEditAcceptanceTicket> = {};
  if (dateEnd !== initialTicket?.end_at || dateStart !== initialTicket?.start_at) {
    res.end_at = dateEnd;
    res.start_at = dateStart;
  }
  if (count !== initialTicket?.count) {
    res.count = count;
  }
  if (checkWorkersWasEdited(initialTicket?.workers, workers)) {
    //@ts-ignore
    res.workers = workers?.map((el) => ({ id: el.id, stake_count: (el as any).count ?? (el as any).stake_count }));
  }
  if (checkWorkersWasEdited(initialTicket?.materials, materials)) {
    //@ts-ignore
    res.materials = materials?.map((el) => ({ id: el.id, count: (el as any).local_count }));
  }
  if (checkWorkersWasEdited(initialTicket?.services, services)) {
    //@ts-ignore
    res.services = services?.map((el) => ({ id: el.id, count: (el as any).local_count }));
  }

  return [res, !!Object.keys(res).length] as const;
};

export const checkResorcesCountIsValid = (
  resources: { id: number; count: string }[],
  allCountsMap: Record<string, number>,
  resourceName: string
) => {
  if (!resources) return true;

  let res = true as boolean;

  resources.forEach((el) => {
    if (+el.count > +allCountsMap[el.id]) {
      res = false;
    }
  });

  if (res === false) {
    message.error(`Количество ${resourceName} превышает доступное`);
  }

  return res;
};
