import React, { PropsWithChildren } from "react";

import LogoGroup from "../ui/LogoGroup/LogoGroup";

import styles from "./Menu.module.scss";

const MenuSkeleton: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <header className={styles.header}>
      <LogoGroup className={styles.logoGroup} />
      {children}
    </header>
  );
};

export default React.memo(MenuSkeleton);
