import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { getDetail, objectDetailSelector, setDetailNull } from "redux/modules/common/building/object/nowObject";
import { IBuildingResponse } from "redux/modules/common/building/object/types";

import { useTypedSelector } from "app/store/typedUseSelector";

//нужно переписать логику хранения обьектовс хранения одного обьекта к хранению многих обьектов по id
export const _DEPRECATED_useBuildingDetailDataById = (buildingId: string, isImmediatelyClearing?: boolean) => {
  const dispatch = useDispatch();
  const buildingData = useTypedSelector(objectDetailSelector);
  const [invalidateKey, setInvalidateKey] = useState(0);

  React.useEffect(() => {
    if (isNaN(+buildingId) || !buildingId) return;
    if (buildingData?.id === +buildingId) return;
    dispatch(getDetail(buildingId));
    return () => {
      if (isImmediatelyClearing) {
        dispatch(setDetailNull());
      }
    };
  }, [buildingId, buildingData?.id, invalidateKey]);

  const refetchBuilding = () => {
    dispatch(getDetail(buildingId));
  };

  return { buildingData, refetchBuilding };
};

export const _TEMP_useBuildingDetailDataById = (buildingId: string) => {
  const [isBuildingLoading, setIsBuildingLoading] = useState(false);
  const [buildingData, setBuildingData] = useState<IBuildingResponse | null>(null);

  useEffect(() => {
    setIsBuildingLoading(true);
    axios
      .get(`/building/${buildingId}/`)
      .then(({ data }) => {
        setBuildingData(data);
      })
      .finally(() => {
        setIsBuildingLoading(false);
      });
  }, [buildingId]);

  return { buildingData, isBuildingLoading };
};
