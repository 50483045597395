import update from "immutability-helper";

const removeNullSupplyOrOfferAmount = (supplyOrOffer) => {
  if (supplyOrOffer.amount !== "0.00") return supplyOrOffer;

  return update(supplyOrOffer, { $unset: ["amount"] });
};

export const transformRequestOrKitForFrontend = (requestOrKit) => ({
  ...requestOrKit,
  offers: requestOrKit.offers.map(removeNullSupplyOrOfferAmount).map((offer) => ({
    ...offer,
    supplies: offer.supplies.map(removeNullSupplyOrOfferAmount)
  }))
});

export const transformOrderForFrontend = (order) => {
  if (!order || !order.body || !order.body.requests) return order;

  return {
    ...order, body: {
      ...order.body,
      requests: order.body.requests.map(transformRequestOrKitForFrontend),
      kits: order.body.kits.map(transformRequestOrKitForFrontend)
    }
  };
};