import djvuSvg from "./assets/djvu.svg";
import excelSvg from "./assets/excel.svg";
import folderSvg from "./assets/folder.svg";
import pdfSvg from "./assets/pdf.svg";
import wordSvg from "./assets/word.svg";

import { FilesExtensionsEnum, IMG_EXTENSIONS_ARR, TABLE_EXTENSIONS_ARR, TEXT_EXTENSIONS_ARR } from "constants/files";

import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";
import { parseFileExtension } from "utils/parseFileFormat";

import styles from "./FileItem.module.scss";

export const switchFileIcons = (fileName: string, isFolder?: boolean, fileSrc?: string) => {
  if (isFolder) return <img src={folderSvg} />;
  const extension = parseFileExtension(fileName);
  if (fileSrc && IMG_EXTENSIONS_ARR.includes(extension as FilesExtensionsEnum)) {
    return <img src={fileSrc} className={styles.photo} />;
  }
  if (TEXT_EXTENSIONS_ARR.includes(extension as FilesExtensionsEnum)) {
    return <img src={wordSvg} />;
  }
  if (TABLE_EXTENSIONS_ARR.includes(extension as FilesExtensionsEnum)) {
    return <img src={excelSvg} />;
  }
  if (extension === FilesExtensionsEnum.pdf) {
    return <img src={pdfSvg} />;
  }
  if (extension === FilesExtensionsEnum.djvu) {
    return <img src={djvuSvg} />;
  } /* @ts-ignore */
  return <div className={styles.customExtension}>{sliceTextByConstraint(extension.toUpperCase(), 3, "")}</div>;
};
