import { useSelector } from "react-redux";

import { chartViewModeSelector } from "redux/modules/common/chart/selectors";
import { CHART_VIEW_MODE } from "redux/modules/common/chart/types";

/** Соотношение визуальной единицы дня на графике к Rem */
export const UNIT_MULTIPLIER_BY_VIEW: Record<CHART_VIEW_MODE, number> = {
  [CHART_VIEW_MODE.DAYS_30]: 2.5,
  [CHART_VIEW_MODE.WEEKS]: 2.5,
  [CHART_VIEW_MODE.DAYS_15]: 5,
  [CHART_VIEW_MODE.MONTHS]: (10.636 * 12) / 52,
};

export const useChartUnitMultiplier = () => {
  const viewMode = useSelector(chartViewModeSelector);
  return UNIT_MULTIPLIER_BY_VIEW[viewMode] || 2.5;
};
