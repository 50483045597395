import React from "react";
import { Field, Form } from "react-final-form";

import InputNumber from "components/UI/atoms/InputNumber";

import Actions from "shared/ui/controls/Actions/Actions";
import Checkbox from "shared/ui/inputs/Checkbox/Checkbox";
import InputBase, { VALUE_TYPES } from "shared/ui/inputs/InputBase";

import styles from "./HandlerIndexForm.module.scss";

interface IProps {
  values?: any;
  onSubmit: (vals: any) => void;
  onCancel: () => void;
  isPending?: boolean;
}

const HandlerIndexForm: React.FC<IProps> = ({ values, onSubmit, onCancel, isPending }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={values}
      key={values}
      render={({ handleSubmit }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className={styles.form}>
            <div className={styles.name}>
              <Field name="title" component={InputBase as any} placeholder="Название индекса" />
            </div>
            <div>
              <Field
                name="value"
                render={({ input }) => (
                  <InputNumber
                    placeholder="Индекс"
                    value={input.value}
                    setValue={input.onChange}
                    decimalPlace={3}
                    hideControls
                  />
                )}
              />
            </div>

            <div className={styles.checks}>
              <Field
                name="is_smr"
                render={({ input }) => (
                  <Checkbox
                    checked={input.value}
                    onCheck={(e) => input.onChange(e.target.checked)}
                    labelPlacement={"right" as any}
                  >
                    СМР
                  </Checkbox>
                )}
              />
              <Field
                name="is_mtr"
                render={({ input }) => (
                  <Checkbox
                    checked={input.value}
                    onCheck={(e) => input.onChange(e.target.checked)}
                    labelPlacement={"right" as any}
                  >
                    МТР
                  </Checkbox>
                )}
              />
            </div>
            <div>
              <Field name="reason" component={InputBase as any} placeholder="Основание" />
            </div>

            <div>
              <Actions
                canSave
                onSave={handleSubmit}
                color="blue"
                className={styles.actions}
                canRemove
                onRemoveDirectly={onCancel}
                deleteLabel="Отменить"
                disabled={isPending}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default HandlerIndexForm;
