import moment from "moment";
import React from "react";

import { tooltipLabelFormatter } from "../constants";
import { TooltipProps } from "recharts/types/component/Tooltip";

import { transformDigitToFinancial } from "../../../../../../utils/formatters/transformDigitToFinancial";

import styles from "./AnalyticsChartTooltip.module.scss";

export type IAnalyticsChartTooltipProps = TooltipProps<any, any> & { matchChartLabels: (_: string) => string };

const AnalyticsChartTooltip: React.FC<IAnalyticsChartTooltipProps> = ({ payload, label, matchChartLabels }) => {
  const hasSignificandDigitsAfterZero = payload?.some((x) => parseFloat(x?.value) % 1 !== 0);
  return (
    <div className={styles.chartTooltip}>
      {!!label && <span>{tooltipLabelFormatter(label) || label}</span>}
      <ul>
        {payload?.map((tooltipItem, index) => (
          <li key={tooltipItem.name + "_" + index}>
            <div className={styles.chartTooltipLabel}>
              <div className={styles.chartTooltipMark} style={{ background: tooltipItem.color || "transparent" }} />
              <span>{matchChartLabels(tooltipItem.name?.toString()) + ": "}</span>
            </div>
            {transformDigitToFinancial(tooltipItem?.value?.toString() || "", {
              withFloat: true,
              dropZeros: !hasSignificandDigitsAfterZero,
              withCurrencySign: true,
            })}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(AnalyticsChartTooltip);
