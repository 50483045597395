import { Moment } from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import {
  planFactVolumeExpendituresLoadingSelector,
  planFactVolumeExpendituresSelector,
} from "pages/PlanFact/PlanFactVolume/model/selectors";
import { getPlanFactVolumesExpenditures } from "pages/PlanFact/PlanFactVolume/model/thunks";

import PlanfactVolumeExpenditure from "../PlanfactVolumeExpenditure/PlanfactVolumeExpenditure";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { useElementOnScreen } from "shared/lib/hooks/useElementOnScreen";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import planFact from "images/icons/navigation/plan-fact.svg";

interface IProps {
  subsectionId: string;
  date: Moment;
  daysCount: number;
  isPreLoaded?: boolean;
}

const NewPlanFactVolumeExpenditures: React.FC<IProps> = ({ date, subsectionId, daysCount, isPreLoaded }) => {
  const objectId = useObjectId();
  const dispatch = useDispatch();

  const startAt = date.startOf("week").format("YYYY-MM-DD");
  const endAt = date.endOf("week").format("YYYY-MM-DD");

  const key = generateStorageKey({ sectionId: subsectionId, startAt, endAt, daysCount });
  const expenditures = useSelector(planFactVolumeExpendituresSelector)[key];
  const isExpendituresLoading = useSelector(planFactVolumeExpendituresLoadingSelector)[subsectionId];

  const [divRef, isVisible] = useElementOnScreen<HTMLDivElement>();
  const [wasOnceLoaded, setWasOnceLoaded] = useState(false);

  useLayoutEffect(() => {
    if (isPreLoaded) return;
    //if (!isVisible) return;
    //if (wasOnceLoaded) return;
    dispatch(getPlanFactVolumesExpenditures(objectId, startAt, endAt, subsectionId, daysCount));
  }, [objectId, subsectionId, startAt, endAt, daysCount, isPreLoaded, isVisible, wasOnceLoaded]);

  const isEmpty = !expenditures?.expenditures?.length && !expenditures?.groups?.length;

  const [expsConut, setExpsCount] = useState(1);

  useEffect(() => {
    if (expenditures?.expenditures?.length || expenditures?.groups?.length) {
      setExpsCount(expenditures?.expenditures?.length + expenditures?.groups?.length);
      setWasOnceLoaded(true);
    } else {
      setWasOnceLoaded(false);
    }
  }, [expenditures]);

  return (
    <div ref={divRef}>
      {(isExpendituresLoading || isEmpty) && (
        <div
          style={{
            height: `calc(6.2rem * ${expsConut ?? 1})`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner isStatic isSmall />
        </div>
      )}
      {expenditures?.groups?.map((el) => (
        <PlanfactVolumeExpenditure
          key={el.id}
          expenditure={el}
          startAt={startAt}
          nestedExpenditures={el.expenditures}
          daysCount={daysCount as any}
        />
      ))}
      {expenditures?.expenditures?.map((el) => (
        <PlanfactVolumeExpenditure key={el.id} expenditure={el} startAt={startAt} daysCount={daysCount as any} />
      ))}
    </div>
  );
};

export default NewPlanFactVolumeExpenditures;
