import React from "react";

import styles from "./DisplayNumber.module.scss";

export interface IDisplayNumberProps {
  largeTitle: string | number;
  smallTitle: string;
  subtitle: string;
  markBackground: string;
}

const DisplayNumber: React.FC<IDisplayNumberProps> = ({ largeTitle, smallTitle, subtitle, markBackground }) => {
  return (
    <div className={styles.block}>
      <div className={styles.head}>
        {!!largeTitle && <span className={styles.largeTitle}>{largeTitle}</span>}
        {!!smallTitle && <span className={styles.smallTitle}>{smallTitle}</span>}
      </div>
      <span className={styles.subtitle}>{subtitle}</span>
      <div className={styles.mark} style={{ background: markBackground }}></div>
    </div>
  );
};

export default React.memo(DisplayNumber);
