import cn from "classnames";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartActions } from "redux/modules/common/chart/actions";
import { chartTreeExpanderSelector, chartTreeFiltersSelector } from "redux/modules/common/chart/selectors";

import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";

import { displayDateFilter } from "../../utils/displayDateFilter";
import { stringToFilterable } from "../ChartTree/ChartTree.utils/stringToFilterable";

import { ReactComponent as SearchIcon } from "shared/assets/icons/IconZoom.svg";

import styles from "./ChartTreeHeader.module.scss";

export interface IChartTreeHeaderProps {
  /** Отображать заглушку вместо фильтров */
  isStub?: boolean;
}

export const ChartTreeHeader: React.FC<IChartTreeHeaderProps> = ({ isStub }) => {
  const dispatch = useDispatch();
  const filters = useSelector(chartTreeFiltersSelector);
  const expander = useSelector(chartTreeExpanderSelector);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [periodKey, setPeriodKey] = useState(-1);

  // фильтр текста (debounce)
  useEffect(() => {
    const searchInput = searchInputRef.current;
    if (!searchInput) return;
    const onInput = debounce((e: Event) => {
      let filterValue = (e.target as HTMLInputElement)?.value || "";
      filterValue = stringToFilterable(filterValue);
      dispatch(chartActions.updateTreeFilter({ filter: "searchText", value: filterValue }));
    }, 150);
    searchInput.addEventListener("input", onInput);
    return () => {
      searchInput?.removeEventListener("input", onInput);
    };
  }, []);

  const setDateStart = useCallback((date: string) => {
    dispatch(chartActions.updateTreeFilter({ filter: "dateStart", value: date }));
  }, []);

  const setDateEnd = useCallback((date: string) => {
    dispatch(chartActions.updateTreeFilter({ filter: "dateEnd", value: date }));
  }, []);

  const clearTextInput = useCallback(() => {
    dispatch(chartActions.updateTreeFilter({ filter: "searchText", value: "" }));
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  }, []);

  // @ts-ignore
  const clearPeriod = useCallback((e) => {
    e.stopPropagation();
    dispatch(chartActions.updateTreeFilter({ filter: "dateStart", value: null }));
    dispatch(chartActions.updateTreeFilter({ filter: "dateEnd", value: null }));
    setPeriodKey(Math.random());
  }, []);

  if (isStub) {
    return <div className={styles.treeHeader} />;
  }

  const treeHeaderCn = cn(styles.treeHeader, {
    [styles.w7]: expander?.days,
    [styles.w22]: expander?.period,
  });

  return (
    <div className={treeHeaderCn}>
      <div className={styles.inputContainer}>
        <input
          ref={searchInputRef}
          className={styles.filterInput}
          placeholder="Поиск расценки"
          defaultValue={filters?.searchText}
        />
        <SearchIcon className={styles.searchIcon} />
        {searchInputRef?.current?.value ? <div className={styles.circleCross} onClick={clearTextInput} /> : null}
      </div>
      <CalendarRange
        isHiddenInput
        containerClassName={styles.calendarWrapper}
        classNameSelect={styles.calendar}
        setDefaultDateStart={setDateStart}
        setDefaultDateEnd={setDateEnd}
        clearInvalidateKey={periodKey}
        skipValidation
      >
        {filters?.dateStart || filters?.dateEnd ? (
          <>
            <span className={styles.date}>
              {displayDateFilter(filters.dateStart)} - {displayDateFilter(filters.dateEnd)}
            </span>
            <div className={styles.circleCross} onClick={clearPeriod} />
          </>
        ) : (
          <>
            <span className={styles.periodFilterText}>По периоду</span>
          </>
        )}
      </CalendarRange>
      {expander && (
        <div className={styles.flexDaysPeriods}>
          {expander.days && <div className={styles.days}>Дней</div>}
          {expander.period && <div className={styles.period}>Начало&nbsp;-&nbsp;окончание</div>}
        </div>
      )}
    </div>
  );
};

export default React.memo(ChartTreeHeader);
