import React, { useMemo, useState } from "react";

import InputFiles from "components/UI/atoms/_TODO/InputFiles";

import FileViewer from "widgets/FileViewer/FileViewer";

import { IUseFileUpload, useFileUpload } from "./hooks/useFileUpload";

import downloadFileByUrl from "utils/helpers/downloadFileByUrl";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./FileUpload.module.scss";

export enum FILE_UPLOAD_VARIANT {
  NEW,
  EDIT,
  VIEW,
}

interface IProps extends IUseFileUpload {}

const FileUpload: React.FC<IProps> = ({ files, variant, onUploadFiles, onDeleteFile, fileStorageId }) => {
  const {
    files: filesBuffer,
    uploadFilesHandler,
    deleteFileHandler,
    isPending,
  } = useFileUpload({
    variant,
    onUploadFiles,
    onDeleteFile,
    fileStorageId,
    files,
  });

  // @ts-ignore
  const preparedFiles = useMemo(() => filesBuffer?.map((x) => ({ ...x, name: x.file_name })) || [], [filesBuffer]);

  const canExtractFiles = variant !== FILE_UPLOAD_VARIANT.NEW;
  const canUploadFiles = variant !== FILE_UPLOAD_VARIANT.VIEW;
  const canDeleteFiles = variant !== FILE_UPLOAD_VARIANT.VIEW;

  const getFilesHandler = (e: React.MouseEvent) => {
    stopEventPropagation(e);
    preparedFiles?.forEach((el) => {
      // @ts-ignore
      downloadFileByUrl(el.file, el.name || el.file_name || el.originalname);
    });
  };

  const serializedFiles = useMemo(() => {
    return filesBuffer?.map((el) => ({
      ...el,
      name: (el as any).file_name,
      // @ts-ignore
      id: el.id ?? Math.random(),
      link: (el as any).link ?? el.file,
      file: el.file as string,
    }));
  }, [filesBuffer]);

  const [fileViewerIndex, setFileViewerIndex] = useState(-1);

  return (
    <div className={styles.files}>
      <div className={styles.head}>
        <span>Список вложений: {preparedFiles?.length}</span>
        {canExtractFiles && !!preparedFiles?.length && <div onClick={getFilesHandler}>Выгрузить</div>}
      </div>
      <InputFiles
        // @ts-ignore
        value={preparedFiles} /* @t2s-expect-error */
        //setValue={handleSetFiles} /* @ts-2expect-error */
        uploadFilesDirectly={(file: File) => uploadFilesHandler([file] as any)}
        removeFileDirectly={deleteFileHandler}
        permissions={{
          addFiles: canUploadFiles,
          deleteFiles: canDeleteFiles,
        }}
        canExpand={undefined}
        disabled={undefined}
        _TODO_onDirectlyClick={setFileViewerIndex}
        isLoading={isPending}
      />
      <FileViewer
        startIndex={fileViewerIndex}
        isOpen={fileViewerIndex !== -1}
        onClose={() => setFileViewerIndex(-1)}
        files={serializedFiles}
      />
    </div>
  );
};

export default FileUpload;
