import axios from "axios";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

export function apiEstimateTree(objectId, type) {
  const config = {
    params: {
      expenditure_type: type,
    },
  };
  return axios
    .get(`/building/${objectId}/estimate/tree/`, config)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetPlan(objectId, year, month) {
  if (Number(month) < 10) month = `0${month}`;

  return axios
    .get(`/building/${objectId}/diagram/works/${year}/${month}/`)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetMaterials(objectId, year, month) {
  if (Number(month) < 10) month = `0${month}`;
  return axios
    .get(`/building/${objectId}/diagram/materials/${year}/${month}/`)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetMimes(objectId, year, month) {
  if (Number(month) < 10) month = `0${month}`;
  return axios
    .get(`/building/${objectId}/diagram/mims/${year}/${month}/`)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetWeekPlan(objectId, start_week__gte, end_week__gte, year) {
  const config = {
    params: {
      start_week__gte,
      end_week__gte,
      year,
    },
  };
  return axios
    .get(`/building/${objectId}/diagram/works/byweeks/`, config)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetWeekMaterials(objectId, start_week__gte, end_week__gte, year) {
  const config = {
    params: {
      start_week__gte,
      end_week__gte,
      year,
    },
  };
  return axios
    .get(`/building/${objectId}/diagram/materials/byweeks/`, config)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetWeekMimes(objectId, start_week__gte, end_week__gte, year) {
  const config = {
    params: {
      start_week__gte,
      end_week__gte,
      year,
    },
  };
  return axios
    .get(`/building/${objectId}/diagram/mims/byweeks/`, config)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetObjectData(objectId, year, month) {
  if (Number(month) < 10) month = `0${month}`;

  return axios
    .get(`/building/${objectId}/diagram/${year}/${month}/`)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetExpenditure(objectId, expenditureId, interval) {
  return axios
    .get(`/building/${objectId}/estimate/items/${expenditureId}/fact-intervals/${interval}`)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetExpenditurePlan(objectId, expenditureId, interval, modalType) {
  if (modalType === "lsr") {
    return axios
      .get(`/building/${objectId}/estimate/sections/plan-intervals/${interval}/`)
      .then(({ data }) => data)
      .catch(errorCatcher);
  }
  return axios
    .get(`/building/${objectId}/estimate/items/${expenditureId}/intervals/${interval}/`)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetProjects(params) {
  const config = {
    params: params || {},
  };
  return axios
    .get(`/projects/`, config)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetProjectItems(projectId) {
  return axios
    .get(`/building/${projectId}/estimate/tree/`)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetInterval(start_dt, end_dt) {
  const config = {
    params: {
      start_dt,
      end_dt,
    },
  };
  return axios
    .get(`/building/work/intervals/`, config)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetMaterialInterval(start_dt, end_dt) {
  const config = {
    params: {
      start_dt,
      end_dt,
    },
  };
  return axios
    .get(`/building/material/intervals/`, config)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetIntervalList(objectId, id, activeModule, date_start, date_end, modalType) {
  const config = {
    params: {
      start_at__gte: date_start,
      start_at__lte: date_end,
      limit: 500,
    },
  };
  if (modalType === "expenditure")
    return axios
      .get(`/building/${objectId}/estimate/items/${id}/${activeModule}/`, config)
      .then(({ data }) => data)
      .catch(errorCatcher);

  if (modalType === "section")
    return axios
      .get(`/building/${objectId}/estimate/items/sections/${id}/${activeModule}/`, config)
      .then(({ data }) => {
        if (data.results) return data.results;
        return data;
      })
      .catch(errorCatcher);

  if (modalType === "lsr" && activeModule === "intervals")
    return axios
      .get(`/building/${objectId}/estimate/sections/plan-intervals/`, {
        ...config,
        params: { ...(config?.params || {}), parent_id: id },
      })
      .then(({ data }) => {
        if (data.results) return data.results;
        return data;
      })
      .catch(errorCatcher);

  return axios
    .get(`/building/${objectId}/${activeModule}/`, config)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetIntervalFiles(objectId, expenditure_id, activeModule, interval_id) {
  return axios
    .get(`/building/${objectId}/estimate/items/${expenditure_id}/${activeModule}/${interval_id}/files/`)
    .then(({ data }) => data?.results)
    .catch(errorCatcher);
}

export function apiGetWorkMaterials(objectId, expenditureId, intervalId) {
  return axios
    .get(`/building/${objectId}/estimate/items/${expenditureId}/fact-intervals/${intervalId}/stockusingmaterials/`)
    .then(({ data }) => data?.results)
    .catch(errorCatcher);
}

export function apiUploadFile(objectId, expenditureId, activeModule, intervalId, formData) {
  return axios
    .post(`/building/${objectId}/estimate/items/${expenditureId}/${activeModule}/${intervalId}/files/`, formData)
    .then(async () => await apiGetIntervalFiles(objectId, expenditureId, activeModule, intervalId))
    .catch(errorCatcher);
}

export function apiRemoveFile(objectId, expenditureId, activeModule, intervalId, fileId) {
  return axios
    .delete(`/building/${objectId}/estimate/items/${expenditureId}/${activeModule}/${intervalId}/files/${fileId}`)
    .then(async () => await apiGetIntervalFiles(objectId, expenditureId, activeModule, intervalId))
    .catch(errorCatcher);
}

export function apiGetPlanRelations({
  from_interval: from_interval_id,
  to_interval: to_interval_id,
  from_group: from_group_id,
  to_group: to_group_id,
  building_id,
}) {
  return axios
    .get(`/building/${building_id}/intervals/plan/relations/`, {
      params: {
        building_id,
        from_interval_id,
        from_group_id,
        to_interval_id,
        to_group_id,
        limit: 100,
      },
    })
    .then(({ data }) => {
      // if (data?.results) {
      //   data.results = data.results.map((arrow) => {
      //     const newArrow = JSON.parse(JSON.stringify(arrow));
      //
      //     if (arrow.from_group && !arrow.from_interval) {
      //       newArrow.from_interval = arrow.from_group;
      //     }
      //
      //     if (arrow.to_group && !arrow.to_interval) {
      //       newArrow.to_interval = arrow.to_group;
      //     }
      //
      //     return newArrow;
      //   })
      // }
      return data;
    })
    .catch(errorCatcher);
}

export function apiCreatePlanRelation(buildingId, relation) {
  return axios
    .post(`/building/${buildingId}/intervals/plan/relations/`, relation)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiDeletePlanRelation(buildingId, relationId) {
  return axios
    .delete(`/building/${buildingId}/intervals/plan/relations/${relationId}/`)
    .then((_) => true)
    .catch((e) => {
      errorCatcher(e);
      return false;
    });
}

export function loadSectionsForRelations(projectId) {
  return axios
    .get(`/building/${projectId}/estimate/tree/`, { params: { limit: 500 } })
    .then(({ data }) => {
      return data
        .map((x) => {
          if (x.is_default) return x.children?.concat(x);
          return x.children;
        })
        .filter((x) => x)
        .flat()
        .map((x) => ({ id: x.id, name: x.name, label: x.name }));
    })
    .catch(errorCatcher);
}

export function loadIntervalsForRelations({
  projectId,
  sectionId,
  start_at__gte,
  start_at__lte,
  end_at__gte,
  end_at__lte,
}) {
  return (
    axios
      // .get(`/building/${projectId}/estimate/items/sections/${sectionId}/intervals/`, {
      //   params: {
      //     start_at__gte,
      //     start_at__lte,
      //     end_at__gte,
      //     end_at__lte,
      //     limit: 500,
      //   },
      // })
      // .then(({ data }) => {
      //   console.log(data);
      //   return data;
      // })
      .get(`/building/${projectId}/plans/lite`, {
        params: {
          section_id: sectionId,
          start_at__gte,
          start_at__lte,
          end_at__gte,
          end_at__lte,
        },
      })
      .then(({ data }) => data)
      .catch(errorCatcher)
  );
}

export function apiBulkCreateRelations(buildingId, relations) {
  return axios
    .post(`/building/${buildingId}/intervals/plan/relations/bulk_create/`, relations)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiPatchRelation({ buildingId, relationId, data }) {
  return axios
    .patch(`/building/${buildingId}/intervals/plan/relations/${relationId}/`, data)
    .then(({ data }) => data)
    .catch(errorCatcher);
}
