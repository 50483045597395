import cn from "classnames";
import React, { ChangeEventHandler, FC, useCallback, useMemo, useState } from "react";

import ExpenditureInfo from "./components/ExpenditureInfo/ExpenditureInfo";
import OrderCard from "./components/OrderCard/OrderCard";
import OrderRequestCard from "./components/OrderRequestCard/OrderRequestCard";

import { Switch } from "shared/ui/inputs/Switch/Switch";

import { IOrder, IRequest, RequestsByExpenditures } from "./types";

import styles from "./OrderComparison.module.scss";

export interface IOrdersComparisonProps {
  orders: IOrder[];
  baseModuleRoute: string;
}

const checkHasOffers = (request: IRequest) => request.offers && request.offers?.length > 0;

const OrdersComparison: FC<IOrdersComparisonProps> = ({ orders, baseModuleRoute }) => {
  const [isDisplayOnlyActiveOrders, setIsDisplayOnlyActiveOrders] = useState(false);

  const onSwitchActiveOrders: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setIsDisplayOnlyActiveOrders(e.target.checked),
    []
  );

  const displayingOrders = useMemo(
    () => orders.filter((order) => (isDisplayOnlyActiveOrders ? order.body.requests.some(checkHasOffers) : true)),
    [orders, isDisplayOnlyActiveOrders]
  );

  const requestsByExpenditures: RequestsByExpenditures = useMemo(() => {
    const result: RequestsByExpenditures = {};

    displayingOrders.forEach((order) => {
      order.body.requests.forEach((request) => {
        const productBuildingId = request.product_building.id;
        if (!result[productBuildingId]) result[productBuildingId] = [];
        result[productBuildingId].push(request);
      });
    });
    return result;
  }, [displayingOrders]);

  const getExpenditureOrderIndex = (requestId: number) =>
    displayingOrders.findIndex((x) => x.body.requests.some((x) => x.id === requestId));

  const emptyArrLength = Math.max(displayingOrders.length, 4);
  const emptyArrOfDisplayingOrdersLength = new Array(emptyArrLength).fill({});

  let globalIndex = 0;

  const realRowsCount = useMemo(() => {
    let count = 0;
    Object.values(requestsByExpenditures).forEach((expenditureRequests) => {
      count += expenditureRequests.filter((x) => (isDisplayOnlyActiveOrders ? checkHasOffers(x) : true)).length;
    });
    return count;
  }, [requestsByExpenditures, isDisplayOnlyActiveOrders]);

  const emptyRowsArray = realRowsCount < 3 ? new Array(3 - realRowsCount).fill({}) : [];

  return (
    <div className={styles.ordersComparison}>
      <header className={styles.header}>
        <div className={styles.switchBlock}>
          <Switch
            label="Показать только активные"
            checked={isDisplayOnlyActiveOrders}
            onChange={onSwitchActiveOrders}
          />
        </div>
        {emptyArrOfDisplayingOrdersLength.map((x, index) => (
          <div className={styles.order} style={{ gridColumn: index + 2 }} key={index} />
        ))}
        {displayingOrders.map((order, index) => (
          <div className={styles.order} key={order.id} style={{ gridColumn: index + 2 }}>
            {/* @ts-ignore */}
            <OrderCard order={order} baseModuleRoute={baseModuleRoute} />
          </div>
        ))}
      </header>
      {Object.values(requestsByExpenditures).map((expenditureRequests) => {
        const hasBlueBg = globalIndex % 2 === 1;
        globalIndex++;
        return (
          <div className={styles.expenditureContainer}>
            {expenditureRequests
              .filter((x) => (isDisplayOnlyActiveOrders ? checkHasOffers(x) : true))
              .map((request, index) => {
                return (
                  <div className={cn(styles.expenditure, { [styles.expenditureAbsolute]: index > 0 })}>
                    {index === 0 && <ExpenditureInfo request={request} hasBlueBg={hasBlueBg} key={request.id} />}
                    {emptyArrOfDisplayingOrdersLength.map((x, index) => (
                      <div
                        key={index}
                        className={cn(styles.request, styles.requestEmpty, { [styles.blueBg]: hasBlueBg })}
                        style={{ gridColumn: index + 2 }}
                      />
                    ))}
                    <div
                      key={request.id}
                      className={cn(styles.request, { [styles.blueBg]: hasBlueBg })}
                      style={{ gridColumn: getExpenditureOrderIndex(request.id) + 2 }}
                    >
                      <OrderRequestCard request={request} />
                    </div>
                  </div>
                );
              })}
          </div>
        );
      })}
      {emptyRowsArray.map((x, index) => (
        <div className={styles.expenditure} key={index}>
          <div
            className={cn(styles.expenditureName, { [styles.blueBg]: (index + globalIndex) % 2 === 1 })}
            style={{ gridColumn: 1 }}
          />
          {emptyArrOfDisplayingOrdersLength.map((x, idx) => (
            <div
              className={cn(styles.request, { [styles.blueBg]: (index + globalIndex) % 2 === 1 })}
              style={{ gridColumn: idx + 2 }}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default React.memo(OrdersComparison);
