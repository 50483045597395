import cn from "classnames";
import React, { FC } from "react";

import { formatNumberWithMeasure } from "../../../../utils/formatters/formatNumberWithMeasure";
import { transformDigitToFinancial } from "../../../../utils/formatters/transformDigitToFinancial";

import styles from "./Amount.module.scss";

interface IProps {
  value: string | number;
  title?: string;
  isBold?: boolean;
  className?: string;
  titleClassName?: string;
  measure?: number;
  isLeft?: boolean;
  isPercent?: boolean;
  htmlTitle?: string;
}

const Amount: FC<IProps> = ({
  value,
  title,
  isBold,
  className,
  titleClassName,
  measure,
  isLeft,
  isPercent,
  htmlTitle,
}) => (
  <div
    className={cn(styles.amount, { [styles.bold]: isBold, [styles.leftTextAlign]: isLeft }, className)}
    title={htmlTitle}
  >
    <div>
      <span className={styles.numericValue}>
        {measure
          ? formatNumberWithMeasure(value, measure)
          : transformDigitToFinancial(value, {
              withFloat: false,
              withCurrencySign: !isPercent,
              withPercent: isPercent,
            })}
      </span>
      {title && <div className={cn(styles.title, titleClassName)}>{title}</div>}
    </div>
  </div>
);

export default React.memo(Amount);
