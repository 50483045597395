import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";

import { objectsBreadcrumbsSelector } from "../../../../redux/modules/common/building/objects";

import Select from "components/UI/atoms/Select/index";

import CalendarRange from "../../../../shared/ui/inputs/CalendarRange/CalendarRange";
import ButtonBase from "shared/ui/controls/ButtonBase";
import Modal from "shared/ui/modal/Modal";

import { LOCALIZATION_CONFIG } from "constants/localization";

import { splitThousands } from "../../../../utils/formatters/splitThousands";
import { errorCatcher } from "utils/helpers/errorCatcher";

import arrowSelectSmallBlue from "images/icons/arrowSelectSmallBlue.svg";

import styles from "./index.module.scss";

const ModalCalculation = ({ close, isOpen }) => {
  const projects = useSelector(objectsBreadcrumbsSelector);

  const [dateStart, setDateStart] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());
  const [sum, setSum] = useState(null);
  const numberAllObjects = -1;

  const objSelectArr = () =>
    projects?.results.map((el) => ({
      id: el.id,
      name: el.name,
    })) || [];

  const newObj = [
    {
      id: numberAllObjects,
      name: "Все проекты",
      label: "Все проекты",
    },
    ...objSelectArr(),
  ];

  const submitRequisite = async (value) => {
    const buildingId = newObj.find((item) => +item.id === +value.building_id);
    try {
      const { data } = await axios.get(`/purchaser/packinglists/summary/`, {
        params: {
          building_id: !buildingId || buildingId?.id === numberAllObjects ? null : buildingId.id,
          delivery_date_after: moment(dateStart).format("YYYY-MM-DD"),
          delivery_date_before: moment(dateEnd).format("YYYY-MM-DD"),
        },
      });
      setSum(data?.total_vat);
    } catch (err) {
      errorCatcher(err);
    }
  };

  return (
    <Modal title="Подсчет НДС" onClose={close} className={styles.container} isOpen={isOpen}>
      <Form
        onSubmit={(values) => submitRequisite(values)}
        render={({ handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.topSide}>
              <div className={styles.blockSelects}>
                <div className={styles.field1}>
                  <Field
                    name="building_id"
                    label="Проект"
                    component={Select}
                    icon={arrowSelectSmallBlue}
                    options={newObj}
                    placeholder="Все проекты"
                  />
                </div>
                <div className={styles.field2}>
                  <Field
                    name="calendar"
                    component={CalendarRange}
                    defaultDateStart={dateStart}
                    setDefaultDateStart={setDateStart}
                    defaultDateEnd={dateEnd}
                    setDefaultDateEnd={setDateEnd}
                  />
                </div>
              </div>
              {sum !== null && (
                <div className={styles.value}>
                  Суммарный НДС:{" "}
                  <span>
                    {sum ? splitThousands(sum.toFixed(2), false, false) : "0.00"} {LOCALIZATION_CONFIG.currency}
                  </span>
                </div>
              )}
            </div>
            <div className={styles.actionBlock}>
              <ButtonBase primary big type="submit">
                Посчитать
              </ButtonBase>
            </div>
          </form>
        )}
      />
    </Modal>
  );

  return <div className={styles.container}></div>;
};

export default ModalCalculation;
