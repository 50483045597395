import cn from "classnames";
import React, { useRef, useState } from "react";

import Input from "../../../../../_LEGACY/UI/__trash/Input";

import ButtonBase from "../../../../../shared/ui/controls/ButtonBase";

import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import useEscapeHandler from "../../../../../utils/hooks/useEscapeHandler";

import Conture from "../../../../../images/icons/Conture";

import styles from "./index.module.scss";

const SearchFilterSum = ({ title, setFilterStart, setFilterEnd, last = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  useEscapeHandler(() => setIsOpen(false));

  const [minNumber, setMinNumber] = useState(null);
  const [maxNumber, setMaxNumber] = useState(null);

  const ref = useRef();

  const handleSaveValue = () => {
    setFilterStart(minNumber);
    setFilterEnd(maxNumber);
    setIsOpen(false);
  };
  const handleRemoveValue = () => {
    setMinNumber(null);
    setMaxNumber(null);
    setFilterStart(null);
    setFilterEnd(null);
  };

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div className={styles.container} ref={ref}>
      <div
        className={cn(styles.button, {
          [styles.active]: isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Conture />
        {(minNumber || maxNumber) && <div className={styles.redMark}></div>}
      </div>
      {isOpen && (
        <div className={cn(styles.modal, { [styles.modalLast]: last })}>
          <div className={styles.title}>{title}</div>
          <div className={styles.input}>
            <Input placeholder="от" value={minNumber === null ? "" : minNumber} setValue={setMinNumber} />
          </div>
          <Input placeholder="до" value={maxNumber === null ? "" : maxNumber} setValue={setMaxNumber} />
          <div className={styles.actionBlock}>
            <ButtonBase secondary small onClick={handleRemoveValue}>
              Очистить
            </ButtonBase>
            &nbsp;&nbsp;&nbsp;
            <ButtonBase primary small onClick={handleSaveValue}>
              Применить
            </ButtonBase>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchFilterSum;
