export const statusFilterDataJournalDelivery = [
  {
    id: true,
    name: "Показать принятые",
  },
  {
    id: false,
    name: "Скрыть принятые",
  },
];

export const DEFAULT_ENTITY_ID = -1;
