import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { requisitionsListActions } from "redux/modules/common/building/requisitionsList/actions";
import {
  newRequisitionsListsSelector,
  requisitionsListsSelector,
  requisitionsListsTabsSelector,
} from "redux/modules/common/building/requisitionsList/selectors";
import { getRequisitionsList } from "redux/modules/common/building/requisitionsList/thunks";
import { parseStatusParam } from "redux/modules/common/building/requisitionsList/utils";

import { TABS, TABS_LINKS } from "../constants";
import { REQUISITION_STATUS } from "constants/constant";

const keyByStatuses = parseStatusParam([REQUISITION_STATUS.NEW, REQUISITION_STATUS.DRAFT]);

const saveTabLinkToStorage = (buildingId, tabLink) => {
  let value = buildingId ? `${buildingId}_${tabLink}` : tabLink;
  localStorage.setItem("lastRequisitionActiveTabLink", value);
};

const parseValueFromStorage = (value) => {
  const splitValue = value.split("_");
  if (splitValue.length === 1) return { tabLink: value };
  return { tabLink: splitValue[1], buildingId: splitValue[0] };
};

const getTabLinkFromStorage = (buildingId) => {
  const valueFromStorage = localStorage.getItem("lastRequisitionActiveTabLink");
  if (!valueFromStorage) return null;

  const { tabLink, buildingId: parsedBuildingId } = parseValueFromStorage(valueFromStorage);
  if (buildingId && buildingId !== parsedBuildingId) return null;
  return tabLink;
};

const useTabs = (baseUrl, buildingId, activeTab) => {
  const dispatch = useDispatch();
  const newTabRequisitions = useSelector(requisitionsListsSelector)[keyByStatuses + "_" + buildingId];

  const history = useHistory();

  const tabs = useSelector(requisitionsListsTabsSelector);

  const setTabs = (tabs) => {
    dispatch(requisitionsListActions.setTabs(tabs));
  };

  const setInitialTab = useCallback(
    (buildingId, requisitionsCount) => {
      if (!baseUrl.includes("requisitions")) return;
      if (requisitionsCount === 0) {
        history.replace(`${baseUrl}/${TABS_LINKS.IN_WORK_REQUISITIONS}`);
        return;
      }
      const tabLinkFromStorage = getTabLinkFromStorage(buildingId);
      if (tabLinkFromStorage) {
        history.replace(`${baseUrl}/${tabLinkFromStorage}`);
      } else {
        history.replace(`${baseUrl}/${TABS_LINKS.NEW_REQUISITIONS}`);
      }
    },
    [history, baseUrl]
  );

  useEffect(() => {
    dispatch(getRequisitionsList([REQUISITION_STATUS.NEW, REQUISITION_STATUS.DRAFT], { building_id: buildingId })).then(
      (responseRequisitions) => {}
    );
  }, [buildingId]);

  useEffect(() => {
    if (!activeTab) return;
    saveTabLinkToStorage(buildingId, activeTab);
  }, [activeTab, buildingId]);

  useEffect(() => {
    if (activeTab || !newTabRequisitions) return;
    setInitialTab(buildingId, newTabRequisitions.count);
  }, [activeTab, buildingId, newTabRequisitions]);

  useEffect(() => {
    if (!newTabRequisitions) return;
    setTabs(TABS);
  }, [newTabRequisitions]);

  return { tabs: tabs || TABS };
};

export default useTabs;
