import cn from "classnames";
import moment from "moment";
import React from "react";

import { IRemarksList } from "redux/modules/common/building/journal/journalExecution/types";

import Header from "../components/Header/Header";

import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";
import TableReusableRow from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { remarkStatusSwitcher } from "../constants/constants";

import { ReactComponent as CalendarIcon } from "images/icons/calendar-gray-icon.svg";

import styles from "./RemarkRegister.module.scss";

export interface IProps {
  remarksCount: number;
  workName: string;
  handleOpen: (remarkId: number) => void;
  remarksList: IRemarksList[];
  isRemarksListLoading: boolean;
}

const RemarkRegister: React.FC<IProps> = ({
  remarksCount,
  workName,
  handleOpen,
  remarksList,
  isRemarksListLoading,
}) => {
  return (
    <>
      <Header title={`Замечаний: ${remarksCount}`} workName={workName} />
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <TableReusableHead className={styles.head}>
            <TableReusableHeaderCell>№</TableReusableHeaderCell>
            <TableReusableHeaderCell>Наименование</TableReusableHeaderCell>
            <TableReusableHeaderCell>Срок устранения</TableReusableHeaderCell>
          </TableReusableHead>
        </div>
        <div className={styles.rowContainer}>
          {remarksList?.length > 0 && !isRemarksListLoading ? (
            remarksList.map((item) => (
              <TableReusableRow
                key={item.id}
                containerClassName={styles.rowItemContainer}
                className={styles.row}
                onClick={() => handleOpen(item.id)}
              >
                <div className={styles.id}>
                  <span>{item.row_number}</span>
                </div>
                <div className={styles.medium}>
                  <span className={styles.name} title={item.name}>
                    {item.name || `Замечание №${item?.id}`}
                  </span>
                  <span>
                    Ответственный: <span className={styles.bold}>{item.responsible_name}</span>
                  </span>
                </div>
                <div className={styles.right}>
                  <div className={styles.date}>
                    <span>{moment(item?.resolve_date).format("DD/MM/YYYY")}</span>
                    <CalendarIcon />
                  </div>
                  <div className={cn(styles.status, styles[item.status])}>{remarkStatusSwitcher[item.status]}</div>
                </div>
              </TableReusableRow>
            ))
          ) : (
            <Spinner isFixed />
          )}
        </div>
        <div className={styles.divider} />
      </div>
    </>
  );
};

export default React.memo(RemarkRegister);
