import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import { useTypedSelector } from "app/store/typedUseSelector";
import SelectMenu from "shared/ui/controls/SelectMenu/SelectMenu";

import styles from "./StocksBreadcrumbs.module.scss";

const StocksBreadcrumbs = () => {
  const { stockId, tab } = useParams<Record<string, string>>();
  const { stocks } = useTypedSelector((state) => state.stocksProcurements);

  const selectedStock = useMemo(() => {
    return stocks?.find((el) => el.id === +stockId)?.name;
  }, [stocks, stockId]);

  return (
    <div className={styles.container}>
      <SelectMenu
        selectedOption={selectedStock}
        titleClassName={styles.breadcrumbsTitleSmall}
        selectClassName={styles.selectClassName}
        optionsBlockClassName={styles.optionsBlockClassName}
        lastEl
      >
        {!!stocks?.length &&
          stocks.map((option) => (
            <Link key={option.id} to={`/purchases/stocks/${option.id}/${tab ?? "receipt"}`}>
              <div className={styles.breadcrumbsItem} title={option.name}>
                {option.name}
              </div>
            </Link>
          ))}
      </SelectMenu>
    </div>
  );
};

export default StocksBreadcrumbs;
