import { IActions } from "app/store/store";

import { IPlanFactVolumeExpenditure, IPlanFactVolumeSection } from "./types";

export const planFactVolumeActions = {
  setSections: (objectId: string, sections: IPlanFactVolumeSection[]) =>
    ({
      type: "planFactVolume/SET_SECTIONS",
      payload: {
        objectId,
        sections,
      },
    } as const),
  setSectionsLoading: (objectId: string, status: boolean) =>
    ({
      type: "planFactVolume/SET_SECTIONS_LOADING",
      payload: {
        objectId,
        status,
      },
    } as const),
  setExpenditures: (sectionId: string, expenditures: IPlanFactVolumeExpenditure) =>
    ({
      type: "planFactVolume/SET_EXPENDITURES",
      payload: {
        sectionId,
        expenditures,
      },
    } as const),
  setExpendituresLoading: (sectionId: string, status: boolean) =>
    ({
      type: "planFactVolume/SET_EXPENDITURES_LOADING",
      payload: {
        sectionId,
        status,
      },
    } as const),
  setAllExpanded: (status: boolean) =>
    ({
      type: "planFactVolume/SET_ALL_EXPANDED",
      payload: status,
    } as const),
  setDaysCount: (count: 5 | 7) =>
    ({
      type: "planFactVolume/SET_DAYS_COUNT",
      payload: count,
    } as const),
};

export type IPlanFactVolumeAction = IActions<typeof planFactVolumeActions>;
