import { IOption } from "components/UI/atoms/Select";

export const PLANFACT_PERIOD_OPTIONS: IOption[] = [
  {
    id: 1,
    name: "1 месяц",
  },
  {
    id: 3,
    name: "3 месяца",
  },
  {
    id: 6,
    name: "6 месяцев",
  },
  {
    id: 12,
    name: "1 год",
  },
  {
    id: "all",
    name: "Весь проект",
  },
];
