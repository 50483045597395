import { IAnalyticsProgressData } from "../../../../../redux/modules/common/analytics/types/progress";

export const ANALYTICS_PROGRESS_COLORS = {
  plan: "#DFE0E1",
  completed: "#DCCDE4",
  accepted: "#914DF6",
  todo: "#3FB6F3",
  to_pay: "#5BD0A2",
};

export const ANALYTICS_PROGRESS_LABELS = {
  plan: "Запланировано",
  completed: "Выполнено",
  accepted: "Принято",
  todo: "К сдаче",
  to_pay: "Сдано (к оплате)",
};

export const makeFill = (direction: "top" | "bottom" | "left" | "right", color: string, percent: number) =>
  `linear-gradient(to ${direction}, ${color} ${percent}%, transparent ${percent + 0.01}%)`;

export const generateFills = (
  plan: IAnalyticsProgressData["plan"],
  completed: IAnalyticsProgressData["completed"],
  accepted: IAnalyticsProgressData["accepted"],
  todo: IAnalyticsProgressData["todo"],
  to_pay: IAnalyticsProgressData["to_pay"],
  total: IAnalyticsProgressData["total"]
) => {
  try {
    const [planNum, completedNum, acceptedNum, todoNum, toPayNum, totalNum] = [
      plan,
      completed,
      accepted,
      todo,
      to_pay,
      total,
    ].map(parseFloat);
    if (!totalNum) return {};
    const planPercent = (100 * planNum) / totalNum;
    const completedPercent = (100 * completedNum) / totalNum;
    const acceptedPercent = (100 * acceptedNum) / totalNum;
    const todoPercent = (100 * todoNum) / totalNum;
    const toPayPercent = (100 * toPayNum) / totalNum;
    const percents: { name: keyof typeof ANALYTICS_PROGRESS_COLORS; percent: number }[] = [
      { name: "to_pay", percent: toPayPercent },
      { name: "todo", percent: todoPercent },
      { name: "accepted", percent: acceptedPercent },
      { name: "completed", percent: completedPercent },
      { name: "plan", percent: planPercent },
    ];
    const front = percents
      .map(({ name, percent }) => makeFill("top", ANALYTICS_PROGRESS_COLORS[name], percent))
      .join(",");
    const right = percents
      .map(({ name, percent }) => makeFill("left", ANALYTICS_PROGRESS_COLORS[name], percent))
      .join(",");
    const topName = percents.find((x) => x.percent >= 100)?.name;

    const top = !!topName ? makeFill("top", ANALYTICS_PROGRESS_COLORS[topName], 100) : "none";

    return {
      front,
      right,
      top,
    };
  } catch (e) {
    console.error(e);
    return {};
  }
};
