import React from "react";

import JournalActingLsrLevel from "./JournalActingLsrLevel/JournalActingLsrLevel";
import JournalActingSectionsLevel from "./JournalActingSectionsLevel/JournalActingSectionsLevel";
import JournalKs2Level from "./JournalKs2Level/JournalKs2Level";
import JournalKs2NestedLevel from "./JournalKs2Level/JournalKs2NestedLevel";

import { useFoldersNavigation } from "hooks/navigation/useFoldersNavigation";

import styles from "./JournalActing.module.scss";

interface IProps {
  isGroupedByKs2: boolean;
}

const JournalActing: React.FC<IProps> = ({ isGroupedByKs2 }) => {
  const { currentFolderId, backHandler, openFolderHandler } = useFoldersNavigation("estimateId");

  return (
    <>
      {isGroupedByKs2 && currentFolderId && <JournalKs2NestedLevel onBack={backHandler} estimateId={currentFolderId} />}
      {isGroupedByKs2 && !currentFolderId && <JournalKs2Level />}
      {!isGroupedByKs2 && !currentFolderId && <JournalActingLsrLevel onOpenLsr={openFolderHandler} />}
      {!isGroupedByKs2 && currentFolderId && (
        <JournalActingSectionsLevel estimateId={currentFolderId} onBack={backHandler} />
      )}
    </>
  );
};

export default React.memo(JournalActing);
