import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { sectionsSelector } from "redux/modules/common/building/sections/sections";

import ProjectSubsectionsHeader from "components/pages/Building/components/ProjectHeader/ProjectSubsectionsHeader";

import ISection from "types/interfaces/Section";

interface IProps {
  objectInfo: any;
}

const CCSubsectionHeader: React.FC<IProps> = ({ objectInfo }) => {
  const history = useHistory();
  const { objectId, sectionId, tab, projectId } = useParams<any>();
  const sectionsFromSelector: ISection[] = useSelector(sectionsSelector);

  const section = useMemo(() => {
    return sectionsFromSelector?.find((el) => el.id === +sectionId);
  }, [sectionsFromSelector, sectionId]);

  return (
    <div>
      <ProjectSubsectionsHeader
        onBackClick={() => {
          history.push(`/construction-control/projects/${projectId}/object/${objectId}/${tab}/`);
        }}
        isExists
        projectName={objectInfo?.name}
        isShared={false} //={isShared}
        subSection={section as any} //={subSection}
        onApproveSharing={() => {}} //={approveSharingHandler}
        onDeclineSharing={() => {}} //={declineSharingHandler}
      />
    </div>
  );
};

export default CCSubsectionHeader;
