import React, { useCallback, useEffect, useMemo, useState } from "react";

import InputBase, { VALUE_TYPES } from "../../../../shared/ui/inputs/InputBase";
import RadioLookingAsCheckbox from "../../RadioLookingAsCheckbox";
import Checkbox from "../../__TODO/Checkbox/Checkbox";

import { LOCALIZATION_CONFIG } from "constants/localization";

import styles from "./index.module.scss";

export const termsNames = {
  pickup: "Самовывоз",
  free: "Бесплатная",
  paid: "Платная",
};

export const INITIAL_TERMS = [
  { term_type: "paid", confirmed: false },
  { term_type: "free", confirmed: false },
  { term_type: "pickup", confirmed: false },
];

const sortTerms = (a, b) => {
  if (a.term_type === "pickup" || b.term_type === "paid") return -1;
  if (a.term_type === "free" || b.term_type === "free") return 0;
  if (a.term_type === "paid" || b.term_type === "pickup") return 1;
  return 0;
};

const DeliveryTerms = ({ terms, disabled, termChangeHandler, disagreeDeliveryChange, multiplySelect = false }) => {
  const [localTerms, setLocalTerms] = useState();
  const [isLocalTermsChanged, setIsLocalTermsChanged] = useState(false);

  useEffect(() => {
    if (
      !localTerms?.length ||
      localTerms.filter((item) => item.confirmed).length <= 1 ||
      isLocalTermsChanged ||
      multiplySelect
    )
      return;

    setIsLocalTermsChanged(true);
    termChangeHandler(localTerms.map((term) => ({ ...term, confirmed: false })));
  }, [localTerms]);

  const onChangePaidTermCost = useCallback(
    (e) => {
      termChangeHandler(
        localTerms.map((term) => (term.term_type === "paid" ? { ...term, cost: e.target.value } : term))
      );
    },
    [termChangeHandler, localTerms]
  );

  const onSelectTerm = useCallback(
    (selectedType, isConfirmed) => {
      termChangeHandler(
        localTerms.map((term) => {
          if (multiplySelect) return term.term_type === selectedType ? { ...term, confirmed: isConfirmed } : term;
          return { ...term, confirmed: term.term_type === selectedType };
        })
      );

      if (isConfirmed && disagreeDeliveryChange) disagreeDeliveryChange(false);
    },
    [termChangeHandler, localTerms, disagreeDeliveryChange, multiplySelect]
  );

  const ConfirmComponent = multiplySelect ? Checkbox : RadioLookingAsCheckbox;

  useEffect(() => {
    setLocalTerms(!terms || terms.length === 0 ? INITIAL_TERMS : terms);
  }, [terms]);

  const sortedTerms = useMemo(() => [...(localTerms || [])]?.sort(sortTerms), [localTerms]);

  if (!localTerms) return null;

  return (
    <div className={styles.deliveryTerms}>
      <header className={styles.header}>
        <h2 className={styles.headerText}>Условия доставки:</h2>
      </header>
      <div className={styles.content}>
        {sortedTerms?.map((term, index) => (
          <div className={styles.checkRow} key={`${term.term_type}_${index}`}>
            <ConfirmComponent
              checked={term.confirmed}
              onChange={(e) => onSelectTerm(term.term_type, e.target.checked)}
              disabled={disabled}
            >
              {termsNames[term.term_type]}
            </ConfirmComponent>
            {term.term_type === "paid" && (
              <>
                <InputBase
                  className={styles.priceInputContainer}
                  classNameInput={styles.priceInput}
                  disabled={disabled}
                  value={term.cost}
                  valueType={VALUE_TYPES.PRICE}
                  placeholder={`Цена, ${LOCALIZATION_CONFIG.currency}`}
                  onChange={onChangePaidTermCost}
                  numberTransformOptions={{ withFloat: false, dropZeros: true }}
                />
                <span className={styles.priceCurrency}>{LOCALIZATION_CONFIG.currency}</span>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(DeliveryTerms);
