import { Dispatch } from "redux";

import { IUIIndicatorResponse } from "../../../redux/modules/common/uiIndicators/types";

import { SET_IS_CHAT_V2_CONNECTED } from "./reducers";

import { IchatMessageResponse } from "widgets/Chat/model/types";

let socket: WebSocket | null = null;
let toBeKilled = false;
let connectionAttempts = 0;

const socketHandlersMap: Record<string, (data: IchatMessageResponse) => void> = {};

export const socketAddHandler = (
  name: string,
  handler: (data: IchatMessageResponse | IUIIndicatorResponse) => void
) => {
  socketHandlersMap[name] = handler;
};

export const socketRemoveHandler = (name: string) => {
  if (socketHandlersMap[name]) {
    delete socketHandlersMap[name];
  }
};

export const socketConnectV2 = (authKey?: string) => {
  return (dispatch: Dispatch) => {
    if (!authKey || connectionAttempts > 5) return;
    try {
      if (socket) {
        socketKill();
      }
      connectionAttempts += 1; //@ts-ignore
      socket = new WebSocket(`${window.externalConfig._websocketV2URL}?token=${authKey}`);

      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data?.type === "chat") socketHandlersMap[data.data.room]?.(data.data);
          if (data?.type === "indicator") {
            data.data?.modules?.forEach((indicatorModule: string) =>
              socketHandlersMap[`indicators_${indicatorModule}`]?.(data.data)
            );
          }
        } catch (error) {
          return;
        }
      };

      socket.onclose = (event) => {
        //Ошибка авторизации
        if (event.code === 1008) {
          socketKill();

          dispatch({
            type: SET_IS_CHAT_V2_CONNECTED,
            payload: false,
          });

          return;
        } else if (process.env?.NODE_ENV === "development") {
          console.error(event.code, event.reason);
        }

        if (!toBeKilled) {
          socket = null;
          socketConnectV2(authKey);
        }
      };
    } catch (error) {
      console.error(error);
    }
  };
};

export const socketKill = () => {
  toBeKilled = true;
  socket && socket.close();
  socket = null;
  toBeKilled = false;
};
