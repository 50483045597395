import cn from "classnames";
import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";

import styles from "./PositionModalRow.module.scss";

interface IProps {
  isEdit: boolean;
}

const PositionModalHeader: React.FC<IProps> = ({ isEdit }) => {
  return (
    <TableReusableHead className={cn(styles.head, { [styles.headDisplaying]: !isEdit })}>
      <TableReusableHeaderCell>Разряд</TableReusableHeaderCell>
      <TableReusableHeaderCell>Ставка за период</TableReusableHeaderCell>
      <TableReusableHeaderCell isRighted className={styles.rightedHeader}>
        Ставка
      </TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default PositionModalHeader;
