import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { analyticsActions } from "../../../../../redux/modules/common/analytics/actions";
import {
  analyticsActiveTabSelector,
  analyticsDatesSelector,
  analyticsInvalidateKeySelector,
  analyticsProjectDatesSelector,
  analyticsScopeSelector,
} from "../../../../../redux/modules/common/analytics/selectors";
import {
  loadAnalyticsProjectDates,
  setAnalyticsMonthsCount,
  setAnalyticsStartDate,
  toggleAnalyticsAllProjectView,
} from "../../../../../redux/modules/common/analytics/thunks";
import { AnalyticsTabsType } from "../../../../../redux/modules/common/analytics/types";
import { makeProjectDatesKey } from "../../../../../redux/modules/common/analytics/utils";

import LabeledSwitch from "../../../../UI/atoms/_TODO/LabeledSwitch";
import AnalyticsScopeSelect from "./components/AnalyticsScopeSelect/AnalyticsScopeSelect";
import DatesSettingsPopup from "./components/DatesSettingsPopup/DatesSettingsPopup";

import { useTypedSelector } from "../../../../../app/store/typedUseSelector";
import TabBarNotLinks from "../../../../../shared/ui/controls/TabBar/TabBarNotLinks";
import MonthsYearsSlider from "../../../../../shared/ui/inputs/MonthsYearsSlider/MonthsYearsSlider";

import { ANALYTICS_TABS } from "../../constants";

import styles from "./AnalyticsHeader.module.scss";

const AnalyticsHeader: React.FC = () => {
  const dispatch = useDispatch();
  const selectedScope = useTypedSelector(analyticsScopeSelector);
  const activeTab = useTypedSelector(analyticsActiveTabSelector);
  const selectedDates = useTypedSelector(analyticsDatesSelector);
  const projectDates = useTypedSelector(analyticsProjectDatesSelector);

  const selectedMoment = useMemo(() => moment(selectedDates.start_at), [selectedDates.start_at]);

  const handleChangeSelectedDate = useCallback((newDate: Moment) => {
    dispatch(setAnalyticsStartDate(moment(newDate).format("YYYY-MM-DD")));
  }, []);

  const handleChangeSelectedMonthsCount = useCallback((newMonthsCount: number) => {
    dispatch(setAnalyticsMonthsCount(newMonthsCount));
  }, []);

  const toggleAllProjectView = useCallback(() => {
    dispatch(toggleAnalyticsAllProjectView());
  }, []);

  const handleChangeActiveTab = useCallback((newTab: string) => {
    dispatch(analyticsActions.setActiveTab(newTab as AnalyticsTabsType));
  }, []);

  useEffect(() => {
    if (
      !selectedDates.allProject ||
      !selectedScope.project ||
      !!projectDates[makeProjectDatesKey(selectedScope.project, selectedScope.section || selectedScope.lsr)]
    )
      return;
    dispatch(loadAnalyticsProjectDates());
  }, [selectedDates.allProject, selectedScope.project, selectedScope.lsr, selectedScope.section, projectDates]);

  const { allProjectStartMoment, allProjectMonthsCount } = useMemo(() => {
    if (!selectedScope.project) return {};
    const dates = projectDates[makeProjectDatesKey(selectedScope.project!, selectedScope.section || selectedScope.lsr)];
    if (!dates || Object.values(dates).every((x) => !x)) return {};
    const minMoment = moment.min(
      moment(dates.min_planfact_date_start || dates.min_construction_date_start),
      moment(dates.min_construction_date_start || dates.min_planfact_date_start)
    );
    const maxMoment = moment.max(
      moment(dates.max_planfact_date_end || dates.max_construction_date_end),
      moment(dates.max_construction_date_end || dates.max_planfact_date_end)
    );
    if (!minMoment?.format("YYYY-MM-DD") || !maxMoment?.format("YYYY-MM-DD")) return {};
    const monthsDiff = (maxMoment.year() - minMoment.year()) * 12 + (maxMoment.month() - minMoment.month() + 1);
    return {
      allProjectStartMoment: minMoment,
      allProjectMonthsCount: monthsDiff || 1,
    };
  }, [projectDates, selectedScope]);

  return (
    <header className={styles.header}>
      <div className={styles.datesRow}>
        <TabBarNotLinks tabs={ANALYTICS_TABS} activeId={activeTab} onClick={handleChangeActiveTab} />
        <div className={styles.sliderBlock}>
          {!!selectedDates?.allProject && !!allProjectStartMoment ? (
            <MonthsYearsSlider
              date={allProjectStartMoment}
              onChange={() => {}}
              className={styles.timeSlider}
              selectingMonthsCount={allProjectMonthsCount}
              variant={"display"}
            />
          ) : (
            <MonthsYearsSlider
              date={selectedMoment}
              onChange={handleChangeSelectedDate}
              className={styles.timeSlider}
              selectingMonthsCount={selectedDates.monthsCount}
            />
          )}
          <DatesSettingsPopup
            disabled={!!selectedDates.allProject}
            selectedMonthsCount={selectedDates.monthsCount}
            onSelectMonthsCount={handleChangeSelectedMonthsCount}
          />
        </div>
        <LabeledSwitch
          value={!!selectedDates.allProject}
          label={"Весь проект"}
          onChange={toggleAllProjectView}
          labelRight={undefined}
          disabled={!selectedScope.project}
        />
      </div>
      <AnalyticsScopeSelect />
    </header>
  );
};

export default React.memo(AnalyticsHeader);
