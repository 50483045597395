import cn from "classnames";
import React from "react";

import TableFilterTextInput from "shared/ui/inputs/TableFilterTextInput/TableFilterTextInput";

import styles from "./TableHeader.module.scss";

function WorkersTableHeader({ onFiltersChange, className }) {
  return (
    <div className={cn(styles.header, className)}>
      <div />
      <div>№</div>
      <div />
      <div className={styles.name}>
        ФИО
        {onFiltersChange && <TableFilterTextInput title="ФИО" setFilter={(value) => onFiltersChange(value, "name")} />}
      </div>
      <div>Кол-во, ч.</div>
      <div />
    </div>
  );
}

export default WorkersTableHeader;
