import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { useAttachFileToDrwaingSetMutation, useCreateDrawingsSetMutation } from "../../model/blueprintsQuery";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import ButtonBase from "shared/ui/controls/ButtonBase";
import ExpandableFileInput from "shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";
import InputBase from "shared/ui/inputs/InputBase";
import TextArea from "shared/ui/inputs/TextArea/TextArea";

import { ICreateDrawingSet } from "../../model/types";

import { composeFieldValidators, required } from "utils/formHelpers/validations";
import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import { IFile } from "utils/serializeFiles";

import styles from "./BlueprintSetModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const BlueprintSetModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const buildingId = useObjectId();
  const [files, setFiles] = useState<any[]>([]);
  const [createBlueprintSet, { isLoading, isSuccess, data }] = useCreateDrawingsSetMutation();
  const [attachFile, { isSuccess: isFileLoadingSuccess, isLoading: isFileLoading }] =
    useAttachFileToDrwaingSetMutation();

  useEffect(() => {
    if (isSuccess && data?.id) {
      onClose();
      files.forEach(async (el) => {
        const [file] = await getArrayFilesInBase64([el]);
        const serializedFile = { file: file.file, originalname: file.name };
        attachFile({ buildingId, drawingSetId: data.id, file: serializedFile as IFile });
      });
    }
  }, [isSuccess, data]);

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} name="Новый комплект чертежей">
      <Form
        onSubmit={(vals: ICreateDrawingSet) => {
          createBlueprintSet({ ...vals, buildingId });
        }}
        render={({ handleSubmit }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className={styles.form}>
              <Field
                render={({ input, meta }) => (
                  <TextArea
                    label="Название комплекта"
                    maxRows={2}
                    value={input.value}
                    onChange={(e) => input.onChange(e.target.value)}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    error={meta.touched && meta.error}
                  />
                )}
                name="title"
                validate={composeFieldValidators(required())}
              />

              <Field
                render={({ input, meta }) => (
                  <LabeledItem label="Шифр/обозначение">
                    <InputBase className={styles.input} input={input} meta={meta} />
                  </LabeledItem>
                )}
                name="code"
                validate={composeFieldValidators(required())}
              />
              <Field
                render={({ input, meta }) => (
                  <LabeledItem label="Организация, разработавшая чертежи">
                    <InputBase className={styles.input} input={input} meta={meta} />
                  </LabeledItem>
                )}
                name="organization"
                validate={composeFieldValidators(required())}
              />
            </div>
            <ExpandableFileInput files={files} setFiles={setFiles} />
            <BottomControls isExists isDoubleBtns>
              <ButtonBase secondary onClick={onClose}>
                Отменить
              </ButtonBase>
              <ButtonBase type="submit" primary disabled={isLoading} isLoading={isLoading || isFileLoading}>
                Сохранить
              </ButtonBase>
            </BottomControls>
          </form>
        )}
      />
    </ModalContainer>
  );
};

export default BlueprintSetModal;
