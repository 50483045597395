import React from "react";

import { IAosrDetail, IMaterialInAosr } from "redux/modules/common/building/journal/aosr/types";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { useCompletedTicketMaterials } from "components/pages/Journal/components/hooks/useCompletedTicketMaterials";

interface IProps {
  currentAosrDetail: IAosrDetail | null;
}

export const useCreateAosrMaterials = ({ currentAosrDetail }: IProps) => {
  const [allMaterials, setAllMaterials] = React.useState(currentAosrDetail?.materials);

  React.useEffect(() => {
    setAllMaterials(currentAosrDetail?.materials?.map((el) => ({ ...el, was_deleted: false })));
  }, [currentAosrDetail?.materials]);

  const deleteMaterial = React.useCallback((id: number) => {
    setAllMaterials((prev) =>
      prev?.map((el) => {
        if (el.material_id == id) {
          return { ...el, was_deleted: true };
        } else {
          return el;
        }
      })
    );
  }, []);

  const addMaterial = React.useCallback((material: IMaterialInAosr) => {
    setAllMaterials((prev) => {
      if (prev?.findIndex((el) => el.material_id === material.material_id) === -1) {
        return [...prev, material];
      }
      return prev?.map((el) => {
        if (el.material_id === material.material_id) {
          return { ...el, was_deleted: false };
        } else {
          return el;
        }
      });
    });
  }, []);

  return {
    allMaterials,
    deleteMaterial,
    addMaterial,
  };
};
