import React from "react";
import { useDispatch } from "react-redux";

import { actingActions } from "redux/modules/common/building/journal/acting/actions";
import { ISectionInActing } from "redux/modules/common/building/journal/acting/types";

import JournalRowIndicator from "../common/JournalRowIndicator/JournalRowIndicator";
import JournalActingSectionContent from "./JournalActingSectionContent";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import Checkbox from "shared/ui/inputs/Checkbox/Checkbox";

import { VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS2 } from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";

import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./JournalActingSectionsLevel.module.scss";

interface IProps {
  section: ISectionInActing;
  estimateId: number;
}

const JournalActingSectionRow: React.FC<IProps> = ({ section, estimateId }) => {
  const dispatch = useDispatch();

  const haveFormationKs2Permission = usePermission(VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS2);

  const checkHandler = React.useCallback(() => {
    dispatch(actingActions.selectSection(section.id, section.parent, !section.isSelected));
  }, [section]);

  const isDisabledByConstructionControl = section.cc_confirmed === false;

  return (
    <TableReusableRow
      isExpandable
      isParentOfExpenditures
      isWhiteSvgOnHover
      innerContent={<JournalActingSectionContent sectionId={section.id} estimateId={estimateId} />}
      className={styles.sectionRow}
      openClassName={styles.sectionWrapper}
    >
      {haveFormationKs2Permission && (
        <TableReusableCell className={styles.sectionCheckbox}>
          {!isDisabledByConstructionControl && <Checkbox checked={!!section.isSelected} onCheck={checkHandler} />}
        </TableReusableCell>
      )}

      <TableReusableCell isNoBreak>{section.name}</TableReusableCell>
      <TableReusableCell>
        <JournalRowIndicator title="Бюджет" value={splitThousands(section.budget_amount)} />
      </TableReusableCell>
      <TableReusableCell>
        <JournalRowIndicator title="Сдано" value={splitThousands(section.completed_amount)} />
      </TableReusableCell>
      <TableReusableCell>
        <JournalRowIndicator title="К сдаче" value={splitThousands(section.todo_amount)} />
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default React.memo(JournalActingSectionRow);
