import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

export const stateSelector = (state: RootState) => state.financeReducer.summaryScrollReducer;

export const summaryYearSelector = createSelector(stateSelector, (state) => state.year);

export const tableScrollPositionSelector = createSelector(stateSelector, (state) => state.tableScrollPosition);
export const tableViewWidthPercentSelector = createSelector(stateSelector, (state) => state.tableViewWidthPercent);
export const summaryTableHighlightingSelector = createSelector(stateSelector, (state) => state.tableHighlighting);
