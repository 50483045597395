import cn from "classnames";
import React, { useCallback, useMemo } from "react";

import { GroupIntervalModulesType } from "redux/modules/common/building/processTypes";

import ProductIntervalCardContent from "../../../../../pages/Journal/components/JournalDelivery/components/ProductIntervalCard/ProductIntervalCardContent";
import IntervalRelationsContent from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/IntervalRelationsContent/IntervalRelationsContent";
import ManufacturingModalContentFact from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModalContentFact";
import ManufacturingModalContentPlan from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModalContentPlan";
import {
  MAIN_PLAN_TAB,
  PLAN_TABS,
  RELATIONS_PLAN_TAB,
} from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/constants";
import { manufacturingModalStatusSwitcher } from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/manufacturingModalStatusSwitcher";
import BottomControls from "../../../WorkOrMaterialsModals/components/BottomControls/BottomControls";
import MaterialBlockInWork from "../../../WorkOrMaterialsModals/components/MaterialsInWork/MaterialBlockInWork";
import ModalContainer from "../../../WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import WorkListItem from "../../../WorkOrMaterialsModals/components/WorkListItem/WorkListItem";
import WorkersList from "../../../WorkOrMaterialsModals/components/WorkersList/WorkersList";
import NavBar from "components/UI/_TODO/WorkOrMaterialsModals/components/NavBar/NavBar";
import PlanEditing from "components/pages/Manufacturing/components/modals/ManufacturingModal/PlanEditing/PlanEditing";
import useCanApprovePlan from "components/pages/Manufacturing/components/modals/ManufacturingModal/PlanEditing/useCanApprovePlan";

import { SimpleResourceBlock } from "../../../../../../features/simpleResources/ui/SimpleResourceBlock/SimpleResourceBlock";
import { Spinner } from "../../../../../../shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "../../../../../../shared/ui/controls/ButtonBase";
import TabBarNotLinks from "../../../../../../shared/ui/controls/TabBar/TabBarNotLinks";
import Expandable from "../../../../atoms/_TODO/Expandable/Expandable";
import { useModalGroupIntervalModal } from "./useModalGroupIntervalModal";
import DisplayAxesAndMarks from "widgets/AxesAndMarks/widgets/DisplayAxesAndMarks/DisplayAxesAndMarks";
import PlanEditingHistory from "widgets/EditingHistory/PlanEditingHistory";

import { ResourceTypeEnum } from "../../../../../../features/simpleResources/types/simpleResources";
import { IIntervalGroupExpenditure } from "types/interfaces/Expenditure";

import { useHasEditPlanPermission } from "../../../../../../shared/lib/hooks/useHasEditPlanPermission";

import { isNanChecker } from "../../../../../../utils/formatters/isNanChecker";

import styles from "./IntervalGroupModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  expenditureId: number;
  expenditure: Record<string, IIntervalGroupExpenditure[]>;
  sectionName: string;
  activeModule: GroupIntervalModulesType;
  date_start: string;
  date_end: string;
  objectId: string;
  onAddToJournalCallback?: () => void;
  onBackButtonClick?: () => void;
  sectionId: number;
  onEditCallback?: () => void;
  defaultOpenedIntervalId?: string | null;
  showRelationsTab?: boolean;
  hasRelations?: boolean;
}

const IntervalGroupModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  expenditureId,
  expenditure,
  sectionName,
  activeModule = "plans",
  date_start,
  date_end,
  objectId,
  onAddToJournalCallback,
  onBackButtonClick,
  sectionId,
  onEditCallback,
  showRelationsTab = false,
  defaultOpenedIntervalId = null,
}) => {
  const {
    handleChangeIntervalId,
    intervalId,
    isLoading,
    data,
    modalStatus,
    isAddingNew,
    isEditingPlan,
    closeHandler,
    handleChangeIsAddingNew,
    handleOpenEditingPlan,
    handleCloseEditingPlan,
    expendituresOnCurrentWeek,
    canEditPlan,
    planIsInFuture,
    // canApprovePlan,
    approveHandler,
    getGroupIntervalsListRequest,
    tab,
    setTab,
    hasEditRelationsPermission,
    hasRelations,
    relationsFromCurrentInterval,
    relationsToCurrentInterval,
    clearIntervalDataHandler,
  } = useModalGroupIntervalModal({
    isOpen,
    buildingId: objectId,
    expenditure,
    expenditureId,
    dateEnd: date_end,
    dateStart: date_start,
    activeModule,
    onClose,
    defaultOpenedIntervalId,
  });

  const canApprovePlan = useCanApprovePlan({
    isActive: isOpen,
    isSection: false,
    approvals: data?.approvals,
    objectId,
  });

  const hasEditPlanPermission = useHasEditPlanPermission();

  const simpleMaterials = useMemo(() => {
    return (
      /* @ts-ignore */
      data?.simpleResources?.filter((r) => ![ResourceTypeEnum.transport, ResourceTypeEnum.machine].includes(r.type)) ??
      []
    ); /* @ts-ignore */
  }, [data?.simpleResources]);

  const simpleMims = useMemo(() => {
    return (
      /* @ts-ignore */
      data?.simpleResources?.filter((r) => [ResourceTypeEnum.transport, ResourceTypeEnum.machine].includes(r.type)) ??
      []
    ); /* @ts-ignore */
  }, [data?.simpleResources]);

  const materialsCount = useMemo(() => {
    /* @ts-ignore */
    return isNanChecker(+data?.materials?.length) + isNanChecker(simpleMaterials.length);
  }, [data?.materials, simpleMaterials]);

  const mimsCount = useMemo(() => {
    /* @ts-ignore */
    return isNanChecker(+data?.materials?.length) + isNanChecker(simpleMims.length);
  }, [data?.materials, simpleMaterials]);

  const isBackButtonExists = expendituresOnCurrentWeek?.length > 1 || !!onBackButtonClick;

  const handleBackButtonClick = useCallback(() => {
    clearIntervalDataHandler();
    onBackButtonClick?.();
  }, [onBackButtonClick]);

  if (isAddingNew) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={data?.group?.name} /* @ts-ignore */
        statusColor={
          manufacturingModalStatusSwitcher({
            isPlan: activeModule === "plans" /* @ts-ignore */,
            status: modalStatus,
          }).color
        }
        statusText={
          manufacturingModalStatusSwitcher({
            isPlan: activeModule === "plans" /* @ts-ignore */,
            status: modalStatus,
          }).text
        }
      >
        {isAddingNew && (
          <NavBar
            isExist
            text="Вернуться к работе"
            onClick={() => handleChangeIsAddingNew(false)}
            className={styles.back}
          />
        )}
        {isAddingNew && (
          <ProductIntervalCardContent
            planId={data?.id}
            objectId={objectId}
            handleClose={() => handleChangeIsAddingNew(false)}
            isOpen={isOpen}
            defaultWorkGroupId={data?.group?.id}
            directlySection={data?.group?.section_id}
            directlyWork={data?.group?.id}
            onAddToJournalCallback={onAddToJournalCallback}
          />
        )}
      </ModalContainer>
    );
  }

  if (isEditingPlan) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={data?.group?.name} /* @ts-ignore */
        statusColor={
          manufacturingModalStatusSwitcher({
            isPlan: activeModule === "plans" /* @ts-ignore */,
            status: modalStatus,
          }).color
        }
        statusText={
          manufacturingModalStatusSwitcher({
            isPlan: activeModule === "plans" /* @ts-ignore */,
            status: modalStatus,
          }).text
        }
      >
        <PlanEditing /* @ts-ignore */
          planCreator={data.author?.name}
          planCreatingDate={data?.created_at} /* @ts-ignore */
          productId={data?.group?.id} /* @ts-ignore */
          buildingId={objectId} /* @ts-ignore */
          intervalId={intervalId} /* @ts-ignore */
          planCount={data?.count} /* @ts-ignore */
          planDateStart={data?.start_at} /* @ts-ignore */
          planDateEnd={data?.end_at} /* @ts-ignore */
          onClose={handleCloseEditingPlan} /* @ts-ignore */
          defaultMaterials={data?.materials} /* @ts-ignore */
          defaultMims={data?.services} /* @ts-ignore */
          defaultWorkers={data?.workers} /* @ts-ignore */
          isExpendituresGroup /* @ts-ignore */
          getUpdatedList={getGroupIntervalsListRequest}
          sectionId={sectionId} /* @ts-ignore */
          paymentDate={data?.payment_date}
          closeWholeModal={onClose}
          onEditCallback={onEditCallback} /* @ts-ignore */
          expemdituresIds={data?.group?.expenditures?.map((el) => el.expenditure_id)}
          measure={data?.group?.measure}
        />
      </ModalContainer>
    );
  }

  const isPlan = activeModule !== "facts";

  const isApprovePlanButton = isPlan && canApprovePlan;
  const isAddToJournalButton = isPlan && !isApprovePlanButton && modalStatus !== "planed";

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={closeHandler}
      name={data?.group?.name}
      isHideStatus={!intervalId} /* @ts-ignore */
      statusColor={
        manufacturingModalStatusSwitcher({
          isPlan: activeModule === "plans" /* @ts-ignore */,
          status: modalStatus,
        }).color
      }
      statusText={
        manufacturingModalStatusSwitcher({
          isPlan: activeModule === "plans" /* @ts-ignore */,
          status: modalStatus,
        }).text
      }
    >
      {isLoading && <Spinner />}
      {!intervalId && expendituresOnCurrentWeek?.length > 1 && (
        <div className={styles.list}>
          {Array.isArray(expendituresOnCurrentWeek) &&
            expendituresOnCurrentWeek.map((el) => (
              <WorkListItem /* @ts-ignore */
                key={el.id}
                name={data?.group?.name}
                measure={data?.group?.measure}
                passAmount={data?.group?.amount} /* @ts-ignore */
                passCount={el.count}
                passDate={el?.created_at}
                passName={data?.author?.name}
                acceptAmount={data?.confirmed_amount}
                acceptCount={data?.count}
                acceptDate={el?.confirmed_at}
                acceptName={data?.confirmed_user?.name}
                hideAccepted={!data?.is_confirmed} /* @ts-ignore */
                onClick={() => handleChangeIntervalId(el.id)}
              />
            ))}
        </div>
      )}
      {intervalId && !isLoading && data && (
        <>
          {isBackButtonExists && <NavBar onClick={handleBackButtonClick} className={styles.back} isExist />}
          {showRelationsTab && hasRelations /* @ts-ignore */ && (
            <TabBarNotLinks tabs={PLAN_TABS} activeId={tab} onClick={setTab} className={styles.relationsTabs} />
          )}
          {activeModule === "plans" && tab === MAIN_PLAN_TAB && (
            <ManufacturingModalContentPlan
              planCreator={data.author?.name}
              planCreatingDate={data.created_at}
              measure={data.group?.measure}
              dateStart={data.start_at}
              dateEnd={data.end_at} /* @ts-ignore */
              projectAmount={data.amount}
              projectFullAmount={data.group?.amount}
              projectFullCount={data.group?.count}
              planCount={data.count}
              planIsInWork={!data.is_confirmed}
              approvals={data?.approvals}
              closureDate={data?.payment_date}
            />
          )}
          {activeModule === "facts" && (
            <ManufacturingModalContentFact
              dateStart={data?.start_at}
              dateEnd={data?.end_at}
              measure={data.group?.measure}
              planApprover={data?.plan?.confirmed_user?.name}
              planApprovingDate={data?.plan?.confirmed_at}
              planCount={data?.plan?.count}
              planCreatingDate={data?.plan?.created_at}
              planCreator={data?.plan?.author?.name}
              projectAmount={data?.plan?.amount}
              passingAuthor={data.author?.name}
              passingDate={data?.created_at}
              passingCount={data?.count}
              investedAmount={data?.amount}
              acceptedDate={data.confirmed_at}
              acceptedAmount={+data.confirmed_amount}
              acceptedAuthor={data?.confirmed_user?.name}
              acceptedCount={data.confirmed_count}
              remarks={data?.remarks ?? []}
              ticketRemarks={data?.ticketRemarks ?? []}
            />
          )}
          {tab === MAIN_PLAN_TAB && (
            <>
              <div className={styles.info}>
                <p className={styles.subtitle}>Раздел:</p>
                <p className={styles.value}>{sectionName}</p>
              </div>
              <WorkersList workers={data?.workers} />
              {data?.expenditures?.length ? (
                <Expandable title={`Расценки: ${data?.expenditures?.length}`} className={styles.subtitle}>
                  {data?.expenditures?.map((el) => (
                    <MaterialBlockInWork
                      key={el.id}
                      name={el.name}
                      measure={el.measure}
                      count={el.count}
                      amount={+el.amount}
                      statusText={activeModule === "plans" ? "План" : undefined}
                      statusColor={activeModule === "plans" ? "blue" : undefined}
                    />
                  ))}
                </Expandable>
              ) : null}
              {materialsCount ? (
                <Expandable title={`Материалы: ${materialsCount}`} className={styles.subtitle}>
                  {
                    /* @ts-ignore */
                    simpleMaterials.map((el) => (
                      <SimpleResourceBlock canInteract={false} name={el.name} count={el.count} measure={el.measure} />
                    ))
                  }
                  {data?.materials?.map((el) => (
                    <MaterialBlockInWork
                      key={el.id}
                      name={el.name}
                      measure={el.measure}
                      count={el.count}
                      amount={+el.amount}
                      statusText={activeModule === "plans" ? "План" : undefined}
                      statusColor={activeModule === "plans" ? "blue" : undefined}
                    />
                  ))}
                </Expandable>
              ) : null}
              {mimsCount ? (
                <Expandable title={`Машины и Механизмы: ${mimsCount}`} className={styles.subtitle}>
                  {
                    /* @ts-ignore */
                    simpleMims.map((el) => (
                      <SimpleResourceBlock canInteract={false} name={el.name} count={el.count} measure={el.measure} />
                    ))
                  }
                  {data?.services?.map((el) => (
                    <MaterialBlockInWork
                      key={el.id}
                      name={el.name}
                      measure={el.measure}
                      count={el.count}
                      amount={+el.amount}
                      statusText={activeModule === "plans" ? "План" : undefined}
                      statusColor={activeModule === "plans" ? "blue" : undefined}
                    />
                  ))}
                </Expandable>
              ) : null}
              <DisplayAxesAndMarks
                id={data?.id}
                planOrFact={activeModule !== "plans" ? "fact" : "plan"}
                workOrGroup="group"
              />
              {isPlan && <PlanEditingHistory objectId={objectId} ticketId={data?.id} isOpen={isOpen} isGroup />}
              <BottomControls
                isExists={
                  isPlan && (isAddToJournalButton || (canEditPlan && hasEditPlanPermission) || isApprovePlanButton)
                }
                isDoubleBtns
              >
                {canEditPlan && hasEditPlanPermission && (
                  <ButtonBase
                    className={cn((isAddToJournalButton || isApprovePlanButton) && styles.editButton)}
                    secondary
                    onClick={handleOpenEditingPlan}
                  >
                    Редактировать
                  </ButtonBase>
                )}
                {isAddToJournalButton && (
                  <ButtonBase primary onClick={() => handleChangeIsAddingNew(true)}>
                    Добавить запись в ЖУ
                  </ButtonBase>
                )}

                {isApprovePlanButton && (
                  <ButtonBase primary onClick={approveHandler}>
                    Согласовать
                  </ButtonBase>
                )}
              </BottomControls>
            </>
          )}
          {activeModule === "plans" && tab === RELATIONS_PLAN_TAB && (
            <IntervalRelationsContent
              hasEditPermission={hasEditRelationsPermission}
              intervalId={intervalId}
              projectId={+objectId}
              relationsFromCurrentInterval={relationsFromCurrentInterval}
              relationsToCurrentInterval={relationsToCurrentInterval}
              intervalStartAt={date_start}
              intervalEndAt={date_end}
              isEditRelationsByDefault={false}
              isIntervalGroup={true}
            />
          )}
        </>
      )}
    </ModalContainer>
  );
};

export default React.memo(IntervalGroupModal);
