import cn from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { aookInvalidateKeySelector } from "redux/modules/common/building/journal/aook/selectors";
import { aosrInvalidateKeySelector } from "redux/modules/common/building/journal/aosr/selectors";
import {
  invalidateKeyInJournalExecSelector,
  journalFulfillmentGroupsSelector,
  journalFulfillmentMaterialsInvalidateKeySelector,
} from "redux/modules/common/building/journal/journalExecution/selectors";
import { getJournalFulfillmentGroupInfo } from "redux/modules/common/building/journal/journalExecution/thunksV2";
import {
  IExpendituresInJournalFulfillment,
  IJournalFulFillmentGroup,
} from "redux/modules/common/building/journal/journalExecution/types";
import { generateJornalTicketSectionKey } from "redux/modules/common/building/journal/utils";

import GroupTabs, { GroupTabsEnum } from "../../../JournalExecTicket/components/GroupTabs/GroupTabs";
import RelatedMaterial from "../../../JournalExecTicket/components/RelatedMaterial/RelatedMaterial";
import RelatedMims from "../../../JournalExecTicket/components/RelatedMims/RelatedMims";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { useCompletedGroupTicket } from "components/pages/Journal/components/hooks/useCompletedGroupTicket";

import JournalExecRow from "../JournalExecRow/JournalExecRow";
import { groupTicketAdapter } from "./groupTicketAdapter";

import { LOCALIZATION_CONFIG } from "constants/localization";
import {
  IExpenditureDetailInJournalExec,
  IGroupMaterialDetailInJournalExec,
  IGroupServiceDetailInJournalExec,
} from "types/interfaces/Groups";

import styles from "./JournalExecRowContent.module.scss";

interface IProps {
  ticket: IExpendituresInJournalFulfillment["items"][0];
  year: number;
  month: number;
  sectionId: number;
}

const JournalExecRowGroupContent: React.FC<IProps> = ({ ticket, year, month, sectionId }) => {
  const dispatch = useDispatch();

  const objectId = useObjectId();
  const group = ticket?.group;
  const expenditure = ticket?.expenditure;
  const key = generateJornalTicketSectionKey(objectId, year, month, group?.group_id);
  const materialsInvalidateKey = useSelector(journalFulfillmentMaterialsInvalidateKeySelector);
  const journalInvalidateKey = useSelector(invalidateKeyInJournalExecSelector);
  const aosrInvalidateKey = useSelector(aosrInvalidateKeySelector);
  const aookInvalidateKey = useSelector(aookInvalidateKeySelector);

  React.useLayoutEffect(() => {
    if (!group?.group_id) return;
    dispatch(getJournalFulfillmentGroupInfo(objectId, group?.group_id, year, month));
  }, [
    objectId,
    year,
    month,
    group?.group_id,
    materialsInvalidateKey,
    journalInvalidateKey,
    aosrInvalidateKey,
    aookInvalidateKey,
  ]);

  const groupContent = objectId ? useSelector(journalFulfillmentGroupsSelector)[key] : null;

  const tabsDisplaying = React.useMemo(
    () => ({
      [GroupTabsEnum.expenditures]: groupContent?.expenditures?.length && groupContent?.expenditures?.length > 0,
      [GroupTabsEnum.materials]: groupContent?.materials?.length && groupContent?.materials?.length > 0,
      [GroupTabsEnum.mims]: groupContent?.services?.length && groupContent?.services?.length > 0,
    }),
    [groupContent]
  );

  const [activeGroupTabId, setActiveGroupTabId] = React.useState<GroupTabsEnum | null>(null);

  React.useEffect(() => {
    if (!groupContent) return;
    if (activeGroupTabId) return;
    setActiveGroupTabId(
      tabsDisplaying[GroupTabsEnum.expenditures]
        ? GroupTabsEnum.expenditures
        : tabsDisplaying[GroupTabsEnum.materials]
        ? GroupTabsEnum.materials
        : GroupTabsEnum.mims
    );
  }, [groupContent]);

  return (
    <div className={styles.content}>
      <GroupTabs
        activeGroupTabId={activeGroupTabId!}
        setActiveGroupTabId={setActiveGroupTabId as any}
        tabsDisplaying={tabsDisplaying as any}
      />
      {!!groupContent?.expenditures?.length &&
        activeGroupTabId === GroupTabsEnum.expenditures &&
        groupContent?.expenditures.map((item) => (
          <JournalExecRow
            ticket={{ expenditure: item } as any}
            sectionId={sectionId}
            month={month}
            year={year}
            isExpenditureInGroup
            isHideRemarks
            stockId={-1}
            groupId={ticket?.group?.group_id}
          />
        ))}

      {!!groupContent?.materials?.length && activeGroupTabId === GroupTabsEnum.materials && (
        <>
          <div className={styles.headerMaterial}>
            <div className={cn(styles.headerItem, styles.name)}>Наименование материала</div>
            <div className={cn(styles.headerItem, styles.measure)}>Ед.изм.</div>
            <div className={cn(styles.headerItem, styles.centeredContent)}>К сдаче</div>
            <div className={cn(styles.headerItem, styles.centeredContent)}>Принято</div>
            <div className={cn(styles.headerItem, styles.centeredContent)}>ФИО</div>
            <div className={cn(styles.headerItem, styles.checkBlock, styles.centeredContent)}>
              Стоимость, {LOCALIZATION_CONFIG.currency}
            </div>
          </div>

          {groupContent?.materials?.map((material, i) => (
            <RelatedMaterial
              key={material.name + i}
              materialData={material}
              objectId={objectId}
              expenditureId={expenditure?.expenditure_id!}
              isGroup
            />
          ))}
        </>
      )}
      {!!groupContent?.services?.length && activeGroupTabId === GroupTabsEnum.mims && (
        <>
          <div className={styles.headerMaterial}>
            <div className={cn(styles.headerItem, styles.name)}>Машины и Механизмы</div>
            <div className={cn(styles.headerItem, styles.measure)}>Ед.изм.</div>
            <div className={cn(styles.headerItem, styles.centeredContent)}>К сдаче</div>
            <div className={cn(styles.headerItem, styles.centeredContent)} />
            <div className={cn(styles.headerItem, styles.centeredContent)} />
            <div className={cn(styles.headerItem, styles.checkBlock, styles.centeredContent)}>
              Стоимость, {LOCALIZATION_CONFIG.currency}
            </div>
          </div>
          {groupContent?.services?.map((mim, i) => (
            <RelatedMims key={mim.name + i} data={mim} />
          ))}
        </>
      )}
    </div>
  );
};

export default JournalExecRowGroupContent;
