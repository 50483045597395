import React from "react";

const EstimateIcon = () => (
  <svg id="Smeta_Popup" xmlns="http://www.w3.org/2000/svg" width="36.756" height="37.819" viewBox="0 0 36.756 37.819">
    <g id="Сгруппировать_7365" data-name="Сгруппировать 7365">
      <path
        id="Контур_5937"
        data-name="Контур 5937"
        d="M155.233,40.454a.738.738,0,1,0-.285.905A.743.743,0,0,0,155.233,40.454Z"
        transform="translate(-142.983 -37.044)"
      />
      <path
        id="Контур_5938"
        data-name="Контур 5938"
        d="M40.8,17.631H30.335V6.188A2.14,2.14,0,0,0,28.2,4.05H23.5a4.789,4.789,0,0,0-9.463,0h-4.7A2.14,2.14,0,0,0,7.2,6.188V30.221a2.14,2.14,0,0,0,2.138,2.138h14.43v2.3a3.161,3.161,0,0,0,3.157,3.157H40.8a3.161,3.161,0,0,0,3.157-3.157V20.788A3.161,3.161,0,0,0,40.8,17.631Zm1.68,3.157v6.2H34.6V19.109h6.2a1.681,1.681,0,0,1,1.68,1.68Zm-9.353,6.2h-7.88v-6.2a1.681,1.681,0,0,1,1.679-1.679h6.2v7.881ZM12.887,5.528h1.83a.746.746,0,0,0,.739-.739,3.311,3.311,0,1,1,6.622,0,.746.746,0,0,0,.739.739h1.83V7.585H12.887ZM9.337,30.882a.661.661,0,0,1-.66-.66V6.188a.661.661,0,0,1,.66-.66H11.41v2.8a.739.739,0,0,0,.739.739H25.385a.739.739,0,0,0,.739-.739v-2.8H28.2a.661.661,0,0,1,.66.66V17.631H26.924a3.161,3.161,0,0,0-3.157,3.157V30.882Zm15.907,3.781V31.628s0,0,0-.007V28.467h7.88v7.875h-6.2A1.682,1.682,0,0,1,25.244,34.662Zm17.233,0a1.682,1.682,0,0,1-1.68,1.68H34.6V28.467h7.875v6.2Z"
        transform="translate(-7.199)"
      />
      <path
        id="Контур_5939"
        data-name="Контур 5939"
        d="M274.886,280a.739.739,0,0,0-.739.739v.873h-.873a.739.739,0,0,0,0,1.477h.873v.873a.739.739,0,1,0,1.477,0v-.873h.873a.739.739,0,1,0,0-1.477h-.873v-.873A.739.739,0,0,0,274.886,280Z"
        transform="translate(-252.937 -259.32)"
      />
      <path
        id="Контур_5940"
        data-name="Контур 5940"
        d="M276.5,428.869h-3.224a.739.739,0,0,0,0,1.477H276.5a.739.739,0,1,0,0-1.477Z"
        transform="translate(-252.938 -397.19)"
      />
      <path
        id="Контур_5941"
        data-name="Контур 5941"
        d="M402.876,411.414h2.56a.739.739,0,0,0,0-1.477h-2.56a.739.739,0,0,0,0,1.477Z"
        transform="translate(-372.964 -379.657)"
      />
      <path
        id="Контур_5942"
        data-name="Контур 5942"
        d="M402.876,448.414h2.56a.739.739,0,0,0,0-1.477h-2.56a.739.739,0,0,0,0,1.477Z"
        transform="translate(-372.964 -413.924)"
      />
      <path
        id="Контур_5943"
        data-name="Контур 5943"
        d="M404.251,289.934a.739.739,0,0,0,1.045,0l.617-.617.617.617a.739.739,0,1,0,1.045-1.045l-.617-.617.617-.617a.739.739,0,1,0-1.045-1.045l-.617.617-.617-.617a.739.739,0,1,0-1.045,1.045l.617.617-.617.617A.739.739,0,0,0,404.251,289.934Z"
        transform="translate(-374.722 -265.239)"
      />
      <path
        id="Контур_5944"
        data-name="Контур 5944"
        d="M64.946,164.7h4.441a.739.739,0,0,0,.739-.739v-4.441a.739.739,0,0,0-.739-.739H64.946a.739.739,0,0,0-.739.739v4.441A.739.739,0,0,0,64.946,164.7Zm.739-4.441h2.963v2.963H65.684Z"
        transform="translate(-59.996 -147.05)"
      />
      <path
        id="Контур_5945"
        data-name="Контур 5945"
        d="M174.793,160.255h4.74a.739.739,0,1,0,0-1.477h-4.74a.739.739,0,0,0,0,1.477Z"
        transform="translate(-161.729 -147.05)"
      />
      <path
        id="Контур_5946"
        data-name="Контур 5946"
        d="M178.641,219.635a.739.739,0,0,0-.739-.739h-3.11a.739.739,0,0,0,0,1.477h3.11A.739.739,0,0,0,178.641,219.635Z"
        transform="translate(-161.729 -202.727)"
      />
      <path
        id="Контур_5947"
        data-name="Контур 5947"
        d="M64.946,283.093h9.018a.739.739,0,1,0,0-1.477H64.946a.739.739,0,1,0,0,1.477Z"
        transform="translate(-59.996 -260.814)"
      />
      <path
        id="Контур_5948"
        data-name="Контур 5948"
        d="M70.593,336.327H64.946a.739.739,0,0,0,0,1.477h5.648a.739.739,0,1,0,0-1.477Z"
        transform="translate(-59.996 -311.484)"
      />
      <path
        id="Контур_5949"
        data-name="Контур 5949"
        d="M187.718,336.94a.738.738,0,1,0-.3.916A.744.744,0,0,0,187.718,336.94Z"
        transform="translate(-173.068 -311.638)"
      />
    </g>
  </svg>
);

export default React.memo(EstimateIcon);
