import React from "react";

import styles from "./Placeholder.module.scss";

interface IProps {
  children: React.ReactNode;
}

const Placeholder: React.FC<IProps> = ({ children }) => {
  return <div className={styles.placeholder}>{children}</div>;
};

export default Placeholder;
