import { Spin, message } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";

import { confirmRegistration } from "redux/modules/_TODO/auth";

class RegisterConfirm extends Component {
  async componentDidMount() {
    await this.props.confirmRegistration(this.props.match.params.token);
    if (this.props.registrationConfirm) {
      if (this.props.registrationConfirm.status === 200) {
        message.success("Аккаунт успешно активирован", 5);
        this.props.history.replace("/");
      }
    }
  }

  render() {
    return (
      <div className="registration-confirm">
        <div className="registration-confirm_content">
          <h5>Подтверждение регистрации</h5>
          <Spin size="large" />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    registrationConfirm: state.auth.registrationConfirm,
  }),
  {
    confirmRegistration,
  }
)(RegisterConfirm);
