import { useCallback, useMemo } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import {
  activeSectionSelector,
  activeSubSectionSelector,
  estimateStateIdSelector,
} from "../../Handler/ui/SimpleHandler/model/selectors";
import { simpleHandlerActions } from "../../Handler/ui/SimpleHandler/model/slice";

import { EEstimateStatesIds } from "../../Handler/ui/ProHandler/enums";

import { useQueryParams } from "../../../utils/hooks/useQueryParams";

export const useApproveFormBackHandler = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { buildingId, projectId, userId } = useParams<{ userId: string; buildingId: string; projectId: string }>();

  const activeSection = useSelector(activeSectionSelector);
  const activeSubSection = useSelector(activeSubSectionSelector);
  const estimateStateId = useSelector(estimateStateIdSelector);

  const status = useQueryParams("status");
  const token = useQueryParams("token");
  const state = useQueryParams("state");

  const backTitle = useMemo(() => {
    if (activeSubSection) {
      return activeSubSection.name;
    }

    if (activeSection) {
      return activeSection.name;
    }

    return "";
  }, [activeSection, activeSubSection]);

  const isVisible = backTitle !== "";

  const onBack = useCallback(() => {
    if (!buildingId || !projectId) return;

    if (activeSubSection && activeSection) {
      // если находимся на уровне расценок
      batch(() => {
        dispatch(simpleHandlerActions.setActiveSubSection(null));
        dispatch(simpleHandlerActions.setExpenditures(null));
        dispatch(simpleHandlerActions.clearCheckedItems());
        dispatch(simpleHandlerActions.clearEditingExpenditures());
        dispatch(simpleHandlerActions.clearAddingExpenditures());
      });
      if (estimateStateId === EEstimateStatesIds.LOCALE && status) {
        // В локальном сметном расчете важно сохранить статус
        // при переходе назад на уровень подразделов
        const url = `/simplified/approve_estimate/${userId}/${projectId}/${buildingId}/${activeSection.id}`;
        const query = new URLSearchParams();
        query.set("status", status);
        if (state) query.set("state", state);
        if (token) query.set("token", token);

        history.push(`${url}?${query.toString()}`);
      } else {
        const url = `/simplified/approve_estimate/${userId}/${projectId}/${buildingId}/${activeSection.id}`;
        const query = new URLSearchParams();

        if (state) query.set("state", state);
        if (token) query.set("token", token);

        const queryString = query.toString();

        history.push(`${url}${queryString.length ? `?${queryString}` : ""}`);
      }
    } else if (activeSection) {
      // если находимся на уровне подразделов
      batch(() => {
        dispatch(simpleHandlerActions.setActiveSection(null));
        dispatch(simpleHandlerActions.setSubSections(null));
        dispatch(simpleHandlerActions.clearCheckedItems());
      });

      const url = `/simplified/approve_estimate/${userId}/${projectId}/${buildingId}`;
      const query = new URLSearchParams();

      if (state) query.set("state", state);
      if (token) query.set("token", token);

      const queryString = query.toString();

      history.push(`${url}${queryString.length ? `?${queryString}` : ""}`);
    }
  }, [projectId, buildingId, activeSection, activeSubSection, estimateStateId, status, userId, token, state]);

  return {
    backTitle,
    onBack,
    isVisible,
  };
};
