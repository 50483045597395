import moment from "moment";

import { IBetonExpenseDetail } from "redux/modules/common/building/beton/types";

export const getInitialValuesBetonExpense = (d: IBetonExpenseDetail | null) => {
  if (!d) return undefined;
  return {
    beton_env_t: d.beton_env_t,
    beton_t: d.beton_t,
    beton_method: d.beton_method,
    time: d.beton_date ? moment(d.beton_date).format("HH:mm") : "",
    date: d.beton_date ? moment(d.beton_date, "YYYY-MM-DD") : "",
  };
};

export const getDateForBetonFilter = (year: number, month: number, fieldNamePrefix: string) => {
  const start = moment().set("year", year).set("month", month).startOf("month");

  return {
    [`${fieldNamePrefix}_after`]: start.format("YYYY-MM-DD"),
    [`${fieldNamePrefix}_before`]: start.add(1, "month").format("YYYY-MM-DD"),
  };
};
