import Sites10Svg from "./img/sites10.png";
import SitesMob10Svg from "./img/sites-mob10.png";


export const FormationDocumentationSection = () => (
  <section className="formation-documentation sites-block-silver" id="documentation-formation">
    <div className="container">
      <h2>Формирование документации</h2>
      <div className="sites-block__flex">
        <div className="sites-block__photo">
          <img src={Sites10Svg} className="sites-pic-dec" alt="image" />
          <img src={SitesMob10Svg} className="sites-pic-mob" alt="image" />
        </div>
        <div className="sites-block__info">
          <div className="sites-block__text">
            <ul>
              <li>Вы можете автоматически сформировать документы и закрыть работы по перечню расценок,
                указанных в контракте
              </li>
              <li>Вы можете закрыть работы по сметной или фактической стоимости
              </li>
            </ul>
          </div>
          <div className="sites-block__hert">
            <ul>
              <li>Формирование и хранение комплекта документов по каждому виду работ</li>
              <li>Автоматическое формирование документов на основе сметных расценок</li>
              <li>Формирование актов для заказчика на основе актов от подрядчика</li>
              <li>Поиск документов в реестре</li>
            </ul>
          </div>
        </div>
      </div>
      <span className="title-bot">Формируйте документацию вовремя, на основе проверенных данных</span>
    </div>
  </section>
)