import cn from "classnames";
import React from "react";

import { REMARK_STATUSES } from "../../../../../../../redux/modules/common/building/journal/journalExecution/types";

import CheckFillRound from "../../../../../../../images/icons/CheckFillRound";
import StatusCanceledIcon from "../../../../../../../images/icons/StatusCanceledIcon";

import styles from "./RemarkCounts.module.scss";

export interface IRemarkCountsProps {
  total: number;
  created: number;
  accepted?: number;
  review?: number;
  className?: string;
}

const RemarkCounts: React.FC<IRemarkCountsProps> = ({ total, created, accepted, review, className }) => {
  return (
    <div className={cn(styles.container, className)}>
      {!!accepted && (
        <div className={cn(styles.accepted, { [styles.withDivider]: !!review || !!created })}>
          {REMARK_STATUSES.accepted.toLowerCase()}
          <CheckFillRound colorFill={"#BC7EF0"} color={"white"} />
          <span>{accepted}</span>
        </div>
      )}
      {!!review && (
        <div className={cn(styles.review, { [styles.withDivider]: !!created })}>
          {REMARK_STATUSES.review.toLowerCase()} <div className={styles.markerReview} /> <span>{review}</span>
        </div>
      )}
      {!!created && (
        <div>
          {REMARK_STATUSES.created.toLowerCase()}
          <StatusCanceledIcon fill={"#EF8932"} stroke={"white"} />
          <span>
            {created} из {total}
          </span>
        </div>
      )}
    </div>
  );
};

export default React.memo(RemarkCounts);
