import {
  ADD_MORE_SERVICE_ACT_FILES,
  CUT_OFF_SERVICE_ACT_FILE,
  LOAD_MORE_SERVICE_ACTS_LIST,
  SET_IS_ACCEPTING_SERVICE_ACT,
  SET_IS_SERVICE_ACT_NOT_FOUND,
  SET_SERVICE_ACT,
  SET_SERVICE_ACTS_LIST,
  SET_SERVICE_ACTS_LIST_LOADING,
  SET_SERVICE_ACT_FILES,
  SET_SERVICE_ACT_LOADING,
} from "./actions";

import { IServiceActsInitialState } from "./types";

export const serviceActsInitialState: IServiceActsInitialState = {
  serviceActsList: null,
  serviceAct: null,
  isActLoading: false,
  isActNotFound: false,
  isActsListLoading: false,
  isAcceptingAct: false,
  files: [],
};

export default (state = serviceActsInitialState, action: any): IServiceActsInitialState => {
  const { type, payload } = action;
  switch (type) {
    case SET_SERVICE_ACTS_LIST:
      return {
        ...state,
        serviceActsList: payload,
      };
    case LOAD_MORE_SERVICE_ACTS_LIST:
      return {
        ...state,
        serviceActsList: {
          ...payload,
          results: (state.serviceActsList?.results || []).concat(payload.results),
        },
      };
    case SET_SERVICE_ACT:
      return {
        ...state,
        serviceAct: payload,
      };
    case SET_SERVICE_ACTS_LIST_LOADING:
      return {
        ...state,
        isActsListLoading: payload,
      };
    case SET_SERVICE_ACT_LOADING:
      return {
        ...state,
        isActLoading: payload,
      };
    case SET_IS_ACCEPTING_SERVICE_ACT:
      return {
        ...state,
        isAcceptingAct: payload,
      };
    case SET_IS_SERVICE_ACT_NOT_FOUND:
      return {
        ...state,
        isActNotFound: payload,
      };
    case SET_SERVICE_ACT_FILES:
      return {
        ...state,
        files: payload,
      };
    case ADD_MORE_SERVICE_ACT_FILES:
      return {
        ...state,
        files: [...payload, ...state.files],
      };
    case CUT_OFF_SERVICE_ACT_FILE:
      return {
        ...state,
        files: state.files.filter((el) => el.id !== payload),
      };
    default:
      return state;
  }
};
