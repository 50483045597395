import React, { FC } from "react";

import Select, { ISelectProps } from "components/UI/atoms/Select";

export const MEASURES = [
  { name: "шт", id: "шт" },
  { name: "кг", id: "кг" },
  { name: "м", id: "м" },
  { name: "м2", id: "м2" },
  { name: "м3", id: "м3" },
  { name: "л", id: "л" },
  { name: "мп", id: "мп" },
  { name: "км", id: "км" },
  { name: "уп", id: "уп" },
  { name: "т", id: "т" },
  { name: "компл", id: "компл" },
];

export const MEASURES_SERVICES = [
  { name: "чел.час", id: "чел.час" },
  { name: "маш.час", id: "маш.час" },
];

export const ALL_MEASURES = [...MEASURES, ...MEASURES_SERVICES];

interface IProps extends Omit<ISelectProps, "options"> {
  useAllMeasures?: boolean;
  isServices?: boolean;
}

const MeasureSelect: FC<IProps> = ({ useAllMeasures, isServices, ...props }) => (
  <Select
    placeholder={"-"}
    options={useAllMeasures ? ALL_MEASURES : isServices ? MEASURES_SERVICES : MEASURES}
    {...props}
  />
);

export default React.memo(MeasureSelect);
