import React from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";

import { _TEMP_assignSignerManually, createSignator } from "../../../model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";
import ButtonBase from "shared/ui/controls/ButtonBase";
import InputBase from "shared/ui/inputs/InputBase";

import { ICreateNewSigner, SignerTypes } from "../../../model/types";

import { composeFieldValidators, emailVal, maxLength, minLength, required } from "utils/formHelpers/validations";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./CreateSignatorForm.module.scss";

interface IProps {
  onCancel: () => void;
  type: SignerTypes;
  objectId: string;
}

const CreateSignatorForm: React.FC<IProps> = ({ onCancel, type, objectId }) => {
  const dispatch = useDispatch();
  const { isLoading } = useTypedSelector((state) => state.signatorsSlice);
  const key = generateStorageKey({ type: "assignManually" });
  const showLoader = isLoading[key];

  return (
    <Form<ICreateNewSigner>
      onSubmit={(v) => {
        dispatch(_TEMP_assignSignerManually(v, objectId, type));
      }}
      render={({ handleSubmit, hasValidationErrors }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            handleSubmit(e);
          }}
        >
          <div className={styles.form}>
            <Field
              name="organization"
              component={InputBase}
              validate={composeFieldValidators(required(), minLength(1), maxLength(255))}
              label="Организация"
              placeholder="Введите"
              className={styles.cols2}
            />
            <Field
              name="last_name"
              component={InputBase}
              validate={composeFieldValidators(required(), minLength(1), maxLength(255))}
              label="Фамилия"
              placeholder="Введите"
            />
            <Field
              name="first_name"
              component={InputBase}
              validate={composeFieldValidators(required(), minLength(1), maxLength(255))}
              label="Имя"
              placeholder="Введите"
            />
            <Field
              name="middle_name"
              component={InputBase}
              validate={composeFieldValidators(maxLength(255))}
              label="Отчество"
              placeholder="Введите"
            />
            <Field
              name="email"
              component={InputBase}
              validate={composeFieldValidators(required(), minLength(1), maxLength(255), emailVal())}
              label="Email"
              placeholder="Введите"
            />
            <Field
              name="position"
              component={InputBase}
              validate={composeFieldValidators(maxLength(255))}
              label="Должность"
              placeholder="Введите"
              className={styles.cols2}
            />
          </div>
          <BottomControls isExists isDoubleBtns>
            <ButtonBase secondary type="reset" onClick={onCancel}>
              Отменить
            </ButtonBase>
            <ButtonBase isLoading={showLoader} primary type="submit" disabled={hasValidationErrors}>
              Сохранить
            </ButtonBase>
          </BottomControls>
        </form>
      )}
    />
  );
};

export default CreateSignatorForm;
