import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { journalExecutionActions } from "redux/modules/common/building/journal/journalExecution/actions";
import { IJournalFulFillmentGroup } from "redux/modules/common/building/journal/journalExecution/types";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { VIEW_MANUFACTURING_JOURNAL_PROGRESS_APPROVE } from "../../../../../constants/permissions/manufacturingPermissions";
import { IStockUsingMaterial } from "../../../../../types/interfaces/Materials";
import { IGroupMaterialDetailInJournalExec } from "types/interfaces/Groups";

import usePermission from "../../../../../hooks/usePermission";

import { errorCatcher } from "utils/helpers/errorCatcher";

export interface IUseApproveTicketMaterialProps {
  expenditureId: number;
  materialData: IJournalFulFillmentGroup["materials"][0];
}

export const useApproveTicketMaterial = ({ expenditureId, materialData }: IUseApproveTicketMaterialProps) => {
  const objectId = useObjectId();
  const dispatch = useDispatch();
  const canApproveMaterial = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_APPROVE);

  const [toApproveCount, setToApproveCount] = useState(materialData?.confirmed_count || 0);

  const toApproveCountHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToApproveCount(event.target.value);
  };

  const handleApprove = async () => {
    axios
      .post(`/journal/${objectId}/fulfillment/materials/confirm/`, {
        confirmed_count: toApproveCount,
        type: "expenditure",
        expenditure_material: materialData?.interval_id,
      })
      .then(() => {
        setToApproveCount(materialData?.confirmed_count ? materialData?.confirmed_count : 0);
        dispatch(journalExecutionActions.changeMaterialsInvalidateKey());
      })
      .catch(errorCatcher);
  };

  const handleGroupApprove = async (count?: number, confirmedType = "estimate", close?: () => void) => {
    axios
      .post(`/journal/${objectId}/fulfillment/materials/confirm/`, {
        confirmed_count: count || toApproveCount,
        type: "group",
        group_material: materialData?.interval_id,
      })
      .then(() => {
        setToApproveCount(materialData?.confirmed_count ? materialData?.confirmed_count : 0);
        close?.();
        dispatch(journalExecutionActions.changeMaterialsInvalidateKey());
      })
      .catch(errorCatcher);
  };

  return {
    handleApprove,
    toApproveCountHandler,
    toApproveCount,
    canApproveMaterial,
    handleGroupApprove,
  };
};
