import cn from "classnames";
import React, { useCallback } from "react";

import ActionsButtons from "_LEGACY/UI/ActionsButtons/ActionsButtons";

import { VALUE_TYPES } from "../../../../../../../../../../shared/ui/inputs/InputBase";
import InputBase from "shared/ui/inputs/InputBase";

import { sliceTextByConstraint } from "../../../../../../../../../../utils/formatters/sliceTextByConstraint";
import { transformDigitToFinancial } from "../../../../../../../../../../utils/formatters/transformDigitToFinancial";

import styles from "./AcceptTicketItemRow.module.scss";

interface IProps {
  id: number;
  title: string;
  count: string;
  measure: string;
  amount: number;
  isWithBackground?: boolean;
  handleRemove?: (id: number, amount: number, isWasActive: boolean) => void;
  changeCountValue?: (countValue: string, id: number) => void;
  canChangeCount?: boolean;
  canRemove?: boolean;
  canCheck?: boolean;
  isChecked?: boolean;
  isHeading?: boolean;
  onCheck?: (id: number) => void;
  justification?: string;
  className?: string;
}

const AcceptTicketItemRow: React.FC<IProps> = ({
  id,
  title,
  count,
  measure,
  amount,
  isWithBackground,
  handleRemove,
  changeCountValue = () => {},
  canChangeCount,
  canRemove = false,
  canCheck = false,
  isChecked = false,
  isHeading = false,
  justification,
  onCheck,
  className,
}) => {
  const onConfirm = useCallback(() => {
    if (canCheck && onCheck) onCheck(id);
  }, [isChecked, id]);

  const onRemove = useCallback(() => {
    handleRemove?.(id, amount, isChecked);
  }, [handleRemove, isChecked, amount, id]);

  const handleCountChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      changeCountValue(event.target.value, id);
    },
    [amount, isChecked]
  );

  const hasActions = canRemove || canCheck;

  return (
    <div
      className={cn(
        styles.item,
        {
          [styles.filled]: isWithBackground,
          [styles.active]: isChecked && isWithBackground,
        },
        className
      )}
    >
      <div className={styles.titleContainer} title={title}>
        <span className={styles.title}>{title}</span>
        {!!justification && <div className={styles.justification}>{justification}</div>}
      </div>
      <div className={styles.count}>
        {canChangeCount ? (
          <InputBase
            valueType={VALUE_TYPES.NUMBER}
            value={count}
            onChange={handleCountChange}
            className={styles.countInput}
          />
        ) : (
          <span className={styles.textCount}>{count}</span>
        )}
        <span title={measure}>{measure}</span>
      </div>
      {!isHeading && !hasActions && <div></div>}
      <div className={cn({ [styles.centered]: !isHeading && !hasActions })}>
        {amount && transformDigitToFinancial(amount, { withFloat: true })}
      </div>
      {hasActions && (
        <div className={styles.centered}>
          <ActionsButtons
            checkClassName={cn({ [styles.buttonActive]: isChecked })}
            onConfirm={onConfirm}
            onRemove={onRemove}
            canRemove={canRemove}
            canCheck={canCheck}
          />
        </div>
      )}
    </div>
  );
};

export default AcceptTicketItemRow;
