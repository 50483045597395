import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { analyticsActions } from "../../../redux/modules/common/analytics/actions";

import AnalyticsBudget from "./components/AnalyticsBudget/AnalyticsBudget";
import AnalyticsBudgetResource from "./components/AnalyticsBudgetResource/AnalyticsBudgetResource";
import AnalyticsEvents from "./components/AnalyticsEvents/AnalyticsEvents";
import AnalyticsFulfillment from "./components/AnalyticsFulfillment/AnalyticsFulfillment";
import AnalyticsHeader from "./components/AnalyticsHeader/AnalyticsHeader";
import AnalyticsProgress from "./components/AnalyticsProgress/AnalyticsProgress";

import TemplateBase from "../../../features/templates/TemplateBase/TemplateBase";

import styles from "./Analytics.module.scss";

const Analytics: React.FC = () => {
  const dispatch = useDispatch();
  const islandsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      dispatch(analyticsActions.clearData());
    };
  }, []);

  return (
    <TemplateBase contentClassName={styles.content}>
      <AnalyticsHeader />
      <div className={styles.islands} ref={islandsRef}>
        <AnalyticsProgress islandParentRef={islandsRef} />
        <AnalyticsBudget islandParentRef={islandsRef} />
        <AnalyticsFulfillment islandParentRef={islandsRef} />
        <AnalyticsBudgetResource islandParentRef={islandsRef} />
        <AnalyticsEvents islandParentRef={islandsRef} />
      </div>
    </TemplateBase>
  );
};

export default Analytics;
