import React, { FC, useMemo } from "react";

import ProductIntervalCardContent from "../../../../../pages/Journal/components/JournalDelivery/components/ProductIntervalCard/ProductIntervalCardContent";
import {
  manufacturingMaterialsStatusSwitcher,
  manufacturingModalStatusSwitcher,
} from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/manufacturingModalStatusSwitcher";
import BottomControls from "../../../WorkOrMaterialsModals/components/BottomControls/BottomControls";
import MaterialBlockInWork from "../../../WorkOrMaterialsModals/components/MaterialsInWork/MaterialBlockInWork";
import ModalContainer from "../../../WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import NavBar from "../../../WorkOrMaterialsModals/components/NavBar/NavBar";
import WorkListItem from "../../../WorkOrMaterialsModals/components/WorkListItem/WorkListItem";
import WorkersList from "../../../WorkOrMaterialsModals/components/WorkersList/WorkersList";
import Expandable from "components/UI/atoms/_TODO/Expandable/Expandable";
import ManufacturingModalContentFact from "components/pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModalContentFact";

import { SimpleResourceBlock } from "../../../../../../features/simpleResources/ui/SimpleResourceBlock/SimpleResourceBlock";
import { Spinner } from "../../../../../../shared/ui/atoms/Spinner/Spinner";
import ExpandableFileInput from "../../../../../../shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";
import { useFactModalData } from "./useFactModalData";

import { ResourceTypeEnum } from "../../../../../../features/simpleResources/types/simpleResources";
import { IExpenditure } from "../../../../../../types/interfaces/Expenditure";

import { isNanChecker } from "../../../../../../utils/formatters/isNanChecker";
import { splitThousands } from "../../../../../../utils/formatters/splitThousands";

import styles from "./FactModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  expidentureId: number;
  expidentureObj: IExpenditure;
  sectionName: string;
  date_start: string;
  date_end: string;
  objectId: number;
  canDeleteFiles: boolean;
  loadProduct: () => void;
  sectionId: number;
  defaultOpenedIntervalId?: string | null;
}

const FactModal: FC<IProps> = ({
  isOpen,
  onClose,
  expidentureId,
  expidentureObj,
  sectionName,
  date_start,
  date_end,
  objectId,
  canDeleteFiles,
  defaultOpenedIntervalId = null,
}) => {
  const {
    fileRemoveHandler,
    fileUploadHandler,
    clearIntervalDataHandler,
    intervalClickHandler,
    backToIntervalsListHandler,
    list,
    intervalId,
    isLoading,
    data,
    files,
  } = useFactModalData({
    isOpen,
    buildingId: objectId,
    expenditureId: expidentureId,
    dateEnd: date_end,
    dateStart: date_start,
    defaultOpenedIntervalId,
  });

  const closeHandler = () => {
    onClose();
    clearIntervalDataHandler();
    setIsAddingNew(false);
  };

  const [isAddingNew, setIsAddingNew] = React.useState(false);

  const status = useMemo(
    () =>
      manufacturingModalStatusSwitcher({
        isPlan: false,
        status: data?.work_status,
      }),
    [data?.work_status]
  );

  const simpleMaterials = useMemo(() => {
    return (
      //@ts-ignore
      data?.simpleResources?.filter((r) => ![ResourceTypeEnum.transport, ResourceTypeEnum.machine].includes(r.type)) ??
      []
    ); //@ts-ignore
  }, [data?.simpleResources]);

  const simpleMims = useMemo(() => {
    return (
      //@ts-ignore
      data?.simpleResources?.filter((r) => [ResourceTypeEnum.transport, ResourceTypeEnum.machine].includes(r.type)) ??
      []
    ); //@ts-ignore
  }, [data?.simpleResources]);

  const materialsCount = useMemo(() => {
    //@ts-ignore
    return isNanChecker(+data?.materials?.length) + isNanChecker(simpleMaterials.length); //@ts-ignore
  }, [data?.materials, simpleMaterials]);

  const mimsCount = useMemo(() => {
    //@ts-ignore
    return isNanChecker(+data?.materials?.length) + isNanChecker(simpleMims.length); //@ts-ignore
  }, [data?.materials, simpleMaterials]);

  if (isAddingNew) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={expidentureObj?.name}
        justification={data?.justification} //@ts-ignore
        statusColor={status.color}
        statusText={status.text}
      >
        {isAddingNew && (
          <NavBar isExist text="Вернуться к работе" onClick={() => setIsAddingNew(false)} className={styles.back} />
        )}
        {isAddingNew && (
          <ProductIntervalCardContent
            objectId={objectId}
            handleClose={() => setIsAddingNew(false)}
            directlySection={data?.section_id ? +data.section_id : undefined}
            directlyWork={data?.expenditure_id}
            isOpen={isOpen}
          />
        )}
      </ModalContainer>
    );
  }

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={closeHandler}
      name={expidentureObj?.name}
      justification={data?.justification} //@ts-ignore
      statusColor={status.color}
      statusText={status.text}
    >
      {isLoading && <Spinner />}
      {!intervalId && (
        <div className={styles.list}>
          {Array.isArray(list) &&
            list.map((el) => (
              //@ts-ignore
              <WorkListItem
                key={el.id}
                name={el.expenditure_name}
                measure={el.measure} //@ts-ignore
                passAmount={el.completed_amount}
                passCount={el.count}
                passDate={el.created_at}
                passName={el.author} //@ts-ignore
                acceptAmount={el.accepted?.[0]?.amount} //@ts-ignore
                acceptCount={el.accepted?.[0]?.count} //@ts-ignore
                acceptDate={el.accepted?.[0]?.created_at} //@ts-ignore
                acceptName={el.accepted?.[0]?.approve_user} //@ts-ignore
                hideAccepted={!el.accepted?.length} //@ts-ignore
                onClick={() => intervalClickHandler(el.id)}
              />
            ))}
        </div>
      )}

      {intervalId && !isLoading && (
        <>
          {" "}
          {/* @ts-ignore */}
          <NavBar onClick={backToIntervalsListHandler} className={styles.back} isExist={list?.length > 1} />
          <ManufacturingModalContentFact /* @ts-ignore */
            dateStart={data?.start_at} /* @ts-ignore */
            dateEnd={data?.end_at} /* @ts-ignore */
            measure={data?.measure}
            passingAuthor={data?.author}
            passingDate={data?.created_at}
            passingCount={data?.count}
            investedAmount={data?.work_amount} //@ts-ignore
            acceptedAmount={data?.accepted?.[0]?.amount} //@ts-ignore
            acceptedAuthor={data?.accepted?.[0]?.approve_user} //@ts-ignore
            acceptedCount={data?.accepted?.[0]?.count} //@ts-ignore
            acceptedDate={data?.accepted?.[0]?.created_at} //@ts-ignore
            planCreator={data?.from_interval?.author} //@ts-ignore
            planCreatingDate={data?.from_interval?.created_at} //@ts-ignore
            planCount={data?.from_interval?.count} //@ts-ignore
            projectAmount={data?.from_interval?.work_amount} //@ts-ignore
            planApprover={data?.from_interval?.approved_user} //@ts-ignore
            planApprovingDate={data?.from_interval?.approved_at} //@ts-ignore
          />
          <div className={styles.info}>
            <p className={styles.subtitle}>Раздел:</p>
            <p className={styles.value}>{sectionName}</p>
          </div>
          {/* @ts-ignore */}
          <WorkersList workers={data?.workers} />
          {materialsCount ? (
            <Expandable title={`Материалы: ${materialsCount}`} className={styles.subtitle}>
              {/* @ts-ignore */}
              {simpleMaterials.map((el) => (
                <SimpleResourceBlock canInteract={false} name={el.name} count={el.count} measure={el.measure} />
              ))}
              {/* @ts-ignore */}
              {data?.stock_using_materials?.map((el, idx) => (
                <MaterialBlockInWork
                  key={el.id}
                  name={el.stock_using_material?.product_building?.name}
                  measure={el.stock_using_material?.product_building?.measure}
                  count={el.count}
                  amount={el.amount}
                  statusColor={manufacturingMaterialsStatusSwitcher(data, idx).color}
                  statusText={manufacturingMaterialsStatusSwitcher(data, idx).text}
                />
              ))}
            </Expandable>
          ) : null}
          {mimsCount ? (
            <Expandable title={`Машины и Механизмы: ${mimsCount}`} className={styles.subtitle}>
              {/* @ts-ignore */}
              {simpleMims.map((el) => (
                <SimpleResourceBlock canInteract={false} name={el.name} count={el.count} measure={el.measure} />
              ))}
              {/* @ts-ignore */}
              {data?.ticket_services?.map((el) => (
                <MaterialBlockInWork
                  key={el.id}
                  name={el.service?.name}
                  measure={el.service?.measure}
                  count={el.service?.estimate_expenditure?.estimate_count}
                  amount={el.service?.estimate_expenditure?.estimate_amount}
                />
              ))}
            </Expandable>
          ) : null}
          <ExpandableFileInput
            files={files || []}
            onRemoveFileDirectly={fileRemoveHandler}
            onUploadFileDirectly={fileUploadHandler}
            canUploadFiles={false}
            canDeleteFiles={canDeleteFiles}
            isFileViewer
          />
          <br />
          {/* @ts-ignore */}
          <BottomControls isExists={data?.work_status === "to_payed"}>
            <div className={styles.sum}>
              Итого к оплате:
              {/* @ts-ignore */}
              <strong>{splitThousands(data?.total_sum_amount)}</strong>
            </div>
          </BottomControls>
        </>
      )}
    </ModalContainer>
  );
};

export default React.memo(FactModal);
