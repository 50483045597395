import { Dispatch } from "redux";

import { postsActions } from "./actions";
import { postsApi } from "./postsApi";

import { ICreatePostRank, IPostRank } from "../../../../../types/interfaces/Posts";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

export const loadAllPostsList = () => (dispatch: Dispatch) => {
  dispatch(postsActions.setPostsListLoading(true));
  return postsApi
    .getPostsList()
    .then(({ data }) => {
      dispatch(postsActions.setPostsList(data.results));
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(postsActions.setPostsListLoading(false));
    });
};

export const loadAllPostsDetailedList = () => (dispatch: Dispatch) => {
  dispatch(postsActions.setPostsDetailedListLoading(true));
  return postsApi
    .getAllPosts()
    .then(({ data }) => {
      dispatch(postsActions.setPostsDetailedList(data.results));
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(postsActions.setPostsDetailedListLoading(false));
    });
};

export const loadPostInModal = (postId: number) => (dispatch: Dispatch) => {
  dispatch(postsActions.setPostInModalLoading(true));
  return postsApi
    .getPostDetail(postId)
    .then(({ data }) => {
      dispatch(postsActions.setPostInModal(data));
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(postsActions.setPostInModalLoading(false));
    });
};

export const createNewPost = (title: string, ranks: ICreatePostRank[]) => (dispatch: Dispatch) => {
  return postsApi
    .createPost(title)
    .then(async ({ data }) => {
      dispatch(postsActions.addNewPostToList(data));
      await Promise.allSettled(ranks.map((rank) => postsApi.createPostRank(data.id, rank).catch(errorCatcher)));
    })
    .catch(errorCatcher);
};

export const deletePost = (postId: number) => (dispatch: Dispatch) => {
  return postsApi
    .deletePost(postId)
    .then(() => {
      dispatch(postsActions.removePostFromList(postId));
    })
    .catch(errorCatcher);
};

export const editPost =
  ({
    title,
    postId,
    removedRanksIds,
    addedRanks,
    editedRanks,
  }: {
    postId: number;
    title?: string;
    removedRanksIds?: number[];
    editedRanks?: IPostRank[];
    addedRanks?: ICreatePostRank[];
  }) =>
  async (dispatch: Dispatch) => {
    if (!!title) {
      await postsApi
        .patchPost(postId, { title })
        .then(() => {
          dispatch(postsActions.updatePostInList(postId, { title }));
        })
        .catch(errorCatcher);
    }
    if (!!removedRanksIds?.length) {
      await Promise.allSettled(removedRanksIds.map((rankId) => postsApi.deletePostRank(rankId).catch(errorCatcher)));
    }
    if (!!addedRanks?.length) {
      await Promise.allSettled(addedRanks.map((rank) => postsApi.createPostRank(postId, rank).catch(errorCatcher)));
    }
    if (!!editedRanks?.length) {
      await Promise.allSettled(
        editedRanks.map((rank) =>
          postsApi.patchPostRank(rank.id, { rank: rank.rank, stake: rank.stake, type: rank.type }).catch(errorCatcher)
        )
      );
    }
  };
