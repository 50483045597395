export interface IConfirmableItem {
  id: number | string;
  isConfirm?: boolean;
}

class ConfirmRepository<T extends IConfirmableItem> {
  confirmableItems: T[];
  confirmedCount: number = 0;
  toConfirmCount: number = 0;

  constructor(items: T[]) {
    this.confirmableItems = items.map((item) => ({
      ...item,
      isConfirm: item.isConfirm !== undefined ? item.isConfirm : false,
    }));
    this.confirmedCount = this.confirmableItems.filter(x => x.isConfirm).length
    this.toConfirmCount = this.confirmableItems.length - this.confirmedCount;
  }

  confirm(itemId: T["id"]) {
    const confirmedItem = this.confirmableItems.find((item) => item.id === itemId);
    if (confirmedItem) confirmedItem.isConfirm = true;
  }

  cancel(itemId: T["id"]) {
    const cancelItemIndex = this.confirmableItems.findIndex((item) => item.id === itemId);
    this.confirmableItems[cancelItemIndex] = this.confirmableItems[this.confirmableItems.length - 1];
    this.confirmableItems.pop();
  }

  checkAreAllConfirm() {
    return this.confirmableItems.every((item) => item.isConfirm);
  }
}

export default ConfirmRepository;
