import cn from "classnames";
import { Moment } from "moment";
import React, { useMemo, useState } from "react";

import PopoverOverlay from "../../../../../../components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import Calendar from "../../../Calendar/Calendar";
import useClosureDates, { IClosureDate } from "../../useClosureDates";

import { CLOSURE_DATE_PLATE_BACKGROUNDS } from "./constants";

import { transformDigitToFinancial } from "../../../../../../utils/formatters/transformDigitToFinancial";
import { percentsFormatter } from "../../../../../../utils/percentsFormatter";

import CalendarIcon from "../../../../../../images/icons/CalendarIcon";
import CheckCircle from "../../../../../../images/icons/CheckCircle";
import CrossRoundedIcon from "../../../../../../images/icons/CrossRoundedIcon";
import InfoCircle from "../../../../../../images/icons/InfoCircle";

import styles from "./ClosureDatePlate.module.scss";

export interface IClosureDatePlateProps
  extends Partial<Pick<ReturnType<typeof useClosureDates>, "removeClosureDate" | "editClosureDate">> {
  date: IClosureDate;
  canEdit: boolean;
}

const ClosureDatePlate: React.FC<IClosureDatePlateProps> = ({ date, removeClosureDate, editClosureDate, canEdit }) => {
  const [isEdit, setIsEdit] = useState<boolean>(() => canEdit && !date.payment_date.length);
  const [tmpDate, setTmpDate] = useState<Moment | null>(null);

  const plateBg = useMemo(() => {
    if (isEdit && !tmpDate?.isValid()) return CLOSURE_DATE_PLATE_BACKGROUNDS.empty;
    if (isEdit && !!tmpDate?.isValid()) return CLOSURE_DATE_PLATE_BACKGROUNDS.active;
    return CLOSURE_DATE_PLATE_BACKGROUNDS.default;
  }, [isEdit, tmpDate]);

  const calendarIconBg = useMemo(() => {
    if (isEdit) return "white";
    return plateBg;
  }, [isEdit, plateBg]);

  const hasInfo = useMemo(
    () =>
      (date.count_plans !== 0 && parseFloat(date.user_percent || "") > 0) ||
      parseFloat(date.amount || "") > 0 /* @ts-ignore */ ||
      date.count_plans > 0,
    [date]
  );

  const handleRemoveDate = () => {
    if (!canEdit || hasInfo) return;
    removeClosureDate?.(date.id);
  };

  const handleConfirmDate = () => {
    if (!tmpDate?.isValid() || !canEdit) return;
    const isSuccessEdit = editClosureDate?.(date.id, { payment_date: tmpDate.format("YYYY-MM-DD") });
    setIsEdit(!isSuccessEdit);
  };

  return (
    <div className={styles.datesPlate} style={{ backgroundColor: plateBg }}>
      {isEdit ? (
        <Calendar
          value={tmpDate}
          setValue={setTmpDate}
          classNameSelect={cn(styles.calendarInput, {
            [styles.calendarInputEmpty]: !tmpDate?.isValid(),
            [styles.calendarInputFilled]: tmpDate?.isValid(),
          })}
          format={"DD.MM.YYYY"}
          placeholder={"Нет даты"}
          customIcon={<CalendarIcon background={calendarIconBg} className={styles.calendarInputIcon} />}
        />
      ) : (
        <Calendar
          value={date.payment_date}
          setValue={() => {}}
          classNameSelect={styles.calendarInput}
          disabled
          placeholder={"Нет даты"}
          variant={"display"}
          format={"DD.MM.YYYY"}
          customIcon={<CalendarIcon background={calendarIconBg} className={styles.calendarInputIcon} />}
        />
      )}
      {canEdit && (
        <div className={styles.actions}>
          {!isEdit && false && (
            <PopoverOverlay
              isDisabled={!hasInfo}
              openType={"hover"}
              portalClassName={styles.modalPortal}
              popoverBorderColor={"default"}
              placement={"bottom"}
              className={styles.popoverAnchor}
              content={
                <div className={styles.infoContent}>
                  <div className={styles.infoContentElement}>
                    <span className={styles.infoContentElementTitle}>
                      {" "}
                      {/* @ts-ignore */}
                      {percentsFormatter(parseFloat(date.strategy === 1 ? date.percent : date.user_percent || "") || 0)}
                    </span>
                    <span className={styles.infoContentElementSubtitle}>% закрытия</span>
                  </div>
                  <div className={styles.infoContentElement}>
                    <span className={styles.infoContentElementTitle}>
                      {transformDigitToFinancial(date.amount || "", {
                        withFloat: true,
                        dropZeros: true,
                        withCurrencySign: true,
                      })}
                    </span>
                    <span className={styles.infoContentElementSubtitle}>сумма закрытия</span>
                  </div>
                  <div className={styles.infoContentElement}>
                    <span className={styles.infoContentElementTitle}>{date.count_plans}</span>
                    <span className={styles.infoContentElementSubtitle}>работ к закрытию</span>
                  </div>
                </div>
              }
            >
              <InfoCircle className={cn(styles.icon, { [styles.iconActive]: hasInfo })} />
            </PopoverOverlay>
          )}
          {isEdit && (
            <CheckCircle
              className={cn(styles.icon, { [styles.iconActive]: isEdit && !!tmpDate?.isValid() })}
              onClick={handleConfirmDate}
            />
          )}
          <hr />
          <CrossRoundedIcon
            color={"black"}
            className={cn(styles.icon, { [styles.iconActive]: canEdit && !hasInfo })}
            onClick={handleRemoveDate}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(ClosureDatePlate);
