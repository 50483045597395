import React from "react";

export interface IProps {
  color?: string;
  width?: string;
}

const CrossInCircle: React.FC<IProps> = ({ color = "#D3D3D3", width = "23" }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.095 0.134766C13.2894 0.134766 15.4345 0.785476 17.2591 2.00461C19.0836 3.22374 20.5057 4.95655 21.3454 6.98389C22.1852 9.01124 22.4049 11.2421 21.9768 13.3943C21.5487 15.5465 20.492 17.5235 18.9403 19.0751C17.3887 20.6268 15.4117 21.6835 13.2595 22.1116C11.1073 22.5397 8.87647 22.32 6.84913 21.4802C4.82178 20.6405 3.08898 19.2184 1.86984 17.3938C0.65071 15.5693 0 13.4241 0 11.2298C0 8.28719 1.16893 5.46513 3.24965 3.38442C5.33037 1.3037 8.15242 0.134766 11.095 0.134766V0.134766Z"
        fill={color}
      />
      <path
        d="M11.0918 13.1945L6.84581 17.4405L4.88281 15.4815L9.13181 11.2335L4.88281 6.98448L6.84581 5.02148L11.0948 9.27048L15.3448 5.02148L17.3058 6.97848L13.0548 11.2305L17.3058 15.4815L15.3448 17.4455L11.0918 13.1945Z"
        fill="white"
      />
    </svg>
  );
};

export default React.memo(CrossInCircle);
