import { message } from "antd";
import axios from "axios";
import { createSelector } from "reselect";

import { errorCatcher } from "utils/helpers/errorCatcher";

const moduleName = "users";
const SET_USERS_ID = `${moduleName}/SET_USERS_ID`;
const UPDATE_USERS = `${moduleName}/UPDATE_USERS`;
const SET_ROLES = `${moduleName}/SET_ROLES`;

const initialState = { user: {}, roles: null };

export default function reducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SET_USERS_ID:
      return { ...state, user: { ...payload } };
    case SET_ROLES:
      return { ...state, rolesSelectorList: payload };
    default:
      return state;
  }
}

export const stateSelector = (state) => state.userRedux;
export const userSelector = createSelector(stateSelector, (state) => state.user);
export const rolesSelector = createSelector(stateSelector, (state) => state.rolesSelectorList);

export const setRolesSelectorList = (data) => {
  return {
    type: SET_ROLES,
    payload: data,
  };
};

export const setUser = (value) => {
  return (dispatch) =>
    dispatch({
      type: SET_USERS_ID,
      payload: value,
    });
};

export const createUser = (data, closeModal) => () => {
  return axios
    .post("/users/", data)
    .then(({ data }) => {
      closeModal && closeModal();
      message.success("Пользователь успешно добавлен");
      return data;
    })
    .catch(errorCatcher);
};

export const updateUser = (id, data) => (dispatch) => {
  return axios
    .patch(`/users/${id}/`, data)
    .then(({ data }) => {
      dispatch({ type: UPDATE_USERS });
      dispatch(setUser(data));
      message.success("Пользователь успешно обновлен");
      return data;
    })
    .catch(errorCatcher);
};

export const getRoles = (() => {
  return () => {
    return (dispatch) => {
      axios
        .get(`/roles/`)
        .then((res) => dispatch(setRolesSelectorList(res.data)))
        .catch(errorCatcher);
    };
  };
})();

export const resetPasswordId = (id, data) => {
  return axios.post(`/users/${id}/reset-password/`, data).catch(errorCatcher);
};
