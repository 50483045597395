import { message } from "antd";
import axios from "axios";

import downloadFileByUrl from "./downloadFileByUrl";

const downloadFile = async (url: string, name = "doc.xlsx", method = "GET", requestBody?: Record<string, any>) => {
  message.info("Загрузка файла...");
  let nameFromServer;

  if (new URL(url).origin === new URL(axios.defaults.baseURL!).origin) {
    let fileBlobUrl;
    if (method === "GET") {
      fileBlobUrl = await axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          if (response.headers["content-disposition"]) {
            nameFromServer = getFilename(response.headers["content-disposition"]);
          }
          return window.URL.createObjectURL(new Blob([response.data]))
        });
    }
    if (method === "POST") {
      fileBlobUrl = await axios
        .post(url, requestBody ?? {}, { responseType: "blob" })
        .then((response) => window.URL.createObjectURL(new Blob([response.data])));
    }
    const link = document.createElement("a");

    link.href = fileBlobUrl as string;
    link.setAttribute("download", nameFromServer ?? name);
    document.body.appendChild(link);
    link.click();
    return true;
  }

  return downloadFileByUrl(url, name);
};


function b64DecodeUnicode(str: string) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

function getFilename(str: string) {
  try {
    const decoded = b64DecodeUnicode(
      str
        .replace('=?utf-8?b?', '')
        .replace('?=', '')
    )

    const filename = decoded
      ?.split(';')
      ?.find(n => n.includes('filename='))
      ?.replace('filename=', '')
      ?.trim()

    return filename;
  } catch (e) {
    return undefined;
  }
}

export default downloadFile;
