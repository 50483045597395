import cn from "classnames";
import React from "react";

import valueInThousandsOrMillions from "utils/formatters/valueInThousandsOrMillions";

import styles from "./styles.module.scss";

const MoneyCell = React.memo((props: any) => {
  const { data, measure, isDiff, isAbs } = props;
  const tableHighlighting = {
    positive: false,
    negative: true,
  };

  return (
    <div
      className={cn(styles.moneyCell__text, {
        [styles.negative]: isDiff && (tableHighlighting as any).negative && data < 0,
        [styles.positive]: isDiff && (tableHighlighting as any).positive && data > 0,
      })}
      title={valueInThousandsOrMillions(data, measure, isAbs) as string}
    >
      {valueInThousandsOrMillions(data, measure, isAbs)}
    </div>
  );
});

//@ts-ignore
const renderMoneyCell = (data, measure, isDiff, isAbs) => {
  return <MoneyCell data={data} measure={measure} isDiff={isDiff} isAbs={isAbs} />;
};

export default renderMoneyCell;
