import React, { useRef } from "react";
import { useDispatch } from "react-redux";

import { createTaskComment } from "components/pages/Tasks/model/thunks";

import { useTypedSelector } from "app/store/typedUseSelector";

import { personFullNameWithId } from "types/personsTypes";

export const useTaskComments = (taskId: number, currentUser: personFullNameWithId, isNewTask?: boolean) => {
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const [commentText, setCommentText] = React.useState("");

  const dispatch = useDispatch();

  const userFirstNameLastName = `${currentUser.first_name} ${currentUser.last_name}`;

  const writeTaskCommentHandler = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentText(e.target.value);
  }, []);

  const createNewTaskCommentHandler = React.useCallback(
    (e: { preventDefault: () => void; stopPropagation: () => void }) => {
      if (!commentText) return;

      e?.preventDefault();
      e?.stopPropagation();

      if (isNewTask) return;
      dispatch(
        createTaskComment(commentText, taskId, currentUser.id!, new Date().toISOString(), userFirstNameLastName)
      );
      setCommentText("");
    },
    [commentText, isNewTask, taskId, currentUser.id, userFirstNameLastName]
  );

  return { createNewTaskCommentHandler, writeTaskCommentHandler, commentText, textInputRef };
};
