import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { actingActions } from "redux/modules/common/building/journal/acting/actions";
import {
  actingKs2ListByEstimateSelector,
  actingKs2ListSelector,
  actingSelectedKs2IdsSelector,
} from "redux/modules/common/building/journal/acting/selectors";
import { createKs3 } from "redux/modules/common/building/journal/acting/thunks";

import { initialCreateKsData } from "../constants";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import { checkCreatingKs3IsDirty } from "./utils";

interface IActData {
  number: string;
  dateStart: string;
  dateEnd: string;
}

export const useCreateKs3 = (isOpen: boolean, estimateId?: string) => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();

  const selectedKs2Ids = useSelector(actingSelectedKs2IdsSelector)[objectId] || [];
  const ks2List = useSelector(actingKs2ListByEstimateSelector)[estimateId as string];
  const ksList = useSelector(actingKs2ListSelector)[objectId];

  const selectedKsList = useMemo(() => {
    return estimateId
      ? ks2List?.results?.filter((el) => selectedKs2Ids.includes(el.id))
      : ksList?.results?.filter((el) => selectedKs2Ids.includes(el.id));
  }, [ks2List, ksList, selectedKs2Ids]);

  const [actData, setActData] = useState<IActData>(initialCreateKsData);

  const actNumberHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setActData((prev) => ({ ...prev, number: e.target.value }));
  }, []);

  const dateStartHandler = useCallback((date: string) => {
    setActData((prev) => ({ ...prev, dateStart: date }));
  }, []);

  const dateEndHandler = useCallback((date: string) => {
    setActData((prev) => ({ ...prev, dateEnd: date }));
  }, []);

  const submitHandler = useCallback(() => {
    dispatch(
      createKs3(objectId, {
        title: actData.number,
        start_at: moment(actData.dateStart).format("YYYY-MM-DD"),
        end_at: moment(actData.dateEnd).format("YYYY-MM-DD"),
        ks2_acts: selectedKs2Ids,
      })
    );
  }, [actData, objectId, selectedKs2Ids]);

  const deselectHandler = useCallback(
    (ksId: number) => {
      dispatch(actingActions.selectKs2Id(ksId, objectId, false));
    },
    [objectId]
  );

  const isShowSubmit = checkCreatingKs3IsDirty(actData, selectedKs2Ids);

  useEffect(() => {
    if (!isOpen) {
      setActData(initialCreateKsData);
    }
  }, [isOpen]);

  return {
    actData,
    actNumberHandler,
    dateEndHandler,
    dateStartHandler,
    submitHandler,
    selectedKs2Ids,
    selectedKsList,
    deselectHandler,
    isShowSubmit,
  };
};
