import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";

import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { useTypedParams } from "utils/hooks/useTypedParams";

import styles from "./PlanFactExtraCostItem.module.scss";

interface IProps {
  count?: number;
  name?: string;
  pushPostfix?: string;
  innerContent?: ReactNode;
}

const PlanFactExtraCostItem: React.FC<IProps> = ({
  count,
  name = "Дополнительные расходы",
  pushPostfix = "",
  innerContent,
}) => {
  const history = useHistory();
  const { objectId } = useTypedParams();

  return (
    <TableReusableRow
      openClassName={styles.open}
      isParentOfExpenditures={!!innerContent}
      innerContent={innerContent}
      containerClassName={styles.container}
      className={styles.row}
      isFont13={false}
      onClick={() => {
        history.push(`/objects/${objectId}/plan-fact/finance/extra/${pushPostfix}`);
      }}
    >
      <TableReusableCell className={styles.name}>{name}</TableReusableCell>
      <TableReusableCell className={styles.name}></TableReusableCell>
      <TableReusableCell className={styles.count}>Объектов {count}</TableReusableCell>
    </TableReusableRow>
  );
};

export default PlanFactExtraCostItem;
