import React from 'react';

export const Paginator = props => {

  const getLi = () => {
    const middleDelta = Math.floor(getMaxValue() / 2);
    const nowPage = getPage() - 1;
    let startPage = 0;
    let displayRange = getMaxValue();
    if ( nowPage > middleDelta ) startPage = nowPage - middleDelta;
    if ( startPage + getMaxValue() >= getPagesCount() ) startPage = startPage - (startPage + getMaxValue() - getPagesCount());
    if ( startPage < 0 ) {
      startPage = 0;
      displayRange = getPagesCount();
    }
    let liArr=[];
    for (let i = startPage; i < startPage + displayRange; i++) {
      liArr.push(
        <li
          key={i}
          style={{
            padding: '5px',
            backgroundColor: getPage() === i + 1 ? '#C3C3C3' : '#dcdcdc',
            cursor: 'pointer'
          }}
          onClick={ () => setPage(getPage(), i)}
        >
          {i + 1}
        </li>
      );
    }
    return liArr;
  };

  const setPage = (nowPage, val) => {
    if (
      nowPage !== val + 1
      &&
      val >= 0
      &&
      val < getPagesCount()
      && !props.ignorePageChange
    ) {
      props.changeOffset(val);
    }
  };

  const getPage = () => props.nowPage;

  const getPagesCount = () => props.pagesCount;

  const setPageBack = val => setPage(getPage(), getPage() - 1 - val);

  const setPageForward = val => setPage(getPage(), getPage() - 1 + val);

  const getMaxValue = () => props.maxValue;

  return(
    props.customPaginator ?
      props.customPaginator({ setPage, getPage, getPagesCount, setPageBack, setPageForward, getMaxValue })
      :
      <div style={{display: 'flex'}}>
        <ul style={{display: 'flex', listStyleType: 'none'}}>
          <li
            style={{
              padding: '5px',
              backgroundColor: '#dcdcdc',
              cursor: 'pointer'
            }}
            onClick={ () => setPageBack(1) }
          >
            {'<'}
          </li>
          { getLi() }
          <li
            style={{
              padding: '5px',
              backgroundColor: '#dcdcdc',
              cursor: 'pointer'
            }}
            onClick={ () => setPageForward(1) }
          >
            {'>'}
          </li>
        </ul>
      </div>
  );
};