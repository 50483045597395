import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { formatPercentValue } from "../../../pages/Handler/ui/ProHandler/hooks/useBudgetDoubleInput";

import {
  parseTransformedNumber,
  parseTransformedString,
  transformDigitToFinancial,
} from "../../../utils/formatters/transformDigitToFinancial";

interface IArgs {
  percent: number | string;
  cost: number | string;
  amountSource: number | string;
  changeCost: (cost: number) => void;
}

export const useMarkupDoubleInput = ({ percent, cost, amountSource, changeCost }: IArgs) => {
  const [changedCost, setChangedCost] = useState(
    transformDigitToFinancial(cost, { withFloat: true, dropZeros: true }) || "0"
  );
  const [changedPercent, setChangedPercent] = useState(() => formatPercentValue(percent));

  const debouncedChangeCost = useMemo(() => debounce((cost) => changeCost?.(cost), 500), [changeCost]);

  const onChangePercent = useCallback(
    (value: string) => {
      let valueWithoutPercent = parseTransformedNumber(value);
      if (isNaN(+(valueWithoutPercent ?? 0)) || +(valueWithoutPercent ?? 0) >= 100 || +(valueWithoutPercent ?? 0) < 0)
        return;

      setChangedPercent(parseTransformedString(value ?? "") ?? 0);

      const beatifiedNewDiscountCost = transformDigitToFinancial(((valueWithoutPercent ?? 0) * +amountSource) / 100, {
        withFloat: true,
        dropZeros: true,
      });
      setChangedCost(beatifiedNewDiscountCost);
      debouncedChangeCost(parseTransformedNumber(beatifiedNewDiscountCost));
    },
    [debouncedChangeCost, amountSource]
  );

  const onChangeCost = useCallback(
    (value: string) => {
      let beautifiedValue = transformDigitToFinancial(parseTransformedString(value) ?? "", {
        withFloat: true,
        dropZeros: true,
      });
      const parsedNumber = parseTransformedNumber(beautifiedValue) ?? 0;

      if (isNaN(+parsedNumber) || +parsedNumber >= +amountSource || +parsedNumber < 0) return;

      debouncedChangeCost(parsedNumber);
      setChangedPercent(formatPercentValue(((+parsedNumber * 100) / +amountSource).toString()));
      setChangedCost(beautifiedValue);
    },
    [debouncedChangeCost, amountSource]
  );

  return {
    changedCost,
    changedPercent,
    onChangePercent,
    onChangeCost,
  };
};
