import cn from "classnames";
import React, { FC, useMemo } from "react";

import { confirmedIcon } from "../../assets/confirmedIcon";
import { processIcon } from "../../assets/processIcon";
import { useTypedSelector } from "app/store/typedUseSelector";

import { ILearningItem } from "../../model/types";

import styles from "./LearningMenuItem.module.scss";

interface IProps {
  item: ILearningItem;
  isActive?: boolean;
  isLastAvailable?: boolean;
  activeLesson?: ILearningItem;
  setActiveLesson?: React.Dispatch<React.SetStateAction<ILearningItem | null>>;
}

const LearningMenuItem: FC<IProps> = ({ item, setActiveLesson, activeLesson, isLastAvailable }) => {
  const enrichedItem = item;

  const isActive = enrichedItem.key === activeLesson?.key;

  return (
    <div
      className={cn(styles.item, isActive && styles.active)}
      onClick={
        !enrichedItem.isDisabled
          ? () => {
              setActiveLesson?.(enrichedItem);
            }
          : undefined
      }
    >
      <div className={styles.info} title={enrichedItem?.edu_data.title}>
        <p>{enrichedItem?.edu_data.title}</p>
        <span>{enrichedItem.menuTitle}</span>
      </div>
      <div className={styles.icon}>
        {enrichedItem.is_confirmed && confirmedIcon}
        {isLastAvailable && isActive && processIcon} {/* ne ok */}
        {isLastAvailable && !isActive && !enrichedItem.is_confirmed && circle}
        {!enrichedItem.is_confirmed && enrichedItem.isDisabled && circle}
      </div>
    </div>
  );
};

export default LearningMenuItem;

const circle = (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="10" stroke="#4FB1EB" strokeWidth="2" />
  </svg>
);
