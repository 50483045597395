import { IHelpExpanditure } from "../../widgets/AutoCompleteOutOfEstimate/model/types";
import { ExpenditureType } from "types/enums/ExpenditureTypeEnum";

import { beautifyNumberValue } from "utils/formatters/beautifyNumberValue";
import { extractKey } from "utils/helpers/extractKey";

export type IAddExpenditureListRawValues = {
  expenditure_type: ExpenditureType;
  number: string;
  name: string;
  measure: string;
  count: string;
  price: string;
} & {
  [key: string]: {
    number: string;
    expenditure_type: ExpenditureType;
    name: string;
    measure: string;
    count: string;
    price: string;
  };
};

export const prepareRawExpendituresList = (
  vals: IAddExpenditureListRawValues,
  materials: number[],
  sectionId: number
) => {
  const work = {
    expenditure_type: vals.expenditure_type,
    number: vals.number,
    name: vals.name,
    measure: vals.measure,
    count: vals.count,
    price: vals.price,
    section_id: sectionId,
  };

  const res = [work];

  for (let key in vals) {
    if (typeof vals[key] === "object" && materials.includes(extractKey(key)) && vals[key].name) {
      res.push({ ...vals[key], section_id: sectionId });
    }
  }

  return {
    expenditures: res,
  };
};

export const getInitialManualExpenditureFormAfterAutoComplete = (
  work: IHelpExpanditure
): [IAddExpenditureListRawValues, number[]] => {
  const prodIds = work.expenditure_products.map((el) => el.id);

  const res = {
    expenditure_type: work.expenditure_type,
    name: work.name,
    measure: work.measure,
    price: beautifyNumberValue(work.price),
  };

  work.expenditure_products.forEach((el) => {
    //@ts-ignore
    res[`id:${el.id}`] = {
      expenditure_type: el.expenditure_type,
      name: el.name,
      measure: el.measure,
      price: beautifyNumberValue(el.price),
    };
  });

  return [res as IAddExpenditureListRawValues, prodIds];
};
