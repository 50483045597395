import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userSelector } from "redux/modules/_TODO/auth";

import { ADD_CHAT_ITEM, RESET_CHAT_TO_INITIAL } from "widgets/Chat/model/reducers";
import {
  allMessagesSelector,
  isMessagesLoadingSelector,
  maxCountSelector,
  unreadCountSelector,
} from "widgets/Chat/model/selectors";
import { socketAddHandler, socketRemoveHandler } from "widgets/Chat/model/socketEventsV2";
import { getMessages, getMoreMessages, sendMessage } from "widgets/Chat/model/thunks";

import { initialChatState, isShowChatParam } from "../constants";
import { IchatState } from "../types";
import { IchatMessageResponse, chatRoomTypes } from "widgets/Chat/model/types";

import { useQueryParams } from "utils/hooks/useQueryParams";

type propsType = {
  room: chatRoomTypes;
  building_id: number;
  item_id?: number;
};

export const useChat = ({ room, building_id, item_id }: propsType) => {
  const dispatch = useDispatch();
  const isShowChatAutomatically = useQueryParams(isShowChatParam);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const chatListRef = useRef<HTMLDivElement>(null);

  const messages = useSelector(allMessagesSelector);
  const messagesCount = useSelector(maxCountSelector);
  const isMessagesLoading = useSelector(isMessagesLoadingSelector);
  const currentUser = useSelector(userSelector);
  const unreadCount = useSelector(unreadCountSelector);
  const [chatState, setChatState] = useState<IchatState>(initialChatState);

  const chatVisibilityHandler = useCallback(() => {
    setChatState((prev) => ({
      ...prev,
      show: !prev.show,
    }));
  }, []);

  const incomingMessageHandler = useCallback(
    (data: IchatMessageResponse) => {
      if (+data?.author?.id !== +currentUser?.id) {
        dispatch({
          type: ADD_CHAT_ITEM,
          payload: data,
        });
      }
    },
    [dispatch, currentUser]
  );

  useEffect(() => {
    if (isShowChatAutomatically) {
      setChatState((prev) => ({
        ...prev,
        show: true,
      }));
    }
  }, [isShowChatAutomatically]);

  useEffect(() => {
    /* @ts-ignore */
    socketAddHandler(room, incomingMessageHandler);
    dispatch(getMessages({ room, building_id, item_id }));

    return () => {
      socketRemoveHandler(room);
      dispatch({ type: RESET_CHAT_TO_INITIAL });
    };
  }, [room, building_id, item_id, incomingMessageHandler]);

  const loadMoreMessagesHandler = () => {
    dispatch(getMoreMessages({ room, building_id }, messages.length));
  };

  useEffect(() => {
    if (chatState.show === true) {
      if (chatState.unreadVal !== 0) {
        setChatState((prev) => ({
          ...prev,
          unreadVal: 0,
        }));
      }
    }
  }, [chatState.show]);

  const sendMessageHandler = (mentions: number[]) => {
    if (!inputRef.current?.value) return;
    dispatch(sendMessage({ room, building_id, item_id }, inputRef.current.value, mentions));
    inputRef.current.value = "";
  };

  useEffect(() => {
    if (!chatListRef.current) return;
    chatListRef.current.scrollTo({ top: 10000 });
  }, [messages, chatState.show]);

  return {
    chatState,
    sendMessageHandler,
    messages,
    messagesCount,
    isMessagesLoading,
    chatVisibilityHandler,
    inputRef,
    chatListRef,
    loadMoreMessagesHandler,
    unreadCount,
  };
};
