import React from 'react';

export default function MessageIcon({ width='29', height='22', color='#6C6C6C' }) {
  return (
    <svg 
      width={width}
      height={height}
      viewBox="0 0 29 22" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M8.37315 20.5895L6.5221 17.3497L6.37814 17.0978H6.08796H3.37275C1.99441 17.0978 0.873047 15.976 0.873047 14.5972V3.59736C0.873047 2.21902 1.99441 1.09766 3.37275 1.09766H25.3732C26.7516 1.09766 27.8729 2.21902 27.8729 3.59736V14.5972C27.8729 15.976 26.7516 17.0978 25.3732 17.0978H10.6583H10.3682L10.2242 17.3497L8.37315 20.5895Z" 
        fill="white"
      />
      <path 
        d="M8.37315 19.5816L10.078 16.5978H25.3732C26.4759 16.5978 27.3729 15.7003 27.3729 14.5972V3.59736C27.3729 2.49472 26.4759 1.59766 25.3732 1.59766H3.37275C2.27011 1.59766 1.37305 2.49472 1.37305 3.59736V14.5972C1.37305 15.7003 2.27011 16.5978 3.37275 16.5978H6.66832L8.37315 19.5816ZM8.37315 21.5974L6.08796 17.5978H3.37275C1.71585 17.5978 0.373047 16.2541 0.373047 14.5972V3.59736C0.373047 1.94046 1.71585 0.597656 3.37275 0.597656H25.3732C27.0301 0.597656 28.3729 1.94046 28.3729 3.59736V14.5972C28.3729 16.2541 27.0301 17.5978 25.3732 17.5978H10.6583L8.37315 21.5974Z" 
        fill={color}
      />
      <path d="M21.373 4.59766H7.37305V5.59766H21.373V4.59766Z" fill="white"/>
      <path 
        d="M7.87305 5.09766H20.873H7.87305Z" 
        stroke={color}
      />
      <path d="M17.373 8.59766H7.37305V9.59766H17.373V8.59766Z" fill="white"/>
      <path 
        d="M7.87305 9.09766H16.873H7.87305Z" 
        stroke={color}
      />
      <path d="M21.373 12.5977H7.37305V13.5977H21.373V12.5977Z" fill="white"/>
      <path 
        d="M7.87305 13.0977H20.873H7.87305Z" 
        stroke={color}
      />
    </svg>
  )
}
