import axios from "axios";

import { errorCatcher } from "../../../../utils/helpers/errorCatcher";

export const apiAddFile = (buildingId, expenditureId, file, callback) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios
    .post(`/building/${buildingId}/expenditure_files/${expenditureId}/`, formData)
    .then(() => callback && callback())
    .catch(errorCatcher);
};

export const apiGetFile = (objectId, itemId) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios
    .get(`/building/${objectId}/expenditure_files/${itemId}/`, config)
    .then(
      ({ data }) => data,
      (error) => {
        console.error(error);
      }
    )
    .catch((e) => console.error(e));
};

export const apiDeleteFile = (objectId, itemId, id, callback) => {
  return axios
    .delete(`/building/${objectId}/expenditure_files/${itemId}/${id}/`)
    .then(() => callback && callback())
    .catch(errorCatcher);
};
