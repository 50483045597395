import React from "react";

const DangerIcon = () => (
  <svg width="44" height="38" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0674 1.10039C20.2664 0.765064 20.5493 0.487295 20.8882 0.29437C21.2271 0.101445 21.6104 0 22.0004 0C22.3903 0 22.7736 0.101445 23.1125 0.29437C23.4514 0.487295 23.7343 0.765064 23.9334 1.10039L43.5894 34.1734C43.7931 34.5148 43.9027 34.9041 43.9069 35.3017C43.911 35.6992 43.8097 36.0908 43.6132 36.4364C43.4167 36.7821 43.1321 37.0694 42.7883 37.2691C42.4445 37.4689 42.0539 37.5738 41.6564 37.5734H2.34436C1.94678 37.5738 1.55619 37.4689 1.21242 37.2691C0.868652 37.0694 0.584003 36.7821 0.387503 36.4364C0.191004 36.0908 0.0896836 35.6992 0.093875 35.3017C0.0980663 34.9041 0.207619 34.5148 0.411362 34.1734L20.0674 1.10039Z"
      fill="#DDDDDD"
    />
    <path
      d="M20.3086 11.523C20.3086 10.979 20.5556 10.707 21.0496 10.707H22.5926C23.0866 10.707 23.3336 10.979 23.3336 11.523L23.0226 25.523C23.0354 25.6136 23.027 25.706 22.9981 25.7927C22.9691 25.8795 22.9203 25.9584 22.8556 26.0231C22.7909 26.0878 22.7121 26.1365 22.6253 26.1655C22.5385 26.1945 22.4462 26.2029 22.3556 26.19H21.2876C21.197 26.2029 21.1047 26.1945 21.0179 26.1655C20.9311 26.1365 20.8523 26.0878 20.7876 26.0231C20.7229 25.9584 20.6741 25.8795 20.6451 25.7927C20.6161 25.706 20.6077 25.6136 20.6206 25.523L20.3086 11.523ZM20.9906 31.972C20.9 31.9849 20.8077 31.9765 20.7209 31.9475C20.6341 31.9185 20.5553 31.8698 20.4906 31.8051C20.4259 31.7404 20.3771 31.6615 20.3481 31.5747C20.3191 31.488 20.3107 31.3956 20.3236 31.305V29.496C20.3107 29.4054 20.3191 29.3131 20.3481 29.2263C20.3771 29.1395 20.4259 29.0607 20.4906 28.996C20.5553 28.9313 20.6341 28.8825 20.7209 28.8536C20.8077 28.8246 20.9 28.8162 20.9906 28.829H22.6516C22.7422 28.8162 22.8345 28.8246 22.9213 28.8536C23.0081 28.8825 23.0869 28.9313 23.1516 28.996C23.2163 29.0607 23.2651 29.1395 23.2941 29.2263C23.323 29.3131 23.3314 29.4054 23.3186 29.496V31.305C23.3314 31.3956 23.323 31.488 23.2941 31.5747C23.2651 31.6615 23.2163 31.7404 23.1516 31.8051C23.0869 31.8698 23.0081 31.9185 22.9213 31.9475C22.8345 31.9765 22.7422 31.9849 22.6516 31.972H20.9906Z"
      fill="white"
    />
  </svg>
);

export default React.memo(DangerIcon);
