import { useCallback, useMemo, useState } from "react";

import { dropNonSignificantZeros } from "./dropNonSignificantZeros";

import { LOCALIZATION_CONFIG } from "constants/localization";

const DEFAULT_OPTIONS = { withFloat: true };
const FINANCIAL_FRACTION_DIGITS = 2;

//TODO переименовать
export type Options = {
  withFloat: boolean;
  withCurrencySign?: boolean;
  withSign?: boolean;
  withPercent?: boolean;
  dropZeros?: boolean;
};

const leadToNumber = (value: string) => {
  const numberValue = Number(value);
  if (isNaN(numberValue)) throw new Error();
  return numberValue;
};

const localeString = (string: string, options: Options) => {
  let localedString = leadToNumber(string)
    .toLocaleString(
      "en",
      options.withFloat
        ? {
            minimumFractionDigits: options.dropZeros ? 0 : FINANCIAL_FRACTION_DIGITS,
            maximumFractionDigits: FINANCIAL_FRACTION_DIGITS,
          }
        : {}
    )
    .replace(/,/g, " ");

  if (/\.0$/.test(string)) localedString += ".0";
  if (/\.$/.test(string)) localedString += ".";

  return localedString;
};

export const parseTransformedString = (transformedString: string | number | null): string | null | "" =>
  !!transformedString?.toString()?.length
    ? transformedString.toString().replaceAll(/\s/g, "").replaceAll(",", ".")
    : null;

export const parseTransformedNumber = (transformedString: string | number | null): number | null =>
  !!transformedString?.toString()?.length ? parseFloat(parseTransformedString(transformedString) || "") : null;

export const transformDigitToFinancial = (initialDigit: string | number, options: Options = DEFAULT_OPTIONS) => {
  try {
    let transformedDigit = localeString(String(initialDigit).replaceAll(",", "."), options);

    if (options.withSign && +initialDigit > 0) transformedDigit = `+${transformedDigit}`;
    if (options.withSign && +initialDigit < 0) transformedDigit = `-${transformedDigit}`;
    if (options.withPercent) transformedDigit += "%";
    if (options.withCurrencySign) transformedDigit += ` ${LOCALIZATION_CONFIG.currency}`;

    return transformedDigit;
  } catch (e) {
    return initialDigit;
  }
};

export const useTransformToFinancial = (
  initialDigit: string | number,
  options: Options = DEFAULT_OPTIONS,
  onChange?: (_: string) => void
) => {
  const [formattedValue, setFormattedValue] = useState(() => transformDigitToFinancial(initialDigit, options));
  const numericValue = useMemo(() => parseTransformedNumber(formattedValue), [formattedValue]);
  const stringValue = useMemo(() => parseTransformedString(formattedValue), [formattedValue]);

  const handleChangeValue = useCallback(
    (newRawValue: string) => {
      const parsedString = parseTransformedString(newRawValue) || "";
      const newFormattedValue = transformDigitToFinancial(parsedString, options);
      setFormattedValue(newFormattedValue);
      onChange?.(parsedString);
    },
    [options]
  );

  return { formattedValue, numericValue, stringValue, handleChangeValue };
};
