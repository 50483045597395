import moment from "moment";
import React, { useMemo } from "react";

import { CheckpointListItem } from "widgets/ViewCheckpoint/types";

import styles from "./CheckpointPreview.module.scss";

export interface CheckpointPreviewProps {
  checkpoint: CheckpointListItem;
  onClick: (checkpointId: number) => void;
}

export const CheckpointPreview: React.FC<CheckpointPreviewProps> = ({ checkpoint, onClick }) => {
  const displayDate = useMemo(
    () => moment(checkpoint.check_point_date).format("DD.MM.YY"),
    [checkpoint.check_point_date]
  );
  const handleClick = () => {
    onClick(checkpoint.id);
  };

  return (
    <div onClick={handleClick} className={styles.container}>
      <span className={styles.name}>
        <p>{checkpoint.name}</p>
        <p>
          <b>{displayDate}</b>
        </p>
        <p>{`${checkpoint.building_name}${checkpoint.section_name ? " / " + checkpoint.section_name : ""}`}</p>
      </span>
    </div>
  );
};
