import axios from "axios";

import { IProjectViewAudit, IProjectViewAuditV2 } from "pages/Finance/_TODO/legacyAuditTypes";

import { AuditTypes, IAuditObjectResponse } from "./types";

interface IDatesProps {
  start: string;
  end: string;
}

export const auditApi = {
  getObjectAudit: (objectId: number, dates: IDatesProps, auditType: AuditTypes) => {
    const params = {
      start_at: dates.start,
      end_at: dates.end,
    };
    if (auditType === "forecast") {
      return axios.get<IAuditObjectResponse>(`/tree/finance_forecasts_audit/${objectId}/`, {
        params,
      });
    }
    return axios.get<IAuditObjectResponse>(`/tree/finance_audit/${objectId}/`, {
      params,
    });
  },

  getProject: (dates: IDatesProps, auditType: AuditTypes, projectId?: string | number) => {
    const params: Record<string, any> = {
      start_at: dates.start,
      end_at: dates.end,
    };
    if (projectId && +projectId !== 0) {
      params.project_id = projectId;
    }
    if (auditType === "forecast") {
      return axios.get<IProjectViewAuditV2[]>(`/tree/projects/finance_forecasts_audit/`, {
        params,
      });
    }
    return axios.get<IProjectViewAuditV2[]>(`/tree/projects/finance_audit/`, {
      params,
    });
  },
};
