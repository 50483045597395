import React from 'react'

export const AddIcon = ({ className, style }) => {
  return (
    <svg className={className} style={style} width="1.5625rem" height="1.5625rem" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 0.25C15.5217 0.25 17.9868 0.997774 20.0835 2.39876C22.1802 3.79975 23.8144 5.79103 24.7795 8.12079C25.7445 10.4505 25.997 13.0141 25.505 15.4874C25.0131 17.9607 23.7987 20.2325 22.0156 22.0156C20.2325 23.7987 17.9607 25.0131 15.4874 25.505C13.0141 25.997 10.4505 25.7445 8.12079 24.7795C5.79103 23.8144 3.79975 22.1802 2.39876 20.0835C0.997774 17.9868 0.25 15.5217 0.25 13C0.25 9.61849 1.5933 6.37548 3.98439 3.98439C6.37548 1.5933 9.61849 0.25 13 0.25V0.25Z" fill="#4FB2ED" />
      <path d="M11.89 19.388V14.112H6.611C6.31634 14.112 6.03376 13.9949 5.8254 13.7865C5.61705 13.5782 5.5 13.2956 5.5 13.001C5.5 12.7063 5.61705 12.4237 5.8254 12.2154C6.03376 12.007 6.31634 11.89 6.611 11.89H11.89V6.61295C11.89 6.3183 12.0071 6.03571 12.2154 5.82736C12.4238 5.619 12.7063 5.50195 13.001 5.50195C13.2957 5.50195 13.5782 5.619 13.7866 5.82736C13.9949 6.03571 14.112 6.3183 14.112 6.61295V11.89H19.39C19.6847 11.89 19.9672 12.007 20.1756 12.2154C20.3839 12.4237 20.501 12.7063 20.501 13.001C20.501 13.2956 20.3839 13.5782 20.1756 13.7865C19.9672 13.9949 19.6847 14.112 19.39 14.112H14.112V19.388C14.112 19.5339 14.0833 19.6783 14.0274 19.8131C13.9716 19.9479 13.8898 20.0704 13.7866 20.1735C13.6834 20.2767 13.561 20.3586 13.4262 20.4144C13.2914 20.4702 13.1469 20.499 13.001 20.499C12.8551 20.499 12.7106 20.4702 12.5758 20.4144C12.441 20.3586 12.3186 20.2767 12.2154 20.1735C12.1122 20.0704 12.0304 19.9479 11.9746 19.8131C11.9187 19.6783 11.89 19.5339 11.89 19.388Z" fill="white" />
    </svg>
  )
}
