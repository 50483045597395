import { axiosBaseQuery } from "redux/axiosBaseQuery";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  IAttachFileToDrawingSet,
  ICreateDrawingReq,
  ICreateDrawingSet,
  IDeleteFileFromDrawingSetReq,
  IDrawing,
  IDrawingDetailed,
  IDrawingSet,
  IGetDrawingSetDetailReq,
  IGetDrawingSetsReq,
  IGetDrawingsReq,
  IdrawingSetDetail,
} from "./types";
import { IListResponseData } from "types/ListResponse";

//не юзаем RTK Query больше
export const blueprintsQuery = createApi({
  reducerPath: "plueprints",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["drawingSets", "drawings"],
  endpoints: (builder) => ({
    getDrawingSets: builder.query<IListResponseData<IDrawingSet>, IGetDrawingSetsReq>({
      query: ({ buildingId, ...req }) => ({
        url: `/building/${buildingId}/drawing_sets/`,
        method: "GET",
        params: { ...req, limit: 500 },
      }),
      providesTags: ["drawingSets"],
    }),
    createDrawingsSet: builder.mutation<IDrawingSet, ICreateDrawingSet>({
      query: ({ buildingId, ...req }) => ({
        url: `/building/${buildingId}/drawing_sets/`,
        method: "POST",
        data: req,
      }),
      invalidatesTags: ["drawingSets"],
    }),
    attachFileToDrwaingSet: builder.mutation<void, IAttachFileToDrawingSet>({
      query: ({ buildingId, drawingSetId, file }) => ({
        url: `/building/${buildingId}/drawing_sets/${drawingSetId}/files/`,
        method: "POST",
        data: file,
      }),
      invalidatesTags: ["drawingSets"],
    }),
    deleteDrawingSet: builder.mutation<void, Omit<IAttachFileToDrawingSet, "file">>({
      query: ({ buildingId, drawingSetId }) => ({
        url: `/building/${buildingId}/drawing_sets/${drawingSetId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["drawingSets"],
    }),
    deleteFileFromDrawingSet: builder.mutation<void, IDeleteFileFromDrawingSetReq>({
      query: ({ buildingId, drawingSetId, fileId }) => ({
        url: `/building/${buildingId}/drawing_sets/${drawingSetId}/files/${fileId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["drawingSets"],
    }),
    getDrawingSetById: builder.query<IdrawingSetDetail, IGetDrawingSetDetailReq>({
      query: ({ buildingId, drawingSetId }) => ({
        url: `/building/${buildingId}/drawing_sets/${drawingSetId}/`,
        method: "GET",
      }),
      providesTags: ["drawingSets"],
    }),
    editDrawingSet: builder.mutation<void, ICreateDrawingSet & { drawingSetId: string | number }>({
      query: ({ buildingId, drawingSetId, ...data }) => ({
        url: `/building/${buildingId}/drawing_sets/${drawingSetId}/`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["drawingSets"],
    }),
    ///////// drawings
    getDrawings: builder.query<IListResponseData<IDrawing>, IGetDrawingsReq>({
      query: ({ buildingId, ...req }) => ({
        url: `/building/${buildingId}/drawings/`,
        method: "GET",
        params: {
          ...req,
          limit: 500,
          expenditure_ids: req.expenditure_ids ? JSON.stringify(req.expenditure_ids) : undefined,
        },
      }),
      providesTags: ["drawings"],
    }),
    getDrawingById: builder.query<IDrawingDetailed, IGetDrawingSetDetailReq>({
      query: ({ buildingId, drawingSetId }) => ({
        url: `/building/${buildingId}/drawings/${drawingSetId}/`,
        method: "GET",
      }),
      providesTags: ["drawings"],
    }),
    createDrawing: builder.mutation<IDrawingSet, ICreateDrawingReq>({
      query: ({ buildingId, ...req }) => ({
        url: `/building/${buildingId}/drawings/`,
        method: "POST",
        data: req,
      }),
      invalidatesTags: ["drawings"],
    }),

    attachFileToDrawing: builder.mutation<void, IAttachFileToDrawingSet>({
      query: ({ buildingId, drawingSetId, file }) => ({
        url: `/building/${buildingId}/drawings/${drawingSetId}/files/`,
        method: "POST",
        data: file,
      }),
      invalidatesTags: ["drawings"],
    }),
    deleteDrawing: builder.mutation<void, Omit<IAttachFileToDrawingSet, "file">>({
      query: ({ buildingId, drawingSetId }) => ({
        url: `/building/${buildingId}/drawings/${drawingSetId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["drawings"],
    }),
    deleteFileFromDrawing: builder.mutation<void, IDeleteFileFromDrawingSetReq>({
      query: ({ buildingId, drawingSetId, fileId }) => ({
        url: `/building/${buildingId}/drawings/${drawingSetId}/files/${fileId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["drawings"],
    }),
    editDrawing: builder.mutation<void, Partial<ICreateDrawingReq> & { drawingSetId: string | number }>({
      query: ({ buildingId, drawingSetId, ...data }) => ({
        url: `/building/${buildingId}/drawings/${drawingSetId}/`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["drawings"],
    }),
    bindDrawingToEstimate: builder.mutation<void, any>({
      query: ({ buildingId, drawingSetId, ...data }) => ({
        url: `/building/${buildingId}/drawings/${drawingSetId}/link_with_estimate/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["drawings"],
    }),
    getDrawingBindings: builder.query<{ expenditure_ids: number[] }, { buildingId: string; drawingSetId: number }>({
      query: ({ buildingId, drawingSetId }) => ({
        url: `/building/${buildingId}/drawings/${drawingSetId}/link_with_estimate/`,
        method: "GET",
      }),
      providesTags: ["drawings"],
    }),
  }),
});

export const {
  useGetDrawingSetsQuery,
  useCreateDrawingsSetMutation,
  useAttachFileToDrwaingSetMutation,
  useGetDrawingsQuery,
  useDeleteDrawingSetMutation,
  useGetDrawingSetByIdQuery,
  useDeleteFileFromDrawingSetMutation,
  useEditDrawingSetMutation,
  useCreateDrawingMutation,
  useAttachFileToDrawingMutation,
  useDeleteDrawingMutation,
  useDeleteFileFromDrawingMutation,
  useEditDrawingMutation,
  useGetDrawingByIdQuery,
  useBindDrawingToEstimateMutation,
  useGetDrawingBindingsQuery,
} = blueprintsQuery;
