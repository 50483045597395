import React from 'react';

export class TableCell extends React.PureComponent{

  state = {
    error: null
  };

  componentDidCatch(err){
    this.setState({
      error: 'invalid data'
    })
  }

  render(){

    const { error } = this.state;

    const {
      formatter = (data, row, methods) => data,
      data,
      row,
      showHiddenBlock,
      closeHiddenBlock,
      getHiddenBlockState,
      cellClass,
      style,
      prevRow
    } = this.props;

    const methods = {
      showHiddenBlock,
      closeHiddenBlock,
      getHiddenBlockState,
      getPrevRow: () => prevRow
    };

    const printData = formatter(data, row, methods);

    return(
      <td
        style={ style || null }
        className={ cellClass }
      >
        { error || printData}
      </td>
    );
  }
}