import axios from "axios";

import { IConstrControlAcc } from "./types";
import { IListResponseData } from "types/ListResponse";

export const settingsProjectApi = {
  getConstControlAccounts: () => {
    return axios.get<IListResponseData<IConstrControlAcc>>("/construction_control/accounts/", {
      params: { limit: 500 },
    });
  },
  attachConstrControlToBuilding: (accountId: number, buildingId: string | number) => {
    return axios.post(`/construction_control/building/${buildingId}/set/`, { account_id: accountId });
  },
};
