export const serializeTimesheetApprovers = (approvers, userApproves) => {
  const userApprovesByUserId = {};
  userApproves.forEach((userApprove) => userApprovesByUserId[userApprove.user] = userApprove);

  return approvers.map(({ user, ...approver }) => ({
    ...approver,
    employee: user,
    is_approved: !!userApprovesByUserId[user.id]?.approved,
    approved_at: userApprovesByUserId[user.id]?.approve_at
  }));
};