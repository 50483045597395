import axios from "axios";
import moment from "moment";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { apiAddInterval } from "redux/modules/common/building/processApi";

import AddPlanMimsInModal from "components/UI/_TODO/AddingListInModal/AddPlanMimsInModal";
import InputNumber from "components/UI/atoms/InputNumber";

import useClosureDates from "../../../../../../shared/ui/inputs/ClosureDates/useClosureDates";
import ExpandableFilesInput from "../../../../../../shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";
import AddExpendituresInModal from "../../../AddingListInModal/AddExpendituresInModal";
import AddPlanMaterialsInModal from "../../../AddingListInModal/AddPlanMaterialsInModal";
import AddWorkersInModal from "../../../AddingListInModal/AddWorkersInModal";
import Button from "shared/ui/controls/ButtonBase";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import DateSelectOrPicker from "shared/ui/inputs/DateSelectOrPicker/DateSelectOrPicker";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";
import CreateAxesAndMarks from "widgets/AxesAndMarks/CreateAxesAndMarks";
import { sendMultiplePlansStateActions } from "widgets/SendMultiplePlansForApprove/model/sendMultiplePlansSlice";

import { useExpendituresInGroups } from "../../../AddingListInModal/hooks/useExpendituresInGroups";
import { useSelectingMaterials } from "../../../AddingListInModal/hooks/useSelectingMaterials";
import { useSelectingMims } from "../../../AddingListInModal/hooks/useSelectingMims";
import { useSelectingWorkers } from "../../../AddingListInModal/hooks/useSelectingWorkers";

import { formatMaterialCount } from "utils/formatters/formatMaterialCount";
import { sanitizeObject } from "utils/formatters/sanitizers";

import styles from "./index.module.scss";

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  product: any; // TODO: delete any;
  selectedWorkGroupId?: number | null;
  loadSectionGroups: () => void;
  sectionId: number;
  onPlanCreateSuccess: () => void;
  defaultDate?: string;
}

const CreatePlanModal: FC<IProps> = ({
  isOpen,
  handleClose,
  product,
  selectedWorkGroupId,
  loadSectionGroups,
  sectionId,
  onPlanCreateSuccess,
  defaultDate,
}) => {
  const dispatch = useDispatch();
  const [dateStart, setDateStart] = useState(defaultDate ? moment(defaultDate) : moment());
  const [dateEnd, setDateEnd] = useState(defaultDate ? moment(defaultDate) : moment());
  const [count, setCount] = useState("");
  const [files, setFiles] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [groupPaymentDate, setGroupPaymentDate] = useState();
  const [selectedClosureDateId, setSelectedClosureDateId] = useState(null);
  const [dynamicsData, setDynamicsData] = useState({});
  /* @ts-ignore */
  const { objectId } = useParams(); /* @ts-ignore */
  const { closureDatesOptions } = useClosureDates({ isOpen, objectId, sectionId });

  const presettedPercentage = useMemo(() => {
    if (!count) return 0;
    if (isNaN(Number(count))) return 0; /* @ts-ignore */
    return count / product.count;
  }, [count]);

  const {
    addedExpenditures,
    changeExpendituresCountHandler,
    validateSubmittingExpenditures,
    createGroupExpendituresPlan,
    workGroupMaterials,
    workGroupMims,
    clearExpendituresInGroups,
  } = useExpendituresInGroups({
    isOpen /* @ts-ignore */,
    selectedWorkGroupId,
    count /* @ts-ignore */,
    setCount,
    isPlan: true,
  });

  useEffect(() => {
    if (!isOpen) setIsPending(false);
  }, [isOpen]);

  const handleCloseModal = () => {
    loadSectionGroups();
    handleClose();
    clearMaterials();
    clearMims();
    clearWorkers();
    setCount("");
    clearExpendituresInGroups();
  };

  const {
    confirmHandler,
    cancelHandler,
    changeCountHandler,
    validateSubmitting,
    deleteAddedHandler,
    selectHandler,
    addedUsers,
    selectedUsers,
    selectedUsersIds,
    addedUsersIds,
    clearWorkers,
  } = useSelectingWorkers();

  const {
    confirmMaterialsHandler,
    cancelMaterialsHandler,
    changeCountMaterialsHandler,
    validateSubmittingMaterials,
    deleteAddedHandler: deleteAddedMaterial,
    deletedMaterials,
    selectMaterialsHandler,
    addedMaterials,
    selectedMaterials,
    selectedMaterialsIds,
    addedMaterialsIds,
    clearMaterials,
    setPresettedMaterials,
  } = useSelectingMaterials(workGroupMaterials);

  const {
    confirmMimsHandler,
    cancelMimsHandler,
    changeCountMimsHandler,
    validateSubmittingMims,
    deleteAddedHandler: deleteAddedMims,
    selectMimsHandler,
    addedMims,
    selectedMims,
    deletedMims,
    selectedMimsIds,
    addedMimsIds,
    clearMims,
    setPresettedMims /* @ts-ignore */,
  } = useSelectingMims(workGroupMims);

  const hasDefinedClosureDate = !!groupPaymentDate || !!selectedClosureDateId;

  const [savedData, setSavedData] = useState(null);

  useEffect(() => {
    if (isOpen) return; /* @ts-ignore */
    setGroupPaymentDate(null);
    setSelectedClosureDateId(null);
  }, [isOpen]);

  const handleSubmit = async () => {
    const hasValidWorkers = validateSubmitting();
    const hasValidMaterials = validateSubmittingMaterials();
    const hasValidMims = validateSubmittingMims();
    const hasValidExpenditures = validateSubmittingExpenditures();

    const paymentDate =
      closureDatesOptions.find((x) => x.id === selectedClosureDateId)?.payment_date ||
      moment(groupPaymentDate).format("YYYY-MM-DD");
    if (selectedWorkGroupId && hasValidWorkers && hasValidMaterials && hasValidMims && hasValidExpenditures) {
      const data = {
        startDate: moment(dateStart).format("YYYY-MM-DD"),
        endDate: moment(dateEnd).format("YYYY-MM-DD"),
        paymentDate: hasDefinedClosureDate
          ? selectedClosureDateId
            ? paymentDate
            : groupPaymentDate
          : moment(dateEnd).format("YYYY-MM-DD"),
        closureDateId: selectedClosureDateId,
        addedMims,
        addedMaterials,
        addedWorkers: addedUsers,
        callback: (id: any) => {
          setSavedData({ id } as any);
          onPlanCreateSuccess?.();
          dispatch(sendMultiplePlansStateActions.invalidateKey());
        },
      }; /* @ts-ignore */
      await createGroupExpendituresPlan(sanitizeObject(data, null));
    } else if (hasValidWorkers && hasValidMaterials && hasValidMims) {
      setIsPending(true);
      const data = {
        start_at: moment(dateStart).format("YYYY-MM-DD"),
        end_at: moment(dateEnd).format("YYYY-MM-DD"),
        count: count || 0,
        workers: addedUsers,
        expenditure_id: product.id,
        planned_materials: addedMaterials.map((el) => ({
          material_id: el.id /* @ts-ignore */,
          count: formatMaterialCount(el.local_count || el.count, el.material?.measure || el.measure),
        })),
        count_planned_services: addedMims.map((el) => ({
          service_id: el.id /* @ts-ignore */,
          count: formatMaterialCount(el.local_count || el.count, el.material?.measure || el.measure),
        })),
        payment_date: hasDefinedClosureDate ? paymentDate : moment(dateEnd).format("YYYY-MM-DD"),
      };

      if (!!selectedClosureDateId) {
        /* @ts-ignore */
        data.close_date_id = selectedClosureDateId;
      }

      if (addedUsers) {
        /* @ts-ignore */
        data.count_workers = addedUsers.map((item) => ({ worker_id: item.id, count: item.count }));
      }
      await apiAddInterval(objectId, product.id, data, false, {
        /* @ts-ignore */
        successCall: ({ data }) => {
          setSavedData(data);
          onPlanCreateSuccess?.();
          dispatch(sendMultiplePlansStateActions.invalidateKey());
          setTimeout(() => {
            handleClose();
          }, 1);
        },
        files,
        finallyCall: () => {
          setIsPending(false);
        },
      });
    }
  };

  const expendituresIds = useMemo(() => {
    if (selectedWorkGroupId) {
      return addedExpenditures?.map((el) => el.id) ?? [];
    }
    return [product.id];
  }, [selectedWorkGroupId, addedExpenditures, product.id]);

  useEffect(() => {
    axios
      .get(`/building/${objectId}/interval_indicators/${selectedWorkGroupId ? "group" : "expenditure"}/${product.id}/`)
      .then((data) => {
        setDynamicsData(data.data);
      });
  }, []);

  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={handleCloseModal} className={styles.sliderClassName}>
        <div className={styles.container}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>{product.name}</div>
          </div>
          <div className={styles.dinamics}>
            <div className={styles.bigFieldTitle}>Количество: {product.measure && `(${product.measure})`}</div>
            <div className={styles.dinamicsContainer}>
              <div>
                <span>По смете:</span>
                {/* @ts-ignore */}
                <p>{dynamicsData.estimate}</p>
              </div>
              <div>
                <span>К сдаче:</span>
                {/* @ts-ignore */}
                <p>{dynamicsData.todo}</p>
              </div>
              <div>
                <span>Сдано:</span>
                {/* @ts-ignore */}
                <p>{dynamicsData.completed}</p>
              </div>
              <div>
                <span>Остаток:</span>
                {/* @ts-ignore */}
                <p>{dynamicsData.left}</p>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.hiddenWrapper}>
              <div className={styles.bigFieldTitle}>Добавить в план:</div>
              <div className={styles.containerPlan}>
                <div className={styles.leftSide}>
                  <div className={styles.fieldTitle}>Количество</div>
                  <div className={styles.fieldValue}>
                    {/* @ts-ignore */}
                    <InputNumber value={count} setValue={setCount} decimalPlace="4" />
                  </div>
                </div>
                <div className={styles.rightSide}>
                  <span>Спланировано:</span>
                  <div>
                    {/* @ts-ignore */}
                    <p className={styles.planText}>{dynamicsData.planned}</p>
                  </div>
                </div>
              </div>

              <div className={styles.multipleFieldRow}>
                <div>
                  <div className={styles.fieldTitle}>Период выполнения</div>
                  <div className={styles.fieldRow}>
                    <div className={styles.fieldCalendar}>
                      <CalendarRange
                        defaultDateStart={dateStart} /* @ts-ignore */
                        setDefaultDateStart={setDateStart}
                        defaultDateEnd={dateEnd} /* @ts-ignore */
                        setDefaultDateEnd={setDateEnd}
                        classNamePopup={styles.rightCalendar}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.fieldTitle}>Плановая дата сдачи</div>
                  <div className={styles.fieldRow}>
                    <div className={styles.fieldCalendar}>
                      <DateSelectOrPicker
                        options={closureDatesOptions}
                        onPickerChange={(date) => {
                          setSelectedClosureDateId(null); /* @ts-ignore */
                          setGroupPaymentDate(date);
                        }}
                        onSelectChange={(id) => {
                          /* @ts-ignore */
                          setSelectedClosureDateId(id);
                        }} /* @ts-ignore */
                        selectValue={selectedClosureDateId} /* @ts-ignore */
                        pickerValue={groupPaymentDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {isOpen && (
                <AddWorkersInModal
                  addedUsers={addedUsers}
                  selectedUsers={selectedUsers}
                  onAccept={confirmHandler}
                  onDecline={cancelHandler}
                  onChangeCount={changeCountHandler}
                  onSelect={selectHandler}
                  selectedIds={selectedUsersIds}
                  onDelete={deleteAddedHandler}
                  addedIds={addedUsersIds}
                />
              )}
              {isOpen && !!selectedWorkGroupId && (
                <AddExpendituresInModal
                  addedExpenditures={addedExpenditures}
                  onChangeCount={changeExpendituresCountHandler}
                />
              )}
              {isOpen && (
                <AddPlanMaterialsInModal
                  addedMaterials={addedMaterials}
                  selectedMaterials={selectedMaterials}
                  onAccept={confirmMaterialsHandler}
                  onDecline={cancelMaterialsHandler}
                  onChangeCount={changeCountMaterialsHandler}
                  onSelect={selectMaterialsHandler}
                  selectedIds={selectedMaterialsIds}
                  onDelete={deleteAddedMaterial}
                  addedIds={addedMaterialsIds}
                  expenditureId={product.id}
                  presettedPercentage={presettedPercentage}
                  onAddPresettedMaterials={setPresettedMaterials} /* @ts-ignore */
                  wholeWorkCount={count} /* @ts-ignore */
                  defaultMaterialsList={!!selectedWorkGroupId ? [...addedMaterials, ...deletedMaterials] : undefined}
                  isWorkGroup={!!selectedWorkGroupId}
                />
              )}
              {isOpen && (
                <AddPlanMimsInModal
                  addedMims={addedMims}
                  selectedMims={selectedMims}
                  onAccept={confirmMimsHandler}
                  onDecline={cancelMimsHandler}
                  onChangeCount={changeCountMimsHandler}
                  onSelect={selectMimsHandler}
                  selectedIds={selectedMimsIds}
                  onDelete={deleteAddedMims}
                  addedIds={addedMimsIds}
                  expenditureId={product.id}
                  presettedPercentage={presettedPercentage}
                  onAddPresettedMims={setPresettedMims} /* @ts-ignore */
                  wholeWorkCount={count}
                  defaultMimsList={!!selectedWorkGroupId ? [...addedMims, ...deletedMims] : undefined}
                  isWorkGroup={!!selectedWorkGroupId}
                />
              )}
              <CreateAxesAndMarks
                isOpen={isOpen}
                planOrFact="plan"
                groupOrWork={!!selectedWorkGroupId ? "group" : "work"}
                /* @ts-ignore */
                expenditureId={savedData?.id}
                triggerForSave={!!savedData}
                expenditureIds={expendituresIds}
              />
              {!selectedWorkGroupId && (
                <ExpandableFilesInput files={files} setFiles={setFiles} canExtractFiles={false} />
              )}
            </div>
          </div>
          <div className={styles.actionBlock}>
            <Button type="button" medium secondary disabled={false} onClick={handleCloseModal}>
              Отменить
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button medium primary onClick={handleSubmit} disabled={!count} isLoading={isPending}>
              Сохранить
            </Button>
          </div>
        </div>
      </SliderModal>
    </>
  );
};

export default CreatePlanModal;
