import { message } from "antd";
import { Dispatch } from "redux";

import { projectsApi } from "./api";
import { projectsActions } from "./projectsSlice";
import { RootState } from "app/store/rootReducer";

import { ICreateProject } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const createProject = (data: ICreateProject, successCallback: (id: number) => void) => (dispatch: Dispatch) => {
  const pendingKey = generateStorageKey({ type: "pending" });
  dispatch(projectsActions.setIsLoading({ key: pendingKey, status: true }));
  projectsApi
    .create(data)
    .then(({ data }) => {
      message.success("Сохранено");
      successCallback(data.id);
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(projectsActions.setIsLoading({ key: pendingKey, status: false }));
    });
};

export const getProjectsList = (params: any) => (dispatch: Dispatch, getState: () => RootState) => {
  if (params.project == 0) {
    delete params.project;
  }
  const key = generateStorageKey({ projectId: 0, ...params });
  if (!getState().projectsV2.projectsLists[key]?.length) {
    dispatch(projectsActions.setIsLoading({ key, status: true }));
  }
  projectsApi
    .getList(params)
    .then(({ data }) => {
      dispatch(projectsActions.setProjectLists({ key, projects: data.results, count: data.count }));
    })
    .finally(() => {
      dispatch(projectsActions.setIsLoading({ key, status: false }));
    });
};

export const getDetailedProject = (projectId: number | string) => (dispatch: Dispatch, getState: () => RootState) => {
  const key = generateStorageKey({ projectId });
  if (!getState().projectsV2.projectsDetailed[key]) {
    dispatch(projectsActions.setIsLoading({ key, status: true }));
  }
  projectsApi
    .getDetailed(projectId)
    .then(({ data }) => {
      dispatch(projectsActions.setDetailed({ key, project: data }));
    })
    .finally(() => {
      dispatch(projectsActions.setIsLoading({ key, status: false }));
    });
};

export const editProject = (projectId: string, data: any) => (dispatch: Dispatch, getState: () => RootState) => {
  const pendingKey = generateStorageKey({ type: "pending" });
  const key = generateStorageKey({ projectId });
  if (!getState().projectsV2.projectsDetailed[key]) {
    dispatch(projectsActions.setIsLoading({ key: pendingKey, status: true }));
  }
  projectsApi
    .edit(projectId, data)
    .then(({ data }) => {
      dispatch(projectsActions.setDetailed({ key, project: data }));
      message.success("Сохранено");
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(projectsActions.setIsLoading({ key: pendingKey, status: false }));
    });
};
