import React from 'react';

export default function Line({ width = '1', height='35', color='#C7C7C7'}) {
  return (
    <svg 
      width={width} 
      height={height}
      viewBox={`0 0 2 37`}
      fill='none' 
      xmlns='http://www.w3.org/2000/svg'
    >
      <path 
        d='M2.66602 1C2.66602 0.447715 2.2183 0 1.66602 0C1.11373 0 0.666016 0.447715 0.666016 1V36C0.666016 36.5523 1.11373 37 1.66602 37C2.2183 37 2.66602 36.5523 2.66602 36V1Z'
        fill={color} 
      />
    </svg>
  );
}