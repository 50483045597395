import { IExpenditure } from "../../../../../types/interfaces/Expenditure";
import axios from "axios";
import { IGroupTotals } from "../types";
import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

interface ICalculateResponse {
  expenditures: { id: number, estimated_cost: string }[];
  totals: IGroupTotals;
}

export const groupApi = {
  calculateAmount: (
    buildingId: string,
    amount: string,
    expenditures: IExpenditure[],
    callback?: (data: ICalculateResponse) => void
  ) => {
    return axios.post<ICalculateResponse>(`/building/${buildingId}/groups/calculate/all/`, {
      all: amount,
      expenditures: expenditures.map(exp => exp.id)
    }).then(({ data }) => {
      callback?.(data);
    }).catch(errorCatcher);
  },

  calculateTotals: (
    buildingId: string,
    totals: IGroupTotals,
    changedTotalName: keyof Omit<IGroupTotals, "all">,
    expenditures: IExpenditure[],
    callback?: (data: ICalculateResponse) => void
  ) => {
    return axios.post<ICalculateResponse>(`/building/${buildingId}/groups/calculate/totals/`, {
      totals,
      changed_total: changedTotalName,
      expenditures: expenditures.map(exp => exp.id)
    }).then(({ data }) => {
      callback?.(data);
    }).catch(errorCatcher);
  }
};