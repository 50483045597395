import cn from "classnames";
import React, { useMemo } from "react";

import ApprovalBar from "../../../../../../../../shared/ui/dataDisplay/ApprovalBar/ApprovalBar";

import { IApprovalInModal } from "../../types";

import styles from "./ApprovalChain.module.scss";

export interface IApprovalChainProps {
  chainedApprovals: (IApprovalInModal & { position: number })[];
  planCreatingDate: string;
}

const ApprovalChain: React.FC<IApprovalChainProps> = ({ chainedApprovals, planCreatingDate }) => {
  const displayedApprovals = useMemo(
    () => [...chainedApprovals].sort((a, b) => a.position - b.position),
    [chainedApprovals]
  );

  return (
    <div className={styles.chain}>
      {/* {displayedApprovals.reverse().map((approval, index, arr) => { */}

      {displayedApprovals.map((approval, index, arr) => {
        const nextApproval = arr[index + 1];
        const prevApproval = arr[index - 1];
        return (
          <React.Fragment key={`${approval.id}_${approval.is_confirmed}_${nextApproval?.is_confirmed}`}>
            <ApprovalBar
              workerName={approval.user.name}
              approvedAt={approval.confirmed_at}
              sendToApproveAt={prevApproval !== undefined ? prevApproval.confirmed_at : planCreatingDate}
              isApproved={approval.is_confirmed}
            />
            {!!nextApproval && (
              <hr
                className={cn(styles.chainLine, {
                  [styles.nextWaiting]: !nextApproval?.is_confirmed,
                  [styles.nextConfirmed]: nextApproval?.is_confirmed,
                })}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(ApprovalChain);
