import cn from "classnames";
import React from "react";

import SearchSelectFilter from "components/UI/atoms/_TODO/SearchSelectFilter";

import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";

import { statusFilterDataJournalDelivery } from "../../constants";

import styles from "./JournalDeliveryTableHead.module.scss";

interface IProps {
  onChangeFilter: (name: string, value: string) => void;
  setIsShow: (val: boolean) => void;
  isShow: boolean;
  hideApprovals: boolean;
}

const JournalDeliveryTableHead: React.FC<IProps> = ({ onChangeFilter, setIsShow, isShow, hideApprovals }) => {
  return (
    <TableReusableHead className={cn(styles.row, { [styles.rowWithoutApproving]: hideApprovals })}>
      <TableReusableHeaderCell filterType="text" filterField="id" filterTitle="Номер" onChangeFilter={onChangeFilter}>
        №
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>Наименование</TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterType="text"
        filterField="author"
        filterTitle="Сдал"
        onChangeFilter={onChangeFilter}
      >
        Сдал
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>Дата</TableReusableHeaderCell>
      <TableReusableHeaderCell>К сдаче</TableReusableHeaderCell>
      {!hideApprovals && (
        <>
          <TableReusableHeaderCell className={styles.centered}>Замечания</TableReusableHeaderCell>
          <TableReusableHeaderCell>Согласование</TableReusableHeaderCell>
        </>
      )}
      <TableReusableHeaderCell>
        Принято
        <SearchSelectFilter
          title="Статус"
          setFilter={setIsShow}
          last
          filterName={statusFilterDataJournalDelivery} /* @ts-ignore */
          defaultValue={isShow}
        />
      </TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default JournalDeliveryTableHead;
