import axios from "axios";

import { Checkpoint, CheckpointEditValues } from "../types";
import { IFilesBase64Format } from "types/interfaces/Files";

export const addCheckpointApi = {
  post: (values: Omit<CheckpointEditValues, "files"> & { files: IFilesBase64Format[] }) => {
    return axios.post<Checkpoint>("/check_points/", values);
  },
};
