import React, { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ks2SectionsAttachmentsSelector,
  passportsInvalidateKeySelector,
  passportsPendingSelector,
} from "redux/modules/common/passportsAndCertificates/selectors";
import {
  attachDocumentToKs2Section,
  attachFileToKs2Section,
  getKs2SectionAttachments,
} from "redux/modules/common/passportsAndCertificates/thunks";
import { ICreatePassport } from "redux/modules/common/passportsAndCertificates/types";

import CommonFilesModal from "./CommonFilesModal";
import { passportsApi } from "../../../redux/modules/common/passportsAndCertificates/api";
import { passportsActions } from "../../../redux/modules/common/passportsAndCertificates/actions";
import { errorCatcher } from "../../../utils/helpers/errorCatcher";

interface IProps {
  isOpen: boolean;
  onClose: (event?: MouseEvent) => void;
  ks2Id: number;
  sectionId: number;
}

const FilesModalKs2Section: FC<IProps> = ({ isOpen, onClose, ks2Id, sectionId }) => {
  const dispatch = useDispatch();
  const invalidateKey = useSelector(passportsInvalidateKeySelector);
  const isPending = useSelector(passportsPendingSelector);
  const attachments = useSelector(ks2SectionsAttachmentsSelector)[`${ks2Id}_${sectionId}`];
  const onUpload = useCallback(
    (file: File) => {
      dispatch(attachFileToKs2Section(file, ks2Id, sectionId));
    },
    [ks2Id, sectionId]
  );

  const onSubmit = useCallback(
    (values: ICreatePassport) => {
      dispatch(attachDocumentToKs2Section(values, ks2Id, sectionId));
    },
    [ks2Id, sectionId]
  );

  useEffect(() => {
    if (!isOpen) return;
    dispatch(getKs2SectionAttachments(ks2Id, sectionId));
  }, [isOpen, ks2Id, invalidateKey, sectionId]);

  const onDirectlyDelete = useCallback((id: number) => {
    passportsApi.hideDocumentInKs2Section(ks2Id, id)
      .then(() => {
        dispatch(passportsActions.invalidateKey());
      })
      .catch(errorCatcher)
  }, [dispatch, ks2Id])

  return (
    <CommonFilesModal
      isOpen={isOpen}
      onClose={onClose}
      type="section"
      onSubmit={onSubmit}
      onUpload={onUpload}
      isPending={isPending}
      attachments={attachments?.results}
      invalidateKey={invalidateKey}
      onDirectlyDelete={onDirectlyDelete}
    />
  );
};

export default React.memo(FilesModalKs2Section);
