import React from "react";

function ArrowFill({ color = "#D8D8D8", width = "19", rotate = "0", ...props }) {
  return (
    <svg
      width={width}
      height="10"
      viewBox="0 0 19 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotate}deg)` }}
      {...props}
    >
      <path d="M18.0039 0.857422L9.00391 9.85742L0.00390625 0.857422" fill={color} />
    </svg>
  );
}

export default React.memo(ArrowFill);
