import React, { Fragment, useEffect } from "react";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { ImproveSection } from "./ImproveSection/ImproveSection";
import { EnvironmentSection } from "./EnvironmentSection/EnvironmentSection";
import { ProductionSection } from "./ProductionSection/ProductionSection";
import { BusinessSection } from "./BusinessSection/BusinessSection";
import { EffectSection } from "./EffectSection/EffectSection";
import { RequestModal } from "../RequestModal/RequestModal";

import { LandingPagesRoutesEnum } from "../../lib/landingPagesRoutesEnum";

export const HomePage = () => {
  return (
    <Fragment>
      <ImproveSection />
      <EnvironmentSection />
      <ProductionSection />
      <BusinessSection />
      <EffectSection />
      <Footer />
      <RequestModal />
    </Fragment>
  )
}