import { message } from "antd";
import axios from "axios";
import { compose } from "redux";

import { addEmployeesAction } from "../actions";

import { MESSAGES } from "../constants";

import { errorCatcher } from "../../../../../../utils/helpers/errorCatcher";

const addEmployees = (employees, options) => async (dispatch) =>
  axios
    .post(`/${options.userType}/orders/${options.orderId}/${options.approvalType}/employees/`, employees)
    .then((response) => {
      compose(dispatch, addEmployeesAction)(response.data, options.approvalType);
      message.success(MESSAGES[options.approvalType].ADDED);
    })
    .catch(errorCatcher);

export { addEmployees };
