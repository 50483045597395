import { messageErrorHandler } from "./errorHandler";

export interface IReason {
  response: {
    data: {
      errors: [];
    };
    status: number;
  };
}

export const errorCatcher = (reason: IReason) => {
  if (reason?.response?.status === 400) {
    messageErrorHandler(reason?.response?.data?.errors);
  } else {
    if (reason?.response?.status === 404) {
      //messageErrorHandler(reason?.response?.data?.errors);
    } else {
      console.log(reason);
    }
  }
};
