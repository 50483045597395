import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { prepareFiles } from "../components/pages/Requisition/components/ProductFilesModal/utils";

import { IFile } from "../types/interfaces/Files";

import downloadFileByUrl from "../utils/helpers/downloadFileByUrl";
import { errorCatcher } from "../utils/helpers/errorCatcher";

export interface IUseProductFilesModalProps {
  productFiles: any;
  isOpen: boolean;
  uploadFilesCallback?: (addedFiles: any[]) => void;
  deleteFilesCallback?: (file: any) => void;
  fetchFilesCallback?: () => void;
  buildingId?: number | string;
  itemId?: number | string;
  onSuccess?: () => void;
}

export const useProductFilesModal = ({
  productFiles,
  isOpen,
  fetchFilesCallback,
  uploadFilesCallback,
  deleteFilesCallback,
  buildingId,
  itemId,
  onSuccess,
}: IUseProductFilesModalProps) => {
  const [files, setFiles] = useState<IFile[]>([]);
  const [filesWasUploaded, setFilesWasUploaded] = useState(false);
  const [invalidateKey, setInvalidateKey] = useState(0);

  useEffect(() => setFiles(productFiles), [productFiles]);

  useEffect(() => {
    if (!filesWasUploaded && fetchFilesCallback && isOpen) {
      setFilesWasUploaded(true);
      fetchFilesCallback();
    }
  }, [isOpen, filesWasUploaded, invalidateKey]);

  const handleFilesChange = (files: React.SetStateAction<IFile[]>) => {
    setFiles(files);
  };

  const uploadFiles = async (files: any[]) => {
    const formData = new FormData();
    if (Array.isArray(files)) {
      files.map((file) => formData.append("files", file));
    } else {
      formData.append("files", files);
    }

    if (uploadFilesCallback) {
      uploadFilesCallback(files);
    } else {
      await axios
        .post(`/building/${buildingId}/materials/${itemId}/files/`, formData)
        .then((resp) => {
          setInvalidateKey((prev) => prev + 1);
          onSuccess?.();
        })
        .catch(errorCatcher);
    }
  };

  const deleteFile = async (file: IFile) => {
    if (deleteFilesCallback) {
      deleteFilesCallback(file);
    } else {
      await axios.delete(`/building/${buildingId}/materials/${itemId}/files/${file.id}/`).then(() => {
        setInvalidateKey((prev) => prev + 1);
        onSuccess?.();
      });
    }
  };

  const downloadFiles = () => {
    /* @ts-ignore */
    const isNewFiles = files.some((el) => !el.file.link);
    if (isNewFiles) {
      message.info("Сохраните прежде, чем выгружать");
      return;
    }
    files.forEach((file) => {
      /* @ts-ignore */
      if (file.file?.link) {
        /* @ts-ignore */
        downloadFileByUrl(file.file.link || file.file, file.file.name || file.name);
      }
    });
  };

  const preparedFiles = prepareFiles(files);

  const onUpload = async (file: File) => {
    await uploadFiles([file]);
  };

  return {
    files,
    preparedFiles,
    downloadFiles,
    handleFilesChange,
    onUpload,
    deleteFile,
  };
};
