import React, { ReactNode } from "react";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";

import ksIcon from "../common/JournalActingExpenditure/ksIcon";
import successIcon from "./../assets/successIcon.svg";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import DownloadButton from "shared/ui/controls/DownloadButton/DownloadButton";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import InputBase from "shared/ui/inputs/InputBase";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import styles from "./JournalActingModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  count: number;
  actNumber: string;
  onChangeAct: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dateStart: string;
  dateEnd: string;
  onChangeDateStart: (date: string) => void;
  onChangeDateEnd: (date: string) => void;
  onSubmit: () => void;
  isPending?: boolean;
  isEdit?: boolean;
  isSuccess?: boolean;
  isShowSubmit: boolean;
  onDownload?: () => void;
  additionalHeader?: ReactNode;
}

const JournalActingModalContainer: React.FC<IProps> = ({
  isOpen,
  onClose,
  children,
  title = "Формирование акта КС-2",
  count,
  actNumber,
  onChangeAct,
  dateEnd,
  dateStart,
  onChangeDateEnd,
  onChangeDateStart,
  onSubmit,
  isEdit,
  isPending,
  isSuccess,
  isShowSubmit,
  onDownload,
  additionalHeader,
}) => {
  return (
    <SliderModal isOpen={isOpen} closeHandler={onClose} className={styles.modal}>
      <header className={styles.head}>
        <div className={styles.firstLine}>
          <h2 className={styles.title}>{title}</h2>
          {additionalHeader}
          {onDownload && <DownloadButton onDownload={onDownload} />}
        </div>
        <p className={styles.subtitle}>Итого расценок: {count || "0"}</p>
        {!isPending && (
          <div className={styles.headControls} key={String(isOpen)}>
            <div className={styles.inputPair}>
              <label>№ акта</label> {/* @ts-ignore */}
              <InputBase onChange={onChangeAct} value={actNumber ?? ""} className={styles.input} />
            </div>
            <div className={styles.inputPair}>
              <label>Отчетный период</label>
              <CalendarRange
                defaultDateStart={dateStart}
                defaultDateEnd={dateEnd}
                setDefaultDateStart={onChangeDateStart}
                setDefaultDateEnd={onChangeDateEnd}
                classNameSelect={styles.input}
              />
            </div>
          </div>
        )}
      </header>
      {!isPending && <div className={styles.list}>{children}</div>}
      {isPending && (
        <div>
          <EmptyPlaceholder svgJsx={ksIcon} fullHeight={false} text="" />
          {!isSuccess && <Spinner isStatic className={styles.spinner} />}
          {!isSuccess && <div className={styles.pendingText}>{isEdit ? "Сохранение" : "Формирование"} акта…</div>}
          {isSuccess && <img src={successIcon} className={styles.spinner} />}
          {isSuccess && <div className={styles.pendingText}>Акт {isEdit ? "сохранен" : "сформирован"}</div>}
        </div>
      )}
      <BottomControls isExists={!isPending && isShowSubmit}>
        <div className={styles.bottom}>
          {isEdit && (
            <ButtonBase secondary onClick={onClose}>
              Отменить
            </ButtonBase>
          )}
          {isEdit && (
            <ButtonBase primary onClick={onSubmit}>
              Сохранить
            </ButtonBase>
          )}
          {!isEdit && (
            <ButtonBase primary onClick={onSubmit}>
              Сформировать
            </ButtonBase>
          )}
        </div>
      </BottomControls>
    </SliderModal>
  );
};

export default React.memo(JournalActingModalContainer);
