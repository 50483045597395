import axios from "axios";

import { IV2SummaryObjectResponse, IV2SummaryProjectsListResponse } from "./types";

import downloadFile from "utils/helpers/download";

export const summaryApi = {
  getListFull: () => {
    return axios.get<IV2SummaryProjectsListResponse>(`/tree/projects/finance_pivot/`);
  },
  getListByProject: (projectId: number) => {
    return axios.get<IV2SummaryProjectsListResponse>(`/tree/projects/finance_pivot/`, {
      params: { project_id: projectId },
    });
  },
  getDetailedObject: (objectId: number) => {
    return axios.get<IV2SummaryObjectResponse>(`/tree/finance_pivot/${objectId}/`);
  },
  downloadReport: (buildingId: number, reportName: string) => {
    downloadFile(`${axios.defaults.baseURL}/buildings/${buildingId}/finances/pivot/report/`, reportName, "POST");
  },
};
