import React from 'react';

export const OslaPaginator = methods => {

  const getLi = () => {
    const middleDelta = Math.floor(methods.getMaxValue() / 2);
    const nowPage = methods.getPage() - 1;
    let startPage = 0;
    let displayRange =
      methods.getPagesCount() < methods.getMaxValue() ? methods.getPagesCount() : methods.getMaxValue();
    if ( nowPage > middleDelta)
      startPage = nowPage - middleDelta;
    if ( startPage + methods.getMaxValue() >= methods.getPagesCount() )
      startPage = startPage - (startPage + methods.getMaxValue() - methods.getPagesCount());
    if ( startPage < 0 ) {
      startPage = 0;
      displayRange = methods.getPagesCount();
    }
    let liArr=[];
    for (let i = startPage; i < ((startPage + displayRange) || 1); i++) {
      liArr.push(
        <li
          key={i}
          className={'page-item'}
        >
          <a
            onClick={ () => methods.setPage(methods.getPage(), i)}
            className={`${methods.getPage() === i + 1 ? 'active' : ''} page-link`}
          >
            {i + 1}
          </a>
        </li>
      );
    }
    return liArr;
  };

  const backButtons = (
    <React.Fragment>
      {
        methods.getPage() > Math.ceil(methods.getMaxValue() /2 ) ?
          <li className={'page-item'} >
            <a
              onClick={ () => methods.setPageBack( methods.getPage() - 1 ) }
              className={`page-link page-link_double-prev`}
            >
            </a>
          </li>
          :
          null
      }
      {
        methods.getPage() > 1 ?
          <li className={'page-item'} >
            <a
              className={`page-link page-link_prev`}
              onClick={ () => methods.setPageBack(1) }
            >
            </a>
          </li>
          :
          null
      }
    </React.Fragment>
  );

  const frontButtons = (
    <React.Fragment>
      {
        methods.getPage() < methods.getPagesCount() ?
          <li className={'page-item'} >
            <a
              className={`page-link page-link_next`}
              onClick={ () => methods.setPageForward(1) }
            >
            </a>
          </li>
          :
          null
      }
      {
        methods.getPage() <= methods.getPagesCount() - Math.ceil(methods.getMaxValue() /2 ) ?
          <li className={'page-item'} >
            <a
              className={`page-link page-link_double-next`}
              onClick={ () => methods.setPageForward(methods.getPagesCount() - methods.getPage()) }
            >
            </a>
          </li>
          :
          null
      }
    </React.Fragment>
  );

  return(
    <div className="custom-table__pagination">
      <ul className={'pagination'}>
        {
          backButtons
        }
        { getLi() }
        {
          frontButtons
        }
      </ul>
    </div>
  );

};
