import cn from "classnames";
import React from "react";

import { BetonExpenseStatusNamesEnum, IBetonExpenseItem } from "redux/modules/common/building/beton/types";

import styles from "./BetonExpenseStatus.module.scss";

interface IProps {
  status: IBetonExpenseItem["status"];
}

const BetonExpenseStatus: React.FC<IProps> = ({ status }) => {
  return <div className={cn(styles.container, styles[status])}>{BetonExpenseStatusNamesEnum[status]}</div>;
};

export default BetonExpenseStatus;
