import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

import { IFileStorageState } from "./types";

const stateSelector = (state: RootState): IFileStorageState => state.fileStorage;

export const filesByObjectIdSelector = createSelector(stateSelector, (state) => state.byObjectId);

export const filesByFolderIdSelector = createSelector(stateSelector, (state) => state.byFolderId);

export const filesCountsSelector = createSelector(stateSelector, (state) => state.countByObjectId);

export const fileStorageObjectsSelector = createSelector(stateSelector, (state) => state.objects);
