import axios from "axios";
import { Dispatch } from "redux";

import { getName, getParentId } from "./selectors";
import { actions } from "./slice";
import { RootState } from "app/store/rootReducer";

import ISection from "../../../types/interfaces/Section";

import { IReason, errorCatcher } from "../../../utils/helpers/errorCatcher";

export const createOutOfEstimateSection =
  (callback?: (v: ISection) => void) => async (dispatch: Dispatch, getState: () => RootState) => {
    const rootState = getState();

    const buildingId = rootState?.nowObject?.detail?.data?.id;
    const parent_id = getParentId(rootState);
    const name = getName(rootState);

    if (buildingId) {
      dispatch(actions.setIsCreating(true));

      try {
        const response = await axios.post<ISection>(`/building/${buildingId}/estimate/sections/default/new/`, {
          name,
          parent_id,
        });

        dispatch(actions.clear());
        callback?.(response.data);
      } catch (e) {
        errorCatcher(e as IReason);
      } finally {
        dispatch(actions.setIsCreating(false));
      }
    }
  };
