import axios from "axios";
import { FC, memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { passportsActions } from "../../../redux/modules/common/passportsAndCertificates/actions";
import { passportsApi } from "../../../redux/modules/common/passportsAndCertificates/api";
import {
  ks2AttachmentsSelector,
  passportsInvalidateKeySelector,
  passportsPendingSelector,
} from "redux/modules/common/passportsAndCertificates/selectors";
import {
  attachDocumentToKs2,
  attachFileToKs2,
  getKs2Attachments,
} from "redux/modules/common/passportsAndCertificates/thunks";
import { ICreatePassport } from "redux/modules/common/passportsAndCertificates/types";

import CommonFilesModal from "./CommonFilesModal";

import { errorCatcher } from "../../../utils/helpers/errorCatcher";

interface IProps {
  isOpen: boolean;
  onClose: (event?: MouseEvent) => void;
  ks2Id: number;
}

const FilesModalKs2: FC<IProps> = ({ isOpen, onClose, ks2Id }) => {
  const dispatch = useDispatch();
  const invalidateKey = useSelector(passportsInvalidateKeySelector);
  const isPending = useSelector(passportsPendingSelector);
  const attachments = useSelector(ks2AttachmentsSelector)[ks2Id];
  const onUpload = useCallback(
    (file: File) => {
      dispatch(attachFileToKs2(file, ks2Id));
    },
    [ks2Id]
  );

  const onSubmit = useCallback(
    (values: ICreatePassport) => {
      dispatch(attachDocumentToKs2(values, ks2Id));
    },
    [ks2Id]
  );

  useEffect(() => {
    if (!isOpen) return;
    dispatch(getKs2Attachments(ks2Id));
  }, [isOpen, ks2Id, invalidateKey]);

  const onDirectlyDelete = useCallback(
    (id: number) => {
      passportsApi
        .hideDocumentInKs2(ks2Id, id)
        .then(() => {
          dispatch(passportsActions.invalidateKey());
        })
        .catch(errorCatcher);
    },
    [dispatch, ks2Id]
  );

  return (
    <CommonFilesModal
      isOpen={isOpen}
      onClose={onClose}
      type="ks2"
      onSubmit={onSubmit}
      onUpload={onUpload}
      isPending={isPending}
      attachments={attachments?.results}
      invalidateKey={invalidateKey}
      onDirectlyDelete={onDirectlyDelete}
      isFileViewer
    />
  );
};

export default memo(FilesModalKs2);
