import { message } from "antd";
import cn from "classnames";
import React, { useCallback } from "react";
import { Field, FieldInputProps } from "react-final-form";

import { IAosrDoc } from "redux/modules/common/building/journal/aosr/types";

import Select, { IOption } from "components/UI/atoms/Select";

import Actions from "shared/ui/controls/Actions/Actions";
import FileRow from "shared/ui/dataDisplay/FileRow/FileRow";
import Calendar from "shared/ui/inputs/Calendar/Calendar";
import FileUploader from "shared/ui/inputs/FileUploader/FileUploader";
import InputBase from "shared/ui/inputs/InputBase";

import { aosrDocsTypes } from "../constants";
import { IFile } from "types/interfaces/Files";

import { composeFieldValidators, required } from "utils/formHelpers/validations";

import styles from "./AosrDocumentAdd.module.scss";

interface IProps {
  index: number;
  remove: (index: number) => void;
  rowClassName?: string;
  isEditMode?: boolean;
  item?: IAosrDoc;
  onDirectlyDelete?: (item: IAosrDoc) => void;
  isHidden?: boolean;
  typeOptions?: IOption[];
}

const AosrDocumentAdd: React.FC<IProps> = ({
  index,
  remove,
  rowClassName,
  isEditMode,
  item,
  onDirectlyDelete,
  isHidden,
  typeOptions = aosrDocsTypes,
}) => {
  const removeHandler = useCallback(
    (input: FieldInputProps<any, HTMLElement>) => {
      if (index === 0) {
        message.error("Нельзя удалить единственный документ");
        return;
      }

      input?.onChange(undefined);
      remove(index);
      onDirectlyDelete?.(item!);
    },
    [index, remove, item, onDirectlyDelete]
  );

  if (isHidden) return null;

  return (
    <div className={styles.item}>
      <div className={cn(styles.row1, rowClassName)}>
        <Field
          name={`docs[${index}].title`} /* @ts-ignore */
          validate={composeFieldValidators(required())}
          render={({ input, meta }) => (
            <>
              <InputBase
                input={input}
                //@ts-ignore
                meta={meta}
                label="Наименование"
              />
              {/* @ts-ignore */}
              <Actions isThinCross canRemove onRemoveDirectly={() => removeHandler(input)} className={styles.remove} />
            </>
          )}
        />
      </div>
      <div className={cn(styles.row, rowClassName)}>
        <Field
          name={`docs[${index}].doc_type`}
          validate={composeFieldValidators(required())}
          render={({ input, meta }) => (
            <div>
              <Select input={input} meta={meta} options={typeOptions} label="Тип документа" />
            </div>
          )}
        />
        <Field
          validate={composeFieldValidators(required())}
          name={`docs[${index}].doc_date`}
          render={({ input, meta }) => (
            <Calendar {...meta} label="Дата" value={input.value} setValue={input.onChange} />
          )}
        />
      </div>

      <Field
        name={isEditMode ? `docs[${index}].listfiles` : `docs[${index}].files`}
        render={({ input }) => (
          <>
            <div className={styles.fileInput}>
              <span>Файлы:</span>
              <FileUploader
                children={undefined}
                className={styles.upload}
                isDisabled={input.value?.length >= 1}
                text={`${input.value?.length || 0}`}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const prevFiles = input.value || [];
                  const newFiles = Array.from(e.target.files || []);
                  input.onChange([...prevFiles, ...newFiles]);
                }}
              />
            </div>
            {(input.value || [])?.map((el: IFile, i: number) => (
              <FileRow
                key={el.name + i}
                className={styles.fileRow}
                file={el}
                canDownload={false}
                canRemove
                onDirectlyRemove={() => {
                  const prevFiles = input.value || [];
                  const newFiles = prevFiles.filter((file: IFile, idx: number) => idx !== i);
                  input.onChange(newFiles);
                }}
              />
            ))}
          </>
        )}
      />
    </div>
  );
};

export default React.memo(AosrDocumentAdd);
