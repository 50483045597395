import cn from "classnames";
import React, { useMemo } from "react";

import TabBarNotLinks from "../../../../../../../../../shared/ui/controls/TabBar/TabBarNotLinks";

import styles from "./GroupTabs.module.scss";

interface ITabsProps {
  activeGroupTabId: string;
  setActiveGroupTabId: (id: string) => void;
  tabsDisplaying: TabsDisplaying;
  className?: string;
}

export enum GroupTabsEnum {
  expenditures = "expenditures",
  materials = "materials",
  mims = "mims",
}

type TabsDisplaying = {
  [key in GroupTabsEnum]: boolean;
};

const GroupTabs = ({ activeGroupTabId, setActiveGroupTabId, tabsDisplaying, className }: ITabsProps) => {
  const groupTabs = useMemo(() => {
    const tabs = [
      { text: "Расценки в группе", id: GroupTabsEnum.expenditures },
      { text: "Материалы", id: GroupTabsEnum.materials },
      { text: "Машины и механизмы", id: GroupTabsEnum.mims },
    ];

    return tabs.filter((tab) => tabsDisplaying[tab.id]);
  }, [tabsDisplaying]);

  return (
    <TabBarNotLinks
      activeId={activeGroupTabId}
      onClick={setActiveGroupTabId}
      tabs={groupTabs}
      className={cn(styles.tabs, className)}
      containerClassName={styles.tabsContainer}
      isOnlyTabsView
    />
  );
};

export default React.memo(GroupTabs);
