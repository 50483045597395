import cn from "classnames";
import React, { FC, memo } from "react";

import ConfirmSelect from "../../../../../../../../../../../_LEGACY/UI/__trash/ConfirmSelect/ConfirmSelect";
import { IActions } from "../../../../../../../../../../../components/UI/_TODO/EstimateSection/EstimateSection";

import Actions from "../../../../../../../../../../../shared/ui/controls/Actions/Actions";
import Amount from "../../../../../../../../../../../shared/ui/dataDisplay/Amount/Amount";
import Checkbox from "../../../../../../../../../../../shared/ui/inputs/Checkbox/Checkbox";
import InputBase from "../../../../../../../../../../../shared/ui/inputs/InputBase";
import { useDrag } from "react-dnd";

import ISection from "../../../../../../../../../../../types/interfaces/Section";
import { EEstimateStatesIds } from "../../../../../../../../ProHandler/enums";
import { ESimpleHandlerMode } from "../../../../../../../types";

import { useEditSectionName } from "../../../hooks/useEditSectionName";

import { sliceTextByConstraint } from "../../../../../../../../../../../utils/formatters/sliceTextByConstraint";
import { transformDigitToFinancial } from "../../../../../../../../../../../utils/formatters/transformDigitToFinancial";
import { stopEventPropagation } from "../../../../../../../../../../../utils/helpers/stopEventPropagation";
import { getSectionAmount } from "../../../../../../../../ProHandler/utils/getSectionAmount";

import styles from "./ConfirmationSectionView.module.scss";

interface IProps {
  section: ISection;
  estimateStateId?: EEstimateStatesIds | null;
  onCheck?: (section: ISection) => void;
  checked?: boolean;
  onClick?: (section: ISection) => void;
  actions?: Partial<IActions>;
  mode?: ESimpleHandlerMode;
  canCheck?: boolean;
  isCheckboxVisible?: boolean;
}

export const ConfirmationSectionView: FC<IProps> = memo(
  ({
    mode = ESimpleHandlerMode.VIEW,
    section,
    estimateStateId,
    onClick = (section: ISection) => {},
    onCheck = (section: ISection) => {},
    actions,
    checked = false,
    isCheckboxVisible = false,
  }) => {
    const amount = transformDigitToFinancial(getSectionAmount(section, estimateStateId), {
      withFloat: false,
      withCurrencySign: true,
    });

    const { changedName, isEditing, setIsEditing, onSubmitEditedSectionName, onKeyDownNameInput, onChangeNameInput } =
      useEditSectionName(section.name, actions?.onSaveEditName, "new");

    const handleClick = () => onClick(section);
    const handleCheck = () => onCheck(section);

    const isShowActions = !!actions;
    const isDisplayEstimateStateMark = section.current_state === EEstimateStatesIds.LOCALE;

    return (
      <div key={section.id} className={styles.container} onClick={handleClick}>
        {isCheckboxVisible && <Checkbox className={styles.checkbox} checked={checked} onCheck={handleCheck} />}
        <div className={styles.titleContainer}>
          {isEditing ? (
            <div className={styles.inputContainer} onClick={stopEventPropagation}>
              <InputBase
                classNameInput={styles.input}
                value={changedName}
                onChange={onChangeNameInput}
                onKeyDown={onKeyDownNameInput}
                placeholder="Введите наименование"
                input={undefined}
                className={undefined}
                label={undefined}
                onBlur={undefined}
              />
            </div>
          ) : (
            <span className={styles.title} title={changedName}>
              {sliceTextByConstraint(changedName, 120)}
            </span>
          )}
        </div>

        {isShowActions && (
          <div
            className={cn(styles.actions, {
              [styles.actionsMargin]: mode !== ESimpleHandlerMode.VIEW || !isDisplayEstimateStateMark,
            })}
            onClick={stopEventPropagation}
          >
            <Actions
              className={styles.actionBlock}
              canSave={isEditing}
              onSave={onSubmitEditedSectionName}
              canEdit={!isEditing && actions.canEdit}
              onEdit={() => setIsEditing(true)}
              canRemove={actions.canRemove}
              onRemove={actions.onRemove}
            />
            {actions.canConfirm && (
              <ConfirmSelect
                className={styles.confirmSelect}
                onConfirm={actions.onConfirm}
                onCancel={actions.onCancel}
                isLoading={actions.isLoading}
                disabled={actions.disabled}
              />
            )}
          </div>
        )}

        <div className={styles.divider} />

        <Amount
          className={styles.budget}
          titleClassName={styles.budgetTitle}
          value={amount}
          title="Бюджет"
          isLeft
          isBold
        />
      </div>
    );
  }
);

ConfirmationSectionView.displayName = "ConfirmationSectionView";
