import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface TasksV2State {
  invalidationKey: number;
  loadings: Record<string, boolean>;
  pendings: Record<string, boolean>;
}

export const initialState: TasksV2State = {
  invalidationKey: 0,
  loadings: {},
  pendings: {},
};

const tasksV2Slice = createSlice({
  name: "tasksV2",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    },
    setPending: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.pendings[key] = status;
    },
    clearPendings: (state) => {
      state.pendings = {};
    },
  },
});

export const tasksV2Actions = tasksV2Slice.actions;

export const tasksV2Reducer = tasksV2Slice.reducer;
