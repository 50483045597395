import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { certainTaskSelector, isCertainTaskLoadingSelector } from "components/pages/Tasks/model/selectors";
import { getOneTask } from "components/pages/Tasks/model/thunks";

import { useTypedSelector } from "app/store/typedUseSelector";

export const useGetTask = (id: number, isOpen = false) => {
  const dispatch = useDispatch();
  const tasks = useTypedSelector(certainTaskSelector);
  const tasksLoading = useTypedSelector(isCertainTaskLoadingSelector);
  const { invalidateKey } = useTypedSelector((state) => state.tasks);
  const invalidationKeyV2 = useTypedSelector((state) => state.tasksV2Reducer.invalidationKey);

  useEffect(() => {
    if (!isOpen) return;

    +id > 0 && dispatch(getOneTask(id));
  }, [dispatch, id, isOpen, invalidateKey, invalidationKeyV2]);

  return {
    certainTask: tasks[id],
    isCertainTaskLoading: tasksLoading[id],
  };
};
