import cn from "classnames";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { actingActions } from "redux/modules/common/building/journal/acting/actions";
import { IExpenditureInActing } from "redux/modules/common/building/journal/acting/types";

import JournalEditKs2Modal from "../../JournalActingModal/JournalEditKs2Modal";
import JournalKs3EditModal from "../../JournalActingModal/JournalKs3EditModal";
import ksIcon from "./ksIcon";
import BlueLabel from "shared/ui/dataDisplay/BlueLabel/BlueLabel";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import Checkbox from "shared/ui/inputs/Checkbox/Checkbox";

import { VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS2 } from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";

import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./JournalActingExpenditure.module.scss";

interface IProps {
  type: "ks2" | "estimate";
  isFirstInParent?: boolean;
  expenditure: IExpenditureInActing;
  estimateId: number;
  sectionId: number;
  sectionName?: string;
}

const JournalActingExpenditure: React.FC<IProps> = ({
  type,
  isFirstInParent,
  expenditure,
  estimateId,
  sectionId,
  sectionName,
}) => {
  const isEstimate = type === "estimate";
  const dispatch = useDispatch();

  const haveFormationKs2Permission = usePermission(VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS2);

  const [isEditKs2, setIsEditKs2] = useState(false);
  const [isEditKs3, setIsEditKs3] = useState(false);

  const checkHandler = useCallback(() => {
    dispatch(actingActions.selectExpenditure(expenditure.id, !expenditure.isSelected, sectionId, estimateId));
  }, [estimateId, expenditure, sectionId]);

  const isDisabledByConstrControl = expenditure.cc_confirmed === false;

  return (
    <>
      {isFirstInParent && sectionName && (
        <div className={styles.parent}>
          <div />
          {sectionName}
        </div>
      )}
      <TableReusableRow
        isHoverable={false}
        className={cn(styles.row, { [styles.rowInKs2]: !isEstimate })}
        containerClassName={styles.wrapper}
      >
        <TableReusableCell className={styles.checkCol}>
          <div className={styles.checkContent}>
            <span>{expenditure.row_number}</span>
            {!isDisabledByConstrControl && isEstimate && haveFormationKs2Permission && (
              <Checkbox onCheck={checkHandler} checked={!!expenditure.isSelected} />
            )}
          </div>
        </TableReusableCell>
        <TableReusableCell className={styles.nameCol}>
          <span className={styles.name} title={expenditure.name}>
            {expenditure.name}
          </span>
          <div className={styles.nameBottom}>
            <BlueLabel className={styles.justification} blueFont>
              {expenditure.justification}
            </BlueLabel>
          </div>
        </TableReusableCell>
        <TableReusableCell isCentered>{expenditure.measure}</TableReusableCell>
        <TableReusableCell isCentered>{expenditure.completed_count}</TableReusableCell>
        <TableReusableCell isCentered>{expenditure.left_count}</TableReusableCell>
        <TableReusableCell isCentered>{expenditure.todo_count}</TableReusableCell>
        <TableReusableCell isRighted>{splitThousands(expenditure.amount, false, false)}</TableReusableCell>
        {isEstimate && (
          <TableReusableCell isCentered onClick={() => expenditure.act_ks2 && setIsEditKs2(true)}>
            <div
              className={cn(styles.ks, { [styles.activeKs]: expenditure.act_ks2 })}
              title={expenditure.act_ks2?.title}
            >
              {ksIcon}
              {expenditure.act_ks2 && <span>{expenditure.act_ks2.title}</span>}
            </div>
          </TableReusableCell>
        )}
        {isEstimate && (
          <TableReusableCell onClick={() => expenditure.act_ks3 && setIsEditKs3(true)}>
            <div
              className={cn(styles.ks, { [styles.activeKs]: expenditure.act_ks3 })}
              title={expenditure.act_ks3?.title}
            >
              {ksIcon}
              {expenditure.act_ks3 && <span>{expenditure.act_ks3.title}</span>}
            </div>
          </TableReusableCell>
        )}
      </TableReusableRow>
      {expenditure.act_ks2 && (
        <JournalEditKs2Modal isOpen={isEditKs2} onClose={() => setIsEditKs2(false)} ks2Id={expenditure.act_ks2?.id} />
      )}
      {expenditure.act_ks3 && (
        <JournalKs3EditModal isOpen={isEditKs3} onClose={() => setIsEditKs3(false)} ks3Id={expenditure.act_ks3?.id} />
      )}
    </>
  );
};

export default JournalActingExpenditure;
