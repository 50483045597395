import {
  ADD_DOCUMENT,
  ADD_PACKING_ITEM_FILE,
  ADD_PACKING_LIST_FILE,
  DELETE_PACKING_ITEM_FILE,
  DELETE_PACKING_LIST_FILE,
  LOAD_DOCUMENTS,
  SET_DOCUMENTS,
  SET_MORE_DOCUMENT,
  SET_PACKING_ITEMS_FILES_LOADING,
  SET_PACKING_ITEM_FILES,
  SET_PACKING_LIST,
  SET_PACKING_LIST_ACCEPTING,
  SET_PACKING_LIST_ERROR,
  SET_PACKING_LIST_IS_NOT_FOUND,
  SET_PACKING_LIST_LOADING,
  SET_PACKING_LIST_NUMBER,
} from "./constants";

export const setPackingItemsFilesLoadingAction = (payload) => ({ type: SET_PACKING_ITEMS_FILES_LOADING, payload });
export const setPackingItemFilesAction = (payload) => ({ type: SET_PACKING_ITEM_FILES, payload });
export const addPackingItemFileAction = (payload) => ({ type: ADD_PACKING_ITEM_FILE, payload });
export const deletePackingItemFileAction = (payload) => ({ type: DELETE_PACKING_ITEM_FILE, payload });

export const setPackingListNumberAction = (payload) => ({ type: SET_PACKING_LIST_NUMBER, payload });
export const setPackingListAction = (payload) => ({ type: SET_PACKING_LIST, payload });

export const loadDocumentsAction = () => ({ type: LOAD_DOCUMENTS });
export const addDocumentsAction = (payload) => ({ type: ADD_DOCUMENT, payload });
export const setDocumentsAction = (payload) => ({ type: SET_DOCUMENTS, payload });
export const setMoreDocumentAction = (payload) => ({ type: SET_MORE_DOCUMENT, payload });

export const addPackingListFileAction = (payload) => ({ type: ADD_PACKING_LIST_FILE, payload });
export const deletePackingListFileAction = (payload) => ({ type: DELETE_PACKING_LIST_FILE, payload });

export const setPackingListLoadingAction = (payload) => ({ type: SET_PACKING_LIST_LOADING, payload });
export const setPackingListAcceptingAction = (payload) => ({ type: SET_PACKING_LIST_ACCEPTING, payload });
export const setPackingListErrorAction = () => ({ type: SET_PACKING_LIST_ERROR });
export const setPackingListIsNotFoundAction = (payload) => ({ type: SET_PACKING_LIST_IS_NOT_FOUND, payload });
