import React from "react";

import styles from "./ForbiddenPage.module.scss";

export interface IForbiddenPageProps {
  dataTestId?: number | string;
}

const ForbiddenPage: React.FC<IForbiddenPageProps> = ({ dataTestId }) => (
  <h1 className={styles.title} data-testid={dataTestId}>
    У вас нет доступа к данной странице
  </h1>
);

export default React.memo(ForbiddenPage);
