import { message } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { IRouterParamsWithObjectId } from "../../../../../../types/routerTypes";
import { EMPTY_SUB_EXPENDITURE, PERCENT_DECIMAL } from "./constants";
import { ISubExpenditure } from "./types";

import { dropNonSignificantZeros } from "../../../../../../utils/formatters/dropNonSignificantZeros";
import { IReason, errorCatcher } from "../../../../../../utils/helpers/errorCatcher";
import {
  checkIsSubExpenditureValidToConfirm,
  checkIsSubExpenditureValidToContinue,
  formatSubExpenditureNumber,
  sortSubExpendituresAscending,
  sortSubExpendituresDescending,
} from "./utils";

export interface IUseUngroupExpenditureModalProps {
  expenditureNumber: number;
  expenditureId: number;
  estimatedCost: number | string;
  confirmSuccessCallback?: () => void;
  setActiveTab: (newTab: "structure" | "cost") => void;
}

export const useUngroupExpenditureModal = ({
  expenditureNumber,
  expenditureId,
  estimatedCost,
  confirmSuccessCallback,
  setActiveTab,
}: IUseUngroupExpenditureModalProps) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const [subExpenditures, setSubExpenditures] = useState<ISubExpenditure[]>([]);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [recalculatedEstimatedCost, setRecalculatedEstimatedCost] = useState<number | string>(estimatedCost);

  useEffect(() => {
    if (!subExpenditures.length) return;
    const candidate = subExpenditures.reduce(
      (total: number, exp: ISubExpenditure) => total + parseFloat(exp.estimated_cost || "0"),
      0
    );
    setRecalculatedEstimatedCost((candidate || estimatedCost)?.toString());
  }, [subExpenditures, estimatedCost]);

  const onRemoveSubExpenditure = useCallback(
    (number: string) =>
      setSubExpenditures((prevState) =>
        prevState
          .filter((exp) => exp.number !== number)
          .sort(sortSubExpendituresAscending)
          .map((exp, index) => ({
            ...exp,
            number: formatSubExpenditureNumber(expenditureNumber, index, prevState.length - 1),
          }))
      ),
    [expenditureNumber]
  );

  const setIsEditSubExpenditure = useCallback(
    (number: string, isEdit: boolean) =>
      setSubExpenditures((prevState) =>
        prevState.map((exp) => (exp.number === number ? { ...exp, _isEdit: isEdit } : exp))
      ),
    []
  );

  const updateSubExpenditure = useCallback(
    (number: string, data: Partial<ISubExpenditure>) =>
      setSubExpenditures((prevState) => prevState.map((exp) => (exp.number === number ? { ...exp, ...data } : exp))),
    []
  );

  const onAddSubExpenditure = useCallback(
    () =>
      setSubExpenditures((prevState) =>
        prevState.concat({
          ...EMPTY_SUB_EXPENDITURE,
          number: formatSubExpenditureNumber(expenditureNumber, prevState.length, prevState.length + 1),
          _key: Math.random(),
        })
      ),
    []
  );

  const canContinue = useMemo(
    () => !!subExpenditures?.length && subExpenditures.every(checkIsSubExpenditureValidToContinue),
    [subExpenditures]
  );

  const totalPercent = useMemo(
    () =>
      parseFloat(
        subExpenditures
          .reduce((totalPercent, exp) => totalPercent + parseFloat(exp.percent), 0)
          .toFixed(PERCENT_DECIMAL)
      ),
    [subExpenditures]
  );

  const hasConfirmValidationErrors = useMemo(
    () => !subExpenditures.every(checkIsSubExpenditureValidToConfirm),
    [subExpenditures]
  );

  const canConfirm = useMemo(
    () => !!subExpenditures?.length && !hasConfirmValidationErrors && totalPercent === 100,
    [subExpenditures, totalPercent, hasConfirmValidationErrors]
  );

  const onContinue = useCallback(() => {
    if (!!subExpenditures.length && !canContinue) {
      message.warn("Все расценки должны быть подтверждены");
      return;
    }
    setActiveTab("cost");
  }, [canContinue, subExpenditures]);

  const onConfirm = useCallback(async () => {
    if (!!subExpenditures.length && !canConfirm) {
      message.warn("Все расценки должны быть подтверждены");
      return;
    }
    const expenditures = subExpenditures.map((x) => ({
      ...x,
      _isEdit: undefined,
      _unitCost: undefined,
      _key: undefined,
    }));
    if (!expenditures.length) {
      message.warn("Не указаны расценки для разгруппировки ");
      return;
    }
    if (!objectId) {
      message.warn("Не удалось определить проект");
      return;
    }
    setIsConfirming(true);
    await axios
      .post(`/building/${objectId}/estimate/link_expenditure/${expenditureId}/`, {
        expenditures,
      })
      .then(() => {
        message.success("Расценки разгруппированы");
        confirmSuccessCallback?.();
        setSubExpenditures([]);
      })
      .catch((e: IReason) => {
        if (e.response.status === 404) message.error("Не найдена расценка для разгруппировки");
        throw e;
      })
      .catch(errorCatcher)
      .finally(() => setIsConfirming(false));
  }, [canConfirm, subExpenditures]);

  const totalPercentHint = (() => {
    if (totalPercent <= 0 || totalPercent > 200) return "Укажите в сумме 100%";
    if (totalPercent > 100)
      return `Укажите на ${dropNonSignificantZeros((totalPercent - 100).toFixed(PERCENT_DECIMAL))}% меньше`;
    if (totalPercent < 100)
      return `Укажите на ${dropNonSignificantZeros((100 - totalPercent).toFixed(PERCENT_DECIMAL))}% больше`;
    return "";
  })();

  const sortedSubExpenditures = useMemo(() => subExpenditures.sort(sortSubExpendituresDescending), [subExpenditures]);

  return {
    subExpenditures: sortedSubExpenditures,
    totalPercentHint,
    onAddSubExpenditure,
    onRemoveSubExpenditure,
    setIsEditSubExpenditure,
    updateSubExpenditure,
    canContinue,
    canConfirm,
    hasConfirmValidationErrors,
    onContinue,
    onConfirm,
    isConfirming,
    recalculatedEstimatedCost,
  };
};
