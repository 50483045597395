import moment from "moment";

import { IAnalyticsEventData } from "../../../../../redux/modules/common/analytics/types/events";

import eventStatusStyles from "../../../../UI/atoms/EventItem/EventItem.module.scss";
import { IOption } from "../../../../UI/atoms/Select";

import { ITab } from "../../../../../shared/ui/controls/TabBar/TabBarNotLinks";

import { EVENT_TYPES as EVENT_ENUM, EventStatus, EventType } from "../../../../../types/interfaces/Event";
import { TaskStatus } from "../../../Tasks/constants";

import blueDone from "../../../../../images/icons/blueDoneSvg.svg";
import doneSvg from "../../../../../images/icons/doneSvgLoad.svg";
import blueLoading from "../../../../../images/icons/loadBlueSvg.svg";
import purpleLoading from "../../../../../images/icons/loadPurpleSvg.svg";
import loadSvg from "../../../../../images/icons/loadingSvgPayment.svg";
import purpleDone from "../../../../../images/icons/purpleDoneSvg.svg";

export const ANALYTICS_EVENTS_TABS: ITab[] = [
  { id: "all", text: "Все" },
  { id: "overdue", text: "Просрочены" },
];

export const ANALYTICS_EVENTS_TYPES_OPTIONS: IOption[] = [
  { id: "order", name: "Заказ" },
  { id: "payment", name: "Оплата" },
  { id: "supply", name: "Поставка" },
  { id: "task", name: "Задача" },
];

export const formatEventDate = (date: string) => {
  const dateMoment = moment(date);
  if (dateMoment.isSame(moment(), "day")) return "Сегодня";
  if (dateMoment.isSame(moment().subtract(1, "day"), "day")) return "Вчера";
  return dateMoment.format("DD/MM/YYYY");
};

export const sortEvents = (a: IAnalyticsEventData, b: IAnalyticsEventData) =>
  moment(a.date).isBefore(moment(b.date)) ? 1 : -1;

export const analyticsEventStatusSwitcher = (
  status: EventStatus | TaskStatus,
  eventType: EventType,
  isCompleted: boolean
) => {
  if (eventType === EVENT_ENUM.supply) {
    if (status === "waiting_shipment" || status === "waiting_payment") {
      return {
        containerClassName: eventStatusStyles.supplyContainer,
        statusClassName: eventStatusStyles.notPayedSupplies,
        statusText: status === "waiting_shipment" ? "Ожидает отгрузки" : "Ожидает оплаты",
        statusIcon: loadSvg,
      };
    } else {
      return {
        containerClassName: eventStatusStyles.supplyContainer,
        statusClassName: eventStatusStyles.payedSupplies,
        statusText: "Принята",
        statusIcon: doneSvg,
      };
    }
  }
  if (eventType === EVENT_ENUM.payment) {
    if (isCompleted)
      return {
        containerClassName: eventStatusStyles.paymentContainer,
        statusClassName: eventStatusStyles.payedPayment,
        statusText: "Оплачено",
        statusIcon: purpleDone,
      };
    return {
      containerClassName: eventStatusStyles.paymentContainer,
      statusClassName: eventStatusStyles.notPayedPayment,
      statusText: "Ожидает оплаты",
      statusIcon: purpleLoading,
    };
  }
  if (eventType === EVENT_ENUM.order) {
    if (status === "completed") {
      return {
        containerClassName: eventStatusStyles.taskContainer,
        statusClassName: eventStatusStyles.taskStatus,
        statusText: "",
        statusIcon: blueDone,
      };
    } else {
      return {
        containerClassName: eventStatusStyles.taskContainer,
        statusClassName: eventStatusStyles.taskStatus,
        statusText: "",
        statusIcon: blueLoading,
      };
    }
  }
  if (eventType === EVENT_ENUM.task) {
    if (["completed", "closed"].includes(status)) {
      return {
        containerClassName: eventStatusStyles.taskContainer,
        statusClassName: eventStatusStyles.taskStatus,
        statusText: "Выполнена",
        statusIcon: blueDone,
      };
    } else if (status === "expired") {
      return {
        containerClassName: eventStatusStyles.taskContainer,
        statusClassName: eventStatusStyles.taskStatus,
        statusText: "Просрочена",
        statusIcon: blueLoading,
      };
    } else {
      return {
        containerClassName: eventStatusStyles.taskContainer,
        statusClassName: eventStatusStyles.taskStatus,
        statusText: "Выполняется",
        statusIcon: blueLoading,
      };
    }
  }
  return {};
};
