import cn from "classnames";
import React from "react";

import DividerExpandButton, { IDividerExpandButtonProps } from "./components/DividerExpandButton/DividerExpandButton";

import styles from "./ManufacturingModalDivider.module.scss";

export type DividerType = "plan" | "progress" | "accepted" | "remark";

type propsType = {
  type: DividerType;
  className?: string;
  expandButton?: IDividerExpandButtonProps;
};

const textSwitcher: Record<DividerType, string> = {
  plan: "План",
  progress: "К сдаче",
  accepted: "Принято",
  remark: "Есть замечания",
};

function ManufacturingModalDivider({ type, className, expandButton }: propsType) {
  return (
    <div className={cn(styles.container, styles[type], className)}>
      <div className={styles.status}>
        <div className={styles.icon} />
        {textSwitcher[type]}
      </div>
      {!!expandButton && (
        <DividerExpandButton
          type={type}
          {...expandButton}
          className={cn(expandButton?.className, styles.expandButton)}
        />
      )}
    </div>
  );
}

export default ManufacturingModalDivider;
