import cn from "classnames";
import React from "react";

import { getExpenseSubProducts } from "../../../../../../redux/modules/common/building/stocksApi";

import SearchRangeDateFilter from "../../../../../UI/atoms/_TODO/SearchRangeDateFilter";

import { Spinner } from "../../../../../../shared/ui/atoms/Spinner/Spinner";
import TableRow from "./../../common/TableRow/TableRow";
import ExpenseProduct from "./ExpenseProduct/ExpenseProductSubrow";
import TableFilterTextInput from "shared/ui/inputs/TableFilterTextInput/TableFilterTextInput";

import styles from "./ExpenseTableRow.module.scss";

function ExpenseTableRow({ row, measure, stockId }) {
  const [products, setProducts] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const openProductsHandler = () => {
    !products?.length && setIsLoading(true);
    getExpenseSubProducts(row?.id, { stock_id: stockId })
      .then((resp) => setProducts(resp.data.results))
      .finally(() => setIsLoading(false));
  };

  const [filters, setFilters] = React.useState(null);

  const changeFilters = (value, name) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  React.useEffect(() => {
    if (!filters) return;
    getExpenseSubProducts(row?.id, filters).then((resp) => setProducts(resp.data.results));
  }, [filters]);

  const sectionString = React.useMemo(() => {
    return row?.section?.parent?.name + ", " + row?.section?.name;
  }, [row]);

  return (
    <TableRow
      countChildren={row?.count_usings}
      rowNumber={row?.row_number}
      name={row?.name}
      sectionString={sectionString}
      measureProduct={row?.measure}
      measureMoney={measure}
      count1={row?.count_using}
      amount1={row?.product_using_amount}
      count2={row?.product_production_count}
      amount2={row?.product_production_amount}
      count3={row?.confirm_sum_using_count}
      amount3={row?.confirm_sum_using_amount}
      onOpen={openProductsHandler}
    >
      <div className={styles.content}>
        <div className={styles.tableHeader}>
          <div className={cn(styles.rowItem, styles.fieldNumber)}>
            <div className={styles.dot}></div>
          </div>
          <div className={cn(styles.rowItem, styles.fieldTitle)}>
            <div className={styles.title}>Номенклатура</div>
            <TableFilterTextInput
              title="Номенклатура"
              setFilter={(value) => {
                changeFilters(value, "estimate_item_name");
              }}
            />
          </div>
          <div className={cn(styles.rowItem, styles.fieldSection, styles.center)}>
            Выдал
            <TableFilterTextInput
              title="Выдал"
              setFilter={(value) => {
                changeFilters(value, "responsible_full_name");
              }}
            />
          </div>
          <div className={cn(styles.rowItem, styles.fieldCount, styles.center)}>
            Когда
            <SearchRangeDateFilter
              title="Когда "
              setFilterStart={(value) => {
                changeFilters(value, "created_at_gte");
              }}
              setFilterEnd={(value) => {
                changeFilters(value, "created_at_lte");
              }}
            />
          </div>
          <div className={cn(styles.rowItem, styles.center)}>
            Кому
            <TableFilterTextInput
              title="Кому"
              setFilter={(value) => {
                changeFilters(value, "user_full_name");
              }}
            />
          </div>
          <div className={cn(styles.rowItem, styles.center)}>Ед. изм</div>
          <div className={cn(styles.rowItem, styles.center)}>Количество</div>
        </div>
        {isLoading && <Spinner />}
        {products?.map((item) => (
          <ExpenseProduct data={item} measure={measure} stockId={stockId} />
        ))}
      </div>
    </TableRow>
  );
}

export default ExpenseTableRow;
