import React, { useCallback, useState } from "react";

import { INormalizedPermissionsTree } from "components/pages/Settings/components/Users/UserPage/utils/permissionsUtils";

import { Switch } from "../../../../../../../../../../../../../../shared/ui/inputs/Switch/Switch";

import { IPermissionsTree } from "features/permissions/model/types";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import ArrowSmallUpGrey from "images/icons/ArrowSmallUpGrey";

import styles from "./SwitchedPermission.module.scss";

interface IProps {
  group: INormalizedPermissionsTree;
  onChangeChecked: (alias: string, status: boolean) => void;
  checkedPermissions: Record<string, boolean>;
  canEdit: boolean;
}

const SwitchedPermission: React.FC<IProps> = ({ group, onChangeChecked, checkedPermissions, canEdit }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => setIsExpanded((prevState) => !prevState);

  const showChildren = group.children?.length! > 1;

  return (
    <>
      <div className={styles.container} onClick={handleExpand}>
        {showChildren && (
          <ArrowSmallUpGrey className={styles.arrow} color="#C9C9C9" rotate={isExpanded ? "180" : "90"} />
        )}
        <div className={styles.switch}>
          <span>{group.name}</span>
          <Switch
            checked={checkedPermissions[group.alias]}
            onChange={(e) => {
              onChangeChecked(group.alias, e.target.checked);
            }}
            //@ts-ignore
            onClick={stopEventPropagation}
            //disabled={!canEdit || group.optional === false}
          />
        </div>
      </div>
      {showChildren && isExpanded && (
        <div className={styles.children}>
          {group.children?.map((el, i, arr) => {
            if (el.type === "group") {
              return (
                <SwitchedPermission
                  key={el.name}
                  group={el}
                  checkedPermissions={checkedPermissions}
                  onChangeChecked={onChangeChecked}
                  canEdit={canEdit}
                />
              );
            }
            return (
              <div className={styles.switch}>
                <span>{el.name}</span>
                <Switch
                  checked={checkedPermissions[el.alias]}
                  onChange={(e) => {
                    onChangeChecked(el.alias, e.target.checked);
                  }}
                  //@ts-ignore
                  onClick={stopEventPropagation}
                  //disabled={!canEdit || !el.optional}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default React.memo(SwitchedPermission);
