import { message } from "antd";
import cn from "classnames";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useRouteMatch } from "react-router-dom";
import { AnyAction } from "redux";

import { objectDetailSelector } from "../../../../../redux/modules/common/building/object/nowObject";
import {
  changeEstimateItemsState,
  sectionsSelector,
} from "../../../../../redux/modules/common/building/sections/sections";

import Chat from "../../../../../widgets/Chat/ChatV2";
import SimpleEstimateStatesIndicators from "../../Aggregations/SimpleEstimateStateIndicators/SimpleEstimateStatesIndicators";
import { loadAggregations } from "../../Aggregations/model/thunks";
import { simpleHandlerActions } from "../model/slice";
import { createSectionInDraft } from "../model/thunks";
import { ItemsList } from "./ItemsList/ItemsList";
import { NavigationBar } from "./NavigationBar/NavigationBar";
import { RootState } from "app/store/rootReducer";
import { ThunkDispatch } from "redux-thunk";

import { IHandlerPageProps } from "../../../types/HandlerProps";
import { IHandlerMatchParams } from "../../../types/MatchParams";
import { ESTIMATE_STATES_IDS, ESTIMATE_STATES_NAMES_FOR_FROM } from "../../ProHandler/constants";
import { EEstimateStatesIds } from "../../ProHandler/enums";
import { chatRoomTypes } from "widgets/Chat/model/types";

import { useQueryParams } from "../../../../../utils/hooks/useQueryParams";
import useEstimateState from "../../ProHandler/hooks/useEstimateState";

import styles from "./SimpleHandler.module.scss";

chatRoomTypes;

export const SimpleHandler: FC<IHandlerPageProps> = ({ match, permissions }) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const { buildingId } = useParams<{ buildingId: string; projectId: string }>();
  const building = useSelector(objectDetailSelector);
  const isBuildingShared = building && building.is_shared;

  const sectionIdMatch = useRouteMatch<IHandlerMatchParams>(`${match.path}/:sectionId`);
  const subsectionIdMatch = useRouteMatch<IHandlerMatchParams>(`${match.path}/:sectionId/:subsectionId`);

  const subsectionId = subsectionIdMatch && subsectionIdMatch.params.subsectionId;
  const sectionId = sectionIdMatch && sectionIdMatch.params.sectionId;

  const isSubsectionsLevel = useMemo(() => !!sectionId, [sectionIdMatch]);

  useEffect(() => {
    return () => {
      dispatch(simpleHandlerActions.reset());
    };
  }, []);

  const sections = useSelector(sectionsSelector);

  const sectionsAreEmpty = sections?.length === 0;

  const [areIndicatorsOpen, setAreIndicatorsOpen] = useState(true);

  const state = useQueryParams("state")?.split("?")[0] as EEstimateStatesIds;

  const updateEstimateStateIdCallback = useCallback((estimateStateId: EEstimateStatesIds, needReset?: boolean) => {
    if (needReset) {
      dispatch(simpleHandlerActions.reset());
    }
    dispatch(simpleHandlerActions.setEstimateStateId(estimateStateId));
  }, []);

  const { activeEstimateStateId, setActiveEstimateStateId, availableEstimateStates, estimateStatesMenu } =
    useEstimateState(match.url, permissions, isBuildingShared, false, updateEstimateStateIdCallback);

  const toggleIndicatorsOpen = useCallback(() => setAreIndicatorsOpen((prevState) => !prevState), []);

  const handleChangeEstimateItemsState = useCallback(
    async (targetEstimateStateId: EEstimateStatesIds, changedEstimateItemsIds: any) => {
      const payload = {
        fromState: activeEstimateStateId,
        state: targetEstimateStateId,
        ids: changedEstimateItemsIds,
      };

      dispatch(changeEstimateItemsState(buildingId, payload))
        .then((response) => {
          if (response && response.data.count_updated === 0) {
            const activeEstimateStateName =
              ESTIMATE_STATES_NAMES_FOR_FROM[
                (
                  activeEstimateStateId as unknown as EEstimateStatesIds
                ).toUpperCase() as keyof typeof ESTIMATE_STATES_NAMES_FOR_FROM
              ];
            message.error(`Невозможно перенести выбранные объекты из ${activeEstimateStateName}`);
            return;
          }

          dispatch(loadAggregations(buildingId));
          const section = (
            availableEstimateStates as unknown as Array<{
              id: EEstimateStatesIds;
              name: string;
            }>
          )?.find((estimateState) => estimateState.id === targetEstimateStateId);
          if (section) message.success(`Разделы успешно перенеслись в ${section.name.toLowerCase()}`);

          if (targetEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION) return;

          if (subsectionId) {
            // handleLoadExpenditures();
          } else {
            // refreshSections();
          }
        })
        .catch(() => {
          message.error("Невозможно перенести выбранные объекты");
        });
    },
    [activeEstimateStateId, dispatch, buildingId, availableEstimateStates, subsectionId]
  );

  const onSubmitCreateSection = useCallback(
    (name: string) => {
      dispatch(createSectionInDraft(buildingId, { name, parent: isSubsectionsLevel ? sectionId : null }));
    },
    [buildingId, isSubsectionsLevel, sectionId]
  );

  const onSubmitCreateOutOfEstimateSection = useCallback(
    (name: string) => {
      dispatch(createSectionInDraft(buildingId, { name, parent: isSubsectionsLevel ? sectionId : null }, true));
    },
    [buildingId, isSubsectionsLevel, sectionId]
  );

  return (
    <>
      <div className={cn(styles.handler, { [styles.withOpenIndicators]: areIndicatorsOpen })}>
        <header className={styles.header}>
          <SimpleEstimateStatesIndicators
            activeEstimateStateId={state || activeEstimateStateId}
            setActiveEstimateStateId={setActiveEstimateStateId}
            changeEstimateItemsState={handleChangeEstimateItemsState}
            isOpen={areIndicatorsOpen}
            handleOpening={toggleIndicatorsOpen}
            isShared={!!isBuildingShared}
            isHideProductionIndicatorInfo={false}
            permissions={permissions}
          />

          <NavigationBar
            sectionsAreEmpty={sectionsAreEmpty}
            onSubmitCreateSection={onSubmitCreateSection}
            onSubmitCreateOutOfEstimateSection={onSubmitCreateOutOfEstimateSection}
          />
        </header>

        <ItemsList />
      </div>
      {isBuildingShared && (
        <Chat
          room={chatRoomTypes.OBJECT_SHARED}
          building_id={+buildingId}
          subTitle="Чат с подрядчиком"
          title="Чат с подрядчиком"
        />
      )}
    </>
  );
};
