import React from "react";

export interface ISharingTicketProps {
  backgroundColor?: string;
  className?: string;
  signColor?: string;
}

const TicketSharedCircle: React.FC<ISharingTicketProps> = ({ backgroundColor = "#EB7F00", signColor= 'white', className }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.60889 0.00146484C10.7306 0.00146484 12.7648 0.844924 14.2651 2.34521C15.7654 3.84551 16.6089 5.87973 16.6089 8.00146C16.6089 10.1232 15.7654 12.1594 14.2651 13.6597C12.7648 15.16 10.7306 16.0015 8.60889 16.0015C6.48715 16.0015 4.45293 15.16 2.95264 13.6597C1.45235 12.1594 0.608887 10.1232 0.608887 8.00146C0.608887 5.87973 1.45235 3.84551 2.95264 2.34521C4.45293 0.844924 6.48715 0.00146484 8.60889 0.00146484Z"
        fill={backgroundColor}
      />
      <path
        d="M4.60889 9.49994C5.43731 9.49994 6.10889 8.82837 6.10889 7.99994C6.10889 7.17151 5.43731 6.49994 4.60889 6.49994C3.78046 6.49994 3.10889 7.17151 3.10889 7.99994C3.10889 8.82837 3.78046 9.49994 4.60889 9.49994Z"
        fill={signColor}
      />
      <path
        d="M8.60889 9.49994C9.43731 9.49994 10.1089 8.82837 10.1089 7.99994C10.1089 7.17151 9.43731 6.49994 8.60889 6.49994C7.78046 6.49994 7.10889 7.17151 7.10889 7.99994C7.10889 8.82837 7.78046 9.49994 8.60889 9.49994Z"
        fill={signColor}
      />
      <path
        d="M12.6089 9.49994C13.4373 9.49994 14.1089 8.82837 14.1089 7.99994C14.1089 7.17151 13.4373 6.49994 12.6089 6.49994C11.7805 6.49994 11.1089 7.17151 11.1089 7.99994C11.1089 8.82837 11.7805 9.49994 12.6089 9.49994Z"
        fill={signColor}
      />
    </svg>
  );
};

export default TicketSharedCircle;
