import cn from "classnames";
import React from "react";

import styles from "./TableHeader.module.scss";

interface IProps {
  onFiltersChange?: (value: any, fieldName: string) => void;
  className?: string;
}

const MaterialsTableHeader: React.FC<IProps> = ({ onFiltersChange, className }) => {
  return (
    <div className={cn(styles.header, styles.materialsHeader, className)}>
      <div />
      <div className={styles.name}>
        Материал
        {/* {onFiltersChange && (
          <TableFilterTextInput
            title="Материал"
            setFilter={value => onFiltersChange(value, "estimate_item_name")}
          />
        )} 
        **Закомментировано до добавления на бэке фильтра**
        */}
      </div>
      <div>Кол-во</div>
      <div className={styles.centered}>Выдано</div>
    </div>
  );
};

export default React.memo(MaterialsTableHeader);
