import moment from "moment";
import React from "react";

import LabeledSwitch from "components/UI/atoms/_TODO/LabeledSwitch";
import { tasksListFilterType } from "components/pages/Tasks/model/types";

import ButtonBase from "shared/ui/controls/ButtonBase";
import MonthsYearsSlider from "shared/ui/inputs/MonthsYearsSlider/MonthsYearsSlider";
import { Switch } from "shared/ui/inputs/Switch/Switch";

import { taskFilterStatuses } from "../../constants";

import styles from "./ControlsBar.module.scss";

export interface IControlsBarProps {
  onAddNew?: () => void;
  filters: tasksListFilterType;
  onChangeFilters: (name: keyof tasksListFilterType, value: tasksListFilterType[typeof name]) => void;
  variant?: "tasks" | "lists";
  onDateChange: (date: moment.Moment) => void;
}

const ControlsBar: React.FC<IControlsBarProps> = ({
  onAddNew,
  onChangeFilters,
  filters,
  variant = "tasks",
  onDateChange,
}) => {
  const switchShowCompletedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.checked
      ? onChangeFilters("status", taskFilterStatuses.COMPLETED)
      : onChangeFilters("status", taskFilterStatuses.NOT_COMPLETED);
  };

  return (
    <div className={styles.container}>
      {/* @ts-ignore */}
      <MonthsYearsSlider date={moment(filters.deadline_start)} onChange={onDateChange} className={styles.months} />
      {/* @ts-ignore */}
      {variant === "tasks" && (
        <Switch
          label="Завершённые"
          onChange={switchShowCompletedHandler}
          checked={filters?.status === taskFilterStatuses.COMPLETED}
        />
      )}
      <ButtonBase secondary onClick={onAddNew} className={styles.add}>
        {variant === "tasks" ? "Новая задача" : "Новый список"}
      </ButtonBase>
    </div>
  );
};

export default React.memo(ControlsBar);
