import cn from "classnames";
import React from "react";
import { compose } from "redux";

import { checkStringHasSymbol } from "../../../../utils/checkers/checkStringHasSymbol";
import { transformDigitToFinancial } from "../../../../utils/formatters/transformDigitToFinancial";

import styles from "./Difference.module.scss";

export interface IProps {
  amount: number;
  percent?: number;
  withDivider?: boolean;
  title?: string;
}

const Difference: React.FC<IProps> = ({ amount, percent, withDivider, title = "" }) => {
  const checkValueAndSetClasses = (value: number) => {
    const valueHasMinus = checkStringHasSymbol(String(value), "-");
    return { [styles.inline]: withDivider, [styles.positive]: !valueHasMinus && value !== 0, [styles.negative]: valueHasMinus };
  };

  if (!amount && !percent && !withDivider) return <span className={styles.nullValue}>&#8722;</span>;

  if (!percent)
    return (
      <div className={styles.difference}>
        {withDivider && amount && <span className={styles.divider}>|</span>}
        <div className={compose(cn, checkValueAndSetClasses)(amount)} title={title}>
          {transformDigitToFinancial(amount, {
            withSign: +amount > 0,
            withFloat: true,
            withCurrencySign: true,
          })}
        </div>
      </div>
    );

  return (
    <div className={styles.difference} title={title}>
      <div className={compose(cn, checkValueAndSetClasses)(amount)}>
        {transformDigitToFinancial(amount, {
          withSign: +amount > 0,
          withFloat: true,
          withCurrencySign: true,
        })}
      </div>
      <div className={cn(styles.diffDenominator, checkValueAndSetClasses(percent))}>
        {transformDigitToFinancial(percent, {
          withSign: +amount > 0,
          withPercent: true,
          withFloat: true,
        })}
      </div>
    </div>
  );
};

export default React.memo(Difference);
