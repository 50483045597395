import cn from "classnames";
import moment from "moment";
import React from "react";

import { IRemarksList } from "../../../../../../redux/modules/common/building/journal/journalExecution/types";

import RemarksInModal from "../../../../../UI/_TODO/WorkOrMaterialsModals/components/RemarksInModal/RemarksInModal";
import JournalDay from "components/UI/_TODO/WorkOrMaterialsModals/JournalTicketModal/JournalDay";

import ManufacturingModalDivider from "./ManufacturingModalDivider/ManufacturingModalDivider";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";

import { personFullNameWithId } from "types/personsTypes";

import getShortFullName from "utils/formatters/getShortFullName";
import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./ManufacturingModal.module.scss";

type propsType = {
  projectAmount?: string;
  measure: string;
  dateStart: string;
  dateEnd: string;

  passingDate?: string;
  passingAuthor?: personFullNameWithId | string;
  passingCount?: string;
  investedAmount?: number;

  acceptedDate?: string;
  acceptedAuthor?: personFullNameWithId | string;
  acceptedCount?: string;
  acceptedAmount?: number;

  planCreatingDate?: string;
  planCreator?: personFullNameWithId | string;
  planApprovingDate?: string;
  planApprover?: personFullNameWithId | string;
  planCount?: string;

  remarks?: IRemarksList[];
  ticketRemarks?: IRemarksList[];
};

function ManufacturingModalContentFact({
  projectAmount,
  measure,
  dateStart,
  dateEnd,

  passingAuthor,
  passingCount,
  passingDate,
  investedAmount,

  acceptedAmount,
  acceptedAuthor,
  acceptedCount,
  acceptedDate,

  planApprover,
  planApprovingDate,
  planCount,
  planCreatingDate,
  planCreator,

  remarks,
  ticketRemarks,
}: propsType) {
  const unresolvedRemarks = (remarks || [])
    .concat(ticketRemarks || [])
    .filter((r) => ["created", "review"].includes(r.status));
  const unresolvedRemarksCount = unresolvedRemarks.length;
  const [isShownRemarks, setIsShownRemarks] = React.useState(false);

  const isOpenedOnChart = window.location.pathname.includes("manufacturing");

  return (
    <>
      {!!unresolvedRemarksCount && (
        <>
          <ManufacturingModalDivider
            type="remark"
            className={cn(
              styles.remarksDivider,
              !planCreatingDate && styles.mt0,
              isShownRemarks && styles.remarksDividerOpen
            )}
            expandButton={{
              text: `не решено ${unresolvedRemarksCount}`,
              onClick: () => {
                setIsShownRemarks((prevState) => !prevState);
              },
            }}
          />
          {isShownRemarks && <RemarksInModal remarks={unresolvedRemarks} />}
        </>
      )}

      {planCreatingDate && (
        <>
          <ManufacturingModalDivider type="plan" className={styles.mt0} />
          {planCreatingDate && (
            <div className={styles.pair}>
              <JournalDay
                day={moment(planCreatingDate).format("DD/MM/YYYY")}
                time={moment(planCreatingDate).format("HH:mm")}
              />
              <div className={styles.pass}>
                <b>Создал :</b>
                <span>{typeof planCreator === "string" ? planCreator : getShortFullName(planCreator)}</span>
              </div>
            </div>
          )}

          {planApprovingDate && (
            <div className={styles.pair}>
              <JournalDay
                day={moment(planApprovingDate).format("DD/MM/YYYY")}
                time={moment(planApprovingDate).format("HH:mm")}
              />
              <div className={styles.pass}>
                <b>Согласовал :</b>
                <span>{typeof planApprover === "string" ? planApprover : getShortFullName(planApprover)}</span>
              </div>
            </div>
          )}

          <div className={styles.pair}>
            <div className={styles.info}>
              <b>Кол-во (план):</b>
              <span>
                {planCount} ({measure})
              </span>
            </div>
            <div className={styles.info}>
              <b>Стоимость (по проекту):</b>
              <span>{splitThousands(projectAmount)}</span>
            </div>
          </div>
        </>
      )}

      <ManufacturingModalDivider
        type="progress"
        className={planCreatingDate && !unresolvedRemarks.length ? undefined : styles.mt0}
      />

      {passingDate && (
        <div className={styles.pair}>
          <JournalDay day={moment(passingDate).format("DD/MM/YYYY")} time={moment(passingDate).format("HH:mm")} />
          <div className={styles.pass}>
            <b>Выполнил:</b>
            <span>{typeof passingAuthor === "string" ? passingAuthor : getShortFullName(passingAuthor, 13)}</span>
          </div>
        </div>
      )}

      <div className={styles.pair}>
        <div className={styles.info}>
          <b>Кол-во (к сдаче):</b>
          <span>
            {passingCount} ({measure})
          </span>
        </div>
        <div className={styles.info}>
          <b>Израсходовано:</b>
          <span>{splitThousands(investedAmount)}</span>
        </div>
      </div>

      <div className={cn(styles.pair, styles.centered)}>
        <b>Период выполнения:</b>
        <CalendarRange
          defaultDateStart={dateStart}
          defaultDateEnd={dateEnd}
          disabled
          classNameSelect={styles.calendar}
        />
      </div>

      {acceptedDate && <ManufacturingModalDivider type="accepted" />}

      {acceptedDate && (
        <div className={styles.pair}>
          <JournalDay day={moment(acceptedDate).format("DD/MM/YYYY")} time={moment(acceptedDate).format("HH:mm")} />
          <div className={styles.pass}>
            <b>Принял :</b>
            <span>{typeof acceptedAuthor === "string" ? acceptedAuthor : getShortFullName(acceptedAuthor)}</span>
          </div>
        </div>
      )}

      {acceptedDate && (
        <div className={styles.pair}>
          <div className={styles.info}>
            <b>Кол-во (принято) :</b>
            <span>
              {acceptedCount} ({measure})
            </span>
          </div>
          <div className={styles.info}>
            <b>Стоимость (по проекту):</b>
            <span>{splitThousands(acceptedAmount)}</span>
          </div>
        </div>
      )}
    </>
  );
}

export default ManufacturingModalContentFact;
