import { dropNonSignificantZeros } from "./dropNonSignificantZeros";

const formatCapacity = (value: number) => dropNonSignificantZeros(value, 2);

const mbToGb = (volume: number) => formatCapacity(volume / 1024);

export const formatFileStorageCapacity = (usedMegabytes: number | string, allMegabytes: number | string) => {
  usedMegabytes = Number(usedMegabytes);
  allMegabytes = Number(allMegabytes);

  const rawUsedFormatted = formatCapacity(usedMegabytes);
  const rawAllFormatted = formatCapacity(allMegabytes);

  const usedGigabytes = mbToGb(usedMegabytes);
  const allGigabytes = mbToGb(allMegabytes);

  if (usedMegabytes === 0) {
    return `0 / ${allGigabytes} Гб`;
  } else if (usedMegabytes < 1024 && allMegabytes < 1024) {
    return `${rawUsedFormatted} / ${rawAllFormatted} Мб`;
  } else if (usedMegabytes < 1024 && allMegabytes >= 1024) {
    return `${rawUsedFormatted} Мб / ${allGigabytes} Гб`;
  } else {
    return `${usedGigabytes} / ${allGigabytes} Гб`;
  }
};
