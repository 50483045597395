import { message } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ksListsSelector, ksSublistSelector } from "redux/modules/common/building/documents/ksList/selectors";
import { getKsSublist } from "redux/modules/common/building/documents/ksList/thunks";
import { actingApi } from "redux/modules/common/building/journal/acting/api";

import TemplateSimple from "_LEGACY/UI/_LEGACY_TemplateSimple/TemplateSimple";

import KsStatus from "../Documents/KsList/KsListRow/KsStatus/KsStatus";
import KS3PageRow from "./KS2PageRow/KS3PageRow";
import KSPageDownloadDropDown, { downloadOptionsValuesEnum } from "./KSPageDownloadDropDown/KSPageDownloadDropDown";
import ButtonBack from "shared/ui/controls/ButtonBack/ButtonBack";
import DateCell from "shared/ui/dataDisplay/DateCell/DateCell";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import Paper from "shared/ui/layout/Paper/Paper";

import { useObjectId } from "../Documents/hooks/useObjectId";
import { useQueryParams } from "utils/hooks/useQueryParams";

import twoListsIcon from "images/icons/navigation/twoListsIcon.svg";

import styles from "./KSPage.module.scss";

const KS3Page = () => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const ks3Id = Number(useQueryParams("ks3Id"));

  const ksList = useSelector(ksListsSelector)[objectId];

  const ks3Data = useMemo(() => {
    return ksList?.results?.find((el) => el.id === +ks3Id);
  }, [ksList, ks3Id]);

  const downloadSelectHandler = useCallback(
    (val: string) => {
      if (!ks3Data) return;
      const value = val as downloadOptionsValuesEnum;
      if (value === downloadOptionsValuesEnum.onlyKs) {
        actingApi.getKs3Report(objectId, ks3Data.id, `Акт КС-3 №${ks3Data.title} ${dateString}.xlsx`);
      }
      if (value === downloadOptionsValuesEnum.all) {
        actingApi.createKsExport(ks3Data.id, "3", objectId).then(() => {
          message.success("Выгрузка формируется, подождите");
        });
      }
    },
    [ks3Data, objectId]
  );

  const dateString = `${moment(ks3Data?.start_at).format("DD/MM/YYYY")} - ${moment(ks3Data?.end_at).format(
    "DD/MM/YYYY"
  )}`;

  const ksSublist = useSelector(ksSublistSelector)[ks3Id];

  useEffect(() => {
    dispatch(getKsSublist(ks3Id));
  }, [ks3Id]);

  if (!ks3Data) return null;

  return (
    <div className={styles.wrapper}>
      <TemplateSimple contentClassName={styles.template}>
        <Paper>
          {ks3Data?.id ? (
            <div className={styles.paper}>
              <div className={styles.headline}>
                <div className={styles.head}>
                  <ButtonBack />
                  <h1>Акт КС-3 № {ks3Data?.title}</h1>
                  <KsStatus status={ks3Data?.status} />
                  <KSPageDownloadDropDown type="ks3" onSelect={downloadSelectHandler} />
                </div>

                <div className={styles.subtitle}>
                  <div className={styles.periodTitle}>Отчетный период:</div>
                  <DateCell date={dateString} className={styles.date} />
                </div>
              </div>
              <div className={styles.list}>
                {ksSublist?.ks2_acts?.map((el) => (
                  <KS3PageRow key={el.id} act={el} isAutoExpand={ksSublist?.ks2_acts?.length === 1} />
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.paper}>
              <div className={styles.head}>
                <ButtonBack />
                <h1>Акт КС-3</h1>
              </div>

              <EmptyPlaceholder text="Нет данных" img={twoListsIcon} />
            </div>
          )}
        </Paper>
      </TemplateSimple>
    </div>
  );
};

export default React.memo(KS3Page);
