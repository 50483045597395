import React, { useState } from "react";

import CrossRoundedIcon from "images/icons/CrossRoundedIcon";
import PlusInCircleIcon from "images/icons/PlusInCircleIcon";
import PlusInFolders from "images/icons/PlusInFolders";

import styles from "../Section/index.module.scss";

const SubSection = ({ data, blockOpenExpenditure }) => {
  return (
    <>
      <div className={styles.container} onClick={() => blockOpenExpenditure(data)}>
        <div className={styles.name}>{data?.name}</div>
        <div className={styles.count}>
          <span>Материалы: </span>
          {data?.mims_count}
        </div>
      </div>
    </>
  );
};

export default SubSection;
