import cn from "classnames";
import React from "react";

import styles from "./TableHeader.module.scss";

interface IProps {
  className?: string;
}

const ExpendituresTableHeader: React.FC<IProps> = ({ className }) => {
  return (
    <div className={cn(styles.header, styles.expendituresHeader, className)}>
      <div />
      <div className={styles.name}>Расценка</div>
      <div>Кол-во</div>
      <div className={styles.centered}>По проекту</div>
    </div>
  );
};

export default React.memo(ExpendituresTableHeader);
