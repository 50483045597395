import { Dispatch } from "redux";

import { buildingApi } from "./api";
import { buildingActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { IGetBuildingSectionsParams } from "./types";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getBuildingSubsections =
  (objectId: string, params: IGetBuildingSectionsParams) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ buildingId: objectId, id: params.parent, type: "subSections" });
    if (getState().buildingReducer.loadings[key] === undefined) {
      dispatch(buildingActions.setIsLoading({ key, status: true }));
    }
    buildingApi
      .getSections(objectId, params)
      .then(({ data }) => {
        dispatch(buildingActions.setSections({ key, data: data.results }));
      })
      .finally(() => {
        dispatch(buildingActions.setIsLoading({ key, status: false }));
      });
  };
