import { concatenateWithoutDuplicates } from "utils/concatenateWithoutDuplicates";

import {
  ADD_EMPLOYEES, APPROVAL_TYPES,
  DELETE_EMPLOYEE,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  RESET_TO_INITIAL
} from "./constants";

const initialState = {
  [APPROVAL_TYPES.order]: { isLoading: true, list: null },
  [APPROVAL_TYPES.payment]: { isLoading: true, list: null }
};

const matchStateByApprovalTypeToGlobalState = (approvalType, globalState, updatedState) => ({
  ...globalState,
  [approvalType]: updatedState
});

const approvalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMPLOYEES:
      return matchStateByApprovalTypeToGlobalState(action.approvalType, state, {
        ...state[action.approvalType],
        list: concatenateWithoutDuplicates(state[action.approvalType].list, action.payload, "id")
      });
    case DELETE_EMPLOYEE:
      return matchStateByApprovalTypeToGlobalState(action.approvalType, state, {
        ...state[action.approvalType],
        list: state[action.approvalType].list.filter(item => item.id !== action.payload)
      });
    case GET_EMPLOYEES_REQUEST:
      return matchStateByApprovalTypeToGlobalState(action.approvalType, state, {
        ...state[action.approvalType],
        isLoading: true
      });
    case GET_EMPLOYEES_SUCCESS:
      return matchStateByApprovalTypeToGlobalState(action.approvalType, state, {
        isLoading: false,
        list: action.payload
      });
    case RESET_TO_INITIAL:
      return initialState;
    default:
      return state;
  }
};

export default approvalReducer;