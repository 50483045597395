import cn from "classnames";
import React, { useCallback, useState } from "react";

import AddContractorModal from "components/pages/Settings/EditProject/components/AddContractorModal/AddContractorModal";
import ContractorsTable from "components/pages/Settings/EditProject/components/ContractorsTable/ContractorsTable";
import useProjectContractors from "components/pages/Settings/EditProject/hooks/useProjectContractors";

import styles from "./EditProject.module.scss";

interface IEditProjectContractorsProps {
  buildingId: string;
  isDisabled?: boolean;
}

type OpenAddModal = () => void;
type CloseAddModal = OpenAddModal;

const EditProjectContractors = ({ buildingId, isDisabled }: IEditProjectContractorsProps) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const openAddModal = useCallback(() => setIsAddModalOpen(true), []);
  const closeAddModal = useCallback(() => setIsAddModalOpen(false), []);

  const {
    availableForAddContractors,
    availableForAddContractorsCount,
    changeAllContractorsFilter,
    ourContractors,
    ourContractorsCount,
    ourContractorsLoading,
    filterHandler,
    deleteOurContractor,
    loadMoreHandler,
    addOurContractors,
  } = useProjectContractors(buildingId);

  return (
    <div className={cn(styles.fullWidth, { [styles.disabled]: isDisabled })}>
      <ContractorsTable
        ourContractors={ourContractors}
        ourContractorsCount={ourContractorsCount}
        ourContractorsLoading={ourContractorsLoading}
        openAddModal={openAddModal}
        filterHandler={filterHandler}
        loadMoreHandler={loadMoreHandler}
        deleteOurContractor={deleteOurContractor}
      />
      <AddContractorModal
        contractors={availableForAddContractors}
        contractorsCount={availableForAddContractorsCount}
        onClose={closeAddModal}
        isOpen={isAddModalOpen}
        handleChangeFilter={changeAllContractorsFilter}
        handleLoadMore={loadMoreHandler}
        addContractors={addOurContractors}
        isLoading={ourContractorsLoading}
      />
    </div>
  );
};

export default React.memo(EditProjectContractors);
export type { IEditProjectContractorsProps, OpenAddModal, CloseAddModal };
