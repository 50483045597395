import { message } from "antd";
import { Dispatch } from "redux";

import { contractorsApi } from "./api";
import { contractorsActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { prepareTableData } from "./utils";
import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const loadContractors =
  (filters: Record<string, any>, initialLimit: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ type: "loadList", ...filters });
    if (!getState().counterpartiesV2.invites.list[key]) {
      dispatch(contractorsActions.setIsLoading({ key, status: true }));
    }
    contractorsApi
      .loadList({ ...filters, offset: 0, limit: initialLimit })
      .then(({ data }) => {
        const preparedData = { count: data.count, results: prepareTableData(data.results) };
        dispatch(contractorsActions.setList({ key, data: preparedData }));
      })
      .finally(() => {
        dispatch(contractorsActions.setIsLoading({ key, status: false }));
      });
  };

export const loadMoreContractors =
  (filters: Record<string, any>, offset: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ type: "loadList", ...filters });
    contractorsApi.loadList({ ...filters, offset }).then(({ data }) => {
      const preparedData = { count: data.count, results: prepareTableData(data.results) };
      dispatch(contractorsActions.setMoreList({ key, data: preparedData }));
    });
  };

export const deleteContractor =
  (id: number, successCallback?: () => void) => (dispatch: Dispatch, getState: () => RootState) => {
    contractorsApi
      .delete(id)
      .then(() => {
        dispatch(contractorsActions.invalidateKey());
        successCallback?.();
      })
      .catch(errorCatcher);
  };

export const loadContractorDetail = (id: number) => (dispatch: Dispatch, getState: () => RootState) => {
  const key = String(id);
  if (!getState().counterpartiesV2.contractors.detail[key]) {
    dispatch(contractorsActions.setIsLoading({ key, status: true }));
  }
  contractorsApi
    .loadDetail(key)
    .then(({ data }) => {
      dispatch(contractorsActions.setDetail({ key, data }));
    })
    .finally(() => {
      dispatch(contractorsActions.setIsLoading({ key, status: false }));
    });
};

export const inviteContractor = (rawData: any, successCallback?: () => void) => (dispatch: Dispatch) => {
  const data = {
    itn: rawData.itn,
    email: rawData.email,
    from_type: "contractor",
  };

  contractorsApi
    .invite(data)
    .then(() => {
      message.success("Приглашение успешно отправлено");
      successCallback?.();
    })
    .catch(errorCatcher);
};

export const changeContractorManager =
  (contractorId: string | number, managerId: number | null) => (dispatch: Dispatch) => {
    contractorsApi
      .changeManager(contractorId, managerId)
      .then(() => {
        message.success("Сохранено");
        dispatch(contractorsActions.invalidateKey());
      })
      .catch(errorCatcher);
  };
