import React from 'react';
import {ASC, DESC} from "../TableHeader/TableHeader";

export class SortCaret extends React.Component{

  selectButton = state => {

    const { elements } = this.props;

    switch (state) {
      case DESC:
        return elements.descElement;
      case ASC:
        return elements.ascElement;
      default:
        return elements.defaultElement;
    }
  };

  render(){

    return this.selectButton(this.props.nowSort);

  }
}