import { Dispatch } from "redux";

import { tasksApi } from "components/pages/Tasks/model/api";
import { tasksFieldsFilterType } from "components/pages/Tasks/model/types";

import { addCheckpointStateActions } from "./addCheckpointSlice";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const loadCheckpointSelects = (params: tasksFieldsFilterType) => (dispatch: Dispatch) => {
  tasksApi
    .getTasksFields(params)
    .then(({ data }) => {
      dispatch(addCheckpointStateActions.setSelects(data));
    })
    .catch(errorCatcher);
};
