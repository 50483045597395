import cn from "classnames";
import React, { MouseEventHandler, ReactNode, useMemo } from "react";

import ButtonBase from "../ButtonBase";

import CheckIcon from "./icons/Check";
import CrossIcon from "./icons/Cross";
import EditIcon from "./icons/Edit";

import styles from "./ButtonPill.module.scss";

export interface IButtonPillProps {
  icon: "check" | "edit" | "cross";
  variant: "primary" | "default";
  onClick: MouseEventHandler;
  className?: string;
  buttonClassName?: string;
  secondIcon?: IButtonPillProps["icon"];
  secondVariant?: IButtonPillProps["variant"];
  onClickSecond?: MouseEventHandler;
  title?: string;
  secondTitle?: string;
}

const formIcons = (color: string | undefined): Record<IButtonPillProps["icon"], ReactNode> => ({
  check: <CheckIcon color={color} />,
  edit: <EditIcon color={color} />,
  cross: <CrossIcon color={color} />,
});

const ButtonPill: React.FC<IButtonPillProps> = ({
  buttonClassName,
  icon,
  variant = "default",
  onClick,
  className,
  title,
  secondIcon,
  secondVariant,
  onClickSecond,
  secondTitle,
}) => {
  const ICONS_FIRST = useMemo(() => formIcons(variant === "primary" ? "white" : "black"), [variant]);
  const ICONS_SECOND = useMemo(() => formIcons(secondVariant === "primary" ? "white" : "black"), [secondVariant]);
  const isShownSecondButton = !!secondIcon;
  return (
    <div className={cn(styles.container, { [styles.doubleButtonWidth]: isShownSecondButton }, className)}>
      <ButtonBase
        onClick={onClick}
        className={cn(styles.button, styles[variant], { [styles.firstButton]: isShownSecondButton }, buttonClassName)}
        /* @ts-ignore */
        title={title}
      >
        {ICONS_FIRST[icon]}
      </ButtonBase>
      {isShownSecondButton && (
        <ButtonBase
          onClick={onClickSecond}
          className={cn(styles.button, styles[secondVariant!], styles.secondButton, buttonClassName)}
          /* @ts-ignore */
          title={secondTitle}
        >
          {/* @ts-ignore */}
          {ICONS_SECOND[secondIcon]}
        </ButtonBase>
      )}
    </div>
  );
};

export default React.memo(ButtonPill);
