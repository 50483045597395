import { MODULES_ENUM } from "../../../../types/enums/ModulesEnum";
import { IIndicatorsInState, IUIIndicatorResponse, IUiIndicatorsInitialState, UIIndicatorsType } from "./types";

export const setIndicators = (
  state: IUiIndicatorsInitialState,
  indicators: IUIIndicatorResponse[],
  allIndicatorsInModuleCount: number,
  module: MODULES_ENUM,
  buildingId?: number
) => {
  const stateCandidate = {
    ...state,
  };
  if (module === MODULES_ENUM.OBJECTS && !!buildingId) {
    stateCandidate[MODULES_ENUM.OBJECTS] = {
      ...stateCandidate[MODULES_ENUM.OBJECTS],
      [buildingId]: {
        indicatorsCount: allIndicatorsInModuleCount,
        indicators,
      },
    };
  } else {
    stateCandidate[module] = {
      ...stateCandidate[module],
      indicatorsCount: allIndicatorsInModuleCount,
      indicators,
    };
  }
  return stateCandidate;
};

export const pushIndicator = (state: IUiIndicatorsInitialState, indicator: IUIIndicatorResponse) => {
  let stateCandidate = {
    ...state,
  };
  indicator?.modules?.forEach((indicatorModule) => {
    const indicatorsInState =
      indicatorModule === MODULES_ENUM.OBJECTS
        ? state[indicatorModule]?.[indicator.building_id]
        : state[indicatorModule];
    const newIndicatorsInModule = Array.from(new Set(indicatorsInState?.indicators?.concat(indicator) || []));
    stateCandidate = setIndicators(
      stateCandidate,
      newIndicatorsInModule,
      newIndicatorsInModule.length,
      indicatorModule,
      indicator.building_id
    );
  });
  return stateCandidate;
};

export const removeIndicators = (
  state: IUiIndicatorsInitialState,
  indicatorId: number,
  modules: MODULES_ENUM[],
  buildingId?: number
) => {
  const stateCandidate = {
    ...state,
  };
  modules
    .filter((x) => x !== MODULES_ENUM.OBJECTS)
    .forEach((module) => {
      const newIndicatorsByModule =
        (stateCandidate[module] as IIndicatorsInState)?.indicators?.filter((i) => i.id !== indicatorId) || [];
      stateCandidate[module] = {
        ...stateCandidate[module],
        indicatorsCount: newIndicatorsByModule.length,
        indicators: newIndicatorsByModule,
      };
    });
  if (modules.includes(MODULES_ENUM.OBJECTS) && !!buildingId) {
    const newIndicatorsByBuilding =
      stateCandidate[MODULES_ENUM.OBJECTS]?.[buildingId]?.indicators?.filter((i) => i.id !== indicatorId) || [];
    stateCandidate[MODULES_ENUM.OBJECTS] = {
      ...stateCandidate[MODULES_ENUM.OBJECTS],
      [buildingId]: {
        indicatorsCount: newIndicatorsByBuilding.length,
        indicators: newIndicatorsByBuilding,
      },
    };
  }
  return stateCandidate;
};

export const indicatorMatchesType = (indicator: IUIIndicatorResponse, type: UIIndicatorsType | UIIndicatorsType[]) => {
  if (Array.isArray(type)) return type.includes(indicator.type);
  return type === indicator.type;
};
