import { VIEW_BUILDING_UNGROUP_EXPENDITURES } from "./constructingPermissions";

export const VIEW_MANUFACTURING_SECTION = "viewManufacturingSection";

export const VIEW_MANUFACTURING_CHART = "viewManufacturingChart";
export const VIEW_MANUFACTURING_CHART_WORKS = "viewManufacturingChartWorks";
export const VIEW_MANUFACTURING_CHART_RESOURCES = "viewManufacturingChartResources";
export const VIEW_MANUFACTURING_CHART_EDIT_RELATIONS = "viewManufacturingChartEditRelations";

export const VIEW_MANUFACTURING_PROJECT = "viewManufacturingProject";
export const VIEW_MANUFACTURING_PROJECT_PLAN = "viewManufacturingProjectPlan";
export const VIEW_MANUFACTURING_PROJECT_PLAN_ADD_WORK = "viewManufacturingProjectPlanAddWork";
export const VIEW_MANUFACTURING_SHARE_PLAN_WORK = "viewManufacturingProjectSharePlanWork";
export const VIEW_MANUFACTURING_PROJECT_PROGRESS = "viewManufacturingProjectProgress";
export const VIEW_MANUFACTURING_PROJECT_PROGRESS_ADD_WORK = "viewManufacturingProjectProgressAddWork";
export const VIEW_MANUFACTURING_SHARE_PROGRESS_WORK = "viewManufacturingProjectShareProgressWork";
export const VIEW_MANUFACTURING_PROJECT_ADD_GROUPS = "viewManufacturingProjectAddGroups";
export const VIEW_MANUFACTURING_PROJECT_UNGROUP_GROUPS = "viewManufacturingProjectUngroupGroups";
export const VIEW_MANUFACTURING_PROJECT_EDIT_GROUPS = "viewManufacturingProjectEditGroups";
export const VIEW_MANUFACTURING_PROJECT_UNGROUP_EXPENDITURES = "viewManufacturingProjectUngroupExpenditures";
export const VIEW_MANUFACTURING_PROJECT_ADD_FILES = "viewManufacturingProjectAddFiles";
export const VIEW_MANUFACTURING_PROJECT_DELETE_FILES = "viewManufacturingProjectDeleteFiles";

export const VIEW_MANUFACTURING_JOURNAL = "viewManufacturingJournal";
export const VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE = "viewManufacturingJournalAcceptance";
export const VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_ADD_TICKET = "viewManufacturingJournalAcceptanceAddTicket";
export const VIEW_MANUFACTIRING_JOURNAL_ACCEPTANCE_EDIT_TICKET = "viewManufacturingJournalAcceptanceEditTicket";
export const VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_APPROVE_TICKET = "viewManufacturingJournalAcceptanceApproveTicket";
export const VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_TICKET_DELETE_FILE =
  "viewManufacturingJournalAcceptanceTicketDeleteFile";
export const VIEW_MANUFACTURING_JOURNAL_PROGRESS = "viewManufacturingJournalProgress";
export const VIEW_MANUFACTURING_JOURNAL_PROGRESS_APPROVE = "viewManufacturingJournalProgressApprove";
export const VIEW_MANUFACTURING_JOURNAL_PROGRESS_ATTACH_KS23 = "viewManufacturingJournalProgressAttachKs23";
export const VIEW_MANUFACTURING_JOURNAL_PROGRESS_KS6A = "viewManufacturingJournalProgressKs6a";
export const VIEW_MANUFACTURING_JOURNAL_PROGRESS_REMARKS = "viewManufacturingJournalProgressRemarks";
export const VIEW_MANUFACTURING_JOURNAL_PROGRESS_CREATE_REMARK = "viewManufacturingJournalProgressCreateRemark";
export const VIEW_MANUFACTURING_JOURNAL_PROGRESS_FORMATION_AOSR = "viewManufacturingJournalProgressFormationAosr";
export const VIEW_MANUFACTURING_JOURNAL_PROGRESS_EDIT_AOSR = "viewManufacturingJournalProgressEditAosr";
export const VIEW_MANUFACTURING_JOURNAL_PROGRESS_IMPORT_AOSR = "viewManufacturingJournalProgressImportAosr";
export const VIEW_MANUFACTURING_JOURNAL_PROGRESS_TRANSFER_TO_ACTS = "viewManufacturingJournalProgressTransferToActs";

export const VIEW_MANUFACTURING_JOURNAL_ACTS = "viewManufacturingJournalActs";
export const VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS2 = "viewManufacturingJournalActsFormationKs2";
export const VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS3 = "viewManufacturingJournalActsFormationKs3";

export const VIEW_MANUFACTURING_WORKERS = "viewManufacturingWorkers";
export const VIEW_MANUFACTURING_WORKERS_TURNOUT = "viewManufacturingWorkersTurnout";
export const VIEW_MANUFACTURING_WORKERS_CARD_EXPORT_FILES = "viewManufacturingWorkersCardExportFiles";
export const VIEW_MANUFACTURING_WORKERS_CARD_DELETE_FILES = "viewManufacturingWorkersCardDeleteFiles";
export const VIEW_MANUFACTURING_WORKERS_TURNOUT_EDIT = "viewManufacturingWorkersTurnoutEdit";
export const VIEW_MANUFACTURING_WORKERS_TIMESHEET = "viewManufacturingWorkersTimesheet";
export const VIEW_MANUFACTURING_WORKERS_TIMESHEET_ADD_APPROVERS = "viewManufacturingWorkersTimesheetAddApprovers";
export const VIEW_MANUFACTURING_WORKERS_TIMESHEET_ADD_WORKERS = "viewManufacturingWorkersTimesheetAddWorkers";
export const VIEW_MANUFACTURING_WORKERS_EXPORT_WORKERS = "viewManufacturingWorkersExportWorkers";
export const VIEW_MANUFACTURING_WORKERS_IMPORT_WORKERS = "viewManufacturingWorkersImportWorkers";

export const VIEW_MANUFACTURING_REQUISITION_SECTION = "viewManufacturingRequisition";
export const VIEW_MANUFACTURING_REQUISITION_PAGE = "viewManufacturingRequisitionPage";
export const VIEW_MANUFACTURING_REQUISITION_PROCESS = "viewManufacturingRequisitionProcess";
export const VIEW_MANUFACTURING_REQUISITION_HANDLING_MODE = "viewManufacturingRequisitionHandlingMode";
export const VIEW_MANUFACTURING_EDIT_REQUISITION_BEFORE_APPROVE = "viewManufacturingRequisitionAddProducts";
export const VIEW_MANUFACTURING_REQUISITION_ADD_APPROVERS = "viewManufacturingRequisitionAddApprovers";
export const VIEW_MANUFACTURING_REQUISITION_ADD_VIEWERS = "viewManufacturingRequisitionAddViewers";
export const VIEW_MANUFACTURING_REQUISITION_DELETE_APPROVERS = "viewManufacturingRequisitionDeleteApprovers";
export const VIEW_MANUFACTURING_REQUISITION_DELETE_VIEWERS = "viewManufacturingRequisitionDeleteViewers";
export const VIEW_MANUFACTURING_REQUISITION_CANCEL_APPROVING = "viewManufacturingRequisitionCancelApproving";
export const VIEW_MANUFACTURING_REQUISITION_LOGS = "viewManufacturingRequisitionLogs";
export const VIEW_MANUFACTURING_REQUISITION_PROCESS_ORDERS = "viewManufacturingRequisitionProcessOrders";
export const VIEW_MANUFACTURING_REQUISITION_PROCESS_PAYMENTS = "viewManufacturingRequisitionProcessPayments";
export const VIEW_MANUFACTURING_REQUISITION_CREATING_REQUISITION = "viewManufacturingRequisitionCreatingRequisition";
export const VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_OUT_OF_ESTIMATE =
  "viewManufacturingRequisitionCreatingProductOutOfEstimate";
export const VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_ADDITIONAL =
  "viewManufacturingRequisitionCreatingProductAdditional";
export const VIEW_MANUFACTURING_REQUISITION_DELETE_PRODUCT_FILES = "viewManufacturingRequisitionDeleteProductFiles";
export const VIEW_MANUFACTURING_REQUISITION_ASSIGN_EXECUTOR = "viewManufacturingRequisitionAssignExecutor";
export const VIEW_MANUFACTURING_REQUISITION_TAKE_TO_WORK = "viewManufacturingRequisitionTakeToWork";
export const VIEW_MANUFACTURING_REQUISITION_REASSIGN_EXECUTOR = "viewManufacturingRequisitionReassignExecutor";
export const VIEW_MANUFACTURING_REQUISITION_ADD_NEW_PROVIDER = "viewManufacturingRequisitionAddNewProvider";
export const VIEW_MANUFACTURING_REQUISITION_COMPLETE = "viewManufacturingRequisitionComplete";

export const VIEW_MANUFACTURING_STOCKS_SECTION = "viewManufacturingStocksSection";
export const VIEW_MANUFACTURING_STOCKS_INCOMES = "viewManufacturingStocksIncomes";
export const VIEW_MANUFACTURING_STOCKS_OUTCOMES = "viewManufacturingStocksOutcomes";
export const VIEW_MANUFACTURING_STOCKS_MOVINGS = "viewManufacturingStocksMovings";
export const VIEW_MANUFACTURING_STOCKS_TO_PRODUCTION = "viewManufacturingStocksToProduction";
export const VIEW_MANUFACTURING_STOCKS_UTILIZATION = "viewManufacturingStocksUtilization";
export const VIEW_MANUFACTURING_STOCKS_DELETE_PRODUCT_FILES = "viewManufacturingStocksDeleteProductFiles";

export const VIEW_MANUFACTURING_MATERIALS_SECTION = "viewManufacturingMaterialsSection";
export const VIEW_MANUFACTURING_MATERIALS_ESTIMATE = "viewManufacturingMaterialsEstimate";
export const VIEW_MANUFACTURING_MATERIALS_ESTIMATE_EDITING = "viewManufacturingMaterialsEstimateEditing";
export const VIEW_MANUFACTURING_MATERIALS_SPECIFICATION = "viewManufacturingMaterialsSpecification";
export const VIEW_MANUFACTURING_MATERIALS_SPECIFICATION_EDITING = "viewManufacturingMaterialsSpecificationEditing";
export const VIEW_MANUFACTURING_MATERIALS_ADDITIONAL = "viewManufacturingMaterialsAdditional";
export const VIEW_MANUFACTURING_MATERIALS_ADDITIONAL_EDITING = "viewManufacturingMaterialsAdditionalEditing";

export const VIEW_MANUFACTURING_PLAN_FACT_SECTION = "viewManufacturingPlanFactSection";

// OSLA-5302 костыль, вернуть обратно, когда приедут новые пермишены
// export const VIEW_PLAN_FACT_VOLUME = "viewPlanFactVolume";
// export const VIEW_PLAN_FACT_FINANCE = "viewPlanFactFinance";

export const VIEW_PLAN_FACT_VOLUME = "viewPlanFactFinance";
export const VIEW_PLAN_FACT_FINANCE = "viewPlanFactVolume";
// ---------

export const VIEW_MANUFACTURING_ORDER_SECTION = "viewManufacturingOrdersSection";
export const VIEW_MANUFACTURING_ORDER_FILES_ATTACH = "viewManufacturingOrderAttachFile";
export const VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_ATTACH = "viewManufacturingOrderMaterialAttachFile";
export const VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_DELETE = "viewManufacturingDeleteOrderMaterialAttachedFile";
export const VIEW_MANUFACTURING_ORDER_AUTOMATIC_AGREEMENT_TRANSITION =
  "viewManufacturingAutomaticTransitionByAgreement";
export const VIEW_MANUFACTURING_ORDER_INVOICE_DIFFERENCE = "viewManufacturingOrderInvoiceDifference";
export const VIEW_MANUFACTURING_ORDER_FILES_DELETE = "viewManufacturingDeleteOrderAttachedFile";

export const VIEW_MANUFACTURING_BILL_SECTION = "viewManufacturingBillSection";
export const VIEW_MANUFACTURING_BILL_EDITING = "viewManufacturingBillEditing";
export const VIEW_MANUFACTURING_BILL_ATTACH_FILES = "viewManufacturingBillAttachFiles";
export const VIEW_MANUFACTURING_BILL_DELETE_FILES = "viewManufacturingBillDeleteFiles";
export const VIEW_MANUFACTURING_BILL_ATTACH_PAYMENT_DOC = "viewManufacturingBillAttachPaymentDoc";
export const VIEW_MANUFACTURING_BILL_MARK_PAYMENT_AS_PAYED = "viewManufacturingBillMarkPaymentAsPayed";

export const VIEW_MANUFACTURING_PACKING_LIST_SECTION = "viewManufacturingPackingListSection";
export const VIEW_MANUFACTURING_PACKING_LIST_ACCEPT = "viewManufacturingPackingListAccept";
export const VIEW_MANUFACTURING_PACKING_LIST_ATTACH_FILES = "viewManufacturingPackingListAttachFiles";
export const VIEW_MANUFACTURING_PACKING_LIST_DELETE_FILES = "viewManufacturingPackingListDeleteFiles";

export const VIEW_MANUFACTURING_CREATE_DIRECTIVE_PLAN = "viewManufacturingCreateDirectivePlan";
export const VIEW_MANUFACTURING_EDIT_DIRECTIVE_PLAN = "viewManufacturingEditDirectivePlan";
export const VIEW_MANUFACTURING_CREATE_SECTION_PLAN = "viewManufacturingCreateSectionPlan";
export const VIEW_MANUFACTURING_EDIT_SECTION_PLAN = "viewManufacturingEditSectionPlan";

export const VIEW_MANUFACTURING_BETON = "viewManufacturingBeton";
export const VIEW_MANUFACTURING_BETON_INCOMES = "viewManufacturingBetonIncomes";
export const VIEW_MANUFACTURING_BETON_TO_PRODUCTION = "viewManufacturingBetonToProduction";
export const VIEW_MANUFACTURING_BETON_OUTCOMES = "viewManufacturingBetonOutcomes";
export const VIEW_MANUFACTURING_BETON_OUTCOMES_ADD_DETAILS = "viewManufacturingBetonOutcomesAddDetails";
export const VIEW_MANUFACTURING_BETON_OUTCOMES_EDIT_DETAILS = "viewManufacturingBetonOutcomesEditDetails";
export const VIEW_MANUFACTURING_BETON_OUTCOMES_ADD_DOCS = "viewManufacturingBetonOutcomesAddDocs";

export const VIEW_MANUFACTURING_KS_LISTS = "viewManufacturingKsLists";
export const CAN_ACCEPT_JOURNAL_DELIVERY_TICKETS_WITHOUT_APPROVE = "canAcceptJournalDeliveryTicketsWithoutApprove";

export const VIEW_MANUFACTURING_DRAWINGS = "viewManufacturingDrawingSetsTab";
export const VIEW_MANUFACTURING_DRAWINGS_SETS_CREATE = "viewManufacturingDrawingSetCreate";
export const VIEW_MANUFACTURING_DRAWINGS_SETS_EDIT = "viewManufacturingDrawingSetEdit";
export const VIEW_MANUFACTURING_DRAWINGS_SETS_DELETE = "viewManufacturingDrawingSetDelete";
export const VIEW_MANUFACTURING_DRAWINGS_CREATE = "viewManufacturingDrawingCreate";
export const VIEW_MANUFACTURING_DRAWINGS_EDIT = "viewManufacturingDrawingEdit";
export const VIEW_MANUFACTURING_DRAWINGS_DELETE = "viewManufacturingDrawingDelete";
export const VIEW_MANUFACTURING_PLANS_DELETE = "viewManufacturingProjectPlansDelete";
export const VIEW_MANUFACTURING_CHART_PLANS_DELETE = "viewManufacturingChartPlansDelete";

export const VIEW_MANUFACTURING_CHART_EDIT_PLAN = "viewManufacturingChartEditPlan";
export const VIEW_MANUFACTURING_PROJECT_PLAN_EDIT_PLAN = "viewManufacturingProjectPlanEditPlan";