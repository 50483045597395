export enum CHART_COLORS {
  ESTIMATE = "#707070",
  BUDGET = "#FF009D",
  ESTIMATED_COST = "#65A0FF",
  FACT = "#7EE5B5",
  CURSOR = "#4FB1EB",
}

export const matchChartLabels = (rawLabel: "estimate" | "budget" | "estimated_cost" | "fact") => {
  switch (rawLabel) {
    case "estimate":
      return "Смета";
    case "budget":
      return "Бюджет";
    case "estimated_cost":
      return "План";
    case "fact":
      return "Факт";
    default:
      return "";
  }
};
