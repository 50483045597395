import useEscapeHandler from 'utils/hooks/useEscapeHandler';
import { IModal } from '../types/types';


export interface IuseModalDisplaying extends Omit<IModal, "children"> {
  styles: { readonly [key: string]: string; };
}

export const useModalDisplaying = ({ isVisible, setIsVisible, styles }: IuseModalDisplaying) => {
  const rootClasses = [styles.myModal];

  if (isVisible) {
    rootClasses.push(styles.active);
  }

  const closeModal = () => {
    setIsVisible(prev => !prev);
  }

  useEscapeHandler(() => setIsVisible(false));

  return {
    closeModal,
    rootClasses
  };
};
