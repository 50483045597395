import cn from "classnames";
import React, { MouseEventHandler } from "react";

import Icon from "../../../../_LEGACY/UI/_LEGACY_Icon/Icon";
import { TaskStatus } from "../../../pages/Tasks/constants";

import { EventStatus, EventType } from "../../../../types/interfaces/Event";

import styles from "./EventItem.module.scss";

export interface IEventItemBodyProps {
  children: React.ReactNode;
  status: EventStatus | TaskStatus;
  name: string;
  eventType: EventType;
  isCompleted: boolean;
  eventStatusSwitcher: (
    status: EventStatus | TaskStatus,
    eventType: EventType,
    isCompleted?: boolean
  ) => {
    containerClassName: string;
    statusClassName: string;
    statusText: string;
    statusIcon: string;
  };
  onClick?: MouseEventHandler;
  className?: string;
  statusDisplay?: string;
}

const EventItemBody: React.FC<IEventItemBodyProps> = ({
  children,
  status,
  name,
  eventType,
  isCompleted,
  eventStatusSwitcher,
  onClick,
  className,
  statusDisplay,
}) => {
  const { containerClassName, statusClassName, statusText, statusIcon } = eventStatusSwitcher(
    status,
    eventType,
    isCompleted
  );
  return (
    <div className={cn(styles.eventItem, containerClassName, className)} onClick={onClick}>
      <span className={styles.eventItemName}>{name}</span>
      {(!!statusIcon || !!statusDisplay || !!statusText) && (
        <div className={cn(styles.eventStatus, statusClassName)}>
          {!!statusIcon && <Icon className={styles.statusIcon} icon={statusIcon} />}
          {statusDisplay || statusText}
        </div>
      )}
      <div className={styles.eventData}>{children}</div>
    </div>
  );
};

export default React.memo(EventItemBody);
