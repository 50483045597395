import React from "react";

const ImportDraftIcon = React.lazy(() => import("./ImportDraftIcon"));

const ImportDraftIconLazy = () => (
  <React.Suspense fallback={null}>
    <ImportDraftIcon />
  </React.Suspense>
);

export default React.memo(ImportDraftIconLazy);
