import { IActions } from "app/store/store";

import { IAosrDoc } from "../aosr/types";
import { IAook } from "./types";
import { IExpenditure } from "types/interfaces/Expenditure";

export const aookActions = {
  setCurrentAook: (aook: IAook | null) =>
    ({
      type: "aook/SET_CURRENT_AOOK",
      payload: aook,
    } as const),
  setDetailedAook: (aook: IAook) =>
    ({
      type: "aook/SET_DETAILED_AOOK",
      payload: aook,
    } as const),
  setDocs: (docs: IAosrDoc[], aookId: number) =>
    ({
      type: "aook/SET_DOCS",
      payload: { docs, aookId },
    } as const),
  setWorksBySection: (works: IExpenditure[], sectionId: number) =>
    ({
      type: "aook/SET_WORKS",
      payload: { works, sectionId },
    } as const),
  setClosingPending: (status: boolean, aookId: number) =>
    ({
      type: "aook/SET_CLOSING_PENDING",
      payload: { status, aookId },
    } as const),
  invalidateKey: () =>
    ({
      type: "aook/INVALIDATE_KEY",
      payload: true,
    } as const),
  setCreatingPending: (status: boolean, aookId: number) =>
    ({
      type: "aook/SET_CREATING_PENDING",
      payload: { status, aookId },
    } as const),
};

export type IAookAction = IActions<typeof aookActions>;
