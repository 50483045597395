import { IAosrDoc } from "./types";

export const separateDocsWhileEditingAct = (formDocs: IAosrDoc[], initialDocs: IAosrDoc[]) => {
  const newDocs = formDocs.filter((el) => !el.id);

  const formDocsIdsDict = formDocs.reduce<Record<number, boolean>>((acc, el) => {
    return { ...acc, [el.id]: true };
  }, {});

  const deletingDocsIds = initialDocs.filter((el) => !!el.id && !formDocsIdsDict[el.id]).map((el) => el.id);

  return {
    newDocs,
    deletingDocsIds,
  };
};
