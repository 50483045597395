import {useCallback, useState} from 'react'
import {DraggableData, DraggableEvent} from 'react-draggable'

export interface XYChords {
  x?: number;
  y?: number;
}

export const useChatPosition = ({ x, y }: XYChords) => {
  const [position, setPosition] = useState<Required<XYChords>>({ x: x || 0, y: y || 0 });
  const [isDrag, setIsDrag] = useState<boolean>(false)

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    setPosition({
      x: data.x,
      y: data.y,
    });
    if (position.x !== data.x || position.y !== data.y) setIsDrag(true)
  };

  const allowOpenChat = useCallback(() => setIsDrag(false), [])

  return {
    position,
    handleDrag,
    isDrag,
    allowOpenChat,
  };
};