import React from "react";

import styles from "./BetonVolume.module.scss";

interface IProps {
  measure: string;
  volume: string;
  leftVolume: string;
}

const BetonVolume: React.FC<IProps> = ({ volume, leftVolume, measure }) => {
  return (
    <div className={styles.container}>
      <div className={styles.value}>{volume}</div>
      <div>
        <div className={styles.divider} />
      </div>
      <div className={styles.value}>{leftVolume}</div>
      <div className={styles.measure}>{measure}</div>
    </div>
  );
};

export default BetonVolume;
