import React from 'react'

export default function ContractorsIcon({color = '#000', width = '24'}) {
  return (
    <svg
      width={width}
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2,18.9c-0.4-0.4-0.8-0.7-1.3-0.9c-0.2-0.1-0.3-0.1-0.5-0.2c0.3-0.1,0.6-0.3,0.8-0.5c0.2-0.2,0.4-0.5,0.6-0.9 c0.1-0.3,0.2-0.7,0.2-1c0-0.5-0.2-1-0.4-1.5c-0.3-0.4-0.7-0.8-1.2-1c-0.5-0.2-1-0.3-1.5-0.1c-0.5,0.1-1,0.4-1.4,0.7 c-0.4,0.4-0.6,0.8-0.7,1.4c-0.1,0.5,0,1,0.2,1.5c0.2,0.5,0.5,0.9,1,1.2c0.1,0.1,0.2,0.1,0.4,0.2c-0.2,0-0.3,0.1-0.5,0.2 c-0.5,0.2-1,0.5-1.3,0.9c-0.4,0.4-0.7,0.8-0.9,1.3c-0.2,0.5-0.3,1-0.3,1.6c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.2,0.5h4.9 c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.1-1.1-0.3-1.6C18.9,19.8,18.6,19.3,18.2,18.9z M13.8,16.1 c-0.1-0.3-0.2-0.6-0.1-1c0.1-0.3,0.2-0.6,0.5-0.8c0.2-0.2,0.5-0.4,0.8-0.5c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.6,0.1 c0.3,0.1,0.6,0.3,0.7,0.6c0.2,0.3,0.3,0.6,0.3,0.9c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.2-0.3,0.3-0.5,0.4 c-0.5,0.2-1.1,0.2-1.5-0.2C14.1,16.6,13.9,16.4,13.8,16.1z M18.2,22.3c-0.1,0.1-0.3,0.2-0.5,0.2h-4.9c-0.2,0-0.4-0.1-0.5-0.2 c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.4,0.1-0.8,0.2-1.2c0.2-0.4,0.4-0.7,0.7-1c0.3-0.3,0.6-0.5,1-0.7c0.4-0.2,0.8-0.2,1.2-0.2 c0.4,0,0.8,0.1,1.2,0.2c0.4,0.2,0.7,0.4,1,0.7c0.3,0.3,0.5,0.6,0.7,1c0.2,0.4,0.2,0.8,0.2,1.2C18.4,22,18.3,22.2,18.2,22.3z"
        fill={color}
      />
      <path
        d="M15.3,10.1c-1.1,0-2.2,0.2-3.2,0.7c-1-0.6-2-0.9-3.2-1c1-0.1,2-0.6,2.7-1.3c0.9-0.9,1.4-2,1.4-3.3c0-0.9-0.3-1.8-0.8-2.6 c-0.5-0.8-1.2-1.4-2.1-1.7C9.3,0.6,8.3,0.5,7.4,0.7C6.6,0.9,5.7,1.3,5.1,2C4.4,2.6,4,3.4,3.8,4.3C3.6,5.2,3.7,6.1,4.1,7 C4.4,7.8,5,8.5,5.8,9.1c0.6,0.4,1.3,0.7,2,0.7c-1.1,0.1-2.1,0.4-3,0.9c-1.2,0.6-2.1,1.6-2.8,2.7c-0.7,1.1-1,2.4-1,3.8 c0,0.8,0.3,1.5,0.8,2c0.5,0.5,1.3,0.8,2,0.8h3.3c0.7,3.8,4.1,6.7,8.2,6.7c4.6,0,8.3-3.7,8.3-8.3C23.6,13.9,19.9,10.1,15.3,10.1z M6.5,8.1c-0.6-0.4-1-0.9-1.3-1.5C4.9,5.9,4.9,5.2,5,4.5c0.1-0.7,0.5-1.3,0.9-1.7C6.4,2.3,7,2,7.7,1.9c0.2,0,0.4-0.1,0.7-0.1 c0.4,0,0.9,0.1,1.3,0.3c0.6,0.3,1.2,0.7,1.5,1.3c0.4,0.6,0.6,1.2,0.6,1.9c0,0.9-0.4,1.8-1,2.4c-0.6,0.6-1.5,1-2.4,1 C7.7,8.7,7,8.4,6.5,8.1z M3.8,18.9c-0.4,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2C2.1,16.1,2.4,15,3,14c0.6-1,1.4-1.7,2.4-2.3 c0.9-0.5,1.9-0.8,3-0.8c0.1,0,0.1,0,0.2,0c0.8,0,1.5,0.2,2.2,0.5c-2.3,1.5-3.8,4.1-3.8,7c0,0.1,0,0.3,0,0.4H3.8z M15.3,25.6 c-3.5,0-6.3-2.5-7-5.7l0.2-0.3l0-0.2c0-0.3-0.1-0.6-0.1-0.9c0-1.3,0.4-2.6,1.1-3.8c0.7-1.1,1.6-2,2.7-2.6c1-0.5,2-0.7,3.1-0.7 c3.9,0,7.1,3.2,7.1,7.1S19.2,25.6,15.3,25.6z"
        fill={color}
      />
    </svg>
  )
}
