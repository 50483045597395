import cn from "classnames";
import React, { useMemo } from "react";

import FileViewDoc from "./FileViewDoc/FileViewDoc";
import FileViewImage from "./FileViewImage/FileViewImage";
import FileViewOther from "./FileViewOther/FileViewOther";
import FileViewPdf from "./FileViewPdf/FileViewPdf";
import { FileViewerIcons } from "./assets/icons";

import { IFile } from "types/interfaces/Files";

import { serializeFilesByName } from "utils/formatters/serializeFilesByName";
import { getFileExtension } from "utils/getFileExtension";
import downloadFile from "utils/helpers/download";

import styles from "./FileViewer.module.scss";

const getIcon = (ext: string) => {
  if (ext.toLowerCase() === "pdf") {
    return FileViewerIcons.PDF;
  }
  if (ext.toLowerCase() === "doc" || ext.toLowerCase() === "docx") {
    return FileViewerIcons.DOC;
  }
  if (ext.toLowerCase() === "jpg" || ext.toLowerCase() === "jpeg") {
    return FileViewerIcons.JPG;
  }
  if (["png", "svg", "gif", "webp"].includes(ext.toLowerCase())) {
    return FileViewerIcons.Image;
  }
  return FileViewerIcons.AnyFile;
};

const switchFileRenderer = (file: IFile, ext: string) => {
  if (["png", "svg", "gif", "webp", "jpg", "jpeg"].includes(ext.toLowerCase())) {
    return <FileViewImage file={file} />;
  }
  if (["pdf"].includes(ext.toLowerCase())) {
    return <FileViewPdf file={file} />;
  }
  return <FileViewOther file={file} />;
};

interface IProps {
  file: IFile;
  isFirst: boolean;
  isLast: boolean;
  onClose: () => void;
  isHidden: boolean;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

const FileViewerItem: React.FC<IProps> = ({ onClose, isFirst, isLast, file, isHidden, setIndex }) => {
  const serializedFile = useMemo(() => {
    return serializeFilesByName(file);
  }, [file]);

  const extension = getFileExtension(serializedFile!.name);

  if (isHidden) return null;

  return (
    <>
      <div className={styles.head}>
        <div className={styles.back} onClick={onClose}>
          {FileViewerIcons.Back}
        </div>
        {getIcon(extension)}
        <span className={styles.name} title={file.name}>
          {serializedFile?.name}
        </span>
        <button className={styles.btn} onClick={() => downloadFile(serializedFile?.link!, serializedFile?.name!)}>
          {FileViewerIcons.Download}Скачать файл
        </button>
      </div>
      {!isFirst && (
        <div className={cn(styles.nav)} onClick={() => setIndex((prev) => prev - 1)}>
          {FileViewerIcons.Nav}
        </div>
      )}
      {!isLast && (
        <div className={cn(styles.nav, styles.next)} onClick={() => setIndex((prev) => prev + 1)}>
          {FileViewerIcons.Nav}
        </div>
      )}
      {switchFileRenderer(serializedFile!, extension)}
    </>
  );
};

export default FileViewerItem;
