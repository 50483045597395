import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useQueryParams } from "utils/hooks/useQueryParams";

export const useFoldersNavigation = (paramName: string) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const history = useHistory();
  const location = useLocation();
  const folderIdsFromParams =
    useQueryParams(paramName)
      ?.split("_")
      .filter((el, index) => !!el && index <= 1)
      .map((el) => +el) || [];

  const [foldersIdsArray, setFoldersIdsArray] = useState<number[]>(folderIdsFromParams);
  const cachedObjectId = useRef<string>(objectId);

  useEffect(() => {
    if (objectId === cachedObjectId.current) return;
    setFoldersIdsArray([]);
    cachedObjectId.current = "None";
  }, [objectId]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set(paramName, foldersIdsArray.join("_"));
    history.push({ search: params.toString() });
  }, [foldersIdsArray, location.search]);

  const backHandler = useCallback(() => {
    history.push({ search: "" });

    setFoldersIdsArray((prev) => {
      const tempArr = [...prev];
      tempArr.pop();
      return tempArr;
    });
  }, [setFoldersIdsArray]);

  const openFolderHandler = useCallback(
    (folderId: number) => {
      setFoldersIdsArray((prev) => [...prev, folderId]);
    },
    [setFoldersIdsArray]
  );

  const currentFolderId = foldersIdsArray[foldersIdsArray.length - 1];

  const foldersDepth = foldersIdsArray.length;

  return {
    backHandler,
    openFolderHandler,
    currentFolderId,
    foldersDepth,
  };
};
