import axios from "axios";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

export function getTimesheetApprovalRequest(options, config) {
  return axios
    .get(`/building/${options.buildingId}/timesheet_approval/${options.year}/${options.month}/`, config)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function postTimesheetApprovalRequest(options, payload) {
  return axios
    .post(`/building/${options.buildingId}/timesheet_approval/${options.year}/${options.month}/`, payload)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export const getLastRequisitionDraftRequest = (buildingId) =>
  axios.get(`/building/${buildingId}/requisition/last-draft/`);
