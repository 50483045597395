import React from "react";

import { LOCALIZATION_CONFIG } from "constants/localization";

export const splitThousands = (num, isCurrencySign = true, span = true, fixedLength = 2, isFloatingDecimals) => {
  const value = parseFloat(num || 0);
  let decimalLength = fixedLength;

  if (isFloatingDecimals) {
    if ((value % 1).toFixed(2) > 0) {
      decimalLength = 2;
    } else {
      decimalLength = 0;
    }
  }

  return span ? (
    <span
      style={{
        whiteSpace: "nowrap",
      }}
    >
      {value
        .toFixed(decimalLength)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
      {isCurrencySign && ` ${LOCALIZATION_CONFIG.currency}`}
    </span>
  ) : (
    value
      .toFixed(decimalLength)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ` ${isCurrencySign ? LOCALIZATION_CONFIG.currency : ""}`.trim()
  );
};
