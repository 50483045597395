import axios from "axios";
import { compose } from "redux";

import { setPackingItemFilesAction, setPackingItemsFilesLoadingAction } from "../actions";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

export const getPackingItemFiles = (packingItemId) => (dispatch) =>
  axios
    .get(`/purchaser/packinglists/packingitems/${packingItemId}/files/`)
    .then((response) =>
      compose(dispatch, setPackingItemFilesAction)({ itemId: packingItemId, files: response.data.results })
    )
    .catch(errorCatcher);
