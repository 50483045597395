import { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import NewPlanFactVolumeExpenditures from "../NewPlanFactVolumeExpenditures/NewPlanFactVolumeExpenditures";
import { planFactVolumeIsAllExpandedSelector } from "../model/selectors";
import ExpandTrigger from "shared/ui/atoms/ExpandTrigger/ExpandTrigger";
import { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import styles from "./NewPlanFactVolumeSection.module.scss";

interface IProps {
  name: string;
  parentName: string;
  subsectionId: string;
  date: Moment;
  daysCount: number;
  isPreLoaded?: boolean;
}

const NewPlanFactVolumeSection: React.FC<IProps> = ({
  name,
  parentName,
  subsectionId,
  date,
  daysCount,
  isPreLoaded,
}) => {
  const isAllExpanded = useSelector(planFactVolumeIsAllExpandedSelector);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(isAllExpanded);
  }, [isAllExpanded]);

  return (
    <div>
      <div className={styles.parentRow}>
        <TableReusableCell isFont13={false} isNoBreak title={`${parentName} / ${name}`}>
          <span className={styles.parentName}>{parentName} </span>/ {name}
        </TableReusableCell>
        <div className={styles.right}>
          <ExpandTrigger
            expandedText="Свернуть раздел"
            collapsedText="Развернуть раздел"
            onExpand={() => setIsExpanded((prev) => !prev)}
            isExpanded={isExpanded}
          />
        </div>
      </div>
      {isExpanded && (
        <NewPlanFactVolumeExpenditures
          subsectionId={subsectionId}
          date={date}
          daysCount={daysCount}
          isPreLoaded={isPreLoaded}
        />
      )}
    </div>
  );
};

export default NewPlanFactVolumeSection;
