import update from "immutability-helper";

const setSuppliesPrice = (supplies, price) =>
  supplies.map((supply) =>
    update(supply, {
      price: { $set: price },
    })
  );

export const syncOfferSuppliesPrice = (offers) =>
  offers.map((offer) =>
    update(offer, {
      supplies: { $set: setSuppliesPrice(offer.supplies, offer.price) },
    })
  );
