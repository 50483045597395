import React, { FC } from "react";

import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";

import styles from "./TableHeader.module.scss";

type OnFiltersChange = (name: string, value: string) => void;

function IncomeHeader(props: { onFiltersChange: OnFiltersChange; withFilters: true }): ReturnType<FC>;

function IncomeHeader(props: { withFilters?: false }): ReturnType<FC>;

function IncomeHeader(
  props: { onFiltersChange: OnFiltersChange; withFilters: true } | { withFilters?: false }
): ReturnType<FC> {
  return (
    <TableReusableHead isSticky isWithEar className={styles.incomeHeader}>
      <TableReusableHeaderCell
        onChangeFilter={props.withFilters ? props.onFiltersChange : undefined}
        filterField="id"
        filterTitle="№"
        filterType={props.withFilters ? "text" : undefined}
      >
        №
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        onChangeFilter={props.withFilters ? props.onFiltersChange : undefined}
        filterField="sendeduser"
        filterTitle="Передал"
        filterType={props.withFilters ? "text" : undefined}
      >
        Передал
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        onChangeFilter={props.withFilters ? props.onFiltersChange : undefined}
        filterField="confirmeduser"
        filterTitle="Категория"
        filterType={props.withFilters ? "text" : undefined}
      >
        Принял
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>Тип</TableReusableHeaderCell>
      <TableReusableHeaderCell>Статус</TableReusableHeaderCell>
      <TableReusableHeaderCell isCentered>Дата</TableReusableHeaderCell>
      <TableReusableHeaderCell isCentered>Файл</TableReusableHeaderCell>
      <div />
    </TableReusableHead>
  );
}

export default React.memo(IncomeHeader);
