import {
  VIEW_CONSTRUCTING_CHART_PLANS_DELETE,
  VIEW_CONSTRUCTING_PLANS_DELETE,
} from "constants/permissions/constructingPermissions";
import {
  VIEW_MANUFACTURING_CHART_PLANS_DELETE,
  VIEW_MANUFACTURING_PLANS_DELETE,
} from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";
import { useLocationArray } from "utils/hooks/useLocationArray";
import { useUrlModule } from "utils/hooks/useUrlModule";

export const useCanDeletePlan = () => {
  const mainRoute = useUrlModule();
  const locationArray = useLocationArray();
  const constructingChartPerm = usePermission(VIEW_CONSTRUCTING_CHART_PLANS_DELETE);
  const constructingPerm = usePermission(VIEW_CONSTRUCTING_PLANS_DELETE);
  const manufacturingChartPerm = usePermission(VIEW_MANUFACTURING_CHART_PLANS_DELETE);
  const manufacturingPerm = usePermission(VIEW_MANUFACTURING_PLANS_DELETE);

  if (mainRoute === "constructing") {
    if (locationArray.includes("manufacturing")) {
      return constructingChartPerm;
    }

    if (locationArray.includes("projects")) {
      return constructingPerm;
    }
  }

  if (mainRoute === "objects") {
    if (locationArray.includes("manufacturing")) {
      return manufacturingChartPerm;
    }

    if (locationArray.includes("estimate")) {
      return manufacturingPerm;
    }
  }

  return false;
};
