export enum OBJECTS_DEFAULT_PAGES_ENUM {
  SCHEDULE = "manufacturing/",
  PROJECT = "estimate",
  FACT_WORK = "journal",
  EMPLOYEES = "workers/turnout",
  REQUISITIONS = "requisitions/in-work",
  STOCK = "stocks/receipt",
  MATERIALS = "materials",
  PLAN_FACT = "plan-fact",
}

export enum CONSTRUCTING_DEFAULT_PAGES_ENUM {
  SCHEDULE = "manufacturing/",
  PROJECTS = "projects",
  FINANCE = "finance/0/audit",
  COUNTERPARTIES = "counterparties/contacts",
  DOCUMENTS = "documents/bills",
}
