import React from "react";

import { FileViewerIcons } from "../assets/icons";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { IFile } from "types/interfaces/Files";

import downloadFile from "utils/helpers/download";

import styles from "./FileViewOther.module.scss";

interface IProps {
  file: IFile;
}

const FileViewOther: React.FC<IProps> = ({ file }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.title}>Предварительный просмотр файла недоступен</div>
      <ButtonBase primary onClick={() => downloadFile(file.link, file.name)}>
        Скачать файл{FileViewerIcons.DownloadAll}
      </ButtonBase>
    </div>
  );
};

export default FileViewOther;
