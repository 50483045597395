import Axios from "axios";
import cn from "classnames";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";

import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import MaterialBlockInWork from "../components/MaterialsInWork/MaterialBlockInWork";
import NavBar from "../components/NavBar/NavBar";
import WorkListItem from "../components/WorkListItem/WorkListItem";
import WorkersList from "../components/WorkersList/WorkersList";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import ExpandableFileInput from "../../../../../shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";
import Expandable from "../../../atoms/_TODO/Expandable/Expandable";
import JournalDay from "../JournalTicketModal/JournalDay";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { dropNonSignificantZeros } from "../../../../../utils/formatters/dropNonSignificantZeros";
import getShortFullName from "utils/formatters/getShortFullName";
import { splitThousands } from "utils/formatters/splitThousands";
import downloadFileByUrl from "utils/helpers/downloadFileByUrl";
import { errorCatcher } from "utils/helpers/errorCatcher";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./JournalDoneModal.module.scss";

interface IProps {
  data: IExpendituresInJournalFulfillment["items"][0];
}

function JournalDoneWorks({ data }: IProps) {
  const objectId = useObjectId();
  const [intervalId, setIntervalId] = React.useState<number | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [list, setList] = React.useState<any[] | null>(null);
  const ticketItem = data.expenditure;

  React.useEffect(() => {
    if (ticketItem?.expenditure_id) {
      setIsLoading(true);
      Axios.get(`/building/${objectId}/tickets/history/${ticketItem?.expenditure_id}/approve_works/`)
        .then((resp) => setList(resp.data.results))
        .finally(() => setIsLoading(false));
    }
  }, [data]);

  const [itemData, setItemData] = React.useState<any>(null);
  const intervalClickHandler = (id: number, name: string, date: string) => {
    setIsLoading(true);
    setIntervalId(id as any);
    setAccepterName(name as any);
    setAcceptedDate(date as any);
    Axios.get(`/building/${objectId}/estimate/items/${ticketItem?.expenditure_id}/fact-intervals/${id}/`)
      .then((resp) => {
        setItemData(resp.data);
      })
      .catch(errorCatcher)
      .finally(() => setIsLoading(false));
  };

  const [accepterName, setAccepterName] = React.useState<string | null>(null);
  const [acceptedDate, setAcceptedDate] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (list && (list as any).length === 1) {
      setIsLoading(true);
      setIntervalId(list[0].id);
      Axios.get(
        `/building/${objectId}/estimate/items/${ticketItem?.expenditure_id}/fact-intervals/${list[0].fact_interval?.id}/`
      )
        .then((resp) => {
          setItemData(resp.data);
          setAccepterName(
            getShortFullName(list[0].author?.last_name, list[0].author?.first_name, list[0].author?.middle_name)
          );
          setAcceptedDate(list[0].created_at);
        })
        .catch(errorCatcher)
        .finally(() => setIsLoading(false));
    }
  }, [list]);

  const backHandler = () => {
    setItemData(null);
    setIntervalId(null);
  };

  const fileUploadHandler = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    Axios.post(
      `/building/${objectId}/estimate/items/${ticketItem?.expenditure_id}/fact-intervals/${itemData?.id}/files/`,
      formData
    ).then((resp) => {
      Axios.get(
        `/building/${objectId}/estimate/items/${ticketItem?.expenditure_id}/fact-intervals/${itemData?.id}/files/`
      ).then((resp) => {
        setItemData((prev: any) => ({ ...prev, files: resp.data.results }));
      });
    });
  };

  const fileRemoveHandler = (fileId: number) => {
    Axios.delete(
      `/building/${objectId}/estimate/items/${ticketItem?.expenditure_id}/fact-intervals/${itemData?.id}/files/${fileId}`
    ).then((resp) => {
      Axios.get(
        `/building/${objectId}/estimate/items/${ticketItem?.expenditure_id}/fact-intervals/${itemData?.id}/files/`
      ).then((resp) => {
        setItemData((prev: any) => ({ ...prev, files: resp.data.results }));
      });
    });
  };

  const accepted = itemData?.accepted?.[0] || {};

  return (
    <>
      {isLoading && <Spinner />}
      {!intervalId && (
        <div>
          {Array.isArray(list) &&
            list.map((el) => (
              /* @ts-ignore */
              <WorkListItem
                key={el.id}
                name={el.fact_interval?.title || ticketItem?.name}
                measure={el.measure}
                passName={el.fact_interval?.author}
                acceptDate={el.created_at}
                acceptName={el.author}
                passDate={el.fact_interval?.created_at}
                passCount={dropNonSignificantZeros(el.fact_interval?.count)}
                acceptCount={dropNonSignificantZeros(el.count)}
                passAmount={dropNonSignificantZeros(el.fact_interval_amount)}
                acceptAmount={dropNonSignificantZeros(el.amount)}
                onClick={() =>
                  intervalClickHandler(
                    el.fact_interval?.id,
                    getShortFullName(el.author?.last_name, el.author?.first_name, el.author?.middle_name),
                    el.created_at
                  )
                }
              />
            ))}
        </div>
      )}
      {!intervalId && !isLoading && !list?.length ? <EmptyPlaceholder text="Нет сведений" img={jurnalIcon} /> : null}
      {intervalId && !isLoading && (
        <>
          <NavBar isExist={!!list?.length} onClick={() => setIntervalId(null)} />
          <h2 className={styles.title}>{itemData?.expenditure_name}</h2>
          <div className={styles.pair}>
            <JournalDay
              day={moment(itemData?.created_at).format("DD/MM/YYYY")}
              time={moment(itemData?.created_at).format("HH:mm")}
            />
            <div className={styles.pass}>
              <b>Сдал:</b>
              <span>
                {getShortFullName(
                  itemData?.author?.last_name,
                  itemData?.author?.first_name,
                  itemData?.author?.middle_name
                )}
              </span>
            </div>
          </div>
          <div className={styles.pair}>
            <JournalDay day={moment(acceptedDate).format("DD/MM/YYYY")} time={moment(acceptedDate).format("HH:mm")} />
            <div className={styles.pass}>
              <b>Принял:</b>
              <span>{accepterName}</span>
            </div>
          </div>
          <div className={styles.hr}></div>
          <div className={styles.pair}>
            <div className={styles.info}>
              <b>Количество (к сдаче):</b>
              <span>
                <>
                  {dropNonSignificantZeros(itemData?.count)} ({ticketItem?.measure})
                </>
              </span>
            </div>
            <div className={styles.info}>
              <b>Израсходовано:</b>
              <span>{splitThousands(itemData?.work_amount)}</span>
            </div>
          </div>
          <div className={styles.pair}>
            <div className={styles.info}>
              <b>Количество (принято):</b>
              <span>
                <>
                  {dropNonSignificantZeros(accepted?.count)} ({ticketItem?.measure})
                </>
              </span>
            </div>
            <div className={styles.info}>
              <b>Стоимость по проекту:</b>
              <span>{splitThousands(itemData?.work_with_material_amount)}</span>
            </div>
          </div>
          <div className={cn(styles.info, styles.mb)}>
            <b>Раздел:</b>
            <span>{itemData?.section_name}</span>
          </div>
          <WorkersList workers={itemData?.workers} />
          {itemData?.stock_using_materials?.length ? (
            <Expandable title={`Материалы: ${itemData?.stock_using_materials?.length}`}>
              {itemData?.stock_using_materials?.map((el: any) => (
                <MaterialBlockInWork
                  name={el.stock_using_material?.product_building?.name}
                  measure={el.stock_using_material?.product_building?.measure}
                  count={dropNonSignificantZeros(el.count) as string}
                  amount={dropNonSignificantZeros(el.amount) as number}
                  key={el.id}
                />
              ))}
            </Expandable>
          ) : null}
          <ExpandableFileInput
            files={itemData?.files || []}
            onRemoveFileDirectly={fileRemoveHandler}
            onUploadFileDirectly={fileUploadHandler}
            isFileViewer
          />
        </>
      )}
    </>
  );
}

export default JournalDoneWorks;
