import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import SendMultiplePlansForApproveModal from "./SendMultiplePlansForApproveModal/SendMultiplePlansForApproveModal";
import { multiplePlanApprovingsCountsSelector } from "./model/selectors";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { useLocationArray } from "utils/hooks/useLocationArray";

export interface SendMultiplePlansForApproveProps {
  noMargin?: boolean;
  children?: React.ReactNode;
  render?: (count: number) => React.ReactNode;
}

const SendMultiplePlansForApprove: React.FC<SendMultiplePlansForApproveProps> = ({ noMargin, children, render }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buildingId = useObjectId(2);
  const loactionArray = useLocationArray();

  const isHidden = useMemo(() => {
    const isNotCertainBuilding = !buildingId || +buildingId === 0;
    const isNotRightLocation = !loactionArray.includes("manufacturing") && !loactionArray.includes("plan");
    return isNotCertainBuilding || isNotRightLocation;
  }, [buildingId, loactionArray]);

  const plansForApproveCount = useSelector(multiplePlanApprovingsCountsSelector)[buildingId];

  if (isHidden) return null;

  return (
    <div style={noMargin ? undefined : { marginRight: "1rem", marginLeft: "auto" }} title="Согласование">
      <ButtonBase secondary disabled={!plansForApproveCount} onClick={() => setIsOpen(true)}>
        {render?.(plansForApproveCount)}
        {children}
      </ButtonBase>
      <SendMultiplePlansForApproveModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
};

export default SendMultiplePlansForApprove;
