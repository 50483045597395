import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import TasksModal from "components/pages/Tasks/components/TasksModal/TasksModal";
import { TASK_LISTS_TYPES } from "components/pages/Tasks/constants";
import { useTasksModal } from "components/pages/Tasks/hooks/useTasksModal";

import MenuItem from "../../../NavigationBar/MenuItem";
import { useTranslation } from "react-i18next";

import { useUrlModule } from "utils/hooks/useUrlModule";

import newTaskIcon from "images/icons/newTaskIcon.svg";

import styles from "./NewTaskButton.module.scss";

const NewTaskButton: React.FC = () => {
  const { t } = useTranslation();

  const moduleName = useUrlModule();

  const { objectId } = useParams<{ objectId: string }>();

  const { openAddModal, isOpen } = useTasksModal();

  const handleOpenTaskModal = useCallback(() => {
    openAddModal({
      placementType: +objectId !== 0 ? "byCertainBuilding" : "byAllBuildings",
      listType: TASK_LISTS_TYPES.ALL,
    });
  }, [objectId]);

  const isExists = ["objects", "purchases", "constructing"].includes(moduleName);

  return (
    <>
      <MenuItem /* @ts-ignore */
        isExists={isExists}
        title={t("Создать задачу")}
        link={undefined}
        icon={newTaskIcon}
        onClick={handleOpenTaskModal}
        isBottom
        isWithoutUnderline
        className={styles.menuItem}
        isTooltipHidden={isOpen}
      />
      {isExists && <TasksModal presetBuildingId={objectId ? +objectId : 0} />}
    </>
  );
};

export default React.memo(NewTaskButton);
