import React from 'react'

type propsType = {
    isExpanded: boolean
}

function ExpandIcon({
    isExpanded
}: propsType) {
    const stylesObj = React.useMemo(() => {
        return {
            transform: `rotate(${isExpanded ? 180 : 0}deg)`
        }
    }, [isExpanded])

    return (
        <svg style={stylesObj} width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.550903 0.539062L9.07239 9.06055L17.5939 0.539062" stroke="#707070" />
        </svg>
    )
}

export default React.memo(ExpandIcon)