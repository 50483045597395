import React, { useCallback } from "react";

import Checkbox from "../__TODO/Checkbox/Checkbox";

const CheckboxFieldComponent = ({ input, label, ...props }) => {
  const onChange = useCallback((e) => input.onChange(e.target.checked), [input.onChange]);
  return (
    <Checkbox checked={input.checked} onChange={onChange} {...props}>
      {label}
    </Checkbox>
  );
};

export default React.memo(CheckboxFieldComponent);
