import moment, { Moment } from "moment";
import React from "react";

import Calendar from "shared/ui/inputs/Calendar/Calendar";
import DoubleInput from "shared/ui/inputs/DoubleInput/DoubleInput";
import { Measure } from "shared/ui/inputs/DoubleInput/DoubleInput";
import InputBase, { VALUE_TYPES } from "shared/ui/inputs/InputBase";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import PlusFillCircleIcon from "images/icons/PlusFillCircleIcon";
import { ReactComponent as DeleteIcon } from "shared/assets/icons/IconClose-big.svg";

import styles from "./AdvancePaymentItem.module.scss";

interface IAdvancePaymentItemProps {
  index: number;
  deleteItem: (id: number) => void;
  handleChangeAdvancePaymentAmountItem: (value: string, id: number) => void;
  id: number;
  amount: string;
  percent: string;
  handleAddAdvancePaymentItem: () => void;
  date: Moment;
  handleChangeAdvancePaymentDateItem: (value: Moment, id: number) => void;
  handleChangeAdvancePaymentPercentItem: (value: string, id: number) => void;
}

const AdvancePaymentItem = ({
  index,
  deleteItem,
  id,
  amount,
  percent,
  handleChangeAdvancePaymentAmountItem,
  handleAddAdvancePaymentItem,
  date,
  handleChangeAdvancePaymentDateItem,
  handleChangeAdvancePaymentPercentItem,
}: IAdvancePaymentItemProps) => {
  const transformedAmount = transformDigitToFinancial(amount, {
    withFloat: true,
    dropZeros: true,
  });
  return (
    <div className={styles.advancePaymentItem}>
      <div className={styles.flex}>
        <span className={styles.title}>Выплата {index + 1}</span>
        <Calendar
          placeholder={moment().format("DD.MM.YYYY")}
          format={"DD.MM.YYYY"}
          value={moment(date, "DD.MM.YYYY")}
          setValue={(date) => handleChangeAdvancePaymentDateItem(date, id)}
          classNameSelect={styles.calendarInput}
          classNameOptions={styles.calendarInputOptions}
        />
        <DoubleInput
          firstValueMeasure={Measure.percent}
          firstInputSize={1}
          secondValueMeasure={Measure.currency}
          firstValue={percent} /* @ts-ignore */
          secondValue={transformedAmount}
          secondInputSize={2}
          onChangeFirstValue={(value) => handleChangeAdvancePaymentPercentItem(value, id)}
          onChangeSecondValue={(value) => {
            handleChangeAdvancePaymentAmountItem(value, id);
          }}
          variant={"outline"}
          className={styles.doubleInput}
        />
      </div>
      {index ? (
        <DeleteIcon onClick={() => deleteItem(id)} className={styles.deleteIcon} />
      ) : (
        <PlusFillCircleIcon onClick={handleAddAdvancePaymentItem} className={styles.addIcon} />
      )}
    </div>
  );
};

export default React.memo(AdvancePaymentItem);
