import React from "react"

export const useTableHover = () => {
    React.useEffect(() => {
        const table = document.querySelector(".ant-table-tbody")
        const mouseOverHandler = (e) => {
            const row = e.target.closest("tr")
            const listItem = document.querySelector(`div[data-row-id="${row?.dataset.rowKey}"]`)
            listItem?.classList?.add("hover")
        }
        const mouseOutHandler = (e) => {
            const row = e.target.closest("tr")
            const listItem = document.querySelector(`div[data-row-id="${row?.dataset.rowKey}"]`)
            listItem?.classList?.remove("hover")
        }
        table.addEventListener("mouseover", mouseOverHandler)
        table.addEventListener("mouseout", mouseOutHandler)
        return () => {
            table.removeEventListener("mouseover", mouseOverHandler)
            table.addEventListener("mouseout", mouseOutHandler)
        }
    }, [])
}
