export const beautifyNumberValue = (value: number | string, floatConstraint = 2) => {
  const stringValue = String(value);

  let result = "";

  let dotIndex = null;

  for (let i = 0; i < stringValue.length; i++) {
    if (dotIndex && i > dotIndex + floatConstraint) break;

    const symbol = stringValue[i];

    if (symbol === "-") {
      if (i === 0) {
        result += symbol;
        continue;
      } else {
        continue;
      }
    }

    if (symbol === "." || symbol === ",") {
      result += dotIndex || i === 0 || floatConstraint === 0 ? "" : ".";
      dotIndex = i;
      continue;
    }

    if (isNaN(+symbol) || symbol === " ") {
      result += "";
      continue;
    }

    result += symbol;
  }
  return result;
};
