export const DEFAULT_PROJECT_FIELDS = {
  address: "",
  responsible: "",
  customer: "",
  general_contractor: "",
  permit_build: "",
  date_start: "",
  date_end: "",
  developer: "",
  technical_supervision: "",
};
