import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import Select from "components/UI/atoms/Select";

import { helpOutOfEstimateExpendituresSelector, helpOutOfEstimateLoadingSelector } from "./model/selectors";
import { getOutOfEstimateHelpExpenditures } from "./model/thunks";
import InputAutocomplete from "shared/ui/inputs/InputAutocomplete/InputAutocomplete";

import { IHelpExpanditure } from "./model/types";
import { ExpenditureType } from "types/enums/ExpenditureTypeEnum";

import useDebounce from "utils/hooks/useDebouncedValue";

import { composeFieldValidators, maxLength, required } from "utils/formHelpers/validations";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./AutoCompleteOutOfEstimate.module.scss";

interface IProps {
  label?: string;
  name: "name" | "number";
  formName: string;
  onSelect: (products: IHelpExpanditure) => void;
  expType: ExpenditureType;
  formaValue: string;
}

const AutoCompleteOutOfEstimate: React.FC<IProps> = ({ label, name, onSelect, formName, expType, formaValue }) => {
  const dispatch = useDispatch();

  const [searchBarValue, setSearchBarValue] = useState("");
  const debouncedSearchBarValue = useDebounce(searchBarValue, 300);

  useEffect(() => {
    setSearchBarValue(formaValue);
  }, [formaValue]);

  const localKey = useRef("");
  const autoCompleteExpenditures = useSelector(helpOutOfEstimateExpendituresSelector)[localKey.current];
  const isLoading = useSelector(helpOutOfEstimateLoadingSelector)[localKey.current];

  const options = useMemo(() => {
    if (name === "name") {
      return autoCompleteExpenditures?.map((el) => ({ ...el, displayName: el.name }));
    } else {
      return autoCompleteExpenditures?.map((el) => ({ ...el, name: el.number ?? "-", displayName: el.name }));
    }
  }, [name, autoCompleteExpenditures]);

  useLayoutEffect(() => {
    localKey.current = `${name}_${Math.random()}_${Date.now()}`;
  }, [name]);

  useEffect(() => {
    if (!debouncedSearchBarValue) return;
    dispatch(
      getOutOfEstimateHelpExpenditures({
        expenditure_type: expType,
        [name]: debouncedSearchBarValue,
        key: localKey.current,
      })
    );
  }, [debouncedSearchBarValue, expType]);

  const handleSearchBarChange = (value: any) => {
    setSearchBarValue(value);
    setIsDirty(true);
  };

  const [isDirty, setIsDirty] = useState(false);

  const isSearchDebouncing = searchBarValue !== debouncedSearchBarValue;

  return (
    <>
      <Field
        name={formName}
        validate={composeFieldValidators(required())}
        render={({ input, meta }) => (
          <>
            <InputAutocomplete
              label={label}
              displayingValue={isDirty ? searchBarValue : searchBarValue || input.value}
              isLoading={isLoading || isSearchDebouncing}
              onChangeSearchBar={(v) => {
                handleSearchBarChange(v);
                input.onChange(v);
              }}
              onSelectOption={(optionName: string, id: number) => {
                setSearchBarValue(optionName);
                const item = autoCompleteExpenditures?.find((el) => el.id == id);
                if (!item) return;
                input.onChange(item?.[name]);
                onSelect?.(item);
              }}
              options={options || []}
              error={meta.touched && meta.error}
              optionClassName={styles.option}
              hideOptions={!autoCompleteExpenditures && expType !== "work"}
            />
          </>
        )}
      />
    </>
  );
};

export default AutoCompleteOutOfEstimate;
