const ADD_FILES = 'ADD_FILES'
const SET_FILES = 'SET_FILES'
const DELETE_FILES = 'DELETE_FILES'

const initialState = {
    files: {}
}

export const stockFilesReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_FILES:
            return {...state, files: {...state.files, [action.payload.stockProductId]: [...state.files[action.payload.stockProductId], action.payload.file]}}
        case SET_FILES:
            return {...state, files: {...state.files, [action.payload.stockProductId]: action.payload.files}}
        case DELETE_FILES:
            return {...state, files: {...state.files, [action.payload.stockProductId]: state.files[action.payload.stockProductId].filter((file) => file.id !== action.payload.fileId)}}
        default:
            return state
    }
}

export const addFilesAction = (payload) => ({type: ADD_FILES, payload})
export const setFilesAction = (payload) => ({type: SET_FILES, payload})
export const deleteFileAction = (payload) => ({type: DELETE_FILES, payload})