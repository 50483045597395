import React from 'react';
import {ISharingTicketProps} from './TicketSharedCircle'

const TicketDeniedTriangle: React.FC<ISharingTicketProps> = ({backgroundColor = '#E8527A', signColor = 'white', className}) => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M6.49926 0.856406C6.65396 0.595462 6.87392 0.379279 7.1375 0.229121C7.40108 0.0789624 7.69921 0 8.00256 0C8.30592 0 8.60405 0.0789624 8.86763 0.229121C9.13121 0.379279 9.35117 0.595462 9.50586 0.856406L15.7547 11.4015C15.9123 11.6661 15.9969 11.9678 15.9999 12.2758C16.0029 12.5838 15.9242 12.8871 15.7717 13.1547C15.6193 13.4224 15.3985 13.6448 15.1321 13.7993C14.8656 13.9538 14.563 14.0348 14.255 14.0342H1.74812C1.43985 14.0354 1.1368 13.9547 0.869911 13.8004C0.603024 13.6462 0.381874 13.4238 0.229053 13.1561C0.0762306 12.8883 -0.00278586 12.5849 7.50145e-05 12.2766C0.00293589 11.9683 0.0875716 11.6664 0.245336 11.4015L6.49926 0.856406Z" fill={backgroundColor}/>
      <path d="M8.40669 3.55978H7.4202V9.38039H8.40669V3.55978Z" fill={signColor}/>
      <path d="M8.40669 10.9303H7.4202V12.094H8.40669V10.9303Z" fill={signColor}/>
    </svg>
  )
}

export default TicketDeniedTriangle;