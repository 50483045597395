import { message } from "antd";
import cn from "classnames";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useParams, useRouteMatch } from "react-router-dom";
import { compose } from "redux";

import {
  isObjectDetailLoadingSelector,
  objectDetailSelector,
} from "../../../../redux/modules/common/building/object/nowObject";
import {
  addOutOfEstimateSection,
  addSection,
  changeEstimateItemsState,
  expendituresSelector,
  loadExpenditures,
  loadSection,
  sectionSelector,
  sectionsSelector,
} from "../../../../redux/modules/common/building/sections/sections";

import Select from "../../../../components/UI/atoms/Select";
import Amounts from "./components/Amounts/Amounts";
import Body from "./components/Body/Body";
import ChangeEstimateItemsState from "./components/ChangeEstimasteItemsState/ChangeEstimateItemsState";
import CreateSection from "./components/CreateSection/CreateSection";
import ImportDocumentation from "./components/ImportExtraordinaryDocumentation/ImportDocumentation";
import VisibilityModal from "./components/VisibilityModal/VisibilityModal";
import ExcelProductsUpload from "_LEGACY/UI/ExcelProductsUpload/ExcelProductsUpload";
import TemplateSimple from "_LEGACY/UI/_LEGACY_TemplateSimple/TemplateSimple";

import ProEstimateStatesIndicators from "../Aggregations/ProEstimateStatesIndicators/ProEstimateStatesIndicators";
import {
  aggregationLoadingSelector,
  aggregationSelector,
  aggregationsInvalidateKeySelector,
} from "../Aggregations/model/selectors";
import { loadAggregations } from "../Aggregations/model/thunks";
import { handlerIndexesInvalidateKeySelector } from "./widgets/HandlerIndexes/model/selectors";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";

import { IHandlerPageProps } from "../../types/HandlerProps";
import { IHandlerMatchParams } from "../../types/MatchParams";
import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS, ESTIMATE_STATES_NAMES_FOR_FROM } from "./constants";
import { EEstimateStatesIds } from "./enums";

import useArrayItemsChecker from "../../../../hooks/useArrayItemsChecker";
import { useCheckUploadStatus } from "./hooks/useCheckUploadStatus";
import useEstimateState from "./hooks/useEstimateState";
import useGetSections from "./hooks/useGetSections";
import usePricingMode, { PRICING_MODES_OPTIONS } from "./hooks/usePricingMode";
import { useQueryParams } from "utils/hooks/useQueryParams";

import styles from "./ProHandler.module.scss";

const EMPTY_ARRAY = [];

const ProHandler = ({ match, permissions }) => {
  const { buildingId, projectId } = useParams();
  const state = useQueryParams("state")?.split("?")[0];
  const sectionIdMatch = useRouteMatch(`${match.path}/:sectionId`);
  const subsectionIdMatch = useRouteMatch(`${match.path}/:sectionId/:subsectionId`);

  const subsectionId = subsectionIdMatch && subsectionIdMatch.params.subsectionId;
  const sectionId = sectionIdMatch && sectionIdMatch.params.sectionId;
  const isSubsections = !!sectionId;

  const dispatch = useDispatch();
  const building = useSelector(objectDetailSelector);
  const buildingIsLoading = useSelector(isObjectDetailLoadingSelector);
  const aggregations = useSelector(aggregationSelector);
  const aggregationsAreLoading = useSelector(aggregationLoadingSelector);
  const activeSection = useSelector(sectionSelector);
  const expenditures = useSelector(expendituresSelector);
  const sections = useSelector(sectionsSelector);
  const aggregationsInvalidateKey = useSelector(aggregationsInvalidateKeySelector);
  const indexesInvalidateKey = useSelector(handlerIndexesInvalidateKeySelector);

  const isBuildingShared = building && building.is_shared;

  const [checkedList, setCheckedList] = useState([]);

  useEffect(() => {
    setCheckedList([]);
  }, [subsectionId, sectionId]);

  const handleVisibilityCheck = useCallback(
    (item) => {
      if (!checkedList.filter((el) => el.id === item.id).length) {
        setCheckedList((prev) => [...prev, { id: item.id, status: item.hidden_status || (item.hidden ? 1 : 0) }]);
      } else {
        setCheckedList((prev) => prev.filter((el) => el.id !== item.id));
      }
    },
    [checkedList]
  );

  const visibilityChecked = useCallback(
    (id) => {
      return !!checkedList.filter((el) => el.id === id).length;
    },
    [checkedList]
  );

  const isHideProductionInfo =
    isBuildingShared && !["accepted", "verification", "sent"].includes(building?.shared_status);

  const { activeEstimateStateId, setActiveEstimateStateId, availableEstimateStates, estimateStatesMenu } =
    useEstimateState(match.url, permissions, isBuildingShared, isHideProductionInfo);

  const { activePricingMode, setActivePricingMode } = usePricingMode({ activeEstimateStateId });

  const isDraft = activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT;
  const isLocale = activeEstimateStateId === ESTIMATE_STATES_IDS.LOCALE;
  const isProduction = activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION;

  const [consolidateStateChapter, setConsolidateStateChapter] = useState(null);
  const resetConsolidateChapter = useCallback(() => setConsolidateStateChapter(null), []);

  const chapterIsSelectedInConsolidateState =
    activeEstimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE && consolidateStateChapter;

  const statusFromQueryParams = new URL(window.location.href).searchParams.get("status");

  const {
    areLoading: sectionsAreLoading,
    refreshSections,
    allSections,
  } = useGetSections({
    buildingId,
    sectionId,
    subsectionId,
    estimateStateId: activeEstimateStateId,
    status: statusFromQueryParams,
    chapterId: consolidateStateChapter,
  });

  const [areIndicatorsOpen, setAreIndicatorsOpen] = useState(true);

  const estimateItems = useMemo(
    () => (expenditures ? [...allSections, ...expenditures.results] : EMPTY_ARRAY),
    [allSections, expenditures]
  );

  const estimateItemsChecker = useArrayItemsChecker(estimateItems, "id");

  const isDraftWithoutSections = useMemo(() => {
    if (!aggregations) return false;
    return isDraft && +aggregations.drafter_count === 0;
  }, [aggregations, isDraft]);

  const getAggregations = useCallback(() => {
    dispatch(loadAggregations(buildingId));
  }, [buildingId]);

  const handleLoadExpenditures = useCallback(
    () =>
      compose(dispatch, loadExpenditures)(
        { building: buildingId, section: subsectionId, estimateState: activeEstimateStateId },
        statusFromQueryParams
      ),
    [dispatch, buildingId, subsectionId, activeEstimateStateId, statusFromQueryParams]
  );

  const handleChangeEstimateItemsState = useCallback(
    async (targetEstimateStateId, changedEstimateItemsIds, chapter) => {
      const payload = {
        fromState: activeEstimateStateId,
        state: targetEstimateStateId,
        ids: changedEstimateItemsIds,
      };
      if (chapter) payload.chapter = chapter;

      compose(dispatch, changeEstimateItemsState)(buildingId, payload)
        .then((response) => {
          if (response.data.count_updated === 0) {
            const activeEstimateStateName = ESTIMATE_STATES_NAMES_FOR_FROM[activeEstimateStateId.toUpperCase()];
            message.error(`Невозможно перенести выбранные объекты из ${activeEstimateStateName}`);
            return;
          }

          getAggregations();
          const section = availableEstimateStates?.find((estimateState) => estimateState.id === targetEstimateStateId);
          if (section) message.success(`Разделы успешно перенеслись в ${section.name.toLowerCase()}`);

          if (targetEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION) return;

          if (subsectionId) {
            handleLoadExpenditures();
          } else {
            refreshSections();
          }
        })
        .catch(() => message.error("Невозможно перенести выбранные объекты"));
    },
    [
      activeEstimateStateId,
      dispatch,
      buildingId,
      getAggregations,
      availableEstimateStates,
      subsectionId,
      handleLoadExpenditures,
      refreshSections,
    ]
  );

  const { checkUploadStatus, isUploading, beginUploading } = useCheckUploadStatus({
    buildingId,
    onComplete: () => {
      refreshSections();
      getAggregations();
    },
  });

  const handleIndicatorsOpening = useCallback(() => setAreIndicatorsOpen((prevState) => !prevState), []);

  const sectionsAreEmpty = sections?.length === 0;

  const onSubmitCreateSection = useCallback(
    (name) => compose(dispatch, addSection)(buildingId, { name, parent: isSubsections ? sectionId : null }),
    [buildingId, isSubsections, sectionId]
  );

  const onSubmitCreateOutOfEstimateSection = useCallback(
    (name) =>
      compose(dispatch, addOutOfEstimateSection)(buildingId, {
        name,
        parent_id: isSubsections ? sectionId : null,
      }),
    [buildingId, isSubsections, sectionId]
  );

  useEffect(() => {
    if (!activeEstimateStateId) return;
    if (subsectionId) {
      compose(dispatch, loadSection)(buildingId, subsectionId, activeEstimateStateId);
      return;
    }
    if (sectionId) {
      compose(dispatch, loadSection)(buildingId, sectionId, activeEstimateStateId);
    }
  }, [sectionId, buildingId, subsectionId, activeEstimateStateId, aggregationsInvalidateKey, indexesInvalidateKey]);

  useEffect(() => {
    estimateItemsChecker.reset();
  }, [activeEstimateStateId, buildingId, subsectionId, sectionId]);

  const isShowChangeEstimateItems =
    !isDraftWithoutSections && !isProduction && statusFromQueryParams !== ESTIMATE_ITEM_STATUSES.NEW;

  const consolidateTitle =
    !!consolidateStateChapter && activeEstimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE ? "ССР" : "Объекты";

  if (buildingIsLoading || aggregationsAreLoading)
    return (
      <TemplateSimple dataTestId="page_handler">
        <Spinner />
      </TemplateSimple>
    );

  if (!building || !aggregations || !activeEstimateStateId || !availableEstimateStates) return null;

  return (
    <div className={cn(styles.handler, { [styles.withOpenIndicators]: areIndicatorsOpen })}>
      <header className={styles.header}>
        <ProEstimateStatesIndicators
          activeEstimateStateId={state || activeEstimateStateId}
          setActiveEstimateStateId={setActiveEstimateStateId}
          changeEstimateItemsState={handleChangeEstimateItemsState}
          isOpen={areIndicatorsOpen}
          handleOpening={handleIndicatorsOpening}
          isShared={!!isBuildingShared}
          isHideProductionIndicatorInfo={!!isHideProductionInfo}
          permissions={permissions}
        />
        <div className={styles.headerContent}>
          <div className={styles.headerBottomRow}>
            <BackNavigationBar
              title={isSubsections ? activeSection?.name : consolidateTitle}
              backLink={isSubsections ? null : `/constructing/projects/objects-list/${projectId}`}
              onBack={
                chapterIsSelectedInConsolidateState && !sectionId && !subsectionId ? resetConsolidateChapter : null
              }
            />
            {!isDraft && <Amounts isBuilding={!isSubsections} activeEstimateStateId={activeEstimateStateId} />}
          </div>

          <div className={styles.estimateState}>
            <Select
              className={styles.estimateStateSelect}
              onChange={setActiveEstimateStateId}
              value={activeEstimateStateId}
              options={availableEstimateStates}
              disabled={isDraftWithoutSections}
            />
            {isProduction && (
              <VisibilityModal
                refreshSections={refreshSections}
                checkedList={checkedList}
                buildingId={buildingId}
                setCheckedList={setCheckedList}
                handleLoadExpenditures={handleLoadExpenditures}
              />
            )}
            {isLocale && (
              <span className={styles.estimateStateDescription}>
                Вы можете перетащить разделы, подразделы и/ или записи в сводный сметный расчет или отправить позиции в
                производство
              </span>
            )}
            {isProduction && (
              <Select
                className={styles.estimateStateSelect}
                onChange={setActivePricingMode}
                value={activePricingMode}
                options={PRICING_MODES_OPTIONS}
              />
            )}
          </div>
          <Route exact path={[`${match.path}/:sectionId`, match.path]}>
            {!sectionsAreLoading && (isDraft || isProduction) && (
              <>
                <CreateSection
                  sectionsAreEmpty={sectionsAreEmpty}
                  isSubsections={isSubsections}
                  onSubmitCreateSection={isProduction ? onSubmitCreateOutOfEstimateSection : onSubmitCreateSection}
                  isProduction={isProduction}
                  isDraft={isDraft}
                />
                {isProduction && !isSubsections && <ImportDocumentation refreshSections={refreshSections} />}
              </>
            )}
          </Route>
          {!isSubsections && isDraft && (
            <div className={styles.uploadFile}>
              <ExcelProductsUpload
                className={styles.button}
                buttonText="Загрузить xml файл"
                api={`/building/${buildingId}/estimates/`}
                postCallback={checkUploadStatus}
                isUploading={isUploading}
                beginUploading={beginUploading}
                noMessage
              />
            </div>
          )}
        </div>
      </header>
      <div className={styles.body}>
        {isShowChangeEstimateItems && (
          <div className={styles.changeStateContainer}>
            <ChangeEstimateItemsState
              subsectionId={subsectionId}
              estimateStatesMenu={estimateStatesMenu}
              estimateItemsChecker={estimateItemsChecker}
              allSections={allSections}
              changeEstimateItemsState={handleChangeEstimateItemsState}
              isShared={isBuildingShared}
            />
          </div>
        )}
        <Body
          activeEstimateStateId={activeEstimateStateId}
          activePricingMode={activePricingMode}
          areIndicatorsOpen={areIndicatorsOpen}
          match={match}
          permissions={permissions}
          estimateItemsChecker={estimateItemsChecker}
          sectionsAreLoading={sectionsAreLoading}
          consolidateStateChapter={consolidateStateChapter}
          onSelectConsolidateStateChapter={setConsolidateStateChapter}
          handleVisibilityCheck={handleVisibilityCheck}
          visibilityChecked={visibilityChecked}
        />
      </div>
    </div>
  );
};

export default ProHandler;
