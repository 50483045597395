import { Dispatch } from "redux";

import { journalActions } from "../actions";
import { journalAcceptanceActions } from "./actions";
import { journalAcceptanceApi } from "./api";
import { RootState } from "app/store/rootReducer";

import { IEditAcceptanceTicketThunkProps } from "./types";

import { generateJornalTicketSectionKey } from "../utils";
import { isFulfilled } from "utils/checkers/isFulfilled";
import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getAcceptanceSections =
  (buildingId: string, year: number, month: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateJornalTicketSectionKey(buildingId, year, month);
    if (!getState().journalAcceptance.sections[key] /* || !getState().journalAcceptance.sharedContractors[key] */) {
      dispatch(journalAcceptanceActions.setSectionsIsLoading(key, true));
    }
    Promise.allSettled([
      journalAcceptanceApi.getSharedContractors(buildingId, year, month),
      journalAcceptanceApi.getSections(buildingId, year, month),
    ])
      .then(([contractorsResp, sectionsResp]) => {
        if (isFulfilled(contractorsResp)) {
          dispatch(journalAcceptanceActions.setSharedContractors(key, contractorsResp.value.data));
        }
        if (isFulfilled(sectionsResp)) {
          dispatch(journalAcceptanceActions.setSections(key, sectionsResp.value.data));
        }
      })
      .finally(() => {
        dispatch(journalAcceptanceActions.setSectionsIsLoading(key, false));
      });
  };

export const getJournalAcceptanceTickets =
  (buildingId: string, sectionId: number, year: number, month: number, filter: Record<string, any>) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateJornalTicketSectionKey(buildingId, year, month, sectionId);
    if (!getState().journalAcceptance.tickets[key]) {
      dispatch(journalAcceptanceActions.setTicketsIsLoading(key, true));
    }
    journalAcceptanceApi
      .getTickets(buildingId, sectionId, year, month, filter)
      .then(({ data }) => {
        dispatch(journalAcceptanceActions.setTickets(key, data));
      })
      .finally(() => {
        dispatch(journalAcceptanceActions.setTicketsIsLoading(key, false));
      });
  };

export const getAcceptanceApprovals =
  (buildingId: string, intervalId: number, type: "expenditure" | "group") => (dispatch: Dispatch) => {
    journalAcceptanceApi.getApprovals(buildingId, intervalId, type).then(({ data }) => {
      dispatch(journalAcceptanceActions.setApprovals(intervalId, data.results));
    });
  };

export const getDetailedAcceptanceTicket =
  (buildingId: string, intervalId: number, type: "expenditure" | "group") => (dispatch: Dispatch) => {
    journalAcceptanceApi.getDetailedTicket(buildingId, intervalId, type).then(({ data }) => {
      //dispatch(journalAcceptanceActions.setApprovals(intervalId, data.results));
    });
  };

export const editAcceptanceTicket =
  ({ isGroup, itemId, buildingId, data, callback }: IEditAcceptanceTicketThunkProps) =>
  async (dispatch: Dispatch) => {
    const path = isGroup ? "editGroup" : "editTicket";
    journalAcceptanceApi[path](buildingId, itemId, data)
      .then(() => {
        dispatch(journalActions.changeInvalidateKey());
        callback?.();
      })
      .catch(errorCatcher);
  };

export const getAcceptanceEditingHistory =
  (buildingId: string, intervalId: number, isGroup: boolean, type: "fact" | "plan" = "fact") =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ expenditureId: intervalId, type });
    if (!getState().journalAcceptance.history[key]) {
      dispatch(journalAcceptanceActions.setEditingHistoryLoading(key, true));
    }
    journalAcceptanceApi
      .getHistory(buildingId, intervalId, isGroup, type)
      .then(({ data }) => {
        dispatch(journalAcceptanceActions.setEditingHistory(key, data));
      })
      .finally(() => {
        dispatch(journalAcceptanceActions.setEditingHistoryLoading(key, false));
      });
  };

export const getDetailedTicketInJournalAcceptance =
  (buildingId: string, ticketId: number, type: "expenditure" | "group") =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ type, ticketId: String(ticketId) });
    if (!getState().journalAcceptance.detailedTickets[key]) {
      dispatch(journalAcceptanceActions.setTicketsIsLoading(key, true));
    }
    journalAcceptanceApi
      .getDetailedTicket(buildingId, ticketId, type)
      .then(({ data }) => {
        const detailedTicket = { ...data, approvals: [] };

        journalAcceptanceApi
          .getApprovals(buildingId, ticketId, type)
          .then(({ data }) => {
            detailedTicket.approvals = data.results;
          })
          .catch(errorCatcher)
          .finally(() => {
            dispatch(journalAcceptanceActions.setDetailedTicket(key, detailedTicket));
          });
      })
      .finally(() => {
        dispatch(journalAcceptanceActions.setTicketsIsLoading(key, false));
      });
  };
