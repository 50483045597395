import { STATES_ENUM, STATES_TYPES_ENUM } from "../../../types/enums";

export const findNotificationStateType = (state?: STATES_ENUM) => {
  switch (state) {
    case STATES_ENUM.LOCALE:
      return STATES_TYPES_ENUM.LOCALE;
    case STATES_ENUM.OBJECT:
      return STATES_TYPES_ENUM.OBJECT;
    case STATES_ENUM.CONSOLIDATE:
      return STATES_TYPES_ENUM.CONSOLIDATE;
    default:
      break;
  }
};
