import moment from "moment";

import { IAosrDetail, ICreateAosr, IPackingItemDocument } from "redux/modules/common/building/journal/aosr/types";
import { DocumentsTypesEnum, DocumentsTypesNamesEnum } from "redux/modules/common/passportsAndCertificates/types";

export const aosrDocsTypes = [
  { id: "Исполнительная схема", name: "Исполнительная схема" },
  { id: "Исполнительный чертеж", name: "Исполнительный чертеж" },
  { id: "Акт проведения испытаний", name: "Акт проведения испытаний" },
  { id: "Протокол лабораторных исследований", name: "Протокол лабораторных исследований" },
  { id: "Специальный журнал работ", name: "Специальный журнал работ" },
  { id: "Паспорт", name: "Паспорт" },
];

export const aookDocsTypesOptions = [
  { id: "executive_geodetic_scheme", name: "Исполнительная геодезическая схема" },
  { id: "expertise", name: "Экспертиза" },
  { id: "survey", name: "Обследование" },
  { id: "lab_test", name: "Лабораторное испытание" },
  { id: "testing", name: "Опробование" },
];

export const initialAosrForm: Partial<ICreateAosr> = {
  act_date: moment().format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
  start_date: moment().format("YYYY-MM-DD"),
  //@ts-ignore
  end_at: moment().format("YYYY-MM-DD"),
  //@ts-ignore
  start_at: moment().format("YYYY-MM-DD"),
};

export const isDocOutdated = (doc: IAosrDetail["materials"][0]["attachments"][0]) =>
  moment(doc.end_at).diff(moment(), "days") <= 30 || moment(doc.start_at).isAfter(moment());
export const getDocLabel = (doc: IAosrDetail["materials"][0]["attachments"][0]) => {
  return DocumentsTypesNamesEnum[doc.type];
};

export const initialAosrDoc = {
  doc_type: "",
  title: "",
  doc_date: "",
  files: [],
  isHidden: false,
};

export enum MATERIAL_SOURCES {
  AOOK = 'АООК',
  AOSR = 'АОСР'
}
