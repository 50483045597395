import cn from "classnames";
import moment from "moment";
import React, { PropsWithChildren, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";

import { becomeExecutor, updateExecutor } from "../../../../../redux/modules/common/building/requisition/reducer";

import StatusFormatter from "../../../../../_LEGACY/UI/_LEGACY_Purchases_components/StatusFormatter/StatusFormatter";
import AddEmployeeSlide from "../../../../../_LEGACY/UI/__trash/AddEmployeeSlide_1";
import Icon from "_LEGACY/UI/_LEGACY_Icon/Icon";
import OpeningInfo from "_LEGACY/UI/_LEGACY_Purchases_components/OpeningInfo";

import DownloadButton from "../../../../../shared/ui/controls/DownloadButton/DownloadButton";
import DynamicTitleInput, { SIZES } from "../../../../../shared/ui/inputs/DynamicTitleInput/DynamicTitleInput";
import Logs from "../Logs";
import ConfirmationModal from "entities/ConfirmationModal/ConfirmModal";
import ButtonBase from "shared/ui/controls/ButtonBase";
import Calendar from "shared/ui/inputs/Calendar/Calendar";
import Chat from "widgets/Chat/ChatV2";

import { REQUISITION_STATUS } from "../../../../../constants/constant";
import { chatRoomTypes } from "widgets/Chat/model/types";

import { useLocationArray } from "utils/hooks/useLocationArray";

import { getLocalizedDate } from "../../../../../utils/formatters/getLocalizedDate";
import { localizeServerDateInUserTimezone } from "../../../../../utils/localizeServerDateInUserTimezone";

import calendarBlue from "images/icons/calendarBlue.svg";

import styles from "./RequisitionInfo.module.scss";

//@ts-ignore
const RequisitionInfo = ({ requisition, changeExecutionDate, changeRequisitionName, saveExcel, permissions }) => {
  const isDraft = requisition.status === REQUISITION_STATUS.DRAFT;
  const dispatch = useDispatch();
  const locationArr = useLocationArray();

  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false);
  const [isOpenTakeToWorkModal, setIsOpenTakeToWorkModal] = useState(false);
  const [isOpenAddEmployerSlider, setIsOpenAddEmployerSlider] = useState(false);

  const toggleOpenLogModal = useCallback(() => setIsLogsModalOpen((prevState) => !prevState), []);

  const handleTakeToWork = useCallback(() => {
    compose(dispatch, becomeExecutor)();
    setIsOpenTakeToWorkModal(false);
  }, []);

  const handleAddEmployees = useCallback((addedEmployees: any) => {
    //@ts-ignore
    compose(dispatch, updateExecutor)(addedEmployees[0]?.id);
    setIsOpenAddEmployerSlider(false);
  }, []);

  const openAddEmployeesSlider = useCallback(() => setIsOpenAddEmployerSlider(true), []);
  const closeAddEmployerSlide = useCallback(() => setIsOpenAddEmployerSlider(false), []);

  const openTakeToWorkModal = useCallback(() => setIsOpenTakeToWorkModal(true), []);
  const closeTakeToWorkModal = useCallback(() => setIsOpenTakeToWorkModal(false), []);

  return (
    <div className={styles.container}>
      <DownloadButton className={styles.downloadButton} onDownload={saveExcel} />
      <DynamicTitleInput title={requisition.name} onChange={changeRequisitionName} size={SIZES.BIG} />
      <div className={styles.mainInfo}>
        <div className={styles.mainInfoBlock}>
          <div>Дата исполнения:</div>
          <Calendar
            icon={calendarBlue}
            classNameSelect={styles.calendar}
            value={moment(requisition.date)}
            setValue={changeExecutionDate}
            disabled={!isDraft}
          />
        </div>
        {!isDraft && (
          <div className={styles.mainInfoBlock}>
            <div className={styles.takenAtTitle}>в работе от:</div>
            <div className={styles.dateWrapper}>
              {requisition.taken_at ? (
                localizeServerDateInUserTimezone(requisition.taken_at, "DD.MM.YY HH:mm")
              ) : (
                <div className={styles.emptyBox} />
              )}
              <Icon icon={calendarBlue} className={styles.calendarIcon} />
            </div>
          </div>
        )}
      </div>
      <Container>
        <FirstRow>
          <div>
            <div className={styles.titleRow}>
              <div className={styles.title}>Статус:</div>
              <div className={styles.value}>{StatusFormatter(requisition.status_name, { flow: "in" })}</div>
            </div>
          </div>
          <div className={cn(styles.titleRow, styles.createInfo)}>
            <div className={styles.value}>
              {requisition.created_at && (
                <div className={styles.dateWrapper}>
                  <Icon icon={calendarBlue} className={styles.calendarIcon} />
                  {getLocalizedDate(requisition.created_at)}
                </div>
              )}
            </div>
            <OpeningInfo info={requisition.creator} title="Контакты заказчика" />
          </div>
        </FirstRow>
        {!isDraft && (
          <div className={styles.titleRow}>
            <div className={styles.title}>Исполнитель:</div>
            <div className={styles.value}>
              {!requisition.executor && (
                <div className={styles.buttons}>
                  {permissions.viewRequisitionTakeToWork && (
                    <ButtonBase primary medium onClick={openTakeToWorkModal}>
                      Взять в работу
                    </ButtonBase>
                  )}
                  {permissions.viewAssignRequisitionExecutor && (
                    <ButtonBase secondary medium onClick={openAddEmployeesSlider}>
                      Назначить
                    </ButtonBase>
                  )}
                </div>
              )}
              {!requisition.executor &&
                !permissions.viewRequisitionTakeToWork &&
                !permissions.viewAssignRequisitionExecutor &&
                "-"}
            </div>
            {requisition.executor && (
              <div className={styles.executor}>
                <OpeningInfo info={requisition.executor} title="Контакты исполнителя" />
                {permissions.viewRequisitionReassign && (
                  <ButtonBase className={styles.executorButton} onClick={openAddEmployeesSlider} secondary medium>
                    Передать
                  </ButtonBase>
                )}
              </div>
            )}
          </div>
        )}
      </Container>

      <div className={styles.rightSide}>
        {!isDraft && requisition.building.id && (
          <div className={styles.chatsBlock}>
            <Chat
              room={chatRoomTypes.REQUISITION}
              building_id={requisition.building.id}
              /* TODO wtf???? */
              item_id={+locationArr.at(-1)!}
              subTitle={requisition.status_name}
              isDragDisable
              isStatic
            />
          </div>
        )}
        {permissions.viewRequisitionLogs && (
          <ButtonBase secondary medium onClick={toggleOpenLogModal}>
            История действий
          </ButtonBase>
        )}
      </div>
      {permissions.viewRequisitionLogs && (
        <Logs
          isOpen={isLogsModalOpen}
          closeLogsBlock={() => setIsLogsModalOpen(false)}
          requisitionId={requisition.id}
        />
      )}
      {isOpenTakeToWorkModal && (
        <ConfirmationModal isOpen={isOpenTakeToWorkModal} action={handleTakeToWork} onClose={closeTakeToWorkModal}>
          <div style={{ fontSize: "1.125rem" }}>Взять заявку в работу?</div>
        </ConfirmationModal>
      )}
      {isOpenAddEmployerSlider && (
        <AddEmployeeSlide
          isOpen={isOpenAddEmployerSlider}
          closeAddPEmployeeBlock={closeAddEmployerSlide}
          addEmployees={handleAddEmployees}
          buttonText={requisition.executor ? "Передать" : "Назначить"}
          returnOneData
        />
      )}
    </div>
  );
};

export default React.memo(RequisitionInfo);

function Container({ children }: PropsWithChildren) {
  return <div className={styles.root}>{children}</div>;
}

function FirstRow({ children }: PropsWithChildren) {
  return <div className={styles.boxTitle}>{children}</div>;
}
