import React, { useEffect } from "react";
import {useDispatch} from 'react-redux'

import { getTableViewWidthPercent } from "./utils";

import { setTableViewWidthPercent } from "../../../../../../../redux/modules/common/ks6a/ks6a";

const useResize = (containerRef: React.RefObject<HTMLDivElement | null>) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleResize = () => {
      if (!containerRef.current) return;
      const tableViewWidthPercent = getTableViewWidthPercent(containerRef.current);
      dispatch(setTableViewWidthPercent(tableViewWidthPercent));
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
};

export default useResize;
