import { Dispatch } from "redux";

import { setManufacturingHash } from "redux/modules/common/building/manufacturing/thunks";

import { IDeletePlanReq, deletePlanApi } from "./deletePlanApi";
import { deletePlanActions } from "./deletePlanSlice";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const deletePlan = (props: IDeletePlanReq & { successCallback: () => void }) => (dispatch: Dispatch) => {
  const key = generateStorageKey({ buildingId: props.buildingId, expenditureId: props.planId });
  dispatch(deletePlanActions.setIsLoading({ key, status: true }));
  deletePlanApi
    .deletePlan(props)
    .then(() => {
      dispatch(deletePlanActions.invalidateKey());
      dispatch(setManufacturingHash(Math.random()) as any);
      props.successCallback?.();
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(deletePlanActions.setIsLoading({ key, status: false }));
    });
};
