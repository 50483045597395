import React from 'react'

function DotsIcon() {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 16.0015C6.37908 15.9988 4.34542 15.1555 2.8457 13.6558C1.34599 12.156 0.502646 10.1224 0.5 8.00146C0.50291 5.88063 1.34604 3.84878 2.8457 2.34912C4.34536 0.84946 6.37916 0.004375 8.5 0.00146484C10.6208 0.004375 12.6546 0.84946 14.1543 2.34912C15.654 3.84878 16.4971 5.88063 16.5 8.00146C16.4971 10.1223 15.654 12.1561 14.1543 13.6558C12.6546 15.1554 10.6208 15.9986 8.5 16.0015ZM12.5 6.50146C12.1022 6.50146 11.7208 6.65961 11.4395 6.94092C11.1581 7.22222 11 7.60364 11 8.00146C11 8.39929 11.1581 8.78071 11.4395 9.06201C11.7208 9.34332 12.1022 9.50146 12.5 9.50146C12.8978 9.50146 13.2792 9.34332 13.5605 9.06201C13.8419 8.78071 14 8.39929 14 8.00146C14.0003 7.80415 13.9621 7.6096 13.8867 7.42725C13.8113 7.2449 13.7001 7.07849 13.5605 6.93896C13.421 6.79944 13.2546 6.68818 13.0723 6.61279C12.8899 6.5374 12.6954 6.49925 12.498 6.49951L12.5 6.50146ZM8.5 6.50146C8.10218 6.50146 7.72076 6.65961 7.43945 6.94092C7.15815 7.22222 7 7.60364 7 8.00146C7 8.39929 7.15815 8.78071 7.43945 9.06201C7.72076 9.34332 8.10218 9.50146 8.5 9.50146C8.89782 9.50146 9.27924 9.34332 9.56055 9.06201C9.84185 8.78071 10 8.39929 10 8.00146C10.0003 7.80431 9.96198 7.60947 9.88672 7.42725C9.81145 7.24503 9.69986 7.08042 9.56055 6.94092C9.42123 6.80142 9.25634 6.69025 9.07422 6.61475C8.8921 6.53924 8.69715 6.49951 8.5 6.49951V6.50146ZM4.5 6.50146C4.10218 6.50146 3.72076 6.65961 3.43945 6.94092C3.15815 7.22222 3 7.60364 3 8.00146C3 8.39929 3.15815 8.78071 3.43945 9.06201C3.72076 9.34332 4.10218 9.50146 4.5 9.50146C4.89782 9.50146 5.27924 9.34332 5.56055 9.06201C5.84185 8.78071 6 8.39929 6 8.00146C6.00026 7.80415 5.96211 7.6096 5.88672 7.42725C5.81133 7.2449 5.70007 7.07849 5.56055 6.93896C5.42102 6.79944 5.25462 6.68818 5.07227 6.61279C4.88992 6.5374 4.69537 6.49925 4.49805 6.49951L4.5 6.50146Z" fill="white" />
        </svg>

    )
}

export default DotsIcon