import moment from "moment/moment";

function sortDatesAscending<T extends { date: string }>(a: T, b: T) {
  return moment(a.date).isBefore(b.date) ? -1 : 1;
}

export function formatDatesForChart<T extends { date: string }>(dates: T[]) {
  if (!dates.length) return dates;
  const emptyElement = Object.fromEntries(Object.keys(dates[0]).map((key) => [key, null]));

  const sortedDates = dates.sort((a, b) => sortDatesAscending<T>(a, b));

  const minDate = sortedDates[0]?.date || "";
  const maxDate = sortedDates[sortedDates.length - 1]?.date || "";

  const datesMap = new Map<string, T | typeof emptyElement>(sortedDates.map((x) => [x.date, x]));

  let currMoment = moment(minDate);
  while (currMoment.isBefore(maxDate, "day")) {
    const currDateKey = currMoment.format("YYYY-MM-DD");
    if (!datesMap.has(currDateKey)) {
      datesMap.set(currDateKey, { ...emptyElement, date: currDateKey } as T);
    }
    currMoment = currMoment.add(1, "day");
  }

  return Array.from(datesMap.values())
    .map((x) =>
      Object.fromEntries(
        Object.entries(x).map(([k, v]) => {
          /* @ts-ignore */
          if (k === "date" || isNaN(parseFloat(v))) return [k, v];
          return [k, v ? parseFloat(v) : v];
        })
      )
    ) /* @ts-ignore */
    .sort((a: T, b: T) => sortDatesAscending<T>(a, b));
}

export const findBottomTopBounds = (data: any[], xAxisKey: string) => {
  if (!data?.length) return ["dataMin", "dataMax"];
  return (
    data?.reduce(
      (acc, value) => {
        try {
          let min = acc[0];
          let max = acc[1];
          Object.entries(value).forEach(([key, value]) => {
            /* @ts-ignore */
            if (key === xAxisKey || isNaN(parseFloat(value?.toString()))) return;
            /* @ts-ignore */
            if (parseFloat(value) < min) min = parseFloat(value);
            /* @ts-ignore */
            if (parseFloat(value) > max) max = parseFloat(value);
          });
          return [min, max];
        } catch (e) {
          console.error(e);
          return acc;
        }
      },
      [Number.MAX_VALUE, Number.MIN_VALUE]
    ) || ["dataMin", "dataMax"]
  );
};
