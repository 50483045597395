import cn from "classnames";
import React, { ReactNode, useRef } from "react";

import Icon from "_LEGACY/UI/_LEGACY_Icon/Icon";

import { DeleteIcon } from "./DeleteIcon";
import Portal from "shared/ui/atoms/Portal";

import useOnClickOutside from "hooks/useOnClickOutside";
import useEscapeHandler from "utils/hooks/useEscapeHandler";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import logoIcon from "images/icons/logoIcon.svg";

import styles from "./Modal.module.scss";

interface IProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: (status: boolean) => void;
  title: ReactNode;
  className?: string;
  headerClassName?: string;
  titleClassName?: string;
  isShownLogo?: boolean;
  closeOnClickOutside?: boolean;
  darkShadow?: boolean;
}

const Modal: React.FC<IProps> = (props) => {
  const {
    children,
    isOpen,
    onClose,
    title,
    className,
    headerClassName,
    titleClassName,
    isShownLogo,
    closeOnClickOutside = true,
    darkShadow = false,
  } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEscapeHandler(() => onClose(false));

  const onShadowClick = () => {
    if (!closeOnClickOutside) return;
    onClose(false);
  };

  return (
    <>
      {isOpen && (
        <Portal>
          <div className={cn(styles.shadow, { [styles.shadowDarker]: darkShadow })} onClick={onShadowClick}>
            <div className={cn(styles.container, className)} ref={ref} onClick={stopEventPropagation}>
              <header className={cn(styles.header, headerClassName)}>
                <div className={cn(styles.title, titleClassName)}>
                  {isShownLogo && <Icon icon={logoIcon} className={styles.title} />}
                  {title}
                </div>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose(false);
                  }}
                  className={`closeModal ${styles.crossIcon}`}
                >
                  <DeleteIcon />
                </span>
              </header>
              {children}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default React.memo(Modal);
