export enum ShiftStatusesEnum {
  new = 'new',
  move = 'move',
  cancel = 'cancel'
}

export interface IShiftMaterialDetail {
  count: string;
  measure: string;
  name: string;
  stock_count: string;
}

export interface IShiftRelatedPlan {
  id: number;
  name: string;
  start_at: string;
  end_at: string;
}

export interface IGetShiftsResponse  {
  count: number;
  next: string | null;
  previous: string | null;
  results: IShift[];
}

export interface IShiftPlanWork {
  id: number;
  name: string;
  parent_section: string;
  section: string;
  count: string;
  measure: string;
  start_at: string;
  end_at: string;
}

export interface IShiftPlanWorkDetail {
  id: number;
  name: string;
  parent_section: string;
  section: string;
  count: string;
  measure: string;
  start_at: string;
  end_at: string;
  not_enough: IShiftMaterialDetail[];
  related: IShiftRelatedPlan[];
}

export interface IShiftPlanGroup {
  id: number;
  name: string;
  parent_section: string;
  section: string;
  count: string;
  measure: string;
  start_at: string;
  end_at: string;
}

export interface IShiftPlanGroupDetail {
  id: number;
  name: string
  parent_section: string;
  section: string;
  count: string;
  measure: string;
  start_at: string;
  end_at: string;
  not_enough: IShiftMaterialDetail[];
  related: IShiftRelatedPlan[];
}

export interface IWorkShift {
  id: number;
  created_at: string;
  status: ShiftStatusesEnum;
  type: "work";
  plan_work: IShiftPlanWork;
  deactivated?: true;
}

export interface IWorkShiftDetail {
  id: number;
  created_at: string;
  status: ShiftStatusesEnum;
  type: "work";
  plan_work: IShiftPlanWorkDetail;
}


export interface IGroupShift {
  id: number;
  created_at: string;
  status: ShiftStatusesEnum;
  type: "group";
  plan_group: IShiftPlanGroup;
  deactivated?: true;
}

export interface IGroupShiftDetail {
  id: number;
  created_at: string;
  status: ShiftStatusesEnum;
  type: "group";
  plan_group: IShiftPlanGroupDetail;
}

export type IShift =  IWorkShift | IGroupShift;
export type IShiftDetail = IWorkShiftDetail | IGroupShiftDetail;
