import cn from "classnames";
import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";

import { LOCALIZATION_CONFIG } from "constants/localization";

import styles from "./ContractsTableHeader.module.scss";

interface Iprops {
  onChangeFilter: (name: string, value: string) => void;
  className?: string;
  isContacts?: boolean;
}

const ContractsTableHeader: React.FC<Iprops> = ({ onChangeFilter, className, isContacts }) => {
  return (
    <TableReusableHead className={cn(styles.row, className)} isSticky>
      <TableReusableHeaderCell
        filterField="contract_num"
        filterTitle="№"
        filterType="text"
        onChangeFilter={onChangeFilter}
      >
        №
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="building_name"
        filterTitle="Проект"
        filterType="text"
        onChangeFilter={onChangeFilter}
      >
        Проект
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField={isContacts ? "contractor_name" : "entity_name"}
        filterTitle="Контрагент"
        filterType="text"
        onChangeFilter={onChangeFilter}
      >
        Контрагент
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterTitle="Дата"
        filterType="datesRange"
        onChangeFilter={onChangeFilter}
        rangeStartField="contract_date_start"
        rangeEndField="contract_date_end"
        isCentered
      >
        Дата
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>Стоимость {LOCALIZATION_CONFIG.currency}</TableReusableHeaderCell>
      <TableReusableHeaderCell>Файлы</TableReusableHeaderCell>
      <TableReusableHeaderCell>Действия</TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default ContractsTableHeader;
