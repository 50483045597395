import { Dispatch } from "redux";

import { sendMultiplePlansStateApi } from "./api";
import { sendMultiplePlansStateActions } from "./sendMultiplePlansSlice";
import { RootState } from "app/store/rootReducer";

export const getMultipleApprovingPlans = (buildingId: string) => (dispatch: Dispatch, getState: () => RootState) => {
  //loading
  sendMultiplePlansStateApi
    .get(buildingId)
    .then(({ data }) => {
      dispatch(sendMultiplePlansStateActions.setSections({ key: buildingId, sections: data }));
    })
    .finally(() => {
      //loading
    });
};

export const sendMultiplePlansToAprove =
  (buildingId: string, data: any) => (dispatch: Dispatch, getState: () => RootState) => {
    sendMultiplePlansStateApi.post(buildingId, data).then(() => {
      dispatch(sendMultiplePlansStateActions.invalidateKey());
    });
  };
