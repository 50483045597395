import { IPermission, IPermissionExtended, IPermissionsTree, IPermissionsTreeResponse } from "./types";

export const permissionsUtils = {
  prepareTree: (tree: IPermissionsTreeResponse): IPermissionsTree => {
    const accExtarctedPermisssions: IPermission[] = [];

    Object.entries(tree.groups).forEach(([_, val]) => {
      const isNeedToExtractPermissions = !val.name && !val.groups.length && val.permissions.length;
      if (isNeedToExtractPermissions) {
        accExtarctedPermisssions.concat(val.permissions);
      }
    });

    return {
      ...tree,
      groups: Object.entries(tree.groups)
        .map(([_, value]) => permissionsUtils.prepareTree(value))
        .map((el) => {
          if (el.permissions.every((p) => p.user_disabled)) {
            return {
              ...el,
              permissionsDisabled: true,
            };
          } else {
            return el;
          }
        }),
      permissions: tree.permissions.concat(accExtarctedPermisssions),
    };
  },
  extractDescriptionsByAliases: (
    tree: IPermissionsTree,
    parentChain: IPermissionExtended["parentChain"] = [],
    parentGroup?: IPermissionsTree,
    preParentGroup?: IPermissionsTree
  ) => {
    const res: Record<string, IPermissionExtended> = {};

    tree.permissions.forEach((el) => {
      let parentName: string;
      if (tree.name) {
        parentName = tree.name;
      } else {
        parentName = parentChain?.at(-1)?.name ?? "";
      }
      res[el.alias] = {
        ...el,
        groups: parentGroup?.groups!,
        parentChain: [...parentChain, { name: tree.name, alias: tree.permissions[0]?.alias }].filter((el) => el.name),
        parentName,
        preParentGroup,
      };
    });

    let groups: Record<string, IPermissionExtended> = {};

    tree.groups.forEach((el) => {
      groups = {
        ...groups,
        ...permissionsUtils.extractDescriptionsByAliases(
          el,
          [...parentChain, { name: tree.name, alias: tree.permissions[0]?.alias }],
          el,
          tree
        ),
      };
    });

    return {
      ...res,
      ...groups,
    };
  },
  searchInTree: (tree: IPermissionsTree, search: string): IPermissionsTree => {
    const filterGroup = (group: IPermissionsTree, search: string) => {
      if (group.groups.some((el) => filterGroup(el, search))) {
        return true;
      }
      if (group.permissions.some((el) => el.title.toLowerCase().includes(search.toLowerCase()))) {
        return true;
      }
    };

    return {
      ...tree,
      permissions: tree.permissions.filter((el) => el.title.toLowerCase().includes(search.toLowerCase())),
      groups: tree.groups
        .filter((el) => filterGroup(el, search))
        .map((el) => permissionsUtils.searchInTree(el, search)),
    };
  },
  toggleChildren: (tree: IPermissionsTree, parentAlias: string): IPermissionsTree => {
    return {
      ...tree,
    };
  },
};
