import cn from "classnames";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartActions } from "redux/modules/common/chart/actions";
import { chartTreeExpanderSelector } from "redux/modules/common/chart/selectors";

import { ReactComponent as LeftIcon } from "shared/assets/icons/ArrowLeft-small.svg";
import { ReactComponent as RigthIcon } from "shared/assets/icons/ArrowRigth.svg";

import styles from "./ChartTreeExpander.module.scss";

const leftArrowCn = cn(styles.arrowButton, styles.arrowLeft);
const rightArrowCn = cn(styles.arrowButton, styles.arrowRight);

const ChartTreeExpander: React.FC = () => {
  const dispatch = useDispatch();
  const expander = useSelector(chartTreeExpanderSelector);

  const expandValues = useMemo(() => (expander ? Object.values(expander) : []), [expander]);
  const canExpand = useMemo(() => expandValues.some((x) => !x), [expandValues]);
  const canCollapse = useMemo(() => expandValues.some((x) => x), [expandValues]);

  const collapse = useCallback(() => {
    const name = (() => {
      if (expander?.period) return "period";
      if (expander?.days) return "days";
    })();
    if (!name) return;
    dispatch(chartActions.updateTreeExpander({ name, value: false }));
  }, [expander]);

  const expand = useCallback(() => {
    const name = (() => {
      if (!expander?.days) return "days";
      if (!expander?.period) return "period";
    })();
    if (!name) return;
    dispatch(chartActions.updateTreeExpander({ name, value: true }));
  }, [expander]);

  if (!expander) return null;

  const areArrowsVisible = canExpand || canCollapse;

  return (
    <>
      {expander.days && !expander.period && <div className={cn(styles.barier, styles.rigthDays)} />}
      {expander.days && expander.period && <div className={cn(styles.barier, styles.rigthFirst)} />}
      {expander.period && <div className={cn(styles.barier, styles.rigthSecond)} />}
      {areArrowsVisible && (
        <div
          className={cn(styles.arrowButtonContainer, {
            [styles.collapseOnly]: canCollapse && !canExpand,
            [styles.expandOnly]: canExpand && !canCollapse,
          })}
        >
          {canCollapse && (
            <div className={leftArrowCn} onClick={collapse}>
              <LeftIcon className={styles.icon} />
            </div>
          )}
          {canExpand && (
            <div className={rightArrowCn} onClick={expand}>
              <RigthIcon className={styles.icon} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(ChartTreeExpander);
