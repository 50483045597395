import cn from "classnames";
import React from "react";

import styles from "./ErrorMessage.module.scss";

interface IProps {
  className?: string;
  children: React.ReactNode;
  isStatic?: boolean;
}

const ErrorMessage: React.FC<IProps> = ({ children, className, isStatic }) => {
  return <div className={cn(styles.msg, className, isStatic && styles.static)}>{children}</div>;
};

export default ErrorMessage;
