import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  dropExpendituresBySections,
  expendituresBySectionsSelector,
  getExpendituresBySection,
} from "../../../../redux/modules/common/building/sections/sections";
import { apiLoadSection, apiLoadSections } from "../../../../redux/modules/common/building/sections/sectionsApi";

import { ESTIMATE_STATES_IDS } from "../../../../pages/Handler/ui/ProHandler/constants";
import { EstimateStateId } from "../../../../pages/Handler/ui/ProHandler/types";
import BottomControls from "../WorkOrMaterialsModals/components/BottomControls/BottomControls";

import { Spinner } from "../../../../shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "../../../../shared/ui/controls/ButtonBase";
import { InputSearchRound } from "../../../../shared/ui/inputs/InputSearchRound/InputSearchRound";
import SliderModal from "../../../../shared/ui/modal/SliderModal/SliderModal";
import SectionsWithCheckedExpenditures from "../SectionsWithCheckedExpenditures/SectionsWithCheckedExpenditures";

import { ExpenditureType } from "../../../../types/enums/ExpenditureTypeEnum";
import { IExpenditure } from "../../../../types/interfaces/Expenditure";

import styles from "./ExpenditureTree.module.scss";

export interface IExpenditureTreeProps {
  currentExpenditure: IExpenditure | null;
  isOpen: boolean;
  onClose: () => {};
  onSubmit: (selectedExpenditureId: number, path: { section: any; subsection: any; expenditure: any }) => {};
  buildingId: string;
  expenditureType: ExpenditureType;
  title: string;
  actionLabel: string;
  subtitle?: string;
  cantChoseShared?: boolean;
  targetStateId: EstimateStateId;
}

const ExpenditureTree: React.FC<IExpenditureTreeProps> = ({
  currentExpenditure,
  isOpen,
  onClose,
  buildingId,
  expenditureType,
  onSubmit,
  title,
  subtitle,
  actionLabel,
  cantChoseShared,
  targetStateId = ESTIMATE_STATES_IDS.DRAFT,
}) => {
  const [initialSections, setInitialSections] = useState();
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const expendituresBySections = useSelector(expendituresBySectionsSelector);

  const [selectedEstimateTarget, setSelectedEstimateTarget] = useState<number | null>(null);

  const checkedExpenditures = useMemo(
    /* @ts-ignore */
    () => (selectedEstimateTarget ? { [selectedEstimateTarget.expenditureId]: true } : {}),
    [selectedEstimateTarget]
  );

  const getEstimateTargetPath = useCallback(() => {
    /* @ts-ignore */
    const selectedSection = sections.find(
      /* @ts-ignore */
      (section) => section.id === selectedEstimateTarget.sectionId
    ); /* @ts-ignore */
    const selectedSubsection = selectedSection.subsections.find(
      /* @ts-ignore */
      (subsection) => subsection.id === selectedEstimateTarget.subsectionId
    ); /* @ts-ignore */
    const selectedExpenditure = expendituresBySections[selectedEstimateTarget.subsectionId].results.find(
      /* @ts-ignore */
      (expenditure) => expenditure.id === selectedEstimateTarget.expenditureId
    );

    return { section: selectedSection, subsection: selectedSubsection, expenditure: selectedExpenditure };
  }, [expendituresBySections, sections, selectedEstimateTarget]);

  const handleSubmit = useCallback(() => {
    if (!selectedEstimateTarget || !currentExpenditure) return; /* @ts-ignore */
    onSubmit(selectedEstimateTarget?.expenditureId, getEstimateTargetPath());
    onClose();
  }, [
    getEstimateTargetPath,
    onClose,
    currentExpenditure,
    buildingId,
    onSubmit,
    selectedEstimateTarget,
    expenditureType,
  ]);
  /* @ts-ignore */
  const onSelectExpenditure = useCallback((estimateTarget, isSelect: boolean) => {
    if (isSelect) setSelectedEstimateTarget(estimateTarget);
  }, []);

  const searchedSections = useMemo(() => {
    if (!sections) return [];
    return sections /* @ts-ignore */
      .filter((el) => !el.is_default)
      .map((sectionItem) => ({
        /* @ts-ignore */
        ...sectionItem /* @ts-ignore */,
        subsections: sectionItem?.subsections?.filter(
          /* @ts-ignore */
          (subsectionItem) => subsectionItem.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        ),
      }));
  }, [sections, search]);

  const onChangeSearchInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const debouncedOnChangeSearchInput = useMemo(() => debounce(onChangeSearchInput, 300), [onChangeSearchInput]);

  const serializedExpendituresBySections = useMemo(() => {
    const expenditures = {};
    Object.entries(expendituresBySections).forEach(
      ([sectionId, data] /* @ts-ignore */) =>
        (expenditures[sectionId] = data?.results?.filter((expenditure) => expenditure?.id !== currentExpenditure?.id))
    );
    return expenditures;
  }, [expendituresBySections, currentExpenditure]);

  const onOpenHiddenSubsection = useCallback(
    (subsectionId: number) => {
      /* @ts-ignore */
      if (expendituresBySections[subsectionId]) return;
      dispatch(
        getExpendituresBySection(
          {
            building: buildingId,
            section: subsectionId,
            estimateState: targetStateId,
          },
          { expenditure_type: expenditureType }
        )
      );
    },
    [expendituresBySections, buildingId, expenditureType, targetStateId, expenditureType]
  );

  useEffect(() => {
    if (!isOpen) return; /* @ts-ignore */
    apiLoadSections(buildingId, targetStateId).then((data) => setInitialSections(data?.results || []));
  }, [buildingId, targetStateId, isOpen]);

  useEffect(() => {
    if (!initialSections || !isOpen) return; /* @ts-ignore */
    const sectionsPromises = initialSections?.map(({ id }: { id: number }) => {
      return apiLoadSection(buildingId, id, targetStateId, true).then((sectionsWithSubsections) => {
        if (!sectionsWithSubsections) return; /* @ts-ignore */
        setSections((prevState) => [...prevState, sectionsWithSubsections]);
      });
    });
    Promise.all(sectionsPromises).then(() => setIsLoading(false));
    return () => {
      setInitialSections(undefined);
      setSections([]);
      setSelectedEstimateTarget(null);
      dispatch(dropExpendituresBySections());
    };
  }, [initialSections, buildingId, targetStateId, isOpen]);
  return (
    <SliderModal isOpen={isOpen} closeHandler={onClose} className={styles.sliderClassName}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titleBlock}>
            <div className={styles.title} title={title}>
              {title}
            </div>
            {subtitle && (
              <span className={styles.userTitle} title={subtitle}>
                {subtitle}
              </span>
            )}
          </div>
          <div className={styles.searchBlock}>
            <InputSearchRound onChange={debouncedOnChangeSearchInput} className={styles.inputClassName} />
            <ButtonBase className={styles.btn} primary onClick={handleSubmit} disabled={!selectedEstimateTarget}>
              {actionLabel}
            </ButtonBase>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            {!isLoading /* @ts-ignore */ ? (
              <SectionsWithCheckedExpenditures
                sections={searchedSections}
                expendituresBySections={serializedExpendituresBySections as any}
                externalCheckedExpenditures={checkedExpenditures}
                onCheckExpenditure={onSelectExpenditure}
                onOpenHiddenSubsection={onOpenHiddenSubsection}
                //selectedEstimateTarget={{ expenditureId: selectedEstimateTarget! }}
              />
            ) : (
              <Spinner isStatic />
            )}
          </div>
        </div>
        <BottomControls isEmpty isExists children={null} />
      </div>
    </SliderModal>
  );
};

export default React.memo(ExpenditureTree);
