import { AookPermitsEnum, ICreateAook } from "redux/modules/common/building/journal/aook/types";

export const getNormalizedAookPermit = (values: Partial<ICreateAook>): Partial<ICreateAook> => {
  if (!values.permit) return { permit: AookPermitsEnum.use /* permit_condition: "", permit_partially: "" */ };

  const [permit, percents] = values.permit.split("/");
  if (permit === AookPermitsEnum.partially) {
    if (!percents) {
      return {
        permit,
        //permit_condition: "",
      };
    }
    return {
      permit,
      permit_partially: percents,
      //permit_condition: "",
    };
  }

  return { permit: values.permit /* permit_partially: "" */ };
};
