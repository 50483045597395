import moment from "moment";
import React, { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { IBuildingPropsPermissions } from "components/pages/Building/Building";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { useGetDrawingSetsQuery } from "../../model/blueprintsQuery";
import DocumentationSetRow from "../../ui/DocumentationSetRow/DocumentationSetRow";
import DocumentationTableHeader from "../../ui/DocumentationTableHeader/DocumentationTableHeader";
import { emptyDrawingsIcon } from "../../ui/emptyDrawingsIcon";
import BlueprintSetModal from "./../BlueprintSetModal/BlueprintSetModal";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

interface IProps {
  children: React.ReactNode;
  permissions: IBuildingPropsPermissions;
}

const BlueprintsSetsLevel: React.FC<IProps> = ({ children, permissions }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const buildingId = useObjectId();
  const [filters, setFilters] = useState<Record<string, string>>({});
  const reqParams = useMemo(() => {
    return {
      ...filters,
      buildingId,
    };
  }, [filters, buildingId]);
  const { data, isLoading, isFetching } = useGetDrawingSetsQuery(reqParams);

  const showLoader = isLoading || (isFetching && !data?.results.length);

  const [isModal, setIsModal] = useState(false);

  return (
    <>
      <ListPageHeaderTemplate>
        {children}
        {permissions.canAddDrawingSets && (
          <ButtonBase secondary onClick={() => setIsModal(true)}>
            Добавить комплект
          </ButtonBase>
        )}
      </ListPageHeaderTemplate>
      <DocumentationTableHeader
        permissions={permissions}
        isSets
        onChangeFilter={(name, value) => setFilters((prev) => ({ ...prev, [name]: value }))}
      />
      {data?.results?.map((el) => (
        <DocumentationSetRow
          id={el.id}
          key={el.id}
          name={el.title}
          code={el.code}
          developerOrganization={el.organization}
          downloaded={moment(el.created_at).format("DD.MM.YYYY")}
          files={el.files_count}
          isSets
          onClickDocumentation={() => history.push(`${pathname}/${el.id}`)}
          permissions={permissions}
        />
      ))}
      {showLoader && <Spinner />}
      {!isLoading && !data?.results?.length && (
        <EmptyPlaceholder text="Нет комплектов чертежей в реестре. Добавьте запись" svgJsx={emptyDrawingsIcon}>
          <ButtonBase onClick={() => setIsModal(true)} primary>
            Добавить комплект
          </ButtonBase>
        </EmptyPlaceholder>
      )}
      <BlueprintSetModal key={data?.results?.length} isOpen={isModal} onClose={() => setIsModal(false)} />
    </>
  );
};

export default BlueprintsSetsLevel;
