import cn from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import { IPlanFactExpenditureInner } from "../../../../../../../../../../redux/modules/_TODO/constructing/finance/planFact/types";

import { useUrlModuleWithObjectId } from "../../../../../../../../../../utils/hooks/useUrlModuleWithObjectId";

import { transformDigitToFinancial } from "../../../../../../../../../../utils/formatters/transformDigitToFinancial";
import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";

import styles from "./FactAcceptedMaterial.module.scss";

export interface IFactAcceptedMaterialProps {
  material: IPlanFactExpenditureInner;
  isExpandedDifference?: boolean;
}

const FactAcceptedMaterial: React.FC<IFactAcceptedMaterialProps> = ({ material, isExpandedDifference }) => {
  const module = useUrlModuleWithObjectId();
  /* @ts-ignore */
  const documentLink = !!material.packinglist_id /* @ts-ignore */
    ? `/${module}/documents/packing-lists/${material.packinglist_id}` /* @ts-ignore */
    : !!material.service_act_id /* @ts-ignore */
    ? `/${module}/documents/service-acts/${material.service_act_id}`
    : undefined;

  return (
    <div className={cn(styles.container, { [styles.withExpandedDifference]: isExpandedDifference })}>
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <div className={styles.field1}></div>
          <div className={styles.field2} title={material.name}>
            <Link to={documentLink}>{material.name}</Link>
          </div>
          {!isExpandedDifference && <div className={styles.field3}>{material.measure}</div>}
        </div>
        <div className={styles.rightSide}>
          {!isExpandedDifference && <div className={styles.fieldLeft1}>-</div>}
          <div className={styles.fieldLeft2}>-</div>
          <div className={styles.fieldLeft3}>-</div>
          {!isExpandedDifference && (
            <div className={styles.fieldLeft4}>{dropNonSignificantZeros(material.fact_count) || "-"}</div>
          )}
          <div className={styles.fieldLeft5}>
            {transformDigitToFinancial(material.fact_avg_price, { withFloat: true, dropZeros: true }) || "-"}
          </div>
          <div className={styles.fieldLeft6}>
            {transformDigitToFinancial(material.fact_amount, { withFloat: true, dropZeros: true }) || "-"}
          </div>
          <div className={styles.fieldLeft7}>{"-"}</div>
          {isExpandedDifference && <div className={styles.fieldLeft8}>{"-"}</div>}
        </div>
      </div>
    </div>
  );
};

export default React.memo(FactAcceptedMaterial);
