import React from "react";

function GroupByIcon({ color = "#4FB2ED", width = "18", ...props }) {
  return (
    <svg
      width={width}
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.78008 7.94968V3.50368C2.78008 2.58568 3.21208 2.06368 4.14808 2.06368H4.63408V1.00168H3.96808C3.63883 0.950978 3.30231 0.977266 2.98493 1.07848C2.66755 1.17969 2.37793 1.35307 2.13882 1.58502C1.8997 1.81696 1.71759 2.10116 1.60676 2.41532C1.49594 2.72947 1.45942 3.06504 1.50008 3.39568V7.96768C1.50008 8.61568 1.21208 8.88568 0.690078 8.88568H0.0800781V9.96568H0.692078C1.21408 9.96568 1.50208 10.2357 1.50208 10.8837V15.4577C1.46183 15.788 1.49858 16.1231 1.60945 16.4368C1.72032 16.7505 1.90229 17.0343 2.14113 17.266C2.37996 17.4977 2.66917 17.6709 2.98613 17.7722C3.30308 17.8734 3.63917 17.9 3.96808 17.8497H4.63408V16.7877H4.14808C3.21208 16.7877 2.78008 16.2657 2.78008 15.3477V10.9017C2.78008 10.0017 2.51008 9.62368 1.93408 9.42568C2.51008 9.22768 2.78008 8.84968 2.78008 7.94968Z"
        fill={color}
      />
      <path
        d="M17 4.90234H8C7.44772 4.90234 7 5.35006 7 5.90234C7 6.45463 7.44772 6.90234 8 6.90234H17C17.5523 6.90234 18 6.45463 18 5.90234C18 5.35006 17.5523 4.90234 17 4.90234Z"
        fill={color}
      />
      <path
        d="M17 5.40234H8C7.72386 5.40234 7.5 5.6262 7.5 5.90234C7.5 6.17849 7.72386 6.40234 8 6.40234H17C17.2761 6.40234 17.5 6.17849 17.5 5.90234C17.5 5.6262 17.2761 5.40234 17 5.40234Z"
        stroke={color}
      />
      <path
        d="M17 8.90234H11C10.4477 8.90234 10 9.35006 10 9.90234C10 10.4546 10.4477 10.9023 11 10.9023H17C17.5523 10.9023 18 10.4546 18 9.90234C18 9.35006 17.5523 8.90234 17 8.90234Z"
        fill={color}
      />
      <path
        d="M17 9.40234H11C10.7239 9.40234 10.5 9.6262 10.5 9.90234C10.5 10.1785 10.7239 10.4023 11 10.4023H17C17.2761 10.4023 17.5 10.1785 17.5 9.90234C17.5 9.6262 17.2761 9.40234 17 9.40234Z"
        stroke={color}
      />
      <path
        d="M17 12.9023H15C14.4477 12.9023 14 13.3501 14 13.9023C14 14.4546 14.4477 14.9023 15 14.9023H17C17.5523 14.9023 18 14.4546 18 13.9023C18 13.3501 17.5523 12.9023 17 12.9023Z"
        fill={color}
      />
      <path
        d="M17 13.4023H15C14.7239 13.4023 14.5 13.6262 14.5 13.9023C14.5 14.1785 14.7239 14.4023 15 14.4023H17C17.2761 14.4023 17.5 14.1785 17.5 13.9023C17.5 13.6262 17.2761 13.4023 17 13.4023Z"
        stroke={color}
      />
    </svg>
  );
}

export default React.memo(GroupByIcon);