import { VIEW_BUILDINGS_SECTION } from "../../../../constants/permissions/constructingPermissions";
import {
  VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_REMARKS,
  VIEW_MANUFACTURING_PROJECT,
} from "../../../../constants/permissions/manufacturingPermissions";
import { MODULES_ENUM } from "../../../../types/enums/ModulesEnum";

export interface IIndicatorsInState {
  indicatorsCount: number;
  indicators: IUIIndicatorResponse[];
}

export interface IUiIndicatorsInitialState {
  isLoading: boolean;
  invalidateKey: number;
  [MODULES_ENUM.CONSTRUCTING]: IIndicatorsInState;
  [MODULES_ENUM.OBJECTS]: {
    [buildingId: number]: IIndicatorsInState;
  };
  [MODULES_ENUM.PURCHASES]: IIndicatorsInState;
  [MODULES_ENUM.TASKS]: IIndicatorsInState;
}

// Производство
export const JOURNAL_INDICATORS = [
  "journal_accept_work",
  "journal_accept_group",
  "journal_accept_work_material",
  "journal_accept_group_material",
  "remark_created",
  "remark_review",
] as const;
export const PROJECT_INDICATORS = ["confirm_expenditure_plan"] as const;
export type ObjectsIndicators = typeof JOURNAL_INDICATORS[number] | typeof PROJECT_INDICATORS;

// Управление
export const PROJECTS_INDICATORS = ["change_estimate_state", "confirm_expenditure_plan"] as const;
export type ConstructingIndicators = typeof PROJECTS_INDICATORS[number];

//Закупки
export type PurchasesIndicators = "";

//Задачи
export type TasksIndicators = "";

export type UIIndicatorsType = ConstructingIndicators & ObjectsIndicators & PurchasesIndicators & TasksIndicators;

export const UI_INDICATORS_PERMISSIONS: Record<UIIndicatorsType, string | string[]> = {
  // journal_accept_work: VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE,
  // journal_accept_group: VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE,
  // journal_accept_work_material: VIEW_MANUFACTURING_JOURNAL_PROGRESS,
  // journal_accept_group_material: VIEW_MANUFACTURING_JOURNAL_PROGRESS,
  // remark_created: [VIEW_MANUFACTURING_JOURNAL_PROGRESS, VIEW_MANUFACTURING_JOURNAL_PROGRESS_REMARKS],
  // remark_review: [VIEW_MANUFACTURING_JOURNAL_PROGRESS, VIEW_MANUFACTURING_JOURNAL_PROGRESS_REMARKS],
  // change_estimate_state: VIEW_BUILDINGS_SECTION,
  // confirm_expenditure_plan: [VIEW_MANUFACTURING_PROJECT, VIEW_BUILDINGS_SECTION],
};

export interface IUIIndicatorResponse {
  id: number;
  type: UIIndicatorsType;
  modules: MODULES_ENUM[];
  account_id: string;
  building_id: number;
  for_user_id: number;
  data: Record<string, any>;
}
