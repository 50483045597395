import React from "react";

import Indicators from "components/pages/Building/components/EstimateIndicators/Indicators";

import { useFinanceMeasure } from "../../../../../features/financeMeasure";
import HideLine from "../../../../../shared/ui/layout/HideLine/HideLine";

import styles from "./EstimateIndicators.module.scss";

function EstimateIndicators({ id, isOpen, setIsOpen }) {
  const { measure } = useFinanceMeasure();

  return (
    <HideLine
      containerClassName={styles.container}
      containerOpenClassName={styles.open}
      contentClassName={styles.content}
      setOuterIsOpen={setIsOpen}
      outerIsOpen={isOpen}
    >
      <Indicators id={id} measure={measure} />
    </HideLine>
  );
}

export default EstimateIndicators;
