import cn from "classnames";
import React, { useState } from "react";

import PopoverContent from "./components/PopoverContent/PopoverContent";
import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import { ReactComponent as RequiredDayIcon } from "images/icons/required-day-icon-blue.svg";

import styles from "./RequiredDays.module.scss";

export interface IProps {
  delayDays: number;
  objectId: number;
  itemId: number;
}

const RequiredDays: React.FC<IProps> = ({ delayDays, objectId, itemId }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <PopoverOverlay
        isOpen={isOpen}
        onInteraction={(event: boolean) => setIsOpen(event)}
        content={<PopoverContent objectId={objectId} itemId={itemId} defaultCount={delayDays} setIsOpen={setIsOpen} />}
        placement={"bottom"}
        popoverBorderColor={"gray"}
        className={cn(styles.container, { [styles.done]: delayDays > 0 })}
      >
        <RequiredDayIcon />
      </PopoverOverlay>
      <span className={styles.count}>{delayDays > 0 && `${delayDays} д.`}</span>
    </>
  );
};

export default React.memo(RequiredDays);
