import moment from "moment";
import { Dispatch } from "redux";

import { RootState } from "../../../../../../app/store/rootReducer";
import { planFactActions } from "./actions";
import { planFactApi } from "./planFactApi";

import { errorCatcher } from "../../../../../../utils/helpers/errorCatcher";
import { measureSwitcher } from "../../../../../../utils/measureSwitcher";

export const loadPlanFactTree = (objectId: number, startDate?: string, endDate?: string) => (dispatch: Dispatch) => {
  dispatch(planFactActions.setLoading(true));
  planFactApi
    .getTree(objectId, startDate, endDate)
    .then(({ data }) => {
      dispatch(planFactActions.setTree(data));
    })
    .catch(errorCatcher)
    .finally(() => dispatch(planFactActions.setLoading(false)));
};

export const togglePlanFactMeasure = () => (dispatch: Dispatch, getState: () => RootState) => {
  const measure = getState()?.constructing?.finance?.planFact?.measure || 1;
  dispatch(planFactActions.setMeasure(measureSwitcher(measure)));
};

export const setPlanFactStartDate = (start_at: string) => (dispatch: Dispatch, getState: () => RootState) => {
  const months = getState()?.constructing?.finance?.planFact?.selectedDates?.monthsCount;
  const monthsCount = typeof months === "string" ? 1 : months || 1;

  const end_at = moment(start_at)
    ?.add(monthsCount - 1, "month")
    ?.endOf("month")
    ?.format("YYYY-MM-DD");

  dispatch(
    planFactActions.setSelectedDates({
      start_at,
      end_at,
      monthsCount,
    })
  );
};

export const setPlanFactMonthsCount =
  (monthsCount: number | "all", fallbackDateStart?: string, fallbackDateEnd?: string) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const currentSelectedDates = getState()?.constructing?.finance?.planFact?.selectedDates;
    if (monthsCount === "all") {
      dispatch(
        planFactActions.setSelectedDates({
          ...currentSelectedDates,
          start_at: fallbackDateStart || currentSelectedDates.start_at,
          end_at: fallbackDateEnd || currentSelectedDates.end_at,
          monthsCount,
        })
      );
    } else {
      const start_at =
        getState()?.constructing?.finance?.planFact?.selectedDates?.start_at || moment().format("YYYY-MM-DD");

      const end_at = moment(start_at)
        ?.add(monthsCount - 1, "month")
        ?.endOf("month")
        ?.format("YYYY-MM-DD");

      dispatch(
        planFactActions.setSelectedDates({
          start_at,
          end_at,
          monthsCount,
        })
      );
    }
  };

export const setPlanFactDatesRange = (start_at: string, end_at: string) => (dispatch: Dispatch) => {
  const startMoment = moment(start_at);
  const endMoment = moment(end_at);
  const monthsCount = (endMoment.year() - startMoment.year()) * 12 + (endMoment.month() - startMoment.month() + 1);

  dispatch(
    planFactActions.setSelectedDates({
      start_at,
      end_at,
      monthsCount,
    })
  );
};
