import React from "react";

export default function CrossIcon({color = '#000', width = "16.124"}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={width}
      height="16.124" 
      viewBox="0 0 16.124 16.124"
    >
      <path 
        d="M-1719.144-844.288l5.637-5.637-5.637-5.637,2.425-2.425,5.637,5.637,5.637-5.637,2.425,2.425-5.637,5.637,5.637,5.637-2.425,2.425-5.637-5.637-5.637,5.637Z" 
        transform="translate(1719.144 857.987)"
        fill={color}
      />
    </svg>
)}