import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";

import Actions from "../../../../../../shared/ui/controls/Actions/Actions";
import Checkbox from "../../../../../../shared/ui/inputs/Checkbox/Checkbox";

import { ISerializedMimsInPlan } from "types/interfaces/Mims";

import useDebounce from "utils/hooks/useDebouncedValue";

import { stopEventPropagation } from "../../../../../../utils/helpers/stopEventPropagation";
import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";
import { isNanChecker } from "utils/formatters/isNanChecker";
import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";

import styles from "./TableRow.module.scss";

interface IProps {
  mim: ISerializedMimsInPlan;
  onCheck?: (e: React.ChangeEvent<HTMLInputElement>, mim: ISerializedMimsInPlan) => void;
  onChangeCount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  canBeChecked?: boolean;
  onDelete?: () => void;
  canDelete?: boolean;
  isSelected?: boolean;
  isPrefilled?: boolean;
  wholeWorkCount?: number;
}

const initialLocalCountSetter = (mim: IProps["mim"], isPrefilled?: IProps["isPrefilled"]) => {
  if (!isPrefilled) return "";
  const measure = mim.measure;
  const value = isNanChecker(Number(mim.local_count || mim.count));
  return String(dropNonSignificantZeros(value, 4));
};

const PlanMimsTableRow: React.FC<IProps> = ({
  mim,
  onCheck,
  onChangeCount,
  canBeChecked = true,
  onDelete,
  canDelete,
  isSelected = false,
  isPrefilled,
  wholeWorkCount,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!mim.local_count) return;
    setInputValue(initialLocalCountSetter(mim, isPrefilled));
  }, [wholeWorkCount]);

  const debouncedMimCount = useDebounce(mim.local_count, 1000);

  useEffect(() => {
    if (!mim.local_count) return;
    setInputValue(initialLocalCountSetter(mim, isPrefilled));
  }, [debouncedMimCount, isPrefilled]);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCount(e);
    setInputValue(e.target.value?.replace(",", "."));
  };

  const clickDrowningHandler = () => {
    ref.current?.click();
  };

  return (
    <div
      className={cn(styles.row, styles.planMaterialRow, { [styles.selected]: isSelected })}
      onClick={clickDrowningHandler}
    >
      <div className={styles.checkbox}>
        {canBeChecked && onCheck && (
          <Checkbox
            onCheck={(e: React.ChangeEvent<HTMLInputElement>) => onCheck(e, { ...mim, local_count: inputValue })}
            checked={isSelected}
          >
            <div ref={ref} />
          </Checkbox>
        )}
      </div>
      <div className={styles.name} title={mim?.name}>
        {mim?.name}
      </div>
      <div onClick={stopEventPropagation}>
        <input className={styles.input} onChange={changeHandler} value={inputValue} placeholder="Кол-во" />
      </div>
      <div className={cn(styles.name, styles.measure)} title={mim?.count + " " + mim?.measure}>
        {`${dropNonSignificantZeros(mim?.available_count ?? mim?.count, 4)} ${sliceTextByConstraint(mim?.measure, 4)}`}
        {canDelete && (
          <Actions canRemove={canDelete} onRemove={onDelete} className={cn(styles.delete, styles.deleteMaterial)} />
        )}
      </div>
    </div>
  );
};

export default React.memo(PlanMimsTableRow);
