import cn from "classnames";
import React from "react";

import styles from "./ManufacturingModalDivider.module.scss";

type eventType = "plan" | "progress" | "accepted" | "order" | "supply" | "fromStock" | "toPay" | "acceptedMaterial";

type propsType = {
  type: eventType;
  className?: string;
  isWithoutMarginTop?: boolean;
};

type textSwitcherType = { [key in eventType]: string };

const textSwitcher: textSwitcherType = {
  plan: "План",
  progress: "К сдаче",
  accepted: "Принято",
  supply: "Поставка",
  order: "Заказ",
  fromStock: "Выдано со склада",
  toPay: "К оплате",
  acceptedMaterial: "Принято",
};

function ManufacturingModalDivider({ type, className, isWithoutMarginTop }: propsType) {
  return (
    <div className={cn(styles.container, styles[type], className, { [styles.mt0]: isWithoutMarginTop })}>
      <div className={styles.status}>
        <div className={styles.icon} />
        {textSwitcher[type]}
      </div>
    </div>
  );
}

export default ManufacturingModalDivider;
