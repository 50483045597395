import { Dispatch } from "redux";

import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import { auditApi } from "./api";
import { auditActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getAuditFullList = () => (dispatch: Dispatch, getState: () => RootState) => {
  const { dateEnd, dateStart, type, dataTree, loadings } = getState().financeReducer.audit;
  const rootKey = generateStorageKey({ type, startAt: dateStart, endAt: dateEnd });
  if (loadings[rootKey] === undefined) {
    dispatch(auditActions.setLoading({ key: rootKey, status: true }));
  }
  auditApi
    .getProject({ start: dateStart, end: dateEnd }, type)
    .then(({ data }) => {
      dispatch(auditActions.setProjects({ data, key: rootKey }));
    })
    .finally(() => {
      dispatch(auditActions.setLoading({ key: rootKey, status: false }));
    });
};

export const getAuditProject = (projectId: number) => (dispatch: Dispatch, getState: () => RootState) => {
  const { dateEnd, dateStart, type, dataTree } = getState().financeReducer.audit;
  const rootKey = generateStorageKey({ type, startAt: dateStart, endAt: dateEnd });
  const wasntLoaded = !dataTree[rootKey]?.some((pr) => pr.id === projectId);
  const key = generateStorageKey({ type, startAt: dateStart, endAt: dateEnd, projectId });
  if (wasntLoaded) {
    dispatch(auditActions.setLoading({ key, status: true }));
  }
  auditApi
    .getProject({ start: dateStart, end: dateEnd }, type, projectId)
    .then(({ data }) => {
      dispatch(auditActions.setProject({ data, projectId, rootKey }));
    })
    .finally(() => {
      dispatch(auditActions.setLoading({ key, status: false }));
    });
};

export const getAuditObject =
  (projectId: number, objectId: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const { dateEnd, dateStart, type, dataTree } = getState().financeReducer.audit;
    const rootKey = generateStorageKey({ type, startAt: dateStart, endAt: dateEnd });
    const wasntLoaded = !dataTree[rootKey]
      ?.find((pr) => pr.id === projectId)
      ?.children?.find((obj) => obj.id === objectId)?.children?.length;
    const loadingKey = generateStorageKey({ buildingId: objectId, type: "object" });
    if (wasntLoaded) {
      dispatch(auditActions.setLoading({ key: loadingKey, status: true }));
    }
    auditApi
      .getObjectAudit(objectId, { start: dateStart, end: dateEnd }, type)
      .then(({ data }) => {
        dispatch(auditActions.setObject({ data, projectId, rootKey, objectId }));
      })
      .finally(() => {
        dispatch(auditActions.setLoading({ key: loadingKey, status: false }));
      });
  };

export const toggleAuditTree = (subTree: IFinanceTreeItem) => (dispatch: Dispatch, getState: () => RootState) => {
  const { dateEnd, dateStart, type, dataTree } = getState().financeReducer.audit;
  const rootKey = generateStorageKey({ type, startAt: dateStart, endAt: dateEnd });
  if (subTree.isOpen) {
    dispatch(auditActions.closeItem({ subTree, key: rootKey }));
  } else {
    dispatch(auditActions.openItem({ subTree, key: rootKey }));
    if (subTree.type === "object" && subTree.shouldBeLoaded && !subTree.children?.length) {
      const tree = dataTree[rootKey] ?? [];
      const parent = tree.find((project) => project.children?.some((obj) => obj.id === subTree.id));
      dispatch(getAuditObject(parent?.id!, subTree.id) as any);
    }
  }
};
