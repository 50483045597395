import axios from "axios";

import { IStockInList, IStockMoving, IStockMovingProductInList } from "./types";
import { IListResponseData } from "types/ListResponse";

export const stocksApi = {
  getList: () => {
    return axios.get<IListResponseData<IStockInList>>(`/stocks/list/`, { params: { limit: 500 } });
  },
  createMoving: (stockId: string, moving: IStockMoving) => {
    return axios.post(`/stocks/${stockId}/move/`, moving);
  },
  getMovingProducts: (stockId: string, movingId: number) => {
    return axios.get<IListResponseData<IStockMovingProductInList>>(`/stocks/${stockId}/move/${movingId}/products/`, {
      params: { limit: 500 },
    });
  },
  confirmMoving: (stockId: string, movingId: number) => {
    return axios.patch(`/stocks/${stockId}/move/${movingId}/confirm/`, { approve_stock_responsible: true });
  },
};
