import React from "react";
import cn from "classnames";

import ArrowRoundedRightBigIcon from "images/icons/ArrowRoundedRightBigIcon";

import styles from "./index.module.scss";

const ProgressTabs = ({ tabs, setActiveTab, activeTabId }) => {
  const onClickTab = (tabId) => {
    const clickedTab = tabs.find((tab) => tab.id === tabId);

    if (clickedTab.disabled) return;
    setActiveTab(tabId);
  };

  return (
    <div className={styles.progressTabs}>
      {tabs.map((tab, index) => (
        <React.Fragment key={tab.id}>
          <div
            className={cn(styles.tab, { [styles.active]: tab.id === activeTabId })}
            onClick={() => onClickTab(tab.id)}
          >
            {`${tab.name} ${tab.activeSteps} / ${tab.totalSteps}`}
          </div>
          {index !== tabs.length - 1 && (
            <div className={styles.arrow}>
              <ArrowRoundedRightBigIcon />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default React.memo(ProgressTabs);
