import { useEffect, useState } from "react";

import { chartIntervalCn } from "./const";

export interface IUseChartIntervalIntersectionProps {
  /** Реф внешнего элемента интервала; родитель этого элемента обязательно содержит все соседние отрезки */
  intervalRef: React.RefObject<HTMLDivElement>;
  /** Ретриггер проверки пересечения */
  recheck?: any;
}

export const useChartIntervalIntersection = ({ intervalRef, recheck }: IUseChartIntervalIntersectionProps) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!intervalRef.current) return;
    const intervalsBranch = intervalRef.current.parentElement as Element;
    const rootBounds = intervalRef.current.getBoundingClientRect();
    try {
      if (
        Array.from(intervalsBranch.querySelectorAll(`.${chartIntervalCn}`))?.some((el) => {
          if (!el || el === intervalRef.current) return false;
          const elBounds = el.getBoundingClientRect();
          return (
            /* [++++]
            [-----] */
            (rootBounds.left < elBounds.right && rootBounds.left > elBounds.left) ||
            /* [++++]
                 [-----] */
            (rootBounds.right < elBounds.right && rootBounds.right > elBounds.left) ||
            // включает полное совпадение отрезков плана и факта
            /* [+++++++++]
                 [-----] */
            (rootBounds.left <= elBounds.left && rootBounds.right >= elBounds.right) ||
            /*    [++]
                [------] */
            (rootBounds.left > elBounds.left && rootBounds.right < elBounds.right)
          );
        })
      ) {
        setIntersecting(true);
      } else {
        setIntersecting(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, [recheck, intervalRef.current]);
  return isIntersecting;
};
