import axios from "axios";
import { useEffect, useState } from "react";

import { IListResponseData } from "types/ListResponse";

interface IProps {
  stockId: string | number;
  productId: number;
  isOpen: boolean;
  invalidateKey: number;
  type?: "incoming" | "expense";
}

export interface IStockFile {
  name: string;
  file: string;
  id: number;
  exp_id: number;
  type: string;
}

type StockFilesResponse = IListResponseData<{
  building_files: IStockFile[];
  resource_files: IStockFile[];
}>;

export const useStockFiles = ({ stockId, productId, isOpen, invalidateKey, type = "incoming" }: IProps) => {
  const [data, setData] = useState<IStockFile[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [buildingFilesIds, setBuildingFilesIds] = useState<number[]>();
  const [resourceFilesIds, setResourceFilesIds] = useState<number[]>();

  useEffect(() => {
    if (stockId && productId && isOpen) {
      !data && setIsLoading(true);
      const url =
        type === "incoming"
          ? `/stocks/${stockId}/replenishments/${productId}/files/`
          : `/stocks/productusings/${productId}/files/`;
      axios
        .get<StockFilesResponse>(url)
        .then(({ data }) => {
          setResourceFilesIds(
            data.results?.reduce<number[]>((acc, curr) => {
              return acc.concat(curr.resource_files?.map((el) => el.id));
            }, [])
          );
          setBuildingFilesIds(
            data.results?.reduce<number[]>((acc, curr) => {
              return acc.concat(curr.building_files?.map((el) => el.id));
            }, [])
          );
          setData(
            data.results.reduce<IStockFile[]>((acc, cur) => {
              return acc.concat(cur.building_files.concat(cur.resource_files));
            }, [])
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [productId, isOpen, stockId, invalidateKey, type]);

  return { isLoading, data, buildingFilesIds, resourceFilesIds };
};
