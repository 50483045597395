import { IActions } from "../../../../../app/store/store";

import { IPostDetail, IPostsListResult } from "../../../../../types/interfaces/Posts";

export const postsActions = {
  setPostsList: (posts: IPostsListResult[]) =>
    ({
      type: "posts/SET_POSTS_LIST",
      payload: posts,
    } as const),
  updatePostInList: (postId: number, data: Partial<IPostsListResult>) =>
    ({
      type: "posts/UPDATE_POST_IN_LIST",
      payload: { postId, data },
    } as const),
  addNewPostToList: (newPost: IPostsListResult) =>
    ({
      type: "posts/ADD_NEW_POST_TO_LIST",
      payload: newPost,
    } as const),
  removePostFromList: (postId: number) =>
    ({
      type: "posts/REMOVE_POST_FROM_LIST",
      payload: postId,
    } as const),
  setPostsListLoading: (isLoading: boolean) =>
    ({
      type: "posts/SET_POSTS_LIST_LOADING",
      payload: isLoading,
    } as const),
  setPostInModal: (post: IPostDetail) =>
    ({
      type: "posts/SET_POST_IN_MODAL",
      payload: post,
    } as const),
  setPostInModalLoading: (isLoading: boolean) =>
    ({
      type: "posts/SET_POST_IN_MODAL_LOADING",
      payload: isLoading,
    } as const),
  setPostsDetailedList: (list: IPostDetail[]) =>
    ({
      type: "posts/SET_POSTS_DETAILED_LIST",
      payload: list,
    } as const),
  setPostsDetailedListLoading: (isLoading: boolean) =>
    ({
      type: "posts/SET_POSTS_DETAILED_LIST_LOADING",
      payload: isLoading,
    } as const),

  clearPostInModal: () => ({ type: "posts/CLEAR_POST_IN_MODAL", payload: {} } as const),
  clearData: () => ({ type: "posts/CLEAR_DATA", payload: {} } as const),
};

export type IPostAction = IActions<typeof postsActions>;
