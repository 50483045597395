import React, { useState } from "react";

import { IAttachmentInExpenditure } from "redux/modules/common/passportsAndCertificates/types";

import PassportsRow from "./PassportsRow";
import PassportsTableHead from "./PassportsTableHead";
import FileViewer from "widgets/FileViewer/FileViewer";

interface IProps {
  docs?: IAttachmentInExpenditure[];
  expenditureId?: number;
  onDirectlyDelete?: (docId: number) => void;
  canDelete?: boolean;
  isFileViewer?: boolean;
}

const PassportsTable: React.FC<IProps> = ({
  docs,
  onDirectlyDelete,
  expenditureId,
  canDelete = true,
  isFileViewer = true,
}) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  if (!docs?.length) return null;

  return (
    <>
      <PassportsTableHead />
      {docs?.map((el, i) => (
        <PassportsRow
          key={el.id}
          expenditureId={expenditureId}
          onDirectlyDelete={onDirectlyDelete}
          document={el}
          canDelete={canDelete}
          onDirectlyDownLoad={
            isFileViewer
              ? () => {
                  setActiveIndex(i);
                }
              : undefined
          }
        />
      ))}
      {isFileViewer && (
        <FileViewer
          isOpen={activeIndex > -1}
          onClose={() => setActiveIndex(-1)}
          files={docs as any}
          startIndex={activeIndex}
        />
      )}
    </>
  );
};

export default PassportsTable;
