import { Dispatch } from "redux";

import { signatorsApi } from "./api";
import { signatorsActions } from "./signatorsSlice";
import { RootState } from "app/store/rootReducer";

import { IBindSigner, ICreateNewSigner, SignerTypes } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const createSignator = (data: ICreateNewSigner) => (dispatch: Dispatch, getState: () => RootState) => {
  dispatch(signatorsActions.setIsLoading({ key: "createAll", status: true }));
  signatorsApi
    .create(data)
    .then(() => {
      dispatch(signatorsActions.invalidateKey());
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(signatorsActions.setIsLoading({ key: "createAll", status: false }));
    });
};

export const getAllSignators = () => (dispatch: Dispatch, getState: () => RootState) => {
  dispatch(signatorsActions.setIsLoading({ key: "loadAll", status: true }));
  signatorsApi
    .getAll()
    .then(({ data }) => {
      dispatch(signatorsActions.setAllSignators(data.results));
    })
    .finally(() => {
      dispatch(signatorsActions.setIsLoading({ key: "loadAll", status: false }));
    });
};

export const bindSigner = (buildingId: string, data: IBindSigner) => (dispatch: Dispatch) => {
  signatorsApi
    .bindSigner(buildingId, data)
    .then(() => {
      dispatch(signatorsActions.invalidateKey());
    })
    .catch(errorCatcher)
    .finally(() => {});
};

export const getSignersByBuilding = (buildingId: string) => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().signatorsSlice.signatorsByBuildings[buildingId]?.length) {
    dispatch(signatorsActions.setIsLoading({ key: buildingId, status: true }));
  }
  signatorsApi
    .get(buildingId)
    .then(({ data }) => {
      dispatch(signatorsActions.setSignatorsByBuilding({ key: buildingId, signators: data.results }));
    })
    .finally(() => {
      dispatch(signatorsActions.setIsLoading({ key: buildingId, status: false }));
      dispatch(signatorsActions.clearDeleted());
    });
};

export const unbindSigner =
  (buildingId: string, signerId: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ type: "unbindSigner", id: signerId });

    dispatch(signatorsActions.setIsLoading({ key, status: true }));
    signatorsApi
      .unbindSigner(buildingId, signerId)
      .then(() => {
        dispatch(signatorsActions.markAsDeleted(signerId));
        dispatch(signatorsActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(signatorsActions.setIsLoading({ key, status: false }));
      });
  };

export const getEmployeesForSigners = (buildingId: string) => (dispatch: Dispatch, getState: () => RootState) => {
  const key = generateStorageKey({ type: "loadEmployees", buildingId });

  dispatch(signatorsActions.setIsLoading({ key, status: true }));
  signatorsApi
    .getAllEmployees(buildingId)
    .then(({ data }) => {
      dispatch(signatorsActions.setEmployeesByBuilding({ employees: data, key: buildingId }));
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(signatorsActions.setIsLoading({ key, status: false }));
    });
};

export const _TEMP_assignSignerManually =
  (data: ICreateNewSigner, buildingId: string, signerType: IBindSigner["type"]) => (dispatch: Dispatch) => {
    const key = generateStorageKey({ type: "assignManually" });
    dispatch(signatorsActions.setIsLoading({ key, status: true }));

    signatorsApi
      .bindSigner(buildingId, { ...data, type: signerType } as any)
      .then(() => {
        dispatch(signatorsActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(signatorsActions.setIsLoading({ key, status: false }));
      });
    return;
  };

export const assignEmployeeAsSigner =
  (employeeId: number, objectId: string, signerType: SignerTypes) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ type: "assignManually" });

    dispatch(signatorsActions.setIsLoading({ key, status: true }));
    signatorsApi
      .bindSigner(objectId, { signer_id: employeeId, type: signerType })
      .then(() => {
        dispatch(signatorsActions.invalidateKey());
      })
      .finally(() => {
        dispatch(signatorsActions.setIsLoading({ key, status: false }));
      });

    return;
  };
