import cn from "classnames";
import React, { useRef, useState } from "react";

import ButtonBase from "../../../../../shared/ui/controls/ButtonBase";
import Select from "../../Select";

import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import useEscapeHandler from "../../../../../utils/hooks/useEscapeHandler";

import Conture from "../../../../../images/icons/Conture";

import styles from "./index.module.scss";

const SearchSelectFilter = ({ title, setFilter, filterName, last = false, defaultValue = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  useEscapeHandler(() => setIsOpen(false));

  const [value, setValue] = useState(defaultValue || "");
  const [filterUsed, setFilterUsed] = useState(false);
  const ref = useRef();

  const clearFilter = () => {
    setValue("");
    setFilter("");
    setFilterUsed(false);
    setIsOpen(false);
  };

  const handleSaveValue = () => {
    setFilter(value);
    setIsOpen(false);
    value ? setFilterUsed(true) : setFilterUsed(false);
  };

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div className={styles.container} ref={ref}>
      <div
        className={cn(styles.button, {
          [styles.active]: isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Conture />
        {(filterUsed || defaultValue) && <div className={styles.redMark}></div>}
      </div>
      {isOpen && (
        <div className={cn(styles.modal, { [styles.modalLast]: last })}>
          <div className={styles.title}>{title}</div>
          <Select options={filterName} onChange={(val) => setValue(val)} value={value} className={styles.select} />
          <div className={styles.actionBlock}>
            <ButtonBase secondary small onClick={clearFilter}>
              Очистить
            </ButtonBase>
            &nbsp;&nbsp;&nbsp;
            <ButtonBase primary small onClick={handleSaveValue}>
              Применить
            </ButtonBase>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchSelectFilter;
