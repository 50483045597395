import { createIntervalOriginType } from "components/pages/Chart/components/ChartInterval/withLinking";
import { IntervalType, SharedBraceStatusType } from "components/pages/Manufacturing/constants";

export interface IChartState {
  controller: AbortController;
  tab: CHART_TABS | null;
  hash: number;
  trees: Record<CHART_TABS, IChartTree>;
  projectSliceIndex: number;
  projectIds: number[];
  scrollState: {
    year: number;
    month: number;
    markers: Record<number, number[]>;
    touchedYears: number[];
  };
  // возможно, стоит размечать не по табу (работы / материалы / мим), а по типу отрезков (fact_intervals/works, fact_intervals/resources)
  loadedIntervalsByYears: Record<CHART_TABS, Partial<Record<number, Record<number, boolean>>>>;
  viewMode: CHART_VIEW_MODE;
  actions: ChartActionsType;
  treeState: {
    cachedOpen: Record<CHART_TABS, Record<string, boolean>>;
    expander: Partial<Record<CHART_TABS, Record<ChartTreeExpanderType, boolean | undefined>>>;
  };
  filters: IChartFilters;
  relations: {
    arrows: Partial<
      Record<
        CHART_TABS,
        Record<"fromIntervalsById" | "fromGroupsById", Record<number, (IPlanRelation | IPlanRelationCore)[]>>
      >
    >;
    hash: number;
    draggedArrow: IDraggedArrow | null;
    justAddedArrow: IPlanRelation | null;
  };
  checkpoinsMarks: ChartCheckpointsMarks;
  loaders: Record<CHART_LOADERS, boolean>;
}

export type CheckpointKeys = "yearWeeks" | "days";

export type ChartCheckpointsMarks = Record<CheckpointKeys, Record<string, number>>;

export enum CHART_LOADERS {
  BLURRED = "blurred",
  BLOCKING = "blocking",
}

export type IChartPersistValues = Partial<Record<keyof IChartState, boolean>>;
export type IChartStatePatch = Pick<IChartState, "filters" | "actions">;

export const enum CHART_TABS {
  WORK = "work",
  RESOURCES = "resources",
  MATERIALS = "materials",
  EQUIPMENT = "equipment",
  MIM = "mim",
}

export const enum CHART_VIEW_MODE {
  DAYS_30 = "days_30",
  DAYS_15 = "days_15",
  WEEKS = "weeks",
  MONTHS = "months",
}

export const enum INTERVAL_MAPPING_TYPES {
  FACT_WORKS = "fact_works",
  PLAN_WORKS = "plan_works",
  PLAN_SECTIONS = "plan_sections",
  PLAN_RESOURCES = "plan_resources",
  FACT_RESOURCES = "fact_resources",
}

export const enum ChartActions {
  /** Расшаривание – отображать статус "подтвержденные". Не отображается в UI */
  confirmed_highlight = "confirmed_highlight",
  /** Расшаривание – отображать статус "на проверке". Не отображается в UI */
  moderation_highlight = "moderation_highlight",
  /** Расшаривание – отображать статус "отклоненные". Не отображается в UI */
  canceled_highlight = "canceled_highlight",
  /** Создание связей */
  linking_enabled = "linking_enabled",
  /** Показывать замечания */
  remarks_visible = "remarks_visible",
  out_of_estimate_enabled = "out_of_estimate_enabled",
  /** Режим "работа со связями". XOR с plans_editing_enabled */
  linking_editing_enabled = "linking_editing_enabled",
  /** Режим "работа с планом". XOR с linking_editing_enabled */
  plans_editing_enabled = "plans_editing_enabled",
  /** Показывать уровень расценок в дереве графика. По умолчанию включено в производстве, отключено в управлении */
  show_expenditures_in_tree = "show_expenditures_in_tree",
  /** Показать все расценки = true Свернуть все расценки = false */
  default_tree_open_state = "default_tree_open_state",
}

export type ChartActionsType = Partial<Record<ChartActions, boolean>>;

export interface IChartTreeCountExpenditures {
  all: number;
  work: number;
  material: number;
  machine: number;
  equipment: number;
  transport: number;
  groups: number;
  sections: number;
  subsections: number;
}

export interface IChartTreeWorkAmountIndicators {
  amount_completed: string;
  amount_accepted: string;
  amount_to_pay: string;
}

export interface IChartTreeMaterialsAmountIndicators {
  amount_on_stock: string;
  amount_issued: string;
  amount_accepted: string;
  amount_to_pay: string;
}

export interface IChartTreeResourceAmountIndicators {
  amount_to_pay: string;
}

export interface IChartTreeWorkCountIndicators {
  count_completed: string;
  count_accepted: string;
  count_to_pay: string;
}

export interface IChartTreeMaterialsCountIndicators {
  count_on_stock: string;
  count_issued: string;
  count_accepted: string;
  count_to_pay: string;
}

export interface IChartTreeResourceCountIndicators {
  count_to_pay: string;
}

export interface IChartTreeWorkIndicators extends IChartTreeWorkAmountIndicators, IChartTreeWorkCountIndicators {}
export interface IChartTreeMaterialsIndicators
  extends IChartTreeMaterialsAmountIndicators,
    IChartTreeMaterialsCountIndicators {}
export interface IChartTreeResourceIndicators
  extends IChartTreeResourceAmountIndicators,
    IChartTreeResourceCountIndicators {}

export interface IChartTreeResponse {
  count_expenditures?: IChartTreeCountExpenditures;
  estimate_amount?: string;
  number?: string;
  plan_start?: string;
  plan_end?: string;
  plan_duration?: number;
  name?: string;
  measure?: string;
  count?: string;
  work_indicators?: IChartTreeWorkIndicators;
  material_indicators?: IChartTreeMaterialsIndicators;
  machine_indicators?: IChartTreeResourceIndicators;
  equipment_indicators?: IChartTreeResourceIndicators;
  transport_indicators?: IChartTreeResourceIndicators;
  work_amount_indicators?: IChartTreeWorkAmountIndicators;
  material_amount_indicators?: IChartTreeMaterialsAmountIndicators;
  machine_amount_indicators?: IChartTreeResourceAmountIndicators;
  equipment_amount_indicators?: IChartTreeResourceAmountIndicators;
  transport_amount_indicators?: IChartTreeResourceAmountIndicators;
}

export interface IChartTree extends IChartTreeResponse {
  /** intervals to display */
  processedIntervals?: IChartProcessedIntervals;
  bubleBounds: Record<INTERVAL_MAPPING_TYPES, Record<string, true>>;
  bubbledIntervals: IChartProcessedIntervals;
  id: number;
  /** unique key */
  _id: string;
  children: IChartTree[];
  parent: IChartTree;
  lvl: number;
  projectId: number;
  isExpenditure?: boolean;
  isGroup?: boolean;
  isSection?: boolean;
}

export interface IChartProcessedIntervals {
  [INTERVAL_MAPPING_TYPES.PLAN_SECTIONS]: IChartPlanSection[];
  [INTERVAL_MAPPING_TYPES.PLAN_WORKS]: IChartPlanWork[];
  [INTERVAL_MAPPING_TYPES.FACT_WORKS]: IChartFactWork[];
  [INTERVAL_MAPPING_TYPES.PLAN_RESOURCES]: IChartPlanResource[];
  [INTERVAL_MAPPING_TYPES.FACT_RESOURCES]: IChartFactResource[];
}

export type ChartProcessedInterval =
  | IChartPlanSection
  | IChartPlanWork
  | IChartFactWork
  | IChartPlanResource
  | IChartFactResource;

export interface IChartIntervalBase {
  /** Дата в формате YYYY-MM-DD */
  start: string;
  /** Дата в формате YYYY-MM-DD */
  end: string;
}

export interface IChartIntervalParents {
  /** ID раздела */
  cs_id?: number;
  /** ID родительского раздела */
  ps_id?: number;
}

export interface IChartIntervalWithParents extends IChartIntervalParents {
  /** ID плана / работы */
  id: number;
}

export interface IChartPlanSection extends IChartIntervalBase, IChartIntervalWithParents {}

export type ExpenditureOrGroupType = "group" | "expenditure";

export interface IChartSharableInterval {
  is_shared: boolean,
  is_from_provider: boolean;
  status: SharedBraceStatusType
}

export interface IChartExpenditurePlanWork extends IChartIntervalWithParents {
  /** ID расценки работы */
  exp_id: number;
}

export interface IChartGroupPlanWork extends IChartIntervalWithParents {
  /** ID группы */
  group_id: number;
}

export interface IChartPlanWork extends IChartIntervalBase {
  type: ExpenditureOrGroupType;
  expenditure?: IChartExpenditurePlanWork;
  group?: IChartGroupPlanWork;
  has_shifts?: boolean;
}

export interface IChartExpenditureFactWork extends IChartIntervalWithParents {
  /** ID расценки работы */
  exp_id: number;
  /** Единицы измерения работы */
  measure: string;
}

export interface IChartGroupFactWork extends IChartIntervalWithParents {
  /** ID группы */
  group_id: number;
  /** Единицы измерения работы */
  measure: string;
}

export type IChartFactWorkCompletionType = "completed" | "accepted" | "todo" | "to_pay"; // "confirmed"
export interface IChartFactWorkCompletion extends Record<IChartFactWorkCompletionType, string> {}
export interface IChartFactWork extends IChartIntervalBase, IChartFactWorkCompletion, IChartSharableInterval {
  type: ExpenditureOrGroupType;
  expenditure?: IChartExpenditureFactWork;
  group?: IChartGroupFactWork;
  /** Список ID замечаний приёмки в работе */
  ticket_remarks_in_work: number[];
  /** Список ID замечаний выполнения в работе */
  remarks_in_work: number[];
}

export interface IChartPlanResourcePlan {
  type: ExpenditureOrGroupType;
  id: number;
  group_id?: number;
  exp_id?: number;
}

export interface IChartPlanResource extends IChartIntervalParents {
  /** ID расценки ресурса */
  exp_id: number;
  /** ID группы ресурса */
  group_id?: number;

  /** Тип расценки ресурса */
  exp_type: IntervalType;
  /** Единицы измерения ресурса */
  measure: string;
  /** Дата в формате YYYY-MM-DD */

  date: string;
  plan?: IChartPlanResourcePlan;
}

export type IChartFactResourceCompletionType = "ordered" | "on_stock" | "issued" | "accepted" | "todo" | "to_pay";

export interface IChartFactResourceCompletion extends Record<IChartFactResourceCompletionType, string> {}

export interface IChartFactResource extends IChartIntervalParents, IChartFactResourceCompletion {
  /** ID расценки ресурса */
  exp_id: number;
  /** ID группы ресурса */
  group_id?: number;

  /** Тип расценки ресурса */
  exp_type: IntervalType;
  measure: string;
  /** Дата в формате YYYY-MM-DD */
  date: string;
}

export type ChartTreeMatcherType = (t: IChartTree) => boolean;

export type ChartTreeUpdaterType = (t: IChartTree) => IChartTree;

export const enum RELATION_TYPES {
  oh = "oh",
  ho = "ho",
  oo = "oo",
  hh = "hh",
}

export interface IPlanRelationCore {
  id?: number;
  related_type: RELATION_TYPES;
  from_interval: number | null;
  to_interval: number | null;
  from_group: number | null;
  to_group: number | null;
}

export interface IPlanRelation extends IPlanRelationCore {
  id: number;
  created_at: string;
  delay_day: number;
  from_expenditure_name: string;
  to_expenditure_name: string;
  from_group_name: string;
  to_group_name: string;
  from_section_name: string;
  to_section_name: string;
}

export interface IDraggedArrow {
  projectId: number;
  intervalId: number;
  startDate: string;
  endDate: string;
  origin: createIntervalOriginType;
  isGroup: boolean;
  relationType: RELATION_TYPES;
}

export interface IChartFilters {
  dateStart: string | null;
  dateEnd: string | null;
  searchText: string;
  skipBranchLvl?: Record<number, boolean> | undefined;
}

export type ChartFilterType = keyof IChartFilters;

export type ChartTreeExpanderType = "days" | "period";

export type ChartModalType = "expenditure" | "building" | "lsr" | "section";

export type TouchedYearsUpdateOptions = { dropMarkers?: boolean };
