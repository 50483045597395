import React from "react";
import { Link } from "react-router-dom";

import SelectMenu, { SelectedOption } from "shared/ui/controls/SelectMenu/SelectMenu";

import { IIdAndName } from "types/idAndName";

import styles from "./CustomBreadcrumbs.module.scss";

interface IProps {
  selectedOption?: SelectedOption;
  options: (IIdAndName & { link: string })[];
  disabled?: boolean;
}

const CustomBreadCrumbsMenu: React.FC<IProps> = ({ selectedOption, options, disabled }) => {
  return (
    <SelectMenu
      selectedOption={selectedOption}
      titleClassName={styles.breadcrumbsTitleSmall}
      selectClassName={styles.selectClassName}
      optionsBlockClassName={styles.optionsBlockClassName}
      lastEl
      disabled={disabled}
    >
      {!!options?.length &&
        options.map((option) => (
          <Link key={option.id} to={option.link}>
            <div className={styles.breadcrumbsItem} title={option.name}>
              {option.name}
            </div>
          </Link>
        ))}
    </SelectMenu>
  );
};

export default CustomBreadCrumbsMenu;
