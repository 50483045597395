import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

export const stateSelector = (state: RootState) => state.projectsV2;

export const projectsV2ListSelector = createSelector(stateSelector, (state) => state.projectsLists);
export const projectsV2CountsSelector = createSelector(stateSelector, (state) => state.projectsListsCounts);
export const projectsV2IsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const projectsV2DetailedSelector = createSelector(stateSelector, (state) => state.projectsDetailed);
