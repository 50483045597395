import React, { FC, memo } from "react";

import styles from "components/UI/_TODO/EstimateSection/EstimateSection.module.scss";

import Amount from "../../../shared/ui/dataDisplay/Amount/Amount";
import DoubleInput, { Measure } from "../../../shared/ui/inputs/DoubleInput/DoubleInput";

import { useMarkupDoubleInput } from "../hooks/useMarkupDoubleInput";

import { isNanChecker } from "../../../utils/formatters/isNanChecker";
import { parseTransformedNumber, transformDigitToFinancial } from "../../../utils/formatters/transformDigitToFinancial";
import { stopEventPropagation } from "../../../utils/helpers/stopEventPropagation";

import blackCheckIcon from "../../../images/icons/blackCheckIcon";
import blueCheckIcon from "../../../images/icons/blueCheckIcon";

interface IProps {
  amounts?: any;
  changeCost: (v: number) => void;
}

export const PricingModeMarkupIndicators: FC<IProps> = memo(({ amounts, changeCost }) => {
  const percent = +amounts.allSource === 0 ? 0 : (amounts.markupCost * 100) / amounts.allSource;

  const { changedCost, changedPercent, onChangePercent, onChangeCost } = useMarkupDoubleInput({
    percent: percent.toFixed(2),
    cost: amounts.markupCost,
    amountSource: amounts.allSource,
    changeCost,
  });

  const value = transformDigitToFinancial(
    isNanChecker(+amounts.allSource) + isNanChecker(+(parseTransformedNumber(changedCost) ?? 0)),
    {
      withFloat: true,
      dropZeros: true,
    }
  );

  return (
    <>
      <Amount
        titleClassName={styles.budgetTitle}
        title="По смете"
        value={amounts.allSource}
        measure={amounts.measure}
        isBold
        isLeft
      />
      <div className={styles.discount} onClick={stopEventPropagation}>
        <DoubleInput
          firstValue={changedPercent?.toString()}
          secondValue={changedCost?.toString()}
          firstInputSize={1}
          secondInputSize={2}
          onChangeFirstValue={onChangePercent}
          onChangeSecondValue={onChangeCost}
          firstValueMeasure={Measure.percent}
          secondValueMeasure={Measure.currency}
          fontWeight={500}
          generalLabel="Размер наценки"
          inputFontSize={+(parseTransformedNumber(changedCost) ?? 0) > 1_000_000_000 ? 0.875 : 1}
        />
        {+(parseTransformedNumber(changedCost) ?? 0) !== 0 ? (
          {
            ...blueCheckIcon,
            props: { ...blueCheckIcon.props, width: 18, height: 18 },
          }
        ) : (
          <span className={styles.withoutDiscountIcon} title="Наценка отсутствует">
            {{
              ...blackCheckIcon,
              props: { ...blackCheckIcon.props, width: 18, height: 18 },
            }}
          </span>
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.budgetCol}>
        <Amount
          titleClassName={styles.budgetTitle}
          title="С учетом наценки"
          value={value}
          measure={amounts.measure}
          isBold
          isLeft
        />
      </div>
    </>
  );
});
