export const daysInWeekCountOptions = [
  {
    id: 7,
    name: "7-и дневка",
  },
  {
    id: 5,
    name: "5-и дневка",
  },
];

export const weekDayNames = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

export const PLAN_FACT_VOLUME_ITEMS_CONSTRAINT = 5;

export const PLAN_FACT_VOLUME_ALL_EXPANDED = "PLAN_FACT_VOLUME_ALL_EXPANDED";
