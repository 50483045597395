import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { addFile, deleteFile } from "redux/modules/common/building/documents/contracts";

import BottomControls from "../../../../../components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import InputFiles from "../../../../../components/UI/atoms/_TODO/InputFiles";
import { FilesPermissionsType } from "../../../../../components/UI/atoms/_TODO/InputFiles/InputFiles";
import { LABELS } from "../../../../../components/pages/Requisition/constants";

import ConfirmationModal from "../../../../../entities/ConfirmationModal/ConfirmModal";
import ButtonBase from "../../../../../shared/ui/controls/ButtonBase";
import SliderModal from "../../../../../shared/ui/modal/SliderModal/SliderModal";
import moment from "moment/moment";

import { LOCALIZATION_CONFIG } from "../../../../../constants/localization";
import { IContract } from "../../../../../types/interfaces/Contract";

import { useConfirmationModal } from "../../../../../hooks/useConfirmationModal";
import { useProductFilesModal } from "../../../../../hooks/useProductFilesModal";

import { ReactComponent as CalendarBlue } from "../../../../../images/icons/calendarBlue.svg";

import styles from "./ContractsModal.module.scss";

export interface IContractsModalProps {
  contract: IContract | {};
  isOpen: boolean;
  onClose: () => void;
  onDeleteContract: () => void;
  onAddFile: () => void;
  onDeleteFile: () => void;
  filesPermissions: FilesPermissionsType;
  canDeleteContract: boolean;
  files: any;
}

const ContractsModal: React.FC<IContractsModalProps> = ({
  contract,
  isOpen,
  onClose,
  onDeleteContract,
  canDeleteContract,
  files: productFiles,
  onAddFile,
  onDeleteFile,
  filesPermissions,
}) => {
  const dispatch = useDispatch();
  const handleDeleteContract = useCallback(() => {
    onDeleteContract();
    onClose();
  }, [onDeleteContract, onClose]);

  const { openRemoveConfirmationModal, isOpenRemoveConfirmationModal, confirmRemove, closeRemoveConfirmationModal } =
    useConfirmationModal({ onRemove: handleDeleteContract });

  const { files, preparedFiles, downloadFiles, handleFilesChange, onUpload } = useProductFilesModal({
    productFiles,
    isOpen,
    uploadFilesCallback: onAddFile,
    deleteFilesCallback: onDeleteFile,
  });

  const onUploadFile = (file: File) => {
    /* @ts-ignore */
    dispatch(addFile(contract.id, file));
  };

  const onDelete = (id: number) => {
    /* @ts-ignore */
    dispatch(deleteFile(contract.id, id));
  };

  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={onClose}>
        <div className={styles.headline}>
          <h2 className={styles.title}>
            {/* @ts-ignore */}
            {contract.title || (!!contract.contract_num && `Контракт №${contract.contract_num}`)}
          </h2>
          {/* @ts-ignore */}
          {!!contract.title && !!contract.contract_num && (
            /* @ts-ignore */
            <p className={styles.subtitle}>{`контракт №${contract.contract_num}`}</p>
          )}
        </div>
        <div className={styles.content}>
          {/* @ts-ignore */}
          {!!contract.building_name && (
            <div className={styles.info}>
              <span className={styles.medium}>Проект</span>
              {/* @ts-ignore */}
              <p className={styles.text}>{contract.building_name}</p>
            </div>
          )}
          {/* @ts-ignore */}
          {!!contract.entity_name && (
            <div className={styles.info}>
              <span className={styles.medium}>Контрагент</span>
              {/* @ts-ignore */}
              <p className={styles.text}>{contract.entity_name}</p>
            </div>
          )}
          {/* @ts-ignore */}
          {!!contract.contract_date && (
            <div className={styles.info}>
              <span className={styles.medium}>Дата</span>
              <p className={styles.text}>
                {/* @ts-ignore */}
                <span>{moment(contract.contract_date).format("DD/MM/YYYY")}</span>
                <CalendarBlue className={styles.calendar} />
              </p>
            </div>
          )}
          {/* @ts-ignore */}
          {!!contract.amount && (
            <div className={styles.info}>
              <span className={styles.medium}>Стоимость</span>
              {/* @ts-ignore */}
              <p className={styles.text}>{`${contract.amount} ${LOCALIZATION_CONFIG.currency}`}</p>
            </div>
          )}
          <div className={styles.files}>
            <div className={styles.filesHeader}>
              <span>
                {LABELS.FILES_INPUT.TITLE} {files?.length}
              </span>
              {!!files?.length && <button onClick={downloadFiles}>{LABELS.FILES_INPUT.DOWNLOAD}</button>}
            </div>
            <InputFiles
              isFileViewer
              value={preparedFiles as any}
              permissions={filesPermissions}
              uploadFilesDirectly={onUploadFile}
              removeFileDirectly={onDelete}
            />
          </div>
        </div>
      </SliderModal>
      <ConfirmationModal
        variant="secondary"
        isOpen={isOpenRemoveConfirmationModal}
        action={confirmRemove}
        onClose={closeRemoveConfirmationModal}
      >
        Подтвердить удаление?
      </ConfirmationModal>
    </>
  );
};

export default React.memo(ContractsModal);
