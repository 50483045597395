import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { isTasksLoadingSelector, tasksbyBuildingSelector } from "components/pages/Tasks/model/selectors";
import { loadMoreTasksByBuilding, loadTasksByBuilding } from "components/pages/Tasks/model/thunks";
import { tasksListFilterType } from "components/pages/Tasks/model/types";

import { useTypedSelector } from "app/store/typedUseSelector";

import { TASK_LISTS_TYPES } from "../constants";

type propsType = {
  objectId?: string;
  type?: TASK_LISTS_TYPES;
  filters: tasksListFilterType;
};

export const useTasksData = ({ objectId, type, filters }: propsType) => {
  const dispatch = useDispatch();
  const tasksData = useTypedSelector(tasksbyBuildingSelector);
  const isTasksLoading = useTypedSelector(isTasksLoadingSelector);
  const { invalidateKey } = useTypedSelector((state) => state.tasks);
  const invalidationKey = useTypedSelector((state) => state.tasksV2Reducer.invalidationKey);

  useEffect(() => {
    if (!objectId || !type || objectId === "0") return;
    dispatch(loadTasksByBuilding(filters, type, objectId));
  }, [objectId, type, filters, invalidateKey, invalidationKey]);

  const tasksByBuilding = useMemo(() => {
    if (!objectId || !type || objectId === "0") return null;
    return tasksData?.[objectId]?.[type] || null;
  }, [tasksData, objectId, type]);

  const loadMoreTasksHandler = useCallback(() => {
    dispatch(loadMoreTasksByBuilding(filters, type!, objectId!, tasksByBuilding?.results?.length ?? 0));
  }, [tasksByBuilding?.results, filters, type, objectId]);

  return {
    tasksByBuilding,
    isTasksLoading,
    loadMoreTasksHandler,
  };
};
