import cn from "classnames";
import { ChangeEvent, FC } from "react";

import { LabelPlacement } from "../../../../../../shared/ui/inputs/Checkbox/Checkbox";
import InputBase, { VALUE_TYPES } from "../../../../../../shared/ui/inputs/InputBase";
import { Switch } from "../../../../../../shared/ui/inputs/Switch/Switch";
import Select, { COLORS, SIZES } from "../../../../atoms/Select";
import PopoverOverlay from "../../../PopoverOverlay/PopoverOverlay";
import { transformOptions } from "../../lib/transformOptions";
import { MEASURES } from "entities/MeasureSelect/MeasureSelect";

import { LOCALIZATION_CONFIG } from "../../../../../../constants/localization";
import { IGroupInfo } from "../../types";

import { transformDigitToFinancial } from "../../../../../../utils/formatters/transformDigitToFinancial";

import { ReactComponent as IconInfo } from "shared/assets/icons/IconInfo.svg";

import styles from "./GroupInfo.module.scss";

interface IProps {
  isEdit: boolean;
  isMarketPrice: boolean;
  expendituresEstimateAmount: number | string;
  groupEstimatePrice: number;
  setIsMarketPrice: (v: boolean) => void;
  count: string;
  measure: string;
  price: string;
  amount: string;
  onMeasureChange: (measureId: string) => void;
  onAmountChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onCountChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPriceChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const GroupInfo: FC<IProps> = ({
  isEdit,
  isMarketPrice,
  expendituresEstimateAmount,
  groupEstimatePrice,
  setIsMarketPrice,
  onMeasureChange,
  count,
  price,
  amount,
  measure,
  onAmountChange,
  onCountChange,
  onPriceChange,
}) => {
  return (
    <div className={styles.mainInfoContainer}>
      <div className={styles.mainInfo}>
        <div className={cn({ [styles.mainInfoItem]: true, [styles.isEditing]: isEdit })}>
          <div className={styles.mainInfoItemTitle}>Кол-во:</div>
          {isEdit ? (
            <InputBase
              className={styles.input}
              classNameInput={styles.inputInner}
              onChange={onCountChange}
              value={count}
              valueType={VALUE_TYPES.NUMBER}
              numberTransformOptions={transformOptions}
            />
          ) : (
            <span className={styles.description}>{count}</span>
          )}
        </div>
        <div className={cn({ [styles.mainInfoItem]: true, [styles.isEditing]: isEdit })}>
          <div className={styles.mainInfoItemTitle}>Ед. изм:</div>
          {isEdit ? (
            <Select
              containerClassName={styles.selectContainer}
              selectClassName={styles.select}
              options={MEASURES} /* @ts-ignore */
              onChange={onMeasureChange}
              value={measure}
              color={COLORS.BLUE}
              size={SIZES.SMALL}
            />
          ) : (
            <span className={styles.description}>{measure}</span>
          )}
        </div>
        <div className={cn(styles.mainInfoItem, styles.large, { [styles.isEditing]: isEdit })}>
          <div className={styles.mainInfoItemTitle}>
            Цена за ед:
            <PopoverOverlay
              openType="hover"
              placement="top"
              popoverBorderColor="primary"
              popoverClassName={styles.popoverTooltip}
              portalClassName={styles.popoverTooltip}
              className={styles.popoverWrapper}
              content={
                <div className={styles.infoPopover}>
                  <span className={styles.title}>Цена за ед. по смете</span>
                  <span className={styles.value}>
                    {transformDigitToFinancial(isFinite(groupEstimatePrice) ? groupEstimatePrice : 0, {
                      withFloat: true,
                      dropZeros: true,
                      withCurrencySign: true,
                    })}
                  </span>
                </div>
              }
            >
              <span className={styles.info}>
                <IconInfo />
              </span>
            </PopoverOverlay>
          </div>
          {isEdit ? (
            <InputBase
              className={styles.input}
              classNameInput={styles.inputInner}
              onChange={onPriceChange}
              value={price}
              valueType={VALUE_TYPES.NUMBER}
              numberTransformOptions={transformOptions}
              icon={<span className={styles.currency}>{LOCALIZATION_CONFIG.currency}</span>}
            />
          ) : (
            <span className={styles.description}>
              {transformDigitToFinancial(price, {
                withFloat: true,
                dropZeros: true,
                withCurrencySign: true,
              })}
            </span>
          )}
        </div>
        <div className={cn(styles.mainInfoItem, styles.large, { [styles.isEditing]: isEdit })}>
          <div className={styles.mainInfoItemTitle}>
            Итоговая сумма:
            <PopoverOverlay
              openType="hover"
              placement="top"
              popoverBorderColor="primary"
              popoverClassName={styles.popoverTooltip}
              portalClassName={styles.popoverTooltip}
              className={styles.popoverWrapper}
              content={
                <div className={styles.infoPopover}>
                  <span className={styles.title}>Сумма по смете</span>
                  <span className={styles.value}>
                    {transformDigitToFinancial(expendituresEstimateAmount, {
                      withFloat: true,
                      dropZeros: true,
                      withCurrencySign: true,
                    })}
                  </span>
                </div>
              }
            >
              <span className={styles.info}>
                <IconInfo />
              </span>
            </PopoverOverlay>
          </div>
          {isEdit ? (
            <InputBase
              className={styles.input}
              classNameInput={styles.inputInner}
              onChange={onAmountChange}
              value={amount}
              valueType={VALUE_TYPES.NUMBER}
              numberTransformOptions={transformOptions}
              icon={<span className={styles.currency}>{LOCALIZATION_CONFIG.currency}</span>}
            />
          ) : (
            <span className={styles.description}>
              {transformDigitToFinancial(amount, {
                withFloat: true,
                dropZeros: true,
                withCurrencySign: true,
              })}
            </span>
          )}
        </div>
      </div>

      <div className={styles.switcherContainer}>
        <Switch
          onChange={(v) => setIsMarketPrice(v.target.checked)}
          checked={isMarketPrice}
          label="Рыночная стоимость"
          labelPlacement={LabelPlacement.RIGHT}
          className={styles.switcher}
        />
      </div>
    </div>
  );
};
