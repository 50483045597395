import cn from "classnames";
import React from "react";
import { Helmet } from "react-helmet";

import Footer from "../Footer/Footer";
import MenuSkeleton from "../Menu/MenuSkeleton";

import { OSLA_CONTACT_PHONE_NUMBER, PHONE_CLEAN_PATTERN } from "../../constants";

import commonStyles from "../../HomePage.module.scss";
import styles from "./ThanksPage.module.scss";

const ThanksPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>OSLA – Спасибо</title>
      </Helmet>
      <MenuSkeleton />
      <section className={cn(commonStyles.container, styles.thanks)}>
        <h1>БЛАГОДАРИМ ВАС ЗА ПРОЯВЛЕННЫЙ ИНТЕРЕС</h1>
        <h2>В ближайшее время Вам позвонит наш менеджер</h2>
        <h2>
          Если у вас срочный вопрос, пожалуйста, звоните <br /> нам по телефону{" "}
          <a
            href={`tel:${OSLA_CONTACT_PHONE_NUMBER?.replaceAll(PHONE_CLEAN_PATTERN, "")}`}
            className={styles.phoneHighlight}
          >
            {OSLA_CONTACT_PHONE_NUMBER}
          </a>
        </h2>
        <h2>
          С уважением, <br />
          Команда OSLA
        </h2>
      </section>
      <Footer withSocialLinks withoutLogo />
    </>
  );
};

export default ThanksPage;
