import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";

import { userSelector } from "redux/modules/_TODO/auth";

import { updateTask } from "components/pages/Tasks/model/thunks";
import { IupdateTask, TaskPlacementType } from "components/pages/Tasks/model/types";

import { useGetTask } from "./useGetTask";
import { useTypedSelector } from "app/store/typedUseSelector";

import { TASK_LISTS_TYPES, TASK_STATUSES } from "../constants";
import { IIdAndName } from "types/idAndName";

import { compareUpdatingTask } from "../utils";

export interface IuseUpdateTaskProps {
  taskId: number;
  placementType: TaskPlacementType;
  listType?: TASK_LISTS_TYPES;
  list_id?: number;
  onSuccess: () => void;
}

export const useUpdateTask = ({ taskId, placementType, listType, list_id, onSuccess }: IuseUpdateTaskProps) => {
  const dispatch = useDispatch();
  const { id: currentUserId } = useTypedSelector(userSelector);

  const { certainTask: task } = useGetTask(taskId);

  const certainTask = useMemo(() => {
    if (!task) return null;
    return {
      ...task,
      remember_task: task.remember_task?.map((el) => {
        if (el) {
          return el;
        } else {
          return "None";
        }
      }),
      repeat_task: task.repeat_task ?? "None",
    };
  }, [task]);

  const isTaskStatusEditable =
    certainTask &&
    certainTask.status !== TASK_STATUSES.closed.toString() &&
    (certainTask.viewers.includes(currentUserId) ||
      certainTask.executor === currentUserId ||
      certainTask.creator === currentUserId);
  /* @ts-ignore */
  const [building, setBuilding] = useState<IIdAndName>(certainTask?.building_obj);
  const [section_id, setSectionId] = useState<number | undefined>(certainTask?.section);
  const [executor, setExecutor] = useState<string>(`,${certainTask?.executor},`);
  const [viewers, setViewers] = useState<string>(`,${certainTask?.viewers?.join(",")},`);

  const updateTaskStatusDirectly = useCallback(
    (newStatus: keyof typeof TASK_STATUSES) => {
      compose(
        dispatch,
        updateTask
      )({
        id: taskId /* @ts-ignore */,
        data: {
          ...certainTask,
          status: newStatus,
        },
        variant: placementType /* @ts-ignore */,
        building_id: certainTask.building,
        type: listType /* @ts-ignore */,
        list_id: certainTask.lists,
      });
    },
    [certainTask, taskId, listType, placementType]
  );

  const onSubmit = (values: IupdateTask) => {
    const {
      name,
      description,
      priority,
      deadline,
      executor: newExecutor,
      viewers: newViewers,
      remember_task,
      repeat_task,
      status,
      section,
    } = values;

    if (compareUpdatingTask(certainTask as any, values)) {
      return;
    }
    const data: any = {
      id: taskId,
      name,
      description: description ?? "",
      priority,
      status,
      deadline: moment(deadline).toISOString() /* @ts-ignore */,
      building: certainTask.building,
      section: section /* @ts-ignore */,
      executor:
        typeof newExecutor === "string" /* @ts-ignore */
          ? newExecutor.split(",").filter((x) => x.length)[0]
          : newExecutor /* @ts-ignore */,
      viewers: typeof newViewers === "string" ? newViewers.split(",").filter((x) => x.length) : newViewers || [],
      lists: list_id /* @ts-ignore */,
      creator: certainTask.creator,
    };
    if (remember_task) data["remember_task"] = [remember_task.toString()];
    if (remember_task?.[0] === null || remember_task === null) {
      data["remember_task"] = ["None"];
    }
    if (repeat_task) data["repeat_task"] = repeat_task.toString();
    if (repeat_task === null) {
      data["repeat_task"] = null;
    }
    dispatch(
      updateTask(
        {
          id: taskId,
          data,
          variant: placementType,
          building_id: certainTask?.building,
          type: listType,
          list_id: data.lists,
        },
        onSuccess
      )
    );
  };
  const onBuildingChange = useCallback((building: IIdAndName) => {
    setBuilding(building);
    setExecutor("");
    setViewers("");
  }, []);
  return {
    onSubmit,
    building,
    setBuilding: onBuildingChange,
    section_id,
    setSectionId,
    executor,
    setExecutor,
    viewers,
    setViewers,
    certainTask,
    updateTaskStatusDirectly,
    isTaskStatusEditable,
  };
};
