import moment from "moment";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ksListApi } from "redux/modules/common/building/documents/ksList/api";
import { IKs3InList } from "redux/modules/common/building/documents/ksList/types";

import UploadKsModal from "../UploadKsModal/UploadKsModal";
import KsListContent from "./KsListContent";
import KsStatus from "./KsStatus/KsStatus";
import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import { NavLinkIcon } from "shared/ui/controls/NavLinkIcon/NavLinkIcon";
import DateCell from "shared/ui/dataDisplay/DateCell/DateCell";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { splitThousands } from "utils/formatters/splitThousands";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import download from "images/download.svg";
import OpenLink from "images/icons/OpenLink";
import StatusCanceledIcon from "images/icons/StatusCanceledIcon";

import styles from "./KsListRow.module.scss";

interface IProps {
  act: IKs3InList;
}

const KsListRow: React.FC<IProps> = ({ act }) => {
  const [isModal, setIsModal] = React.useState(false);

  const downloadHandler = React.useCallback(() => {
    const fileName = `Акты выполненных работ за ${moment(act.start_at).format("DD.MM.YYYY")}-${moment(
      act.end_at
    ).format("DD.MM.YYYY")}.zip`;
    ksListApi.downloadFullKsFiles(act.id, fileName);
  }, [act]);

  const switchModal = React.useCallback(() => {
    setIsModal((prev) => !prev);
  }, []);

  const history = useHistory();
  const location = useLocation();

  const clickHandler = React.useCallback(() => {
    history.push(`${location.pathname}?ks3Id=${act.id}`);
  }, []);

  return (
    <>
      <TableReusableRow
        isExpandable
        isEarCounter
        earCount={act?.ks2_acts_count}
        innerContent={<KsListContent ks3Id={act.id} />}
        isHoverable={false}
        className={styles.row}
        onClick={clickHandler}
      >
        <TableReusableCell>
          <DateCell date={`${moment(act.start_at).format("DD/MM/YY")} - ${moment(act.end_at).format("DD/MM/YY")}`} />
        </TableReusableCell>
        <TableReusableCell className={styles.ks}>
          <NavLinkIcon to="#" title={`КС-3 № ${act.title}`} className={styles.link} />
        </TableReusableCell>
        <TableReusableCell>
          <KsStatus status={act.status} />
        </TableReusableCell>
        <TableReusableCell onClick={stopEventPropagation}>
          <FilesClipButton count={act.status === "todo" ? "0" : "1"} onClick={switchModal} />
        </TableReusableCell>
        <TableReusableCell>
          {/* <StatusCanceledIcon /> */}
          <div className={styles.remark}>{/* {act.} */}</div>
        </TableReusableCell>
        <TableReusableCell>
          <div className={styles.financial}>{splitThousands(act.amount, false, false)}</div>
        </TableReusableCell>
        <TableReusableCell onClick={stopEventPropagation}>
          <img src={download} className={styles.download} onClick={downloadHandler} />
        </TableReusableCell>
      </TableReusableRow>
      <UploadKsModal isOpen={isModal} onClose={switchModal} ks3Id={act.id} />
    </>
  );
};

export default React.memo(KsListRow);
