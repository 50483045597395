import cn from "classnames";
import moment from "moment";
import React, { useEffect, useMemo } from "react";

import ButtonArrow from "../../../controls/ButtonArrow/ButtonArrow";
import { useMonthsSlider } from "./useMonthsSlider";
import { useMonthsSliderWidth } from "./useMonthsSliderWidth";
import Draggable, { DraggableEventHandler } from "react-draggable";

import { getInitialXPositionInMonthSlider, getMonthIndexOnDragStop } from "./utils";

import Stick from "../../../../../images/icons/Stick";

import styles from "./MonthsSlider.module.scss";

interface IProps {
  date: moment.Moment;
  changeDate: (year: number, month: number) => void;
  selectingMonthsCount: number;
  onDirectlyClickLeftArrow?: () => void;
  onDirectlyClickRightArrow?: () => void;
  monthsCount?: number;
  disabled?: boolean;
}

function MonthsSlider({
  date,
  changeDate,
  selectingMonthsCount,
  onDirectlyClickLeftArrow,
  onDirectlyClickRightArrow,
  monthsCount = 12,
  disabled,
}: IProps) {
  const monthSliderWidth = useMonthsSliderWidth();
  const year = date.year();
  const monthIndex = date.month();
  const [arrayIndex, setArrayIndex] = React.useState(monthIndex);

  useEffect(() => {
    setArrayIndex(monthIndex);
  }, [monthIndex]);

  const { decreaseMonth, increaseMonth, monthsArray } = useMonthsSlider({
    year,
    monthIndex,
    changeDate,
    selectingMonthsCount,
    onDirectlyClickLeftArrow,
    onDirectlyClickRightArrow,
    monthsCount,
    arrayIndex,
  });

  const [x, setX] = React.useState(getInitialXPositionInMonthSlider(monthSliderWidth, monthIndex, monthsCount));

  const onStopDrag: DraggableEventHandler = (_, { x }) => {
    const { realIndex, arrayIndex } = getMonthIndexOnDragStop(
      x,
      monthsCount,
      monthSliderWidth,
      monthsArray,
      selectingMonthsCount
    );
    setX(monthSliderWidth * arrayIndex);
    changeDate(year, realIndex);
    setArrayIndex(arrayIndex);
  };

  React.useEffect(() => {
    const arrMonthIndex = selectingMonthsCount > 1 ? arrayIndex : monthsArray.findIndex((el) => el.id === monthIndex);
    setX(monthSliderWidth * arrMonthIndex);
    if (selectingMonthsCount === 1) {
      setArrayIndex(monthIndex);
    }
  }, [monthSliderWidth, monthIndex, selectingMonthsCount, monthsCount, arrayIndex, monthsArray]);

  const isWithBorder = useMemo(() => {
    return (
      selectingMonthsCount > monthsCount ||
      (monthsArray?.[0]?.id === 0 && monthIndex + selectingMonthsCount > monthsCount)
    );
  }, [selectingMonthsCount, monthsCount, arrayIndex]);

  return (
    <div className={styles.container}>
      <ButtonArrow direction="left" onClick={decreaseMonth} disabled={disabled} />
      <div className={cn(styles.monthSlider, { [styles.withBorder]: isWithBorder }, "monthSlider")}>
        <Draggable axis="x" bounds="parent" position={{ x, y: 0 }} onStop={onStopDrag} disabled={disabled}>
          <div
            className={styles.draggable}
            aria-disabled={disabled}
            style={{ width: `${monthSliderWidth * selectingMonthsCount}px` }}
            data-testid={`draggable_${x / monthSliderWidth}_${selectingMonthsCount}`}
          >
            <span className={styles.left}>
              <div className={styles.stick}>
                <Stick />
              </div>
            </span>
            <span className={styles.right}>
              <div className={styles.stick}>
                <Stick />
              </div>
            </span>
          </div>
        </Draggable>
        <div className={styles.inner}>
          {monthsArray.map((month, i) => (
            <div
              key={month.id + i + Math.random()}
              style={{ width: `${monthSliderWidth}px` }}
              className={styles.month}
              data-testid={`month_${month.id}_${i}`}
            >
              {month.label}
            </div>
          ))}
        </div>
      </div>
      <ButtonArrow direction="right" onClick={increaseMonth} disabled={disabled} />
    </div>
  );
}

export default React.memo(MonthsSlider);
