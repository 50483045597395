import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getNotificationsToModify,
  notificationsToModifySelector,
  unsubscribeNotification,
} from "../../../../../../redux/modules/_TODO/notifications";

import { ChangeNotificationConfigFnType, INotificationsToModify } from "../types/types";
import { VIEW_CONSTRUCTING_SECTION } from "constants/permissions/constructingPermissions";
import { VIEW_MANUFACTURING_SECTION } from "constants/permissions/manufacturingPermissions";
import { VIEW_PURCHASES_SECTION } from "constants/permissions/purchasesPermissions";

import usePermission from "hooks/usePermission";

export const useManageNotifications = () => {
  const dispatch = useDispatch();

  const canEditNotifications = true;
  const notificationsToModify: INotificationsToModify = useSelector(notificationsToModifySelector);

  const [isManageNotificationsModalOpen, setIsManageNotificationsModalOpen] = useState(false);
  const openManageNotificationsModal = useCallback(() => setIsManageNotificationsModalOpen(true), []);
  const closeManageNotificationsModal = useCallback(() => setIsManageNotificationsModalOpen(false), []);

  useEffect(() => {
    if (!isManageNotificationsModalOpen) return;
    dispatch(getNotificationsToModify());
  }, [isManageNotificationsModalOpen]);

  const onChangeCheckedNotifications = useCallback<ChangeNotificationConfigFnType>(
    async (option, type, isChecked) => {
      if (option?.class_name === "NotificationChatMention") {
        const chatV2MentionOption = Object.assign({}, option);

        chatV2MentionOption.class_name = "NotificationChatMentionV2";

        await dispatch(unsubscribeNotification(notificationsToModify, chatV2MentionOption, type, isChecked));
      }
      dispatch(unsubscribeNotification(notificationsToModify, option, type, isChecked));
    },
    [notificationsToModify]
  );

  const purchasePermission = usePermission(VIEW_PURCHASES_SECTION);
  const manufacturingPermission = usePermission(VIEW_MANUFACTURING_SECTION);
  const constructionPermission = usePermission(VIEW_CONSTRUCTING_SECTION);

  //TODO выпилить этот костыль после реализации фильтра по пермишенам на бэке
  const filteredByPermissions = useMemo<INotificationsToModify>(() => {
    if (purchasePermission || constructionPermission || manufacturingPermission) {
      return notificationsToModify;
    }
    return {
      email: (notificationsToModify?.email ?? []).filter((el) => el.group === "tasks_track"),
      web: (notificationsToModify?.web ?? []).filter((el) => el.group === "tasks_track"),
    };
  }, [purchasePermission, constructionPermission, manufacturingPermission, notificationsToModify]);

  return {
    canEditNotifications,
    notificationsToModify: filteredByPermissions,
    isManageNotificationsModalOpen,
    openManageNotificationsModal,
    closeManageNotificationsModal,
    onChangeCheckedNotifications,
  };
};
