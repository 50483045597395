import { message } from "antd";
import React, { useState } from "react";

const checkIfWithoutChain = (employees) => employees?.filter((employee) => !!employee.position)?.length < 2;

export const useProjectApprovals = (urlId, isNew, projectDetail, projectEmployees) => {
  const [approvalsState, setApprovalsState] = React.useState({
    requisitionApprovalEmployees: [],
    orderApprovalEmployees: [],
    paymentApprovalEmployees: [],
    timesheetApprovalEmployees: [],
    planWorkConfirmEmployees: [],
    planSectionConfirmEmployees: [],
    ticketApprovalEmployees: [],
    sharingfactchainapprovalemployees_set: [],
    shared_remark_responsible_employees: [],
    allow_shift: false,
    new_close_dates: false,
  });

  const [isDirty, setIsDirty] = React.useState(false);

  React.useEffect(() => {
    if (isNew || !projectDetail || !urlId) return;
    const mapProjectEmployee = (employee) => {
      const projectEmployee = projectEmployees?.find((projectEmployee) => projectEmployee.id === employee.user);
      if (!projectEmployee) return employee;
      return { ...projectEmployee, ...employee, user: projectEmployee.id };
    };
    setApprovalsState({
      requisitionApprovalEmployees: projectDetail?.requisition_approval_employees
        ?.map((item) => {
          return {
            ...item,
            position:
              projectDetail?.requisitionchainapprovalemployees_set.find((chain) => chain.user === item.id)?.position ||
              null,
          };
        })
        ?.sort((a, b) => (a.position > b.position ? 1 : -1)),
      orderApprovalEmployees: projectDetail?.order_approval_employees
        ?.map((item) => {
          return {
            ...item,
            position:
              projectDetail?.orderchainapprovalemployees_set.find((chain) => chain.user === item.id)?.position || null,
          };
        })
        ?.sort((a, b) => (a.position > b.position ? 1 : -1)),
      paymentApprovalEmployees: projectDetail?.payment_approval_employees
        ?.map((item) => {
          return {
            ...item,
            position:
              projectDetail?.paymentchainapprovalemployees_set.find((chain) => chain.user === item.id)?.position ||
              null,
          };
        })
        ?.sort((a, b) => (a.position > b.position ? 1 : -1)),
      timesheetApprovalEmployees: projectDetail?.timesheet_approval_employees
        ?.map((item) => {
          return {
            ...item,
            position:
              projectDetail?.timesheetchainapprovalemployees_set.find((chain) => chain.user === item.id)?.position ||
              null,
          };
        })
        ?.sort((a, b) => (a.position > b.position ? 1 : -1)),
      planWorkConfirmEmployees: projectDetail?.planworkchainapprovalemployees_set
        ? projectDetail.planworkchainapprovalemployees_set.map(mapProjectEmployee)
        : [],
      planSectionConfirmEmployees: projectDetail?.plansectionchainapprovalemployees_set
        ? projectDetail.plansectionchainapprovalemployees_set.map(mapProjectEmployee)
        : [],
      ticketApprovalEmployees: projectDetail?.ticketchainapprovalemployees_set
        ? projectDetail.ticketchainapprovalemployees_set.map(mapProjectEmployee)
        : null,
      allow_shift: projectDetail?.allow_shift ?? false,
      sharingfactchainapprovalemployees_set: projectDetail?.sharingfactchainapprovalemployees_set
        ? projectDetail?.sharingfactchainapprovalemployees_set.map(mapProjectEmployee)
        : null,
      shared_remark_responsible_employees: projectDetail?.shared_remark_responsible_employees
        ? projectDetail?.shared_remark_responsible_employees.map(mapProjectEmployee)
        : null,
      new_close_dates: projectDetail?.new_close_dates ?? false,
    });
  }, [urlId, isNew, projectDetail, projectEmployees]);

  const handleAddChainEmployee = (employes, type, prevEmployee) => {
    setIsDirty(true);
    let errorEmployees = [];
    let approvalEmployees = approvalsState[type] || [];
    let prevIndex;

    employes.forEach((employee) => {
      if (approvalEmployees.find((item) => item.id === employee.id || item.user === employee.id)) {
        errorEmployees.push(employee);
      }
    });

    if (errorEmployees.length === 0) {
      const employeesWithPosition = approvalEmployees.map((item, index) => {
        if (item.id === prevEmployee.id) {
          prevIndex = index;
          return { ...item, position: item.position || 1 };
        }
        return item;
      });

      employes[0].position = prevEmployee.position ? prevEmployee.position + 1 : 2;
      employeesWithPosition.splice(prevIndex + 1, 0, employes[0]);

      setApprovalsState((prev) => ({
        ...prev,
        [type]: employeesWithPosition,
      }));
    }

    errorEmployees.forEach((employee) => {
      message.error(`${employee.last_name} ${employee.first_name} ${employee.middle_name} уже есть в списке`);
    });
  };

  const deleteAgreementsEmployee = (employee, type) => {
    setIsDirty(true);
    const filteredApprovalEmployees =
      approvalsState[type]?.filter((stateEmployee) => stateEmployee.id !== employee.id) || [];

    const isWithoutChain = checkIfWithoutChain(filteredApprovalEmployees);

    const newEmployees = filteredApprovalEmployees.map((item) => {
      if (isWithoutChain) return { ...item, position: null };
      return employee.position && item.position > employee.position
        ? {
            ...item,
            position: item.position - 1,
          }
        : item;
    });

    setApprovalsState((prev) => ({
      ...prev,
      [type]: newEmployees,
    }));
    message.success(
      `${employee.name || employee.last_name + " " + employee.first_name + " " + employee.middle_name} успешно удален`
    );
  };

  const handleChangeAllowShift = (v) => {
    setIsDirty(true);
    setApprovalsState((prev) => ({
      ...prev,
      allow_shift: v,
    }));
  };

  const handleChangenew_close_dates = (v) => {
    setIsDirty(true);
    setApprovalsState((prev) => ({
      ...prev,
      new_close_dates: v,
    }));
  };

  const handleChangeAgreementsEmployees = (items, type) => {
    setIsDirty(true);
    let errorEmployees = [];
    let newEmployees = [];

    const approvalEmployees = approvalsState[type] || [];

    items.forEach((employee) => {
      if (approvalEmployees.find((item) => item.id === employee.id || item.user === employee.id)) {
        errorEmployees.push(employee);
      } else {
        newEmployees.push({
          id: employee.id,
          name: employee.name,
          roles: employee.roles,
        });
      }
    });

    errorEmployees.forEach((employee) => {
      const name = employee.name || employee.last_name + " " + employee.first_name;
      message.error(`${name} уже есть в списке`);
    });

    setApprovalsState((prev) => ({
      ...prev,
      [type]: [...approvalEmployees, ...newEmployees],
    }));
  };

  const getValues = () => {
    const isWithoutRequisitionChain = checkIfWithoutChain(approvalsState?.requisitionApprovalEmployees);

    const requisitionchainapprovalemployees_set = approvalsState?.requisitionApprovalEmployees?.map((employee) => {
      if (isWithoutRequisitionChain) return { user: employee.id };
      return { user: employee.id, position: employee.position };
    });

    const isWithoutOrderChain = checkIfWithoutChain(approvalsState?.orderApprovalEmployees);
    const orderchainapprovalemployees_set = approvalsState?.orderApprovalEmployees?.map((employee) => {
      if (isWithoutOrderChain) return { user: employee.id };
      return { user: employee.id, position: employee.position };
    });

    const isWithoutPaymentChain = checkIfWithoutChain(approvalsState?.paymentApprovalEmployees);
    const paymentchainapprovalemployees_set = approvalsState?.paymentApprovalEmployees?.map((employee) => {
      if (isWithoutPaymentChain) return { user: employee.id };
      return { user: employee.id, position: employee.position };
    });

    const isWithoutTimesheetChain = checkIfWithoutChain(approvalsState?.timesheetApprovalEmployees);
    const timesheetchainapprovalemployees_set = approvalsState?.timesheetApprovalEmployees?.map((employee) => {
      if (isWithoutTimesheetChain) return { user: employee.id };
      return { user: employee.id, position: employee.position };
    });

    const planworkchainapprovalemployees_set = approvalsState?.planWorkConfirmEmployees.map((employee) => ({
      user: employee.user || employee.id,
      position: employee.position,
    }));

    const plansectionchainapprovalemployees_set = approvalsState?.planSectionConfirmEmployees.map((employee) => ({
      user: employee.user || employee.id,
      position: employee.position,
    }));

    const ticketchainapprovalemployees_set = approvalsState?.ticketApprovalEmployees?.map((employee) => ({
      user: employee.user || employee.id,
      position: employee.position,
    }));

    const sharingfactchainapprovalemployees_set = approvalsState?.sharingfactchainapprovalemployees_set?.map(
      (employee) => ({
        user: employee.user || employee.id,
        position: employee.position,
      })
    );

    const shared_remark_responsible_employees = approvalsState?.shared_remark_responsible_employees?.map(
      (employee) => ({
        user: employee.user || employee.id,
        position: employee.position,
      })
    );

    const shared_remark_responsible_employee_ids = approvalsState?.shared_remark_responsible_employees?.map(
      (employee) => employee.user || employee.id
    );

    return {
      name: projectDetail.name,
      /*       allow_shift: approvalsState?.allow_shift ?? false,
      new_close_dates: approvalsState?.new_close_dates ?? false, */
      requisitionchainapprovalemployees_set,
      orderchainapprovalemployees_set,
      paymentchainapprovalemployees_set,
      timesheetchainapprovalemployees_set,
      planworkchainapprovalemployees_set,
      plansectionchainapprovalemployees_set,
      ticketchainapprovalemployees_set,
      sharingfactchainapprovalemployees_set,
      shared_remark_responsible_employees,
      shared_remark_responsible_employee_ids,
    };
  };

  return {
    approvalsState,
    handleAddChainEmployee,
    deleteAgreementsEmployee,
    handleChangeAgreementsEmployees,
    getValues,
    handleChangeAllowShift,
    handleChangenew_close_dates,
    isDirty,
  };
};
