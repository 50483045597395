/** Получить элемент строки в дереве расценок, соответствующую текущему положению курсора
 7rem подобрано на глаз – координаты соответствующей строки в дереве расценок
*/
export const getCorrespondingTreeElement = (e: MouseEvent, REM: number) =>
  document.elementFromPoint(7 * REM, e.pageY)?.closest("[data-chart-tree-uid]");

/** Получить дату из линейки дат, соответствующую текущему положению курсора
    11.3rem подобрано с учётом контента шапки графика – координаты соответствующей даты
*/
export const getCorrespondingDateElement = (e: MouseEvent, REM: number) =>
  document.elementFromPoint(e.pageX, 11.3 * REM)?.closest("[data-year][data-month]");
