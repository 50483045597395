import cn from "classnames";
import React, { useState } from "react";
import { Field } from "react-final-form";

import InputBase, { VALUE_TYPES } from "../InputBase";
import doubleInputIcon from "./doubleInputIcon";
import { FormState } from "final-form";

import styles from "./InputDoubleOrSingle.module.scss";

interface IProps {
  firstFieldName: string;
  secondFieldName: string;
  formValues?: FormState<any, Partial<any>>["values"];
  placeholder?: string;
  onChange?: (val: string) => void;
  validate?: any;
  isDefaultDoubled?: boolean;
  onSplit?: () => void;
}

const InputDoubleOrSingle: React.FC<IProps> = ({
  firstFieldName,
  secondFieldName,
  formValues,
  placeholder,
  onChange,
  isDefaultDoubled,
  validate,
  onSplit,
}) => {
  const [isDoubled, setIsDoubled] = useState(isDefaultDoubled);
  const [isFocused, setIsFocused] = useState(false);
  const isFilled = !!formValues?.[firstFieldName] && !!formValues?.[secondFieldName];

  if (isDoubled) {
    return (
      <div className={styles.double}>
        <Field
          name={firstFieldName}
          validate={validate}
          render={({ input }) => (
            <InputBase
              className={cn(styles.firstInput, styles.halfInput, { [styles.filledInputs]: !isFocused && isFilled })}
              valueType={VALUE_TYPES.NUMBER}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              value={input.value}
              onChange={(e) => {
                input.onChange(e.target.value);
                onChange?.(e.target.value);
              }}
              onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
              numbersConstraint={2}
            />
          )}
        />
        <Field
          name={secondFieldName}
          render={({ input }) => (
            <InputBase
              className={cn(styles.secondInput, styles.halfInput)}
              valueType={VALUE_TYPES.NUMBER}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              value={input.value}
              onChange={(e) => {
                input.onChange(e.target.value);
                onChange?.(e.target.value);
              }}
              onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
              numbersConstraint={2}
            />
          )}
        />
      </div>
    );
  }

  return (
    <Field
      name={firstFieldName}
      validate={validate}
      render={({ input }) => (
        <InputBase
          valueType={VALUE_TYPES.NUMBER}
          value={input.value}
          onChange={(e) => {
            input.onChange(e.target.value);
            onChange?.(e.target.value);
          }}
          placeholder={placeholder}
          icon={
            <div
              className={styles.icon}
              onClick={() => {
                setIsDoubled(true);
                onSplit?.();
              }}
            >
              {doubleInputIcon}
            </div>
          }
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          numbersConstraint={2}
        />
      )}
    />
  );
};

export default InputDoubleOrSingle;
