import { useMemo } from "react";

import {
  VIEW_CONSTRUCTING_CHART_EDIT_PLAN,
  VIEW_CONSTRUCTING_EDIT_PLAN,
} from "../../../constants/permissions/constructingPermissions";
import {
  VIEW_MANUFACTURING_CHART_EDIT_PLAN,
  VIEW_MANUFACTURING_PROJECT_PLAN_EDIT_PLAN,
} from "../../../constants/permissions/manufacturingPermissions";

import usePermission from "../../../hooks/usePermission";
import { useUrlModule } from "../../../utils/hooks/useUrlModule";

//TODO replace from this folder. it isnt "shared"
export const useHasEditPlanPermission = () => {
  const module = useUrlModule();

  const canEditInConstructing = usePermission(VIEW_CONSTRUCTING_EDIT_PLAN);
  const canEditInConstructingChart = usePermission(VIEW_CONSTRUCTING_CHART_EDIT_PLAN);
  const canEditInManufacturing = usePermission(VIEW_MANUFACTURING_PROJECT_PLAN_EDIT_PLAN);
  const canEditInManufacturingChart = usePermission(VIEW_MANUFACTURING_CHART_EDIT_PLAN);

  const hasEditPermission = useMemo(() => {
    const isOpenedOnChart = window.location.pathname.includes("manufacturing");

    if (isOpenedOnChart) {
      if (module === "constructing") {
        return canEditInConstructingChart;
      } else {
        return canEditInManufacturingChart;
      }
    } else {
      if (module === "constructing") {
        return canEditInConstructing;
      } else {
        return canEditInManufacturing;
      }
    }
  }, [canEditInConstructing, canEditInConstructingChart, canEditInManufacturing, canEditInManufacturingChart, module]);

  return hasEditPermission;
};
