import React from "react";

function CheckFillRound({ width = "32", colorFill = "#00C4FF", color = "#fff", className = "" }) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.977 0.773013C12.9654 0.773013 10.0214 1.66606 7.51734 3.33923C5.01328 5.01239 3.06159 7.39052 1.9091 10.1729C0.756601 12.9553 0.455051 16.0169 1.04259 18.9707C1.63013 21.9244 3.08036 24.6376 5.20989 26.7671C7.33943 28.8967 10.0526 30.3469 13.0064 30.9344C15.9601 31.522 19.0218 31.2204 21.8041 30.0679C24.5865 28.9154 26.9646 26.9637 28.6378 24.4597C30.311 21.9556 31.204 19.0116 31.204 16C31.2098 13.9987 30.8199 12.0161 30.0567 10.166C29.2936 8.31598 28.1722 6.63506 26.7571 5.21995C25.342 3.80483 23.661 2.68344 21.811 1.92027C19.9609 1.1571 17.9783 0.767205 15.977 0.773013ZM13.901 22.506L8.57101 17.173L9.54001 16.204L13.9 20.564L24.49 9.97801L25.459 10.947L13.901 22.506Z"
        fill={colorFill}
        stroke={color}
        strokeWidth="1.4"
      />
    </svg>
  );
}

export default React.memo(CheckFillRound);
