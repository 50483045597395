import cn from "classnames";
import moment from "moment";
import React, { useMemo } from "react";

import Icon from "../../../../_LEGACY/UI/_LEGACY_Icon/Icon";
import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import errorIcon from "./icons/error.svg";
import successIcon from "./icons/success.svg";

import styles from "./StatusElement.module.scss";

interface IProps {
  status: "success" | "fail";
  text?: string;
  className?: string;
  portalClassName?: string;
  onClick?: (event: React.MouseEvent) => void;
  dateEnd?: string | null;
  isTransparent?: boolean;
}

const iconsDict = {
  success: successIcon,
  fail: errorIcon,
};

const StatusElement: React.FC<IProps> = ({
  status,
  text,
  className,
  onClick,
  dateEnd,
  isTransparent,
  portalClassName,
}) => {
  const icon = iconsDict[status];

  const formattedDate = useMemo(() => moment(dateEnd).format("DD.MM.YYYY"), [dateEnd]);
  const isExpired = useMemo(() => moment(dateEnd).isAfter(moment()), [dateEnd]);

  return (
    <PopoverOverlay
      content={
        <div className={styles.popover}>
          {/* TODO абстрагировать это от компонента */}
          <span>{isExpired ? `Срок действия истекает ${formattedDate}` : `Срок действия истек ${formattedDate}`}</span>
        </div>
      }
      isDisabled={!dateEnd}
      portalClassName={portalClassName}
      openType="hover"
      popoverBorderColor="default"
      placement="bottom"
    >
      <div
        className={cn(styles.container, className, styles[status], { [styles.transparent]: isTransparent })}
        onClick={onClick}
      >
        <Icon icon={icon} />
        {text}
      </div>
    </PopoverOverlay>
  );
};

export default React.memo(StatusElement);
