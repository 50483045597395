import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { RequestModal } from "../RequestModal/RequestModal";
import { LandingPagesRoutesEnum } from "../../lib/landingPagesRoutesEnum";
import { OpportunitySection } from "./OpportunitySection/OpportunitySection";
import { PlanningSection } from "./PlanningSection/PlanningSection";
import { BenefitsSection } from "./BenefitsSection/BenefitsSection";
import { PlanFactSection } from "./PlanFactSection/PlanFactSection";
import { CostSection } from "./CostSection/CostSection";
import { SafetySection } from "./SafetySection/SafetySection";

export const ForOfficePage = () => {
  return (
    <Fragment>
      <OpportunitySection />
      <PlanningSection />
      <BenefitsSection />
      <PlanFactSection />
      <CostSection />
      <SafetySection />
      <Footer />
      <RequestModal />
    </Fragment>
  )
}