const MAX_PERCENT_VALUE = 999;

export const getSectionCompletedPercent = (section) => {
  let completedPercent = 0;

  completedPercent = section.indicators ? section.indicators.completed_percent : 0;

  const rounded = Math.round(+completedPercent);
  return rounded <= MAX_PERCENT_VALUE ? rounded : MAX_PERCENT_VALUE;
};
