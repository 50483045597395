import Features1Svg from './img/features1.svg';
import Features2Svg from './img/features2.svg';
import Features3Svg from './img/features3.svg';
import Features4Svg from './img/features4.svg';

export const PlanningSection = () => (
  <section className="planning__main" id="list">
    <div className="container">
      <div className="planning__flex">
        <div className="planning__box">
          <img src={Features1Svg} alt="icon" />
            <span>Планирование производства</span>
            <p>Построение плана производства работ автоматически формирует график потребности в ресурсах</p>
        </div>
        <div className="planning__box">
          <img src={Features2Svg} alt="icon" />
            <span>Снабжение ресурсами</span>
            <p>Создание и мониторинг заявок на поставку, ведение складского учета для контроля ресурсов</p>
        </div>
        <div className="planning__box">
          <img src={Features3Svg} alt="icon" />
            <span>Выполнение работ</span>
            <p>Сбор факта о выполнении, согласование и приемка, создание и устранение замечаний</p>
        </div>
        <div className="planning__box">
          <img src={Features4Svg} alt="icon" />
            <span>Формирование документации</span>
            <p>Автоматическое формирование КС-2, КС-3 и КС-6а на основе сметных расценок</p>
        </div>
      </div>
    </div>
  </section>
)