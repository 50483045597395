import axios from "axios";

import { MODULES_ENUM } from "../../../../types/enums/ModulesEnum";
import { IUIIndicatorResponse, UIIndicatorsType } from "./types";

export const uiIndicatorsApi = {
  getIndicators: ({
    type,
    module,
    forUserId,
    buildingId,
    limit = 1000,
    offset,
    ordering,
    search,
  }: {
    type?: UIIndicatorsType;
    module: MODULES_ENUM;
    forUserId?: number;
    buildingId?: number;
    limit?: number;
    offset?: number;
    ordering?: string;
    search?: string;
  }) => {
    return axios.get<IUIIndicatorResponse[]>(`/ui_indicators/`, {
      params: {
        type,
        module,
        limit,
        offset,
        ordering,
        search,
        for_user_id: forUserId,
        building_id: buildingId,
      },
    });
  },
  deleteIndicator: (indicatorId: number) => {
    return axios.delete(`/ui_indicators/${indicatorId}/`);
  },
};
