import React, { PureComponent } from "react";

interface IProps {
  children: React.ReactNode;
}

interface IState {
  hasError: boolean;
  error?: any;
}

export class ErrorBoundary extends PureComponent<IProps, IState> {
  state: IState = { hasError: false };

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }
  componentDidCatch(error: any, errorInfo: any) {
    console.error("Error Boundary Caught:", error, errorInfo);
    if (error?.name === "ChunkLoadError") {
      window.location.reload();
    }
    if (error?.name === "SyntaxError") {
      window.location.reload();
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: "center", paddingTop: "30vh", fontSize: "2rem" }}>
          Произошла ошибка, обновите страницу и попробуйте снова
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
