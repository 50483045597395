export const TABS_IDS = {
  NEW_REQUISITIONS: "newRequisitions",
  IN_WORK_REQUISITIONS: "inWorkRequisitions",
  COMPLETED_REQUISITIONS: "completedRequisitions"
};

export const TABS_LINKS = {
  NEW_REQUISITIONS: "new",
  IN_WORK_REQUISITIONS: "in-work",
  COMPLETED_REQUISITIONS: "completed"
};

export const TABS = [
  { text: "Новые", id: TABS_IDS.NEW_REQUISITIONS, link: TABS_LINKS.NEW_REQUISITIONS },
  { text: "В работе", id: TABS_IDS.IN_WORK_REQUISITIONS, link: TABS_LINKS.IN_WORK_REQUISITIONS },
  { text: "Исполнены", id: TABS_IDS.COMPLETED_REQUISITIONS, link: TABS_LINKS.COMPLETED_REQUISITIONS }
];