import cn from "classnames";
import React, { FC } from "react";

import PlusFillCircleIcon from "../../../../../../../../images/icons/PlusFillCircleIcon";

import styles from "./AddButton.module.scss";

interface IProps {
  text?: string;
  onClick?: () => void;
  className?: string;
}

export const AddButton: FC<IProps> = ({ text, onClick, className }) => {
  return (
    <button className={cn(styles.addSectionButton, className)} onClick={onClick}>
      <span className={styles.text}>{text}</span>
      <PlusFillCircleIcon />
    </button>
  );
};
