import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { IDetailedKs2InDocs } from "redux/modules/common/building/documents/ksList/types";
import { aosrActsSelector } from "redux/modules/common/building/journal/aosr/selectors";
import { getAosr } from "redux/modules/common/building/journal/aosr/thunks";
import {
  expenditureAttachmentsSelector,
  ks2ExpendituresAttachmentsSelector,
  ks2SectionsAttachmentsSelector,
  passportsInvalidateKeySelector,
} from "redux/modules/common/passportsAndCertificates/selectors";
import {
  getExpendituresAttachments,
  getKs2ExpenditureAttachments,
  getKs2SectionAttachments,
} from "redux/modules/common/passportsAndCertificates/thunks";
import { DocumentsTypesEnum } from "redux/modules/common/passportsAndCertificates/types";

import ExpenditureKs2FilesModal from "components/modals/ExpenditureFilesModal/FilesModalKs2Expenditure";
import FilesModalKs2Section from "components/modals/ExpenditureFilesModal/FilesModalKs2Section";
import { checkExpenditureAttachmentIsWarn } from "components/modals/PassportsAndSertificates/utils";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import AosrExistingModal from "components/pages/Journal/components/JournalDone/components/JournalExecTicket/components/Aosr/AosrExistingModal/AosrExistingModal";

import ExpandPillTrigger from "shared/ui/controls/ExpandPillTrigger/ExpandPillTrigger";
import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import StatusElement from "shared/ui/dataDisplay/StatusElement/StatusElement";
import { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";
import { ITicketInJournalDone } from "types/interfaces/Tickets";

import { getAttachmentName } from "../../utils";
import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./KS3PageRowContent.module.scss";

interface IProps {
  isFirst?: boolean;
  expenditure: IDetailedKs2InDocs["items"][0]["expenditures"][0];
  section: IDetailedKs2InDocs["items"][0];
  ks2Id: number;
}

const KS3PageSubRow: React.FC<IProps> = ({ isFirst, expenditure, section, ks2Id }) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const [isSectionFilesRow, setIsSectionFilesRow] = React.useState(false);
  const [isSectionModal, setIsSectionModal] = React.useState(false);

  const toggleSectionFilesRow = React.useCallback(() => {
    setIsSectionFilesRow((prev) => !prev);
  }, []);

  const toggleSectionModal = React.useCallback(() => {
    setIsSectionModal((prev) => !prev);
  }, []);

  const invalidateDocsKey = useSelector(passportsInvalidateKeySelector);
  const sectionAttachments = useSelector(ks2SectionsAttachmentsSelector)[`${ks2Id}_${section?.section_id}`];

  const filteredSectionAttachments = React.useMemo(() => {
    return sectionAttachments?.results?.filter((el) => el.type !== DocumentsTypesEnum.file);
  }, [sectionAttachments]);

  React.useEffect(() => {
    if (!isSectionFilesRow) return;
    dispatch(getKs2SectionAttachments(ks2Id, section.section_id));
  }, [invalidateDocsKey, ks2Id, isSectionFilesRow, section.section_id]);

  const [isExpenditureFilesRow, setIsExpenditureFilesRow] = React.useState(false);
  const [isExpenditureModal, setIsExpenditureModal] = React.useState(false);

  const toggleExpenditureFilesRow = React.useCallback(() => {
    setIsExpenditureFilesRow((prev) => !prev);
  }, []);

  const toggleExpenditureModal = React.useCallback(() => {
    setIsExpenditureModal((prev) => !prev);
  }, []);

  const expenditureAttachments = useSelector(ks2ExpendituresAttachmentsSelector)[
    `${ks2Id}_${expenditure?.expenditure_id}`
  ];
  const expenditureAttachmentsForResources = useSelector(expenditureAttachmentsSelector)[expenditure?.expenditure_id];
  const isWork = expenditure.expenditure_type === ExpenditureTypeEnum.work;

  React.useEffect(() => {
    if (!isExpenditureFilesRow) return;
    if (isWork) {
      dispatch(getKs2ExpenditureAttachments(objectId, ks2Id, expenditure.expenditure_id));
    } else {
      dispatch(getExpendituresAttachments(expenditure.expenditure_id));
    }
  }, [invalidateDocsKey, ks2Id, isExpenditureFilesRow, expenditure?.expenditure_id, objectId]);

  React.useEffect(() => {
    if (!isExpenditureFilesRow) return; /* @ts-ignore */
    if (!expenditureAttachments?.aosr?.length) return;
    dispatch(getAosr(objectId, expenditure.expenditure_id));
  }, [objectId, expenditure.expenditure_id, isExpenditureFilesRow, expenditureAttachments]);
  const aosr = useSelector(aosrActsSelector)[expenditure.expenditure_id];
  const [isAosrModal, setIsAosrModal] = React.useState(false);
  const openAosrModal = React.useCallback(() => {
    setIsAosrModal(true);
  }, []);
  const closeAosrModal = React.useCallback(() => {
    setIsAosrModal(false);
  }, []);

  const filteredExpenditureAttachments = React.useMemo(() => {
    if (!expenditureAttachments && isWork) return [];
    if (!isWork && !expenditureAttachmentsForResources?.results) return [];
    const attachmentsArr = [];
    for (let key in expenditureAttachments) {
      attachmentsArr.push(expenditureAttachments[key]);
    }
    if (isWork) {
      return attachmentsArr; //.filter((el) => el.type !== DocumentsTypesEnum.file);
    } else {
      return expenditureAttachmentsForResources.results; //.filter((el) => el.type !== DocumentsTypesEnum.file);
    }
  }, [expenditureAttachments, expenditureAttachmentsForResources]);

  return (
    <>
      {isFirst && (
        <div className={styles.section}>
          <div className={styles.sectionRow}>
            <TableReusableCell isNoBreak>{section.section_name}</TableReusableCell>
            <TableReusableCell className={styles.opacity}>К сдаче</TableReusableCell>
            <TableReusableCell isRighted>{splitThousands(section.amount)}</TableReusableCell>
            <TableReusableCell className={styles.opacity}>Расценки</TableReusableCell>
            <TableReusableCell isRighted>{section.expenditures.length}</TableReusableCell>
            <div></div>
            <TableReusableCell>
              <FilesClipButton onClick={toggleSectionModal} />
            </TableReusableCell>
            <TableReusableCell>
              {!!section.attachments_count && (
                <ExpandPillTrigger
                  isExpanded={isSectionFilesRow}
                  toggleExpand={toggleSectionFilesRow}
                  variant="success"
                  label={`${section.attachments_count}`}
                />
              )}
            </TableReusableCell>
          </div>
          {isSectionFilesRow && (
            <div className={styles.filesRow}>
              {filteredSectionAttachments?.map((el) => (
                <StatusElement key={el.id} status="success" text={getAttachmentName(el)} onClick={toggleSectionModal} />
              ))}
            </div>
          )}
        </div>
      )}
      <div className={styles.expenditure}>
        <div className={styles.row}>
          <TableReusableCell>{expenditure.row_number}</TableReusableCell>
          <TableReusableCell isNoBreak>{expenditure.expenditure_name}</TableReusableCell>
          <TableReusableCell isCentered>
            {expenditure.count} {expenditure.expenditure_measure}
          </TableReusableCell>
          <TableReusableCell isRighted>{splitThousands(expenditure.amount)}</TableReusableCell>
          <div />
          <TableReusableCell>
            {[ExpenditureTypeEnum.material, ExpenditureTypeEnum.equipment, ExpenditureTypeEnum.work].includes(
              expenditure.expenditure_type
            ) && <FilesClipButton onClick={toggleExpenditureModal} />}
          </TableReusableCell>
          <TableReusableCell>
            {!!(expenditure.docs_count?.active + expenditure.docs_count?.expired) && (
              <ExpandPillTrigger
                isExpanded={isExpenditureFilesRow}
                toggleExpand={toggleExpenditureFilesRow}
                variant={expenditure.docs_count?.expired ? "error" : "success"}
                label={`${expenditure.docs_count?.active + expenditure.docs_count?.expired}`}
              />
            )}
          </TableReusableCell>
        </div>
        {isExpenditureFilesRow && (
          <div className={styles.filesRow}>
            {/* @ts-ignore */}
            {filteredExpenditureAttachments?.aosr?.map((el) => (
              <StatusElement
                key={el.id}
                status={!!el.docs?.length ? "success" : "fail"}
                text={`Акт АОСР`}
                onClick={openAosrModal}
              />
            ))}
            {filteredExpenditureAttachments?.map((el) => (
              <StatusElement
                key={el.id}
                status={checkExpenditureAttachmentIsWarn(el, true) as "fail"}
                dateEnd={el?.end_at}
                onClick={toggleExpenditureModal}
                text={getAttachmentName(el)}
              />
            ))}
          </div>
        )}
      </div>
      <FilesModalKs2Section
        isOpen={isSectionModal}
        onClose={toggleSectionModal}
        ks2Id={ks2Id}
        sectionId={section.section_id}
      />
      <ExpenditureKs2FilesModal
        isOpen={isExpenditureModal}
        onClose={toggleExpenditureModal}
        ks2Id={ks2Id}
        expenditureId={expenditure.expenditure_id}
        expenditureName={expenditure.expenditure_name}
        expenditureType={expenditure.expenditure_type}
      />
      {aosr && (
        <AosrExistingModal
          isOpen={isAosrModal}
          onClose={closeAosrModal} /* @ts-ignore */
          aosr={aosr}
          sectionId={section.section_id}
          expenditure={{ name: expenditure.expenditure_name, id: expenditure.expenditure_id } as ITicketInJournalDone}
        />
      )}
    </>
  );
};

export default React.memo(KS3PageSubRow);
