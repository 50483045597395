import { Dispatch } from "redux";

import { aggregationsApi } from "./api";
import { aggregationsActions } from "./slice";
import { RootState } from "app/store/rootReducer";

export const loadAggregations = (objectId?: string | number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const finalObjectId = objectId ?? getState().nowObject?.detail?.data?.id;

    if (!finalObjectId) return;

    const data = await aggregationsApi.getAggregations(finalObjectId);
    if (data) {
      dispatch(aggregationsActions.setAggregations(data));
    }
  };
};
