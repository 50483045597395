import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IObjectInList, IObjectMastering } from "./types";
import { IListResponseData } from "types/ListResponse";

interface ObjectsListState {
  invalidationKey: number;
  loadings: Record<string, boolean>;
  objects: Record<string, IListResponseData<IObjectInList>>;
  masterings: Record<string, IObjectMastering>;
}

export const initialState: ObjectsListState = {
  invalidationKey: 0,
  loadings: {},
  objects: {},
  masterings: {},
};

const objectsListSlice = createSlice({
  name: "features/objectsList",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    },
    setObjects: (state, action: PayloadAction<{ data: IListResponseData<IObjectInList>; key: string }>) => {
      const { data, key } = action.payload;
      state.objects[key] = data;
    },
    setMoreObjects: (state, action: PayloadAction<{ data: IListResponseData<IObjectInList>; key: string }>) => {
      const { data, key } = action.payload;
      state.objects[key] = {
        ...(state.objects[key] ?? {}),
        results: [...(state.objects[key]?.results ?? []), ...data.results],
      };
    },
    setMastering: (state, action: PayloadAction<{ data: IObjectMastering; key: string }>) => {
      const { data, key } = action.payload;
      state.masterings[key] = data;
    },
  },
});

export const objectsListActions = objectsListSlice.actions;

export const objectsListReducer = objectsListSlice.reducer;
