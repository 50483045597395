import cn from "classnames";
import React from "react";

import { WorkStatusType, MONTH_COLOR_MAP as colorMap } from "../../../../../../constants";
import { IWorkStatusPercentage } from "../../../../../../types";

import styles from "../../InfoPopup.module.scss";

/* @ts-ignore */
const STATUSES: Record<WorkStatusType, { class: string; name: string }> = {
  to_pay: {
    class: styles.paid,
    name: "К оплате",
  },
  todo: {
    class: styles.todo,
    name: "К сдаче",
  },
  // TODO: детализировать статус "Подтверждено" в попапе
  // confirmed: {
  //   class: styles.confirmed,
  //   name: "Подтверждено",
  // },
  accepted: {
    class: styles.received,
    name: "Принято",
  },
  completed: {
    class: styles.doned,
    name: "Выполнено",
  },
  default: {
    class: styles.default,
    name: "Запланировано",
  },
};

export interface IStatusBlockProps {
  status: WorkStatusType;
  percentage?: IWorkStatusPercentage;
  count?: string | number;
  measure?: string;
}

const StatusBlock: React.FC<IStatusBlockProps> = ({ status, percentage, count, measure }) => {
  if (STATUSES[status]) {
    return (
      <div className={styles.statusBlock}>
        <div
          className={cn(styles.status, STATUSES[status].class)} /* @ts-ignore */
          style={{ backgroundColor: colorMap[status], borderColor: colorMap[status + "Border"] }}
        />
        <div className={styles.indicators}>
          <span className={styles.statusName}>{STATUSES[status].name}</span> {/* @ts-ignore */}
          {percentage && <span className={styles.statusPercentage}>{Math.round(percentage[status] * 100)}%</span>}
          {count !== undefined && (
            <span className={cn(styles.statusCount, styles.textPrimary)}>{`${count} ${
              measure ? `(${measure})` : ""
            }`}</span>
          )}
        </div>
      </div>
    );
  }
  return null;
};

export default React.memo(StatusBlock);
