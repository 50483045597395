import { IPlanFactExtraCostRaw } from "./types";

export type IPlanFactSummaryExtraCostPrepared = ReturnType<typeof planFactSummaryUtils.prepareRawExtraCosts>[number];

export const planFactSummaryUtils = {
  prepareRawExtraCosts: (raw: IPlanFactExtraCostRaw[]) => {
    const sections: Record<string, Set<string>> = {};
    const subSections: Record<string, IPlanFactExtraCostRaw[]> = {};

    raw.forEach((el) => {
      if (sections[el.category]) {
        sections[el.category].add(el.chapter);
      } else {
        sections[el.category] = new Set([el.chapter]);
      }
      if (subSections[el.chapter]) {
        subSections[el.chapter].push(el);
      } else {
        subSections[el.chapter] = [el];
      }
    });

    const res = Object.entries(sections).map(([sectionName, subSectionNames], i) => {
      const childrenArr: { id: number; name: string; costs: IPlanFactExtraCostRaw[] }[] = Array.from(
        subSectionNames
      ).map((subSectionName, index) => ({
        id: index,
        name: subSectionName,
        costs: subSections[subSectionName],
      }));

      return {
        id: i,
        name: sectionName,
        count: childrenArr.reduce((acc, cur) => {
          return acc + cur.costs?.length;
        }, 0),
        subSections: childrenArr,
      };
    });

    return res;
  },
};
