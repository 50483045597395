import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { projectsV2CountsSelector, projectsV2IsLoadingSelector, projectsV2ListSelector } from "./model/selectors";
import { getProjectsList } from "./model/thunks";
import ProjectsTableHeader from "./ui/ProjectsTable/ProjectsTableHeader";
import ProjectsTableRow from "./ui/ProjectsTable/ProjectsTableRow";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import { useTranslation } from "react-i18next";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TabBarNotLinks, { ITab } from "shared/ui/controls/TabBar/TabBarNotLinks";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import projectsIcon from "images/icons/navigation/estimateIcon.svg";

import styles from "./Projects.module.scss";

const getTabs = (text1: string, text2: string): ITab[] => [
  { id: "all", text: text1 },
  { text: text2, id: "accepted" },
];

const Projects = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<Record<string, string>>({});
  const [tab, setTab] = useState("all");

  const tabs = getTabs(t("Все проекты"), t("Выполняются"));

  const key = generateStorageKey({ projectId: 0, ...filter } as any);

  const isLoading = useSelector(projectsV2IsLoadingSelector)[key];
  const projects = useSelector(projectsV2ListSelector)[key];
  const total = useSelector(projectsV2CountsSelector)[key];

  const onCreate = () => {
    history.push("/constructing/projects/create-project/new");
  };

  const onClickProject = (id: number) => {
    history.push(`/constructing/projects/objects-list/${id}`);
  };

  const onClickSettings = (id: number) => {
    history.push(`/constructing/projects/edit-project/${id}`);
  };

  useEffect(() => {
    dispatch(getProjectsList(filter));
  }, [filter]);

  return (
    <TemplateBase>
      <ListPageHeaderTemplate>
        <TabBarNotLinks tabs={tabs} activeId={tab} onClick={setTab} />
        <ButtonBase secondary onClick={onCreate}>
          {t("Создать проект")}
        </ButtonBase>
      </ListPageHeaderTemplate>

      <ProjectsTableHeader onChangeFilter={(name, value) => setFilter((prev) => ({ ...prev, [name]: value }))} />
      {!isLoading && !projects?.length && (
        <EmptyPlaceholder text={t("Нет проектов")!} img={projectsIcon}>
          <ButtonBase primary onClick={onCreate}>
            {t("Создать проект")}
          </ButtonBase>
        </EmptyPlaceholder>
      )}
      {isLoading && <Spinner />}
      {projects?.map((el) => (
        <ProjectsTableRow project={el} key={el.id} onClick={onClickProject} onSettingsClick={onClickSettings} />
      ))}
    </TemplateBase>
  );
};

export default Projects;
