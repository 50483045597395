const InfoIcon = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="20" 
      height="20" 
      viewBox="0 0 20 20"
    >
      <g 
        id="Сгруппировать_7264" 
        data-name="Сгруппировать 7264" 
        transform="translate(-189.063)"
      >
        <circle 
          id="Эллипс_48574" 
          data-name="Эллипс 48574" 
          cx="10" 
          cy="10" 
          r="10" 
          transform="translate(189.063)" 
          fill="#4FB2ED"
        />
        <path 
          id="Контур_5801" 
          data-name="Контур 5801" 
          d="M2.544,0H1.456V-7.488H2.544Zm.224-10.368a.709.709,0,0,1-.24.552A.773.773,0,0,1,2-9.6a.773.773,0,0,1-.528-.216.709.709,0,0,1-.24-.552.709.709,0,0,1,.24-.552A.773.773,0,0,1,2-11.136a.773.773,0,0,1,.528.216A.709.709,0,0,1,2.768-10.368Z" 
          transform="translate(197.063 15)" 
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default InfoIcon;