import cn from "classnames";
import React from "react";

import { detailRemarkStatuses } from "components/modals/Journal/Remarks/types/types";

import { detailRemarkStatusSwitcher } from "components/modals/Journal/Remarks/constants/constants";

import styles from "./ModalDivider.module.scss";

type IProps = {
  type: detailRemarkStatuses;
  className?: string;
  isWithoutMarginTop?: boolean;
  typeSwitcher: typeof detailRemarkStatusSwitcher;
};

const ModalDivider: React.FC<IProps> = ({ type, className, typeSwitcher, isWithoutMarginTop }) => {
  return (
    <div className={cn(styles.container, styles[type], className, { [styles.mt0]: isWithoutMarginTop })}>
      <div className={styles.status}>
        <div className={styles.icon} />
        {typeSwitcher[type]}
      </div>
    </div>
  );
};

export default React.memo(ModalDivider);
