import cn from "classnames";
import React from "react";

import PaperClipIcon from "images/icons/PaperClipIcon";

import styles from "./FilesClipButton.module.scss";

interface IProps {
  onClick?: React.MouseEventHandler;
  count?: number | string;
  className?: string;
  isEmptyLowOpacity?: boolean;
  hasGlobalClassName?: boolean;
}

const FilesClipButton: React.FC<IProps> = ({ onClick, className, count, isEmptyLowOpacity, hasGlobalClassName }) => {
  return (
    <button
      className={cn(styles.btn, className, {
        [styles.empty]: isEmptyLowOpacity && !count,
        ["filesClipButton"]: hasGlobalClassName,
      })}
      onClick={onClick}
    >
      <PaperClipIcon />
      {count && <span className={styles.text}>{count}</span>}
    </button>
  );
};

export default React.memo(FilesClipButton);
