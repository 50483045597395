import Axios from "axios";
import React from "react";

import download from "../../../../../utils/helpers/download";

export const useDownloadStockReport = (stockId: string) => {
  const baseUrl = Axios.defaults.baseURL;

  function downloadReport({ packinglist }: { packinglist: string }): () => void;
  function downloadReport({ stockusing }: { stockusing: string }): () => void;

  function downloadReport({
    packinglist,
    stockusing,
    stock_moving,
  }: {
    packinglist?: string;
    stockusing?: string;
    stock_moving?: string;
  }): () => void {
    return () => {
      if (!stockId) return;
      if (packinglist) {
        download(`${baseUrl}/purchaser/packinglists/${packinglist}/reports/excel/`, "Приходный_ордер.xlsx");
      } else if (stockusing) {
        download(`${baseUrl}/stocks/${stockId}/using/${stockusing}/reports/excel/`, "Расходный_ордер.xlsx");
      } else if (stock_moving) {
        download(`${baseUrl}/stocks/${stockId}/move/${stock_moving}/file/`, "??????.xlsx");
      }
    };
  }

  return React.useCallback(downloadReport, [stockId]);
};
