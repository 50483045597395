import cn from "classnames";
import React from "react";

import styles from "./BannerWithIcon.module.scss";

interface IProps {
  title?: string;
  className?: string;
  url?: string;
  jsxIcon?: React.ReactNode;
  svg?: React.ReactNode;
}

const BannerWithIcon: React.FC<IProps> = ({ title, url, jsxIcon, svg, className, ...props }) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.banner} {...props}>
        <div className={styles.iconContainer}>
          {svg || jsxIcon || (url && url?.length > 0 && <img src={url} alt="Icon" />)}
        </div>
      </div>
      {title && <span className={styles.title}>{title}</span>}
    </div>
  );
};

export default BannerWithIcon;
