import React from "react";

import SwitchedPermission from "./components/SwitchedPermission/SwitchedPermission";
import { INormalizedPermissionsTree } from "components/pages/Settings/components/Users/UserPage/utils/permissionsUtils";

import { IPermission, IPermissionsTree } from "features/permissions/model/types";

import styles from "./Permissions.module.scss";

interface IProps {
  permissions: INormalizedPermissionsTree | null;
  checkedPermissions: any;
  onChangeChecked: any;
  canEdit: boolean;
}

const Permissions: React.FC<IProps> = ({ permissions, checkedPermissions, onChangeChecked, canEdit }) => {
  return (
    <div className={styles.permissions}>
      {permissions?.children?.map((permission) => (
        <SwitchedPermission
          group={permission}
          checkedPermissions={checkedPermissions}
          onChangeChecked={onChangeChecked}
          canEdit={canEdit}
          key={permission.name}
        />
      ))}
    </div>
  );
};

export default React.memo(Permissions);
