import React from 'react'

export const emptyIcon = <svg width="24" height="24" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.1,2.6C8,2.5,7.9,2.5,7.8,2.5s-0.2,0-0.3,0.1C7.5,2.7,7.4,2.8,7.4,2.9c0,0.1,0,0.2,0,0.3
c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.2,0-0.3C8.3,2.7,8.2,2.7,8.1,2.6
z"></path><path d="M23.1,12.8c-0.4-0.4-0.9-0.6-1.5-0.6h-6.2V4.6c0-0.4-0.1-0.7-0.4-1s-0.6-0.4-1-0.4H11
c-0.1-0.7-0.5-1.4-1.1-1.9c-0.6-0.5-1.3-0.8-2-0.8s-1.5,0.3-2,0.8C5.2,1.8,4.9,2.5,4.7,3.2H1.7c-0.4,0-0.7,0.1-1,0.4
s-0.4,0.6-0.4,1v15.8c0,0.4,0.1,0.7,0.4,1s0.6,0.4,1,0.4h9.5v1.5c0,0.6,0.2,1.1,0.6,1.5c0.4,0.4,0.9,0.6,1.5,0.6h8.5
c0.5,0,1.1-0.2,1.5-0.6c0.4-0.4,0.6-0.9,0.6-1.5v-9.1C23.7,13.7,23.5,13.2,23.1,12.8z M4,4.2h1.2c0.1,0,0.3-0.1,0.3-0.1
C5.6,4,5.7,3.9,5.7,3.7c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,1-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,1,0.6,1.5
c0,0.1,0.1,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h1.2v1.4H4V4.2z M11.7,12.8c-0.4,0.4-0.6,0.9-0.6,1.5v6.6H1.7c-0.1,0-0.2,0-0.3-0.1
c-0.1-0.1-0.1-0.2-0.1-0.3V4.6c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1H3V6c0,0.1,0.1,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1
h8.7c0.1,0,0.3-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3V4.2H14c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v7.5h-1.3
C12.7,12.2,12.1,12.4,11.7,12.8z M22.8,23.3c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.4,0.4c-0.3,0.2-0.6,0.3-0.9,0.3h-7.7
c-0.4,0-0.7-0.1-1-0.4c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.1-0.1-0.3-0.1-0.4v-8.6c0-0.1,0-0.3,0.1-0.4c0-0.2,0.1-0.4,0.2-0.5
c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.2,0.8-0.2h7.7c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.1,0.2,0.2,0.4,0.2,0.7
V23.3z" fill="#000"></path><path d="M11.7,8.3H8.8c-0.1,0-0.3,0.1-0.3,0.1C8.4,8.5,8.3,8.6,8.3,8.8v2.9c0,0.1,0.1,0.3,0.1,0.3
c0.1,0.1,0.2,0.1,0.3,0.1h2.9c0.1,0,0.3-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3V8.8c0-0.1-0.1-0.3-0.1-0.3C12,8.3,11.9,8.3,11.7,8.3z
M11.3,11.2H9.3v-2h1.9V11.2z" fill="#000"></path><path d="M9.4,14.2H3.5c-0.1,0-0.3,0.1-0.3,0.1C3.1,14.5,3,14.6,3,14.7s0.1,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h5.9
c0.1,0,0.3-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3s-0.1-0.3-0.1-0.3C9.7,14.3,9.6,14.2,9.4,14.2z" fill="#000"></path>
    <path d="M7.2,16.9H3.5c-0.1,0-0.3,0.1-0.3,0.1C3.1,17.1,3,17.3,3,17.4s0.1,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h3.7
c0.1,0,0.3-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3S7.7,17.1,7.6,17C7.5,16.9,7.3,16.9,7.2,16.9z" fill="#000"></path>
    <path d="M9.7,17c-0.1-0.1-0.2-0.1-0.3-0.1s-0.2,0-0.3,0.1C9.1,17.1,9,17.1,9,17.2c0,0.1,0,0.2,0,0.3
c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.2,0-0.3
C9.8,17.1,9.8,17,9.7,17z" fill="#000"></path><path d="M3.5,9.3h3.7c0.1,0,0.3-0.1,0.3-0.1S7.7,9,7.7,8.8c0-0.1-0.1-0.3-0.1-0.3C7.5,8.4,7.3,8.4,7.2,8.4H3.5
c-0.1,0-0.3,0.1-0.3,0.1C3.1,8.6,3,8.7,3,8.8C3,9,3.1,9.1,3.2,9.2C3.3,9.3,3.4,9.3,3.5,9.3z" fill="#000"></path>
    <path d="M3.5,12.6h3.7c0.1,0,0.3-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3s-0.1-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1H3.5
c-0.1,0-0.3,0.1-0.3,0.1C3.1,11.9,3,12,3,12.1s0.1,0.3,0.1,0.3C3.3,12.6,3.4,12.6,3.5,12.6z" fill="#000"></path>
    <path d="M18.3,18.5c0.2-0.1,0.3-0.2,0.5-0.4c0.4-0.4,0.7-1,0.7-1.6c0-0.4-0.1-0.9-0.4-1.3c-0.2-0.4-0.6-0.7-1-0.8
c-0.4-0.2-0.9-0.2-1.3-0.1c-0.4,0.1-0.8,0.3-1.2,0.6c-0.3,0.3-0.5,0.7-0.6,1.2c-0.1,0.4,0,0.9,0.1,1.3s0.5,0.8,0.8,1
c0,0,0.1,0,0.1,0.1c-0.5,0.2-1,0.5-1.3,0.8c-0.7,0.7-1,1.5-1,2.5c0,0.4,0.2,0.8,0.4,1.1s0.7,0.4,1.1,0.4h4c0.4,0,0.8-0.2,1.1-0.4
c0.3-0.3,0.4-0.7,0.4-1.1c0-0.9-0.4-1.8-1-2.5C19.2,18.9,18.8,18.6,18.3,18.5z M16,17c-0.1-0.2-0.1-0.5-0.1-0.7
c0-0.2,0.2-0.5,0.3-0.6c0.2-0.2,0.4-0.3,0.7-0.3c0.1,0,0.2,0,0.2,0c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.4,0.3,0.6,0.5
c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3-0.1,0.7-0.4,0.9c-0.2,0.2-0.5,0.3-0.8,0.4h-0.1c-0.3,0-0.5-0.1-0.7-0.2C16.2,17.4,16.1,17.2,16,17z
M19.5,22.1c-0.1,0.1-0.2,0.1-0.4,0.1h-4c-0.1,0-0.3-0.1-0.4-0.1s-0.1-0.2-0.1-0.4c0-0.7,0.3-1.3,0.7-1.8s1.1-0.7,1.8-0.7
s1.3,0.3,1.8,0.7s0.7,1.1,0.7,1.8C19.6,21.9,19.6,22,19.5,22.1z" fill="#000"></path></svg>