import OfficeCircSvg from "./img/office-circ.svg"
import OfficeCircMobSvg from "./img/office-circ-mob.svg"

export const OpportunitySection = () => (
  <section className="opportun__main" id="opportunity">
    <div className="container">
      <div className="opportun__flex">
        <div className="opportun__info">
          <h1>Возможности для управления</h1>
          <p>Платформа обеспечивает полноту данных для управленческого учета и позволяет контролировать объект
            строительства в реальном времени с любого устройства, подключенного к интернету</p>
          <button data-val="modal" className="open_modal button">Запросить демонстрацию</button>
        </div>
        <div className="opportunities-pic">
          <img src={OfficeCircSvg} alt="image" />
        </div>
      </div>
    </div>
    <div className="opportun-mob__pic">
      <img src={OfficeCircMobSvg} alt="image" />
    </div>
  </section>
)