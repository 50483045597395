import { IRequisitionsListActions } from "./actions";

import { IRequisitionsListState } from "./types";

export const requisitionsListInitialsState: IRequisitionsListState = {
  requisitions: {},
  cachedTabs: null,
};

export default (
  state: IRequisitionsListState = requisitionsListInitialsState,
  action: IRequisitionsListActions
): IRequisitionsListState => {
  const { type, payload } = action;
  switch (type) {
    case "requisitionsList/SET_IS_LOADING":
      return {
        ...state,
        requisitions: {
          ...state.requisitions,
          [payload.key]: {
            ...(state.requisitions[payload.key] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "requisitionsList/SET_REQUISITIONS":
      return {
        ...state,
        requisitions: {
          ...state.requisitions,
          [payload.key]: {
            ...(state.requisitions[payload.key] || {}),
            isLoading: false,
            requisitions: payload.requistions,
            filters: payload.filters,
            count: payload.count,
          },
        },
      };
    case "requisitionsList/SET_MORE_REQUISITIONS":
      return {
        ...state,
        requisitions: {
          ...state.requisitions,
          [payload.key]: {
            ...(state.requisitions[payload.key] || {}),
            isLoading: false,
            requisitions: [...(state.requisitions[payload.key].requisitions || []), ...payload.requistions],
            filters: payload.filters,
            count: payload.count,
          },
        },
      };
    case "requisitionsList/SET_TABS":
      return {
        ...state,
        cachedTabs: payload,
      };
    default:
      return state;
  }
};
