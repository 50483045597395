import classNames from "classnames";
import React from "react";

import styles from "./PassportsTable.module.scss";

const PassportsTableHead = () => {
  return (
    <div className={classNames(styles.row, styles.head)}>
      <div>№</div>
      <div>Тип</div>
      <div className={styles.centered}>Статус</div>
      <div className={styles.centered}>Дата</div>
      <div className={styles.centered}>Файлы</div>
    </div>
  );
};

export default React.memo(PassportsTableHead);
