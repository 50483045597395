import React, { FC, memo } from "react";
import { splitThousands } from "../../../../../../../utils/formatters/splitThousands";
import styles from './RelationsInfoModal.module.scss'

interface IExpenditureInfoProps {
  section?: string;
  number?: string | number;
  justification?: string;
  expenditureName?: string;
  price?: string;
  count?: string;
}

const ExpenditureInfo: FC<IExpenditureInfoProps> = ({
  section,
  number,
  justification,
  expenditureName,
  price,
  count
}) => {
  return (
    <div className={styles.infoItem}>
      <div>
        <b>Раздел: </b>
        <br />
        {section}
      </div>
      {number && (
        <div className={styles.item}>
          <b>Номер: </b>
          <br />
          {number}
        </div>
      )}
      {justification && (
        <div className={styles.item}>
          <b>Обоснование: </b>
          <br />
          {justification}
        </div>
      )}
      {expenditureName && (
        <div className={styles.item}>
          <b>Позиция: </b>
          <br />
          {expenditureName}
        </div>
      )}
      {price && (
        <div className={styles.item}>
          <b>Цена за ед. : </b>
          {splitThousands(parseFloat(price))}
        </div>
      )}
      {count && (
        <div className={styles.item}>
          <b>Кол-во по смете: </b>
          {splitThousands(parseFloat(count), false)}
        </div>
      )}
      {price && (
        <div className={styles.item}>
          <b>Бюджет по смете: </b>
          {splitThousands(parseFloat(price) * parseFloat(count || ""))}
        </div>
      )}
    </div>
  )
}

export default memo(ExpenditureInfo);
