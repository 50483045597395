import React from "react";

import { CHART_TABS } from "redux/modules/common/chart/types";

import CompBox from "./components/CompBox";

import { MATERIALS_COLOR_MAP, MATERIALS_STATUSES, MONTH_COLOR_MAP } from "../../constants";

import styles from "./Legend.module.scss";

export interface ILegendProps {
  type: CHART_TABS;
}

const Legend: React.FC<ILegendProps> = ({ type = CHART_TABS.WORK }) => {
  return (
    <div className={styles.container}>
      <div className={styles.big}>
        {type === CHART_TABS.WORK && (
          <>
            <CompBox title={"План"} style={{ backgroundColor: MONTH_COLOR_MAP["default"] }} />
            <CompBox title={"Выполнено"} className={styles.doned} />
            <CompBox title={"Принято"} style={{ background: MONTH_COLOR_MAP["accepted"] }} />
            {/* TODO: детализировать статус "Подтверждено" в попапе
                <CompBox title={"Подтверждено"} className={styles.confirmed} /> */}
            <CompBox title={"К сдаче"} style={{ backgroundColor: MONTH_COLOR_MAP["todo"] }} />
            <CompBox title={"К оплате"} style={{ backgroundColor: MONTH_COLOR_MAP["to_pay"] }} />
            {/*<CompBox title={"Критический путь"} style={{ backgroundColor: MONTH_COLOR_MAP["critical"] }} />*/}
          </>
        )}
        {[CHART_TABS.MATERIALS, CHART_TABS.MIM, CHART_TABS.RESOURCES, CHART_TABS.EQUIPMENT].includes(type) &&
          Object.entries(MATERIALS_STATUSES).map(([key, value]) => (
            /* @ts-ignore */
            <CompBox key={key} title={value.name} style={{ backgroundColor: MATERIALS_COLOR_MAP[key] as string }} />
          ))}
        <div className={styles.triangleRight} />
      </div>
    </div>
  );
};

export default React.memo(Legend);
