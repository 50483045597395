import cn from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./MenuItemModule.module.scss";

/* @ts-ignore */
const MenuItemModule = ({ name, link, title, icon, classname = "" }) => {
  return (
    <NavLink to={link} className={styles.link} activeClassName={styles.active}>
      <div className={cn(styles.container)} data-testid={`modules_menu_${name}`}>
        <img src={icon} alt={name} className={styles.icon} />
        <span className={styles.title}>{title}</span>
      </div>
    </NavLink>
  );
};

export default MenuItemModule;
