import cn from "classnames";
import React from "react";

import Icon from "../../../../_LEGACY/UI/_LEGACY_Icon/Icon";

import ArrowSmallUpGrey from "../../../../images/icons/ArrowSmallUpGrey";
import errorIcon from "./icons/error.svg";
import successIcon from "./icons/success.svg";

import styles from "./ExpandPillTrigger.module.scss";

interface IExpandPillTriggerProps {
  isExpanded?: boolean;
  toggleExpand: () => void;
  label: string;
  variant?: "success" | "error";
  className?: string;
}

const ExpandPillTrigger: React.FC<IExpandPillTriggerProps> = ({
  isExpanded,
  toggleExpand,
  label,
  variant = "success",
  className,
}) => {
  const icon = variant === "error" ? errorIcon : successIcon;
  return (
    <div className={cn(styles.pill, styles[variant!], className)} onClick={toggleExpand}>
      <Icon icon={icon} />
      <span className={styles.label}>{label}</span>
      <ArrowSmallUpGrey
        rotate={isExpanded ? "0" : "180"}
        color={isExpanded ? "#767676" : "#BABABA"}
        className={styles.expandArrow}
      />
    </div>
  );
};

export default React.memo(ExpandPillTrigger);
