import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { compose } from "redux";

import Person from "../../../../UI/_TODO/Person/Person";
import ContactsModal from "_LEGACY/UI/_LEGACY_Purchases_components/ContactsModal/ContactsModal";
import { TableOslaLocal } from "_LEGACY/UI/_LEGACY_TableOslaCustom/TableOslaLocal";
import { TableHeader } from "_LEGACY/UI/_LEGACY_TotoRowTable/TableHeader/TableHeader";

import { useTypedSelector } from "app/store/typedUseSelector";
import { loadObjects } from "features/objectsList";
import TableFilterTextInput from "shared/ui/inputs/TableFilterTextInput/TableFilterTextInput";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { generateStorageKey } from "utils/helpers/generateStorageKey";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import settingGear from "images/icons/navigation/settingGear.svg";

import styles from "./ProjectsTable.module.scss";

//TODO wtf
let offset = 0;

function ProjectsTable({ path }: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { projectId } = useParams<any>();

  const rootPath = useUrlModuleWithObjectId();
  const [personal, setPersonal] = useState(null);
  const [filter, setFilter] = useState({});

  const composedFilter = useMemo(() => {
    return { offset, ...filter, project: projectId ?? "", limit: 500 };
  }, [filter, projectId]);
  const key = generateStorageKey(composedFilter as any);
  const objectsLoading = useTypedSelector((state) => state.objectsListReducer.loadings)[key];
  const objects = useTypedSelector((state) => state.objectsListReducer.objects)[key];

  const filterHandler = (val: any, name: any) => setFilter((prev) => ({ ...prev, [name]: val }));

  const resetPersonal = useCallback(() => setPersonal(null), []);

  useEffect(() => {
    offset = 0;
    dispatch(loadObjects({}, composedFilter));
  }, [composedFilter]);

  return (
    <>
      <div className={styles.wrapper}>
        <TableOslaLocal
          data={objects?.results ?? []}
          pagination={false}
          tableClass={styles.table}
          headerClass={styles.table__header}
          rowClass={styles.table__row}
          isLoading={objectsLoading && !objects?.results?.length}
          isFixedLoader
          onRowClick={(row: any) => history.push(`/${rootPath}/settings/object/${projectId}/${row.id}/passport`)}
          tableStyle={{ tableLayout: "fixed" }}
          emptyPlaceholder={<EmptyPlaceholder text="Нет проектов" img={settingGear} />}
          paginationHide
        >
          <TableHeader data="name" cellStyle={{ width: "35%" }}>
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>Наименование проекта</div>
              <TableFilterTextInput
                title="Наименование компании"
                setFilter={(value) => {
                  filterHandler(value, "name");
                }}
              />
            </div>
          </TableHeader>
          <TableHeader
            data="entity_name"
            cellStyle={{
              width: "20%",
            }}
          >
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>Организация</div>
              <TableFilterTextInput
                title="Организация"
                setFilter={(value) => {
                  filterHandler(value, "entity_name");
                }}
              />
            </div>
          </TableHeader>
          <TableHeader
            data="address"
            cellStyle={{
              width: "24%",
            }}
          >
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>Адрес</div>
            </div>
          </TableHeader>
          <TableHeader
            data="responsible_employee"
            cellStyle={{
              width: "18%",
            }}
            formatter={(cell: any) => (
              <div onClick={compose(() => setPersonal(cell), stopEventPropagation)}>
                <Person cell={cell} />
              </div>
            )}
          >
            <div className={styles.table__titleBlock}>
              <div>Ответственный</div>
            </div>
          </TableHeader>
        </TableOslaLocal>
      </div>
      {false && personal && (
        <ContactsModal isOpen title="Контакты ответственного" contacts={personal} onClose={resetPersonal} />
      )}
    </>
  );
}

export default React.memo(ProjectsTable);
