import { message } from "antd";
import { Dispatch } from "redux";

import { loadAggregations } from "../../../../Aggregations/model/thunks";
import { handlerIndexesActions } from "./actions";
import { indexesApi } from "./api";
import { RootState } from "app/store/rootReducer";

import { ICreateIndex } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const createHandlerIndex = (buildingId: string, vals: ICreateIndex) => (dispatch: Dispatch) => {
  const key = generateStorageKey({ type: "pending", buildingId });
  dispatch(handlerIndexesActions.setLoading(key, true));
  indexesApi
    .createIndex(buildingId, vals)
    .then(() => {
      dispatch(handlerIndexesActions.invalidateKey());
      dispatch(loadAggregations(buildingId) as any);
      message.success("Индекс успешно добавлен");
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(handlerIndexesActions.setLoading(key, false));
    });
};

export const getHandlerIndexes =
  (buildingId: string, sectionId: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = String(sectionId);
    if (!getState().handlerIndexesReducer.indexes[sectionId]) {
      dispatch(handlerIndexesActions.setLoading(key, true));
    }
    indexesApi
      .getIndexes(buildingId, sectionId)
      .then(({ data }) => {
        dispatch(handlerIndexesActions.setIndexes(sectionId, data.results));
      })
      .finally(() => {
        dispatch(handlerIndexesActions.setLoading(key, false));
      });
  };

export const editHandlerIndex =
  (buildingId: string, indexId: number, vals: Partial<ICreateIndex>) => (dispatch: Dispatch) => {
    const key = generateStorageKey({ type: "pending", buildingId });
    dispatch(handlerIndexesActions.setLoading(key, true));
    indexesApi
      .editIndex(buildingId, indexId, vals)
      .then(() => {
        dispatch(handlerIndexesActions.invalidateKey());
        message.success("Индекс успешно сохранен");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(handlerIndexesActions.setLoading(key, false));
      });
  };

export const deleteHandlerIndex = (buildingId: string, indexId: number) => (dispatch: Dispatch) => {
  const key = generateStorageKey({ type: "pending", buildingId });
  dispatch(handlerIndexesActions.setLoading(key, true));
  indexesApi
    .deleteIndex(buildingId, indexId)
    .then(() => {
      dispatch(handlerIndexesActions.invalidateKey());
      dispatch(loadAggregations(buildingId) as any);
      message.success("Индекс успешно удален");
    })
    .catch((e) => {
      if (e?.response?.status == 404) {
        message.error("Нельзя удалить индекс, если были произведены работы по смете");
      }
    })
    .finally(() => {
      dispatch(handlerIndexesActions.setLoading(key, false));
    });
};
