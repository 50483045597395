import cn from "classnames";
import { memoize } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { objectDetailSelector } from "redux/modules/common/building/object/nowObject";

import ApproveModal from "../components/ApproveModal/ApproveModal";
import HandlerIndicatorItem from "../components/HandlerIndicatorItem/HandlerIndicatorItem";
import HandlerStateCount from "../components/HandlerStateCount/HandlerStateCount";
import LoadDraftIndicator from "../components/LoadDraftIndicator/LoadDraftIndicator";
import ProductionIndicator from "../components/ProductionIndicator/ProductionIndicator";

import { useDrop } from "react-dnd";

import { AGGERATIONS_TRANSFORM_OPTIONS, ESTIMATE_STATES_IDS } from "../../ProHandler/constants";
import { EEstimateStatesIds } from "../../ProHandler/enums";
import { IHandlerPermissions } from "../../ProHandler/types";

import { useAggregations } from "../../ProHandler/hooks/useAggregations";

import { getExpenditureAmount } from "../../ProHandler/utils/getExpenditureAmount";
import { getSectionAmount } from "../../ProHandler/utils/getSectionAmount";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";
import { stringifyArgs } from "utils/helpers/stringifyArgs";

import styles from "../ProEstimateStatesIndicators/ProEstimateStatesIndicators.module.scss";

const SimpleEstimateStatesIndicators = ({
  activeEstimateStateId,
  setActiveEstimateStateId,
  changeEstimateItemsState,
  isShared,
  permissions,
  isHideProductionIndicatorInfo,
  isOpen,
  handleOpening,
}) => {
  const building = useSelector(objectDetailSelector);
  const isBuildingShared = building && building.is_shared;

  const { aggregations, locale, object, consolidate } = useAggregations({
    transformOptions: AGGERATIONS_TRANSFORM_OPTIONS,
  });

  const [dropTarget, setDropTarget] = useState(null);

  const [{ canDropLocale, didDrop, droppedItem }, dropLocale] = useDrop(() => ({
    accept: "box",
    drop: () => ({ name: ESTIMATE_STATES_IDS.LOCALE }),
    collect: (monitor) => {
      const itemState = monitor.getItem()?.current_state;
      const canDrop = itemState === "";

      return {
        didDrop: monitor.getDropResult(),
        canDropLocale: canDrop,
        droppedItem: monitor.getItem(),
      };
    },
  }));

  const onApproveTransfer = useCallback(
    (isApprove, targetStateId, chapter) => {
      setDropTarget(null);

      if (isApprove && dropTarget && dropTarget.item) {
        changeEstimateItemsState(
          targetStateId,
          dropTarget.item.map((item) => item.id),
          chapter
        );
      }
    },
    [changeEstimateItemsState, dropTarget]
  );

  const isLocaleIndicatorIsDropTarget = dropTarget?.id === ESTIMATE_STATES_IDS.LOCALE;

  const totalDropItemsAmount = useMemo(() => {
    if (!dropTarget) return 0;
    const totalDropItemsAmount = dropTarget.item.reduce((acc, estimateItem) => {
      if (estimateItem.expenditure_type) return acc + +getExpenditureAmount(estimateItem);
      return acc + +getSectionAmount(estimateItem, activeEstimateStateId);
    }, 0);
    return transformDigitToFinancial(totalDropItemsAmount, AGGERATIONS_TRANSFORM_OPTIONS);
  }, [dropTarget?.item]);

  const handleOnClickByIndicator = useMemo(
    () => memoize((indicatorStateId) => () => setActiveEstimateStateId(indicatorStateId), stringifyArgs),
    [setActiveEstimateStateId]
  );

  useEffect(() => {
    if (dropTarget) return;

    if (didDrop?.name === ESTIMATE_STATES_IDS.LOCALE && droppedItem) {
      setDropTarget({ id: ESTIMATE_STATES_IDS.LOCALE, item: droppedItem });
    }
  }, [dropTarget, droppedItem, didDrop?.name]);

  const handleProductionClick = (id) => {
    if (isHideProductionIndicatorInfo) return;

    setActiveEstimateStateId(id);
  };

  return (
    <div className={cn(styles.outer, { [styles.outerOpened]: isOpen })}>
      <div className={cn(styles.trigger, { [styles.triggerOpened]: isOpen })} onClick={handleOpening}>
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.13278 0L0.591797 4.5L6.13278 9" fill="white" />
        </svg>
      </div>
      {isOpen && (
        <div className={styles.container}>
          {!isShared && permissions.viewDraftHandlerState && (
            <LoadDraftIndicator
              onClick={setActiveEstimateStateId}
              isActive={activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT}
              drafterCount={aggregations?.drafter_count ?? 0}
              isFullWidth
              isFatText
            />
          )}
          {permissions.viewLocaleHandlerState && (
            <HandlerIndicatorItem
              isDropTarget={isLocaleIndicatorIsDropTarget}
              isActive={activeEstimateStateId === ESTIMATE_STATES_IDS.LOCALE}
              isFullWidth
            >
              <div
                ref={!isBuildingShared ? dropLocale : null}
                className={cn(styles.item, { [styles.canDrop1]: canDropLocale })}
                onClick={handleOnClickByIndicator(ESTIMATE_STATES_IDS.LOCALE)}
              >
                <div
                  className={cn(styles.circle, {
                    [styles.active]: activeEstimateStateId === ESTIMATE_STATES_IDS.LOCALE,
                  })}
                >
                  <HandlerStateCount
                    count={aggregations?.locale_count ?? 0}
                    amount={locale}
                    plus={isLocaleIndicatorIsDropTarget && totalDropItemsAmount}
                    title="Сметный расчет"
                  />
                </div>
                <ApproveModal
                  activeEstimateStateId={activeEstimateStateId}
                  targetEstimateStateId={ESTIMATE_STATES_IDS.LOCALE}
                  isOpen={isLocaleIndicatorIsDropTarget}
                  onApprove={onApproveTransfer}
                  items={dropTarget?.item}
                  isFullWidth
                />
                {activeEstimateStateId === ESTIMATE_STATES_IDS.LOCALE && <span className={styles.border} />}
              </div>
            </HandlerIndicatorItem>
          )}
          {permissions.viewProductionHandlerState && (
            <ProductionIndicator
              sumEstimateAmount={aggregations.sum_estimate_amount}
              activeEstimateStateId={activeEstimateStateId}
              changeEstimateItemsState={changeEstimateItemsState}
              onClick={handleProductionClick}
              count={aggregations.confirmed_count}
              isHideInfo={isHideProductionIndicatorInfo}
              isShared={isBuildingShared}
              isFullWidth
            />
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(SimpleEstimateStatesIndicators);
