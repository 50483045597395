import classNames from "classnames";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Expandable from "components/UI/atoms/_TODO/Expandable/Expandable";

import { getTaskApprovers } from "../../model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";
import ApprovalBar from "shared/ui/dataDisplay/ApprovalBar/ApprovalBar";

import { ITaskApprover } from "../../model/types";

import getShortFullName from "utils/formatters/getShortFullName";

import Check from "images/icons/Check";

import styles from "./TaskApprovers.module.scss";

interface IProps {
  taskId: number;
  approvers: ITaskApprover[];
}

const TaskApproversView: React.FC<IProps> = ({ taskId, approvers }) => {
  const dispatch = useDispatch();
  const { invalidateKey } = useTypedSelector((state) => state.tasks);

  if (!approvers?.length) {
    return null;
  }

  return (
    <Expandable title="Согласование">
      <div className={styles.apprList}>
        {approvers?.map((el) => (
          <ApprovalBar
            isApproved={el.status === "approved"}
            key={el.id} /* @ts-ignore */
            workerName={getShortFullName(el.approver)} /* @ts-ignore */
            sendToApproveAt={el.created_at}
            approvedAt={el.approved_at}
          />
        ))}
      </div>
    </Expandable>
  );
};

export default TaskApproversView;
