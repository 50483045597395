import classNames from "classnames";
import React, { FC, ReactNode } from "react";

import styles from "./ObjectExtendedRowItem.module.scss";

interface IProps {
  className?: string;
  name: ReactNode;
  children?: ReactNode;
  percents?: number;
  title?: string | number;
  highlightPercentsOverflowing?: boolean;
}

const ObjectExtendedRowItem: FC<IProps> = ({
  className,
  name,
  children,
  percents,
  title,
  highlightPercentsOverflowing,
}) => {
  const highlightPercents = highlightPercentsOverflowing && (percents ?? 0) > 100;

  return (
    <div
      className={classNames(styles.root, className, percents !== undefined && styles.withPercents)}
      title={title ? String(title) : undefined}
    >
      <div className={styles.name}>{name}</div>
      {children}
      {percents !== undefined && (
        <div className={classNames(styles.percents, highlightPercents && styles.red)}>
          <span>{percents}</span>%
        </div>
      )}
    </div>
  );
};

export default ObjectExtendedRowItem;
