import React from 'react'
import styles from "./WorkSharingInfoItem.module.scss"

interface IProps {
    name: string;
    value?: string;
    className?: string;
    customInnerComponent?: React.ReactNode
}

const WorkSharingInfoItem: React.FC<IProps> = ({
    name,
    value,
    className,
    customInnerComponent
}) => {
  return (
    <div className={className}>
        <div className={styles.name}>{name}</div>
        {customInnerComponent}
        {!customInnerComponent && <div className={styles.value} title={value}>{value}</div>}
    </div>
  )
}

export default React.memo(WorkSharingInfoItem)