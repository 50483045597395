import cn from "classnames";
import React, { useState } from "react";

import EditTasksLists from "../EditTasksLists/EditTasksLists";
import ExpandIcon from "../ExpandIcon/ExpandIcon";
import ListOfTasks from "../ListOfTasks/ListOfTasks";
import ConfirmModal from "entities/ConfirmationModal/ConfirmModal";
import Actions from "shared/ui/controls/Actions/Actions";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { useCurrentTasksList } from "../../hooks/useCurrentTasksListData";
import { useTasksFilter } from "../../hooks/useTasksFilter";
import { useTasksListEdit } from "../../hooks/useTasksListEdit";
import { useTasksModal } from "../../hooks/useTasksModal";

import styles from "./TasksListHeader.module.scss";

type propsType = {
  id: number;
  name: string;
  count?: number;
  user: number;
  deadline_start?: string;
  deadline_end?: string;
  building_id?: string;
};

function TasksListHeader({ id, name, count = 0, user, deadline_end, deadline_start, building_id }: propsType) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { filters, filtersHandler } = useTasksFilter();

  const memoizedFilters = React.useMemo(() => {
    return { ...filters, deadline_end, deadline_start };
  }, [filters, deadline_end, deadline_start]);

  const { tasksByCurrentList, loadMoreTasksByListHandler, isCurrentTasksLoading } = useCurrentTasksList({
    listId: id,
    filters: memoizedFilters,
  });

  const expandHandler = React.useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const switchToEditingHandler = React.useCallback(() => {
    setIsEditing((prev) => !prev);
  }, []);

  const { editNameHandler, editTasksListHandler, deleteTasksListHandler } = useTasksListEdit({
    user,
    id,
    list_name: name,
    successEditCallback: switchToEditingHandler,
  });

  const { openAddModal } = useTasksModal();

  const handleAddTask = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      openAddModal({ placementType: "inLists", list_id: id });
    },
    [id]
  );

  return (
    <>
      {!isEditing && (
        <div className={styles.container} onClick={expandHandler}>
          <div>
            <ExpandIcon isExpanded={isExpanded} />
          </div>
          <div className={styles.name}>{name}</div>
          <div />
          <div>Задач: {tasksByCurrentList?.results?.length || 0}</div>
          <div>
            <ButtonBase primary onClick={handleAddTask} className={cn(styles.add, { [styles.addVisible]: isExpanded })}>
              Новая задача
            </ButtonBase>
          </div>
          <Actions canRemove onRemoveDirectly={() => setIsDeleting(true)} canEdit onEdit={switchToEditingHandler} />
        </div>
      )}
      {isEditing && (
        <EditTasksLists
          listId={id}
          name={name}
          onNameChange={editNameHandler}
          tasksCount={count}
          onClose={switchToEditingHandler}
          onSave={editTasksListHandler}
        />
      )}
      {isExpanded && (
        <ListOfTasks
          listId={id}
          building_id={building_id}
          filtersHandler={filtersHandler}
          tasksByCurrentList={tasksByCurrentList}
          loadMoreTasksByListHandler={loadMoreTasksByListHandler}
          isCurrentTasksLoading={isCurrentTasksLoading}
        />
      )}
      <ConfirmModal
        title="Удалить все задачи списка или расформировать список?"
        variant="secondary"
        acceptButtonText="Удалить"
        cancelButtonText="Расформировать"
        action={() => deleteTasksListHandler(true)}
        secondAction={() => deleteTasksListHandler(false)}
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
      ></ConfirmModal>
    </>
  );
}

export default TasksListHeader;
