import axios from "axios";

import { IBillDetailed } from "./types";

export const billsApi = {
  getDetailed: (invoiceId: string) => {
    return axios.get<IBillDetailed>(`/purchaser/invoices/${invoiceId}/`);
  },
  changeBillDate: (invoiceId: string, date: string) => {
    return axios.patch(`/purchaser/invoices/${invoiceId}/change-date/`, { date });
  },
  changePaymentDate: (invoiceId: string, paymentId: number, date: string) => {
    return axios.patch(`/purchaser/invoices/${invoiceId}/payments/${paymentId}/change-date/`, { date });
  },
  saveBillNumber: (invoiceId: string, number: number) => {
    return axios.post(`/purchaser/invoices/${invoiceId}/set-number/`, { number });
  },
  attachFilesToBill: (invoiceId: string, files: File[]) => {
    const data = new FormData();
    files.forEach((file) => {
      data.append("files", file);
    });
    return axios.post(`/purchaser/invoices/${invoiceId}/files/`, data, {
      headers: {
        "content-type": "miltipart/form-data",
      },
    });
  },
  deleteFileFromBill: (invoiceId: string, fileId: number) => {
    return axios.delete(`/purchaser/invoices/${invoiceId}/files/${fileId}/`);
  },
  payPayment: (invoiceId: string, paymentId: number) => {
    return axios.post(`/purchaser/invoices/${invoiceId}/payments/${paymentId}/pay/`);
  },
  addPaymentFile: (invoiceId: string, paymentId: number, file: File) => {
    const data = new FormData();
    data.append("file", file);
    return axios.patch(`/purchaser/invoices/${invoiceId}/payments/${paymentId}/change-file/`, data, {
      headers: {
        "content-type": "miltipart/form-data",
      },
    });
  },
};
