import React, { FC, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Spinner from "../../../../../../../../../../shared/ui/atoms/Spinner/Spinner";
import {
  activeSectionSelector,
  estimateStateIdSelector,
  invalidateKeySelector,
  subSectionsAreLoadingSelector,
} from "../../../../../../model/selectors";
import { loadSubSections } from "../../../../../../model/thunks";
import { ConfirmedStatusLocaleStateSubSectionsList } from "./ConfirmedStatusLocaleStateSubSectionsList/ConfirmedStatusLocaleStateSubSectionsList";
import { NewStatusLocaleStateSubSectionsList } from "./NewStatusLocaleStateSubSectionsList/NewStatusLocaleStateSubSectionsList";

import { EstimateItemsStatusesEnum } from "../../../../../../../ProHandler/enums";

import { useIsOpenedInApproveForm } from "../../../../../../hooks/useIsOpenedInApproveForm";
import { useStatusFromQueryParams } from "../../hooks/useStatusFromQueryParams";

/**
 * Список подразделов в стейте локального сметного расчета (ЛСР)
 * В зависимости от query параметра ?status=new|confirmed
 * имеет разные виды
 */
export const LocaleStateSubSectionsListView = memo(() => {
  const dispatch = useDispatch();

  const estimateStateId = useSelector(estimateStateIdSelector);
  const activeSection = useSelector(activeSectionSelector);
  const invalidateKey = useSelector(invalidateKeySelector);
  const subSectionsAreLoading = useSelector(subSectionsAreLoadingSelector);

  const status = useStatusFromQueryParams();

  const { buildingId } = useParams<{ buildingId?: string }>();

  useEffect(() => {
    if (buildingId && estimateStateId && activeSection && status) {
      dispatch(loadSubSections(buildingId, activeSection.id, estimateStateId, { with_child_status: status }));
    }
  }, [estimateStateId, buildingId, activeSection, invalidateKey, status]);

  if (subSectionsAreLoading) {
    return <Spinner isStatic />;
  }

  if (status === EstimateItemsStatusesEnum.NEW) {
    return <NewStatusLocaleStateSubSectionsList />;
  } else {
    return <ConfirmedStatusLocaleStateSubSectionsList />;
  }
});

LocaleStateSubSectionsListView.displayName = "LocaleStateSubSectionsListView";
