import cn from "classnames";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { ks6aTableMeasureSelector } from "../../../../../../../../../redux/modules/common/ks6a/ks6a";
import { Ks6aListItemType } from "../../../../../../../../../redux/modules/common/ks6a/types";

import { computeCost, costToRubs } from "../../../../constants";
import { LOCALIZATION_CONFIG } from "constants/localization";

import { countFormatter } from "../../utils";

import styles from "./Ks6aYearTotal.module.scss";

export interface IKs6aYearTotalProps {
  list: Ks6aListItemType[];
  year: number;
}

const Ks6aYearTotal: React.FC<IKs6aYearTotalProps> = ({ list, year }) => {
  const tableMeasure = useSelector(ks6aTableMeasureSelector);

  const { yearCount, yearAmount } = useMemo(() => {
    let yearCount = 0;
    let yearAmount = 0;

    list.forEach(({ month_data }) => {
      const { year_count, year_amount } = month_data;
      if (year_count) yearCount += +year_count;
      if (year_amount) yearAmount += +year_amount;
    });

    return { yearCount, yearAmount };
  }, [list]);

  return (
    <div className={styles.yearTotal}>
      <div className={styles.yearTotalHeader}>
        <div className={styles.yearTotalLabel}>За {year} год</div>
        <div className={styles.yearTotalCount}>Кол-во</div>
        <div className={styles.yearTotalCost}>Стоимость {LOCALIZATION_CONFIG.currency}</div>
      </div>

      {list?.length > 0 && (
        <>
          {list.map((work: Ks6aListItemType, index: number) => {
            const { year_count, year_amount } = work.month_data;
            return (
              <div className={styles.yearTotalRow} key={index}>
                <div className={cn(styles.yearTotalRowCount, styles.count)}>{countFormatter(year_count)}</div>
                <div className={cn(styles.yearTotalRowCost, styles.cost)}>{computeCost(year_amount, tableMeasure)}</div>
              </div>
            );
          })}
          <div className={styles.yearTotalFooter}>
            <div className={styles.count}>{countFormatter(yearCount)}</div>
            <div className={styles.cost}>{costToRubs(yearAmount, tableMeasure)}</div>
            <div className={styles.count}>&nbsp;</div>
            <div className={styles.cost}>&nbsp;</div>
            <div className={styles.count}>{countFormatter(yearCount)}</div>
            <div className={styles.cost}>{costToRubs(yearAmount, tableMeasure)}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(Ks6aYearTotal);
