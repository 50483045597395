import moment from "moment";

import { IUsePlanApproveLinkArgs } from "../../../types/types";
import { IUseOpenFactApproveLinkArgs } from "../../../types/types";
import {
  VIEW_BILLS_LIST as VIEW_CONSTRUCTING_BILL_LIST,
  VIEW_EXPORT_LIST as VIEW_CONSTRUCTING_EXPORT_LIST,
  VIEW_PACKING_LISTS as VIEW_CONSTRUCTING_PACKING_LISTS,
} from "constants/permissions/constructingPermissions";
import {
  VIEW_MANUFACTURING_BILL_SECTION as VIEW_MANUFACTURING_BILL_LIST,
  VIEW_MANUFACTURING_ORDER_SECTION,
  VIEW_MANUFACTURING_PACKING_LIST_SECTION as VIEW_MANUFACTURING_PACKING_LISTS,
  VIEW_MANUFACTURING_REQUISITION_SECTION as VIEW_MANUFACTURING_REQUISITION_LIST,
} from "constants/permissions/manufacturingPermissions";
import {
  ORDERS_SECTION_VIEW as ORDERS_PURCHASES_SECTION_VIEW,
  VIEW_BILLS_LIST as VIEW_PURCHASES_BILL_LIST,
  VIEW_EXPORT_LIST as VIEW_PURCHASES_EXPORT_LIST,
  VIEW_PACKING_LISTS as VIEW_PURCHASES_PACKING_LISTS,
  VIEW_REQUISITIONS_LIST as VIEW_PURCHASES_REQUISITIONS_LIST,
} from "constants/permissions/purchasesPermissions";
import { isShowChatParam } from "widgets/Chat/constants";
import { chatRoomTypes } from "widgets/Chat/model/types";

import usePermission from "hooks/usePermission";

import { findChatTypePage } from "../utils/findChatTypePage";
import { returnMonthNumber } from "../utils/returnMonthNumber";

export const useOrderLink = (order_id: number, building_id: number) => {
  const haveViewPurchasesOrderSectionPermission = usePermission(ORDERS_PURCHASES_SECTION_VIEW);
  const haveViewManufacturingOrderSectionPermission = usePermission(VIEW_MANUFACTURING_ORDER_SECTION);
  if (haveViewPurchasesOrderSectionPermission) return `/purchases/orders/${order_id}`;
  if (haveViewManufacturingOrderSectionPermission) return `/objects/${building_id}/orders/${order_id}`;

  return `/objects/${building_id}/orders/${order_id}`;
};

export const useChatLink = (link: string) => {
  return `${link}/?${isShowChatParam}=true`;
};

export const useChatV2Link = (chat_type: chatRoomTypes, building_id: number) => {
  return `/objects/${building_id}/${findChatTypePage(chat_type)}/?${isShowChatParam}=true`;
};

export const useExportLink = () => {
  const haveViewConstructingExportListPermission = usePermission(VIEW_CONSTRUCTING_EXPORT_LIST);
  const haveViewPurchasesOrderSectionPermission = usePermission(VIEW_PURCHASES_EXPORT_LIST);

  if (haveViewConstructingExportListPermission) return "/constructing/documents/export";
  if (haveViewPurchasesOrderSectionPermission) return "/purchases/documents/export";

  return "/purchases/documents/export";
};

export const usePartnerInviteLink = () => "/constructing/counterparties/invites";

export const usePackingListLink = (packinglist_id: number, building_id: number) => {
  const haveViewPurchasesPackingListPermission = usePermission(VIEW_PURCHASES_PACKING_LISTS);
  const haveViewManufacturingPackingListPermission = usePermission(VIEW_MANUFACTURING_PACKING_LISTS);
  const haveViewConstructingPackingListPermission = usePermission(VIEW_CONSTRUCTING_PACKING_LISTS);

  if (haveViewPurchasesPackingListPermission) return `/purchases/documents/packing-lists/${packinglist_id}`;
  if (haveViewManufacturingPackingListPermission)
    return `/objects/${building_id}/documents/packing-lists/${packinglist_id}`;
  if (haveViewConstructingPackingListPermission) return `/constructing/documents/packing-lists/${packinglist_id}`;

  return `/constructing/documents/packing-lists/${packinglist_id}`;
};

export const usePaymentLink = (invoice_id: number, building_id: number) => {
  const haveViewPurchasesBillListPermission = usePermission(VIEW_PURCHASES_BILL_LIST);
  const haveViewManufacturingBillListPermission = usePermission(VIEW_MANUFACTURING_BILL_LIST);
  const haveViewConstructingBillListPermission = usePermission(VIEW_CONSTRUCTING_BILL_LIST);

  if (haveViewPurchasesBillListPermission) return `/purchases/documents/bills/${invoice_id}`;
  if (haveViewManufacturingBillListPermission) return `/objects/${building_id}/documents/bills/${invoice_id}`;
  if (haveViewConstructingBillListPermission) return `/constructing/documents/bills/${invoice_id}`;

  return `/constructing/documents/bills/${invoice_id}`;
};

export const useRequisitionLink = (requisition_id: number, building_id: number) => {
  const haveViewPurchasesRequisitionListPermission = usePermission(VIEW_PURCHASES_REQUISITIONS_LIST);
  const haveViewManufacturingRequisitionListPermission = usePermission(VIEW_MANUFACTURING_REQUISITION_LIST);

  if (haveViewPurchasesRequisitionListPermission) return `/purchases/requisitions/in-work/${requisition_id}`;
  if (haveViewManufacturingRequisitionListPermission)
    return `/objects/${building_id}/requisitions/in-work/${requisition_id}`;

  return `/objects/${building_id}/requisitions/in-work/${requisition_id}`;
};

export const useTasksTrackLink = (id: number) => `/tasks/all/0/${id}`;

export const useRemarkLink = (building_id: number, remark_id: number, section_id: number) =>
  `/objects/${building_id}/journal/remarks?sectionId=${section_id}&remarkId=${remark_id}`;

export const useStateLink = (building_id?: number, state?: string) =>
  `/constructing/handler/${building_id}?state=${state}`;

export const useShiftLink = (buildingId: string, datetime: string) => {
  const date = moment(datetime).format("YYYY-MM-DD");
  return `/constructing/manufacturing/${buildingId}?openShiftModal=1&openShiftModalDate=${date}`;
};

export const useTimeSheetLink = (id: number, month: string) => {
  return `/objects/${id}/workers/timesheet?month=${returnMonthNumber(month)}`;
};

export const usePlanApproveLink = ({
  building_id,
  plan_type,
  plan_id,
  parent_section_id,
  child_section_id,
  expenditure_id,
  group_id,
}: IUsePlanApproveLinkArgs) => {
  const params = new URLSearchParams({
    openPlanApprove: "1",
    plan_type: plan_type?.toString(),
    plan_id: plan_id?.toString(),
    parent_section_id: parent_section_id?.toString(),
    child_section_id: child_section_id?.toString(),
  });

  if (plan_type === "group") {
    params.append("group_id", group_id?.toString());
  }

  if (plan_type === "work") {
    params.append("expenditure_id", expenditure_id?.toString());
  }

  return `/objects/${building_id}/estimate/?${params.toString()}`;
};

export const useOpenFactApproveLink = ({
  buildingId,
  sectionId,
  factId,
  factType,
  year,
  month,
}: IUseOpenFactApproveLinkArgs) => {
  return `/objects/${buildingId}/journal/delivery/?sectionId=${sectionId}&openFactApprove=1&fact_id=${factId}&fact_type=${factType}&year=${year}&month=${month}`;
};
