import { Moment } from "moment";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTimeout } from "timers";

import { chartActions } from "redux/modules/common/chart/actions";
import {
  chartScrollMarkersSelector,
  chartScrollMonthSelector,
  chartScrollTouchedYearsSelector,
  chartScrollYearSelector,
  chartViewModeSelector,
} from "redux/modules/common/chart/selectors";
import { CHART_VIEW_MODE, TouchedYearsUpdateOptions } from "redux/modules/common/chart/types";

import { useRem } from "components/pages/Manufacturing/hooks/useRem";

import { useChartUnitMultiplier } from "./useChartUnitMultiplier";

import { daysInYear } from "../utils/daysInYear";
import { weeksInYear } from "../utils/weeksInYear";

export interface IUseChartScrollProps {
  calendarRef: React.RefObject<HTMLElement>;
  isLoading?: boolean;
}

export const useChartScroll = ({ calendarRef, isLoading }: IUseChartScrollProps) => {
  const dispatch = useDispatch();
  const monthMarkersByYears = useSelector(chartScrollMarkersSelector);
  const touchedYears = useSelector(chartScrollTouchedYearsSelector);
  const year = useSelector(chartScrollYearSelector);
  const month = useSelector(chartScrollMonthSelector);
  const isObserve = useRef(false);
  const dateChangeObserverTimeout = useRef<any>();
  const unitMultiplier = useChartUnitMultiplier();
  const chartViewMode = useSelector(chartViewModeSelector);

  const setTouchedYears = useCallback((ty: number[], options?: TouchedYearsUpdateOptions) => {
    dispatch(chartActions.setTouchedYears(ty, options));
  }, []);

  const setYear = useCallback((y: number) => {
    dispatch(chartActions.setYear(y));
  }, []);

  const setMonth = useCallback((m: number) => {
    dispatch(chartActions.setMonth(m));
  }, []);

  const { REM } = useRem();

  const scrollCalendar = (units: number) => {
    if (!calendarRef.current) return;
    calendarRef.current.scrollLeft += units * unitMultiplier * REM;
  };

  const scrollToStartMonth = (year: number, month: number, scrollOptions: ScrollIntoViewOptions = {}) => {
    const elementCandidate = calendarRef.current?.querySelector(
      `.startMonth[data-year='${year}'][data-month='${month}']`
    );
    elementCandidate?.scrollIntoView({
      behavior: "auto",
      block: "nearest",
      inline: "center",
      ...scrollOptions,
    });
  };

  useEffect(() => {
    if (!calendarRef.current || isLoading) return;
    isObserve.current = false;
    // @ts-ignore
    scrollToStartMonth(year, month, { behavior: "instant" });
    setTimeout(() => (isObserve.current = true), 1000);
  }, [isLoading, calendarRef.current, chartViewMode]);

  useEffect(() => {
    if (month === undefined || !year || !calendarRef.current || !monthMarkersByYears?.[year] || isLoading) return;

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      if (!isObserve.current) return;
      try {
        const entriesInView = entries.filter((x) => x.isIntersecting);
        if (!entriesInView.length) return;

        const datesInViewPool: { year: string; month: string }[] = [];

        const counts: Record<string, number> = {};

        entriesInView.forEach((entry) => {
          const entryYear = entry.target.attributes.getNamedItem("data-year")?.value;
          const entryMonth = entry.target.attributes.getNamedItem("data-month")?.value;
          if (entryYear === undefined || entryMonth === undefined) return;
          datesInViewPool.push({ year: entryYear, month: entryMonth });
        });

        if (!datesInViewPool?.length) return;

        datesInViewPool.forEach((x) => {
          const key = JSON.stringify(x);
          counts[key] = (counts[key] || 0) + 1;
        });

        const mostRecentDateEntry = Object.entries(counts).sort((a, b) => b[1] - a[1])[0];

        if (!mostRecentDateEntry) return;

        const dateCandidate = JSON.parse(mostRecentDateEntry[0]);

        if (!dateCandidate) return;

        const prevYear = +dateCandidate.year - 1;
        const nextYear = +dateCandidate.year + 1;

        if (
          (+dateCandidate.month >= 10 ||
            (chartViewMode === CHART_VIEW_MODE.MONTHS && datesInViewPool.findIndex((x) => +x.month === 11) !== -1)) &&
          !touchedYears.includes(nextYear)
        ) {
          setTouchedYears([...touchedYears, nextYear]);
        }
        if (
          (+dateCandidate.month <= 1 ||
            (chartViewMode === CHART_VIEW_MODE.MONTHS && datesInViewPool.findIndex((x) => +x.month === 11) !== -1)) &&
          !touchedYears.includes(prevYear)
        ) {
          setTouchedYears([prevYear, ...touchedYears]);
          if (calendarRef.current?.parentElement) {
            calendarRef.current.parentElement.style.scrollBehavior = "auto";
            calendarRef.current.parentElement.scrollLeft +=
              (chartViewMode === CHART_VIEW_MODE.MONTHS ? weeksInYear(prevYear) : daysInYear(prevYear)) *
              unitMultiplier *
              REM;
            calendarRef.current.parentElement.style.scrollBehavior = "smooth";
          }
        }
        setYear(+dateCandidate.year);
        setMonth(+dateCandidate.month);
      } catch (e) {
        console.warn(e);
      }
    };

    const scrollObserver =
      chartViewMode === CHART_VIEW_MODE.MONTHS
        ? new IntersectionObserver(observerCallback, {
            rootMargin: "100% 0px 100% -35%",
            threshold: 0.5,
          })
        : new IntersectionObserver(observerCallback, {
            rootMargin: "100% -30% 100% -65%",
            threshold: 0.01,
          });

    try {
      Array.from(document.querySelectorAll(`[data-year][data-month]`))?.forEach((el) => scrollObserver.observe(el));
    } catch (e) {
      console.error(e);
    }
    return () => {
      scrollObserver.disconnect();
    };
  }, [calendarRef.current, monthMarkersByYears, touchedYears, year, isLoading, REM, unitMultiplier, chartViewMode]);

  const handleDateChange = (date: Moment) => {
    isObserve.current = false;
    if (dateChangeObserverTimeout.current) {
      clearTimeout(dateChangeObserverTimeout.current);
      dateChangeObserverTimeout.current = undefined;
    }
    const yearCandidate = date.year();
    const monthCandidate = date.month();
    // @ts-ignore
    scrollToStartMonth(yearCandidate, monthCandidate, { behavior: "instant" });
    if (monthCandidate !== month) {
      setMonth(monthCandidate);
    }
    if (yearCandidate !== year) {
      if (touchedYears.indexOf(yearCandidate) === -1) {
        setTouchedYears([yearCandidate - 1, yearCandidate, yearCandidate + 1], { dropMarkers: true });
      }
      setYear(yearCandidate);
    }
    dateChangeObserverTimeout.current = setTimeout(() => (isObserve.current = true), 1000);
  };

  return {
    handleDateChange,
    scrollCalendar,
  };
};
