import React from "react";

const ArrowSmallUpGrey = ({ width = "11.082", height = "5.54", color = "#727272", rotate = "0", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 11.082 5.54"
      style={{ transform: `rotate(${rotate}deg)` }}
      {...props}
    >
      <path
        id="Контур_5994"
        data-name="Контур 5994"
        d="M430.447,20l-5.54,5.541,5.54,5.541"
        transform="translate(31.082 -424.907) rotate(90)"
        fill={color}
      />
    </svg>
  );
};

export default React.memo(ArrowSmallUpGrey);