import axios from "axios";

import { IConstControlAccForObject } from "./types";
import { IListResponseData } from "types/ListResponse";

export const settingsObjectApi = {
  getConstrControlAccounts: (objectId: string) => {
    return axios.get<IListResponseData<IConstControlAccForObject>>(
      `/construction_control/building/${objectId}/accounts/`,
      { params: { limit: 500 } }
    );
  },
};
