import { IChartFilters, IChartTree } from "redux/modules/common/chart/types";

import { stringToFilterable } from "./stringToFilterable";

export const checkSatisfyFilters = (tree: IChartTree, filters: IChartFilters) => {
  if (filters.skipBranchLvl && filters.skipBranchLvl[tree.lvl]) {
    return false;
  }
  if (
    filters.searchText &&
    !stringToFilterable(tree.name || "").includes(filters.searchText) &&
    !stringToFilterable(tree.number?.toString() || "").includes(filters.searchText)
  ) {
    return false;
  }
  if (filters.dateStart) {
    if (!tree.plan_start || +new Date(tree.plan_start || "") < +new Date(filters.dateStart)) {
      return false;
    }
  }
  if (filters.dateEnd) {
    if (!tree.plan_end || +new Date(tree.plan_end || "") > +new Date(filters.dateEnd)) {
      return false;
    }
  }
  return true;
};
