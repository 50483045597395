import React from "react";

export const calendar = (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 3.07422H17V14.0742C17 14.8699 16.6839 15.6329 16.1213 16.1955C15.5587 16.7581 14.7956 17.0742 14 17.0742H3C2.20435 17.0742 1.44129 16.7581 0.87868 16.1955C0.31607 15.6329 0 14.8699 0 14.0742L0 3.07422Z"
      fill="white"
    />
    <path
      d="M0.5 3.57422H16.5V14.0742C16.5 14.7373 16.2366 15.3731 15.7678 15.842C15.2989 16.3108 14.663 16.5742 14 16.5742H3C2.6717 16.5742 2.34661 16.5096 2.04329 16.3839C1.73998 16.2583 1.46438 16.0741 1.23223 15.842C0.763392 15.3731 0.5 14.7373 0.5 14.0742V3.57422Z"
      stroke="#BABABA"
    />
    <path
      d="M4 6.57422H3C2.72386 6.57422 2.5 6.79808 2.5 7.07422V8.07422C2.5 8.35036 2.72386 8.57422 3 8.57422H4C4.27614 8.57422 4.5 8.35036 4.5 8.07422V7.07422C4.5 6.79808 4.27614 6.57422 4 6.57422Z"
      stroke="#BABABA"
      strokeDasharray="1 1"
    />
    <path
      d="M9 6.07422H8C7.44772 6.07422 7 6.52193 7 7.07422V8.07422C7 8.6265 7.44772 9.07422 8 9.07422H9C9.55228 9.07422 10 8.6265 10 8.07422V7.07422C10 6.52193 9.55228 6.07422 9 6.07422Z"
      fill="white"
    />
    <path
      d="M9 6.57422H8C7.72386 6.57422 7.5 6.79808 7.5 7.07422V8.07422C7.5 8.35036 7.72386 8.57422 8 8.57422H9C9.27614 8.57422 9.5 8.35036 9.5 8.07422V7.07422C9.5 6.79808 9.27614 6.57422 9 6.57422Z"
      stroke="#BABABA"
    />
    <path
      d="M14 6.07422H13C12.4477 6.07422 12 6.52193 12 7.07422V8.07422C12 8.6265 12.4477 9.07422 13 9.07422H14C14.5523 9.07422 15 8.6265 15 8.07422V7.07422C15 6.52193 14.5523 6.07422 14 6.07422Z"
      fill="white"
    />
    <path
      d="M14 6.57422H13C12.7239 6.57422 12.5 6.79808 12.5 7.07422V8.07422C12.5 8.35036 12.7239 8.57422 13 8.57422H14C14.2761 8.57422 14.5 8.35036 14.5 8.07422V7.07422C14.5 6.79808 14.2761 6.57422 14 6.57422Z"
      stroke="#BABABA"
    />
    <path
      d="M9 11.0742H8C7.44772 11.0742 7 11.5219 7 12.0742V13.0742C7 13.6265 7.44772 14.0742 8 14.0742H9C9.55228 14.0742 10 13.6265 10 13.0742V12.0742C10 11.5219 9.55228 11.0742 9 11.0742Z"
      fill="white"
    />
    <path
      d="M9 11.5742H8C7.72386 11.5742 7.5 11.7981 7.5 12.0742V13.0742C7.5 13.3504 7.72386 13.5742 8 13.5742H9C9.27614 13.5742 9.5 13.3504 9.5 13.0742V12.0742C9.5 11.7981 9.27614 11.5742 9 11.5742Z"
      stroke="#BABABA"
    />
    <path
      d="M4 11.5742H3C2.72386 11.5742 2.5 11.7981 2.5 12.0742V13.0742C2.5 13.3504 2.72386 13.5742 3 13.5742H4C4.27614 13.5742 4.5 13.3504 4.5 13.0742V12.0742C4.5 11.7981 4.27614 11.5742 4 11.5742Z"
      stroke="#BABABA"
      strokeDasharray="1 1"
    />
    <path
      d="M16.4992 3.7687H0.5V2.9997C0.5 1.65467 1.56779 0.554358 2.9002 0.501954V1.2861C2.9002 2.265 3.69619 3.0614 4.6746 3.0614C5.6535 3.0614 6.4499 2.265 6.4499 1.2861V0.5H10.5493V1.2861C10.5493 2.265 11.3457 3.0614 12.3246 3.0614C13.3035 3.0614 14.0999 2.265 14.0999 1.2861V0.50199C15.4318 0.554904 16.4992 1.65501 16.4992 2.9997V3.7687Z"
      fill="#BABABB"
    />
    <path
      d="M15.9992 3.2687V2.9997C15.9992 2.10616 15.4101 1.34762 14.5999 1.09206V1.2861C14.5999 2.13562 14.1318 2.87793 13.4401 3.2687H15.9992ZM11.2091 3.2687C10.5174 2.87793 10.0493 2.13562 10.0493 1.2861V1H6.9499V1.2861C6.9499 2.13562 6.48184 2.87793 5.79014 3.2687H11.2091ZM3.5595 3.2687C2.86807 2.87793 2.4002 2.13562 2.4002 1.2861V1.09178C1.58952 1.34705 1 2.10582 1 2.9997V3.2687H3.5595ZM16.9992 4.2687H0V2.9997C0 1.34566 1.34566 0 2.9997 0H3.4002V1.2861C3.4002 1.9893 3.97189 2.5614 4.6746 2.5614C5.3778 2.5614 5.9499 1.9893 5.9499 1.2861V0H11.0493V1.2861C11.0493 1.9893 11.6214 2.5614 12.3246 2.5614C13.0278 2.5614 13.5999 1.9893 13.5999 1.2861V0H13.9995C15.6535 0 16.9992 1.34566 16.9992 2.9997V4.2687Z"
      fill="#BABABA"
    />
  </svg>
);
