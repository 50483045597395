import { AosrTypes, IAosrDoc } from "../aosr/types";
import { IExpenditure } from "types/interfaces/Expenditure";
import { IListResponseData } from "types/ListResponse";

export interface IAookState {
  currentAook: IAook | null;
  detailedAooks: Record<string, IAook>;
  worksBySections: Record<number, IExpenditure[]>;
  docs: Record<string, IListResponseData<IAosrDoc>>;
  isClosingPending: Record<string, boolean>;
  invalidateKey: number;
  isCreatingPending: Record<string, boolean>;
}

export enum AookPermitsEnum {
  use = "use",
  partially = "partially",
  on_condition = "on_condition",
}

export enum AookTypes {
  FOR_EXP = "for_exp",
  FOR_GROUP = "for_group",
  FOR_GROUP_EXP = "for_group_exp",
}

export interface ICreateAook {
  type: AookTypes;
  number: string;
  act_date: string;
  start_date: string; ///////////////////////
  end_date: string; ////////////////////////
  copy_count?: string;
  //
  structure_name: string;
  project_doc: string;
  accordance: string;
  additional?: string;
  permit: AookPermitsEnum;
  permit_partially?: string;
  permit_condition?: string;
  next?: string;
  next_expenditure_id?: number;
  next_group_id?: number;
  //attachment to:
  expenditure?: number;
  group?: number;
  //
  materials: number[];
  hiddenworks: number[];
  docs?: any[];
}

export interface IAook {
  id: number;
  type: AosrTypes;
  status: "draft" | "close";
  number: string;
  act_date: string;
  start_at: string;
  end_at: string;
  copy_count: string;
  structure_name: string;
  project_doc: string;
  accordance: string;
  additional: string;
  permit: AookPermitsEnum;
  permit_partially: string;
  permit_condition: string;
  next: string;
  next_expenditure: null;
  next_group: null;
  docs: [];
  expenditure?: number;
  group?: number;
  all_materials: [];
  materials: [];
  all_hiddenworks: {
    id: number;
    number: string;
    act_date: string;
    title: string;
  }[];
  hiddenworks: number[];
}

export interface IAookDoc {
  id: number;
  aook: number;
  title: string;
  doc_date: string;
  doc_type: string;
  listfiles: {
    id: 203;
    aook_doc: 216;
    originalname: string;
    file_path: string;
  }[];
}

export interface ICreateAookDoc extends Omit<IAookDoc, "id" | "listfiles"> {
  files: File[];
}
