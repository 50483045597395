import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import AddContractorModal from "components/pages/Settings/EditProject/components/AddContractorModal/AddContractorModal";
import { projectsApi } from "pages/Projects/model/api";
import { projectsActions } from "pages/Projects/model/projectsSlice";
import { ConstructionControlSuffix, IDetailedProject } from "pages/Projects/model/types";
import { getConstControlAccounts } from "pages/Settings/Project/model/thunks";

import { useTypedSelector } from "app/store/typedUseSelector";

import { errorCatcher } from "utils/helpers/errorCatcher";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  type: ConstructionControlSuffix;
  project?: IDetailedProject;
}

const ProjectSettingsControlModal: React.FC<IProps> = ({ isOpen, onClose, type, project }) => {
  const { projectId } = useParams<any>();
  const dispatch = useDispatch();
  const { constrControlAccounts } = useTypedSelector((state) => state.settingsReducer.settingsProjectReducer);

  const options = useMemo(() => {
    if (!constrControlAccounts?.length) return [];
    const target = project?.[type] ?? [];
    return constrControlAccounts.filter((el) => !target.some((targ) => targ.organization_id === el.id));
  }, [constrControlAccounts, project, type]);

  useEffect(() => {
    dispatch(getConstControlAccounts());
  }, []);

  const [isPending, setIsPending] = useState(false);
  const onSubmit = (ids: number[]) => {
    setIsPending(true);
    const reqs = ids.map((el) => projectsApi.addConstControl(projectId, type, el));
    Promise.all(reqs)
      .then(() => {
        dispatch(projectsActions.invalidateKey());
        onClose();
      })
      .catch(errorCatcher)
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <AddContractorModal
      isOpen={isOpen}
      onClose={onClose}
      contractors={options as any}
      contractorsCount={0}
      addContractors={onSubmit}
      handleChangeFilter={() => {}}
      handleLoadMore={() => {}}
      isPending={isPending}
      hideContractorInfo
    />
  );
};

export default ProjectSettingsControlModal;
