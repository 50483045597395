import axios from "axios";
import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";
import { IAggregationData } from "./types";


export const aggregationsApi = {
  getAggregations: (objectId: string | number) => {
    return axios
      .get<IAggregationData>(`building/${objectId}/estimate/aggregations/`)
      .then(({ data }) => data)
      .catch(errorCatcher);
  },
}
