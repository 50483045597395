import axios from "axios";

import {
  IGetSimpleResourcesArgs,
  ISimpleResource,
  ISimpleResourceCreate,
  ISimpleResourceUpdate,
} from "../types/simpleResources";

interface IGetSimpleResourcesResponse {
  results: ISimpleResource[];
}

interface ISimpleResourcesAPI {
  getAll: (options: IGetSimpleResourcesArgs) => Promise<IGetSimpleResourcesResponse>;
  create: (resource: ISimpleResourceCreate, buildingId: string | number) => Promise<ISimpleResource>;
  getById: (id: number, buildingId: string | number) => Promise<ISimpleResource | null>;
  updateById: (resource: ISimpleResourceUpdate, id: number, buildingId: number | string) => Promise<ISimpleResource>;
  deleteById: (id: number, buildingId: string | number) => Promise<null>;
}

export const simpleResourcesAPI: ISimpleResourcesAPI = {
  getAll: ({ buildingId, ...params }) =>
    axios
      .get<{ results: ISimpleResource[] }>(`/building/${buildingId}/simple_resources/`, { params })
      .then(({ data }) => data),

  create: (resource, buildingId) => {
    if (!resource.fact_group && !resource.fact_work) {
      return Promise.resolve();
    }
    return axios.post(`building/${buildingId}/simple_resources/`, resource).then(({ data }) => data);
  },

  getById: (id, buildingId) => axios.get(`building/${buildingId}/simple_resources/${id}/`).then(({ data }) => data),

  updateById: (resource, id, buildingId) =>
    axios.patch(`building/${buildingId}/simple_resources/${id}/`, resource).then(({ data }) => data),

  deleteById: (id, buildingId) => axios.delete(`building/${buildingId}/simple_resources/${id}/`).then(() => null),
};
