import { IPostAction } from "./actions";

import { IPostsState } from "./types";

const initialPostsState: IPostsState = {
  allPostsList: [],
  allPostsDetailedList: [],
  postInModal: null,
  loadings: {
    allPostsList: false,
    postInModal: false,
    allPostsDetailedList: false,
  },
};

export default (state = initialPostsState, action: IPostAction): IPostsState => {
  const { payload, type } = action;
  switch (type) {
    case "posts/SET_POSTS_DETAILED_LIST":
      return { ...state, allPostsDetailedList: payload };
    case "posts/SET_POSTS_LIST":
      return { ...state, allPostsList: payload };
    case "posts/ADD_NEW_POST_TO_LIST":
      return { ...state, allPostsList: [...state.allPostsList, payload] };
    case "posts/REMOVE_POST_FROM_LIST":
      return { ...state, allPostsList: state.allPostsList.filter((post) => post.id !== payload) };
    case "posts/UPDATE_POST_IN_LIST":
      return {
        ...state,
        allPostsList: state.allPostsList.map((post) => {
          if (post.id === payload.postId) return { ...post, ...payload.data };
          return post;
        }),
      };
    case "posts/SET_POST_IN_MODAL":
      return { ...state, postInModal: payload };
    case "posts/SET_POST_IN_MODAL_LOADING":
      return { ...state, loadings: { ...state.loadings, postInModal: payload } };
    case "posts/SET_POSTS_LIST_LOADING":
      return { ...state, loadings: { ...state.loadings, allPostsList: payload } };
    case "posts/SET_POSTS_DETAILED_LIST_LOADING":
      return { ...state, loadings: { ...state.loadings, allPostsDetailedList: payload } };
    case "posts/CLEAR_POST_IN_MODAL":
      return { ...state, postInModal: null, loadings: { ...state.loadings, postInModal: false } };
    case "posts/CLEAR_DATA":
      return { ...initialPostsState };
    default:
      return state;
  }
};
