import axios from "axios";
import { compose } from "redux";

import { setCommentsAction, setCommentsLoadingAction } from "../actions";

import { DEFAULT_QUERY_PARAMS } from "../constants";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

const getCommentsByItemsIds = (comments) => {
  const commentsByItemsIds = {};
  comments.forEach((comment) => {
    if (!commentsByItemsIds[comment.item_id]) commentsByItemsIds[comment.item_id] = [];
    commentsByItemsIds[comment.item_id].push(comment);
  });
  return commentsByItemsIds;
};

export const getRequisitionComments = (requisitionId) => (dispatch) => {
  axios
    .get(`/requisition/${requisitionId}/products/comments/`, { params: DEFAULT_QUERY_PARAMS })
    .then((response) => {
      compose(dispatch, setCommentsAction)(getCommentsByItemsIds(response.data.results));
      compose(dispatch, setCommentsLoadingAction)(false);
    })
    .catch(errorCatcher);
};
