import React from "react";

import { IWorker } from "redux/modules/common/building/processTypes";

import { IIdAndName } from "types/idAndName";

import getShortFullName from "../../../../../../utils/formatters/getShortFullName";

import styles from "./WorkersList.module.scss";

export interface IProps {
  workers: IWorker[] | IIdAndName[];
}

const WorkersList: React.FC<IProps> = ({ workers = [] }) => {
  return (
    <div className={styles.workers}>
      <b>Исполнители: {workers?.length}</b>
      <div className={styles.workersList}>
        {workers?.length ? (
          workers?.map((el) => (
            <div
              key={(el as IWorker).worker_id || el.id}
              className={styles.worker} /* @ts-ignore */
              title={el.name || getShortFullName(el)}
            >
              {el.name || getShortFullName(el as any)}
            </div>
          ))
        ) : (
          <div className={styles.emptyWorkers}>Исполнители не назначены</div>
        )}
      </div>
    </div>
  );
};

export default React.memo(WorkersList);
