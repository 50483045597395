import { useSelector } from "react-redux";

import { estimateStateIdSelector } from "../model/selectors";

import { IExpenditure } from "../../../../../types/interfaces/Expenditure";
import { EEstimateStatesIds } from "../../ProHandler/enums";

export const useExpenditurePermissions = (expenditure: IExpenditure) => {
  const activeEstimateStateId = useSelector(estimateStateIdSelector);

  const isDraft = activeEstimateStateId === EEstimateStatesIds.DRAFT;
  const isLocale = activeEstimateStateId === EEstimateStatesIds.LOCALE;
  const isProduction = activeEstimateStateId === EEstimateStatesIds.PRODUCTION;

  const canEdit = isDraft || (isProduction && !!expenditure.is_default);

  const canDelete = activeEstimateStateId !== EEstimateStatesIds.LOCALE && expenditure.current_state === "";

  return {
    canEdit,
    canDelete,
  };
};
