import Features3Svg from "./img/features3.svg";
import Features5Svg from "./img/features5.svg";
import Features6Svg from "./img/features6.svg";
import Features7Svg from "./img/features7.svg";


export const PlanningSection = () => (
  <section className="planning__main" id="list">
    <div className="container">
      <div className="planning__flex">
        <div className="planning__box">
          <img src={Features5Svg} alt="icon" />
            <span>Планирование бюджета</span>
            <p>Планирование производственных расходов, согласование плана-графика и ресурсов для формирования
              бюджета</p>
        </div>
        <div className="planning__box">
          <img src={Features3Svg} alt="icon" />
            <span>Мониторинг и контроль</span>
            <p>Мониторинг ГПР, факта выполнения работ, расходов на ресурсы в режиме реального времени</p>
        </div>
        <div className="planning__box">
          <img src={Features6Svg} alt="icon" />
            <span>Анализ деятельности</span>
            <p>Мониторинг и анализ плана-факта и ключевых показателей по объекту за период</p>
        </div>
        <div className="planning__box">
          <img src={Features7Svg} alt="icon" />
            <span>Задачи и контроль исполнения</span>
            <p>Постановка и контроль выполнения задач сотрудникам, организация совещаний</p>
        </div>
      </div>
    </div>
  </section>
)