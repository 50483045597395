import moment from "moment";

import { generateWeeksNumbersInMonth } from "components/pages/Manufacturing/utils";

const nowMoment = moment();

export const generateMonthsForLine = (year: number) => {
  const months = [];

  for (let m = 0; m < 12; m++) {
    const monthMoment = moment().year(year).month(m);
    const startOfMonthMoment = moment(monthMoment).startOf("month");
    const endOfMonthMoment = moment(monthMoment).endOf("month");

    months.push({
      name: monthMoment.format("MMM"),
      label: `${monthMoment.startOf("month").format("DD.MM")} - ${monthMoment.endOf("month").format("DD.MM")}`,
      todayWeek: nowMoment.isBetween(startOfMonthMoment, endOfMonthMoment, "month", "[]")
        ? nowMoment.week()
        : undefined,
      month: m,
      weeks: generateWeeksNumbersInMonth(+year, m),
    });
  }
  return months;
};
