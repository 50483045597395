import React from "react";

import ContactsModal from "../ContactsModal/ContactsModal";

import { getFullNameString } from "../../../../utils/helpers/helpers";

import styles from "./OpeningInfo.module.scss";

class OpeningInfo extends React.Component {
  state = {
    isOpen: false,
    isChangeExecutorModalOpen: false,
  };

  openHandler = () =>
    this.setState({
      isOpen: true,
    });

  closeHandler = () =>
    this.setState({
      isOpen: false,
    });

  render() {
    const { title, info } = this.props;
    const { isOpen } = this.state;

    if (!info) return null;

    const fullName = getFullNameString(info.first_name, info.last_name, info.middle_name);

    return (
      <React.Fragment>
        <ContactsModal isOpen={isOpen} onClose={this.closeHandler} contacts={info} title={title} />
        <div className={styles.name} title={fullName} onClick={this.openHandler}>
          {fullName}
        </div>
      </React.Fragment>
    );
  }
}

export default React.memo(OpeningInfo);
