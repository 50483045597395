import React from "react";

const DeleteIcon = ({ fill="#C4C4C4", ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.9851 14.5271L7.7711 11.3194L4.5571 14.5271C4.37137 14.7125 4.15085 14.8597 3.90815 14.96C3.66545 15.0604 3.40531 15.112 3.1426 15.112C2.87988 15.112 2.61975 15.0604 2.37704 14.96C2.13434 14.8597 1.91382 14.7125 1.7281 14.5271L1.0851 13.8854C0.899293 13.7 0.751902 13.4799 0.651342 13.2377C0.550782 12.9954 0.499023 12.7358 0.499023 12.4736C0.499023 12.2114 0.550782 11.9518 0.651342 11.7095C0.751902 11.4673 0.899293 11.2472 1.0851 11.0619L4.3001 7.8551L1.0861 4.64735C0.900293 4.46198 0.752902 4.2419 0.652342 3.99967C0.551782 3.75743 0.500023 3.4978 0.500023 3.2356C0.500023 2.9734 0.551782 2.71377 0.652342 2.47154C0.752902 2.2293 0.900293 2.00922 1.0861 1.82385L1.7291 1.1821C1.91482 0.99666 2.13534 0.849555 2.37804 0.74919C2.62075 0.648826 2.88088 0.597168 3.1436 0.597168C3.40631 0.597168 3.66645 0.648826 3.90915 0.74919C4.15185 0.849555 4.37237 0.99666 4.5581 1.1821L7.7711 4.39085L10.9851 1.1831C11.1708 0.997658 11.3913 0.850553 11.634 0.750188C11.8767 0.649824 12.1369 0.598166 12.3996 0.598166C12.6623 0.598166 12.9224 0.649824 13.1652 0.750188C13.4079 0.850553 13.6284 0.997658 13.8141 1.1831L14.4571 1.82485C14.6429 2.01022 14.7903 2.2303 14.8909 2.47253C14.9914 2.71477 15.0432 2.9744 15.0432 3.2366C15.0432 3.4988 14.9914 3.75843 14.8909 4.00066C14.7903 4.2429 14.6429 4.46298 14.4571 4.64835L11.2421 7.8551L14.4561 11.0629C14.6419 11.2482 14.7893 11.4683 14.8899 11.7105C14.9904 11.9528 15.0422 12.2124 15.0422 12.4746C15.0422 12.7368 14.9904 12.9964 14.8899 13.2387C14.7893 13.4809 14.6419 13.701 14.4561 13.8864L13.8131 14.5281C13.6274 14.7135 13.4069 14.8606 13.1642 14.961C12.9214 15.0614 12.6613 15.113 12.3986 15.113C12.1359 15.113 11.8757 15.0614 11.633 14.961C11.3903 14.8606 11.1698 14.7135 10.9841 14.5281L10.9851 14.5271Z"
      fill={fill}
    />
  </svg>
);

export default React.memo(DeleteIcon);