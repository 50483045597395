import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";

import App from "app/App";
import ErrorBoundary from "app/ErrorBoundary";
import { useTypedSelector } from "app/store/typedUseSelector";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";

import { URL_MODULE_NAMES_MAP } from "../constants/breadcrumbs";

import { useUrlModule } from "../utils/hooks/useUrlModule";

const Root: FC = () => {
  const urlModule = useUrlModule();
  const { i18n } = useTranslation();
  const profile = useTypedSelector((state) => state.auth.profile);

  useEffect(() => {
    i18n.changeLanguage(profile?.language ?? "ru");
  }, [profile]);

  return (
    <DndProvider backend={HTML5Backend}>
      <ErrorBoundary>
        <Helmet>
          <title>OSLA {URL_MODULE_NAMES_MAP[urlModule] || ""}</title>
        </Helmet>
        <App />
      </ErrorBoundary>
    </DndProvider>
  );
};

export default Root;
