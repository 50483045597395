import cn from "classnames";
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import { HOME_PATH } from "../../../constants";

import logo from "../../../../../../images/authorization/auth_logo.svg";

import styles from "./LogoGroup.module.scss";

export interface ILogoGroupProps extends PropsWithChildren {
  className?: string;
  withoutLogo?: boolean;
}

const LogoGroup: React.FC<ILogoGroupProps> = ({
  className,
  children = (
    <div>
      <span>Управление</span>
      <br />
      <span>строительством</span>
    </div>
  ),
  withoutLogo,
}) => {
  return (
    <div className={cn(styles.logoGroup, className)}>
      {!withoutLogo && (
        <Link to={HOME_PATH}>
          <img src={logo} alt="OSLA" />
        </Link>
      )}
      {children}
    </div>
  );
};

export default LogoGroup;
