import React, { FC, memo, useCallback, useEffect, useMemo } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import EmptyDraft from "../../../../../../../ProHandler/components/Body/components/Sections/components/Draft/Draft";
import EmptyMessage from "../../../../../../../ProHandler/components/EmptyMessage/EmptyMessage";

import Spinner from "../../../../../../../../../../shared/ui/atoms/Spinner/Spinner";
import {
  checkedItemsIdsSelector,
  confirmedSectionsSelector,
  estimateStateIdSelector,
  invalidateKeySelector,
  modeSelector,
  newSectionsSelector,
  sectionsAreLoadingSelector,
  sectionsSelector,
} from "../../../../../../model/selectors";
import { simpleHandlerActions } from "../../../../../../model/slice";
import { loadSections } from "../../../../../../model/thunks";
import { CommonSectionView } from "../Section/CommonSectionView/CommonSectionView";
import { ConfirmationSectionView } from "../Section/ConfirmationSectionView/ConfirmationSectionView";

import ISection from "../../../../../../../../../../types/interfaces/Section";
import { EEstimateStatesIds, EstimateItemsStatusesEnum } from "../../../../../../../ProHandler/enums";

import { useQueryParams } from "../../../../../../../../../../utils/hooks/useQueryParams";
import { useIsOpenedInApproveForm } from "../../../../../../hooks/useIsOpenedInApproveForm";
import { useUserCanConfirm } from "../../../../hooks/useUserCanConfirm";
import { useSectionActions } from "../../hooks/useSectionActions";

import styles from "./SectionsListInLocaleStateView.module.scss";

/**
 * Список разделов (секций, ЛСР) в состоянии локального сметного расчета
 */
export const SectionsListInLocaleStateView: FC = memo(() => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();

  const estimateStateId = useSelector(estimateStateIdSelector);
  const mode = useSelector(modeSelector);
  const sectionsAreLoading = useSelector(sectionsAreLoadingSelector);

  const stateFromQuery = useQueryParams("state");
  const tokenFromQuery = useQueryParams("token");

  const invalidateKey = useSelector(invalidateKeySelector);

  const newSections = useSelector(newSectionsSelector);
  const confirmedSections = useSelector(confirmedSectionsSelector);

  const checkedItemsIds = useSelector(checkedItemsIdsSelector);

  const { buildingId } = useParams<{ buildingId?: string }>();

  const isUserCanConfirm = useUserCanConfirm();

  const isOpenedInApproveForm = useIsOpenedInApproveForm();

  useEffect(() => {
    if (buildingId && estimateStateId) {
      dispatch(loadSections(buildingId, estimateStateId));
    }
  }, [estimateStateId, buildingId, invalidateKey]);

  const onCreateSectionButtonClick = useCallback(() => {
    dispatch(simpleHandlerActions.setIsSectionAdding(true));
  }, []);

  const onSectionClick = useCallback(
    (section: ISection, status: "new" | "confirmed") => {
      batch(() => {
        dispatch(simpleHandlerActions.setActiveSection(section));
        dispatch(simpleHandlerActions.clearCheckedItems());
      });

      const url = `${match.url}/${section.id}`;
      const query = new URLSearchParams();

      if (status === EstimateItemsStatusesEnum.NEW) {
        query.set("status", EstimateItemsStatusesEnum.NEW);
      } else {
        query.set("status", EstimateItemsStatusesEnum.CONFIRMED);
      }

      if (stateFromQuery) query.set("state", stateFromQuery);
      if (tokenFromQuery) query.set("token", tokenFromQuery);

      history.push(`${url}?${query.toString()}`);
    },
    [history, stateFromQuery, tokenFromQuery]
  );

  const onSectionCheck = useCallback((section: ISection) => {
    dispatch(simpleHandlerActions.toggleCheckedItem({ id: section.id, name: section.name }));
  }, []);

  const { getSectionActions } = useSectionActions();

  if (sectionsAreLoading) {
    return <Spinner isStatic />;
  }

  // Если секции не null и пустой массив – значит успешно прошел запрос
  // и вернулся пустой массив
  if (newSections?.length === 0 && confirmedSections?.length === 0) {
    // Для стейта "Черновик" показываем специальный компонент
    // из которого можно нажать на кнопку создания новой секции
    if (estimateStateId === EEstimateStatesIds.DRAFT) {
      return (
        <EmptyDraft
          onCreateSection={onCreateSectionButtonClick}
          titleClassName={styles.emptyTitle}
          buttonClassName={styles.emptyButton}
        />
      );
    }
    return <EmptyMessage message={`Чтобы продолжить, перенесите разделы и/или позиции`} />;
  }

  if (isUserCanConfirm && newSections?.length) {
    // В случае, если есть, что подтверждать и пользователь ответственный за смету
    // отрисовываем 2 блока ("Требуют утверждения" и "Утвержденные")
    return (
      <>
        <div className={styles.toConfirmContainer}>
          <h2 className={styles.title}>
            Требуют утверждения: <span className={styles.count}>{newSections.length}</span>
          </h2>
          {newSections.map((section) => (
            <ConfirmationSectionView
              key={section.id}
              onClick={() => onSectionClick(section, "new")}
              section={section}
              onCheck={onSectionCheck}
              checked={checkedItemsIds.includes(section.id)}
              estimateStateId={estimateStateId!}
              mode={mode}
              actions={getSectionActions(section)}
              isCheckboxVisible={false}
            />
          ))}
        </div>

        <div className={styles.confirmedContainer}>
          <h2 className={styles.title}>
            Утвержденные: <span className={styles.count}>{confirmedSections?.length}</span>
          </h2>
          {confirmedSections?.map((section) => (
            <CommonSectionView
              key={section.id}
              onClick={() => onSectionClick(section, "confirmed")}
              section={section}
              estimateStateId={estimateStateId!}
              onCheck={onSectionCheck}
              checked={checkedItemsIds.includes(section.id)}
              mode={mode}
              actions={getSectionActions(section)}
              isCheckboxVisible={!isOpenedInApproveForm}
              status={"confirmed"}
            />
          ))}
        </div>
      </>
    );
  } else {
    // В случае, если подтверждать нечего или пользователь не ответственный за смету
    // отрисовываем обычный вид
    return (
      <>
        {confirmedSections?.map((section) => (
          <CommonSectionView
            key={section.id}
            onClick={() => onSectionClick(section, "confirmed")}
            section={section}
            estimateStateId={estimateStateId!}
            onCheck={onSectionCheck}
            checked={checkedItemsIds.includes(section.id)}
            mode={mode}
            actions={getSectionActions(section)}
            isCheckboxVisible={!isOpenedInApproveForm}
          />
        ))}
      </>
    );
  }
});

SectionsListInLocaleStateView.displayName = "SectionsListInLocaleStateView";
