import React from 'react';

export default function NotificationIcon ({}) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.883 19.8471H14.838C14.838 21.8651 13.506 23.4961 11.858 23.4961C10.21 23.4961 8.883 21.8651 8.883 19.8471ZM11.922 19.7191H1C1 19.7191 6.369 16.2041 6.453 6.98409V6.94809C6.49719 5.51397 7.08833 4.15107 8.10521 3.13884C9.1221 2.1266 10.4877 1.54172 11.922 1.50409V1.49609C11.947 1.49609 11.975 1.49609 12 1.49609C12.025 1.49609 12.051 1.49609 12.076 1.49609V1.50409C13.5107 1.54236 14.8765 2.12727 15.8942 3.13921C16.9119 4.15115 17.5046 5.51366 17.551 6.94809L17.545 6.98409C17.631 16.2041 23 19.7191 23 19.7191H11.922Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
}
