import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IMultipleApprovingPlans } from "./types";
import { multiplePlansUtils } from "../utils";

export interface SendMultiplePlansState {
  isLoading: Record<string, boolean>;
  sections: Record<string, IMultipleApprovingPlans>;
  counts: Record<string, number>;
  invalidateKey: number;
}

const initialState: SendMultiplePlansState = {
  isLoading: {},
  sections: {},
  counts: {},
  invalidateKey: 0,
};

export const sendMultiplePlansStateSlice = createSlice({
  name: "sendMultiplePlansState",
  initialState,
  reducers: {
    setSections: (state, action: PayloadAction<{ key: string; sections: IMultipleApprovingPlans }>) => {
      const { key, sections } = action.payload;
      state.sections[key] = sections;
      state.counts[key] = multiplePlansUtils.countPlansForApprove(sections);
    },
    setIsLoading: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      const { key, status } = action.payload;
      state.isLoading[key] = status;
    },
    invalidateKey: (state) => {
      state.invalidateKey++;
    },
  },
});

export const sendMultiplePlansStateActions = sendMultiplePlansStateSlice.actions;

export default sendMultiplePlansStateSlice.reducer;
