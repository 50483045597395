import { IProjectActions } from "./actions";

import { IProjectState } from "./types";

const initialState: IProjectState = {
  invalidateKey: 0,
};

const projectReducer = (state = initialState, action: IProjectActions): IProjectState => {
  const { type, payload } = action;
  switch (type) {
    case "project/INVALIDATE_KEY":
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    default:
      return state;
  }
};

export default projectReducer;
