import axios from "axios";

import { IGroupInfo } from "components/UI/_TODO/ExpenditureGroupModal/types";

import { IChangeJournalPriceTypeRequest, IConfirmGroupData } from "./types";
import { ITicketJournalDelivery } from "types/interfaces/Tickets";

export const journalApi = {
  changeMaterialPrice: (buildingId: number, data: IChangeJournalPriceTypeRequest) => {
    return axios.post(`/journal/${buildingId}/fulfillment/materials/change_amount/`, data);
  },

  patchTicket: (objectId: string, ticketId: number, data: Partial<ITicketJournalDelivery>) => {
    return axios.patch(`/building/${objectId}/tickets/${ticketId}/`, data);
  },

  createTicket: (objectId: string, ticketData: Partial<ITicketJournalDelivery>) => {
    return axios.post(`/building/${objectId}/tickets/`, ticketData);
  },

  getGroupDetailInfo: (objectId: string, groupId: number) => {
    return axios.get<IGroupInfo>(`/building/${objectId}/groups/${groupId}/`);
  },

  postGroup: (objectId: string, groupId: number, data: IConfirmGroupData) => {
    return axios.post(`/building/${objectId}/groups/facts/${groupId}/confirm/`, data);
  },
  approveTicket: (buildingId: string, expenditureId: number) => {
    return axios.post(`/building/${buildingId}/estimate/fact/expenditure/approve/${expenditureId}/`);
  },
  approveGroupTicket: (buildingId: string, groupId: number) => {
    return axios.post(`/building/${buildingId}/estimate/fact/group/approve/${groupId}/`);
  },
};
