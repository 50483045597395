import { materialType, mimsType } from "./useProductIntervalForm";

export const mapAddedToTicketMaterials = (materials: materialType[] = []) => {
  return materials
    ?.filter((el) => !!el.using_ids?.[0])
    .map((el) => ({ stock_using_material_id: el.using_ids?.[0], count: el.local_count }));
};

export const mapAddedToTicketMims = (materials: mimsType[] = []) => {
  return materials
    ?.filter((el) => !!el.service_id)
    .map((el) => ({ service_id: el.service_id, count: el.local_count }));
};
