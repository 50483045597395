import moment from "moment";

import { parseTransformedNumber, parseTransformedString } from "../formatters/transformDigitToFinancial";

export const isEmpty = (value) => value == null || (typeof value === "string" && value.trim().length === 0);

export const composeFieldValidators =
  (...validators) =>
  (value, values) =>
    validators
      .filter((v) => typeof v === "function")
      .reduce((error, validator) => error || validator(value, values), undefined);

export const required =
  (errorMessage = "Обязательное поле") =>
  (value) =>
    isEmpty(value) || value === false ? errorMessage : undefined;

export const maxLength = (max, errorMessage = "Слишком длинный") =>
  withEmpty((value) => (value.length > max ? errorMessage : undefined));

export const minLength = (min, errorMessage = "Слишком короткий") =>
  withEmpty((value) => (value.length < min ? errorMessage : undefined));

export const fixedLength = (fixLen, errorMessage = `Должен быть длины ${fixLen}`) =>
  withEmpty((value) => (value.length !== fixLen ? errorMessage : undefined));

const withEmpty = (f) => (value, values, props) => {
  if (!isEmpty(value)) {
    return f(value, values, props);
  } else {
    return undefined;
  }
};

export const mustBeNonZero = (value = "") => {
  if (value.length === 0) return;
  if (value == 0) return "Не может быть 0";
};

export const mustBeNumber = (value = "") => {
  if (value.length === 0) return;
  if (isNaN(value.toString().replaceAll(",", "."))) return "Должно быть числом";
};

export const emailVal =
  (errorMessage = "Некорректный email") =>
  (value = "") => {
    if (value.length === 0) return;

    if (value.split(" ").length > 1) return "email не должен содержать пробелы";

    const emailSymbolCount = value.split("").reduce((acc, symbol) => {
      if (symbol === "@") return acc + 1;
      return acc;
    }, 0);

    if (emailSymbolCount !== 1) return errorMessage;
  };

export const email = (value = "", errorMessage = "Некорректный email") => {
  if (value.length === 0) return;

  if (value.split(" ").length > 1) return "email не должен содержать пробелы";

  const emailSymbolCount = value.split("").reduce((acc, symbol) => {
    if (symbol === "@") return acc + 1;
    return acc;
  }, 0);

  if (emailSymbolCount !== 1) return errorMessage;
};

export const matchPattern = (pattern, errorMessage = `Не соответствует ожидаемому формату`) =>
  withEmpty((value) => (!value?.toString()?.trim()?.match(pattern) ? errorMessage : undefined));

export const mustBeDate = (errMsg = "Введите дату") =>
  withEmpty((value) => (!moment(value).isValid() ? errMsg : undefined));

export const mustBeEstimateNumber = (errMsg = "Некорректный номер") =>
  withEmpty((value) => {
    const regexpNumsAndDots = /^\d+(\.\d+)*$/g;
    return regexpNumsAndDots.test(value) ? undefined : errMsg;
  });
