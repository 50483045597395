import cn from "classnames";
import React, { MouseEventHandler, useEffect, useMemo, useRef, useState } from "react";

import Draggable, { DraggableEventHandler } from "react-draggable";

import { TASK_PRIORITY } from "../../constants";

import { remToPx } from "utils/remToPx";

import styles from "./PriorityRange.module.scss";

export interface IPriorityRangeProps {
  onChange?: (value: string) => void;
  className?: string;
  value: keyof typeof TASK_PRIORITY;
  defaultValue?: keyof typeof TASK_PRIORITY;
  disabled?: boolean;
  testId?: string;
  label?: string;
}

const computeSliderBaseWidth = (containerElement: HTMLElement | null, indicatorElement: HTMLElement | null) => {
  if (!containerElement || !indicatorElement) return 0;
  return containerElement.clientWidth - indicatorElement.clientWidth;
};

const PriorityRange: React.FC<IPriorityRangeProps> = ({
  onChange,
  className,
  value,
  defaultValue = TASK_PRIORITY.gray,
  disabled = false,
  testId,
  label,
}) => {
  const priorityKeys = Object.keys(TASK_PRIORITY) as Array<keyof typeof TASK_PRIORITY>;
  const percentBase = priorityKeys.length > 1 ? 1 / (priorityKeys.length - 1) : 0.5;

  const containerRef = useRef<HTMLDivElement>(null);
  const indicatorRef = useRef<HTMLDivElement>(null);

  const [sliderBaseWidth, setSliderBaseWidth] = useState(
    computeSliderBaseWidth(containerRef.current, indicatorRef.current)
  );

  useEffect(() => {
    if (!containerRef.current?.clientWidth || !indicatorRef.current?.clientWidth) return;
    const initSliderBaseWidth = () =>
      setSliderBaseWidth(computeSliderBaseWidth(containerRef.current, indicatorRef.current));
    initSliderBaseWidth();
    window.addEventListener("resize", initSliderBaseWidth);
    return () => window.removeEventListener("resize", initSliderBaseWidth);
  }, [containerRef.current?.clientWidth, indicatorRef.current?.clientWidth]);

  const [xPercent, setXPercent] = useState<number>(0);
  const [priority, setPriority] = useState<TASK_PRIORITY>(TASK_PRIORITY[value || defaultValue]);

  useEffect(() => {
    if (!value && !defaultValue) return;
    const preferredValue = value || defaultValue;
    setPriority(TASK_PRIORITY[preferredValue]);
    priorityKeys.forEach((key, index) => {
      if (preferredValue === key) {
        setXPercent(percentBase * index);
      }
    });
  }, [value, defaultValue]);

  const handlePriority = (newPriority: keyof typeof TASK_PRIORITY) => {
    if (!onChange) return;
    onChange(newPriority);
    setPriority(TASK_PRIORITY[newPriority]);
  };

  const setNormalisedX = (xValue: number) => {
    if (!sliderBaseWidth) return;
    const percent = xValue / sliderBaseWidth;
    let candidateKey = priorityKeys[0];
    let candidateIndex = 0;
    let min = 1;
    priorityKeys.forEach((key, index) => {
      if (Math.abs(percentBase * index - percent) < min) {
        min = Math.abs(percentBase * index - percent);
        candidateIndex = index;
        candidateKey = key;
      }
    });
    setXPercent(percentBase * candidateIndex);
    handlePriority(candidateKey);
  };

  const handleDrag: DraggableEventHandler = (_, data) => {
    if (disabled) return;
    setNormalisedX(data.x);
  };

  const handleLineClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (disabled) return;
    // @ts-ignore
    setNormalisedX(e.nativeEvent.layerX);
  };

  const xPosition = useMemo(() => xPercent * sliderBaseWidth - remToPx(0.5), [xPercent, sliderBaseWidth]);

  return (
    <div className={cn(styles.rangeElement, className)} ref={containerRef} data-testid={testId}>
      <span className={styles.label}>
        {`${label !== undefined ? label : "Приоритет: "}`}
        <span>{priority}</span>
      </span>
      <div className={cn(styles.line, { [styles.disabled]: disabled })} onClick={handleLineClick}>
        <Draggable
          axis={"x"}
          bounds="parent"
          position={{ x: xPosition, y: 0 }}
          defaultPosition={{ x: xPosition, y: 0 }}
          onStop={handleDrag}
          disabled={disabled}
        >
          <div className={cn(styles.indicator, { [styles.disabled]: disabled })} ref={indicatorRef} />
        </Draggable>
      </div>
      {/* <div className={styles.legend}>
        <div className={cn(styles.priority, styles.priorityLow)} />
        <div className={cn(styles.priority, styles.priorityMedium)} />
        <div className={cn(styles.priority, styles.priorityHigh)} />
      </div> */}
    </div>
  );
};

export default PriorityRange;
