function declOfNum(n: number | undefined, text_forms: string[], config?: { hideValue?: boolean }) {
  const displayingNumber = !config?.hideValue ? n + " " : "";
  if (!n && !config?.hideValue) return "0" + " " + text_forms[2];
  if (!n && config?.hideValue) return text_forms[2];
  const n2 = Math.abs(n!) % 100;
  const n1 = n! % 10;
  if (n2 > 10 && n2 < 20) {
    return displayingNumber + text_forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return displayingNumber + text_forms[1];
  }
  if (n1 == 1) {
    return displayingNumber + text_forms[0];
  }
  return displayingNumber + text_forms[2];
}

export default declOfNum;
