import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Building from "components/pages/Building/Building";
import Calendar from "components/pages/Calendar";
import FilesStorage from "components/pages/FilesStorage/FilesStorage";
import PaymentsList from "components/pages/PaymentsList";
import SuppliesList from "components/pages/SuppliesList";
import { ProfilePage } from "features/templates/Header/components/Profile/components/ProfilePage/ProfilePage";
import { ConstructionControlJournal } from "pages/ConstructionControl/ConstructionControlJournal";
import ConstructionControlObject from "pages/ConstructionControl/ConstructionControlObject/ConstructionControlObject";
import {
  ConstructionControlObjects,
  ConstructionControlProjects,
} from "pages/ConstructionControl/ConstructionControlProjects";

import ForbiddenPage from "../ForbiddenPage/ForbiddenPage";
import SettingsRoutes from "../SettingsRoutes/SettingsRoutes";

const ConstructionControlRoutes = () => {
  return (
    <Switch>
      {/* Projects */}
      <Route exact path={`/construction-control/projects`} render={(props) => <ConstructionControlProjects />} />
      <Route
        exact
        path={"/construction-control/projects/objects-list/:projectId"}
        render={(props) => <ConstructionControlObjects />}
      />
      <Route
        path={`/construction-control/projects/:projectId/object/:objectId`}
        render={(props) => (
          <ConstructionControlObject
            {...props}
            permissions={{
              canAddFiles: true,
              canAddPlan: true,
              canAddProgress: true,
              canDeleteFiles: true,
              canSharePlan: true,
              canShareProgress: true,
              canViewPlan: true,
              canViewProgress: true,
              //drawings
              canAddDrawings: true,
              canAddDrawingSets: true,
              canDeleteDrawingSets: true,
              canDeleteDrawings: true,
              canEditDrawings: true,
              canEditDrawingSets: true,
              canViewDrawings: true,
            }}
          />
        )}
      />
      {/* Journal */}
      <Route
        path={`/construction-control/journal/:projectId/:objectId/`}
        render={(props) => <ConstructionControlJournal />}
      />
      {/* Common pages */}
      <Route exact path={`/construction-control/profile`} component={ProfilePage} />
      <Route exact path={`/construction-control/events/calendar`} component={Calendar} />
      <Route exact path={`/construction-control/events/payments`} component={PaymentsList} />
      <Route exact path={`/construction-control/events/supplies`} component={SuppliesList} />
      <Route exact path={`/construction-control/files/:objectId/`} component={FilesStorage} />
      <Route path={`/construction-control/settings`} component={SettingsRoutes} />
      {/* Default redirect */}
      <Redirect exact to="/construction-control/projects" />
      <ForbiddenPage />
    </Switch>
  );
};

export default ConstructionControlRoutes;
