import React, { useCallback, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { actingActions } from "redux/modules/common/building/journal/acting/actions";
import {
  actingInvalidateKeySelector,
  actingKs2ListSelector,
  actingSelectedKs2IdsSelector,
} from "redux/modules/common/building/journal/acting/selectors";
import { getKs2ListByBuilding } from "redux/modules/common/building/journal/acting/thunks";

import JournalKs3Modal from "../JournalActingModal/JournalKs3Modal";
import JournalActingSelectingBar from "../common/JournalActingSelectingBar/JournalActingSelectingBar";
import JournalKs2Row from "./JournalKs2Row";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./JournalKs2Level.module.scss";

const JournalKs2Level = () => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const dispatch = useDispatch();

  const ksList = useSelector(actingKs2ListSelector)[objectId];
  const cacheKey = useSelector(actingInvalidateKeySelector);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useLayoutEffect(() => {
    dispatch(getKs2ListByBuilding(objectId));
  }, [objectId, cacheKey]);

  const selectedIds = useSelector(actingSelectedKs2IdsSelector)[objectId] || [];

  const checkHandler = useCallback(
    (ksId: number, status: boolean) => {
      dispatch(actingActions.selectKs2Id(ksId, objectId, status));
    },
    [objectId]
  );

  return (
    <>
      <div className={styles.nav}>
        <JournalActingSelectingBar
          type="ks2"
          onSubmit={() => setIsModalOpen(true)}
          selectedCount={selectedIds.length}
        />
      </div>
      {ksList?.isLoading && <Spinner />}
      {!ksList?.isLoading &&
        ksList?.results?.map((el) => (
          <JournalKs2Row key={el.id} ks2={el} isChecked={selectedIds.includes(el.id)} onCheck={checkHandler} />
        ))}
      {!ksList?.isLoading && !ksList?.results?.length && <EmptyPlaceholder img={jurnalIcon} />}
      <JournalKs3Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default React.memo(JournalKs2Level);
