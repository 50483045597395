import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";

import { updateUserCurrentProfileInfo, userSelector } from "redux/modules/_TODO/auth";

import Checkbox from "_LEGACY/UI/__TODO/Checkbox/Checkbox";

import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { IEditProfileFieldValues } from "../../types/types";
import { NUMBER_PLACEHOLDER } from "constants/placeholders";

import { composeFieldValidators, email, maxLength, minLength, required } from "utils/formHelpers/validations";
import { parseInputMask } from "utils/formatters/parseInputMask";
import { extractChangedValues } from "utils/helpers/extractChangedValues/extractChangedValues";

import styles from "./ProfilePage.module.scss";

export const ProfilePage = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector(userSelector);

  const [phoneNumber, setPhoneNumber] = useState<string>(userInfo?.phone_number);

  const isPhoneNumberError = useMemo(
    () => phoneNumber !== userInfo?.phone_number,
    [phoneNumber, userInfo?.phone_number]
  );
  const isPhoneNumberNotFilled = useMemo(() => phoneNumber?.includes("_"), [phoneNumber]);

  const numberHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  useEffect(() => {
    setPhoneNumber(userInfo?.phone_number);
  }, [userInfo?.phone_number]);

  const onSubmit = useCallback(
    (values: IEditProfileFieldValues) => {
      const changedValues = extractChangedValues(userInfo, {
        ...values,
        phone_number: phoneNumber !== NUMBER_PLACEHOLDER ? phoneNumber : undefined,
        middle_name: values.middle_name ?? "",
      });

      delete changedValues.roles;

      dispatch(
        updateUserCurrentProfileInfo({
          ...changedValues,
        })
      );
    },
    [dispatch, phoneNumber, userInfo.roles]
  );

  return (
    <TemplateBase dataTestId="page_profile">
      <div className={styles.container}>
        <header className={styles.header}>
          <h1 className={styles.fullName}>
            {userInfo.last_name} {userInfo.first_name} {userInfo.middle_name}
          </h1>
          <h2 className={styles.title}>редактирование профиля</h2>
        </header>
        <h3 className={styles.subtitle}>Контактные данные</h3>
        <Form
          onSubmit={onSubmit}
          validate={(values) => {
            const errors: Partial<IEditProfileFieldValues> = {};
            if (values.repeat_password !== values.new_password) {
              errors.repeat_password = "Пароли должны совпадать";
            }

            if (isPhoneNumberNotFilled) {
              errors.phone_number = "Обязательное поле";
            }
            return errors;
          }}
          render={({ handleSubmit, hasValidationErrors, dirty }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.columnContainer}>
                <div className={styles.column}>
                  <Field
                    name="last_name"
                    validate={composeFieldValidators(required(), maxLength(20))}
                    initialValue={userInfo.last_name}
                  >
                    {({ input, meta }) => (
                      <div className={styles.field}>
                        <label className={styles.label} htmlFor="last_name">
                          *Фамилия
                        </label>
                        <div className={styles.inputContainer}>
                          <input {...input} id="last_name" name="last_name" placeholder="Введите фамилию" />
                          {(meta.error || meta.submitError) && <div className={styles.errorMessage}>{meta.error}</div>}
                        </div>
                      </div>
                    )}
                  </Field>
                  <Field name="middle_name" validate={maxLength(20)} initialValue={userInfo.middle_name}>
                    {({ input, meta }) => (
                      <div className={styles.field}>
                        <label className={styles.label} htmlFor="middle_name">
                          Отчество
                        </label>
                        <div className={styles.inputContainer}>
                          <input {...input} id="middle_name" name="middle_name" placeholder="Введите отчество" />
                          {(meta.error || meta.submitError) && <div className={styles.errorMessage}>{meta.error}</div>}
                        </div>
                      </div>
                    )}
                  </Field>{" "}
                  {/* @ts-ignore */}
                  <Field name="email" initialValue={userInfo.email} validate={email}>
                    {({ input }) => (
                      <div className={styles.field}>
                        <label className={styles.label} htmlFor="email">
                          E-mail
                        </label>
                        <div className={styles.inputContainer}>
                          <input {...input} id="email" name="email" disabled placeholder="Введите e-mail" />
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
                <div className={styles.column}>
                  <Field
                    name="first_name"
                    validate={composeFieldValidators(required(), maxLength(20))}
                    initialValue={userInfo.first_name}
                  >
                    {({ input, meta }) => (
                      <div className={styles.field}>
                        <label className={styles.label} htmlFor="first_name">
                          *Имя
                        </label>
                        <div className={styles.inputContainer}>
                          <input {...input} id="first_name" name="first_name" placeholder="Введите имя" />
                          {(meta.error || meta.submitError) && <div className={styles.errorMessage}>{meta.error}</div>}
                        </div>
                      </div>
                    )}
                  </Field>
                  <Field
                    name="phone_number"
                    parse={parseInputMask}
                    render={({ input, meta }) => (
                      <div className={styles.field}>
                        <label className={styles.label} htmlFor="phone_number">
                          *Номер телефона
                        </label>
                        <div className={styles.inputContainer}>
                          <InputMask
                            {...input}
                            id="phone_number"
                            name="phone_number"
                            mask="+7 (999) 999-99-99"
                            onChange={numberHandler}
                            placeholder={NUMBER_PLACEHOLDER}
                            type="tel"
                            value={phoneNumber}
                          />
                          {(meta.error || meta.submitError) && <div className={styles.errorMessage}>{meta.error}</div>}
                        </div>
                      </div>
                    )}
                  />
                  <Field name="roles" initialValue={userInfo.roles[0]?.name}>
                    {({ input }) => (
                      <div className={styles.field}>
                        <label className={styles.label} htmlFor="roles">
                          Должность
                        </label>
                        <div className={styles.inputContainer}>
                          <input {...input} id="roles" name="roles" disabled placeholder="Выберите должность" />
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              <h3 className={styles.subtitle}>Обновить пароль</h3>
              <div className={styles.columnContainer}>
                <div className={styles.column}>
                  <Field name="old_password" validate={composeFieldValidators(maxLength(20), minLength(8))}>
                    {({ input, meta }) => (
                      <div className={styles.field}>
                        <label className={styles.label} htmlFor="old_password">
                          Старый пароль
                        </label>
                        <div className={styles.inputContainer}>
                          <input
                            {...input}
                            id="old_password"
                            name="old_password"
                            type="password"
                            placeholder="Введите старый пароль"
                          />
                          {(meta.error || meta.submitError) && <div className={styles.errorMessage}>{meta.error}</div>}
                        </div>
                      </div>
                    )}
                  </Field>
                  <div className={styles.checkboxContainer}>
                    <Checkbox disabled checked />
                    <label className={styles.checkboxLabel}>Доступ ко всем проектам</label>
                  </div>
                </div>
                <div className={styles.column}>
                  <Field name="new_password" validate={composeFieldValidators(maxLength(20), minLength(8))}>
                    {({ input, meta }) => (
                      <div className={styles.field}>
                        <label className={styles.label} htmlFor="new_password">
                          Новый пароль
                        </label>
                        <div className={styles.inputContainer}>
                          <input
                            {...input}
                            id="new_password"
                            name="new_password"
                            type="password"
                            placeholder="Введите новый пароль"
                          />
                          {(meta.error || meta.submitError) && <div className={styles.errorMessage}>{meta.error}</div>}
                        </div>
                      </div>
                    )}
                  </Field>
                  <Field name="repeat_password" validate={composeFieldValidators(maxLength(20), minLength(8))}>
                    {({ input, meta }) => (
                      <div className={styles.field}>
                        <label className={styles.label} htmlFor="repeat_password">
                          Подтверждение пароля
                        </label>
                        <div className={styles.inputContainer}>
                          <input
                            {...input}
                            id="repeat_password"
                            name="repeat_password"
                            type="password"
                            placeholder="Повторите ввод нового пароля"
                          />
                          {(meta.error || meta.submitError) && <div className={styles.errorMessage}>{meta.error}</div>}
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              {(dirty || isPhoneNumberError) && (
                <div className={styles.buttonContainer}>
                  <ButtonBase type="submit" medium primary disabled={hasValidationErrors || isPhoneNumberNotFilled}>
                    Сохранить
                  </ButtonBase>
                </div>
              )}
            </form>
          )}
        />
      </div>
    </TemplateBase>
  );
};
