import React from "react";

import styles from "./MoreButton.module.scss";

function MoreButton({ isLoading, allCount, existingCount, onLoadMore, limit = 10 }) {
  if (isLoading) {
    return null;
  }

  if (allCount <= existingCount) {
    return null;
  }

  if (!allCount && !existingCount) {
    return null;
  }

  return (
    <button className={styles.btn} onClick={onLoadMore}>
      Еще {allCount - existingCount > limit ? limit : allCount - existingCount} из {allCount - existingCount}
    </button>
  );
}

export default MoreButton;
