import React, { useEffect, useMemo, useRef } from "react";
import { Field, Form } from "react-final-form";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";

import { createUser, getRoles, rolesSelector, setUser, userSelector } from "redux/modules/_TODO/userRedux";

import Select from "components/UI/atoms/Select";

import ButtonBase from "shared/ui/controls/ButtonBase";
import InputBase from "shared/ui/inputs/InputBase";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import { IUserModalFieldValues } from "../types/types";
import { NUMBER_PLACEHOLDER } from "constants/placeholders";
import { INewUser, IRoles } from "types/interfaces/User";

import useEscapeHandler from "utils/hooks/useEscapeHandler";
import useFormFocus from "utils/hooks/useFormFocus";

import { composeFieldValidators, emailVal, maxLength, required } from "utils/formHelpers/validations";

import arrowSelectSmallBlue from "images/icons/arrowSelectSmallBlue.svg";
import sendEmailImage from "images/sendEmail.png";

import styles from "./UserModal.module.scss";

export interface IProps {
  isOpen: boolean;
  onClose: () => void;
  pushNewUser: (userData: INewUser) => void;
  isEditable?: boolean;
}

const UserModal: React.FC<IProps> = ({ isOpen, onClose, pushNewUser, isEditable = true }) => {
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const inputRootRef = useFormFocus();

  const user = useSelector(userSelector);
  const roles: IRoles[] = useSelector(rolesSelector);

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  const rolesList = useMemo(() => {
    return (
      roles?.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      }) || []
    );
  }, [roles]);

  const normalizePhone = (value: string) =>
    value && value.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "$1($2)-$3-$4-$5");

  const submitRequisite = async (value: IUserModalFieldValues) => {
    const objectValues = {
      ...value,
      user_type: roles.filter((item) => item.id === value.roles)[0].user_type,
      roles: [value.roles],
      permissions: user.permissions,
      is_blocked: user.is_blocked,
    };
    let data;
    data = await dispatch(createUser(objectValues, closeModal));
    /* @ts-ignore */
    if (data) await pushNewUser(data);
  };

  const closeModal = () => {
    if (!formRef.current) return;
    formRef.current.dispatchEvent(new Event("reset", { cancelable: true, bubbles: true }));

    dispatch(setUser(null));
    onClose();
  };

  useEscapeHandler(closeModal);

  return (
    <SliderModal isOpen={isOpen} closeHandler={closeModal}>
      <div className={styles.container}>
        <Form
          onSubmit={submitRequisite}
          initialValues={user}
          render={({ handleSubmit, hasValidationErrors, form, errors }) => (
            <form ref={formRef} onSubmit={handleSubmit} onReset={() => form.restart()}>
              <div className={styles.header}>
                <div className={styles.title}>Добавить сотрудника</div>
                <div className={styles.subtitle} style={{ fontSize: "1rem" }}>
                  Введите данные нового сотрудника. На его e-mail будет выслано приглашение в OSLA. После регистрации вы
                  будете видеть его в списке сотрудников
                </div>
              </div>
              <div className={styles.topSide} ref={inputRootRef}>
                <div className={styles.rowItem}>
                  <Field
                    name="last_name"
                    label="Фамилия"
                    component={InputBase}
                    validate={composeFieldValidators(required(), maxLength(255))}
                    classNameInput={styles.colorInput}
                    disabled={!isEditable}
                  />
                </div>
                <div className={styles.rowItem}>
                  <Field
                    name="first_name"
                    label="Имя"
                    component={InputBase}
                    validate={composeFieldValidators(required(), maxLength(255))}
                    classNameInput={styles.colorInput}
                    disabled={!isEditable}
                  />
                </div>
                <div className={styles.rowItem}>
                  <Field
                    name="middle_name"
                    label="Отчество"
                    component={InputBase}
                    validate={composeFieldValidators(required(), maxLength(255))}
                    classNameInput={styles.colorInput}
                    disabled={!isEditable}
                  />
                </div>
                <div className={styles.rowItem}>
                  <Field
                    name="phone_number"
                    label="Телефон"
                    parse={normalizePhone}
                    classNameInput={styles.colorInput}
                    disabled={!isEditable}
                    render={({ input, meta }) => (
                      <div>
                        <label htmlFor="phone_number">Телефон</label>
                        <InputMask
                          mask="+7 (999) 999-99-99"
                          placeholder={NUMBER_PLACEHOLDER}
                          onChange={(e) => input.onChange(e.target.value)}
                        />
                      </div>
                    )}
                  />
                </div>
                <div className={styles.rowItem}>
                  <Field
                    name="email"
                    label="Почта"
                    component={InputBase}
                    validate={composeFieldValidators(required(), maxLength(255), emailVal())}
                    classNameInput={styles.colorInput}
                    disabled={!isEditable}
                  />
                </div>
                <div className={styles.rowItem}>
                  <Field
                    name="roles"
                    label="Роль" /* @ts-ignore */
                    component={Select}
                    validate={required()}
                    icon={arrowSelectSmallBlue}
                    options={rolesList}
                    disabled={!isEditable}
                    style={{ marginTop: 0 }}
                  />
                </div>
              </div>
              <div className={styles.bottomSide}>
                <img src={sendEmailImage} className={styles.img} alt="email" />
              </div>
              <div className={styles.actionBlock}>
                <ButtonBase className={styles.btnSave} primary medium type="submit" disabled={hasValidationErrors}>
                  Отправить приглашение
                </ButtonBase>
              </div>
            </form>
          )}
        />
      </div>
    </SliderModal>
  );
};

export default React.memo(UserModal);
