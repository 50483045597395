import React from "react";

import InputBase, { VALUE_TYPES } from "../../../../../../shared/ui/inputs/InputBase";

import { splitThousands } from "../../../../../../utils/formatters/splitThousands";

import styles from "../Products.module.scss";

export interface IAmountWithTaxRow {
  id: number;
  is_union: boolean;
  amount: number;
  is_removed: boolean;
}

export interface IProps {
  canEdit: boolean;
  isPurchaser?: boolean;
  onChange?: (changedProductId: number, changedItemName: string) => void;
}

const AmountWithTaxColumnCell =
  ({ canEdit, isPurchaser, onChange }: IProps) =>
  (_cellData: any, row: IAmountWithTaxRow) => {
    const isShownEditableInput = !row.is_union && canEdit && isPurchaser;

    return (
      <div>
        {isShownEditableInput ? (
          <InputBase /* @ts-ignore */
            variant="secondary"
            valueType={VALUE_TYPES.PRICE} /* @ts-ignore */
            value={row.amount} /* @ts-ignore */
            onChange={onChange?.(row.id, "amount")}
            disabled={!isPurchaser || row.is_removed}
            classNameInput={styles.input}
            input={undefined}
            className={undefined}
            label={undefined}
            onBlur={undefined}
          />
        ) : (
          <div>{splitThousands(row.amount, false, false)}</div>
        )}
      </div>
    );
  };

export default AmountWithTaxColumnCell;
