import { message } from "antd";
import axios from "axios";
import React, { useCallback } from "react";

import { VIEW_CREATE_REQUISITION } from "../../../../../constants/permissions/purchasesPermissions";

import usePermission from "../../../../../hooks/usePermission";
import { useTasksModal } from "../../../Tasks/hooks/useTasksModal";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

export const useAnalyticsEventsActions = () => {
  const haveViewCreateRequisitionPermission = usePermission(VIEW_CREATE_REQUISITION);

  const { openViewModal, placementType, listType, list_id } = useTasksModal();

  const openTask = useCallback(
    (taskId: number) => {
      openViewModal({ placementType, listType, list_id, id: taskId });
    },
    [openViewModal, placementType, listType, list_id]
  );

  const addToRequisition = useCallback(
    ({
      buildingId,
      productBuildingId,
      count,
      lastRequisitionDraftId,
    }: {
      buildingId: number;
      productBuildingId: number;
      count: string;
      lastRequisitionDraftId: number | null;
    }) => {
      if (!haveViewCreateRequisitionPermission) return;
      const requisitionSuccessMessage = "Успешно добавлено в заявку";
      axios.get(`/building/${buildingId}/requisition/last-draft/`).then((resp) => {
        if (!Object.keys(resp.data).length) {
          const payload = {
            building_id: buildingId,
            requisition_items: [
              {
                product_building: productBuildingId,
                required_count: count,
              },
            ],
          };
          axios
            .post(`/requisition/`, payload)
            .then(() => message.success(requisitionSuccessMessage))
            .catch(errorCatcher);
        } else if (resp.data.id) {
          const payload = [
            {
              product_building_id: productBuildingId,
              required_count: count,
            },
          ];
          axios
            .post(`/requisition/${resp.data.id}/products/`, payload)
            .then(() => message.success(requisitionSuccessMessage))
            .catch(errorCatcher);
        }
      });
    },
    [haveViewCreateRequisitionPermission]
  );
  return {
    openTask,
    addToRequisition: haveViewCreateRequisitionPermission ? addToRequisition : undefined,
  };
};
