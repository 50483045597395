import React from "react";

import styles from "./AnalyticsFulfillmentItemEntry.module.scss";

export interface IAnalyticsFulfillmentItemEntryProps {
  title: string;
  entries: { subtitle?: string; value: string | number; measure?: string }[];
}

const AnalyticsFulfillmentItemEntry: React.FC<IAnalyticsFulfillmentItemEntryProps> = ({ title, entries }) => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      <div className={styles.row}>
        {entries?.map((entry, index) => (
          <div className={styles.entry} key={index}>
            <span className={styles.subtitle}>{entry.subtitle || <>&nbsp;</>}</span>
            <div className={styles.value}>
              <span>{entry.value}</span> <span>{entry.measure}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(AnalyticsFulfillmentItemEntry);
