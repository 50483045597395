import React from "react";
import { useDispatch } from "react-redux";

import { isTasksLoadingSelector, tasksListsSelector } from "components/pages/Tasks/model/selectors";
import { loadMoreTasksLists, loadTasksLists } from "components/pages/Tasks/model/thunks";

import { useTypedSelector } from "app/store/typedUseSelector";

export const useTasksLists = (objectId: string) => {
  const dispatch = useDispatch();
  const tasksLists = useTypedSelector(tasksListsSelector);
  const isTasksLoading = useTypedSelector(isTasksLoadingSelector);
  const { invalidateKey } = useTypedSelector((state) => state.tasks);

  React.useEffect(() => {
    /* @ts-ignore */
    dispatch(loadTasksLists(objectId));
  }, [objectId, invalidateKey]);

  const loadMoreTasksListsHandler = React.useCallback(() => {
    dispatch(loadMoreTasksLists(tasksLists.results.length));
  }, [tasksLists.results.length]);

  return {
    tasksLists,
    isTasksLoading,
    loadMoreTasksListsHandler,
  };
};
