import cn from "classnames";
import React, { useCallback } from "react";

import { Switch } from "../../../../../shared/ui/inputs/Switch/Switch";

import styles from "./index.module.scss";

const LabeledSwitch = ({ onChange, label, labelRight, value: isActive, className = "", disabled = false }) => {
  const handleActive = useCallback(() => {
    if (disabled) return;
    onChange?.(!isActive);
  }, [isActive, onChange, disabled]);

  return (
    <div className={cn(styles.switchBlock, className)} onClick={handleActive}>
      {label && <span className={cn(styles.text, { [styles.textInactive]: isActive && !!labelRight })}>{label}</span>}
      <div style={{ alignSelf: "flex" }}>
        <Switch checked={isActive} disabled={disabled} />
      </div>
      {labelRight && <span className={cn(styles.textRight, { [styles.textInactive]: !isActive })}>{labelRight}</span>}
    </div>
  );
};

export default React.memo(LabeledSwitch);
