import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import {
  expendituresSubMaterialsLoadingSelector,
  expendituresSubMaterialsSelector,
  getExpenditureSubMaterials,
} from "../../../../../../redux/modules/common/building/sections/sections";

import MatchingIcon, { MatchingStatusesEnum } from "../../../../../../_LEGACY/UI/MatchingIcon/MatchingIcon";
import SaveFileModal from "../../../../../../_LEGACY/UI/__trash/SaveFileModal/SaveFileModal";
import ChartPopup from "../../../../../pages/Building/components/ChartPopup/ChartPopup";
import Ks, { KS_TYPES } from "./components/Ks/Ks";
import VisibilityMark from "components/UI/_TODO/EstimateSection/components/VisibilityMark/VisibilityMark";

import SpecificationModal from "../../../../../../entities/SpecificationModal/SpecificationModal";
import UngroupExpenditureModal from "../UngroupExpenditureModal/UngroupExpenditureModal";

import { EXPENDITURE_TYPES_NAMES } from "../../../../../../constants/constant";
import { VIEW_BUILDING_UNGROUP_EXPENDITURES } from "../../../../../../constants/permissions/constructingPermissions";
import { VIEW_MANUFACTURING_PROJECT_UNGROUP_EXPENDITURES } from "../../../../../../constants/permissions/manufacturingPermissions";
import { VARIANTS } from "../../constants";

import { useCommonModulesPermissions } from "../../../../../../hooks/useCommonModulesPermissions";

import PaperClipIcon from "../../../../../../images/icons/PaperClipIcon";
import ungroupIcon from "../../../../../../images/icons/ungroup.svg";

import styles from "./Info.module.scss";

const Info = ({
  expenditure,
  buildingId,
  variant,
  isShared,
  onOverview,
  overviewState,
  canDeleteFiles,
  canAddFiles,
  loadSectionExpenditures,
  isKSHidden,
  onReplacementIconClick,
  canViewFiles = true,
  isExpendituresGroup,
  handleShowGroupModal,
  isPopupsHidden,
  onFileDirectlyClick,
  isFileHidden,
  isProductionInHendler = false,
}) => {
  const [isOpenJustification, setIsOpenJustification] = useState(false);
  const [isOpenUngroupModal, setIsOpenUngroupModal] = useState(false);

  const haveUngroupExpendituresPermission = useCommonModulesPermissions({
    objects: VIEW_MANUFACTURING_PROJECT_UNGROUP_EXPENDITURES,
    constructing: VIEW_BUILDING_UNGROUP_EXPENDITURES,
  });

  const isUngroupExists =
    !isExpendituresGroup &&
    haveUngroupExpendituresPermission &&
    variant === VARIANTS.PLAN &&
    expenditure.expenditure_type === "work" &&
    !expenditure.linked_expenditures_count &&
    !expenditure.linked_expenditure_id;

  const onUngroupIconClick = () => setIsOpenUngroupModal(true);
  const onCloseUngroupModal = () => setIsOpenUngroupModal(false);

  const dispatch = useDispatch();
  const subMaterialsAreLoading = useSelector(expendituresSubMaterialsLoadingSelector);
  const subMaterials = useSelector(expendituresSubMaterialsSelector);

  const areThereSubMaterials = subMaterials && subMaterials.length !== 0;

  const onClickByJustification = useCallback(() => {
    setIsOpenJustification(true);
    if (!subMaterials[expenditure.id]) compose(dispatch, getExpenditureSubMaterials)(buildingId, expenditure.id);
  }, [buildingId, expenditure.id, subMaterials]);

  const closeJustification = useCallback(() => setIsOpenJustification(false), []);

  const [isOpenFilesModal, setIsOpenFilesModal] = useState(false);
  const closeFilesModal = useCallback(() => setIsOpenFilesModal(false), []);

  const displayingJustificationName = useMemo(() => {
    if (!expenditure.justification) return "-";
    if (!isNaN(+expenditure.justification[0])) return expenditure.justification;
    return expenditure.justification;
  }, [expenditure.justification]);

  const justificationLabel = () => {
    if (isExpendituresGroup) return `Расценок: ${expenditure?.expenditures?.length}`;
    if (!!expenditure.linked_expenditure_id) return `${EXPENDITURE_TYPES_NAMES[expenditure.expenditure_type] || ""}`;
    return displayingJustificationName;
  };

  const onJustificationClick = (e) => {
    if (isExpendituresGroup) handleShowGroupModal?.(e);
    else if (!!expenditure.linked_expenditure_id) return;
    else onClickByJustification(e);
  };

  return (
    <div className={styles.container}>
      <div title={expenditure.name} className={styles.title}>
        {expenditure.name}
      </div>
      <div className={styles.labelBox}>
        <div
          className={cn(styles.blueLabel, { [styles.cursorPointer]: areThereSubMaterials })}
          title={displayingJustificationName}
          onClick={onJustificationClick}
        >
          {justificationLabel()}
        </div>
        {!isFileHidden && canViewFiles && (
          <div
            className={styles.greyLabel}
            onClick={() => {
              if (!!onFileDirectlyClick) {
                onFileDirectlyClick();
              } else {
                setIsOpenFilesModal(true);
              }
            }}
          >
            <PaperClipIcon width={7} height={11} fill="#808080" />
            {expenditure.count_files > 0 && expenditure.count_files}
          </div>
        )}
        {!isKSHidden && variant !== VARIANTS.HANDLER && variant !== VARIANTS.HANDLER_DRAFT && (
          <>
            <Ks
              count={expenditure.ks2_list?.length}
              expenditureId={expenditure.id}
              ks={expenditure?.ks2_list}
              type={KS_TYPES.KS2}
            />
            <Ks
              count={expenditure.ks3_list?.length}
              expenditureId={expenditure.id}
              ks={expenditure?.ks3_list}
              type={KS_TYPES.KS3}
            />
          </>
        )}
        {!isPopupsHidden && !isShared && variant !== VARIANTS.HANDLER_DRAFT && (
          <ChartPopup
            onHover={onOverview}
            accepted={overviewState.accepted}
            all={overviewState.all}
            completed={overviewState.completed}
          />
        )}
        {isProductionInHendler && (
          <VisibilityMark isExpenditure variant={expenditure.hidden ? "allHidden" : "allAvailable"} />
        )}
        {isUngroupExists && (
          <img src={ungroupIcon} alt={"Разгруппировка"} className={styles.ungroupIcon} onClick={onUngroupIconClick} />
        )}
        {/* {!!expenditure?.count_replacement_expenditures && (
          <div className={styles.greyLabel} onClick={onReplacementIconClick} title={"Вариантов для замены"}>
            {expenditure.count_replacement_expenditures}
          </div>
        )} */}
      </div>
      <SaveFileModal
        isOpen={isOpenFilesModal}
        handleClose={closeFilesModal}
        product={expenditure}
        objectId={buildingId}
        canDelete={canDeleteFiles}
        canUpload={canAddFiles}
        loadSectionExpenditures={loadSectionExpenditures}
      />
      {isUngroupExists && (
        <UngroupExpenditureModal
          isOpen={isOpenUngroupModal}
          onClose={onCloseUngroupModal}
          expenditureId={expenditure.id}
          expenditureName={expenditure.name}
          expenditureNumber={expenditure.number}
          measure={expenditure.measure}
          count={expenditure.indicators?.count}
          estimatedCost={expenditure.estimated_cost}
          budget={expenditure.indicators?.estimate_amount}
          ungroupSuccessCallback={loadSectionExpenditures}
        />
      )}
      {areThereSubMaterials && (
        <SpecificationModal
          name={expenditure.justification}
          isOpen={isOpenJustification}
          onClose={closeJustification}
          subMaterials={subMaterials[expenditure.id]}
          isLoading={subMaterialsAreLoading}
        />
      )}
    </div>
  );
};

export default React.memo(Info);
