import cn from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";

import Icon from "_LEGACY/UI/_LEGACY_Icon/Icon";

import { Spinner } from "shared/ui/atoms/Spinner/Spinner";

import useEscapeHandler from "utils/hooks/useEscapeHandler";

import arrowDownSelect from "images/icons/arrowDownSelect.svg";

import styles from "./SelectStatus.module.scss";

const INITIAL_OPTIONS = [];

const getOptionById = (options, id) => options.find((option) => option.id === id);

const SelectStatus = (props) => {
  const {
    options = INITIAL_OPTIONS,
    value,
    placeholder = "Выберите",
    onChange,
    disabled,
    className,
    contentClassName,
    optionsClassName,
    optionClassName,
    label,
    icon = arrowDownSelect,
    isValueFrozen,
    isLoading,
  } = props;
  const selectRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [localValueId, setLocalValueId] = useState(value);
  const [availableOptions, setAvailableOptions] = useState(
    options.length && value ? options.filter((x) => x.id !== value) : options
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const handleOpening = useCallback(() => {
    if (disabled || isLoading) return;
    setIsOpen((prevState) => !prevState);
  }, [disabled, isLoading]);

  const selectOption = useCallback(
    (optionId) => {
      if (onChange) onChange(optionId);
      setIsOpen(false);
      if (!isValueFrozen) {
        setLocalValueId(optionId);
        setAvailableOptions(options.filter((x) => x.id !== optionId));
      }
    },
    [onChange, isValueFrozen]
  );

  const handleTitleClick = () => {
    if (!disabled) {
      setIsOpen(false);
      onChange(localValueId);
    }
  };

  useEscapeHandler(() => setIsOpen(false));

  return (
    <>
      {label && <label>{label}</label>}
      <div className={cn(styles.selectContainer, className)} ref={selectRef}>
        <div
          className={cn(styles.select, contentClassName, {
            [styles.isOpen]: isOpen && !disabled,
            [styles.disabled]: disabled || isLoading,
            [styles.loading]: isLoading,
          })}
        >
          <span className={cn(styles.title)} onClick={handleTitleClick}>
            {localValueId ? getOptionById(options, localValueId)?.name : placeholder}
          </span>
          <div className={styles.iconContainer} onClick={handleOpening}>
            {!disabled && (
              <Icon icon={icon} className={cn(styles.arrow, { [styles.arrowReverse]: isOpen && !disabled })} />
            )}
          </div>
        </div>
        {isOpen && (
          <div className={cn(styles.optionsBlock, optionsClassName)}>
            {availableOptions &&
              availableOptions.map((option) => (
                <div
                  key={option.id}
                  className={cn(styles.option, optionClassName, { [styles.selected]: option.id === localValueId })}
                  onClick={() => selectOption(option.id)}
                >
                  <span className={styles.name}>{option.name}</span>
                </div>
              ))}
            {(!availableOptions || availableOptions.length === 0) && (
              <div className={cn(styles.option, optionClassName)}>Нет данных</div>
            )}
          </div>
        )}
        {isLoading && (
          <div className={styles.spinner}>
            <Spinner isStatic isSmallGray />
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(SelectStatus);
