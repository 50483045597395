import cn from "classnames";
import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { loadAggregations } from "../../../../../../../../../Aggregations/model/thunks";
import { simpleHandlerActions } from "../../../../../../../../model/slice";
import { reloadActiveSubSection, setEstimatedCost } from "../../../../../../../../model/thunks";
import DoubleInput, { Measure } from "shared/ui/inputs/DoubleInput/DoubleInput";

import { IExpenditure } from "../../../../../../../../../../../../types/interfaces/Expenditure";

import useEstimatedPriceDoubleInput from "../../../../../../../../../ProHandler/hooks/useEstimatedPriceDoubleInput";

import { beautifyNumberValue } from "../../../../../../../../../../../../utils/formatters/beautifyNumberValue";
import {
  parseTransformedNumber,
  transformDigitToFinancial,
} from "../../../../../../../../../../../../utils/formatters/transformDigitToFinancial";
import { percentsFormatter } from "../../../../../../../../../../../../utils/percentsFormatter";

import blackCheckIcon from "../../../../../../../../../../../../images/icons/blackCheckIcon";
import blueCheckIcon from "../../../../../../../../../../../../images/icons/blueCheckIcon";

import styles from "../../Expenditure.module.scss";

interface IProps {
  expenditure: IExpenditure;
}

export const EstimatedPriceColumns = memo<IProps>(({ expenditure }) => {
  const dispatch = useDispatch();

  const changeCost = useCallback(
    (v: string | number) => {
      dispatch(
        setEstimatedCost(expenditure.id, v, (estimated_cost) => {
          dispatch(simpleHandlerActions.setExpenditureEstimatedCost({ id: expenditure.id, estimated_cost }));
          dispatch(reloadActiveSubSection());
          dispatch(loadAggregations());
        })
      );
    },
    [dispatch, expenditure]
  );

  const { editableCost, costByUnit, onChangeCost, onChangeCostByUnit } = useEstimatedPriceDoubleInput({
    cost: expenditure.estimated_cost,
    changeCost,
    count: expenditure.count,
  });

  const budget = +expenditure.price * +expenditure.count;

  const difference = budget - +beautifyNumberValue(editableCost);
  const differencePercent = (difference * 100) / +budget;

  const displayedPercent = +editableCost === 0 ? "0%" : percentsFormatter(differencePercent);
  const displayedDifference =
    +editableCost === 0 ? 0 : transformDigitToFinancial(difference, { withFloat: true, dropZeros: true });

  return (
    <>
      <div className={cn(styles.column, styles.isCentered)}>
        {expenditure.count} ({expenditure.measure})
      </div>
      <div className={cn(styles.column, styles.isCentered)}>
        {transformDigitToFinancial(budget)}
        <br />({transformDigitToFinancial(budget / +expenditure.count)} за ед.)
      </div>
      <div className={cn(styles.column, styles.isCentered)}>
        <div className={styles.estimatePriceCol}>
          <DoubleInput
            firstValue={costByUnit?.toString() || "0"}
            secondValue={editableCost?.toString() || "0"}
            firstInputSize={1}
            secondInputSize={1}
            onChangeFirstValue={onChangeCostByUnit}
            onChangeSecondValue={onChangeCost}
            firstValueMeasure={Measure.currency}
            secondValueMeasure={Measure.currency}
            firstValueLabel="за единицу"
            secondValueLabel="итого"
            inputFontSize={parseTransformedNumber(editableCost) ?? 0 < 10_000_000_000 ? 1 : 0.875}
            className={styles.estimatePriceDoubleInput}
          />
          {+differencePercent !== 0 && +editableCost !== 0 ? (
            {
              ...blueCheckIcon,
              props: { ...blueCheckIcon.props, width: 18, height: 18 },
            }
          ) : (
            <span className={styles.withoutDiscountIcon} title="Разница отсутствует">
              {{
                ...blackCheckIcon,
                props: { ...blackCheckIcon.props, width: 18, height: 18 },
              }}
            </span>
          )}
        </div>
      </div>
      <div className={cn(styles.column, styles.diff)}>
        <div className={styles.diffPercent}>{displayedPercent === "-Infinity%" ? "-" : displayedPercent}</div>
        <div className={styles.diffPrice}>{displayedDifference}</div>
      </div>
    </>
  );
});

EstimatedPriceColumns.displayName = "EstimatedPriceColumns";
