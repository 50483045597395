import React from "react";

import TemplateSimple from "../../../_LEGACY/UI/_LEGACY_TemplateSimple/TemplateSimple";

import Order from "./index";

const TemplatedOrder = (props) => {
  return (
    <TemplateSimple>
      <Order {...props} />
    </TemplateSimple>
  );
};

export default React.memo(TemplatedOrder);
