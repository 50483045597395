import React from "react";

import CircleAddIcon from "../../../../images/icons/CircleAddIcon";

import styles from "./AddButton.module.scss";

export const AddButton = React.memo((props) => {
  return (
    <button {...props} className={`${styles.addButton} ${props.className}`}>
      {props.children && <span className={styles.text}>{props.children}</span>}
      <CircleAddIcon />
    </button>
  );
});
