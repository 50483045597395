import update from "immutability-helper";
import moment from "moment";

import { generateRandomId } from "../../../../../utils/helpers/generateRandomId";

class Fork {
  static #generateNewRequest(blankRequest, { id, ...baseRequest }) {
    const newRequest = baseRequest;

    newRequest.temporaryId = generateRandomId();
    newRequest.count = parseFloat(blankRequest.count).toFixed(2);
    newRequest.delivery_date = moment(blankRequest.date).format("YYYY-MM-DD");
    newRequest.offers = [
      {
        temporaryId: generateRandomId(),
        supplies: [{ temporaryId: generateRandomId() }],
        measure: baseRequest.measure,
      },
    ];
    newRequest.separated_from_id = id;

    return newRequest;
  }

  static forkRequest(newRequestBlank, targetRequest) {
    const differenceInCounts = parseFloat(targetRequest.count) - parseFloat(newRequestBlank.count);
    if (differenceInCounts <= 0) return;

    const newRequest = this.#generateNewRequest(newRequestBlank, targetRequest);

    return {
      targetRequest: update(targetRequest, { count: { $set: differenceInCounts.toFixed(2) } }),
      newRequest,
    };
  }

  static forkKit(newRequestBlank, targets) {
    const differenceInCounts = parseFloat(targets.request.count) - parseFloat(newRequestBlank.count);
    if (differenceInCounts <= 0) return;

    const newRequest = this.#generateNewRequest(newRequestBlank, targets.request);
    const targetRequestIndex = targets.kit.requests.indexOf(targets.request);

    return {
      targetKit: update(targets.kit, {
        requests: { [targetRequestIndex]: { count: { $set: differenceInCounts.toFixed(2) } } },
      }),
      newRequest,
    };
  }
}

export default Fork;
