const REQUISITION_TAB = "requisition";
const ORDER_TAB = "order";
const PAYMENT_TAB = "payment";

const INITIAL_TABS = [
  { id: "requisition", name: "Обработка заявки", isActive: false },
  { id: "order", name: "Обработка заказов", isActive: false },
  { id: "payment", name: "Обработка платежей", isActive: false }
];

class ProgressTabs {
  static setActiveTab = (tabs, setTabId) => tabs.map((tab) => tab.id === setTabId ?
    { ...tab, isActive: true } : { ...tab, isActive: false }
  );

  static calculateRequisitionTabSteps = (products, infoDataRequest) => {
    const noInOrdersProducts = products.filter((product) => !product.in_orders);

    if (products.length > 0) return { activeSteps: noInOrdersProducts.length, totalSteps: noInOrdersProducts.length };

    return { activeSteps: infoDataRequest.state.active.items, totalSteps: infoDataRequest.state.total.items };
  };

  static calculateTabsSteps = (initialTabs, products, infoDataRequest) => initialTabs.map((tab) => {
    if (tab.id === REQUISITION_TAB) return {
      ...tab,
      ...ProgressTabs.calculateRequisitionTabSteps(products, infoDataRequest)
    };

    if (tab.id === ORDER_TAB) return {
      ...tab,
      activeSteps: infoDataRequest.state.active.orders,
      totalSteps: infoDataRequest.state.total.orders
    };

    if (tab.id === PAYMENT_TAB) return {
      ...tab,
      activeSteps: infoDataRequest.state.active.payments,
      totalSteps: infoDataRequest.state.total.payments
    };

    return tab;
  });

  static getActiveTab = (tabs) => tabs.find((tab) => tab.isActive);

  static getTabsByPermission = (permissions) => {
    return INITIAL_TABS.filter((tab) => {
      if (tab.id === REQUISITION_TAB) return permissions.processRequisitionStep;
      if (tab.id === ORDER_TAB) return permissions.processOrdersStep;
      if (tab.id === PAYMENT_TAB) return permissions.processPaymentsStep;

      return true;
    });
  }

  static getTabs = (permissions, haveOrders, havePaymentOrders) => {
    const tabsByPermissions = ProgressTabs.getTabsByPermission(permissions);
    if (tabsByPermissions.length === 0) return [];

    const markedWithoutOrdersTabs = tabsByPermissions.map((tab) => {
      if (tab.id === ORDER_TAB && !haveOrders) return { ...tab, disabled: true };
      if (tab.id === PAYMENT_TAB && !havePaymentOrders) return { ...tab, disabled: true };

      return tab;
    });
    return ProgressTabs.setActiveTab(markedWithoutOrdersTabs, markedWithoutOrdersTabs[0].id);
  };
}

export { REQUISITION_TAB, ORDER_TAB, PAYMENT_TAB, INITIAL_TABS };
export default ProgressTabs;
