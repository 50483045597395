import { IGroupInfo } from "components/UI/_TODO/ExpenditureGroupModal/types";

import { SET_GROUP_DETAIL_INFO } from "./reducer";
import { IActions } from "app/store/store";

export const setGroupDetailInfo = (payload: IGroupInfo | null) => ({
  type: SET_GROUP_DETAIL_INFO,
  payload,
});

export const journalActions = {
  changeInvalidateKey: () =>
    ({
      type: "journal/CHANGE_INVALIDATE_KEY",
      payload: true,
    } as const),
};

export type IJournalAction = IActions<typeof journalActions>;
