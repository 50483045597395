import axios from "axios";

import { IntervalType } from "../../../../../components/pages/Manufacturing/constants";
import { IGroupPlanData, IGroupWorkData } from "../../../../../components/pages/Manufacturing/types";

import { IListResponseData } from "../../../../../types/ListResponse";
import { IRemarksList } from "../journal/journalExecution/types";
import { IProjectTreeResponse } from "./types";

import { queryParamsFormatter } from "../../../../../utils/formatters/queryParamsFormatter";

export * from "./manufacturingApi";

export const manufacturingApi = {
  getManufacturingProjects: () => {
    return axios.get<IProjectTreeResponse>(`/tree/buildings/`, {
      params: { limit: 1000 },
    });
  },
  getEstimateTree: (projectId: number | string, expenditureType: IntervalType | IntervalType[]) => {
    const params = Array.isArray(expenditureType)
      ? queryParamsFormatter({}, expenditureType, "expenditure_type")
      : { expenditure_type: expenditureType };
    return axios.get<IProjectTreeResponse>(`/tree/buildings/${projectId}/`, {
      params,
    });
  },
  getGroupsFacts: (projectId: number | string, end_at_after: string, end_at_before: string) => {
    const params = { end_at_after, end_at_before };
    return axios.get<IGroupWorkData[]>(`/building/${projectId}/groups/facts/`, {
      params,
    });
  },
  getGroupFact: (projectId: number | string, factId: number) => {
    return axios.get<IGroupWorkData>(`/building/${projectId}/groups/facts/${factId}`);
  },
  getGroupsPlans: (
    projectId: number | string,
    end_at_after: string,
    end_at_before: string,
    extraParams: Record<string, any> = {}
  ) => {
    const params = { end_at_after, end_at_before, ...extraParams };
    return axios.get<IGroupPlanData[]>(`/building/${projectId}/groups/plans/`, {
      params,
    });
  },
  getGroupPlan: (projectId: number | string, planId: number) => {
    return axios.get<IGroupPlanData>(`/building/${projectId}/groups/plans/${planId}`);
  },
  getGroupRemarks: (projectId: number, groupId: number, params: Record<string, unknown> = {}) => {
    return axios.get<IListResponseData<IRemarksList>>(`/building/${projectId}/remarks/`, {
      params: {
        estimate_group_id: groupId,
        with_files: 1,
        ...params,
      },
    });
  },
  getGroupTicketRemarks: (projectId: number, groupFactId: number, params: Record<string, unknown> = {}) => {
    return axios.get<IListResponseData<IRemarksList>>(`/building/${projectId}/ticket_remarks/`, {
      params: {
        group_interval_id: groupFactId,
        with_files: 1,
        ...params,
      },
    });
  },
  getWorkRemarks: (projectId: number, expenditureId: number, params: Record<string, unknown> = {}) => {
    return axios.get<IListResponseData<IRemarksList>>(`/building/${projectId}/remarks/`, {
      params: {
        estimate_expenditure_id: expenditureId,
        with_files: 1,
        ...params,
      },
    });
  },
  getWorkTicketRemarks: (projectId: number, workFactId: number, params: Record<string, unknown> = {}) => {
    return axios.get<IListResponseData<IRemarksList>>(`/building/${projectId}/ticket_remarks/`, {
      params: {
        work_interval_id: workFactId,
        with_files: 1,
        ...params,
      },
    });
  },
  downloadChart: (projectId: number | string, year: string) => {
    return axios.post<IGroupPlanData>(
      `/tree/buildings/${projectId}/chart/report/?year=${year}`,
      {},
      { responseType: "blob" }
    );
  },
  shiftInterval: (intervalId: number, days: number, intervalType: "plan_work" | "plan_group") => {
    return axios.post(`/shift/chart/`, { interval_id: intervalId, interval_type: intervalType, days });
  },
};
