import { CURRENCY_ENUM } from "../types/enums/CurrencyEnum";

export const REGIONS_LABELS = {
  ru: "ru",
  kz: "kz",
}; // for .js files

export enum RegionEnum {
  ru = "ru",
  kz = "kz",
} // for .ts files

export interface LocalizationConfig {
  currency: CURRENCY_ENUM;
  taxesNumberLabel: string;
  langLabel: keyof typeof RegionEnum;
  bicLength: number;
  taxesNumberLength: number;
}

const ruConfig: LocalizationConfig = {
  currency: CURRENCY_ENUM.RUBLE,
  taxesNumberLabel: "ИНН",
  taxesNumberLength: 10,
  langLabel: "ru",
  bicLength: 9,
};

const kzConfig: LocalizationConfig = {
  currency: CURRENCY_ENUM.TENGE,
  taxesNumberLabel: "БИН",
  taxesNumberLength: 12,
  langLabel: "kz",
  bicLength: 8,
};

const chooseLocalizationConfig = () => {
  switch ((window as any).externalConfig?._settingLangLabel) {
    case RegionEnum.kz:
      return kzConfig;
    case RegionEnum.ru:
      return ruConfig;
    default:
      return ruConfig;
  }
};

export const LOCALIZATION_CONFIG = chooseLocalizationConfig();
