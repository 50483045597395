import React from "react";

import { LOCALIZATION_CONFIG } from "constants/localization";

import { dropNonSignificantZeros } from "../../../../../../utils/formatters/dropNonSignificantZeros";
import { formatNumberWithMeasure } from "../../../../../../utils/formatters/formatNumberWithMeasure";

import styles from "./AmountAndCount.module.scss";

function AmountAndCount({ amount, count, measure }) {
  const roundedCount = !!count ? dropNonSignificantZeros(count, 4) : " - ";
  const formattedMeasure = amount ? formatNumberWithMeasure(amount, measure) : ` - ${LOCALIZATION_CONFIG.currency}`;
  return (
    <div className={styles.container}>
      <div className={styles.count} title={roundedCount}>
        {roundedCount}
      </div>
      <div className={styles.divider} />
      <div className={styles.amount} title={formattedMeasure}>
        {formattedMeasure}
      </div>
    </div>
  );
}

export default AmountAndCount;
