import moment from "moment";

import { WORKS_TAB_ID } from "../../../../../components/pages/Manufacturing/constants";

import {
  addIntervalLinkHelper,
  concatStateMaterialField,
  concatStateMimesField,
  concatStateWorkField,
  deleteArrowsHelper,
  dropChartMonths,
  dropHighlightedElements,
  highlightArrow,
  isArrowDraggedHelper,
  markYearMonth,
  setArrowsHelper,
  updateArrowHelper,
} from "./utils";

export const moduleName = "manufacturing";

export const GET_ESTIMATE_TREE = `${moduleName}/GET_ESTIMATE_TREE`;
export const GET_ESTIMATE_MATERIALS_TREE = `${moduleName}/GET_ESTIMATE_MATERIALS_TREE`;
export const GET_ESTIMATE_MIMES_TREE = `${moduleName}/GET_ESTIMATE_MIMES_TREE`;
export const GET_ESTIMATE_RESOURCES_TREE = `${moduleName}/GET_ESTIMATE_RESOURCES_TREE`;
export const GET_ESTIMATE_EQUIPMENT_TREE = `${moduleName}/GET_ESTIMATE_EQUIPMENT_TREE`;

export const GET_PLAN = `${moduleName}/GET_PLAN`;
export const GET_MATERIALS = `${moduleName}/GET_MATERIALS`;
export const GET_MIMES = `${moduleName}/GET_MIMES`;

export const GET_WEEK_PLAN = `${moduleName}/GET_WEEK_PLAN`;
export const GET_WEEK_MATERIALS = `${moduleName}/GET_WEEK_MATERIALS`;
export const GET_WEEK_MIMES = `${moduleName}/GET_WEEK_MIMES`;

export const SET_CHART_VIEW_MODE = `${moduleName}/SET_CHART_VIEW_MODE`;
export const SET_TAB = `${moduleName}/SET_TAB`;

export const GET_INTERVAL = `${moduleName}/GET_INTERVAL`;
export const GET_MATERIAL_INTERVAL = `${moduleName}/GET_MATERIAL_INTERVAL`;
export const GET_MODAL_INTERVAL_LIST = `${moduleName}/GET_MODAL_INTERVAL_LIST`;
export const GET_MODAL_INTERVAL = `${moduleName}/GET_MODAL_INTERVAL`;
export const GET_MODAL_FILES = `${moduleName}/GET_MODAL_FILES`;
export const GET_MODAL_MATERIALS = `${moduleName}/GET_MODAL_MATERIALS`;
export const SET_MODAL_DATA = `${moduleName}/SET_MODAL_DATA`;

export const GET_PROJECTS = `${moduleName}/GET_PROJECTS`;
export const SET_IS_LOADING_PROJECTS = `${moduleName}/SET_IS_LOADING_PROJECTS`;

export const SET_PROJECT_DATA = `${moduleName}/SET_PROJECT_DATA`;
export const SET_MATERIAL_DATA = `${moduleName}/SET_MATERIAL_DATA`;
export const SET_MIMES_DATA = `${moduleName}/SET_MIMES_DATA`;
export const PROJECT_ESTIMATE_SET = `${moduleName}/PROJECT_ESTIMATE_SET`;
export const SET_PROJECT_WEEK_DATA = `${moduleName}/SET_PROJECT_WEEK_DATA`;
export const SET_MATERIAL_WEEK_DATA = `${moduleName}/SET_MATERIAL_WEEK_DATA`;
export const SET_MIMES_WEEK_DATA = `${moduleName}/SET_MIMES_WEEK_DATA`;

export const SET_IS_LOADING_MODAL = `${moduleName}/SET_IS_LOADING_MODAL`;
export const SET_IS_LOADING_CHART_DATA = `${moduleName}/SET_IS_LOADING_CHART_DATA`;

export const MARK_MANUFACTURING_PROJECT_DATA_TYPE_MONTH_YEAR = `${moduleName}/MARK_MANUFACTURING_PROJECT_DATA_TYPE_MONTH_YEAR`;
export const MARK_CONSTRUCTING_PROJECT_DATA_TYPE_MONTH_YEAR = `${moduleName}/MARK_CONSTRUCTING_PROJECT_DATA_TYPE_MONTH_YEAR`;

export const DROP_LOADED_CHART_MONTHS = `${moduleName}/DROP_LOADED_CHART_MONTHS`;
export const SET_MONTH_MARKERS = `${moduleName}/SET_MONTH_MARKERS`;
export const SET_IS_LOADING_CHART_TREE = `${moduleName}/SET_IS_LOADING_CHART_TREE`;
export const SET_ACTIVE_BRANCH = `${moduleName}/SET_ACTIVE_BRANCH`;
export const UPDATE_DIAGRAM_FILTERS = `${moduleName}/UPDATE_DIAGRAM_FILTERS`;
export const SET_IS_LOCKED_INTERVAL_EDITING = `${moduleName}/SET_IS_LOCKED_INTERVAL_EDITING`;

export const ADD_INTERVAL_LINK = `${moduleName}/ADD_INTERVAL_LINK`;
export const SET_IS_ENABLED_LINKING = `${moduleName}/SET_IS_ENABLED_LINKING`;
export const SET_IS_BEING_DRAGGED = `${moduleName}/SET_IS_BEING_DRAGGED`;
export const SET_ARROWS = `${moduleName}/SET_ARROWS`;
export const DELETE_ARROW = `${moduleName}/DELETE_ARROW`;
export const UPDATE_ARROW = `${moduleName}/UPDATE_ARROW`;
export const SET_RELATIONS_FROM_CURRENT_INTERVAL = `${moduleName}/SET_RELATIONS_FROM_CURRENT_INTERVAL`;
export const SET_RELATIONS_TO_CURRENT_INTERVAL = `${moduleName}/SET_RELATIONS_TO_CURRENT_INTERVAL`;
export const SET_AVAILABLE_SECTIONS = `${moduleName}/SET_AVAILABLE_SECTIONS`;
export const SET_AVAILABLE_INTERVALS = `${moduleName}/SET_AVAILABLE_INTERVALS`;
export const PUSH_TO_RELATIONS = `${moduleName}/PUSH_TO_RELATIONS`;
export const PUSH_FROM_RELATIONS = `${moduleName}/PUSH_FROM_RELATIONS`;
export const REMOVE_FROM_RELATION = `${moduleName}/REMOVE_FROM_RELATION`;
export const REMOVE_TO_RELATION = `${moduleName}/REMOVE_TO_RELATION`;
export const UPDATE_TO_RELATION = `${moduleName}/UPDATE_TO_RELATION`;
export const UPDATE_FROM_RELATION = `${moduleName}/UPDATE_FROM_RELATION`;
export const HIGHLIGHT_INTERVAL = `${moduleName}/HIGHLIGHT_INTERVAL`;
export const DROP_HIGHLIGHTED_INTERVAL = `${moduleName}/DROP_HIGHLIGHTED_INTERVAL`;
export const SET_ARROW_HASH = `${moduleName}/SET_ARROW_HASH`;
export const SET_CRITICAL_DATES = `${moduleName}/SET_CRITICAL_DATES`;

export const SET_MANUFACTURING_HASH = `${moduleName}/SET_MANUFACTURING_HASH`;

export const SET_MANUFACTURING_EXPANDED_BRANCHES = `${moduleName}/SET_MANUFACTURING_EXPANDED_BRANCHES`;
export const SET_CONSTRUCTING_EXPANDED_BRANCHES = `${moduleName}/SET_CONSTRUCTING_EXPANDED_BRANCHES`;

export const FACT_INTERVALS = "fact-intervals";
export const INTERVALS = "intervals";

export const MONTH = "MONTH";
export const HALF_MONTH = "HALF_MONTH";
export const WEEK = "WEEK";
export const YEAR = "YEAR";

export const getInitialModalData = () => ({
  modalIntervalLists: {
    building: [],
    lsr: [],
    section: [],
    expenditure: [],
  },
  modalDetailedInterval: {
    building: {},
    lsr: {},
    section: {},
    expenditure: {},
  },
  modalFiles: [],
  modalMaterials: [],
  isLoading: {
    building: false,
    lsr: false,
    section: false,
    expenditure: false,
  },
  relationsFromCurrentInterval: [],
  relationsToCurrentInterval: [],
  availableSections: [],
  availableIntervals: [],
});

export const getInitialWorksData = () => ({
  plans: [],
  works: [],
  sections: [],
  planned_sections: [],
  groupPlans: [],
  groupWorks: [],
});

export const getInitialMaterialData = () => ({
  accepted: [],
  on_stock: [],
  payed: [],
  to_paid: [],
  plans: [],
  purchases: [],
  stockless: [],
});

export const getInitialMimesData = () => ({
  accepted: [],
  on_stock: [],
  passed: [],
  plans: [],
  purchases: [],
  stockless: [],
  to_paid: [],
});

export const getInitialDiagramFilters = () => ({
  confirmed_highlight: true, // не отображается в UI
  moderation_highlight: true, // не отображается в UI
  canceled_highlight: true, // не отображается в UI
  linking_enabled: true, // не отображается в UI
  remarks_visible: true, // не отображается в UI
  out_of_estimate_enabled: false,
  linking_editing_enabled: false, // XOR с plans_editing_enabled
  plans_editing_enabled: false, // XOR с linking_editing_enabled
});

export const initialState = {
  tree: [],
  materialsTree: [],
  mimesTree: [],
  resourcesTree: [],
  equipmentTree: [],

  plan: getInitialWorksData(),
  materials: getInitialMaterialData(),
  mimes: getInitialMimesData(),

  weekPlan: { [moment().year()]: getInitialWorksData() },
  weekMaterials: { [moment().year()]: getInitialMaterialData() },
  weekMimes: { [moment().year()]: getInitialMimesData() },

  interval: {},
  materialInterval: {},

  projects: [],
  isLoadingProjects: false,

  chartViewMode: MONTH,
  tab: WORKS_TAB_ID,

  modal: getInitialModalData(),

  projectData: getInitialWorksData(),
  materialData: getInitialMaterialData(),
  mimesData: getInitialMimesData(),

  projectWeekData: {},
  materialWeekData: {},
  mimesWeekData: {},

  projectEstimate: new Map(),
  monthMarkers: {},
  manufacturingExpandedBranches: new Set(),
  constructingExpandedBranches: new Set(),

  isLoadingChartData: true,
  isBlockedRunningLine: false,
  activeBranch: {
    index: 0,
    eventFrom: "",
  },

  loadedManufacturingChartData: {},
  loadedConstructingChartData: {},

  manufacturingHash: null,

  diagramFilters: getInitialDiagramFilters(),
  isLockedIntervalEditing: false,

  diagramIntervalLinks: {
    isBeingDragged: {},
    arrows: {},
    highlightedArrows: {},
    highlightedIntervals: {},
    hash: null,
  },

  isMaterialsLoading: false,
  criticalDates: [],
};
/* @ts-ignore */
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_LOADING_CHART_TREE:
      return {
        ...state,
        isLoadingChartTree: payload,
      };
    case GET_ESTIMATE_TREE:
      return {
        ...state,
        tree: payload,
      };
    case GET_ESTIMATE_MATERIALS_TREE:
      return {
        ...state,
        materialsTree: payload,
      };
    case GET_ESTIMATE_MIMES_TREE:
      return {
        ...state,
        mimesTree: payload,
      };
    case GET_ESTIMATE_RESOURCES_TREE:
      return {
        ...state,
        resourcesTree: payload,
      };
    case GET_ESTIMATE_EQUIPMENT_TREE:
      return {
        ...state,
        equipmentTree: payload,
      };
    case GET_PLAN:
      return concatStateWorkField("plan", state, payload);
    case GET_WEEK_PLAN:
      return concatStateWorkField("weekPlan", state, payload);
    case SET_IS_LOADING_PROJECTS:
      return {
        ...state,
        isLoadingProjects: payload,
      };
    case GET_PROJECTS:
      return {
        ...state,
        projects: payload,
        isLoadingProjects: false,
      };
    case SET_PROJECT_DATA:
      return concatStateWorkField("projectData", state, payload);
    case SET_MATERIAL_DATA:
      return concatStateMaterialField("materialData", state, payload);
    case SET_MIMES_DATA:
      return concatStateMimesField("mimesData", state, payload);
    case SET_PROJECT_WEEK_DATA:
      return concatStateWorkField("projectWeekData", state, payload);
    case SET_MATERIAL_WEEK_DATA:
      return concatStateMaterialField("materialWeekData", state, payload);
    case SET_MIMES_WEEK_DATA:
      return concatStateMimesField("mimesWeekData", state, payload);
    case PROJECT_ESTIMATE_SET:
      return {
        ...state,
        projectEstimate: state.projectEstimate.set(payload.key, payload.value),
      };
    case GET_INTERVAL:
      return {
        ...state,
        interval: payload,
      };
    case GET_MATERIAL_INTERVAL:
      return {
        ...state,
        materialInterval: payload,
      };
    case SET_CHART_VIEW_MODE:
      return {
        ...state,
        chartViewMode: payload,
      };
    case SET_TAB:
      return {
        ...state,
        tab: payload,
      };
    case GET_MIMES:
      return concatStateMimesField("mimes", state, payload);
    case GET_WEEK_MIMES:
      return concatStateMimesField("weekMimes", state, payload);
    case GET_MATERIALS:
      return concatStateMaterialField("materials", state, payload);
    case GET_WEEK_MATERIALS:
      return concatStateMaterialField("weekMaterials", state, payload);
    case GET_MODAL_INTERVAL_LIST:
      return {
        ...state,
        modal: {
          ...state.modal,
          modalIntervalLists: {
            ...state.modal.modalIntervalLists,
            [payload.modalType]: payload.intervals,
          },
        },
      };
    case GET_MODAL_INTERVAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          modalDetailedInterval: { ...state.modal.modalDetailedInterval, [payload.modalType]: payload.data },
        },
      };
    case GET_MODAL_MATERIALS:
      return {
        ...state,
        modal: {
          ...state.modal,
          modalMaterials: payload,
        },
      };
    case GET_MODAL_FILES:
      return {
        ...state,
        modal: {
          ...state.modal,
          modalFiles: payload,
        },
      };
    case SET_IS_LOADING_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          isLoading: { ...state.modal.isLoading, [payload.modalType]: payload.isLoading },
        },
      };
    case SET_MODAL_DATA:
      return {
        ...state,
        modal: payload,
      };
    case SET_IS_LOADING_CHART_DATA:
      return {
        ...state,
        isLoadingChartData: payload,
      };
    case DROP_LOADED_CHART_MONTHS:
      return dropChartMonths(state);
    case MARK_MANUFACTURING_PROJECT_DATA_TYPE_MONTH_YEAR:
      return markYearMonth("loadedManufacturingChartData", state, payload);
    case MARK_CONSTRUCTING_PROJECT_DATA_TYPE_MONTH_YEAR:
      return markYearMonth("loadedConstructingChartData", state, payload);
    case SET_MONTH_MARKERS:
      return {
        ...state,
        monthMarkers: {
          ...state.monthMarkers,
          [payload.year]: payload.markers,
        },
      };
    case SET_ACTIVE_BRANCH:
      return {
        ...state,
        activeBranch: payload,
      };
    case UPDATE_DIAGRAM_FILTERS:
      return {
        ...state,
        diagramFilters: {
          ...state.diagramFilters,
          [payload.name]: payload.value,
        },
      };
    case SET_IS_LOCKED_INTERVAL_EDITING:
      return {
        ...state,
        isLockedIntervalEditing: payload,
      };
    case ADD_INTERVAL_LINK:
      return addIntervalLinkHelper(state, payload);
    case SET_ARROWS:
      return setArrowsHelper(state, payload);
    case DELETE_ARROW:
      return deleteArrowsHelper(state, payload);
    case SET_IS_BEING_DRAGGED:
      return isArrowDraggedHelper(state, payload);
    case UPDATE_ARROW:
      return updateArrowHelper(state, payload);
    case SET_RELATIONS_FROM_CURRENT_INTERVAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          relationsFromCurrentInterval: payload,
        },
      };
    case PUSH_FROM_RELATIONS:
      return {
        ...state,
        modal: {
          ...state.modal,
          relationsFromCurrentInterval: Array.from(new Set(state.modal.relationsFromCurrentInterval.concat(payload))),
        },
      };
    case REMOVE_FROM_RELATION:
      return {
        ...state,
        modal: {
          ...state.modal /* @ts-ignore */,
          relationsFromCurrentInterval: state.modal.relationsFromCurrentInterval.filter((x) => x.id !== payload),
        },
      };
    case UPDATE_FROM_RELATION:
      return {
        ...state,
        modal: {
          ...state.modal,
          relationsFromCurrentInterval: state.modal.relationsFromCurrentInterval.map((x) => {
            /* @ts-ignore */
            if (x.id === payload.relationId) return { ...x, ...payload.data };
            return x;
          }),
        },
      };
    case SET_RELATIONS_TO_CURRENT_INTERVAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          relationsToCurrentInterval: payload,
        },
      };
    case PUSH_TO_RELATIONS:
      return {
        ...state,
        modal: {
          ...state.modal,
          relationsToCurrentInterval: Array.from(new Set(state.modal.relationsToCurrentInterval.concat(payload))),
        },
      };
    case REMOVE_TO_RELATION:
      return {
        ...state,
        modal: {
          ...state.modal /* @ts-ignore */,
          relationsToCurrentInterval: state.modal.relationsToCurrentInterval.filter((x) => x.id !== payload),
        },
      };
    case UPDATE_TO_RELATION:
      return {
        ...state,
        modal: {
          ...state.modal,
          relationsToCurrentInterval: state.modal.relationsToCurrentInterval.map((x) => {
            /* @ts-ignore */
            if (x.id === payload.relationId) return { ...x, ...payload.data };
            return x;
          }),
        },
      };
    case SET_AVAILABLE_INTERVALS:
      return {
        ...state,
        modal: {
          ...state.modal,
          availableIntervals: payload,
        },
      };
    case SET_AVAILABLE_SECTIONS:
      return {
        ...state,
        modal: {
          ...state.modal,
          availableSections: payload,
        },
      };
    case HIGHLIGHT_INTERVAL:
      return highlightArrow(state, payload);
    case DROP_HIGHLIGHTED_INTERVAL:
      return dropHighlightedElements(state);
    case SET_ARROW_HASH:
      return {
        ...state,
        diagramIntervalLinks: {
          ...state.diagramIntervalLinks,
          hash: payload,
        },
      };
    case SET_MANUFACTURING_HASH:
      return {
        ...state,
        manufacturingHash: payload,
      };
    case SET_CONSTRUCTING_EXPANDED_BRANCHES:
      return {
        ...state,
        constructingExpandedBranches: payload,
      };
    case SET_MANUFACTURING_EXPANDED_BRANCHES:
      return {
        ...state,
        manufacturingExpandedBranches: payload,
      };
    case "manufacturing/SET_MATERIALS_LOADING":
      return {
        ...state,
        isMaterialsLoading: payload,
      };

    case SET_CRITICAL_DATES:
      return {
        ...state,
        criticalDates: payload,
      };
    default:
      return state;
  }
};
