import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IAxesInList } from "./types";

export interface AxesState {
  isLoading: Record<string, boolean>;
  axes: Record<string, IAxesInList[]>;
  invalidateKey: number;
}

const initialState: AxesState = {
  isLoading: {},
  axes: {},
  invalidateKey: 0,
};

export const axesSlice = createSlice({
  name: "axes",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      const { key, status } = action.payload;
      state.isLoading[key] = status;
    },
    setAxes: (state, action: PayloadAction<{ key: string; axes: IAxesInList[] }>) => {
      const { key, axes } = action.payload;
      state.axes[key] = axes;
    },
    invalidateKey: (state) => {
      state.invalidateKey++;
    },
  },
});

export const axesActions = axesSlice.actions;

export default axesSlice.reducer;
