import React from "react";

import manuallyDraftIcon from "../../../../../../images/sgroup.svg";

import styles from "./EmptyMessage.module.scss";

const EmptyMessage = ({ message }) => (
  <div className={styles.container}>
    <h2 className={styles.message}>{message}</h2>
    <img src={manuallyDraftIcon} alt="Иконка пустого объекта" />
  </div>
);

export default React.memo(EmptyMessage);
