import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";

import {
  isLoadingSelector,
  planFactSectionsSelector,
} from "../../../../redux/modules/_TODO/constructing/finance/planFact/selectors";

import ForbiddenPage from "../../../../app/routes/components/ForbiddenPage/ForbiddenPage";
import PlanFactIndicators from "../PlanFactList/components/PlanFactIndicators/PlanFactIndicators";
import SubSectionRow from "./components/SubSectionRow/SubSectionRow";

import EmptyPlaceholder from "../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBack from "shared/ui/controls/ButtonBack/ButtonBack";

import {
  VIEW_PLAN_FACT_FINANCE,
  VIEW_PLAN_FACT_VOLUME,
} from "../../../../constants/permissions/manufacturingPermissions";
import { ROUTES } from "../../../../constants/routes";

import usePermission from "../../../../hooks/usePermission";

import planFact from "images/icons/navigation/plan-fact.svg";

import styles from "./PlanFactItem.module.scss";

const PlanFactItem: React.FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { sectionId } = useParams<{ sectionId: string }>();

  const isLoading = useSelector(isLoadingSelector);
  const sections = useSelector(planFactSectionsSelector);

  const currentSection = useMemo(() => sections?.find((section) => section.id === +sectionId), [sections, sectionId]);

  const budgets = useMemo(
    () => ({
      plan: currentSection?.plan ?? "",
      fact: currentSection?.fact ?? "",
      budget: currentSection?.budget ?? "",
    }),
    [currentSection]
  );

  const viewVolumePermission = usePermission(VIEW_PLAN_FACT_VOLUME);
  const viewFinancePermission = usePermission(VIEW_PLAN_FACT_FINANCE);

  if (!viewFinancePermission && viewVolumePermission) {
    return <Redirect to={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_VOLUME} />;
  } else if (!viewFinancePermission) {
    return <ForbiddenPage />;
  }

  if (isLoading)
    return (
      <>
        {children}
        <Spinner />
      </>
    );

  return (
    <div>
      {children}
      <div className={styles.container}>
        <PlanFactIndicators planFactBudgets={budgets} />
        <div className={styles.subHeader}>
          <div className={styles.halfBlock}>
            <ButtonBack onClick={() => history.goBack()} />
            <span className={styles.title}>{currentSection?.name}</span>
          </div>
          <div className={styles.halfBlock}>
            <div className={styles.countSection}>Всего записей: {currentSection?.subsections?.length}</div>
          </div>
        </div>
        {currentSection?.subsections?.length ? (
          currentSection?.subsections?.map((subsection) => (
            <SubSectionRow key={subsection?.id} subsection={subsection} />
          ))
        ) : (
          <EmptyPlaceholder img={planFact} />
        )}
      </div>
    </div>
  );
};

export default React.memo(PlanFactItem);
