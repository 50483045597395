import { message } from "antd";
import moment from "moment";
import React, { useMemo } from "react";
import { Field, Form } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";

import InputSelect from "_LEGACY/UI/__trash/InputSelect";
import Select from "components/UI/atoms/Select";
import TasksPersonsSelect from "components/pages/Tasks/components/TaskPersonsSelect/TasksPersonsSelect";

import { FILE_UPLOAD_VARIANT, FileUpload, IUseFileUpload } from "entities/FileUpload";
import Calendar from "shared/ui/inputs/Calendar/Calendar";
import InputBase from "shared/ui/inputs/InputBase";

import { IdNameLabelType } from "types/IdNameLabelType";
import { IIdAndName } from "types/idAndName";
import { Checkpoint, CheckpointEditValues } from "widgets/AddCheckpoint/types";

import { required } from "utils/formHelpers/validations";

import styles from "./CheckpointForm.module.scss";

export interface CheckpointFormProps extends Pick<IUseFileUpload, "onDeleteFile" | "onUploadFiles" | "fileStorageId"> {
  checkpoint?: Checkpoint;
  defaultObjectId: string | undefined;
  object: IIdAndName | undefined;
  section: IIdAndName | undefined;
  onSumbmit: (values: CheckpointEditValues) => Promise<void>;
  objects: IIdAndName[];
  sections: IIdAndName[];
  /** Список пользователей-исполнителей и контролёров */
  users: IIdAndName[];
  selectObjectById: (id: string | number) => void;
  selectObjectByName: (name: string) => void;
  selectSectionById: (id: string | number) => void;
  formRef: React.RefObject<HTMLFormElement>;
}

export const CheckpointForm: React.FC<CheckpointFormProps> = ({
  checkpoint,
  onSumbmit,
  defaultObjectId,
  object,
  objects,
  section,
  sections,
  users,
  selectObjectById,
  selectObjectByName,
  selectSectionById,
  onDeleteFile,
  onUploadFiles,
  fileStorageId,
  formRef,
}) => {
  const executorsInitial = useMemo(
    () => (checkpoint?.executors_data || []).map((e) => e.executor.id),
    [checkpoint?.executors_data]
  );
  const viewersInitial = useMemo(
    () => (checkpoint?.viewers_data || []).map((e) => e.viewer.id),
    [checkpoint?.viewers_data]
  );

  return (
    <Form
      onSubmit={onSumbmit}
      initialValues={
        checkpoint
          ? {
              name: checkpoint?.name,
              description: checkpoint?.description,
              check_point_date: checkpoint?.check_point_date,
              building: checkpoint?.building,
              section: checkpoint?.section,
              executors: executorsInitial,
              viewers: viewersInitial,
              files: checkpoint?.files || [],
            }
          : undefined
      }
      render={({ form, handleSubmit }) => (
        <form
          className={styles.form}
          ref={formRef}
          onReset={() => form.restart()}
          onSubmit={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (form.getState().valid) {
              (handleSubmit() || Promise.resolve()).then(() => {
                form.restart();
              });
            } else {
              message.warn("Заполните обязательные поля формы");
            }
          }}
        >
          <Field
            name={"name"}
            validate={required()}
            render={({ input, meta }) => (
              <div className={styles.outView}>
                <label className={styles.label}>*Название</label>
                <InputBase {...input} placeholder={"Название контрольной точки"} />
                {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
              </div>
            )}
          />
          <Field
            name={"description"}
            validate={required()}
            render={({ input, meta }) => (
              <div className={styles.outView}>
                <label className={styles.label}>*Описание</label>
                <TextareaAutosize {...input} placeholder={"Описание контрольной точки"} minRows={3} />
                {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
              </div>
            )}
          />
          <Field
            name={"check_point_date"}
            validate={required()}
            render={({ input, meta }) => (
              <div className={styles.outView}>
                <label className={styles.label}>*Контрольная дата</label>
                <Calendar
                  value={input.value || meta.initial}
                  setValue={(v) => input.onChange(moment(v).format("YYYY-MM-DD"))}
                  classNameSelect={""}
                  classNameOptions={styles.calendarOptions}
                  label={input.label}
                  placeholder={moment(checkpoint?.check_point_date).format("DD/MM/YY")}
                  format={"DD/MM/YY"}
                />
                {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
              </div>
            )}
          />
          {!defaultObjectId?.length ? (
            <Field
              name={"building"}
              validate={required()}
              render={({ input, meta }) => (
                <div className={styles.outView}>
                  <InputSelect
                    className={styles.fieldInput}
                    optionsBlockClassName={styles.options}
                    name={"building"}
                    label={"*Проект"}
                    placeholder={"Введите или укажите наименование проекта"}
                    meta={meta}
                    defaultValue={object}
                    selectedAction={undefined}
                    input={{
                      ...input,
                      onChange: (value: string) => {
                        input.onChange(value);
                        selectObjectByName(value);
                      },
                      value: object?.id,
                    }}
                    onClick={selectObjectById}
                    options={objects}
                  />
                </div>
              )}
            />
          ) : null}
          <Field
            name={"section"}
            value={section?.id}
            validate={required()}
            render={({ input, meta }) => (
              <div className={styles.outView}>
                <Select
                  className={styles.fieldInput}
                  label={"*Раздел"}
                  placeholder={"Укажите наименование раздела"}
                  meta={meta}
                  value={input.value}
                  input={{
                    ...input,
                    onChange: (value: number) => {
                      input.onChange(value);
                      selectSectionById(value);
                    },
                    value: section?.id || "",
                  }}
                  options={sections}
                />
              </div>
            )}
          />
          <Field
            name={"executors"}
            validate={required()}
            multiple
            defaultValue={executorsInitial}
            render={({ input, meta }) => (
              <TasksPersonsSelect
                name={"executors"}
                input={input}
                options={users as IdNameLabelType[]}
                label={"*Исполнители"}
                placeholder={"Введите или укажите ФИО исполнителя"}
                meta={meta}
                value={input.value}
              />
            )}
          />
          <Field
            name={"viewers"}
            validate={required()}
            multiple
            defaultValue={viewersInitial}
            render={({ input, meta }) => (
              <TasksPersonsSelect
                name={"viewers"}
                input={input}
                options={users as IdNameLabelType[]}
                label={"*Контролеры"}
                placeholder={"Введите или укажите ФИО контролера"}
                meta={meta}
                value={input.value}
              />
            )}
          />
          <FileUpload
            variant={checkpoint ? FILE_UPLOAD_VARIANT.EDIT : FILE_UPLOAD_VARIANT.NEW}
            //@ts-ignore
            files={checkpoint?.files || []}
            onDeleteFile={onDeleteFile}
            onUploadFiles={onUploadFiles}
            fileStorageId={fileStorageId}
          />
        </form>
      )}
    />
  );
};
