import cn from "classnames";
import React from "react";

import { IKs3InList } from "redux/modules/common/building/documents/ksList/types";

import styles from "./KsStatus.module.scss";

enum statusTexts {
  "to_pay" = "К оплате",
  "paid" = "Оплачено", //Оплачено
  "todo" = "К сдаче",
}

interface IProps {
  status: IKs3InList["status"];
}

const KsStatus: React.FC<IProps> = ({ status }) => {
  return (
    <div className={cn(styles.container, styles[status])}>
      <div className={styles.marker} />
      {statusTexts[status]}
    </div>
  );
};

export default React.memo(KsStatus);
