import { isEqual } from "lodash";
import moment from "moment";

import { IBuildingApprovalSetItem, IBuildingApprovalsSets } from "../types";
import { IApprovalsEmployeesSetItem, IBuilding } from "types/interfaces/Building";
import { IEmployee } from "types/personsTypes";

interface IBaseProps {
  localForm: Record<string, string>;
  dirty: boolean;
}

interface IExtendedProps extends IBaseProps {
  object: IBuilding;
  employees: IEmployee[];
  responsible_employee: IEmployee;
  pto_employee: IEmployee;
  payment_decision_employee: IEmployee;
  timesheetResponsibleEmployee: IEmployee;
  stockResponsibleEmployee: IEmployee;
  estimateResponsible: IEmployee;
  stockData: { responsible: IEmployee };
  approvals: IBuildingApprovalsSets;
  dateStart: string;
  dateEnd: string;
}

export const dirtySubmitBtnSwitcher = ({
  localForm,
  dirty,
  object,
  employees,
  responsible_employee,
  pto_employee,
  payment_decision_employee,
  timesheetResponsibleEmployee,
  stockResponsibleEmployee,
  stockData,
  approvals,
  dateEnd,
  dateStart,
  estimateResponsible,
}: IExtendedProps): boolean => {
  if (dirty) return true;
  if (+localForm.entity !== +object?.entity?.id || localForm.name !== object?.name) return true;
  if (moment(dateStart).format() !== moment(object?.construction_date_start).format()) return true;
  if (moment(dateEnd).format() !== moment(object?.construction_date_end).format()) return true;

  if (responsible_employee?.id !== object?.responsible_employee?.id) return true;
  if (pto_employee?.id !== object?.pto_employee?.id) return true;
  if (payment_decision_employee?.id !== object?.payment_decision_employee?.id) return true;
  if (timesheetResponsibleEmployee?.id !== object?.timesheet_responsible?.id) return true;
  if (stockResponsibleEmployee?.id !== stockData?.responsible?.id) return true;
  if (estimateResponsible?.id !== object?.responsible_estimate?.id) return true;

  const localEmployeesIds = employees?.map((el) => el.id).sort();
  const objectEmployees = object?.employees?.map((el) => el.id).sort();

  if (!isEqual(localEmployeesIds, objectEmployees)) return true;

  const mapIdApprovals = (el: IApprovalsEmployeesSetItem | IBuildingApprovalSetItem) => ({
    id: el.id,
    position: el.position,
  });

  const mapUserApprovals = (el: IApprovalsEmployeesSetItem | IBuildingApprovalSetItem) => ({
    id: el.user,
    position: el.position,
  });

  if (!approvals || !object) return false;

  const sortById = (a: { id?: number }, b: { id?: number }) => a.id! - b.id!;

  const localApprovals = {
    orderApprovalEmployees: [...approvals.orderApprovalEmployees?.map(mapIdApprovals)]?.sort(sortById),
    paymentApprovalEmployees: [...approvals.paymentApprovalEmployees?.map(mapIdApprovals)]?.sort(sortById),
    requisitionApprovalEmployees: [...approvals.requisitionApprovalEmployees?.map(mapIdApprovals)]?.sort(sortById),
    timesheetApprovalEmployees: [...approvals.timesheetApprovalEmployees?.map(mapIdApprovals)]?.sort(sortById),
    ticketApprovalEmployees: [...approvals.ticketApprovalEmployees?.map(mapUserApprovals)]?.sort(sortById),
    planWorkConfirmEmployees: [...approvals.planWorkConfirmEmployees?.map(mapUserApprovals)]?.sort(sortById),
    planSectionConfirmEmployees: [...approvals.planSectionConfirmEmployees?.map(mapUserApprovals)]?.sort(sortById),
    sharingfactchainapprovalemployees_set: [
      ...(approvals?.sharingfactchainapprovalemployees_set?.map(mapUserApprovals) ?? []),
    ]?.sort(sortById),
    shared_remark_responsible_employees: [
      ...(approvals?.shared_remark_responsible_employees?.map(mapUserApprovals) ?? []),
    ]?.sort(sortById),
    allow_shift: approvals?.allow_shift,
    new_close_dates: approvals?.new_close_dates,
  };

  const objectApprovals = {
    orderApprovalEmployees: [...object.orderchainapprovalemployees_set?.map(mapUserApprovals)]?.sort(sortById),
    paymentApprovalEmployees: [...object.paymentchainapprovalemployees_set?.map(mapUserApprovals)]?.sort(sortById),
    requisitionApprovalEmployees: [...object.requisitionchainapprovalemployees_set?.map(mapUserApprovals)]?.sort(
      sortById
    ),
    timesheetApprovalEmployees: [...object.timesheetchainapprovalemployees_set?.map(mapUserApprovals)]?.sort(sortById),
    ticketApprovalEmployees: [...object.ticketchainapprovalemployees_set?.map(mapUserApprovals)]?.sort(sortById),
    planWorkConfirmEmployees: [...object.planworkchainapprovalemployees_set?.map(mapUserApprovals)]?.sort(sortById),
    planSectionConfirmEmployees: [...object.plansectionchainapprovalemployees_set?.map(mapUserApprovals)]?.sort(
      sortById
    ),
    sharingfactchainapprovalemployees_set: [
      ...(object.sharingfactchainapprovalemployees_set?.map(mapUserApprovals) ?? []),
    ]?.sort(sortById),
    shared_remark_responsible_employees: [...(object.shared_remark_responsible_employees?.map(mapUserApprovals) ?? [])]?.sort(
      sortById
    ),
    allow_shift: object?.allow_shift,
    new_close_dates: object?.new_close_dates,
  };
  return !isEqual(localApprovals, objectApprovals);
};

export const dirtySubmitBtnSwitcherForNewProject = ({ localForm, dirty }: IBaseProps): boolean => {
  if (dirty) return true;

  if (localForm.name !== "") return true;
  if (+localForm.entity) return true;

  return false;
};
