export const planFactUtils = {
  getRoute: (locationArray: string[]) => {
    const planFactIndex = locationArray.indexOf("plan-fact");
    const sectionIndex = planFactIndex + 2;
    const subSectionIndex = planFactIndex + 3;

    const tab = locationArray[planFactIndex + 1];

    const sectionId = !isNaN(+locationArray[sectionIndex]!) ? locationArray[sectionIndex] : undefined;
    const subSectionId = !isNaN(+locationArray[subSectionIndex]!) ? locationArray[subSectionIndex] : undefined;

    return [sectionId, subSectionId].filter(Boolean).join("/");
  },
  getTab: (locationArray: string[]) => {
    const planFactIndex = locationArray.indexOf("plan-fact");

    return locationArray[planFactIndex + 1];
  },
  getRouteIds: (locationArray: string[]) => {
    const planFactIndex = locationArray.indexOf("plan-fact");
    const sectionIndex = planFactIndex + 2;
    const subSectionIndex = planFactIndex + 3;

    const sectionId = !isNaN(+locationArray[sectionIndex]!) ? locationArray[sectionIndex] : undefined;
    const subSectionId = !isNaN(+locationArray[subSectionIndex]!) ? locationArray[subSectionIndex] : undefined;

    return { sectionId, subSectionId };
  },
};
