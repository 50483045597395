import axios from "axios";

import { IPlanFactResponse } from "./types";

export const planFactApi = {
  getTree: (objectId: number, startDate?: string, endDate?: string) => {
    return axios.get<IPlanFactResponse>(`/tree/buildings/${objectId}/planfact/`, {
      params: {
        start_at: startDate,
        end_at: endDate,
      },
    });
  },
};
