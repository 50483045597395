import React from "react";

export default function CrossBoldRoundedIcon({ width='17', color = '#C4C4C4' }) {
  return (
    <svg 
      width={'' + width}
      height="16" 
      viewBox="0 0 17 16" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M12.0292 14.6846L8.61524 11.4716L5.20224 14.6846C4.79303 15.0603 4.25774 15.2687 3.70223 15.2687C3.14673 15.2687 2.61144 15.0603 2.20223 14.6846L1.51924 14.0416C1.32329 13.8617 1.16684 13.6431 1.05982 13.3996C0.9528 13.1562 0.897538 12.8931 0.897538 12.6271C0.897538 12.3611 0.9528 12.098 1.05982 11.8546C1.16684 11.6111 1.32329 11.3925 1.51924 11.2126L4.92824 7.9996L1.51524 4.7856C1.31901 4.60591 1.16233 4.38736 1.05513 4.14384C0.947935 3.90032 0.892578 3.63717 0.892578 3.3711C0.892578 3.10503 0.947935 2.84188 1.05513 2.59836C1.16233 2.35484 1.31901 2.13629 1.51524 1.9566L2.19823 1.3146C2.60744 0.93892 3.14273 0.730469 3.69824 0.730469C4.25374 0.730469 4.78903 0.93892 5.19824 1.3146L8.61524 4.5286L12.0292 1.3146C12.4384 0.93892 12.9737 0.730469 13.5292 0.730469C14.0847 0.730469 14.62 0.93892 15.0292 1.3146L15.7122 1.9576C15.9082 2.13745 16.0646 2.35606 16.1716 2.59955C16.2787 2.84305 16.3339 3.10612 16.3339 3.3721C16.3339 3.63808 16.2787 3.90115 16.1716 4.14465C16.0646 4.38814 15.9082 4.60675 15.7122 4.7866L12.3032 7.9996L15.7162 11.2136C15.9122 11.3935 16.0686 11.6121 16.1756 11.8556C16.2827 12.099 16.3379 12.3621 16.3379 12.6281C16.3379 12.8941 16.2827 13.1572 16.1756 13.4006C16.0686 13.6441 15.9122 13.8627 15.7162 14.0426L15.0332 14.6856C14.624 15.0613 14.0887 15.2697 13.5332 15.2697C12.9777 15.2697 12.4424 15.0613 12.0332 14.6856L12.0292 14.6846Z" 
        fill={color}
      />
    </svg>
  )
}