import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSpecificationsExpenditures,
  specificationExpendituresSelector,
} from "redux/modules/common/building/materials/specification";

import { MODAL_TABS_TITLES, PRODUCT_TYPES } from "_LEGACY/UI/_LEGACY_Purchases_components/AddProductsModal/constants";

import ProductTable from "../ProductTable/ProductTable";

import styles from "./SectionChild.module.scss";

interface IProps {
  data: any;
  objectId: string;
  type: string;
  products: any;
  setProducts: any;
  search: string;
  isDefaultOpen: boolean;
}

const SectionChild: React.FC<IProps> = ({ data, objectId, type, products, setProducts, search, isDefaultOpen }) => {
  const dispatch = useDispatch();

  const specificationSubExpenditures = useSelector(specificationExpendituresSelector);

  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  useEffect(() => {
    dispatch(getSpecificationsExpenditures(objectId, type, data?.id, true));
  }, [data, objectId, type]);

  const filteredSpecificationSubExpendituresArray = useMemo(
    () =>
      specificationSubExpenditures?.length > 0
        ? specificationSubExpenditures?.filter((item: any) => item.id === data?.id)?.at(-1)
        : [],
    [specificationSubExpenditures, data, type]
  );

  const searchedExpenditures = useMemo(() => {
    if (!filteredSpecificationSubExpendituresArray) return null;
    if (!search) return filteredSpecificationSubExpendituresArray;
    return {
      ...filteredSpecificationSubExpendituresArray,
      products: filteredSpecificationSubExpendituresArray.products.filter((el: any) =>
        el.name.toLowerCase().includes(search.toLowerCase())
      ),
    };
  }, [filteredSpecificationSubExpendituresArray, search]);

  const handleOpenProductTable = useCallback(() => {
    if (data?.products_count <= 0) return;

    setIsOpen((prev) => !prev);
  }, [data]);

  if (!data) return null;

  if (!!searchedExpenditures && !searchedExpenditures.products?.length) return null;

  return (
    <div className={styles.container}>
      <div className={cn(styles.header, { [styles.active]: isOpen })} onClick={handleOpenProductTable}>
        <span className={styles.title}>{data.name}</span>
        <span className={styles.materialTitle}>
          {type !== PRODUCT_TYPES.SERVICE ? `${MODAL_TABS_TITLES.MATERIALS}:` : `${MODAL_TABS_TITLES.SERVICE}:`}
        </span>
        <span className={styles.count}>{data.products_count}</span>
      </div>
      {isOpen && (
        <ProductTable section={searchedExpenditures} type={type} products={products} setProducts={setProducts} />
      )}
    </div>
  );
};

export default React.memo(SectionChild);
