export const numberSortFunction = (a: string, b: string): number => {
  if (!a || !b) return 0;
  const aParts = a.split(".");
  const bParts = b.split(".");
  const biggestLength = Math.max(aParts.length, bParts.length);

  for (let i = 0; i < biggestLength; i++) {
    if (typeof aParts?.[i] === "string" && typeof bParts?.[i] === "undefined") {
      return 1;
    }

    if (typeof aParts?.[i] === "undefined" && typeof bParts?.[i] === "string") {
      return -1;
    }

    if (typeof aParts?.[i] === "undefined" && typeof bParts?.[i] === "undefined") {
      return 0;
    }

    const diff = Number(aParts[i]) - Number(bParts[i]);

    if (diff === 0) {
      if (i + 1 === biggestLength) {
        return 0;
      }
    } else {
      return diff > 0 ? 1 : -1;
    }
  }

  return 0;
};
