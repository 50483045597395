import cn from "classnames";
import React, { FC, useCallback, useMemo } from "react";

import { ISectionPlan } from "redux/modules/common/building/processTypes";

import { ESTIMATE_SECTION_VARIANTS } from "../../../../UI/_TODO/EstimateSection/EstimateSection";
import { AmountsType } from "../../../../UI/_TODO/EstimateSection/components/Amounts/Amounts";
import EstimateStateMark from "../../../../UI/_TODO/EstimateSection/components/EstimateStateMark/EstimateStateMark";
import PlanSectionMark from "../../../../UI/_TODO/EstimateSection/components/PlanSectionMark/PlanSectionMark";
import Checkbox from "_LEGACY/UI/__TODO/Checkbox/Checkbox";
import { TabsEnum } from "components/pages/Building/enums";

import Amount from "../../../../../shared/ui/dataDisplay/Amount/Amount";
import Amounts from "./Amounts/Amounts";
import AddButton from "shared/ui/controls/AddButton/AddButton";

import ISection from "types/interfaces/Section";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import CheckDouble from "images/icons/CheckDouble";

import styles from "./BuildingSection.module.scss";

interface IProps {
  isChecked?: boolean;
  onCheck: (checked: boolean) => void;
  canCheck?: boolean;
  section: ISection;
  amounts: AmountsType;
  isDisplayAmounts?: boolean;
  isDisplayEstimateStateMark?: boolean;
  variant?: ESTIMATE_SECTION_VARIANTS;
  isDisplayAddButton?: boolean;
  isShownPlanData?: boolean;
  onAddButtonClick?: () => void;
  sectionPlanData?: ISectionPlan | null;
  isCheckboxPlaceholder?: boolean;
  onRefetchSectionPlan?: (parentId: number) => void;
  dataTestId?: string | number;
  isNeedOpenSectionPlan?: boolean;
  activeTab?: keyof typeof TabsEnum;
  isWithIndicator?: boolean;
  isWithIndexes?: boolean;
  isProduction?: boolean;
  visibilityChecked: (id: number) => boolean;
  handleVisibilityCheck: (item: any) => void;
  canAddExpenditure?: boolean;
}

const BuildingSection: FC<IProps> = ({
  activeTab,
  isChecked,
  onCheck,
  canCheck,
  section,
  amounts,
  isDisplayAmounts,
  isDisplayEstimateStateMark,
  variant = ESTIMATE_SECTION_VARIANTS.primary,
  isDisplayAddButton,
  onAddButtonClick,
  sectionPlanData,
  isShownPlanData,
  isCheckboxPlaceholder,
  onRefetchSectionPlan,
  dataTestId,
  isNeedOpenSectionPlan,
  isWithIndicator,
  isWithIndexes,
  isProduction = false,
  visibilityChecked,
  handleVisibilityCheck,
  canAddExpenditure,
}) => {
  const isShowCheckbox = useMemo(() => !activeTab || activeTab === TabsEnum.progress, [activeTab]);

  const handleOnCheck = useCallback(() => onCheck(!isChecked), [onCheck, isChecked]);

  return (
    <div
      className={cn(styles.sectionRow, styles[variant], {
        [styles.withIndicator]: isWithIndicator,
      })}
      data-testid={dataTestId}
    >
      <div className={styles.titleContainer}>
        {!canCheck && section.shared_count > 0 && (
          <CheckDouble
            className={styles.checkDouble}
            variant={section.shared_status === "accepted" ? "primary" : "default"}
          />
        )}
        {isProduction && (
          <div onClick={stopEventPropagation}>
            <Checkbox
              checked={visibilityChecked(section.id)}
              onChange={(e) => {
                e.stopPropagation();
                handleVisibilityCheck(section);
              }}
              checkboxClassName={styles.checkbox}
            />
          </div>
        )}
        {canCheck && !isCheckboxPlaceholder && (
          <div className={styles.checkboxContainer} onClick={stopEventPropagation}>
            {isShowCheckbox && (
              <Checkbox checked={!!isChecked} onChange={handleOnCheck} checkboxClassName={styles.checkbox} />
            )}
          </div>
        )}
        {canCheck && isCheckboxPlaceholder && (
          <div className={cn(styles.checkboxContainer, styles.checkboxPlaceholder)}>
            {isShowCheckbox && (
              <Checkbox checked={!!isChecked} onChange={handleOnCheck} checkboxClassName={styles.checkbox} />
            )}
          </div>
        )}
        <span className={styles.title} title={section.name}>
          {section.name}
        </span>
      </div>
      <div className={styles.leftSide} onClick={stopEventPropagation}>
        {sectionPlanData && isShownPlanData && (
          <PlanSectionMark
            {...sectionPlanData}
            onRefetchSectionPlan={onRefetchSectionPlan}
            isNeedToOpen={isNeedOpenSectionPlan}
            className={styles.sectionPlanButton}
          />
        )}
        {!sectionPlanData && isDisplayAddButton && (
          <AddButton className={styles.addBtn} onClick={onAddButtonClick} text="" />
        )}
        {isDisplayEstimateStateMark && section.confirmed_state && <EstimateStateMark state={section.confirmed_state} />}
        {isDisplayAmounts && <Amounts amounts={amounts} section={section} isWithIndexes={isWithIndexes} />}
        {(isDisplayAmounts || isDisplayEstimateStateMark) && <div className={styles.divider} />}
        <div className={styles.budgetCol}>
          <Amount
            className={styles.budgetTitle}
            title="Бюджет"
            value={amounts.all}
            measure={amounts.measure}
            isBold
            isLeft
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(BuildingSection);
