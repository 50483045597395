import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  actingEstimatesSelector,
  actingInvalidateKeySelector,
} from "redux/modules/common/building/journal/acting/selectors";
import { getActingEstimates } from "redux/modules/common/building/journal/acting/thunks";

import JournalActingLsrRow from "./JournalActingLsrRow";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./JournalActingLsrLevel.module.scss";

interface IProps {
  onOpenLsr: (lsrId: number) => void;
}

const JournalActingLsrLevel: React.FC<IProps> = ({ onOpenLsr }) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const dispatch = useDispatch();
  const estimates = useSelector(actingEstimatesSelector)[objectId];
  const cacheKey = useSelector(actingInvalidateKeySelector);

  React.useEffect(() => {
    dispatch(getActingEstimates(objectId));
  }, [objectId, cacheKey]);

  return (
    <div className={styles.container}>
      {estimates?.isLoading && <Spinner />}
      {!estimates?.isLoading && !estimates?.results?.length && <EmptyPlaceholder img={jurnalIcon} />}
      {estimates?.results?.map((el) => (
        <JournalActingLsrRow onClick={onOpenLsr} key={el.id} estimate={el} />
      ))}
    </div>
  );
};

export default React.memo(JournalActingLsrLevel);
