import moment from "moment";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";

import LabeledSwitch from "components/UI/atoms/_TODO/LabeledSwitch";

import LabeledSwitchingItem from "../LabeledSwitchingItem/LabeledSwitchingItem";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import Calendar from "shared/ui/inputs/Calendar/Calendar";
import InputBase, { VALUE_TYPES } from "shared/ui/inputs/InputBase";
import Modal from "shared/ui/modal/Modal";

import { IAddExtraExpenseForm } from "../../model/types";
import { LOCALIZATION_CONFIG } from "constants/localization";
import { IIdAndName } from "types/idAndName";

import { composeFieldValidators, mustBeNumber, required } from "utils/formHelpers/validations";

import styles from "./ExtraExpenseModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (vals: IAddExtraExpenseForm) => void;
  title?: string;
  sections: IIdAndName[];
  subSections: IIdAndName[];
  isLoading?: boolean;
  initialData?: IAddExtraExpenseForm;
  isPending?: boolean;
}

const ExtraExpenseModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title = "Добавление статьи расхода",
  sections,
  subSections,
  isLoading,
  initialData,
  isPending,
}) => {
  const [isEndDate, setIsEndDate] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const submit = (vals: IAddExtraExpenseForm) => {
    if (!isEndDate) {
      delete vals["end_date"];
    } else {
      vals["end_date"] = moment(vals["end_date"]).format("YYYY-MM-DD");
    }
    vals["date"] = moment(vals["date"]).format("YYYY-MM-DD");
    onSubmit(vals);
  };

  useEffect(() => {
    if (!initialData) return;
    if (initialData?.end_date) {
      setIsEndDate(true);
    } else {
      setIsEndDate(false);
    }
  }, [initialData]);

  return (
    <Modal title={title} isOpen={isOpen} onClose={onClose}>
      {isLoading && (
        <div className={styles.spinner}>
          <Spinner isStatic />
        </div>
      )}
      {!isLoading && (
        <Form
          initialValues={initialData}
          onSubmit={submit}
          render={({ handleSubmit, hasValidationErrors, dirty }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className={styles.content}>
                <LabeledSwitchingItem fieldName="category" options={sections} label="Категория" />
                <LabeledSwitchingItem fieldName="chapter" options={subSections} label="Раздел" />
                <Field
                  name="title"
                  validate={composeFieldValidators(required())}
                  render={({ input, meta }) => (
                    <LabeledItem label="Наименование">
                      <InputBase className={styles.input} input={input} meta={meta} />
                    </LabeledItem>
                  )}
                />
                <div className={styles.row}>
                  <Field
                    name="amount"
                    validate={composeFieldValidators(required(), mustBeNumber)}
                    render={({ input, meta }) => (
                      <LabeledItem label="Стоимость">
                        <InputBase
                          className={styles.input}
                          input={input}
                          meta={meta}
                          icon={<span className={styles.currency}>{LOCALIZATION_CONFIG.currency}</span>}
                        />
                      </LabeledItem>
                    )}
                  />
                  <Field
                    name="date"
                    validate={composeFieldValidators(required())}
                    render={({ input, meta }) => (
                      <LabeledItem label="Дата платежа">
                        <Calendar setValue={input.onChange} {...meta} {...input} containerClassName={styles.input} />
                      </LabeledItem>
                    )}
                  />
                </div>
                <div className={styles.row}>
                  <LabeledSwitch
                    value={isEndDate}
                    onChange={(v: any) => {
                      setIsEndDate(v);
                      setIsDirty(true);
                    }}
                    labelRight="Ежемесячный расход"
                    label=""
                  />
                  {isEndDate ? (
                    <Field
                      name="end_date"
                      validate={composeFieldValidators(required())}
                      render={({ input, meta }) => (
                        <LabeledItem>
                          <Calendar
                            placeholder="Укажите период"
                            setValue={input.onChange}
                            {...meta}
                            {...input}
                            containerClassName={styles.input}
                          />
                        </LabeledItem>
                      )}
                    />
                  ) : (
                    <LabeledItem>&nbsp;</LabeledItem>
                  )}
                </div>
              </div>
              <div className={styles.bottom}>
                <ButtonBase secondary onClick={onClose} type="reset">
                  Отменить
                </ButtonBase>
                <ButtonBase
                  primary
                  type="submit"
                  disabled={hasValidationErrors || (!dirty && !isDirty)}
                  isLoading={isPending}
                >
                  Сохранить
                </ButtonBase>
              </div>
            </form>
          )}
        />
      )}
    </Modal>
  );
};

export default ExtraExpenseModal;
