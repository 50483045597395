export const confirmedIcon = (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill="#00DA8B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5854 15.5891C9.19472 15.9777 8.563 15.9771 8.17311 15.5872L5.29289 12.707C4.90237 12.3164 4.90237 11.6833 5.29289 11.2927C5.68342 10.9022 6.31658 10.9022 6.70711 11.2927L8.88916 13.4748L16.0711 6.29289C16.4616 5.90237 17.0948 5.90237 17.4853 6.29289C17.8758 6.68342 17.8758 7.31658 17.4853 7.70711L9.70711 15.4853C9.66873 15.5237 9.628 15.5583 9.5854 15.5891Z"
      fill="white"
    />
  </svg>
);
