import React, { FC, PropsWithChildren } from "react";

import styles from "./Paper.module.scss";

const Paper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.paper}>
      {children}
    </div>
  );
};

export default React.memo(Paper);