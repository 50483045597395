import { numberSortFunction } from "../../../../../../../../shared/lib/numberSortFunction/numberSortFunction";

import { IExpenditureInHandlerProduction } from "./types";

export const groupHandlerExpendituresForEdit = (
  expenditures: IExpenditureInHandlerProduction[],
  editingWorksIds: number[]
) => {
  const worksByMaterials: { [materialId: string]: number } = {};
  const materialsByWorks: { [workId: string]: number[] } = {};
  const expendituresByOwnId: Record<string, IExpenditureInHandlerProduction> = {};

  expenditures.forEach((el) => {
    expendituresByOwnId[el.id] = el;

    if (editingWorksIds.includes(el.related_work?.id)) {
      worksByMaterials[el.id] = el.related_work.id;
      if (!!materialsByWorks[el.related_work.id]) {
        materialsByWorks[el.related_work.id].push(el.id);
      } else {
        materialsByWorks[el.related_work.id] = [el.id];
      }
    }
  });

  const res = expenditures
    .sort((a, b) => numberSortFunction(a.number, b.number))
    .map((el) => {
      if ((materialsByWorks[el.id] || !materialsByWorks?.length) && el.expenditure_type === "work") {
        const res = {
          ...el,
          editingForm: {
            ...el,
          },
        };

        materialsByWorks[el.id]?.forEach((materialId) => {
          (res.editingForm as any)[materialId] = expendituresByOwnId[materialId];
        });

        return res;
      }

      if (worksByMaterials[+el.id]) {
        return null;
      }
      return el;
    })
    .filter((el) => !!el) as IExpenditureInHandlerProduction[];
  return res;
};

export const prepareExpenditureForEditInHandler = (expenditure?: IExpenditureInHandlerProduction["editingForm"]) => {
  if (!expenditure) return undefined;
  const entries = Object.entries(expenditure).map(([key, value]) => {
    if (!!(value as any)?.expenditure_type && (value as any)?.expenditure_type !== "work") {
      return [`id:${key}`, value];
    }
    return [key, value];
  });

  return Object.fromEntries(entries);
};

export const prepareWorkAndRelatedResourcesForDeleting = (
  expId: number,
  expenditures: IExpenditureInHandlerProduction[]
) => {
  return expenditures
    .filter((el) => el.related_work?.id === expId && el.expenditure_type !== "work")
    .map((el) => el.id);
};
