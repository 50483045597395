import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";

import { Spinner } from "../../../../../../../shared/ui/atoms/Spinner/Spinner";
import TableReusableHead, {
  TableReusableHeaderCell,
} from "../../../../../../../shared/ui/dataDisplay/TableReusable/TableReusableHead";
import EmptyPlaceholder from "../../../../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import BannerWithIcon from "../BannerWithIcon/BannerWithIcon";
import TableFilterTextInput from "shared/ui/inputs/TableFilterTextInput/TableFilterTextInput";

import { IIdAndName } from "../../../../../../../types/idAndName";

import { ITaskObject, useTaskObjectsSectionsUsers } from "../../../../hooks/useTaskObjectsSectionsUsers";

import estimateIcon from "../../../../../../../images/icons/navigation/estimateIcon.svg";
import newTaskCheck from "../../../../../../../images/icons/newTaskCheck.svg";

import styles from "./AddTaskByBuilding.module.scss";

export interface IAddTaskByBuilding {
  setBuilding: (newBuilding: IIdAndName) => void;
  isHidden?: boolean;
  isOpen?: boolean;
}

const AddTaskByBuilding: React.FC<IAddTaskByBuilding> = ({ setBuilding, isHidden, isOpen = true }) => {
  const [buildingNameFilter, setBuildingNameFilter] = useState<string>("");

  const filters = useMemo(() => ({ name: buildingNameFilter }), [buildingNameFilter]);

  const handleBuildingNameFilter = useCallback((newFilter: string) => setBuildingNameFilter(newFilter), []);

  const { objects, isLoadingObjects } = useTaskObjectsSectionsUsers({ buildingFilterParams: filters });

  return (
    <div className={cn(styles.container, { [styles.isHidden]: isHidden })}>
      <BannerWithIcon url={newTaskCheck} title={"Выберите объект чтобы продолжить создание задачи"} />
      <TableReusableHead className={styles.objectsHead}>
        <TableReusableHeaderCell>
          Наименование объекта
          <TableFilterTextInput title="Наименование объекта" setFilter={handleBuildingNameFilter} />
        </TableReusableHeaderCell>
      </TableReusableHead>
      {isLoadingObjects && !objects.length ? (
        <Spinner />
      ) : objects.length > 0 ? (
        objects?.map((object: ITaskObject) => (
          <div key={object.id} className={styles.row} onClick={() => setBuilding({ id: object.id, name: object.name })}>
            <span className={styles.objectName}>{object.name}</span>
            {object.entity_name && (
              <>
                <span className={styles.entity}>Организация:</span>
                <span className={styles.entityName}>{object.entity_name}</span>
              </>
            )}
          </div>
        ))
      ) : (
        <EmptyPlaceholder text={"Нет проектов"} img={estimateIcon} className={styles.emptyPlaceholder} />
      )}
    </div>
  );
};

export default AddTaskByBuilding;
