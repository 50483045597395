import cn from "classnames";
import React from "react";
import { useSelector } from "react-redux";

import { summaryTableHighlightingSelector } from "pages/Finance/_TODO/summaryScrollReducer/selectors";

import valueInThousandsOrMillions from "utils/formatters/valueInThousandsOrMillions";

import styles from "./styles.module.scss";

const MoneyCell = React.memo((props: any) => {
  const { data, measure = 1000, isDiff } = props;
  const tableHighlighting = useSelector(summaryTableHighlightingSelector) || {};

  return (
    <div>
      <span
        className={cn(styles.moneyCell__text, props.className, {
          /* @ts-ignore */
          [styles.negative]: isDiff && tableHighlighting.negative && data < 0,
          /* @ts-ignore */
          [styles.positive]: isDiff && tableHighlighting.positive && data > 0,
        })}
        /* @ts-ignore */
        title={valueInThousandsOrMillions(data, measure)}
      >
        {/* @ts-ignore */}
        {valueInThousandsOrMillions(data, measure)}
      </span>
    </div>
  );
});

/* @ts-ignore */
const renderMoneyCell = (data, measure, isDiff, className) => {
  return <MoneyCell data={data} measure={measure} isDiff={isDiff} className={className} />;
};

export default renderMoneyCell;
