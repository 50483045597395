import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useConstructionControlRedirectToObject } from "pages/ConstructionControl/common/useConstructionControlRedirectToObject";

import CustomBreadCrumbsMenu from "entities/CustomBreadcrumbs/CustomBreadCrumbsMenu";
import CustomBreadcrumbs from "entities/CustomBreadcrumbs/CustomBreadcrumbs";
import BreadcrumbsProjectSelect from "features/templates/BreadcrumbsSelects/BreadcrumbsProjectSelect";

const ConstructionControlObjectBreadcrumbs = () => {
  const { projectId, objectId } = useParams<Record<string, string>>();
  const { objects, isLoadingObjects } = useConstructionControlRedirectToObject();

  const objectsOptions = useMemo(() => {
    if (objects) {
      return [
        {
          id: 0,
          name: "Все объекты",
          link: `/construction-control/projects/objects-list/${projectId}`,
        },
        ...objects.map((el) => ({
          id: el.building,
          name: el.name,
          link: `/construction-control/projects/${projectId}/object/${el.building}`,
        })),
      ];
    }
    return [];
  }, [objects]);

  return (
    <CustomBreadcrumbs title="Стройконтроль" subtitle="Проекты">
      <BreadcrumbsProjectSelect
        constructLink={({ projectId }) => `/construction-control/projects/objects-list/${projectId}`}
        customFirstOption={{
          id: 0,
          name: "Все проекты",
          link: `/construction-control/projects`,
        }}
      />
      <CustomBreadCrumbsMenu
        selectedOption={objectsOptions?.find((el) => el.id === +objectId)?.name}
        disabled={projectId === "0" || isLoadingObjects}
        options={objectsOptions}
      />
    </CustomBreadcrumbs>
  );
};

export default ConstructionControlObjectBreadcrumbs;
