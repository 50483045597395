import cn from "classnames";
import React from "react";

import styles from "../Legend.module.scss";

export interface ICompBoxProps {
  title: string;
  style?: Record<string, string>;
  className?: string;
}

const CompBox: React.FC<ICompBoxProps> = ({ title, style, className }) => {
  return (
    <div className={styles.box}>
      <div className={cn(styles.divider, className)} style={style} />
      <div className={styles.item}>{title}</div>
    </div>
  );
};

export default React.memo(CompBox);
