import cn from "classnames";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";

import MonthsWork from "./components/MonthsWork/MonthsWork";

import ShowMoreButton from "shared/ui/controls/ShowMoreButton";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import timesheetIcon from "images/icons/navigation/timesheetIcon.svg";

import styles from "./WorkersTable.module.scss";

const WorkersTable = ({ workers, filteredWorkers, loadMore, workersLimit, monthIndex, year, workersCountWork }) => {
  const tableRef = useRef(null);

  const dateInCurrentMonth = moment().month(monthIndex).year(year).endOf("month").date();

  const [tableHeight, setTableHeight] = useState();

  useEffect(() => {
    if (!tableRef.current) return;

    setTableHeight(tableRef.current.scrollHeight);
  }, []);

  useEffect(() => {
    if (!tableRef.current) return;

    tableRef.current.scroll({ top: tableHeight - 115, behavior: "smooth" });
    setTableHeight(tableRef.current.scrollHeight);
  }, [workers.results.length]);

  const getItems = (worker, day) => {
    const data = workersCountWork.results.find(
      (item) =>
        item.worker === worker.id &&
        item.work_date === moment().date(day).month(monthIndex).year(year).format("YYYY-MM-DD")
    );

    return (
      <div className={styles.info}>
        <div className={styles.status}>{data?.status}</div>
        <div className={styles.value}>{data?.status && parseInt(data?.count, 0)}</div>
      </div>
    );
  };

  const getMonthsWork = (worker) => {
    const workersCountData = workersCountWork.results.find(
      (item) => item.worker === worker.id && item.worker_data !== undefined
    );

    if (!workersCountData) {
      return (
        <MonthsWork
          firstHalfMonthCountDays={+worker.first_half_month_days || 0}
          firstHalfMonthSumHours={+worker.first_half_month_hours || 0}
          secondHalfMonthCountDays={+worker.second_half_month_days || 0}
          secondHalfMonthSumHours={+worker.second_half_month_hours || 0}
          monthCountDays={+worker.month_count_days || 0}
          monthSumHours={+worker.month_sum_hours || 0}
        />
      );
    }

    return (
      <MonthsWork
        firstHalfMonthCountDays={+workersCountData.worker_data.first_half_month_days || 0}
        firstHalfMonthSumHours={+workersCountData.worker_data.first_half_month_hours || 0}
        secondHalfMonthCountDays={+workersCountData.worker_data.second_half_month_days || 0}
        secondHalfMonthSumHours={+workersCountData.worker_data.second_half_month_hours || 0}
        monthCountDays={+workersCountData.worker_data.month_count_days || 0}
        monthSumHours={+workersCountData.worker_data.month_sum_hours || 0}
      />
    );
  };

  return (
    <>
      <div className={styles.top}>
        <div className={styles.firstTitle}>Отметки о явках и неявках на работу по числам месяца</div>
        <div className={styles.secondTitle}>Отработано</div>
      </div>
      <div ref={tableRef} className={styles.tableContainer}>
        <div className={styles.table}>
          <header className={styles.tableHeader}>
            <div className={styles.headerContent}>
              <div className={styles.columnsGroup}>
                <span className={styles.numberColumn}>№</span>
                <span className={styles.nameColumn}>ФИО, должность (специальность, профессия)</span>
                <span className={styles.tableNumColumn}>Табельный номер</span>
              </div>
              <div className={styles.dayTableCell}>
                <div className={styles.dayBlock}>
                  <div className={styles.dayRow}>
                    {[...Array(15).keys()].map((item) => (
                      <div key={item} className={styles.dayItemHeader}>
                        {item + 1}
                      </div>
                    ))}
                    <div key={15} className={styles.dayItemHeader}>
                      x
                    </div>
                  </div>
                  <div className={styles.dayRow}>
                    {[...Array(dateInCurrentMonth - 15).keys()].map((item) => (
                      <div key={item} className={styles.dayItemHeader}>
                        {item + 16}
                      </div>
                    ))}
                    {[...Array(31 - dateInCurrentMonth).keys()].map((item) => (
                      <div key={item + 16} className={styles.dayItemHeader}>
                        31
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={cn(styles.durations, styles.monthBlocks)}>
                <div className={styles.columns}>
                  <span className={cn(styles.blueTitle, styles.column)}>Половину месяца (I,II)</span>
                  <span className={styles.column}>Месяц</span>
                </div>
                <div className={styles.durationRows}>
                  <span className={styles.durationRow}>Дни</span>
                  <span className={styles.durationRow}>Часы</span>
                </div>
              </div>
            </div>
            <div className={styles.greyRow}>
              <span className={styles.one}>1</span>
              <span className={styles.two}>2</span>
              <span className={styles.three}>3</span>
              <span className={styles.four}>4</span>
              <span className={styles.five}>5</span>
              <span className={styles.six}>6</span>
            </div>
          </header>
          {filteredWorkers?.length !== 0 &&
            filteredWorkers.map((worker, index) => (
              <div className={styles.row} key={worker.id}>
                <div className={styles.columnsGroup}>
                  <span className={styles.numberColumn}>{index + 1}</span>
                  <div className={styles.nameColumn}>
                    <span>
                      {worker.last_name} {worker.first_name} {worker.middle_name}
                    </span>
                    <span className={styles.postInfo}>
                      {worker.post_rank_info?.post_title}{" "}
                      {!!worker.post_rank_info?.rank_display && `(${worker.post_rank_info?.rank_display})`}
                    </span>
                  </div>
                  <span className={cn(styles.tableNumColumn, styles.tableNumItem)}>{worker.table_num}</span>
                </div>
                <div className={styles.dayTableCell}>
                  <div className={styles.dayBlock}>
                    <div className={styles.dayRow}>
                      {[...Array(15).keys()].map((day) => (
                        <div className={styles.dayItem}>{getItems(worker, day + 1)}</div>
                      ))}
                      <div className={styles.dayItem}></div>
                    </div>
                    <div className={styles.dayRow}>
                      {[...Array(dateInCurrentMonth - 15).keys()].map((day) => (
                        <div className={styles.dayItem}>{getItems(worker, day + 16)}</div>
                      ))}
                      {[...Array(31 - dateInCurrentMonth).keys()].map(() => (
                        <div className={styles.dayItem}></div>
                      ))}
                    </div>
                  </div>
                </div>
                {getMonthsWork(worker)}
              </div>
            ))}
        </div>
      </div>
      <ShowMoreButton
        handleAdd={loadMore}
        isExists={workers.count > workers.results.length && workers.results.length >= workersLimit}
        allCount={workers.count}
        showedCount={workers.results.length}
        limit={workersLimit}
      />
    </>
  );
};

export default React.memo(WorkersTable);
