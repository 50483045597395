import update from "immutability-helper";
import { memoize } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { compose } from "redux";

import Difference from "../../../../../../shared/ui/dataDisplay/Difference/Difference";
import Calendar from "../../../../../../shared/ui/inputs/Calendar/Calendar";
import InputBase, { VALUE_TYPES } from "../../../../../../shared/ui/inputs/InputBase/InputBaseOrder";
import DeleteOffer from "../DeleteOffer";
import MeasureSelect from "entities/MeasureSelect/MeasureSelect";

import { beautifyNumberValue } from "../../../../../../utils/formatters/beautifyNumberValue";
import { getLocalizedDate } from "../../../../../../utils/formatters/getLocalizedDate";
import { transformDigitToFinancial } from "../../../../../../utils/formatters/transformDigitToFinancial";
import { getIdentifier } from "../../../../../../utils/helpers/getIdentifier";
import { getValueFromEventTarget } from "../../../../../../utils/helpers/getValueFromEventTarget";
import { stringifyArgs } from "../../../../../../utils/helpers/stringifyArgs";

import GroupByIcon from "../../../../../../images/icons/GroupByIcon";
import plusIcon from "../../../../../../images/plus-bordered.svg";

import styles from "../../index.module.scss";

const OfferColumns = (props) => {
  const {
    offer,
    setOffer,
    handleKitComponentsOpening,
    deleteOffer,
    isShownCountRequestMeasure,
    createOfferSupply,
    offerIsKitWithNotKitMeasure,
    permissions,
  } = props;

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const openConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(true), []);
  const closeConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(false), []);

  const handleDeleteOffer = useCallback(() => {
    deleteOffer(getIdentifier(offer));
    closeConfirmDeleteModal();
  }, [offer, deleteOffer, closeConfirmDeleteModal]);

  const onChangeField = useCallback((name, value) => setOffer({ ...offer, [name]: value }), [offer, setOffer]);

  const onChangeFieldByName = useCallback(
    (name) => memoize((value) => onChangeField(name, value), stringifyArgs),
    [onChangeField]
  );

  const onChangeDate = useCallback(
    (changedDate) => setOffer(update(offer, { supplies: { 0: { date: { $set: changedDate.format("YYYY-MM-DD") } } } })),
    [setOffer, offer]
  );

  const onChangeCountRequestMeasure = useCallback(
    (event) =>
      setOffer(
        update(offer, {
          supplies: { 0: { count_request_measure: { $set: event.target.value } } },
        })
      ),
    [setOffer, offer]
  );

  const onChangeCount = useCallback(
    (event) =>
      setOffer(
        update(offer, {
          supplies: { 0: { count: { $set: event.target.value } } },
        })
      ),
    [setOffer, offer]
  );

  const onChangePrice = useCallback(
    (changedPrice) => {
      // Обновляет сумму всех supply у offer'а

      // Состояние из которого рендерятся supply и функция его обновления
      // расположены в компоненте Order ([orderRequests, setOrderRequests])
      // и тянутся вглубь дерева компонентов обрастая различными обертками

      // Сумма каждой supply расчитывается здесь, из-за того, что
      // если это делать внутри компонента OfferSupplyColumns через useEffect,
      // в один момент времени асинхронно произойдет много setState'ов и
      // применится только последний

      const suppliesToUpdate = {};

      offer.supplies.forEach((supply, idx) => {
        suppliesToUpdate[idx] = { price: { $set: changedPrice } };

        if (supply.count && changedPrice) {
          suppliesToUpdate[idx].amount = { $set: calculateAmount(changedPrice, supply.count) };
        } else {
          suppliesToUpdate[idx].amount = { $set: "" };
        }
      });

      const updatedOffer = update(offer, {
        price: { $set: changedPrice },
        supplies: suppliesToUpdate,
      });
      setOffer(updatedOffer);
    },
    [setOffer, offer]
  );

  const onChangeAmount = useCallback(
    (changedAmount) =>
      setOffer(
        update(offer, {
          supplies: { 0: { amount: { $set: changedAmount } } },
        })
      ),
    [setOffer, offer]
  );

  const calculateAmount = (price, count) => beautifyNumberValue(price * count);

  const differenceAmount = useMemo(() => {
    // Расчет разницы между сметной стоимостью и стоимостью в заказе

    if (!offer.benefit?.amount_plan) {
      return 0;
    }

    // Сметная (или расчетная) стоимость может включать в себя НДС, а может и не включать
    // На данный момент это не реализовано в системе
    // По дефолту считаем, что сметная стоимость ВКЛЮЧАЕТ в себя НДС

    // Если свитчер "Включая НДС" включен, то в стоимость по заказу уже включен НДС
    // Если выключен, то в эту стоимость он еще не включен

    // Проверяем, включен ли НДС в стоимость заказа
    const isVatIncludedInOrder = offer.calculation?.vat !== 0;

    if (isVatIncludedInOrder) {
      // В случае, если заказ включает в себя НДС (свитчер включен)
      // Считаем разницу по формуле С - З
      return offer.benefit?.amount_plan - offer.calculation?.amount;
    } else {
      // В случае, если заказ тоже не включает в себя НДС (свитчер выключен)
      // Считаем разницу по формуле: С - (З * 1.2)
      // Где: С – сметная стоимость, З – стоимость в заказе
      return offer.benefit?.amount_plan - offer.calculation?.amount * 1.2;
    }
  }, [offer]);

  const differencePercent = useMemo(() => {
    return offer.benefit?.amount_plan ? (differenceAmount / offer.benefit?.amount_plan) * 100 : 0;
  }, [offer, differenceAmount]);

  useEffect(() => {
    if (!offer.supplies[0].count || !offer.price) {
      onChangeAmount("");
    } else {
      onChangeAmount(calculateAmount(offer.price, offer.supplies[0].count));
    }
  }, [offer.supplies[0].count]);

  return (
    <>
      <div className={styles.nameColumn}>
        {offer.is_kit && <GroupByIcon className={styles.kitOfferIcon} onClick={handleKitComponentsOpening} />}
        {permissions.editRequest ? (
          <TextareaAutosize
            className={styles.input}
            value={offer.name}
            placeholder="Наименование предложения"
            onChange={compose(onChangeFieldByName("name"), getValueFromEventTarget)}
          />
        ) : (
          offer.name
        )}
      </div>
      <div className={styles.dateColumn}>
        {permissions.editRequest ? (
          <Calendar classNameSelect={styles.calendar} setValue={onChangeDate} value={offer.supplies[0].date || ""} />
        ) : (
          getLocalizedDate(offer.supplies[0].date)
        )}
      </div>
      <div className={styles.countColumn}>
        {permissions.editRequest && !offerIsKitWithNotKitMeasure ? (
          <InputBase
            className={styles.input}
            valueType={VALUE_TYPES.NUMBER}
            variant="secondary"
            value={offer.supplies[0].count}
            onChange={onChangeCount}
            placeholder="Кол-во"
          />
        ) : (
          offer.supplies[0].count
        )}
      </div>
      <div className={styles.measureColumn}>
        {permissions.editRequest ? (
          <MeasureSelect value={offer.measure} onChange={onChangeFieldByName("measure")} />
        ) : (
          offer.measure
        )}
      </div>
      {isShownCountRequestMeasure && (
        <span className={styles.countColumn}>
          {permissions.editRequest ? (
            <InputBase
              className={styles.input}
              valueType={VALUE_TYPES.NUMBER}
              variant="secondary"
              value={offer.supplies[0].count_request_measure}
              onChange={onChangeCountRequestMeasure}
              placeholder="Кол-во в ед.изм"
            />
          ) : (
            offer.supplies[0].count_request_measure
          )}
        </span>
      )}
      <div className={styles.priceColumn}>
        {permissions.editRequest ? (
          <InputBase
            className={styles.input}
            valueType={VALUE_TYPES.PRICE}
            variant="secondary"
            value={offer.price}
            onChange={compose(onChangePrice, getValueFromEventTarget)}
            placeholder="Цена"
          />
        ) : (
          <span className={styles.financial}>{transformDigitToFinancial(offer.price)}</span>
        )}
      </div>
      <div className={styles.sumColumn}>
        {permissions.editRequest ? (
          <InputBase
            className={styles.input}
            valueType={VALUE_TYPES.PRICE}
            variant="secondary"
            value={offer.supplies[0].amount}
            onChange={compose(onChangeAmount, getValueFromEventTarget)}
            placeholder="Сумма"
          />
        ) : (
          <span className={styles.financial}>{transformDigitToFinancial(offer.supplies[0].amount)}</span>
        )}
      </div>
      {permissions.editRequest &&
        (!offerIsKitWithNotKitMeasure ? (
          <div className={styles.actionsColumn}>
            {offer.supplies.length === 1 && (
              <img
                className={styles.createOfferSupplyIcon}
                src={plusIcon}
                alt="Иконка добавления предложения"
                onClick={createOfferSupply}
              />
            )}
            <div className={styles.delete}>
              <DeleteOffer
                openModal={openConfirmDeleteModal}
                closeModal={closeConfirmDeleteModal}
                isOpen={isConfirmDeleteModalOpen}
                deleteOffer={handleDeleteOffer}
              />
            </div>
          </div>
        ) : (
          offerIsKitWithNotKitMeasure && <div className={styles.actionsColumn} />
        ))}
      {!permissions.editRequest && permissions.viewInvoiceDifference && (
        <div className={styles.differenceColumn}>
          <Difference amount={differenceAmount} percent={differencePercent} />
        </div>
      )}
    </>
  );
};

export default React.memo(OfferColumns);
