import axios from "axios";
import React, { useEffect, useState } from "react";

import { IListResponseData } from "types/ListResponse";

//весь этот файл - временный костыль
interface IProps {
  expenditure_id: number;
  objectId: string;
  isOpen: boolean;
}

type IResponse = IListResponseData<{
  id: 4;
  title: "Название чертежа";
  sheet_numbers: ["1"];
  created_at: "2024-07-03T11:01:42.760436Z";
  drawing_set: 5;
  files_count: 1;
  drawing_set_title: "Название комплекта";
  has_link_with_estimate: true;
  drawing_set_code: string;
  drawing_set_organization: string;
}>;

export const useDocumentationInAct = ({ expenditure_id, objectId, isOpen }: IProps) => {
  const [res, setRes] = useState<string>();

  useEffect(() => {
    if (isOpen) {
      axios.get<IResponse>(`/building/${objectId}/drawings/?expenditure_ids=[${expenditure_id}]`).then(({ data }) => {
        const item = data.results[0];
        if (item) {
          setRes(
            `${item.drawing_set_organization}, ${item.drawing_set_title}, шифр: ${item.drawing_set_code}, ${
              item.title
            }, листы: ${item.sheet_numbers?.join(", ")}`
          );
        }
      });
    }
  }, [isOpen, objectId, expenditure_id]);

  return res;
};
