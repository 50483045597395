import axios from "axios";

import {
  IAosrInKs2Expenditure,
  IAttachFileInWorkInJournalRequest,
  IAttachmentExpenditureInJournal,
  IAttachmentInExpenditureResponse,
  ICreateCertificate,
  ICreatePassport,
  IFileAttachingToExpenditureParams,
  IPassport,
} from "./types";
import { IListResponseData } from "types/ListResponse";

export const passportsApi = {
  getDocuments: (productId: number) => {
    return axios.get<IListResponseData<IPassport>>(`/purchaser/packinglists/documents/`, {
      params: { limit: 100, packing_item_id: productId },
    });
  },
  createPassport: (data: ICreatePassport) => {
    return axios.post(`/purchaser/packinglists/passports/`, data);
  },
  createCertificate: (data: ICreateCertificate) => {
    return axios.post(`/purchaser/packinglists/certificates/`, data);
  },
  deletePassport: (passportId: number) => {
    return axios.delete(`/purchaser/packinglists/passports/${passportId}/`);
  },
  deleteCertificate: (certId: number) => {
    return axios.delete(`/purchaser/packinglists/certificates/${certId}/`);
  },
  attachFileToExpenditure: (expenditureId: number, data: IFileAttachingToExpenditureParams) => {
    return axios.post(`/estimate/documents/resource_attachments/${expenditureId}/`, data);
  },
  getExpenditureAttachments: (expenditureId: number) => {
    return axios.get<IAttachmentInExpenditureResponse[]>(`/estimate/documents/resource_attachments/${expenditureId}/`);
  },
  deleteResourceAttachment: (expenditureId: number, attachmentId: number) => {
    return axios.delete(`/estimate/documents/resource_attachments/${expenditureId}/${attachmentId}`);
  },
  deleteWorkAttachment: (buildingId: number, attachmentId: number) => {
    return axios.delete(`/building/${buildingId}/estimate/attachments/work/${attachmentId}`);
  },
  getKs2Attachments: (ks2Id: number) => {
    return axios.get<IListResponseData<IAttachmentInExpenditureResponse>>(`/estimate/documents/ks2/${ks2Id}/attachments/`, {
      params: { limit: 100 },
    });
  },
  attachFileToKs2: (ks2Id: number, data: IFileAttachingToExpenditureParams) => {
    return axios.post(`/estimate/documents/ks2/${ks2Id}/attachments/`, data);
  },
  getSectionAttachmentsInKs2: (ks2Id: number, sectionId: number) => {
    return axios.get<IListResponseData<IAttachmentInExpenditureResponse>>(
      `/estimate/documents/ks2/${ks2Id}/attachments/section/${sectionId}/`,
      {
        params: { limit: 100 },
      }
    );
  },
  attachFileToSectionInKs2: (ks2Id: number, sectionId: number, data: IFileAttachingToExpenditureParams) => {
    return axios.post(`/estimate/documents/ks2/${ks2Id}/attachments/section/${sectionId}/`, data);
  },
  attachFileToExpenditureInKs2: (data: IAttachFileInWorkInJournalRequest, buildingId: string) => {
    return axios.post(`/building/${buildingId}/estimate/attachments/work/`, data);
  },
  hideDocumentInKs2: (ks2Id: number, docId: number) => {
    return axios.post(`/estimate/documents/ks2/${ks2Id}/hide_attachments/`, {
      ks2: docId
    })
  },
  hideDocumentInKs2Section: (ks2Id: number, docId: number) => {
    return axios.post(`/estimate/documents/ks2/${ks2Id}/hide_attachments/`, {
      section: docId
    })
  },
  ///////////
  getWorkFilesInJournal: (buildingId: string, expId: number, year: number, month: number) => {
    return axios.get<IListResponseData<IAttachmentExpenditureInJournal>>(`/building/${buildingId}/estimate/attachments/work/`, {
      params: {
        year,
        month,
        expenditure_id: expId,
      },
    });
  },
  getWorkFilesInKs: (buildingId: string, expId: number, ks2Id: number) => {
    return axios.get<IListResponseData<IAttachmentExpenditureInJournal>>(`/building/${buildingId}/estimate/attachments/work/`, {
      params: {
        act_ks2: ks2Id,
        expenditure_id: expId,
      },
    });
  },
  attachFileToWorkInJournal: (buildingId: string, data: IAttachFileInWorkInJournalRequest) => {
    return axios.post(`/building/${buildingId}/estimate/attachments/work/`, data);
  },
  deleteFileFromWorkInJournal: (buildingId: string, docId: number) => {
    return axios.delete(`/building/${buildingId}/estimate/attachments/work/${docId}/`);
  },
};
