import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IDetailedKs3InDocs } from "redux/modules/common/building/documents/ksList/types";
import {
  ks2AttachmentsSelector,
  passportsInvalidateKeySelector,
} from "redux/modules/common/passportsAndCertificates/selectors";
import { getKs2Attachments } from "redux/modules/common/passportsAndCertificates/thunks";
import { DocumentsTypesEnum } from "redux/modules/common/passportsAndCertificates/types";

import FilesModalKs2 from "components/modals/ExpenditureFilesModal/FilesModalKs2";

import KS3PageRowContent from "./KS3PageRowContent/KS3PageRowContent";
import ExpandPillTrigger from "shared/ui/controls/ExpandPillTrigger/ExpandPillTrigger";
import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import DateCell from "shared/ui/dataDisplay/DateCell/DateCell";
import StatusElement from "shared/ui/dataDisplay/StatusElement/StatusElement";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { getAttachmentName } from "../utils";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./KS2PageRow.module.scss";

interface IProps {
  act: IDetailedKs3InDocs["ks2_acts"][0];
  isAutoExpand?: boolean;
}

const KS3PageRow: React.FC<IProps> = ({ act, isAutoExpand }) => {
  const dispatch = useDispatch();
  const [isFilesRow, setIsFilesRow] = useState(false);
  const [isFilesModal, setIsFilesModal] = useState(false);

  const toggleFilesRow = useCallback(() => {
    setIsFilesRow((prev) => !prev);
  }, []);

  const toggleFilesModal = useCallback((event?: React.MouseEvent) => {
    event && event.stopPropagation();

    setIsFilesModal((prev) => !prev);
  }, []);

  const dateString = `${moment(act?.start_at).format("DD/MM/YYYY")} - ${moment(act?.end_at).format("DD/MM/YYYY")}`;

  const invalidateDocsKey = useSelector(passportsInvalidateKeySelector);

  useEffect(() => {
    if (!isFilesRow) return;
    dispatch(getKs2Attachments(act.id));
  }, [isFilesRow, act.id, invalidateDocsKey]);

  const ks2Attachments = useSelector(ks2AttachmentsSelector)[act.id];

  const filteredAttachments = useMemo(() => {
    return ks2Attachments?.results?.filter((el) => el.type !== DocumentsTypesEnum.file);
  }, [ks2Attachments]);

  const isShowExpandPill = act.attachments_count || !!filteredAttachments?.length;
  const expandPillCount = act.attachments_count || filteredAttachments?.length;

  return (
    <>
      <TableReusableRow
        isExpandable
        isParentOfExpenditures
        className={styles.row}
        innerContent={<KS3PageRowContent ks2Id={act.id} />}
        containerClassName={styles.rowContainer}
        openClassName={styles.rowOpen}
        isDefaultExpanded={isAutoExpand}
      >
        <div className={styles.headRowKs3}>
          <TableReusableCell className={styles.ks3title}>Акт КС-2 №{act.title}</TableReusableCell>
          <TableReusableCell>
            <DateCell date={dateString} />
          </TableReusableCell>
          <TableReusableCell onClick={stopEventPropagation} className={styles.fileCell}>
            <FilesClipButton onClick={toggleFilesModal} />
          </TableReusableCell>
          <TableReusableCell onClick={stopEventPropagation} className={styles.expandCell}>
            {isShowExpandPill && (
              <ExpandPillTrigger
                isExpanded={isFilesRow}
                toggleExpand={toggleFilesRow}
                variant="success"
                label={`${expandPillCount}`}
              />
            )}
          </TableReusableCell>
        </div>
        {isFilesRow && (
          <div className={styles.filesRow}>
            {filteredAttachments?.map((el) => (
              <StatusElement status="success" text={getAttachmentName(el)} key={el.id} onClick={toggleFilesModal} />
            ))}
          </div>
        )}
      </TableReusableRow>
      {/* @ts-ignore */}
      <FilesModalKs2 isOpen={isFilesModal} onClose={toggleFilesModal} ks2Id={act.id} />
    </>
  );
};

export default React.memo(KS3PageRow);
