import React from 'react'

export const Collect = ({ className, color }) => {
    return (
        <svg width="23" height="15" fill='#4FB2ED' xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3637 9.75938C14.3637 9.32178 14.1898 8.90207 13.8804 8.59264C13.571 8.2832 13.1513 8.10938 12.7137 8.10938H9.41367C8.97607 8.10938 8.55638 8.2832 8.24694 8.59264C7.93751 8.90207 7.76367 9.32178 7.76367 9.75938V13.0594C7.76367 13.497 7.93751 13.9167 8.24694 14.2261C8.55638 14.5355 8.97607 14.7094 9.41367 14.7094H12.7137C13.1513 14.7094 13.571 14.5355 13.8804 14.2261C14.1898 13.9167 14.3637 13.497 14.3637 13.0594V9.75938Z" fill='#4FB2ED' />
            <path d="M5.19414 8.10938H1.89414C1.45653 8.10938 1.03684 8.28322 0.727409 8.59265C0.417974 8.90209 0.244141 9.32176 0.244141 9.75937V13.0594C0.244141 13.497 0.417974 13.9167 0.727409 14.2261C1.03684 14.5355 1.45653 14.7094 1.89414 14.7094H5.19414C5.63174 14.7094 6.05143 14.5355 6.36086 14.2261C6.6703 13.9167 6.84414 13.497 6.84414 13.0594V9.75937C6.84414 9.32176 6.6703 8.90209 6.36086 8.59265C6.05143 8.28322 5.63174 8.10938 5.19414 8.10938Z" fill='#4FB2ED' />
            <path d="M22.2445 2.06017C22.2445 1.62256 22.0707 1.20285 21.7613 0.893417C21.4518 0.583982 21.0322 0.410156 20.5945 0.410156H17.2945C16.8569 0.410156 16.4372 0.583982 16.1278 0.893417C15.8184 1.20285 15.6445 1.62256 15.6445 2.06017V5.36015C15.6445 5.79776 15.8184 6.21744 16.1278 6.52687C16.4372 6.83631 16.8569 7.01015 17.2945 7.01015H20.5945C21.0322 7.01015 21.4518 6.83631 21.7613 6.52687C22.0707 6.21744 22.2445 5.79776 22.2445 5.36015V2.06017Z" fill='#4FB2ED' />
            <path d="M20.5945 8.10938H17.2945C16.8569 8.10938 16.4372 8.28322 16.1278 8.59265C15.8184 8.90209 15.6445 9.32176 15.6445 9.75937V13.0594C15.6445 13.497 15.8184 13.9167 16.1278 14.2261C16.4372 14.5355 16.8569 14.7094 17.2945 14.7094H20.5945C21.0322 14.7094 21.4518 14.5355 21.7613 14.2261C22.0707 13.9167 22.2445 13.497 22.2445 13.0594V9.75937C22.2445 9.32176 22.0707 8.90209 21.7613 8.59265C21.4518 8.28322 21.0322 8.10938 20.5945 8.10938Z" fill='#4FB2ED' />
            <path d="M9.41367 7.01016H12.7137C13.1513 7.01016 13.571 6.83632 13.8804 6.52689C14.1898 6.21745 14.3637 5.79778 14.3637 5.36017V2.06015C14.3637 1.62254 14.1898 1.20287 13.8804 0.893433C13.571 0.583998 13.1513 0.410156 12.7137 0.410156H9.41367C8.97606 0.410156 8.55638 0.583998 8.24695 0.893433C7.93751 1.20287 7.76367 1.62254 7.76367 2.06015V5.36017C7.76367 5.57685 7.80636 5.79139 7.88928 5.99158C7.9722 6.19176 8.09373 6.37367 8.24695 6.52689C8.40016 6.6801 8.58207 6.80163 8.78226 6.88455C8.98244 6.96747 9.19698 7.01016 9.41367 7.01016ZM8.86368 2.06015C8.86368 1.98792 8.87791 1.91643 8.90555 1.8497C8.93319 1.78297 8.97368 1.72234 9.02475 1.67126C9.07582 1.62019 9.13646 1.57967 9.20319 1.55203C9.26992 1.52439 9.34144 1.51016 9.41367 1.51016H12.7137C12.7859 1.51016 12.8574 1.52439 12.9241 1.55203C12.9909 1.57967 13.0515 1.62019 13.1026 1.67126C13.1536 1.72234 13.1942 1.78297 13.2218 1.8497C13.2494 1.91643 13.2637 1.98792 13.2637 2.06015V5.36017C13.2637 5.50604 13.2057 5.64594 13.1026 5.74908C12.9994 5.85223 12.8596 5.91016 12.7137 5.91016H9.41367C9.2678 5.91016 9.12789 5.85223 9.02475 5.74908C8.9216 5.64594 8.86368 5.50604 8.86368 5.36017V2.06015Z" fill='#4FB2ED' />
        </svg>

    )
}
