import cn from "classnames";
import React, { FC, MouseEventHandler, useCallback } from "react";

import ButtonBase from "../../../../../../shared/ui/controls/ButtonBase";
import HandlerIndicatorItem from "../HandlerIndicatorItem/HandlerIndicatorItem";

import { ESTIMATE_STATES_IDS } from "../../../ProHandler/constants";
import { EEstimateStatesIds } from "../../../ProHandler/enums";

import styles from "./LoadDraftIndicator.module.scss";

interface IProps {
  drafterCount: number | string;
  onClick: (stateId: EEstimateStatesIds) => void;
  isActive: boolean;
  isFullWidth?: boolean;
  isFatText?: boolean;
}

const LoadDraftIndicator: FC<IProps> = ({
  drafterCount,
  onClick,
  isActive,
  isFullWidth = false,
  isFatText = false,
}) => {
  const handleOnClick = useCallback(() => onClick(ESTIMATE_STATES_IDS.DRAFT), [onClick]);

  const buttonHandler: MouseEventHandler = (e) => {
    e.stopPropagation();
    handleOnClick();
  };

  return (
    <HandlerIndicatorItem isActive={isActive} isFullWidth={isFullWidth}>
      <div className={cn(styles.container, { [styles.fatText]: isFatText })} onClick={handleOnClick}>
        <ButtonBase primary className={styles.btn} onClick={buttonHandler}>
          Создать позицию
        </ButtonBase>
        <span className={styles.number}>{drafterCount || 0} позиций </span>
        <div className={styles.subTitle}>В черновике</div>
        {isActive && <span className={styles.border} />}
      </div>
    </HandlerIndicatorItem>
  );
};

export default React.memo(LoadDraftIndicator);
