import axios from "axios";

import { addCommentActions } from "../actions";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

export const createStockProductComment = (stockId, productId, comment) => {
  return (dispatch) => {
    axios
      .post(`/stocks/${stockId}/products/${productId}/comments/`, { comment, item_id: productId })
      .then((response) => dispatch(addCommentActions({ comment: response.data, itemId: productId })))
      .catch(errorCatcher);
  };
};
