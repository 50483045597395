import { IPermissionsTree } from "features/permissions/model/types";

export interface INormalizedPermissionsTree {
  isOpen?: boolean;
  alias: string;
  children?: INormalizedPermissionsTree[];
  name: string;
  type: "group" | "permission";
  parentChain?: string[];
  index?: number;
  optional: boolean;
}

export const permissionsUtils = {
  getCountWithoutFakes: (tree: IPermissionsTree | null) => {
    if (!tree) return 0;
    const childrenLength: number = tree.groups.reduce((acc, cur) => {
      return acc + permissionsUtils.getCountWithoutFakes(cur);
    }, 0);
    return tree.permissions.filter((el) => !el.alias.toLowerCase().startsWith("fake")).length + childrenLength;
  },
  matchPermissionsWithChildrenAliases: (
    tree: IPermissionsTree | null,
    res: Record<string, string[]> = {}
  ): Record<string, string[]> => {
    if (!tree) return {};

    const mainTreePerm = tree.permissions.at(-1);

    if (mainTreePerm) {
      res[mainTreePerm.alias] = buildChildrenAliasesChain(tree);
    }

    tree.groups.forEach((group) => {
      const mainGroupPermission = group.permissions.at(-1);

      if (mainGroupPermission) {
        res[mainGroupPermission.alias] = buildChildrenAliasesChain(group);
      }

      group.groups.forEach((el) => {
        permissionsUtils.matchPermissionsWithChildrenAliases(el, res);
      });
    });

    return res;
  },
  normalizeTree: (tree: IPermissionsTree | null): INormalizedPermissionsTree => {
    if (!tree) return {} as INormalizedPermissionsTree;
    return resurrectPermissionsTree(extractPermissionsToDict(tree));
  },
};

function extractPermissionsToDict(
  group: IPermissionsTree,
  res: Record<string, INormalizedPermissionsTree> = {},
  parentChain: string[] = []
) {
  const filteredPermissions = group.permissions.filter((el) => !el.alias?.toLocaleLowerCase()?.startsWith("fake"));
  const mainPerm = filteredPermissions.at(-1);
  const newParentChain = [...parentChain];
  if (mainPerm?.alias) {
    newParentChain.push(mainPerm?.alias);
  }
  filteredPermissions.forEach((perm, index) => {
    res[perm.alias] = {
      name: perm.title,
      alias: perm.alias,
      children: [],
      type: perm.alias === mainPerm?.alias ? "group" : "permission",
      parentChain: newParentChain,
      index,
      optional: mainPerm?.optional ?? true,
    };
  });

  group.groups.forEach((gr) => {
    extractPermissionsToDict(gr, res, newParentChain);
  });

  return res;
}

function resurrectPermissionsTree(permissionsDict: Record<string, INormalizedPermissionsTree>) {
  const maxDepth = Object.values(permissionsDict).reduce<number>((acc, cur, i, arr) => {
    if (cur.parentChain?.length! > acc) {
      return cur.parentChain?.length!;
    } else {
      return acc;
    }
  }, 0);
  const tree: INormalizedPermissionsTree = { children: [], name: "", alias: "root", type: "group", optional: false };
  for (let i = 0; i <= maxDepth; i++) {
    const nodes = Object.values(permissionsDict).filter((el) => el.parentChain?.length! === i);
    nodes.forEach((node) => {
      let target: INormalizedPermissionsTree | undefined = tree;
      node.parentChain?.forEach((parent, i, arr) => {
        const isEnd = i === arr.length - 1;
        if (isEnd) {
          if (target) {
            target.children = [...(target.children ?? []), node];
          } else {
            target = node;
          }
        } else {
          if (target) {
            target = target.children?.find((el) => el.alias === parent);
          } else {
            target = {
              alias: parent,
              name: permissionsDict[parent]?.name!,
              type: "group",
              children: [],
              optional: permissionsDict[parent]?.optional,
            };
          }
        }
      });
    });
  }

  return tree;
}

function buildChildrenAliasesChain(group: IPermissionsTree, res: string[] = []) {
  group.permissions.forEach((el) => {
    if (el.alias.toLowerCase().startsWith("fake")) {
      return;
    }
    res.push(el.alias);
  });
  group.groups.forEach((group) => {
    res.concat(buildChildrenAliasesChain(group, res));
  });
  return res;
}
