import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { updateChartHash } from "redux/modules/common/chart/thunks";
import { IChartTree } from "redux/modules/common/chart/types";

import SectionPlanModal from "../../Building/components/SectionPlanModal/SectionPlanModal";
import CreatePlanModal from "components/UI/_TODO/Expenditure/components/ProductIntervalCard/CreatePlanModal";

import { CHART_TREE_LVL } from "../constants";

export interface IUseChartCreatePlanModal {
  tree: IChartTree | undefined;
  defaultDate?: string;
}

export const useChartCreatePlanModal = ({ tree, defaultDate }: IUseChartCreatePlanModal) => {
  const [isOpen, setCretePlanModalOpen] = useState(false);
  const dispatch = useDispatch();

  const onPlanCreateSuccess = useCallback(() => {
    dispatch(updateChartHash());
    closeCretePlanModal();
  }, []);

  const closeCretePlanModal = useCallback(() => {
    setCretePlanModalOpen(false);
  }, []);

  const openCretePlanModalOpen = useCallback(() => {
    setCretePlanModalOpen(true);
  }, []);

  const createPlanModal = useMemo(() => {
    if (!tree) return null;
    return (
      <>
        {isOpen && tree.isSection ? (
          <SectionPlanModal
            isOpen
            isLsr={tree.lvl === CHART_TREE_LVL.LSR}
            sectionName={tree.name || ""}
            sectionId={tree.id}
            sectionBudget={tree.estimate_amount}
            onPlanCreateSuccess={onPlanCreateSuccess}
            onClose={closeCretePlanModal}
            defaultDate={defaultDate}
          />
        ) : null}
        {isOpen && !tree.isSection ? (
          <CreatePlanModal
            isOpen
            selectedWorkGroupId={tree.isGroup ? tree.id : null}
            loadSectionGroups={() => {}}
            onPlanCreateSuccess={onPlanCreateSuccess}
            handleClose={closeCretePlanModal}
            sectionId={tree.parent?.id || tree.id}
            product={{
              name: tree.name,
              id: tree.id,
            }}
            defaultDate={defaultDate}
          />
        ) : null}
      </>
    );
  }, [isOpen, tree, onPlanCreateSuccess, closeCretePlanModal]);

  return {
    createPlanModal,
    closeCretePlanModal,
    openCretePlanModalOpen,
  };
};
