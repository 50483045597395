import cn from "classnames";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";

import { addProducts, compareProduct } from "../../../../../redux/modules/common/building/requisition/reducer";

import LabeledSwitch from "components/UI/atoms/_TODO/LabeledSwitch";

import Actions from "./Actions/Actions";
import Table from "./Table/Table";

import useArrayItemsChecker from "hooks/useArrayItemsChecker";

import EmptyListIcon from "images/icons/EmptyListIconLazy";

import styles from "./index.module.scss";

const Products = ({ requisition, products, productsComments, isDraft, createOrder, permissions, onSuccess }) => {
  const dispatch = useDispatch();

  const [isViewModeMonitoring, setIsViewModeMonitoring] = useState(!permissions.viewRequisitionInProcessingMode);

  const productsChecker = useArrayItemsChecker(products, "id");

  const handleAddProducts = useCallback(
    (addedProducts) => compose(dispatch, addProducts)(requisition.id, addedProducts),
    [requisition.id]
  );

  const addVirtualProviders = useCallback(
    (addedProviders) => {
      if (!requisition) return;

      Object.entries(productsChecker.items).forEach(([productId, isChecked]) => {
        const existedProviders = products
          .find((product) => product.id === +productId)
          ?.requested_providers?.map((item) => item?.id);

        if (!isChecked) return;

        compose(dispatch, compareProduct)(requisition.id, productId, {
          requested_providers_ids: [...addedProviders, ...existedProviders],
        });
      });
    },
    [requisition, productsChecker.items, products, dispatch]
  );

  const handleCreateOrder = useCallback(() => {
    createOrder(
      Object.entries(productsChecker.items)
        ?.filter((el) => Boolean(el[1]))
        .map((item) => +item[0])
    );
    productsChecker.reset();
  }, [createOrder, productsChecker]);

  return (
    <div className={styles.container}>
      <Actions
        buildingId={requisition.building.id}
        requisitionIsDraft={isDraft}
        requisitionNotApprove={requisition.is_agreed}
        isViewModeMonitoring={isViewModeMonitoring}
        requisitionExecutor={requisition.executor}
        productsChecker={productsChecker}
        products={products}
        onCreateOrder={handleCreateOrder}
        onAddProducts={handleAddProducts}
        onAddVirtualProviders={addVirtualProviders}
        permissions={permissions}
      />
      {products.length !== 0 ? (
        <Table
          products={products}
          productsChecker={productsChecker}
          comments={productsComments}
          requisition={requisition}
          isRequisitionDraft={isDraft}
          isViewModeMonitoring={isViewModeMonitoring}
          permissions={permissions}
          onSuccess={onSuccess}
        />
      ) : (
        <div className={styles.emptyBlock}>
          <div className={styles.title}>Необходимо добавить минимум 1 товар для продолжения</div>
          <EmptyListIcon />
        </div>
      )}
      {!isDraft && permissions.viewRequisitionInProcessingMode && (
        <div className={cn(styles.productsHeaderTableAction, styles.monitoringSwitcherContainer)}>
          <LabeledSwitch label={"Мониторинг"} value={isViewModeMonitoring} onChange={setIsViewModeMonitoring} />
        </div>
      )}
    </div>
  );
};

export default React.memo(Products);
