import { message } from "antd";
import axios from "axios";
import { createSelector } from "reselect";

import { setWorkerEntity } from "../../_TODO/auth";

const moduleName = "organization";
const SET_PAGINATION = `${moduleName}/SET_PAGINATION`;
const SET_ENTITIES_LIST = `${moduleName}/SET_ENTITIES_LIST`;
const LOAD_ENTITIES_LIST = `${moduleName}/LOAD_ENTITIES_LIST`;
const SET_CURRENT_ENTITY = `${moduleName}/SET_CURRENT_ENTITY`;
const LOAD_CURRENT_ENTITY = `${moduleName}/LOAD_CURRENT_ENTITY`;
const ADD_ORGANIZATION = `${moduleName}/ADD_ORGANIZATION`;
const SET_TAXES = `${moduleName}/SET_TAXES`;
const LOADING_END = `${moduleName}/LOADING_END`;
const LOCK_ORGANIZATION = "organization/LOCK";
const SET_CONTACT_FACE = "organization/SET_CONTACT_FACE";
const SET_CONTACT_FACE_LOADING = "organization/SET_CONTACT_FACE_LOADING";

const initialState = {
  currentEntity: null,
  currentEntityLoading: true,
  tableData: {
    count: 1,
    list: [],
    results: [],
  },
  pagination: {
    params: { limit: 50, offset: 0 },
    page: 1,
  },
  taxes: [],
  sorting: null,
  loadingList: true,
  contactFace: {},
};

/*
  Reducer
*/

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...payload,
        },
      };
    }

    case LOAD_ENTITIES_LIST: {
      return {
        ...state,
        loadingList: true,
      };
    }

    case SET_ENTITIES_LIST: {
      return {
        ...state,
        tableData: {
          count: payload.count,
          list: payload.results,
          results: payload.results,
        },
        loadingList: false,
      };
    }

    case LOAD_CURRENT_ENTITY: {
      return {
        ...state,
        currentEntityLoading: true,
      };
    }

    case SET_CURRENT_ENTITY: {
      return {
        ...state,
        currentEntity: payload,
        currentEntityLoading: false,
      };
    }

    case SET_TAXES: {
      return {
        ...state,
        taxes: [...payload],
      };
    }

    case LOADING_END: {
      return {
        ...state,
        currentEntityLoading: false,
      };
    }
    case ADD_ORGANIZATION:
      return {
        ...state,
        tableData: { count: payload.count, results: [...state.tableData.results, ...payload.results] },
      };

    case LOCK_ORGANIZATION:
      const tempRes = [...state.tableData.results];
      const index = tempRes.findIndex((el) => el.id == payload.id);
      if (index < 0) return state;
      const tempItem = { ...tempRes[index], is_blocked: payload.status };
      tempRes[index] = tempItem;
      return {
        ...state,
        tableData: {
          ...state.tableData,
          list: tempRes,
          results: tempRes,
        },
      };

    case SET_CONTACT_FACE:
      return {
        ...state,
        contactFace: payload,
      };

    case SET_CONTACT_FACE_LOADING:
      return {
        ...state,
        contactFaceLoading: payload,
      };

    default: {
      return state;
    }
  }
};

/*
  Selectors
*/

export const stateSelector = (state) => state.organization;
export const loadingStateSelector = createSelector(stateSelector, (state) => state.loadingList);
export const sortingStateSelector = createSelector(stateSelector, (state) => state.sorting);
export const paginationStateSelector = createSelector(stateSelector, (state) => state.pagination);
export const dataStateSelector = createSelector(stateSelector, (state) => state.tableData);
export const curEntityStateSelector = createSelector(stateSelector, (state) => state.currentEntity);
export const curEntityLoadingStateSelector = createSelector(stateSelector, (state) => state.currentEntityLoading);
export const taxesStateSelector = createSelector(stateSelector, (state) => state.taxes);
export const resultsStateSelector = createSelector(stateSelector, (state) => state.tableData.results);

/*
  Action creators
*/

export const setPaginationState = (params, page) => {
  return {
    type: SET_PAGINATION,
    payload: { params, page },
  };
};

export const setEntitiesState = (value) => {
  return {
    type: SET_ENTITIES_LIST,
    payload: value,
  };
};

export const loadCurEntityState = () => {
  return {
    type: LOAD_CURRENT_ENTITY,
  };
};

export const loadingEnd = () => {
  return {
    type: LOADING_END,
  };
};

export const loadEntityList = () => {
  return {
    type: LOAD_ENTITIES_LIST,
  };
};

export const setCurEntityState = (value) => {
  return {
    type: SET_CURRENT_ENTITY,
    payload: value,
  };
};

export const setTaxesState = (value) => {
  return {
    type: SET_TAXES,
    payload: value,
  };
};

/*
  Thunks
*/

export const getEntitiesList = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (finallyCallback, paginationParams, filterParams = null, sorting = null) => {
    if (ge_cancel) ge_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        limit: 50,
      },
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch(loadEntityList());
      axios
        .get(`/entities/`, config)
        .then((res) => {
          dispatch(setEntitiesState(res.data));
        })
        .finally(() => {
          finallyCallback?.();
        });
    };
  };
})();
export const addLoadOrganization = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (entityId, paginationParams, filterParams = null, sorting = null) => {
    if (ge_cancel) ge_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
      },
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    return (dispatch) => {
      axios.get(`/entities/`, config).then(
        (res) => {
          dispatch({
            type: ADD_ORGANIZATION,
            payload: res.data,
          });
        },
        (err) => {
          console.error(err);
        }
      );
    };
  };
})();

export const getTaxes = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return () => {
    if (ge_cancel) ge_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    return (dispatch) => {
      axios.get(`/taxsystems/`, config).then(
        (taxes) => {
          dispatch(setTaxesState(taxes.data.results));
        },
        (err) => {
          console.error(err);
        }
      );
    };
  };
})();

export const deleteEntity = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (id, data, callback = null) => {
    if (ge_cancel) ge_cancel();
    const params = {
      ...data,
      is_removed: true,
    };
    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch(loadCurEntityState());
      axios.delete(`/entities/${id}/`, config).then(
        (res) => {
          callback && callback();
          message.success("Юридическое лицо успешно удалено");
        },
        (err) => {
          console.error(err);
        }
      );
    };
  };
})();

export const updateEntity = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (id, data, errCallback = null) => {
    if (ge_cancel) ge_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch(loadCurEntityState());
      axios.put(`/entities/${id}/`, data, config).then(
        (res) => {
          dispatch(setCurEntityState(res.data));
          dispatch(setWorkerEntity(res.data));
          message.success("Сохранено");
        },
        (err) => {
          console.error(err);
          if (errCallback) errCallback(err.response.data);
          dispatch(loadingEnd());
        }
      );
    };
  };
})();

export const saveEntity = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (data, resCallback = null, errCallback = null) => {
    if (ge_cancel) ge_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch(loadCurEntityState());
      axios.post(`/entities/`, data, config).then(
        (res) => {
          //dispatch(setCurEntityState(res.data));
          if (resCallback) resCallback();
          message.success("Сохранено");
        },
        (err) => {
          console.error(err);
          if (errCallback) errCallback(err.response.data);
          dispatch(loadingEnd());
        }
      );
    };
  };
})();

export const lockOrganization = (id, status) => (dispatch) => {
  axios
    .patch(`/entities/${id}/`, { is_blocked: status })
    .then((resp) =>
      dispatch({
        type: LOCK_ORGANIZATION,
        payload: {
          id,
          status,
        },
      })
    )
    .catch((err) => console.error(err));
};

export const getContactFace = (orgId) => (dispatch) => {
  dispatch({
    type: SET_CONTACT_FACE_LOADING,
    payload: true,
  });
  axios
    .get(`/entities/${orgId}/users/`)
    .then((resp) => {
      dispatch({
        type: SET_CONTACT_FACE,
        payload: resp.data.result.filter((el) => el.is_contact_user)[0],
      });
      dispatch({
        type: SET_CONTACT_FACE_LOADING,
        payload: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_CONTACT_FACE_LOADING,
        payload: false,
      });
    });
};

export const getEntity = (id, finallyCallback) => (dispatch) => {
  dispatch(loadCurEntityState());
  id &&
    axios
      .get(`/entities/${id}/`)
      .then((res) => {
        dispatch(setCurEntityState(res.data));
        dispatch({
          type: LOADING_END,
        });
      })
      .finally(() => finallyCallback?.());
  !id && dispatch(setCurEntityState({}));
};

export const editOrganization =
  (id, data, { finallyCallback }) =>
  (dispatch) => {
    axios
      .patch(`/entities/${id}/`, data)
      .then((resp) => {
        dispatch(setCurEntityState(resp.data));
        message.success("Сохранено");
      })
      .catch((err) => {
        message.error("Не сохранено");
      })
      .finally(() => {
        finallyCallback?.();
      });
  };

export const createOrganization = (data, requisites, callback, finallyCallback) => (dispatch) => {
  const requisites = data.general_entity?.requisite_set || [];
  axios
    .post(`/entities/`, data)
    .then((resp) => {
      message.success("Сохранено");
      callback && callback(resp.data.id);
      resp.data.id &&
        Array.isArray(requisites) &&
        requisites.forEach((requisit) => {
          axios.post(`/entities/${resp.data.id}/requisites/`, requisit).catch((err) => console.error(err));
        });
    })
    .catch((err) => {
      message.error("Не сохранено");
    })
    .finally(() => {
      finallyCallback?.();
    });
};
