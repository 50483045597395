import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartActionsSelector } from "redux/modules/common/chart/selectors";
import { editChartIntervalLength, shiftChartInterval } from "redux/modules/common/chart/thunks";
import { ChartActions } from "redux/modules/common/chart/types";

import { useChartUnitMultiplier } from "components/pages/Chart/hooks/useChartUnitMultiplier";
import { useRem } from "components/pages/Manufacturing/hooks/useRem";

import { discreteValue } from "utils/helpers/discreteValue";

export interface IUseIntervalEditingProps {
  intervalInitialsRem: { width: number; left: number };
  intervalWrapperRef?: React.RefObject<HTMLElement | null> | undefined;
  intervalId: number;
  projectId: number | string;
  isGroup?: boolean;
  isSectionPlan?: boolean;
  expenditureId: number;
  initialDateStart: string;
  initialDateEnd: string;
}

export const enum IntervalEditAnchor {
  HEAD = "head",
  MID = "mid",
  TAIL = "tail",
}

const muteDragIcon = (e: DragEvent) => {
  if (!e.dataTransfer) return;
  e.dataTransfer.effectAllowed = "move";
  e.dataTransfer.dropEffect = "move";
  const img = new Image();
  img.style.display = "none";
  e.dataTransfer.setDragImage(img, 0, 0);
};

export const useIntervalEditing = ({
  intervalInitialsRem,
  intervalWrapperRef,
  isGroup,
  isSectionPlan,
  expenditureId,
  intervalId,
  projectId,
  initialDateStart,
  initialDateEnd,
}: IUseIntervalEditingProps) => {
  const { REM } = useRem();
  const dispatch = useDispatch();
  const wasEdited = useRef(false);
  const clickOnIntervalPosition = useRef<{ x: number | null }>({ x: null });

  const [dateStart, setDateStart] = useState<string>(initialDateStart);
  const [dateEnd, setDateEnd] = useState<string>(initialDateEnd);

  const [intervalEditAnchor, setIntervalEditAnchor] = useState<IntervalEditAnchor | null>(null);

  const [intervalLeftRem, setIntervalLeftRem] = useState<number>(intervalInitialsRem.left);
  const [intervalWidthRem, setIntervalWidthRem] = useState<number>(intervalInitialsRem.width);

  const diagramFilters = useSelector(chartActionsSelector);
  const isIntervalEditingEnabled = diagramFilters[ChartActions.plans_editing_enabled];

  const unitMultiplier = useChartUnitMultiplier();

  const cancelIntervalEditing = useCallback(() => {
    setIntervalLeftRem(intervalInitialsRem.left);
    setIntervalWidthRem(intervalInitialsRem.width);
  }, [intervalInitialsRem]);

  const interrupt = useCallback(() => {
    wasEdited.current = true;
    clickOnIntervalPosition.current.x = null;
    setIntervalEditAnchor(null);
  }, [setIntervalEditAnchor]);

  useEffect(() => {
    const intervalElement = intervalWrapperRef?.current;
    if (!intervalEditAnchor || !intervalElement) return;
    const setSafeIntervalWidthRem = (w: number) => setIntervalWidthRem(Math.max(w, unitMultiplier));

    const intervalBounds = intervalWrapperRef.current?.getBoundingClientRect();
    const initialScrollPosition = document.querySelector(".diagram_calendar")?.scrollLeft || 0;
    let prevHandledX: number | undefined = undefined;

    const handleEnlargeInterval = (e: MouseEvent | DragEvent) => {
      const mouseX = e.x;
      if (clickOnIntervalPosition.current.x === null) {
        clickOnIntervalPosition.current.x = mouseX;
      }
      const discreteX = discreteValue(mouseX / REM, unitMultiplier);
      if ((prevHandledX && !Math.round(discreteX - prevHandledX)) || !mouseX) return;
      const currentScrollPosition = document.querySelector(".diagram_calendar")?.scrollLeft || 0;
      const scrollDelta = currentScrollPosition - initialScrollPosition;
      if (intervalEditAnchor === IntervalEditAnchor.HEAD) {
        const deltaPx = mouseX - intervalBounds.right + scrollDelta;
        const addedHeadDays = discreteValue(deltaPx / REM, unitMultiplier) / unitMultiplier;
        setSafeIntervalWidthRem(intervalInitialsRem.width + addedHeadDays * unitMultiplier);
        // Дата окончания – Head
        setDateEnd(moment(initialDateEnd).add(addedHeadDays, "day").format("YYYY-MM-DD"));
      } else if (intervalEditAnchor === IntervalEditAnchor.TAIL) {
        const deltaPx = mouseX - intervalBounds.left + scrollDelta;
        const addedTailDays = discreteValue(deltaPx / REM, unitMultiplier) / unitMultiplier;
        setIntervalLeftRem(intervalInitialsRem.left + addedTailDays * unitMultiplier);
        // Дата начала – Tail
        setDateStart(moment(initialDateStart).add(addedTailDays, "day").format("YYYY-MM-DD"));
        setSafeIntervalWidthRem(
          intervalInitialsRem.width + discreteValue(-deltaPx / REM + unitMultiplier, unitMultiplier)
        );
        setDateStart(moment(initialDateStart).add(addedTailDays, "day").format("YYYY-MM-DD"));
      } else if (intervalEditAnchor === IntervalEditAnchor.MID) {
        const deltaPx = mouseX - clickOnIntervalPosition.current.x + scrollDelta;
        const deltaDays = discreteValue(deltaPx / REM, unitMultiplier) / unitMultiplier;
        setIntervalLeftRem(intervalInitialsRem.left + deltaDays * unitMultiplier);
        setDateEnd(moment(initialDateEnd).add(deltaDays, "day").format("YYYY-MM-DD"));
        setDateStart(moment(initialDateStart).add(deltaDays, "day").format("YYYY-MM-DD"));
      }
      prevHandledX = discreteX;
    };
    window.addEventListener("mousemove", handleEnlargeInterval);
    window.addEventListener("mouseup", interrupt);
    wasEdited.current = false;
    return () => {
      window.removeEventListener("mousemove", handleEnlargeInterval);
      window.removeEventListener("mouseup", interrupt);
    };
  }, [
    intervalEditAnchor,
    REM,
    interrupt,
    intervalInitialsRem.width,
    intervalInitialsRem.left,
    initialDateStart,
    initialDateEnd,
    unitMultiplier,
    intervalWrapperRef,
  ]);

  const handleDragStartHead = useCallback((e: DragEvent) => {
    muteDragIcon(e);
    setIntervalEditAnchor(IntervalEditAnchor.HEAD);
  }, []);

  const handleDragStartMid = useCallback((e: DragEvent) => {
    muteDragIcon(e);
    setIntervalEditAnchor(IntervalEditAnchor.MID);
  }, []);

  const handleDragStartTail = useCallback((e: DragEvent) => {
    muteDragIcon(e);
    setIntervalEditAnchor(IntervalEditAnchor.TAIL);
  }, []);

  useEffect(() => {
    setDateEnd(initialDateEnd);
    setDateStart(initialDateStart);
  }, [initialDateStart, initialDateEnd]);

  useEffect(() => {
    setIntervalWidthRem(intervalInitialsRem.width);
    setIntervalLeftRem(intervalInitialsRem.left);
  }, [initialDateStart, initialDateEnd, intervalInitialsRem.left, intervalInitialsRem.width]);

  const editIntervalDates = useCallback(
    ({
      dateStart,
      dateEnd,
      successCallback,
      failCallback,
    }: {
      dateStart: string;
      dateEnd: string;
      successCallback?: () => void;
      failCallback?: () => void;
    }) => {
      if (!intervalId || !projectId) return;
      dispatch(
        editChartIntervalLength({
          projectId,
          intervalId,
          expenditureId,
          isGroup,
          isSectionPlan,
          dateStart,
          dateEnd,
          successCallback,
          failCallback,
        })
      );
    },
    [isGroup, expenditureId, intervalId, projectId, isSectionPlan]
  );

  const shiftIntervalDates = useCallback(
    ({
      dateStart,
      successCallback,
      failCallback,
    }: {
      dateStart: string;
      successCallback?: () => void;
      failCallback?: () => void;
    }) => {
      if (!intervalId || !projectId) return;
      const days = moment(dateStart).diff(initialDateStart, "days");
      dispatch(
        shiftChartInterval({
          intervalId,
          isGroup,
          successCallback,
          failCallback,
          days,
        })
      );
    },
    [isGroup, expenditureId, intervalId, projectId, isSectionPlan]
  );

  const failCallback = useCallback(() => {
    cancelIntervalEditing();
  }, [cancelIntervalEditing]);

  const handleDragEnd = useCallback(() => {
    const isInvalidEditing =
      moment(dateEnd).isBefore(moment(dateStart), "day") ||
      (moment(dateStart).isSame(initialDateStart) && moment(dateEnd).isSame(initialDateEnd));
    if (isInvalidEditing) {
      cancelIntervalEditing();
      return;
    }
    if (intervalEditAnchor === IntervalEditAnchor.MID) {
      shiftIntervalDates({
        dateStart,
        failCallback,
      });
    } else {
      editIntervalDates({
        dateStart,
        dateEnd,
        failCallback,
      });
    }
    setIntervalEditAnchor(null);
  }, [
    editIntervalDates,
    cancelIntervalEditing,
    dateStart,
    dateEnd,
    intervalEditAnchor,
    failCallback,
    initialDateStart,
    initialDateEnd,
  ]);

  useEffect(() => {
    if (intervalEditAnchor || !wasEdited.current) return;
    handleDragEnd();
    wasEdited.current = false;
  }, [intervalEditAnchor, handleDragEnd]);

  return {
    isIntervalEditingEnabled,
    intervalLeftRem,
    intervalWidthRem,
    handleDragStartHead,
    handleDragStartMid,
    handleDragStartTail,
    handleDragEnd,
  };
};
