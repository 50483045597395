import moment from "moment";
import React from "react";

import ObjectExtendedRowItem from "../../ui/ObjectExtendedRowItem/ObjectExtendedRowItem";
import ObjectExtendedSubRow from "../../ui/ObjectExtendedSubRow/ObjectExtendedSubRow";
import { useObjectMastering } from "./useObjectMastering";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import Calendar from "shared/ui/inputs/Calendar/Calendar";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./ObjectRowMasteringCell.module.scss";

const displayValue = (val: any) => ({
  title: transformDigitToFinancial(val ?? 0, { withCurrencySign: true, withFloat: true }),
  children: (
    <span className={styles.nums}>
      {transformDigitToFinancial(Math.round(+(val ?? 0)), { withCurrencySign: true, withFloat: false })}
    </span>
  ),
});

const data: any = {};

interface IProps {
  objectId: string | number;
}

const ObjectRowMasteringCell = ({ objectId }: IProps) => {
  const { mastering, endAt, changeDate, isMasteringLoading } = useObjectMastering(objectId);

  return (
    <ObjectExtendedSubRow
      className={styles.thirdRow}
      indicator={
        <div className={styles.date}>
          Прогноз освоения{" "}
          <Calendar
            disabled={isMasteringLoading}
            containerClassName={styles.calendar}
            value={endAt}
            setValue={changeDate}
            classNameSelect={styles.calendarSelect}
            format="D MMM YYYY"
            customIcon={
              <div className={styles.calendarIcon}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.74964 0.799948H12V12H0V0.799948H2.24984V0H2.99935V0.799948H9.00117V0H9.75068L9.74964 0.799948ZM2.24984 1.59977H0.750032V3.20005H11.2507V1.59977H9.75016V2.39971H9.00065V1.59977H2.99883V2.39971H2.24932L2.24984 1.59977ZM0.750032 11.2001H11.2507V3.99948H0.750032V11.2001ZM2.24984 7.20005V6.40023H2.99935V7.20005H2.24984ZM2.24984 8.79995V8H2.99935V8.79995H2.24984ZM2.24984 10.4002V9.60028H2.99935V10.4002H2.24984ZM4.50045 5.59977V4.79995H5.24919V5.59977H4.50045ZM4.50045 7.20005V6.40023H5.24919V7.20005H4.50045ZM4.50045 8.79995V8H5.24919V8.79995H4.50045ZM4.50045 10.4002V9.60028H5.24919V10.4002H4.50045ZM6.74977 5.59977V4.79995H7.49981V5.59977H6.74977ZM6.74977 7.20005V6.40023H7.49981V7.20005H6.74977ZM6.74977 8.79995V8H7.49981V8.79995H6.74977ZM6.74977 10.4002V9.60028H7.49981V10.4002H6.74977ZM8.99961 5.59977V4.79995H9.74913V5.59977H8.99961ZM8.99961 7.20005V6.40023H9.74913V7.20005H8.99961ZM8.99961 8.79995V8H9.74913V8.79995H8.99961Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          />
        </div>
      }
    >
      <ObjectExtendedRowItem name="Выполнение" percents={Math.round(mastering?.percent ?? 0)}></ObjectExtendedRowItem>
      <ObjectExtendedRowItem title={displayValue(data.plan_mastering).title} name="План:">
        {displayValue(mastering?.plan).children}
      </ObjectExtendedRowItem>
      <ObjectExtendedRowItem title={displayValue(data.fact_mastering).title} name="Факт:">
        {displayValue(mastering?.fact).children}
      </ObjectExtendedRowItem>
    </ObjectExtendedSubRow>
  );
};

export default ObjectRowMasteringCell;
