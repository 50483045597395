import cn from "classnames";
import React, { useCallback, useState } from "react";

import {
  IChartFactResource,
  IChartFactResourceCompletionType,
  IChartPlanResource,
} from "redux/modules/common/chart/types";

import ChartMaterialModal from "components/UI/_TODO/WorkOrMaterialsModals/ChartMaterialModal/ChartMaterialModal";

import type { IChartIntervalProps } from "../ChartInterval.typings";

import intervalStyles from "../ChartInterval.module.scss";
import styles from "./withResource.module.scss";

export interface IChartResourceIntervalProps
  extends Omit<
    IChartIntervalProps,
    "start" | "end" | "id" | "forwardRef" | "openModal" | "closeModal" | "intervalData" | "popupType"
  > {
  resource: IChartFactResource | IChartPlanResource;
}

function withResource(Base: React.FC<IChartIntervalProps>) {
  return ({ resource, ...props }: IChartResourceIntervalProps) => {
    const [isOpenModal, setIsOpenModal] = useState(false);

    const closeModal = useCallback(() => {
      setIsOpenModal(false);
    }, []);

    const openModal = useCallback(() => {
      setIsOpenModal(true);
    }, []);

    const resourceStatus: IChartFactResourceCompletionType | "plan" = (() => {
      // @ts-ignore
      if (Number(resource.todo) > 0) return "todo";
      // @ts-ignore
      if (Number(resource.to_pay) > 0) return "to_pay";
      // @ts-ignore
      if (Number(resource.accepted) > 0) return "accepted";
      // @ts-ignore
      if (Number(resource.issued) > 0) return "issued";
      // @ts-ignore
      if (Number(resource.on_stock) > 0) return "on_stock";
      // @ts-ignore
      if (Number(resource.ordered) > 0) return "ordered";
      return "plan";
    })();

    return (
      <Base
        {...props}
        start={resource.date}
        end={resource.date}
        openModal={openModal}
        closeModal={closeModal}
        intervalData={resource}
        // @ts-ignore
        popupType={resource.exp_type}
        children={
          <>
            {props.children}
            <div className={cn(styles.resource, styles[resourceStatus], intervalStyles.ticketShape)} />
          </>
        }
        afterContent={
          <>
            {props.afterContent}
            {isOpenModal && (
              <ChartMaterialModal
                isOpen
                onClose={closeModal}
                // name={actualMaterial?.data?.name}
                objectId={+props.projectId}
                expId={resource.exp_id}
                start_date={resource.date}
                end_date={resource.date}
                intervalType={resource.exp_type}
              />
            )}
          </>
        }
      />
    );
  };
}

export default withResource;
