import cn from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  expendituresSubMaterialsLoadingSelector,
  expendituresSubMaterialsSelector,
  getExpenditureSubMaterials,
} from "redux/modules/common/building/sections/sections";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { IPlanFactVolumeExpenditure } from "pages/PlanFact/PlanFactVolume/model/types";

import PlanfactVolumeDayCell from "./PlanfactVolumeDayCell";
import Popup from "./Popup/Popup";
import WorkersPlanFactVolume from "./Workers/WorkersPlanFactVolume";
import SpecificationModal from "entities/SpecificationModal/SpecificationModal";
import BlueLabel from "shared/ui/dataDisplay/BlueLabel/BlueLabel";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { prepareDaysForPlanfactVolume } from "./utils";
import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";

import styles from "./PlanfactVolumeExpenditure.module.scss";

type ExpenditureLocalType = Omit<IPlanFactVolumeExpenditure["expenditures"][0], "number" | "justification"> & {
  justification?: string;
  number?: number;
};

interface IProps {
  expenditure: ExpenditureLocalType;
  nestedExpenditures?: ExpenditureLocalType[];
  startAt: string;
  daysCount?: 7 | 5;
}

const PlanFactVulmeNestedExpenditure: React.FC<IProps> = ({ expenditure, startAt, daysCount = 7 }) => {
  const objectId = useObjectId();
  const days = React.useMemo(() => {
    return prepareDaysForPlanfactVolume(daysCount, startAt, expenditure.days);
  }, [startAt, daysCount, expenditure.days]);

  const rowClassName = cn(styles.row, { [styles.row5days]: daysCount === 5 });

  const [isOpenJustification, setIsOpenJustification] = React.useState(false);

  const dispatch = useDispatch();
  const subMaterialsAreLoading = useSelector(expendituresSubMaterialsLoadingSelector);
  const subMaterials = useSelector(expendituresSubMaterialsSelector) as any;

  const onClickByJustification = React.useCallback(() => {
    setIsOpenJustification(true);
    if (!subMaterials[expenditure.id]) {
      dispatch(getExpenditureSubMaterials(objectId, expenditure.id));
    }
  }, [objectId, expenditure.id, subMaterials]);

  const closeJustification = React.useCallback(() => setIsOpenJustification(false), []);

  const measure = sliceTextByConstraint(expenditure.measure, 9);

  return (
    <div className={cn(rowClassName, styles.nestedRow)} key={expenditure.id}>
      <div></div>
      <TableReusableCell isFont13={false}>{expenditure.number}</TableReusableCell>
      <TableReusableCell isFont13={false} className={styles.mainCell}>
        <div className={styles.name} title={expenditure.name}>
          {expenditure.name}
        </div>
        <div className={styles.bottom}>
          <BlueLabel
            className={cn(styles.justification, styles.justificationOpened)}
            blueFont
            onClick={onClickByJustification}
          >
            {expenditure.justification}
          </BlueLabel>
          <WorkersPlanFactVolume workers={expenditure.workers} />
        </div>
      </TableReusableCell>
      <TableReusableCell isFont13={false}>
        <Popup
          percents={expenditure.percent_total_completed}
          count={expenditure.count}
          countCompleted={expenditure.count_total_completed}
        />
      </TableReusableCell>
      <TableReusableCell isFont13={false} isCentered>
        {measure}
      </TableReusableCell>
      <TableReusableCell isFont13={false} className={styles.bordered} isCentered>
        {expenditure.period_plan}
      </TableReusableCell>
      {days?.map((elem, index) => (
        <PlanfactVolumeDayCell key={`${elem.date}_${index}`} {...elem} />
      ))}
      <TableReusableCell isFont13={false} className={styles.bordered} isCentered>
        {expenditure.period_fact}
      </TableReusableCell>
      <SpecificationModal
        name={expenditure.justification}
        isOpen={isOpenJustification}
        onClose={closeJustification}
        subMaterials={subMaterials[expenditure.id]}
        isLoading={subMaterialsAreLoading}
      />
    </div>
  );
};

export default React.memo(PlanFactVulmeNestedExpenditure);
