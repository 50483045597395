import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import { scrollTable } from "./utils";

import {
  setTableScrollPositionAction,
  ks6aTableScrollPositionSelector,
} from "../../../../../../../redux/modules/common/ks6a/ks6a";
import {GEAR_COEFFICIENT, TABLE_INITIAL_SCROLL_PERCENT} from '../../constants'


const useTableScroll = (containerRef: React.RefObject<HTMLDivElement>) => {
  const dispatch = useDispatch();
  const scrollPosition = useSelector(ks6aTableScrollPositionSelector);

  useEffect(() => {
    const tableScrollableElement = containerRef.current;
    if (!tableScrollableElement) return;

    const debouncedHandler = debounce(() => {
      if (!tableScrollableElement) return;
      dispatch(
        setTableScrollPositionAction({
          eventFrom: "table",
          percent:
            scrollPosition.eventFrom === "table"
              ? (tableScrollableElement.scrollLeft / (tableScrollableElement.scrollWidth * GEAR_COEFFICIENT)) * 100
              : scrollPosition.percent,
        })
      );
    }, 50);

    scrollPosition.eventFrom === 'slider' && scrollTable(tableScrollableElement, Math.min(100, scrollPosition.percent * GEAR_COEFFICIENT))
    
    const handleTableScroll = (e: Event) => {
      if (!tableScrollableElement) return;
      debouncedHandler();
      requestAnimationFrame(() => {
        tableScrollableElement.scrollLeft = (e.target as Element)?.scrollLeft;
      });
    };

    tableScrollableElement.addEventListener("scroll", handleTableScroll);
    return () => {
      tableScrollableElement?.removeEventListener("scroll", handleTableScroll);
    };
  }, [containerRef.current, scrollPosition]);

  useEffect(() => {
    if (!containerRef.current) return;
    dispatch(setTableScrollPositionAction({eventFrom: 'slider', percent: TABLE_INITIAL_SCROLL_PERCENT}))
  }, [containerRef.current]);
};

export default useTableScroll;
