import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { actingActions } from "redux/modules/common/building/journal/acting/actions";
import {
  actingExpendituresSelector,
  actingIntersectingExpendituresSelector,
  actingSectionsSelector,
} from "redux/modules/common/building/journal/acting/selectors";
import { ISelectedActingIds, ISelectedSectionInActing } from "redux/modules/common/building/journal/acting/types";

import JournalExcludeKsModal from "../JournalExcludeKsModal/JournalExcludeKsModal";
import JournalActingModalContainer from "./JournalActingModalContainer";
import JournalKs2ModalRow from "./Rows/JournalKs2ModalRow/JournalKs2ModalRow";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";

import { useCreateKs2 } from "../hooks/useCreateKs2";

import styles from "./JournalActingModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  estimateId: number;
  selectedSections: ISelectedSectionInActing[];
  selectedCount: number;
  selectedIds: ISelectedActingIds;
  isLoading?: boolean;
}

const JournalKs2Modal: React.FC<IProps> = ({
  isOpen,
  onClose,
  estimateId,
  selectedSections,
  selectedCount,
  selectedIds,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const {
    actData,
    actNumberHandler,
    dateEndHandler,
    dateStartHandler,
    submitHandler,
    submitAfterExcluding,
    isShowSubmit,
  } = useCreateKs2(selectedSections, selectedIds, isOpen);
  const intersectingExpenditures = useSelector(actingIntersectingExpendituresSelector);

  const closeExcludingModal = React.useCallback(() => {
    dispatch(actingActions.setIntersectingExpenditures([]));
  }, []);

  const excludeExpenditureFromCreatingKs = React.useCallback((expId: number, parentId: number) => {
    dispatch(actingActions.selectExpenditure(expId, false, parentId, estimateId));
  }, []);

  React.useEffect(() => {
    if (!selectedCount) {
      onClose();
    }
  }, [selectedCount]);

  const closeExcludingAndAllModal = React.useCallback(() => {
    onClose();
    closeExcludingModal();
  }, []);

  return (
    <>
      {isLoading && (
        <div className={styles.loading}>
          <Spinner isStatic />
        </div>
      )}
      <JournalActingModalContainer
        isOpen={isOpen && !intersectingExpenditures?.length}
        onClose={onClose}
        count={selectedCount}
        actNumber={actData.number}
        onChangeAct={actNumberHandler}
        dateEnd={actData.dateEnd}
        dateStart={actData.dateStart}
        onChangeDateStart={dateStartHandler}
        onChangeDateEnd={dateEndHandler}
        onSubmit={submitHandler}
        isShowSubmit={isShowSubmit}
      >
        {selectedSections?.map((el) =>
          el ? <JournalKs2ModalRow key={el.id} section={el} estimateId={estimateId} /> : null
        )}
      </JournalActingModalContainer>
      <JournalExcludeKsModal
        isOpen={!!intersectingExpenditures?.length}
        onClose={closeExcludingAndAllModal}
        expenditures={intersectingExpenditures}
        onExcludeExpenditure={excludeExpenditureFromCreatingKs}
        onSubmit={closeExcludingModal}
      />
    </>
  );
};

export default React.memo(JournalKs2Modal);
