import React from "react";
import { TableRow } from "./TableRow/TableRow";
import { classJoin } from "./utils/classJoin";
import { Controls } from "./Controls/Controls";
import { ifMobile } from "./utils/ifMobile";

export class TotoRowTable extends React.PureComponent {
  state = {
    extraMobileColumn: null
  };

  disableSorting = (() => {
    let disableArr = [];
    return {
      subscribe: (func) => disableArr.push(func),
      disable: (exept) => disableArr.forEach((func) => func !== exept && func())
    };
  })();

  render() {
    const {
      isLoading,
      data,
      children,
      tableClass,
      headerClass,
      headerSortClass,
      rowClass,
      cellClass,
      pagination,
      paginationHide,
      limitChange,
      pageChange,
      limiter,
      paginator,
      containerClass,
      customPaginator,
      customLimiter,
      containerTableClass,
      containerTbodyClass,
      keyProp = null,
      customLoader = null,
      isMobileView = false,
      onRowClick = () => {
      },
      onRowMouseEnter = () => {
      },
      onRowMouseDown = () => {
      },
      tableStyle = {tableLayout: "fixed"},
      noDataPlate,
      hiddenBlock,
      changeSorting = (val) => `new sorting is ${val}`,
      controlsNoPadding,
      groupByFunc = null,
      hiddenBlockOpen,
      hideHeader = false,
      colspanCount,
      hiddenBlockOpenFunc
    } = this.props;

    const rowNum = (() => {
      let index = 0;
      return () => (pagination ? pagination.params.offset : 0) + (index += 1);
    })();

    const filteredChildren = children.filter((child) => {
      if (child) return child;
    });

    const headers = React.Children.map(children, (child) => {
      if (child) {
        return React.cloneElement(child, {
          headerClass,
          headerSortClass,
          disableSorting: this.disableSorting,
          changeSorting,
          extraMobile: this.state.extraMobileColumn
        });
      }
    }).filter((child) => ifMobile(isMobileView, child.props));

    const headersRows = headers.reduce((result, header) => {
      if (!result[header.props.row]) result[header.props.row] = [];
      result[header.props.row].push(header);
      return result;
    }, []);

    const row = (row, index) => (
      <TableRow
        key={row[keyProp] || index}
        rowDataId={rowNum()}
        row={row}
        headers={children}
        rowClass={Array.isArray(rowClass) ? classJoin(rowClass, row) : rowClass}
        cellClass={Array.isArray(cellClass) ? classJoin(cellClass) : cellClass}
        onRowClick={(e) => onRowClick && onRowClick(row, false, e)}
        onRowMouseEnter={onRowMouseEnter}
        onRowMouseDown={onRowMouseDown}
        isMobileView={isMobileView}
        prevRow={index > 0 ? data[index - 1] : null}
        extraMobileColumn={this.state.extraMobileColumn}
        hiddenBlock={hiddenBlock}
        hiddenBlockOpen={hiddenBlockOpen}
        hiddenBlockOpenFunc={hiddenBlockOpenFunc}
        colspanCount={colspanCount}
        idx={index}
      />
    );

    return filteredChildren ? (
      <div className={Array.isArray(containerClass) ? classJoin(containerClass) : containerClass}>
        <div
          className={
            Array.isArray(containerTableClass)
              ? classJoin(containerTableClass)
              : containerTableClass
          }
        >
          {isMobileView ? (
            <select
              placeholder={"select extra column"}
              onChange={(e) => this.setState({ extraMobileColumn: e.target.value })}
            >
              {React.Children.map(children, (child) => {
                if (child) {
                  return (
                    !child.props.mainMobile && (
                      <option
                        selected={child.props.extraMobile === child.props.data}
                        value={child.props.data}
                      >
                        {filteredChildren}
                      </option>
                    )
                  );
                }
              })}
            </select>
          ) : null}
          <table
            style={tableStyle || null}
            className={Array.isArray(tableClass) ? classJoin(tableClass) : tableClass}
          >
            {
              <thead
                style={
                  hideHeader ? { visibility: "collapse" } : {}
                }
              >
              {headersRows.length ? (
                headersRows.map((row, idx) => <tr key={idx}>{row}</tr>)
              ) : (
                <tr>{headers}</tr>
              )}
              </thead>
            }
            <tbody
              className={Array.isArray(containerTbodyClass) ? classJoin(containerTbodyClass) : null}
            >
            {!isLoading &&
              data &&
              (groupByFunc
                ? Object.values(data.reduce(groupByFunc, {})).map((group) => (
                  <>
                    <tr
                      className={Array.isArray(rowClass) ? classJoin(rowClass, row) : rowClass}
                    >
                      <td
                        colSpan={children.length}
                        style={{ textAlign: "left", fontWeight: "500" }}
                      >
                        {group.name}
                      </td>
                    </tr>
                    {group.items.map(row)}
                  </>
                ))
                : data.map(row))}
            {!isLoading && data && data.length === 0 ? (
              <tr>
                <td colSpan={this.props.children.length} style={{ textAlign: "center" }}>
                  {noDataPlate || "Нет данных"}
                </td>
              </tr>
            ) : null}
            </tbody>
          </table>
        </div>
        {isLoading && (
          <div style={{ marginTop: "1rem" }}>{customLoader ? customLoader : "loading"}</div>
        )}
        {!paginationHide && pagination && (
          <Controls
            ignorePageChange={isLoading && !data}
            pagination={pagination}
            limitChange={limitChange}
            pageChange={data !== null ? pageChange : () => false}
            limiter={limiter || { values: [10, 20, 30, 50] }}
            paginator={paginator || { maxValue: 5 }}
            customPaginator={customPaginator || null}
            customLimiter={customLimiter || null}
            controlsNoPadding={controlsNoPadding || false}
          />
        )}
      </div>
    ) : null;
  }
}
