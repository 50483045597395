import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { userSelector } from "redux/modules/_TODO/auth";
import { journalExecutionActions } from "redux/modules/common/building/journal/journalExecution/actions";
import {
  acceptRemarkIsLoadingSelector,
  deleteRemarkIsLoadingSelector,
  deleteRemarkIsSuccessSelector,
  detailRemarkInfoIsLoadingSelector,
  detailRemarkInfoResultsSelector,
  editRemarkIsLoadingSelector,
  editRemarkIsSuccessSelector,
  reportInvalidateKeyInJournalExecSelector,
  resolveOrReturnRemarkIsLoadingSelector,
  resolveOrReturnRemarkIsSuccessSelector,
} from "redux/modules/common/building/journal/journalExecution/selectors";
import {
  deleteJournalDeliveryRemark,
  deleteJournalExecutionRemark,
  getJournalDeliveryExecutionDetailRemarkInfo,
  getJournalExecutionDetailRemarkInfo,
  postJournalDeliveryAcceptRemark,
  postJournalDeliveryResolveRemark,
  postJournalDeliveryReturnRemark,
  postJournalExecutionAcceptRemark,
  postJournalExecutionResolveRemark,
  postJournalExecutionReturnRemark,
  putJournalDeliveryEditRemark,
  putJournalExecutionEditRemark,
} from "redux/modules/common/building/journal/journalExecution/thunks";
import { getDetail } from "redux/modules/common/building/object/nowObject";

import { useUIIndicator } from "../../../../../hooks/uiIndicators/useUIIndicator";

import { MODULES_ENUM } from "../../../../../types/enums/ModulesEnum";
import { ICreateAndEditRemarkFieldValues } from "../types/types";
import { IFile, IFilesBase64Format } from "types/interfaces/Files";
import { IEmployee } from "types/personsTypes";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import getShortFullName from "utils/formatters/getShortFullName";
import { serializeFiles } from "utils/serializeFiles";

export interface IProps {
  isOpen: boolean;
  currentRemarkId: number;
  formRef: React.RefObject<HTMLFormElement | null>;
  handleBack: () => void;
  closeModal: () => void;
  remarksCount: number;
  isDelivery?: boolean;
}

export const useRemarkDetailInfo = ({
  isOpen,
  currentRemarkId,
  formRef,
  handleBack,
  closeModal,
  remarksCount,
  isDelivery,
}: IProps) => {
  const dispatch = useDispatch();
  const params = useParams<IRouterParamsWithObjectId>();
  const buildingId = params.objectId;

  const detailRemarkInfo = useSelector(detailRemarkInfoResultsSelector);
  const isRemarkDetailInfoLoading = useSelector(detailRemarkInfoIsLoadingSelector);
  const userInfo = useSelector(userSelector);
  const isEditLoading = useSelector(editRemarkIsLoadingSelector);
  const isEditSuccess = useSelector(editRemarkIsSuccessSelector);
  const isDeleteSuccess = useSelector(deleteRemarkIsSuccessSelector);
  const isDeleteLoading = useSelector(deleteRemarkIsLoadingSelector);
  const isResolveOrReturnSuccess = useSelector(resolveOrReturnRemarkIsSuccessSelector);
  const isResolveOrReturnLoading = useSelector(resolveOrReturnRemarkIsLoadingSelector);
  const isAcceptLoading = useSelector(acceptRemarkIsLoadingSelector);
  const reportInvalidateKey = useSelector(reportInvalidateKeyInJournalExecSelector);

  const [isShowEditRemark, setIsShowEditRemark] = useState(false);
  const [selectedWorker, setSelectedWorker] = useState<Partial<IEmployee> | null>(null);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [isResetFiles, setIsResetFiles] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isShowReportFields, setIsShowReportFields] = useState(false);

  const [reportFiles, setReportFiles] = useState<IFile[]>([]);
  const [reportDescription, setReportDescription] = useState("");

  const userId = userInfo?.id;
  const timeDifferenceInMinutes = moment.duration(moment(new Date()).diff(detailRemarkInfo?.created_at)).asMinutes();

  const isRemarkAuthor = userId === detailRemarkInfo?.author;
  const isRemarkResponsible = userId === detailRemarkInfo?.responsible;
  const canEditRemark = isRemarkAuthor && +timeDifferenceInMinutes < 10;
  const isResolveOrReturnButtonDisabled = isResolveOrReturnLoading || reportFiles.length <= 0 || !reportDescription;

  const { deleteIndicator: deleteCreatedRemarkIndicator } = useUIIndicator({
    data: {
      remark_id: currentRemarkId,
    },
    module: MODULES_ENUM.OBJECTS /* @ts-ignore */,
    type: "remark_created",
  });

  const { deleteIndicator: deleteReviewRemarkIndicator } = useUIIndicator({
    data: {
      remark_id: currentRemarkId,
    },
    module: MODULES_ENUM.OBJECTS /* @ts-ignore */,
    type: "remark_review",
  });

  useEffect(() => {
    if (!detailRemarkInfo?.responsible) return;

    setSelectedWorker({ id: detailRemarkInfo.responsible, short_full_name: detailRemarkInfo.responsible_name });
  }, [isShowEditRemark]);

  const editRemarkInitialValues = {
    name: detailRemarkInfo?.name || `Замечание №${detailRemarkInfo?.id}`,
    description: detailRemarkInfo?.description || "",
    regulations: detailRemarkInfo?.regulations || "",
    resolve_date: moment(detailRemarkInfo?.resolve_date).toISOString(),
  };

  const serializedFiles = useMemo(
    () =>
      /* @ts-ignore */
      detailRemarkInfo?.files?.length && detailRemarkInfo?.files?.length > 0 && serializeFiles(detailRemarkInfo?.files),
    [detailRemarkInfo?.files]
  );

  useEffect(() => {
    if (!serializedFiles) return;
    /* @ts-ignore */
    setFiles(serializedFiles);
  }, [serializedFiles]);

  useEffect(() => {
    if (!currentRemarkId || !isOpen) return;

    if (isDelivery) {
      dispatch(getJournalDeliveryExecutionDetailRemarkInfo(buildingId, currentRemarkId as number));
    } else {
      dispatch(getJournalExecutionDetailRemarkInfo(buildingId, currentRemarkId as number));
    }
  }, [currentRemarkId, reportInvalidateKey, isOpen]);

  const handleOpenEditRemark = () => {
    setIsShowEditRemark(true);
  };

  const editRemark = (
    values: ICreateAndEditRemarkFieldValues,
    oldFiles: IFile[],
    arrayFilesInBase64?: IFilesBase64Format[]
  ) => {
    if (!selectedWorker?.id || !detailRemarkInfo) return;
    const editThunk = isDelivery ? putJournalDeliveryEditRemark : putJournalExecutionEditRemark;

    dispatch(
      editThunk(buildingId, currentRemarkId, {
        ...values,
        resolve_date: moment(values.resolve_date).format("YYYY-MM-DD"),
        responsible: selectedWorker.id,
        files: oldFiles,
        uploaded_files: arrayFilesInBase64,
        author: detailRemarkInfo.author,
      })
    );
  };

  const onSubmit = (values: ICreateAndEditRemarkFieldValues) => {
    /* @ts-ignore */
    const oldFiles = files?.filter((item) => item?.originalname !== undefined);
    /* @ts-ignore */
    const newFiles = files?.filter((item) => item?.originalname === undefined);

    getArrayFilesInBase64(newFiles)
      .then((arrayFilesInBase64) => {
        editRemark(values, oldFiles, arrayFilesInBase64);
      })
      .catch(() => {
        editRemark(values, oldFiles);
      });
  };

  const handleOpenTable = () => {
    setIsTableOpen(true);
  };

  const handleCloseTable = () => {
    setIsTableOpen(false);
    setSelectedWorker(null);
  };

  const handleRemoveSelected = () => {
    setSelectedWorker(null);
  };

  const handleSelectWorker = (worker: IEmployee) => {
    const shortFullName = getShortFullName(worker.last_name, worker.first_name, worker.middle_name);

    setSelectedWorker({ ...worker, short_full_name: shortFullName });
    setIsTableOpen(false);
  };

  const resetFormWithTimeout = () => {
    setTimeout(() => {
      if (!formRef.current) return;

      formRef.current.dispatchEvent(new Event("reset", { cancelable: true, bubbles: true }));
      setIsResetFiles(true);
      setFiles([]);
      setSelectedWorker(null);
    }, 500);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getDetail(buildingId));
      setIsResetFiles(false);
    }
    resetFormWithTimeout();
  }, [isOpen]);

  const handleCloseEditForm = () => {
    setIsShowEditRemark(false);
  };

  useEffect(() => {
    if (!isEditSuccess) return;

    handleCloseEditForm();
    resetFormWithTimeout();
  }, [isEditSuccess]);

  useEffect(() => {
    if (isDeleteLoading) return;

    setIsOpenDeleteModal(false);
  }, [isDeleteLoading]);

  useEffect(() => {
    if (!isDeleteSuccess) return;

    dispatch(journalExecutionActions.setIsDeleteRemarkSuccess(false));

    if (remarksCount === 1) {
      closeModal();
    }

    handleCloseEditForm();
    handleBack();
  }, [isDeleteSuccess]);

  const handleDeleteRemark = () => {
    const deleteThunk = isDelivery ? deleteJournalDeliveryRemark : deleteJournalExecutionRemark;
    dispatch(deleteThunk(buildingId, currentRemarkId));
  };

  const handleOpenDeleteModal = () => {
    setIsOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const handleOpenReportFields = () => {
    setIsShowReportFields(true);
  };

  const handleCloseReportFields = () => {
    setIsShowReportFields(false);
    setReportDescription("");
    setReportFiles([]);
  };

  useEffect(() => {
    if (!isResolveOrReturnSuccess) return;

    handleCloseReportFields();
  }, [isResolveOrReturnSuccess]);

  const resolveRemark = (arrayFilesInBase64?: IFilesBase64Format[]) => {
    const resolveThunk = isDelivery ? postJournalDeliveryResolveRemark : postJournalExecutionResolveRemark;
    dispatch(
      resolveThunk(
        buildingId,
        currentRemarkId,
        {
          resolve_comment: reportDescription,
          files: arrayFilesInBase64,
        },
        isRemarkAuthor ? deleteReviewRemarkIndicator : isRemarkResponsible ? deleteCreatedRemarkIndicator : undefined
      )
    );
  };

  const returnRemark = (arrayFilesInBase64?: IFilesBase64Format[]) => {
    if (isDelivery) {
      dispatch(
        postJournalDeliveryReturnRemark(
          buildingId,
          currentRemarkId,
          {
            check_comment: reportDescription,
            files: arrayFilesInBase64,
          },
          isRemarkAuthor ? deleteReviewRemarkIndicator : isRemarkResponsible ? deleteCreatedRemarkIndicator : undefined
        )
      );
    } else {
      dispatch(
        postJournalExecutionReturnRemark(
          buildingId,
          currentRemarkId,
          {
            check_comment: reportDescription,
            files: arrayFilesInBase64,
          },
          isRemarkAuthor ? deleteReviewRemarkIndicator : isRemarkResponsible ? deleteCreatedRemarkIndicator : undefined
        )
      );
    }
  };

  const handleSubmitAfterFillReportForm = (sendReport: (arrayFilesInBase64?: IFilesBase64Format[]) => void) => {
    getArrayFilesInBase64(reportFiles)
      .then((arrayFilesInBase64) => {
        sendReport(arrayFilesInBase64);
      })
      .catch(() => {
        sendReport();
      });
  };

  const acceptRemark = () => {
    const acceptThunk = isDelivery ? postJournalDeliveryAcceptRemark : postJournalExecutionAcceptRemark;
    dispatch(acceptThunk(buildingId, currentRemarkId, deleteReviewRemarkIndicator));
  };

  return {
    detailRemarkInfo,
    isRemarkDetailInfoLoading,
    serializedFiles,
    isRemarkAuthor,
    isRemarkResponsible,
    canEditRemark,
    isShowEditRemark,
    handleOpenEditRemark,
    onSubmit,
    editRemarkInitialValues,
    handleOpenTable,
    handleCloseTable,
    selectedWorker,
    handleRemoveSelected,
    handleSelectWorker,
    files,
    setFiles,
    isResetFiles,
    isEditLoading,
    handleCloseEditForm,
    handleDeleteRemark,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    isOpenDeleteModal,
    isTableOpen,
    isShowReportFields,
    handleOpenReportFields,
    handleCloseReportFields,
    handleSubmitAfterFillReportForm,
    reportDescription,
    setReportDescription,
    reportFiles,
    setReportFiles,
    resolveRemark,
    isResolveOrReturnButtonDisabled,
    returnRemark,
    acceptRemark,
    isAcceptLoading,
  };
};
