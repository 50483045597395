import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  analyticsFulfillmentDataSelector,
  analyticsInvalidateKeySelector,
  analyticsLoadingsSelector,
  analyticsScopeSelector,
} from "../../../../../redux/modules/common/analytics/selectors";
import { loadAnalyticsFulfillment } from "../../../../../redux/modules/common/analytics/thunks";

import Icon from "../../../../../_LEGACY/UI/_LEGACY_Icon/Icon";
import AnalyticsFulfillmentItem from "./components/AnalyticsFulfillmentItem/AnalyticsFulfillmentItem";

import { useTypedSelector } from "../../../../../app/store/typedUseSelector";
import useAnalyticsScopeChange from "../../useAnalyticsScopeChange";
import Island, { IIslandElement } from "../Island/Island";
import AnalyticsChartLegend from "../charts/AnalyticsChartLegend/AnalyticsChartLegend";

import { FULFILLMENT_COLORS } from "./constants";

import { makeLinearGradient } from "./utils";

import calendarIcon from "images/icons/calendarIcon.svg";

import commonStyles from "../../Analytics.module.scss";
import styles from "./AnalyticsFulfillment.module.scss";

const AnalyticsFulfillment: React.FC<IIslandElement> = ({ islandParentRef }) => {
  const dispatch = useDispatch();
  const fulfillmentData = useTypedSelector(analyticsFulfillmentDataSelector);
  const invalidateKey = useTypedSelector(analyticsInvalidateKeySelector);
  const isLoading = useTypedSelector(analyticsLoadingsSelector)["fulfillment"];
  const selectedScope = useTypedSelector(analyticsScopeSelector);

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(loadAnalyticsFulfillment(abortController.signal));
    return () => {
      abortController.abort();
    };
  }, [invalidateKey]);

  const currentDateLabel = useMemo(() => moment().format("DD/MM/YYYY"), []);

  const isEmpty = useMemo(
    () => !fulfillmentData || Object.values(fulfillmentData).every((x) => !x.length),
    [fulfillmentData]
  );

  const { onChangeProject, onChangeLsr, onChangeSection } = useAnalyticsScopeChange();

  return (
    <Island
      heading={
        <>
          <h3 className={commonStyles.islandHeading}>Выполнение</h3>{" "}
          <div className={styles.date}>
            <span>{currentDateLabel}</span>
            <Icon icon={calendarIcon} />
          </div>
        </>
      }
      className={styles.fulfillmentIsland}
      isLoading={isLoading}
      isEmpty={isEmpty}
      islandParentRef={islandParentRef}
    >
      <div className={styles.body}>
        {fulfillmentData.works?.map((work, index) => (
          <AnalyticsFulfillmentItem fulfillment={work} variant={"work"} key={`${work.name}_${index}`} />
        ))}
        {fulfillmentData.sections?.map((section, index) => (
          <AnalyticsFulfillmentItem
            fulfillment={section}
            variant={"section"}
            key={`${section.name}_${index}`}
            onClick={() =>
              !!selectedScope?.lsr ? onChangeSection(section.section_id) : onChangeLsr(section.section_id)
            }
          />
        ))}
        {fulfillmentData.buildings?.map((building, index) => (
          <AnalyticsFulfillmentItem
            fulfillment={building}
            variant={"building"}
            key={`${building.name}_${index}`}
            onClick={() => onChangeProject(building.building_id)}
          />
        ))}
      </div>
      <AnalyticsChartLegend
        centered
        className={styles.fulfillmentLegend}
        columns={3}
        payload={[
          {
            value: "Факт",
            type: "circle",
            color: makeLinearGradient(FULFILLMENT_COLORS.RED, FULFILLMENT_COLORS.GREEN, 100),
          },
          { value: "План", type: "circle", color: FULFILLMENT_COLORS.PLAN } /* @ts-ignore */,
          { value: "Всего", type: "circle", outline: FULFILLMENT_COLORS.PLAN },
        ]}
      />
    </Island>
  );
};

export default React.memo(AnalyticsFulfillment);
