import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useLoadUIIndicators } from "../../hooks/uiIndicators/useLoadUIIndicators";

import { useTypedSelector } from "app/store/typedUseSelector";
import { loadObjects } from "features/objectsList";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import settingGear from "images/icons/navigation/settingGear.svg";

import styles from "./ManufacturingDefaultPage.module.scss";

const DEFAULT_FILTERS = { limit: 1000, offset: 0 };

const ObjectsList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const key = generateStorageKey(DEFAULT_FILTERS as any);
  const objects = useTypedSelector((state) => state.objectsListReducer.objects)[key];
  const isLoadingObjects = useTypedSelector((state) => state.objectsListReducer.loadings)[key];

  useLoadUIIndicators();

  useEffect(() => {
    if (objects?.results?.length) return;
    dispatch(loadObjects({}, DEFAULT_FILTERS));
  }, []);

  useEffect(() => {
    if (!objects?.results?.length) return;
    if (localStorage.getItem("lastObjectId")) {
      history.push(`/objects/${localStorage.getItem("lastObjectId")}`);
    } else {
      history.push(`/objects/${objects?.results[0]?.id}`);
      localStorage.setItem("lastObjectId", String(objects?.results[0]?.id));
    }
  }, [objects]);

  if (objects?.results?.length === 0 && !isLoadingObjects) {
    return (
      <TemplateBase>
        <EmptyPlaceholder text="Нет проектов" img={settingGear} className={styles.placeholder} />
      </TemplateBase>
    );
  }

  if (isLoadingObjects) {
    return (
      <TemplateBase>
        <Spinner />
      </TemplateBase>
    );
  }

  return null;
};

export default ObjectsList;
