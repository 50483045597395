import React from "react";

import { sliceTextByConstraint } from "../../../../../../utils/formatters/sliceTextByConstraint";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./MaterialListItem.module.scss";

function MaterialListItem({ name, count, measure, onClick, item }) {
  const clickHandler = () => {
    onClick(item?.id);
  };

  return (
    <div className={styles.container} onClick={clickHandler}>
      <div className={styles.name}>{name}</div>
      <div className={styles.measure}>{sliceTextByConstraint(String(measure), 5)}</div>
      <svg
        width="2"
        height="29"
        viewBox="0 0 2 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.divider}
      >
        <path d="M0.503906 0.3125V28.3125" stroke="#969696" />
      </svg>
      <div
        className={styles.count}
        title={count ? sliceTextByConstraint(String(transformDigitToFinancial(count, { withFloat: false })), 5) : "0"}
      >
        {count ? sliceTextByConstraint(String(transformDigitToFinancial(count, { withFloat: false })), 5) : "0"}
      </div>
      <svg
        width="8"
        height="16"
        viewBox="0 0 8 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.arrow}
      >
        <path d="M0.510742 0.8125L7.91974 8.2215L0.510742 15.6305" fill="#969696" />
      </svg>
    </div>
  );
}

export default MaterialListItem;
