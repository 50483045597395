import { message } from "antd";
import { excludedMessageWords } from "constants/constant";

const checkErrorMessage = (message) => {
  let formattedMessage = message;
  excludedMessageWords.forEach(item => formattedMessage = message.replaceAll(item, ''))
  return formattedMessage;
}

const messageError = (error, displayField) => {
  if (typeof error === "string") {
    message.error(checkErrorMessage(error));
    return;
  }

  if (error.message) {
    message.error(`${displayField ? (error.field + ": ") : ""}${checkErrorMessage(error.message)}`);
  }

  if (error.nested) {
    messageErrorHandler(error.nested, displayField);
  }
};

export const messageErrorHandler = (errors, displayField) => {
  if (!errors) return;

  const messagedErrors = Array.isArray(errors) ? errors : errors.non_field_errors;
  if (!messagedErrors) return;
  messagedErrors.forEach(error => messageError(error, displayField));
};
