import cn from "classnames";
import React from "react";

import { ISectionPlan } from "redux/modules/common/building/processTypes";

import DayWorkerAndEvent from "components/UI/_TODO/WorkOrMaterialsModals/components/DayWorkerAndEvent/DayWorkerAndEvent";
import InfoItem from "components/UI/_TODO/WorkOrMaterialsModals/components/InfoItem/InfoItem";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";

import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./ManufacturingPlanSectionModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  lsrPlanData: ISectionPlan;
}

const ReducedPlanSectionModal: React.FC<IProps> = ({ isOpen, onClose, lsrPlanData }) => {
  if (!lsrPlanData) return null;

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} name={lsrPlanData?.section_name}>
      <DayWorkerAndEvent date={lsrPlanData?.created_at} label="Создал" author={lsrPlanData?.author} />
      <div className={cn(styles.pair, styles.centered)}>
        <b>Период выполнения:</b>
        <CalendarRange
          defaultDateStart={lsrPlanData?.start_at}
          defaultDateEnd={lsrPlanData?.end_at}
          disabled
          classNameSelect={styles.calendar}
        />
      </div>
      <InfoItem
        label="Бюджет (по проекту)"
        value={`${transformDigitToFinancial(lsrPlanData.section_estimate_amount, {
          withCurrencySign: true,
          withFloat: true,
        })}`}
      />
    </ModalContainer>
  );
};

export default React.memo(ReducedPlanSectionModal);
