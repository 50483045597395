import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getStockMovingProducts } from "pages/Stocks/model/thunks";

import { useTypedSelector } from "app/store/typedUseSelector";

export const useSubTableMovings = (stockId: string, movingId: number) => {
  const dispatch = useDispatch();
  const key = String(movingId);
  const isLoading = useTypedSelector((state) => state.stocksProcurements.isLoading)[key];
  const products = useTypedSelector((state) => state.stocksProcurements.products)[key];

  useEffect(() => {
    dispatch(getStockMovingProducts(stockId, movingId));
  }, [movingId, stockId]);

  return { isLoading, products };
};
