import { useMemo } from "react";
import { useSelector } from "react-redux";

import { permissionsDescrSelector } from "features/permissions/model/selectors";

import { IPermission, IPermissionExtended, IPermissionsTree } from "features/permissions/model/types";

interface IProps {
  fullActivePermission?: IPermissionExtended;
}

export const useInstructionsBottom = ({ fullActivePermission }: IProps) => {
  const descriptions = useSelector(permissionsDescrSelector);

  const slides = useMemo(() => {
    if (fullActivePermission?.parentChain?.length === 1) {
      return fullActivePermission?.groups?.map((g) => g.permissions.filter((el) => el.showing_in_instructions));
    }
    if (fullActivePermission?.parentChain?.length === 2) {
      return fullActivePermission?.groups?.map((g) => g.permissions.filter((el) => el.showing_in_instructions));
    }
    const tabAlias = fullActivePermission?.parentChain?.[2]?.alias;

    const tab = descriptions?.[tabAlias!];

    if (!tab) {
      return [];
    }

    const children = tab?.groups.map((el) => el.permissions.filter((el) => el.showing_in_instructions));
    return children;
  }, [fullActivePermission, descriptions]);

  return slides;
};
