import axios from "axios";
import { createSelector } from "reselect";

const moduleName = "payment";
const LOAD_PAYMENT = `${moduleName}/LOAD_PAYMENT`;
const SET_PAYMENT = `${moduleName}/SET_PAYMENT`;
const ADD_DOCUMENT_PAYMENT = `${moduleName}/ADD_DOCUMENT_PAYMENT`;

export const DEFAULT_LIMIT_PAYMENT = 10;

const initialState = {
  payment: {
    count: 1,
    list: [],
    results: [],
  },
  isLoading: false,
};

export const stateSelector = (state) => state.payments;
export const paymentSelector = createSelector(stateSelector, (state) => state.payment);
export const loadingStateSelector = createSelector(stateSelector, (state) => state.isLoading);

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_PAYMENT:
      return {
        ...state,
        isLoading: true,
      };
    case SET_PAYMENT:
      return {
        ...state,
        payment: payload,
        isLoading: false,
      };
    case ADD_DOCUMENT_PAYMENT:
      return {
        ...state,
        payment: { count: action.payload.count, results: [...state.payment.results, ...action.payload.results] },
      };
    default:
      return state;
  }
};

export const setPayment = (data) => {
  return {
    type: SET_PAYMENT,
    payload: data,
  };
};

export const loadPayment = (params) => {
  const config = {
    params,
  };
  return (dispatch) =>
    axios.get(`/purchaser/invoices/payments/`, config).then(
      (response) => dispatch(setPayment(response.data)),
      (err) => console.error(err)
    );
};

export const loadPaymentV2 = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (entityId, isPurchaser, paginationParams, filterParams, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        limit: DEFAULT_LIMIT_PAYMENT,
        ordering: sorting,
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch, getState) => {
      dispatch({
        type: LOAD_PAYMENT,
      });
      axios.get(`/purchaser/invoices/payments/`, config).then(
        (response) => dispatch(setPayment(response.data)),
        (err) => console.error("docs not loaded")
      );
    };
  };
})();
export const addLoadDocumentPayment = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (entityId, paginationParams, filterParams = null, sorting = null) => {
    if (ge_cancel) ge_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        limit: DEFAULT_LIMIT_PAYMENT,
      },
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    return (dispatch, getState) => {
      axios.get(`/purchaser/invoices/payments/`, config).then(
        (res) => {
          dispatch({
            type: ADD_DOCUMENT_PAYMENT,
            payload: res.data,
          });
        },
        (err) => {
          console.error(err);
        }
      );
    };
  };
})();
