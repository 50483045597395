import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { useAttachFileToDrawingMutation, useCreateDrawingMutation } from "../../model/blueprintsQuery";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import ButtonBase from "shared/ui/controls/ButtonBase";
import ExpandableFileInput from "shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";
import InputBase from "shared/ui/inputs/InputBase";
import TextArea from "shared/ui/inputs/TextArea/TextArea";

import { ICreateDrawingReq, ICreateDrawingSet } from "../../model/types";

import { composeFieldValidators, required } from "utils/formHelpers/validations";
import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import { IFile } from "utils/serializeFiles";

import styles from "./BlueprintsModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  drawingsSetId: number | string;
}

const BlueprintsCreateModal: React.FC<IProps> = ({ isOpen, onClose, drawingsSetId }) => {
  const buildingId = useObjectId();
  const [files, setFiles] = useState<any[]>([]);
  const [createBlueprint, { isLoading, isSuccess, data }] = useCreateDrawingMutation();
  const [attachFile, { isSuccess: isFileLoadingSuccess, isLoading: isFileLoading }] = useAttachFileToDrawingMutation();

  useEffect(() => {
    if (isSuccess && data?.id) {
      onClose();
      files.forEach(async (el) => {
        const [file] = await getArrayFilesInBase64([el]);
        const serializedFile = { file: file.file, originalname: file.name };
        attachFile({ buildingId, drawingSetId: data.id, file: serializedFile as IFile });
      });
    }
  }, [isSuccess, data]);

  const onSubmit = (
    vals: Omit<ICreateDrawingReq, "buildingId" | "drawing_set" | "sheet_numbers"> & { sheet_numbers: string }
  ) => {
    const sheet_numbers = vals.sheet_numbers?.split(",");

    createBlueprint({ ...vals, buildingId, drawing_set: Number(drawingsSetId), sheet_numbers });
  };

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} name="Новый чертеж">
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className={styles.form}>
              <Field
                render={({ input, meta }) => (
                  <TextArea
                    label="Название чертежа"
                    maxRows={2}
                    value={input.value}
                    onChange={(e) => input.onChange(e.target.value)}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    error={meta.touched && meta.error}
                  />
                )}
                name="title"
                validate={composeFieldValidators(required())}
              />

              <Field
                render={({ input, meta }) => (
                  <LabeledItem label="Номера листов">
                    <InputBase className={styles.input} input={input} meta={meta} />
                  </LabeledItem>
                )}
                name="sheet_numbers"
                validate={composeFieldValidators(required())}
              />
            </div>
            <ExpandableFileInput files={files} setFiles={setFiles} />
            <BottomControls isExists isDoubleBtns>
              <ButtonBase secondary onClick={onClose}>
                Отменить
              </ButtonBase>
              <ButtonBase type="submit" primary disabled={isLoading} isLoading={isLoading || isFileLoading}>
                Сохранить
              </ButtonBase>
            </BottomControls>
          </form>
        )}
      />
    </ModalContainer>
  );
};

export default BlueprintsCreateModal;
