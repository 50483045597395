import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { compose } from "redux";

import { getDetail, objectDetailSelector } from "../../../redux/modules/common/building/object/nowObject";
import { loadStockDetail, stockDetailSelector } from "../../../redux/modules/common/building/stocks";

import Expense from "./components/Expense";
import Income from "./components/Income/Income";
import Receipt from "./components/Receipt";
import { WriteOff } from "./components/WriteOff/WriteOff";
import ForbiddenPage from "app/routes/components/ForbiddenPage/ForbiddenPage";
import { getStocksList } from "pages/Stocks/model/thunks";

import { useFinanceMeasure } from "../../../features/financeMeasure";
import MeasureBtn from "../../../features/financeMeasure/MeasureBtn/MeasureBtn";
import ButtonBase from "../../../shared/ui/controls/ButtonBase";
import TabBar from "../../../shared/ui/controls/TabBar/TabBar";
import { useTypedSelector } from "app/store/typedUseSelector";
import CustomBreadcrumbs from "entities/CustomBreadcrumbs/CustomBreadcrumbs";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import ListPageHeaderTemplate from "shared/ui/layout/ListPageHeaderTemplate/ListPageHeaderTemplate";

import {
  VIEW_MANUFACTURING_STOCKS_INCOMES,
  VIEW_MANUFACTURING_STOCKS_MOVINGS,
  VIEW_MANUFACTURING_STOCKS_OUTCOMES,
  VIEW_MANUFACTURING_STOCKS_TO_PRODUCTION,
} from "constants/permissions/manufacturingPermissions";

import { useLocationArray } from "../../../utils/hooks/useLocationArray";
import { useUrlModule } from "../../../utils/hooks/useUrlModule";
import usePermission from "hooks/usePermission";

import { formatNumberWithMeasure } from "../../../utils/formatters/formatNumberWithMeasure";
import isLaptop from "utils/checkers/isLaptop";

import { ActiveCollect } from "./icons/ActiveCollect";
import { Collect } from "./icons/Collect";
import Line from "images/icons/Line";

import styles from "./Stocks.module.scss";

const generateTabRouteDefault = (basePathSlice: any, stockId: any, objectId: any) => (tab: any) =>
  `/${basePathSlice}/${objectId}/stocks/${tab}`;

interface IProps {
  customBreadCrumbs: any;
  routeKey?: string;
  generateTabRoute: any;
}

const Stock = ({ customBreadCrumbs, routeKey = "objectId", generateTabRoute = generateTabRouteDefault }: IProps) => {
  const { objectId: paramsObjectId, tab, stockId } = useParams<Record<string, string>>();
  const objectId = routeKey === "objectId" ? paramsObjectId : stockId;
  const dispatch = useDispatch();

  const { stocks } = useTypedSelector((state) => state.stocksProcurements);

  useEffect(() => {
    if (!stocks.length) {
      dispatch(getStocksList());
    }
  }, [stocks]);

  const module = useUrlModule();
  const history = useHistory();
  const match = useRouteMatch();
  const { measure } = useFinanceMeasure();
  const locationArray = useLocationArray();

  const stockDetail = useSelector(stockDetailSelector);
  const objectDetail = useSelector(objectDetailSelector);

  const haveIncomeTabPermission = usePermission(VIEW_MANUFACTURING_STOCKS_INCOMES);
  const haveOutcomeTabPermission = usePermission(VIEW_MANUFACTURING_STOCKS_OUTCOMES);
  const haveMovingsTabPermission = usePermission(VIEW_MANUFACTURING_STOCKS_MOVINGS);
  const haveToProductionTabPermission = usePermission(VIEW_MANUFACTURING_STOCKS_TO_PRODUCTION);

  const generateRouteFn = useMemo(() => {
    return generateTabRoute(module, stockId, objectId);
  }, [module, stockId, objectId]);

  const [isLaptop, setIsLaptop] = useState(document.documentElement.clientWidth < 1540);

  useEffect(() => {
    const handler = () => {
      setIsLaptop(document.documentElement.clientWidth < 1540);
    };
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  const tabs = useMemo(() => {
    return [
      {
        link: generateRouteFn("writeOff"),
        text: "Выберите",
        isDesctopHidden: tab !== "writeOff",
        isHidden: tab !== "writeOff" || !isLaptop,
      },
      { link: generateRouteFn("receipt"), text: "Поступления", isHidden: !haveIncomeTabPermission },
      { link: generateRouteFn("expense"), text: "Списания", isHidden: !haveOutcomeTabPermission },
      { link: generateRouteFn("income"), text: "Движение", isHidden: !haveMovingsTabPermission },
    ];
  }, [generateRouteFn, haveIncomeTabPermission, haveOutcomeTabPermission, haveMovingsTabPermission, tab, isLaptop]);

  useEffect(() => {
    if (!haveIncomeTabPermission && haveOutcomeTabPermission) {
      history.replace(`/${module}/${objectId}/stocks/expense`);
    }
    if (!haveIncomeTabPermission && !haveOutcomeTabPermission && haveMovingsTabPermission) {
      history.replace(`/${module}/${objectId}/stocks/income`);
    }
    if (
      !haveIncomeTabPermission &&
      !haveOutcomeTabPermission &&
      !haveMovingsTabPermission &&
      haveToProductionTabPermission
    ) {
      history.replace(`/${module}/${objectId}/stocks/writeOff`);
    }
  }, []);

  useEffect(() => {
    if (!tab) {
      history.replace(`/${module}/${objectId}/stocks/receipt`);
    }
  }, [tab]);

  useEffect(() => {
    if (!objectDetail || !objectId) return;
    //@ts-expect-error
    compose(dispatch, loadStockDetail)(objectDetail.stock_id);
  }, [objectDetail, objectId]);

  useEffect(() => {
    if (!stockId) return;
    dispatch(loadStockDetail(stockId));
  }, [stockId]);

  useEffect(() => {
    compose(dispatch, getDetail)(objectId);
  }, [objectId]);

  if (
    !haveIncomeTabPermission &&
    !haveOutcomeTabPermission &&
    !haveMovingsTabPermission &&
    !haveToProductionTabPermission
  ) {
    return (
      <TemplateBase>
        <ForbiddenPage />
      </TemplateBase>
    );
  }

  return (
    <TemplateBase breadcrumbs={customBreadCrumbs}>
      <ListPageHeaderTemplate>
        <TabBar tabs={tabs} />
        <div className={styles.rightSide}>
          {(locationArray.includes("receipt") || locationArray.includes("expense")) && (
            <span className={styles.line}>
              <Line height={"29"} width={"3"} color="#CECECE" />
            </span>
          )}
          {locationArray.includes("receipt") && "Итого поступило "}
          {locationArray.includes("expense") && "Итого выдано  "}
          {locationArray.includes("receipt") && (
            <span className={styles.count}>
              {formatNumberWithMeasure(stockDetail?.balance?.replenishment_amount, measure)}
            </span>
          )}
          {locationArray.includes("expense") && (
            <span className={styles.count}>{formatNumberWithMeasure(stockDetail?.balance?.using_amount, measure)}</span>
          )}
        </div>
        {haveToProductionTabPermission && (
          <div>
            <NavLink
              className={(isActive) => (isActive ? styles.activeWriteLink : "")}
              to={generateRouteFn("writeOff")}
            >
              <ButtonBase secondary medium className={styles.toWriteOff}>
                <span>В производство </span>
                {/* @ts-ignore */}
                {tab === "writeOff" ? <ActiveCollect /> : <Collect />}
              </ButtonBase>
            </NavLink>
          </div>
        )}
        <MeasureBtn className={styles.measure} />
      </ListPageHeaderTemplate>
      <Switch>
        <Route
          exact
          path={[generateRouteFn(""), generateRouteFn("receipt"), generateRouteFn("receipt") + "/:packingListId"]}
        >
          <Receipt stockId={stockId ?? stockDetail?.id} objectId={objectId} />
        </Route>
        <Route exact path={generateRouteFn("expense")}>
          <Expense stockId={stockId ?? stockDetail?.id} objectId={objectId} />
        </Route>
        <Route exact path={generateRouteFn("income")}>
          <Income stockId={stockId ?? stockDetail?.id} />
        </Route>
        <Route exact path={generateRouteFn("writeOff")}>
          <WriteOff
            objectDetail={objectDetail}
            //@ts-expect-error
            objectDetailId={objectDetail?.id}
            stockDetailId={stockId ?? stockDetail?.id}
            objectId={objectId}
          />
        </Route>
      </Switch>
    </TemplateBase>
  );
};

export default Stock;
