import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  uiIndicatorsAreLoadingSelector,
  uiIndicatorsInvalidateKeySelector,
} from "../../redux/modules/common/uiIndicators/selectors";
import { checkUIIndicator, deleteIndicator } from "../../redux/modules/common/uiIndicators/thunks";
import { IUIIndicatorResponse, UIIndicatorsType } from "../../redux/modules/common/uiIndicators/types";

import { MODULES_ENUM } from "../../types/enums/ModulesEnum";
import { IRouterParamsWithObjectId } from "../../types/routerTypes";

import { useUrlModule } from "../../utils/hooks/useUrlModule";

export interface IUseUIIndicatorProps {
  data: Partial<IUIIndicatorResponse["data"]>;
  type: UIIndicatorsType | UIIndicatorsType[];
  module?: MODULES_ENUM;
  buildingId?: number;
  customFilter?: (_: IUIIndicatorResponse) => boolean;
}

export const useUIIndicator = ({ data, type, module, buildingId, customFilter }: IUseUIIndicatorProps) => {
  const dispatch = useDispatch();

  const urlModule = useUrlModule();
  const { objectId } = useParams<IRouterParamsWithObjectId>();

  const indicatorsAreLoading = useSelector(uiIndicatorsAreLoadingSelector);
  const indicatorsInvalidateKey = useSelector(uiIndicatorsInvalidateKeySelector);

  const [indicator, setIndicator] = useState<IUIIndicatorResponse | undefined>();

  useEffect(() => {
    if (indicatorsAreLoading || !type) return;
    setIndicator(
      /* @ts-ignore */
      dispatch(
        checkUIIndicator({
          data,
          type /* @ts-ignore */,
          module: module || urlModule /* @ts-ignore */,
          buildingId: +buildingId || +objectId || undefined,
          customFilter,
        })
      )
    );
  }, [data, type, module, buildingId, objectId, indicatorsAreLoading, customFilter, indicatorsInvalidateKey]);

  const deleteIndicatorCallback = useCallback(() => {
    /* @ts-ignore */
    const indicatorCandidate: IUIIndicatorResponse | undefined =
      indicator ||
      dispatch(
        /* @ts-ignore */
        checkUIIndicator({ data, type, module, buildingId: +buildingId || +objectId || undefined, customFilter })
      );
    if (!indicatorCandidate?.id) return;
    dispatch(deleteIndicator(indicatorCandidate.id, indicatorCandidate.modules, indicatorCandidate.building_id));
    setIndicator(undefined);
  }, [indicator, data, type, module, buildingId, objectId, indicatorsAreLoading, customFilter]);

  return { indicator, deleteIndicator: deleteIndicatorCallback, isIndicatorDisplayed: !!indicator };
};
