import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useCheckpointSelects } from "./useCheckpointSelects";
import { fileUploadStorageSelector } from "entities/FileUpload/model/selectors";
import { fileUploadActions } from "entities/FileUpload/model/slice";

import { IIdAndName } from "types/idAndName";

export interface IUseAddCheckpoint {
  objectId: string;
  isActive: boolean;
  key?: number | undefined;
}

export const useEditCheckpoint = ({ objectId, isActive, key }: IUseAddCheckpoint) => {
  const dispatch = useDispatch();
  const [object, setObject] = useState<IIdAndName | undefined>();
  const [section, setSection] = useState<IIdAndName | undefined>();
  const { objects, sections, users } = useCheckpointSelects({
    objectId: object?.id || objectId,
    isActive,
  });
  const fileStorageId = useRef(Math.round(Math.random() * 1000000));
  const storage = useSelector(fileUploadStorageSelector);
  const files = storage[fileStorageId.current]?.value || [];

  useEffect(() => {
    if (isActive) return;
    setSection(undefined);
    setSection(undefined);
  }, [isActive]);

  useEffect(() => {
    dispatch(fileUploadActions.flushStorage(fileStorageId.current));
  }, [key]);

  useEffect(() => {
    if (!objects || !objectId) return;
    if (Number(object?.id) !== Number(objectId)) {
      setObject(objects.find((x: any) => x.id == objectId) || { id: objectId, name: "" });
    }
  }, [object, objects, objectId]);

  const selectObjectById = useCallback(
    (id: string | number) => {
      setObject(objects.find((x: IIdAndName) => x.id == id));
    },
    [objects]
  );

  const selectObjectByName = useCallback(
    (name: string) => {
      const nameText = name.toLowerCase().trim();
      setObject(objects.find((x: IIdAndName) => x.name.toLowerCase() == nameText));
    },
    [objects]
  );

  const selectSectionById = useCallback(
    (id: string | number) => {
      setSection(sections.find((x: IIdAndName) => x.id == id));
    },
    [sections]
  );

  return {
    object,
    section,
    objects,
    sections,
    users,
    selectObjectById,
    selectObjectByName,
    selectSectionById,
    fileStorageId: fileStorageId.current,
    files,
  };
};
