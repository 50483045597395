import axios from "axios";

import { IEditAcceptanceTicket, IJournalAcceptanceSharedContractors, IJournalHistoryEditing } from "./types";
import { IListResponseData } from "types/ListResponse";
import { ITicketApproval } from "types/interfaces/Tickets";

export const journalAcceptanceApi = {
  getSections: (buildingId: string, year: number, month: number) => {
    return axios.get(`/journal/${buildingId}/acceptance/sections/`, { params: { year, month, limit: 500 } });
  },
  getTickets: (buildingId: string, sectionId: number, year: number, month: number, filter: Record<string, any>) => {
    return axios.get(`/journal/${buildingId}/acceptance/sections/${sectionId}/`, {
      params: { ...filter, year, month, limit: 500 },
    });
  },
  getApprovals: (buildingId: string, intervalId: number, type: "expenditure" | "group") => {
    return axios.get<IListResponseData<ITicketApproval>>(`/journal/${buildingId}/acceptance/${type}/${intervalId}/approvals/`, {
      params: { limit: 100 },
    });
  },
  getDetailedTicket: (buildingId: string, intervalId: number, type: "expenditure" | "group") => {
    return axios.get(`/journal/${buildingId}/acceptance/${type}/${intervalId}/`);
  },
  editTicket: (buildingId: string, ticketId: number, data: Partial<IEditAcceptanceTicket>) => {
    return axios.patch(`/journal/${buildingId}/acceptance/expenditure/${ticketId}/`, data);
  },
  editGroup: (buildingId: string, groupId: number, data: Partial<IEditAcceptanceTicket>) => {
    return axios.patch(`/building/${buildingId}/groups/facts/${groupId}/`, data);
  },
  getHistory: (buildingId: string, ticketId: number, isGroup: boolean, type: "fact" | "plan") => {
    const urlPart = `${type}_${isGroup ? "group" : "work"}`;
    return axios.get<IJournalHistoryEditing[]>(`/building/${buildingId}/history/${urlPart}/${ticketId}/`);
  },
  getSharedContractors: (buildingId: string, year: number, month: number) => {
    return axios.get<IJournalAcceptanceSharedContractors[]>(`/journal/${buildingId}/shared_acceptance/sections/`, {
      params: { year, month },
    });
  },
};
