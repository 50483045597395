import { createSelector } from "reselect";

import { ADD_COMMENT, SET_COMMENTS, SET_COMMENTS_LOADING, SET_ITEM_ID_COMMENTS } from "./constants";

const initialState = {
  comments: {},
  commentsAreLoading: false,
};

const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.payload.itemId]: state.comments[action.payload.itemId]
            ? [action.payload.comment, ...state.comments[action.payload.itemId]]
            : [action.payload.comment],
        },
      };
    case SET_COMMENTS:
      return { ...state, comments: action.payload };
    case SET_COMMENTS_LOADING:
      return { ...state, commentsAreLoading: action.payload };
    case SET_ITEM_ID_COMMENTS:
      return { ...state, comments: { ...state.comments, [action.payload.itemId]: action.payload.comments } };
    default:
      return state;
  }
};

const fromRootState = (rootState) => rootState.comments;
export const commentsSelector = createSelector(fromRootState, (localState) => localState.comments);
export const commentsLoadingSelector = createSelector(fromRootState, (localState) => localState.commentsAreLoading);

export default commentsReducer;
