import cn from "classnames";
import { ChangeEvent, FC, memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { chartTreeSelector } from "redux/modules/common/chart/selectors";
import { findChartTreeNode, makeTreeId } from "redux/modules/common/chart/utils";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import { CHART_TREE_LVL } from "components/pages/Chart/constants";

import moment, { Moment } from "moment/moment";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import InputBase, { VALUE_TYPES } from "shared/ui/inputs/InputBase";

import { IShiftDetail } from "../types";

import { getDayLabel, serializeShiftPlanData } from "../utils";
import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";

import { ReactComponent as ArrowUp } from "shared/assets/icons/ArrowUp.svg";
import { ReactComponent as IconCheck } from "shared/assets/icons/IconCheck.svg";
import { ReactComponent as IconWarning } from "shared/assets/icons/IconWarning.svg";

import styles from "../ShiftsModal.module.scss";

BottomControls;

interface ISpecificPlanContentProps {
  data: IShiftDetail;
  isApproveActionOpened: boolean;
  setIsApproveActionOpened: (v: boolean) => void;
  onCancel: () => void;
  onMove: (days: number, finallyCallback: () => void) => void;
  lsrPlanId?: number;
  sectionPlanId?: number;
}

const SelectedPlanContent: FC<ISpecificPlanContentProps> = ({
  data,
  isApproveActionOpened,
  setIsApproveActionOpened,
  onCancel,
  onMove,
  lsrPlanId,
  sectionPlanId,
}) => {
  const chartTree = useSelector(chartTreeSelector);

  const serializedShift = serializeShiftPlanData(data);

  const [shiftDays, setShiftDays] = useState("1");
  const [isRelatedPlansOpened, setIsRelatedPlansOpened] = useState(false);
  const [isShiftMoving, setIsShiftMoving] = useState(false);

  const materials = serializedShift.not_enough;
  const relatedPlans = serializedShift.related;

  let isCritical = useMemo(() => {
    let maxPlanDateMoment = moment.max([
      moment(serializedShift.end_at, "DD.MM.YYYY"),
      ...serializedShift.related.map((r) => moment(r.end_at, "YYYY-MM-DD")),
    ]);

    const lsr = lsrPlanId ? findChartTreeNode(makeTreeId(lsrPlanId, CHART_TREE_LVL.LSR), chartTree) : undefined;
    const section = sectionPlanId
      ? findChartTreeNode(makeTreeId(sectionPlanId, CHART_TREE_LVL.SECTION), chartTree)
      : undefined;

    const lsrPlanEndAtMoment = lsr?.plan_end ? moment(lsr.plan_end, "YYYY-MM-DD") : null;
    const sectionPlanEndAtMoment = section?.plan_end ? moment(section.plan_end, "YYYY-MM-DD") : null;

    const momentToCheck = moment.max([lsrPlanEndAtMoment, sectionPlanEndAtMoment].filter((x) => x) as Moment[]);

    const maxPlanDateMomentAfterShift = maxPlanDateMoment ? maxPlanDateMoment.clone().add(+shiftDays, "days") : null;

    return maxPlanDateMomentAfterShift && momentToCheck ? maxPlanDateMomentAfterShift.isAfter(momentToCheck) : false;
  }, [data, shiftDays, serializedShift, lsrPlanId, sectionPlanId]);

  const handleOnMove = useCallback(() => {
    setIsShiftMoving(true);
    onMove(+shiftDays, () => {
      setIsShiftMoving(false);
      setIsApproveActionOpened(false);
    });
  }, [shiftDays, onMove]);

  const handleActionClick = useCallback(() => {
    setIsApproveActionOpened(true);
  }, []);

  const onShiftDaysChange = (e: ChangeEvent<HTMLInputElement>) => {
    // для запрета ввода дробного числа
    if (!e.target.value.includes(".")) {
      setShiftDays(e.target.value);
    }
  };

  if (isApproveActionOpened) {
    return (
      <>
        <div className={styles.centerContent}>
          {isShiftMoving ? (
            <Spinner className={cn(styles.spinner, styles.smallMargin)} />
          ) : (
            <>
              <IconWarning className={cn(styles.warningIcon, { [styles.critical]: isCritical })} />
              {isCritical ? (
                <>
                  <span className={styles.attention}>Внимание!</span>
                  <span>
                    Вы хотите сдвинуть график работ на {shiftDays} {getDayLabel(+shiftDays)},<br />
                    это повлияет на критический путь проекта.
                  </span>
                </>
              ) : (
                <span>
                  Вы действительно хотите сдвинуть
                  <br />
                  график работы на {shiftDays} {getDayLabel(+shiftDays)}?
                </span>
              )}
            </>
          )}
        </div>

        <footer>
          <BottomControls
            className={cn(styles.controls, styles.double)}
            wrapperClassName={styles.controlsWrapper}
            isExists={true}
          >
            <ButtonBase secondary className={styles.bigButton} onClick={() => setIsApproveActionOpened(false)}>
              Отмена
            </ButtonBase>
            {/* @ts-ignore */}
            <ButtonBase className={styles.bigButton} onClick={handleOnMove}>
              Подтвердить
            </ButtonBase>
          </BottomControls>
        </footer>
      </>
    );
  }

  return (
    <>
      {!!materials.length ? (
        <>
          <div className={styles.materials}>
            <div className={styles.materialsHeader}>Недостающие материалы для начала работы:</div>

            <div className={styles.materialsItemsWrapper}>
              {materials.map((m, idx) => (
                <div className={styles.materialsItem} key={`${idx}_${m.name}`}>
                  <span className={styles.materialItemName}>{m.name}</span>
                  <div className={styles.materialInfo}>
                    <div className={styles.materialInfoItem}>
                      <span className={styles.materialInfoItemName}>В наличии</span>
                      <span className={styles.materialInfoItemCount}>
                        <>
                          {dropNonSignificantZeros(m.stock_count)} {m.measure}
                        </>
                      </span>
                    </div>
                    <div className={styles.materialInfoItem}>
                      <span className={styles.materialInfoItemName}>Необходимо</span>
                      <span className={styles.materialInfoItemCount}>
                        <>
                          {dropNonSignificantZeros(m.count)} {m.measure}
                        </>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {relatedPlans.length ? (
            <div className={styles.relatedPlans}>
              <div
                className={styles.relatedPlansHeader}
                onClick={() => {
                  setIsRelatedPlansOpened((prev) => !prev);
                }}
              >
                <span>Также будут затронуты связанные планы работ: {relatedPlans.length}</span>
                <ArrowUp className={cn({ [styles.arrow]: true, [styles.opened]: isRelatedPlansOpened })} />
              </div>
              <div className={cn({ [styles.relatedPlansItems]: true, [styles.opened]: isRelatedPlansOpened })}>
                {relatedPlans.map((plan, idx) => {
                  const dateStart = moment(plan.start_at).format("DD.MM.YYYY");
                  const dateEnd = moment(plan.end_at).format("DD.MM.YYYY");

                  return (
                    <div className={styles.relatedPlansItem} key={plan.id}>
                      <span className={styles.relatedPlansItemName}>{plan.name}</span>
                      <div className={styles.relatedPlansItemInfo}>
                        <span>Выполнение с&nbsp;</span>
                        <span className={styles.date}>
                          {dateStart} по {dateEnd}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          <div className={styles.shiftDays}>
            Сдвинуть график выполнения работ на&nbsp;
            <InputBase
              className={styles.input}
              value={shiftDays}
              onChange={onShiftDaysChange}
              valueType={VALUE_TYPES.NUMBER}
            />
            &nbsp;
            {getDayLabel(+shiftDays)}
          </div>
        </>
      ) : (
        <div className={styles.centerContent}>
          <IconCheck />
          <span>
            Недостающих материалов для начала работы нет.
            <br />
            Сдвиг графика не требуется.
          </span>
        </div>
      )}

      <footer>
        <BottomControls className={styles.controls} wrapperClassName={styles.controlsWrapper} isExists={true}>
          {materials.length ? (
            <>
              <ButtonBase
                primary={true}
                disabled={!+shiftDays}
                onClick={handleActionClick}
                className={styles.bigButton}
              >
                Сдвинуть график
              </ButtonBase>
            </>
          ) : (
            <ButtonBase primary={true} onClick={onCancel} className={styles.bigButton}>
              Хорошо!
            </ButtonBase>
          )}
        </BottomControls>
      </footer>
    </>
  );
};

export default memo(SelectedPlanContent);
