import React, { useEffect } from "react";

const useEscapeHandler = (fn: () => void) => {
  useEffect(() => {
    const escHandler = (e: KeyboardEvent) => {
      if (e?.key === "Escape" || e?.keyCode === 27) {
        fn && fn();
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("keydown", escHandler);
    }

    return () => {
      window.removeEventListener("keydown", escHandler);
    };
  }, []);
};

export default useEscapeHandler;
