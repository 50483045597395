import React from "react";
import { Field, Form } from "react-final-form";

import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import InputBase from "shared/ui/inputs/InputBase";
import TextArea from "shared/ui/inputs/TextArea/TextArea";

import { composeFieldValidators, required } from "utils/formHelpers/validations";

import styles from "./BlueprintsModal.module.scss";

interface IProps {
  onSubmit: (v: any) => void;
  fileInput: React.ReactNode;
  bottomControls: React.ReactNode;
  initialValue?: any;
}

const BlueprintForm: React.FC<IProps> = ({ onSubmit, fileInput, bottomControls, initialValue }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValue}
      render={({ handleSubmit }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className={styles.form}>
            <Field
              render={({ input, meta }) => (
                <TextArea
                  label="Название чертежа"
                  maxRows={2}
                  value={input.value}
                  onChange={(e) => input.onChange(e.target.value)}
                  onFocus={input.onFocus}
                  onBlur={input.onBlur}
                  error={meta.touched && meta.error}
                />
              )}
              name="title"
              validate={composeFieldValidators(required())}
            />

            <Field
              render={({ input, meta }) => (
                <LabeledItem label="Номера листов">
                  <InputBase className={styles.input} input={input} meta={meta} />
                </LabeledItem>
              )}
              name="sheet_numbers"
              validate={composeFieldValidators(required())}
            />
          </div>
          {fileInput}
          {bottomControls}
        </form>
      )}
    />
  );
};

export default BlueprintForm;
