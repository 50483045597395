import React, { ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getAuditFullList, getAuditObject, getAuditProject } from "./model/thunks";
import AuditHeadline from "./ui/AuditHeadline/AuditHeadline";
import AuditTableHead from "./ui/AuditTableHead/AuditTableHead";
import ListSide from "./ui/ListSide/ListSide";
import TableSide from "./ui/TableSide/TableSide";
import { useTypedSelector } from "app/store/typedUseSelector";

import styles from "./Audit.module.scss";

interface IProps {
  children?: ReactNode;
}

const Audit: React.FC<IProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { objectId, projectId } = useParams<Record<"objectId" | "projectId", string>>();
  const { dateEnd, dateStart, type } = useTypedSelector((state) => state.financeReducer.audit);

  useEffect(() => {
    if (objectId === "0" && projectId === "0") {
      dispatch(getAuditFullList());
    } else if (objectId === "0") {
      dispatch(getAuditProject(+projectId));
    } else {
      dispatch(getAuditObject(+projectId, +objectId));
    }
  }, [objectId, projectId, dateEnd, dateStart, type]);

  return (
    <div className={styles.audit} data-testid="page_finance_audit">
      <div className={styles.audit__listSide}>
        <ListSide>{children}</ListSide>
      </div>
      <div className={styles.audit__tableSide}>
        <div className={styles.sticky}>
          <AuditHeadline />
          <AuditTableHead />
        </div>
        <TableSide />
      </div>
    </div>
  );
};

export default Audit;
