import { createSelector } from "reselect";

import { SET_KSES, SET_KSES_LOADING, SET_MORE_KSES, MODULE, RESET_KSES } from "./constants";

const initialState = {
  kses: null,
  ksesOffset: 10,
  ksesAreLoading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_KSES:
      return { ...state, kses: action.payload };
    case SET_KSES_LOADING:
      return { ...state, ksesAreLoading: action.payload };
    case SET_MORE_KSES:
      return {
        ...state,
        kses: {
          ...state.kses,
          results: [...state.kses.results, ...action.payload.results],
        },
        ksesOffset: state.kses.count > state.ksesOffset ? state.ksesOffset + 10 : state.ksesOffset,
      };
    case RESET_KSES:
      return { ...initialState };
    default:
      return state;
  }
};

const baseState = (state) => state[MODULE];
export const ksesSelector = createSelector(baseState, (state) => state.kses);
export const ksesOffsetSelector = createSelector(baseState, (state) => state.ksesOffset);
export const ksesLoadingSelector = createSelector(baseState, (state) => state.ksesAreLoading);

export default reducer;
