import update from "immutability-helper";

const calculateTotalComponentsCount = (components) => {
  let totalCount = 0;
  components.forEach((component) => totalCount += +component.count);
  return isNaN(totalCount) ? 0 : totalCount;
};

export const calculateOffersCount = (offers) => offers.map((offer) => {
  if (offer.measure !== "компл") return update(offer, {
    supplies: { 0: { count: { $set: calculateTotalComponentsCount(offer.components) } } }
  });

  return offer;
});