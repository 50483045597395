import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

import { IRequisitionsListState } from "./types";
import { REQUISITION_STATUS } from "constants/constant";

import { parseStatusParam } from "./utils";

const stateSelector = (state: RootState): IRequisitionsListState => state.requisitionsList;

export const requisitionsListsSelector = createSelector(stateSelector, (state) => state.requisitions);

export const requisitionsListsTabsSelector = createSelector(stateSelector, (state) => state.cachedTabs);

export const newRequisitionsListsSelector = createSelector(
  stateSelector,
  (state) => state.requisitions[parseStatusParam([REQUISITION_STATUS.NEW, REQUISITION_STATUS.DRAFT])]
);
