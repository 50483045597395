import React from "react";

import StatusFormatter from "../../../../../../../_LEGACY/UI/_LEGACY_Purchases_components/StatusFormatter/StatusFormatter";

import { STATUSES_NAMES } from "../../constants";

import styles from "./Info.module.scss";

const Info = ({ brandName, departmentTitle, status, docNumber }) => {
  return (
    <div className={styles.infoBlock}>
      <header className={styles.header}>
        <h2 className={styles.title}>Табель №{docNumber}</h2>
        <div>{StatusFormatter(STATUSES_NAMES[status], { flow: "in" })}</div>
      </header>
      <div className={styles.row}>
        <div className={styles.titleRow}>Наименование организации:</div>
        <div className={styles.valueRow}>{brandName}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.titleRow}>Структурное подразделение:</div>
        <div className={styles.valueRow}>{departmentTitle || "-"}</div>
      </div>
    </div>
  );
};

export default React.memo(Info);
