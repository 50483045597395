import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import {
  addFile,
  contractsLoadingSelector,
  contractsSelector,
  deleteContract,
  deleteFile,
  filesSelector,
  loadContracts,
  resetContractsAction,
} from "../../../../redux/modules/common/building/documents/contracts";
import { DEFAULT_LIMIT_DOCUMENT } from "../../../../redux/modules/common/documents/constants";

import ContractsTable from "../../../../_LEGACY/UI/_LEGACY_Purchases_components/ContractsTable/ContractsTable";

import { Spinner } from "../../../../shared/ui/atoms/Spinner/Spinner";

import { hasOnlyPaginationFilters } from "../../../../utils/hasOnlyPaginationFilters";

import styles from "./index.module.scss";

const INITIAL_REQUEST_PARAMS = { limit: DEFAULT_LIMIT_DOCUMENT, offset: 0 };

const Contracts = ({ permissions }) => {
  const dispatch = useDispatch();
  const contracts = useSelector(contractsSelector);
  const contractsFiles = useSelector(filesSelector);
  const isLoading = useSelector(contractsLoadingSelector);

  const [filters, setFilters] = useState({});
  const isWithFilters = useMemo(() => !hasOnlyPaginationFilters(filters), [filters]);

  const [requestParams, setRequestParams] = useState(INITIAL_REQUEST_PARAMS);

  const changeFilter = useCallback((name, value) => setFilters((prevState) => ({ ...prevState, [name]: value })), []);

  const loadMore = useCallback(
    () =>
      setRequestParams((prevState) => {
        const nextState = {
          ...prevState,
          offset: prevState.offset + DEFAULT_LIMIT_DOCUMENT,
        };
        compose(dispatch, loadContracts)({ ...nextState, ...filters }, { isMore: true });
        return nextState;
      }),
    [filters]
  );

  const handleAddFiles = useCallback(
    (contractId, files) => files.forEach(({ file }) => compose(dispatch, addFile)(contractId, file)),
    []
  );

  const handleDeleteFile = useCallback((contractId, file) => compose(dispatch, deleteFile)(contractId, file.id), []);

  const handleDeleteContract = useCallback((contractId) => compose(dispatch, deleteContract)(contractId), []);

  useEffect(() => {
    compose(dispatch, loadContracts)({ ...requestParams, ...filters });
  }, [filters]);

  useEffect(() => {
    if (contracts && contracts.results.length === 0 && contracts.count !== 0) {
      setRequestParams(INITIAL_REQUEST_PARAMS);
    }
  }, [contracts]);

  if (isLoading && !isWithFilters) return <Spinner />;

  return (
    <ContractsTable
      contracts={contracts?.results || []}
      files={contractsFiles}
      isLoading={isLoading && !contracts?.results?.length}
      allCount={contracts?.count}
      changeFilter={changeFilter}
      deleteContract={handleDeleteContract}
      loadMore={loadMore}
      addFiles={handleAddFiles}
      deleteFile={handleDeleteFile}
      permissions={permissions}
      headerClassName={styles.tableHead}
      dataTestId="page_docs_contracts"
      isWithFilters={isWithFilters}
    />
  );
};

export default React.memo(Contracts);
