import { FinanceExpendituresItemsTypes, FinanceTreeItemsTypes } from "./interfaces";

import { assertUnreachable } from "utils/assertUnreacheable";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const generateFinanceKey = (id: number, type: FinanceTreeItemsTypes | FinanceExpendituresItemsTypes) => {
  return generateStorageKey({ id, type });
};

export const financeUtils = {
  getExpenditureName: (type: FinanceExpendituresItemsTypes) => {
    switch (type) {
      case "group":
        return "Группы";
      case "material":
        return "Материалы";
      case "service":
        return "Машины и механизмы";
      case "work":
        return "Работы";
      default:
        assertUnreachable(type);
    }
  },
};
