import moment from "moment";

import { IPlanFactVolumeExpenditure } from "pages/PlanFact/PlanFactVolume/model/types";

type dayType = IPlanFactVolumeExpenditure["expenditures"][0]["days"][0];

export const prepareDaysForPlanfactVolume = (daysCount: number, startAt: string, days: dayType[]) => {
  const res: Map<string, dayType> = new Map();

  for (let i = 0; i < daysCount; i++) {
    const key = moment(startAt).add(i, "days").format("YYYY-MM-DD");
    res.set(key, { date: key, plan: "-", fact: "-" });
  }

  if (new Set([...Array.from(res.values()).map((x) => x.date), ...days.map((x) => x.date)]).size > daysCount)
    return Array.from(res.values());

  for (let i = 0; i < daysCount; i++) {
    const el = days[i];
    if (el) {
      const key = moment(el.date).format("YYYY-MM-DD");
      const plan = Math.round(+el.plan * 100) / 100 + "" || "-";
      const fact = Math.round(+el.fact * 100) / 100 + "" || "-";
      res.set(key, { ...el, plan, fact });
    }
  }

  return Array.from(res.values());
};

export const prepareDaysForPlanFactVolumeHeader = (startAt: string, daysCount: number) => {
  const res: string[] = [];
  for (let i = 0; i < daysCount; i++) {
    const day = moment(startAt).add(i, "days").format("YYYY-MM-DD");
    res.push(day);
  }
  return res;
};
