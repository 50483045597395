import cn from "classnames";
import React from "react";
import ReactTextareaAutosize from "react-textarea-autosize";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import CheckIcon from "components/UI/_TODO/WorkOrMaterialsModals/components/CheckIcon/CheckIcon";
import NavBar from "components/UI/_TODO/WorkOrMaterialsModals/components/NavBar/NavBar";

import WorkSharingInfoItem from "./WorkSharingInfoItem/WorkSharingInfoItem";
import declinePng from "./decline.png";
import { useReviewSharedWork } from "./useReviewSharedWork";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import Checkbox from "shared/ui/inputs/Checkbox/Checkbox";
import ExpandableFileInput from "shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";
import InputBase from "shared/ui/inputs/InputBase";

import { LOCALIZATION_CONFIG } from "../../../../../../../constants/localization";
import { IFactInterval, IPlanInterval } from "types/interfaces/FactInterval";
import { IFile } from "types/interfaces/Files";

import sendEmailImage from "images/sendEmail.png";

import styles from "./WorkSharingModalContent.module.scss";

interface IProps {
  onClose: () => void;
  onCloseWholeModal: () => void;
  interval:
    | Pick<IFactInterval, "id" | "count" | "measure" | "interval_organisation" | "provider_feedback_msg">
    | Pick<IPlanInterval, "id" | "count" | "measure" | "interval_organisation" | "provider_feedback_msg">;
  onIntervalReload: (id: number) => void;
  objectId: number | string;
  files: IFile[];
  onAddFile: (file: File) => void;
  onDeleteFile: (fileId: number) => void;
  isPlan?: boolean;
}

const WorkApprovingSharedModalContent: React.FC<IProps> = ({
  onClose,
  onCloseWholeModal,
  interval,
  onIntervalReload,
  objectId,
  files,
  onAddFile,
  onDeleteFile,
  isPlan,
}) => {
  const {
    count,
    countHandler,
    approveHandler,
    isApproving,
    isLoading,
    isDeclining,
    isSuccessfullyDeclined,
    declineHandler,
    setIsDeclining,
    commentHandler,
    comment,
  } = useReviewSharedWork(interval, onIntervalReload, objectId, isPlan);

  const navbarDeclineText = isPlan ? "Отклонение плана работы" : "Отклонение работы";
  const declinedText = isPlan ? "План отклонен" : "Работа отклонена";
  const declineText = `Вы отклоняете ${isPlan ? "план" : "работу"}. Введите обоснование.`;

  const navbarApproveText = isPlan ? "Принятие плана работы" : "Принятие работы";
  const approvedText = isPlan ? "План успешно принят" : "Работа успешно принята";

  const navbarInfoText = `Сведения о ${isPlan ? "плане" : "работе"}`;

  if (isSuccessfullyDeclined)
    return (
      <>
        <NavBar text={navbarDeclineText} onClick={onClose} isExist />
        <img src={declinePng} className={styles.img} />
        <div className={styles.result}>
          <div className={cn(styles.resultImg, styles.decline)}>
            {isLoading ? <Spinner className={styles.spinner} /> : <CheckIcon />}
          </div>
          <div className={styles.resultText}>{!isLoading && declinedText}</div>
        </div>
        <BottomControls isExists isDoubleBtns>
          <ButtonBase tertiary onClick={onCloseWholeModal}>
            Закрыть
          </ButtonBase>
        </BottomControls>
      </>
    );

  if (isDeclining)
    return (
      <>
        <NavBar text={navbarDeclineText} onClick={() => setIsDeclining(false)} isExist />
        <img src={declinePng} className={styles.declineImg} />
        <div className={styles.declineText}>{declineText}</div>
        <h2 className={styles.title}>Обоснование отклонения:</h2>
        <div className={styles.label}>*Укажие причину отклонения</div>
        <ReactTextareaAutosize
          onChange={commentHandler}
          placeholder={"Введите текст комментария с обоснованием"}
          value={comment}
          minRows={3}
          maxRows={10}
        />
        {/* <div className={styles.checkbox}>
                <Checkbox checked onCheck={() => null}>уведомить по email</Checkbox>
            </div> */}
        <ExpandableFileInput
          title="Вложения (поясняющие документы):"
          files={files}
          canExtractFiles={false}
          onRemoveFileDirectly={onDeleteFile}
          onUploadFileDirectly={onAddFile}
        />
        <BottomControls isExists isDoubleBtns>
          <ButtonBase tertiary onClick={() => setIsDeclining(false)}>
            Отменить
          </ButtonBase>
          <ButtonBase className={styles.dangerBtn} primary onClick={declineHandler}>
            Подтвердить
          </ButtonBase>
        </BottomControls>
      </>
    );

  if (isApproving) {
    return (
      <>
        <NavBar text={navbarApproveText} onClick={onClose} isExist />
        <img src={sendEmailImage} className={styles.img} />
        <div className={styles.result}>
          <div className={styles.resultImg}>{isLoading ? <Spinner className={styles.spinner} /> : <CheckIcon />}</div>
          <div className={styles.resultText}>{!isLoading && approvedText}</div>
        </div>
        <BottomControls isExists isDoubleBtns>
          <ButtonBase tertiary onClick={onCloseWholeModal}>
            Закрыть
          </ButtonBase>
        </BottomControls>
      </>
    );
  }

  return (
    <>
      <NavBar text={navbarInfoText} onClick={onClose} isExist />
      <h2 className={styles.title}>От кого:</h2>
      <div className={styles.pair}>
        <WorkSharingInfoItem
          name="Наименование компании"
          value={interval?.interval_organisation?.name || "Не указано"}
        />
        <WorkSharingInfoItem
          name="ФИО контактного лица"
          value={interval?.interval_organisation?.contact_full_name || "Не указано"}
        />
      </div>
      <div className={styles.pair}>
        <WorkSharingInfoItem
          name={LOCALIZATION_CONFIG.taxesNumberLabel}
          value={interval?.interval_organisation?.itn || "Не указано"}
        />
        <WorkSharingInfoItem name="Email" value={interval?.interval_organisation?.email || "Не указано"} />
      </div>
      <h2 className={styles.title}>Что проверить:</h2>
      <WorkSharingInfoItem
        name={`Кол-во ${interval?.measure}:`}
        className={styles.pairLine}
        customInnerComponent={
          /* @ts-ignore */
          <InputBase value={count} onChange={countHandler} />
        }
      />
      <h2 className={styles.title}>Обоснование передачи:</h2>
      <div className={styles.comment}>{interval?.provider_feedback_msg}</div>
      <ExpandableFileInput /* files.length */
        title="Дополнительные вложения (документы):"
        files={files}
        canExtractFiles={true}
        canUploadFiles={false}
        canDeleteFiles={false}
      />
      <BottomControls isExists /*isDoubleBtns*/>
        {/* <ButtonBase tertiary onClick={() => setIsDeclining(true)}> */}
        {/* Отклонить */}
        {/* </ButtonBase> */}
        <ButtonBase primary onClick={approveHandler}>
          Принять
        </ButtonBase>
      </BottomControls>
    </>
  );
};

export default WorkApprovingSharedModalContent;
