import { compose } from "redux";

import { APPROVAL_TYPES } from "redux/modules/_TODO/orderCard/approval/constants";
import { approve } from "redux/modules/_TODO/orderCard/approval/thunks/approve";

export const optionsIds = {
  approveOrder: "approveOrder",
  unApproveOrder: "unApproveOrder",
  approvePayment: "approvePayment",
  unApprovePayment: "unApprovePayment",
};

const getPartialApprove = (options) => (dispatch, orderId) => compose(dispatch, approve)(options, orderId);

export const options = {
  approveOrder: {
    name: "Согласовать заказ",
    callback: getPartialApprove({ isApprove: true, approvalType: APPROVAL_TYPES.order }),
  },
  unApproveOrder: {
    name: "Отменить согласование заказа",
    callback: getPartialApprove({ isApprove: false, approvalType: APPROVAL_TYPES.order }),
    variant: "secondary",
  },
  approvePayment: {
    name: "Согласовать оплату",
    callback: getPartialApprove({ isApprove: true, approvalType: APPROVAL_TYPES.payment }),
  },
  unApprovePayment: {
    name: "Отменить согласование оплаты",
    callback: getPartialApprove({ isApprove: false, approvalType: APPROVAL_TYPES.payment }),
    variant: "secondary",
  },
};
