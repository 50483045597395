import { ChangeEventHandler, KeyboardEventHandler, useCallback, useState } from "react";

export const useEditSectionName = (
  sectionName: string,
  onSaveEditName?: (name: string, status?: string) => void,
  status?: "new" | "confirmed"
) => {
  const [isEditing, setIsEditing] = useState(false);
  const [changedName, setChangedName] = useState(() => sectionName);

  const onChangeNameInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setChangedName(e.target.value),
    []
  );

  const onSubmitEditedSectionName = useCallback(() => {
    setIsEditing(false);
    if (changedName === sectionName) return;
    onSaveEditName?.(changedName, status);
  }, [onSaveEditName, sectionName, changedName]);

  const onKeyDownNameInput: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key !== "Enter") return;
      onSubmitEditedSectionName();
    },
    [onSubmitEditedSectionName]
  );

  return {
    isEditing,
    setIsEditing,
    onChangeNameInput,
    onSubmitEditedSectionName,
    onKeyDownNameInput,
    changedName,
  };
};
