export enum FilesExtensionsEnum {
  xls = "xls",
  xlsx = "xlsx",
  ods = "ods",

  doc = "doc",
  docx = "docx",
  odt = "odt",
  txt = "txt",
  rtf = "rtf",

  ppt = "ppt",
  pptx = "pptx",
  odp = "odp",

  pdf = "pdf",
  djvu = "djvu",
  xml = "xml",

  png = "png",
  jpg = "jpg",
  svg = "svg",
}

export const TABLE_EXTENSIONS_ARR = [
  FilesExtensionsEnum.xls,
  FilesExtensionsEnum.xlsx,
  FilesExtensionsEnum.ods,
];

export const IMG_EXTENSIONS_ARR = [
  FilesExtensionsEnum.png,
  FilesExtensionsEnum.jpg,
  FilesExtensionsEnum.svg,
];

export const TEXT_EXTENSIONS_ARR = [
  FilesExtensionsEnum.doc,
  FilesExtensionsEnum.docx,
  FilesExtensionsEnum.odt,
];
