import cn from "classnames";
import React, { useEffect, useState } from "react";

import styles from "./RadioSquares.module.scss";

export type ElementType = {
  id: any;
  name: string;
};

export interface IRadioSquaresProps {
  options: ElementType[];
  defaultValue?: any;
  onClick?: (id: any) => void;
  rows?: number;
  cols?: number;
  label?: string;
  value?: any;
}

const RadioSquares: React.FC<IRadioSquaresProps> = ({
  options,
  defaultValue,
  onClick,
  rows = 1,
  cols,
  label,
  value,
}) => {
  const [activeElement, setActiveElement] = useState<ElementType | undefined>(
    options.find((x) => x.id?.toString() === defaultValue?.toString())
  );
  const handleElementChange = (element: ElementType) => {
    onClick && onClick(element.id);
    setActiveElement(element);
  };
  useEffect(() => {
    if (!value) return;
    const candidate = options.find((x) => x.id?.toString() === value.toString());
    if (!candidate) return;
    handleElementChange(candidate);
  }, [value]);

  return (
    <div className={styles.radioSquares}>
      {label && <span className={styles.label}>{label}</span>}
      {!options.length && <span>Нет данных</span>}
      <div
        className={styles.container}
        /* style={{
          gridTemplateRows: `repeat(${rows}, 1fr)`,
          gridTemplateColumns: `repeat(${cols || options.length}, 1fr)`,
        }} */
      >
        {options.map((option) => (
          <div
            className={cn(styles.element, {
              [styles.activeElement]: activeElement?.id === option.id,
            })}
            onClick={() => handleElementChange(option)}
            key={option.id}
          >
            <div className={styles.circle} />
            <span className={styles.elementName}>{option.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioSquares;
