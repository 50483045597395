import axios from "axios";

import { IPaginationParams } from "./types";

import { errorCatcher } from "../../../../utils/helpers/errorCatcher";

export function apiGetKs6aFile(objectId: number, year: number) {
  return axios
    .get(`/building/${objectId}/reports/ks6a/${year}/`)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiGetKs6aList(objectId: number, year: number, paginationParams?: IPaginationParams) {
  const config = {
    params: {
      year,
      ...paginationParams,
    },
  };
  return axios
    .get(`/building/${objectId}/reports/ks6a/list/`, config)
    .then(({ data }) => data.results)
    .catch(errorCatcher);
}
