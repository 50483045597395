import axios from "axios";
import moment from "moment";
import { Dispatch } from "redux";
import { createSelector } from "reselect";

import { calculateTotals } from "../../../../components/pages/Journal/components/Ks6a/components/Ks6aTable/utils";

import { apiGetKs6aFile, apiGetKs6aList } from "./ks6aApi";

import { ActionType, IKs6aState, Ks6aTableScrollPositionType } from "./types";

const moduleName = "ks6a";
const DOWNLOAD_FILE = `${moduleName}/DOWNLOAD_FILE`;
const SET_MEASURE = `${moduleName}/SET_MEASURE`;
const LOAD_LIST = `${moduleName}/LOAD_LIST`;
const SET_FILE_URL = `${moduleName}/SET_FILE_URL`;
const SET_MONTH_TOTAL = `${moduleName}/SET_MONTH_TOTAL`;
const SET_TOTAL_PRICE = `${moduleName}/SET_TOTAL_PRICE`;
const SET_TOTAL_COUNT = `${moduleName}/SET_TOTAL_COUNT`;
const SET_YEAR = `${moduleName}/SET_YEAR`;
const SET_TABLE_SCROLL_POSITION = `${moduleName}/SET_TABLE_SCROLL_POSITION`;
const SET_TABLE_VIEW_WIDTH_PERCENT = `${moduleName}/SET_TABLE_VIEW_WIDTH_PERCENT`;
const SET_IS_LOADING_LIST = `${moduleName}/SET_IS_LOADING_LIST`;

const initialState: IKs6aState = {
  file: null,
  measure: 1,
  list: [],
  fileUrl: "",
  monthTotal: {},
  total: 0,
  totalCount: 0,
  other: 0,
  year: moment().year(),
  tableScrollPosition: {
    percent: 0,
    eventFrom: "table",
  },
  tableViewWidthPercent: 16.7,
  isLoadingList: false,
};

export default (state = initialState, action: ActionType<any>) => {
  switch (action.type) {
    case DOWNLOAD_FILE:
      return { ...state, file: action.payload };
    case SET_FILE_URL:
      return { ...state, fileUrl: action.payload };
    case SET_MEASURE:
      return { ...state, measure: action.payload };
    case LOAD_LIST:
      return { ...state, list: action.payload, isLoadingList: false };
    case SET_MONTH_TOTAL:
      return {
        ...state,
        monthTotal: action.payload,
      };
    case SET_TABLE_VIEW_WIDTH_PERCENT:
      return {
        ...state,
        tableViewWidthPercent: action.payload,
      };
    case SET_TABLE_SCROLL_POSITION:
      return {
        ...state,
        tableScrollPosition: action.payload,
      };
    case SET_TOTAL_PRICE:
      return {
        ...state,
        total: action.payload,
      };
    case SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case SET_YEAR:
      return {
        ...state,
        year: action.payload,
      };
    case SET_IS_LOADING_LIST:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    default:
      return state;
  }
};

export const stateSelector = (state: any) => state[moduleName];
export const ks6aListSelector = createSelector(stateSelector, (state) => state.list);
export const ks6aFileSelector = createSelector(stateSelector, (state) => state.file);
export const ks6aFileUrlSelector = createSelector(stateSelector, (state) => state.fileUrl);
export const ks6aTableMeasureSelector = createSelector(stateSelector, (state) => state.measure);
export const ks6aTableTotalSelector = createSelector(stateSelector, (state) => state.total);
export const ks6aTableTotalCountSelector = createSelector(stateSelector, (state) => state.totalCount);
export const ks6aTableOtherSelector = createSelector(stateSelector, (state) => state.other);
export const ks6aTableMonthTotalSelector = createSelector(stateSelector, (state) => state.monthTotal);
export const ks6aYearSelector = createSelector(stateSelector, (state) => state.year);
export const ks6aTableScrollPositionSelector = createSelector(stateSelector, (state) => state.tableScrollPosition);
export const ks6aTableViewWidthPercentSelector = createSelector(stateSelector, (state) => state.tableViewWidthPercent);
export const ks6aIsLoadingListSelector = createSelector(stateSelector, (state) => state.isLoadingList);

export const downloadKs6aFile = (objectId: number, year: number) => {
  return async (dispatch: Dispatch) => {
    const file = await apiGetKs6aFile(objectId, year);
    dispatch({
      type: DOWNLOAD_FILE,
      payload: file,
    });
  };
};

export const setKs6aFileUrl = (objectId: number, year: number) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_FILE_URL,
      payload: (axios.defaults?.baseURL || "") + `/building/${objectId}/reports/ks6a/${year}/`,
    });
  };
};

export const loadKs6aList = (objectId: number, year: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_IS_LOADING_LIST,
      payload: true,
    });

    const list = (await apiGetKs6aList(objectId, year, { limit: 100 })) || [];

    dispatch({
      type: LOAD_LIST,
      payload: list,
    });

    const { newTotalPrice, newMonthTotal, newTotalCount } = calculateTotals(list);

    dispatch({
      type: SET_MONTH_TOTAL,
      payload: newMonthTotal,
    });
    dispatch({
      type: SET_TOTAL_PRICE,
      payload: newTotalPrice,
    });
    dispatch({
      type: SET_TOTAL_COUNT,
      payload: newTotalCount,
    });
  };
};

export const setMeasure = (measure: number) => {
  return (dispatch: Dispatch<ActionType<number>>) => {
    dispatch({
      type: SET_MEASURE,
      payload: measure,
    });
  };
};

export const setYear = (year: number) => {
  return (dispatch: Dispatch<ActionType<number>>) => {
    dispatch({
      type: SET_YEAR,
      payload: year,
    });
  };
};

export const setTableScrollPositionAction = (value: Ks6aTableScrollPositionType) => {
  return (dispatch: Dispatch<ActionType<Ks6aTableScrollPositionType>>) => {
    dispatch({
      type: SET_TABLE_SCROLL_POSITION,
      payload: value,
    });
  };
};

export const setTableViewWidthPercent = (value: number) => {
  return (dispatch: Dispatch<ActionType<number>>) => {
    dispatch({
      type: SET_TABLE_VIEW_WIDTH_PERCENT,
      payload: value,
    });
  };
};
