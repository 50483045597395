import classNames from "classnames";
import moment from "moment";
import React, { FC, useRef, useState } from "react";

import { hours, minutes } from "./constatnts";

import useOnClickOutside from "hooks/useOnClickOutside";

import DownIcon from "../../../../images/arrow-down-filled.svg";
import UpIcon from "../../../../images/arrow-up-filled.svg";

import styles from "./TimePicker.module.scss";

interface IProps {
  deadline: string;
  setValue: (date: moment.Moment) => void;
  disabled?: boolean;
}

export const TimePicker: FC<IProps> = ({ deadline, setValue, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const deadlineHours = moment(deadline).format("HH");
  const deadlineMinutes = moment(deadline).format("mm");

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  const openPickerHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const increaseHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const result = moment(deadline).set("minute", +deadlineMinutes + 1);
    setValue(result);
  };

  const decreaseHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const result = moment(deadline).set("minute", +deadlineMinutes - 1);
    setValue(result);
  };

  const selectHourHandler = (value: string) => {
    const result = moment(deadline).set("hour", +value);
    setValue(result);
  };

  const selectMinuteHandler = (value: string) => {
    const result = moment(deadline).set("minute", +value);
    setValue(result);
  };

  return (
    <div
      className={classNames(styles.inputContainer, disabled && styles.disabled)}
      tabIndex={1}
      onClick={openPickerHandler}
      ref={wrapperRef}
    >
      <span className={styles.time}>{deadlineHours}</span> : <span className={styles.time}>{deadlineMinutes}</span>
      {!disabled && (
        <div className={styles.buttons}>
          <button onClick={increaseHandler}>
            <img src={DownIcon} alt="" />
          </button>
          <button onClick={decreaseHandler}>
            <img src={UpIcon} alt="" />
          </button>
        </div>
      )}
      {isOpen && (
        <div className={styles.timeSelectContainer}>
          <ul className={styles.timeList}>
            {hours.map((hour) => (
              <li onClick={() => selectHourHandler(hour)}>{hour}</li>
            ))}
          </ul>
          <ul className={styles.timeList}>
            {minutes.map((minute) => (
              <li onClick={() => selectMinuteHandler(minute)}>{minute}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
