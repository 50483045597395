import React, { lazy, Suspense } from "react";

const EmptyListIcon = lazy(() => import("./EmptyListIcon"));

const EmptyListIconLazy = () => (
  <Suspense fallback={null}>
    <EmptyListIcon />
  </Suspense>
);

export default React.memo(EmptyListIconLazy);
