import React from 'react';

export default function OpenLink({ color, width }) {
  return (
    <svg width={width}
         height='16'
         viewBox='0 0 17 16'
         fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 15.9914C2.93913 15.9914 1.92202 15.5697 1.17188 14.8195C0.42173 14.0694 0 13.0522 0 11.9914V4.83123C0 3.77036 0.42173 2.75325 1.17188 2.0031C1.92202 1.25296 2.93913 0.831227 4 0.831227H7.66699V2.34881H4.51562C3.71998 2.34881 2.95714 2.6651 2.39453 3.22771C1.83192 3.79032 1.51562 4.55316 1.51562 5.34881V11.4777C1.51562 12.2734 1.83192 13.0381 2.39453 13.6008C2.95714 14.1634 3.71998 14.4777 4.51562 14.4777H11.3994C12.1951 14.4777 12.9579 14.1634 13.5205 13.6008C14.0831 13.0381 14.3994 12.2734 14.3994 11.4777V7.79021H15.917V11.9953C15.9151 13.0556 15.4929 14.0717 14.7432 14.8215C13.9934 15.5712 12.9773 15.9934 11.917 15.9953L4 15.9914ZM5.91113 8.76677L9.81641 4.86638L8.74414 3.79412C8.6253 3.67563 8.53759 3.52933 8.49023 3.36834C8.44288 3.20734 8.4369 3.03816 8.47266 2.8742C8.50842 2.71024 8.58522 2.55749 8.69531 2.43084C8.80541 2.30418 8.94515 2.20588 9.10254 2.14763L14.6816 0.0753681C14.8614 0.00813539 15.0566 -0.00633971 15.2441 0.0343525C15.4317 0.0750447 15.6036 0.168208 15.7393 0.303884C15.8749 0.439559 15.9691 0.612468 16.0098 0.799977C16.0505 0.987487 16.036 1.18277 15.9688 1.36248L13.8945 6.9406C13.846 7.07658 13.7671 7.19983 13.6631 7.29998C13.4756 7.48714 13.221 7.59295 12.9561 7.59295C12.6911 7.59295 12.4375 7.48714 12.25 7.29998L11.0039 6.05388L7.10352 9.95427L5.91113 8.76677Z'
        fill={color} />
    </svg>

  );
}