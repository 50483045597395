import React from "react";
import { Link } from "react-router-dom";

import EventEntry from "../../../../../UI/atoms/EventItem/EventEntry";
import EventItemBody, { IEventItemBodyProps } from "../../../../../UI/atoms/EventItem/EventItemBody";

import { EVENT_TYPES, PaymentEvent, SupplyEvent, TaskEvent } from "../../../../../../types/interfaces/Event";
import { eventsNames } from "../../../constants";

import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { transformDigitToFinancial } from "../../../../../../utils/formatters/transformDigitToFinancial";

export interface IEventItemProps {
  event: PaymentEvent | SupplyEvent | TaskEvent;
  statusSwitcher: IEventItemBodyProps["eventStatusSwitcher"];
}

const EventItem: React.FC<IEventItemProps> = ({ event, statusSwitcher }) => {
  const urlAdapter = useUrlModuleWithObjectId();

  if (event.event_type === EVENT_TYPES.payment) {
    event = event as PaymentEvent;
    return (
      <EventItemBody
        status={event.status}
        name={eventsNames.payment}
        eventType={EVENT_TYPES.payment}
        isCompleted={event.is_completed}
        eventStatusSwitcher={statusSwitcher}
      >
        <EventEntry
          label={`Тип:`}
          value={event?.payment?.payment_term?.payment_type_name}
          bubbleLabel={`Счет №${event?.invoice?.number}`}
          bubbleLink={`/${urlAdapter}/documents/bills/${event?.invoice?.id}`}
        />
        <EventEntry
          label={`Сумма:`}
          value={transformDigitToFinancial(event?.payment?.amount, {
            withFloat: true,
            dropZeros: true,
            withCurrencySign: true,
          })}
          bubbleLabel={`Заказ №${event?.order?.number}`}
          bubbleLink={`/${urlAdapter}/orders/${event?.order?.id}`}
        />
      </EventItemBody>
    );
  }

  if (event.event_type === EVENT_TYPES.supply) {
    event = event as SupplyEvent;
    return (
      <EventItemBody
        status={event.status}
        name={eventsNames.supply}
        eventType={EVENT_TYPES.supply}
        isCompleted={event.is_completed}
        eventStatusSwitcher={statusSwitcher}
      >
        <EventEntry label={`Поставщик:`} value={event.packinglist.provider.name} />
        <EventEntry label={`Объект:`} value={event.building.name} />
        <EventEntry
          label={`Сумма:`}
          value={transformDigitToFinancial(event.packinglist_amount, {
            withFloat: true,
            dropZeros: true,
            withCurrencySign: true,
          })}
          bubbleLabel={`УПД №${event.packinglist.number}`}
          bubbleLink={`/${urlAdapter}/documents/packing-lists/${event.packinglist.id}`}
        />
      </EventItemBody>
    );
  }

  if (event.event_type === EVENT_TYPES.task) {
    event = event as TaskEvent;
    return (
      <EventItemBody
        status={event.task.status}
        name={`${eventsNames.task} №${event?.task?.number}`}
        eventType={EVENT_TYPES.task}
        isCompleted={event.is_completed}
        eventStatusSwitcher={statusSwitcher}
      >
        <EventEntry label={"Название: "} value={<Link to={`/tasks/all/0/${event.task.id}`}>{event.task.name}</Link>} />
        <EventEntry label={"Проект:"} value={event.task.building_name} />
        <EventEntry label={"Исполнитель:"} value={event.executor.name} />
      </EventItemBody>
    );
  }

  return null;
};

export default React.memo(EventItem);
