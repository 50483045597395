import React from "react";

import InputBase, { VALUE_TYPES } from "../../../../../../shared/ui/inputs/InputBase";

import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";

import styles from "../Products.module.scss";

export interface IFactCountRow {
  id: number;
  is_union: boolean;
  start_price: number;
  is_removed: boolean;
  count_get?: string | number;
}

export interface IProps {
  onChange?: (changedProductId: number, changedItemName: string) => void;
  canEdit?: boolean;
  isPurchaser?: boolean;
}

export const FactCountColumnCell =
  ({ canEdit, isPurchaser, onChange }: IProps) =>
  (_cellData: any, row: IFactCountRow) => {
    const isShownEditableInput = !row.is_union && canEdit && isPurchaser;

    return (
      <div>
        {isShownEditableInput ? (
          <InputBase /* @ts-ignore */
            variant="secondary"
            valueType={VALUE_TYPES.NUMBER} /* @ts-ignore */
            value={row.count_get} /* @ts-ignore */
            onChange={onChange!(row.id, "count_get")}
            disabled={!isPurchaser || row.is_removed}
            classNameInput={styles.input}
            input={undefined}
            className={undefined}
            label={undefined}
            onBlur={undefined}
          />
        ) : (
          /* @ts-ignore */
          <span>{dropNonSignificantZeros(row.count_get)}</span>
        )}
      </div>
    );
  };

export default FactCountColumnCell;
