import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

import PlanFactExtraCostItem from "./PlanFactExtraCostItem/PlanFactExtraCostItem";
import { usePlanFactExtraCosts } from "./usePlanFactExtraCosts";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import ListLayout from "shared/ui/layout/ListLayout/ListLayout";

import { LOCALIZATION_CONFIG } from "constants/localization";

import { useTypedParams } from "utils/hooks/useTypedParams";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./PlanFactExtraCostSubsections.module.scss";

const PlanFactExtraCostSubsections = () => {
  const history = useHistory();
  const { sectionId, objectId } = useTypedParams();
  const { extraCosts } = usePlanFactExtraCosts();

  const section = useMemo(() => {
    return extraCosts?.find((el) => el.id === +sectionId);
  }, [extraCosts]);

  const backNavigationProps = useMemo(() => {
    return {
      title: section?.name!,
      onBack: () => {
        history.push(`/objects/${objectId}/plan-fact/finance/extra/`);
      },
    };
  }, []);

  return (
    <ListLayout
      isWithBackNavigation
      backNavigationProps={backNavigationProps}
      items={section?.subSections!}
      render={(el) => (
        <PlanFactExtraCostItem
          key={el.id}
          name={el.name}
          count={el.costs?.length}
          innerContent={
            <div className={styles.content}>
              {el.costs?.map((cost) => (
                <TableReusableRow isHoverable={false} key={cost.id} className={styles.row}>
                  <TableReusableCell>{cost.title}</TableReusableCell>
                  <TableReusableCell>
                    {transformDigitToFinancial(cost.amount)}
                    {LOCALIZATION_CONFIG.currency}
                  </TableReusableCell>
                </TableReusableRow>
              ))}
            </div>
          }
        />
      )}
    />
  );
};

export default PlanFactExtraCostSubsections;
