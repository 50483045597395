import React, { useCallback, useEffect, useMemo, useState } from "react";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import {
  addFilesToWorker,
  deleteFileFromWorker,
  editWorker,
  getFilesForWorker,
  workersFilesSelector,
} from "redux/modules/common/building/workers";

import InputFiles from "components/UI/atoms/_TODO/InputFiles";
import { MAX_DISPLAYING_FILES_COUNT } from "components/UI/atoms/_TODO/InputFiles/InputFiles";

import EditableWorkerInfo from "../../../../../EditableWorkerInfo/EditableWorkerInfo";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import Button from "shared/ui/controls/ButtonBase";
import ProgressBar from "shared/ui/dataDisplay/ProgressBar/ProgressBar";
import { Switch } from "shared/ui/inputs/Switch/Switch";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import {
  VIEW_MANUFACTURING_WORKERS_CARD_DELETE_FILES,
  VIEW_MANUFACTURING_WORKERS_CARD_EXPORT_FILES,
} from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";

import { getAddedAndDeletedFiles } from "./utils/getAddedAndDeletedFiles";
import { getLocalizedDate } from "utils/formatters/getLocalizedDate";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";
import downloadFileByUrl from "utils/helpers/downloadFileByUrl";
import { serializeFiles } from "utils/serializeFiles";

import CalendarIcon from "images/icons/calendarBlue.svg";
import EditIcon from "images/icons/edit-square-icon-white.svg";
import UserGrayIcon from "images/icons/user-gray-icon.svg";

import styles from "./WorkerCard.module.scss";

const WorkerCard = ({ worker, isOpen, onClose, buildingId }) => {
  const dispatch = useDispatch();
  const workerFiles = useSelector((state) => workersFilesSelector(state)[worker.id]);

  const [isShowEditModal, setIsShowEditModal] = useState(false);

  const serializedFiles = useMemo(() => workerFiles && serializeFiles(workerFiles), [workerFiles]);

  const addFiles = useCallback(
    (files) => {
      compose(dispatch, addFilesToWorker)(buildingId, worker.id, files);
    },
    [dispatch, buildingId, worker.id]
  );

  const deleteFile = useCallback(
    (file) => compose(dispatch, deleteFileFromWorker)(buildingId, worker.id, file.id),
    [dispatch, buildingId, worker.id]
  );

  const downloadFiles = useCallback(
    () => serializedFiles.forEach((file) => downloadFileByUrl(file.file.link, file.file.name)),
    [serializedFiles]
  );

  const manageFiles = useCallback(
    (managedFiles) => {
      const { addedFiles, deletedFiles } = getAddedAndDeletedFiles(serializedFiles, managedFiles);
      if (addedFiles.length !== 0) addFiles(addedFiles);
      deletedFiles.forEach(deleteFile);
    },
    [serializedFiles, addFiles, deleteFile]
  );

  useEffect(() => {
    if (workerFiles) return;
    compose(dispatch, getFilesForWorker)(buildingId, worker.id);
  }, [workerFiles, buildingId, worker.id, dispatch]);

  const haveExportFilesPermission = usePermission(VIEW_MANUFACTURING_WORKERS_CARD_EXPORT_FILES);
  const haveDeleteFilesPermission = usePermission(VIEW_MANUFACTURING_WORKERS_CARD_DELETE_FILES);

  return (
    <SliderModal className={styles.workerCard} isOpen={isOpen} closeHandler={onClose}>
      {!isShowEditModal ? (
        workerFiles ? (
          <>
            <header className={styles.header}>
              <div>
                {worker?.avatar ? (
                  <img
                    className={styles.avatar}
                    src={worker.avatar}
                    alt={`${worker.first_name}-Фото`}
                    width={113}
                    height={113}
                  />
                ) : (
                  <div className={styles.avatarNone}>
                    <img src={UserGrayIcon} alt="Нет фото" width={42} height={42} />
                  </div>
                )}
              </div>
              <div className={styles.headerInfo}>
                <h2 className={`${styles.title} ${styles.dotsIfLong}`}>
                  {worker.last_name} {worker.first_name} {worker.middle_name}
                </h2>
                <div className={styles.headerInfoItem}>
                  <span className={styles.medium}>Должность</span>
                  <span>{worker?.post_rank_info?.post_title || "-"}</span>
                </div>
                <div className={styles.headerInfoItem}>
                  <span>Штатный</span>
                  <div className={styles.switcher}>
                    <Switch checked={worker?.is_staff} disabled />
                  </div>
                </div>
              </div>
            </header>
            <section className={styles.section}>
              <div className={styles.mainInfo}>
                <div className={styles.mainInfoItem}>
                  <span className={`${styles.medium} ${styles.mainInfoTitle}`}>Дата начала работы</span>
                  <div className={styles.itemDate}>
                    <span>{getLocalizedDate(worker.start_work_at) || "-"}</span>
                    {worker.start_work_at && (
                      <img src={CalendarIcon} alt="Дата" width={17} height={17} className={styles.calendarIcon} />
                    )}
                  </div>
                </div>
                <div className={styles.mainInfoItem}>
                  <span className={`${styles.medium} ${styles.mainInfoTitle}`}>Степень/разряд</span>
                  <span className={styles.textWithBackground}>{worker.post_rank_info?.rank_display || "-"}</span>
                </div>
                <div className={styles.mainInfoItem}>
                  <span className={`${styles.medium} ${styles.mainInfoTitle}`}>Загрузка</span>
                  <ProgressBar completed={90} />
                </div>
                <div className={styles.mainInfoItem}>
                  <span className={`${styles.medium} ${styles.mainInfoTitle}`}>Табельный №</span>
                  <span className={styles.textWithBackground}>{worker?.table_num || "-"}</span>
                </div>
                <div className={styles.mainInfoItem}>
                  <span className={`${styles.medium} ${styles.mainInfoTitle}`}>Ставка</span>
                  <span>{transformDigitToFinancial(worker.stake, { withFloat: true, withCurrencySign: true })}</span>
                </div>
                <div className={styles.mainInfoItem}>
                  <span className={`${styles.medium} ${styles.mainInfoTitle}`}>Telegram</span>
                  <span>{worker?.telegram || "отсутствует"}</span>
                </div>
                <div className={styles.mainInfoItem}>
                  <span className={`${styles.medium} ${styles.mainInfoTitle}`}>E-mail</span>
                  <span className={styles.email} title={worker?.email}>
                    {worker?.email || "отсутствует"}
                  </span>
                </div>
                <div className={styles.mainInfoItem}>
                  <span className={`${styles.medium} ${styles.mainInfoTitle}`}>Номер телефона</span>
                  {worker?.phone_number ? (
                    <InputMask
                      className={styles.phoneInput}
                      mask="+7 (999) 999-99-99"
                      readOnly
                      name="number"
                      value={worker?.phone_number}
                    />
                  ) : (
                    <span>отсутствует</span>
                  )}
                </div>
                <div className={`${styles.mainInfoItem} ${styles.bigWidth}`}>
                  <span className={`${styles.medium} ${styles.mainInfoTitle}`}>Код авторизации Telegram</span>
                  <span>{worker?.tg_code || "отсутствует"}</span>
                </div>
              </div>
            </section>
            <section className={`${styles.section} ${styles.pBottom}`}>
              <header className={styles.attachHeader}>
                <h3 className={`${styles.medium} ${styles.subtitle}`}>Вложения: {serializedFiles.length}</h3>
                {haveExportFilesPermission && (
                  <span className={styles.downloadButton} onClick={downloadFiles}>
                    Выгрузить
                  </span>
                )}
              </header>
              <InputFiles
                value={serializedFiles}
                setValue={manageFiles}
                canExpand={serializedFiles.length > MAX_DISPLAYING_FILES_COUNT}
                canExport={haveExportFilesPermission}
                permissions={{
                  addFiles: false,
                  deleteFiles: false,
                }}
                isFileViewer
              />
            </section>
            <footer className={`${styles.section} ${styles.footer}`}>
              <Button type="button" onClick={() => setIsShowEditModal(true)}>
                <img src={EditIcon} alt="Редактировать" />
                Редактировать
              </Button>
            </footer>
          </>
        ) : (
          <Spinner />
        )
      ) : (
        <EditableWorkerInfo
          isOpen={isOpen}
          onClose={onClose}
          buildingId={buildingId}
          submitAction={editWorker}
          workerFullName={`${worker.last_name} ${worker.first_name} ${worker.middle_name}`}
          worker={worker}
          workerId={worker.id}
          workerFiles={serializedFiles}
          isShowEditActions
        />
      )}
    </SliderModal>
  );
};

export default React.memo(WorkerCard);
