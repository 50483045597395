import classNames from "classnames";
import { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import Select from "components/UI/atoms/Select";
import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import { useFinanceMeasure } from "../../../../../features/financeMeasure";

import { LOCALIZATION_CONFIG } from "constants/localization";

import valueInThousandsOrMillions from "utils/formatters/valueInThousandsOrMillions";

import styles from "./FinanceFooter.module.scss";

const options = [
  { id: "q1", name: "I квартал" },
  { id: "q2", name: "II квартал" },
  { id: "q3", name: "III квартал" },
  { id: "q4", name: "IV квартал" },
  { id: "year", name: "Год" },
];

interface IProps {
  totalsData: IFinanceTreeItem | null;
}

const FinanceFooter: React.FC<IProps> = ({ totalsData }) => {
  const { measureInTitle, measure } = useFinanceMeasure();
  const { objectId } = useParams<any>();

  const [period, setPeriod] = useState("year");
  const totalsSecRow = useMemo(() => {
    return {
      plan: totalsData?.data[`${period}_plan`],
      fact: totalsData?.data[`${period}_fact`],
      diff: totalsData?.data[`${period}_diff`],
    };
  }, [totalsData, period]);

  const isObject = +(objectId ?? 0) > 0;

  return (
    <div className={classNames(styles.footer)}>
      <div className={styles.secRowWrapper}>
        <div className={styles.secRow}>
          <div>
            План {measureInTitle} {LOCALIZATION_CONFIG.currency}:{" "}
            <span>{valueInThousandsOrMillions(+totalsSecRow?.plan!, measure, false)}</span>
          </div>
          <div>
            Факт {measureInTitle} {LOCALIZATION_CONFIG.currency}:{" "}
            <span>{valueInThousandsOrMillions(+totalsSecRow?.fact!, measure, false)}</span>
          </div>
          <div>
            Итого {measureInTitle} {LOCALIZATION_CONFIG.currency}:{" "}
            <span>{valueInThousandsOrMillions(+totalsSecRow?.diff!, measure, false)}</span>
          </div>
        </div>
      </div>
      <div className={styles.legend}>
        <div className={styles.legendRow}>
          <span className={styles.legendText}>Итого по {isObject ? "объекту" : "проекту"} за:</span>
          <Select
            options={options}
            size={"small" as any}
            className={styles.select}
            selectClassName={styles.selectSelect}
            placement="top"
            classNameOption={styles.option}
            onChange={(v) => setPeriod(v as string)}
            value={period}
          />
        </div>
      </div>
    </div>
  );
};

export default FinanceFooter;
