import cn from "classnames";
import { FC, memo } from "react";

import ButtonBase from "../../../../../../../shared/ui/controls/ButtonBase";

import { IShift, ShiftStatusesEnum } from "../types";

import { serializeShiftPlanData } from "../utils";

import { ReactComponent as IconLink } from "../../../../../../../shared/assets/icons/IconLink.svg";

import styles from "../ShiftsModal.module.scss";

interface IShiftsListProps {
  shifts: IShift[];
  onShiftClick: (planId: number) => void;
  showLoadMore: boolean;
  isLoadingMore: boolean;
  onLoadMore: () => void;
}

const ShiftsList: FC<IShiftsListProps> = (props) => {
  const { shifts, onShiftClick, showLoadMore, isLoadingMore, onLoadMore } = props;

  const handleShiftClick = (planId: number, deactivated: boolean) => () => {
    if (deactivated) {
      return;
    }

    onShiftClick(planId);
  };

  return (
    <div className={styles.shiftsList}>
      {shifts.map((shift) => {
        const { parent_section, section, name, count, measure, start_at, end_at, plan_id, deactivated } =
          serializeShiftPlanData(shift);

        const isNew = shift.status === ShiftStatusesEnum.new;

        return (
          <div
            className={cn(styles.shiftsListItem, { [styles.selectable]: isNew && !deactivated })}
            onClick={handleShiftClick(plan_id, deactivated || !isNew)}
            key={shift.id}
          >
            <div className={styles.breadcrumbs}>
              <span>{parent_section}</span>&nbsp;<span>/ {section}</span>
            </div>

            <div className={styles.name}>{name}</div>

            <div className={styles.info}>
              <div className={styles.infoItem}>
                <span>Объем:</span>&nbsp;
                <span>
                  {count} {measure}
                </span>
              </div>
              <div className={styles.infoItem}>
                <span>Выполнение:</span>&nbsp;
                <span className={cn({ [styles.accent]: !deactivated })}>
                  {start_at} – {end_at}
                </span>
                {isNew && !deactivated && (
                  <IconLink className={cn(styles.iconLink, { [styles.deactivated]: deactivated })} />
                )}
              </div>
            </div>
          </div>
        );
      })}
      {!shifts.length && <div className={styles.centerContent}>Нет данных</div>}
      {showLoadMore && (
        <div className={styles.loadMore}>
          <ButtonBase secondary onClick={onLoadMore} isLoading={isLoadingMore}>
            Показать еще
          </ButtonBase>
        </div>
      )}
    </div>
  );
};

export default memo(ShiftsList);
