import { chatRoomTypes } from "./types";

export const roomFormatter = (room: chatRoomTypes) => {
  switch (room) {
    case chatRoomTypes.REQUISITION:
      return chatRoomTypes.REQUISITION_SEND;
    case chatRoomTypes.ORDER:
      return chatRoomTypes.ORDER_SEND;
    default:
      break;
  }
};
