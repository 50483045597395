import React, { useCallback, useMemo, useRef } from "react";
import { Field, Form } from "react-final-form";

import {
  addAdditionalMaterial,
  addMaterial,
} from "../../../../../../redux/modules/common/building/sections/sectionsApi";

import Select from "../../../../../../components/UI/atoms/Select";

import ButtonBase from "../../../../../../shared/ui/controls/ButtonBase";
import SelectedSectionField from "../../SelectedSectionField/SelectedSectionField";
import Input, { VALUE_TYPES } from "shared/ui/inputs/InputBase";
import InputBase from "shared/ui/inputs/InputBase";

import { measures } from "constants/constant";

import useFormFocus from "../../../../../../utils/hooks/useFormFocus";

import { composeFieldValidators, maxLength, required } from "utils/formHelpers/validations";

import styles from "./NewProductForm.module.scss";

const TAG_OPTIONS = [
  { id: "expendable_material", name: "Расходный материал" },
  { id: "instrument", name: "Инструмент" },
  { id: "provision", name: "Обеспечение" },
  { id: "shaped_element", name: "Фасонный элемент" },
];

const getMeasureOptions = measures.map((measure) => ({ id: measure, name: measure }));

const NewProductForm = ({ objectId, sections, handleAddProducts, permissions }) => {
  const formRef = useRef(null);
  const inputRootRef = useFormFocus(formRef?.current?.form?.submit);

  const handleSubmit = useCallback(
    async ({ section, ...values }) => {
      if (values?.from_estimate) {
        addAdditionalMaterial(objectId, {
          ...values,
          section_id: section?.id,
          section_name: section?.name,
          ret_own_mat: false,
        }).then((response) => handleAddProducts([{ ...response.data, required_count: response.data.count }]));
      } else {
        addMaterial(objectId, {
          ...values,
          section_id: section?.id,
          section_name: section?.name,
          ret_own_mat: false,
        }).then((response) => handleAddProducts([{ ...response.data, required_count: response.data.count }]));
      }
    },
    [handleAddProducts, objectId]
  );

  const fromEstimateOptions = useMemo(() => {
    const result = [];

    if (permissions.viewAddOutOfEstimateProducts) result.push({ id: false, name: "Вне сметы" });
    if (permissions.viewAddAdditionalProducts) result.push({ id: true, name: "Доп" });

    return result;
  }, [permissions]);

  return (
    <div className={styles.container}>
      <Form
        ref={formRef}
        onSubmit={(values) => handleSubmit(values)}
        mutators={{
          setFromEstimate: ([v], state, tools) => {
            tools.changeValue(state, "tag", () => undefined);
            tools.resetFieldState("tag");
          },
        }}
        validate={(values) => {
          const errors = {};

          if (typeof values.from_estimate !== "boolean") {
            errors.from_estimate = required()();
          } else {
            if (!values.from_estimate && values.tag === undefined) {
              errors.tag = required()();
            }
            if (values.from_estimate && values.section === undefined) {
              errors.section = required()();
            }
          }

          return errors;
        }}
        render={({ handleSubmit, reset, values, form }) => (
          <form onSubmit={(e) => handleSubmit(e).then(reset)}>
            <div className={styles.content} ref={inputRootRef}>
              <div className={styles.formRow}>
                <Field
                  name="section"
                  // validate={!values.from_estimate && composeFieldValidators(required())}
                  component={SelectedSectionField}
                  sections={sections}
                  objectId={objectId}
                  disabled={!values.from_estimate}
                />
                <div>
                  <Field
                    name="from_estimate"
                    options={fromEstimateOptions}
                    value={values.from_estimate}
                    className={styles.select}
                    render={({ input, meta }) => (
                      <Select
                        {...input}
                        name="from_estimate"
                        className={styles.select}
                        value={values.from_estimate}
                        options={fromEstimateOptions}
                        meta={meta}
                        onChange={(e) => {
                          input.onChange(e);
                          form.mutators.setFromEstimate(e);
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Field
                    name="tag"
                    // validate={values.from_estimate && composeFieldValidators(required())}

                    selfControlled
                    disabled={values.from_estimate}
                    render={({ input, meta }) => (
                      <Select
                        options={TAG_OPTIONS}
                        className={styles.select}
                        input={input}
                        meta={{
                          ...meta,
                          touched: meta.touched || values["from_estimate"] === false,
                        }}
                      />
                    )}
                  />
                </div>
                <ButtonBase onClick={handleSubmit}>Добавить</ButtonBase>
              </div>
              <div className={styles.formRow2}>
                <div className={styles.field}>
                  <Field
                    label="Наименование *"
                    name="name"
                    component={Input}
                    selfControlled={true}
                    classNameInput={styles.input}
                    validate={composeFieldValidators(required(), maxLength(256))}
                  />
                </div>
                <div className={styles.field}>
                  <Field
                    label="Марка"
                    name="brand"
                    component={Input}
                    selfControlled={true}
                    classNameInput={styles.input}
                  />
                </div>
                <div className={styles.field}>
                  <Field
                    label="Код"
                    name="number"
                    component={Input}
                    selfControlled={true}
                    classNameInput={styles.input}
                  />
                </div>
                <div className={styles.field}>
                  <Field
                    name="count"
                    render={(props) => {
                      return (
                        <InputBase
                          classNameInput={styles.input}
                          name="count"
                          meta={props.meta}
                          value={props.input.value.replaceAll(",", ".")}
                          label="Количество *"
                          onChange={(e) => props.input.onChange(e)}
                          valueType={VALUE_TYPES.NUMBER}
                        />
                      );
                    }}
                    validate={composeFieldValidators(required(), maxLength(256))}
                  />
                </div>
                <div>
                  <Field
                    label="Ед.измерения *"
                    name="measure"
                    component={Select}
                    options={getMeasureOptions}
                    selfControlled={true}
                    className={styles.select2}
                    validate={composeFieldValidators(required())}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default React.memo(NewProductForm);
