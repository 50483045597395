export const TASK_MANAGER_LINK = "https://task.osla.ru/";

export const VK_GROUP_LINK = "https://vk.com/osla_ru";
export const TELEGRAM_MESSAGE_LINK = "https://t.me/oslaconstruction";
export const WHATSAPP_MESSAGE_LINK = "https://wa.me/79585236751";

export const OSLA_CONTACT_EMAIL_ADDRESS = "info@osla.ru";
export const OSLA_CONTACT_PHONE_NUMBER = "+7 (812) 701-00-05";

export const PRIVACY_POLICY_LINK =
  "https://docs.google.com/document/d/1l9_Xa_nEzEwvwdqNkeou7kaObs8XAzx7fXGg5IyA6gs/edit?usp=sharing";

export const PHONE_CLEAN_PATTERN = /[\(\)\s-]/g;

export const HOME_PATH = "/";
