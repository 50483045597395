import axios from "axios";
import { compose } from "redux";

import { getEmployeesRequestAction, getEmployeesSuccessAction } from "../actions";

import { errorCatcher } from "../../../../../../utils/helpers/errorCatcher";

const getEmployees = (orderId, options) => (dispatch) => {
  dispatch(getEmployeesRequestAction(options.approvalType));

  axios
    .get(`/${options.userType || "purchaser"}/orders/${orderId}/${options.approvalType}/employees/`)
    .then((response) => {
      compose(dispatch, getEmployeesSuccessAction)(response.data, options.approvalType);
    })
    .catch(errorCatcher);
};

export { getEmployees };
