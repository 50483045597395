import moment from "moment";

import { IAdvancePaymentItem, IGuaranteeObligationItem } from "./types";

import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";

export const prepareGuaranteeObligations = (arr: IGuaranteeObligationItem[]) => {
  return arr.map((el: IGuaranteeObligationItem) => {
    return { ...el, percent: String(dropNonSignificantZeros(el.percent)), type: el.delay ? "delay" : "completion" };
  });
};

export const prepareAdvancePaymentItem = (arr: IAdvancePaymentItem[], budget: number) => {
  return arr
    .sort((a, b) => (moment(a.date).isAfter(moment(b.date)) ? 1 : -1))
    .map((el) => {
      return {
        ...el,
        date: moment(el.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
        percent: dropNonSignificantZeros((+el.amount / budget) * 100, 2),
      };
    });
};
