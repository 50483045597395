import cn from "classnames";
import React from "react";

import { IAosrDetail, IAosrFactProduct, IPackingItemDocument } from "redux/modules/common/building/journal/aosr/types";

import Icon from "../../../../../../../../../../../_LEGACY/UI/_LEGACY_Icon/Icon";
import errorIcon from "../../../../../../../../../../UI/atoms/ExpandPillTrigger/icons/error.svg";
import successIcon from "../../../../../../../../../../UI/atoms/ExpandPillTrigger/icons/success.svg";

import ConfirmModal from "../../../../../../../../../../../entities/ConfirmationModal/ConfirmModal";
import ButtonBase from "../../../../../../../../../../../shared/ui/controls/ButtonBase";
import ExpandPillTrigger from "../../../../../../../../../../../shared/ui/controls/ExpandPillTrigger/ExpandPillTrigger";
import StatusElement from "shared/ui/dataDisplay/StatusElement/StatusElement";

import { getDocLabel, isDocOutdated } from "../../constants";

import downloadFile from "../../../../../../../../../../../utils/helpers/download";

import removeIcon from "images/icons/removeIcon.svg";

import styles from "../AosrMaterials.module.scss";

export interface IAosrMaterialRowProps {
  material: IAosrDetail["materials"][0];
  isBlueBg?: boolean;
  isEdit?: boolean;
  source: string;
  onRemove?: () => void;
  onAdd?: () => void;
  type?: "removed";
}

const AosrMaterialRow: React.FC<IAosrMaterialRowProps> = ({
  material,
  isBlueBg,
  isEdit,
  onRemove,
  onAdd,
  type,
  source,
}) => {
  const docs = material.attachments;
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const toggleExpanded = React.useCallback(() => setIsExpanded((prevState) => !prevState), []);

  const [isConfirmOpen, setIsConfirmOpen] = React.useState<boolean>(false);
  const handleCloseConfirm = React.useCallback(() => setIsConfirmOpen(false), []);

  const docsPill = React.useMemo<{ variant: "success" | "error"; count: number }>(() => {
    const count = docs?.length;
    if (docs?.some(isDocOutdated)) {
      return { variant: "error", count };
    } else {
      return { variant: "success", count };
    }
  }, [docs]);

  const isRemovedMaterial = type === "removed";

  const removeOrAddMaterial = React.useCallback(() => {
    isRemovedMaterial ? onAdd?.() : onRemove?.();
  }, [isRemovedMaterial]);

  const handleButtonClick = React.useCallback(() => setIsConfirmOpen(true), []);

  return (
    <div className={cn(styles.materialRow, { [styles.blueBg]: isBlueBg })}>
      <div className={styles.materialRowHead}>
        <span>{material.material_name}</span>
        {isEdit ? (
          <ButtonBase className={styles.removeButton} onClick={handleButtonClick} primary>
            <Icon icon={removeIcon} className={cn(styles.removeIcon, { [styles.plusIcon]: isRemovedMaterial })} />
          </ButtonBase>
        ) : (
          !!docsPill.count && (
            <ExpandPillTrigger
              isExpanded={isExpanded}
              toggleExpand={toggleExpanded}
              label={`${docsPill.count}`}
              variant={docsPill.variant}
              className={styles.pill}
            />
          )
        )}
      </div>
      {isExpanded && !isEdit && (
        <div className={styles.materialRowBody}>
          {docs?.map((doc) => {
            const isOutdated = isDocOutdated(doc);
            const label = getDocLabel(doc);
            return (
              <div className={styles.doc} key={doc.id}>
                <StatusElement
                  status={isOutdated ? "fail" : "success"}
                  isTransparent
                  dateEnd={doc.end_at}
                  portalClassName={styles.statusPopup}
                />
                <span onClick={() => downloadFile(doc.file_path, doc.originalname ?? label)}>{label}</span>
              </div>
            );
          })}
        </div>
      )}
      <ConfirmModal
        isOpen={isConfirmOpen}
        onClose={handleCloseConfirm}
        action={removeOrAddMaterial}
        variant={isRemovedMaterial ? "primary" : "secondary"}
      >
        {isRemovedMaterial ? (
          <span>
            {`Вы действительно хотите добавить материал в ${source}?`}
            <br />
            <br />
            Информация о материале будет отражена в печатной версии акта
          </span>
        ) : (
          `Вы действительно хотите удалить материал из ${source}?`
        )}
      </ConfirmModal>
    </div>
  );
};

export default React.memo(AosrMaterialRow);
