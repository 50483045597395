import { message } from "antd";
import axios from "axios";
import update from "immutability-helper";
import { compose } from "redux";
import { createSelector } from "reselect";

import { errorCatcher } from "../../../../utils/helpers/errorCatcher";
import { messageErrorHandler } from "utils/helpers/errorHandler";

const WORKERS_PAGINATION_LIMIT = 10;

const moduleName = "workers";
const LOAD_WORKERS = `${moduleName}/LOAD_WORKERS`;
const SET_WORKERS_LIST = `${moduleName}/SET_WORKERS_LIST`;
const ADD_TO_WORKERS_LIST = `${moduleName}/ADD_TO_WORKERS_LIST`;
const ADD_DELETE_WORKER_ID = `${moduleName}/ADD_DELETE_WORKER_ID`;
const ADD_NEW_WORKER_ID = `${moduleName}/ADD_NEW_WORKER_ID`;
const REMOVE_DELETE_WORKER_ID = `${moduleName}/REMOVE_DELETE_WORKER_ID`;
const SET_CREATE_OR_EDIT_STATUS = `${moduleName}/SET_CREATE_OR_EDIT_STATUS`;
const SET_IS_CREATE_OR_EDIT_LOADING = `${moduleName}/SET_IS_CREATE_OR_EDIT_LOADING`;
const SET_WORKERS_COUNT_UPDATED = `${moduleName}/SET_WORKERS_COUNT_UPDATED`;
const SET_IS_UPDATING_TURNOUT = `${moduleName}/SET_IS_UPDATING_TURNOUT`;
const SET_WORKERS_COUNT_WORK = `${moduleName}/SET_WORKERS_COUNT_WORK`;
const CHANGE_WORKER_COUNT_WORK = `${moduleName}/CHANGE_WORKER_COUNT_WORK`;
const ADD_WORKER_COUNT_WORK = `${moduleName}/ADD_WORKER_COUNT_WORK`;
const RESET_WORKERS_TO_INITIAL = `${moduleName}/RESET_WORKERS_TO_INITIAL`;
const RESET_WORKERS_COUNT_WORK_TO_INITIAL = `${moduleName}/RESET_WORKERS_COUNT_WORK_TO_INITIAL`;
const SET_FILES_FOR_WORKER = `${moduleName}/SET_FILES_FOR_WORKER`;
const ADD_FILES_TO_WORKER = `${moduleName}/ADD_FILES_TO_WORKER`;
const DELETE_FILE_FROM_WORKER = `${moduleName}/DELETE_FILE_FROM_WORKER`;
const ADD_WORKER_TO_WORKERS_LIST = `${moduleName}/ADD_WORKER_TO_WORKERS_LIST`;
const SET_IS_WORKERS_LOADING = `${moduleName}/SET_IS_WORKERS_LOADING`;

const GET_FILES_LIMIT = 100;

const initialState = {
  workers: null,
  workersCountWork: null,
  deleteWorkersId: [],
  newWorkerId: null,
  isLoading: true,
  workersFiles: {},
  isCreateOrEditSuccess: false,
  isCreateOrEditLoading: false,
  isWorkerCountUpdated: false,
  isUpdatingTurnout: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_WORKERS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_WORKERS_LIST:
      return {
        ...state,
        workers: payload,
        isLoading: false,
      };
    case SET_FILES_FOR_WORKER: {
      const { workerId, files } = payload;
      return {
        ...state,
        workersFiles: { ...state.workersFiles, [workerId]: files },
      };
    }
    case DELETE_FILE_FROM_WORKER: {
      const { workerId, deletedFileId } = payload;
      return {
        ...state,
        workersFiles: {
          ...state.workersFiles,
          [workerId]: state.workersFiles[workerId].filter((file) => file.id !== deletedFileId),
        },
      };
    }
    case ADD_FILES_TO_WORKER: {
      const { workerId, files } = payload;
      return {
        ...state,
        workersFiles: {
          [workerId]: files,
        },
      };
    }
    case ADD_TO_WORKERS_LIST:
      return update(state, {
        isLoading: { $set: false },
        workers: state.workers
          ? {
              results: { $push: payload.results },
              count: { $set: payload.count },
            }
          : { $set: payload },
      });
    case ADD_DELETE_WORKER_ID:
      return {
        ...state,
        deleteWorkersId: [...state.deleteWorkersId, payload],
      };
    case ADD_NEW_WORKER_ID:
      return {
        ...state,
        newWorkerId: payload?.id,
      };
    case REMOVE_DELETE_WORKER_ID:
      return {
        ...state,
        deleteWorkersId: state.deleteWorkersId.filter((id) => id !== payload),
      };
    case SET_CREATE_OR_EDIT_STATUS:
      return {
        ...state,
        isCreateOrEditSuccess: payload,
      };
    case SET_IS_CREATE_OR_EDIT_LOADING:
      return {
        ...state,
        isCreateOrEditLoading: payload,
      };
    case SET_WORKERS_COUNT_WORK:
      return {
        ...state,
        workersCountWork: payload,
        isLoading: false,
      };
    case SET_WORKERS_COUNT_UPDATED:
      return {
        ...state,
        isWorkersCountUpdated: payload,
      };
    case SET_IS_UPDATING_TURNOUT:
      return {
        ...state,
        isUpdatingTurnout: {
          ...state.isUpdatingTurnout,
          [payload.id]: payload.isUpdating,
        },
      };
    case ADD_WORKER_COUNT_WORK:
      return update(state, {
        workersCountWork: {
          results: { $push: [payload] },
          count: { $set: state.workersCountWork.count + 1 },
        },
      });
    case CHANGE_WORKER_COUNT_WORK:
      return update(state, {
        workersCountWork: {
          results: {
            $set: state.workersCountWork.results.map((item) => (item.id === payload.id ? payload : item)),
          },
        },
      });
    case RESET_WORKERS_TO_INITIAL:
      return update(state, { workers: { $set: initialState.workers } });
    case RESET_WORKERS_COUNT_WORK_TO_INITIAL:
      return update(state, {
        workersCountWork: { $set: initialState.workersCountWork },
      });
    case ADD_WORKER_TO_WORKERS_LIST:
      return update(state, {
        workers:
          state.workers.count >= WORKERS_PAGINATION_LIMIT && state.workers.count !== state.workers.results.length
            ? { count: { $set: state.workers.count + 1 } }
            : { results: { $push: [payload] } },
      });
    case SET_IS_WORKERS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const workersCreateOrEditSuccessSelector = createSelector(stateSelector, (state) => state.isCreateOrEditSuccess);
export const workersCreateOrEditLoadingSelector = createSelector(stateSelector, (state) => state.isCreateOrEditLoading);
export const workerCountLoadingSelector = createSelector(stateSelector, (state) => state.isWorkerCountLoading);
export const workersDeleteLoadingSelector = createSelector(stateSelector, (state) => state.deleteWorkersId);
export const workersSelector = createSelector(stateSelector, (state) => state.workers);
export const workersLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const workersCountWorkSelector = createSelector(stateSelector, (state) => state.workersCountWork);
export const workerNewIdSelector = createSelector(stateSelector, (state) => state.newWorkerId);
export const workersFilesSelector = createSelector(stateSelector, (state) => state.workersFiles);
export const workersUpdatingTurnoutSelector = createSelector(stateSelector, (state) => state.isUpdatingTurnout);

export const setWorkersCountUpdatedAction = (payload) => ({
  type: SET_WORKERS_COUNT_UPDATED,
  payload,
});
export const setWorkersIsUpdatingTurnoutAction = ({ id, isUpdating }) => ({
  type: SET_IS_UPDATING_TURNOUT,
  payload: { id, isUpdating },
});
export const setWorkersList = (payload) => ({
  type: SET_WORKERS_LIST,
  payload,
});
export const setCreateOrEditStatusAction = (payload) => ({
  type: SET_CREATE_OR_EDIT_STATUS,
  payload,
});
export const setIsCreateOrEditLoadingAction = (payload) => ({
  type: SET_IS_CREATE_OR_EDIT_LOADING,
  payload,
});
export const resetWorkersToInitialAction = () => ({
  type: RESET_WORKERS_TO_INITIAL,
});
export const setFilesForWorkerAction = (payload) => ({
  type: SET_FILES_FOR_WORKER,
  payload,
});
export const deleteFileFromWorkerAction = (payload) => ({
  type: DELETE_FILE_FROM_WORKER,
  payload,
});
export const addFilesToWorkerAction = (payload) => ({
  type: ADD_FILES_TO_WORKER,
  payload,
});
export const addWorkerToWorkersList = (payload) => ({
  type: ADD_WORKER_TO_WORKERS_LIST,
  payload,
});
export const setIsWorkersLoadingAction = (payload) => ({
  type: SET_IS_WORKERS_LOADING,
  payload,
});

export const loadWorkers = (buildingId, params) => (dispatch) => {
  axios
    .get(`/building/${buildingId}/workerlist/`, { params })
    .then((response) => {
      dispatch(setWorkersList(response.data));
    })
    .catch(errorCatcher);
};

export const addToWorkers = (buildingId, params) => {
  const config = { params };

  return (dispatch) => {
    axios
      .get(`/building/${buildingId}/workerlist/`, config)
      .then((response) => {
        dispatch({
          type: ADD_TO_WORKERS_LIST,
          payload: response.data,
        });
      })
      .catch(errorCatcher);
  };
};

export const loadWorkersCountWork = (buildingId, params) => (dispatch) => {
  axios
    .get(`/building/${buildingId}/timesheet_count_work/`, { params: params })
    .then((response) => dispatch({ type: SET_WORKERS_COUNT_WORK, payload: response.data }))
    .catch(errorCatcher);
};

export const createWorker = (buildingId, data) => (dispatch) => {
  dispatch(setIsWorkersLoadingAction(true));
  const formData = new FormData();

  for (let item in data) {
    if (data[item] !== undefined) formData.append(item, data[item]);
  }

  axios
    .post(`/building/${buildingId}/workers/`, formData)
    .then((response) => {
      dispatch({
        type: ADD_NEW_WORKER_ID,
        payload: response.data,
      });
      dispatch(addWorkerToWorkersList(response.data));
      dispatch(setCreateOrEditStatusAction(true));
      message.success("Сотрудник успешно добавлен");
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.errors[0].message;

      if (typeof errorMessage === "object") {
        messageErrorHandler(Object.values(errorMessage).flat());
      } else {
        messageErrorHandler([errorMessage]);
      }
    })
    .finally(() => {
      dispatch(setIsWorkersLoadingAction(false));
    });
};

export const deleteWorker = (buildingId, workerId) => {
  return (dispatch) => {
    dispatch({
      type: ADD_DELETE_WORKER_ID,
      payload: workerId,
    });
    axios
      .delete(`/building/${buildingId}/workers/${workerId}/`)
      .then(() => {
        dispatch(loadWorkers(buildingId, false));
        dispatch({
          type: REMOVE_DELETE_WORKER_ID,
          payload: workerId,
        });
      })
      .catch(errorCatcher);
  };
};

export const editWorker = (buildingId, data, workerId) => (dispatch) => {
  dispatch(setIsCreateOrEditLoadingAction(true));
  const formData = new FormData();

  for (let item in data) {
    if (data[item] !== undefined) formData.append(item, data[item]);
  }

  return axios
    .patch(`/building/${buildingId}/workers/${workerId}/`, formData)
    .then(() => {
      dispatch(loadWorkers(buildingId, false));
      dispatch(setCreateOrEditStatusAction(true));
      message.success("Данные сотрудника сохранены");
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.errors[0].message;

      if (typeof errorMessage === "object") {
        messageErrorHandler(Object.values(errorMessage).flat());
      } else {
        messageErrorHandler([errorMessage]);
      }
    })
    .finally(() => {
      dispatch(setIsCreateOrEditLoadingAction(false));
    });
};

export const addWorkerCountWork = (buildingId, work) => (dispatch) => {
  axios
    .post(`/building/${buildingId}/timesheet_count_work/`, work)
    .then((response) => {
      dispatch({ type: ADD_WORKER_COUNT_WORK, payload: response.data });
      dispatch(setWorkersCountUpdatedAction(true));
    })
    .catch(errorCatcher);
};

export const changeWorkerCountWork = (buildingId, work) => (dispatch) => {
  dispatch(setWorkersIsUpdatingTurnoutAction({ id: work.id, isUpdating: true }));
  axios
    .patch(`/building/${buildingId}/timesheet_count_work/${work.id}/`, work)
    .then((response) => {
      dispatch({ type: CHANGE_WORKER_COUNT_WORK, payload: response.data });
      dispatch(setWorkersCountUpdatedAction(true));
    })
    .catch(errorCatcher)
    .finally(() => dispatch(setWorkersIsUpdatingTurnoutAction({ id: work.id, isUpdating: false })));
};

export const getFilesForWorker = (buildingId, workerId) => (dispatch) => {
  axios
    .get(`/building/${buildingId}/workers/${workerId}/files/`, {
      params: { limit: GET_FILES_LIMIT },
    })
    .then((response) => compose(dispatch, setFilesForWorkerAction)({ files: response.data.results, workerId }))
    .catch(errorCatcher);
};

export const addFilesToWorker = (buildingId, workerId, files) => (dispatch) => {
  const formData = new FormData();

  files.forEach((item) => item.file?.path && formData.append("files", item.file));

  axios
    .post(`/building/${buildingId}/workers/${workerId}/files/`, formData)
    .then((response) => compose(dispatch, addFilesToWorkerAction)({ files: response.data, workerId }))
    .catch(errorCatcher);
};

export const deleteFileFromWorker = (buildingId, workerId, deletedFileId) => (dispatch) => {
  axios
    .delete(`/building/${buildingId}/workers/${workerId}/files/${deletedFileId}/`)
    .then(() => compose(dispatch, deleteFileFromWorkerAction)({ deletedFileId, workerId }))
    .catch(errorCatcher);
};
