import React, { FC, useMemo } from "react";

import LearningMenuItem from "../LearningMenuItem/LearningMenuItem";
import LearningSublist from "../LearningSublist/LearningSublist";
import ProgressBar from "shared/ui/dataDisplay/ProgressBar/ProgressBar";

import { ILearningItem, ILearningNormItem, ILearningSublists } from "../../model/types";

import styles from "./LearningSidebar.module.scss";

interface IProps {
  confirmedPercents?: number;
  items?: ILearningItem[];
  activeLesson?: ILearningItem;
  setActiveLesson?: React.Dispatch<React.SetStateAction<ILearningItem | null>>;
  lists: ILearningNormItem[] | null;
}

const LearningSidebar: FC<IProps> = ({ confirmedPercents, items, activeLesson, setActiveLesson, lists }) => {
  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <h1>Обучение Osla</h1>
        <div className={styles.progressTitle}>
          <span>Прохождение:</span>
          <span>{confirmedPercents ?? 0}%</span>
        </div>
        <ProgressBar completed={confirmedPercents ?? 0} direction="row-reverse" containerClassName={styles.progress} />
      </div>
      <div className={styles.nav}>
        <>
          {lists?.map((el, i) => (
            <LearningSublist key={el.uid} item={el} activeLesson={activeLesson} setActiveLesson={setActiveLesson} />
          ))}
        </>

        {false &&
          items?.map((el, i, arr) => (
            <LearningMenuItem
              key={el.key}
              item={el}
              isActive={el.key === activeLesson?.key}
              isLastAvailable={arr[i - 1]?.is_confirmed && (arr[i + 1]?.isDisabled || !arr[i + 1])}
            />
          ))}
      </div>
    </div>
  );
};

export default LearningSidebar;
