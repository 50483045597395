import { LOCALIZATION_CONFIG } from "constants/localization";

const switchMeasureName = (measure: number) => {
  switch (measure) {
    case 1e12:
      return "трлн.";
    case 1e9:
      return "млрд.";
    case 1e6:
      return "млн.";
    case 1e3:
      return "тыс.";
    default:
      return "";
  }
};

const splitThousandsLocal = (num: string) => {
  const fixedLength = Number(num) % 1 > 0 ? 2 : 0;
  return parseFloat(num || "0")
    .toFixed(fixedLength)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const formatNumberWithMeasure = (value: number | string, measure: number) => {
  if (!measure) return `0 ${LOCALIZATION_CONFIG.currency}`;
  if (!value) return "0 " + switchMeasureName(measure) + LOCALIZATION_CONFIG.currency;
  value = Number(value);
  const numericValue = measure > 1 ? Number(value / measure).toFixed(2) : Number(value).toFixed(2);
  return splitThousandsLocal(numericValue) + " " + switchMeasureName(measure) + LOCALIZATION_CONFIG.currency;
};
