import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IFile } from "utils/serializeFiles";

interface FileUploadState {
  storage: Record<number, { value: IFile[]; inited: boolean }>;
}

export const initialState: FileUploadState = {
  storage: {},
};

const FileUploadSlice = createSlice({
  name: "FileUpload",
  initialState,
  reducers: {
    storeFiles: (state, action: PayloadAction<{ files: IFile[]; storageId: number }>) => {
      const { files, storageId } = action.payload;
      if (!state.storage[storageId]) {
        state.storage[storageId] = { value: [], inited: true };
      }
      state.storage[storageId].value ||= [];
      state.storage[storageId].value = state.storage[storageId].value.concat(files);
    },
    removeStoredFile: (state, action: PayloadAction<{ fileId: number; storageId: number }>) => {
      const { fileId, storageId } = action.payload;
      if (!state.storage[storageId]) return;
      // @ts-ignore
      state.storage[storageId].value = state.storage[storageId].value.filter((el) => Number(el.id) !== Number(fileId));
    },
    flushStorage: (state, action: PayloadAction<number>) => {
      delete state.storage[action.payload];
    },
  },
});

export const fileUploadActions = FileUploadSlice.actions;

export default FileUploadSlice.reducer;
