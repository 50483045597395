import axios from "axios";
import React from "react";

import { IReplacementInExpenditure } from "pages/Handler/ui/ProHandler/components/Body/components/Expenditures/ReplacedExpenditure/ReplacedExpenditure";

import { IListResponseData } from "types/ListResponse";

export const useJournalReplacements = (buildingId: string, expenditureId: number, isOpen: boolean) => {
  const [replacements, setReplacements] = React.useState<IReplacementInExpenditure[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!isOpen) return;
    if (!replacements.length) {
      setIsLoading(true);
    }
    axios
      .get<IListResponseData<IReplacementInExpenditure>>(`building/${buildingId}/expenditures/${expenditureId}/replacements/`)
      .then(({ data }) => {
        setReplacements(data.results);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [expenditureId, buildingId, isOpen]);

  return { replacements, isLoading };
};
