import React from "react";
import TextareaAutosize from "react-textarea-autosize";

import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";

import styles from "./TextArea.module.scss";

interface IProps {
  label?: React.ReactNode;
  error?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  maxRows?: number;
}

const TextArea: React.FC<IProps> = ({ label, error, value, onBlur, onChange, onFocus, maxRows = 3 }) => {
  return (
    <LabeledItem label={label} error={error} isDefaultHeight={false}>
      <TextareaAutosize maxRows={maxRows} value={value} onBlur={onBlur} onChange={onChange} onFocus={onFocus} />
    </LabeledItem>
  );
};

export default TextArea;
