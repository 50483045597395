import React from 'react'
import styles from './Events.module.scss'
import { NavLink, useLocation, useParams } from 'react-router-dom'

export const Events = () => {

  const location = useLocation()
  const {objectId} = useParams()
  const locationArray = location.pathname.split('/')

  return (
    <div className={styles.wrapper}>
      {objectId 
      ? 
        <header className={styles.innerLinks}>
          <NavLink to={`/${locationArray[1]}/${objectId}/events/calendar`} className={styles.link} activeClassName={styles.activeLink}>Календарь</NavLink>
          <NavLink to={`/${locationArray[1]}/${objectId}/events/payments`} className={styles.link} activeClassName={styles.activeLink}>Платежи</NavLink>
          <NavLink to={`/${locationArray[1]}/${objectId}/events/supplies`} className={styles.link} activeClassName={styles.activeLink}>Поставки</NavLink>
        </header>
      :
        <header className={styles.innerLinks}>
          <NavLink to={`/${locationArray[1]}/events/calendar`} className={styles.link} activeClassName={styles.activeLink}>Календарь</NavLink>
          <NavLink to={`/${locationArray[1]}/events/payments`} className={styles.link} activeClassName={styles.activeLink}>Платежи</NavLink>
          <NavLink to={`/${locationArray[1]}/events/supplies`} className={styles.link} activeClassName={styles.activeLink}>Поставки</NavLink>
        </header>
      }
    </div>
  )
}
