import { ILearningNormItem, ILearningSublists } from "./types";

export const learningUtils = {
  normalizeTree: (
    rawTree: ILearningSublists,
    parentsIndexes: number[] = [],
    parentUids: string[] = []
  ): ILearningNormItem[] => {
    if (!rawTree) return [];

    return rawTree.map((el, i) => ({
      uid: generateKey(i, parentsIndexes),
      isOpen: true,
      name: el.name,
      tasks: el.tasks.map((task, taskIndex, arr) => ({
        ...task,
        menuTitle: `Задание ${task.number ?? taskIndex + 1}`,
        isDisabled:
          !!arr[taskIndex - 1] &&
          !task.is_confirmed &&
          (arr[taskIndex - 1].isDisabled || !arr[taskIndex - 1].is_confirmed),
      })),
      children: learningUtils.normalizeTree(
        el.children,
        [...parentsIndexes, i],
        [...parentUids, generateKey(i, parentsIndexes)]
      ),
      parentChain: parentUids,
    }));
  },
  findNode: (tree: ILearningNormItem[], uid: string): ILearningNormItem | null => {
    if (!tree) return null;
    for (const node of tree) {
      if (node.uid === uid) {
        return node;
      }

      if (node.children && node.children.length > 0) {
        const foundInChildren = learningUtils.findNode(node.children, uid);

        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }

    return null;
  },
  openTree: (tree: ILearningNormItem[], uid: string, status: boolean) => {
    const item = learningUtils.findNode(tree, uid);

    if (!item) return tree;

    item.isOpen = status;

    return tree;
  },
};

function generateKey(index: number, parentIndexes: number[]) {
  return [...parentIndexes, index].join("_");
}
