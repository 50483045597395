import cn from "classnames";
import React from "react";

import NavigationBar from "../../../features/templates/NavigationBar";
import Header from "features/templates/Header/Header";

import styles from "./TemplateSimple.module.scss";

export interface IProps {
  children: React.ReactNode;
  contentClassName?: string;
  dataTestId?: string;
}

const TemplateSimple: React.FC<IProps> = ({ children, contentClassName, dataTestId = "templateBase" }) => {
  return (
    <>
      <Header />
      <div className={styles.container} data-testid={dataTestId}>
        <NavigationBar />
        <div className={cn(styles.content, contentClassName)}>{children}</div>
      </div>
    </>
  );
};

export default React.memo(TemplateSimple);
