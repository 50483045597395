import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { filesCountsSelector } from "redux/modules/common/filesStorage/selectors";
import { getStorageCounts } from "redux/modules/common/filesStorage/thunks";

import { IRouterParamsWithObjectId } from "types/routerTypes";

export const useFilesIndicators = () => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const indicators = useSelector(filesCountsSelector)[objectId];

  React.useEffect(() => {
    dispatch(getStorageCounts(objectId));
  }, [objectId]);

  return {
    all: indicators?.count_total,
    text: indicators?.count_txt,
    tables: indicators?.count_tbl,
    presentations: indicators?.count_prsnt,
    docs: indicators?.count_doc,
    others: indicators?.count_other,
    capacityAll: indicators?.tariff_size, // Ограничения по файлам аккаунта в Мб
    capacityUsed: indicators?.used_size,
  };
};
