import cn from "classnames";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import styles from "./DatePicker.module.scss";

export interface IDatePickerProps {
  date: Moment;
  dateStart: Moment | string;
  activeDatePoint?: "start" | "end";
  dateEnd: Moment | string;
  navigationDate: Moment;
  setDate: (_: Moment) => void;
  isDirty: boolean;
  onDirectlyChangeDate?: (date: moment.Moment) => void;
}

const DatePicker = ({
  date,
  setDate,
  dateStart,
  dateEnd,
  isDirty,
  onDirectlyChangeDate,
  activeDatePoint,
  navigationDate,
}: IDatePickerProps) => {
  const [pseudoIntervalStart, setPseudoIntervalStart] = useState<Moment | undefined>(moment(dateStart));
  const [pseudoIntervalEnd, setPseudoIntervalEnd] = useState<Moment | undefined>(moment(dateEnd));

  useEffect(() => {
    setPseudoIntervalStart(moment(dateStart));
    setPseudoIntervalEnd(moment(dateEnd));
  }, [dateStart, dateEnd]);

  const onHover = useCallback(
    (activeDate: string) => () => {
      if (!moment(dateEnd).isSame(dateStart) || !isDirty) return;
      const activeMoment = moment(activeDate);
      if (activeDatePoint === "end" && activeMoment.isAfter(dateStart)) setPseudoIntervalEnd(activeMoment);
      if (activeDatePoint === "start" && activeMoment.isBefore(dateEnd)) setPseudoIntervalStart(activeMoment);
    },
    [dateStart, dateEnd, isDirty, activeDatePoint]
  );

  const onLeave = useCallback(() => {
    if (!isDirty) return;
    setPseudoIntervalStart(moment(dateStart));
    setPseudoIntervalEnd(moment(dateEnd));
  }, [dateStart, dateEnd, isDirty]);

  const monthDaysNumbers = useMemo(
    () => Array.from({ length: moment(navigationDate, "YYYY-MM").daysInMonth() }, (v, k) => k + 1),
    [navigationDate]
  );

  const onDayClick = useCallback(
    (newDate: string) => () => {
      setDate(moment(newDate));
      onDirectlyChangeDate?.(moment(newDate));
      setPseudoIntervalStart(undefined);
      setPseudoIntervalEnd(undefined);
    },
    [setDate]
  );

  const prevMonth = useMemo(() => moment(navigationDate).subtract(1, "month"), [navigationDate]);
  const prevMonthArr = useMemo(() => Array.from({ length: prevMonth.endOf("month").day() }), [prevMonth]);

  const nextMonth = useMemo(() => moment(navigationDate).add(1, "month"), [navigationDate]);
  const nextMonthArr = useMemo(() => {
    const remainder = (moment(navigationDate).daysInMonth() + prevMonthArr.length) % 7;
    return Array.from({ length: remainder > 0 ? 7 - remainder : 0 });
  }, [navigationDate]);

  return (
    <div className={styles.container}>
      <div className={styles.dayNameBlock}>
        <div className={styles.dayName}>Пн</div>
        <div className={styles.dayName}>Вт</div>
        <div className={styles.dayName}>Ср</div>
        <div className={styles.dayName}>Чт</div>
        <div className={styles.dayName}>Пт</div>
        <div className={styles.dayName}>Сб</div>
        <div className={styles.dayName}>Вс</div>
        {prevMonthArr.map((item, index) => {
          const currentMoment = prevMonth.endOf("month").subtract(prevMonthArr.length - index - 1, "day");
          return (
            <div
              key={index}
              className={cn(styles.dayNumber, styles.secondaryDate, {
                [styles.numberWithBackground]:
                  checkIsDateInInterval(currentMoment, dateStart, dateEnd) ||
                  checkIsDateInInterval(currentMoment, pseudoIntervalStart, pseudoIntervalEnd),
                [styles.activeDate]: !!dateStart && moment(dateStart).isSame(currentMoment, "day"),
                [styles.activeDateEnd]: !!dateEnd && moment(dateEnd).isSame(currentMoment, "day"),
                [styles.pseudoEnding]: isDirty && pseudoIntervalEnd?.isSame(currentMoment, "day"),
                [styles.pseudoBeginning]: isDirty && pseudoIntervalStart?.isSame(currentMoment, "day"),
                [styles.singleDate]: isDirty && pseudoIntervalStart?.isSame(pseudoIntervalEnd),
              })}
              onClick={onDayClick(currentMoment.format("YYYY-MM-DD"))}
              onMouseEnter={onHover(currentMoment.format("YYYY-MM-DD"))}
              onMouseLeave={onLeave}
            >
              {currentMoment.date()}
            </div>
          );
        })}
        {monthDaysNumbers.map((dayNumber, index) => {
          const dateAtCurrentDayNumber = moment({
            day: dayNumber,
            month: navigationDate?.month?.(),
            year: navigationDate?.year?.(),
          });
          return (
            <div
              className={cn(styles.dayNumber, {
                [styles.holidays]: [6, 7].includes(dateAtCurrentDayNumber.isoWeekday()),
                [styles.numberWithBackground]:
                  checkIsDateInInterval(dateAtCurrentDayNumber, dateStart, dateEnd) ||
                  checkIsDateInInterval(dateAtCurrentDayNumber, pseudoIntervalStart, pseudoIntervalEnd),
                [styles.activeDate]: !!dateStart && moment(dateStart).isSame(dateAtCurrentDayNumber, "day"),
                [styles.activeDateEnd]: !!dateEnd && moment(dateEnd).isSame(dateAtCurrentDayNumber, "day"),
                [styles.pseudoEnding]: isDirty && pseudoIntervalEnd?.isSame(dateAtCurrentDayNumber, "day"),
                [styles.pseudoBeginning]: isDirty && pseudoIntervalStart?.isSame(dateAtCurrentDayNumber, "day"),
                [styles.singleDate]: isDirty && pseudoIntervalStart?.isSame(pseudoIntervalEnd),
              })}
              onClick={onDayClick(dateAtCurrentDayNumber.format("YYYY-MM-DD"))}
              onMouseEnter={onHover(dateAtCurrentDayNumber.format("YYYY-MM-DD"))}
              onMouseLeave={onLeave}
              key={index}
            >
              {dayNumber}
            </div>
          );
        })}
        {nextMonthArr.map((item, index) => {
          const currentMoment = nextMonth.startOf("month").add(index, "day");
          return (
            <div
              key={index}
              className={cn(styles.dayNumber, styles.secondaryDate, {
                [styles.numberWithBackground]:
                  checkIsDateInInterval(currentMoment, dateStart, dateEnd) ||
                  checkIsDateInInterval(currentMoment, pseudoIntervalStart, pseudoIntervalEnd),
                [styles.activeDate]: moment(dateStart).isSame(currentMoment, "day"),
                [styles.activeDateEnd]: moment(dateEnd).isSame(currentMoment, "day"),
                [styles.pseudoEnding]: isDirty && pseudoIntervalEnd?.isSame(currentMoment, "day"),
                [styles.pseudoBeginning]: isDirty && pseudoIntervalStart?.isSame(currentMoment, "day"),
                [styles.singleDate]: isDirty && pseudoIntervalStart?.isSame(pseudoIntervalEnd),
              })}
              onClick={onDayClick(currentMoment.format("YYYY-MM-DD"))}
              onMouseEnter={onHover(currentMoment.format("YYYY-MM-DD"))}
              onMouseLeave={onLeave}
            >
              {currentMoment.date()}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const checkIsDateInInterval = (
  date: Moment,
  dateStart: Moment | string | undefined,
  dateEnd: Moment | string | undefined
) => {
  if (!dateStart || !dateEnd || !moment.isMoment(date)) return false;
  return moment(date).isBetween(dateStart, dateEnd, "day");
};

export default React.memo(DatePicker);
