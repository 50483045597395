import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { updateSpecificationDetail } from "redux/modules/common/building/materials/specification";

import InputNumber from "components/UI/atoms/InputNumber";

import ButtonBase from "shared/ui/controls/ButtonBase";

import styles from "./PopoverContent.module.scss";

export interface IProps {
  defaultCount: number;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  objectId: number;
  itemId: number;
}

const PopoverContent: React.FC<IProps> = ({ defaultCount, setIsOpen, objectId, itemId }) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(String(defaultCount || 0));

  const isSaveButtonDisabled = useMemo(
    () => defaultCount === Number(count) || Number(count) > 9999,
    [count, defaultCount]
  );

  useEffect(() => {
    setCount(String(defaultCount));
  }, [defaultCount]);

  const handleSubmit = () => {
    dispatch(updateSpecificationDetail(objectId, itemId, { delay_days: count || 0 }));
    setIsOpen(false);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Укажите за сколько дней необходимо сделать заказ</h1>
      <section className={styles.section}>
        <InputNumber className={styles.counter} value={count} setValue={setCount} decimalPlace={4} />
        <span className={styles.measure}>дней</span>
      </section>
      <footer className={styles.footer}>
        <ButtonBase onClick={handleSubmit} primary medium disabled={isSaveButtonDisabled}>
          Подтвердить
        </ButtonBase>
        <ButtonBase onClick={() => setIsOpen(false)} secondary medium>
          Отменить
        </ButtonBase>
      </footer>
    </div>
  );
};

export default React.memo(PopoverContent);
