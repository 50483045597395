import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { ILearningItem, ILearningNormItem, ILearningSublists } from "./types";
import { IListResponseData } from "types/ListResponse";

import { learningUtils } from "./utils";

export interface LearningState {
  isLoading: boolean;
  data: IListResponseData<ILearningItem> | null;
  instructionsRedirects: Record<string, string>;
  //isOpen: boolean;
  isVideoModal: boolean;
  lists: ILearningNormItem[] | null;
}

const initialState: LearningState = {
  isLoading: false,
  data: null,
  instructionsRedirects: {},
  //isOpen: false,
  isVideoModal: false,
  lists: null,
};

const learningSlice = createSlice({
  name: "learning",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setInstructionsRedirects: (state, { payload }) => {
      state.instructionsRedirects = payload;
    },
    /* setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    }, */
    setIsVideoModal: (state, action: PayloadAction<boolean>) => {
      state.isVideoModal = action.payload;
    },
    setLists: (state, action: PayloadAction<ILearningSublists>) => {
      state.lists = learningUtils.normalizeTree(action.payload);
    },
    setSublistOpen: (state, action: PayloadAction<{ uid: string; status: boolean }>) => {
      if (!state.lists) return;
      state.lists = learningUtils.openTree(state.lists, action.payload.uid, action.payload.status);
    },
  },
});

export const learningActions = learningSlice.actions;

export default learningSlice.reducer;
