import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  manufacturingModalMaterialsLoadingSelector,
  modalDetailedIntervalsSelector,
  modalFilesSelector,
  modalIntervalListsSelector,
  modalLoadingsSelector,
  modalMaterialsSelector,
} from "../../../../../../redux/modules/common/building/manufacturing/selectors";
import {
  clearModalData,
  clearModalInterval,
  getModalFiles,
  getModalInterval,
  getModalIntervalList,
  removeModalFile,
  uploadModalFile,
} from "../../../../../../redux/modules/common/building/manufacturing/thunks";

import { useLoadIntervalRelations } from "../ManufacturingModal/IntervalRelationsContent/useLoadIntervalRelations";
import { useApprovePlan } from "../ManufacturingModal/PlanEditing/useApprovePlan";
import useCanApprovePlan from "../ManufacturingModal/PlanEditing/useCanApprovePlan";
import { useCanEditPlan } from "../ManufacturingModal/PlanEditing/useCanEditPlan";
import { manufacturingModalStatusSwitcher } from "../ManufacturingModal/manufacturingModalStatusSwitcher";
import { ManufacturingModalsTypesEnum, PlanTabsEnum } from "../const";
import moment from "moment/moment";

import { VIEW_CONSTRUCTING_CHART_EDIT_RELATIONS } from "../../../../../../constants/permissions/constructingPermissions";
import { VIEW_MANUFACTURING_CHART_EDIT_RELATIONS } from "../../../../../../constants/permissions/manufacturingPermissions";
import { IRouterParamsWithObjectId } from "../../../../../../types/routerTypes";

import { useCommonModulesPermissions } from "../../../../../../hooks/useCommonModulesPermissions";

const activeModule = "intervals";

interface IArgs {
  dateStart?: string;
  dateEnd?: string;
  expenditureId: number;
  sectionId: number;
  updatePlanStatus?: (status: string, id: number) => void;
  onClose?: () => void;
  modalType: ManufacturingModalsTypesEnum;
  isGroupPlan: boolean;
  data: any;
  isPlanSection?: boolean;
  projectId?: string;
}

export const useManufacturingPlanModal = ({
  dateStart,
  dateEnd,
  expenditureId,
  sectionId,
  updatePlanStatus,
  onClose,
  modalType,
  isGroupPlan,
  data,
  isPlanSection,
  projectId,
}: IArgs) => {
  const dispatch = useDispatch();
  const modalIntervalList = useSelector(modalIntervalListsSelector)[modalType];
  const interval = useSelector(modalDetailedIntervalsSelector)[modalType];
  const files = useSelector(modalFilesSelector);
  const modalMaterials = useSelector(modalMaterialsSelector);
  const isLoading = useSelector(modalLoadingsSelector)[modalType];
  const isMaterialsLoading = useSelector(manufacturingModalMaterialsLoadingSelector);

  const { objectId: objectIdParams } = useParams<IRouterParamsWithObjectId>();

  const objectId = projectId || objectIdParams;

  const planIsInFuture = !!interval?.start_at && moment(interval.start_at).isAfter(moment(), "day");

  const [isGroup, setIsGroup] = useState<boolean>(isGroupPlan || false);

  const list = useMemo(() => {
    let listCandidate = modalIntervalList;
    if (modalType !== "section" && modalType !== "lsr") {
      // @ts-ignore
      listCandidate = listCandidate?.filter((x) => x?.id !== data?.id);
    }
    // @ts-ignore
    return listCandidate?.sort((a, b) => (moment(a?.start_at).isAfter(moment(b?.start_at)) ? 1 : -1));
  }, [modalIntervalList, data, modalType]);

  const [tab, setTab] = useState<PlanTabsEnum>(PlanTabsEnum.MAIN);

  const hasEditRelationsPermission = useCommonModulesPermissions({
    objects: VIEW_MANUFACTURING_CHART_EDIT_RELATIONS,
    constructing: VIEW_CONSTRUCTING_CHART_EDIT_RELATIONS,
  });

  useEffect(() => {
    if (!dateStart?.length && !dateEnd?.length) return;
    dispatch(
      getModalIntervalList({
        projectId: objectId,
        expenditureId,
        sectionId,
        activeModule /* @ts-ignore */,
        date_start: dateStart /* @ts-ignore */,
        date_end: dateEnd,
        modalType,
        hasToRedirectOnSingleInterval: true,
        isGroupInterval: isGroup,
      })
    );
    return () => {
      dispatch(clearModalInterval(modalType));
    };
  }, [objectId, expenditureId, sectionId]);

  const { hasRelations, relationsFromCurrentInterval, relationsToCurrentInterval } = useLoadIntervalRelations({
    projectId: +objectId,
    intervalId: interval?.id,
  });

  const intervalClickHandler = (interval_id: number, expenditure_id: number, isGroup: boolean = false) => {
    dispatch(getModalInterval(objectId, activeModule, expenditure_id, interval_id, modalType, isGroup));
    setIsGroup(isGroup);
  };

  useEffect(() => {
    if (!interval?.id || !interval?.expenditure_id) return;
    dispatch(getModalFiles(objectId, interval.expenditure_id, activeModule, interval.id));
  }, [interval]);

  useEffect(() => {
    if (!interval?.id) return;
    updatePlanStatus?.(interval.status, interval.id);
  }, [interval, updatePlanStatus]);

  const status = manufacturingModalStatusSwitcher({
    isPlan: true,
    status: interval?.work_status,
    sharingStatus: interval?.status,
  });

  const closeHandler = () => {
    dispatch(clearModalData());
    onClose?.();
  };

  const fileUploadHandler = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(uploadModalFile(objectId, interval?.expenditure_id, activeModule, interval.id, formData));
  };

  const fileRemoveHandler = (fileId: number) =>
    dispatch(removeModalFile(objectId, interval?.expenditure_id, activeModule, interval.id, fileId));

  const handleListBackButton = () => {
    dispatch(clearModalInterval(modalType));
    setIsGroup(isGroupPlan || false);
    if (tab !== PlanTabsEnum.MAIN) setTab(PlanTabsEnum.MAIN);
  };

  const intervalDataFetcher = (id: number) => {
    dispatch(getModalInterval(objectId, activeModule, interval?.expenditure_id || expenditureId, id, modalType, false));
  };

  const [isAddingNew, setIsAddingNew] = React.useState(false);
  const [isEditingPlan, setIsEditingPlan] = React.useState(false);

  const canEditPlan = useCanEditPlan({
    isActive: true,
    isSection: false,
    approvals: interval?.approvals,
    objectId,
  });

  const canApprovePlan = useCanApprovePlan({
    objectId,
    approvals: interval?.approvals,
    isSection: false,
    isActive: true,
  });

  const approveType: "section" | "group" | "expenditure" = useMemo(() => {
    if (isGroup) return "group";
    if (isPlanSection) return "section";
    return "expenditure";
  }, [isGroup, isPlanSection]);

  const approveHandler = useApprovePlan(
    Number(objectId),
    interval?.expenditure_id || expenditureId,
    interval?.id,
    { year: moment(interval.start_at).year(), month: moment(interval.start_at).month() + 1 },
    approveType,
    intervalDataFetcher
  );

  const closePlanEditingHandler = () => {
    setIsEditingPlan(false);
    intervalDataFetcher(interval?.id);
  };

  return {
    closeHandler,
    interval,
    intervalDataFetcher,
    files,
    fileUploadHandler,
    fileRemoveHandler,
    isEditingPlan,
    setIsEditingPlan,
    closePlanEditingHandler,
    isLoading,
    hasRelations,
    isAddingNew,
    setIsAddingNew,
    list,
    handleListBackButton,
    hasEditRelationsPermission,
    relationsFromCurrentInterval,
    relationsToCurrentInterval,
    canEditPlan,
    canApprovePlan,
    modalMaterials,
    approveHandler,
    intervalClickHandler,
    isMaterialsLoading,
    tab,
    setTab,
    status,
    objectId,
    isGroup,
    setIsGroup,
    planIsInFuture,
  };
};
