export const initialChatState = {
  show: false,
  inputValue: "",
  offset: 0,
  newMessagesBelow: false,
  mentionedUser: "",
  suggestedUsers: [],
  mentions: [],
  mentionIndex: -1,
  maxCount: 0,
  unreadVal: 0
};

export const chatV2Rooms = {
  SCHEDULE: 'schedule',
  PROJECT: 'project',
  FACT_WORK: 'fact_work',
  EMPLOYEES: 'employees',
  STOCK: 'stock'
}

export const isShowChatParam = "isShowChat";