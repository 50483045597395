import React, { useCallback, useEffect } from "react";

import { tasksListFilterType } from "components/pages/Tasks/model/types";

import TaskRow from "../TaskRow/TaskRow";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import ShowMoreButton from "shared/ui/controls/ShowMoreButton";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { TASK_LISTS_TYPES } from "../../constants";

import { useDeleteTask } from "../../hooks/useDeleteTask";
import { useTasksData } from "../../hooks/useTasksData";
import { useTasksModal } from "../../hooks/useTasksModal";

import taskIcon from "images/icons/navigation/tasksIcon.svg";

type propsType = {
  filters: tasksListFilterType;
  objectId: string;
  type: TASK_LISTS_TYPES;
  setIsEmpty: (_: boolean) => void;
};

function TaskList({ filters, objectId, type, setIsEmpty }: propsType) {
  const { tasksByBuilding, isTasksLoading, loadMoreTasksHandler } = useTasksData({ objectId, type, filters });
  const { deleteTaskHandler } = useDeleteTask({ variant: "byCertainBuilding", building_id: Number(objectId), type });
  const { openAddModal, openEditModal, openViewModal } = useTasksModal();

  const handleAddTask = useCallback(() => {
    openAddModal({
      placementType: "byCertainBuilding",
      listType: type,
    });
  }, [type]);

  const handleOpenTask = useCallback(
    (id: number) => {
      openViewModal({
        placementType: "byCertainBuilding",
        listType: type,
        id,
      });
    },
    [type]
  );

  const handleEditTask = useCallback(
    (id: number) => {
      openEditModal({
        placementType: "byCertainBuilding",
        listType: type,
        id,
      });
    },
    [type]
  );

  const isEmpty = !tasksByBuilding?.results?.length;

  React.useEffect(() => {
    setIsEmpty(isEmpty);
    return () => {
      setIsEmpty(false);
    };
  }, [isEmpty]);

  if (isTasksLoading) {
    return <Spinner />;
  }

  return (
    <>
      {!isEmpty &&
        tasksByBuilding.results.map((el) => (
          <TaskRow
            key={el.id}
            {...el}
            dateEnd={el.deadline}
            dateStart={el.created_at}
            commentsCount={el.task_comments?.length}
            executor={el.executor_user}
            onDelete={deleteTaskHandler}
            onEdit={handleEditTask}
            handleOpen={() => handleOpenTask(el.id)}
          />
        ))}
      <ShowMoreButton
        handleAdd={loadMoreTasksHandler}
        allCount={tasksByBuilding?.count}
        showedCount={tasksByBuilding?.results?.length}
      />
      {isEmpty && (
        <EmptyPlaceholder text="Нет данных в разделе задач" img={taskIcon}>
          <ButtonBase primary onClick={handleAddTask}>
            Новая задача
          </ButtonBase>
        </EmptyPlaceholder>
      )}
    </>
  );
}

export default React.memo(TaskList);
