import React from "react"

export const declineSvg = <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 0.700195C20.3623 0.700195 23.6491 1.69723 26.4447 3.56521C29.2403 5.4332 31.4193 8.08823 32.706 11.1946C33.9926 14.3009 34.3293 17.7191 33.6734 21.0167C33.0174 24.3144 31.3983 27.3435 29.0208 29.721C26.6433 32.0985 23.6142 33.7176 20.3165 34.3735C17.0189 35.0295 13.6007 34.6928 10.4944 33.4061C7.38804 32.1195 4.733 29.9405 2.86502 27.1449C0.997033 24.3493 0 21.0625 0 17.7002C0 13.1915 1.79107 8.8675 4.97918 5.67938C8.1673 2.49126 12.4913 0.700195 17 0.700195V0.700195Z" fill="#DE87DB"/>
<path d="M9.92997 22.6772L14.906 17.7012L9.92859 12.7239C9.65152 12.4461 9.49666 12.0704 9.49666 11.6781C9.49666 11.2857 9.65256 10.9097 9.92962 10.6319C10.2074 10.3548 10.5834 10.1989 10.9758 10.1989C11.3681 10.1989 11.7438 10.3538 12.0216 10.6309L16.999 15.6082L21.9743 10.6329C22.252 10.3552 22.6287 10.1993 23.0215 10.1989C23.4143 10.1985 23.7914 10.354 24.0697 10.6312C24.347 10.9096 24.5025 11.2865 24.502 11.6794C24.5014 12.0724 24.3453 12.4496 24.0673 12.7273L19.092 17.7026L24.0707 22.6814C24.3478 22.9592 24.5027 23.3348 24.5027 23.7272C24.5027 24.1195 24.3481 24.4969 24.0711 24.7747C23.7933 25.0518 23.4159 25.2063 23.0235 25.2063C22.6312 25.2063 22.2555 25.0514 21.9777 24.7744L16.999 19.7956L12.023 24.7716C11.8856 24.9092 11.7219 25.0176 11.5424 25.092C11.3628 25.1665 11.1705 25.2053 10.9761 25.2053C10.7817 25.2053 10.5891 25.1668 10.4095 25.0924C10.23 25.0179 10.067 24.9088 9.92962 24.7713C9.79201 24.6339 9.68332 24.4707 9.60887 24.291C9.53442 24.1113 9.4959 23.9189 9.49597 23.7244C9.49603 23.5299 9.53464 23.3378 9.60922 23.1582C9.68379 22.9786 9.79227 22.8146 9.92997 22.6772Z" fill="white"/>
</svg>

export const acceptSvg = <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 0.700195C20.3623 0.700195 23.6491 1.69723 26.4447 3.56521C29.2403 5.4332 31.4193 8.08823 32.706 11.1946C33.9926 14.3009 34.3293 17.7191 33.6734 21.0167C33.0174 24.3144 31.3983 27.3435 29.0208 29.721C26.6433 32.0985 23.6142 33.7176 20.3165 34.3735C17.0189 35.0295 13.6007 34.6928 10.4944 33.4061C7.38804 32.1195 4.733 29.9405 2.86502 27.1449C0.997033 24.3493 0 21.0625 0 17.7002C0 13.1915 1.79107 8.8675 4.97918 5.67938C8.1673 2.49126 12.4913 0.700195 17 0.700195V0.700195Z" fill="#87D4DE"/>
<path d="M12.561 24.5383L7.11133 19.0886C6.83377 18.8111 6.67773 18.4347 6.67733 18.0421C6.67693 17.6496 6.83192 17.2727 7.10892 16.9946C7.38754 16.7171 7.76494 16.5612 8.15818 16.5613C8.55143 16.5614 8.92866 16.7179 9.2071 16.9956L13.8199 21.6084L24.7963 10.632C24.9336 10.4946 25.096 10.3846 25.2755 10.3102C25.455 10.2359 25.6484 10.1986 25.8428 10.1987C26.0371 10.1988 26.2281 10.2361 26.4076 10.3106C26.5871 10.385 26.7502 10.4942 26.8875 10.6317C27.025 10.7689 27.1342 10.9321 27.2086 11.1116C27.283 11.291 27.3219 11.4836 27.3219 11.6778C27.3219 11.8721 27.2834 12.0643 27.209 12.2437C27.1346 12.4232 27.026 12.5871 26.8886 12.7243L15.0984 24.5145C15.0396 24.61 14.9698 24.6983 14.8905 24.7776C14.7328 24.9356 14.5407 25.0552 14.3298 25.1284C14.1189 25.2016 13.8942 25.2264 13.6724 25.2002C13.3238 25.1716 12.9966 25.02 12.7495 24.7724C12.6788 24.7011 12.6158 24.6226 12.561 24.5383Z" fill="white"/>
</svg>
