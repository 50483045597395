import { useEffect, useState } from "react";

export const useHover = (elemRef: React.RefObject<HTMLElement | null> | undefined) => {
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const element = elemRef?.current;
    if (!element) return;
    const onEnter = () => setHover(true);
    const onLeave = () => setHover(false);

    element.addEventListener("mouseenter", onEnter);
    element.addEventListener("mouseleave", onLeave);

    return () => {
      element.removeEventListener("mouseenter", onEnter);
      element.removeEventListener("mouseleave", onLeave);
    };
  }, []);

  return { hover, setHover };
};
