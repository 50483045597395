import { DocumentsTypesEnum, IAosrInKs2ExpenditureDocs } from "redux/modules/common/passportsAndCertificates/types";

import { IExpenditure } from "types/interfaces/Expenditure";
import { IFile } from "types/interfaces/Files";

export interface IAosrState {
  acts: Record<number, IAosr>;
  worksBySections: Record<number, IExpenditure[]>;
  invalidateKey: number;
  currentAosrDetail: IAosrDetail | null;
  isClosingPending: Record<string, boolean>;
  isCreatingPending: Record<string, boolean>;
}

export interface IAosr {
  expenditure_id: number;
  id: number;
  docs: IAosrDoc[];
  confirmed_file_path: string | null;
  next_expenditure_id: null;
  number: string;
  act_date: string;
  start_date: string;
  end_date: string;
  title: string;
  tech_regulations: string;
  project_documentation: string;
  next_work_title: string;
  next_expenditure: null;
  extra_info: string;
  status?: string;
  expenditure: {
    id: number;
    type: "expenditure";
    confirmed: boolean;
    name: string;
    expenditure_type: "work";
    number: number;
    justification: string;
    estimate_count: string;
    estimate_measure: string;
    estimate_price: string;
    estimate_amount: string;
    count: string;
    measure: string;
    price: string;
  };
}

export interface IMaterialInAosr {
  was_deleted?: boolean;
  material_id: number;
  material_name: string;
  id: number;
  attachments: {
    id: number;
    type: DocumentsTypesEnum;
    type_display: string;
    start_at: string;
    end_at: string;
    date: string;
    file_path: string;
    number: string;
    originalname?: string;
  }[];
}

export interface IAosrDetail extends IAosr {
  copy_count: number;
  status: string;
  materials_in_aosr?: number[];
  materials: IMaterialInAosr[];
}

export interface ICreateAosr {
  number: string;
  act_date: string;
  start_date: string;
  end_date: string;
  docs: IAosrDoc[];
  title: string;
  project_documentation?: string;
  copy_count?: string;
  publish: boolean;
}

export interface IAosrDocument {
  doc_type: string;
  title: string;
  doc_date: string;
  listfiles: IFile[];
  hiddenwork_id?: number;
  id: number;
}

export interface IAosrDoc {
  id: number;
  hiddenwork: number;
  title: string;
  doc_date: string;
  doc_type: string;
  listfiles: {
    id: number;
    hiddenwork_doc_id: number;
    originalname: string;
    file_path: string;
  }[];
  was_deleted?: boolean;
  is_new?: boolean;
}

export interface IAosrFactProduct {
  id: number;
  fact_accepted: IAosrFactProductAccepted;
  stock_product_using: number;
  count: string;
}

export interface IAosrFactProductAccepted {
  id: number;
  packinglist_item_id: number;
  offer_name: string;
  count: string;
}

export interface IPackingItemDocument {
  id: number;
  number: number;
  start_at: string;
  end_at: string;
  created_at: string;
  file: string;
  file_type: string;
  packing_item_id: number;
}

export interface IEditDocsValues {
  doc_date: string;
  doc_type: string;
  hiddenwork: number;
  title: string;
  materials_in_aosr?: number[];
}

export enum AosrTypes {
  FOR_EXP = "for_exp",
  FOR_GROUP = "for_group",
  FOR_GROUP_EXP = "for_group_exp",
}
