export default (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
    <g id="Group_17472" data-name="Group 17472" transform="translate(-1619.074 -37.26)">
      <g id="Union_665" data-name="Union 665" transform="translate(-22581.426 19649.043)" fill="#03c3ff">
        <path
          d="M 24213.5 -19586.283203125 C 24210.16015625 -19586.283203125 24207.021484375 -19587.583984375 24204.662109375 -19589.9453125 C 24202.30078125 -19592.3046875 24201 -19595.443359375 24201 -19598.783203125 C 24201 -19602.123046875 24202.30078125 -19605.26171875 24204.662109375 -19607.62109375 C 24207.021484375 -19609.982421875 24210.16015625 -19611.283203125 24213.5 -19611.283203125 C 24216.83984375 -19611.283203125 24219.978515625 -19609.982421875 24222.337890625 -19607.62109375 C 24224.69921875 -19605.26171875 24226 -19602.123046875 24226 -19598.783203125 C 24226 -19595.443359375 24224.69921875 -19592.3046875 24222.337890625 -19589.9453125 C 24219.978515625 -19587.583984375 24216.83984375 -19586.283203125 24213.5 -19586.283203125 Z"
          stroke="none"
        />
        <path
          d="M 24213.5 -19610.783203125 C 24210.294921875 -19610.783203125 24207.28125 -19609.53515625 24205.015625 -19607.267578125 C 24202.748046875 -19605.001953125 24201.5 -19601.98828125 24201.5 -19598.783203125 C 24201.5 -19595.578125 24202.748046875 -19592.564453125 24205.015625 -19590.298828125 C 24207.28125 -19588.03125 24210.294921875 -19586.783203125 24213.5 -19586.783203125 C 24216.705078125 -19586.783203125 24219.71875 -19588.03125 24221.984375 -19590.298828125 C 24224.251953125 -19592.564453125 24225.5 -19595.578125 24225.5 -19598.783203125 C 24225.5 -19601.98828125 24224.251953125 -19605.001953125 24221.984375 -19607.267578125 C 24219.71875 -19609.53515625 24216.705078125 -19610.783203125 24213.5 -19610.783203125 M 24213.5 -19611.783203125 C 24220.6796875 -19611.783203125 24226.5 -19605.962890625 24226.5 -19598.783203125 C 24226.5 -19591.603515625 24220.6796875 -19585.783203125 24213.5 -19585.783203125 C 24206.3203125 -19585.783203125 24200.5 -19591.603515625 24200.5 -19598.783203125 C 24200.5 -19605.962890625 24206.3203125 -19611.783203125 24213.5 -19611.783203125 Z"
          stroke="none"
          fill="#fff"
        />
      </g>
      <path
        id="Path_8602"
        data-name="Path 8602"
        d="M115.861,256.611l2.191,3.723a1.125,1.125,0,0,0,1.87.139l7.194-9.055a1.246,1.246,0,0,0-.15-1.7,1.12,1.12,0,0,0-1.625.157l-6.167,7.762-1.348-2.291a1.125,1.125,0,0,0-1.588-.4A1.241,1.241,0,0,0,115.861,256.611Z"
        transform="translate(1510.539 -204.91)"
        fill="#fff"
      />
    </g>
  </svg>
);
