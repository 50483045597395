import cn from "classnames";
import React, { KeyboardEventHandler, useState } from "react";

import ButtonBase from "shared/ui/controls/ButtonBase";

import useOnClickOutside from "hooks/useOnClickOutside";
import useEscapeHandler from "utils/hooks/useEscapeHandler";

import Conture from "images/icons/Conture";

import styles from "./TableFilterTextInput.module.scss";

interface IProps {
  title: string;
  setFilter: (value: string) => void;
  iconColor?: string;
  iconFill?: string;
  last?: boolean;
  isPadTop?: boolean;
}

const TableFilterTextInput: React.FC<IProps> = ({ title, setFilter, iconColor, iconFill, last = false, isPadTop }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEscapeHandler(() => setIsOpen(false));

  const [value, setValue] = useState("");
  const [filterUsed, setFilterUsed] = useState(false);

  const ref = React.createRef<HTMLDivElement>();

  const clearFilter = () => {
    setValue("");
    setFilter("");
    setFilterUsed(false);
    setIsOpen(false);
  };

  const handleSaveValue = () => {
    setFilter(value);
    setIsOpen(false);
    value ? setFilterUsed(true) : setFilterUsed(false);
  };

  const keyDownHandler: KeyboardEventHandler = (e) => {
    if (e.key === "Enter") {
      if (value) {
        handleSaveValue();
      } else {
        clearFilter();
      }
    }
  };

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div className={styles.container} ref={ref}>
      <div
        className={cn(styles.button, { [styles.isPad]: isPadTop }, { [styles.active]: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Conture color={iconColor} fill={iconFill} />
        {filterUsed && <div className={styles.redMark}></div>}
      </div>
      {isOpen && (
        <div className={cn(styles.modal, { [styles.modalLast]: last })}>
          <div className={styles.title}>{title}</div>
          <input
            autoFocus
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={keyDownHandler}
          />
          <div className={styles.actionBlock}>
            <ButtonBase small secondary onClick={clearFilter}>
              Очистить
            </ButtonBase>
            &nbsp;&nbsp;&nbsp;
            <ButtonBase small primary onClick={handleSaveValue}>
              Применить
            </ButtonBase>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableFilterTextInput;
