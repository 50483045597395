import {
  SET_INFO,
  SET_IS_INFO_ERROR,
  SET_IS_INFO_LOADING,
  SET_IS_INFO_NOT_FOUND,
  SET_REQUISITION_IS_AGREED,
  UPDATE_ORDER,
} from "./constants";

export const setRequisitionIsAgreedAction = (payload) => ({ type: SET_REQUISITION_IS_AGREED, payload });

export const setIsInfoLoadingAction = (payload) => ({ type: SET_IS_INFO_LOADING, payload });
export const setIsInfoNotFoundAction = (payload) => ({ type: SET_IS_INFO_NOT_FOUND, payload });
export const setIsInfoErrorAction = () => ({ type: SET_IS_INFO_ERROR });
export const setRequisitionInfoAction = (payload) => ({ type: SET_INFO, payload });
export const updateRequisitionOrdersAction = (payload) => ({ type: UPDATE_ORDER, payload });
