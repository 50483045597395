import React from "react";

import { DeleteButton } from "_LEGACY/UI/DeleteButton/DeleteButton";

export interface IDeleteCountRow {
  id: number;
  is_removed: boolean;
}

export interface IProps {
  onDelete: (deletedProductId: number) => void;
}

export const DeleteColumnCell =
  ({ onDelete }: IProps) =>
  (_cell: any, row: IDeleteCountRow) => {
    return (
      <div title={`Нажать для ${!row.is_removed ? "удаления" : "восстановления"} товара`}>
        {/* @ts-ignore */}
        <DeleteButton onClick={onDelete(row.id)} />
      </div>
    );
  };

export default DeleteColumnCell;
