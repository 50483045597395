import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  highlightedArrowsSelector,
  highlightedIntervalsSelector,
} from "redux/modules/common/building/manufacturing/selectors";
import { chartActions } from "redux/modules/common/chart/actions";
import {
  chartActionsSelector,
  chartArrowsHashSelector,
  chartArrowsSelector,
  chartDraggedArrowSelector,
} from "redux/modules/common/chart/selectors";
import { addRelation } from "redux/modules/common/chart/thunks";
import { ChartActions, IPlanRelationCore, RELATION_TYPES } from "redux/modules/common/chart/types";

import { relationType } from "react-xarrows";

export interface IDraggedInterval {
  isBeingDragged: boolean;
  projectId: number;
  startDate: string;
  endDate: string;
  origin: createIntervalOriginType;
  isGroup?: boolean;
}

export interface IDiagramIntervalLink {
  from_interval: number | null;
  from_group: number | null;
  to_interval: number;
  to_group: number | null;
  id: number;
  delay_day: number | null;
  related_type: relationType;
}

export interface IUseIntervalLinksProps {
  intervalWrapperRef: React.RefObject<HTMLElement | null> | undefined;
  toIntervalId: number;
  projectId: number;
  startDate: string;
  endDate: string;
  onAddArrowCallback?: () => void;
  isToIntervalGroup?: boolean;
  hideLinks?: boolean;
}

export interface IArrows {
  [key: number]: IDiagramIntervalLink[];
}

export type createIntervalOriginType = "start" | "end";

export const useIntervalLinks = ({
  intervalWrapperRef,
  toIntervalId,
  projectId,
  startDate,
  endDate,
  onAddArrowCallback,
  isToIntervalGroup = false,
  hideLinks = false,
}: IUseIntervalLinksProps) => {
  const dispatch = useDispatch();
  const { fromIntervalsById, fromGroupsById } = useSelector(chartArrowsSelector);
  const arrowHash = useSelector(chartArrowsHashSelector);
  const draggedArrow = useSelector(chartDraggedArrowSelector);
  const [position, setPosition] = useState<{
    x: number | undefined;
    y: number | undefined;
  }>({ x: undefined, y: undefined });

  const chartFilters = useSelector(chartActionsSelector);
  const isLinkingEnabled = chartFilters[ChartActions.linking_editing_enabled];
  const isLinkingVisible = !hideLinks && chartFilters[ChartActions.linking_enabled];

  const currentArrows = useMemo(
    () => (isToIntervalGroup ? fromGroupsById[toIntervalId] : fromIntervalsById[toIntervalId]),
    [isToIntervalGroup, fromGroupsById, fromIntervalsById, toIntervalId]
  );

  const addArrow = useCallback(
    (
      {
        from_interval,
        from_group,
        to_interval,
        to_group,
        related_type,
      }: Pick<IDiagramIntervalLink, "from_interval" | "from_group" | "to_interval" | "to_group" | "related_type">,
      successCallback?: () => void
    ) => {
      if ((!from_interval && !from_group) || (!to_interval && !to_group) || !projectId) return;
      const arrowCandidate = {
        from_interval,
        from_group,
        to_group,
        to_interval,
        related_type,
      };

      if (
        !currentArrows ||
        currentArrows.findIndex(
          // @ts-ignore
          (x) =>
            x.from_interval === arrowCandidate.from_interval &&
            x.to_interval === arrowCandidate.to_interval &&
            x.related_type === arrowCandidate.related_type
        ) === -1
      ) {
        dispatch(addRelation(projectId, arrowCandidate as IPlanRelationCore, successCallback));
      }
    },
    [currentArrows, projectId]
  );

  const handleDragging = useCallback(
    (e: MouseEvent | DragEvent) => {
      const rect = intervalWrapperRef?.current?.getBoundingClientRect();
      setPosition({
        x: e.clientX - 10 - (rect?.left || 0),
        y: e.clientY - 10 - (rect?.top || 0),
      });
    },
    [intervalWrapperRef?.current]
  );

  const handleDragEnd = useCallback(() => {
    if (!draggedArrow?.intervalId) return;
    dispatch(chartActions.setDraggedArrow(null));
    setPosition({ x: undefined, y: undefined });
  }, [draggedArrow, projectId]);

  useEffect(() => {
    if (!draggedArrow?.intervalId || !toIntervalId || draggedArrow?.intervalId !== toIntervalId) return;
    window.addEventListener("mousemove", handleDragging);
    window.addEventListener("mouseup", handleDragEnd);
    return () => {
      window.removeEventListener("mouseup", handleDragEnd);
      window.removeEventListener("mousemove", handleDragging);
    };
  }, [draggedArrow, handleDragging, handleDragEnd, toIntervalId]);

  const handleDragStart = useCallback(
    (e: MouseEvent, origin: createIntervalOriginType, relationType: RELATION_TYPES) => {
      if (!toIntervalId) return;
      handleDragging(e);
      dispatch(
        chartActions.setDraggedArrow({
          projectId,
          intervalId: toIntervalId,
          startDate,
          endDate,
          origin,
          isGroup: isToIntervalGroup,
          relationType,
        })
      );
    },
    [toIntervalId, projectId, startDate, endDate, handleDragging, isToIntervalGroup]
  );

  const handleCreateIntervalLink = useCallback(
    (targetStartOrEnd: "start" | "end") => {
      if (
        !draggedArrow?.intervalId ||
        !toIntervalId ||
        draggedArrow.intervalId === toIntervalId // ||
        // draggedArrow.projectId !== projectId
      ) {
        return;
      }
      const isFromIntervalGroup = !!draggedArrow.isGroup;

      const arrow = {
        from_interval: isFromIntervalGroup ? null : draggedArrow.intervalId,
        from_group: isFromIntervalGroup ? draggedArrow.intervalId : null,
        to_interval: isToIntervalGroup ? null : toIntervalId,
        to_group: isToIntervalGroup ? toIntervalId : null,
      };

      const sourceStartMoment = moment(draggedArrow.startDate);
      const sourceEndMoment = moment(draggedArrow.endDate);
      const targetStartMoment = moment(startDate);
      const targetEndMoment = moment(endDate);
      const origin = draggedArrow.origin;

      if (targetStartMoment.isAfter(sourceEndMoment) && targetStartOrEnd === "start" && origin === "end") {
        // OH-связь "Начнётся после окончания текущей"
        // @ts-ignore
        addArrow?.({ ...arrow, related_type: RELATION_TYPES.oh }, onAddArrowCallback);
      } else if (
        targetStartMoment.isSameOrAfter(sourceStartMoment) &&
        targetStartOrEnd === "start" &&
        origin === "start"
      ) {
        // HH-связь "Начнётся после начала текущей"
        // @ts-ignore
        addArrow?.({ ...arrow, related_type: RELATION_TYPES.hh }, onAddArrowCallback);
      } else if (targetEndMoment.isSameOrAfter(sourceEndMoment) && targetStartOrEnd === "end" && origin === "end") {
        // OO-связь "Окончится до окончания текущей"
        // @ts-ignore
        addArrow?.({ ...arrow, related_type: RELATION_TYPES.oo }, onAddArrowCallback);
      } else if (targetEndMoment.isAfter(sourceStartMoment) && targetStartOrEnd === "end" && origin === "start") {
        // HO-связь "Окончится после начала текущей"
        // @ts-ignore
        addArrow?.({ ...arrow, related_type: RELATION_TYPES.ho }, onAddArrowCallback);
      }
      handleDragEnd();
    },
    [addArrow, draggedArrow, handleDragEnd, toIntervalId, projectId, onAddArrowCallback, isToIntervalGroup]
  );

  const handleIntervalClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!draggedArrow?.intervalId) return;
      if (toIntervalId === draggedArrow.intervalId) {
        handleDragEnd();
        return;
      }
      const targetBound = (e.currentTarget as Element)?.getBoundingClientRect();

      const targetStartOrEnd =
        Math.abs(e.clientX - targetBound?.left) <= Math.abs(e.clientX - targetBound?.right) ? "start" : "end";
      handleCreateIntervalLink(targetStartOrEnd);
    },
    [handleCreateIntervalLink, toIntervalId, draggedArrow]
  );

  const onHoverIntervalWithDraggedLink = useCallback(
    debounce((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!draggedArrow?.intervalId || draggedArrow.intervalId === toIntervalId) return;
      const targetBound = (e.target as Element)?.getBoundingClientRect();

      const targetStartOrEnd =
        Math.abs(e.clientX - targetBound?.left) <= Math.abs(e.clientX - targetBound?.right) ? "start" : "end";

      const sourceStartMoment = moment(draggedArrow.startDate);
      const sourceEndMoment = moment(draggedArrow.endDate);
      const targetStartMoment = moment(startDate);
      const targetEndMoment = moment(endDate);
      const origin = draggedArrow.origin;

      if (targetStartMoment.isAfter(sourceEndMoment) && targetStartOrEnd === "start" && origin === "end") {
        // OH-связь "Начнётся после окончания текущей"
        dispatch(chartActions.setDraggedArrowRelationType(RELATION_TYPES.oh));
      } else if (
        targetStartMoment.isSameOrAfter(sourceStartMoment) &&
        targetStartOrEnd === "start" &&
        origin === "start"
      ) {
        // HH-связь "Начнётся после начала текущей"
        dispatch(chartActions.setDraggedArrowRelationType(RELATION_TYPES.hh));
      } else if (targetEndMoment.isSameOrAfter(sourceEndMoment) && targetStartOrEnd === "end" && origin === "end") {
        // OO-связь "Окончится до окончания текущей"
        dispatch(chartActions.setDraggedArrowRelationType(RELATION_TYPES.oo));
      } else if (targetEndMoment.isAfter(sourceStartMoment) && targetStartOrEnd === "end" && origin === "start") {
        // HO-связь "Окончится после начала текущей"
        dispatch(chartActions.setDraggedArrowRelationType(RELATION_TYPES.ho));
      }
    }, 100),
    [draggedArrow, startDate, endDate, toIntervalId]
  );

  const highlightedIntervals = useSelector(highlightedIntervalsSelector);
  const highlightedArrows = useSelector(highlightedArrowsSelector);
  const isIntervalHighlighted = isLinkingEnabled && !!toIntervalId && highlightedIntervals?.[toIntervalId];

  return {
    isLinkingEnabled,
    isLinkingVisible,
    handleIntervalClick,
    position,
    handleDragging,
    handleDragStart,
    handleCreateIntervalLink,
    arrowsStartsWithCurrentIntervalId: currentArrows,
    draggedArrow,
    isIntervalHighlighted,
    highlightedArrows,
    arrowHash,
    onHoverIntervalWithDraggedLink,
  };
};
