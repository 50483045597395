import cn from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  expendituresSubMaterialsLoadingSelector,
  expendituresSubMaterialsSelector,
  getExpenditureSubMaterials,
} from "redux/modules/common/building/sections/sections";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { IPlanFactVolumeExpenditure } from "pages/PlanFact/PlanFactVolume/model/types";

import { groupIcon } from "../../ui/ExpandGroup/groupIcon";
import { planFactVolumeIsAllExpandedSelector } from "../model/selectors";
import ExpandGroup from "./../../ui/ExpandGroup/ExpandGroup";
import PlanFactVulmeNestedExpenditure from "./PlanFactVulmeNestedExpenditure";
import PlanfactVolumeDayCell from "./PlanfactVolumeDayCell";
import Popup from "./Popup/Popup";
import WorkersPlanFactVolume from "./Workers/WorkersPlanFactVolume";
import SpecificationModal from "entities/SpecificationModal/SpecificationModal";
import BlueLabel from "shared/ui/dataDisplay/BlueLabel/BlueLabel";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { sliceTextByConstraint } from "../../../../utils/formatters/sliceTextByConstraint";
import { prepareDaysForPlanfactVolume } from "./utils";

import styles from "./PlanfactVolumeExpenditure.module.scss";

type ExpenditureLocalType = Omit<IPlanFactVolumeExpenditure["expenditures"][0], "number" | "justification"> & {
  justification?: string;
  number?: number;
};

interface IProps {
  expenditure: ExpenditureLocalType;
  nestedExpenditures?: ExpenditureLocalType[];
  startAt: string;
  daysCount?: 7 | 5;
}

const PlanfactVolumeExpenditure: React.FC<IProps> = ({ expenditure, startAt, daysCount = 7, nestedExpenditures }) => {
  const objectId = useObjectId();
  const [isOpenJustification, setIsOpenJustification] = React.useState(false);
  const isAllExpanded = useSelector(planFactVolumeIsAllExpandedSelector);

  const dispatch = useDispatch();
  const subMaterialsAreLoading = useSelector(expendituresSubMaterialsLoadingSelector);
  const subMaterials = useSelector(expendituresSubMaterialsSelector) as any;
  const isParent = !!nestedExpenditures?.length;

  const onClickByJustification = React.useCallback(() => {
    if (isParent) return;
    setIsOpenJustification(true);
    if (!subMaterials[expenditure.id]) {
      dispatch(getExpenditureSubMaterials(objectId, expenditure.id));
    }
  }, [objectId, expenditure.id, subMaterials, isParent]);

  const closeJustification = React.useCallback(() => setIsOpenJustification(false), []);

  const labelText = isParent ? `Расценок: ${nestedExpenditures?.length}` : expenditure.justification;

  const days = React.useMemo(() => {
    return prepareDaysForPlanfactVolume(daysCount, startAt, expenditure.days);
  }, [startAt, daysCount, expenditure.days]);

  const rowClassName = cn(styles.row, { [styles.row5days]: daysCount === 5 });

  const [expanded, setExpanded] = useState(false);

  const measure = sliceTextByConstraint(expenditure.measure, 9);

  if (isAllExpanded) {
    return (
      <div className={styles.wrapper}>
        <div className={rowClassName}>
          <TableReusableCell isFont13={false}>
            {expenditure.number ?? <ExpandGroup isExpand={expanded} onExpand={() => setExpanded((p) => !p)} />}
          </TableReusableCell>
          <TableReusableCell className={styles.mainCell} isFont13={false}>
            <div className={styles.name} title={expenditure.name}>
              {expenditure.name}
            </div>
            <div className={styles.bottom}>
              <BlueLabel className={styles.justification} blueFont onClick={onClickByJustification} title={labelText}>
                {sliceTextByConstraint(labelText, 20)}
              </BlueLabel>
              <WorkersPlanFactVolume workers={expenditure.workers} />
            </div>
          </TableReusableCell>
          <TableReusableCell isFont13={false}>
            <Popup
              percents={expenditure.percent_total_completed}
              count={expenditure.count}
              countCompleted={expenditure.count_total_completed}
            />
          </TableReusableCell>
          <TableReusableCell isFont13={false} isCentered>
            {measure}
          </TableReusableCell>
          <TableReusableCell isFont13={false} className={styles.bordered} isCentered>
            {expenditure.period_plan}
          </TableReusableCell>
          {days?.map((el, index) => (
            <PlanfactVolumeDayCell key={`${el.date}_${index}`} {...el} />
          ))}
          <TableReusableCell isFont13={false} className={styles.bordered} isCentered>
            {expenditure.period_fact}
          </TableReusableCell>
          <SpecificationModal
            name={expenditure.justification}
            isOpen={isOpenJustification}
            onClose={closeJustification}
            subMaterials={(subMaterials as any)[expenditure.id]}
            isLoading={subMaterialsAreLoading}
          />
        </div>
        {expanded &&
          nestedExpenditures?.map((el, i) => (
            <PlanFactVulmeNestedExpenditure key={el.id} expenditure={el} daysCount={daysCount} startAt={startAt} />
          ))}
      </div>
    );
  }

  return (
    <TableReusableRow
      containerClassName={styles.container}
      openClassName={styles.open}
      className={rowClassName}
      isHoverable={false}
      isExpandable={isParent}
      isEarBlue
      innerContent={
        <>
          {nestedExpenditures?.map((el, i) => (
            <PlanFactVulmeNestedExpenditure key={el.id} expenditure={el} daysCount={daysCount} startAt={startAt} />
          ))}
        </>
      }
    >
      <TableReusableCell>{expenditure.number}</TableReusableCell>
      <TableReusableCell className={styles.mainCell}>
        <div className={styles.name} title={expenditure.name}>
          {expenditure.name}
        </div>
        <div className={styles.bottom}>
          <BlueLabel className={styles.justification} blueFont onClick={onClickByJustification} title={labelText}>
            {sliceTextByConstraint(labelText, 20)}
          </BlueLabel>
          <WorkersPlanFactVolume workers={expenditure.workers} />
        </div>
      </TableReusableCell>
      <TableReusableCell>
        <Popup
          percents={expenditure.percent_total_completed}
          count={expenditure.count}
          countCompleted={expenditure.count_total_completed}
        />
      </TableReusableCell>
      <TableReusableCell isCentered>{measure}</TableReusableCell>
      <TableReusableCell className={styles.bordered} isCentered>
        {expenditure.period_plan}
      </TableReusableCell>
      {days?.map((el, index) => (
        <PlanfactVolumeDayCell key={`${el.date}_${index}`} {...el} />
      ))}
      <TableReusableCell className={styles.bordered} isCentered>
        {expenditure.period_fact}
      </TableReusableCell>
      <SpecificationModal
        name={expenditure.justification}
        isOpen={isOpenJustification}
        onClose={closeJustification}
        subMaterials={(subMaterials as any)[expenditure.id]}
        isLoading={subMaterialsAreLoading}
      />
    </TableReusableRow>
  );
};

export default React.memo(PlanfactVolumeExpenditure);
