import React, { FC } from "react";

import { ExpendituresList } from "./ui/ExpendituresList/ExpendituresList";
import { SectionsList } from "./ui/SectionsList/SectionsList";
import { SubSectionsList } from "./ui/SubSectionsList/SubSectionsList";

import { EHandlerLevels } from "../../types";

import { useHandlerLevel } from "../../hooks/useHandlerLevel";

import styles from "./ItemsList.module.scss";

interface IProps {}

/**
 * В зависимости от уровня вложенности отображаем нужный список
 * На данный момент есть 3 уровня:
 * 1. Уровень секций (разделов, ЛСР) – SECTIONS
 * 2. Уровень подразделов – SUBSECTIONS
 * 3. Уровень расценок – EXPENDITURES
 */
export const ItemsList: FC<IProps> = () => {
  const level = useHandlerLevel();

  return (
    <div className={styles.itemsList}>
      {level === EHandlerLevels.SECTIONS && <SectionsList />}
      {level === EHandlerLevels.SUBSECTIONS && <SubSectionsList />}
      {level === EHandlerLevels.EXPENDITURES && <ExpendituresList />}
    </div>
  );
};
