import { message } from "antd";
import axios from "axios";
import moment from "moment";
import React from "react";

import { INTERVAL_TYPES, IntervalType } from "../../../../pages/Manufacturing/constants";

import { IExpenditure } from "../../../../../types/interfaces/Expenditure";
import { IIdAndName } from "types/idAndName";
import { fullNameWithPhoneAndMail } from "types/personsTypes";

import { formatMaterialCount } from "../../../../../utils/formatters/formatMaterialCount";
import { errorCatcher } from "utils/helpers/errorCatcher";

type propsType = {
  objectId: number;
  expId: number;
  isOpen: boolean;
  start_date: string;
  end_date: string;
  intervalType: IntervalType;
};

type materialResponseType = {
  id: number;
  name: string;
  justification: string;
  count: string;
  measure: string;
  section_id: number;
  section_name: string;
  number: number;
  related_work: {
    id: number;
    name: string;
    number: number;
  };
  estimate_amount: string;
  price: string;
  products: IIdAndName[];
  planed_materials: {
    id: number;
    workinterval: {
      id: number;
      start_at: string;
      end_at: string;
      count: string;
      created_at: string;
      expenditure_id: number;
      author: fullNameWithPhoneAndMail;
      expenditure_name: string;
      measure: string;
      work_amount: number;
    };
    count: string;
    amount: string;
  }[];
  purchases: {
    id: number;
    name: string;
    count: string;
    confirm_count?: string;
    measure: string;
    amount: string;
    creator: fullNameWithPhoneAndMail;
    created_at: string;
  }[];
  on_stock: {
    id: number;
    name: string;
    count: string;
    confirm_count?: string;
    amount: string;
    delivery_date: string;
    accepted_user: fullNameWithPhoneAndMail;
    count_get?: string;
  }[];
  on_stock_service: {
    id: number;
    name: string;
    count: string;
    amount: string;
    accepted_date: string;
    accepted_user: fullNameWithPhoneAndMail;
  }[];
  payed: {
    id: number;
    count: string;
    confirm_count?: string;
    amount: string;
    confirmed_date: string | null;
    confirm_date: string;
    confirm_user: fullNameWithPhoneAndMail;
  }[];
  passed: any[];
  to_paid: {
    id: number;
    count: string;
    confirm_count?: string;
    amount: string;
    confirmed_date: string | null;
    confirm_date: string;
    confirm_user: fullNameWithPhoneAndMail;
  }[];
  planed_mims: {
    id: number;
    workinterval: {
      id: number;
      start_at: string;
      end_at: string;
      count: string;
      created_at: string;
      expenditure_id: number;
      author: fullNameWithPhoneAndMail;
      expenditure_name: string;
      measure: string;
      work_amount: number;
      approved_user: null;
      approved_at: null;
      planned_materials_list: any[];
      count_planned_services: {
        id: number;
        service_id: number;
        service: {
          id: number;
          name: string;
          measure: string;
          type: "service";
          estimate_expenditure: IExpenditure;
        };
        count: string;
        amount: string;
      }[];
    };
    count: string;
    amount: string;
  }[];
  givenout: {
    id: number;
    count: string;
    ticket: {
      id: number;
      title: string;
      start_at: string;
      end_at: string;
      created_at: string;
      updated_at: string;
      count: string;
      section_id: number;
      expenditure_id: number;
      author: fullNameWithPhoneAndMail;
      sum_count_aproved: string;
      is_after_related: boolean;
      measure: string;
      expenditure_count: string;
      row_number: number;
      is_default: boolean;
    };
    service: IIdAndName;
  }[];
  stockless: {
    id: number;
    count: string;
    confirm_count?: string;
    amount: string;
    created_at: string;
    who_issued_it: fullNameWithPhoneAndMail;
    who_accepted: fullNameWithPhoneAndMail;
  }[];
};

export const useChartMaterialModal = ({ objectId, expId, isOpen, start_date, end_date, intervalType }: propsType) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState<materialResponseType | null>(null);
  const [files, setFiles] = React.useState([]);

  const requisitionRequiredCount = React.useMemo(() => {
    if (
      intervalType === INTERVAL_TYPES.material &&
      !!data?.planed_materials?.[0]?.count &&
      !!data?.planed_materials?.[0]?.workinterval?.measure
    ) {
      return formatMaterialCount(data.planed_materials[0].count, data.planed_materials[0].workinterval.measure);
    } else if (!!data?.planed_mims?.[0]?.count && !!data?.planed_mims?.[0]?.workinterval?.measure) {
      return formatMaterialCount(data.planed_mims[0].count, data.planed_mims[0].workinterval.measure);
    }
    return undefined;
  }, [data, intervalType]);

  const requisitionSuccessMessage =
    intervalType === INTERVAL_TYPES.equipment
      ? "Оборудование добавлено в заявку"
      : `${intervalType === INTERVAL_TYPES.material ? "Материал" : "МиМ"} добавлен в заявку`;

  React.useEffect(() => {
    if (!isOpen) return;
    setIsLoading(true);
    axios
      .get<materialResponseType>(`/building/${objectId}/material/${expId}/events/`, {
        params: {
          start_date: moment(start_date).format("YYYY-MM-DD"),
          end_date: moment(end_date).format("YYYY-MM-DD"),
        },
      })
      .then((resp) => setData(resp.data))
      .finally(() => setIsLoading(false));
    axios.get(`/building/${objectId}/expenditure_files/${expId}/`).then((resp) => setFiles(resp.data.results));
  }, [objectId, expId, isOpen, start_date, end_date]);

  const addToRequisitionHandler = () => {
    axios.get(`/building/${objectId}/requisition/last-draft/`).then((resp) => {
      if (!Object.keys(resp.data).length) {
        const payload = {
          building_id: objectId,
          requisition_items: data?.products.map((el) => ({
            product_building: el.id,
            required_count: requisitionRequiredCount,
          })),
        };
        axios
          .post(`/requisition/`, payload)
          .then(() => message.success(requisitionSuccessMessage))
          .catch(errorCatcher);
      } else if (resp.data.id) {
        const payload = data?.products.map((el) => ({
          required_count: requisitionRequiredCount,
          product_building_id: el?.id,
        }));
        axios
          .post(`/requisition/${resp.data.id}/products/`, payload)
          .then(() => message.success(requisitionSuccessMessage))
          .catch(errorCatcher);
      }
    });
  };

  return {
    isLoading,
    data,
    files,
    addToRequisitionHandler,
  };
};
