import { isEqual } from "lodash";

import { RootState } from "app/store/rootReducer";

export const parseStatusParam = (statuses: string[]) => {
  let statusParam = "?status=";

  statuses.forEach((statusItem, i) => {
    statusParam += statusItem;
    if (i !== statuses.length - 1) statusParam += ",";
  });

  return statusParam;
};

export const checkIsNeedSetLoading = (
  state: RootState,
  filters: Record<string, string | number>,
  key: string
): boolean => {
  if (!state.requisitionsList.requisitions?.[key]?.requisitions?.length) {
    return true;
  }
  if (!isEqual(state.requisitionsList.requisitions?.[key]?.filters, filters)) {
    return true;
  }
  return false;
};
