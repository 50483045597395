import React from "react";

const ThreeDots = () => {
  return (
    <svg
      width="26"
      height="7"
      viewBox="0 0 26 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 3.11719C20 2.32154 20.3163 1.5587 20.8789 0.996094C21.4415 0.433485 22.2044 0.117188 23 0.117188C23.7956 0.117188 24.5585 0.433485 25.1211 0.996094C25.6837 1.5587 26 2.32154 26 3.11719C26 3.91284 25.6837 4.67567 25.1211 5.23828C24.5585 5.80089 23.7956 6.11719 23 6.11719C22.2044 6.11719 21.4415 5.80089 20.8789 5.23828C20.3163 4.67567 20 3.91284 20 3.11719ZM10 3.11719C10 2.32154 10.3163 1.5587 10.8789 0.996094C11.4415 0.433485 12.2044 0.117188 13 0.117188C13.7956 0.117188 14.5585 0.433485 15.1211 0.996094C15.6837 1.5587 16 2.32154 16 3.11719C16 3.91284 15.6837 4.67567 15.1211 5.23828C14.5585 5.80089 13.7956 6.11719 13 6.11719C12.2044 6.11719 11.4415 5.80089 10.8789 5.23828C10.3163 4.67567 10 3.91284 10 3.11719ZM0 3.11719C0 2.32154 0.316297 1.5587 0.878906 0.996094C1.44152 0.433485 2.20435 0.117188 3 0.117188C3.79565 0.117188 4.55848 0.433485 5.12109 0.996094C5.6837 1.5587 6 2.32154 6 3.11719C6 3.91284 5.6837 4.67567 5.12109 5.23828C4.55848 5.80089 3.79565 6.11719 3 6.11719C2.20435 6.11719 1.44152 5.80089 0.878906 5.23828C0.316297 4.67567 0 3.91284 0 3.11719Z"
        fill="#717171"
      />
    </svg>
  );
};

export default ThreeDots;
