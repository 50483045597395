import { IActions } from "../../../../app/store/store";
import { IFinanceTableHighlighting, IFinanceTableScrollPosition } from "../financeCommonTypes";
import { getScrollPositionPercent } from "../getScrollPositionPercent";

export const summaryActions = {
  setTableViewWidthPercent: (widthPercent: number) =>
    ({
      type: "constructing/finance/summary_SET_TABLE_VIEW_WIDTH_PERCENT",
      payload: widthPercent,
    } as const),
  setTableHighlighting: (highlightingParams: Partial<IFinanceTableHighlighting>) =>
    ({
      type: "constructing/finance/summary_SET_TABLE_HIGHLIGHTING",
      payload: highlightingParams,
    } as const),
  setYear: (year: number) =>
    ({
      type: "constructing/finance/summary_SET_YEAR",
      payload: year,
    } as const),
  setTableScrollPosition: (positionParams: Partial<IFinanceTableScrollPosition>) =>
    ({
      type: "constructing/finance/summary_SET_TABLE_SCROLL_POSITION",
      payload: { ...positionParams, percent: getScrollPositionPercent(positionParams) },
    } as const),
};

export type ISummaryAction = IActions<typeof summaryActions>;
