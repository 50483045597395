//@ts-nocheck
import moment from "moment";

import { IPlanFactAction } from "./actions";

import { IPlanFactInitialState } from "./types";

const initialState: IPlanFactInitialState = {
  isLoading: false,
  tree: {},
  measure: 1,
  selectedDates: {
    start_at: moment().startOf("month").format("YYYY-MM-DD"),
    end_at: moment().endOf("month").format("YYYY-MM-DD"),
    monthsCount: 1,
  },
};

export default (state = initialState, action: IPlanFactAction) => {
  const { type, payload } = action;
  switch (type) {
    case "constructing/finance/planFact_SET_LOADING":
      return {
        ...state,
        isLoading: payload,
      };
    case "constructing/finance/planFact_CLEAR_DATA":
      return {
        ...initialState,
      };
    case "constructing/finance/planFact_SET_TREE":
      return {
        ...state,
        tree: payload,
      };
    case "constructing/finance/planFact_SET_MEASURE":
      return {
        ...state,
        measure: payload,
      };
    case "constructing/finance/planFact_SET_SELECTED_DATES":
      return {
        ...state,
        selectedDates: payload,
      };
    default:
      return state;
  }
};
