import { isEqual } from "lodash";

export const extractChangedValues = <T extends Record<string, any>>(oldObj: T, newObj: T): Partial<T> => {
  const res: Partial<T> = {};

  for (let key in newObj) {
    if (!isEqual(newObj[key], oldObj[key])) {
      res[key] = newObj[key];
    }
  }

  return res;
};
