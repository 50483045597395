import React from "react";

import {
  IDetailedKs2InActing,
  IExpenditureInActing,
  ISectionInActing,
  ISelectedSectionInActing,
} from "redux/modules/common/building/journal/acting/types";

import JournalRowIndicator from "../../../common/JournalRowIndicator/JournalRowIndicator";
import JournalKs2ModalRowContent from "./JournalKs2ModalRowContent";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import styles from "./JournalKsModalRow.module.scss";

interface IProps {
  section: IDetailedKs2InActing["items"][0];
  onDirectlyRemoveExpenditure?: (expenditureId: number) => void;
}

const JournalKs2ModalEditingRow: React.FC<IProps> = ({ section, onDirectlyRemoveExpenditure }) => {
  return (
    <TableReusableRow
      isOpenByDefault
      isHoverable={false}
      isExpandable
      isParentOfExpenditures
      className={styles.sectionRow}
      innerContent={
        <JournalKs2ModalRowContent
          sectionId={section.section_id}
          isNeedLoad={false} //@ts-ignore
          expenditures={section.expenditures}
          estimateId={0}
          onDirectlyRemoveExpenditure={onDirectlyRemoveExpenditure}
        />
      }
    >
      <TableReusableCell isNoBreak>{section.section_name}</TableReusableCell>
      <TableReusableCell>
        <JournalRowIndicator value={section.todo_amount} title="К сдаче" />
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default React.memo(JournalKs2ModalEditingRow);
