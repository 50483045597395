import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  actingInvalidateKeySelector,
  actingSectionsSelector,
} from "redux/modules/common/building/journal/acting/selectors";
import { checkIntersectingKs2, getActingSections } from "redux/modules/common/building/journal/acting/thunks";

import JournalKs2Modal from "../JournalActingModal/JournalKs2Modal";
import JournalActingSelectingBar from "../common/JournalActingSelectingBar/JournalActingSelectingBar";
import JournalActingSectionRow from "./JournalActingSectionRow";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useSelectedForKs2 } from "../hooks/useSelectedForKs2";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./JournalActingSectionsLevel.module.scss";

interface IProps {
  onBack: () => void;
  estimateId: number;
}

const JournalActingSectionsLevel: React.FC<IProps> = ({ onBack, estimateId }) => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();

  const sections = useSelector(actingSectionsSelector)[String(estimateId)];
  const cacheKey = useSelector(actingInvalidateKeySelector);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getActingSections(objectId, estimateId));
  }, [objectId, estimateId, cacheKey]);

  const { selectedCount, selectedSections, selectedIds } = useSelectedForKs2({ estimateId });

  const [isIntersectingLoading, setIsIntersectingLoading] = useState(false);

  const openModalHandler = useCallback(() => {
    setIsIntersectingLoading(true);
    dispatch(
      checkIntersectingKs2(
        objectId,
        {
          title: "none",
          start_at: moment().format("YYYY-MM-DD"),
          end_at: moment().format("YYYY-MM-DD"),
          ...selectedIds,
        },
        () => {
          setIsModalOpen(true);
          setIsIntersectingLoading(false);
        }
      )
    );
  }, [selectedIds, objectId]);

  const navigationTitle = sections?.results?.[0]?.parent_name;

  return (
    <>
      <div className={styles.head}>
        <BackNavigationBar isInNestedList onBack={onBack} title={navigationTitle} isBold />
        <JournalActingSelectingBar type="estimate" onSubmit={openModalHandler} selectedCount={selectedCount} />
      </div>
      {sections?.isLoading && <Spinner />}
      {!sections?.isLoading && !sections?.results?.length && <EmptyPlaceholder img={jurnalIcon} />}
      {!sections?.isLoading &&
        sections?.results?.map((el) => <JournalActingSectionRow key={el.id} section={el} estimateId={estimateId} />)}
      <JournalKs2Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        estimateId={estimateId}
        selectedSections={selectedSections}
        selectedCount={selectedCount}
        selectedIds={selectedIds}
        isLoading={isIntersectingLoading}
      />
    </>
  );
};

export default React.memo(JournalActingSectionsLevel);
