import axios from "axios";

export function apiAddRequisitionEmployees(requisitionId, config) {
  return axios
    .post(`requisition/${requisitionId}/employees/`, config)
    .then(
      ({ data }) => data,
      (error) => {
        console.error(error);
      }
    )
    .catch((e) => console.error(e));
}

export function apiGetExport(requestId) {
  return axios
    .get(`/requisition/${requestId}/export/`, {
      responseType: "blob",
    })
    .then((data) => data)
    .catch((e) => console.error(e));
}

export function setRequisitionAgreementApprove(id, type = "requisition") {
  return axios
    .post(`/${type}/${id}/approve/`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => console.error(e));
}

export function removeRequisitionAgreementApprove(id, type = "requisition") {
  return axios
    .delete(`/${type}/${id}/approve/`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => console.error(e));
}
