export enum EditingHistoryFields {
  count = "count",
  startAt = "start_at",
  endAt = "end_at",
}

export const EditingHistoryFieldNames = {
  [EditingHistoryFields.count]: "Объем",
  [EditingHistoryFields.startAt]: "Период",
  [EditingHistoryFields.endAt]: "Период",
};
