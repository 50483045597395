import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { objectsBreadcrumbsSelector } from "../../../redux/modules/common/building/objects";
import { filesByFolderIdSelector, filesByObjectIdSelector } from "redux/modules/common/filesStorage/selectors";
import {
  createFolderInStorage,
  getFileStorageItems,
  getRecentFiles,
  getStorageCounts,
} from "redux/modules/common/filesStorage/thunks";
import { IMergedItemInStorage } from "redux/modules/common/filesStorage/types";

import FileItem from "./components/FileItem/FileItem";
import fileSvg from "./components/FileItem/assets/files.svg";
import FileUploadInStorage from "./components/FileUploadInStorage/FileUploadInStorage";
import FilesIndicators from "./components/FilesIndicators/FilesIndicators";
import FilesNavBar from "./components/FilesNavBar/FilesNavBar";
import Expandable from "components/UI/atoms/_TODO/Expandable/Expandable";

import FileStorageRootFoldersList from "./FileStorageRootFoldersList";
import { useFileStorageNavigation } from "./useFileStorageNavigation";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import FileViewer from "widgets/FileViewer/FileViewer";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useUrlModule } from "utils/hooks/useUrlModule";

import styles from "./FileStorage.module.scss";

const FilesStorage = () => {
  const history = useHistory();
  const moduleRoute = useUrlModule();
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const isAllObjects = objectId === "0";
  const filesByObject = useSelector(filesByObjectIdSelector)[objectId];
  const allObjectsCount = useSelector(objectsBreadcrumbsSelector)?.count;

  const [isCreatingFolder, setIsCreatingFolder] = useState(false);

  const creatingFolderSwitcher = useCallback(() => {
    setIsCreatingFolder((prev) => !prev);
  }, [setIsCreatingFolder]);

  const { currentFolderId, backHandler, openFolderHandler, foldersDepth, activeFolderName } =
    useFileStorageNavigation();
  const filesByFolder = useSelector(filesByFolderIdSelector)[currentFolderId];

  const fileStorage = currentFolderId ? filesByFolder : filesByObject;
  useEffect(() => {
    dispatch(getFileStorageItems(objectId, currentFolderId));
    !currentFolderId && dispatch(getRecentFiles(objectId));
    setIsCreatingFolder(false);
  }, [objectId, currentFolderId, dispatch]);

  const createFolder = useCallback(
    (name: string) => {
      dispatch(createFolderInStorage(name, objectId, currentFolderId));
      dispatch(getStorageCounts(objectId));
      setIsCreatingFolder(false);
    },
    [objectId, currentFolderId]
  );

  const isTooDeep = foldersDepth >= 2;

  const isRoot = objectId === "0";

  const isInObjectButNotInManufacturing = moduleRoute !== "objects" && +objectId > 0 && !currentFolderId;

  const isEmpty = fileStorage?.isLoaded && !fileStorage?.items?.length && !isCreatingFolder;

  const goBack = useCallback(() => {
    if (isInObjectButNotInManufacturing) {
      history.push(`/${moduleRoute}/files/0`);
    } else {
      backHandler();
    }
  }, [isInObjectButNotInManufacturing, backHandler, moduleRoute]);

  const [openedFileIndex, setOpenedFileIndex] = useState(-1);

  const onlyFiles = useMemo(() => {
    return fileStorage?.items?.filter((el) => !el.isFolder) ?? [];
  }, [fileStorage?.items]);

  const onClickFile = (file: IMergedItemInStorage) => {
    if (file.isFolder) return;
    const fileIndex = onlyFiles.findIndex((el) => el.id === file.id);
    setOpenedFileIndex(fileIndex);
  };

  return (
    <TemplateBase dataTestId="page_storage">
      <FileUploadInStorage sectionId={currentFolderId} />
      <FilesIndicators />
      <FilesNavBar
        canBack={allObjectsCount > 1 && (!!currentFolderId || isInObjectButNotInManufacturing)}
        canCreateFolder={!isAllObjects && !isTooDeep}
        onCreateNewFolder={creatingFolderSwitcher}
        onGoBack={goBack}
        text={fileStorage?.parentName || activeFolderName}
      />
      {!isRoot && !currentFolderId && !!fileStorage?.recentFiles?.length && (
        <Expandable title="Недавние файлы:">
          <div className={styles.grid}>
            {fileStorage?.recentFiles?.map((el) => (
              <FileItem key={el.id + "recent"} name={el.originalname} link={el.file} id={el.id} />
            ))}
          </div>
        </Expandable>
      )}
      {!isRoot && (
        <Expandable
          title={`Все файлы: Папок: ${fileStorage?.foldersCount || 0}  файлов: ${fileStorage?.filesCount || 0}`}
        >
          {!isEmpty && (
            <div className={styles.grid}>
              {isCreatingFolder && (
                <FileItem
                  id={-1}
                  isFolder
                  name="Новая папка"
                  isNewFolder
                  onCancelEditing={creatingFolderSwitcher}
                  onApproveEditing={createFolder}
                />
              )}
              {fileStorage?.items?.map((el, i) => (
                <FileItem
                  key={el.id + String(el.isFolder)}
                  name={el.name}
                  link={el.link}
                  id={el.id}
                  isFolder={el.isFolder}
                  onOpenFolder={openFolderHandler}
                  filesCount={el.filesCount}
                  foldersCount={el.foldersCount}
                  onFileClick={() => onClickFile(el)}
                />
              ))}
              {!fileStorage?.isLoaded && !fileStorage?.items?.length && <Spinner />}
              <FileViewer
                startIndex={openedFileIndex}
                isOpen={openedFileIndex !== -1}
                onClose={() => setOpenedFileIndex(-1)}
                files={onlyFiles as any}
              />
            </div>
          )}
          {isEmpty && <EmptyPlaceholder text="Нет файлов" img={fileSvg} />}
        </Expandable>
      )}
      {isRoot && <FileStorageRootFoldersList />}
    </TemplateBase>
  );
};

export default FilesStorage;
