import cn from "classnames";
import React from "react";

import styles from "./DateCell.module.scss";

interface IProps {
  date: string;
  isIcon?: boolean;
  className?: string;
}

const DateCell: React.FC<IProps> = ({ date, isIcon = true, className }) => {
  return (
    <div className={cn(styles.cell, className)}>
      {date}
      {isIcon && (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" className={styles.icon}>
          <path
            d="M0.0700684 3.92578H17.0701V14.9258C17.0701 15.7214 16.754 16.4845 16.1914 17.0471C15.6288 17.6097 14.8657 17.9258 14.0701 17.9258H3.07007C2.27442 17.9258 1.51136 17.6097 0.948748 17.0471C0.386139 16.4845 0.0700684 15.7214 0.0700684 14.9258L0.0700684 3.92578Z"
            fill="white"
          />
          <path
            d="M0.570068 4.42578H16.5701V14.9258C16.5701 15.5888 16.3067 16.2247 15.8378 16.6935C15.369 17.1624 14.7331 17.4258 14.0701 17.4258H3.07007C2.74176 17.4258 2.41667 17.3611 2.11336 17.2355C1.81005 17.1098 1.53445 16.9257 1.3023 16.6935C0.83346 16.2247 0.570068 15.5888 0.570068 14.9258V4.42578Z"
            stroke="#BABABA"
          />
          <path
            d="M4.07007 7.42578H3.07007C2.79393 7.42578 2.57007 7.64964 2.57007 7.92578V8.92578C2.57007 9.20192 2.79393 9.42578 3.07007 9.42578H4.07007C4.34621 9.42578 4.57007 9.20192 4.57007 8.92578V7.92578C4.57007 7.64964 4.34621 7.42578 4.07007 7.42578Z"
            stroke="#BABABA"
            strokeDasharray="1 1"
          />
          <path
            d="M9.07007 6.92578H8.07007C7.51778 6.92578 7.07007 7.3735 7.07007 7.92578V8.92578C7.07007 9.47807 7.51778 9.92578 8.07007 9.92578H9.07007C9.62235 9.92578 10.0701 9.47807 10.0701 8.92578V7.92578C10.0701 7.3735 9.62235 6.92578 9.07007 6.92578Z"
            fill="white"
          />
          <path
            d="M9.07007 7.42578H8.07007C7.79393 7.42578 7.57007 7.64964 7.57007 7.92578V8.92578C7.57007 9.20192 7.79393 9.42578 8.07007 9.42578H9.07007C9.34621 9.42578 9.57007 9.20192 9.57007 8.92578V7.92578C9.57007 7.64964 9.34621 7.42578 9.07007 7.42578Z"
            stroke="#BABABA"
          />
          <path
            d="M14.0701 6.92578H13.0701C12.5178 6.92578 12.0701 7.3735 12.0701 7.92578V8.92578C12.0701 9.47807 12.5178 9.92578 13.0701 9.92578H14.0701C14.6224 9.92578 15.0701 9.47807 15.0701 8.92578V7.92578C15.0701 7.3735 14.6224 6.92578 14.0701 6.92578Z"
            fill="white"
          />
          <path
            d="M14.0701 7.42578H13.0701C12.7939 7.42578 12.5701 7.64964 12.5701 7.92578V8.92578C12.5701 9.20192 12.7939 9.42578 13.0701 9.42578H14.0701C14.3462 9.42578 14.5701 9.20192 14.5701 8.92578V7.92578C14.5701 7.64964 14.3462 7.42578 14.0701 7.42578Z"
            stroke="#BABABA"
          />
          <path
            d="M9.07007 11.9258H8.07007C7.51778 11.9258 7.07007 12.3735 7.07007 12.9258V13.9258C7.07007 14.4781 7.51778 14.9258 8.07007 14.9258H9.07007C9.62235 14.9258 10.0701 14.4781 10.0701 13.9258V12.9258C10.0701 12.3735 9.62235 11.9258 9.07007 11.9258Z"
            fill="white"
          />
          <path
            d="M9.07007 12.4258H8.07007C7.79393 12.4258 7.57007 12.6496 7.57007 12.9258V13.9258C7.57007 14.2019 7.79393 14.4258 8.07007 14.4258H9.07007C9.34621 14.4258 9.57007 14.2019 9.57007 13.9258V12.9258C9.57007 12.6496 9.34621 12.4258 9.07007 12.4258Z"
            stroke="#BABABA"
          />
          <path
            d="M4.07007 12.4258H3.07007C2.79393 12.4258 2.57007 12.6496 2.57007 12.9258V13.9258C2.57007 14.2019 2.79393 14.4258 3.07007 14.4258H4.07007C4.34621 14.4258 4.57007 14.2019 4.57007 13.9258V12.9258C4.57007 12.6496 4.34621 12.4258 4.07007 12.4258Z"
            stroke="#BABABA"
            strokeDasharray="1 1"
          />
          <path
            d="M16.5693 4.62026H0.570068V3.85126C0.570068 2.50624 1.63786 1.40592 2.97027 1.35352V2.13766C2.97027 3.11656 3.76626 3.91296 4.74467 3.91296C5.72357 3.91296 6.51997 3.11656 6.51997 2.13766V1.35156H10.6194V2.13766C10.6194 3.11656 11.4158 3.91296 12.3947 3.91296C13.3736 3.91296 14.17 3.11656 14.17 2.13766V1.35355C15.5019 1.40647 16.5693 2.50657 16.5693 3.85126V4.62026Z"
            fill="#BABABB"
          />
          <path
            d="M16.0693 4.12026V3.85126C16.0693 2.95772 15.4802 2.19918 14.67 1.94363V2.13766C14.67 2.98718 14.2019 3.7295 13.5102 4.12026H16.0693ZM11.2791 4.12026C10.5874 3.7295 10.1194 2.98718 10.1194 2.13766V1.85156H7.01997V2.13766C7.01997 2.98718 6.55191 3.7295 5.86021 4.12026H11.2791ZM3.62957 4.12026C2.93814 3.7295 2.47027 2.98718 2.47027 2.13766V1.94334C1.65959 2.19862 1.07007 2.95739 1.07007 3.85126V4.12026H3.62957ZM17.0693 5.12026H0.0700684V3.85126C0.0700684 2.19722 1.41573 0.851562 3.06977 0.851562H3.47027V2.13766C3.47027 2.84086 4.04196 3.41296 4.74467 3.41296C5.44787 3.41296 6.01997 2.84086 6.01997 2.13766V0.851562H11.1194V2.13766C11.1194 2.84086 11.6915 3.41296 12.3947 3.41296C13.0979 3.41296 13.67 2.84086 13.67 2.13766V0.851562H14.0696C15.7236 0.851562 17.0693 2.19722 17.0693 3.85126V5.12026Z"
            fill="#BABABA"
          />
        </svg>
      )}
    </div>
  );
};

export default React.memo(DateCell);
