import React from "react";

import { SharedBraceColors, SharedBraceStatusType, SharedBraceStatuses } from "../../../../../../constants";

import TicketAcceptedCircle from "../../../../../../../../../images/TicketAcceptedCircle";
import TicketDeniedTriangle from "../../../../../../../../../images/TicketDeniedTriangle";
import TicketSharedCircle from "../../../../../../../../../images/TicketSharedCircle";

import styles from "./SharedBrace.module.scss";

export interface ISharedBraceProps {
  status: SharedBraceStatusType;
}

const SharedBrace: React.FC<ISharedBraceProps> = ({ status }) => {
  const sharedColor = SharedBraceColors[status];

  const sharedBraceStyle = {
    borderBottomColor: sharedColor,
    borderLeftColor: sharedColor,
  };

  return (
    <div className={styles.sharedBrace} style={sharedBraceStyle}>
      {status === SharedBraceStatuses.confirmed && <TicketAcceptedCircle className={styles.sharedCircle} />}
      {status === SharedBraceStatuses.moderation && <TicketSharedCircle className={styles.sharedCircle} />}
      {status === SharedBraceStatuses.canceled && <TicketDeniedTriangle className={styles.sharedCircle} />}
    </div>
  );
};

export default SharedBrace;
