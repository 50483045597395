import { IListResponseData } from "types/ListResponse";

export interface IBetonState {
  income: Record<string, IListResponseData<IBetonIncomeItem> | null>;
  incomeIsLoading: Record<string, boolean>;
  invalidateKey: number;
  expense: Record<string, IListResponseData<IBetonExpenseItem> | null>;
  expenseDetail: Record<string, IBetonExpenseDetail | null>;
  incomeDetail: Record<string, IBetonIncomeDetail | null>;
  isPending: Record<string, boolean>;
}

export enum BetonIncomeStatusEnum {
  new = "new",
  part = "part",
  full = "full",
}

export interface IBetonIncomeItem {
  id: number;
  name: string;
  measure: string;
  count: string;
  left_count: string;
  delivery_date: string;
  status: BetonIncomeStatusEnum;
  provider: string;
  files: number;
  part_percent: number;
}

export enum BetonIncomeStatusNamesEnum {
  new = "Поступил",
  part = "Залит част.",
  full = "Залит полн.",
}

export interface IBetonExpenseItem {
  id: number;
  name: string;
  measure: string;
  count: string;
  created_at: string;
  worker: string;
  user: string;
  files: number;
  status: "in_work" | "solidification" | "completed";
}

export interface IBetonToProduction {
  count: string;
  user?: number;
  worker?: number;
  work: null | number;
}

export enum BetonExpenseStatusNamesEnum {
  in_work = "В работе",
  solidification = "Набор прочности",
  completed = "Завершено",
}

export interface IBetonExpenseDetail {
  id: number;
  created_at: string;
  name: string;
  measure: string;
  status: "in_work" | "solidification" | "completed";
  user?: string;
  worker?: string;
  exp_work_id: number;
  exp_work_name: string;
  count: string;
  comments: { id: number; user: string; created_at: string; message: string }[];
  beton_date: null;
  beton_method: null;
  beton_t: null;
  beton_env_t: null;
  expenditure_id: number;
  /////////////
  attachments: [];
}

export interface IEditBetonExpenseRequest {
  beton_date: string;
  beton_method: string;
  beton_t: string;
  beton_env_t: string;
}

export interface IBetonIncomeDetail {
  id: number;
  measure: string;
  count: string;
  left_count: string;
  using_count: string;
  received_at: string;
  provider: string;
  expenditure_id: number;
  default_exp_work_id: number;
  default_exp_work_name: string;
  using: {
    id: number;
    count: string;
    created_at: string;
    work_name: string;
    from_user: string;
    to_user: string;
  }[];
  comments: [];
  attachments: [];
}
