import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";

import PopoverOverlay from "../../../../components/UI/_TODO/PopoverOverlay/PopoverOverlay";
import AddEmployeeSlide from "_LEGACY/UI/__trash/AddEmployeeSlide_1";

import moment from "moment/moment";

import { localizeServerDateInUserTimezone } from "../../../../utils/localizeServerDateInUserTimezone";

import InfoIcon from "../../../../images/icons/InfoIcon";
import CheckBoldIcon from "images/icons/CheckBold";
import CrossIcon from "images/icons/CrossIcon";
import PlusIcon from "images/icons/PlusIcon";

import styles from "./index.module.scss";

// TODO write types
interface IProps {
  className?: string;
  employee?: any;
  deleteEmployee?: any;
  editPermission?: any;
  addChainEmployee?: any;
  nextEmployee?: any;
  isChain?: any;
  isCanChain?: any;
  index?: any;
  buildingId?: any;
  isApproved?: any;
  isShownRole?: any;
  isShownApproveDate?: any;
  employees?: any;
  approvedAt?: any;
  objectOnly?: any;
  isInApprovals?: any;
  isShownContacts?: any;
  receivedToApproveDate?: any;
  canDirectlyDelete?: any;
  canDirectlyAdd?: any;
  onAdd?: any;
}

const EmployeesElem = ({
  className = "",
  employee,
  deleteEmployee,
  editPermission,
  addChainEmployee,
  nextEmployee,
  isChain,
  isCanChain,
  index,
  buildingId,
  isApproved,
  isShownRole = true,
  isShownApproveDate,
  employees,
  approvedAt,
  objectOnly,
  isInApprovals,
  isShownContacts,
  receivedToApproveDate,
  //
  canDirectlyDelete,
  canDirectlyAdd,
  onAdd,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasNumericPosition = typeof employee.position === "number";
  const hasNextNumericPosition = typeof nextEmployee?.position === "number";

  const handleAddEmployee = useCallback(
    /* @ts-ignore */
    (val) => {
      if (addChainEmployee) addChainEmployee(val);

      setIsOpen(false);
    },
    [addChainEmployee]
  );

  const isShownAddChain = useMemo(() => {
    if (employee.position && hasNumericPosition && nextEmployee?.position && hasNextNumericPosition) return false;
    if (isChain && !employee.position) return false;

    return isCanChain;
  }, [employee.position, nextEmployee?.position, isChain, isCanChain, hasNumericPosition, hasNextNumericPosition]);

  const isShownEmployeePosition = !!Number(employee.position) && !(employee.position === 1 && !nextEmployee);
  const isShownChainLine =
    hasNumericPosition &&
    !!employee.position &&
    hasNextNumericPosition &&
    !!nextEmployee?.position &&
    (index + 1) % 3 !== 0;

  return (
    <div key={employee?.id} className={cn(styles.container, className)}>
      <div className={styles.blueArea}>
        {isShownEmployeePosition && <div className={styles.positionCount}>{employee.position}</div>}
        {((editPermission && !isApproved) || canDirectlyDelete) && (
          <div className={styles.delete} onClick={() => deleteEmployee(employee)}>
            <CrossIcon width="12" color="#fff" />
          </div>
        )}
        {isApproved && (
          <div>
            {/* @ts-ignore */}
            <CheckBoldIcon width="12" color="#fff" />
          </div>
        )}
        {canDirectlyAdd && (
          <div className={styles.delete} onClick={onAdd}>
            <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_5049_56561)">
                <path d="M7 2H5V7H0V9H5V14H7V9H12V7H7V2Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_5049_56561">
                  <rect width="12" height="12" fill="white" transform="translate(0 2)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.name}>
          {employee?.name ? employee?.name : `${employee?.last_name} ${employee?.first_name} ${employee?.middle_name}`}
        </div>
        {isShownRole && (
          <div className={styles.type}>
            {/* @ts-ignore */}
            {employee?.roles?.map((role) => (
              <span key={role.id}>{role.name} </span>
            ))}
            {employee?.roles?.length === 0 && <span className={styles.noRole}>Должность не назначена</span>}
          </div>
        )}
        {isShownContacts && (
          <div className={styles.contacts}>
            <div className={styles.email}>{employee.email}</div>
            <div>{employee.phone_number}</div>
          </div>
        )}
        {isShownApproveDate && (
          <div className={styles.date}>{localizeServerDateInUserTimezone(approvedAt, "DD.MM.YY\tHH:mm")}</div>
        )}
      </div>
      {!!receivedToApproveDate && (
        <PopoverOverlay
          content={
            <div className={styles.receivedToApprovePopover}>
              <span>Передано на согласование:</span>
              <span>{moment(receivedToApproveDate).format("DD.MM.YY HH:mm")}</span>
            </div>
          }
          openType={"hover"}
          popoverBorderColor={"default"}
          placement={"bottom"}
          className={styles.receivedPopover}
        >
          <InfoIcon />
        </PopoverOverlay>
      )}
      {isShownAddChain && (
        <div className={styles.addIcon} onClick={() => setIsOpen(true)}>
          <PlusIcon color="#4FB2ED" width="14" />
        </div>
      )}
      {isShownChainLine && <div className={styles.chainLine} />}
      {isShownAddChain && (
        <AddEmployeeSlide
          employees={!isInApprovals ? employees : undefined}
          objectEmployees={employees}
          returnOneData={true}
          isOpen={isOpen}
          closeAddPEmployeeBlock={() => setIsOpen(false)}
          addEmployees={handleAddEmployee}
          buildingId={buildingId}
          objectOnly={objectOnly}
        />
      )}
    </div>
  );
};

export default React.memo(EmployeesElem);
