import React from "react";

import SelectStatus from "components/UI/atoms/_TODO/SelectStatus/SelectStatus";

import download from "images/download.svg";

import styles from "./KSPageDownloadDropDown.module.scss";

export const enum downloadOptionsValuesEnum {
  all = "all",
  onlyKs = "onlyKs",
  onlyDocs = "onlyDocs",
}

interface IProps {
  onSelect: (value: string) => void;
  type?: "ks2" | "ks3";
}

const KSPageDownloadDropDown: React.FC<IProps> = ({ onSelect, type = "ks2" }) => {
  const downloadOptions = React.useMemo(() => {
    return [
      {
        id: downloadOptionsValuesEnum.all,
        name: (
          <div className={styles.downloadOption}>
            Скачать все
            <img className={styles.downloadIcon} alt="Иконка скачать" src={download} />
          </div>
        ),
      },
      {
        id: downloadOptionsValuesEnum.onlyKs,
        name: `Скачать только ${type === "ks2" ? "КС-2" : "КС-3"}`,
      },
    ];
  }, [type]);

  return (
    <SelectStatus
      className={styles.selectContainer}
      contentClassName={styles.selectContent}
      optionsClassName={styles.selectOptions}
      optionClassName={styles.selectOption}
      options={downloadOptions}
      onChange={onSelect}
      value={downloadOptionsValuesEnum.all}
      isValueFrozen
    />
  );
};

export default React.memo(KSPageDownloadDropDown);
