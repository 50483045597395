import moment from "moment";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useParams } from "react-router-dom";
import { compose } from "redux";

import {
  approveStockMovement,
  getStockEvent,
  stockEventDataSelector,
  stockEventLoadingSelector,
} from "redux/modules/common/building/stocks";

import SubTablePacking from "components/pages/Stock/components/Income/SubTable/SubTablePacking";
import SubTableUsing from "components/pages/Stock/components/Income/SubTable/SubTableUsing";
import { useDownloadStockReport } from "components/pages/Stock/components/Income/useDownloadStockReport";
import IncomeHeader from "components/pages/Stock/components/common/TableHeader/IncomeHeader";
import IncomeRow from "components/pages/Stock/components/common/TableRow/IncomeRow";

import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import Paper from "shared/ui/layout/Paper/Paper";

import getShortFullName from "utils/formatters/getShortFullName";

import styles from "./StockWriteOffApprove.module.scss";

type RouteParams = { stockId: string; usingId: string; userId: string };

const StockWriteOffApprove: FC<RouteComponentProps<RouteParams>> = () => {
  const { stockId, usingId } = useParams<RouteParams>();

  const dispatch = useDispatch();
  const event = useSelector(stockEventDataSelector);
  const eventIsLoading = useSelector(stockEventLoadingSelector);

  const downloadReportHandler = useDownloadStockReport(usingId);

  const handleApproveStockMovement = React.useCallback(
    (itemId: number, stockUsing: number, packingList: number, movingId: number) => {
      dispatch(approveStockMovement({ stockId, stockUsing, packingList, itemId, payload: true, moving: movingId }));
    },
    [stockId]
  );

  useEffect(() => {
    compose<[RouteParams["stockId"], RouteParams["usingId"]]>(dispatch, getStockEvent)(stockId, usingId);
  }, []);

  if (eventIsLoading) return <Spinner />;

  return (
    <div className={styles.container}>
      <Paper>
        <div className={styles.content}>
          <h1 className={styles.title}>Подтверждение списания со склада</h1>
          <div>
            <IncomeHeader />
            <IncomeRow
              rowNumber={event.id}
              countChildren={event.count_packingitems || event.count_stockusingitems}
              date={moment(event.created_at).format("DD.MM.YYYY")}
              sendUser={getShortFullName(event.sendeduser)}
              confirmedUser={getShortFullName(event.confirmeduser)}
              type={event.type_label}
              status={event.status}
              onFileClick={downloadReportHandler(event)}
              onApprove={() =>
                handleApproveStockMovement(event.id, event.stockusing, event.packinglist, event.stock_moving)
              }
              isExpanded={false}
              isResponsible
            >
              <SubTableUsing stockId={stockId} limit={event.count_stockusingitems} stockusing={event.stockusing} />
              <SubTablePacking packinglist={event.packinglist} />
            </IncomeRow>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default React.memo(StockWriteOffApprove);
