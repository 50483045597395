import cn from "classnames";
import React, { ReactNode } from "react";

import styles from "./TasksIndicators.module.scss";

interface IProps {
  isActive: boolean;
  onClick: () => void;
  children: ReactNode;
}

const TaskIndicatorWrapper: React.FC<IProps> = ({ isActive, onClick, children }) => {
  return (
    <div onClick={onClick} className={cn(styles.wrapper, isActive && styles.wrapperActive)}>
      {children}
    </div>
  );
};

export default TaskIndicatorWrapper;
