export const templateTestId = "buildingTemplate";
export const forbiddenTestId = "buildingForbiddenPage";

export enum BuildingPermissions {
  canViewPlan = "canViewPlan",
  canViewProgress = "canViewProgress",
  canAddPlan = "canAddPlan",
  canAddProgress = "canAddProgress",
  canSharePlan = "canSharePlan",
  canShareProgress = "canShareProgress",
  canAddFiles = "canAddFiles",
  canDeleteFiles = "canDeleteFiles",
}

export type BuildingPermissionType = keyof typeof BuildingPermissions;

export const disabledBuildingPermissions: Record<BuildingPermissionType, boolean> = {
  [BuildingPermissions.canViewPlan]: false,
  [BuildingPermissions.canViewProgress]: false,
  [BuildingPermissions.canAddPlan]: false,
  [BuildingPermissions.canAddProgress]: false,
  [BuildingPermissions.canSharePlan]: false,
  [BuildingPermissions.canShareProgress]: false,
  [BuildingPermissions.canAddFiles]: false,
  [BuildingPermissions.canDeleteFiles]: false,
};

export const generateMockBuildingPermissions = (permissionNames: BuildingPermissionType[]) => {
  let permissions = { ...disabledBuildingPermissions };
  permissionNames.forEach((x) => (permissions[x] = true));
  return permissions;
};
