import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FILE_UPLOAD_VARIANT } from "../FileUpload";
import { fileUploadStorageSelector } from "../model/selectors";
import { fileUploadActions } from "../model/slice";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { IFile } from "utils/serializeFiles";

export interface IUseFileUpload {
  variant: FILE_UPLOAD_VARIANT;
  onUploadFiles?: (files: IFile[]) => Promise<void>;
  onDeleteFile?: (fileId: number) => Promise<void>;
  fileStorageId: number;
  files?: IFile[];
}

/* @ts-ignore */
const computeLocalId = (file: IFile): number => file.lastModified + file.size;

export const useFileUpload = ({
  variant,
  onUploadFiles,
  onDeleteFile,
  fileStorageId,
  files: baseFiles,
}: IUseFileUpload) => {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const storage = useSelector(fileUploadStorageSelector);
  const files = storage[fileStorageId]?.value || [];

  useEffect(() => {
    if (!baseFiles || storage[fileStorageId]?.inited) return;
    dispatch(fileUploadActions.storeFiles({ files: baseFiles, storageId: fileStorageId }));
  }, [baseFiles, fileStorageId]);

  const uploadFilesHandler = useCallback(
    (newFiles: IFile[]) => {
      if (variant === FILE_UPLOAD_VARIANT.NEW || !onUploadFiles) {
        const filesToStore = newFiles.map((file) => ({ id: Math.random(), file })) as unknown as IFile[];
        dispatch(fileUploadActions.storeFiles({ files: filesToStore, storageId: fileStorageId }));
      } else if (onUploadFiles) {
        setIsPending(true);
        onUploadFiles(newFiles)
          .catch(errorCatcher)
          .finally(() => {
            setIsPending(false);
          });
      }
    },
    [variant, onUploadFiles, fileStorageId]
  );

  const deleteFileHandler = useCallback(
    (fileId: number) => {
      if (variant === FILE_UPLOAD_VARIANT.NEW || !onDeleteFile) {
        dispatch(fileUploadActions.removeStoredFile({ fileId, storageId: fileStorageId }));
      } else if (onDeleteFile) {
        setIsPending(true);
        onDeleteFile(fileId)
          .catch(errorCatcher)
          .finally(() => {
            setIsPending(false);
          });
      }
    },
    [variant, onDeleteFile, fileStorageId]
  );

  return {
    uploadFilesHandler,
    deleteFileHandler,
    computeLocalId,
    files,
    isPending,
  };
};
