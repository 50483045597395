import { ISubExpenditure } from "./types";

export const checkIsSubExpenditureValidToContinue = (exp: ISubExpenditure) =>
  !exp._isEdit && checkIsValidStructureItem(exp);

export const checkIsSubExpenditureValidToConfirm = (exp: ISubExpenditure) =>
  checkIsSubExpenditureValidToContinue(exp) && checkIsValidCostItem(exp);

export const formatSubExpenditureNumber = (expNumber: number, index: number, subExpendituresLength: number): string => {
  if (index >= subExpendituresLength) return "";
  const num = index + 1;
  const extraZeros = Math.floor(Math.log10(subExpendituresLength));
  const digits = Math.ceil(Math.log10(num));
  return expNumber + "." + "0".repeat(Math.max(0, extraZeros - digits)) + num;
};

export const sortSubExpendituresDescending = (a: ISubExpenditure, b: ISubExpenditure) => {
  const aNumber = a.number.split(".");
  const bNumber = b.number.split(".");
  if (+aNumber[0] > +bNumber[0]) return -1;
  else if (+aNumber[0] < +bNumber[0]) return 1;
  return +aNumber[1] > +bNumber[1] ? -1 : 1;
};

export const sortSubExpendituresAscending = (a: ISubExpenditure, b: ISubExpenditure) => {
  const aNumber = a.number.split(".");
  const bNumber = b.number.split(".");
  if (+aNumber[0] < +bNumber[0]) return -1;
  else if (+aNumber[0] > +bNumber[0]) return 1;
  return +aNumber[1] < +bNumber[1] ? -1 : 1;
};

export const checkIsValidCostItem = (exp: ISubExpenditure) =>
  !!exp.name && !!Number(exp.percent) && Number(exp.percent) > 0;

export const checkIsValidStructureItem = (exp: ISubExpenditure) =>
  !!exp.expenditure_type &&
  !!exp.measure &&
  exp.measure !== "-" &&
  !!exp.name.length &&
  !!exp.count.length &&
  !isNaN(Number(exp.count));
