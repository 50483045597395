import { createSelector } from "reselect";

import { moduleName } from "./manufacturing";
import { RootState } from "app/store/rootReducer";

export const stateSelector = (state: RootState) => state[moduleName];

export const treeSelector = createSelector(stateSelector, (state) => state.tree);
export const materialsTreeSelector = createSelector(stateSelector, (state) => state.materialsTree);
export const mimesTreeSelector = createSelector(stateSelector, (state) => state.mimesTree);
export const resourcesTreeSelector = createSelector(stateSelector, (state) => state.resourcesTree);
export const equipmentTreeSelector = createSelector(stateSelector, (state) => state.equipmentTree);

export const planSelector = createSelector(stateSelector, (state) => state.plan);
export const materialsSelector = createSelector(stateSelector, (state) => state.materials);
export const mimesSelector = createSelector(stateSelector, (state) => state.mimes);

export const weekPlanSelector = createSelector(stateSelector, (state) => state.weekPlan);
export const weekMaterialsSelector = createSelector(stateSelector, (state) => state.weekMaterials);
export const weekMimesSelector = createSelector(stateSelector, (state) => state.weekMimes);

export const chartViewModeSelector = createSelector(stateSelector, (state) => state.chartViewMode);
export const manufacturingTabSelector = createSelector(stateSelector, (state) => state.tab);

export const intervalSelector = createSelector(stateSelector, (state) => state.interval);
export const materialIntervalSelector = createSelector(stateSelector, (state) => state.materialInterval);

export const modalIntervalListsSelector = createSelector(stateSelector, (state) => state.modal.modalIntervalLists);
export const modalDetailedIntervalsSelector = createSelector(
  stateSelector,
  (state) => state.modal.modalDetailedInterval
);
export const modalFilesSelector = createSelector(stateSelector, (state) => state.modal.modalFiles);
export const modalMaterialsSelector = createSelector(stateSelector, (state) => state.modal.modalMaterials);
export const modalLoadingsSelector = createSelector(stateSelector, (state) => state.modal.isLoading);

export const projectsSelector = createSelector(stateSelector, (state) => state.projects);
export const isLoadingProjectsSelector = createSelector(stateSelector, (state) => state.isLoadingProjects);
export const projectEstimateSelector = createSelector(stateSelector, (state) => state.projectEstimate);

export const projectDataSelector = createSelector(stateSelector, (state) => state.projectData);
export const sectionsDataSelector = createSelector(projectDataSelector, (state) => state.sections);
export const plannedSectionsDataSelector = createSelector(projectDataSelector, (state) => state.planned_sections);
export const planSectionsDataSelector = createSelector(planSelector, (state) => state.sections);
export const planPlannedSectionsDataSelector = createSelector(planSelector, (state) => state.planned_sections);
export const materialDataSelector = createSelector(stateSelector, (state) => state.materialData);
export const mimesDataSelector = createSelector(stateSelector, (state) => state.mimesData);

export const projectWeekDataSelector = createSelector(stateSelector, (state) => state.projectWeekData);
export const materialWeekDataSelector = createSelector(stateSelector, (state) => state.materialWeekData);
export const mimesWeekDataSelector = createSelector(stateSelector, (state) => state.mimesWeekData);

export const isLoadingChartTreeSelector = createSelector(stateSelector, (state) => state.isLoadingChartTree);
export const manufacturingMonthMarkersSelector = createSelector(stateSelector, (state) => state.monthMarkers);

export const isLoadingChartDataSelector = createSelector(stateSelector, (state) => state.isLoadingChartData);
export const activeBranchSelector = createSelector(stateSelector, (state) => state.activeBranch);
export const diagramFiltersSelector = createSelector(stateSelector, (state) => state.diagramFilters);
export const isLockedIntervalEditingSelector = createSelector(stateSelector, (state) => state.isLockedIntervalEditing);

export const criticalDatesSelector = createSelector(stateSelector, (state) => state.criticalDates);

export const isEnabledDiagramLinkingSelector = createSelector(
  stateSelector,
  (state) => state.diagramIntervalLinks.isEnabledLinking
);
export const isBeingDraggedDiagramIntervalSelector = createSelector(
  stateSelector,
  (state) => state.diagramIntervalLinks.isBeingDragged
);
export const diagramIntervalArrowsSelector = createSelector(
  stateSelector,
  (state) => state.diagramIntervalLinks.arrows
);
export const highlightedIntervalsSelector = createSelector(
  stateSelector,
  (state) => state.diagramIntervalLinks.highlightedIntervals
);
export const highlightedArrowsSelector = createSelector(
  stateSelector,
  (state) => state.diagramIntervalLinks.highlightedArrows
);
export const arrowHashSelector = createSelector(stateSelector, (state) => state.diagramIntervalLinks.hash);

export const relationsFromCurrentIntervalSelector = createSelector(
  stateSelector,
  (state) => state.modal.relationsFromCurrentInterval
);
export const relationsToCurrentIntervalSelector = createSelector(
  stateSelector,
  (state) => state.modal.relationsToCurrentInterval
);
export const relationAvailableSectionsSelector = createSelector(
  stateSelector,
  (state) => state.modal.availableSections
);
export const relationAvailableIntervalsSelector = createSelector(
  stateSelector,
  (state) => state.modal.availableIntervals
);

export const manufacturingHashSelector = createSelector(stateSelector, (state) => state.manufacturingHash);

export const manufacturingExpandedBranchesSelector = createSelector(
  stateSelector,
  (state) => state.manufacturingExpandedBranches
);

export const constructingExpandedBranchesSelector = createSelector(
  stateSelector,
  (state) => state.constructingExpandedBranches
);

export const manufacturingModalMaterialsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.isMaterialsLoading
);
