import { ESTIMATE_STATES_IDS } from "../../../../../../pages/Handler/ui/ProHandler/constants";

const sliceSlashChar = (arg) => {
  if (typeof string === "number") return arg;
  if (!arg) return "";
  if (arg[0] === "/") return arg.slice(1);
  return arg;
};

export const getExpendituresUrl = (ids, isDefaultExpenditure) => {
  const formattedArgs = {};
  for (let key in ids) {
    formattedArgs[key] = sliceSlashChar(ids[key]);
  }
  let url = `/building/${formattedArgs.building}/estimate/${formattedArgs.estimateState}/expenditures/`;
  if (ids.estimateState === ESTIMATE_STATES_IDS.DRAFT) {
    url = `/building/${formattedArgs.building}/estimate/${formattedArgs.estimateState}/sections/${formattedArgs.section}/expenditures/`;
  }
  if (isDefaultExpenditure) {
    url = `/building/${formattedArgs.building}/estimate/expenditures/default/new/`;
  }
  return url;
};
