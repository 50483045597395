import cn from "classnames";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";

import MatchingIcon, { MatchingStatusesEnum } from "_LEGACY/UI/MatchingIcon/MatchingIcon";
import AddRelationToProduct from "components/UI/_TODO/AddRelationToProduct";
import { IBuildingPropsPermissions } from "components/pages/Building/Building";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import {
  useBindDrawingToEstimateMutation,
  useDeleteDrawingMutation,
  useGetDrawingBindingsQuery,
} from "../../model/blueprintsQuery";
import BlueprintsModal from "./../../widgets/BlueprintsModal/BlueprintsModal";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import Actions from "shared/ui/controls/Actions/Actions";
import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { IDrawing, IDrawingSet } from "../../model/types";
import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";

import styles from "./DrawingRow.module.scss";

interface IProps {
  drawing: IDrawing;
  parent?: IDrawingSet;
  permissions: IBuildingPropsPermissions;
}

const DrawingRow: React.FC<IProps> = ({ drawing, parent, permissions }) => {
  const buildingId = useObjectId();
  const [deleteDrawing, { isLoading }] = useDeleteDrawingMutation();

  const onDelete = () => {
    deleteDrawing({ buildingId, drawingSetId: drawing.id });
  };

  const showActionsLoader = isLoading;

  const [isEditModal, setIsEditModal] = useState(false);
  const [isDetailModal, setIsDetailModal] = useState(false);

  const openViewModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDetailModal(true);
    setIsEditModal(false);
  };

  const openEditModal = () => {
    setIsDetailModal(true);
    setIsEditModal(true);
  };

  const closeModal = () => {
    setIsDetailModal(false);
    setIsEditModal(false);
  };

  const [matching, setMatching] = useState<any>(null);
  const [selectedMathedIds, setSelectedMatchedIds] = useState<Record<number, boolean>>({});

  const onSelectMatched = (id: number) => {
    setSelectedMatchedIds((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const closeMatching = () => {
    setMatching(null);
    setSelectedMatchedIds(bindingsDict);
  };

  const [bindDrawings] = useBindDrawingToEstimateMutation();
  const submitMatching = () => {
    const matchedExpenditureIds = Object.entries(selectedMathedIds)
      .filter(([_, value]) => !!value)
      .map(([key]) => key);

    bindDrawings({ buildingId, drawingSetId: drawing.id, expenditure_ids: matchedExpenditureIds });
  };

  const { data: bindings } = useGetDrawingBindingsQuery({ buildingId, drawingSetId: drawing.id }, { skip: !matching });

  const bindingsDict = useMemo(() => {
    return (
      bindings?.expenditure_ids.reduce<Record<number, boolean>>((acc, cur) => {
        return {
          ...acc,
          [cur]: true,
        };
      }, {}) ?? {}
    );
  }, [bindings]);

  useEffect(() => {
    setSelectedMatchedIds(bindingsDict);
  }, [bindingsDict]);

  const isWithActions = permissions.canEditDrawings || permissions.canDeleteDrawings;

  const className = cn(styles.row, { [styles.withActions]: isWithActions });

  return (
    <>
      <TableReusableRow className={className} isHoverable={false}>
        <TableReusableCell isNoBreak>{drawing.title}</TableReusableCell>
        <TableReusableCell isCentered>
          <MatchingIcon
            onClick={() => setMatching({ id: 1 })}
            status={drawing.has_link_with_estimate ? MatchingStatusesEnum.auto : MatchingStatusesEnum.outOfEstimate}
          />
        </TableReusableCell>
        <TableReusableCell isNoBreak>{drawing.sheet_numbers.join(", ")}</TableReusableCell>
        <TableReusableCell>{moment(drawing.created_at).format("DD.MM.YYYY")}</TableReusableCell>
        <TableReusableCell>
          <FilesClipButton
            isEmptyLowOpacity
            hasGlobalClassName={!drawing.files_count}
            count={drawing.files_count}
            className={styles.files}
            onClick={openViewModal}
          />
        </TableReusableCell>
        {isWithActions && (
          <TableReusableCell>
            {showActionsLoader && <Spinner isStatic isSmallGray />}
            {!showActionsLoader && (
              <Actions
                canEdit={permissions.canEditDrawings}
                canRemove={permissions.canDeleteDrawings}
                className={styles.actions}
                onRemove={onDelete}
                onEdit={openEditModal}
                confirmDeleteText="Вы действительно хотите удалить чертеж?"
              />
            )}
          </TableReusableCell>
        )}
      </TableReusableRow>
      <BlueprintsModal
        parent={parent}
        isDefaultEditing={isEditModal}
        isOpen={isDetailModal}
        onClose={closeModal}
        drawing={drawing}
        canEdit={permissions.canEditDrawings}
        canDelete={permissions.canDeleteDrawings}
      />
      <AddRelationToProduct
        key={matching}
        idAddRelation={matching}
        handleClose={closeMatching}
        objectId={buildingId}
        onSubmit={submitMatching as any}
        updateProducts={undefined as any}
        type={undefined}
        typeForFilter={ExpenditureTypeEnum.work}
        isExternalMultiple
        externalOnSelect={onSelectMatched}
        externalCheckedExpenditureIds={selectedMathedIds}
        canSubmitEmpty
      />
    </>
  );
};

export default DrawingRow;
