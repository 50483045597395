import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";

import useOnClickOutside from "hooks/useOnClickOutside";

import styles from "./Dropdown.module.scss";

interface IProps {
  children: React.ReactNode;
  placeholder: React.ReactNode;
  classNameContainer?: string;
  classNameContent?: string;
  classNameInput?: string;
  icon?: React.ReactNode;
  externalIsOpen?: boolean;
  onClick?: () => void;
  onClose?: () => void;
}

const Dropdown: React.FC<IProps> = ({
  children,
  placeholder,
  classNameContainer,
  classNameContent,
  classNameInput,
  icon,
  externalIsOpen,
  onClick,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false);
    onClose?.();
  });

  useEffect(() => {
    if (externalIsOpen === undefined) return;
    setIsOpen(externalIsOpen);
  }, [externalIsOpen]);

  const toggleHandler = () => {
    if (onClick) {
      onClick();
    } else {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div className={cn(styles.container, classNameContainer, { [styles.containerOpened]: isOpen })} ref={dropdownRef}>
      <div className={cn(styles.input, classNameInput, { [styles.inputOpened]: isOpen })} onClick={toggleHandler}>
        {placeholder}
        {icon}
      </div>
      {isOpen && <div className={cn(styles.content, classNameContent)}>{children}</div>}
    </div>
  );
};

export default Dropdown;
