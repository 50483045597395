export enum ExpenditureTypeEnum {
  material = "material",
  work = "work",
  equipment = "equipment",
  worker = "worker",
  machine = "machine",
  transport = "transport",
}

export type ExpenditureType = keyof typeof ExpenditureTypeEnum;
