import React from "react";

const RoundChart = ({ data }) => {
  const RADIUS = "15.91549430918954";
  const circumference = 2 * Math.PI * RADIUS;

  return (
    <svg width="100%" height="100%" viewBox="0 0 42 42" style={{ transform: "rotate(-90deg)" }}>
      <circle className="donut-hole" cx="21" cy="21" r={RADIUS} fill="#fff"></circle>
      <circle
        className="donut-ring"
        cx="21"
        cy="21"
        r={RADIUS}
        fill="transparent"
        stroke="#F0F0F0"
        strokeWidth="5"
      ></circle>
      {data.map((item, i) => {
        const offset = item.width < 100 ? item.width : 100;
        return (
          <circle
            key={i}
            className="donut-segment"
            cx="21"
            cy="21"
            r={RADIUS}
            fill="transparent"
            stroke={item.color}
            strokeWidth={5 + 0.5 * i}
            strokeDasharray={circumference}
            strokeDashoffset={100 - offset}
          ></circle>
        );
      })}
    </svg>
  );
};

export default React.memo(RoundChart);
