import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

const rootSelector = (state: RootState) => state.createOutOfEstimateSection;

export const getIsVisible = createSelector(rootSelector, (state) => state.isVisible);
export const getIsCreating = createSelector(rootSelector, (state) => state.isCreating);
export const getName = createSelector(rootSelector, (state) => state.name);
export const getParentId = createSelector(rootSelector, (state) => state.parentId);
