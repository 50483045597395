class ApprovalAndView {
  static filterRequisitionApprovers = (approvalAndViewElements) => approvalAndViewElements.filter((element) =>
    !element.is_initial
  );

  static getApprovers = (approvalAndViewElements) => approvalAndViewElements.filter((element) => element.is_obligatory);
  static getViewers = (approvalAndViewElements) => approvalAndViewElements.filter((element) => !element.is_obligatory);

  static makeEmployeesApprovers = (employees) => employees.map((employee) => ({ ...employee, is_obligatory: true }));
  static makeEmployeesViewers = (employees) => employees.map((employee) => ({ ...employee, is_obligatory: false }));

  static getApprovalElementsFromEmployees = (employees) => employees.map((employee) => ({
    employee_id: employee.id,
    position: employee.position || 0,
    is_obligatory: employee.is_obligatory
  }));

  constructor(approvalAndViewElements) {
    const elementsWithoutRequisition = ApprovalAndView.filterRequisitionApprovers(approvalAndViewElements);

    this.approvers = ApprovalAndView.getApprovers(elementsWithoutRequisition);
    this.viewers = ApprovalAndView.getViewers(elementsWithoutRequisition);
  }

  checkUserInApprovers = (userId) => this.approvers.length !== 0 && this.approvers.some((approver) => (
    approver.employee?.id === userId || approver?.employee_id === userId
  ));

  checkUserIsApprover = (userId, approver) => (approver.employee?.id === userId || approver?.employee_id === userId);

  checkApproverInChain = (approver) => approver.position > 0;

  checkApproversInTheSameChain = (firstApprover, secondApprover) => {
    if (firstApprover.position > secondApprover.position && firstApprover.position - 1 === secondApprover.position) {
      return true;
    }

    if (secondApprover.position > firstApprover.position && secondApprover.position - 1 === firstApprover.position) {
      return true;
    }
    return false;
  };

  checkCanUserApprove = (employees, userId) => employees.length !== 0 && employees.some((currentApprover, i) => {
    const prevApprover = employees[i - 1];

    if (!this.checkUserIsApprover(userId, currentApprover) || currentApprover.is_approved) return false;

    if (
      this.checkApproverInChain(currentApprover)
      && prevApprover
      && this.checkApproverInChain(prevApprover)
      && this.checkApproversInTheSameChain(prevApprover, currentApprover)
      && !prevApprover.is_approved
    ) return false;

    return true;
  });

  checkCanUserUnApprove = (employees, userId) => employees.length !== 0 && employees.some((currentApprover, i) => {
    const nextApprover = employees[i + 1];

    if (!this.checkUserIsApprover(userId, currentApprover) || !currentApprover.is_approved) return false;

    if (
      this.checkApproverInChain(currentApprover)
      && nextApprover
      && this.checkApproverInChain(nextApprover)
      && this.checkApproversInTheSameChain(nextApprover, currentApprover)
      && nextApprover.is_approved
    ) return false;

    return true;
  });

  checkAllApproversApprove = () => (
    this.approvers.every((approveElement) => approveElement.is_approved)
  );

  checkCanAddApprovers = () => {
    return this.approvers.length === 0 || !this.approvers.every((approver) => approver.is_approved);
  };

  getApprovedApprovers = () => this.approvers.filter((approver) => approver.is_approved);
}

export default ApprovalAndView;