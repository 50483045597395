import React, { FC } from "react";

import { userSelector } from "redux/modules/_TODO/auth";

import TaskComments, { ITaskComments } from "./TaskComments";
import { useTypedSelector } from "app/store/typedUseSelector";

import { useTaskComments } from "../../hooks/useTaskComments";

export interface ITaskCommentsContainer extends Pick<ITaskComments, "comments"> {
  taskId: number;
  isNewTask?: boolean;
}

const TaskCommentsContainer: FC<ITaskCommentsContainer> = ({ taskId, isNewTask, comments }) => {
  const currentUser = useTypedSelector(userSelector);
  const { createNewTaskCommentHandler, writeTaskCommentHandler, commentText, textInputRef } = useTaskComments(
    taskId,
    currentUser,
    isNewTask
  );

  return (
    <TaskComments
      comments={comments}
      createNewTaskCommentHandler={createNewTaskCommentHandler}
      writeTaskCommentHandler={writeTaskCommentHandler}
      commentText={commentText}
      textInputRef={textInputRef}
    />
  );
};

export default React.memo(TaskCommentsContainer);
