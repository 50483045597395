import moment from "moment";
import React from "react";

const CurrentDate = (
  <span>
    Текущий период на: <span style={{ color: "#4FB2ED" }}>{moment().format("DD/MM/YYYY")}</span>
  </span>
);

export const TABLE_GROUPS = [
  {
    title: "*Сведения за выбранный период",
    fieldName: "selected_period",
  },
  {
    title: "План на весь проект",
    fieldName: "project",
  },
  {
    title: CurrentDate,
    fieldName: "from_start",
  },
];
