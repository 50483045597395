import { IFinanceTableRowData } from "pages/Finance/Summary/model/types";
import { summaryUtils } from "pages/Finance/Summary/model/utils";
import { IProjectViewAuditV2 } from "pages/Finance/_TODO/legacyAuditTypes";
import { financeUtils, generateFinanceKey } from "pages/Finance/common/model/commonFinanceUtils";
import {
  FinanceExpendituresItemsTypes,
  IFinanceTreeItem,
  IPreparedMonthData,
} from "pages/Finance/common/model/interfaces";

import { IAuditObjectResponse } from "./types";

export const auditUtils = {
  mapProjectData: (project: IProjectViewAuditV2, ctx: { oldTree: IFinanceTreeItem[] }): IFinanceTreeItem => {
    const { id, name } = project;

    const oldProject = ctx.oldTree.find((el) => el.key === generateFinanceKey(id, "project"));
    const children2 = project.buildings.map<IFinanceTreeItem>((obj) => ({
      id: obj.id,
      name: obj.name,
      data: auditUtils.getProjectData(obj),
      depth: -1,
      key: generateFinanceKey(obj.id, "object"),
      type: "object",
      children: [],
    }));

    const children = auditUtils.mergeObjects(project.buildings, oldProject?.children, ctx);

    return {
      id,
      name,
      type: "project",
      key: generateFinanceKey(id, "project"),
      depth: -2,
      shouldBeLoaded: false,
      isOpen: oldProject?.isOpen,
      data: auditUtils.getProjectData(project),
      children,
    };
  },

  mergeObjects: (
    objectsFromProject: IProjectViewAuditV2["buildings"] = [],
    objectsAlreadyExists: IFinanceTreeItem[] = [],
    ctx: { oldTree: IFinanceTreeItem[] }
  ): IFinanceTreeItem[] => {
    const detailedObjectsMap = objectsAlreadyExists.reduce<Record<string, IFinanceTreeItem>>((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    const newObjectsWithReplacingIfOldExists = objectsFromProject.map((obj) => {
      if (detailedObjectsMap[obj.id]) {
        const candidate = { ...detailedObjectsMap[obj.id] };
        delete detailedObjectsMap[obj.id];
        return candidate;
      } else {
        return auditUtils.mapObjects(obj, ctx);
      }
    });

    return [...newObjectsWithReplacingIfOldExists, ...Object.values(detailedObjectsMap)];
  },

  generateAuditTableKey: (type: keyof IProjectViewAuditV2["audit_data"], key: string) => {
    return `${type}_${key}`;
  },

  getProjectData: (project: Pick<IProjectViewAuditV2, "audit_data">) => {
    const acc: IPreparedMonthData = {};

    Object.entries(project.audit_data.from_start).forEach(([key, value]) => {
      acc[auditUtils.generateAuditTableKey("from_start", key)] = value;
    });

    Object.entries(project.audit_data.project).forEach(([key, value]) => {
      acc[auditUtils.generateAuditTableKey("project", key)] = value;
    });

    Object.entries(project.audit_data.selected_period).forEach(([key, value]) => {
      acc[auditUtils.generateAuditTableKey("selected_period", key)] = value;
    });

    return acc;
  },

  setProject: (tree: IFinanceTreeItem[], data: IProjectViewAuditV2[], projectId: number): IFinanceTreeItem[] => {
    const isProjectAlreadyExists = tree.some((el) => el.id === projectId);
    if (isProjectAlreadyExists) {
      return tree.map((project) => {
        if (project.id !== projectId) {
          return project;
        } else {
          return auditUtils.mapProjectData(data[0], { oldTree: tree });
        }
      });
    } else {
      return [...tree, auditUtils.mapProjectData(data[0], { oldTree: tree })];
    }
  },

  setObject: (
    tree: IFinanceTreeItem[],
    data: IAuditObjectResponse,
    projectId: number,
    objectId: number
  ): IFinanceTreeItem[] => {
    const parentProjectIndex = tree.findIndex((el) => el.id === +projectId);
    if (parentProjectIndex === -1) {
      return [
        ...tree,
        {
          id: projectId,
          name: data.name,
          type: "project",
          key: generateFinanceKey(projectId, "project"),
          depth: -2,
          shouldBeLoaded: true,
          isOpen: true, //oldProject?.isOpen || isOnlyOneProject,
          data: {}, //summaryUtils.getProjectData(project),
          children: [
            {
              id: objectId,
              name: data.name,
              type: "object",
              key: generateFinanceKey(objectId, "object"),
              depth: -1,
              shouldBeLoaded: false, //!!oldObject?.children?.length,
              isOpen: true, //oldObject?.isOpen || isOnlyOneObject,
              data: auditUtils.getProjectData(data),
              children: data.sections.map((section) => auditUtils.mapSections(section, { object: data })),
            },
          ],
        },
      ];
    } else {
      return tree.map((proj, i) => {
        if (i === parentProjectIndex) {
          return {
            ...proj,
            children: [
              ...(proj.children ?? []).map((obj) => {
                if (obj.id === objectId) {
                  return {
                    ...obj,
                    type: "object" as const,
                    depth: -1,
                    children: data.sections.map((section) => auditUtils.mapSections(section, { object: data })),
                  };
                } else {
                  return obj;
                }
              }),
            ],
          };
        } else {
          return proj;
        }
      });
    }
  },

  mapObjects: (
    object: IProjectViewAuditV2["buildings"][number],
    ctx: { oldTree: IFinanceTreeItem[] }
  ): IFinanceTreeItem => {
    const { id, name } = object;

    const oldObject = summaryUtils.findNode(ctx.oldTree, { id, type: "object", key: generateFinanceKey(id, "object") });

    const isOnlyOneObject = false; //= Object.keys(ctx.objects ?? {}).length < 2;

    return {
      id,
      name,
      type: "object",
      key: generateFinanceKey(id, "object"),
      depth: -1,
      shouldBeLoaded: !oldObject?.children?.length,
      isOpen: oldObject?.isOpen || isOnlyOneObject,
      data: auditUtils.getProjectData(object),
      children: oldObject?.children ?? [],
    };
  },

  mapSections: (
    section: IAuditObjectResponse["sections"][number],
    ctx: { object: IAuditObjectResponse }
  ): IFinanceTreeItem => {
    const { id, name } = section;

    return {
      id,
      name,
      type: "object",
      key: generateFinanceKey(id, "object"),
      depth: 0,
      shouldBeLoaded: false,
      isOpen: ctx.object.sections.length < 2,
      data: auditUtils.getProjectData(section),
      children: section.subsections.map((el) => auditUtils.mapSubsections(el, { section })),
    };
  },

  mapSubsections: (
    section: IAuditObjectResponse["sections"][number]["subsections"][number],
    ctx: { section: IAuditObjectResponse["sections"][number] }
  ): IFinanceTreeItem => {
    const { id, name } = section;

    const work = auditUtils.mapExpenditure(section.works, "work", { section });
    //const group = summaryUtils.mapExpenditure(section.groups, "group");
    const material = auditUtils.mapExpenditure(section.materials, "material", { section });
    const service = auditUtils.mapExpenditure(section.services, "service", { section });

    return {
      id,
      name,
      type: "object",
      key: generateFinanceKey(id, "object"),
      depth: 1,
      shouldBeLoaded: false, //!!oldObject?.children?.length,
      isOpen: ctx.section.subsections.length < 2,
      data: auditUtils.getProjectData(section),
      children: [work, material, service /* group */].filter((el) => !!el) as IFinanceTreeItem[],
    };
  },

  mapExpenditure: (
    exp: IAuditObjectResponse["sections"][number]["subsections"][number]["works"],
    type: FinanceExpendituresItemsTypes,
    ctx: { section: IAuditObjectResponse["sections"][number]["subsections"][number] }
  ): IFinanceTreeItem | null => {
    if (!exp.expenditures.length && !exp.groups.length) {
      return null;
    }

    const isOpen =
      ctx.section.materials.expenditures.length +
        ctx.section.materials.groups.length +
        ctx.section.works.expenditures.length +
        ctx.section.works.groups.length +
        ctx.section.services.expenditures.length +
        ctx.section.services.groups.length <
      2;

    const childrenExpenditures = exp.expenditures.map<IFinanceTreeItem>((el) => ({
      id: el.id,
      name: el.name,
      type: "expenditure",
      key: generateFinanceKey(el.id, type),
      shouldBeLoaded: false,
      isPositive: true,
      isLoading: false,
      depth: 3,
      data: auditUtils.getProjectData(el),
      number: +el.number,
    }));

    /* const childrenGroups = exp.groups.map<IFinanceTreeItem>((group) => ({
      id: group.id,
      name: group.name,
      type: "group",
      key: generateFinanceKey(group.id, type),
      isPositive: true,
      isLoading: false,
      depth: 3,
      data: summaryUtils.getProjectData(exp),
      children: group.expenditures.map((ex) => ({
        id: ex.id,
        name: ex.name,
        type: "expenditure",
        key: generateFinanceKey(ex.id, type),
        shouldBeLoaded: false,
        isPositive: true,
        isLoading: false,
        depth: 4,
        data: summaryUtils.getProjectData(group),
        number: ex.number,
      })),
    })); */

    return {
      id: ctx.section.id,
      name: financeUtils.getExpenditureName(type),
      type,
      key: generateFinanceKey(ctx.section.id, type),
      depth: 2,
      shouldBeLoaded: false,
      isOpen,
      data: auditUtils.getProjectData(exp),
      children: childrenExpenditures ?? [] /* .concat(childrenGroups ?? []) */,
    };
  },
};
