import { tasksFieldsType } from "components/pages/Tasks/model/types";

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface AddCheckpointState {
  selects: tasksFieldsType;
}

const initialState: AddCheckpointState = {
  selects: {
    section: [],
    users: [],
    approvers: [],
  },
};

export const addCheckpointStateSlice = createSlice({
  name: "addCheckpointState",
  initialState,
  reducers: {
    setSelects: (state, action: PayloadAction<tasksFieldsType>) => {
      state.selects = action.payload;
    },
  },
});

export const addCheckpointStateActions = addCheckpointStateSlice.actions;

export default addCheckpointStateSlice.reducer;
