import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCurrencyShortName } from "../../utils/helpers/getCurrencyShortName";
import { measureSwitcher } from "../../utils/measureSwitcher";
import { measureSelector } from "./model/selectors";
import { financeMeasureActions } from "./model/slice";

export const measureContentSwitcher = (measure: 1 | 1_000 | 1_000_000) => {
  const activeCurrency = getCurrencyShortName();

  if (measure === 1_000_000) {
    return "млн.";
  } else if (measure === 1_000) {
    return "тыс.";
  } else {
    return `${activeCurrency}.`;
  }
};

export const useFinanceMeasure = () => {
  const dispatch = useDispatch();
  const measure = useSelector(measureSelector);
  const measureChangeHandler = useCallback(() => {
    dispatch(financeMeasureActions.setMeasure(measureSwitcher(measure)));
  }, [measure]);
  const measureInTitle = useMemo(() => measureContentSwitcher(measure), [measure]);
  return {
    measure,
    measureChangeHandler,
    measureInTitle,
  };
};
