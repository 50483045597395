import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

export const stateSelector = (state: RootState) => state.addCheckpointState;

export const checkpointSelectsSelector = createSelector(stateSelector, (state) => state.selects);
// export const multiplePlanApprovingsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
// export const multiplePlanApprovingsInvalidateKeySelector = createSelector(
//   stateSelector,
//   (state) => state.invalidateKey
// );
