import { message } from "antd";
import moment from "moment";
import { Dispatch } from "redux";

import { journalExecutionActions } from "../building/journal/journalExecution/actions";
import { passportsActions } from "./actions";
import { passportsApi } from "./api";
import { RootState } from "app/store/rootReducer";

import {
  DocumentsTypesEnum,
  IAttachFileInWorkInJournalRequest,
  ICreateCertificate,
  ICreatePassport,
  IFileAttachingToExpenditureParams,
  IPassport,
} from "./types";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const createPassport =
  (values: Omit<ICreatePassport, "file"> & { file: File }, productId: number) => async (dispatch: Dispatch) => {
    dispatch(passportsActions.setPending(true));
    const files = await getArrayFilesInBase64([values.file]);
    passportsApi
      .createPassport({
        ...values,
        packing_item: productId,
        start_at: moment(values.start_at).format("YYYY-MM-DD"),
        end_at: moment(values.end_at).format("YYYY-MM-DD"),
        // file: files[0]?.file as string,
        originalname: files[0]?.name,
      })
      .then(() => {
        dispatch(passportsActions.invalidateKey());
        message.success("Успешно сохранено");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(passportsActions.setPending(false));
      });
  };

export const createCertificate =
  (values: Omit<ICreateCertificate, "file"> & { file: File }, productId: number) => async (dispatch: Dispatch) => {
    dispatch(passportsActions.setPending(true));
    const files = await getArrayFilesInBase64([values.file]);
    passportsApi
      .createCertificate({
        ...values,
        packing_item: productId,
        start_at: moment(values.start_at).format("YYYY-MM-DD"),
        end_at: moment(values.end_at).format("YYYY-MM-DD"),
        file: files[0]?.file as string,
        originalname: files[0]?.name,
        issue_date: moment(values.start_at).format("YYYY-MM-DD"),
      })
      .then(() => {
        dispatch(passportsActions.invalidateKey());
        message.success("Успешно сохранено");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(passportsActions.setPending(false));
      });
  };

export const getPassportsAndCertificates = (productId: number) => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().passports.documents?.[productId]?.documents?.length) {
    dispatch(passportsActions.setDocumentsLoading(productId, true));
  }
  passportsApi
    .getDocuments(productId)
    .then(({ data }) => {
      dispatch(passportsActions.setDocuments(productId, data.results));
    })
    .finally(() => {
      dispatch(passportsActions.setDocumentsLoading(productId, true));
    });
};

export const deletePassportOrCertificate = (document: IPassport) => (dispatch: Dispatch) => {
  if (document.file_type === "passport") {
    passportsApi
      .deletePassport(document.id)
      .then(() => {
        dispatch(passportsActions.invalidateKey());
      })
      .catch(errorCatcher);
  } else {
    passportsApi
      .deleteCertificate(document.id)
      .then(() => {
        dispatch(passportsActions.invalidateKey());
      })
      .catch(errorCatcher);
  }
};

export const deletePassportDocument =
  (expenditureId: number, attachmentId: IPassport["id"]) => (dispatch: Dispatch) => {
    passportsApi
      .deleteResourceAttachment(expenditureId, attachmentId)
      .then(() => dispatch(passportsActions.invalidateKey()))
      .catch(errorCatcher);
  };

export const attachFileToExpenditure = (expId: number, file: File) => async (dispatch: Dispatch) => {
  dispatch(passportsActions.setPending(true));
  const fileInB64 = await getArrayFilesInBase64([file]);
  const serializedFileData: IFileAttachingToExpenditureParams = {
    file: fileInB64[0].file as string,
    originalname: file.name as string,
    type: DocumentsTypesEnum.file,
  };
  passportsApi
    .attachFileToExpenditure(expId, serializedFileData)
    .then(() => {
      dispatch(passportsActions.invalidateKey());
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(passportsActions.setPending(false));
    });
};

export const attachDocumentToExpenditure =
  (values: Omit<ICreatePassport, "file"> & { file: File }, expId: number) => async (dispatch: Dispatch) => {
    dispatch(passportsActions.setPending(true));
    const fileInB64 = await getArrayFilesInBase64([values.file]);
    const serializedDocumentData = {
      ...values,
      file: fileInB64[0]?.file as string,
      originalname: values.file?.name as string,
    };
    passportsApi
      .attachFileToExpenditure(expId, serializedDocumentData)
      .then(() => {
        dispatch(passportsActions.invalidateKey());
        dispatch(journalExecutionActions.changeInvalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(passportsActions.setPending(false));
      });
  };

export const getExpendituresAttachments = (expId: number) => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().passports?.expenditureAttachments?.[expId]?.results?.length) {
    dispatch(passportsActions.setExpenditureAttachmentsLoading(expId, true));
  }
  passportsApi
    .getExpenditureAttachments(expId)
    .then(({ data }) => {
      const formattedAttachments = data.map((el) => ({ ...el, file: el.file_path, name: el.originalname }));
      dispatch(passportsActions.setExpenditureAttachments(expId, formattedAttachments));
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(passportsActions.setExpenditureAttachmentsLoading(expId, false));
    });
};

export const getWorkAttachmentsInJournal =
  (buildingId: string, expId: number, year: number, month: number) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ buildingId, expenditureId: expId, year, month });
    if (!getState().passports?.expenditureAttachments?.[key]?.results?.length) {
      dispatch(passportsActions.setExpenditureAttachmentsLoading(key, true));
    }
    passportsApi
      .getWorkFilesInJournal(buildingId, expId, year, month)
      .then(({ data }) => {
        const formattedAttachments = data.results.map((el) => ({ ...el, file: el.file_path, name: el.originalname }));
        dispatch(passportsActions.setExpenditureAttachments(key, formattedAttachments));
      })
      .finally(() => {
        dispatch(passportsActions.setExpenditureAttachmentsLoading(key, false));
      });
  };

export const attachFileToWorkInJournal =
  (props: { buildingId: string; expenditureId: number; month: number; year: number; file: File; fileType: string }) =>
  async (dispatch: Dispatch) => {
    const { buildingId, expenditureId, month, year, file, fileType } = props;
    dispatch(passportsActions.setPending(true));
    const [fileInB64] = await getArrayFilesInBase64([file]);
    passportsApi
      .attachFileToWorkInJournal(buildingId, {
        expenditure: expenditureId,
        month,
        year,
        file: fileInB64.file as string,
        originalname: fileInB64.name,
        type: fileType,
      })
      .then(() => {
        dispatch(passportsActions.invalidateKey());
        dispatch(journalExecutionActions.changeInvalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(passportsActions.setPending(false));
      });
  };

export const deleteAttachmentFromWorkInJournal = (buildingId: string, docId: number) => (dispatch: Dispatch) => {
  passportsApi
    .deleteFileFromWorkInJournal(buildingId, docId)
    .then(() => {
      dispatch(passportsActions.invalidateKey());
      dispatch(journalExecutionActions.changeInvalidateKey());
    })
    .catch(errorCatcher);
};

export const getKs2Attachments = (ks2Id: number) => (dispatch: Dispatch) => {
  passportsApi
    .getKs2Attachments(ks2Id)
    .then(({ data }) => {
      const formattedAttachments = data.results.map((el) => ({ ...el, file: el.file_path, name: el.originalname }));
      dispatch(passportsActions.setKs2Attachments(ks2Id, formattedAttachments));
    })
    .catch(errorCatcher);
};

export const attachKs2DocumentToExpenditure =
  (values: Omit<ICreatePassport, "file"> & { file: File }, expId: number, ks2Id: number, objectId: string) =>
  async (dispatch: Dispatch) => {
    dispatch(passportsActions.setPending(true));
    const fileInB64 = await getArrayFilesInBase64([values.file]);
    const serializedDocumentData: IAttachFileInWorkInJournalRequest = {
      ...values,
      file: fileInB64[0]?.file as string,
      originalname: values.file?.name as string,
      expenditure: expId,
      act_ks2: ks2Id,
    };
    passportsApi
      .attachFileToExpenditureInKs2(serializedDocumentData, objectId)
      .then(() => {
        dispatch(passportsActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(passportsActions.setPending(false));
      });
  };

export const getKs2SectionAttachments = (ks2Id: number, sectionId: number) => (dispatch: Dispatch) => {
  passportsApi
    .getSectionAttachmentsInKs2(ks2Id, sectionId)
    .then(({ data }) => {
      const formattedAttachments = data.results.map((el) => ({ ...el, file: el.file_path, name: el.originalname }));
      dispatch(passportsActions.setKs2SectionAttachments(ks2Id, sectionId, formattedAttachments));
    })
    .catch(errorCatcher);
};

export const getKs2ExpenditureAttachments =
  (buildingId: string, ks2Id: number, expId: number) => (dispatch: Dispatch) => {
    passportsApi
      .getWorkFilesInKs(buildingId, expId, ks2Id)
      .then(({ data }) => {
        const formattedAttachments = data.results.map((el) => ({ ...el, file: el.file_path, name: el.originalname }));

        dispatch(passportsActions.setKs2ExpenditureAttachments(ks2Id, expId, formattedAttachments));
      })
      .catch(errorCatcher);
  };

export const attachFileToKs2 = (file: File, ks2Id: number) => async (dispatch: Dispatch) => {
  dispatch(passportsActions.setPending(true));
  const fileInB64 = await getArrayFilesInBase64([file]);
  const serializedFileData: IFileAttachingToExpenditureParams = {
    file: fileInB64[0].file as string,
    originalname: file.name as string,
    type: DocumentsTypesEnum.file,
  };
  passportsApi
    .attachFileToKs2(ks2Id, serializedFileData)
    .then(() => {
      dispatch(passportsActions.invalidateKey());
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(passportsActions.setPending(false));
    });
};

export const attachDocumentToKs2 =
  (values: Omit<ICreatePassport, "file"> & { file: File }, ks2Id: number) => async (dispatch: Dispatch) => {
    dispatch(passportsActions.setPending(true));
    const fileInB64 = await getArrayFilesInBase64([values.file]);
    const serializedDocumentData = {
      ...values,
      file: fileInB64[0]?.file as string,
      originalname: values.file?.name as string,
    };
    passportsApi
      .attachFileToKs2(ks2Id, serializedDocumentData)
      .then(() => {
        dispatch(passportsActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(passportsActions.setPending(false));
      });
  };

export const attachFileToKs2Section = (file: File, ks2Id: number, sectionId: number) => async (dispatch: Dispatch) => {
  dispatch(passportsActions.setPending(true));
  const fileInB64 = await getArrayFilesInBase64([file]);
  const serializedFileData: IFileAttachingToExpenditureParams = {
    file: fileInB64[0].file as string,
    originalname: file.name as string,
    type: DocumentsTypesEnum.file,
  };
  passportsApi
    .attachFileToSectionInKs2(ks2Id, sectionId, serializedFileData)
    .then(() => {
      dispatch(passportsActions.invalidateKey());
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(passportsActions.setPending(false));
    });
};

export const attachDocumentToKs2Section =
  (values: Omit<ICreatePassport, "file"> & { file: File }, ks2Id: number, sectionId: number) =>
  async (dispatch: Dispatch) => {
    dispatch(passportsActions.setPending(true));
    const fileInB64 = await getArrayFilesInBase64([values.file]);
    const serializedDocumentData = {
      ...values,
      file: fileInB64[0]?.file as string,
      originalname: values.file?.name as string,
    };
    passportsApi
      .attachFileToSectionInKs2(ks2Id, sectionId, serializedDocumentData)
      .then(() => {
        dispatch(passportsActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(passportsActions.setPending(false));
      });
  };
