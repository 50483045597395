import React, { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { aookInvalidateKeySelector } from "redux/modules/common/building/journal/aook/selectors";
import { aosrInvalidateKeySelector } from "redux/modules/common/building/journal/aosr/selectors";
import {
  invalidateKeyInJournalExecSelector,
  journalFulfillmentExpendituresLoadingSelector,
  journalFulfillmentExpendituresSelector,
  journalFulfillmentMaterialsInvalidateKeySelector,
  ticketsInJournalExecSelector,
} from "redux/modules/common/building/journal/journalExecution/selectors";
import { getJournalFulfillmentExpenditures } from "redux/modules/common/building/journal/journalExecution/thunksV2";
import { generateJornalTicketSectionKey } from "redux/modules/common/building/journal/utils";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import JournalExecTicketV2 from "../JournalExecTicketV2/JournalExecTicketV2";
import JournalExecTicketsHeader from "../JournalExecutionHeaders/JournalExecTicketsHeader";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import {
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_CREATE_REMARK,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_FORMATION_AOSR,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_TRANSFER_TO_ACTS,
} from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./JournalExecTickets.module.scss";

interface IProps {
  onBack: () => void;
  year: number;
  month: number;
  sectionId: number;
}

const JournalExecTickets: React.FC<IProps> = ({ onBack, year, month, sectionId }) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();

  const key = generateJornalTicketSectionKey(objectId, year, month, sectionId);
  const ticketsV2 = useSelector(journalFulfillmentExpendituresSelector)[key];
  const isLoading = useSelector(journalFulfillmentExpendituresLoadingSelector)[key];

  const haveCreateRemarkPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_CREATE_REMARK);
  const haveFormationAosrPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_FORMATION_AOSR);
  const haveTransferToActsPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_TRANSFER_TO_ACTS);

  const materialsInvalidateKey = useSelector(journalFulfillmentMaterialsInvalidateKeySelector);
  const invalidateKey = useSelector(invalidateKeyInJournalExecSelector);
  const aookInvalidateKey = useSelector(aookInvalidateKeySelector);
  const aosrInvalidateKey = useSelector(aosrInvalidateKeySelector);

  useLayoutEffect(() => {
    dispatch(getJournalFulfillmentExpenditures(objectId, sectionId, year, month));
  }, [objectId, month, year, sectionId, invalidateKey, aosrInvalidateKey, aookInvalidateKey, materialsInvalidateKey]);

  return (
    <>
      <div className={styles.sticky}>
        <BackNavigationBar
          onBack={onBack}
          title={ticketsV2?.name}
          slashTitle={ticketsV2?.parent_name}
          className={styles.nav}
        />
        <JournalExecTicketsHeader
          isWithoutCreateRemark={!haveCreateRemarkPermission}
          isWithoutLastColumn={!haveFormationAosrPermission && !haveTransferToActsPermission}
        />
      </div>
      {isLoading && <Spinner />}
      {!isLoading &&
        ticketsV2?.items?.map((el) => {
          const key = el.expenditure?.expenditure_id! || el.group?.group_id || el.linked?.expenditure_id;
          return (
            <JournalExecTicketV2
              key={key}
              ticket={el}
              year={year}
              month={month}
              sectionId={sectionId}
              stockId={ticketsV2.stock_id}
            />
          );
        })}
      {!isLoading && !ticketsV2?.items?.length && <EmptyPlaceholder img={jurnalIcon} />}
    </>
  );
};

export default React.memo(JournalExecTickets);
