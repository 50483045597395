export const enum PlanTabsEnum {
  MAIN = "mainPlanTab",
  RELATIONS = "relationsPlanTab"
}

export const enum ManufacturingModalsTypesEnum {
  EXPENDITURE = "expenditure",
  SECTION = "section",
  LSR = "lsr",
  BUILDING = "building"
}
