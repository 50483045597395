import { IKsListActions } from "./actions";

import { IKsListState } from "./types";

export const initialKsListState: IKsListState = {
  ksLists: {},
  ksSublists: {},
  invalidateKey: 1,
  detailedKs2: {},
};

export default (state = initialKsListState, action: IKsListActions): IKsListState => {
  const { type, payload } = action;
  switch (type) {
    case "ksList/SET_LIST":
      return {
        ...state,
        ksLists: {
          ...state.ksLists,
          [payload.buildingId]: {
            ...payload.results,
            isLoading: false,
          },
        },
      };
    case "ksList/ADD_MORE_LIST":
      return {
        ...state,
        ksLists: {
          ...state.ksLists,
          [payload.buildingId]: {
            ...payload.results,
            results: [...(state.ksLists?.[payload.buildingId]?.results || []), ...payload.results.results],
          },
        },
      };
    case "ksList/SET_SUBLIST":
      return {
        ...state,
        ksSublists: {
          ...state.ksSublists,
          [payload.ks3Id]: {
            ...payload.results,
          },
        },
      };
    case "ksList/SET_SUBLIST_LOADING":
      return {
        ...state,
        ksSublists: {
          ...state.ksSublists,
          [payload.ks3Id]: {
            ...(state.ksSublists[payload.ks3Id] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "ksList/SET_LIST_LOADING":
      return {
        ...state,
        ksLists: {
          ...state.ksLists,
          [payload.buildingId]: {
            ...(state.ksLists[payload.buildingId] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "ksList/INVALIDATE_KEY":
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    case "ksList/SET_DETAILED_KS2":
      return {
        ...state,
        detailedKs2: {
          ...state.detailedKs2,
          [payload.ks2Id]: payload.data,
        },
      };
    case "ksList/SET_DETAILED_KS2_LOADING":
      return {
        ...state,
        detailedKs2: {
          ...state.detailedKs2,
          [payload.ks2Id]: {
            ...(state.detailedKs2[payload.ks2Id] || {}),
            isLoading: payload.status,
          },
        },
      };
    default:
      return state;
  }
};
