import cn from "classnames";
import React from "react";

import { personFullNameWithId } from "types/personsTypes";

import getShortFullName from "utils/formatters/getShortFullName";
import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";

import styles from "./WorkersList.module.scss";

type propsType = {
  worker: personFullNameWithId;
  color?: "green";
  className?: string;
};

function WorkerItem({ worker, color, className }: propsType) {
  return (
    <div className={cn(styles.worker, styles[color as string], className)} title={getShortFullName(worker)}>
      {sliceTextByConstraint(getShortFullName(worker), 20)}
    </div>
  );
}

export default WorkerItem;
