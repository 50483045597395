import eventStatusStyles from "components/UI/atoms/EventItem/EventItem.module.scss";

import { ITab } from "shared/ui/controls/TabBar/TabBarNotLinks";

import { EVENT_TYPES as EVENT_ENUM, EventStatus, EventType } from "../../../../types/interfaces/Event";
import { TASK_STATUSES, TaskStatus } from "../../Tasks/constants";

import blueDone from "../../../../images/icons/blueDoneSvg.svg";
import doneSvg from "../../../../images/icons/doneSvgLoad.svg";
import blueLoading from "../../../../images/icons/loadBlueSvg.svg";
import purpleLoading from "../../../../images/icons/loadPurpleSvg.svg";
import loadSvg from "../../../../images/icons/loadingSvgPayment.svg";
import purpleDone from "../../../../images/icons/purpleDoneSvg.svg";

import styles from "./index.module.scss";

export const CALENDAR_MODAL_TABS = { EVENTS_BY_TYPES: "eventsByTypes", ALL_EVENTS: "allEvents" };

export const calendarModalTabs: ITab[] = [
  {
    id: CALENDAR_MODAL_TABS.ALL_EVENTS,
    text: "Все события",
    className: styles.tab,
  },
  {
    id: CALENDAR_MODAL_TABS.EVENTS_BY_TYPES,
    text: "События по типам",
    className: styles.tab,
  },
];

export const EVENT_TYPES = [
  { name: "Поставка", id: "supply" },
  { name: "Оплата", id: "payment" },
  {
    name: "Задача",
    id: "task",
  },
];

export const calendarEventStatusSwitcher = (
  status: EventStatus | TaskStatus,
  eventType: EventType,
  isCompleted: boolean
) => {
  if (eventType === EVENT_ENUM.supply) {
    if (!isCompleted) {
      return {
        containerClassName: eventStatusStyles.supplyContainer,
        statusClassName: eventStatusStyles.notPayedSupplies,
        statusText: "Ожидает отгрузки",
        statusIcon: loadSvg,
      };
    } else {
      return {
        containerClassName: eventStatusStyles.supplyContainer,
        statusClassName: eventStatusStyles.payedSupplies,
        statusText: "Принята",
        statusIcon: doneSvg,
      };
    }
  }
  if (eventType === EVENT_ENUM.payment) {
    if (!isCompleted) {
      return {
        containerClassName: eventStatusStyles.paymentContainer,
        statusClassName: eventStatusStyles.notPayedPayment,
        statusText: "Ожидает оплаты",
        statusIcon: purpleLoading,
      };
    } else {
      return {
        containerClassName: eventStatusStyles.paymentContainer,
        statusClassName: eventStatusStyles.payedPayment,
        statusText: "Оплачен",
        statusIcon: purpleDone,
      };
    }
  }
  if (eventType === EVENT_ENUM.task) {
    if (["completed", "closed"].includes(status)) {
      return {
        containerClassName: eventStatusStyles.taskContainer,
        statusClassName: eventStatusStyles.taskStatus,
        statusText: TASK_STATUSES[status as "completed"],
        statusIcon: blueDone,
      };
    } else {
      return {
        containerClassName: eventStatusStyles.taskContainer,
        statusClassName: eventStatusStyles.taskStatus,
        statusText: TASK_STATUSES[status as "completed"],
        statusIcon: blueLoading,
      };
    }
  }
  return {};
};
