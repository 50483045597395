import { Fragment } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

export const PrivacyPolicyPage = () => {
  return (
    <Fragment>
      <section className="privacy-policy">
        <div className="container">
          <div className="privacy-policy__info">
            <h1>Политика в отношении обработки персональных данных</h1>
            <p>
              Настоящий документ определяет политику Общества с ограниченной ответственностью «ОСЛА»,
              зарегистрированного по адресу 199226, Санкт-Петербург, улица Нахимова, дом 26, литера А, помещение 4Н,
              офис 20 (далее – «Оператор» или «мы»), реализуемую нами в отношении обработки персональных данных
              пользователей сайтов, доступных по адресам <a href="https://osla.ru" target="_blank">https://osla.ru</a>, <a href="https://task.osla.ru"
                                                                                 target="_blank">https://task.osla.ru</a> («Сайты»).
            </p>
            <p>
              Предоставляя нам персональные данные, Вы соглашаетесь на обработку соответствующих персональных данных в
              соответствии с условиями настоящей Политики.
            </p>
            <p>
              Все обновления настоящей Политики размещаются нами на настоящей странице. Настоящая редакция Политики
              датирована 2 июня 2023 года.
            </p>
            <h3>Какие данные мы собираем и зачем?</h3>
            <p>
              Оператор обрабатывает перечисленные ниже данные, предоставленные самими пользователями Сайтов в следующих
              целях:
            </p>
            <ul>
              <li>
                фамилии, имена, электронные адреса, телефонные номера, электронные адреса – с целью рассмотрения
                обращений, направленных через интерактивные формы Сайтов;
              </li>
              <li>
                фамилии, имена, отчества, телефонные номера, электронные адреса – с целью аутентификации пользователей,
                заключения и исполнения соглашений с Оператором.
              </li>
            </ul>
            <p>
              Адрес электронной почты также может использоваться для рассылки информации о продуктах Оператора и его
              предложениях. Вы в любой момент можете отказаться от соответствующей рассылки, обратившись к нам любым
              удобным способом.
            </p>
            <p>
              Помимо персональных данных, мы используем обезличенную информацию о статистике использования Сайтов (в том
              числе, количество посетителей, их пол, возраст, типы их устройств, источники их визитов, а также поведение
              посетителей на страницах сайта). Указанная информация собирается с использованием сторонних сервисов
              Яндекс.Метрика и Google Аналитика, которые применяют cookie – небольшие текстовые файлы, размещаемые на
              компьютере пользователей с целью анализа их пользовательской активности. Собранная при помощи cookie
              информация не позволят идентифицировать Вас, однако может помочь нам улучшить работу наших Сайтов. Вы
              можете отказаться от использования cookie, выбрав соответствующие настройки в браузере.
            </p>
            <h3>Как мы обрабатываем данные?</h3>
            <p>
              Поскольку такие операции соответствуют обозначенным выше целям, мы можем собирать Ваши данные,
              осуществлять их запись, систематизацию, накопление, хранение и обновление в своих базах данных, извлекать,
              использовать, передавать, блокировать и удалять соответствующие данные.
            </p>
            <h3>Разглашаются ли какие-либо данные?</h3>
            <p>
              Мы не передаем Ваши данные третьим лицам, за исключением провайдеров, которые поддерживают наши Сайты и
              онлайн сервисы. Хотя такие провайдеры не имеют прямого доступа к Вашим данным, они могут быть вовлечены в
              техническую обработку соответствующей информации (физическое хранение, передача и иные подобные операции).
            </p>
            <p>
              При передаче персональных данных третьим лицам мы уведомляем их об обязанности сохранения
              конфиденциальности персональных данных и использования их лишь в тех целях, для которых они переданы.
            </p>
            <h3>Как долго мы храним Ваши персональные данные?</h3>
            <p>
              Обработка Ваших данных в целях, предусмотренных настоящей Политикой, может осуществляться нами до
              прекращения деятельности Оператора как юридического лица, если Вы не попросите прекратить обработку Ваших
              данных ранее.
            </p>
            <p>
              После истечения срока хранения мы прекратим обработку Ваших персональных данных, безвозвратно удалив их из
              наших баз данных.
            </p>
            <h3>Каковы Ваши права как субъекта данных?</h3>
            <p>
              Мы уважаем Ваши права, предусмотренные законодательством о защите данных. Среди прочего Вы имеете право:
            </p>
            <ul>
              <li>
                получать доступ к полной и достоверной информации, касающейся обработки Ваших персональных данных;
              </li>
              <li>
                требовать от Оператора уточнения персональных данных, их блокирования или уничтожения в случае, если
                персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются
                необходимыми для заявленной цели обработки;
              </li>
              <li>
                в любой момент отозвать ранее данное согласие на обработку персональных данных;
              </li>
              <li>
                обжаловать в суд любые неправомерные действия или бездействия Оператора, а также принимать иные
                предусмотренные законом меры по защите своих прав.
              </li>
            </ul>
            <p>
              Обращения по вопросам обработки персональных данных Вы можете направить нам по адресу 199226,
              Санкт-Петербург, улица Нахимова, дом 26, литера А, помещение 4Н, офис 20. Запросы о блокировке, изменении,
              уточнении или удалении персональных данных Вы можете также направить в упрощенном порядке посредством
              направления сообщения с электронного адреса, ранее предоставленного Вами в наш адрес. Указанное сообщение
              должно быть направлено на адрес info@osla.ru.
            </p>
            <p>
              Мы рассмотрим и направим ответ на Ваш запрос в течение 10 (десяти) рабочих дней с момента поступления
              обращения (если более короткий срок рассмотрения обращения не установлен законодательством). Ответ на Ваш
              запрос будет направлен по адресу, указанному Вами в соответствующем запросе.
            </p>
            <h3>Какие меры, направленные на защиту персональных данных, мы принимаем?</h3>
            <p>
              Ваши данные хранятся защищенным образом с соблюдением общепринятых стандартов информационной безопасности,
              принятых в отношении веб-сервисов.
            </p>
            <p>
              В соответствии с законодательством Российской Федерации мы предприняли ряд технических и
              организационно-правовых мер в целях обеспечения защиты персональных данных пользователей от неправомерного
              или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а также
              от иных неправомерных действий третьих лиц.
            </p>
            <p>
              Среди прочего в рамках защиты Ваших персональных данных от несанкционированного доступа мы предприняли
              следующие меры:
            </p>
            <ul>
              <li>
                привлекли надежного провайдера услуг хостинга, инфраструктура которого соответствует требованиям к
                защите персональных данных;
              </li>
              <li>
                обеспечили такой режим безопасности помещений, в которых размещены средства обработки персональных
                данных, который препятствует возможности неконтролируемого проникновения третьих лиц в такие помещения;
              </li>
              <li>
                определили перечень лиц, доступ которых к персональным данным пользователей необходим для выполнения ими
                служебных обязанностей, а также реализовали меры по разграничению прав доступа пользователей к
                информации, обрабатываемой в информационных системах (в том числе, посредством применения мер парольной
                защиты);
              </li>
              <li>
                внедрили средства антивирусной защиты информационных систем обработки персональных данных;
              </li>
              <li>
                осуществляем резервное копирование баз персональных данных;
              </li>
              <li>
                назначили лицо, ответственное за обеспечение безопасности персональных данных.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer showDemoSection={false} />
    </Fragment>
  )
}