import React, { ImgHTMLAttributes } from "react";

interface IIcon extends Omit<ImgHTMLAttributes<any>, "src" | "alt" | "title"> {
  icon?: string;
  text?: string;
}

const Icon = ({ icon, text, ...rest }: IIcon) => {
  return <img src={icon} alt={text} title={text} {...rest} />;
};

export default React.memo(Icon);