import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  extraForecastDetailSelector,
  extraForecastInvalidateKeySelector,
  extraForecastPendingSelector,
  extraForecastSectionsSelector,
  extraForecastSubsectionsSelector,
} from "./model/selectors";
import { editExtraForecast, getDetailedExtraForecast } from "./model/thunks";
import ExtraExpenseModal from "./ui/ExtraExpenseModal/ExtraExpenseModal";

import { IAddExtraExpenseForm } from "./model/types";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  buildingId: string;
  expenseId: number;
}

const EditExtraExpenseModal: React.FC<IProps> = ({ isOpen, onClose, buildingId, expenseId }) => {
  const dispatch = useDispatch();
  const { projectId } = useParams<Record<string, string>>();
  const key = generateStorageKey({ buildingId, type: "detail", expenditureId: expenseId });
  const pendingKey = generateStorageKey({ buildingId, type: "edit" });
  const sections = useSelector(extraForecastSectionsSelector)[buildingId];
  const subSections = useSelector(extraForecastSubsectionsSelector)[buildingId];
  const detailData = useSelector(extraForecastDetailSelector)[key];
  const isLoading = useSelector(extraForecastPendingSelector)[key];
  const isPending = useSelector(extraForecastPendingSelector)[pendingKey];
  const invalidateKey = useSelector(extraForecastInvalidateKeySelector);

  const onSubmit = (vals: IAddExtraExpenseForm) => {
    dispatch(editExtraForecast(projectId, buildingId, expenseId, vals));
  };

  useEffect(() => {
    if (!isOpen) return;
    dispatch(getDetailedExtraForecast(buildingId, expenseId));
  }, [isOpen]);

  useEffect(() => {
    onClose();
  }, [invalidateKey]);

  return (
    <ExtraExpenseModal
      initialData={detailData}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      sections={sections}
      subSections={subSections}
      isLoading={isLoading}
      title="Статья расхода"
      isPending={isPending}
    />
  );
};

export default EditExtraExpenseModal;
