import React from "react";
import { useDispatch } from "react-redux";

import { tasksIndicatorsByBuildingSelector } from "components/pages/Tasks/model/selectors";
import { getTasksIndicatorsByBuilding } from "components/pages/Tasks/model/thunks";
import { tasksListFilterType } from "components/pages/Tasks/model/types";

import { useTypedSelector } from "app/store/typedUseSelector";

export const useTaskIndicatorsByBuilding = (buildingId: number | undefined, filters: tasksListFilterType) => {
  const dispatch = useDispatch();
  const indicators = useTypedSelector(tasksIndicatorsByBuildingSelector);

  React.useEffect(() => {
    buildingId && dispatch(getTasksIndicatorsByBuilding(buildingId, filters));
  }, [buildingId, filters]);

  return buildingId ? indicators[buildingId] : null;
};
