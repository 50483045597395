import React from "react";

const Edit: React.FC<{ className?: string; color?: string }> = ({ className, color = "#000000" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.999" viewBox="0 0 16 15.999" className={className}>
      <path
        d="M5,16a5.005,5.005,0,0,1-5-5V6.028a5.006,5.006,0,0,1,5-5H9.659a5.032,5.032,0,0,1,.924.086L8.669,3.069H5a3,3,0,0,0-3,3v4.888a3,3,0,0,0,3,3H9.661a3,3,0,0,0,3-3v-3.6l1.954-2a4.988,4.988,0,0,1,.045.669V11a5.005,5.005,0,0,1-5,5ZM7.435,6.341,13.2.449a1.6,1.6,0,0,1,2.287.069,1.68,1.68,0,0,1,.069,2.336L9.791,8.746l-3.175.836Z"
        style={{ transform: "translate(0px, 0.001px)" }}
        fill={color}
      />
    </svg>
  );
};

export default React.memo(Edit);
