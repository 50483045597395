import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  aosrCreatingPendingSelector,
  aosrCurrentDetailSelector,
} from "../../../../../../../../../redux/modules/common/building/journal/aosr/selectors";
import { createAosr, publishAosr, setAosrDetail } from "redux/modules/common/building/journal/aosr/thunks";
import {
  AosrTypes,
  IAosr,
  IAosrDetail,
  IAosrDocument,
  ICreateAosr,
} from "redux/modules/common/building/journal/aosr/types";
import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { useCreateAosrMaterials } from "./AosrMaterials/useCreateAosrMaterials";
import { FormState } from "final-form";

import { initialAosrDoc, initialAosrForm } from "./constants";
import { IGroupInJournalDone, ITicketInJournalDone } from "types/interfaces/Tickets";
import { IRouterParamsWithObjectId } from "types/routerTypes";

export type ICreateAosrFormVals = Omit<IAosr, "id" | "expenditure_id"> & {
  publish: boolean;
  usingfact_products: number[];
  type: AosrTypes;
  group_id?: number;
  expenditure_id?: number;
  materials_in_aosr?: any[];
};

type IAosrCreatingDocument = Omit<IAosrDocument, "id" | "hiddenwork_id"> & { isHidden?: boolean };

export const useCraeteAosr = (
  expenditure: IExpendituresInJournalFulfillment["items"][0],
  type: AosrTypes,
  groupId?: number,
  ctx?: {
    isOpen: boolean;
  }
) => {
  const objectId = useObjectId();
  const dispatch = useDispatch();
  const expenditureId = expenditure?.expenditure?.expenditure_id ?? expenditure?.group?.group_id;
  const currentAosrDetail: IAosrDetail | null = useSelector(aosrCurrentDetailSelector);
  const isCreatingPending = useSelector(aosrCreatingPendingSelector)[1];

  const { allMaterials, deleteMaterial, addMaterial } = useCreateAosrMaterials({ currentAosrDetail });

  const [aosr, setAosr] = React.useState<ICreateAosr & { id?: number }>({
    ...initialAosrForm,
    publish: true,
  } as ICreateAosr);

  const changeAosrDateField = (field: string) => (date: moment.Moment) => {
    setAosr((prev) => ({ ...prev, [field]: moment(date).format("YYYY-MM-DD") }));
  };
  //@ts-ignore
  const [aosrDocs, setAosrDocs] = React.useState<IAosrCreatingDocument[]>([initialAosrDoc]);

  const addAosrDoc = React.useCallback(() => {
    //@ts-ignore
    setAosrDocs((prev) => [...prev, { doc_date: "", doc_type: "", title: "", files: [] }]);
  }, []);

  const removeAosrDoc = React.useCallback((i: number) => {
    setAosrDocs((prev) =>
      prev.map((el, idx) => {
        if (idx == i) {
          return { ...el, isHidden: true };
        } else {
          return el;
        }
      })
    );
  }, []);

  const createAosrHandler = React.useCallback(
    (values: ICreateAosrFormVals, isPublishing: boolean = true) => {
      if (isCreatingPending) return;
      const aosrData: ICreateAosrFormVals = {
        materials_in_aosr: [],
        ...values,
        type,
        act_date: moment(values.act_date).format("YYYY-MM-DD"),
        start_date: aosr.start_date,
        end_date: aosr.end_date,
        docs: (values.docs || [])
          .filter((el, i) => !aosrDocs[i].isHidden)
          .map((doc) => ({ ...doc, doc_date: moment(doc.doc_date).format("YYYY-MM-DD") })),
        expenditure_id: type !== AosrTypes.FOR_GROUP ? expenditureId : undefined,
        group_id: type === AosrTypes.FOR_GROUP ? expenditureId : type === AosrTypes.FOR_GROUP_EXP ? groupId : undefined,
        //@ts-ignore
        materials_in_aosr: allMaterials?.filter((el) => !el.was_deleted).map((el) => el.id) ?? [],
      };
      if (isPublishing) {
        dispatch(publishAosr(objectId, expenditureId!, aosrData, currentAosrDetail?.id!));
      } else {
        dispatch(createAosr(objectId, expenditureId!, aosrData, isPublishing));
      }
    },
    [objectId, expenditureId, aosr, aosrDocs, type, allMaterials, isCreatingPending]
  );

  const handleAosrFormChange = React.useCallback(
    (formState: FormState<ICreateAosrFormVals, Partial<ICreateAosrFormVals>>) => {
      const { number, title } = formState?.values || {};
      if (!number || !title || currentAosrDetail) return;
      createAosrHandler(formState.values, false);
    },
    [createAosrHandler, currentAosrDetail]
  );

  return {
    handleAosrFormChange,
    createAosrHandler,
    changeAosrDateField,
    aosr,
    aosrDocs,
    addAosrDoc,
    removeAosrDoc,
    allMaterials,
    deleteMaterial,
    addMaterial,
  };
};
