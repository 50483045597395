import update from "immutability-helper";
import { compose } from "redux";

import { calculateOffersCount } from "../utils/calculateOffersCount";
import { syncOfferSuppliesMeasure } from "../utils/syncOfferSuppliesMeasure";
import { syncOfferSuppliesPrice } from "../utils/syncOfferSuppliesPrice";

class Serializer {
  static serializeKits = (kits) =>
    kits.map((kit) =>
      update(kit, {
        offers: {
          $set: compose(calculateOffersCount, syncOfferSuppliesMeasure, syncOfferSuppliesPrice)(kit.offers),
        },
      })
    );

  static serializeRequests = (requests) =>
    requests.map((request) => {
      return update(request, {
        offers: {
          $set: compose(syncOfferSuppliesMeasure, syncOfferSuppliesPrice)(request.offers),
        },
      });
    });
}

export default Serializer;
