import React from 'react';

export const Limiter = props => {

  const changeHandler = e => props.limitChange(e.target.value);

  const methods = {
    change: val => props.limitChange(val),
    getSelectedValue: () => props.selectedValue,
    getValues: () => props.values
  };

  return(
    props.customLimiter ?
      props.customLimiter(methods)
      :
      <select defaultValue={methods.getSelectedValue()} onChange={ changeHandler }>
        {
          methods.getValues().map( (value, index) =>
            <option
              key={index}
              value={value}
            >
              {value}
            </option>
          )
        }
      </select>
  );
};