export type sharingBarExistingCheckerPropsType = {
  isShared: boolean;
  haveSharingPlanPermission: boolean;
  haveSharingProgressPermission: boolean;
  activeTab?: "plan" | "progress";
};

export const sharingBarExistingChecker = ({
  isShared,
  haveSharingPlanPermission,
  haveSharingProgressPermission,
  activeTab,
}: sharingBarExistingCheckerPropsType): boolean => {
  if (isShared) return false;
  if ((activeTab === "progress" || !activeTab) && haveSharingProgressPermission) return true;
  return false;
};

type addIntervalBtnExistingCheckerPropsType = {
  haveAddingPlanPermission: boolean;
  haveAddingProgressPermission: boolean;
  activeTab: "plan" | "progress";
  moduleRoute: string;
};

export const addIntervalChecker = ({
  activeTab,
  haveAddingPlanPermission,
  haveAddingProgressPermission,
  moduleRoute,
}: addIntervalBtnExistingCheckerPropsType): boolean => {
  if (activeTab === "plan" && haveAddingPlanPermission) return true;
  if (activeTab === "progress" && haveAddingProgressPermission && moduleRoute === "objects") return true;
  return false;
};
