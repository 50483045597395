import $ from "jquery";
import { useEffect } from "react";

import Sites1Svg from "./img/sites1.png";
import Sites2Svg from "./img/sites2.png";
import Sites3Svg from "./img/sites3.png";
import SitesMob1Svg from "./img/sites-mob1.png";
import SitesMob2Svg from "./img/sites-mob2.png";
import SitesMob3Svg from "./img/sites-mob3.png";

export const ProductionPlanningSection = () => {
  useEffect(() => {
    addActiveClass("reviews-min-text-hrefs", "active-href");
    changeCaseBlock(this, "reviews-min-text-hrefs", "reviews-min-info", "active-href", "click-reviews");
    $(".click-reviews").on("click", function () {
      changeActiveClassWithClick(this, "reviews-min-text-hrefs", "active-href");
      changeCaseBlock(this, "reviews-min-text-hrefs", "reviews-min-info", "active-href", "click-reviews");
    }); /* @ts-ignore */
    function addActiveClass(parent_menu, active_class) {
      let prt = $("." + parent_menu);
      let prt_childrens = $(prt).children();
      let prt_child_li = $(prt_childrens).children();
      let first_child = $(prt_child_li)[0];
      if (!$(first_child).hasClass(active_class)) {
        !$(first_child).addClass(active_class);
      }
    } /* @ts-ignore */
    function changeActiveClassWithClick($this, parent_block, active_class) {
      let prt = $($this).parents("." + parent_block);
      let prt_child = $(prt).find("li");
      $(prt_child).each(function () {
        $(this).removeClass(active_class);
      });
      $($this).addClass(active_class);
    } /* @ts-ignore */
    function changeCaseBlock($this, case_menu, case_block, active_class, menu_link) {
      let case_menu_block = $("." + case_menu);
      let case_block_sub = $("." + case_block);
      let case_block_child = $(case_block_sub).children();
      $(case_block_child).each(function () {
        $(this).css({ display: "none", height: 0 });
      });

      if ($($this).hasClass(menu_link)) {
        let this_attr = $($this).attr("data-catalog");
        $(case_block_child).each(function () {
          if ($(this).attr("data-catalog") == this_attr) {
            $(this).css({ display: "block", height: "100%" });
          }
        });
      } else {
        let active_find = $(case_menu_block).find("." + active_class);
        let active_find_attr = $(active_find).attr("data-catalog");
        $(case_block_child).each(function () {
          if ($(this).attr("data-catalog") == active_find_attr) {
            $(this).css({ display: "block", height: "100%" });
          }
        });
      }
    }
  }, []);

  return (
    <section className="production-planning sites-block-plan" id="production-planning">
      <div className="container">
        <div className="sites-block__title">
          <h2>Планирование производства</h2>
          <div className="reviews-min-text-hrefs menu-sites-blocks">
            <ul>
              <li className="click-reviews active-href" data-catalog="list-works">
                Перечень работ
              </li>
              <li className="click-reviews" data-catalog="gpr-dat">
                ГПР
              </li>
              <li className="click-reviews" data-catalog="contractors">
                Подрядчики
              </li>
            </ul>
          </div>
        </div>
        <div className="reviews-min-info">
          <div className="reviews-min-block-js" data-catalog="list-works">
            <div className="sites-block__flex">
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>Группируйте расценки для создания согласованного перечня работ</li>
                    <li>
                      Подрядчики, имеющие доступ к платформе, планируют работы в рамках своей части графика производства
                      работ
                    </li>
                    <li>
                      Руководители получают план производственных расходов и график ресурсов, автоматически
                      сформированные на основе графика производства работ
                    </li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Создание и редактирование перечня работ</li>
                    <li>Согласование перечня работ в производство</li>
                  </ul>
                </div>
              </div>
              <div className="sites-block__photo sites-before-after">
                <img src={Sites1Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob1Svg} className="sites-pic-mob" alt="image" />
              </div>
            </div>
          </div>
          <div className="reviews-min-block-js" data-catalog="gpr-dat">
            <div className="sites-block__flex">
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>
                      Запланировав работы, вы получаете автоматически сформированные план производственных расходов и
                      график ресурсов
                    </li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Построение директивного графика</li>
                    <li>Построение детального ГПР с учетом связей и расчета критического пути</li>
                    <li>Недельно-суточное планирование</li>
                  </ul>
                </div>
              </div>
              <div className="sites-block__photo">
                <img src={Sites2Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob2Svg} className="sites-pic-mob" alt="image" />
              </div>
            </div>
          </div>
          <div className="reviews-min-block-js" data-catalog="contractors">
            <div className="sites-block__flex">
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>Вы можете передать подрядчику часть сметы со скидкой</li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Передача плановых объемов работ подрядчикам</li>
                    <li>Возможность для субподрядчика планировать работы</li>
                    <li>Контроль выполнения в соответствии с утвержденными планами по каждому субподрядчику</li>
                  </ul>
                </div>
              </div>
              <div className="sites-block__photo">
                <img src={Sites3Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob3Svg} className="sites-pic-mob" alt="image" />
              </div>
            </div>
          </div>
        </div>
        <span className="title-bot">Подключайте субподрядчиков для совместной работы</span>
      </div>
    </section>
  );
};
