import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

const stateSelector = (state: RootState) => state.simpleHandler;

export const modeSelector = createSelector(stateSelector, (state) => state.mode);
export const estimateStateIdSelector = createSelector(stateSelector, (state) => state.estimateStateId);

export const sectionsSelector = createSelector(stateSelector, (state) => state.sections);
export const activeSectionSelector = createSelector(stateSelector, (state) => state.activeSection);
export const sectionsAreLoadingSelector = createSelector(stateSelector, (state) => state.sectionsAreLoading);

export const subSectionsSelector = createSelector(stateSelector, (state) => state.subSections);
export const activeSubSectionSelector = createSelector(stateSelector, (state) => state.activeSubSection);
export const subSectionsAreLoadingSelector = createSelector(stateSelector, (state) => state.subSectionsAreLoading);

export const expendituresSelector = createSelector(stateSelector, (state) => state.expenditures);
export const expendituresAreLoadingSelector = createSelector(stateSelector, (state) => state.expendituresAreLoading);

export const isSectionAddingSelector = createSelector(stateSelector, (state) => state.isSectionAdding);

export const checkedItemsSelector = createSelector(stateSelector, (state) => state.checkedItems);
export const checkedItemsIdsSelector = createSelector(stateSelector, (state) => state.checkedItems.map((i) => i.id));

export const isItemsStateChangingSelector = createSelector(stateSelector, (state) => state.isItemsStateChanging);

export const invalidateKeySelector = createSelector(stateSelector, (state) => state.invalidateKey);

export const addingExpendituresSelector = createSelector(stateSelector, (state) => state.addingExpenditures);
export const editingExpendituresSelector = createSelector(stateSelector, (state) => state.editingExpenditures);

export const loadingCreationFormsIdsSelector = createSelector(stateSelector, (state) => state.creationFormLoadingIds);
export const editingLoadingExpendituresIdsSelector = createSelector(
  stateSelector,
  (state) => state.editingLoadingExpendituresIds
);

export const deletingExpendituresIdsSelector = createSelector(stateSelector, (state) => state.deletingExpendituresIds);

export const newSectionsSelector = createSelector(stateSelector, (state) => state.newSections);
export const newSubSectionsSelector = createSelector(stateSelector, (state) => state.newSubSections);
export const confirmedSectionsSelector = createSelector(stateSelector, (state) => state.confirmedSections);
export const confirmedSubSectionsSelector = createSelector(stateSelector, (state) => state.confirmedSubSections);
