import React from "react";

export const marker = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00098 16C12.4193 16 16.001 12.4183 16.001 8C16.001 3.58172 12.4193 0 8.00098 0C3.5827 0 0.000976562 3.58172 0.000976562 8C0.000976562 12.4183 3.5827 16 8.00098 16Z"
      fill="#8DA312"
    />
    <path
      d="M4.00098 9.5C4.8294 9.5 5.50098 8.82843 5.50098 8C5.50098 7.17157 4.8294 6.5 4.00098 6.5C3.17255 6.5 2.50098 7.17157 2.50098 8C2.50098 8.82843 3.17255 9.5 4.00098 9.5Z"
      fill="white"
    />
    <path
      d="M8.00098 9.5C8.8294 9.5 9.50098 8.82843 9.50098 8C9.50098 7.17157 8.8294 6.5 8.00098 6.5C7.17255 6.5 6.50098 7.17157 6.50098 8C6.50098 8.82843 7.17255 9.5 8.00098 9.5Z"
      fill="white"
    />
    <path
      d="M12.001 9.5C12.8294 9.5 13.501 8.82843 13.501 8C13.501 7.17157 12.8294 6.5 12.001 6.5C11.1725 6.5 10.501 7.17157 10.501 8C10.501 8.82843 11.1725 9.5 12.001 9.5Z"
      fill="white"
    />
  </svg>
);
