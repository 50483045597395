import { CHART_TABS, IChartTree } from "redux/modules/common/chart/types";

const sumObjetsByKeys = (objects: Array<Record<string, number> | undefined>, keys: string[]) => {
  const results: Record<string, number> = {};
  const existingObjects = objects.filter((x) => x);
  keys.forEach((key) => {
    results[key] = existingObjects.reduce((sum, object) => sum + (object?.[key] || 0), 0);
  });
  return results;
};

export const mergeIndicators = (tree: IChartTree, tab: CHART_TABS | null) => {
  if (!tab || !tree) return {};

  if (tab === CHART_TABS.WORK) {
    return {
      ...tree?.work_amount_indicators,
      ...tree?.work_indicators,
    };
  } else if (tab === CHART_TABS.MATERIALS) {
    return {
      ...tree.material_amount_indicators,
      ...tree.material_indicators,
    };
  } else if (tab === CHART_TABS.EQUIPMENT) {
    return {
      ...tree.equipment_amount_indicators,
      ...tree.equipment_indicators,
    };
  } else if (tab === CHART_TABS.MIM) {
    /** Мим = Машины + Транспорт */
    return sumObjetsByKeys(
      [
        // @ts-ignore
        tree.machine_amount_indicators,
        // @ts-ignore
        tree.machine_indicators,
        // @ts-ignore
        tree.transport_amount_indicators,
        // @ts-ignore
        tree.transport_indicators,
      ],
      ["count_to_pay", "amount_to_pay"]
    );
  } else if (tab === CHART_TABS.RESOURCES) {
    /** Ресурсы = Материал + Оборудование + Машины + Транспорт */
    return sumObjetsByKeys(
      [
        // @ts-ignore
        tree.material_amount_indicators,
        // @ts-ignore
        tree.material_indicators,
        // @ts-ignore
        tree.equipment_amount_indicators,
        // @ts-ignore
        tree.equipment_indicators,
        // @ts-ignore
        tree.machine_amount_indicators,
        // @ts-ignore
        tree.machine_indicators,
        // @ts-ignore
        tree.transport_amount_indicators,
        // @ts-ignore
        tree.transport_indicators,
      ],
      [
        "count_on_stock",
        "count_issued",
        "count_accepted",
        "count_to_pay",
        "amount_on_stock",
        "amount_issued",
        "amount_accepted",
        "amount_to_pay",
      ]
    );
  }

  return {};
};
