import cn from "classnames";
import React, { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";

import { chartActions } from "redux/modules/common/chart/actions";
import { IPlanRelation, RELATION_TYPES } from "redux/modules/common/chart/types";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";
import Select, { IOption } from "components/UI/atoms/Select";
import { ChartContext } from "components/pages/Chart/Chart";

import { useRelationEditing } from "../useRelationEditing";
import InputBase from "shared/ui/inputs/InputBase";

import styles from "./JustAddedArrowPopup.module.scss";

export interface JustAddedArrowPopupProps {
  arrow: IPlanRelation;
  projectId: number;
}

const RELATION_TYPES_OPTIONS: (IOption & { direction: "from" | "to" })[] = [
  {
    direction: "to",
    id: RELATION_TYPES.oh,
    name: "Окончится перед началом",
  },
  {
    direction: "to",
    id: RELATION_TYPES.hh,
    name: "Начнется после начала",
  },
  {
    direction: "to",
    id: RELATION_TYPES.ho,
    name: "Начнется до начала",
  },
  {
    direction: "to",
    id: RELATION_TYPES.oo,
    name: "Окончится после окончания",
  },
];

export const JustAddedArrowPopup = ({ arrow, projectId }: JustAddedArrowPopupProps) => {
  const { contentRef } = useContext(ChartContext);
  const dispatch = useDispatch();

  const {
    deleteArrowConfirmModal,
    openDeleteArrowModal,
    handleDelayDayInputKeyDown,
    handleDelayDayInputChange,
    delayDaySubmitHandler,
    dynamicRelationDelayDay,
    handleRelationTypeChange,
    isOpenDeleteModal,
    isSubmited,
  } = useRelationEditing({ relation: arrow, projectId });

  const onClosePopup = useCallback(() => {
    if (isOpenDeleteModal) return;
    if (!isSubmited) {
      delayDaySubmitHandler();
    }
    dispatch(chartActions.setJustAddedArrow(null));
  }, [isOpenDeleteModal, delayDaySubmitHandler, isSubmited]);

  return (
    <>
      <PopoverOverlay
        isOpen
        // @ts-ignore
        onClose={onClosePopup}
        placement="top"
        portalContainer={(contentRef?.current as HTMLElement) || undefined}
        content={
          <div className={styles.container}>
            <div className={cn(styles.row, styles.inputs)}>
              <div>
                <span className={styles.label}>Тип связи</span>
                <Select
                  options={RELATION_TYPES_OPTIONS}
                  value={arrow.related_type}
                  //@ts-ignore
                  onChange={handleRelationTypeChange}
                />
              </div>
              <div>
                <span className={styles.label}>Перерыв</span>
                <InputBase
                  value={dynamicRelationDelayDay?.toString()}
                  onChange={handleDelayDayInputChange}
                  className={styles.breakInputContainer}
                  classNameInput={styles.breakInput}
                  onBlur={delayDaySubmitHandler}
                  // @ts-ignore
                  onKeyDown={handleDelayDayInputKeyDown}
                  isButtonActive={dynamicRelationDelayDay?.toString()?.length > 0}
                  isWithButton
                  type="number"
                />
              </div>
            </div>
            <div className={cn(styles.row, styles.deleteArrow)} onClick={openDeleteArrowModal}>
              <span>Удалить связь</span>
            </div>
          </div>
        }
        popoverBorderColor="primary"
      >
        <div />
      </PopoverOverlay>
      {deleteArrowConfirmModal}
    </>
  );
};
