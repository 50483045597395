import React from "react";

import CommentPopup from "../CommentPopup";

import MessageIcon from "../../../../images/icons/MessageIcon";

import styles from "./ProductCommentsFormatter.module.scss";

const EMPTY_ARRAY = [];

export const ProductCommentsFormatter = ({
  product,
  productComments,
  productsCommentsAreLoading,
  createProductComment,
  getProductComments,
}) => {
  const getProductCommentsIfNotExist = (productId) => {
    if (productComments || product.count_comments === 0) return;
    getProductComments(productId);
  };

  const productCommentsCount = (() => {
    if (!productComments) return product.count_comments;
    return productComments.length;
  })();

  return (
    <CommentPopup
      comments={productComments || EMPTY_ARRAY}
      commentsAreLoading={productsCommentsAreLoading}
      onCreateComment={createProductComment}
      editable
    >
      <div onClick={() => getProductCommentsIfNotExist(product.id)}>
        {productCommentsCount !== 0 ? (
          <div className={styles.comment}>
            <MessageIcon width="28" />
            <div className={styles.commentCount}>{productCommentsCount}</div>
          </div>
        ) : (
          <MessageIcon width="28" />
        )}
      </div>
    </CommentPopup>
  );
};

export default React.memo(ProductCommentsFormatter);
