import { useQueryParams } from "../../../../utils/hooks/useQueryParams";

export const useOpenPlanApprove = () => {
  const openPlanApprove = useQueryParams('openPlanApprove') as "1" | null;
  const openPlanType = useQueryParams('plan_type') as "work" | "group" | null;
  const openPlanId = useQueryParams('plan_id');
  const openSectionId = useQueryParams('parent_section_id');
  const openSubsectionId = useQueryParams('child_section_id');
  const expenditureId = useQueryParams('expenditure_id');
  const groupId = useQueryParams('group_id');

  const isNeedToOpenPlan = !!(openPlanApprove
    && (openPlanType === "group" ? groupId : expenditureId)
    && openPlanId
    && openSectionId
    && openSubsectionId);

  return {
    isNeedToOpenPlan,
    openPlanType,
    openPlanId,
    openSectionId,
    openSubsectionId,
    entityId: openPlanType === "group" ? groupId : expenditureId
  }
}