import IllustrationSvg from './img/lustration.svg';
import SchemeBlockSvg from './img/scheme-block.svg';

export const ProductionSection = () => {
  return (
    <section className="production__main" id="production">
      <div className="container">
        <div className="production__flex">
          <div className="production__info">
            <h2>Управление производством СМР</h2>
            <p>
              Платформа обеспечивает управление полным циклом процесса производства СМР от планирования до сдачи
              выполненных работ заказчику
            </p>
            <ul>
              <li>Вы моделируете процесс производства до начала работ для анализа кассовых разрывов</li>
              <li>Участники строительства управляют ходом выполнения работ на платформе</li>
              <li>Руководители контролируют ход строительства и производственные расходы</li>
            </ul>
            <button data-val="modal" className="open_modal btn">Запросить демонстрацию</button>
          </div>
          <div className="production__photo">
            <img src={IllustrationSvg} className="production__dec" alt="image"/>
            <img src={SchemeBlockSvg} className="production__mob" alt="image"/>
          </div>
        </div>
        <div className="production__costs">
          <span className="title-bot">Контролируйте объемы и производственные расходы</span>
        </div>
      </div>
    </section>
  )
}