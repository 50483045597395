export function extractFileNameFromUrl(url: string) {
  const urlObject = new URL(url);

  const pathname = urlObject.pathname;

  const pathSegments = pathname.split("/");
  const fileNameWithExtension = pathSegments[pathSegments.length - 1];

  return fileNameWithExtension;
}
