import React from "react";

export default function StatusConfirmedIcon({width = '25'}) {
  return (
    <svg 
      width={width} 
      height="26" 
      viewBox="0 0 25 26" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M12.8786 1.63677C10.6314 1.63696 8.43481 2.3035 6.56647 3.5521C4.69813 4.80069 3.24199 6.57526 2.38218 8.65141C1.52237 10.7276 1.2975 13.0121 1.73602 15.216C2.17454 17.4199 3.25675 19.4444 4.84579 21.0333C6.43484 22.6222 8.45935 23.7042 10.6633 24.1425C12.8673 24.5808 15.1518 24.3558 17.2279 23.4958C19.3039 22.6358 21.0784 21.1795 22.3268 19.3111C23.5752 17.4426 24.2416 15.2459 24.2416 12.9988C24.2458 11.5054 23.9548 10.026 23.3852 8.64549C22.8157 7.26502 21.9788 6.01076 20.9228 4.95485C19.8668 3.89893 18.6125 3.0622 17.2319 2.49276C15.8514 1.92332 14.3719 1.63241 12.8786 1.63677ZM11.3296 17.8538L7.35258 13.8768L8.07558 13.1538L11.3296 16.4078L19.2296 8.50777L19.9526 9.23077L11.3296 17.8538Z" 
        fill="#00DEC5" 
        stroke="white" 
        strokeWidth="1.4"
      />
    </svg>
  )
}