import cn from "classnames";
import moment from "moment";
import React from "react";

import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { exportDocTypes } from "../constants";
import { IExport } from "types/interfaces/Export";

import { useCheckIsProductionModule } from "../../hooks/useCheckIsProductionModule";

import getShortFullName from "utils/formatters/getShortFullName";

import CrossRoundedIcon from "images/icons/CrossRoundedIcon";
import SaveImportList from "images/icons/SaveImportList";

import styles from "./ExportRow.module.scss";

interface Iprops {
  exportDoc: IExport;
  idx: number;
  canDeleteExport?: boolean;
  onDelete: (id: number) => void;
}

const ExportRow: React.FC<Iprops> = ({ exportDoc, idx, canDeleteExport, onDelete }: Iprops) => {
  const isInProduction = useCheckIsProductionModule();

  return (
    <TableReusableRow className={cn(styles.row, { [styles.inProduction]: isInProduction })}>
      <TableReusableCell>{idx + 1}</TableReusableCell>
      <TableReusableCell>{exportDoc.doc_type_display || exportDocTypes[exportDoc.doc_type]}</TableReusableCell>
      {!isInProduction && <TableReusableCell isNoBreak>{exportDoc.building?.name || "Все объекты"}</TableReusableCell>}
      <TableReusableCell isCentered>
        {(exportDoc.start_at || exportDoc.start_at) &&
          `${moment(exportDoc.start_at).format("DD.MM.YY")} - ${moment(exportDoc.end_at).format("DD.MM.YY")}`}
      </TableReusableCell>
      <TableReusableCell isNoBreak>{getShortFullName(exportDoc.creator)}</TableReusableCell>
      <TableReusableCell isCentered>{moment(exportDoc.created_at).format("DD.MM.YYYY")}</TableReusableCell>
      <TableReusableCell>{exportDoc.status_name}</TableReusableCell>
      <TableReusableCell>
        <div className={styles.actions}>
          {exportDoc.status === "complete" && (
            <div className={styles.action}>
              <a href={exportDoc.file} download>
                <SaveImportList />
              </a>
            </div>
          )}
          {canDeleteExport && (
            <div
              className={styles.action}
              onClick={() => {
                onDelete(exportDoc.id);
              }}
            >
              {/* @ts-ignore */}
              <CrossRoundedIcon />
            </div>
          )}
        </div>
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default ExportRow;
