import React from "react";

function EditIcon({ width = "18", color = "#606060", ...props }) {
  return (
    <svg width={"" + width} height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5 17.0001C3.67432 16.9988 2.40332 16.4716 1.46593
        15.5342C0.528533 14.5968 0.00132369 13.3258 0 12.0001L0
        6.22814C0.00158786 4.90255 0.528882 3.6317 1.46622 2.69436C2.40356
        1.75703 3.67441 1.22973 5 1.22814H10.444C10.692 1.22795 10.9397 1.24634
        11.185 1.28314L9.132 3.38314H5.106C4.31035 3.38314 3.54729 3.69921 2.98468
        4.26182C2.42207 4.82443 2.106 5.58749 2.106 6.38314V11.8541C2.106 12.6498 2.42207
        13.4129 2.98468 13.9755C3.54729 14.5381 4.31035 14.8541 5.106 14.8541H10.339C11.1346 14.8541
        11.8977 14.5381 12.4603 13.9755C13.0229 13.4129 13.339 12.6498 13.339 11.8541V7.89414L15.423
        5.76614C15.438 5.92614 15.445 6.08114 15.445 6.22914V12.0021C15.4437 13.3278 14.9165 14.5988
        13.9791 15.5362C13.0417 16.4736 11.7707 17.0008 10.445 17.0021L5 17.0001ZM7.833 6.82414L13.911
        0.617144C14.0726 0.461082 14.2638 0.338972 14.4734 0.25802C14.6829 0.177068 14.9066 0.138915
        15.1311 0.145813C15.3557 0.15271 15.5765 0.204519 15.7807 0.29818C15.9849 0.391842 16.1683
        0.525458 16.32 0.691144C16.6498 1.01157 16.8418 1.4479 16.8553 1.90754C16.8687 2.36719 16.7025
        2.81399 16.392 3.15314L10.315 9.35914L6.97 10.2441L7.833 6.82414Z"
        fill={color}
      />
    </svg>
  );
}

export default React.memo(EditIcon);
