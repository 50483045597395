import React from 'react'
import styles from "./ListPageHeaderTemplate.module.scss"
import cn from "classnames"

interface IProps {
    children: React.ReactNode;
    className?: string;
}

const ListPageHeaderTemplate: React.FC<IProps> = ({ children, className }) => {
    return (
        <div className={cn(styles.container, className)}>
            {children}
        </div>
    )
}

export default ListPageHeaderTemplate