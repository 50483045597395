import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";

import SelectSectionsModal from "./SelectSectionsModal/SelectSectionsModal";

import { sliceTextByConstraint } from "../../../../../utils/formatters/sliceTextByConstraint";

import styles from "./SelectedSectionField.module.scss";

const MAX_VALUE_LENGTH = 38;

const SelectedSectionField = ({ input, meta, sections, objectId, disabled }) => {
  const [isSectionSelectorOpen, setIsSectionSelectorOpen] = useState(false);
  const openSectionSelector = useCallback(() => !disabled && setIsSectionSelectorOpen(true), [disabled]);
  const closeSectionSelector = useCallback(() => setIsSectionSelectorOpen(false), []);

  const selectSection = useCallback(
    (section) => {
      input.onChange({ target: { value: section } });
    },
    [input.onChange, closeSectionSelector]
  );

  const slicedValue = useMemo(
    () => input.value && sliceTextByConstraint(input.value.name, MAX_VALUE_LENGTH),
    [input.value.name]
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.selectedSection} onClick={openSectionSelector}>
          <span className={cn(styles.text, { [styles.disabled]: disabled })}>{slicedValue || "Выберите раздел"}</span>
        </div>
        {meta?.touched && meta?.error && <div className={cn(styles.errorMessage)}>{meta.error}</div>}
      </div>
      {sections && (
        <SelectSectionsModal
          sections={sections}
          isOpen={isSectionSelectorOpen}
          onClose={closeSectionSelector}
          selectSection={selectSection}
          objectId={objectId}
        />
      )}
    </>
  );
};

export default React.memo(SelectedSectionField);
