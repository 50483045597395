import cn from "classnames";
import React, { useState } from "react";

import styles from "./index.module.scss";

interface ITabProps {
  name?: string;
  title?: string;
  setTab?: (name: string) => void;
  activeTab?: string;
  className?: string;
}

export const Tab: React.FC<ITabProps> = ({ name, title, setTab, activeTab, className }) => {
  return (
    <div
      className={cn(styles.sliderItem, className, {
        [styles.active]: name === activeTab,
      })}
      onClick={() => setTab?.(name!)}
    >
      {title}
    </div>
  );
};

interface IHeaderProps {
  children: React.ReactNode;
}

export const Header: React.FC<IHeaderProps> = ({ children }) => {
  return <div className={styles.sliderBlock}>{children}</div>;
};
