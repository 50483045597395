import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IBuildingSection } from "./types";

interface BuildingState {
  invalidationKey: number;
  loadings: Record<string, boolean>;
  sections: Record<string, IBuildingSection[]>;
}

export const initialState: BuildingState = {
  invalidationKey: 0,
  loadings: {},
  sections: {},
};

const buildingSlice = createSlice({
  name: "building",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    },
    setSections: (state, action: PayloadAction<{ data: IBuildingSection[]; key: string }>) => {
      const { data, key } = action.payload;
      state.sections[key] = data;
    },
  },
});

export const buildingActions = buildingSlice.actions;

export const buildingReducer = buildingSlice.reducer;
