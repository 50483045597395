import moment from "moment";
import React from "react";

import JournalDay from "../../JournalTicketModal/JournalDay";

import { IIdAndName } from "types/idAndName";
import { personFullNameWithId } from "types/personsTypes";

import getShortFullName from "utils/formatters/getShortFullName";

import styles from "./DayWorkerAndEvent.module.scss";

type propsType = {
  date: string;
  label: string;
  author: personFullNameWithId | IIdAndName | string;
};

function DayWorkerAndEvent({ date, label, author }: propsType) {
  if (!date) return null;

  return (
    <div className={styles.pair}>
      <JournalDay
        day={moment(date).format("DD/MM/YYYY")}
        time={date.includes("T") ? moment(date).format("HH:mm") : undefined}
      />
      <div className={styles.pass}>
        <b>{label}</b>
        <span>{typeof author === "string" ? author : getShortFullName(author, 13)}</span>
      </div>
    </div>
  );
}

export default React.memo(DayWorkerAndEvent);
