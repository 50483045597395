import cn from "classnames";
import React, { FC } from "react";

import { handlerCountSvg } from "./handlerCountSvg";

import styles from "./HandlerStateCount.module.scss";

interface IProps {
  color?: "blue" | "green";
  count: string | number;
  title: string;
  amount: string | number;
  plus?: string | number | false;
}

const HandlerStateCount: FC<IProps> = ({
  count,
  title,
  amount,
  plus,
  color = "blue",
}) => {
  return (
    <div className={cn(styles.container, styles[color])}>
      <div className={styles.row}>
        <div className={styles.count}>
          <div className={styles.bg}>{handlerCountSvg}</div>
          <span>{count}</span>
        </div>
        <div className={styles.amount}>
          {amount}
          {plus && <div className={styles.plus}>+ {plus}</div>}
        </div>
      </div>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default HandlerStateCount;
