import { message } from "antd";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";

import { ISectionPlan } from "../../../../../redux/modules/common/building/processTypes";
import { createSectionPlan } from "redux/modules/common/building/processApi";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { errorCatcher } from "utils/helpers/errorCatcher";

export interface IUseSectionPlan {
  sectionId: number;
  onClose: () => void;
  planCreateSuccessCallback?: (_: ISectionPlan) => void;
  /** Предустановленная дата начала/окончания плана (1 день)
  @example YYYY-MM-DD
  */
  defaultDate?: string;
}

const DEFAULT_DATE = moment();

export const useSectionPlan = ({ sectionId, onClose, planCreateSuccessCallback, defaultDate }: IUseSectionPlan) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const [dateStart, setDateStart] = React.useState(defaultDate ? moment(defaultDate) : DEFAULT_DATE);
  const [dateEnd, setDateEnd] = React.useState(defaultDate ? moment(defaultDate) : DEFAULT_DATE);

  const submitHandler = (fillMissedPercents: boolean) => {
    if (!objectId) return;

    const data = {
      start_at: moment(dateStart).format("YYYY-MM-DD"),
      end_at: moment(dateEnd).format("YYYY-MM-DD"),
      section_id: String(sectionId),
    };

    createSectionPlan(objectId, data)
      .then(({ data }) => {
        message.success("План создан"); /* @ts-ignore */
        planCreateSuccessCallback?.(data, fillMissedPercents);
        onClose();
      })
      .catch(errorCatcher);
  };

  return {
    dateEnd,
    dateStart,
    setDateEnd,
    setDateStart,
    submitHandler,
  };
};
