export const sortExpendituresAndGroups = (
  expenditures: any[] = [],
  groups: any[] = [],
  anchorIndexFn: (_: any) => number,
  insertIndexFn: (expenditures: any[], groupAnchorNumber: number) => any
) => {
  /* if (!expenditures?.length) return groups;
  if (!groups?.length) return expenditures; */
  const sortedExpenditures = [].concat(expenditures as any);
  groups
    .sort((a, b) => anchorIndexFn(a) - anchorIndexFn(b))
    .forEach((group, index) => {
      const groupAnchorNumber = anchorIndexFn(group);
      let insertIndex = index + insertIndexFn(expenditures, groupAnchorNumber); /* @ts-ignore */
      if (insertIndex === -1) sortedExpenditures.push(group); /* @ts-ignore */
      else sortedExpenditures.splice(insertIndex, 0, group);
    });
  return sortedExpenditures;
};
