import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  actingDetailedKs2Selector,
  actingDetailedKs3Selector,
} from "redux/modules/common/building/journal/acting/selectors";
import { editKs2, editKs3, getDetailedKs2, getDetailedKs3 } from "redux/modules/common/building/journal/acting/thunks";

import { IActData } from "./useCreateKs2";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { checkEditingKs3IsDirty } from "./utils";

export const useEditKs3 = (ks3Id: number, isOpen: boolean) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const dispatch = useDispatch();
  const [deletingExpsIds, setDeletingExpsIds] = React.useState<number[]>([]);
  const detailedKs2 = useSelector(actingDetailedKs3Selector)[ks3Id];

  React.useEffect(() => {
    isOpen && dispatch(getDetailedKs3(objectId, ks3Id));
  }, [objectId, ks3Id, isOpen]);

  const expendituresInKs = React.useMemo(() => {
    return detailedKs2?.items?.map((section) => ({
      ...section,
      expenditures: section.ks2_acts.filter((el) => !deletingExpsIds.includes(el.id)),
    }));
  }, [detailedKs2, deletingExpsIds]);

  const expendituresCount = React.useMemo(() => {
    return expendituresInKs?.reduce((prev, cur) => {
      return prev + cur.expenditures.length;
    }, 0);
  }, [expendituresInKs]);

  const deleteExpenditure = React.useCallback((id: number) => {
    setDeletingExpsIds((prev) => [...prev, id]);
  }, []);

  const [actData, setActData] = React.useState<IActData>({
    dateEnd: moment().format("YYYY-MM-DD"),
    dateStart: moment().format("YYYY-MM-DD"),
  } as IActData);

  const actNumberHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setActData((prev) => ({ ...prev, number: e.target.value }));
  }, []);

  React.useEffect(() => {
    detailedKs2 &&
      setActData((prev) => ({
        number: detailedKs2.title,
        dateStart: detailedKs2.start_at,
        dateEnd: detailedKs2.end_at,
      }));
  }, [detailedKs2]);

  const dateStartHandler = React.useCallback((date: string) => {
    setActData((prev) => ({ ...prev, dateStart: date }));
  }, []);

  const dateEndHandler = React.useCallback((date: string) => {
    setActData((prev) => ({ ...prev, dateEnd: date }));
  }, []);

  const submitHandler = React.useCallback(() => {
    const formattedData = {
      title: actData.number,
      start_at: moment(actData.dateStart).format("YYYY-MM-DD"),
      end_at: moment(actData.dateEnd).format("YYYY-MM-DD"),
    };
    dispatch(editKs3(objectId, ks3Id, deletingExpsIds, formattedData));
  }, [objectId, ks3Id, deletingExpsIds, actData]);

  const isShowSubmit = checkEditingKs3IsDirty(actData, deletingExpsIds, detailedKs2);

  return {
    submitHandler,
    actData,
    actNumberHandler,
    dateStartHandler,
    dateEndHandler,
    deleteExpenditure,
    expendituresInKs,
    expendituresCount,
    isShowSubmit,
    detailedKs2,
  };
};
