import cn from "classnames";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";

import InputSelect from "../../../../../../../_LEGACY/UI/__trash/InputSelect";
import Select from "components/UI/atoms/Select";

import Calendar from "../../../../../../../shared/ui/inputs/Calendar/Calendar";
import InputBase from "../../../../../../../shared/ui/inputs/InputBase";
import PriorityRange from "../../../PriorityRange/PriorityRange";
import TasksPersonsSelect from "../../../TaskPersonsSelect/TasksPersonsSelect";
import { useTypedSelector } from "app/store/typedUseSelector";
import { TimePicker } from "shared/ui/inputs/TimePicker/TimePicker";

import { IdNameLabelType } from "../../../../../../../types/IdNameLabelType";
import { TASKS_STATUS_OPTIONS } from "../../../../constants";

import { useTaskObjectsSectionsUsers } from "../../../../hooks/useTaskObjectsSectionsUsers";

import {
  composeFieldValidators,
  maxLength,
  minLength,
  required,
} from "../../../../../../../utils/formHelpers/validations";

import selectStyles from "../../../TaskPersonsSelect/TasksPersonsSelect.module.scss";
import styles from "../ModalAddTask/ModalAddTask.module.scss";

const ModalEditTaskMainFields: React.FC<any> = ({
  building,
  section_id,
  setSectionId,
  executor,
  setExecutor,
  viewers,
  setViewers,
  isVisible = true,
  isTaskStatusEditable = true,
}) => {
  const { section, users } = useTaskObjectsSectionsUsers({ building_id: building.id });

  useEffect(() => {
    if (!section.find((x) => x.id === section_id)) {
      setSectionId(undefined);
    }
  }, [section]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const inputs = containerRef.current?.querySelectorAll("input");
    inputs?.forEach((input) => {
      input.addEventListener("keypress", (e) => {
        if (e.key.toLowerCase() === "enter") {
          e.preventDefault();
        }
      });
    });
  }, []);

  const invalidateKey = useTypedSelector((state) => state.tasks.invalidateKey);

  return (
    <div
      className={cn(styles.fields, {
        [styles.isVisible]: isVisible,
      })}
      ref={containerRef}
      key={invalidateKey}
    >
      <Field
        name={"name"}
        label={"*Название"}
        placeholder={"Название новой задачи"}
        /* @ts-ignore */
        component={InputBase}
        validate={composeFieldValidators(
          required(),
          maxLength(255, "Не более 255 символов"),
          minLength(1, "Введите название задачи")
        )}
        className={styles.outView}
      />
      <Field
        name={"description"}
        validate={maxLength(255, "Не более 255 символов")}
        render={({ input, meta }) => (
          <div className={styles.outView}>
            <label className={styles.label}>Описание задачи</label>
            <TextareaAutosize {...input} placeholder={"Описание задачи"} minRows={3} />
            {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
          </div>
        )}
      />
      <div className={styles.pair}>
        <Field
          name={"deadline"}
          validate={required()}
          render={({ input, meta }) => (
            <div className={styles.outView}>
              <label className={styles.label}>*Контрольная дата</label>
              <Calendar
                value={input.value || meta.initial}
                setValue={(v) => {
                  const minutes = moment(input.value).get("minutes");
                  const hours = moment(input.value).get("hours");
                  input.onChange(moment(v).add(hours, "hours").add(minutes, "minutes"));
                }}
                classNameSelect={""}
                classNameOptions={styles.calendarOptions}
                label={input.label}
                placeholder={moment().format("DD/MM/YY")}
                format={"DD/MM/YY"}
              />
              {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
            </div>
          )}
        />
        <Field
          name={"deadline"}
          render={({ input, meta }) => (
            <div className={styles.outView}>
              <label className={styles.label}>Время</label>
              <TimePicker deadline={input.value || meta.initial} setValue={input.onChange} />
              {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
            </div>
          )}
        />
      </div>
      <Field
        name={"priority"}
        render={({ input, meta }) => (
          <>
            <PriorityRange
              onChange={input.onChange}
              value={input.value}
              defaultValue={meta.initial}
              className={styles.range}
              label={"*Приоритет:"}
            />
            {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
          </>
        )}
      />

      <Field
        name={"section"}
        render={({ input, meta }) => (
          <Select
            label="Раздел"
            options={section}
            input={input}
            placeholder={"Введите или укажите наименование раздела"}
            meta={meta}
          />
        )}
      />

      <Field
        name={"executor"}
        validate={required()}
        render={({ input, meta }) => (
          <TasksPersonsSelect
            name={"executor"}
            input={{
              ...input,
              onChange: (value: string) => {
                input.onChange(value);
              },
            }}
            options={users}
            label={"*Исполнители"}
            placeholder={"Введите или укажите ФИО исполнителя"}
            meta={meta}
            value={input.value}
            isSingleSelect
          />
        )}
      />
      <Field
        name={"viewers"}
        render={({ input, meta }) => (
          <TasksPersonsSelect
            name={"viewers"}
            input={{
              ...input,
              onChange: (value: string) => {
                input.onChange(value);
              },
            }}
            options={users}
            label={"Контролеры"}
            placeholder={"Введите или укажите ФИО контролера"}
            meta={meta}
            value={input.value}
          />
        )}
      />
    </div>
  );
};

export default React.memo(ModalEditTaskMainFields);
