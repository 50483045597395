import { message } from "antd";
import { isEqual } from "lodash";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { simpleResourcesAPI } from "../../../../../features/simpleResources/lib/api";

import {
  ISimpleResource,
  ISimpleResourceCreate,
  ISimpleResourceUpdate,
  ResourceTypeEnum,
} from "../../../../../features/simpleResources/types/simpleResources";
import { IRouterParamsWithObjectId } from "../../../../../types/routerTypes";

export type TResource = Omit<ISimpleResource, "type" | "measure" | "id" | "type_display"> & {
  id?: string;
  tmpId?: string;
  type: ResourceTypeEnum | null;
  measure?: string | null;
  isEditing?: boolean;
  isAdding?: boolean;
  touched?: boolean;
  delete?: boolean;
};

let id = 1;

export const useSimpleResources = (initialResources?: ISimpleResource[]) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  /* @ts-ignore */
  const [resources, setResources] = useState<TResource[]>(() => initialResources ?? []);

  const isAdding = useMemo(() => {
    return resources.some((r) => r.isAdding);
  }, [resources]);

  const isMaterialAdding = useMemo(() => {
    return resources.some(
      /* @ts-ignore */
      (r) => ![ResourceTypeEnum.transport, ResourceTypeEnum.machine].includes(r.type) && r.isAdding
    );
  }, [resources]);

  const isMimAdding = useMemo(() => {
    /* @ts-ignore */
    return resources.some((r) => [ResourceTypeEnum.transport, ResourceTypeEnum.machine].includes(r.type) && r.isAdding);
  }, [resources]);

  const isEditing = useMemo(() => {
    return resources.some((r) => r.isEditing);
  }, [resources]);

  const createNewResource = ({ type = null }) => {
    id += 1;
    const newResource: TResource = {
      tmpId: id.toString(),
      isAdding: true,
      name: "",
      count: "",
      type,
      measure: null,
    };
    setResources((prev) => [newResource, ...prev]);
  };

  const deleteResource = (r: TResource) => {
    const idx = findIndex(resources, r);

    if (idx !== -1) {
      if (r.tmpId) {
        setResources((prev) => prev.filter((_, innerIdx) => innerIdx !== idx));
      } else {
        setResources((prev) =>
          prev.map((r2, innerIdx) => {
            if (innerIdx === idx) {
              return {
                ...r2,
                delete: true,
              };
            } else {
              return r2;
            }
          })
        );
      }
    }
  };

  const toggleIsEditing = (r: TResource) => {
    let idx = findIndex(resources, r);

    if (idx !== -1) {
      setResources((prev) =>
        prev.map((resource, innerIdx) => {
          if (innerIdx === idx) {
            return {
              ...resource,
              isEditing: !resource.isEditing,
            };
          } else {
            return resource;
          }
        })
      );
    }
  };

  const updateResource = (r: TResource) => {
    const idx = findIndex(resources, r);

    if (idx !== -1) {
      setResources((prev) =>
        prev.map((r2, innerIdx) => {
          if (idx === innerIdx) {
            const touched = !isEqual({ ...r2, ...r }, r2);

            return {
              ...r2,
              ...r,
              isAdding: false,
              isEditing: false,
              touched,
            };
          } else {
            return r2;
          }
        })
      );
    }
  };

  const onSubmit = async ({ fact_work, fact_group }: { fact_work?: number; fact_group?: number }) => {
    const createCandidates = resources.filter((r) => r.tmpId && !r.isAdding);
    const updateCandidates = resources.filter((r) => r.touched && !r.tmpId && !r.isEditing && !r.delete);
    const deleteCandidates = resources.filter((r) => r.delete);

    const promises = []; /* @ts-ignore */
    const deleted = []; /* @ts-ignore */
    const updated = []; /* @ts-ignore */
    const created = [];

    if (deleteCandidates.length) {
      promises.push(
        ...deleteCandidates.map((c) =>
          simpleResourcesAPI.deleteById(+c.id!, objectId).then(() => {
            deleted.push(c.id);
            return null;
          })
        )
      );
    }
    if (createCandidates.length) {
      promises.push(
        ...createCandidates.map((c) => {
          const resource: ISimpleResourceCreate = {
            name: c.name,
            count: c.count,
            type: c.type!,
            measure: c.measure!,
            fact_work,
            fact_group,
          };
          return simpleResourcesAPI.create(resource, objectId).then((data) => {
            created.push(data);
            return data;
          });
        })
      );
    }
    if (updateCandidates.length) {
      promises.push(
        ...updateCandidates.map((c) => {
          const resource: ISimpleResourceUpdate = {
            measure: c.measure!,
            count: c.count,
            name: c.name,
            type: c.type!,
          };
          return simpleResourcesAPI.updateById(resource, +c.id!, objectId).then((data) => {
            updated.push(data);
            return data;
          });
        })
      );
    }

    await Promise.allSettled(promises);

    return {
      /* @ts-ignore */
      deleted /* @ts-ignore */,
      created /* @ts-ignore */,
      updated,
    };
  };

  const isValidForSubmit = () => {
    if (isAdding) {
      message.error("Завершите добавление ресурса");
      return false;
    }
    if (isEditing) {
      message.error("Завершите редактирование ресурса");
      return false;
    }

    return true;
  };

  const mims = resources.filter(
    (r) => r.type && [ResourceTypeEnum.transport, ResourceTypeEnum.machine].includes(r.type)
  );

  const materials = resources.filter(
    (r) => r.type && ![ResourceTypeEnum.transport, ResourceTypeEnum.machine].includes(r.type)
  );

  return {
    resources,
    materials,
    mims,
    createNewResource,
    deleteResource,
    isAdding,
    isMaterialAdding,
    isMimAdding,
    isEditing,
    toggleIsEditing,
    updateResource,
    onSubmit,
    isValidForSubmit,
  };
};

// У ресурса может быть 3 состояния
// 1. Обычный созданный ресурс, который пришел с бэка
// 2. Обычный ресурс, который отредактировали и его нужно обновить (touched = true)
// 3. Ресурс, который был добавлен и его нужно создать, отправив запрос (tmpId)
// 4. Ресурс, который нужно удалить (delete = true)

const findIndex = (resources: TResource[], r: TResource) => {
  let idx: number;

  if (r.tmpId) {
    idx = resources.findIndex((r2: TResource) => r2.tmpId === r.tmpId);
  } else {
    idx = resources.findIndex((r2: TResource) => r2.id === r.id);
  }

  return idx;
};
