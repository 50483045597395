import React, { ReactNode, memo, useMemo } from "react";

import EmptyPlaceholder, { IEmptyPlaceholderProps } from "../EmptyPlaceholder/EmptyPlaceholder";
import Spinner, { ISpinnerProps } from "shared/ui/atoms/Spinner/Spinner";
import ShowMoreButton from "shared/ui/controls/ShowMoreButton";

type IProps<Data, Filter extends Record<string, any>> = {
  isLoading?: boolean;
  spinnerProps?: ISpinnerProps;
  header?: ReactNode;
  dataMapper: (data: Data) => ReactNode;
  data?: Data[];
  filter?: Filter;
  checkIsAnyFilterApplied?: (filter: Filter) => boolean;
  total?: number;
  onPagination?: () => void;
  placeholderProps?: IEmptyPlaceholderProps;
  isLoadingMore?: boolean;
};

function ListWrapper<Data, Filter extends Record<string, any>>({
  isLoading,
  spinnerProps,
  header,
  data,
  placeholderProps,
  dataMapper,
  filter,
  checkIsAnyFilterApplied = baseFilterApllienceChecker,
  isLoadingMore,
  total,
  onPagination,
}: IProps<Data, Filter>) {
  const showHeader = useMemo(() => {
    if (!!data?.length) return true;
    if (filter) {
      return checkIsAnyFilterApplied(filter);
    }
    return false;
  }, [filter, checkIsAnyFilterApplied, data?.length]);

  return (
    <>
      {showHeader && header}
      {data?.map(dataMapper)}
      {isLoading && <Spinner {...spinnerProps} />}
      {!isLoading && !data?.length && <EmptyPlaceholder {...placeholderProps} />}
      {!!onPagination && (
        <ShowMoreButton
          isLoading={isLoadingMore}
          isExists={!isLoading}
          handleAdd={onPagination}
          showedCount={data?.length}
          allCount={total}
        />
      )}
    </>
  );
}

export default ListWrapper;

function baseFilterApllienceChecker<Filter extends Record<string, any>>(filter: Filter) {
  return Object.values(filter).filter((el) => el !== "" && el !== undefined).length > 0;
}
