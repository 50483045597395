//@ts-nocheck
import { message } from "antd";
import { createSelector } from "reselect";

import { PRODUCT_TYPES } from "_LEGACY/UI/_LEGACY_Purchases_components/AddProductsModal/constants";

import {
  apiAddFile,
  apiDeleteExpenditures,
  apiDeleteFile,
  apiDeleteSections,
  apiGetSections,
  apiGetSubSections,
  apiPostExpenditures,
  apiPostSubSections,
  apiUpdateExpenditure,
  apiUpdateSpecificationDetail,
} from "./specificationApi";

import { IAddProductsSubsection } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";

const moduleName = "specification";

const GET_SPECIFICATION = `${moduleName}/GET_SPECIFICATION`;
const SET_SPECIFICATION_SECTIONS = `${moduleName}/SET_SPECIFICATION_SECTIONS`;
const ADD_SPECIFICATION_SECTIONS = `${moduleName}/ADD_SPECIFICATION_SECTIONS`;
const SET_SPECIFICATION_SUB_SECTIONS = `${moduleName}/SET_SPECIFICATION_SUB_SECTIONS`;
const SET_CHOOSE_SECTION_SUB_SECTIONS = `${moduleName}/SET_CHOOSE_SECTION_SUB_SECTIONS`;
const SET_IS_CHOOSE_SECTION_SUB_SECTIONS_LOADING = `${moduleName}/SET_IS_CHOOSE_SECTION_SUB_SECTIONS_LOADING`;
const ADD_SPECIFICATION_SUB_SECTIONS = `${moduleName}/ADD_SPECIFICATION_SUB_SECTIONS`;
const ADD_SPECIFICATION_EXPENDITURES = `${moduleName}/ADD_SPECIFICATION_EXPENDITURES`;
const SET_SPECIFICATION_EXPENDITURES = `${moduleName}/SET_SPECIFICATION_EXPENDITURES`;
const SET_SPECIFICATION_EXPENDITURES_AS_ARRAY = `${moduleName}/SET_SPECIFICATION_EXPENDITURES_AS_ARRAY`;
const UPDATE_SPECIFICATION_EXPENDITURES = `${moduleName}/UPDATE_SPECIFICATION_EXPENDITURES`;
const ADD_NEW_SPECIFICATION_EXPENDITURE = `${moduleName}/ADD_NEW_SPECIFICATION_EXPENDITURE`;
const REMOVE_SPECIFICATION_EXPENDITURES = `${moduleName}/REMOVE_SPECIFICATION_EXPENDITURES`;
const REMOVE_SPECIFICATION_SECTIONS = `${moduleName}/REMOVE_SPECIFICATION_SECTIONS`;
const REMOVE_SPECIFICATION_SUB_SECTIONS = `${moduleName}/REMOVE_SPECIFICATION_SUB_SECTIONS`;
const ADD_FILES = `${moduleName}/ADD_FILES`;
const REMOVE_FILES = `${moduleName}/REMOVE_FILES`;
const SHOW_LOADER = `${moduleName}/SHOW_LOADER`;
const HIDE_LOADER = `${moduleName}/HIDE_LOADER`;
const CHANGE_SPECIFICATION_DELAY_DAYS = `${moduleName}/CHANGE_SPECIFICATION_DELAY_DAYS`;

export const MATERIALS_TYPES = {
  SPECIFICATION: "specification",
  OUT_OF_ESTIMATE: "out_of_estimate",
  ADDITIONAL: "additional",
  MIMS: "service",
};

const initialState = {
  specification_sections: [],
  specification_sub_sections: {},
  choose_section_sub_sections: {},
  specification_expenditures: {},
  isLoading: false,
  isChooseSectionSubSectionsLoading: false,
};

export default (state = initialState, action) => {
  const { type, payload, id, fileId } = action;
  switch (type) {
    case SHOW_LOADER:
      return { ...state, isLoading: true };
    case HIDE_LOADER:
      return { ...state, isLoading: false };
    case GET_SPECIFICATION:
      return { ...state };
    case SET_SPECIFICATION_SECTIONS:
      return {
        ...state,
        specification_sections: payload,
      };
    case ADD_SPECIFICATION_SECTIONS:
      return {
        ...state,
        specification_sections: [payload, ...state.specification_sections],
      };
    case REMOVE_SPECIFICATION_SECTIONS:
      return {
        ...state,
        specification_sections: [...state.specification_sections.filter((el) => el.id !== payload)],
      };

    case SET_SPECIFICATION_SUB_SECTIONS:
      return {
        ...state,
        specification_sub_sections: payload,
      };
    case SET_CHOOSE_SECTION_SUB_SECTIONS:
      return {
        ...state,
        choose_section_sub_sections: payload,
      };
    case SET_IS_CHOOSE_SECTION_SUB_SECTIONS_LOADING:
      return {
        ...state,
        isChooseSectionSubSectionsLoading: payload,
      };
    case ADD_SPECIFICATION_SUB_SECTIONS:
      return {
        ...state,
        specification_sub_sections: {
          ...state.specification_sub_sections,
          subsections: [payload, ...state.specification_sub_sections.subsections],
        },
      };
    case ADD_SPECIFICATION_EXPENDITURES:
      return {
        ...state,
        specification_sub_sections: {
          ...state.specification_sub_sections,
          subsections: [payload, ...state.specification_sub_sections.subsections],
        },
      };
    case REMOVE_SPECIFICATION_SUB_SECTIONS:
      return {
        ...state,
        specification_sub_sections: {
          ...state.specification_sub_sections,
          subsections: [...state.specification_sub_sections.subsections.filter((el) => el.id !== payload)],
        },
      };

    case SET_SPECIFICATION_EXPENDITURES:
      return {
        ...state,
        specification_expenditures: payload,
      };
    case SET_SPECIFICATION_EXPENDITURES_AS_ARRAY:
      return {
        ...state,
        specification_expenditures: Array.isArray(state.specification_expenditures)
          ? [...state.specification_expenditures, payload]
          : [state.specification_expenditures, payload],
      };
    case UPDATE_SPECIFICATION_EXPENDITURES:
      return {
        ...state,
        specification_expenditures: {
          ...state.specification_expenditures,
          products: [
            ...state.specification_expenditures.products.map((el) =>
              el.id === payload.id ? { ...el, ...payload } : el
            ),
          ],
        },
      };
    case ADD_NEW_SPECIFICATION_EXPENDITURE:
      return {
        ...state,
        specification_expenditures: {
          ...state.specification_expenditures,
          products: [payload, ...state.specification_expenditures.products],
        },
      };
    case REMOVE_SPECIFICATION_EXPENDITURES:
      return {
        ...state,
        specification_expenditures: {
          ...state.specification_expenditures,
          products: [...state.specification_expenditures.products.filter((el) => el.id !== payload)],
        },
      };
    case ADD_FILES:
      return {
        ...state,
        specification_expenditures: {
          ...state.specification_expenditures,
          products: [
            ...state.specification_expenditures.products.map((el) => (el.id === id ? { ...el, files: payload } : el)),
          ],
        },
      };
    case REMOVE_FILES:
      return {
        ...state,
        specification_expenditures: {
          ...state.specification_expenditures,
          products: [
            ...state.specification_expenditures.products.map((el) => {
              if (el.id === id) {
                return {
                  ...el,
                  files: el.files.filter((file) => file.id !== fileId),
                };
              } else {
                return el;
              }
            }),
          ],
        },
      };
    case CHANGE_SPECIFICATION_DELAY_DAYS:
      return {
        ...state,
        specification_expenditures: {
          ...state.specification_expenditures,
          products: [
            ...state.specification_expenditures.products.map((item) =>
              item.id === payload?.id ? { ...item, delay_days: payload?.delay_days } : item
            ),
          ],
        },
      };

    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const specificationSectionsSelector = createSelector(stateSelector, (state) => state.specification_sections);
export const specificationSubSectionsSelector = createSelector(
  stateSelector,
  (state) => state.specification_sub_sections as IAddProductsSubsection
);
export const chooseSectionSubSectionsSelector = createSelector(
  stateSelector,
  (state) => state.choose_section_sub_sections
);
export const isChooseSectionSubSectionsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.isChooseSectionSubSectionsLoading
);
export const specificationExpendituresSelector = createSelector(
  stateSelector,
  (state) => state.specification_expenditures
);

export const isLoadingSpecificationSelector = createSelector(stateSelector, (state) => state.isLoading);

export const showLoaderActionSpecification = () => ({ type: SHOW_LOADER });
export const hideLoaderActionSpecification = () => ({ type: HIDE_LOADER });
export const setSpecificationSections = (payload) => ({ type: SET_SPECIFICATION_SECTIONS, payload });
export const setSpecificationSubSections = (payload) => ({ type: SET_SPECIFICATION_SUB_SECTIONS, payload });
export const setChooseSectionSubSections = (payload) => ({ type: SET_CHOOSE_SECTION_SUB_SECTIONS, payload });
export const setIsChooseSectionSubSectionsLoading = (payload) => ({
  type: SET_IS_CHOOSE_SECTION_SUB_SECTIONS_LOADING,
  payload,
});
export const setSpecificationExpenditures = (payload) => ({ type: SET_SPECIFICATION_EXPENDITURES, payload });
export const setSpecificationExpendituresAsArray = (payload) => ({
  type: SET_SPECIFICATION_EXPENDITURES_AS_ARRAY,
  payload,
});

export const getSpecificationsSections =
  (objectId, type, searchText = "") =>
  async (dispatch) => {
    try {
      dispatch(showLoaderActionSpecification());
      const { data } = await apiGetSections(objectId, type, searchText);
      await dispatch(setSpecificationSections(data));
    } catch (error) {
      errorCatcher(error);
    }

    dispatch(hideLoaderActionSpecification());
  };

export const getSpecificationsSubSections = (objectId, type, Id, searchText = "") => {
  return async (dispatch) => {
    try {
      dispatch(showLoaderActionSpecification());
      const { data } = await apiGetSubSections(objectId, type, Id, searchText);
      await dispatch(setSpecificationSubSections(data));
      dispatch(hideLoaderActionSpecification());
    } catch (error) {
      if (error.response.status === 404 && searchText) {
        await dispatch(setSpecificationSubSections({}));
      }

      errorCatcher(error);
    }
  };
};

export const getChooseSectionModalSubSections = (objectId, id) => {
  return async (dispatch) => {
    try {
      dispatch(setIsChooseSectionSubSectionsLoading(true));
      const { data } = await apiGetSubSections(objectId, PRODUCT_TYPES.SPECIFICATION, id);
      await dispatch(setChooseSectionSubSections(data));
      dispatch(setIsChooseSectionSubSectionsLoading(false));
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const getSpecificationsExpenditures = (objectId, type, Id, isAddToArray) => async (dispatch) => {
  try {
    dispatch(showLoaderActionSpecification());
    const { data } = await apiGetSubSections(objectId, type, Id);

    isAddToArray ? dispatch(setSpecificationExpendituresAsArray(data)) : dispatch(setSpecificationExpenditures(data));
  } catch (error) {
    errorCatcher(error);
  }
  dispatch(hideLoaderActionSpecification());
};

export const addSection = (objectId, config) => {
  return async (dispatch) => {
    try {
      const { data } = await apiPostSubSections(objectId, config);
      await dispatch({
        type: ADD_SPECIFICATION_SECTIONS,
        payload: data,
      });
      await message.success("Сохранено");
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const addSubSection = (objectId, config) => {
  return async (dispatch) => {
    try {
      const { data } = await apiPostSubSections(objectId, config);
      await dispatch({
        type: ADD_SPECIFICATION_SUB_SECTIONS,
        payload: data,
      });
      await message.success("Сохранено");
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const addExpenditure = (objectId, type, config, successCallback) => {
  return async (dispatch) => {
    try {
      const { data } = await apiPostExpenditures(objectId, type, config);
      await dispatch({
        type: ADD_NEW_SPECIFICATION_EXPENDITURE,
        payload: data,
      });
      await dispatch({
        type: GET_SPECIFICATION,
      });
      await message.success("Сохранено");
      successCallback?.();
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const deleteSection = (objectId, id) => {
  return async (dispatch) => {
    try {
      await apiDeleteSections(objectId, id);
      await dispatch({
        type: REMOVE_SPECIFICATION_SECTIONS,
        payload: id,
      });
      await message.success(`Раздел удален из списка`);
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const deleteSubSection = (objectId, id) => {
  return async (dispatch) => {
    try {
      await apiDeleteSections(objectId, id);
      await dispatch({
        type: REMOVE_SPECIFICATION_SUB_SECTIONS,
        payload: id,
      });
      await message.success(`Подраздел удален из списка`);
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const deleteExpenditure = (objectId, typeUrl, Id) => {
  return async (dispatch) => {
    try {
      await apiDeleteExpenditures(objectId, typeUrl, Id);
      await dispatch({
        type: REMOVE_SPECIFICATION_EXPENDITURES,
        payload: Id,
      });
      await message.success(`Позиция удалена из списка`);
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const updateExpenditure = (objectId, typeUrl, Id, config) => {
  return async (dispatch) => {
    try {
      const { data } = await apiUpdateExpenditure(objectId, typeUrl, Id, config);
      if ("ret_own_mat" in config) {
        // Не возвращается это поле при успешном PATCH запросе
        // Поэтому обновляем его здесь
        data.ret_own_mat = config.ret_own_mat;
      }
      await dispatch({
        type: UPDATE_SPECIFICATION_EXPENDITURES,
        payload: data,
      });
      await message.success("Обновлено");
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const addFile = (objectId, Id, config) => {
  return async (dispatch) => {
    try {
      const { data } = await apiAddFile(objectId, Id, config);
      await dispatch({
        type: ADD_FILES,
        id: Id,
        payload: data,
      });
      await message.success("Добавленно");
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const deleteFile = (objectId, Id, fileId, config) => {
  return async (dispatch) => {
    try {
      await apiDeleteFile(objectId, Id, fileId, config);
      await dispatch({
        type: REMOVE_FILES,
        id: Id,
        fileId: fileId,
      });
      await message.success("Удалено");
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const updateSpecificationDetail = (objectId, specificationId, delayDays) => {
  return async (dispatch) => {
    try {
      const { data } = await apiUpdateSpecificationDetail(objectId, specificationId, delayDays);
      dispatch({
        type: CHANGE_SPECIFICATION_DELAY_DAYS,
        payload: data,
      });
      message.success("Количество дней успешно указано");
    } catch (error) {
      errorCatcher(error);
    }
  };
};
