import moment from "moment";
import React, { useMemo } from "react";

import LabelTemplate from "_LEGACY/UI/__TODO/LabeledItem/LabelTemplate/LabelTemplate";
import { renderTextWithLinks } from "components/pages/Tasks/components/TaskComments/TaskOneComment";

import { FILE_UPLOAD_VARIANT, FileUpload } from "entities/FileUpload";
import ButtonBack from "shared/ui/controls/ButtonBack/ButtonBack";
import { Checkpoint } from "widgets/AddCheckpoint";

import getShortFullName from "utils/formatters/getShortFullName";
import { IFile } from "utils/serializeFiles";

import styles from "./CheckpointDetails.module.scss";

export interface CheckpointDetailsProps {
  checkpoint: Checkpoint;
  onBack?: () => void;
  fileStorageId: number;
}

export const CheckpointDetails: React.FC<CheckpointDetailsProps> = ({ checkpoint, onBack, fileStorageId }) => {
  const filesMemo = useMemo(
    () => (checkpoint.files.map((file) => ({ ...file, name: file.originalname })) as unknown as IFile[]) || [],
    [checkpoint]
  );
  return (
    <div className={styles.viewContainer}>
      {onBack ? (
        <div className={styles.back}>
          <ButtonBack onClick={onBack} />
          <span>Назад к списку</span>
        </div>
      ) : null}
      <LabelTemplate label={"Название"}>{checkpoint.name}</LabelTemplate>
      <LabelTemplate label={"Описание"}>{renderTextWithLinks(checkpoint.description, onBack)}</LabelTemplate>
      <LabelTemplate label={"Контрольная дата"}>
        <div className={styles.date}>
          {moment(checkpoint.check_point_date).format("DD/MM/YY")}
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6 2.5C6 2.22386 6.22386 2 6.5 2H7.5C7.77614 2 8 2.22386 8 2.5V4.00001H16V2.5C16 2.22386 16.2239 2 16.5 2H17.5C17.7761 2 18 2.22386 18 2.5V4.00001H18.5C20.433 4.00001 22 5.56701 22 7.50001V18.5C22 20.433 20.433 22 18.5 22H5.5C3.567 22 2 20.433 2 18.5V7.50001C2 5.56701 3.567 4.00001 5.5 4.00001H6V2.5ZM4 18.5V9H20V18.5C20 19.3284 19.3284 20 18.5 20H5.5C4.67157 20 4 19.3284 4 18.5ZM13.5 14C13.2239 14 13 14.2239 13 14.5V17.5C13 17.7761 13.2239 18 13.5 18H17.5C17.7761 18 18 17.7761 18 17.5V14.5C18 14.2239 17.7761 14 17.5 14H13.5Z"
              fill="#4FB1EB"
            />
          </svg>
        </div>
      </LabelTemplate>
      <LabelTemplate label={"Проект"}>{checkpoint.building_name}</LabelTemplate>
      <LabelTemplate label={"Раздел"}>{checkpoint.section_name}</LabelTemplate>
      <LabelTemplate label={`Исполнители: ${checkpoint.executors_data.length}`}>
        <div className={styles.bubbles}>
          {checkpoint.executors_data.map(({ executor }) => (
            <div className={styles.bubble} key={executor.id}>
              {/* @ts-ignore */}
              <span>{getShortFullName(executor.last_name, executor.first_name, executor.middle_name)}</span>
            </div>
          ))}
        </div>
      </LabelTemplate>
      <LabelTemplate label={`Контролеры: ${checkpoint.viewers_data.length}`}>
        <div className={styles.bubbles}>
          {checkpoint.viewers_data.map(({ viewer }) => (
            <div className={styles.bubble} key={viewer.id}>
              {/* @ts-ignore */}
              <span>{getShortFullName(viewer.last_name, viewer.first_name, viewer.middle_name)}</span>
            </div>
          ))}
        </div>
      </LabelTemplate>
      <FileUpload variant={FILE_UPLOAD_VARIANT.VIEW} files={filesMemo} fileStorageId={fileStorageId} />
    </div>
  );
};
