import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";

import Actions from "../../../../../../shared/ui/controls/Actions/Actions";
import Checkbox from "../../../../../../shared/ui/inputs/Checkbox/Checkbox";
import InputBase, { VALUE_TYPES } from "../../../../../../shared/ui/inputs/InputBase";

import { IMimsInAdding } from "types/interfaces/Mims";

import { stopEventPropagation } from "../../../../../../utils/helpers/stopEventPropagation";
import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";
import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";

import styles from "./TableRow.module.scss";

interface IProps {
  material: IMimsInAdding;
  onCheck?: (e: React.ChangeEvent<HTMLInputElement>, item: IMimsInAdding) => void;
  onChangeCount: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete?: () => void;
  isCanBeChecked?: boolean;
  isCanDelete?: boolean;
  isSelected?: boolean;
  isValidatingCount?: boolean;
}

const MimsTableRow: React.FC<IProps> = ({
  material,
  onCheck,
  onChangeCount,
  onDelete,
  isCanBeChecked = true,
  isCanDelete,
  isSelected,
  isValidatingCount,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [localValue, setLocalValue] = useState<string>(material.local_count || "");

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCount(e);
    setLocalValue(e.target.value);
  };

  const clickDrowningHandler = () => {
    ref.current?.click();
  };

  useEffect(() => {
    setLocalValue(material.local_count || "");
  }, [material]);

  const issuedCount = material?.sum_balance_count ?? material?.count ?? material?.available_count;

  const mimCountWithMeasure = `${dropNonSignificantZeros(issuedCount, 4)} ${sliceTextByConstraint(
    material?.measure,
    4
  )}`;

  const isCountError = isValidatingCount && +(material?.local_count || 0) > +issuedCount;

  return (
    <div
      className={cn(styles.row, styles.materialRow, {
        [styles.selected]: isSelected,
      })}
      onClick={clickDrowningHandler}
    >
      <div className={styles.checkbox}>
        {isCanBeChecked && (
          <Checkbox
            onCheck={(e: React.ChangeEvent<HTMLInputElement>) => onCheck?.(e, { ...material, local_count: localValue })}
            checked={isSelected as boolean}
          >
            <div ref={ref} />
          </Checkbox>
        )}
      </div>
      <div className={styles.name} title={material?.name}>
        {material?.name}
      </div>
      <div onClick={stopEventPropagation}>
        <InputBase
          classNameInput={cn(styles.input, { [styles.errorInput]: isCountError })}
          onChange={changeHandler}
          placeholder="Кол-во"
          value={localValue}
          valueType={VALUE_TYPES.NUMBER}
        />
      </div>
      <div className={cn(styles.name, styles.measure)} title={`${issuedCount} ${material?.measure}`}>
        {mimCountWithMeasure}
        {isCanDelete && (
          <Actions canRemove={isCanDelete} onRemove={onDelete} className={cn(styles.delete, styles.deleteMaterial)} />
        )}
      </div>
    </div>
  );
};

export default React.memo(MimsTableRow);
