import React from "react";

import { tasksListFilterType } from "components/pages/Tasks/model/types";

import TaskRow, { ItaskRowProps } from "../TaskRow/TaskRow";
import TaskSubheaderByObject from "./TaskSubheaderByObject/TaskSubheaderByObject";

import { IIdAndName } from "types/idAndName";

import styles from "./TaskRowByAllObjects.module.scss";

interface Iprops extends ItaskRowProps {
  isFirstInObject: boolean;
  isExists: boolean;
  building: IIdAndName;
  onExpand: (id: number) => void;
  onChangeSection: (id: number) => void;
  filters: tasksListFilterType;
}

function TaskRowByAllObjects(props: Iprops) {
  return (
    <>
      {props.isFirstInObject && (
        <TaskSubheaderByObject
          onChangeSection={props.onChangeSection}
          isExpanded={props.isExists}
          building={props.building}
          onExpand={props.onExpand}
          filters={props.filters}
        />
      )}
      {props.isExists && <TaskRow {...props} />}
    </>
  );
}

export default TaskRowByAllObjects;
