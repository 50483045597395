import cn from "classnames";
import React, { ReactNode } from "react";

import BackNavigationBar, { IBackNavigationBarProps } from "../BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder, { IEmptyPlaceholderProps } from "../EmptyPlaceholder/EmptyPlaceholder";
import Spinner from "shared/ui/atoms/Spinner/Spinner";

import styles from "./ListLayout.module.scss";

interface IProps<T> {
  isWithBackNavigation?: boolean;
  backNavigationProps?: IBackNavigationBarProps;
  items: T[];
  render: (item: T) => ReactNode;
  isLoading?: boolean;
  emptyPlaceholderProps?: IEmptyPlaceholderProps;
}

const ListLayout = <T extends any>({
  isWithBackNavigation,
  backNavigationProps,
  items,
  render,
  isLoading,
  emptyPlaceholderProps,
}: IProps<T>) => {
  return (
    <div>
      {isWithBackNavigation && (
        <BackNavigationBar
          {...(backNavigationProps ?? {})}
          title={backNavigationProps?.title ?? "Назад"}
          className={cn(styles.back)}
        />
      )}
      {!isWithBackNavigation && <div className={styles.topHolder} />}
      {items?.map(render)}
      {isLoading && <Spinner />}
      {!isLoading && !items?.length && <EmptyPlaceholder {...(emptyPlaceholderProps ?? {})} />}
    </div>
  );
};

export default ListLayout;
