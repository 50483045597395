import { IBetonActions } from "./betonActions";

import { IBetonState } from "./types";

const initialBetonState: IBetonState = {
  income: {},
  incomeIsLoading: {},
  invalidateKey: 0,
  expense: {},
  expenseDetail: {},
  incomeDetail: {},
  isPending: {},
};

export const betonReducer = (state = initialBetonState, action: IBetonActions): IBetonState => {
  const { type, payload } = action;

  switch (type) {
    case "beton/SET_INCOME":
      return {
        ...state,
        income: {
          ...state.income,
          [payload.stockId]: payload.income,
        },
      };
    case "beton/SET_INCOME_LOADING":
      return {
        ...state,
        incomeIsLoading: {
          ...state.incomeIsLoading,
          [payload.objectId]: payload.status,
        },
      };
    case "beton/INVALIDATE_KEY":
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    case "beton/SET_EXPENSE":
      return {
        ...state,
        expense: {
          ...state.expense,
          [payload.stockId]: payload.expense,
        },
      };
    case "beton/SET_EXPENSE_DETAIL":
      return {
        ...state,
        expenseDetail: {
          ...state.expenseDetail,
          [payload.betonId]: payload.detail,
        },
      };
    case "beton/SET_INCOME_DETAIL":
      return {
        ...state,
        incomeDetail: {
          ...state.incomeDetail,
          [payload.betonId]: payload.detail,
        },
      };
    case "beton/SET_PENDING":
      return {
        ...state,
        isPending: {
          ...state.isPending,
          [payload.betonId]: payload.status,
        },
      };
    default:
      return state;
  }
};
