import axios from "axios";
import { compose } from "redux";

import { setCommentsLoadingAction, setItemIdCommentsAction } from "../actions";

import { DEFAULT_QUERY_PARAMS } from "../constants";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

export const getStockProductComments = (stockId, productId) => async (dispatch) => {
  compose(dispatch, setCommentsLoadingAction)(true);

  const response = await axios
    .get(`/stocks/${stockId}/products/${productId}/comments/`, { params: DEFAULT_QUERY_PARAMS })
    .catch(errorCatcher);

  compose(dispatch, setItemIdCommentsAction)({ comments: response.data.results, itemId: productId });
  compose(dispatch, setCommentsLoadingAction)(false);
};
