import React from 'react';

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const SkeletonData = () =>
  <div
    style={{
      display: 'block',
      width: `${getRandomInt(40, 80)}%`,
      height: '0.6em',
      backgroundColor: '#ebebeb',
      margin: '0.2em',
      borderRadius: '0.5em'
    }}
  />;