import React from "react";
import { ChaptersEnum } from "pages/Handler/ui/ProHandler/enums";

import { CHAPTERS } from "../../../../constants";

import Chapter from "../../../Chapter/Chapter";

import styles from "./ChaptersList.module.scss";
import EmptyMessage from "../../../EmptyMessage/EmptyMessage";


interface IChaptersListProps {
  onClickByChapter?: (chapterId: ChaptersEnum) => void;
  chapters: typeof CHAPTERS;
}

const ChaptersList = ({ chapters, onClickByChapter }: IChaptersListProps) => {
  return (
    <div className={styles.list}>
      {chapters.length > 0 ? 
        chapters.map((chapter) => (
          <Chapter chapter={chapter} onClick={onClickByChapter} key={chapter.id} />
        ))
        :
        <EmptyMessage message="Чтобы продолжить, перенесите разделы и/или позиции" />
      }

    </div>
  );
};

export default React.memo(ChaptersList);
export type { IChaptersListProps };
