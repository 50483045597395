import { IActions } from "app/store/store";

import { IAttachmentInExpenditure, IPassport, Iks2ExpenditureDocsRecord } from "./types";

export const passportsActions = {
  setDocuments: (productId: number, documents: IPassport[]) =>
    ({
      type: "passports/SET_DOCUMENTS",
      payload: { productId, documents },
    } as const),
  setDocumentsLoading: (productId: number, status: boolean) =>
    ({
      type: "passports/SET_LOADING",
      payload: { productId, status },
    } as const),
  invalidateKey: () =>
    ({
      type: "passports/INVALIDATE_KEY",
      payload: true,
    } as const),
  setPending: (status: boolean) =>
    ({
      type: "passports/SET_PENDING",
      payload: status,
    } as const),
  setExpenditureAttachments: (expenditureId: string | number, attachments: IAttachmentInExpenditure[]) =>
    ({
      type: "passports/SET_ATTACHMENTS",
      payload: { expenditureId, attachments },
    } as const),
  setExpenditureAttachmentsLoading: (expenditureId: number | string, status: boolean) =>
    ({
      type: "passports/SET_ATTACHMENTS_LOADING",
      payload: { expenditureId, status },
    } as const),
  setKs2ExpenditureAttachments: (ks2Id: number, expenditureId: number, attachments: IAttachmentInExpenditure[]) =>
    ({
      type: "passports/SET_KS2_EXPENDITURE_ATTACHMENTS",
      payload: { expenditureId, attachments, ks2Id },
    } as const),
  setKs2Attachments: (ks2Id: number, attachments: IAttachmentInExpenditure[]) =>
    ({
      type: "passports/SET_KS2_ATTACHMENTS",
      payload: { attachments, ks2Id },
    } as const),
  setKs2SectionAttachments: (ks2Id: number, sectionId: number, attachments: IAttachmentInExpenditure[]) =>
    ({
      type: "passports/SET_KS2_SECTION_ATTACHMENTS",
      payload: { sectionId, attachments, ks2Id },
    } as const),
};

export type IPassportActions = IActions<typeof passportsActions>;
