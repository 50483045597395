import React from 'react';
import {ISharingTicketProps} from './TicketSharedCircle'

const TicketAcceptedCircle: React.FC<ISharingTicketProps> = ({backgroundColor = '#00C1AB', signColor= 'white', className}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M8 0C10.1217 0 12.156 0.843459 13.6562 2.34375C15.1565 3.84404 16 5.87827 16 8C16 10.1217 15.1565 12.1579 13.6562 13.6582C12.156 15.1585 10.1217 16 8 16C5.87827 16 3.84404 15.1585 2.34375 13.6582C0.843459 12.1579 0 10.1217 0 8C0 5.87827 0.843459 3.84404 2.34375 2.34375C3.84404 0.843459 5.87827 0 8 0Z" fill={backgroundColor}/>
      <path d="M6.18597 12.0755L2.65497 8.54448L3.64496 7.55446L6.18597 10.0955L12.3539 3.92747L13.3439 4.91747L6.18597 12.0755Z" fill={signColor}/>
    </svg>

  )
}

export default TicketAcceptedCircle