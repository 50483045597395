export default (
  <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.382 3.63417C10.3366 3.52461 10.2587 3.43165 10.1587 3.36792C10.0587 3.30418 9.94147 3.27275 9.82299 3.27789C9.70451 3.28302 9.59048 3.32448 9.49636 3.39663C9.40224 3.46878 9.33259 3.56813 9.29687 3.68121C9.26114 3.79429 9.26107 3.91563 9.29665 4.02876C9.33223 4.14188 9.40174 4.24133 9.49576 4.3136C9.58979 4.38587 9.70377 4.42748 9.82224 4.43277C9.94071 4.43806 10.0579 4.40678 10.158 4.34317C10.2721 4.26955 10.3568 4.15844 10.3977 4.02902C10.4386 3.89959 10.4331 3.75995 10.382 3.63417Z"
      fill="#404040"
    />
    <path
      d="M13.6173 26.3208H2.45625C2.012 26.3203 1.58612 26.1435 1.27208 25.8293C0.95804 25.515 0.781515 25.0891 0.78125 24.6448V5.8098C0.781515 5.36556 0.95804 4.93956 1.27208 4.62534C1.58612 4.31111 2.012 4.13433 2.45625 4.13381H6.13925C6.27674 3.24893 6.7261 2.44238 7.40619 1.8598C8.08628 1.27723 8.95226 0.957031 9.84775 0.957031C10.7432 0.957031 11.6092 1.27723 12.2893 1.8598C12.9694 2.44238 13.4188 3.24893 13.5562 4.13381H17.2393C17.6833 4.1346 18.109 4.3115 18.4228 4.62569C18.7366 4.93989 18.913 5.36573 18.9132 5.8098V14.6748H17.7563V5.8098C17.756 5.67268 17.7015 5.54123 17.6046 5.44417C17.5077 5.34711 17.3764 5.29233 17.2393 5.2918H15.6132V7.4828C15.6132 7.55884 15.5983 7.63413 15.5692 7.70438C15.5401 7.77463 15.4974 7.83845 15.4437 7.89222C15.3899 7.94599 15.3261 7.98863 15.2558 8.01773C15.1856 8.04683 15.1103 8.06181 15.0343 8.06181H4.66025C4.50669 8.06181 4.35942 8.0008 4.25084 7.89222C4.14225 7.78364 4.08125 7.63636 4.08125 7.4828V5.2918H2.45625C2.31895 5.29207 2.18735 5.34673 2.09026 5.44382C1.99317 5.5409 1.93851 5.6725 1.93825 5.8098V24.6458C1.93851 24.783 1.99321 24.9145 2.09032 25.0114C2.18744 25.1084 2.31904 25.1628 2.45625 25.1628H13.6173V26.3198V26.3208ZM5.23925 5.29281V6.90481H14.4563V5.29281H13.0212C12.8682 5.29073 12.7219 5.22892 12.6138 5.12057C12.5056 5.01222 12.4441 4.8659 12.4423 4.71281C12.4423 4.02411 12.169 3.36354 11.6825 2.87609C11.196 2.38864 10.5359 2.11413 9.84725 2.1128C9.16011 2.1206 8.5033 2.39703 8.01739 2.88294C7.53147 3.36886 7.25504 4.02566 7.24725 4.71281C7.24544 4.8659 7.18389 5.01222 7.07572 5.12057C6.96756 5.22892 6.82134 5.29073 6.66825 5.29281H5.23925Z"
      fill="#404040"
    />
    <path
      d="M4.7494 11.3103H15.5164C15.67 11.3103 15.8172 11.2493 15.9258 11.1408C16.0344 11.0322 16.0954 10.8849 16.0954 10.7313C16.0954 10.5778 16.0344 10.4305 15.9258 10.3219C15.8172 10.2133 15.67 10.1523 15.5164 10.1523H4.7494C4.59584 10.1523 4.44858 10.2133 4.34 10.3219C4.23141 10.4305 4.17041 10.5778 4.17041 10.7313C4.17041 10.8849 4.23141 11.0322 4.34 11.1408C4.44858 11.2493 4.59584 11.3103 4.7494 11.3103Z"
      fill="#404040"
    />
    <path
      d="M12.4674 14.2118C12.4674 14.1358 12.4525 14.0605 12.4234 13.9902C12.3943 13.92 12.3516 13.8562 12.2979 13.8024C12.2441 13.7486 12.1803 13.706 12.11 13.6769C12.0398 13.6478 11.9645 13.6328 11.8884 13.6328H4.74844C4.59488 13.6328 4.4476 13.6938 4.33902 13.8024C4.23044 13.911 4.16943 14.0583 4.16943 14.2118C4.16943 14.3654 4.23044 14.5126 4.33902 14.6212C4.4476 14.7298 4.59488 14.7908 4.74844 14.7908H11.8884C12.042 14.7908 12.1893 14.7298 12.2979 14.6212C12.4064 14.5126 12.4674 14.3654 12.4674 14.2118Z"
      fill="#404040"
    />
    <path
      d="M4.66006 18.4197H11.7281C11.8816 18.4197 12.0289 18.3587 12.1375 18.2501C12.246 18.1415 12.3071 17.9943 12.3071 17.8407C12.3071 17.6872 12.246 17.5399 12.1375 17.4313C12.0289 17.3227 11.8816 17.2617 11.7281 17.2617H4.66006C4.5065 17.2617 4.35922 17.3227 4.25064 17.4313C4.14206 17.5399 4.08105 17.6872 4.08105 17.8407C4.08105 17.9943 4.14206 18.1415 4.25064 18.2501C4.35922 18.3587 4.5065 18.4197 4.66006 18.4197Z"
      fill="#404040"
    />
    <path
      d="M9.08605 20.4297H4.66006C4.5065 20.4297 4.35922 20.4907 4.25064 20.5993C4.14206 20.7079 4.08105 20.8551 4.08105 21.0087C4.08105 21.1623 4.14206 21.3095 4.25064 21.4181C4.35922 21.5267 4.5065 21.5877 4.66006 21.5877H9.08605C9.23961 21.5877 9.38688 21.5267 9.49547 21.4181C9.60405 21.3095 9.66505 21.1623 9.66505 21.0087C9.66505 20.8551 9.60405 20.7079 9.49547 20.5993C9.38688 20.4907 9.23961 20.4297 9.08605 20.4297Z"
      fill="#404040"
    />
    <path
      d="M12.2633 20.791C12.2181 20.6819 12.1406 20.5892 12.0412 20.5253C11.9417 20.4615 11.8251 20.4296 11.707 20.434C11.589 20.4383 11.475 20.4787 11.3806 20.5497C11.2861 20.6207 11.2156 20.7189 11.1786 20.8311C11.1415 20.9433 11.1397 21.0642 11.1734 21.1774C11.207 21.2907 11.2745 21.391 11.3668 21.4648C11.4591 21.5386 11.5717 21.5824 11.6896 21.5903C11.8075 21.5982 11.925 21.5699 12.0263 21.5091C12.1447 21.4371 12.2336 21.3253 12.277 21.1937C12.3205 21.0621 12.3156 20.9194 12.2633 20.791Z"
      fill="#404040"
    />
    <path
      d="M12.437 22.1213C12.4366 20.5703 12.8962 19.0541 13.7575 17.7642C14.6189 16.4744 15.8434 15.469 17.2762 14.875C18.7089 14.2811 20.2857 14.1254 21.8069 14.4276C23.3282 14.7298 24.7257 15.4764 25.8227 16.5728C26.9197 17.6692 27.667 19.0664 27.9699 20.5875C28.2729 22.1086 28.118 23.6854 27.5248 25.1185C26.9317 26.5516 25.9268 27.7766 24.6375 28.6386C23.3481 29.5006 21.832 29.961 20.281 29.9613C18.2018 29.96 16.208 29.1337 14.7373 27.6638C13.2667 26.194 12.4394 24.2006 12.437 22.1213ZM13.605 22.1213C13.6052 23.442 13.9971 24.733 14.731 25.8309C15.4649 26.9289 16.508 27.7845 17.7282 28.2897C18.9485 28.7948 20.2911 28.9267 21.5863 28.6687C22.8816 28.4107 24.0712 27.7744 25.0048 26.8403C25.9384 25.9061 26.5739 24.7161 26.8312 23.4208C27.0884 22.1254 26.9557 20.7828 26.4498 19.5629C25.9439 18.3429 25.0877 17.3004 23.9893 16.5671C22.8909 15.8338 21.5997 15.4428 20.279 15.4433C18.5085 15.4447 16.8109 16.1487 15.5591 17.4009C14.3074 18.653 13.6038 20.3508 13.603 22.1213H13.605ZM18.613 25.8593C18.523 25.8367 18.4413 25.7893 18.377 25.7223L16.269 23.5543C16.2218 23.5077 16.1846 23.452 16.1596 23.3905C16.1347 23.3291 16.1225 23.2632 16.1238 23.1968C16.1251 23.1305 16.1399 23.0652 16.1673 23.0047C16.1946 22.9443 16.234 22.8901 16.283 22.8453C16.3787 22.7535 16.5066 22.7029 16.6391 22.7044C16.7717 22.7059 16.8985 22.7594 16.992 22.8533L18.709 24.6233L23.13 18.5813C23.2104 18.4764 23.3287 18.4071 23.4596 18.3884C23.5905 18.3697 23.7235 18.4031 23.83 18.4813C23.8832 18.5201 23.9283 18.5689 23.9626 18.6251C23.9969 18.6813 24.0197 18.7437 24.0299 18.8087C24.04 18.8737 24.0372 18.9401 24.0216 19.0041C24.006 19.068 23.9779 19.1283 23.939 19.1813L19.187 25.6813C19.1395 25.7453 19.0777 25.7971 19.0064 25.8327C18.9352 25.8683 18.8566 25.8867 18.777 25.8863C18.7205 25.8869 18.6644 25.8778 18.611 25.8593H18.613Z"
      fill="#404040"
    />
  </svg>
);
