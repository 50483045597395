import React from "react";

import styles from "./JournalRowIndicator.module.scss";

interface IProps {
  title: string;
  value: React.ReactNode;
}

const JournalRowIndicator: React.FC<IProps> = ({ value, title }) => {
  return (
    <div className={styles.container}>
      <div className={styles.value}>{value}</div>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default React.memo(JournalRowIndicator);
