import cn from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  invalidateKeyInJournalExecSelector,
  journalFulfillmentContentOfExpendituresSelector,
  journalFulfillmentMaterialsInvalidateKeySelector,
} from "redux/modules/common/building/journal/journalExecution/selectors";
import { getJournalFulfillmentContentOfExpenditure } from "redux/modules/common/building/journal/journalExecution/thunksV2";
import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";
import { generateJornalTicketSectionKey } from "redux/modules/common/building/journal/utils";

import RelatedMaterial from "../../../JournalExecTicket/components/RelatedMaterial/RelatedMaterial";
import RelatedMims from "../../../JournalExecTicket/components/RelatedMims/RelatedMims";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { useCompletedTicket } from "components/pages/Journal/components/hooks/useCompletedTicket";

import { LOCALIZATION_CONFIG } from "constants/localization";
import { IStockUsingMaterial } from "types/interfaces/Materials";

import styles from "./JournalExecRowContent.module.scss";

interface IProps {
  ticket: IExpendituresInJournalFulfillment["items"][0];
  year: number;
  month: number;
  sectionId: number;
}

const JournalExecRowContent: React.FC<IProps> = ({ ticket, year, month, sectionId }) => {
  const objectId = useObjectId();
  const dispatch = useDispatch();
  const ticketItem = ticket.expenditure;
  const key = generateJornalTicketSectionKey(objectId, year, month, ticket.expenditure?.expenditure_id);
  const content = useSelector(journalFulfillmentContentOfExpendituresSelector)[key];
  const materialsInvalidateKey = useSelector(journalFulfillmentMaterialsInvalidateKeySelector);
  const journalInvalidateKey = useSelector(invalidateKeyInJournalExecSelector);

  React.useLayoutEffect(() => {
    if (!ticket.expenditure?.expenditure_id) return;
    dispatch(getJournalFulfillmentContentOfExpenditure(objectId, ticket.expenditure?.expenditure_id, year, month));
  }, [key, materialsInvalidateKey, journalInvalidateKey]);

  return (
    <div className={styles.content}>
      {!!content?.materials?.length && (
        <div className={styles.headerMaterial}>
          <div className={cn(styles.headerItem, styles.name)}>Наименование материала</div>
          <div className={cn(styles.headerItem, styles.measure)}>Ед.изм.</div>
          <div className={cn(styles.headerItem, styles.centeredContent)}>К сдаче</div>
          <div className={cn(styles.headerItem, styles.centeredContent)}>Принято</div>
          <div className={cn(styles.headerItem, styles.centeredContent)}>ФИО</div>
          <div className={cn(styles.headerItem, styles.checkBlock, styles.centeredContent)}>
            Стоимость, {LOCALIZATION_CONFIG.currency}
          </div>
        </div>
      )}
      {content?.materials?.map((material, i) => (
        <RelatedMaterial
          key={material.name + i}
          materialData={material}
          objectId={objectId}
          expenditureId={ticketItem?.expenditure_id!}
        />
      ))}
      {!!content?.services?.length && (
        <>
          <div className={styles.headerMaterial}>
            <div className={cn(styles.headerItem, styles.name)}>Машины и Механизмы</div>
            <div className={cn(styles.headerItem, styles.measure)}>Ед.изм.</div>
            <div className={cn(styles.headerItem, styles.centeredContent)}>К сдаче</div>
            <div className={cn(styles.headerItem, styles.centeredContent)} />
            <div className={cn(styles.headerItem, styles.centeredContent)} />
            <div className={cn(styles.headerItem, styles.checkBlock, styles.centeredContent)}>
              Стоимость, {LOCALIZATION_CONFIG.currency}
            </div>
          </div>
          {content?.services?.map((mim, i) => (
            <RelatedMims key={mim.name + i} data={mim} />
          ))}
        </>
      )}
    </div>
  );
};

export default JournalExecRowContent;
