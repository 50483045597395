import React, { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";

import { tasksAggregationsSelector } from "components/pages/Tasks/model/selectors";
import { getTasksAggregations } from "components/pages/Tasks/model/thunks";
import { ItaskAggregationsParams } from "components/pages/Tasks/model/types";

import { useTypedSelector } from "app/store/typedUseSelector";

import { TASK_LISTS_TYPES, tasksAggregationSectionsEnum } from "../constants";

export const useTaskAggregations = (type: TASK_LISTS_TYPES, building?: number) => {
  const dispatch = useDispatch();
  const aggregations = useTypedSelector(tasksAggregationsSelector);
  const section = type !== TASK_LISTS_TYPES.LISTS ? type : tasksAggregationSectionsEnum.tasks_lists;
  const building_id = !isNaN(building!) && building !== 0 ? building : undefined;
  const invalidateKey = useTypedSelector((state) => state.tasksV2Reducer.invalidationKey);

  useLayoutEffect(() => {
    const params: ItaskAggregationsParams = {
      section: section as tasksAggregationSectionsEnum,
      building_id,
    };
    dispatch(getTasksAggregations(params));
  }, [type, building, invalidateKey]);

  return aggregations[`${section}_${building_id || 0}`] || {};
};
