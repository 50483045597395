import cn from "classnames";
import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ConfirmSelect from "../../../../../../../../../../../_LEGACY/UI/__trash/ConfirmSelect/ConfirmSelect";

import ConfirmModal from "../../../../../../../../../../../entities/ConfirmationModal/ConfirmModal";
import Spinner from "../../../../../../../../../../../shared/ui/atoms/Spinner/Spinner";
import {
  deletingExpendituresIdsSelector,
  expendituresSelector,
  modeSelector,
} from "../../../../../../../model/selectors";
import { simpleHandlerModel } from "../../../../../../../model/thunks";
import { BudgetColumns } from "./BudgetColumns/BudgetColumns";
import { EstimatedPriceColumns } from "./EstimatedPriceColumns/EstimatedPriceColumns";
import { MarkupColumns } from "./MarkupColumns/MarkupColumns";

import { IExpenditure } from "../../../../../../../../../../../types/interfaces/Expenditure";
import { ESimpleHandlerMode } from "../../../../../../../types";

import { useExpenditurePermissions } from "../../../../../../../hooks/useExpenditurePermissions";

import { transformDigitToFinancial } from "../../../../../../../../../../../utils/formatters/transformDigitToFinancial";

import { ReactComponent as EditIcon } from "shared/assets/icons/EditIcon.svg";
import { ReactComponent as CloseIcon } from "shared/assets/icons/IconClose.svg";

import styles from "../Expenditure.module.scss";

interface IProps {
  expenditure: IExpenditure;
  canConfirm?: boolean;
  onConfirm?: (id: number) => void;
  onCancel?: (id: number) => void;
  isStatusUpdating?: boolean;
}

export const ExpenditureColumns = memo<IProps>(({ expenditure, canConfirm, onConfirm, onCancel, isStatusUpdating }) => {
  const dispatch = useDispatch();

  const mode = useSelector(modeSelector);
  const deletingExpendituresIds = useSelector(deletingExpendituresIdsSelector);
  const isDeleting = deletingExpendituresIds.includes(expenditure.id);
  const currentExpenditures = useSelector(expendituresSelector);

  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

  const resourcesCount = (currentExpenditures ?? []).filter(
    (e) => (e.related_work_id ?? e.related_work?.id) === expenditure.id
  ).length;

  const handleEdit = useCallback(() => {
    dispatch(simpleHandlerModel.startEditingExpenditure(expenditure.id));
  }, [dispatch, expenditure.id]);

  const handleDelete = useCallback(() => {
    dispatch(simpleHandlerModel.deleteExpenditure(expenditure.id));
  }, [dispatch, expenditure.id]);

  const { canEdit, canDelete } = useExpenditurePermissions(expenditure);

  const isAllForbidden = !canEdit && !canDelete;

  if (mode === ESimpleHandlerMode.VIEW) {
    return (
      <>
        <div className={cn(styles.column, styles.isCentered)}>
          {expenditure.count} ({expenditure.measure})
        </div>
        <div className={cn(styles.column, styles.isCentered)}>{transformDigitToFinancial(expenditure.price)}</div>
        <div className={cn(styles.column, styles.isCentered)}>
          {transformDigitToFinancial(expenditure.indicators?.estimate_amount)}
        </div>
        <div className={cn(styles.column, styles.isCentered, canConfirm && styles.canConfirm)}>
          {canConfirm ? (
            <ConfirmSelect
              bordered
              onConfirm={() => onConfirm?.(expenditure.id)}
              onCancel={() => onCancel?.(expenditure.id)}
              isLoading={isStatusUpdating}
            />
          ) : isAllForbidden ? null : (
            <div className={styles.buttons}>
              {canEdit && (
                <button type="button" className={styles.editButton} onClick={handleEdit} disabled={isDeleting}>
                  <EditIcon className={styles.editIcon} />
                </button>
              )}
              {canDelete &&
                (isDeleting ? (
                  <Spinner
                    isStatic
                    className={styles.deletingSpinnerContainer}
                    spinnerClassName={styles.deletingSpinner}
                  />
                ) : (
                  <CloseIcon className={styles.close} onClick={() => setIsDeleteModalOpened(true)} />
                ))}
            </div>
          )}
        </div>

        {isDeleteModalOpened && (
          <ConfirmModal variant="secondary" isOpen onClose={() => setIsDeleteModalOpened(false)} action={handleDelete}>
            <span>
              Вы действительно хотите удалить позицию?
              <br />
              {!!resourcesCount && `Связанные ресурсы (${resourcesCount} шт) тоже будут удалены.`}
            </span>
          </ConfirmModal>
        )}
      </>
    );
  }

  if (mode === ESimpleHandlerMode.BUDGET) {
    return <BudgetColumns expenditure={expenditure} />;
  }

  if (mode === ESimpleHandlerMode.ESTIMATED_PRICE) {
    return <EstimatedPriceColumns expenditure={expenditure} />;
  }

  if (mode === ESimpleHandlerMode.MARKUP) {
    return <MarkupColumns expenditure={expenditure} />;
  }

  return null;
});
