import React from 'react';

export default function MinusBoxIcon({ width=15 , height, color='#d1d1d1', fill='#7c7c7c'}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={width}
      height="15" 
      viewBox="0 0 15 15"
      name="minus"
    >
      <defs>
      </defs>
      <g name='a' fill='none' stroke={color}>
        <rect 
          stroke='none'
          width="15" 
          height="15" 
          rx="2"
        />
        <rect 
          fill='none'
          x="0.5" 
          y="0.5" 
          width="14" 
          height="14" 
          rx="1.5"
        />
      </g>
      <path 
        name='b'
        fill={fill}
        d="M1.012-5.4q-.426,0-.426-.355v-.611q0-.355.426-.355H8.191q.426,0,.426.355v.611q0,.355-.426.355H1.012Z" 
        transform="translate(3.014 13.783)"
      />
    </svg>
  )
}