import React, { Fragment, useEffect, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch } from "react-router-dom";
import { LandingPagesRoutesEnum } from "../lib/landingPagesRoutesEnum";
import { HomePage } from "./HomePage/HomePage";
import { ForSitePage } from "./ForSitePage/ForSitePage";
import { ForOfficePage } from "./ForOfficePage/ForOfficePage";
import { PrivacyPolicyPage } from "./PrivacyPolicyPage/PrivacyPolicyPage"

import "./LandingPages.scss"
import { Header } from "./Header/Header";

import TagManager from 'react-gtm-module'

export const LandingPages = () => {
  useLayoutEffect(() => {
    document.body.classList.add('osla');

    TagManager.initialize({
      gtmId: 'GTM-NMQDKKMZ'
    });

    return () => {
      document.body.classList.remove('osla');
    }
  }, [])

  return (
    <Fragment>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
        />
        <meta
          httpEquiv="X-UA-Compatible"
          content="ie=edge"
        />
        <title>OSLA – Управление строительством</title>
      </Helmet>
      <Header />
      <Switch>
        <Route
          path={LandingPagesRoutesEnum.home}
          component={HomePage}
          exact
        />
        <Route
          path={LandingPagesRoutesEnum.forSite}
          component={ForSitePage}
          exact
        />
        <Route
          path={LandingPagesRoutesEnum.forOffice}
          component={ForOfficePage}
          exact
        />
        <Route
          path={LandingPagesRoutesEnum.privacyPolicy}
          component={PrivacyPolicyPage}
          exact
        />
        <Redirect to={LandingPagesRoutesEnum.home} />
      </Switch>
    </Fragment>
  )
}