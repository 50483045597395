import { useEffect } from "react";

import { remToPx } from "utils/remToPx";

export const useRem = () => {
  let REM = remToPx(1);
  useEffect(() => {
    const recountRem = () => {
      REM = remToPx(1);
    };
    window.addEventListener("resize", recountRem);
    return () => window.removeEventListener("resize", recountRem);
  }, []);
  return {
    REM,
  };
};
