import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { actingActions } from "redux/modules/common/building/journal/acting/actions";
import { IDetailedKs3InActing } from "redux/modules/common/building/journal/acting/types";

import { useCheckIsConstructingControl } from "features/constructingControl";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const useConstrControlInActing = (ks2List: IDetailedKs3InActing, ks3Id: number) => {
  const dispatch = useDispatch();

  const isConstrControlEnabled = ks2List?.cc_confirmed !== undefined;
  const isConstrControlAccepted = ks2List?.cc_confirmed === true;
  const isConstrControl = useCheckIsConstructingControl();

  const [isPending, setIsPending] = useState(false);
  //TODO
  const sendToApprove = () => {
    setIsPending(true);
    axios
      .post(`construction_control/acts/ks3/${ks3Id}/send/`)
      .then(() => {
        dispatch(actingActions.invalidateCache());
      })
      .catch((err) => {
        errorCatcher(err);
      })
      .finally(() => {
        setIsPending(false);
      });
  };
  //TODO
  const approveByConstrControl = () => {
    setIsPending(true);
    axios
      .post(`construction_control/acts/ks3/${ks3Id}/accept/`)
      .then(() => {
        dispatch(actingActions.invalidateCache());
      })
      .catch((err) => {
        errorCatcher(err);
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  return {
    isConstrControlEnabled,
    isConstrControl,
    isConstrControlAccepted,
    sendToApprove,
    isPending,
    approveByConstrControl,
  };
};
