import { IDetailedKs3InActing } from "redux/modules/common/building/journal/acting/types";

import { IActData } from "./useCreateKs2";

export const checkCreatingKs2IsDirty = (
  actData: IActData,
  selectedSectionsCount: number[],
  selectedExpendituresCount: number[]
) => {
  if (!actData?.number) return false;
  if (!selectedSectionsCount?.length && !selectedExpendituresCount?.length) return false;
  return true;
};

export const checkCreatingKs3IsDirty = (actData: IActData, selectedSectionsCount: number[]) => {
  if (!actData?.number) return false;
  if (!selectedSectionsCount?.length) return false;
  return true;
};

export const checkEditingKs3IsDirty = (actData: IActData, deletingItems: number[], ks3Info: IDetailedKs3InActing) => {
  if (actData?.number !== ks3Info?.title) return true;
  if (actData?.dateEnd !== ks3Info?.end_at) return true;
  if (actData?.dateStart !== ks3Info?.start_at) return true;
  if (deletingItems?.length) return true;
  return false;
};
