import cn from "classnames";
import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";

import { LOCALIZATION_CONFIG } from "constants/localization";

import styles from "./KsListRow.module.scss";

interface IProps {
  onFilterChange: (name: string, value: string) => void;
}

const statusOptions = [
  {
    id: "todo",
    name: "К сдаче",
  },
  {
    id: "to_pay",
    name: "К оплате",
  },
  {
    id: "paid",
    name: "Оплачено",
  },
];

const KsListHead: React.FC<IProps> = ({ onFilterChange }) => {
  return (
    <TableReusableHead isSticky isWithEar className={cn(styles.row)}>
      <TableReusableHeaderCell>Отчетный период</TableReusableHeaderCell>
      <div />
      <TableReusableHeaderCell
        filterType="select"
        filterTitle="Статус"
        filterField="status"
        filterOptions={statusOptions}
        onChangeFilter={onFilterChange}
      >
        Статус
      </TableReusableHeaderCell>
      <div />
      <div />
      <TableReusableHeaderCell>
        <div className={styles.sumHead}>Сумма, {LOCALIZATION_CONFIG.currency} </div>
      </TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default React.memo(KsListHead);
