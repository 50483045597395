import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  groupTicketActingInfoIsLoadingSelector,
  groupTicketActingInfoResultsSelector,
  ticketActingInfoIsLoadingSelector,
  ticketActingInfoResultsSelector,
  ticketActingIsApprovingSelector,
} from "../../../../../../../../../redux/modules/common/building/journal/journalExecution/selectors";
import { contractsLoadingSelector } from "redux/modules/common/building/documents/contracts";
import { journalExecutionActions } from "redux/modules/common/building/journal/journalExecution/actions";
import {
  acceptGroupTicketToActing,
  acceptTicketToActing,
  getGroupTicketActingInfo,
  getTicketActingInfo,
} from "redux/modules/common/building/journal/journalExecution/thunks";
import {
  IExpendituresInJournalFulfillment,
  IGroupTicketActingInfo,
  ITicketActingExpenditure,
  ITicketActingInfo,
} from "redux/modules/common/building/journal/journalExecution/types";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { Spinner } from "../../../../../../../../../shared/ui/atoms/Spinner/Spinner";
import GroupTabs, { GroupTabsEnum } from "../GroupTabs/GroupTabs";
import AcceptTicketItemRow from "./AcceptTicketItemRow/AcceptTicketItemRow";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";
import Modal from "shared/ui/modal/Modal";

import { LOCALIZATION_CONFIG } from "constants/localization";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import Check from "images/icons/Check";

import styles from "./AcceptTicketModal.module.scss";

export interface IAcceptTicketData {
  year: number;
  month: number;
  work_count: string;
  materials: { count: string; expenditure: number }[];
  services: IAcceptTicketData["materials"];
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onApprove: AcceptTicket;
  ticket: IExpendituresInJournalFulfillment["items"][0];
  sectionId: number;
  year: number;
  month: number;
}

export interface IAcceptedExpenditure {
  expenditure: number;
  count: string;
}

export interface AcceptTicket {
  (data: {
    services: IAcceptedExpenditure[];
    materials: IAcceptedExpenditure[];
    expenditure: { isSelected: boolean; count: number };
  }): void;
}

let initialExpenditureCount: number | null = null;

const AcceptTicketModal: React.FC<IProps> = ({ isOpen, onClose, onApprove, sectionId, month, year, ticket }) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const isApproving = useSelector(ticketActingIsApprovingSelector);
  const ticketActingInfo = useSelector(ticketActingInfoResultsSelector);
  const groupTicketActingInfo = useSelector(groupTicketActingInfoResultsSelector);
  const isTicketActingInfoLoading = useSelector(ticketActingInfoIsLoadingSelector);
  const isGroupTicketActingInfoLoading = useSelector(groupTicketActingInfoIsLoadingSelector);

  const ticketItem = ticket.expenditure ?? ticket.group ?? ticket.linked;
  const itemId = (ticketItem as any)?.expenditure_id ?? (ticketItem as any)?.group_id;
  const isExpenditureGroup = ticket.type === "group";
  const actingInfo = ticketActingInfo;
  const groupActingInfo = useMemo(
    () => ({ ...(groupTicketActingInfo || {}), expenditure: ticket.group }),
    [groupTicketActingInfo, ticket]
  );
  const isLoading = isTicketActingInfoLoading ?? isGroupTicketActingInfoLoading ?? false;

  const [totalCount, setTotalCount] = useState<string>("0");

  const [selectedMaterials, setSelectedMaterials] = useState<number[]>([]);
  const [selectedServices, setSelectedServices] = useState<number[]>([]);

  const [materials, setMaterials] = useState<ITicketActingExpenditure[] | null>(null);
  const [services, setServices] = useState<ITicketActingExpenditure[] | null>(null);
  const [expenditures, setExpenditures] = useState<ITicketActingExpenditure[] | null>(null);

  const [isExpenditureSelected, setIsExpenditureSelected] = useState(false);
  const [expenditureAmount, setExpenditureAmount] = useState(0);

  const [activeGroupTabId, setActiveGroupTabId] = useState<string | null>(null);

  useEffect(() => {
    if (!isOpen) return;
    if (isExpenditureGroup) {
      dispatch(getGroupTicketActingInfo(objectId, itemId, year, month));
    } else {
      dispatch(getTicketActingInfo(objectId, itemId, year, month));
    }
  }, [objectId, year, month, itemId, isOpen]);

  const handleRemove = useCallback((id: number) => {
    dispatch(journalExecutionActions.removeOneTicketActingInfoData(id));
  }, []);

  const changeTotalCount = (count: string) => {
    if (!groupActingInfo && !actingInfo) return;

    setTotalCount(count); /* @ts-ignore */
    if (!isExpenditureGroup && actingInfo) setExpenditureAmount(+(+count * actingInfo.expenditure?.price).toFixed(4));
    if (!isExpenditureGroup) return;

    const price =
      Number(groupActingInfo?.expenditure?.count) === 0
        ? 0 /* @ts-ignore */
        : +(+count * (+groupActingInfo.expenditure?.amount / +groupActingInfo?.expenditure?.count)).toFixed(4);
    setExpenditureAmount(price);
    /* @ts-ignore */
    if (!initialExpenditureCount) initialExpenditureCount = +groupActingInfo.expenditure?.count;

    /* @ts-ignore */
    setExpenditures((prev) => {
      return prev?.map((expenditure) => {
        const expenditureCount = /* @ts-ignore */ (
          ((expenditure.cachedInitialCount ?? +expenditure.sum_accepted_count) * +count) /* @ts-ignore */ /
          initialExpenditureCount
        ).toFixed(4);

        return {
          ...expenditure,
          sum_accepted_count: expenditureCount,
          sum_accepted_amount: expenditure.price * +expenditureCount,
          cachedInitialCount:
            /* @ts-ignore */
            expenditure.cachedInitialCount === undefined
              ? expenditure.sum_accepted_count /* @ts-ignore */
              : expenditure.cachedInitialCount,
        };
      });
    });
  };

  const changeMaterialsCount = useCallback((count: string, id: number) => {
    setMaterials((prevState) => {
      if (!prevState) return prevState;
      return prevState.map((material) =>
        material.id === id
          ? {
              ...material,
              sum_accepted_count: count,
              sum_accepted_amount: (+count * material.price).toFixed(4),
            }
          : material
      );
    });
  }, []);

  const changeServicesCount = useCallback((count: string, id: number) => {
    setServices((prevState) => {
      if (!prevState) return prevState;
      return prevState.map((service) =>
        service.id === id
          ? {
              ...service,
              sum_accepted_count: count,
              sum_accepted_amount: (+count * service.price).toFixed(4),
            }
          : service
      );
    });
  }, []);

  const tabsDisplaying = useMemo(() => {
    const materials = isExpenditureGroup ? groupTicketActingInfo.materials : ticketActingInfo?.materials;
    const services = isExpenditureGroup ? groupTicketActingInfo.services : ticketActingInfo?.services;

    return {
      [GroupTabsEnum.expenditures]: !!isExpenditureGroup,
      [GroupTabsEnum.materials]: !!materials && materials.length > 0,
      [GroupTabsEnum.mims]: !!services && services.length > 0,
    };
  }, [ticketActingInfo, groupTicketActingInfo, isOpen, isExpenditureGroup]);

  const onCheckMaterial = useCallback(
    (id: number) => {
      const material = materials?.find((material) => material.id === id);
      if (!material) return;

      setSelectedMaterials((prev) => {
        if (prev.includes(id)) {
          return prev.filter((m) => m !== id);
        }
        return [...prev, id];
      });
    },
    [materials]
  );

  const onCheckService = useCallback(
    (id: number) => {
      const service = services?.find((material) => material.id === id);
      if (!service) return;

      setSelectedServices((prev) => {
        if (prev.includes(id)) {
          return prev.filter((m) => m !== id);
        }
        return [...prev, id];
      });
    },
    [services]
  );

  const isButtonDisabled = useMemo(
    () => selectedMaterials.length === 0 && selectedServices.length === 0 && !isExpenditureSelected,
    [selectedMaterials, selectedServices, isExpenditureSelected]
  );

  const onSelectExpenditure = useCallback(() => {
    setIsExpenditureSelected((prevState) => !prevState);
  }, [expenditureAmount]);

  const handleApprove = useCallback(() => {
    onApprove({
      materials: materials
        ? materials
            .filter((material) => selectedMaterials.includes(material.id))
            .map((material) => ({
              expenditure: material.id,
              count: material.sum_accepted_count,
            }))
        : [],
      services: services
        ? services
            .filter((service) => selectedServices.includes(service.id))
            .map((service) => ({
              expenditure: service.id,
              count: service.sum_accepted_count,
            }))
        : [],
      expenditure: { isSelected: isExpenditureSelected, count: +totalCount },
    });
  }, [selectedServices, services, materials, selectedMaterials, isExpenditureSelected, totalCount]);

  const totalAmount = useMemo(() => {
    let result = 0;

    if (services) {
      result += services
        .filter((service) => selectedServices.includes(service.id))
        .reduce((acc, service) => acc + +service.sum_accepted_amount, 0);
    }
    if (materials) {
      result += materials
        .filter((material) => selectedMaterials.includes(material.id))
        .reduce((acc, material) => acc + +material.sum_accepted_amount, 0);
    }

    if (isExpenditureSelected && isExpenditureGroup) {
      if (expenditures) {
        result += expenditures.reduce((acc, expenditure) => acc + +expenditure.sum_accepted_amount, 0);
      }
      return result;
    }

    if (isExpenditureSelected) {
      result += expenditureAmount;
    }

    return result;
  }, [services, materials, expenditureAmount, selectedMaterials, selectedServices, isExpenditureSelected]);

  useEffect(() => {
    const displayTabs = Object.entries(tabsDisplaying).filter(([_tab, isDisplay]) => isDisplay);
    setActiveGroupTabId(displayTabs.length ? displayTabs[0][0] : GroupTabsEnum.expenditures);
  }, [tabsDisplaying]);

  useEffect(() => {
    if (!isOpen) return;
    if (groupActingInfo && groupActingInfo.expenditure) {
      setTotalCount(groupActingInfo.expenditure.count);
      setExpenditureAmount(+groupActingInfo.expenditure.amount);
    } else if (actingInfo && actingInfo.expenditure) {
      setTotalCount(actingInfo.expenditure.sum_accepted_count);
      setExpenditureAmount(+actingInfo.expenditure.sum_accepted_count * +actingInfo.expenditure.price);
    } else {
      setTotalCount("0");
    }
    return () => {
      setExpenditureAmount(0);
      setSelectedMaterials([]);
      setSelectedServices([]);
      setIsExpenditureSelected(false);
    };
  }, [actingInfo, groupActingInfo, isOpen]);

  useEffect(() => {
    if (groupTicketActingInfo && isExpenditureGroup) {
      setMaterials(groupTicketActingInfo.materials);
      setServices(groupTicketActingInfo.services);
      setExpenditures(groupTicketActingInfo.expenditures);
    } else if (ticketActingInfo && !isExpenditureGroup) {
      setMaterials(ticketActingInfo.materials);
      setServices(ticketActingInfo.services);
    }
  }, [ticketActingInfo, groupTicketActingInfo, isExpenditureGroup]);

  const acceptTicket: AcceptTicket = ({ services, materials, expenditure: afterAcceptingExpenditure }) => {
    const currentActingInfo = actingInfo;
    const acceptedActingInfo = {
      ...currentActingInfo,
      expenditure: {
        ...ticketItem,
        sum_accepted_count: afterAcceptingExpenditure.isSelected ? afterAcceptingExpenditure.count : 0,
      },
      services,
      materials,
    };

    if (!isExpenditureGroup) {
      /* @ts-ignore */
      dispatch(acceptTicketToActing(objectId, itemId, year, month, sectionId, acceptedActingInfo));
    } else {
      /* @ts-ignore */
      dispatch(acceptGroupTicketToActing(objectId, itemId, year, month, sectionId, acceptedActingInfo));
    }
  };

  if (!ticketItem) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Подтвердить перевод ${!!isExpenditureGroup ? "группы " : ""}в актирование`}
      className={styles.modal}
    >
      <TableReusableHead className={styles.head}>
        <TableReusableHeaderCell>
          {!!isExpenditureGroup ? "Наименование группы расценок" : "Наименование работы"}
        </TableReusableHeaderCell>
        <TableReusableHeaderCell>Кол-во</TableReusableHeaderCell>
        <TableReusableHeaderCell>Итого, {LOCALIZATION_CONFIG.currency}</TableReusableHeaderCell>
        <TableReusableHeaderCell isCentered>Подтвердить</TableReusableHeaderCell>
      </TableReusableHead>
      {!isLoading && activeGroupTabId && ticketItem ? (
        <>
          <div className={styles.content}>
            {Object.keys(ticketItem).length > 0 && (
              <AcceptTicketItemRow
                id={itemId}
                title={ticketItem.name}
                count={totalCount}
                measure={ticketItem.measure}
                amount={expenditureAmount}
                changeCountValue={changeTotalCount}
                isChecked={isExpenditureSelected}
                onCheck={onSelectExpenditure}
                canChangeCount
                canCheck
                isHeading
              />
            )}
            <GroupTabs
              tabsDisplaying={tabsDisplaying}
              activeGroupTabId={activeGroupTabId}
              setActiveGroupTabId={setActiveGroupTabId}
              className={styles.tabs}
            />
            {activeGroupTabId === GroupTabsEnum.expenditures &&
              expenditures?.map((expenditure) => (
                <AcceptTicketItemRow
                  key={expenditure.id}
                  id={expenditure.id}
                  title={expenditure.name}
                  count={expenditure.sum_accepted_count}
                  measure={expenditure.measure}
                  amount={+expenditure.sum_accepted_amount}
                  justification={expenditure.justification}
                  className={styles.expenditureRow}
                />
              ))}
            {activeGroupTabId === GroupTabsEnum.materials &&
              materials?.map((item) => (
                <AcceptTicketItemRow
                  key={item.id}
                  id={item.id}
                  title={item.name}
                  count={item.sum_accepted_count}
                  measure={item.measure}
                  amount={+item.sum_accepted_amount}
                  changeCountValue={changeMaterialsCount}
                  isChecked={selectedMaterials.includes(item.id)}
                  onCheck={onCheckMaterial}
                  canChangeCount
                  canCheck
                  className={styles.materialsAndServicesRow}
                />
              ))}
            {activeGroupTabId === GroupTabsEnum.mims &&
              services?.map((item) => (
                <AcceptTicketItemRow
                  key={item.id}
                  id={item.id}
                  title={item.name}
                  count={item.sum_accepted_count}
                  measure={item.measure}
                  amount={+item.sum_accepted_amount}
                  changeCountValue={changeServicesCount}
                  isChecked={selectedServices.includes(item.id)}
                  onCheck={onCheckService}
                  canChangeCount
                  canCheck
                  className={styles.materialsAndServicesRow}
                />
              ))}
          </div>
          <div className={styles.total}>
            <span className={styles.totalText}>Итого в актирование будет передано:</span>
            <span className={styles.totalAmount}>
              {transformDigitToFinancial(totalAmount, {
                withCurrencySign: true,
                withFloat: false,
              })}
            </span>
          </div>
          <BottomControls isDoubleBtns isExists className={styles.bottomControls}>
            <ButtonBase secondary onClick={onClose}>
              Отменить
            </ButtonBase>
            <ButtonBase disabled={isButtonDisabled} onClick={handleApprove} primary isLoading={isApproving}>
              <Check />
              Подтвердить
            </ButtonBase>
          </BottomControls>
        </>
      ) : (
        <Spinner isFixed />
      )}
    </Modal>
  );
};

export default React.memo(AcceptTicketModal);
