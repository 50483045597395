import moment from "moment";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

const digitInMeasure = (digit: number | string, measure: number) => Math.round((+digit * 100) / measure) / 100;

export const computeCost = (value: number | string | undefined, measure: number) => {
  if (value === undefined) return "";
  return transformDigitToFinancial(digitInMeasure(value, measure), { withFloat: true });
};
export const costToRubs = (value: number | string | undefined, measure: number) => {
  if (value === undefined) return "";
  return transformDigitToFinancial(digitInMeasure(value, measure), { withFloat: true, withCurrencySign: true });
};

export const GEAR_COEFFICIENT = 0.83;

export const MONTH_WIDTH_PERCENT = 20.5;

export const MONTH_PERCENTS = [
  0,
  1 / 12,
  2 / 12,
  3 / 12,
  4 / 12,
  5 / 12,
  6 / 12,
  7 / 12,
  8 / 12,
  9 / 12,
  10 / 12,
  11 / 12,
].map((x) => Math.round(100 * x));

export const getTableScrollPercentByMonth = (monthId: number) =>
  (Math.max(0, monthId - 1) * MONTH_WIDTH_PERCENT) / GEAR_COEFFICIENT / 3;

export const TABLE_INITIAL_SCROLL_PERCENT = getTableScrollPercentByMonth(moment().month());
