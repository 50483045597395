import cn from "classnames";
import React, { useCallback } from "react";

import Offer from "./components/Offer/OfferItem";

import { LOCALIZATION_CONFIG } from "constants/localization";

import { getIdentifier } from "../../../../utils/helpers/getIdentifier";

import PlusFillCircleIcon from "../../../../images/icons/PlusFillCircleIcon";

import styles from "./index.module.scss";

interface IProps {
  offers: any[];
  setOffers: any;
  kitRequestsMeasures: any;
  requestMeasure: any;
  createOffer: any;
  createOfferSupply: any;
  permissions: Record<string, boolean>;
  showCountRequestMeasure: any;
}

const RequestOffers: React.FC<IProps> = ({
  offers,
  setOffers,
  kitRequestsMeasures,
  requestMeasure,
  createOffer,
  createOfferSupply,
  permissions,
  showCountRequestMeasure,
}) => {
  const setOffer = useCallback(
    (setValue: any) => {
      return setOffers(offers.map((offer) => (getIdentifier(offer) === getIdentifier(setValue) ? setValue : offer)));
    },
    [offers, setOffers]
  );

  const deleteOffer = useCallback(
    (deletedOfferId: number) => {
      return setOffers(offers.filter((offer) => getIdentifier(offer) !== deletedOfferId));
    },
    [offers, setOffers]
  );

  const setOfferSupply = (targetOffer: any, setSupply: any) => {
    return setOffer({
      ...targetOffer,
      supplies: targetOffer.supplies.map((supply: any) =>
        getIdentifier(supply) === getIdentifier(setSupply) ? setSupply : supply
      ),
    });
  };

  const deleteOfferSupply = (targetOffer: any, deletedSupplyId: number) => {
    return setOffer({
      ...targetOffer,
      supplies: targetOffer.supplies.filter((supply: any) => getIdentifier(supply) !== deletedSupplyId),
    });
  };

  const handleCreateOfferSupply = (offer: any) => () => {
    return createOfferSupply(offer);
  };

  return (
    <div
      className={cn(styles.requestOffers, {
        [styles.withCountRequestMeasure]: showCountRequestMeasure,
        [styles.canNotEdit]: !permissions.editRequest,
      })}
    >
      <div className={styles.verticalText}>Предложение</div>
      <div className={styles.rows}>
        <header className={styles.header}>
          <span className={styles.nameColumn}>
            Предложение
            {permissions.editRequest && <PlusFillCircleIcon className={styles.createOfferIcon} onClick={createOffer} />}
          </span>
          <span className={styles.dateColumn}>Поставка</span>
          <span className={styles.countColumn}>Кол-во</span>
          <span className={styles.measureColumn} />
          {showCountRequestMeasure && <span className={styles.countColumn}>Кол-во в ед.изм</span>}
          <span className={styles.priceColumn}>Цена за ед. {LOCALIZATION_CONFIG.currency}</span>
          <span className={styles.sumColumn}>Сумма {LOCALIZATION_CONFIG.currency}</span>
          {!permissions.editRequest ? (
            permissions.viewInvoiceDifference && <span className={styles.differenceColumn}>Разница</span>
          ) : (
            <span className={styles.actionsColumn} />
          )}
        </header>
        {offers.map((offer, idx) => (
          <Offer
            offer={offer}
            setOffer={setOffer}
            requestMeasure={requestMeasure}
            kitRequestsMeasures={kitRequestsMeasures}
            deleteOffer={deleteOffer}
            createOfferSupply={handleCreateOfferSupply(offer)}
            setSupply={(supply: any) => setOfferSupply(offer, supply)}
            deleteSupply={(deletedSupplyId: number) => deleteOfferSupply(offer, deletedSupplyId)}
            permissions={permissions}
            key={idx}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(RequestOffers);
