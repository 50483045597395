import moment from "moment/moment";

import { LOCALIZATION_CONFIG } from "../../../../../constants/localization";

export const CHART_BASE_PROPS = {
  margin: { top: 0, right: 20, bottom: 0, left: 20 },
} as const;

export const GRID_BASE_PROPS = {
  strokeDasharray: "5 1",
} as const;

export const XAXIS_BASE_PROPS = {
  tickFormatter: (date: string | null) => (!!date ? moment(date).format("DD.MM") : ""),
  angle: 315,
  height: 55,
} as const;

export const YAXIS_BASE_PROPS = {
  type: "number",
  scale: "linear",
  min: 0,
  label: { value: `тыс. ${LOCALIZATION_CONFIG.currency}`, position: "insideBottomRight", dy: 10, dx: -20 },
  tickFormatter: (value: number | string) => parseFloat(value?.toString()) / 1_000,
} as const;

export const CHART_MAIN_ELEMENT_BASE_PROPS = {
  connectNulls: true,
  strokeWidth: 2,
} as const;

export const tooltipLabelFormatter = (date: string) => moment(date)?.format("DD/MM/YY");
