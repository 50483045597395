import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

import PlanFactExtraCostItem from "./PlanFactExtraCostItem/PlanFactExtraCostItem";
import { usePlanFactExtraCosts } from "./usePlanFactExtraCosts";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import BackNavigationBar, { IBackNavigationBarProps } from "shared/ui/layout/BackNavigationBar/BackNavigationBar";
import ListLayout from "shared/ui/layout/ListLayout/ListLayout";

const PlanFactExtraCostsSections = () => {
  const history = useHistory();
  const { extraCosts, isExtraCostsLoading, objectId } = usePlanFactExtraCosts();

  const navProps = useMemo<IBackNavigationBarProps>(() => {
    return {
      title: "План-факт",
      onBack: () => history.push(`/objects/${objectId}/plan-fact/finance/`),
    };
  }, []);

  return (
    <ListLayout<(typeof extraCosts)[number]>
      isLoading={isExtraCostsLoading}
      items={extraCosts}
      render={(el) => <PlanFactExtraCostItem key={el.id} name={el.name} count={el.count} pushPostfix={`${el.id}/`} />}
      backNavigationProps={navProps}
      isWithBackNavigation
    />
  );
};

export default PlanFactExtraCostsSections;
