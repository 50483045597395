import React from "react";

import RemarkDetailInfo from "../RemarkDetailInfo/RemarkDetailInfo";
import RemarkRegister from "../RemarkRegister/RemarkRegister";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import { useRemarkModal } from "../hooks/useRemarkModal";

import styles from "./RemarkModal.module.scss";

export interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  workName: string;
  remarksCount: number;
  expenditureId: number;
  isShowOnlyAccepted: boolean;
  defaultRemarkId?: number;
  isGroup?: boolean;
  isDelivery?: boolean;
}

const RemarkModal: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  workName,
  remarksCount,
  expenditureId,
  isShowOnlyAccepted,
  defaultRemarkId,
  isGroup,
  isDelivery,
}) => {
  const {
    closeModal,
    handleOpen,
    handleCloseDetailInfo,
    isOpenCurrentRemark,
    currentRemarkId,
    remarksList,
    isRemarksListLoading,
  } = useRemarkModal({
    isOpen,
    setIsOpen,
    expenditureId,
    isShowOnlyAccepted,
    defaultRemarkId,
    isGroup,
    isDelivery,
  });

  return (
    <SliderModal className={styles.modal} isOpen={isOpen} closeHandler={closeModal}>
      {remarksCount > 1 && !isOpenCurrentRemark ? (
        <RemarkRegister
          remarksCount={remarksCount}
          workName={workName}
          handleOpen={handleOpen}
          remarksList={remarksList}
          isRemarksListLoading={isRemarksListLoading}
        />
      ) : (
        <RemarkDetailInfo
          isOpen={isOpen}
          currentRemarkId={currentRemarkId as number}
          remarksCount={remarksCount}
          workName={workName}
          handleBack={handleCloseDetailInfo}
          closeModal={closeModal}
          isDelivery={isDelivery}
        />
      )}
    </SliderModal>
  );
};

export default React.memo(RemarkModal);
