import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { useTypedSelector } from "app/store/typedUseSelector";
import { getInstructionsRedirects } from "widgets/Help/Learning/model/thunks";

interface IProps {
  onSelect: (alias: string) => void;
  userId?: number;
  isOpen: boolean;
}

export const useInstructionsRedirect = ({ onSelect, userId, isOpen }: IProps) => {
  const dispatch = useDispatch();
  const { instructionsRedirects } = useTypedSelector((state) => state.learning);
  const { pathname } = useLocation();
  const [autoAlias, aetAutoAlias] = useState("");

  useEffect(() => {
    if (!userId) return;
    dispatch(getInstructionsRedirects(userId));
  }, [userId]);

  useEffect(() => {
    if (!isOpen) return;
    if (!instructionsRedirects) return;

    Object.entries(instructionsRedirects).forEach(([pattern, alias]) => {
      const reg = new RegExp(pattern);

      if (reg.exec(pathname)) {
        aetAutoAlias(alias);
        onSelect(alias);
      }
    });
  }, [pathname, instructionsRedirects, isOpen]);

  return autoAlias;
};
