import React from "react";

import { ITab } from "../../../../../../shared/ui/controls/TabBar/TabBarNotLinks";

import { ExpenditureTypeEnum } from "../../../../../../types/enums/ExpenditureTypeEnum";
import { ISubExpenditure } from "./types";

export const PERCENT_DECIMAL = 4;

export const UNGROUP_MODAL_TABS: ITab[] = [
  {
    id: "structure",
    text: "Состав",
  },
  {
    id: "cost",
    text: "Стоимость",
  },
];

export const EMPTY_SUB_EXPENDITURE: ISubExpenditure = {
  number: "",
  measure: "",
  count: "",
  expenditure_type: ExpenditureTypeEnum.work,
  percent: "0",
  estimated_cost: "0",
  name: "",
  _isEdit: true,
  _unitCost: "0",
  _key: -1,
};

export const EXPENDITURE_TYPES_SELECT = [
  { name: "Работа", id: "work" },
  { name: "Материал", id: "material" },
  { name: "Транспорт", id: "transport" },
  { name: "Оборудование", id: "equipment" },
  { name: "Машины", id: "machine" },
];

export const UngroupArrowsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.161" height="13.146" viewBox="0 0 15.161 13.146">
    <path
      d="M7072.025-11148.94a1.677,1.677,0,0,1-1.919-1.862v-3.558c0-.5-.224-.712-.63-.712H7069v-.84h.476c.406,0,.63-.211.63-.715v-3.558a1.677,1.677,0,0,1,1.919-1.86h.518v.827h-.378c-.728,0-1.064.4-1.064,1.117v3.46c0,.7-.21.994-.658,1.148.448.154.658.448.658,1.146v3.46c0,.714.336,1.12,1.064,1.12h.378v.826Zm1.134-4.146h3v-2.5a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v2.5h3l-3.5,4Zm7-2.5v-2.5h-3l3.5-4,3.5,4h-3v2.5a.5.5,0,0,1-.5.5A.5.5,0,0,1,7080.16-11155.585Z"
      style={{ transform: "translate(-7069px, 11162.087px)" }}
      fill="#5a5a5a"
    />
  </svg>
);
