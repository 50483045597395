import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";

import styles from "./TabBar.module.scss";

interface IProps {
  tabs: Array<{ link: string; text: string; className?: string; isHidden?: boolean; isDesktopHidden?: boolean }>;
  className?: string;
  disabled?: boolean;
  isDirectlyOpen?: boolean;
  setIsDirectlyOpen?: (status: boolean) => void;
  isOnlyTabsView?: boolean;
  variant?: "default" | "light";
}

function TabBar({
  tabs,
  className,
  disabled,
  isDirectlyOpen,
  setIsDirectlyOpen,
  isOnlyTabsView = false,
  variant = "default",
  ...rest
}: IProps) {
  const [isOpen, setIsOpen] = useState(isDirectlyOpen);

  const filteredTabs = useMemo(() => {
    if (!Array.isArray(tabs)) return [];
    return tabs.filter((el) => !el.isHidden);
  }, [tabs]);
  const isSingle = filteredTabs?.length === 1;

  const clickHandler = useCallback(() => {
    setIsDirectlyOpen?.(!isOpen);
    setIsOpen((prev) => !prev);
  }, [setIsDirectlyOpen]);

  return (
    <div
      className={cn(styles.container, {
        [styles.select]: !isOnlyTabsView,
        [styles.default]: variant === "default",
        [styles.light]: variant === "light",
      })}
    >
      <div
        className={cn(className, styles.tabBar, {
          [styles.disabled]: disabled,
          [styles.open]: isOpen && !isSingle,
          [styles.single]: isSingle,
        })}
        {...rest}
      >
        {filteredTabs.map((el) => (
          <NavLink
            activeClassName={styles.active}
            className={cn(styles.tab, el.className, { [styles.desktopHidden]: el.isDesktopHidden })}
            to={el.link}
            key={el.text}
            data-testid={`tab_${el.text}`}
            onClick={clickHandler}
          >
            {el.text}
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default TabBar;
