import { useEffect, useState } from "react";

import ApprovalAndView from "../_LEGACY/domain/ApprovalAndView";

const useApprovalAndView = (approvalAndViewElements) => {
  const [approvalAndView, setApprovalAndView] = useState(new ApprovalAndView([]));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!approvalAndViewElements) {
      setIsLoading(false);
      //
      return;
    }

    setApprovalAndView(new ApprovalAndView(approvalAndViewElements));
    setIsLoading(false);
  }, [approvalAndViewElements]);

  return { approvalAndView, isLoading };
};

export default useApprovalAndView;
