import axios from 'axios';
import { normalize, schema } from 'normalizr';
import update from 'immutability-helper';
import { createSelector } from 'reselect';

const moduleName = 'orderMatrix';

const LOAD_MATRIX = `${moduleName}/LOAD_MATRIX`;
const SET_MATRIX = `${moduleName}/SET_MATRIX`;
const UPDATE_PRODUCT_LOADING = `${moduleName}/UPDATE_PRODUCT_LOADING`;
const UPDATE_PRODUCT = `${moduleName}/UPDATE_PRODUCT`;
const UPDATE_SYNC_PRODUCT = `${moduleName}/UPDATE_SYNC_PRODUCT`;
const UPDATE_PRODUCT_IN_MATRIX = `${moduleName}/UPDATE_PRODUCT_IN_MATRIX`;
const SET_CURRENT_SYNCED_PRODUCTS = `${moduleName}/SET_CURRENT_SYNCED_PRODUCTS`;
const SET_STOCK_FILTER = `${moduleName}/SET_STOCK_FILTER`;
const SET_CATEGORY_FILTER = `${moduleName}/SET_CATEGORY_FILTER`;
const SET_SWITCH_FILTER = `${moduleName}/SET_SWITCH_FILTER`;
const CLEAR_FILTERS = `${moduleName}/CLEAR_FILTERS`;
const UPDATE_SEARCH_INPUT_VALUE = `${moduleName}/UPDATE_SEARCH_INPUT_VALUE`;
const SET_PAGINATION_STATE = `${moduleName}/SET_PAGINATION_STATE`;
const SET_PRICE_SORT = `${moduleName}/SET_PRICE_SORT`;
const SET_FREQ_SORT = `${moduleName}/SET_FREQ_SORT`;
const SET_NEW_SORTING = `${moduleName}/SET_NEW_SORTING`;
const SET_PARTNER_FILTER = `${moduleName}/SET_PARTNER_FILTER`;

const initialState = {
  currentSyncedProducts: {},
  matrix: {},
  filtersState: {
    search: '',
    stock: null,
  },
  product: {},
  pagination: {
    params: { limit: 50, offset: 0 },
    page: 1,
  },
  sorting: null,
  loading: true
};

/*
  Reducer
*/

export default (matrixState = initialState, action) => {
  const { payload, type } = action;

  switch (type) {

    case LOAD_MATRIX:
      return {
        ...matrixState,
        loading: true
      };

    case SET_MATRIX: {
      return {
        ...matrixState,
        matrix: payload,
        loading: false
      };
    }

    case UPDATE_PRODUCT_LOADING:
      return {
        ...matrixState,
        product: {
          isLoading: true,
        },
      };

    case UPDATE_PRODUCT:
      return {
        ...matrixState,
        product: {
          isLoading: false,
          min_capacity: 1,
          repetition_factor: 1,
          ...payload,
        },
      };

    case SET_CURRENT_SYNCED_PRODUCTS: {
      return {
        ...matrixState,
        currentSyncedProducts: payload,
      };
    }

    case UPDATE_SYNC_PRODUCT: {
      return {
        ...matrixState,
        currentSyncedProducts: {
          ...matrixState.currentSyncedProducts,
          [payload.productId]: payload.selectedValue,
        },
      };
    }

    case UPDATE_PRODUCT_IN_MATRIX: {

      return {
        ...matrixState,
        matrix: {
          ...matrixState.matrix,
          entities: {
            ...matrixState.matrix.entities,
            products: {
              ...matrixState.matrix.entities.products,
              [payload.id]: payload
            }
          }
        },
      }

    }

    case SET_PARTNER_FILTER:
      return update(matrixState, { filtersState: { partner: { $set: payload } } });

    case SET_STOCK_FILTER: {
      return update(matrixState, { filtersState: { stock: { $set: payload } } });
    }

    case SET_PRICE_SORT: {
      return update(matrixState, { filtersState: { order_price: { $set: payload } } });
    }

    case SET_FREQ_SORT: {
      return update(matrixState, { filtersState: { order_freq: { $set: payload } } });
    }

    case SET_CATEGORY_FILTER: {
      return update(matrixState, { filtersState: { category: { $set: payload } } });
    }

    case SET_SWITCH_FILTER: {
      return update(matrixState, { filtersState: { hasAbstractProduct: { $set: payload } } });
    }

    case CLEAR_FILTERS: {
      return update(matrixState, {
        filtersState: {
          $set: {
            order_price: null,
            order_freq: null,
            search: '',
            stock: null,
            category: null,
            hasAbstractProduct: false,
          },
        },
      });
    }

    case UPDATE_SEARCH_INPUT_VALUE: {
      return update(matrixState, { filtersState: { search: { $set: payload } } });
    }

    case SET_PAGINATION_STATE:
      return {
        ...matrixState,
        pagination: {
          params: payload.params,
          page: payload.page,
        },
      };

    case SET_NEW_SORTING:
      return update(matrixState, {
        sorting: { $set: payload }
      });

    default:
      return matrixState;
  }
};

/*
  Selectors
*/

export const stateSelector = state => state.orderMatrix;
export const filtersStateSelector = createSelector(stateSelector, state => state.filtersState);
export const paginationStateSelector = createSelector(stateSelector, state => state.pagination);
export const currentSyncedProductsSelector = createSelector(
  stateSelector,
  state => state.currentSyncedProducts,
);
export const loadingStateSelector = createSelector(stateSelector, state => state.loading);
export const sortingStateSelector = createSelector(stateSelector, state => state.sorting);
export const matrixStateSelector = createSelector(stateSelector, state => state.matrix);


/*
  Action creators
*/

export const updateSyncProduct = product => {
  return {
    type: UPDATE_SYNC_PRODUCT,
    payload: product,
  };
};

export const setCurrentMatrix = data => {
  return {
    type: SET_MATRIX,
    payload: data,
  };
};

export const updateProductInMatrix = product => {
  return {
    type: UPDATE_PRODUCT_IN_MATRIX,
    payload: product,
  };
};

export const setStockFilter = stock => ({
  type: SET_STOCK_FILTER,
  payload: stock,
});

export const setPriceSort = stock => ({
  type: SET_PRICE_SORT,
  payload: stock,
});

export const setFreqSort = stock => ({
  type: SET_FREQ_SORT,
  payload: stock,
});

export const setCategoryFilter = category => ({
  type: SET_CATEGORY_FILTER,
  payload: category,
});

export const setSwitchFilter = switchValue => ({
  type: SET_SWITCH_FILTER,
  payload: switchValue,
});

export const updateSearchInputValue = value => {
  return {
    type: UPDATE_SEARCH_INPUT_VALUE,
    payload: value,
  }
};

export const clearFilters = () => ({
  type: CLEAR_FILTERS,
});

export const setPaginationState = (params, page) => ({
  type: SET_PAGINATION_STATE,
  payload: { params, page },
});

export const initProductLoading = () => ({
  type: UPDATE_PRODUCT_LOADING,
});

export const setProduct = (product) => ({
  type: UPDATE_PRODUCT,
  payload: product,
});

export const sortTableTab = (tab) => ({
  type: SET_NEW_SORTING,
  payload: tab
});

export const setPartnerFilter = (partnerId) => dispatch => dispatch({
  type: SET_PARTNER_FILTER,
  payload: partnerId,
});

/*
  Thunks
*/

//MATRIX THUNKS

export const cleanFilters = () => {
  return dispatch => dispatch(clearFilters());
};

export const setCurrentSyncedProducts = products => {
  const syncProducts = Object.keys(products).reduce(
    (acc, productId) => ({
      ...acc,
      [productId]: products[productId].abstract_product
        ? {
          value: products[productId].abstract_product.id,
          label: products[productId].abstract_product.name,
        }
        : null,
    }),
    {},
  );
  return {
    type: SET_CURRENT_SYNCED_PRODUCTS,
    payload: syncProducts,
  };
};

export const loadMatrixV2 = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (entityId, partner, paginationParams, filterParams) => {
    if (ge_cancel) ge_cancel();
    const config = {
      params: {
        entity_id: partner,
        ...paginationParams,
        ...filterParams,
      },
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      })
    };

    //const url = purchaser ? `/providers/${entityId}/clients/${purchaser}/products/` : `/purchasers/${entityId}/providers/products/`;

    const url = `/entities/${entityId}/organisations/partners/products/`;

    return dispatch => {
      dispatch({type: LOAD_MATRIX});
      return axios.get(url, config).then(
        res => {
          const productSchema = new schema.Entity('products');
          const productListSchema = new schema.Array(productSchema);

          const normalizedData = normalize(res.data.results, productListSchema);
          const result = {count: res.data.count, ...normalizedData, results: res.data.results};

          dispatch(setCurrentMatrix(result));
        },
        err => {
          console.error(err);
          //dispatch(setCurrentMatrix({}));
        },
      );
    };
  }
})();

//PRODUCT CARD THUNKS
export const loadProduct = (entityId, productId, successCallback, errorCallback) => {
  return dispatch => {
    dispatch(initProductLoading());
    axios.get(`/providers/${entityId}/products/${productId}/`).then(
      response => {
        dispatch(setProduct(response.data));
        successCallback();
      })
      .catch(error => {
        console.error(error);
        errorCallback();
      });
  };
};

export const updateProduct = (product) => {
  return dispatch => dispatch(setProduct(product));
};

export const saveProduct = (key, entityId, orderId, data) => {
  const config = {
    headers: { Authorization: `Token ${key}` },
  };
  return () => axios.put(`/providers/${entityId}/products/${orderId}/`, data, config);
};

export const createProduct = (key, entityId, data) => {
  const config = {
    headers: {
      Authorization: `Token ${key}`,
    },
  };
  return () => axios.post(`/providers/${entityId}/products/`, data, config);
};

export const deleteProduct = (entityId, key, propNumber) => {
  const config = {
    headers: {
      Authorization: `Token ${key}`,
      'Cache-Control': 'no-cache',
    },
  };
  return () => axios.delete(`/providers/${entityId}/products/${propNumber}/`, config);
};

export const loadMeasures = () => {
  return () => axios.get(`/measure/`);
}