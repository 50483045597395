export const PRODUCT_TYPES = {
  SPECIFICATION: "specification",
  SERVICE: "service",
  NEW_PRODUCT: "new_product",
};

export const MODAL_TABS_TITLES = {
  MATERIALS: "Материалы",
  SERVICE: "Услуги",
  NEW_PRODUCT: "Новый товар",
};
