import React from "react";

import PairOfInfoItems from "./PairOfInfoItems";

import { splitThousands } from "utils/formatters/splitThousands";

type propsType = {
  measure: string;
  count: string | number;
  amount: string | number;
  labelCount: string;
  labelAmount: string;
};

function PairOfCountAndAmount({ measure, count, amount, labelAmount, labelCount }: propsType) {
  return (
    <PairOfInfoItems
      label1={labelCount}
      value1={count && measure ? `${count} (${measure})` : ''}
      label2={labelAmount}
      value2={splitThousands(amount) as string}
    />
  );
}

export default PairOfCountAndAmount;
