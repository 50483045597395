import cn from "classnames";
import React from "react";

import { remarkStatusSwitcher } from "../../constants/constants";
import { remarkStatuses } from "../../types/types";
import { LAPTOP_WIDTH } from "constants/dimensions";

import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";

import styles from "./Header.module.scss";

export interface IProps {
  title?: string;
  workName: string;
  remarkStatus?: remarkStatuses;
}

const Header: React.FC<IProps> = ({ title = "Замечание", remarkStatus, workName }) => {
  return (
    <header className={styles.header}>
      <div className={styles.flexRow}>
        <h1 className={styles.title} title={title}>
          {remarkStatus ? sliceTextByConstraint(title, window.innerWidth <= LAPTOP_WIDTH ? 23 : 28) : title}
        </h1>
        {remarkStatus && (
          <div className={cn(styles.remark, styles[remarkStatus])}>{remarkStatusSwitcher[remarkStatus]}</div>
        )}
      </div>
      <span className={styles.secondary}>к работе:</span>
      <p className={styles.name}>{workName}</p>
    </header>
  );
};

export default React.memo(Header);
