import React, { useCallback } from "react";

import { MonthArray } from "../../../../../constants/constant";

import { getInitialMonthsArray } from "./utils";

interface IProps {
  year: number;
  monthIndex: number;
  selectingMonthsCount: number;
  onDirectlyClickLeftArrow?: () => void;
  onDirectlyClickRightArrow?: () => void;
  monthsCount: number;
  changeDate: (year: number, month: number) => void;
  arrayIndex: number;
}

export const useMonthsSlider = ({
  year,
  monthIndex,
  changeDate,
  selectingMonthsCount,
  onDirectlyClickLeftArrow,
  onDirectlyClickRightArrow,
  monthsCount,
  arrayIndex,
}: IProps) => {
  const preparedMonthArray = React.useMemo(() => {
    return MonthArray.map((month) => ({
      ...month,
      label: month.label.length > 4 ? month.label.slice(0, 3) : month.label,
    }));
  }, []);

  const [usingMonthArray, setUsingMonthArray] = React.useState(
    getInitialMonthsArray(monthIndex, monthsCount, preparedMonthArray)
  );

  const increaseMonth = useCallback(() => {
    if (monthsCount < 12) {
      const tempMonths = [...usingMonthArray];
      tempMonths.shift();
      const idOfLastExistingItem = tempMonths.at(-1)?.id;
      const indexOfLastShowingMonth = preparedMonthArray.findIndex((el) => el.id === idOfLastExistingItem);
      const firstMonth = preparedMonthArray[idOfLastExistingItem === 11 ? 0 : indexOfLastShowingMonth + 1];
      tempMonths.push(firstMonth);
      setUsingMonthArray(tempMonths);
      changeDate(year, monthIndex + 1);
      return;
    }
    if (onDirectlyClickRightArrow) {
      onDirectlyClickRightArrow();
      return;
    }
    if (selectingMonthsCount > 1) {
      const tempMonths = [...usingMonthArray];
      const firstMonth = tempMonths.shift();
      tempMonths.push(firstMonth!);
      setUsingMonthArray(tempMonths);
      changeDate(year, monthIndex + 1);
    } else {
      if (monthIndex === preparedMonthArray.length - 1) {
        changeDate(year + 1, 0);
        return;
      }
      changeDate(year, monthIndex + 1);
    }
  }, [changeDate, year, monthIndex, selectingMonthsCount, usingMonthArray, onDirectlyClickRightArrow, monthsCount]);

  const decreaseMonth = useCallback(() => {
    if (monthsCount < 12) {
      const tempMonths = [...usingMonthArray];
      tempMonths.pop();
      const indexOfLastShowingMonth = preparedMonthArray.findIndex((el) => el?.id === tempMonths[0].id);
      const lastMonth = preparedMonthArray[indexOfLastShowingMonth === 0 ? 11 : indexOfLastShowingMonth - 1];
      tempMonths.unshift(lastMonth);
      setUsingMonthArray(tempMonths);
      changeDate(year, monthIndex - 1);
      return;
    }
    if (onDirectlyClickLeftArrow) {
      onDirectlyClickLeftArrow();
      return;
    }
    if (selectingMonthsCount > 1) {
      const tempMonths = [...usingMonthArray];
      const lastMonth = tempMonths.pop();
      tempMonths.unshift(lastMonth!);
      setUsingMonthArray(tempMonths);
      changeDate(year, monthIndex - 1);
    } else {
      if (monthIndex === 0) {
        changeDate(year - 1, preparedMonthArray.length - 1);
        return;
      }
      changeDate(year, monthIndex - 1);
    }
  }, [changeDate, year, monthIndex, selectingMonthsCount, usingMonthArray, onDirectlyClickLeftArrow, monthsCount]);

  return { monthsArray: usingMonthArray, increaseMonth, decreaseMonth };
};
