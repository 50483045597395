import React from "react";

const CircleAddIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 24C8.92824 24 6.04033 22.8038 3.86827 20.6317C1.69621 18.4597 0.5 15.5718 0.5 12.5C0.5 9.42824 1.69621 6.54033 3.86827 4.36827C6.04033 2.19621 8.92824 1 12 1C15.0718 1 17.9597 2.19621 20.1317 4.36827C22.3038 6.54033 23.5 9.42824 23.5 12.5C23.5 15.5718 22.3038 18.4597 20.1317 20.6317C17.9597 22.8038 15.0718 24 12 24Z"
            fill="#4FB2ED"
        />
        <path
            d="M12 1.5C9.06179 1.5 6.29945 2.6442 4.22182 4.72182C2.1442 6.79945 1 9.56179 1 12.5C1 15.4382 2.1442 18.2006 4.22182 20.2782C6.29945 22.3558 9.06179 23.5 12 23.5C14.9382 23.5 17.7006 22.3558 19.7782 20.2782C21.8558 18.2006 23 15.4382 23 12.5C23 9.56179 21.8558 6.79945 19.7782 4.72182C17.7006 2.6442 14.9382 1.5 12 1.5ZM12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5C0 5.87258 5.37258 0.5 12 0.5Z"
            fill="#4FB2ED"
        />
        <path
            d="M10.9554 18.5129V13.5419H5.98741C5.70999 13.5419 5.44394 13.4317 5.24777 13.2355C5.05161 13.0393 4.94141 12.7733 4.94141 12.4959C4.94141 12.2185 5.05161 11.9524 5.24777 11.7562C5.44394 11.5601 5.70999 11.4499 5.98741 11.4499H10.9554V6.48887C10.9554 6.21145 11.0656 5.9454 11.2618 5.74924C11.4579 5.55307 11.724 5.44287 12.0014 5.44287C12.2788 5.44287 12.5449 5.55307 12.741 5.74924C12.9372 5.9454 13.0474 6.21145 13.0474 6.48887V11.4549H18.0154C18.1528 11.4549 18.2888 11.4819 18.4157 11.5345C18.5426 11.5871 18.6579 11.6641 18.755 11.7612C18.8522 11.8584 18.9292 11.9737 18.9818 12.1006C19.0344 12.2275 19.0614 12.3635 19.0614 12.5009C19.0614 12.6382 19.0344 12.7743 18.9818 12.9012C18.9292 13.0281 18.8522 13.1434 18.755 13.2405C18.6579 13.3376 18.5426 13.4147 18.4157 13.4672C18.2888 13.5198 18.1528 13.5469 18.0154 13.5469H13.0464V18.5129C13.0464 18.7903 12.9362 19.0563 12.74 19.2525C12.5439 19.4487 12.2778 19.5589 12.0004 19.5589C11.723 19.5589 11.4569 19.4487 11.2608 19.2525C11.0646 19.0563 10.9544 18.7903 10.9544 18.5129H10.9554Z"
            fill="white"
        />
    </svg>
);

export default CircleAddIcon;