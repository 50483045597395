import styles from "../ShiftsModal.module.scss";
import { FC, memo } from "react";
import BackButton from "./BackButton";

interface SelectedPlanHeaderProps {
  title: string;
  dateStart: string;
  dateEnd: string;
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
}

const SelectedPlanHeader: FC<SelectedPlanHeaderProps> = (props) => {
  const {
    title,
    dateStart,
    dateEnd,
    showBackButton = false,
    onBackButtonClick
  } = props;

  return (
    <div className={styles.header}>
      {showBackButton && (
        <BackButton
          onClick={onBackButtonClick}
        />
      )}
      <div className={styles.content}>
        <span className={styles.title}>{title}</span>
        <div className={styles.info}>
          <span className={styles.dateInfo}>Выполнение:</span>
          <span className={styles.dates}>{dateStart} – {dateEnd}</span>
        </div>
      </div>
    </div>
  );
}


export default memo(SelectedPlanHeader);
