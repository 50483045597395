import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getSignersByBuilding } from "./model/thunks";
import SignatorsRow from "./ui/SignatorsRow/SignatorsRow";
import SignatorsModal from "./widgets/SignatorsModal/SignatorsModal";
import { useTypedSelector } from "app/store/typedUseSelector";

import { SignerTypes } from "./model/types";

import styles from "./Signators.module.scss";

const aosrSignerTypes: SignerTypes[] = ["aosr_1", "aosr_2", "aosr_3", "aosr_4", "aosr_5"];
const aookSignerTypes: SignerTypes[] = ["aook_1", "aook_2", "aook_3", "aook_4", "aook_5", "aook_6"];
const ks2SignerTypes: SignerTypes[] = ["ks2_passed", "ks2_accepted"];
const ks3SignerTypes: SignerTypes[] = ["ks3_provider", "ks3_purchaser"];

interface IProps {
  objectId: string;
}

const Signators: React.FC<IProps> = ({ objectId }) => {
  const dispatch = useDispatch();
  const { signatorsByBuildings, isLoading, invalidateKey } = useTypedSelector((state) => state.signatorsSlice);
  const [modalType, setModalType] = useState<SignerTypes | null>(null);

  const signators = signatorsByBuildings[objectId];

  useEffect(() => {
    dispatch(getSignersByBuilding(objectId));
  }, [objectId, invalidateKey]);

  return (
    <>
      <SignatorsRow
        objectId={objectId}
        onClick={setModalType}
        title="АОСР"
        types={aosrSignerTypes}
        signers={signators}
      />
      <SignatorsRow
        objectId={objectId}
        onClick={setModalType}
        title="АООК"
        types={aookSignerTypes}
        signers={signators}
      />
      <SignatorsRow
        objectId={objectId}
        onClick={setModalType}
        title="КС 2"
        types={ks2SignerTypes}
        signers={signators}
      />
      <SignatorsRow
        objectId={objectId}
        onClick={setModalType}
        title="КС 3"
        types={ks3SignerTypes}
        signers={signators}
      />
      <SignatorsModal isOpen={!!modalType} onClose={() => setModalType(null)} type={modalType!} objectId={objectId} />
    </>
  );
};

export default Signators;
