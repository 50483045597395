import axios from "axios";

import { IAddExtraExpenseForm } from "./types";

export const extraForecastApi = {
  create: (buildingId: string, data: IAddExtraExpenseForm) => {
    return axios.post(`/buildings/${buildingId}/finances/extra_costs/`, data);
  },
  getDetail: (buildingId: string, id: number) => {
    return axios.get(`/buildings/${buildingId}/finances/extra_costs/${id}/`);
  },
  edit: (buildingId: string, id: number, data: IAddExtraExpenseForm) => {
    return axios.patch(`/buildings/${buildingId}/finances/extra_costs/${id}/`, data);
  },
  refetchExtra: (buildingId: string) => {
    return axios.get(`/tree/finance_forecasts/${buildingId}/`);
  },
  delete: (buildingId: string, id: number) => {
    return axios.delete(`/buildings/${buildingId}/finances/extra_costs/${id}/`);
  },
};
