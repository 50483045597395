import { Dispatch } from "redux";

import { learningApi } from "./api";
import { learningActions } from "./learningSlice";
import { RootState } from "app/store/rootReducer";

export const getLearning = () => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().learning.data?.results?.length) {
    dispatch(learningActions.setIsLoading(true));
  }
  learningApi
    .get()
    .then(({ data }) => {
      dispatch(
        learningActions.setData({
          ...data,
          results: data.results.map((el, i, arr) => ({
            ...el,
            menuTitle: `Задание ${el.number ?? i + 1}`,
            isDisabled: !!arr[i - 1] && !el.is_confirmed && (arr[i - 1].isDisabled || !arr[i - 1].is_confirmed),
          })),
        })
      );
    })
    .finally(() => {
      dispatch(learningActions.setIsLoading(false));
    });
};

//??? перенести в инструкции
export const getInstructionsRedirects = (userId: number) => (dispatch: Dispatch, getState: () => RootState) => {
  learningApi.getInstructionsRedirects(userId).then(({ data }) => {
    dispatch(learningActions.setInstructionsRedirects(data));
  });
};

export const getLearningSublists = () => (dispatch: Dispatch, getState: () => RootState) => {
  //
  learningApi.getSublists().then(({ data }) => {
    dispatch(learningActions.setLists(data));
  });
};
