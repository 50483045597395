import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface DeletePlanState {
  isLoading: Record<string, boolean>;
  invalidateKey: number;
}

const initialState: DeletePlanState = {
  isLoading: {},
  invalidateKey: 0,
};

export const deletePlanSlice = createSlice({
  name: "deletePlan",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      const { key, status } = action.payload;
      state.isLoading[key] = status;
    },
    invalidateKey: (state) => {
      state.invalidateKey++;
    },
  },
});

export const deletePlanActions = deletePlanSlice.actions;

export default deletePlanSlice.reducer;
