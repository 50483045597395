import cn from "classnames";
import React, { useMemo } from "react";

import { IJournalAcceptanceSharedContractors } from "redux/modules/common/building/journal/acceptance/types";

import JournalSection from "../JournalSections/JournalSection/JournalSection";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";

import commonJournalStyles from "../../../../Journal.module.scss";
import styles from "./JournalContractors.module.scss";

interface IProps {
  contractors: IJournalAcceptanceSharedContractors[];
  currentContractorId: number;
  month: number;
  year: number;
  onBackFromSection: () => void;
  onSectionClick: (sectionId: number) => void;
  activeContractorName: string;
}

const JournalContractorSections: React.FC<IProps> = ({
  contractors,
  currentContractorId,
  year,
  month,
  onBackFromSection,
  onSectionClick,
  activeContractorName,
}) => {
  const { sections, title } = useMemo(() => {
    const currentItem = contractors.find((el) => el.entity_id === +currentContractorId);
    return { sections: currentItem?.sections ?? [], title: currentItem?.entity_name ?? "" };
  }, [contractors, currentContractorId]);

  return (
    <>
      {<BackNavigationBar title={title} onBack={onBackFromSection} />}
      {sections.map((el) => (
        <JournalSection year={year} month={month} section={el} key={el.id} onSectionClick={onSectionClick} />
      ))}
    </>
  );
};

export default JournalContractorSections;
