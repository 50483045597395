import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  ks6aTableMeasureSelector,
  ks6aTableMonthTotalSelector,
} from "../../../../../../../../../redux/modules/common/ks6a/ks6a";
import { Ks6aListItemType } from "../../../../../../../../../redux/modules/common/ks6a/types";

import { computeCost } from "../../../../constants";

import { countFormatter } from "../../utils";

import styles from "./Ks6aMonth.module.scss";

export interface IKs6aMonthProps {
  list: Ks6aListItemType[];
  monthId: number;
  label: string;
}

const Ks6aMonth: React.FC<IKs6aMonthProps> = ({ monthId, label, list }) => {
  const measure = useSelector(ks6aTableMeasureSelector);
  const monthTotal = useSelector(ks6aTableMonthTotalSelector);
  const total = useMemo(() => monthTotal[monthId + 1] || {}, [monthId, monthTotal]);

  return (
    <div className={styles.month}>
      {list.map((work: Ks6aListItemType, index: number) => {
        const { count, amount, fact_amount } = work.month_data.months[monthId + 1] || {};
        return (
          <div className={styles.monthRow} key={index}>
            <div className={styles.monthRowCount}>{countFormatter(count) || ""}</div>
            <div className={styles.monthRowCost}>{computeCost(amount, measure) || ""}</div>
            <div className={styles.monthRowCostFact}>{computeCost(fact_amount, measure) || ""}</div>
          </div>
        );
      })}
      <div className={styles.monthTotal}>
        <div className={styles.monthTotalCount}>{countFormatter(total.count) || <>&nbsp;</>}</div>
        <div className={styles.monthOtherCount}>&nbsp;</div>
        <div className={styles.monthSumCount}>{countFormatter(total.count) || <>&nbsp;</>}</div>
        <div className={styles.monthTotalCost}>&nbsp;</div>
        <div className={styles.monthOtherCost}>&nbsp;</div>
        <div className={styles.monthSumCost}>&nbsp;</div>
        <div className={styles.monthTotalCostFact}>{computeCost(total.fact_amount, measure) || <>&nbsp;</>}</div>
        <div className={styles.monthOtherCostFact}>&nbsp;</div>
        <div className={styles.monthSumCostFact}>{computeCost(total.fact_amount, measure) || <>&nbsp;</>}</div>
      </div>
    </div>
  );
};

export default React.memo(Ks6aMonth);
