import React from "react";

import { formatNumberWithMeasure } from "../../../../../../../utils/formatters/formatNumberWithMeasure";

import styles from "./SubTableRow.module.scss";

function SubTableRow({ name, measureProduct, count, amount, measureMoney }) {
  return (
    <div className={styles.container}>
      <div className={styles.field1}>
        <div className={styles.dot}></div>
      </div>
      <div>{name}</div>
      <div className={styles.center}>{measureProduct}</div>
      <div className={styles.center}>{count}</div>
      <div className={styles.money}>{formatNumberWithMeasure(amount, measureMoney)}</div>
    </div>
  );
}

export default SubTableRow;
