import React from "react";

import { IAosrDoc } from "redux/modules/common/building/journal/aosr/types";

export const useAosrDocs = (initialDocs: IAosrDoc[]) => {
  const [docs, setDocs] = React.useState(initialDocs || []);
  const [deletedDocsIds, setDeletedDocsIds] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (!initialDocs) {
      return;
    }
    setDocs(initialDocs);
  }, [initialDocs]);

  const addDoc = React.useCallback((newDoc: Omit<IAosrDoc, "id">) => {
    const tempId = Date.now();
    setDocs((prev) => [...prev, { ...newDoc, id: tempId }]);
  }, []);

  const deleteDoc = React.useCallback((doc: IAosrDoc) => {
    setDocs((prev) => prev.filter((el) => el.id != doc.id));
    if (!doc.is_new) {
      setDeletedDocsIds((prev) => [...prev, doc.id]);
    }
  }, []);

  const startCreatingDoc = React.useCallback(() => {
    setDocs((prev) => [
      ...prev,
      { doc_date: "", doc_type: "", title: "", listfiles: [], id: 0, is_new: true, hiddenwork: -1 },
    ]);
  }, []);

  return {
    docs,
    addDoc,
    deleteDoc,
    deletedDocsIds,
    startCreatingDoc,
  };
};
