import { message } from "antd";
import cn from "classnames";
import React, { FC, memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { objectDetailSelector } from "../../../../../../../../../../redux/modules/common/building/object/nowObject";
import { userSelector } from "redux/modules/_TODO/auth";

import EmptyMessage from "../../../../../../../ProHandler/components/EmptyMessage/EmptyMessage";

import Spinner from "../../../../../../../../../../shared/ui/atoms/Spinner/Spinner";
import {
  activeSubSectionSelector,
  checkedItemsIdsSelector,
  expendituresAreLoadingSelector,
  expendituresSelector,
  modeSelector,
} from "../../../../../../model/selectors";
import { simpleHandlerActions } from "../../../../../../model/slice";
import { simpleHandlerModel } from "../../../../../../model/thunks";
import { SectionInfo } from "../CommonExpendituresList/SectionInfo/SectionInfo";
import { TableHeader } from "../CommonExpendituresList/TableHeader/TableHeader";
import { Expenditure } from "../Expenditure/Expenditure";

import { IExpenditure } from "../../../../../../../../../../types/interfaces/Expenditure";
import { EstimateItemsStatusesEnum } from "../../../../../../../ProHandler/enums";
import { ESimpleHandlerMode } from "../../../../../../types";

import { useIsOpenedInApproveForm } from "../../../../../../hooks/useIsOpenedInApproveForm";
import { useStatusFromQueryParams } from "../../../SubSectionsList/hooks/useStatusFromQueryParams";

import styles from "../../ExpendituresList.module.scss";

export const ExpendituresListInLocaleStateView: FC = memo(() => {
  const dispatch = useDispatch();

  const activeSubSection = useSelector(activeSubSectionSelector);
  const expenditures = useSelector(expendituresSelector);
  const expendituresAreLoading = useSelector(expendituresAreLoadingSelector);
  const mode = useSelector(modeSelector);
  const checkedItemsIds = useSelector(checkedItemsIdsSelector);
  const building = useSelector(objectDetailSelector);
  const user = useSelector(userSelector);

  const isOpenedInApproveForm = useIsOpenedInApproveForm();
  const userIsResponsibleEmployee = user?.id === building?.responsible_estimate?.id;
  const statusFromQueryParams = useStatusFromQueryParams();
  const isNewStatus = statusFromQueryParams === "new";
  const canConfirm = (isOpenedInApproveForm || userIsResponsibleEmployee) && isNewStatus;

  const [statusUpdatingIds, setStatusUpdatingIds] = useState<number[]>([]);

  const filteredExpenditures = useMemo(() => {
    if (!expenditures) return [];

    if (isNewStatus) {
      return expenditures.filter((e) => e.status === "new");
    } else {
      return expenditures.filter((e) => e.status === "confirmed");
    }
  }, [expenditures, statusFromQueryParams]);

  const onCheck = useCallback((e: IExpenditure) => {
    dispatch(simpleHandlerActions.toggleCheckedItem({ id: e.id, name: e.name }));
  }, []);

  const handleConfirm = useCallback((id: number) => {
    setStatusUpdatingIds((prev) => [...prev, id]);

    dispatch(
      simpleHandlerModel.updateItemStatus({
        itemId: id,
        status: EstimateItemsStatusesEnum.CONFIRMED,
        successCallback: () => message.success("Позиция утверждена"),
        callback: () => setStatusUpdatingIds((prev) => prev.filter((prevId) => prevId !== id)),
      })
    );
  }, []);

  const handleCancel = useCallback((id: number) => {
    setStatusUpdatingIds((prev) => [...prev, id]);

    dispatch(
      simpleHandlerModel.updateItemStatus({
        itemId: id,
        status: EstimateItemsStatusesEnum.CANCELED,
        successCallback: () => message.success("Позиция отклонена"),
        callback: () => setStatusUpdatingIds((prev) => prev.filter((prevId) => prevId !== id)),
      })
    );
  }, []);

  return (
    <div className={cn(styles.container, isOpenedInApproveForm && styles.approveForm)}>
      <SectionInfo section={activeSubSection} />

      <TableHeader canConfirm={canConfirm} />

      {expendituresAreLoading && <Spinner isStatic className={styles.spinner} />}

      {filteredExpenditures.map((e) => {
        return (
          <Expenditure
            key={e.id}
            expenditure={e}
            showCheckbox={mode === ESimpleHandlerMode.VIEW && !isNewStatus && !isOpenedInApproveForm}
            onCheck={onCheck}
            isChecked={checkedItemsIds.includes(e.id)}
            canConfirm={canConfirm}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            isStatusUpdating={statusUpdatingIds.includes(e.id)}
          />
        );
      })}

      {!expendituresAreLoading && filteredExpenditures.length === 0 && <EmptyMessage message="Нет позиций" />}
    </div>
  );
});

ExpendituresListInLocaleStateView.displayName = "ExpendituresListInLocaleStateView";
