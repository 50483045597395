import React from "react";

export default function StatusCanceledIcon({ width = "25", fill = "#EB70B7", stroke = "white" }) {
  return (
    <svg width={width} height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8781 23.4803C10.2582 23.4729 7.72195 22.5578 5.70085 20.8908C3.67974 19.2237 2.29879 16.9077 1.79313 14.3371C1.28748 11.7664 1.68828 9.09998 2.92741 6.79163C4.16654 4.48328 6.1676 2.67567 8.58952 1.67655C11.0114 0.677428 13.7048 0.548519 16.2111 1.31177C18.7174 2.07503 20.8817 3.68328 22.3356 5.86274C23.7895 8.0422 24.4431 10.6582 24.1852 13.2654C23.9274 15.8725 22.7737 18.3098 20.9211 20.1623C19.8664 21.2196 18.6127 22.0573 17.2321 22.6268C15.8515 23.1963 14.3715 23.4864 12.8781 23.4803ZM12.5002 16.4983V17.4983H13.5002V16.4983H12.5002ZM12.5002 6.4983V13.4983H13.5002V6.4983H12.5002Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="1.4"
      />
    </svg>
  );
}
