import moment from "moment";

export const CREATE_REMARK_INITIALS = {
  name: "",
  description: "",
  resolve_date: moment().toISOString(), 
};

export const remarkStatusSwitcher = {
  created: "К устранению",
  review: "На проверке",  
  accepted: "Принято",
};

export const detailRemarkStatusSwitcher = {
  created: "Создано",
  resolve: "На доработку",  
  accepted: "Устранено",
}
