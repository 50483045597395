import { Dispatch } from "redux";

import { planFactSummaryApi } from "./api";
import { planFactSummaryActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getPlanFactSummaryExtraCosts = (objectId: string) => (dispatch: Dispatch, getState: () => RootState) => {
  const loadingsKey = generateStorageKey({ objectId, type: "extraCosts" });
  if (getState().planFactReducer.planFactSummaryReducer.loadings[loadingsKey] === undefined) {
    dispatch(planFactSummaryActions.setIsLoading({ key: loadingsKey, status: true }));
  }
  planFactSummaryApi
    .getExtraCosts(objectId)
    .then(({ data }) => {
      dispatch(planFactSummaryActions.setExtraCosts({ key: objectId, data: data.results }));
    })
    .finally(() => {
      dispatch(planFactSummaryActions.setIsLoading({ key: loadingsKey, status: false }));
    });
};
