import axios from "axios";

import { IPlanFactExtraCostRaw } from "./types";
import { IListResponseData } from "types/ListResponse";

export const planFactSummaryApi = {
  getExtraCosts: (objectId: string) => {
    return axios.get<IListResponseData<IPlanFactExtraCostRaw>>(`/buildings/${objectId}/finances/extra_costs/tree/`, {
      params: { limit: 500 },
    });
  },
};
