export const IN_WORK_ORDERS_STATUSES = [
  "new",
  "payment_decision",
  "pcr_need_approve",
  "pvr_need_approve",
  "pvr_rejected",
  "pcr_rejected",
];

export const COMPLETED_ORDERS_STATUSES = ["completed"];

export const ACCEPTED_ORDERS_STATUSES = ["waiting_payment", "waiting_shipment"];

export enum OrdersListTabsNamesEnum {
  acceptedOrders = "acceptedOrders",
  completedOrders = "completedOrders",
  inWorkOrders = "inWorkOrders",
}

export const ordersListTabStatuses = {
  [OrdersListTabsNamesEnum.acceptedOrders]: ACCEPTED_ORDERS_STATUSES,
  [OrdersListTabsNamesEnum.completedOrders]: COMPLETED_ORDERS_STATUSES,
  [OrdersListTabsNamesEnum.inWorkOrders]: IN_WORK_ORDERS_STATUSES,
};
