import EnvironmentSvg from './img/environment.svg';
import PlanshetSvg from './img/envi-plansh.svg';
import TrationSvg from './img/tration-mob.svg';

export const EnvironmentSection= () => {
  return (
    <section id="environment" className="environment__main">
      <div className="container">
        <div className="environment__title">
          <h2>Общая среда данных</h2>
          <p>Сотрудники на стройплощадке и в офисе обмениваются данными в общей среде</p>
        </div>
      </div>
      <div className="environment__photo">
        <img src={EnvironmentSvg} className="environment__dec" alt="image"/>
        <img src={PlanshetSvg} className="environment__planshet" alt="image"/>
        <img src={TrationSvg} className="environment__mob" alt="image"/>
      </div>
      <div className="environment__info">
          <span
            className="title-bot">Получайте данные от всех участников строительства в режиме реального времени</span>
      </div>
    </section>
  )
}