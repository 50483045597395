import axios, { AxiosRequestConfig } from "axios";
import { DeepPartial } from "redux";

import ISection from "../../../../../types/interfaces/Section";
import { ESTIMATE_STATES_IDS } from "../../ProHandler/constants";
import { EEstimateStatesIds, EstimateItemsStatusesEnum } from "../../ProHandler/enums";
import { ICreateOrUpdateDefaultExpenditureBody, ICreateOrUpdateExpenditureInDraftBody } from "../types";
import { IListResponseData } from "types/ListResponse";
import { IExpenditure } from "types/interfaces/Expenditure";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

const ESTIMATE_ITEMS_LIMIT = 500;

export const simpleHandlerAPI = {
  getSections: (
    buildingId: string | number,
    estimateStateId: EEstimateStatesIds,
    params: Record<string, string | number> = {}
  ) => {
    const url =
      estimateStateId === EEstimateStatesIds.PRODUCTION
        ? `/building/${buildingId}/estimate/sections/`
        : `/building/${buildingId}/estimate/${estimateStateId}/sections/`;
    return axios
      .get<IListResponseData<ISection>>(url, {
        params: { limit: ESTIMATE_ITEMS_LIMIT, ...params },
      })
      .then(({ data }) => data)
      .catch(errorCatcher);
  },

  getSubSections: (
    buildingId: string | number,
    parentSectionId: number,
    estimateStateId: EEstimateStatesIds,
    params: Record<string, string | number> = {}
  ) => {
    const url =
      estimateStateId === EEstimateStatesIds.PRODUCTION
        ? `/building/${buildingId}/estimate/sections/`
        : `/building/${buildingId}/estimate/${estimateStateId}/sections/`;
    return axios
      .get<IListResponseData<ISection>>(url, {
        params: { limit: ESTIMATE_ITEMS_LIMIT, parent: parentSectionId, ...params },
      })
      .then(({ data }) => data)
      .catch(errorCatcher);
  },

  getSection: (buildingId: string | number, sectionId: string | number, estimateStateId: EEstimateStatesIds) => {
    const url =
      estimateStateId === EEstimateStatesIds.PRODUCTION
        ? `/building/${buildingId}/estimate/items/${sectionId}/`
        : `/building/${buildingId}/estimate/${estimateStateId}/sections/${sectionId}/`;

    return axios
      .get<ISection>(url, {
        params: { limit: ESTIMATE_ITEMS_LIMIT },
      })
      .then(({ data }) => data)
      .catch(errorCatcher);
  },

  getExpenditures: (
    buildingId: string | number,
    sectionId: string | number,
    state = EEstimateStatesIds.DRAFT,
    config: Partial<AxiosRequestConfig> = {}
  ) => {
    let url;
    const additionalParams: Record<string, string | number> = {};

    if (state === EEstimateStatesIds.DRAFT) {
      url = `/building/${buildingId}/estimate/${state}/sections/${sectionId}/expenditures/`;
    } else if (state === EEstimateStatesIds.LOCALE) {
      url = `/building/${buildingId}/estimate/${state}/expenditures/`;
      additionalParams.section = sectionId;
    } else {
      // EEstimateStatesIds.PRODUCTION
      url = `/building/${buildingId}/estimate/expenditures/`;
      additionalParams.section = sectionId;
    }

    return axios
      .get<IListResponseData<IExpenditure>>(url, {
        params: { limit: ESTIMATE_ITEMS_LIMIT, ...additionalParams },
        ...config,
      })
      .then(({ data }) => data)
      .catch(errorCatcher);
  },

  changeEstimateItemsStateToProduction: (
    buildingId: string | number,
    payload: {
      ids: number[];
      state: EEstimateStatesIds;
    }
  ) => {
    return axios.post<{
      confirmed_ids: number[];
    }>(`/building/${buildingId}/estimate/items/confirm/`, payload);
  },

  changeEstimateItemsState: (
    buildingId: string | number,
    payload: {
      from_state: EEstimateStatesIds;
      ids: number[];
      state: EEstimateStatesIds;
    }
  ) => {
    let from_state: EEstimateStatesIds | "" = payload.from_state;
    if (payload.from_state === ESTIMATE_STATES_IDS.DRAFT) {
      from_state = "";
    }
    return axios.post<{ count_updated: number }>(`/building/${buildingId}/estimate/change_state/`, {
      ...payload,
      from_state,
    });
  },

  createSectionInDraft: (
    buildingId: string | number,
    section: {
      name: string;
      parent: number | string | null;
    },
    isDefault?: boolean
  ) => {
    if (isDefault) {
      return axios.post<ISection>(`/building/${buildingId}/estimate/sections/default/new/`, {
        name: section.name,
        parent_id: section.parent,
      });
    }

    return axios.post<ISection>(`/building/${buildingId}/estimate/draft/sections/`, section);
  },

  changeEstimateItemsStatus: (
    buildingId: string | number,
    payload: {
      ids: number[];
      from_state: EEstimateStatesIds;
      status: EstimateItemsStatusesEnum;
    }
  ) => {
    let from_state: EEstimateStatesIds | "" = payload.from_state;
    if (payload.from_state === ESTIMATE_STATES_IDS.DRAFT) {
      from_state = "";
    }
    return axios.post<{
      result: { updated_count: number };
    }>(`/building/${buildingId}/estimate/change_status/`, {
      ...payload,
      from_state,
    });
  },

  setMarkup: (id: number, objectId: string | number, markup_cost: number) => {
    return axios.post<{
      markup_cost: string;
    }>(`/building/${objectId}/estimateitem/${id}/set_markup/`, {
      markup_cost,
    });
  },

  setDiscountCost: (id: number, objectId: string | number, discount_cost: number) => {
    return axios.post<ISetDiscountCostResponse>(`/building/${objectId}/estimateitem/${id}/set_discount/`, {
      discount_cost,
    });
  },

  setCost: (id: number, objectId: string | number, estimated_cost: number) => {
    return axios.post<{ estimated_cost: string }>(`/building/${objectId}/estimateitem/${id}/set_cost/`, {
      estimated_cost,
    });
  },

  deleteSection: (buildingId: string, sectionId: ISection["id"], estimateStateId: EEstimateStatesIds) => {
    return axios.delete(`/building/${buildingId}/estimate/${estimateStateId}/delete/${sectionId}/`);
  },

  deleteDefaultSection: (buildingId: string, sectionId: ISection["id"]) => {
    return axios.delete(`/building/${buildingId}/estimateitem/${sectionId}/delete_default/`);
  },

  patchSection: (
    buildingId: string,
    estimateStateId: EEstimateStatesIds | ISection["current_state"],
    changedSection: DeepPartial<ISection>
  ) => {
    return axios.patch(
      `/building/${buildingId}/estimate/${!estimateStateId ? "draft" : estimateStateId}/sections/${changedSection.id}/`,
      changedSection
    );
  },

  deleteExpendituresList: ({ buildingId, state, ids }: IDeleteExpendituresParams) => {
    return axios.post(`/building/${buildingId}/estimate/expenditures/${state}/list/delete/`, { expenditures: ids });
  },

  createDraftExpenditures: (buildingId: number | string, body: ICreateOrUpdateExpenditureInDraftBody[]) => {
    return axios.post<IExpenditure[]>(`/building/${buildingId}/estimate/expenditures/draft/list/new/`, {
      expenditures: body,
    });
  },

  editDraftExpenditure: (
    buildingId: number | string,
    expenditureId: number,
    body: ICreateOrUpdateExpenditureInDraftBody
  ) => {
    return axios.patch(`/building/${buildingId}/estimate/expenditures/draft/new/${expenditureId}/`, body);
  },

  createDefaultExpenditures: (buildingId: number | string, body: ICreateOrUpdateDefaultExpenditureBody[]) => {
    return axios.post<IExpenditure[]>(`/building/${buildingId}/estimate/expenditures/default/list/new/`, {
      expenditures: body,
    });
  },

  editDefaultExpenditure: (
    buildingId: number | string,
    expenditureId: number,
    body: ICreateOrUpdateDefaultExpenditureBody
  ) => {
    return axios.patch(`/building/${buildingId}/estimate/expenditures/default/new/${expenditureId}/`, body);
  },

  deleteExpenditure: (buildingId: number | string, state: string, id: number) => {
    return axios.delete(`/building/${buildingId}/estimate/${state}/expenditures/${id}/`);
  },
};

interface IDeleteExpendituresParams {
  buildingId: string | number;
  state: "draft" | "default";
  ids: number[];
}
export interface ISetDiscountCostResponse {
  discount_cost: string;
  discount_percent: string;
  estimate_amount: string;
  estimated_cost: string;
  id: number;
  price: null | string;
  price_source: null | string;
  section: null | {
    discount_cost: string;
    discount_percent: string;
    estimate_amount: string;
    estimated_cost: string;
    id: number;
    price: null;
    price_source: null;
  };
}
