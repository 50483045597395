import { message } from "antd";
import axios, { AxiosResponse } from "axios";
import { useCallback, useState } from "react";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";

export interface IUseCheckUploadStatus {
  buildingId: number | string;
  onComplete?: () => void;
}

export interface ICheckUploadStatusResponse {
  id: number | string;
  status: string;
}

export const useCheckUploadStatus = ({ buildingId, onComplete }: IUseCheckUploadStatus) => {
  const [isUploading, setIsUploading] = useState(false);

  const checkUploadStatus = useCallback(
    (response: AxiosResponse<ICheckUploadStatusResponse>) => {
      const timeoutCheckUploadStatus = (res: AxiosResponse<ICheckUploadStatusResponse>) =>
        setTimeout(() => checkUploadStatus(res), 4000);

      if (response.data.status === "processing") {
        axios
          .get<ICheckUploadStatusResponse>(`/building/${buildingId}/estimates/${response.data.id}/`)
          .then((res) => timeoutCheckUploadStatus(res))
          .catch(errorCatcher);
        return;
      }
      if (response.data.status === "complete") {
        if (onComplete) onComplete();
        message.success(`Файл обработан успешно`);
        setIsUploading(false);
        return;
      }
      setIsUploading(false);
      message.error(`Файл обработан c ошибками`);
    },
    [onComplete, buildingId]
  );

  const beginUploading = useCallback(() => setIsUploading(true), []);

  return {
    checkUploadStatus,
    isUploading,
    beginUploading,
  };
};
