import cn from "classnames";
import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { loadAggregations } from "../../../../../../../../../Aggregations/model/thunks";
import { simpleHandlerActions } from "../../../../../../../../model/slice";
import { reloadActiveSubSection, setMarkup } from "../../../../../../../../model/thunks";
import { useMarkupDoubleInput } from "./useMarkupDoubleInput";
import DoubleInput, { Measure } from "shared/ui/inputs/DoubleInput/DoubleInput";

import { IExpenditure } from "../../../../../../../../../../../../types/interfaces/Expenditure";

import { beautifyNumberValue } from "../../../../../../../../../../../../utils/formatters/beautifyNumberValue";
import { transformDigitToFinancial } from "../../../../../../../../../../../../utils/formatters/transformDigitToFinancial";

import blackCheckIcon from "../../../../../../../../../../../../images/icons/blackCheckIcon";
import blueCheckIcon from "../../../../../../../../../../../../images/icons/blueCheckIcon";

import styles from "../../Expenditure.module.scss";

interface IProps {
  expenditure: IExpenditure;
}

export const MarkupColumns = memo<IProps>(({ expenditure }) => {
  const dispatch = useDispatch();

  const amountSource = +(
    expenditure.indicators?.estimate_amount_source ??
    expenditure.indicators?.estimate_amount ??
    0
  );

  const cost = +(expenditure?.markup_cost ?? 0);
  const calculatedPercent = amountSource === 0 ? 0 : (cost * 100) / amountSource;
  const percent = expenditure?.markup_percent !== undefined ? +expenditure.markup_percent : calculatedPercent;

  const changeCost = useCallback(
    (v: number | string) => {
      dispatch(
        setMarkup(expenditure.id, +v, (markup_cost) => {
          const markup_percent = ((+markup_cost / amountSource) * 100).toFixed(2);
          dispatch(
            simpleHandlerActions.setExpenditureMarkup({
              id: expenditure.id,
              markup_cost,
              markup_percent,
            })
          );
          dispatch(reloadActiveSubSection());
          dispatch(loadAggregations());
        })
      );
    },
    [dispatch, expenditure]
  );

  const { changedCost, changedPercent, onChangePercent, onChangeCost } = useMarkupDoubleInput({
    amountSource: amountSource,
    percent,
    cost,
    changeCost,
  });

  return (
    <>
      <div className={cn(styles.column, styles.isCentered)}>
        {expenditure.count} ({expenditure.measure})
      </div>
      <div className={cn(styles.column, styles.isCentered)}>
        {transformDigitToFinancial(amountSource)}
        <br />({transformDigitToFinancial(+amountSource / +expenditure.count)} за ед.)
      </div>
      <div className={cn(styles.column, styles.isCentered)}>
        <div className={styles.discount}>
          <DoubleInput
            firstValue={(changedPercent ?? 0).toString()}
            secondValue={(changedCost ?? 0).toString()}
            firstInputSize={1}
            secondInputSize={2}
            onChangeFirstValue={onChangePercent}
            onChangeSecondValue={onChangeCost}
            firstValueMeasure={Measure.percent}
            secondValueMeasure={Measure.currency}
          />
          {+beautifyNumberValue(changedCost) !== 0 ? (
            {
              ...blueCheckIcon,
              props: { ...blueCheckIcon.props, width: 18, height: 18 },
            }
          ) : (
            <span className={styles.withoutDiscountIcon} title="Наценка отсутствует">
              {{
                ...blackCheckIcon,
                props: { ...blackCheckIcon.props, width: 18, height: 18 },
              }}
            </span>
          )}
        </div>
      </div>
      <div className={cn(styles.column, styles.isCentered)}>
        {transformDigitToFinancial(+amountSource + +beautifyNumberValue(changedCost))}
        <br />({transformDigitToFinancial((+amountSource + +beautifyNumberValue(changedCost)) / +expenditure.count)} за
        ед.)
      </div>
    </>
  );
});

MarkupColumns.displayName = "MarkupColumns";
