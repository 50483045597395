import axios from "axios";

import { IchatMessageResponse, IgetChatMessagesParams } from "./types";
import { IListResponseData } from "types/ListResponse";
import { IEmployee } from "types/personsTypes";

import { roomFormatter } from "./utils";

export const chatApi = {
  getMessages: (params: IgetChatMessagesParams, optionalParams: Record<string, number | string> | null = null) => {
    return axios.get<IListResponseData<IchatMessageResponse>>(`/chat_v2/${params.building_id}/${params.room}/`, {
      params: { limit: 100, ...optionalParams },
    });
  },
  postMessage: (params: IgetChatMessagesParams, text: string, mentions: number[]) => {
    return axios.post<IchatMessageResponse>(
      params?.item_id
        ? `/chat_v2/${roomFormatter(params.room)}/${params?.item_id}/`
        : `/chat_v2/${params.building_id}/${params.room}/`,
      { text, mentions }
    );
  },
  getSuggectedUsers: (buildingId: number, search: string) => {
    return axios.get<IEmployee[]>(`/building/${buildingId}/employees/`, { params: { search } });
  },
};
