import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IDetailedProject, IPropjectInList } from "./types";

export interface ProjectsState {
  isLoading: Record<string, boolean>;
  invalidateKey: number;
  projectsLists: Record<string, IPropjectInList[]>;
  projectsListsCounts: Record<string, number>;
  projectsDetailed: Record<string, IDetailedProject>;
}

const initialState: ProjectsState = {
  isLoading: {},
  invalidateKey: 0,
  projectsLists: {},
  projectsListsCounts: {},
  projectsDetailed: {},
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      const { key, status } = action.payload;
      state.isLoading[key] = status;
    },
    invalidateKey: (state) => {
      state.invalidateKey++;
    },
    setProjectLists: (state, action: PayloadAction<{ key: string; projects: IPropjectInList[]; count: number }>) => {
      const { key, projects, count } = action.payload;
      state.projectsLists[key] = projects;
      state.projectsListsCounts[key] = count;
    },
    setDetailed: (state, action: PayloadAction<{ key: string; project: IDetailedProject }>) => {
      const { key, project } = action.payload;
      state.projectsDetailed[key] = project;
    },
  },
});

export const projectsActions = projectsSlice.actions;

export default projectsSlice.reducer;
