import React, { PropsWithChildren, ReactNode } from "react";
import { useDispatch } from "react-redux";

import List from "pages/Finance/_TODO/List/List";
import ListRow from "pages/Finance/_TODO/List/_internal/ListRow";
import { useSummaryScroll } from "pages/Finance/_TODO/useSummaryScroll";
import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import { toggleSummaryTree } from "../../model/thunks";
import { useSummaryTreeSelector } from "../../model/useSummaryTreeSelector";
import { useTypedSelector } from "app/store/typedUseSelector";

import styles from "./ListSide.module.scss";

export interface IListSideProps extends PropsWithChildren {
  additionalHeader?: ReactNode;
}

const ListSide: React.FC<IListSideProps> = ({ children, additionalHeader }) => {
  const dispatch = useDispatch();
  const loadings = useTypedSelector((state) => state.financeReducer.summary.loadings);
  const { year } = useSummaryScroll();

  const handleListItemClick = (subTree: IFinanceTreeItem) => {
    dispatch(toggleSummaryTree(subTree, year));
  };

  const { displayingTree, displayingTotals, showSpinner } = useSummaryTreeSelector();

  return (
    <div className={styles.listSide}>
      <div className={styles.sticky}>
        {children}
        <div className={styles.listSide__header}>
          <span className={styles.listSide__title}>Сводные показатели</span>
          {additionalHeader ?? <div style={{ height: "calc(2rem + 1px)" }}></div>}
        </div>
      </div>
      <div className={styles.listSide__list}>
        {!showSpinner && <List data={displayingTree!} onClickItem={handleListItemClick} loadings={loadings} />}
        {!!displayingTotals && (
          <ListRow
            data={displayingTotals?.[0]}
            depth={2}
            handleListItemClick={handleListItemClick}
            className={styles.total}
          />
        )}
      </div>
    </div>
  );
};

export default ListSide;
