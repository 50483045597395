import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

export const stateSelector = (state: RootState) => state.viewCheckpointState;

export const checkpointsViewListSelector = createSelector(stateSelector, (state) => state.list);
export const checkpointsViewDetailsSelector = createSelector(stateSelector, (state) => state.details);
export const checkpointsViewDatesSelector = createSelector(stateSelector, (state) => state.dates);
export const checkpointsViewIsOpenSelector = createSelector(stateSelector, (state) => state.isOpen);
export const checkpointsViewIsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
