import { useMemo } from "react";
import { useParams } from "react-router-dom";

import {
  VIEW_FINANCE_AUDIT,
  VIEW_FINANCE_FORECAST,
  VIEW_FINANCE_SUMMARY,
} from "constants/permissions/constructingPermissions";

import usePermission from "hooks/usePermission";

export const useGetFinanceTabs = () => {
  const { objectId, projectId } = useParams<Record<"objectId" | "projectId", string>>();
  const haveViewFinanceAuditPermission = usePermission(VIEW_FINANCE_AUDIT);
  const haveViewFinanceSummaryPermission = usePermission(VIEW_FINANCE_SUMMARY);
  const haveViewFinanceForecastPermission = usePermission(VIEW_FINANCE_FORECAST);

  const tabs = useMemo(
    () => [
      {
        text: "Аудит",
        link: `/constructing/finance/${projectId}/${objectId}/audit`,
        isHidden: !haveViewFinanceAuditPermission,
      },
      {
        text: "Сводная",
        link: `/constructing/finance/${projectId}/${objectId}/summary`,
        isHidden: !haveViewFinanceSummaryPermission,
      },
      {
        text: "Прогноз",
        link: `/constructing/finance/${projectId}/${objectId}/forecast`,
        isHidden: !haveViewFinanceForecastPermission,
      },
    ],
    [
      projectId,
      objectId,
      haveViewFinanceAuditPermission,
      haveViewFinanceSummaryPermission,
      haveViewFinanceForecastPermission,
    ]
  );

  const ableTab = useMemo(() => tabs.filter((tab) => !tab.isHidden)?.[0]?.link, [tabs]);

  return { tabs, ableTab };
};
