import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { journalExecutionActions } from "redux/modules/common/building/journal/journalExecution/actions";
import {
  remarksListIsLoadingSelector,
  remarksListResultsSelector,
} from "redux/modules/common/building/journal/journalExecution/selectors";
import {
  getJournalDeliveryExecutionRemarksList,
  getJournalExecutionRemarksList
} from "redux/modules/common/building/journal/journalExecution/thunks";

import { IRouterParamsWithObjectId } from "types/routerTypes";

export interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  expenditureId: number;
  isShowOnlyAccepted: boolean;
  defaultRemarkId?: number;
  isGroup?: boolean;
  isDelivery?: boolean;
}

export const useRemarkModal = ({ isOpen, setIsOpen, expenditureId, isShowOnlyAccepted, defaultRemarkId, isGroup, isDelivery }: IProps) => {
  const dispatch = useDispatch();
  const params = useParams<IRouterParamsWithObjectId>();
  const buildingId = params.objectId;

  const remarksList = useSelector(remarksListResultsSelector);
  const isRemarksListLoading = useSelector(remarksListIsLoadingSelector);

  const [isOpenCurrentRemark, setIsOpenCurrentRemark] = useState(false);
  const [currentRemarkId, setCurrentRemarkId] = useState<number | null>(null);

  useEffect(() => {
    if (!isOpen || currentRemarkId) return;

    if (isDelivery) {
      dispatch(getJournalDeliveryExecutionRemarksList(buildingId, expenditureId, isShowOnlyAccepted, isGroup));
    } else {
      dispatch(getJournalExecutionRemarksList(buildingId, expenditureId, isShowOnlyAccepted, isGroup));
    }
  }, [isOpen, currentRemarkId, isOpen]);

  useEffect(() => {
    if (remarksList.length !== 1 || currentRemarkId) return;

    setCurrentRemarkId(remarksList[0].id);
    setIsOpenCurrentRemark(true);
  }, [remarksList, currentRemarkId]);

  const handleOpen = (remarkId: number) => {
    setIsOpenCurrentRemark(true);
    setCurrentRemarkId(remarkId);
    dispatch(journalExecutionActions.setRemarksListData([]));
  };

  const handleCloseDetailInfo = () => {
    dispatch(journalExecutionActions.setRemarkDetailInfoData(null));
    dispatch(journalExecutionActions.setRemarksListData([]));
    setCurrentRemarkId(null);
    setIsOpenCurrentRemark(false);
  };

  const closeModal = () => {
    setIsOpen(false);

    setTimeout(() => {
      handleCloseDetailInfo();
    }, 500);
  };

  useEffect(() => {
    setCurrentRemarkId(null);
    setIsOpenCurrentRemark(false);
  }, [isOpen]);

  useEffect(() => {
    if (!defaultRemarkId || currentRemarkId) return;
    handleOpen(defaultRemarkId);
  }, [defaultRemarkId, currentRemarkId]);

  return {
    closeModal,
    handleOpen,
    handleCloseDetailInfo,
    isOpenCurrentRemark,
    currentRemarkId,
    remarksList,
    isRemarksListLoading,
  };
};
