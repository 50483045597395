import { Component } from "react";
import ReactDOM from "react-dom";

export default class Portal extends Component {
  element = void 0;

  componentDidMount() {
    this.element = document.querySelector("#modal");
    this.forceUpdate();
  }

  render() {
    if (!this.element) return null;
    return ReactDOM.createPortal(this.props.children, this.element);
  }
}
