import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { IRouterParamsWithObjectId } from "types/routerTypes";

export const useLastObjectId = () => {
  const location = useLocation();
  const { objectId } = useParams<IRouterParamsWithObjectId>();

  useEffect(() => {
    if (objectId && objectId !== "undefined" && !isNaN(Number(objectId))) {
      localStorage.setItem("lastObjectId", objectId);
    }
  }, [objectId, location]);
};
