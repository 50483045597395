export const enum ResourceTypeEnum {
  material = "material",
  equipment = "equipment",
  machine = "machine",
  transport = "transport",
}

export interface ISimpleResource {
  id: number;
  name: string;
  type: ResourceTypeEnum;
  type_display: string;
  count: string;
  measure: string;
}

export interface ISimpleResourceCreate {
  name: string;
  type: ResourceTypeEnum;
  count?: string;
  measure: string;
  fact_work?: number;
  fact_group?: number;
}

export interface ISimpleResourceUpdate extends Pick<ISimpleResourceCreate, 'name' | 'count' | 'measure' | 'type'> {}


export interface IGetSimpleResourcesArgs {
  buildingId: string | number;
  fact_work_id?: number;
  fact_group_id?: number;
  group_id?: number;
  expenditure_id?: number;
  start_at?: string;
  end_at?: string;
  ordering?: string;
  limit?: number;
  offset?: number;
}
