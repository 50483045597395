import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ksSublistSelector } from "redux/modules/common/building/documents/ksList/selectors";
import { getKsSublist } from "redux/modules/common/building/documents/ksList/thunks";

import KsListSubRow from "./KsListSubRow";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";

import styles from "./KsStatus.module.scss";

interface IProps {
  ks3Id: number;
}

const KsListContent: React.FC<IProps> = ({ ks3Id }) => {
  const dispatch = useDispatch();
  const ksSublist = useSelector(ksSublistSelector)[ks3Id];

  React.useEffect(() => {
    dispatch(getKsSublist(ks3Id));
  }, [ks3Id]);

  return (
    <>
      {ksSublist?.isLoading && <Spinner isStatic isSmall />}
      {ksSublist?.ks2_acts?.map((el) => (
        <KsListSubRow key={el.id} act={el} />
      ))}
    </>
  );
};

export default React.memo(KsListContent);
