import React from "react";
import { Field } from "react-final-form";

import { IAook } from "redux/modules/common/building/journal/aook/types";

import Select from "components/UI/atoms/Select";

import AddButton from "shared/ui/controls/AddButton/AddButton";
import FileRow from "shared/ui/dataDisplay/FileRow/FileRow";

import { IFile } from "types/interfaces/Files";

import styles from "./AookSelectAosrs.module.scss";

interface IProps {
  allHiddenworks: IAook["all_hiddenworks"];
  selectedHiddenworksIds: number[];
  isDisabled?: boolean;
}

const AookSelectAosrs: React.FC<IProps> = ({ allHiddenworks, selectedHiddenworksIds, isDisabled }) => {
  const options = React.useMemo(() => {
    if (!allHiddenworks) return [];
    const selected = selectedHiddenworksIds ?? [];
    return allHiddenworks
      ?.filter((el) => !selected.includes(el.id))
      ?.map((el) => ({
        id: el.id,
        name: el.title,
      }));
  }, [allHiddenworks, selectedHiddenworksIds]);

  const selectedHiddenworks = React.useMemo<IFile[]>(() => {
    if (!allHiddenworks) return [];
    const selected = selectedHiddenworksIds ?? [];
    return allHiddenworks
      ?.filter((el) => selected.includes(el.id))
      ?.map((el) => ({
        id: el.id,
        name: `АОСР №${el.number} от ${el.act_date}`,
      })) as IFile[];
  }, [selectedHiddenworksIds, allHiddenworks]);

  const [isAdding, setIsAdding] = React.useState(false);

  const canAdding = !isAdding && !!options.length;

  if (!allHiddenworks?.length) return null;

  return (
    <Field
      name="hiddenworks"
      render={({ input }) => (
        <div className={styles.container}>
          <div className={styles.title}>Освидетельствованные скрытые работы</div>
          {!isDisabled && canAdding && (
            <AddButton onClick={() => setIsAdding(true)} className={styles.add} textPosition="left" />
          )}
          {isAdding && (
            <Select
              options={options}
              onChange={(id) => {
                const values = input.value || [];
                input.onChange([...values, id]);
                setIsAdding(false);
              }}
            />
          )}
          {selectedHiddenworks?.map((el) => (
            <FileRow
              key={el.id}
              file={el}
              canDownload={false}
              canRemove={!isDisabled}
              onDirectlyRemove={() => {
                const newValue = [...(input.value || [])].filter((id) => id != el.id);
                input.onChange(newValue);
              }}
            />
          ))}
        </div>
      )}
    />
  );
};

export default React.memo(AookSelectAosrs);
