import cn from "classnames";
import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";
import TableReusableRow from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { IEmployee } from "types/personsTypes";

import styles from "./AddOneFromTable.module.scss";

export interface IProps {
  rowList: IEmployee[];
  headerCellName: string;
  onRowClick: (item: IEmployee) => void;
}

const AddOneFromTable: React.FC<IProps> = ({ rowList, headerCellName, onRowClick }) => {
  return (
    <>
      <TableReusableHead className={styles.head}>
        <TableReusableHeaderCell>{headerCellName}</TableReusableHeaderCell>
      </TableReusableHead>
      {rowList?.length > 0 &&
        rowList.map((item, index) => (
          <TableReusableRow
            key={item.id}
            containerClassName={cn(styles.rowContainer, { [styles.whiteBackground]: index % 2 })}
            className={styles.header}
            onClick={() => onRowClick(item)}
          >
            {item.last_name} {item.first_name} {item.middle_name} {item.full_name}
          </TableReusableRow>
        ))}
    </>
  );
};

export default React.memo(AddOneFromTable);
