import React from 'react'
import styles from "./EmptyProgressBlock.module.scss"

function EmptyProgressBlock() {
  return (
    <div className={styles.block}>
        Нет сведений
        <div className={styles.bar}></div>
    </div>
  )
}

export default EmptyProgressBlock