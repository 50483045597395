import cn from "classnames";
import React from "react";

import { acceptSvg, declineSvg } from "./icons";

import styles from "./ConfirmBlock.module.scss";

interface IProps {
  count?: number;
  onAccept: React.MouseEventHandler;
  onDecline: React.MouseEventHandler;
  className?: string;
  onCommonClick?: React.MouseEventHandler;
  isWithoutCount?: boolean;
  disabled?: boolean;
}

const ConfirmBlock: React.FC<IProps> = ({
  count,
  onAccept,
  onDecline,
  className,
  onCommonClick,
  isWithoutCount,
  disabled,
}) => {
  return (
    <div className={cn(styles.container, className)} onClick={onCommonClick}>
      <button onClick={onDecline} disabled={disabled}>
        {declineSvg}
        Отменить
      </button>
      <button onClick={onAccept} disabled={disabled || (!count && !isWithoutCount)}>
        {acceptSvg}
        {isWithoutCount ? "Подтвердить" : `Подтвердить: ${count || 0}`}
      </button>
    </div>
  );
};

export default ConfirmBlock;
