import { message } from "antd";
import axios from "axios";
import { Dispatch } from "redux";

import { projectsActions } from "pages/Projects/model/projectsSlice";
import { IDetailedProject } from "pages/Projects/model/types";

import { settingsProjectApi } from "./settingsProjectApi";
import { settingsProjectActions } from "./slice";

import { errorCatcher } from "utils/helpers/errorCatcher";

interface IConfig {
  successCallback?: () => void;
  successMessage?: string;
}

export const editStock =
  (stockId: number, stockData: Partial<IDetailedProject["stock"]>, { successCallback, successMessage }: IConfig = {}) =>
  (dispatch: Dispatch) => {
    axios
      .patch(`/stocks/${stockId}/`, stockData)
      .then(() => {
        message.success(successMessage ?? "Добавлено");
        successCallback?.();
        dispatch(projectsActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {});
  };

export const getConstControlAccounts = () => (dispatch: Dispatch) => {
  settingsProjectApi.getConstControlAccounts().then(({ data }) => {
    dispatch(settingsProjectActions.setConstrContrAccounts(data.results));
  });
};

export const attachConstrControlToBuilding =
  (accountId: number, buildingId: string | number) => (dispatch: Dispatch) => {
    settingsProjectApi
      .attachConstrControlToBuilding(accountId, buildingId)
      .then(() => {
        message.success("Сохранено");
      })
      .catch(errorCatcher)
      .finally(() => {});
  };
