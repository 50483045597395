import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  analyticsBudgetDataSelector,
  analyticsInvalidateKeySelector,
  analyticsLoadingsSelector,
} from "../../../../../redux/modules/common/analytics/selectors";
import { loadAnalyticsBudget } from "../../../../../redux/modules/common/analytics/thunks";

import { useTypedSelector } from "../../../../../app/store/typedUseSelector";
import Island, { IIslandElement } from "../Island/Island";
import AnalyticsChartLegend from "../charts/AnalyticsChartLegend/AnalyticsChartLegend";
import AnalyticsChartTick from "../charts/AnalyticsChartTick/AnalyticsChartTick";
import AnalyticsChartTooltip from "../charts/AnalyticsChartTooltip/AnalyticsChartTooltip";
import FullScreenBtn from "../charts/FullScreenBtn/FullScreenBtn";
import ZoomOutBtn from "../charts/ZoomOutBtn/ZoomOutBtn";
import useAnalyticsFullScreen from "../charts/useAnalyticsFullScreen";
import { useChartSlice } from "../charts/useChartSlice";

import {
  CHART_BASE_PROPS,
  CHART_MAIN_ELEMENT_BASE_PROPS,
  GRID_BASE_PROPS,
  XAXIS_BASE_PROPS,
  YAXIS_BASE_PROPS,
} from "../charts/constants";
import { CHART_COLORS, matchChartLabels } from "./constants";

import { formatDatesForChart } from "../charts/utils";

import commonStyles from "../../Analytics.module.scss";
import styles from "./AnalyticsBudget.module.scss";

const AnalyticsBudget: React.FC<IIslandElement> = ({ islandParentRef }) => {
  const dispatch = useDispatch();
  const invalidateKey = useTypedSelector(analyticsInvalidateKeySelector);
  const budgetData = useTypedSelector(analyticsBudgetDataSelector);
  const isLoading = useTypedSelector(analyticsLoadingsSelector)["budget"];

  const { isFullScreen, toggleFullScreen } = useAnalyticsFullScreen();

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(loadAnalyticsBudget(abortController.signal));
    return () => {
      abortController.abort();
    };
  }, [invalidateKey]);

  const formattedData = useMemo(() => formatDatesForChart(budgetData || []), [budgetData]);

  const {
    zoom,
    zoomOut,
    onMouseDown,
    onMouseMove,
    left,
    right,
    bottom,
    top,
    refAreaLeft,
    refAreaRight,
    data,
    isZoomed,
  } = useChartSlice({
    initialData: formattedData,
    xAxisKey: "date",
  });

  return (
    <Island
      islandParentRef={islandParentRef}
      heading={
        <div className={styles.heading}>
          <h3 className={commonStyles.islandHeading}>Финансы</h3>
          {isZoomed && <ZoomOutBtn zoomOut={zoomOut} />}
          <FullScreenBtn isFullScreen={isFullScreen} toggleFullScreen={toggleFullScreen} />
        </div>
      }
      className={styles.budgetIsland}
      isLoading={isLoading}
      isEmpty={!formattedData?.length}
      isFullScreen={isFullScreen}
    >
      <ResponsiveContainer width="99%" height={"99%"}>
        <LineChart
          data={data}
          {...CHART_BASE_PROPS}
          onMouseUp={zoom}
          onMouseMove={onMouseMove}
          onMouseDown={onMouseDown}
        >
          <CartesianGrid {...GRID_BASE_PROPS} />
          <XAxis
            dataKey="date"
            {...XAXIS_BASE_PROPS}
            tick={<AnalyticsChartTick />}
            domain={[left, right]}
            allowDataOverflow
          />
          {/* @ts-ignore */}
          <YAxis {...YAXIS_BASE_PROPS} domain={[bottom, top]} allowDataOverflow />
          <Tooltip
            cursor={{ stroke: CHART_COLORS.CURSOR, strokeWidth: 2 }} /* @ts-ignore */
            content={(props) => <AnalyticsChartTooltip {...props} matchChartLabels={matchChartLabels} />}
          />
          {refAreaLeft && refAreaRight ? (
            <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
          ) : null}
          <Line
            {...CHART_MAIN_ELEMENT_BASE_PROPS}
            type="linear"
            dataKey="estimate"
            stroke={CHART_COLORS.ESTIMATE}
            fill={CHART_COLORS.ESTIMATE}
            dot={false}
            isAnimationActive={!isFullScreen}
          />
          <Line
            {...CHART_MAIN_ELEMENT_BASE_PROPS}
            type="linear"
            dataKey="budget"
            stroke={CHART_COLORS.BUDGET}
            fill={CHART_COLORS.BUDGET}
            dot={false}
            isAnimationActive={!isFullScreen}
          />
          <Line
            {...CHART_MAIN_ELEMENT_BASE_PROPS}
            type="linear"
            dataKey="fact"
            stroke={CHART_COLORS.FACT}
            fill={CHART_COLORS.FACT}
            dot={false}
            isAnimationActive={!isFullScreen}
          />
          <Line
            {...CHART_MAIN_ELEMENT_BASE_PROPS}
            type="linear"
            dataKey="estimated_cost"
            stroke={CHART_COLORS.ESTIMATED_COST}
            fill={CHART_COLORS.ESTIMATED_COST}
            dot={false}
            isAnimationActive={!isFullScreen}
          />
          <Legend
            content={(props) => (
              <AnalyticsChartLegend {...props} columns={isFullScreen ? 4 : 2} centered={isFullScreen} />
            )}
            payload={[
              { value: "Смета", type: "circle", color: CHART_COLORS.ESTIMATE },
              { value: "План", type: "circle", color: CHART_COLORS.ESTIMATED_COST },
              { value: "Бюджет", type: "circle", color: CHART_COLORS.BUDGET },
              { value: "Факт", type: "circle", color: CHART_COLORS.FACT },
            ]}
          />
        </LineChart>
      </ResponsiveContainer>
    </Island>
  );
};

export default React.memo(AnalyticsBudget);
