import cn from "classnames";
import React, { useCallback, useEffect, useState } from "react";

import { getAddingToApproveAmount } from "pages/Handler/ui/ProHandler/utils/getAddingToApproveAmount";

import ApproveModal from "../ApproveModal/ApproveModal";
import HandlerStateCount from "../HandlerStateCount/HandlerStateCount";
import { useDrop } from "react-dnd";

import { ESTIMATE_STATES_IDS } from "../../../ProHandler/constants";
import { SECTIONS_STATUS } from "constants/constant";
import { LOCALIZATION_CONFIG } from "constants/localization";

import { transformDigitToFinancial } from "../../../../../../utils/formatters/transformDigitToFinancial";
import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./ProductionIndicator.module.scss";
import HandlerIndicatorItem from "../HandlerIndicatorItem/HandlerIndicatorItem";

const ProductionIndicator = (props) => {
  const {
    sumEstimateAmount,
    changeEstimateItemsState,
    onClick,
    activeEstimateStateId,
    count,
    isHideInfo = false,
    isShared = false,
    isFullWidth = false
  } = props;

  const amount = transformDigitToFinancial(sumEstimateAmount || 0, { withFloat: false, withCurrencySign: true });

  const [isDropTarget, setIsDropTarget] = useState(false);

  const [{ canDrop, didDrop, droppedItem }, drop] = useDrop(() => ({
    accept: "box",
    drop: () => ({ name: ESTIMATE_STATES_IDS.PRODUCTION }),
    collect: (monitor) => {
      const itemState = monitor?.getItem()?.current_state;
      const status = monitor?.getItem()?.status;

      const isItemStateAllowed = [
        ESTIMATE_STATES_IDS.LOCALE,
        ESTIMATE_STATES_IDS.OBJECT,
        ESTIMATE_STATES_IDS.CONSOLIDATE
      ].indexOf(itemState) !== -1;

      const canDrop = isItemStateAllowed && status === SECTIONS_STATUS.CONFIRMED;

      return {
        isOver: monitor.isOver(),
        canDrop: canDrop,
        didDrop: monitor.getDropResult(),
        droppedItem: monitor.getItem(),
      };
    },
  }));

  if (didDrop?.name === ESTIMATE_STATES_IDS.PRODUCTION && droppedItem && !isDropTarget) {
    setIsDropTarget({ status: ESTIMATE_STATES_IDS.PRODUCTION, item: droppedItem });
  }

  const onApprove = useCallback(
    (isApprove, state) => {
      setIsDropTarget(false);

      if (isApprove) {
        changeEstimateItemsState(
          state,
          isDropTarget?.item?.map((item) => item?.id)
        );
      }
    },
    [changeEstimateItemsState, isDropTarget?.item]
  );

  const handleOnClick = useCallback(() => onClick(ESTIMATE_STATES_IDS.PRODUCTION), [onClick]);

  const [addingSum, setAddingSum] = useState(null);

  useEffect(() => {
    if (droppedItem) {
      setAddingSum(splitThousands(getAddingToApproveAmount(droppedItem)));
    }
  }, [droppedItem]);

  const currentAmount = isHideInfo ? `0 ${LOCALIZATION_CONFIG.currency}` : amount;
  const currentCount = isHideInfo ? 0 : count;

  return (
    <HandlerIndicatorItem
      color="#8472E6"
      isActive={activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION}
      isDisabled={isHideInfo}
      isDropTarget={isDropTarget}
      isFullWidth={isFullWidth}
    >
      <div
        ref={!isShared ? drop : null}
        className={cn(styles.container, { [styles.canDrop]: canDrop })}
        onClick={handleOnClick}
      >
        {!isDropTarget && <div className={styles.title}>Выдано в работу</div>}
        <HandlerStateCount
          count={currentCount}
          amount={currentAmount}
          plus={isDropTarget && addingSum}
          title={"В производстве"}
        />
        <ApproveModal
          activeEstimateStateId={activeEstimateStateId}
          targetEstimateStateId={ESTIMATE_STATES_IDS.PRODUCTION}
          isOpen={isDropTarget?.status === ESTIMATE_STATES_IDS.PRODUCTION}
          onApprove={onApprove}
          items={isDropTarget?.item}
          isFullWidth={isFullWidth}
        />
      </div>
      {activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION && <span className={styles.border} />}
    </HandlerIndicatorItem>
  );
};

export default React.memo(ProductionIndicator);
