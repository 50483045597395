import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  expenditureAttachmentsSelector,
  passportsInvalidateKeySelector,
  passportsPendingSelector,
} from "redux/modules/common/passportsAndCertificates/selectors";
import {
  attachDocumentToExpenditure,
  attachFileToExpenditure,
  attachFileToWorkInJournal,
  deleteAttachmentFromWorkInJournal,
  getExpendituresAttachments,
  getWorkAttachmentsInJournal,
} from "redux/modules/common/passportsAndCertificates/thunks";
import { DocumentsTypesEnum, ICreatePassport } from "redux/modules/common/passportsAndCertificates/types";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import CommonFilesModal from "./CommonFilesModal";

import { IExpenditure } from "types/interfaces/Expenditure";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  expenditureName?: string;
  expenditureId: number;
  expenditureType?: IExpenditure["expenditure_type"];
  month: number;
  year: number;
}

const JournalFilesModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  expenditureName,
  expenditureId,
  expenditureType,
  month,
  year,
}) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const key = generateStorageKey({ buildingId: objectId, expenditureId, month, year });
  const attachments = useSelector(expenditureAttachmentsSelector)[key as any];
  const invalidateKey = useSelector(passportsInvalidateKeySelector);
  const isPending = useSelector(passportsPendingSelector);

  React.useEffect(() => {
    if (!isOpen) return;
    dispatch(getWorkAttachmentsInJournal(objectId, expenditureId, year, month));
  }, [expenditureId, isOpen, invalidateKey, objectId, year, month]);

  const uploadFile = React.useCallback(
    (file: File) => {
      dispatch(
        attachFileToWorkInJournal({
          buildingId: objectId,
          expenditureId,
          month,
          year,
          file: file,
          fileType: DocumentsTypesEnum.file,
        })
      );
    },
    [expenditureId]
  );

  const onSubmit = React.useCallback(
    (values: ICreatePassport) => {
      dispatch(
        attachFileToWorkInJournal({
          buildingId: objectId,
          expenditureId,
          month,
          year,
          file: values.file,
          fileType: values.type,
        })
      );
    },
    [expenditureId]
  );

  const deleteDocument = React.useCallback(
    (docId: number) => {
      dispatch(deleteAttachmentFromWorkInJournal(objectId, docId));
    },
    [objectId]
  );

  return (
    <CommonFilesModal
      isOpen={isOpen}
      onClose={onClose}
      name={expenditureName}
      expenditureType={expenditureType}
      attachments={attachments?.results}
      isPending={isPending}
      expenditureId={expenditureId}
      invalidateKey={invalidateKey}
      onUpload={uploadFile}
      onSubmit={onSubmit}
      onDirectlyDelete={deleteDocument}
    />
  );
};

export default JournalFilesModal;
