import React from "react";
import { useParams } from "react-router-dom";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useLocationArray } from "utils/hooks/useLocationArray";

export const useObjectId = (indexInLocationArray: number = 1) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();

  const locationArr = useLocationArray();

  const objectIdFallback: string = React.useMemo(() => {
    const candidate = locationArr[indexInLocationArray];
    if (isNaN(+candidate)) {
      return "0";
    } else {
      return candidate;
    }
  }, [locationArr]);

  return objectId || objectIdFallback;
};
