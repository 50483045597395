import { useSelector } from "react-redux";

import { aggregationSelector } from "../../Aggregations/model/selectors";

import { AGGERATIONS_TRANSFORM_OPTIONS, ESTIMATE_STATES_IDS } from "../constants";
import { EEstimateStatesIds } from "../enums";

import { transformDigitToFinancial } from "../../../../../utils/formatters/transformDigitToFinancial";

export interface IUseAggregationsProps {
  transformOptions?: any;
  sectionId?: EEstimateStatesIds;
}

export const useAggregations = ({
  transformOptions = AGGERATIONS_TRANSFORM_OPTIONS,
  sectionId,
}: IUseAggregationsProps) => {
  const aggregations = useSelector(aggregationSelector);

  const locale = transformDigitToFinancial(aggregations?.locale_amount_sum || 0, transformOptions);
  const object = transformDigitToFinancial(aggregations?.object_amount_sum || 0, transformOptions);
  const consolidate = transformDigitToFinancial(aggregations?.consolidate_amount_sum || 0, transformOptions);
  const estimate = transformDigitToFinancial(aggregations?.sum_estimate_amount || 0, transformOptions);

  let actualAggregation;
  if (sectionId === ESTIMATE_STATES_IDS.LOCALE) {
    actualAggregation = locale;
  } else if (sectionId === ESTIMATE_STATES_IDS.OBJECT) {
    actualAggregation = object;
  } else if (sectionId === ESTIMATE_STATES_IDS.CONSOLIDATE) {
    actualAggregation = consolidate;
  } else if (sectionId === ESTIMATE_STATES_IDS.PRODUCTION) {
    actualAggregation = estimate;
  }

  return {
    aggregations,
    locale,
    object,
    consolidate,
    actualAggregation,
  };
};
