import React from 'react';

function ArrowDownSelect({ color = '#000', rotate = '0' , ...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="8"
      viewBox="0 0 16.047 8.731"
      transform={`rotate(${rotate})`}
      {...props}
    >
      <path
        className="a"
        d="M235.027,1035.5l7.67,7.67,7.67-7.67"
        transform="translate(-234.673 -1035.148)"
        fill="none"
        stroke={color}
      />
    </svg>
  );
}

export default React.memo(ArrowDownSelect);
