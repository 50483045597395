export interface IFile extends File {
  originalname: string;
  file: string;
}

export const serializeFiles = (files: IFile[]) =>
  files.map((file) => {
    return {
      ...file,
      //@ts-ignore
      file: { name: file.originalname || file.file_name, link: file.file },
    };
  });
