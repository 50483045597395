import { IActions } from "app/store/store";
import { ITab } from "shared/ui/controls/TabBar/TabBarNotLinks";

import { IRequisitionInList } from "types/interfaces/Requisition";

export const requisitionsListActions = {
  setIsLoading: (status: boolean, key: string) =>
    ({
      type: "requisitionsList/SET_IS_LOADING",
      payload: { status, key },
    } as const),
  setRequisitions: (
    requistions: IRequisitionInList[],
    key: string,
    filters: Record<string, string | number>,
    count: number
  ) =>
    ({
      type: "requisitionsList/SET_REQUISITIONS",
      payload: { requistions, key, filters, count },
    } as const),
  setMoreRequisitions: (
    requistions: IRequisitionInList[],
    key: string,
    filters: Record<string, string | number>,
    count: number
  ) =>
    ({
      type: "requisitionsList/SET_MORE_REQUISITIONS",
      payload: { requistions, key, filters, count },
    } as const),
  setTabs: (tabs: ITab[]) =>
    ({
      type: "requisitionsList/SET_TABS",
      payload: tabs,
    } as const),
};

export type IRequisitionsListActions = IActions<typeof requisitionsListActions>;
