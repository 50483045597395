import { IHandlerIndexesActions } from "./actions";

import { IHandlerIndexesState } from "./types";

const initialState: IHandlerIndexesState = {
  indexes: {},
  loading: {},
  invalidateKey: 1,
};

export const handlerIndexesReducer = (state = initialState, action: IHandlerIndexesActions): IHandlerIndexesState => {
  const { type, payload } = action;
  switch (type) {
    case "handlerIndexes/SET_INDEXES":
      return {
        ...state,
        indexes: {
          ...state.indexes,
          [payload.sectionId]: payload.indexes,
        },
      };
    case "handlerIndexes/SET_LOADING":
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload.key]: payload.status,
        },
      };
    case "handlerIndexes/INVALIDATE":
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    default:
      return state;
  }
};
