import React from "react";
import moment from "moment";
import cn from "classnames";

import styles from "./WeekBar.module.scss";

const WeekBar = ({ className, number, range, isActive }) => {
  const startOfWeekDate = moment(range[0]).format("DD.MM");
  const endOfWeekDate = moment(range[1]).format("DD.MM");

  return (
    <div className={cn(styles.weekBar, { [styles.active]: isActive }, className)}>
      <span>Неделя {number}</span>
      <span className={styles.date}>{startOfWeekDate}-{endOfWeekDate}</span>
    </div>
  );
};

export default React.memo(WeekBar);