import React from "react";

export default function CrossRoundedIcon({ width = "16", color = "#606060", className, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="13.574"
      viewBox="0 0 13.574 13.574"
      className={className}
      {...props}
    >
      <path
        fill={color}
        id="Union_363"
        data-name="Union 363"
        d="M14.289,15.751,9.55,11.012,4.943,15.62A1.214,1.214,0,0,1,3.226,13.9L7.833,9.3,3.094,4.556A1.189,1.189,0,0,1,4.776,2.874L9.515,7.613l4.607-4.608A1.214,1.214,0,1,1,15.84,4.722L11.232,9.33l4.739,4.739a1.189,1.189,0,0,1-1.682,1.682Z"
        transform="translate(-2.746 -2.525)"
      />
    </svg>
  );
}
