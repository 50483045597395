import React, { useEffect, useMemo, useRef } from "react";
import { Field, Form } from "react-final-form";

import ButtonBase from "shared/ui/controls/ButtonBase";
import InputBase from "shared/ui/inputs/InputBase";

import { LOCALIZATION_CONFIG, RegionEnum } from "constants/localization";

import { composeFieldValidators, maxLength, minLength, required } from "utils/formHelpers/validations";

import styles from "../../ProviderContacts.module.scss";

const MAX_LENGTH_MESSAGE = "Кол-во символов больше максимального";
const MIN_LENGTH_MESSAGE = "Кол-во символов меньше минимального";

const getFieldValidators = (field: string) => {
  const validators = [required()];
  if (field.length)
    validators.push(maxLength(field.length, MAX_LENGTH_MESSAGE), minLength(field.length, MIN_LENGTH_MESSAGE));
  return composeFieldValidators(...validators);
};

interface IProps {
  requisites: Record<string, any>;
  onSubmit: () => void;
}

const ProviderRequisitesForm: React.FC<IProps> = ({ requisites, onSubmit }) => {
  const formRef = useRef<HTMLFormElement>(null);

  const fields = useMemo(() => {
    const res = [
      { name: "bic", label: "БИК", length: LOCALIZATION_CONFIG.bicLength },
      { name: "bank_name", label: "Наименование банка" },
      { name: "itn", label: LOCALIZATION_CONFIG.taxesNumberLabel, minLength: 10, maxLength: 12 },
      { name: "checking_account", label: "Расчетный счет", length: 20 },
      { name: "corresponding_account", label: "Корреспондентский счет", length: 20 },
      { name: "address", label: "Юридический адрес" },
    ];

    if (LOCALIZATION_CONFIG.langLabel !== RegionEnum.kz) {
      res.splice(3, 0, {
        name: "iec",
        label: "КПП",
        length: 9,
      });
    }
    return res;
  }, [LOCALIZATION_CONFIG]);

  useEffect(() => {
    if (!formRef.current) return;
    formRef.current.form.submit();
  }, []);

  return (
    <Form
      ref={formRef}
      onSubmit={onSubmit}
      initialValues={requisites}
      validateOnBlur={true}
      render={({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          {fields.map((field) => (
            <div className={styles.field} key={field.name}>
              {/* @ts-ignore */}
              <Field component={InputBase} validate={getFieldValidators(field)} {...field} />
            </div>
          ))}
          {dirty && (
            <footer className={styles.footer}>
              <ButtonBase type="submit" primary>
                Сохранить
              </ButtonBase>
            </footer>
          )}
        </form>
      )}
    />
  );
};

export default React.memo(ProviderRequisitesForm);
