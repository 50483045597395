import React, { FC } from "react";
import { useDispatch } from "react-redux";

import { learningActions } from "../../model/learningSlice";
import LearningMenuItem from "../LearningMenuItem/LearningMenuItem";

import { ILearningItem, ILearningNormItem, ILearningSublists } from "../../model/types";

import MinusBoxIcon from "images/icons/MinusBoxIcon";
import PlusBoxIcon from "images/icons/PlusBoxIcon";

import styles from "./LearningSublist.module.scss";

interface IProps {
  item: ILearningNormItem;
  depth?: number;
  activeLesson?: ILearningItem;
  setActiveLesson?: React.Dispatch<React.SetStateAction<ILearningItem | null>>;
}

const LearningSublist: FC<IProps> = ({ item, depth = 0, activeLesson, setActiveLesson }) => {
  const dispatch = useDispatch();
  return (
    <div className={styles.root}>
      <div
        className={styles.head}
        style={{ paddingLeft: `${depth}rem` }}
        onClick={() => dispatch(learningActions.setSublistOpen({ uid: item.uid, status: !item.isOpen }))}
      >
        {/* @ts-ignore */}
        <div className={styles.expandIcon}>{item.isOpen ? <MinusBoxIcon /> : <PlusBoxIcon />}</div>
        {item.name}
      </div>
      {item.isOpen && (
        <>
          {item.children.map((child) => (
            <LearningSublist
              key={child.uid}
              item={child}
              depth={depth + 1}
              activeLesson={activeLesson}
              setActiveLesson={setActiveLesson}
            />
          ))}
          {item.tasks.map((task, i, arr) => (
            <LearningMenuItem
              key={task.key}
              item={task}
              activeLesson={activeLesson}
              setActiveLesson={setActiveLesson}
              isLastAvailable={(arr[i - 1]?.is_confirmed || !arr[i - 1]) && (arr[i + 1]?.isDisabled || !arr[i + 1])}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default LearningSublist;
