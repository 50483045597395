import React from "react";

import { sliceTextByConstraint } from "../../../../../../../utils/formatters/sliceTextByConstraint";
import { splitThousands } from "../../../../../../../utils/formatters/splitThousands";

import styles from "./BillTable.module.scss";

//@ts-ignore
function BillTableRow({ name, measure, number, count, price, retailPrice, amount }) {
  return (
    <div className={styles.row}>
      <div className={styles.numberCol}>{number}</div>
      <div className={styles.nameCol} title={name}>
        {sliceTextByConstraint(name, 90)}
      </div>
      <div className={styles.countCol}>{count}</div>
      <div className={styles.measureCol}>{measure}</div>
      <div className={styles.retailPriceCol}>{splitThousands(retailPrice, false, false)}</div>
      <div className={styles.priceCol}>{splitThousands(price, false, false)}</div>
      <div className={styles.amountCol}>{splitThousands(amount, false, false)}</div>
    </div>
  );
}

export default React.memo(BillTableRow);
