import React from "react";

import Permissions from "./components/Permissions/Permissions";

import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import { IPermissionsTree } from "features/permissions/model/types";

import { INormalizedPermissionsTree } from "../../../../utils/permissionsUtils";

import styles from "./ManagePermissionsModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  permissions: INormalizedPermissionsTree | null;
  checkedPermissions: any;
  onChangeChecked: any;
  canEdit: boolean;
}

const ManagePermissionsModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  permissions,
  checkedPermissions,
  onChangeChecked,
  canEdit,
}) => {
  return (
    <SliderModal isOpen={isOpen} closeHandler={onClose}>
      <header className={styles.header}>
        <h2 className={styles.title}>Управление доступами</h2>
        <p className={styles.subtitle}>Выберите доступ в дереве доступов ниже для выбранного пользователя</p>
      </header>
      <div className={styles.body}>
        <Permissions
          permissions={permissions}
          checkedPermissions={checkedPermissions}
          onChangeChecked={onChangeChecked}
          canEdit={canEdit}
        />
      </div>
    </SliderModal>
  );
};

export default React.memo(ManagePermissionsModal);
