import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { summaryUtils } from "pages/Finance/Summary/model/utils";

import { useTypedSelector } from "app/store/typedUseSelector";

import { auditUtils } from "./utils";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const useAuditTreeSelector = () => {
  const { objectId, projectId } = useParams<Record<"objectId" | "projectId", string>>();
  const { dataTree, loadings, totals, dateEnd, dateStart, type } = useTypedSelector(
    (state) => state.financeReducer.audit
  );

  const displayingTree = useMemo(() => {
    const rootKey = generateStorageKey({ type, endAt: dateEnd, startAt: dateStart });
    if (projectId === "0") {
      return dataTree[rootKey];
    }
    if (objectId === "0" && projectId !== "0") {
      return summaryUtils.extractTreeByProject(dataTree[rootKey] ?? [], +projectId);
    }
    return summaryUtils.extractTreeByObject(dataTree[rootKey] ?? [], +projectId, +objectId);
  }, [dataTree, objectId, projectId, type, dateStart, dateEnd]);

  const tableData = useMemo(() => {
    return summaryUtils.extractTableData(displayingTree);
  }, [displayingTree]);

  const showSpinner = useMemo(() => {
    if (projectId === "0") {
      const key = generateStorageKey({ type, endAt: dateEnd, startAt: dateStart });
      return loadings[key];
    }
    if (objectId === "0" && projectId !== "0") {
      const key = generateStorageKey({ type, endAt: dateEnd, startAt: dateStart, projectId });
      return loadings[key];
    }
    return loadings[generateStorageKey({ buildingId: objectId, type: "object" })];
  }, [objectId, projectId, loadings]);

  const displayingTotals = useMemo(() => {
    if (projectId === "0") {
      return null;
    }
    if (objectId === "0" && projectId !== "0") {
      return totals[generateStorageKey({ type: "project", id: projectId })];
    }
    return totals[generateStorageKey({ type: "object", id: objectId })];
  }, [totals, projectId, objectId]);

  return {
    displayingTree,
    tableData,
    showSpinner,
    displayingTotals,
  };
};
