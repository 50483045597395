import { message } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { IOption } from "../../../../../../UI/atoms/Select";

import { IPostRank, PostRankType } from "../../../../../../../types/interfaces/Posts";
import { MAX_RANK, POSITION_STAKES, STAKES_OPTIONS } from "./constants";

import intToRoman from "../../../../../../../utils/formatters/intToRoman";
import { sortPositionRanks, spawnPosition } from "./utils";

export interface IPositionInModal extends Partial<IPostRank> {
  isNew?: boolean;
}

const useEditPositions = (defaultPositions?: IPostRank[]) => {
  const [positions, setPositions] = useState<IPositionInModal[]>(defaultPositions || []);
  const [removedRanksIds, setRemovedRanksIds] = useState<number[]>([]);

  const availableRanks: IOption[] = useMemo(() => {
    const rankIds: Record<number, number> = {};
    positions.forEach((position) => {
      if (!position.rank) return;
      rankIds[position.rank] = (rankIds[position.rank] || 0) + 1;
    });
    return new Array(MAX_RANK)
      .fill(0)
      .map((_, index) => ({ id: index + 1, name: intToRoman(index + 1) }))
      .filter((rank) => (rankIds[rank.id] ? rankIds[rank.id] < STAKES_OPTIONS.length : true));
  }, [positions]);

  const availableRankTypes: Record<number, IOption[]> = useMemo(() => {
    const takenTypes: Record<number, PostRankType[]> = Object.fromEntries(
      new Array(MAX_RANK).fill(0).map((_, index) => [index + 1, []])
    );
    positions.forEach((position) => {
      if (!position.rank || !position.type) return;
      takenTypes[position.rank] = [...(takenTypes[position.rank] || []), position.type];
    });
    return Object.fromEntries(
      Object.entries(takenTypes).map(([rank, typesInfo]) => {
        const takenTypesSet = new Set(typesInfo);
        return [
          rank,
          STAKES_OPTIONS.filter((option) => !takenTypesSet.has(option)).map((type) => ({
            id: type,
            name: POSITION_STAKES[type],
          })),
        ];
      })
    );
  }, [positions]);

  useEffect(() => {
    if (!defaultPositions) return;
    setPositions(defaultPositions);
  }, [defaultPositions]);

  const addNewPosition = () => {
    if (!availableRanks.length) {
      message.warn("Уже добавлены все разряды и ставки");
      return;
    }
    setPositions((prevState) => [...prevState, spawnPosition()]);
  };

  const removePosition = (id: number) => {
    setPositions((prevState) => prevState.filter((p) => p.id !== id));
    if (id % 1 === 0) setRemovedRanksIds((prevState) => [...prevState, id]);
  };

  const editPosition = (id: number, payload: Partial<IPositionInModal>) => {
    if (payload.isNew && !!payload.rank && availableRanks.findIndex((rank) => rank.id === payload.rank) === -1) {
      message.warn(`${intToRoman(payload.rank)} разряд больше нельзя добавить `);
      return;
    }
    if (
      payload.isNew &&
      !!payload.type &&
      !!payload.rank &&
      availableRankTypes[payload.rank].findIndex((type) => type.id === payload.type) === -1
    ) {
      message.warn("Данный тип ставки больше нельзя указать");
      return;
    }
    setPositions((prevState) =>
      prevState.map((p) => {
        if (p.id === id) return { ...p, ...payload };
        return p;
      })
    );
  };

  const sortedPositions = useMemo(() => positions.sort(sortPositionRanks), [positions]);

  const dropPositions = useCallback(() => setPositions([]), []);

  const onCancelEdit = useCallback(() => {
    setPositions((prevState) => prevState.filter((position) => !position.isNew));
  }, []);

  return {
    positions: sortedPositions,
    addNewPosition,
    removePosition,
    editPosition,
    dropPositions,
    onCancelEdit,
    availableRanks,
    availableRankTypes,
    removedRanksIds,
  };
};

export default useEditPositions;
