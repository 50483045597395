import React, { ReactNode } from "react";

import SelectMenu from "shared/ui/controls/SelectMenu/SelectMenu";

import Line from "images/icons/Line";

import styles from "./CustomBreadcrumbs.module.scss";

interface IProps {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
}

const CustomBreadcrumbs: React.FC<IProps> = ({ title, subtitle, children }) => {
  return (
    <div className={styles.container}>
      {title && (
        <div className={styles.selectClassNameNew}>
          <div className={styles.breadcrumbsTitle}>{title}</div>
          {(subtitle || children) && <Line width={"0.125rem"} height={"2.313rem"} />}
        </div>
      )}
      {subtitle && (
        <SelectMenu
          selectedOption={subtitle}
          titleClassName={styles.breadcrumbsTitleSmall}
          selectClassName={styles.selectClassName}
          optionsBlockClassName={styles.optionsBlockClassName}
        />
      )}
      {children}
    </div>
  );
};

export default CustomBreadcrumbs;
