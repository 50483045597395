import React from "react";
import { useSelector } from "react-redux";

import { createWorker, workerNewIdSelector } from "redux/modules/common/building/workers";

import EditableWorkerInfo from "../EditableWorkerInfo/EditableWorkerInfo";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import styles from "./AddWorkerModal.module.scss";

const AddWorkerModal = ({ isOpen, onClose, buildingId, permissions }) => {
  const workerId = useSelector(workerNewIdSelector);

  return (
    <SliderModal className={styles.addWorkerModal} isOpen={isOpen} closeHandler={onClose}>
      <EditableWorkerInfo
        isOpen={isOpen}
        onClose={onClose}
        buildingId={buildingId}
        workerId={workerId}
        submitAction={createWorker}
        permissions={permissions}
      />
    </SliderModal>
  );
};

export default React.memo(AddWorkerModal);
