import moment from "moment";

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IPlanFactExtraCostRaw } from "./types";

import { IPlanFactSummaryExtraCostPrepared, planFactSummaryUtils } from "./utils";

interface PlanFactSummaryState {
  invalidationKey: number;
  loadings: Record<string, boolean>;
  extraCosts: Record<string, { list: IPlanFactSummaryExtraCostPrepared[]; count: number; loadedAt: number }>;
}

export const initialState: PlanFactSummaryState = {
  invalidationKey: 0,
  loadings: {},
  extraCosts: {},
};

const planFactSummarySlice = createSlice({
  name: "planFactSummary",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    },
    setExtraCosts: (state, action: PayloadAction<{ data: IPlanFactExtraCostRaw[]; key: string }>) => {
      const { data, key } = action.payload;
      state.extraCosts[key] = {
        list: planFactSummaryUtils.prepareRawExtraCosts(data),
        count: data.length,
        loadedAt: Date.now(),
      };
    },
  },
});

export const planFactSummaryActions = planFactSummarySlice.actions;

export const planFactSummaryReducer = planFactSummarySlice.reducer;
