import {
  BetonExpenseStatusNamesEnum,
  BetonIncomeStatusEnum,
  BetonIncomeStatusNamesEnum,
} from "redux/modules/common/building/beton/types";

export const betonSolidificationMethodsOptions = [
  { id: "method_1", name: "Периодическое увлажнение водой" },
  { id: "method_2", name: "Укрытие гидрофильным материалом с постоянным увлажнением" },
  { id: "method_3", name: "Укрытие пароводонепроницаемым рулонным материалом" },
  { id: "method_4", name: "Обработка пленкообразующими составами" },
  { id: "method_5", name: "Пропитка полимеризующимися гидрофобными композициями" },
  { id: "method_6", name: "Устройство шатров, навесов и т.д." },
  { id: "method_7", name: "Защита теплоизоляционными покрытиями" },
  { id: "method_8", name: "Тепловое воздействие" },
];

export const betonIncomeStatusOptions = [
  { id: BetonIncomeStatusEnum.new, name: BetonIncomeStatusNamesEnum.new },
  { id: BetonIncomeStatusEnum.part, name: BetonIncomeStatusNamesEnum.part },
  { id: BetonIncomeStatusEnum.full, name: BetonIncomeStatusNamesEnum.full },
];

export const betonExpenseStatusOptions = [
  { id: "in_work", name: BetonExpenseStatusNamesEnum.in_work },
  { id: "solidification", name: BetonExpenseStatusNamesEnum.solidification },
  { id: "completed", name: BetonExpenseStatusNamesEnum.completed },
];
