import { useCallback, useRef } from "react";
import { DraggableData, DraggableEvent } from "react-draggable";

export type ScrollPositionType = {
  percent: number;
  eventFrom: string;
  containerWidth?: number;
};

export interface IUseMonthSliderProps {
  tableScrollPosition: ScrollPositionType;
  tableViewWidthPercent: number;
  setTableScrollPosition: (x: number, sliderElement: HTMLElement) => void;
  onArrowClick?: (percent: number) => void;
  handleYearChange?: (newYear: number) => void;
  year?: number;
}

export const useMonthSlider = ({
  tableScrollPosition,
  tableViewWidthPercent,
  setTableScrollPosition,
  onArrowClick,
  handleYearChange,
  year,
}: IUseMonthSliderProps) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  const handleDrag = (_: DraggableEvent, data: DraggableData) => {
    if (!sliderRef.current) return;
    setTableScrollPosition(data.x, sliderRef.current);
  };

  const x =
    sliderRef?.current && !isNaN(sliderRef?.current?.clientWidth * tableScrollPosition?.percent)
      ? (sliderRef?.current?.clientWidth * tableScrollPosition?.percent) / 100
      : 0;

  const increaseMonth = useCallback(() => {
    if (!year || !handleYearChange || !onArrowClick) return;

    const newPercent = Math.round(
      Math.min(100 - tableViewWidthPercent, tableScrollPosition.percent + tableViewWidthPercent / 2)
    );

    onArrowClick(newPercent);

    if (
      Math.round(tableScrollPosition.percent) === newPercent &&
      newPercent === Math.round(100 - tableViewWidthPercent)
    ) {
      handleYearChange(++year);
    }
  }, [year, handleYearChange, onArrowClick, tableScrollPosition.percent, tableViewWidthPercent]);

  const decreaseMonth = useCallback(() => {
    if (!year || !handleYearChange || !onArrowClick) return;

    const newPercent = Math.round(Math.max(0, tableScrollPosition.percent - tableViewWidthPercent / 2));

    onArrowClick(newPercent);

    if (Math.round(tableScrollPosition.percent) === newPercent && newPercent === 0) {
      handleYearChange(--year);
    }
  }, [year, handleYearChange, onArrowClick, tableScrollPosition.percent, tableViewWidthPercent]);

  return {
    sliderRef,
    x,
    handleDrag,
    decreaseMonth,
    increaseMonth,
  };
};
