export const FileViewerIcons = {
  Back: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 6.7998L6.66667 15.9998M6.66667 15.9998L16 25.1998M6.66667 15.9998L28 15.9998"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  ),
  JPG: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="6" fill="#4FB1EB" />
      <path
        d="M10.317 17.4336C10.317 17.792 10.2744 18.1675 10.189 18.56C10.1122 18.9525 9.96289 19.3152 9.74103 19.648C9.52769 19.9723 9.22476 20.2411 8.83223 20.4544C8.44823 20.6677 7.94902 20.7744 7.33463 20.7744C6.56662 20.7744 5.92663 20.5781 5.41463 20.1856C4.90263 19.7845 4.56129 19.2725 4.39062 18.6496L6.32342 17.9968C6.38316 18.2357 6.48983 18.4277 6.64342 18.5728C6.80556 18.7179 7.00183 18.7904 7.23223 18.7904C7.59063 18.7904 7.82529 18.6667 7.93623 18.4192C8.05569 18.1717 8.11543 17.8475 8.11543 17.4464V11.4688H10.317V17.4336Z"
        fill="white"
      />
      <path
        d="M18.628 14.2592C18.628 14.7883 18.5299 15.2363 18.3336 15.6032C18.1374 15.9616 17.8728 16.2517 17.54 16.4736C17.2072 16.6955 16.8232 16.8576 16.388 16.96C15.9528 17.0624 15.5006 17.1136 15.0312 17.1136H13.9432V20.5312H11.7544V11.4688H15.0824C15.5774 11.4688 16.0382 11.52 16.4648 11.6224C16.9 11.7163 17.2755 11.8741 17.5912 12.096C17.9155 12.3093 18.1672 12.5952 18.3464 12.9536C18.5342 13.3035 18.628 13.7387 18.628 14.2592ZM16.4392 14.272C16.4392 14.0587 16.3966 13.8837 16.3112 13.7472C16.2259 13.6107 16.1107 13.504 15.9656 13.4272C15.8206 13.3504 15.6542 13.2992 15.4664 13.2736C15.2872 13.248 15.0995 13.2352 14.9032 13.2352H13.9432V15.3728H14.8648C15.0696 15.3728 15.2659 15.3557 15.4536 15.3216C15.6414 15.2875 15.8078 15.2277 15.9528 15.1424C16.1064 15.0571 16.2259 14.9461 16.3112 14.8096C16.3966 14.6645 16.4392 14.4853 16.4392 14.272Z"
        fill="white"
      />
      <path
        d="M27.6091 19.9808C27.1654 20.2112 26.6449 20.3989 26.0475 20.544C25.4587 20.6891 24.8145 20.7616 24.1147 20.7616C23.3894 20.7616 22.7195 20.6464 22.1051 20.416C21.4993 20.1856 20.9745 19.8613 20.5307 19.4432C20.0955 19.0251 19.7542 18.5259 19.5067 17.9456C19.2593 17.3568 19.1355 16.704 19.1355 15.9872C19.1355 15.2619 19.2593 14.6048 19.5067 14.016C19.7627 13.4272 20.1126 12.928 20.5563 12.5184C21.0001 12.1003 21.5206 11.7803 22.1179 11.5584C22.7153 11.3365 23.3595 11.2256 24.0507 11.2256C24.7675 11.2256 25.4331 11.3365 26.0475 11.5584C26.6619 11.7717 27.1611 12.0619 27.5451 12.4288L26.1627 14.0032C25.9494 13.7557 25.6678 13.5552 25.3179 13.4016C24.9681 13.2395 24.5713 13.1584 24.1275 13.1584C23.7435 13.1584 23.3894 13.2309 23.0651 13.376C22.7409 13.5125 22.4593 13.7088 22.2203 13.9648C21.9814 14.2123 21.7937 14.5109 21.6571 14.8608C21.5291 15.2021 21.4651 15.5776 21.4651 15.9872C21.4651 16.4053 21.5249 16.7893 21.6443 17.1392C21.7638 17.4891 21.9387 17.792 22.1691 18.048C22.4081 18.2955 22.6982 18.4917 23.0395 18.6368C23.3894 18.7733 23.7862 18.8416 24.2299 18.8416C24.4859 18.8416 24.7291 18.8245 24.9595 18.7904C25.1899 18.7477 25.4033 18.6837 25.5995 18.5984V16.9472H23.8715V15.1808H27.6091V19.9808Z"
        fill="white"
      />
    </svg>
  ),
  Download: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8.41421C20 8.149 19.8946 7.89464 19.7071 7.70711L14.2929 2.29289C14.1054 2.10536 13.851 2 13.5858 2H6ZM14 9C13.4477 9 13 8.55228 13 8V3.5L18.5 9H14ZM14 13H16V16.2426L17.1569 15.0858L18.5711 16.5L15.0355 20.0355L11.5 16.5L12.9142 15.0858L14 16.1716V13Z"
        fill="white"
      />
    </svg>
  ),
  Nav: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 1.09961L4.5 7.99963L11.5 14.8996" stroke="white" strokeWidth="3" />
    </svg>
  ),
  DownloadAll: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 22L22 18H20V20H4V18H2V22H22Z" fill="white" />
      <path d="M18.59 8.59L20 10L12 18L4 10L5.41 8.59L11 14.17V2L13 2V14.17L18.59 8.59Z" fill="white" />
    </svg>
  ),
  PDF: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="6" fill="#BE2E28" />
      <path
        d="M11.2252 14.2592C11.2252 14.7882 11.127 15.2362 10.9308 15.6031C10.7345 15.9615 10.47 16.2517 10.1372 16.4735C9.80436 16.6954 9.42036 16.8575 8.98516 16.9599C8.54996 17.0623 8.0977 17.1135 7.62836 17.1135H6.54036V20.5311H4.35156V11.4688H7.67956C8.1745 11.4688 8.6353 11.5199 9.06196 11.6223C9.49716 11.7162 9.87263 11.8741 10.1884 12.0959C10.5126 12.3093 10.7644 12.5951 10.9436 12.9535C11.1313 13.3034 11.2252 13.7386 11.2252 14.2592ZM9.03636 14.2719C9.03636 14.0586 8.9937 13.8837 8.90836 13.7471C8.82303 13.6106 8.70783 13.504 8.56276 13.4272C8.4177 13.3503 8.2513 13.2991 8.06356 13.2735C7.88436 13.2479 7.69663 13.2351 7.50036 13.2351H6.54036V15.3727H7.46196C7.66676 15.3727 7.86303 15.3557 8.05076 15.3216C8.2385 15.2874 8.4049 15.2277 8.54996 15.1423C8.70356 15.057 8.82303 14.9461 8.90836 14.8095C8.9937 14.6645 9.03636 14.4853 9.03636 14.2719Z"
        fill="white"
      />
      <path
        d="M20.6159 15.9743C20.6159 16.768 20.4665 17.4549 20.1679 18.0352C19.8777 18.6069 19.4895 19.0805 19.0031 19.456C18.5252 19.8229 17.9833 20.096 17.3775 20.2752C16.7716 20.4458 16.1572 20.5311 15.5343 20.5311H12.1551V11.4688H15.4319C16.0719 11.4688 16.7033 11.5455 17.3263 11.6991C17.9492 11.8442 18.5039 12.0917 18.9903 12.4415C19.4767 12.7829 19.8692 13.2437 20.1679 13.8239C20.4665 14.4042 20.6159 15.121 20.6159 15.9743ZM18.3375 15.9743C18.3375 15.4623 18.2521 15.0399 18.0815 14.7071C17.9193 14.3658 17.6975 14.097 17.4159 13.9007C17.1428 13.6959 16.8271 13.5509 16.4687 13.4655C16.1188 13.3802 15.7561 13.3375 15.3807 13.3375H14.2927V18.6368H15.3295C15.722 18.6368 16.0975 18.5941 16.4559 18.5088C16.8228 18.4149 17.1428 18.2655 17.4159 18.0608C17.6975 17.8559 17.9193 17.5829 18.0815 17.2416C18.2521 16.9002 18.3375 16.4778 18.3375 15.9743Z"
        fill="white"
      />
      <path
        d="M23.8468 13.3375V15.2064H27.354V17.0112H23.8468V20.5311H21.6836V11.4688H27.6484V13.3375H23.8468Z"
        fill="white"
      />
    </svg>
  ),
  DOC: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="6" fill="#4FB1EB" />
      <path
        d="M4 7.7002C4 7.42405 4.22386 7.2002 4.5 7.2002H27.5C27.7761 7.2002 28 7.42405 28 7.7002V9.90019C28 10.1763 27.7761 10.4002 27.5 10.4002H4.5C4.22386 10.4002 4 10.1763 4 9.9002V7.7002Z"
        fill="white"
      />
      <path
        d="M4 14.9004C4 14.6242 4.22386 14.4004 4.5 14.4004H27.5C27.7761 14.4004 28 14.6242 28 14.9004V17.1004C28 17.3765 27.7761 17.6004 27.5 17.6004H4.5C4.22386 17.6004 4 17.3765 4 17.1004V14.9004Z"
        fill="white"
      />
      <path
        d="M4 22.0996C4 21.8235 4.22386 21.5996 4.5 21.5996H19.5C19.7761 21.5996 20 21.8235 20 22.0996V24.2996C20 24.5758 19.7761 24.7996 19.5 24.7996H4.5C4.22386 24.7996 4 24.5758 4 24.2996V22.0996Z"
        fill="white"
      />
    </svg>
  ),
  AnyFile: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="6" fill="#4FB1EB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4363 13.6004C19.3116 13.6004 18.3999 12.6887 18.3999 11.564V2.40039L29.5999 13.6004H20.4363Z"
        fill="white"
      />
    </svg>
  ),
  Image: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="6" fill="#4FB1EB" />
      <path
        d="M9.6001 17.6002L14.1715 22.4162L20.5715 15.2002L29.1646 26.3912C29.6696 27.0489 29.2007 28.0002 28.3714 28.0002H3.63096C2.80129 28.0002 2.33248 27.0481 2.83834 26.3905L9.6001 17.6002Z"
        fill="white"
      />
    </svg>
  ),
  Zoom: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.0297 12.122C8.12655 12.122 9.18709 11.7207 10.0159 10.9922L10.4971 10.5693L10.8474 10.0302C11.3416 9.2695 11.6029 8.38252 11.6029 7.46514C11.6029 4.89736 9.55136 2.80832 7.0297 2.80832C4.50805 2.80832 2.45652 4.89736 2.45652 7.46514C2.45652 10.0329 4.50805 12.122 7.0297 12.122ZM14.042 14.5C13.8543 14.5 13.6657 14.434 13.5128 14.2993L10.8779 11.9755C9.84663 12.8819 8.50138 13.4303 7.0297 13.4303C3.78229 13.4303 1.1499 10.7597 1.1499 7.46514C1.1499 4.17054 3.78229 1.5 7.0297 1.5C10.2771 1.5 12.9095 4.17054 12.9095 7.46514C12.9095 8.67628 12.5539 9.80285 11.9426 10.7437L14.5723 13.0629C14.9086 13.3596 14.9445 13.8765 14.6517 14.218C14.4921 14.4045 14.2677 14.5 14.042 14.5Z"
        fill="#C3C3C3"
      />
    </svg>
  ),
  ZoomOut: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="11" width="20" height="2" fill="currentcolor" />
    </svg>
  ),
  ZoomIn: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 2H11V11H2V13H11V22H13V13H22V11H13V2Z" fill="currentcolor" />
    </svg>
  ),
};
