import axios from "axios";

import { IBindSigner, ICreateNewSigner, ISignator, ISignerInCommonList } from "./types";
import { IListResponseData } from "types/ListResponse";

export const signatorsApi = {
  create: (data: ICreateNewSigner) => {
    return axios.post(`/help/signers/`, data);
  },
  get: (buildingId: string) => {
    return axios.get<IListResponseData<ISignator>>(`/help/buildings/${buildingId}/signers/`, {
      params: { limit: 500 },
    });
  },
  getAll: () => {
    return axios.get<IListResponseData<ISignerInCommonList>>(`/help/signers/`, { params: { limit: 500 } });
  },
  bindSigner: (buildingId: string, data: IBindSigner) => {
    return axios.post(`/help/buildings/${buildingId}/signers/`, data);
  },
  unbindSigner: (buildingId: string, signerId: number) => {
    return axios.delete(`/help/buildings/${buildingId}/signers/${signerId}/`);
  },
  getAllEmployees: (buildingId: string) => {
    return axios.get(`/building/${buildingId}/employees/`, { params: { limit: 500 } });
  },
};
