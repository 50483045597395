import { message } from "antd";
import { Dispatch } from "redux";

import { RootState } from "../../../../app/store/rootReducer";
import { fileStorageActions } from "./fileStorageActions";
import { filesStorageApi } from "./filesStorageApi";

import { RECENT_FILES_OFFSET } from "./constants";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const getFileStorageItems =
  (objectId: string, folderId?: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const buildingId = objectId !== "0" ? objectId : null;
    Promise.all([
      filesStorageApi.getFolders({
        limit: 500,
        project_id: buildingId,
        section_id: folderId || null,
      }),
      filesStorageApi.getFiles({
        limit: 500,
        project_id: buildingId,
        section_id: folderId || null,
      }),
    ])
      .then(([{ data: foldersData }, { data: filesData }]) => {
        dispatch(
          fileStorageActions.setFoldersAndFiles({
            files: filesData.results,
            folders: foldersData.results,
            filesCount: filesData.count,
            foldersCount: foldersData.count,
            objectId,
            folderId,
          })
        );
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(fileStorageActions.setIsLoaded(true, objectId, folderId));
      });
  };

export const getRecentFiles = (objectId: string) => (dispatch: Dispatch) => {
  const buildingId = objectId != "0" ? objectId : null;
  filesStorageApi
    .getFiles({
      limit: RECENT_FILES_OFFSET,
      project_id: buildingId,
    })
    .then(({ data }) => {
      dispatch(fileStorageActions.setRecent(objectId, data.results));
    });
};

export const deleteFileInStorage =
  (fileId: number, objectId: string, preSuccessCallback?: () => void) => (dispatch: Dispatch) => {
    preSuccessCallback?.();
    filesStorageApi
      .deleteFile(fileId)
      .then(() => {
        message.success("Успешно удалено");
        dispatch(fileStorageActions.deleteFileInStorage(fileId));
        // @ts-ignore
        dispatch(getStorageCounts(objectId));
      })
      .catch(errorCatcher);
  };

export const deleteFolderInStorage =
  (folderId: number, objectId: string, preSuccessCallback?: () => void) => (dispatch: Dispatch) => {
    preSuccessCallback?.();
    filesStorageApi
      .deleteFolder(folderId)
      .then(() => {
        message.success("Успешно удалено");
        dispatch(fileStorageActions.deleteFolder(folderId));
        // @ts-ignore
        dispatch(getStorageCounts(objectId));
      })
      .catch(errorCatcher);
  };

export const createFolderInStorage = (name: string, objectId: string, parentId?: number) => (dispatch: Dispatch) => {
  const buildingId = objectId !== "0" ? objectId : null;
  filesStorageApi
    .createFolder(buildingId, name, parentId)
    .then(() => {
      message.success("Успешно создано"); //@ts-ignore
      dispatch(getFileStorageItems(objectId, parentId));
      // @ts-ignore
      dispatch(getStorageCounts(objectId));
    })
    .catch(errorCatcher);
};

export const createFileInStorage = (file: File, objectId: string, parentId?: number) => (dispatch: Dispatch) => {
  const buildingId = objectId !== "0" ? objectId : null;
  if (file.name.length > 99) {
    message.error("Убедитесь, что имя файла меньше 100 символов");
    return;
  }
  filesStorageApi
    .createFile(file, { project_id: buildingId, section_id: parentId })
    .then(() => {
      message.success("Успешно создано"); //@ts-ignore
      dispatch(getFileStorageItems(objectId, parentId));
      // @ts-ignore
      dispatch(getStorageCounts(objectId));
    })
    .catch(errorCatcher);
};

export const renameFileInStorage = (id: number, name: string) => (dispatch: Dispatch) => {
  filesStorageApi
    .renameFile(id, name)
    .then(() => {
      dispatch(fileStorageActions.renameFile(name, id));
    })
    .catch(errorCatcher);
};

export const renameFolderInStorage = (id: number, name: string) => (dispatch: Dispatch) => {
  if (name.length > 128) {
    message.error("Название не должно превышать 128 символов");
    return;
  }
  dispatch(fileStorageActions.renameFolder(name, id));
  filesStorageApi.renameFolder(id, name).catch(errorCatcher);
};

export const getStorageCounts = (objectId: string) => (dispatch: Dispatch) => {
  const buildingId = objectId !== "0" ? objectId : null;
  filesStorageApi.getCounters(buildingId).then(({ data }) => {
    dispatch(fileStorageActions.setCounts(objectId, data));
  });
};

export const getStorageObjects = () => (dispatch: Dispatch) => {
  dispatch(fileStorageActions.setObjectsIsLoading(true));
  filesStorageApi
    .getObjects()
    .then(({ data }) => {
      dispatch(fileStorageActions.setObjects(data.results.filter((el) => el.count_files || el.count_folders)));
    })
    .finally(() => {
      dispatch(fileStorageActions.setObjectsIsLoading(false));
    });
};
