import { Dispatch } from "redux";

import { constructionControlProjectsApi } from "./api";
import { constructionControlProjectsActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getConstructionControlObjects = (projectId: string) => (dispatch: Dispatch, getState: () => RootState) => {
  const key = projectId;
  if (getState().constructionControlProjectsReducer.loadings[key] === undefined) {
    dispatch(constructionControlProjectsActions.setIsLoading({ key, status: true }));
  }
  constructionControlProjectsApi
    .getObjects(projectId)
    .then(({ data }) => {
      dispatch(constructionControlProjectsActions.setObjects({ key, objects: data.results }));
    })
    .finally(() => {
      dispatch(constructionControlProjectsActions.setIsLoading({ key, status: false }));
    });
};

export const changeConstructionControlObjectStatus =
  (objectId: number, status: boolean) => async (dispatch: Dispatch) => {
    const key = generateStorageKey({ buildingId: objectId, type: "changeStatus" });
    dispatch(constructionControlProjectsActions.setIsLoading({ key, status: true }));
    try {
      if (status) {
        await constructionControlProjectsApi.accept(objectId);
      } else {
        await constructionControlProjectsApi.reject(objectId);
      }
      dispatch(constructionControlProjectsActions.invalidateKey());
    } catch (e: any) {
      errorCatcher(e);
    } finally {
      dispatch(constructionControlProjectsActions.setIsLoading({ key, status: false }));
    }
  };
