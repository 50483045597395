import { message } from "antd";
import { isEmpty, isEqual, memoize, xorWith } from "lodash";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { commentsLoadingSelector, commentsSelector } from "redux/modules/_TODO/comments/reducer";
import { createPackingItemComment } from "redux/modules/_TODO/comments/thunks/createPackingItemComment";
import { getPackingItemComments } from "redux/modules/_TODO/comments/thunks/getPackingItemComments";
import {
  acceptPackingList as acceptPackingListRequest,
  addFileInPackingList,
  changePackingListDate,
  deleteFileFromPackingList,
  loadPackingListV2,
} from "redux/modules/common/documents/documents";
import {
  packingIsAcceptingSelector,
  packingItemsFilesLoadingSelector,
  packingItemsFilesSelector,
  packingListErrorSelector,
  packingListIsNotFoundSelector,
  packingListLoadingSelector,
  packingListSelector,
} from "redux/modules/common/documents/reducer";
import { addPackingItemFile } from "redux/modules/common/documents/thunks/addPackingItemFile";
import { deletePackingItemFile } from "redux/modules/common/documents/thunks/deletePackingItemFile";
import { getPackingItemFiles } from "redux/modules/common/documents/thunks/getPackingItemsFiles";
import {
  _LEGACY_setPackingListNumber_API,
  setPackingListNumber,
} from "redux/modules/common/documents/thunks/setPackingListNumber";

import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Products from "./components/Products/Products";
import SplitModal from "./components/SplitModal/SplitModal";

import { Spinner } from "../../../shared/ui/atoms/Spinner/Spinner";
import BackNavigationBar from "../../../shared/ui/layout/BackNavigationBar/BackNavigationBar";
import Paper from "../../../shared/ui/layout/Paper/Paper";
import ListValidator from "./domain/ListValidator";
import { calculateAmount } from "./helpers/calculateAmount";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { PACKING_LIST_STATUSES } from "./constants";
import { NOT_FOUND_PLACEHOLDER } from "constants/placeholders";
import { IFile } from "types/interfaces/Files";
import { IPackingListItems } from "types/interfaces/PackingList";

import { getSerializedProductsComments } from "../Requisition/utils/getSerializedProductsComments";
import { stringifyArgs } from "utils/helpers/stringifyArgs";
import { serializeFiles } from "utils/serializeFiles";

import notFoundIcon from "images/icons/not-found-icon-black.svg";

import styles from "./PackingList.module.scss";

interface IProps {
  propsPackingListId?: number;
  permissions: Record<string, boolean>;
  isSimplified: boolean;
  fallbackObjectId?: string;
}

const PackingList: React.FC<IProps> = ({ propsPackingListId = null, permissions, isSimplified, fallbackObjectId }) => {
  const paramsPackingListId = useParams<{ packingListId: string }>().packingListId;
  const packingListId = paramsPackingListId || propsPackingListId;
  const history = useHistory();
  const from = new URLSearchParams(history.location.search).get("from");

  const dispatch = useDispatch();
  const packingList = useSelector(packingListSelector);
  const packingListIsLoading = useSelector(packingListLoadingSelector);
  const isAcceptingPackingList = useSelector(packingIsAcceptingSelector);
  const packingListIsError = useSelector(packingListErrorSelector);
  const packingListIsNotFound = useSelector(packingListIsNotFoundSelector);
  const isPurchaser = true; //? restore when pcr and pvr appear

  const productsFiles = useSelector(packingItemsFilesSelector);
  const productsFilesAreLoading = useSelector(packingItemsFilesLoadingSelector);

  const productsComments = useSelector(commentsSelector);
  const productsCommentsAreLoading = useSelector(commentsLoadingSelector);

  const [comment, setComment] = useState("");
  const [products, setProducts] = useState<IPackingListItems[] | undefined>(undefined);
  const [splitModalIsOpen, setSplitModalIsOpen] = useState(false);

  const [title, setTitle] = useState("");

  const productsHaveChanges = useMemo(() => {
    return !isEmpty(xorWith(products, packingList?.items, isEqual));
  }, [products, packingList?.items, packingListIsLoading]);

  const onChangePackingListNumber = useCallback(
    (number: string) => {
      if (number === packingList?.number) return;
      dispatch(setPackingListNumber(packingListId, number));
    },
    [packingListId, packingList?.number]
  );

  const commentChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value),
    []
  );

  const onChangeProductItem = useCallback(
    (changedProductId: number, changedItemName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const changedItemValue = event.target.value;

      setProducts((prevState) =>
        prevState?.map((product) =>
          product.id === changedProductId
            ? {
                ...product,
                [changedItemName]: changedItemValue,
              }
            : product
        )
      );
    },
    []
  );

  const onDeleteProduct = useCallback(
    (deletedProductId: number) => () => {
      setProducts((prevState) => {
        if (prevState && prevState?.length <= 1) {
          message.error("В УПД должен быть как минимум 1 товар");
          return prevState;
        }

        return prevState?.map((product) =>
          product.id === deletedProductId ? { ...product, is_removed: true } : product
        );
      });
    },
    []
  );

  const productsWithoutRemoved = useMemo(() => products?.filter((product) => !product.is_removed) || [], [products]);

  const memoizedOnDeleteProduct = useMemo(() => memoize(onDeleteProduct, stringifyArgs), [onDeleteProduct]);

  const memoizedOnChangeProductItem = useMemo(() => memoize(onChangeProductItem, stringifyArgs), [onChangeProductItem]);

  const handleSplitModalOpen = useCallback((status = false) => {
    setSplitModalIsOpen((prevState) => {
      if (status !== undefined) {
        return status;
      } else {
        return !prevState;
      }
    });
  }, []);

  const acceptPackingList = useCallback(
    (isSplit = false) => {
      if (!packingList || !products) return;

      dispatch(
        acceptPackingListRequest(packingList.id, {
          comment,
          items: products.map((product) => ({
            id: product.id,
            count_get: product.count_get,
            amount: product.amount || calculateAmount(product.count_get, product.price),
            is_removed: product.is_removed,
          })),
          refuse_product: !isSplit,
        })
      );

      handleSplitModalOpen(false);
    },
    [products, packingList, comment]
  );

  const acceptPackingListWithSplitting = useCallback(() => {
    acceptPackingList(true);
  }, [acceptPackingList]);

  const onValidatorError = (errors: string[]) => errors?.forEach((error) => message.error(error));

  const onAccept = useCallback(async () => {
    if (!packingList.number && title) {
      await _LEGACY_setPackingListNumber_API(packingList.id, title);
    }

    const packingListValidator = new ListValidator(onValidatorError);

    if (!packingListValidator.validate(title || packingList.number, products)) return;

    if (productsHaveChanges) {
      handleSplitModalOpen(true);
      return;
    }
    acceptPackingList();
  }, [packingList?.number, productsHaveChanges, acceptPackingList, products, productsWithoutRemoved, title]);

  const handleAddFiles = useCallback(
    (files: IFile[]) => files.forEach((file) => dispatch(addFileInPackingList(packingListId, file))),
    [packingListId]
  );

  const handleDeleteFile = useCallback(
    (removedFileId: number) => dispatch(deleteFileFromPackingList(packingListId, removedFileId)),
    [packingListId]
  );

  const changeExecutionDate = useCallback(
    (changedDate: Moment) => dispatch(changePackingListDate(packingListId, moment(changedDate).format("YYYY-MM-DD"))),
    [packingListId]
  );

  const addProductFiles = useCallback(
    (productId: number, addedFiles: IFile[]) =>
      addedFiles.forEach((file: IFile) => dispatch(addPackingItemFile(productId, file.file))),
    []
  );

  const deleteProductFile = useCallback(
    (productId: number, file: IFile) => dispatch(deletePackingItemFile(productId, file.id)),
    []
  );

  const serializedProductsFiles = useMemo(() => {
    const result = {}; /* @ts-ignore */
    Object.entries(productsFiles).forEach(([productId, files]) => (result[productId] = serializeFiles(files)));
    return result;
  }, [productsFiles]);

  const handleCreateProductComment = useCallback(
    (productId: number, comment: string) => dispatch(createPackingItemComment(productId, comment)),
    []
  );

  const handleGetProductComments = useCallback((productId: number) => dispatch(getPackingItemComments(productId)), []);

  const serializedProductsComments = useMemo(() => {
    const result = {};
    Object.entries(productsComments).forEach(
      /* @ts-ignore */
      ([productId, comments]) => (result[productId] = getSerializedProductsComments(comments))
    );
    return result;
  }, [productsComments]);

  const loadPackingList = useCallback(
    (isWithoutLoader: boolean = true) => dispatch(loadPackingListV2(packingListId, isWithoutLoader)),
    [packingListId]
  );

  useEffect(() => {
    if (!packingListId) return;

    loadPackingList(false);
  }, [packingListId]);

  useEffect(() => {
    if (!packingList) return;

    setProducts(packingList.items || []);
    setComment(packingList.comment);
  }, [packingList?.items, packingList?.comment]);

  const packingItemsFiles = useSelector(packingItemsFilesSelector);

  const loadPackingItemFiles = React.useCallback(
    (packingItemId: number) => {
      if (packingItemsFiles[packingItemId]) return;
      dispatch(getPackingItemFiles(packingItemId));
    },
    [packingItemsFiles]
  );

  const canEditPackingList =
    packingList.status === PACKING_LIST_STATUSES.waitingShipment && permissions.viewPackingListAccept;

  if ((packingListIsLoading || productsFilesAreLoading) && !packingListIsNotFound) return <Spinner />;

  if (packingListIsNotFound) return <EmptyPlaceholder img={notFoundIcon} text={NOT_FOUND_PLACEHOLDER} />;

  if (packingListIsError) return null;

  return (
    <>
      <div className={styles.packingListContainer}>
        {!isSimplified && (
          <BackNavigationBar title="Документы" backLink={from as string} rightSideText={packingList?.building?.name} />
        )}
        <Paper>
          <div className={styles.packingList}>
            <Header
              data={packingList}
              onChangeNumber={onChangePackingListNumber}
              addFiles={handleAddFiles}
              deleteFile={handleDeleteFile}
              canEdit={canEditPackingList}
              changeExecutionDate={changeExecutionDate}
              subPackingList={packingList.subpackinglist}
              filesPermissions={permissions}
              isSimplified={isSimplified}
              fallbackObjectId={fallbackObjectId}
              onDirectlyChange={setTitle}
              title={title}
            />
            {productsWithoutRemoved.length !== 0 && (
              <Products
                products={productsWithoutRemoved} /* @ts-ignore */
                productsComments={serializedProductsComments}
                productsCommentsAreLoading={productsCommentsAreLoading}
                loadPackingList={loadPackingList}
                createProductComment={handleCreateProductComment}
                getProductComments={handleGetProductComments}
                loadPackingItemFiles={loadPackingItemFiles} /* @ts-ignore */
                files={serializedProductsFiles}
                addFiles={addProductFiles}
                deleteFile={deleteProductFile}
                onChangeProductItem={memoizedOnChangeProductItem}
                canEdit={canEditPackingList}
                onDeleteProduct={memoizedOnDeleteProduct}
                isPurchaser={isPurchaser}
                permissions={permissions}
              />
            )}
            <Footer
              canEditPackingList={canEditPackingList}
              productsHaveChanges={productsHaveChanges}
              onAccept={onAccept}
              data={packingList}
              commentChangeHandler={commentChangeHandler}
              comment={comment}
              isAccepting={isAcceptingPackingList}
            />
          </div>
        </Paper>
      </div>
      <SplitModal
        isOpen={splitModalIsOpen}
        onClose={handleSplitModalOpen}
        acceptPackingList={acceptPackingList}
        acceptPackingListWithSplitting={acceptPackingListWithSplitting}
      />
    </>
  );
};

export default React.memo(PackingList);
