import React from 'react';
import { TableCell } from '../TableCell/TableCell';
import { SkeletonData } from '../SkeletonData/SkeletonData';
import { ifMobile } from '../utils/ifMobile';

const headersArrayCreator = headers => {
  return Array.isArray(headers) ? headers.flat(Infinity).filter(h => !!h) : [].concat(headers).filter(h => !!h);
};

export class TableRow extends React.PureComponent {
  state = {
    hiddenBlockOpen: this.props.hiddenBlockOpen || false,
    hiddenBlock: this.props.hiddenBlock || (() => null)
  };

  componentDidMount() {
    const {
      row,
      hiddenBlockOpenFunc,
      hiddenBlockOpen
    } = this.props;
    this.setState({
      hiddenBlockOpen: hiddenBlockOpenFunc ? hiddenBlockOpenFunc?.(row) : hiddenBlockOpen
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.hiddenBlockOpen !== this.props.hiddenBlockOpen) {
      this.setState({
        hiddenBlockOpen: this.props.hiddenBlockOpen
      })
    }
    if (prevProps.hiddenBlock !== this.props.hiddenBlock) {
      this.setState({
        hiddenBlock: this.props.hiddenBlock
      })
    }
  }

  showHiddenBlock = () => this.setState({ hiddenBlockOpen: true });

  closeHiddenBlock = () => this.setState({ hiddenBlockOpen: false });

  loadingFormatter = () => <SkeletonData />;
  onRowClick = (e, row, onRowClick) => {
    const rowMethods = {
      hiddenBlock: this.state.hiddenBlock,
      hiddenBlockOpen: this.state.hiddenBlockOpen,
      showHiddenBlock: this.showHiddenBlock,
      closeHiddenBlock: this.closeHiddenBlock,
    };
    return onRowClick ? onRowClick(e, row, rowMethods) : null;
  };
  render() {
    const {
      row,
      headers,
      rowClass,
      cellClass,
      isLoading,
      onRowClick,
      isMobileView,
      extraMobileColumn,
      rowDataId,
      onRowMouseEnter,
      onRowMouseDown,
      prevRow,
      colspanCount,
      idx
    } = this.props;

    const headersArr = headersArrayCreator(headers);

    const { hiddenBlock, hiddenBlockOpen } = this.state;
    const hiddenBlockMethods = {
      row: row,
      close: this.closeHiddenBlock,
    };

    const isOpen = hiddenBlockOpen;

    return (
      <React.Fragment>
        <tr
          onClick={(e) => this.onRowClick(e, row, onRowClick)}
          onMouseEnter={onRowMouseEnter ? () => onRowMouseEnter(row) : null}
          onMouseDown={onRowMouseDown ? () => onRowMouseDown(row) : null}
          className={rowClass}
        >
          {headersArr
            .filter(header =>
              ifMobile(isMobileView, { ...header.props, extraMobile: extraMobileColumn }) &&
              !header.props.colSpan
            )
            .map((header, index) => (
              <TableCell
                formatter={
                  isLoading && header.props.skeletonData !== false
                    ? this.loadingFormatter
                    : header.props.formatter
                }
                data={header.props.numberGeneration === true ? rowDataId : header.props.numberGeneration === "reverse" ? header.props.dataLength - idx : row[header.props.data]}
                style={
                  typeof header.props.cellStyle === 'function' ?
                    header.props.cellStyle(row)
                    :
                    header.props.cellStyle
                }
                showHiddenBlock={() => this.showHiddenBlock(header.props.hiddenBlock)}
                closeHiddenBlock={this.closeHiddenBlock}
                getHiddenBlockState={() => isOpen}
                cellClass={cellClass}
                row={row}
                prevRow={prevRow}
                key={index}
              />
            ))}
        </tr>
        {isOpen && hiddenBlock(hiddenBlockMethods) ? (
          <React.Fragment>
            <tr style={{ height: 0 }} />
            <tr>
              <td style={{ padding: 0 }} colSpan={ colspanCount || headers.length}>
                {
                  hiddenBlock(hiddenBlockMethods)
                }
              </td>
            </tr>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    )
  }
}
