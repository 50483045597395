import { useMemo } from "react";
import { useSelector } from "react-redux";

import { objectDetailSelector } from "../../../../../../../redux/modules/common/building/object/nowObject";
import { userSelector } from "redux/modules/_TODO/auth";

import { estimateStateIdSelector } from "../../../model/selectors";

import { EEstimateStatesIds } from "../../../../ProHandler/enums";

import { useIsOpenedInApproveForm } from "../../../hooks/useIsOpenedInApproveForm";

export const useUserCanConfirm = () => {
  const user = useSelector(userSelector);
  const building = useSelector(objectDetailSelector);
  const estimateStateId = useSelector(estimateStateIdSelector);
  const isOpenedInApproveForm = useIsOpenedInApproveForm();

  return useMemo(() => {
    if (isOpenedInApproveForm) return true;

    return estimateStateId === EEstimateStatesIds.LOCALE && user?.id === building?.responsible_estimate?.id;
  }, [user, building, estimateStateId, isOpenedInApproveForm]);
};
