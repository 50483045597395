import cn from "classnames";
import React from "react";

import { MATERIALS_COLOR_MAP, MATERIALS_STATUSES } from "../../../../../../constants";
import { MaterialType } from "../../../../../../types";

import styles from "../../InfoPopup.module.scss";

export interface IMaterialStatusBlockProps {
  status: MaterialType;
  count: string;
  measure: string;
}

const MaterialStatusBlock: React.FC<IMaterialStatusBlockProps> = ({ status, count, measure }) => {
  return (
    <div className={styles.statusBlock}>
      <div
        className={styles.status}
        style={{ backgroundColor: MATERIALS_COLOR_MAP[status], borderColor: MATERIALS_COLOR_MAP[status] }}
      />
      <div className={styles.indicators}>
        <span className={styles.statusName}>{MATERIALS_STATUSES[status]?.name}</span>
        {count !== undefined && (
          <span className={cn(styles.statusCount, styles.textPrimary)}>{`${count} ${
            measure ? `(${measure})` : ""
          }`}</span>
        )}
      </div>
    </div>
  );
};

export default React.memo(MaterialStatusBlock);
