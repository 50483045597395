import cn from "classnames";
import moment from "moment";
import React, { useCallback } from "react";

import OpeningInfo from "../../../../../../../_LEGACY/UI/_LEGACY_Purchases_components/OpeningInfo";

import DownloadButton from "../../../../../../../shared/ui/controls/DownloadButton/DownloadButton";
import { NavLinkIcon } from "../../../../../../../shared/ui/controls/NavLinkIcon/NavLinkIcon";
import Calendar from "../../../../../../../shared/ui/inputs/Calendar/Calendar";
import DynamicTitleInput, { SIZES } from "../../../../../../../shared/ui/inputs/DynamicTitleInput/DynamicTitleInput";
import FileBlock from "../../../../../../../shared/ui/inputs/FileBlock/FileBlock";
import { saveExcel } from "../../saveExcelHandler";

import { useUrlModuleWithObjectId } from "../../../../../../../utils/hooks/useUrlModuleWithObjectId";

import { sliceTextByConstraint } from "../../../../../../../utils/formatters/sliceTextByConstraint";

import calendarBlue from "../../../../../../../images/icons/calendarBlue.svg";

import styles from "./Header.module.scss";

//@ts-ignore
const EMPTY_ARRAY = [];
//@ts-ignore
const Header = ({ bill, number, onChangeNumber, onChangeDate, addFiles, deleteFile, permissions }) => {
  const urlAdapter = useUrlModuleWithObjectId();

  const handleDownload = useCallback(() => saveExcel(bill.id, number), [bill.id, number]);

  return (
    <header className={styles.container}>
      <DownloadButton className={styles.downloadButton} onDownload={handleDownload} />
      <div>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>Счет на оплату №</h2>
          <DynamicTitleInput
            title={number}
            onChange={onChangeNumber}
            size={SIZES.SMALL}
            placeholder="Введите номер"
            isDisabled={!permissions.viewBillInEdit}
          />
        </div>
        <div className={styles.mainInfo}>
          <div className={styles.infoBlock}>
            <div>от</div>
            <Calendar
              icon={calendarBlue}
              classNameSelect={cn(styles.calendar, { [styles.disabled]: !permissions.viewBillInEdit })}
              value={moment(bill.date)}
              setValue={onChangeDate}
              disabled={!permissions.viewBillInEdit}
              //@ts-ignore
              variant="brandColorTransparent"
            />
          </div>
          <div className={styles.infoBlock}>
            <div>заказ:</div>
            <NavLinkIcon
              className={styles.infoValue}
              title={`№${bill.order.number}`}
              to={`/${urlAdapter}/orders/${bill.order.id}`}
            />
          </div>
        </div>
      </div>
      <div className={styles.infoColumns}>
        <div className={styles.col}>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Решение по оплате:</div>
            <OpeningInfo info={bill.payment_decision_user} title="Решение по оплате" />
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Заявку создал:</div>
            <OpeningInfo info={bill.requisition.creator} title="Заявку создал" />
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Получатель:</div>
            {bill.provider.name}
          </div>
        </div>
        <div className={cn(styles.middleCol, styles.col)}>
          <div className={styles.row}>
            <div className={cn(styles.rowTitle, styles.projectTitle)}>Проект:</div>
            <div title={bill.building.name}>{sliceTextByConstraint(bill.building.name, 120)}</div>
          </div>
        </div>
        <FileBlock
          className={styles.files}
          //@ts-ignore
          files={bill.files || EMPTY_ARRAY}
          addFiles={addFiles}
          deleteFile={deleteFile}
          permissions={permissions}
          isFileViewer
        />
      </div>
    </header>
  );
};

export default React.memo(Header);
