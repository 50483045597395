import axios from "axios";

import { IDetailedKs3InDocs, IKs3InList } from "./types";
import { IListResponseData } from "types/ListResponse";

import downloadFile from "utils/helpers/download";

export const ksListApi = {
  getKsList: (building: string, params: Record<string, string | number> = {}) => {
    return axios.get<IListResponseData<IKs3InList>>(`/estimate/documents/ks3/`, {
      params: {
        ...params,
        building,
      },
    });
  },
  getKsSubList: (ks3Id: number) => {
    return axios.get<IDetailedKs3InDocs>(`/estimate/documents/ks3/${ks3Id}/`);
  },
  downloadFullKsFiles: (ks3Id: number, name: string) => {
    downloadFile(`${axios.defaults.baseURL}/estimate/documents/ks3/${ks3Id}/download/`, name);
  },
  attachFileToKs3: (ks3Id: number, file: string, filename: string) => {
    return axios.post(`/estimate/documents/ks3/${ks3Id}/to_pay/`, { file, filename });
  },
  signKs3: (ks3Id: number) => {
    return axios.post(`/estimate/documents/ks3/${ks3Id}/sign/`);
  },
  getDetailedKs2: (ks2Id: number) => {
    return axios.get(`/estimate/documents/ks2/${ks2Id}/`);
  },
};
