import cn from "classnames";
import React from "react";

import { Spinner } from "../../shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import Modal from "../../shared/ui/modal/Modal";

import { IExpenditure } from "types/interfaces/Expenditure";

import { sliceTextByConstraint } from "../../utils/formatters/sliceTextByConstraint";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./SpecificationModal.module.scss";

const MAX_NAME_LENGTH = 85;

interface IProps {
  onClose: () => void;
  isOpen: boolean;
  name?: string;
  isLoading: boolean;
  subMaterials: Pick<IExpenditure, "count" | "id" | "measure" | "name">[];
}

const SpecificationModal: React.FC<IProps> = ({ onClose, isOpen, subMaterials, name, isLoading = false }) => {
  if (!isOpen) return null;

  return (
    <Modal className={styles.modal} title="Состав расценки" onClose={onClose} isOpen={isOpen}>
      <div className={styles.subtitle} title={name}>
        {name}
      </div>
      <div className={cn(styles.list, { [styles.withoutScroll]: !isLoading && !subMaterials?.length })}>
        {isLoading && <Spinner />}
        {!isLoading && subMaterials?.length
          ? subMaterials?.map((subMaterial) => (
              <div className={styles.item} key={subMaterial.id + subMaterial.name}>
                <div className={styles.itemName} title={subMaterial.name}>
                  {sliceTextByConstraint(subMaterial.name, MAX_NAME_LENGTH)}
                </div>
                <div className={styles.itemCount} title={subMaterial.count + " " + subMaterial.measure}>
                  {subMaterial.count} {subMaterial.measure}
                </div>
              </div>
            ))
          : null}
        {!isLoading && !subMaterials?.length ? (
          <EmptyPlaceholder fullHeight={false} text="Нет сведений" img={jurnalIcon} className={styles.placeholder} />
        ) : null}
      </div>
      <footer className={styles.footer} />
    </Modal>
  );
};

export default React.memo(SpecificationModal);
