import { IdNameLabelType } from "types/IdNameLabelType";

export const getInitialMonthsArray = (monthIndex: number, showMonthsCount: number, monthsArray: IdNameLabelType[]) => {
  if (showMonthsCount === 12) {
    return monthsArray;
  }
  if (monthIndex < showMonthsCount) {
    return monthsArray.slice(0, showMonthsCount);
  } else {
    const restYearLength = 12 - monthIndex + 2;
    const resultInThisYear = monthsArray.slice(monthIndex, monthIndex + restYearLength);
    return resultInThisYear.concat(monthsArray.slice(0, showMonthsCount - resultInThisYear.length));
  }
};

export const getInitialXPositionInMonthSlider = (monthWidth: number, monthIndex: number, showMonthsCount: number) => {
  if (showMonthsCount === 12) {
    return monthWidth * monthIndex;
  } else {
    return 0;
  }
};

export const getMonthIndexOnDragStop = (
  x: number,
  showMonthsCount: number,
  monthWidth: number,
  monthsArray: IdNameLabelType[],
  selectingCount: number
) => {
  const indexInDisplayingArray = Math.round(x / monthWidth);
  const realMonthIndex = monthsArray[indexInDisplayingArray]?.id;
  if (showMonthsCount === 12 && selectingCount === 1) {
    return { realIndex: indexInDisplayingArray, arrayIndex: indexInDisplayingArray };
  } else {
    return { realIndex: realMonthIndex, arrayIndex: indexInDisplayingArray };
  }
};
