import moment from "moment";

import { summaryUtils } from "pages/Finance/Summary/model/utils";
import { IProjectViewAuditV2 } from "pages/Finance/_TODO/legacyAuditTypes";
import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { AuditTypes, IAuditObjectResponse } from "./types";

import { auditUtils } from "./utils";

/* import { IForecastObjectResponse, IForecastProjectListResponse } from "./types";

import { forecastUtils } from "./utils"; */
import { generateStorageKey } from "utils/helpers/generateStorageKey";

interface IState {
  dataTree: Record<string, IFinanceTreeItem[]>;
  loadings: Record<string, boolean>;
  totals: Record<string, IFinanceTreeItem[]>;
  dateStart: string;
  dateEnd: string;
  type: AuditTypes;
}

const initialState: IState = {
  dataTree: {},
  loadings: {},
  totals: {},
  dateEnd: moment().format("YYYY-MM-DD"),
  dateStart: moment().format("YYYY-MM-DD"),
  type: "summary",
};

const auditSlice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    setStart: (state, { payload }: PayloadAction<string>) => {
      state.dateStart = payload;
    },
    setEnd: (state, { payload }: PayloadAction<string>) => {
      state.dateEnd = payload;
    },
    setType: (state, { payload }: PayloadAction<AuditTypes>) => {
      state.type = payload;
    },
    //
    setProjects: (state, { payload: { key, data } }: PayloadAction<{ data: IProjectViewAuditV2[]; key: string }>) => {
      const oldTree = [...(state.dataTree[key] ?? [])];
      const context = { oldTree };
      state.dataTree[key] = data.map((el) => auditUtils.mapProjectData(el, context));
    },
    setLoading: (state, { payload: { key, status } }: PayloadAction<{ key: string; status: boolean }>) => {
      state.loadings[key] = status;
    },
    closeItem: (state, { payload: { key, subTree } }: PayloadAction<{ subTree: IFinanceTreeItem; key: string }>) => {
      const oldTree = [...(state.dataTree[key] ?? [])];
      state.dataTree[key] = summaryUtils.closeTree(oldTree, subTree)!;
    },
    openItem: (state, { payload: { key, subTree } }: PayloadAction<{ subTree: IFinanceTreeItem; key: string }>) => {
      const oldTree = [...(state.dataTree[key] ?? [])];
      state.dataTree[key] = summaryUtils.openTree(oldTree, subTree)!;
    },
    setObject: (
      state,
      {
        payload: { data, objectId, projectId, rootKey },
      }: PayloadAction<{ data: IAuditObjectResponse; projectId: number; objectId: number; rootKey: string }>
    ) => {
      state.dataTree[rootKey] = auditUtils.setObject(state.dataTree[rootKey] ?? [], data, projectId, objectId);
      /* const key = generateStorageKey({ type: "object", id: objectId });
      state.totals[key] = summaryUtils.extractObjectTotal(data); */
    },
    setProject: (
      state,
      {
        payload: { data, projectId, rootKey },
      }: PayloadAction<{ data: IProjectViewAuditV2[]; projectId: number; rootKey: string }>
    ) => {
      state.dataTree[rootKey] = auditUtils.setProject(state.dataTree[rootKey] ?? [], data, projectId);
      /* const key = generateStorageKey({ type: "project", id: projectId });
      state.totals[key] = summaryUtils.extractProjectTotal(data); */
    },
    /*
    setAnyExtras: (
      state,
      {
        payload: { data, objectId, projectId },
      }: PayloadAction<{ data: IForecastObjectResponse; projectId: number; objectId: number }>
    ) => {
      state.dataTree = forecastUtils.updateExtras(state.dataTree, data, projectId, objectId);
    }, */
  },
});

export const auditActions = auditSlice.actions;
export default auditSlice.reducer;
