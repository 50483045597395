import { IAosrAction } from "./actions";

import { IAosrState } from "./types";

export const aosrInitialState: IAosrState = {
  acts: {},
  worksBySections: {},
  invalidateKey: 1,
  currentAosrDetail: null,
  isClosingPending: {},
  isCreatingPending: {},
};

export default (state = aosrInitialState, action: IAosrAction): IAosrState => {
  const { type, payload } = action;
  switch (type) {
    case "aosr/SET":
      return {
        ...state,
        acts: {
          ...state.acts,
          [payload.expenditureId]: payload.aosr,
        },
      };
    case "aosr/SET_DETAIL":
      return {
        ...state,
        currentAosrDetail: payload,
      };
    case "aosr/SET_WORKS":
      return {
        ...state,
        worksBySections: {
          ...state.worksBySections,
          [payload.sectionId]: payload.works,
        },
      };
    case "aosr/INVALIDATE_KEY":
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    case "aosr/SET_CLOSING_PENDING":
      return {
        ...state,
        isClosingPending: {
          ...state.isClosingPending,
          [payload.aookId]: payload.status,
        },
      };
    case "aosr/SET_CREATING_PENDING":
      return {
        ...state,
        isCreatingPending: {
          ...state.isCreatingPending,
          [payload.aosrId]: payload.status,
        },
      };
    default:
      return state;
  }
};
