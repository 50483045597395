import React from "react";

export const useMonthsSliderWidth = () => {
  const [width, setWidth] = React.useState(widthSwitcher(document.documentElement.offsetWidth));

  React.useEffect(() => {
    const handler = () => {
      setWidth(widthSwitcher(document.documentElement.offsetWidth));
    };
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  return width;
};

const widthSwitcher = (windowWidth: number) => {
  if (windowWidth > 2000) {
    return 60;
  } else if (windowWidth > 1919) {
    return 50;
  } else if (windowWidth > 1439) {
    return 48;
  } else {
    return 43;
  }
};
