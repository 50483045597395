import cn from "classnames";
import React from "react";

import styles from "./ModalDivider.module.scss";

type propsType = {
  className?: string;
  isWithoutMarginTop?: boolean;
  color?: "#4FB1EB" | "#47D6D1";
  text: string;
  icon?: "arrow" | "check";
};

function ModalDivider({ className, isWithoutMarginTop, color = "#4FB1EB", text, icon = "arrow" }: propsType) {
  return (
    <div
      style={{ backgroundColor: color }}
      className={cn(styles.container, className, { [styles.mt0]: isWithoutMarginTop })}
    >
      <div className={styles.status} style={{ backgroundColor: color }}>
        <div className={cn(styles.icon, styles[icon!])} />
        {text}
      </div>
    </div>
  );
}

export default ModalDivider;
