import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { analyticsScopeSelector } from "../../../redux/modules/common/analytics/selectors";
import { changeAnalyticsScope } from "../../../redux/modules/common/analytics/thunks";

import { useTypedSelector } from "../../../app/store/typedUseSelector";

const useAnalyticsScopeChange = () => {
  const dispatch = useDispatch();
  const selectedScope = useTypedSelector(analyticsScopeSelector);

  const onChangeProject = useCallback((newProjectId: number) => {
    dispatch(changeAnalyticsScope({ project: newProjectId, lsr: null, section: null }));
  }, []);

  const onChangeLsr = useCallback(
    (newLsrId: number) => {
      dispatch(changeAnalyticsScope({ project: selectedScope?.project, lsr: newLsrId, section: null }));
    },
    [selectedScope?.project]
  );

  const onChangeSection = useCallback(
    (newSectionId: number) => {
      dispatch(
        changeAnalyticsScope({
          project: selectedScope?.project,
          lsr: selectedScope?.lsr,
          section: newSectionId,
        })
      );
    },
    [selectedScope?.project, selectedScope?.lsr]
  );
  return { onChangeProject, onChangeLsr, onChangeSection };
};

export default useAnalyticsScopeChange;
