import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import List from "pages/Finance/_TODO/List/List";
import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import { toggleAuditTree } from "../../model/thunks";
import { useAuditTreeSelector } from "../../model/useAuditTreeSelector";
import { useTypedSelector } from "app/store/typedUseSelector";

import { LABELS } from "./constants";

import styles from "./ListSide.module.scss";

interface IListSideProps extends PropsWithChildren {}

const ListSide: React.FC<IListSideProps> = ({ children }) => {
  const dispatch = useDispatch();

  const { type } = useTypedSelector((state) => state.financeReducer.audit);

  const handleListItemClick = (subTree: IFinanceTreeItem) => {
    dispatch(toggleAuditTree(subTree));
  };

  const { displayingTree, showSpinner } = useAuditTreeSelector();
  const { loadings } = useTypedSelector((state) => state.financeReducer.audit);

  return (
    <div className={styles.listSide}>
      <div className={styles.sticky}>
        {children}
        <div className={styles.listSide__header}>
          <span className={styles.listSide__title}>{type === "summary" ? LABELS.SUMMARY : LABELS.FORECAST}</span>
        </div>
      </div>

      <div className={styles.listSide__list}>
        {!showSpinner && <List data={displayingTree!} onClickItem={handleListItemClick} loadings={loadings} />}
      </div>
    </div>
  );
};

export default React.memo(ListSide);
