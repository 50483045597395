import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  tasksIsSectionsLoadingSelector,
  tasksSectionFilterSelector,
  tasksSectionsForBuildingsSelector,
} from "components/pages/Tasks/model/selectors";
import { getFilteredTasksBySection, getSectionsForBuilding } from "components/pages/Tasks/model/thunks";
import { tasksListFilterType } from "components/pages/Tasks/model/types";

import { useTypedSelector } from "app/store/typedUseSelector";

import { TASK_LISTS_TYPES } from "../constants";

export const useFilterTasksBySection = (objectId: number, filters: tasksListFilterType, type: TASK_LISTS_TYPES) => {
  const dispatch = useDispatch();
  const sections = useTypedSelector(tasksSectionsForBuildingsSelector);
  const sectionTaskFilter = useTypedSelector(tasksSectionFilterSelector);
  const isIndicatorsloading = useTypedSelector(tasksIsSectionsLoadingSelector);

  const loadSectionsHandler = React.useCallback(() => {
    if (!objectId) return;
    dispatch(getSectionsForBuilding(objectId));
  }, [objectId]);

  const submitFilterBySectionHandler = React.useCallback(
    (id: number) => {
      if (!objectId) return;
      dispatch(
        getFilteredTasksBySection({
          ...filters,
          building: String(objectId),
          section_id: id === -1 ? undefined : id,
          query_filter: type,
        })
      );
    },
    [objectId, filters, type]
  );

  const sectionsByObject = useMemo(() => {
    if (!sections[objectId]) return [];
    return [{ name: "Все разделы", id: -1 }, ...sections[objectId]];
  }, [sections, objectId]);

  return {
    fields: sectionsByObject,
    loadSectionsHandler,
    submitFilterBySectionHandler,
    sectionTaskFilter: sectionTaskFilter[objectId] ? +sectionTaskFilter[objectId] : undefined,
    isSectionsLoading: isIndicatorsloading[objectId] || false,
  };
};
