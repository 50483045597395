import axios from "axios";
import React, { FC, memo, useEffect, useState } from "react";

import { Spinner } from "../../../../../../../shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "../../../../../../../shared/ui/controls/ButtonBase";
import Modal from "../../../../../../../shared/ui/modal/Modal";
import ExpenditureInfo from "./ExpenditureInfo";

import { errorCatcher } from "../../../../../../../utils/helpers/errorCatcher";

import styles from "./RelationsInfoModal.module.scss";

interface IProductInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  matchingClickHandler: () => void;
  expendituresIds: number[];
  objectId: string;
}

const RelationsInfoModal: FC<IProductInfoModalProps> = (props) => {
  const { isOpen, onClose, matchingClickHandler, objectId, expendituresIds } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [expenditures, setExpenditures] = useState<any[]>([]);

  useEffect(() => {
    if (isOpen) {
      const loadInfo = async () => {
        setIsLoading(true);

        const promises = expendituresIds.map((id) => {
          return axios
            .get(`/building/${objectId}/estimate/expenditure/${id}/`)
            .then(({ data }) => data)
            .catch(errorCatcher);
        });

        const settledPromises = await Promise.allSettled(promises); /* @ts-ignore */
        setExpenditures(settledPromises.map((promise) => promise.value));
        setIsLoading(false);
      };

      loadInfo();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="По смете" closeOnClickOutside={true}>
      <div className={styles.body}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <h3>Количество связей: {expendituresIds.length}</h3>
            <div className={styles.infoWrapper}>
              {expenditures.map((exp) => (
                <ExpenditureInfo
                  section={exp?.section?.name}
                  number={exp?.number}
                  justification={exp?.justification}
                  expenditureName={exp?.name}
                  price={exp?.price}
                  count={exp?.count}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <footer className={styles.footer}>
        <div className={styles.btns}>
          <ButtonBase primary onClick={matchingClickHandler} medium>
            Изменить
          </ButtonBase>
        </div>
      </footer>
    </Modal>
  );
};

export default memo(RelationsInfoModal);
