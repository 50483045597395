import { message } from "antd";
import Axios from "axios";
import { Location } from "history";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import { Action, compose } from "redux";

import { userSelector } from "redux/modules/_TODO/auth";
import { getKsLists } from "redux/modules/common/building/documents/ksList/thunks";
import { getDetail, objectDetailSelector } from "redux/modules/common/building/object/nowObject";
import { loadObjectsBreadcrumbs } from "redux/modules/common/building/objects";
import {
  clearIntervals,
  clearSelecteds,
  selectedProductsSelector,
  setLoadingIntervals,
} from "redux/modules/common/building/process";
import { apiLoadProductsSection } from "redux/modules/common/building/processApi";
import {
  loadSections,
  resetSectionsAction,
  sectionsLoadingByKeySelector,
  sectionsSelector,
} from "redux/modules/common/building/sections/sections";
import { apiLoadSections } from "redux/modules/common/building/sections/sectionsApi";
import { createExpendituresGroup, isUpdateGroupSuccessSelector } from "redux/modules/common/building/shedules";

import ExpenditureGroupModal from "../../UI/_TODO/ExpenditureGroupModal/ui/ExpenditureGroupModal";
import EstimateIndicators from "./components/EstimateIndicators/EstimateIndicators";
import ProjectHeader from "./components/ProjectHeader/ProjectHeader";
import ProjectSubsectionsHeader from "./components/ProjectHeader/ProjectSubsectionsHeader";
import ProjectNavbar from "./components/ProjectNavbar/ProjectNavbar";
import ProjectSharingBar from "./components/ProjectSharingBar/ProjectSharingBar";
import Section from "./components/Section/Section";
import SubSection from "./components/SubSection/SubSectionContainer";
import { useSectionPlan } from "./components/SubSection/useSectionPlan";
import ForbiddenPage from "app/routes/components/ForbiddenPage/ForbiddenPage";
import Blueprints from "pages/Estimate/Blueprints/Blueprints";
import { ESTIMATE_STATES_IDS } from "pages/Handler/ui/ProHandler/constants";
import Projects from "pages/Projects/Projects";

import {
  CreateOutOfEstimateSection,
  createOutOfEstimateSectionActions,
  createOutOfEstimateSectionSelectors,
} from "../../../features/CreateOutOfEstimateSection";
import KS2Page from "../KSPage/KS2Page";
import KS3Page from "../KSPage/KS3Page";
import { forbiddenTestId, templateTestId } from "./tests/buildingMocks";
import { ExpenditureUI } from "entities/Expenditure";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import {
  VIEW_BUILDING_ADD_GROUPS,
  VIEW_CONSTRUCTING_CREATE_DIRECTIVE_PLAN,
  VIEW_CONSTRUCTING_CREATE_SECTION_PLAN,
} from "../../../constants/permissions/constructingPermissions";
import {
  VIEW_MANUFACTURING_CREATE_DIRECTIVE_PLAN,
  VIEW_MANUFACTURING_CREATE_SECTION_PLAN,
  VIEW_MANUFACTURING_PROJECT_ADD_GROUPS,
  VIEW_MANUFACTURING_SECTION,
} from "../../../constants/permissions/manufacturingPermissions";
import { DEFAULT_BUILDING_FILTERS, DOCUMENTATION } from "./constants";
import { PROGRESS, TABS_IDS } from "./constants";
import { PLAN } from "./constants";
import { TabsEnum } from "./enums";
import { ICreateGroupValues, IExpenditureTypesFiltersInBuilding, ISelectedProduct, IbuildingSubsection } from "./types";
import ISection from "types/interfaces/Section";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useCommonModulesPermissions } from "../../../hooks/useCommonModulesPermissions";
import usePermission from "../../../hooks/usePermission";
import { useOpenPlanApprove } from "./hooks/useOpenPlanApprove";
import { useQueryParams } from "utils/hooks/useQueryParams";
import { useUrlModule } from "utils/hooks/useUrlModule";

import { sharingBarExistingChecker, sharingBarExistingCheckerPropsType } from "./utils";
import { errorCatcher } from "utils/helpers/errorCatcher";

import estimateIcon from "images/icons/navigation/estimateIcon.svg";

import styles from "./Building.module.scss";

export interface IBuildingPropsPermissions {
  canViewPlan: boolean;
  canViewProgress: boolean;
  canSharePlan: boolean;
  canShareProgress: boolean;
  canAddFiles: boolean;
  canDeleteFiles: boolean;
  canAddPlan: boolean;
  canAddProgress: boolean;

  canViewDrawings: boolean;
  canAddDrawingSets: boolean;
  canEditDrawingSets: boolean;
  canDeleteDrawingSets: boolean;
  canAddDrawings: boolean;
  canEditDrawings: boolean;
  canDeleteDrawings: boolean;
}

export interface IBuildingProps {
  editable?: boolean;
  permissions: IBuildingPropsPermissions;
  location: Location;
  match: RouteComponentProps["match"];
}

const Building = ({ editable = true, permissions, location, match }: IBuildingProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlModule = useUrlModule();
  const params = useParams<IRouterParamsWithObjectId>();
  let buildingId = params.objectId;

  const objectInfo = useSelector(objectDetailSelector);
  const userInfo = useSelector(userSelector);
  const sectionsFromSelector: ISection[] = useSelector(sectionsSelector); /* @ts-ignore */
  const rawSectionsAreLoading: boolean | undefined = useSelector(sectionsLoadingByKeySelector)?.[buildingId];
  const sectionsAreLoading = rawSectionsAreLoading === undefined ? true : rawSectionsAreLoading;
  const selectedProducts: ISelectedProduct[] = useSelector(selectedProductsSelector);
  const isUpdateGroupSuccess = useSelector(isUpdateGroupSuccessSelector);

  const { isNeedToOpenPlan, openSectionId, openSubsectionId } = useOpenPlanApprove();

  const [selectedSection, setSelectedSection] = useState<IbuildingSubsection | null>();
  const [filter, setFilter] = useState<IExpenditureTypesFiltersInBuilding | null>(DEFAULT_BUILDING_FILTERS);
  const [isShowSharedButtons, setIsShowSharedButtons] = useState(true);
  const [isShowGroupModal, setIsShowGroupModal] = useState(false);

  useEffect(() => {
    if (isNeedToOpenPlan) {
      if (!permissions.canViewPlan && urlModule === "objects") {
        history.replace(`/constructing/projects/${buildingId}/${location.search}`);
      }
    }
  }, [isNeedToOpenPlan, permissions, urlModule]);

  const getDefaultActiveTab = useCallback(() => {
    if (isNeedToOpenPlan && permissions.canViewPlan) {
      return PLAN;
    }

    if (permissions.canViewProgress) return PROGRESS;
    if (permissions.canViewPlan) return PLAN;
    if (permissions.canViewDrawings) return DOCUMENTATION;
  }, [permissions.canViewPlan, permissions.canViewProgress, permissions.canViewDrawings]);

  const activeTabId = useMemo(() => {
    const partOfSectionPath = location.pathname.replace(match.url, "");

    return TABS_IDS.find((id: string) => partOfSectionPath.includes(id)) || getDefaultActiveTab();
  }, [location.pathname, match.url]);

  const handleChangeActiveTab = useCallback(
    (tabId: string) => {
      history.push(`${match.url}/${tabId}`);
    },
    [match.url, history]
  );

  useEffect(() => {
    if (!activeTabId) handleChangeActiveTab(getDefaultActiveTab()!);
  }, []);

  const sections = useMemo(() => {
    return (sectionsFromSelector || []).filter((el) => {
      if (el.name === "Вне сметы") return true;
      return !!el.subsection_count;
    });
  }, [sectionsFromSelector]);

  const [isIndicatorsOpen, setIsIndicatorsOpen] = useState(true);
  const [selectingMonthsCount, setSelectingMonthsCount] = useState(() => (isNeedToOpenPlan ? 12 : 1));

  const [date, setDate] = useState(() => (isNeedToOpenPlan ? moment().startOf("year") : moment()));

  useEffect(() => {
    if (!isNeedToOpenPlan && selectingMonthsCount === 12) {
      setSelectingMonthsCount(1);
      setDate(moment());
    }
  }, [isNeedToOpenPlan]);

  const startOfMonthFormattedDate = useMemo(() => moment(date).startOf("month").format("YYYY-MM-DD"), [date]);
  const endOfMonthFormattedDate = useMemo(
    () =>
      moment(date)
        .add(selectingMonthsCount - 1, "month")
        .endOf("month")
        .format("YYYY-MM-DD"),
    [date, selectingMonthsCount]
  );

  const loadAndSetSubSection = async (subSectionId: number) => {
    const section = await apiLoadProductsSection(+buildingId, subSectionId, editable);

    await apiLoadSections(buildingId, ESTIMATE_STATES_IDS.PRODUCTION, {
      parent: subSectionId,
    })
      .then((responseData) => setSelectedSection(() => ({ ...section, subsections: responseData?.results })))
      .catch(errorCatcher);
  };

  const loadSubSection = async (subSectionId: number) => {
    setIsLoadingSubSections(true);
    await loadAndSetSubSection(subSectionId);
    setIsLoadingSubSections(false);
  };

  const sharingCallback = useCallback(async () => {
    if (!selectedSection?.id) {
      compose<Action>(dispatch, loadSections)({ estimateStateId: ESTIMATE_STATES_IDS.PRODUCTION, buildingId });
    } else {
      await loadAndSetSubSection(selectedSection.id);
    }
  }, [selectedSection?.id, buildingId]);

  const approveSharingHandler = () => {
    Axios.post(`/building/${buildingId}/estimate/items/entities/change_status/`, { status: "accepted" })
      .then(() => {
        dispatch(loadObjectsBreadcrumbs());
        setIsShowSharedButtons(false);
        message.success("Успешно принято");
      })
      .catch(errorCatcher);
  };

  const declineSharingHandler = () => {
    Axios.post(`/building/${buildingId}/estimate/items/entities/change_status/`, { status: "canceled" })
      .then(() => {
        setIsShowSharedButtons(false);
        message.success("Успешно отклонено");
      })
      .catch(errorCatcher);
    //
  };

  const [isLoadingSubSections, setIsLoadingSubSections] = useState(false);

  useEffect(() => {
    dispatch(getDetail(buildingId));
    dispatch(clearSelecteds());
    setSelectedSection(null);
    compose<Action>(dispatch, loadSections)({ estimateStateId: ESTIMATE_STATES_IDS.PRODUCTION, buildingId });
    return () => {
      dispatch(clearIntervals());
      dispatch(resetSectionsAction());
    };
  }, [buildingId]);

  const isShared = useMemo(
    () => Boolean(objectInfo?.is_shared && ["transferred"].includes(objectInfo?.shared_status!)),
    [objectInfo?.is_shared, objectInfo?.shared_status]
  );

  const canSharing = useMemo(() => {
    if (userInfo.tariff === "BASIC") return false;
    return sharingBarExistingChecker({
      isShared,
      haveSharingPlanPermission: permissions.canSharePlan,
      haveSharingProgressPermission: permissions.canShareProgress,
      activeTab: activeTabId as sharingBarExistingCheckerPropsType["activeTab"],
    });
  }, [isShared, permissions, activeTabId, userInfo]);

  const haveAddGroupsPermission = useCommonModulesPermissions({
    objects: VIEW_MANUFACTURING_PROJECT_ADD_GROUPS,
    constructing: VIEW_BUILDING_ADD_GROUPS,
  });

  const canGroup = useMemo(() => {
    return haveAddGroupsPermission && activeTabId === TabsEnum.plan;
  }, [activeTabId, haveAddGroupsPermission]);

  const canCheck = canSharing || canGroup;

  const canAddOutOfEstimate = activeTabId === TabsEnum.plan;

  const isSectionsExist = useMemo(
    () => sections && sections.length > 0 && sections.filter((el) => el.hidden_status !== 1).length > 0,
    [sections]
  );

  const backPath = useMemo(() => (urlModule === "constructing" ? "/constructing/projects" : undefined), [urlModule]);

  useEffect(() => {
    if (permissions.canViewPlan && !permissions.canViewProgress) {
      if (activeTabId !== TabsEnum.plan) {
        handleChangeActiveTab(TabsEnum.plan);
      }
    }
  }, [permissions]);

  const haveCreateSupersectionPlanConstructingPermission = usePermission(VIEW_CONSTRUCTING_CREATE_DIRECTIVE_PLAN);
  const haveCreateSectionPlanConstructingPermission = usePermission(VIEW_CONSTRUCTING_CREATE_SECTION_PLAN);
  const haveCreateSupersectionPlanManufacturingPermission = usePermission(VIEW_MANUFACTURING_CREATE_DIRECTIVE_PLAN);
  const haveCreateSectionPlanManufacturingPermission = usePermission(VIEW_MANUFACTURING_CREATE_SECTION_PLAN);

  const canAddSupersectionPlan =
    activeTabId === PLAN &&
    ((urlModule === "constructing" && haveCreateSupersectionPlanConstructingPermission) ||
      (urlModule === "objects" && haveCreateSupersectionPlanManufacturingPermission));

  const canAddSectionPlan =
    activeTabId === PLAN &&
    ((urlModule === "constructing" && haveCreateSectionPlanConstructingPermission) ||
      (urlModule === "objects" && haveCreateSectionPlanManufacturingPermission));

  const [openedParentId, setOpenedParentId] = React.useState(0);

  const { planData, refetchSectionPlan } = useSectionPlan(openedParentId || -1, !!openedParentId);

  const tabs = React.useMemo(() => {
    const res = [];
    if (permissions.canViewProgress) {
      res.push({
        text: "Прогресс",
        id: PROGRESS,
        className: styles.tab,
      });
    }
    if (permissions.canViewPlan) {
      res.push({
        text: "Планирование",
        id: PLAN,
        className: styles.tab,
      });
    }
    if (permissions.canViewDrawings) {
      res.push({
        text: "Документация",
        id: DOCUMENTATION,
        className: styles.tab,
      });
    }
    return res;
  }, [permissions.canViewPlan, permissions.canViewProgress, permissions.canViewDrawings]);

  const ks2Id = useQueryParams("ks2Id");
  const ks3Id = useQueryParams("ks3Id");

  useEffect(() => {
    if (sections.length && isNeedToOpenPlan && !openedParentId) {
      loadSubSection(+openSectionId!);
      setOpenedParentId(+openSectionId!);
    }
  }, [isNeedToOpenPlan, sections]);

  useEffect(() => {
    dispatch(getKsLists(buildingId));
  }, [buildingId]);

  const handleCloseGroupModal = useCallback(() => {
    setIsShowGroupModal(false);
  }, []);

  const handleOpenCreateGroupModal = useCallback(() => {
    setIsShowGroupModal(true);
  }, []);

  useEffect(() => {
    if (!isUpdateGroupSuccess) return;

    setIsShowGroupModal(false);
  }, [isUpdateGroupSuccess]);

  const handleCreateGroup = useCallback(
    (values: ICreateGroupValues) => {
      dispatch(createExpendituresGroup(buildingId, values));
    },
    [buildingId, selectedProducts]
  );

  const handleBackClick = useCallback(() => {
    setSelectedSection(null);
    history.replace({ search: "" });
  }, []);

  const onAddOutOfEstimateClick = useCallback(() => {
    dispatch(createOutOfEstimateSectionActions.open({ parentId: selectedSection?.id }));
  }, [selectedSection]);

  const isCreateOutOfEstimateSectionVisibleRaw = useSelector(createOutOfEstimateSectionSelectors.getIsVisible);
  const isCreateOutOfEstimateSectionVisible = !isLoadingSubSections && isCreateOutOfEstimateSectionVisibleRaw;

  useEffect(() => {
    if (activeTabId !== TabsEnum.plan) {
      dispatch(createOutOfEstimateSectionActions.clear());
    }
  }, [activeTabId]);

  const onCreateOutOfEstimateSectionCallback = useCallback(async () => {
    if (selectedSection) {
      // уровень подразделов, перезагружаем их
      const section = await apiLoadProductsSection(+buildingId, selectedSection.id, editable);

      await apiLoadSections(buildingId, ESTIMATE_STATES_IDS.PRODUCTION, {
        parent: selectedSection.id,
      })
        .then((responseData) => setSelectedSection(() => ({ ...section, subsections: responseData?.results })))
        .catch(errorCatcher);
    } else {
      // верхний уровень, перезагружаем секции
      compose<Action>(dispatch, loadSections)({ estimateStateId: ESTIMATE_STATES_IDS.PRODUCTION, buildingId });
    }
  }, [buildingId, selectedSection]);

  if ((!permissions.canViewPlan && !permissions.canViewProgress && !permissions.canViewDrawings) || !activeTabId) {
    return (
      <TemplateBase>
        <ForbiddenPage dataTestId={forbiddenTestId} />
      </TemplateBase>
    );
  }

  if (activeTabId === DOCUMENTATION) {
    return (
      <Blueprints
        permissions={permissions}
        activeId={activeTabId}
        tabs={tabs as any}
        onTabChange={(tab: TabsEnum) => {
          if ((tab as string) === activeTabId) return;
          handleChangeActiveTab(tab);
        }}
      />
    );
  }

  return (
    <>
      {ks2Id && <KS2Page />}
      {ks3Id && <KS3Page />}
      <TemplateBase contentClassName={styles.outerTemplate} dataTestId={templateTestId}>
        <div className={styles.head}>
          <ProjectNavbar
            isTabs={!isShared}
            onTabChange={(tab: TabsEnum) => {
              if (tab === activeTabId) return;
              dispatch(clearIntervals());
              dispatch(setLoadingIntervals(true));
              handleChangeActiveTab(tab);
            }}
            activeTab={activeTabId}
            filters={filter}
            setFilters={setFilter}
            timeSliderDate={date}
            onChangeTimeSlider={setDate}
            selectingMonthsCount={selectingMonthsCount}
            setSelectingMonthsCount={setSelectingMonthsCount}
            permissions={permissions}
            /* @ts-ignore */
            location={location}
            match={match}
            history={history}
          />
          {isSectionsExist && (
            <>
              <EstimateIndicators id={buildingId} isOpen={isIndicatorsOpen} setIsOpen={setIsIndicatorsOpen} />
              <ProjectHeader
                isExists={!Boolean(selectedSection)}
                back={backPath}
                name={objectInfo?.name}
                isShared={isShared}
                isShowSharedButtons={isShowSharedButtons}
                onApproveSharing={approveSharingHandler}
                onDeclineSharing={declineSharingHandler}
                isSharedStatusTransferred={objectInfo?.shared_status === "transferred"}
              />
              <ProjectSubsectionsHeader
                onBackClick={handleBackClick}
                isExists={Boolean(selectedSection)}
                projectName={objectInfo?.name}
                isShared={isShared}
                subSection={selectedSection}
                onApproveSharing={approveSharingHandler}
                onDeclineSharing={declineSharingHandler}
              />
              <ProjectSharingBar
                handleOpenModal={handleOpenCreateGroupModal}
                isExists={canSharing || canGroup}
                canSharing={canSharing}
                canGroup={canGroup}
                canAddOutOfEstimate={canAddOutOfEstimate}
                onAddOutOfEstimateClick={onAddOutOfEstimateClick}
                sharingCallback={sharingCallback}
                filters={filter}
              />
            </>
          )}
        </div>
        {isSectionsExist ? (
          <div className={styles.container}>
            {isCreateOutOfEstimateSectionVisible && (
              <CreateOutOfEstimateSection
                parentId={selectedSection?.id}
                onCreateCallback={onCreateOutOfEstimateSectionCallback}
              />
            )}
            {isShowGroupModal && (
              /* @ts-ignore */
              <ExpenditureGroupModal
                isCreation
                isShowButtons
                isOpen={isShowGroupModal}
                onClose={handleCloseGroupModal}
                handleCreate={handleCreateGroup}
                creationExpenditures={selectedProducts}
              />
            )}
            {isLoadingSubSections && <Spinner />}
            {!selectedSection &&
              sections &&
              !isLoadingSubSections &&
              sections?.map((section, index) => {
                const canShareSection = canSharing && !section.providers?.length;
                if (section.hidden_status === 1) return;
                return (
                  <div
                    key={section.id}
                    onClick={() => {
                      loadSubSection(section.id);
                      setOpenedParentId(section.id);
                    }}
                  >
                    {/* @ts-ignore */}
                    <Section
                      section={section}
                      canSharing={canShareSection}
                      canCheck={canCheck}
                      activeTab={activeTabId}
                      filters={filter}
                      dateEnd={endOfMonthFormattedDate}
                      dateStart={startOfMonthFormattedDate}
                      permissions={permissions}
                      canAddPlan={canAddSupersectionPlan}
                      canViewPlanData={activeTabId === PLAN}
                      refetchLsrPlan={refetchSectionPlan}
                    />
                  </div>
                );
              })}
            {selectedSection && (
              <>
                {selectedSection.subsections?.length > 0 &&
                  selectedSection.subsections?.map((subSection, index) => {
                    if (subSection.hidden_status === 1) return;
                    return (
                      <SubSection
                        objectId={+buildingId}
                        section={subSection}
                        editable={editable}
                        /* @ts-ignore */
                        activeTab={activeTabId}
                        date_start={startOfMonthFormattedDate}
                        date_end={endOfMonthFormattedDate}
                        isShared={isShared}
                        isIndicatorsOpen={isIndicatorsOpen}
                        key={subSection.id}
                        filters={filter!}
                        canSharing={canSharing}
                        canCheck={canCheck}
                        permissions={permissions}
                        canAddPlan={canAddSectionPlan}
                        lsrPlanData={planData!}
                        isKSHidden={subSection.is_default}
                        /* @ts-ignore */
                        isDefaultOpened={+openSubsectionId === +subSection.id}
                      />
                    );
                  })}
                {selectedSection.subsections.filter((el) => el.hidden_status !== 1)?.length === 0 && (
                  <div className={styles.errorMessage}>Нет разделов</div>
                )}
              </>
            )}
          </div>
        ) : sectionsAreLoading ? (
          <div data-testid="spinner">
            <Spinner />
          </div>
        ) : (
          <EmptyPlaceholder img={estimateIcon} />
        )}
      </TemplateBase>
    </>
  );
};

export default Building;
