export const handlerCountSvg = <svg width="50" height="34" viewBox="0 0 50 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
        <path d="M5 19.5C5 18.1193 3.88071 17 2.5 17C1.11929 17 0 18.1193 0 19.5V31.5C0 32.8807 1.11929 34 2.5 34C3.88071 34 5 32.8807 5 31.5V19.5Z" fill="#E8E8E8" />
        <path d="M14 3.5C14 2.11929 12.8807 1 11.5 1C10.1193 1 9 2.11929 9 3.5V31.5C9 32.8807 10.1193 34 11.5 34C12.8807 34 14 32.8807 14 31.5V3.5Z" fill="#E8E8E8" />
        <path d="M23 3.5C23 2.11929 21.8807 1 20.5 1C19.1193 1 18 2.11929 18 3.5V31.5C18 32.8807 19.1193 34 20.5 34C21.8807 34 23 32.8807 23 31.5V3.5Z" fill="#E8E8E8" />
        <path className="handlerCountSvg" d="M5 26.5C5 25.1193 3.88071 24 2.5 24C1.11929 24 0 25.1193 0 26.5V31.5C0 32.8807 1.11929 34 2.5 34C3.88071 34 5 32.8807 5 31.5V26.5Z" fill="#ADE9FF" />
        <path className="handlerCountSvg" d="M14 12.5C14 11.1193 12.8807 10 11.5 10C10.1193 10 9 11.1193 9 12.5V31.5C9 32.8807 10.1193 34 11.5 34C12.8807 34 14 32.8807 14 31.5V12.5Z" fill="#ADE9FF" />
        <path className="handlerCountSvg" d="M23 12.5C23 11.1193 21.8807 10 20.5 10C19.1193 10 18 11.1193 18 12.5V31.5C18 32.8807 19.1193 34 20.5 34C21.8807 34 23 32.8807 23 31.5V12.5Z" fill="#ADE9FF" />
        <path className="handlerCountSvg" d="M32 7.5C32 6.11929 30.8807 5 29.5 5C28.1193 5 27 6.11929 27 7.5V31.5C27 32.8807 28.1193 34 29.5 34C30.8807 34 32 32.8807 32 31.5V7.5Z" fill="#ADE9FF" />
        <path className="handlerCountSvg" d="M41 2.2973C41 1.02854 39.8807 0 38.5 0C37.1193 0 36 1.02854 36 2.2973V31.7027C36 32.9715 37.1193 34 38.5 34C39.8807 34 41 32.9715 41 31.7027V2.2973Z" fill="#ADE9FF" />
        <path className="handlerCountSvg" d="M50 7.5C50 6.11929 48.8807 5 47.5 5C46.1193 5 45 6.11929 45 7.5V31.5C45 32.8807 46.1193 34 47.5 34C48.8807 34 50 32.8807 50 31.5V7.5Z" fill="#ADE9FF" />
    </g>
</svg>
