import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IHelpExpanditure } from "./types";

export interface AutoCompleteOutOfEstimateState {
  expenditures: Record<string, IHelpExpanditure[]>;
  isLoading: Record<string, boolean>;
}

const initialState: AutoCompleteOutOfEstimateState = {
  expenditures: {},
  isLoading: {},
};

export const autoCompleteOutOfEstimateSlice = createSlice({
  name: "autoCompleteOutOfEstimate",
  initialState,
  reducers: {
    setExpenditures: (state, action: PayloadAction<{ key: string; exps: IHelpExpanditure[] }>) => {
      const { key, exps } = action.payload;
      state.expenditures[key] = exps;
    },
    setLoading: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      const { key, status } = action.payload;
      state.isLoading[key] = status;
    },
    clear: (state) => {
      state.expenditures = {};
      state.isLoading = {};
    },
  },
});

export const autoCompleteOutOfEstimateActions = autoCompleteOutOfEstimateSlice.actions;

export default autoCompleteOutOfEstimateSlice.reducer;
