import cn from "classnames";
import React, { FC, useCallback } from "react";

import MatchingIcon, { MatchingStatusesEnum } from "_LEGACY/UI/MatchingIcon/MatchingIcon";

import Check from "images/icons/Check";
import closeIcon from "images/icons/closeNotyItem.svg";

import styles from "./ConfirmWriteOffExpenditure.module.scss";

export interface IExpenditure {
  id: number | string;
  name: string;
  isConfirm: boolean;
  writeOffTarget: string;
  matchingStatus: MatchingStatusesEnum;
}

export type ActionHandler = (expenditureId: IExpenditure["id"]) => void;

export interface IConfirmWriteOffExpenditureProps {
  expenditure: IExpenditure;
  onCancel: ActionHandler;
  onConfirm: ActionHandler;
  onChangeWriteOffTarget: ActionHandler;
}

const ConfirmWriteOffExpenditure: FC<IConfirmWriteOffExpenditureProps> = ({
  expenditure,
  onCancel,
  onConfirm,
  onChangeWriteOffTarget,
}) => {
  const handleOnCancel = useCallback(() => onCancel(expenditure.id), [expenditure.id, onCancel]);
  const handleOnConfirm = useCallback(() => onConfirm(expenditure.id), [expenditure.id, onConfirm]);

  const handleOnChangeWriteOffTarget = useCallback(
    () => onChangeWriteOffTarget(expenditure.id),
    [expenditure.id, onChangeWriteOffTarget]
  );

  return (
    <div
      data-testid={`expenditure-${expenditure.id}`}
      className={cn(styles.expenditure, { [styles.confirmed]: expenditure.isConfirm })}
      style={{ backgroundColor: expenditure.isConfirm ? "#E2FAFA" : "#F4F4F4" }}
    >
      <div>
        <button data-testid="cancelButton" className={styles.cancelButton} onClick={handleOnCancel}>
          <img src={closeIcon} alt="&#65794;" />
        </button>
      </div>
      <div className={styles.content}>
        <div>
          <div className={styles.label}>Наименование:</div>
          <div data-testid="expenditureName" className={styles.name}>
            <span title={expenditure.name}>{expenditure.name}</span>
          </div>
        </div>
        <footer className={styles.footer}>
          <div>
            <div className={styles.label}>Списание на:</div>
            <div data-testid="expenditureWriteOffTarget" className={styles.name}>
              <span title={expenditure.writeOffTarget} className={styles.writeOffTarget}>
                {expenditure.writeOffTarget ? expenditure.writeOffTarget : "-"}
              </span>
            </div>
          </div>
          <div className={styles.actions}>
            <div data-testid="matchingIcon" className={styles.matchingContainer} onClick={handleOnChangeWriteOffTarget}>
              <MatchingIcon className={styles.matching} status={expenditure.matchingStatus} />
            </div>
            <button data-testid="confirmButton" className={styles.confirmButton} onClick={handleOnConfirm}>
              <Check width="16" />
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default React.memo(ConfirmWriteOffExpenditure);
