import { memo } from "react";

import Spinner from "../../../../../../../../shared/ui/atoms/Spinner/Spinner";
import { ExpenditureRow } from "./ExpenditureRow/ExpenditureRow";
import { ResourceRow } from "./ResourceRow/ResourceRow";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

import { ExpenditureTypeEnum } from "../../../../../../../../types/enums/ExpenditureTypeEnum";
import { IEditingExpenditure } from "../../../../types";
import { IExpenditureFormState } from "./types";

import { ReactComponent as IconPlus } from "shared/assets/icons/Icon-Plus-Black.svg";
import { ReactComponent as CheckIcon } from "shared/assets/icons/IconCheckWhite.svg";
import { ReactComponent as CloseIcon } from "shared/assets/icons/IconCloseWhite.svg";

import styles from "./ExpendituresForm.module.scss";

interface IProps {
  onClose?: () => void;
  onSave?: (formState: IExpenditureFormState) => void;
  isLoading?: boolean;
  defaultValues?: IEditingExpenditure;
}

const createResource = (type: ExpenditureTypeEnum) => ({
  id: undefined,
  type,
  name: "",
  measure: "",
  count: "",
  price: "",
  amount: "",
});

export const ExpendituresForm = memo<IProps>(({ onClose, onSave, isLoading, defaultValues }) => {
  const defaultResources =
    defaultValues?.resources?.map((r) => ({
      ...r,
      amount: (+r.price * +r.count).toString(),
    })) ?? [];

  const form = useForm<IExpenditureFormState>({
    defaultValues: {
      id: defaultValues?.id,
      name: defaultValues?.name ?? "",
      measure: defaultValues?.measure ?? "",
      count: defaultValues?.count ?? "",
      price: defaultValues?.price ?? "",
      amount: defaultValues ? (+defaultValues.price * +defaultValues.count).toString() : "",
      resources: defaultResources,
    },
  });

  const {
    fields: resources,
    append: appendResource,
    remove: removeResource,
  } = useFieldArray({
    control: form.control,
    name: "resources",
    keyName: "formId",
  });

  const onSubmit = (formState: IExpenditureFormState) => {
    onSave?.(formState);
  };

  const hasRemovableResources = resources.some((r) => !r.id);

  return (
    <FormProvider {...form}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.title}>{defaultValues ? "Редактирование" : "Добавление"}&nbsp;позиции</span>
          <div className={styles.actionsWrapper}>
            <div className={styles.actionsDescription}>Добавить к работе:</div>
            <div className={styles.buttons}>
              <button
                type="button"
                className={styles.addButton}
                onClick={() => appendResource(createResource(ExpenditureTypeEnum.material))}
              >
                <IconPlus />
                <span>Материал</span>
              </button>
              <button
                type="button"
                className={styles.addButton}
                onClick={() => appendResource(createResource(ExpenditureTypeEnum.transport))}
              >
                <IconPlus />
                <span>Транспорт</span>
              </button>
              <button
                type="button"
                className={styles.addButton}
                onClick={() => appendResource(createResource(ExpenditureTypeEnum.equipment))}
              >
                <IconPlus />
                <span>Оборудование</span>
              </button>
            </div>
            <div className={styles.closeOrSaveButton}>
              <button type="button" className={styles.save} disabled={isLoading} onClick={form.handleSubmit(onSubmit)}>
                {isLoading ? (
                  <Spinner isStatic isWhite className={styles.spinnerContainer} spinnerClassName={styles.spinner} />
                ) : (
                  <CheckIcon />
                )}
              </button>
              <button type="button" disabled={isLoading} className={styles.close} onClick={onClose}>
                <CloseIcon />
              </button>
            </div>
          </div>
        </div>

        <ExpenditureRow hasRightPadding={hasRemovableResources} />

        {!!resources.length && (
          <div className={styles.resourcesRows}>
            <div className={styles.divider} />

            {resources.map((r, idx) => (
              <ResourceRow
                key={r.formId}
                idx={idx}
                hasRemoveButtonPadding={hasRemovableResources}
                isRemovable={!r.id}
                onRemove={() => removeResource(idx)}
              />
            ))}
          </div>
        )}
      </div>
    </FormProvider>
  );
});

ExpendituresForm.displayName = "ExpendituresForm";
