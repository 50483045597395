import { useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { IHandlerMatchParams } from "../../../types/MatchParams";
import { EHandlerLevels } from "../types";

interface IMatchParams {
  projectId: string;
  buildingId: string;
  sectionId?: string;
  subsectionId?: string;
}

/**
 * В зависимости от параметров роутера возвращает
 * уровень вложенности обработчика
 */
export const useHandlerLevel = (): EHandlerLevels => {
  const match = useRouteMatch();
  const sectionIdMatch = useRouteMatch<IHandlerMatchParams>(`${match.path}/:sectionId`);
  const subsectionIdMatch = useRouteMatch<IHandlerMatchParams>(`${match.path}/:sectionId/:subsectionId`);

  const level = useMemo(() => {
    if (subsectionIdMatch && subsectionIdMatch.params.subsectionId) {
      return EHandlerLevels.EXPENDITURES;
    } else if (sectionIdMatch && sectionIdMatch.params.sectionId) {
      return EHandlerLevels.SUBSECTIONS;
    } else {
      return EHandlerLevels.SECTIONS;
    }
  }, [sectionIdMatch, subsectionIdMatch]);

  return level;
};
