import { memoize, partial } from "lodash";
import { useEffect, useState } from "react";
import { compose } from "redux";

import { options } from "../options";

import { stringifyArgs } from "../../../../../utils/helpers/stringifyArgs";

const getOptionsByIds = (optionsIds) => {
  const allOptions = options;
  const resultOptions = [];

  optionsIds.forEach((optionId) => {
    const option = allOptions[optionId];

    resultOptions.push(option);
  });

  return resultOptions;
};

const getTruthOptionsIds = (optionsIdsFlags) => {
  const truthOptionsIds = [];

  for (const optionId in optionsIdsFlags) {
    const flag = optionsIdsFlags[optionId];

    if (flag) truthOptionsIds.push(optionId);
  }

  return truthOptionsIds;
};

const fillOptionsCallbacksArgs = (dispatch, orderId, options) =>
  options.map((option) => ({
    ...option,
    callback: () => option.callback(dispatch, orderId),
  }));

const useManageOrderOptions = (optionsIdsFlags, dispatch, orderId) => {
  const partialFillOptionsCallbacksArgs = partial(fillOptionsCallbacksArgs, dispatch, orderId);

  const [activeOptions, setActiveOptions] = compose(
    useState,
    partialFillOptionsCallbacksArgs,
    getOptionsByIds,
    getTruthOptionsIds
  )(optionsIdsFlags);

  useEffect(() => {
    compose(setActiveOptions, partialFillOptionsCallbacksArgs, getOptionsByIds, getTruthOptionsIds)(optionsIdsFlags);
  }, [optionsIdsFlags]);

  return activeOptions;
};

export default useManageOrderOptions;
