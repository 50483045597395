import React, { useCallback, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { compose } from "redux";

import { addVirtualProvider } from "redux/modules/common/building/virtualProviders";

import AddButton from "shared/ui/controls/AddButton/AddButton";
import ButtonBase from "shared/ui/controls/ButtonBase";
import Input from "shared/ui/inputs/InputBase";

import { LOCALIZATION_CONFIG } from "../../../../../constants/localization";

import { composeFieldValidators, maxLength, required } from "utils/formHelpers/validations";

import arrowDownSelect from "images/icons/arrowDownSelect.svg";

import styles from "./index.module.scss";

const FORM_FIELDS = {
  NICKNAME: "nickname",
  NAME: "name",
  EMAIL: "email",
  ITN: "itn",
};

const CreateVirtualProvider = ({ reload }) => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const formRef = useRef();

  const resetFormFieldsWithTimeout = useCallback(() => {
    setTimeout(() => {
      if (!formRef.current) return;
      formRef.current.form.reset();
      Object.values(FORM_FIELDS).forEach(formRef.current.form.resetFieldState);
    }, 500);
  }, []);

  const onSubmit = useCallback(
    (values) => {
      compose(dispatch, addVirtualProvider)(values);
      resetFormFieldsWithTimeout();
      reload();
    },
    [reload, resetFormFieldsWithTimeout]
  );

  const handleShowForm = useCallback(() => setShowForm((prev) => !prev), [setShowForm]);

  return (
    <div className={styles.container}>
      <div className={styles.showForm} onClick={handleShowForm}>
        <div className={styles.title}>Создать поставщика</div>
        {showForm ? (
          <div className={styles.arrowDown} style={{ transform: "rotate(180deg)" }}>
            <img src={arrowDownSelect} alt="Иконка стрелки" />
          </div>
        ) : (
          <div className={styles.arrowDown}>
            {/* <img src={arrowDownSelect} alt="Иконка стрелки" /> */}
            <AddButton className={styles.plus} />
          </div>
        )}
      </div>
      {showForm && (
        <Form
          ref={formRef}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.formRow}>
                <div className={styles.field}>
                  <label>Наименование</label>
                  <Field
                    name={FORM_FIELDS.NICKNAME}
                    component={Input}
                    classNameInput={styles.input}
                    validate={composeFieldValidators(required(), maxLength(256))}
                  />
                </div>
                <div className={styles.field}>
                  <label>Юр. наименование</label>
                  <Field
                    name={FORM_FIELDS.NAME}
                    component={Input}
                    classNameInput={styles.input}
                    validate={composeFieldValidators(required(), maxLength(256))}
                  />
                </div>
                <div className={styles.field}>
                  <label>Эл. почта</label>
                  <Field
                    name={FORM_FIELDS.EMAIL}
                    component={Input}
                    classNameInput={styles.input}
                    validate={composeFieldValidators(required(), maxLength(256))}
                  />
                </div>
                <div className={styles.field}>
                  <label>{LOCALIZATION_CONFIG.taxesNumberLabel}</label>
                  <Field
                    name={FORM_FIELDS.ITN}
                    component={Input}
                    classNameInput={styles.input}
                    validate={composeFieldValidators(required(), maxLength(256))}
                  />
                </div>
              </div>
              <footer className={styles.formFooter}>
                <ButtonBase type="submit" primary medium>
                  Добавить
                </ButtonBase>
              </footer>
            </form>
          )}
        />
      )}
    </div>
  );
};

export default React.memo(CreateVirtualProvider);
