import cn from "classnames";
import React from "react";

import { BetonIncomeStatusNamesEnum, IBetonIncomeItem } from "redux/modules/common/building/beton/types";

import styles from "./BetonProgress.module.scss";

interface IProps {
  progress: number;
  status: IBetonIncomeItem["status"];
}

const BetonProgress: React.FC<IProps> = ({ progress, status }) => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <span>{BetonIncomeStatusNamesEnum[status]}</span>
        <span>{progress}%</span>
      </div>
      <div className={styles.bar}>
        <div style={{ width: `${progress}%` }} className={cn(styles.progress, styles[status])}></div>
      </div>
    </div>
  );
};

export default BetonProgress;
