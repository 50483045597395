import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";

import InputBase, { VALUE_TYPES } from "../../../../../../shared/ui/inputs/InputBase";

import { IMimsInAdding } from "types/interfaces/Mims";

import { stopEventPropagation } from "../../../../../../utils/helpers/stopEventPropagation";
import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";
import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";

import styles from "./TableRow.module.scss";

interface IProps {
  expenditure: IMimsInAdding;
  onChangeCount: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete?: () => void;
}

const ExpenditureTableRow: React.FC<IProps> = ({ expenditure, onChangeCount }) => {
  const [localValue, setLocalValue] = useState<string>(expenditure?.local_count || "");

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCount(e);
    setLocalValue(e.target.value?.replace(",", "."));
  };

  useEffect(() => {
    setLocalValue(expenditure.local_count || "");
  }, [expenditure]);

  const materialCountWithMeasure = `${dropNonSignificantZeros(expenditure?.count, 2)} ${sliceTextByConstraint(
    expenditure?.measure,
    4
  )}`;

  return (
    <div className={cn(styles.row, styles.expenditureRow)}>
      <div />
      <div className={styles.name} title={expenditure?.name}>
        {expenditure?.name}
      </div>
      <div onClick={stopEventPropagation}>
        <InputBase
          classNameInput={styles.input}
          onChange={changeHandler}
          placeholder="Кол-во"
          value={localValue}
          valueType={VALUE_TYPES.NUMBER}
        />
      </div>
      <div className={cn(styles.name, styles.measure)} title={`${expenditure?.count} ${expenditure?.measure}`}>
        {materialCountWithMeasure}
      </div>
    </div>
  );
};

export default React.memo(ExpenditureTableRow);
