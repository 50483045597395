import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  betonExpenseSelector,
  betonIncomeLoadingSelector,
  betonInvalidateKeySelector,
} from "redux/modules/common/building/beton/selectors";
import { getBetonExpense } from "redux/modules/common/building/beton/thunks";

import BetonExpenseHeader from "./components/BetonExpenseHeader/BetonExpenseHeader";
import BetonExpenseRow from "./components/BetonExpenseRow/BetonExpenseRow";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import ListWrapper from "shared/ui/layout/ListWrapper/ListWrapper";

import { getDateForBetonFilter } from "../utils";

import beton from "images/icons/navigation/beton.svg";

interface IProps {
  stockId?: number;
  isStockMismatching: boolean;
  month: number;
  year: number;
}

const BetonExpense: React.FC<IProps> = ({ stockId, isStockMismatching, year, month }) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const expense = useSelector(betonExpenseSelector)[stockId ?? "-1"];
  const incomeIsLoading = useSelector(betonIncomeLoadingSelector)[objectId];
  const invalidateKey = useSelector(betonInvalidateKeySelector);
  const [filter, setFilter] = useState<Record<string, string>>({});

  useEffect(() => {
    if (isStockMismatching) return;
    if (!stockId) return;
    const dates = getDateForBetonFilter(year, month, "created_at");
    dispatch(getBetonExpense(stockId, objectId, { ...filter, ...dates }));
  }, [invalidateKey, isStockMismatching, stockId, filter, year, month]);

  return (
    <div>
      <ListWrapper
        header={
          <BetonExpenseHeader onChangeFilter={(name, value) => setFilter((prev) => ({ ...prev, [name]: value }))} />
        }
        isLoading={incomeIsLoading || isStockMismatching}
        data={expense?.results}
        dataMapper={(el) => <BetonExpenseRow key={el.id} item={el} stockId={stockId!} />}
        filter={filter}
        placeholderProps={{ img: beton }}
      />
    </div>
  );
};

export default BetonExpense;
