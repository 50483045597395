import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";

import { USER_AUTO_LOGOUT } from "../../redux/modules/_TODO/auth";

import store from "../store/store";

class ProtectedRoute extends Component {
  render() {
    const { component: Main, auth, location, SubstituteComponent, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (auth.isAuthenticated) {
            return <Main {...props} />;
          } else if (!auth.isAuthenticated && location.pathname !== "/auth" && !SubstituteComponent) {
            if (auth.isAuthenticated !== null) {
              sessionStorage.setItem("lastRoutes", window.location.pathname);
              if (window.location.search) {
                sessionStorage.setItem("lastQuery", window.location.search);
              }
            }
            store.dispatch({
              type: USER_AUTO_LOGOUT,
            });
            return <Redirect to="/auth" />;
          }
          return SubstituteComponent ? <SubstituteComponent {...props} /> : null;
        }}
      />
    );
  }
}

export default withRouter(
  connect((state) => ({
    auth: state.auth,
  }))(ProtectedRoute)
);
