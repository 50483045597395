import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { IBuildingPropsPermissions } from "components/pages/Building/Building";
import { IExternalOffsetsSubsection } from "components/pages/Building/components/SubSection/SubSection";
import SubSection from "components/pages/Building/components/SubSection/SubSectionContainer";
import { getBuildingSubsections } from "components/pages/Building/model/thunks";
import { IExpenditureTypesFiltersInBuilding } from "components/pages/Building/types";

import { useTypedSelector } from "app/store/typedUseSelector";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

interface IProps {
  editable?: boolean;
  permissions: IBuildingPropsPermissions;
  isIndicatorsOpen: boolean;
  filters: IExpenditureTypesFiltersInBuilding;
  startOfMonthFormattedDate: string;
  endOfMonthFormattedDate: string;
}

const ConstructionControlObjectSubSections: React.FC<IProps> = ({
  editable,
  permissions,
  isIndicatorsOpen,
  filters,
  startOfMonthFormattedDate,
  endOfMonthFormattedDate,
}) => {
  const { objectId, sectionId, tab } = useParams<any>();
  const key = generateStorageKey({ buildingId: objectId, id: sectionId, type: "subSections" });
  const dispatch = useDispatch();
  const isLoading = useTypedSelector((state) => state.buildingReducer.loadings)[key];
  const subSections = useTypedSelector((state) => state.buildingReducer.sections)[key];

  useEffect(() => {
    dispatch(getBuildingSubsections(objectId, { parent: sectionId }));
  }, [objectId, sectionId]);

  const externalTopOffsets = useMemo<IExternalOffsetsSubsection>(() => {
    return { subsection: isIndicatorsOpen ? 12 : 6, products: isIndicatorsOpen ? 15 : 9 };
  }, [isIndicatorsOpen]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!isLoading && !subSections?.length) {
    return <EmptyPlaceholder />;
  }

  return (
    <div>
      {subSections?.map((subSection) => {
        if (subSection.hidden_status === 1) return;
        return (
          <SubSection
            key={subSection.id}
            objectId={+objectId}
            section={subSection as any}
            editable={editable!}
            activeTab={tab}
            date_start={startOfMonthFormattedDate}
            date_end={endOfMonthFormattedDate}
            isShared={false}
            isIndicatorsOpen={isIndicatorsOpen}
            filters={filters!}
            canSharing={false}
            //canCheck={canCheck}
            permissions={permissions}
            //canAddPlan={canAddSectionPlan}
            //lsrPlanData={planData!}
            //isKSHidden={subSection.is_default}
            //isDefaultOpened={+openSubsectionId === +subSection.id}
            externalTopOffsets={externalTopOffsets}
          />
        );
      })}
    </div>
  );
};

export default ConstructionControlObjectSubSections;
