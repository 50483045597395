import { IAookAction } from "./actions";

import { IAook, IAookState } from "./types";

export const aosrInitialState: IAookState = {
  currentAook: null,
  detailedAooks: {},
  docs: {},
  isClosingPending: {},
  invalidateKey: 0,
  isCreatingPending: {},
  worksBySections: [],
};

export default (state = aosrInitialState, action: IAookAction): IAookState => {
  const { type, payload } = action;
  switch (type) {
    case "aook/SET_CURRENT_AOOK":
      return {
        ...state,
        currentAook: payload,
      };
    case "aook/SET_WORKS":
      return {
        ...state,
        worksBySections: {
          ...state.worksBySections,
          [payload.sectionId]: payload.works,
        },
      };
    case "aook/SET_DETAILED_AOOK":
      return {
        ...state,
        detailedAooks: {
          ...state.detailedAooks,
          [payload.id]: payload,
        },
      };
    case "aook/SET_DOCS":
      return {
        ...state,
        docs: {
          ...state.docs,
          [payload.aookId]: payload.docs,
        },
      };
    case "aook/SET_CLOSING_PENDING":
      return {
        ...state,
        isClosingPending: {
          ...state.isClosingPending,
          [payload.aookId]: payload.status,
        },
      };
    case "aook/INVALIDATE_KEY":
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    case "aook/SET_CREATING_PENDING":
      return {
        ...state,
        isCreatingPending: {
          ...state.isCreatingPending,
          [payload.aookId]: payload.status,
        },
      };
    default:
      return state;
  }
};
