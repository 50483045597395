import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { compose } from "redux";

import Calendar from "../../../../components/pages/Calendar";
import PaymentsList from "../../../../components/pages/PaymentsList";
import SuppliesList from "../../../../components/pages/SuppliesList";
import { ProfilePage } from "../../../../features/templates/Header/components/Profile/components/ProfilePage/ProfilePage";
import { useLoadUIIndicators } from "../../../../hooks/uiIndicators/useLoadUIIndicators";
import FilesStorage from "components/pages/FilesStorage/FilesStorage";
import Tasks from "components/pages/Tasks/Tasks";
import TasksLists from "components/pages/Tasks/TasksLists";
import { TASK_LISTS_TYPES } from "components/pages/Tasks/constants";

import DocumentsRoutes from "../DocumentsRoutes/DocumentsRoutes";
import SettingsRoutes from "../SettingsRoutes/SettingsRoutes";

import {
  VIEW_ADD_BILL_FILES,
  VIEW_ADD_BILL_PAYMENTS_FILE,
  VIEW_ADD_CONTRACT_FILES,
  VIEW_ADD_PACKING_LIST_FILES,
  VIEW_BILL,
  VIEW_BILLS_LIST,
  VIEW_BILL_IN_EDIT,
  VIEW_BILL_PAYMENTS_MAKE_PAID,
  VIEW_CONTRACTS_LIST,
  VIEW_DELETE_BILL_FILES,
  VIEW_DELETE_CONTRACT,
  VIEW_DELETE_CONTRACT_FILES,
  VIEW_DELETE_EXPORTS,
  VIEW_DELETE_PACKING_LIST_FILES,
  VIEW_DOCUMENTS_SECTION,
  VIEW_EXPORT_LIST,
  VIEW_PACKING_LISTS,
  VIEW_PACKING_LISTS_VAT_CALCULATION,
  VIEW_PACKING_LIST_ACCEPT,
  VIEW_PACKING_LIST_PAGE,
} from "constants/permissions/constructingPermissions";
import { VIEW_SETTINGS_SECTION } from "constants/permissions/generalPermissions";

import { useLastObjectId } from "../../../../utils/hooks/uselastObjectId";
import { usePermissionsObject } from "features/permissions/hooks/usePermissionsObject";
import usePermission from "hooks/usePermission";

function TasksRoutes() {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  useLastObjectId();
  useLoadUIIndicators();

  const haveViewDocumentsPermission = usePermission(VIEW_DOCUMENTS_SECTION);
  const haveViewSettingsPermission = usePermission(VIEW_SETTINGS_SECTION);

  const documentsPermissions = usePermissionsObject({
    viewBillsList: VIEW_BILLS_LIST,
    viewBill: VIEW_BILL,
    viewPackingLists: VIEW_PACKING_LISTS,
    viewPackingListPage: VIEW_PACKING_LIST_PAGE,
    viewPackingListsVatCalculation: VIEW_PACKING_LISTS_VAT_CALCULATION,
    viewContractsList: VIEW_CONTRACTS_LIST,
    viewDeleteContract: VIEW_DELETE_CONTRACT,
    viewAddContractFiles: VIEW_ADD_CONTRACT_FILES,
    viewDeleteContractFiles: VIEW_DELETE_CONTRACT_FILES,
    viewExportList: VIEW_EXPORT_LIST,
    viewDeleteExports: VIEW_DELETE_EXPORTS,
  });

  const billPermissions = usePermissionsObject({
    viewBillInEdit: VIEW_BILL_IN_EDIT,
    addFiles: VIEW_ADD_BILL_FILES,
    deleteFiles: VIEW_DELETE_BILL_FILES,
    viewAddBillPaymentsFile: VIEW_ADD_BILL_PAYMENTS_FILE,
    viewBillPaymentsMakePaid: VIEW_BILL_PAYMENTS_MAKE_PAID,
  });

  const packingListPermissions = usePermissionsObject({
    viewPackingListAccept: VIEW_PACKING_LIST_ACCEPT,
    addFiles: VIEW_ADD_PACKING_LIST_FILES,
    deleteFiles: VIEW_DELETE_PACKING_LIST_FILES,
  });

  return (
    <>
      <Switch>
        {haveViewDocumentsPermission && (
          <Route
            path={`${match.path}/documents`}
            render={(props) => (
              <DocumentsRoutes
                {...props}
                documentsPermissions={documentsPermissions}
                billPermissions={billPermissions}
                packingListPermissions={packingListPermissions}
              />
            )}
          />
        )}

        <Route exact path={`${match.path}/profile`} component={ProfilePage} />

        <Route path={`${match.path}/events/calendar`} component={Calendar} />
        <Route path={`${match.path}/events/payments`} component={PaymentsList} />
        <Route path={`${match.path}/events/supplies`} component={SuppliesList} />

        {haveViewSettingsPermission && <Route path={`${match.path}/settings`} component={SettingsRoutes} />}

        <Route exact path={`${match.path}/${TASK_LISTS_TYPES.ALL}/:objectId/:taskId`} component={Tasks} />
        <Route exact path={`${match.path}/${TASK_LISTS_TYPES.ALL}/:objectId`} component={Tasks} />
        <Route exact path={`${match.path}/${TASK_LISTS_TYPES.MY}/:objectId`} component={Tasks} />
        <Route exact path={`${match.path}/${TASK_LISTS_TYPES.ASSIGNED}/:objectId`} component={Tasks} />
        <Route exact path={`${match.path}/${TASK_LISTS_TYPES.WATCHING}/:objectId`} component={Tasks} />
        <Route exact path={`${match.path}/${TASK_LISTS_TYPES.LISTS}/:objectId`} component={TasksLists} />
        <Route exact path={`${match.path}/files/:objectId/`} component={FilesStorage} />
        <Redirect to={`${match.path}/${TASK_LISTS_TYPES.ALL}/0`} />
      </Switch>
    </>
  );
}

export default TasksRoutes;
