import moment from "moment";
import React from "react";

import WorkerItem from "../WorkersList/WorkerItem";

import { personFullNameWithId } from "types/personsTypes";

import { ReactComponent as CalendarBlue } from "images/icons/calendarBlue.svg";

import styles from "./DoubleWorkers.module.scss";

type propsType = {
  date: string;
  label1?: string;
  worker1: personFullNameWithId;
  label2?: string;
  worker2: personFullNameWithId;
};

const DoubleWorkers = ({ date, worker1, worker2, label1 = "Выдал:", label2 = "Получил:" }: propsType) => {
  return (
    <div className={styles.doubleInfo}>
      <div className={styles.grid}>
        <div className={styles.date}>
          {moment(date).format("DD/MM/YYYY")} <CalendarBlue />
        </div>
        <div className={styles.accept}>{label1}</div>
        <WorkerItem worker={worker1} color="green" />
      </div>
      <div className={styles.grid}>
        <div className={styles.hours}>в {moment(date).format("HH:mm")}</div>
        <div className={styles.accept}>{label2}</div>
        <WorkerItem worker={worker2} />
      </div>
    </div>
  );
};

export default React.memo(DoubleWorkers);
