export const EDIT_EMPLOYEE_TYPES = {
  AGREEMENT: 'agreement',
  VIEW: 'view'
}

export const LABELS = {
  TITLE: 'Вложения',
  FILES_INPUT: {
    TITLE: 'Список вложений:',
    DOWNLOAD: 'Выгрузить'
  },
  BUTTONS: {
    CANCEL: 'Отменить',
    SUBMIT: 'Сохранить'
  }
}
