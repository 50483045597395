import { useEffect } from "react";

const checkSomeOrderRequestsHaveOffers = (orderRequests) =>
  orderRequests.some((request) => request.offers.length !== 0);

const usePreFillingOrderRequests = (orderWasSaved, orderRequests, setOrderRequests) => {
  useEffect(() => {
    if (
      orderWasSaved ||
      !orderRequests ||
      orderRequests.length === 0 ||
      checkSomeOrderRequestsHaveOffers(orderRequests)
    )
      return;

    setOrderRequests(
      orderRequests.map((request) => ({
        ...request,
        offers: [
          {
            measure: request.measure,
            name: "",
            supplies: [{}],
          },
        ],
      }))
    );
  }, [orderRequests, orderWasSaved, setOrderRequests]);
};

export default usePreFillingOrderRequests;
