import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";

import {
  cancelExpenditure,
  confirmExpenditure,
  deleteDefaultItemInHandler,
  deleteExpenditure,
} from "../../../../../../../../../../redux/modules/common/building/sections/sections";

import DraggableExpenditure from "./components/DraggableExpenditure/DraggableExpenditure";
import UIExpenditure from "components/UI/_TODO/Expenditure/Expenditure";

import { loadAggregations } from "../../../../../../../Aggregations/model/thunks";

import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS } from "../../../../../../constants";
import { IExpenditureInHandlerProduction } from "../../types";

import removeIcon from "../../../../../../../../../../images/icons/closeNotyItem.svg";
import editIcon from "../../../../../../../../../../images/icons/editIcon.svg";
import cancelIcon from "../../../../../../../../../../images/icons/statusCanceled.svg";
import confirmIcon from "../../../../../../../../../../images/icons/statusConfirmed.svg";

export interface IHandlerExpenditureProps {
  expenditure: IExpenditureInHandlerProduction;
  onEdit: (arg: any) => void;
  onSwap: (arg: any) => void;
  activeEstimateStateId: string;
  isChecked: boolean;
  isParentSectionChecked: boolean;
  withActions: any;
  buildingId: number;
  isShared: boolean;
  canDrag?: boolean;
  isPopupsHidden?: boolean;
  onFileDirectlyClick?: (arg: any) => void;
  onReloadExpenditures?: (arg?: any) => void;
  directlyAction?: React.ReactNode;
  visibilityChecked?: (id: number) => boolean;
  handleVisibilityCheck?: (item: any) => void;
}

const Expenditure: React.FC<IHandlerExpenditureProps> = ({
  expenditure,
  onEdit,
  onSwap,
  activeEstimateStateId,
  isChecked,
  isParentSectionChecked,
  withActions,
  buildingId,
  isShared,
  canDrag = true,
  isPopupsHidden,
  onFileDirectlyClick,
  onReloadExpenditures,
  directlyAction,
  visibilityChecked,
  handleVisibilityCheck,
  ...props
}) => {
  const dispatch = useDispatch();

  const isDraft = activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT;
  const isProduction = activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION;
  const isDefault = expenditure?.is_default;
  const isAddedManually = expenditure?.added_manually;

  const handleConfirmExpenditure = useCallback(async () => {
    await compose(
      dispatch,
      confirmExpenditure
    )({
      buildingId,
      expenditureId: expenditure?.id,
      fromState: activeEstimateStateId,
    });

    dispatch(loadAggregations(buildingId));
  }, [activeEstimateStateId, buildingId, expenditure?.id]);

  const handleCancelExpenditure = useCallback(() => {
    compose(
      dispatch,
      cancelExpenditure
    )({
      buildingId,
      expenditureId: expenditure?.id,
      fromState: activeEstimateStateId,
    });
  }, [activeEstimateStateId, buildingId, expenditure?.id]);

  const expenditureConfirmActions = useMemo(
    () => [
      { name: "Утвердить", action: handleConfirmExpenditure, icon: confirmIcon },
      { name: "Отклонить", action: handleCancelExpenditure, icon: cancelIcon },
    ],
    [handleConfirmExpenditure, handleCancelExpenditure]
  );

  const partialOnEdit = useCallback(() => onEdit(expenditure?.id), [expenditure?.id, onEdit]);
  const partialOnSwap = useCallback(() => onSwap(expenditure?.id), [expenditure?.id, onSwap]);

  const onDeleteExpenditure = useCallback(() => {
    if (isDefault) {
      dispatch(deleteDefaultItemInHandler(String(buildingId), expenditure.id, onReloadExpenditures));
    } else {
      compose(
        dispatch,
        deleteExpenditure
      )({
        building: buildingId,
        section: expenditure.section_id,
        expenditure: expenditure.id,
        estimateState: isProduction ? expenditure.current_state : activeEstimateStateId,
      });
    }
  }, [
    buildingId,
    expenditure?.section_id,
    expenditure?.id,
    expenditure?.current_state,
    isProduction,
    activeEstimateStateId,
    isDefault,
  ]);

  const expenditureActions = useMemo(() => {
    if (!withActions) return [];
    const confirmActionsAreAvailable = expenditure?.status === ESTIMATE_ITEM_STATUSES.NEW && !isDraft && !isProduction;
    const actions = [
      { name: "Редактировать", action: partialOnEdit, icon: editIcon },
      ...(confirmActionsAreAvailable ? expenditureConfirmActions : []),
    ];
    if (!expenditure?.is_shared) actions.push({ name: "Заменить", action: partialOnSwap, icon: editIcon });
    actions.push({
      name: "Удалить",
      action: onDeleteExpenditure,
      icon: removeIcon,
    });
    return actions;
  }, [
    expenditure?.status,
    expenditureConfirmActions,
    isDraft,
    isProduction,
    onDeleteExpenditure,
    partialOnEdit,
    withActions,
  ]);

  const newExpenditureNotInDraft = expenditure?.status === ESTIMATE_ITEM_STATUSES.NEW && !isDraft;

  if (!expenditure) return null;

  if (isProduction || newExpenditureNotInDraft || isShared || !canDrag)
    return (
      /* @ts-ignore */
      <UIExpenditure
        activeEstimateStateId={activeEstimateStateId}
        buildingId={buildingId}
        expenditure={expenditure}
        actions={expenditureActions}
        isChecked={isChecked || isParentSectionChecked}
        isShared={isShared}
        isPopupsHidden={isPopupsHidden}
        onFileDirectlyClick={onFileDirectlyClick}
        directlyAction={directlyAction}
        handleVisibilityCheck={handleVisibilityCheck}
        visibilityChecked={visibilityChecked}
        {...props}
      />
    );

  return (
    /* @ts-ignore */
    <DraggableExpenditure
      buildingId={buildingId}
      expenditure={expenditure}
      actions={expenditureActions}
      isChecked={isChecked || isParentSectionChecked}
      isParentSectionChecked={isParentSectionChecked}
      isShared={isShared}
      canCheck
      onFileDirectlyClick={onFileDirectlyClick}
      isPopupsHidden={isPopupsHidden}
      {...props}
    />
  );
};

export default React.memo(Expenditure);
