import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useRouteMatch } from "react-router-dom";

import { simpleHandlerAPI } from "../../../../../model/api";
import { activeSectionSelector, estimateStateIdSelector } from "../../../../../model/selectors";
import { simpleHandlerActions } from "../../../../../model/slice";

import { EEstimateStatesIds } from "../../../../../../ProHandler/enums";

export const useDefineActiveSection = () => {
  const dispatch = useDispatch();
  const activeSection = useSelector(activeSectionSelector);
  const estimateStateId = useSelector(estimateStateIdSelector);

  const match = useRouteMatch();
  const sectionIdMatch = useRouteMatch<{ sectionId: string }>(`${match.path}/:sectionId`);

  const { buildingId } = useParams<{ buildingId?: string }>();

  useEffect(() => {
    // Если нет выбранной активной секции, она подгружается и сохраняется
    if (!activeSection && sectionIdMatch && buildingId && estimateStateId) {
      simpleHandlerAPI
        .getSection(buildingId, sectionIdMatch.params.sectionId, estimateStateId)
        .then((loadedSection) => {
          if (loadedSection) {
            dispatch(simpleHandlerActions.setActiveSection(loadedSection));
          }
        });
    }
  }, [activeSection, estimateStateId, sectionIdMatch, buildingId]);
};
