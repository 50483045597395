import cn from "classnames";
import React, { useState } from "react";

import { IBuildingPropsPermissions } from "components/pages/Building/Building";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { useDeleteDrawingSetMutation } from "../../model/blueprintsQuery";
import BlueprintSetEditModal from "../../widgets/BlueprintSetModal/BlueprintSetEditModal";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import Actions from "shared/ui/controls/Actions/Actions";
import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import styles from "./DocumentationSetRow.module.scss";

interface IProps {
  name: string;
  code?: string;
  developerOrganization?: string;
  downloaded: string;
  files: number;
  estimateConnection?: boolean;
  lists?: string;
  isSets: boolean;
  onClickDocumentation?: () => void;
  id: number;
  permissions: IBuildingPropsPermissions;
}

const DocumentationSetRow = ({
  name,
  code,
  developerOrganization,
  downloaded,
  files,
  lists,
  estimateConnection,
  isSets,
  onClickDocumentation,
  permissions,
  id,
}: IProps) => {
  const buildingId = useObjectId();
  const [deleteSet, { isLoading }] = useDeleteDrawingSetMutation();

  const onDelete = () => {
    if (isSets) {
      deleteSet({ buildingId, drawingSetId: id });
    }
  };

  const showActionsLoader = isLoading;

  const [isEditModal, setIsEditModal] = useState(false);
  const [isDetailModal, setIsDetailModal] = useState(false);

  const openViewModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDetailModal(true);
    setIsEditModal(false);
  };

  const openEditModal = () => {
    setIsDetailModal(true);
    setIsEditModal(true);
  };

  const closeModal = () => {
    setIsDetailModal(false);
    setIsEditModal(false);
  };

  const isWithActions = permissions.canEditDrawingSets || permissions.canDeleteDrawingSets;

  const className = cn(styles.setsRow, { [styles.withActions]: isWithActions });

  return (
    <>
      <TableReusableRow className={className} onClick={onClickDocumentation} isHoverable={code ? true : false}>
        <TableReusableCell isNoBreak>{name}</TableReusableCell>
        <TableReusableCell>{code ? code : estimateConnection}</TableReusableCell>
        <TableReusableCell isNoBreak>{developerOrganization ? developerOrganization : lists}</TableReusableCell>
        <TableReusableCell>{downloaded}</TableReusableCell>
        <TableReusableCell>
          <FilesClipButton
            isEmptyLowOpacity
            hasGlobalClassName={!files}
            count={files}
            className={styles.files}
            onClick={openViewModal}
          />
        </TableReusableCell>
        {isWithActions && (
          <TableReusableCell>
            {showActionsLoader && <Spinner isStatic isSmallGray />}
            {!showActionsLoader && (
              <Actions
                canEdit={permissions.canEditDrawingSets}
                canRemove={permissions.canDeleteDrawingSets}
                className={styles.actions}
                onRemove={onDelete}
                onEdit={openEditModal}
                confirmDeleteText="Вы действительно хотите удалить комплект чертежей?"
              />
            )}
          </TableReusableCell>
        )}
      </TableReusableRow>
      {isSets && (
        <BlueprintSetEditModal
          isOpen={isDetailModal}
          onClose={closeModal}
          drawingSetId={id}
          isDefaultEditing={isEditModal}
          permissions={permissions}
        />
      )}
    </>
  );
};

export default React.memo(DocumentationSetRow);
