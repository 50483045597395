import React, { FC, memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import EmptyMessage from "../../../../../../../ProHandler/components/EmptyMessage/EmptyMessage";

import Spinner from "../../../../../../../../../../shared/ui/atoms/Spinner/Spinner";
import {
  activeSubSectionSelector,
  addingExpendituresSelector,
  checkedItemsIdsSelector,
  editingExpendituresSelector,
  editingLoadingExpendituresIdsSelector,
  estimateStateIdSelector,
  expendituresAreLoadingSelector,
  expendituresSelector,
  loadingCreationFormsIdsSelector,
  modeSelector,
} from "../../../../../../model/selectors";
import { simpleHandlerActions } from "../../../../../../model/slice";
import { simpleHandlerModel } from "../../../../../../model/thunks";
import { ExpendituresForm } from "../../../ExpendituresForm/ExpendituresForm";
import { Expenditure } from "../Expenditure/Expenditure";
import { SectionInfo } from "./SectionInfo/SectionInfo";
import { TableHeader } from "./TableHeader/TableHeader";

import { IExpenditure } from "../../../../../../../../../../types/interfaces/Expenditure";
import { EEstimateStatesIds } from "../../../../../../../ProHandler/enums";
import { ESimpleHandlerMode } from "../../../../../../types";
import { IExpenditureFormState } from "../../../ExpendituresForm/types";

import { ReactComponent as PlusFillCircleIcon } from "images/icons/plusFillCircleIcon.svg";

import styles from "../../ExpendituresList.module.scss";

export const CommonExpendituresList: FC = memo(() => {
  const dispatch = useDispatch();

  const activeEstimateStateId = useSelector(estimateStateIdSelector);
  const activeSubSection = useSelector(activeSubSectionSelector);
  const expenditures = useSelector(expendituresSelector);
  const expendituresAreLoading = useSelector(expendituresAreLoadingSelector);
  const mode = useSelector(modeSelector);
  const checkedItemsIds = useSelector(checkedItemsIdsSelector);
  const addingExpenditures = useSelector(addingExpendituresSelector);
  const editingExpendituresRaw = useSelector(editingExpendituresSelector);
  const loadingCreationFormsIds = useSelector(loadingCreationFormsIdsSelector);
  const editingLoadingExpendituresIds = useSelector(editingLoadingExpendituresIdsSelector);
  const isDefaultInProduction =
    !!activeSubSection?.is_default && activeEstimateStateId === EEstimateStatesIds.PRODUCTION;

  const addButtonVisible = isDefaultInProduction || activeEstimateStateId === EEstimateStatesIds.DRAFT;

  const editingExpenditures = useMemo(() => {
    const result: { worksIds: number[]; resourcesIds: number[] } = {
      worksIds: [],
      resourcesIds: [],
    };

    Object.values(editingExpendituresRaw).forEach((e) => {
      result.worksIds.push(e.id);
      result.resourcesIds.push(...e.resources.map((r) => r.id));
    });

    return result;
  }, [editingExpendituresRaw]);

  const onCancelEdit = useCallback((id: number) => {
    dispatch(simpleHandlerActions.removeEditingExpenditure(id));
  }, []);

  const onCheck = useCallback((e: IExpenditure) => {
    dispatch(simpleHandlerActions.toggleCheckedItem({ id: e.id, name: e.name }));
  }, []);

  const onCloseAddingExpenditureForm = useCallback((id: string) => {
    dispatch(simpleHandlerActions.removeAddingExpenditure(id));
  }, []);

  const onCreateAddingExpenditureClick = useCallback(() => {
    dispatch(simpleHandlerActions.createAddingExpenditure());
  }, []);

  const handleCreateExpenditures = useCallback((formId: string, expenditure: IExpenditureFormState) => {
    dispatch(simpleHandlerModel.createExpenditures(formId, expenditure));
  }, []);

  const handleEditExpenditures = useCallback((expenditure: IExpenditureFormState) => {
    dispatch(simpleHandlerModel.editExpenditures(expenditure));
  }, []);

  return (
    <div className={styles.container}>
      <SectionInfo section={activeSubSection} />

      <TableHeader />

      {expendituresAreLoading && <Spinner isStatic className={styles.spinner} />}

      {expenditures?.map((e) => {
        if (editingExpenditures.resourcesIds.includes(e.id)) {
          return null;
        }

        if (editingExpenditures.worksIds.includes(e.id)) {
          return (
            <div key={e.id} className={styles.expenditureFormWrapper}>
              <ExpendituresForm
                onClose={() => onCancelEdit(e.id)}
                onSave={handleEditExpenditures}
                defaultValues={editingExpendituresRaw[e.id]}
                isLoading={editingLoadingExpendituresIds.includes(e.id)}
              />
            </div>
          );
        }

        return (
          <Expenditure
            key={e.id}
            expenditure={e}
            showCheckbox={mode === ESimpleHandlerMode.VIEW && activeEstimateStateId !== EEstimateStatesIds.PRODUCTION}
            onCheck={onCheck}
            isChecked={checkedItemsIds.includes(e.id)}
          />
        );
      })}

      {addingExpenditures.map((id) => (
        <div key={id} className={styles.expenditureFormWrapper}>
          <ExpendituresForm
            onClose={() => onCloseAddingExpenditureForm(id)}
            onSave={(v) => handleCreateExpenditures(id, v)}
            isLoading={loadingCreationFormsIds.includes(id)}
          />
        </div>
      ))}

      {!addingExpenditures.length && !Object.keys(editingExpendituresRaw).length && expenditures?.length === 0 && (
        <EmptyMessage message="Чтобы продолжить, добавьте позицию сметы" />
      )}

      {!expendituresAreLoading && addButtonVisible && (
        <button className={styles.addButton} onClick={onCreateAddingExpenditureClick}>
          <span>Добавить позицию</span>
          <PlusFillCircleIcon className={styles.icon} />
        </button>
      )}
    </div>
  );
});

CommonExpendituresList.displayName = "CommonExpendituresList";
