import { transformDigitToFinancial } from "../formatters/transformDigitToFinancial";

import { LOCALIZATION_CONFIG } from "constants/localization";

export const hasOnlyDigits = (value) => /^[0-9]*$/.test(value);

export const getFullNameString = (firstName, lastName, middleName) =>
  `${lastName || ""} ${firstName || ""} ${middleName || ""}`;

export const getShortPrice = (value) => {
  const valueNumber = Number.parseFloat(value);

  if (isNaN(valueNumber) || !value) return `0.00 ${LOCALIZATION_CONFIG.currency}`;

  if (Math.abs(value) >= 1000000000000)
    return `${Math.round(valueNumber / 1000000000000)} трлн.${LOCALIZATION_CONFIG.currency}`;
  if (Math.abs(value) >= 1000000000)
    return `${Math.round(valueNumber / 1000000000)} млрд.${LOCALIZATION_CONFIG.currency}`;
  if (Math.abs(value) >= 1000000) return `${Math.round(valueNumber / 1000000)} млн.${LOCALIZATION_CONFIG.currency}`;
  if (Math.abs(value) >= 1000) return `${Math.round(valueNumber / 1000)} тыс.${LOCALIZATION_CONFIG.currency}`;
  else return `${Number(value || 0).toFixed(2)} ${LOCALIZATION_CONFIG.currency}`;
};

export const getShortPriceObject = (value) => {
  const shortPrice = getShortPrice(value).split(" ");
  return { value: shortPrice[0], title: shortPrice[1] };
};

export const getPriceMeasureObject = (value) => {
  if (isNaN(value)) return;
  const absValue = Math.abs(value);

  let measure = 1;
  let label = `${LOCALIZATION_CONFIG.currency}`;

  if (absValue >= 1e3) {
    measure = 1e3;
    label = `тыс.${LOCALIZATION_CONFIG.currency}`;
  }
  if (absValue >= 1e6) {
    measure = 1e6;
    label = `млн.${LOCALIZATION_CONFIG.currency}`;
  }
  if (absValue >= 1e9) {
    measure = 1e9;
    label = `млрд.${LOCALIZATION_CONFIG.currency}`;
  }
  if (absValue >= 1e12) {
    measure = 1e12;
    label = `трлн.${LOCALIZATION_CONFIG.currency}`;
  }
  return { measure, label };
};

export const getUnifiedMeasurePrices = (...args) => {
  if (!args.length) return;
  const priceMeasureObjects = args.map(getPriceMeasureObject).sort((a, b) => b.measure - a.measure);
  const { measure: maxMeasure, label: maxLabel } = priceMeasureObjects[0];
  return args.map(
    (value) => `${transformDigitToFinancial(value / maxMeasure, { withFloat: true, dropZeros: true })} ${maxLabel}`
  );
};

export const getShortPriceValute = (value) => {
  const valueNumber = Number.parseFloat(value);
  if (Math.abs(value) >= 1000000000) return `${(valueNumber / 1000000000).toFixed(1)} млрд.`;
  if (Math.abs(value) >= 1000000) return `${(valueNumber / 1000000).toFixed(1)} млн.`;
  if (Math.abs(value) >= 1000) return `${(valueNumber / 1000).toFixed(1)} тыс.`;
  else return Math.round(value * 10) / 10;
};
