import React, { FC } from "react";

import BottomControls from "../../../../WorkOrMaterialsModals/components/BottomControls/BottomControls";

import ButtonBase from "../../../../../../../shared/ui/controls/ButtonBase";

import styles from "../Footer.module.scss";

interface IProps {
  isShowButtons?: boolean;
  haveUngroupGroupsPermission: boolean;
  haveEditGroupsPermission: boolean;
  isEditing: boolean;
  hasChanges: boolean;

  toggleEdit: () => void;
  handleCompleteEdit: () => void;
  handleUngroup?: () => void;
}

export const EditingControls: FC<IProps> = ({
  isShowButtons,
  haveUngroupGroupsPermission,
  haveEditGroupsPermission,
  isEditing,
  hasChanges,
  toggleEdit,
  handleCompleteEdit,
  handleUngroup,
}) => {
  return (
    <BottomControls
      isExists={isShowButtons && (haveUngroupGroupsPermission || haveEditGroupsPermission)}
      wrapperClassName={styles.bottomControlsWrapper}
      className={styles.bottomControls}
    >
      {haveEditGroupsPermission && (
        <ButtonBase secondary onClick={toggleEdit}>
          {!isEditing ? "Редактировать" : "Отменить"}
        </ButtonBase>
      )}
      {isEditing && haveEditGroupsPermission && (
        <ButtonBase primary className={styles.button} onClick={handleCompleteEdit} disabled={!hasChanges}>
          Сохранить
        </ButtonBase>
      )}
      {!isEditing && haveUngroupGroupsPermission && (
        <ButtonBase primary className={styles.button} onClick={handleUngroup}>
          Разгруппировать
        </ButtonBase>
      )}
    </BottomControls>
  );
};
