import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import {
  getLastRequisitionDraftId,
  lastRequisitionDraftIdSelector,
} from "../../../../../redux/modules/common/building/object/nowObject";

import Select from "components/UI/atoms/Select";

import ButtonBase from "../../../../../shared/ui/controls/ButtonBase";
import Modal from "shared/ui/modal/Modal";

import styles from "./CreateRequisitionModal.module.scss";

const CreateRequisitionModal = ({ isOpen, onClose, buildings, buildingId, createRequisition, onUseExistedDraft }) => {
  const dispatch = useDispatch();
  const lastRequisitionDraftId = useSelector(lastRequisitionDraftIdSelector);

  const [selectedBuildingId, setSelectedBuildingId] = useState(buildingId);

  const handleCreateRequisition = useCallback(
    () => createRequisition(selectedBuildingId),
    [createRequisition, selectedBuildingId]
  );

  const onCreate = useCallback(
    () =>
      compose(
        dispatch,
        getLastRequisitionDraftId
      )(selectedBuildingId).then((responseData) => {
        if (!responseData) handleCreateRequisition();
      }),
    [selectedBuildingId, handleCreateRequisition]
  );

  return (
    <Modal className={styles.createRequisitionModal} isOpen={isOpen} title="Создать заявку" onClose={onClose}>
      <div className={styles.body}>
        {lastRequisitionDraftId ? (
          <>
            <p className={styles.text}>У Вас есть неотправленная заявка.</p>
            <p className={styles.text}>Продолжить ее редактировать или создать новую заявку?</p>
          </>
        ) : (
          <Select
            value={selectedBuildingId}
            placeholder="Выберите проект"
            onChange={setSelectedBuildingId}
            options={buildings.results}
          />
        )}
      </div>
      <footer className={styles.footer}>
        {lastRequisitionDraftId ? (
          <>
            <ButtonBase className={styles.button} medium secondary onClick={handleCreateRequisition}>
              Создать новую
            </ButtonBase>
            <ButtonBase className={styles.button} medium onClick={onUseExistedDraft}>
              Продолжить
            </ButtonBase>
          </>
        ) : (
          <ButtonBase className={styles.button} primary medium disabled={!selectedBuildingId} onClick={onCreate}>
            Создать
          </ButtonBase>
        )}
      </footer>
    </Modal>
  );
};

export default React.memo(CreateRequisitionModal);
