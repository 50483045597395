import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IStockInList, IStockMovingProductInList } from "./types";
import { IEmployee } from "types/personsTypes";

interface State {
  isLoading: Record<string, boolean>;
  invalidateKey: number;
  stocks: IStockInList[];
  products: Record<string, IStockMovingProductInList[]>;
}

const initialState: State = {
  isLoading: {},
  invalidateKey: 0,
  stocks: [],
  products: {},
};

const stocksSlice = createSlice({
  name: "stocks",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ key: string; status: boolean }>) => {
      const { key, status } = action.payload;
      state.isLoading[key] = status;
    },
    invalidateKey: (state) => {
      state.invalidateKey++;
    },
    setStocks: (state, { payload }: PayloadAction<IStockInList[]>) => {
      if (payload.length) {
        state.stocks = payload;
      }
    },
    setProducts: (state, { payload }: PayloadAction<{ key: string; data: IStockMovingProductInList[] }>) => {
      const { key, data } = payload;
      state.products[key] = data;
    },
  },
});

export const stocksActions = stocksSlice.actions;

export default stocksSlice.reducer;
