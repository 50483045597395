import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartActions } from "redux/modules/common/chart/actions";
import { chartTabSelector } from "redux/modules/common/chart/selectors";
import { CHART_TABS } from "redux/modules/common/chart/types";

import { ITab } from "shared/ui/controls/TabBar/TabBarNotLinks";

import { VIEW_CONSTRUCTING_CHART_WORKS } from "constants/permissions/constructingPermissions";
import {
  VIEW_MANUFACTURING_CHART_RESOURCES,
  VIEW_MANUFACTURING_CHART_WORKS,
} from "constants/permissions/manufacturingPermissions";
import {
  VIEW_PURCHASES_CHART_EQUIPMENT,
  VIEW_PURCHASES_CHART_MATERIALS,
  VIEW_PURCHASES_CHART_MIMES,
} from "constants/permissions/purchasesPermissions";
import { MODULES_ENUM } from "types/enums/ModulesEnum";

import usePermission from "hooks/usePermission";
import { useUrlModule } from "utils/hooks/useUrlModule";

export const useChartTabs = () => {
  const dispatch = useDispatch();
  const tab = useSelector(chartTabSelector);
  const module = useUrlModule();

  const hasWorksTabObjectsPermission = usePermission(VIEW_MANUFACTURING_CHART_WORKS);
  const hasWorksTabConstructingPermission = usePermission(VIEW_CONSTRUCTING_CHART_WORKS);
  const hasResourcesTabPermission = usePermission(VIEW_MANUFACTURING_CHART_RESOURCES);
  const hasMaterialsTabPermission = usePermission(VIEW_PURCHASES_CHART_MATERIALS);
  const haEquipmentTabPermission = usePermission(VIEW_PURCHASES_CHART_EQUIPMENT);
  const hasMimTabPermission = usePermission(VIEW_PURCHASES_CHART_MIMES);

  const visibleTabs: Record<CHART_TABS, boolean> = {
    [CHART_TABS.WORK]:
      (hasWorksTabObjectsPermission && module === MODULES_ENUM.OBJECTS) ||
      (hasWorksTabConstructingPermission && module === MODULES_ENUM.CONSTRUCTING),
    [CHART_TABS.RESOURCES]: hasResourcesTabPermission && module === MODULES_ENUM.OBJECTS,
    [CHART_TABS.MATERIALS]: hasMaterialsTabPermission && module === MODULES_ENUM.PURCHASES,
    [CHART_TABS.EQUIPMENT]: haEquipmentTabPermission && module === MODULES_ENUM.PURCHASES,
    [CHART_TABS.MIM]: hasMimTabPermission && module === MODULES_ENUM.PURCHASES,
  };

  const setTab = useCallback((t: CHART_TABS | string) => {
    dispatch(chartActions.setTab(t as CHART_TABS));
  }, []);

  const chartTabs: ITab[] = useMemo(
    () =>
      [
        visibleTabs.work && { id: CHART_TABS.WORK, text: "Работы" },
        visibleTabs.resources && { id: CHART_TABS.RESOURCES, text: "Ресурсы" },
        visibleTabs.materials && { id: CHART_TABS.MATERIALS, text: "Материалы" },
        visibleTabs.equipment && { id: CHART_TABS.EQUIPMENT, text: "Оборудование" },
        visibleTabs.mim && { id: CHART_TABS.MIM, text: "МиМ" },
      ].filter(Boolean) as ITab[],
    [visibleTabs]
  );

  useEffect(() => {
    if (!chartTabs.length || chartTabs.findIndex((t) => t.id === tab) !== -1) return;
    setTab(chartTabs[0].id);
  }, [chartTabs, tab]);

  return {
    chartTabs,
    tab,
    setTab,
  };
};
