import React from "react";
import { Field, Form } from "react-final-form";

import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import InputBase from "shared/ui/inputs/InputBase";
import TextArea from "shared/ui/inputs/TextArea/TextArea";

import { ICreateDrawingSet, IDrawingSet } from "../../model/types";

import { composeFieldValidators, required } from "utils/formHelpers/validations";

import styles from "./BlueprintSetModal.module.scss";

interface IProps {
  onClose: () => void;
  initialValues?: Partial<IDrawingSet>;
  onSubmit: (vals: ICreateDrawingSet) => void;
  filesInput: React.ReactNode;
  bottomControls: React.ReactNode;
}

const BlueprintSetForm: React.FC<IProps> = ({ onClose, initialValues, onSubmit, filesInput, bottomControls }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className={styles.form}>
            <Field
              render={({ input, meta }) => (
                <TextArea
                  label="Название комплекта"
                  maxRows={2}
                  value={input.value}
                  onChange={(e) => input.onChange(e.target.value)}
                  onFocus={input.onFocus}
                  onBlur={input.onBlur}
                  error={meta.touched && meta.error}
                />
              )}
              name="title"
              validate={composeFieldValidators(required())}
            />

            <Field
              render={({ input, meta }) => (
                <LabeledItem label="Шифр/обозначение">
                  <InputBase className={styles.input} input={input} meta={meta} />
                </LabeledItem>
              )}
              name="code"
              validate={composeFieldValidators(required())}
            />
            <Field
              render={({ input, meta }) => (
                <LabeledItem label="Организация, разработавшая чертежи">
                  <InputBase className={styles.input} input={input} meta={meta} />
                </LabeledItem>
              )}
              name="organization"
              validate={composeFieldValidators(required())}
            />
          </div>
          {filesInput}
          {bottomControls}
        </form>
      )}
    />
  );
};

export default BlueprintSetForm;
