import { message } from "antd";
import { batch } from "react-redux";
import { DeepPartial, Dispatch } from "redux";

import { loadAggregations } from "../../Aggregations/model/thunks";
import { ISetDiscountCostResponse, simpleHandlerAPI } from "./api";
import { simpleHandlerActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { ExpenditureTypeEnum } from "../../../../../types/enums/ExpenditureTypeEnum";
import { IExpenditure } from "../../../../../types/interfaces/Expenditure";
import ISection from "../../../../../types/interfaces/Section";
import { ESTIMATE_STATES_NAMES, ESTIMATE_STATES_NAMES_FOR_FROM } from "../../ProHandler/constants";
import { EEstimateStatesIds, EstimateItemsStatusesEnum } from "../../ProHandler/enums";
import {
  EHandlerLevels,
  ICreateOrUpdateDefaultExpenditureBody,
  ICreateOrUpdateExpenditureInDraftBody,
  IEditingExpenditure,
} from "../types";
import { IExpenditureFormState } from "../ui/ItemsList/ui/ExpendituresForm/types";

import { IReason, errorCatcher } from "../../../../../utils/helpers/errorCatcher";

export const loadSections =
  (buildingId: string | number, estimateStateId: EEstimateStatesIds) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    if (!buildingId || !estimateStateId) return;

    const state = getState().simpleHandler;

    if (estimateStateId === EEstimateStatesIds.LOCALE) {
      if (!state.newSections?.length && !state.confirmedSections?.length) {
        dispatch(simpleHandlerActions.setSectionsAreLoading(true));
      }

      const promises = [
        simpleHandlerAPI.getSections(buildingId, estimateStateId, { with_child_status: "new" }),
        simpleHandlerAPI.getSections(buildingId, estimateStateId, { with_child_status: "confirmed" }),
      ];

      Promise.allSettled(promises).then((results) => {
        results.forEach((r, idx) => {
          if (r.status === "fulfilled") {
            if (idx === 0) {
              dispatch(simpleHandlerActions.setNewSections(r.value?.results ?? []));
            } else {
              dispatch(simpleHandlerActions.setConfirmedSections(r.value?.results ?? []));
            }
          }
        });
      });
    } else {
      if (!state.sections?.length) {
        dispatch(simpleHandlerActions.setSectionsAreLoading(true));
      }

      simpleHandlerAPI.getSections(buildingId, estimateStateId).then((data) => {
        dispatch(simpleHandlerActions.setSections(data?.results ?? []));
      });
    }
  };

export const loadSubSections =
  (
    buildingId: string | number,
    parentSectionId: number,
    estimateStateId: EEstimateStatesIds,
    params: Record<string, string | number> = {}
  ) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState().simpleHandler;

    if (!state.subSections?.length && !state.newSubSections?.length && !state.confirmedSubSections?.length) {
      dispatch(simpleHandlerActions.setSubSectionsAreLoading(true));
    }

    simpleHandlerAPI
      .getSubSections(buildingId, parentSectionId, estimateStateId, params)
      .then((data) => {
        dispatch(simpleHandlerActions.setSubSections(data?.results ?? []));
      })
      .finally(() => {
        dispatch(simpleHandlerActions.setSubSectionsAreLoading(false));
      });
  };

export const loadExpenditures =
  (
    buildingId: string | number,
    parentSectionId: string | number,
    estimateStateId: EEstimateStatesIds,
    hideLoaders?: boolean
  ) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const currentExpenditures = getState().simpleHandler.expenditures;

    if (!hideLoaders && !currentExpenditures) {
      dispatch(simpleHandlerActions.setExpendituresAreLoading(true));
    }
    return simpleHandlerAPI
      .getExpenditures(buildingId, parentSectionId, estimateStateId)
      .then((data) => {
        dispatch(simpleHandlerActions.setExpenditures(data?.results ?? []));
      })
      .catch(errorCatcher)
      .finally(() => {
        if (!hideLoaders) {
          dispatch(simpleHandlerActions.setExpendituresAreLoading(false));
        }
      });
  };

const invalidateExpendituresList = () => (dispatch: Dispatch, getState: () => RootState) => {
  const rootState = getState();
  const buildingId = rootState.nowObject?.detail?.data?.id;
  const activeEstimateStateId = rootState.simpleHandler.estimateStateId;
  const activeSubSectionId = rootState.simpleHandler.activeSubSection?.id;

  if (!buildingId || !activeSubSectionId || !activeEstimateStateId) return;

  return simpleHandlerAPI
    .getExpenditures(buildingId, activeSubSectionId, activeEstimateStateId)
    .then((data) => {
      dispatch(simpleHandlerActions.setExpenditures(data?.results ?? []));
    })
    .catch(errorCatcher);
};

export const moveItemsBetweenStates =
  (
    buildingId: string | number,
    payload: {
      from_state: EEstimateStatesIds;
      ids: number[];
      state: EEstimateStatesIds;
    },
    level: EHandlerLevels
  ) =>
  async (dispatch: Dispatch) => {
    if (payload.from_state === payload.state) {
      message.error("Выбранные позиции уже были перенесены");
      return;
    }

    dispatch(simpleHandlerActions.setIsItemsStateChanging(true));

    let response;

    try {
      if (payload.state === EEstimateStatesIds.PRODUCTION) {
        response = await simpleHandlerAPI.changeEstimateItemsStateToProduction(buildingId, {
          ids: payload.ids,
          state: payload.from_state,
        });
      } else {
        response = await simpleHandlerAPI.changeEstimateItemsState(buildingId, payload);
      }
    } catch (e) {
      message.error("Невозможно перенести выбранные объекты");
    } finally {
      dispatch(simpleHandlerActions.setIsItemsStateChanging(false));
    }

    if (response?.data && "count_updated" in response.data && response.data.count_updated === 0) {
      const activeEstimateStateName =
        ESTIMATE_STATES_NAMES_FOR_FROM[payload.from_state.toUpperCase() as keyof typeof ESTIMATE_STATES_NAMES_FOR_FROM];
      message.error(`Невозможно перенести выбранные объекты из ${activeEstimateStateName}`);
      return;
    }

    dispatch(loadAggregations(buildingId) as any);

    let messageText = "";

    if (!level || level === EHandlerLevels.SECTIONS) {
      messageText += "Разделы";
    } else if (level === EHandlerLevels.SUBSECTIONS) {
      messageText += "Подразделы";
    } else {
      messageText += "Позиции";
    }

    messageText += ` успешно перенеслись в ${ESTIMATE_STATES_NAMES[
      payload.state.toUpperCase() as keyof typeof ESTIMATE_STATES_NAMES
    ].toLowerCase()}`;

    message.success(messageText);

    batch(() => {
      dispatch(simpleHandlerActions.clearCheckedItems());
      dispatch(simpleHandlerActions.invalidate());
    });
  };

export const createSectionInDraft =
  (buildingId: string | number, section: { name: string; parent: number | string | null }, isDefault?: boolean) =>
  (dispatch: Dispatch) => {
    return simpleHandlerAPI
      .createSectionInDraft(buildingId, section, isDefault)
      .then((response) => {
        if (section.parent) {
          dispatch(simpleHandlerActions.addSubSection(response.data));
        } else {
          dispatch(simpleHandlerActions.addSection(response.data));
        }
        message.success(`${section.parent ? "Подраздел" : "Раздел"} успешно создан`);
      })
      .catch(errorCatcher);
  };

const updateItemStatus =
  ({
    itemId,
    status,
    callback,
    successCallback,
  }: {
    itemId: number;
    status: EstimateItemsStatusesEnum;
    callback?: () => void;
    successCallback?: () => void;
  }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const rootState = getState();
    const buildingId = rootState.nowObject?.detail?.data?.id;
    const activeEstimateStateId = rootState.simpleHandler.estimateStateId;

    if (!buildingId || !activeEstimateStateId) return;

    simpleHandlerAPI
      .changeEstimateItemsStatus(buildingId, {
        ids: [itemId],
        from_state: activeEstimateStateId,
        status,
      })
      .then(({ data }) => {
        if (data && data.result?.updated_count) {
          dispatch(simpleHandlerActions.invalidate());
          if (!location.pathname.includes("approve_estimate")) {
            dispatch(loadAggregations(buildingId) as any);
          }
          successCallback?.();
        }
      })
      .catch(errorCatcher)
      .finally(() => callback?.());
  };

export const setMarkup =
  (itemId: number, value: number, callback?: (markup_cost: string) => void) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const buildingId = getState().nowObject?.detail?.data?.id ?? "0";

    return simpleHandlerAPI
      .setMarkup(itemId, buildingId, value)
      .then(({ data }) => {
        callback?.(data.markup_cost);
      })
      .catch(errorCatcher);
  };

export const setDiscountCost =
  (itemId: number, value: number, callback?: (v: ISetDiscountCostResponse) => void) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const buildingId = getState().nowObject?.detail?.data?.id ?? "0";

    return simpleHandlerAPI
      .setDiscountCost(itemId, buildingId, value)
      .then(({ data }) => {
        return callback?.(data);
      })
      .catch(errorCatcher);
  };

export const setEstimatedCost =
  (itemId: number, value: number | string, callback?: (v: string) => void) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const buildingId = getState().nowObject?.detail?.data?.id ?? "0";

    return simpleHandlerAPI
      .setCost(itemId, buildingId, +value)
      .then(({ data }) => {
        callback?.(data.estimated_cost);
      })
      .catch(errorCatcher);
  };

interface IDeleteSectionArgs {
  buildingId: string;
  sectionId: ISection["id"];
  estimateState: EEstimateStatesIds;
  isSubSection?: boolean;
  isDefault?: boolean;
}

export const deleteSection =
  ({ buildingId, sectionId, estimateState, isSubSection = false, isDefault = false }: IDeleteSectionArgs) =>
  async (dispatch: Dispatch) => {
    try {
      if (isDefault) {
        await simpleHandlerAPI.deleteDefaultSection(buildingId, sectionId);
      } else {
        await simpleHandlerAPI.deleteSection(buildingId, sectionId, estimateState);
      }

      message.success("Раздел успешно удален");
      if (isSubSection) {
        dispatch(simpleHandlerActions.deleteSubSection(sectionId));
      } else {
        dispatch(simpleHandlerActions.deleteSection(sectionId));
      }

      dispatch(loadAggregations(buildingId) as any);
    } catch (e) {
      errorCatcher(e as IReason);
    }
  };

export const patchSection =
  (
    buildingId: string,
    estimateStateId: ISection["current_state"],
    section: DeepPartial<ISection>,
    status?: "new" | "confirmed"
  ) =>
  (dispatch: Dispatch) => {
    simpleHandlerAPI.patchSection(buildingId, estimateStateId || EEstimateStatesIds.DRAFT, section).then(({ data }) => {
      if (!!data.parent) {
        dispatch(simpleHandlerActions.replaceSubSection({ data, status }));
      } else {
        dispatch(simpleHandlerActions.replaceSection({ data, status }));
      }
      message.success("Раздел успешно изменен");
    });
  };

export const reloadActiveSubSection = () => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState().simpleHandler;

  const buildingId = getState().nowObject?.detail?.data?.id ?? "0";
  const estimateStateId = state.estimateStateId;
  const activeSubSectionId = state.activeSubSection?.id;

  if (estimateStateId && activeSubSectionId) {
    simpleHandlerAPI.getSection(buildingId, activeSubSectionId, estimateStateId).then((loadedSection) => {
      if (loadedSection) {
        dispatch(simpleHandlerActions.setActiveSubSection(loadedSection));
      }
    });
  }
};

export const deleteExpenditure = (id: number) => async (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState().simpleHandler;
  const buildingId = getState().nowObject?.detail?.data?.id;
  const activeSubSectionId = state.activeSubSection?.id;
  const activeEstimateStateId = state.estimateStateId;
  const currentExpenditures = state.expenditures;

  const expenditure = currentExpenditures?.find((e) => e.id === id);

  if (!expenditure || !buildingId || !activeEstimateStateId || !activeSubSectionId) return;

  if (expenditure.is_default || expenditure.current_state === "") {
    const ids = [expenditure.id];

    if (expenditure.expenditure_type === "work") {
      ids.push(
        ...(currentExpenditures ?? [])
          .filter((e) => [e.related_work?.id, e.related_work_id].includes(expenditure.id))
          .map((e) => e.id)
      );
    }

    try {
      batch(() => {
        ids.forEach((id) => {
          dispatch(simpleHandlerActions.addDeletingExpenditureId(id));
        });
      });

      if (expenditure.is_default && ids.length > 1) {
        // При удалении расценок по РД списком бэк отдает ошибку, если
        // у работы остались связанные услуги или ресурсы
        // Если попробовать удалить работу и ее ресуры с услугами одним запросом, получаем такую же ошибку

        // поэтому это здесь

        await simpleHandlerAPI.deleteExpendituresList({
          buildingId,
          state: "default",
          ids: ids.slice(1),
        });

        await simpleHandlerAPI.deleteExpendituresList({
          buildingId,
          state: "default",
          ids: [ids[0]],
        });
      } else {
        await simpleHandlerAPI.deleteExpendituresList({
          buildingId,
          state: expenditure.is_default ? "default" : "draft",
          ids,
        });
      }
    } catch (e) {
      errorCatcher(e as IReason);
    }

    await dispatch(reloadActiveSubSection() as any);
    await dispatch(loadExpenditures(buildingId, activeSubSectionId, activeEstimateStateId, true) as any);

    batch(() => {
      ids.forEach((id) => {
        dispatch(simpleHandlerActions.removeDeletingExpenditureId(id));
      });
    });
  }
};

const startEditingExpenditure = (id: number) => (dispatch: Dispatch, getState: () => RootState) => {
  const currentExpenditures = getState().simpleHandler.expenditures ?? [];
  let expenditure = currentExpenditures.find((e) => e.id === id);

  if (
    expenditure &&
    expenditure.expenditure_type !== ExpenditureTypeEnum.work &&
    (expenditure.related_work_id ?? expenditure.related_work?.id)
  ) {
    expenditure = currentExpenditures.find(
      (e) => e.id === ((expenditure as IExpenditure).related_work_id ?? (expenditure as IExpenditure).related_work?.id)
    );
  }

  if (!expenditure) return;

  const resources = currentExpenditures.filter(
    (e) => (e.related_work_id ?? e.related_work?.id) === (expenditure as IExpenditure).id
  );

  const editing: IEditingExpenditure = {
    id: expenditure.id,
    name: expenditure.name,
    measure: expenditure.measure,
    count: expenditure.count,
    price: expenditure.price,
    resources: resources.map((e) => ({
      id: e.id,
      type: e.expenditure_type,
      name: e.name,
      measure: e.measure,
      count: e.count,
      price: e.price,
    })),
  };

  dispatch(simpleHandlerActions.addEditingExpenditure(editing));
};

const createExpenditures =
  (formId: string, expenditure: IExpenditureFormState) => async (dispatch: Dispatch, getState: () => RootState) => {
    const rootState = getState();
    const buildingId = rootState.nowObject?.detail?.data?.id;
    const estimateStateId = rootState.simpleHandler.estimateStateId;
    const activeSubSection = rootState.simpleHandler.activeSubSection;
    const activeSubSectionId = activeSubSection?.id;
    const isDefault = !!activeSubSection?.is_default;

    if (!buildingId || !activeSubSectionId || !estimateStateId) return;

    if (estimateStateId === EEstimateStatesIds.DRAFT) {
      try {
        const body: ICreateOrUpdateExpenditureInDraftBody[] = [];

        body.push({
          name: expenditure.name,
          expenditure_type: ExpenditureTypeEnum.work,
          draft_count: expenditure.count,
          measure: expenditure.measure,
          price: expenditure.price,
          section_id: activeSubSectionId,
        });

        expenditure.resources.forEach((r) => {
          body.push({
            name: r.name,
            expenditure_type: r.type,
            draft_count: r.count,
            measure: r.measure,
            price: r.price,
            section_id: activeSubSectionId,
          });
        });

        dispatch(simpleHandlerActions.addCreationFormLoadingId(formId));

        await simpleHandlerAPI.createDraftExpenditures(buildingId, body);
        await dispatch(loadExpenditures(buildingId, activeSubSectionId, estimateStateId, true) as any);

        dispatch(simpleHandlerActions.removeAddingExpenditure(formId));

        dispatch(reloadActiveSubSection() as any);
        dispatch(loadAggregations() as any);
      } catch (e) {
        errorCatcher(e as IReason);
      } finally {
        dispatch(simpleHandlerActions.removeCreationFormLoadingId(formId));
      }
    }

    if (isDefault) {
      try {
        const body: ICreateOrUpdateDefaultExpenditureBody[] = [];

        body.push({
          name: expenditure.name,
          expenditure_type: ExpenditureTypeEnum.work,
          count: expenditure.count,
          measure: expenditure.measure,
          price: expenditure.price,
          section_id: activeSubSectionId,
        });

        expenditure.resources.forEach((r) => {
          body.push({
            name: r.name,
            expenditure_type: r.type,
            count: r.count,
            measure: r.measure,
            price: r.price,
            section_id: activeSubSectionId,
          });
        });

        dispatch(simpleHandlerActions.addCreationFormLoadingId(formId));

        await simpleHandlerAPI.createDefaultExpenditures(buildingId, body);
        await dispatch(loadExpenditures(buildingId, activeSubSectionId, estimateStateId, true) as any);

        dispatch(simpleHandlerActions.removeAddingExpenditure(formId));

        dispatch(reloadActiveSubSection() as any);
        dispatch(loadAggregations() as any);
      } catch (e) {
        errorCatcher(e as IReason);
      } finally {
        dispatch(simpleHandlerActions.removeCreationFormLoadingId(formId));
      }
    }
  };

const editExpenditures =
  (expenditure: IExpenditureFormState) => async (dispatch: Dispatch, getState: () => RootState) => {
    const rootState = getState();
    const buildingId = rootState.nowObject?.detail?.data?.id;
    const estimateStateId = rootState.simpleHandler.estimateStateId;
    const activeSubSectionId = rootState.simpleHandler.activeSubSection?.id;
    const isDefault = !!rootState.simpleHandler.activeSubSection?.is_default;

    if (!buildingId || !activeSubSectionId || !estimateStateId) return;

    if (estimateStateId === EEstimateStatesIds.DRAFT) {
      try {
        const createCandidates = expenditure.resources
          .filter((r) => !r.id)
          .map((c) => ({
            name: c.name,
            expenditure_type: c.type,
            draft_count: c.count,
            measure: c.measure,
            price: c.price,
            section_id: activeSubSectionId,
            related_work_id: expenditure.id,
          }));

        const updateCandidates: ICreateOrUpdateExpenditureInDraftBody[] = [
          {
            id: expenditure.id,
            name: expenditure.name,
            expenditure_type: ExpenditureTypeEnum.work,
            draft_count: expenditure.count,
            measure: expenditure.measure,
            price: expenditure.price,
            section_id: activeSubSectionId,
          },
          ...expenditure.resources
            .filter((r) => !!r.id)
            .map((c) => ({
              id: c.id,
              name: c.name,
              expenditure_type: c.type,
              draft_count: c.count,
              measure: c.measure,
              price: c.price,
              section_id: activeSubSectionId,
              related_work_id: expenditure.id,
            })),
        ];

        const updatePromises = updateCandidates.map((e) => simpleHandlerAPI.editDraftExpenditure(buildingId, e.id!, e));

        dispatch(simpleHandlerActions.addEditingLoadingExpenditureId(expenditure.id as number));

        if (createCandidates.length) {
          await simpleHandlerAPI.createDraftExpenditures(buildingId, createCandidates);
        }
        await Promise.allSettled(updatePromises);

        await dispatch(loadExpenditures(buildingId, activeSubSectionId, estimateStateId, true) as any);

        dispatch(simpleHandlerActions.removeEditingExpenditure(expenditure.id!));

        dispatch(reloadActiveSubSection() as any);
        dispatch(loadAggregations() as any);
      } catch (e) {
        errorCatcher(e as IReason);
      } finally {
        dispatch(simpleHandlerActions.removeEditingLoadingExpenditureId(expenditure.id as number));
      }

      return;
    }

    if (isDefault) {
      try {
        const createCandidates = expenditure.resources
          .filter((r) => !r.id)
          .map((c) => ({
            name: c.name,
            expenditure_type: c.type,
            count: c.count,
            measure: c.measure,
            price: c.price,
            section_id: activeSubSectionId,
            related_work_id: expenditure.id,
          }));

        const updateCandidates: ICreateOrUpdateDefaultExpenditureBody[] = [
          {
            id: expenditure.id,
            name: expenditure.name,
            expenditure_type: ExpenditureTypeEnum.work,
            count: expenditure.count,
            measure: expenditure.measure,
            price: expenditure.price,
            section_id: activeSubSectionId,
          },
          ...expenditure.resources
            .filter((r) => !!r.id)
            .map((c) => ({
              id: c.id,
              name: c.name,
              expenditure_type: c.type,
              count: c.count,
              measure: c.measure,
              price: c.price,
              section_id: activeSubSectionId,
              related_work_id: expenditure.id,
            })),
        ];

        const updatePromises = updateCandidates.map((e) =>
          simpleHandlerAPI.editDefaultExpenditure(buildingId, e.id!, e)
        );

        dispatch(simpleHandlerActions.addEditingLoadingExpenditureId(expenditure.id as number));

        if (createCandidates.length) {
          await simpleHandlerAPI.createDefaultExpenditures(buildingId, createCandidates);
        }
        await Promise.allSettled(updatePromises);

        await dispatch(loadExpenditures(buildingId, activeSubSectionId, estimateStateId, true) as any);

        dispatch(simpleHandlerActions.removeEditingExpenditure(expenditure.id!));

        dispatch(reloadActiveSubSection() as any);
        dispatch(loadAggregations() as any);
      } catch (e) {
        errorCatcher(e as IReason);
      } finally {
        dispatch(simpleHandlerActions.removeEditingLoadingExpenditureId(expenditure.id as number));
      }
    }
  };

export const simpleHandlerModel = {
  loadSections,
  loadSubSections,
  loadExpenditures,
  invalidateExpendituresList,
  moveItemsBetweenStates,
  createSectionInDraft,
  updateItemStatus,
  setMarkup,
  setDiscountCost,
  setEstimatedCost,
  deleteSection,
  patchSection,
  reloadActiveSubSection,
  deleteExpenditure,
  startEditingExpenditure,
  createExpenditures,
  editExpenditures,
};
