import React from "react";

import LogoGroup from "../ui/LogoGroup/LogoGroup";
import SocialLinks from "../ui/SocialLinks/SocialLinks";

import { PRIVACY_POLICY_LINK } from "../../constants";

import commonStyles from "../../HomePage.module.scss";
import styles from "./Footer.module.scss";

export interface IFooterProps {
  withSocialLinks?: boolean;
  withoutLogo?: boolean;
}

const Footer: React.FC<IFooterProps> = ({ withSocialLinks, withoutLogo }) => {
  return (
    <footer className={styles.footer}>
      <LogoGroup withoutLogo={withoutLogo}>
        <div className={styles.logoSubtitle}>
          <a href={PRIVACY_POLICY_LINK} rel={"noopener noreferrer"} target="_blank">
            Политика конфиденциальности
          </a>
          <span>&copy; «ООО Осла», {new Date().getFullYear()}</span>
        </div>
      </LogoGroup>
      {withSocialLinks && <SocialLinks />}
    </footer>
  );
};

export default React.memo(Footer);
