import cn from "classnames";
import React from "react";

import { IBuildingPropsPermissions } from "components/pages/Building/Building";

import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";

import styles from "./DocumentationTableHeader.module.scss";

export interface IDocumentationTableHeaderProps {
  isSets?: boolean;
  onChangeFilter: (name: string, value: string) => void;
  permissions: IBuildingPropsPermissions;
}

const DocumentationTableHeader = ({ isSets, onChangeFilter, permissions }: IDocumentationTableHeaderProps) => {
  const isSetsActions = permissions.canEditDrawingSets || permissions.canDeleteDrawingSets;
  const isDocsActions = permissions.canEditDrawings || permissions.canDeleteDrawings;

  const className = cn({
    [styles.setsRow]: isSets,
    [styles.docsRow]: !isSets,
    [styles.setsRowWithActions]: isSetsActions,
    [styles.docsRowWithActions]: isDocsActions,
  });

  return (
    <TableReusableHead className={className} isSticky>
      <TableReusableHeaderCell
        filterField="title"
        filterTitle={!isSets ? "Название чертежа" : "Название комплекта"}
        filterType="text"
        onChangeFilter={onChangeFilter}
      >
        {!isSets ? "Название чертежа" : "Название комплекта"}
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField={isSets ? "code" : undefined}
        filterTitle={isSets ? "Шифр" : undefined}
        filterType={isSets ? "text" : undefined}
        onChangeFilter={onChangeFilter}
        isCentered={!isSets}
      >
        {!isSets ? "Связь со сметой" : "Шифр"}
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>{!isSets ? "Листы" : "Разработавшая организация"}</TableReusableHeaderCell>
      <TableReusableHeaderCell>Загружено</TableReusableHeaderCell>
      <TableReusableHeaderCell>Файлы</TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default React.memo(DocumentationTableHeader);
