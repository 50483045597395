import axios from "axios";

import { IListResponseData } from "../../../../../types/ListResponse";
import { ICreatePostRank, IPostDetail, IPostRank, IPostsListResult } from "../../../../../types/interfaces/Posts";

export const postsApi = {
  getPostsList: () => axios.get<IListResponseData<IPostsListResult>>(`/posts_v2/`, { params: { limit: 1000 } }),
  createPost: (title: string) => axios.post<IPostsListResult>(`/posts_v2/`, { title }),
  getPostDetail: (postId: number) => axios.get<IPostDetail>(`/posts_v2/${postId}/`),
  patchPost: (postId: number, payload: Partial<Omit<IPostDetail, "ranks">>) =>
    axios.patch<IPostDetail>(`/posts_v2/${postId}/`, payload),
  deletePost: (postId: number) => axios.delete(`/posts_v2/${postId}/`),
  getPostRanks: (postId: number) =>
    axios.get<IListResponseData<IPostRank>>(`/posts_v2/ranks/`, { params: { post_id: postId, limit: 100 } }),
  createPostRank: (postId: number, rankPayload: ICreatePostRank) =>
    axios.post<IPostRank>(`/posts_v2/ranks/`, { post: postId, ...rankPayload }),
  deletePostRank: (rankId: number) => axios.delete(`/posts_v2/ranks/${rankId}/`),
  patchPostRank: (rankId: number, rankPayload: ICreatePostRank) =>
    axios.patch(`/posts_v2/ranks/${rankId}/`, rankPayload),
  getAllPosts: () => axios.get<IListResponseData<IPostDetail>>(`/posts_v2/all/`, { params: { limit: 500 } }),
  attachExecutorToSectionPlan: ({
    objectId,
    payload,
  }: {
    objectId: number | string;
    payload: { plan: number; count: number; post_rank: number };
  }) => axios.post(`/building/${objectId}/estimate/sections/plan-intervals/posts/`, payload),
  detachExecutorFromSectionPlan: ({ objectId, postIdInPlan }: { objectId: number | string; postIdInPlan: number }) =>
    axios.delete(`/building/${objectId}/estimate/sections/plan-intervals/posts/${postIdInPlan}/`),
};
