import moment from "moment";
import { Dispatch } from "redux";

import { RootState } from "../../../../app/store/rootReducer";
import { analyticsActions } from "./actions";
import { IAnalyticsEventsParams, analyticsApi } from "./analyticsApi";
import {
  analyticsBudgetResourceSelector,
  analyticsDatesSelector,
  analyticsEventsActiveTabSelector,
  analyticsEventsFiltersSelector,
  analyticsScopeSelector,
} from "./selectors";

import { IAnalyticsSelectedScope } from "./types";
import { IAnalyticsEvents, IAnalyticsEventsFilters } from "./types/events";

import { errorCatcher } from "../../../../utils/helpers/errorCatcher";
import { constructAnalyticsParams, makeProjectDatesKey } from "./utils";

export const setAnalyticsStartDate = (start_at: string) => (dispatch: Dispatch, getState: () => RootState) => {
  const currentSelectedDates = analyticsDatesSelector(getState());

  const end_at = moment(start_at)
    ?.add(currentSelectedDates.monthsCount - 1, "month")
    ?.endOf("month")
    ?.format("YYYY-MM-DD");

  dispatch(
    analyticsActions.setSelectedDates({
      ...currentSelectedDates,
      start_at,
      end_at,
    })
  );

  dispatch(analyticsActions.invalidateKey());
};

export const setAnalyticsMonthsCount = (monthsCount: number) => (dispatch: Dispatch, getState: () => RootState) => {
  const currentSelectedDates = analyticsDatesSelector(getState());

  const end_at = moment(currentSelectedDates.start_at)
    ?.add(monthsCount - 1, "month")
    ?.endOf("month")
    ?.format("YYYY-MM-DD");

  dispatch(
    analyticsActions.setSelectedDates({
      ...currentSelectedDates,
      end_at,
      monthsCount,
    })
  );

  dispatch(analyticsActions.invalidateKey());
};

export const toggleAnalyticsAllProjectView = () => (dispatch: Dispatch, getState: () => RootState) => {
  const currentSelectedDates = analyticsDatesSelector(getState());
  dispatch(
    analyticsActions.setSelectedDates({
      ...currentSelectedDates,
      allProject: Number(!currentSelectedDates.allProject) || 0,
    })
  );

  dispatch(analyticsActions.invalidateKey());
};

export const loadAnalyticsProjectTree = (objectId: number) => (dispatch: Dispatch, getState: () => RootState) => {
  return analyticsApi
    .loadTree(objectId)
    .then(({ data }) => dispatch(analyticsActions.addTree(objectId, data)))
    .catch(errorCatcher);
};

export const loadAnalyticsBudgetResource = (signal: AbortSignal) => (dispatch: Dispatch, getState: () => RootState) => {
  const currentSelectedScope = analyticsScopeSelector(getState());
  const currentSelectedDates = analyticsDatesSelector(getState());
  const currentBudgetResource = analyticsBudgetResourceSelector(getState());

  const budgetResourceParams = {
    ...constructAnalyticsParams({ currentSelectedDates, currentSelectedScope }),
    resource_type: currentBudgetResource.resourceType,
  };
  dispatch(analyticsActions.setLoading("budgetResource", true));
  return analyticsApi
    .loadBudgetResource(currentBudgetResource.resourceType, budgetResourceParams as any, signal)
    .then(({ data }) => {
      dispatch(analyticsActions.setBudgetResourceData(currentBudgetResource.resourceType, data));
      dispatch(analyticsActions.setLoading("budgetResource", false));
    })
    .catch(errorCatcher);
};

export const changeAnalyticsScope =
  (scope: Partial<IAnalyticsSelectedScope>) => (dispatch: Dispatch, getState: () => RootState) => {
    const currentSelectedScope = analyticsScopeSelector(getState());
    const currentSelectedDates = analyticsDatesSelector(getState());
    const newScope = { ...currentSelectedScope, ...scope };
    if (!newScope.project && !!currentSelectedDates.allProject) dispatch(toggleAnalyticsAllProjectView() as any);
    dispatch(analyticsActions.changeSelectedScope(newScope));
    dispatch(analyticsActions.invalidateKey());
  };

export const changeAnalyticsEventsTab = (newTab: IAnalyticsEvents["activeTab"]) => (dispatch: Dispatch) => {
  dispatch(analyticsActions.setEventsActiveTab(newTab));
};

export const changeAnalyticsEventsFilter = (payload: Partial<IAnalyticsEventsFilters>) => (dispatch: Dispatch) => {
  dispatch(analyticsActions.changeEventsFilters(payload));
};

export const loadAnalyticsBudget = (signal: AbortSignal) => (dispatch: Dispatch, getState: () => RootState) => {
  const currentSelectedScope = analyticsScopeSelector(getState());
  const currentSelectedDates = analyticsDatesSelector(getState());

  const budgetParams = constructAnalyticsParams({ currentSelectedDates, currentSelectedScope });
  dispatch(analyticsActions.setLoading("budget", true));
  return analyticsApi
    .loadBudget(budgetParams)
    .then(({ data }) => {
      dispatch(analyticsActions.setBudgetData(data));
      dispatch(analyticsActions.setLoading("budget", false));
    })
    .catch(errorCatcher);
};

export const loadAnalyticsProjectDates = () => (dispatch: Dispatch, getState: () => RootState) => {
  const currentSelectedScope = analyticsScopeSelector(getState());
  const currentSelectedDates = analyticsDatesSelector(getState());
  const params = constructAnalyticsParams({ currentSelectedScope, currentSelectedDates });
  return analyticsApi /* @ts-ignore */
    .loadProjectDates(params)
    .then(({ data }) => {
      dispatch(
        analyticsActions.addProjectDates(
          makeProjectDatesKey(currentSelectedScope.project!, params.section_id || null),
          data
        )
      );
    })
    .catch(errorCatcher);
};

export const loadAnalyticsProgress = (signal: AbortSignal) => (dispatch: Dispatch, getState: () => RootState) => {
  const currentSelectedScope = analyticsScopeSelector(getState());
  const currentSelectedDates = analyticsDatesSelector(getState());
  const params = constructAnalyticsParams({ currentSelectedScope, currentSelectedDates });

  dispatch(analyticsActions.setLoading("progress", true));
  return analyticsApi /* @ts-ignore */
    .loadProgress(params, signal)
    .then(({ data }) => {
      dispatch(analyticsActions.setProgressData(data));
      dispatch(analyticsActions.setLoading("progress", false));
    })
    .catch(errorCatcher);
};

export const loadAnalyticsFulfillment = (signal: AbortSignal) => (dispatch: Dispatch, getState: () => RootState) => {
  const currentSelectedScope = analyticsScopeSelector(getState());
  const currentSelectedDates = analyticsDatesSelector(getState());
  const params = constructAnalyticsParams({ currentSelectedScope, currentSelectedDates });

  dispatch(analyticsActions.setLoading("fulfillment", true));
  return analyticsApi /* @ts-ignore */
    .loadFulfillment(params, signal)
    .then(({ data }) => {
      dispatch(analyticsActions.setFulfillmentData(data));
      dispatch(analyticsActions.setLoading("fulfillment", false));
    })
    .catch(errorCatcher);
};

export const loadAnalyticsEvents = (signal: AbortSignal) => (dispatch: Dispatch, getState: () => RootState) => {
  const currentSelectedScope = analyticsScopeSelector(getState());
  const currentSelectedDates = analyticsDatesSelector(getState());
  const eventsTab = analyticsEventsActiveTabSelector(getState());
  const eventsFilters = analyticsEventsFiltersSelector(getState());
  /* @ts-ignore */
  const params: IAnalyticsEventsParams = {
    ...constructAnalyticsParams({ currentSelectedScope, currentSelectedDates }),
    overdue: eventsTab === "overdue" ? 1 : undefined,
    ...Object.fromEntries(Object.entries(eventsFilters).filter(([k, v]) => !!v)),
    start_at: eventsFilters.start_at || currentSelectedDates.start_at,
    end_at: eventsFilters.end_at || currentSelectedDates.end_at,
  };

  dispatch(analyticsActions.setLoading("events", true));

  return analyticsApi
    .loadEvents(params, signal)
    .then(({ data }) => {
      if (eventsTab === "overdue") dispatch(analyticsActions.setOverdueEvents(data));
      else dispatch(analyticsActions.setAllEvents(data));
      dispatch(analyticsActions.setLoading("events", false));
    })
    .catch(errorCatcher);
};
