import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";
import { DEFAULT_LIMIT_DOCUMENT } from "redux/modules/common/documents/constants";
import { loadDocuments, loadMoreDocuments } from "redux/modules/common/documents/documents";
import { documentsSelector, loadingStateSelector } from "redux/modules/common/documents/reducer";

import EmptyPlaceholder from "../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import PackingListsRow from "./PackingListsRow/PackingListsRow";
import PackingListsTableHeader from "./PackingListsTableHeader/PackingListsTableHeader";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ShowMoreButton from "shared/ui/controls/ShowMoreButton";

import { statusPackingListArray } from "constants/constant";
import { IIdAndName } from "types/idAndName";
import { IPackingListInList } from "types/interfaces/PackingList";

import { useCheckIsProductionModule } from "../hooks/useCheckIsProductionModule";
import { useObjectId } from "../hooks/useObjectId";

import { hasOnlyPaginationFilters } from "../../../../utils/hasOnlyPaginationFilters";

import ActsIcon from "../../../../images/icons/documentsTab/ActsIcon";

const PackingLists = () => {
  const isInProduction = useCheckIsProductionModule();
  const objectId = useObjectId();
  const dispatch = useDispatch();
  const documents = useSelector(documentsSelector);
  const [filters, setFilters] = useState({
    limit: DEFAULT_LIMIT_DOCUMENT,
    offset: 0,
    building_id: isInProduction ? objectId : null,
  });

  const isWithFilters = useMemo(
    () => !hasOnlyPaginationFilters(filters, isInProduction ? ["building_id"] : []),
    [filters, isInProduction]
  );

  const isListLoading = useSelector(loadingStateSelector);
  const breadcrumbsProjects = useSelector(objectsBreadcrumbsSelector);

  const formattedObjects = React.useMemo(() => {
    return breadcrumbsProjects.results.map((el: IIdAndName) => ({ id: el.id, name: el.name, label: el.name }));
  }, [breadcrumbsProjects]);

  const changeFilters = (name: string, value: string) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const hasNextPage = useMemo(() => documents?.count > documents?.results?.length, [documents]);
  const loadMore = () => {
    dispatch(
      loadMoreDocuments({
        ...filters,
        offset: documents?.results?.length,
      })
    );
  };

  useEffect(() => {
    dispatch(loadDocuments(filters));
  }, [filters]);

  const isEmpty = !isListLoading && !documents?.results.length;

  if (!isWithFilters && isListLoading) return <Spinner />;

  return (
    <div data-testid="page_docs_upds">
      {((isEmpty && isWithFilters) || !isEmpty) && (
        <PackingListsTableHeader
          onChangeFilter={changeFilters}
          statusBillsArray={statusPackingListArray}
          formattedObjects={formattedObjects}
        />
      )}
      {documents?.results.map((el: IPackingListInList) => (
        <PackingListsRow key={el.id} packingList={el} />
      ))}
      {isEmpty && <EmptyPlaceholder text="Нет УПД" svgJsx={<ActsIcon />} />}
      {isListLoading && <Spinner />}
      <ShowMoreButton
        isExists={hasNextPage && !isListLoading}
        showedCount={documents?.results?.length}
        allCount={documents?.count}
        handleAdd={loadMore}
      />
    </div>
  );
};

export default PackingLists;
