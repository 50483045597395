import cn from "classnames";
import React, { useMemo } from "react";

import { IchatMessageResponse } from "widgets/Chat/model/types";

import { parseMessageText } from "../../utils";
import { localizeServerDateInUserTimezone } from "utils/localizeServerDateInUserTimezone";

import styles from "./MessageV2.module.scss";

interface Iprops extends IchatMessageResponse {
  isMe?: boolean;
  isFirstByAuthor?: boolean;
  isFirstByDate?: boolean;
}

function MessageV2({ text, author, isMe, isFirstByAuthor, created_at, isFirstByDate }: Iprops) {
  const authorSubtitle = useMemo(
    () => author.position || author.roles?.map((role) => role.name).join(", "),
    [author, isMe]
  );
  return (
    <div
      className={styles.container}
      style={{
        alignSelf: isMe ? "flex-end" : "flex-start",
        alignItems: isMe ? "flex-end" : "flex-start",
      }}
    >
      {" "}
      {isFirstByDate && (
        <div className={styles.dateText}>{localizeServerDateInUserTimezone(created_at, "DD MMMM")}</div>
      )}
      {isFirstByAuthor && (
        <div className={styles.sender} style={{ alignSelf: isMe ? "flex-end" : "flex-start" }}>
          {author.full_name} <br />
          <span className={cn(styles.senderRole, { [styles.myMessage]: isMe })}>{authorSubtitle}</span>
        </div>
      )}
      <div style={{ flexDirection: isMe ? "row-reverse" : "row" }} className={styles.message}>
        <div className={styles.text}>{parseMessageText(text)}</div>
        <div className={styles.time}>{localizeServerDateInUserTimezone(created_at, "HH:mm")}</div>
      </div>
    </div>
  );
}

export default MessageV2;
