import React, { useState } from "react";

import FileViewerZoom from "./../FileViewerZoom/FileViewerZoom";

import { IFile } from "types/interfaces/Files";

import styles from "./FileViewImage.module.scss";

interface IProps {
  file: IFile;
}

const FileViewImage: React.FC<IProps> = ({ file }) => {
  const [zoom, setZoom] = useState(1);
  const [isMoving, setIsMoving] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  return (
    <>
      <div className={styles.container}>
        <img
          /* onMouseDown={(e) => {
            setIsMoving(true);
            setStartX(e.clientX);
            setStartY(e.clientY);
          }}
          onMouseMove={(e) => {
            if (!isMoving) return;
            if (zoom <= 1) return;
            const newX = e.clientX - startX
            if (Math.abs(newX) > )
            setX(e.clientX - startX);
            setY(e.clientY - startY);
          }}
          onMouseUp={() => {
            setIsMoving(false);
            setStartX(0);
            setStartY(0);
          }} */
          src={file.link}
          style={{ transform: `scale(${zoom}) translateX(${x}px) translateY(${y}px)` }}
        />
      </div>
      <FileViewerZoom zoom={zoom} setZoom={setZoom} />
    </>
  );
};

export default FileViewImage;
