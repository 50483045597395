import React from "react";

import { IChartIntervalProps } from "../ChartInterval.typings";
import { IChartPlanIntervalProps } from "../_plan/withPlan";
import { useChartIntervalSize } from "../useChartIntervalSize";
import { useIntervalEditing } from "./useIntervalEditing";

import styles from "./withEditing.module.scss";

export interface IWithEditingProps {
  intervalLeftRem: number;
  intervalWidthRem: number;
}

export const withEditing =
  (Component: React.ComponentType<IChartPlanIntervalProps>) =>
  (props: IChartPlanIntervalProps & Pick<IChartIntervalProps, "start" | "end">) => {
    const { forwardRef: intervalWrapperRef, isSectionPlan, plan, start, end } = props;

    // @ts-ignore
    const isGroup = plan.type === "group";
    // @ts-ignore
    const intervalData = plan.type ? plan[plan.type] : plan;
    const intervalSectionId = intervalData.cs_id || intervalData.ps_id;
    const intervalId = intervalData.id || intervalSectionId;
    const expenditureId = intervalData.exp_id || intervalData.group_id;

    const intervalSize = useChartIntervalSize({ start, end });
    const intervalInitialsRem = { left: intervalSize.leftRem as number, width: intervalSize.widthRem as number };

    const {
      isIntervalEditingEnabled,
      intervalLeftRem,
      intervalWidthRem,
      handleDragStartHead,
      handleDragStartMid,
      handleDragStartTail,
      handleDragEnd,
    } = useIntervalEditing({
      intervalInitialsRem,
      intervalWrapperRef,
      isGroup,
      isSectionPlan,
      expenditureId,
      intervalId,
      projectId: props.projectId,
      initialDateStart: start,
      initialDateEnd: end,
    });

    const innerBeforeContent = (
      <>
        {props.innerBeforeContent}
        {isIntervalEditingEnabled && (
          <div className={styles.editAnchorContainer}>
            {/* @ts-ignore */}
            <div className={styles.editAnchorTail} onDragEnd={handleDragEnd} onMouseDown={handleDragStartTail} />
            {/* @ts-ignore */}
            <div className={styles.editAnchorMid} onDragEnd={handleDragEnd} onMouseDown={handleDragStartMid} />
            {/* @ts-ignore */}
            <div className={styles.editAnchorHead} onDragEnd={handleDragEnd} onMouseDown={handleDragStartHead} />
          </div>
        )}
      </>
    );

    return (
      <Component
        {...props}
        children={props.children}
        innerBeforeContent={innerBeforeContent}
        intervalLeftRem={intervalLeftRem}
        intervalWidthRem={intervalWidthRem}
        forwardRef={intervalWrapperRef}
      />
    );
  };
