export const getTableViewWidthPercent = (tableElement) => {
  const tableInnerElement = tableElement.querySelector(".ant-table-tbody");
  const widthPercent = (tableElement.offsetWidth / tableInnerElement.scrollWidth) * 100;
  return widthPercent;
};

export const scrollTable = (tableElement, scrollPosition, isNotTable) => {
  if (!tableElement) return;
  if (isNotTable) {
    const scrollTo = tableElement.scrollWidth * (scrollPosition / 100);
    tableElement.scroll(scrollTo, 0);
    return;
  }
  const tableInnerElement = tableElement.querySelector(".ant-table-content");
  if (!tableInnerElement) return;
  const scrollTo = tableInnerElement.scrollWidth * (scrollPosition / 100);
  tableInnerElement.scroll(scrollTo, 0);
};
