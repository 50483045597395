import axios from "axios";
import cn from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { passportsActions } from "redux/modules/common/passportsAndCertificates/actions";
import {
  attachDocumentToExpenditure,
  attachFileToExpenditure,
} from "redux/modules/common/passportsAndCertificates/thunks";

import CommonFilesModal from "components/modals/ExpenditureFilesModal/CommonFilesModal";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { IStockFile, useStockFiles } from "components/pages/Stock/hooks/useStockFIles";

import DateCell from "../../../../../../../shared/ui/dataDisplay/DateCell/DateCell";
import { useTypedSelector } from "app/store/typedUseSelector";
import ConfirmModal from "entities/ConfirmationModal/ConfirmModal";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ExpandPillTrigger from "shared/ui/controls/ExpandPillTrigger/ExpandPillTrigger";
import FilesClipButton from "shared/ui/controls/FilesClipButton/FilesClipButton";
import StatusElement from "shared/ui/dataDisplay/StatusElement/StatusElement";
import { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import FileViewer from "widgets/FileViewer/FileViewer";

import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";

import getShortFullName from "../../../../../../../utils/formatters/getShortFullName";
import downloadFile from "utils/helpers/download";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import { ReactComponent as DownloadIcon } from "images/download.svg";
import CrossInCircle from "images/icons/CrossInCircle";

import styles from "./ExpenseProduct.module.scss";

//@ts-ignore
const ExpenseProduct = ({ data, onClick, measure, stockId }) => {
  const { invalidateKey, isPending } = useTypedSelector((state) => state.passports);
  const [isOpen, setIsOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const {
    isLoading,
    data: files,
    buildingFilesIds,
    resourceFilesIds,
  } = useStockFiles({
    isOpen: isOpen || isModal,
    productId: data.id,
    stockId,
    invalidateKey,
  });
  const [fileViewerIndex, setFileViewerIndex] = useState(-1);

  //file
  const [isDelete, setIsDelete] = useState<IStockFile | null>(null);
  const objectId = useObjectId();

  const [isDeletePending, setIsDeletePending] = useState(false);
  const deleteDocument = async (externalId?: number) => {
    const id = externalId || isDelete?.id;
    const isResource = resourceFilesIds?.includes(id!);

    setIsDeletePending(true);
    if (isResource) {
      await axios.delete(`/estimate/documents/resource_attachments/${files?.[0]?.exp_id}/${id}/`);
    } else {
      await axios.delete(`/building/${objectId}/materials/${data.id}/files/${id}`);
    }
    dispatch(passportsActions.invalidateKey());
    setIsDeletePending(false);
    setIsDelete(null);
  };

  const downloadHandler = (file: any) => {
    downloadFile(file.file, file.name);
  };

  const dispatch = useDispatch();
  const onSubmit = (vals: any) => {
    dispatch(attachDocumentToExpenditure(vals, files?.[0]?.exp_id!));
  };

  const onUpload = (vals: any) => {
    dispatch(attachFileToExpenditure(files?.[0]?.exp_id!, vals));
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.field1}>
        <div className={styles.dot}></div>
      </div>
      <div className={styles.field2} title={data?.stock_product?.product_building?.name}>
        {data?.stock_product?.product_building?.name}
      </div>
      <div className={styles.center}>{getShortFullName(data?.stock_using?.responsible)}</div>
      <div className={cn(styles.center, styles.date)}>
        <DateCell date={moment(data?.stock_using?.used_at).format("DD.MM.YYYY")} />
      </div>
      <div className={styles.center}>{getShortFullName(data?.stock_using?.user || data?.stock_using?.worker)}</div>
      <div className={styles.center}>{data?.stock_product?.product_building?.measure}</div>
      <div className={styles.center}>{data?.count}</div>
      <div className={styles.filesCount} onClick={stopEventPropagation}>
        <FilesClipButton
          onClick={() => {
            setIsModal(true);
          }}
        />{" "}
        {!!data?.count_files && (
          <ExpandPillTrigger label={data?.count_files || 0} toggleExpand={() => setIsOpen((prev) => !prev)} />
        )}
      </div>
      {isOpen && (
        <div className={styles.filesRow}>
          {isLoading && <Spinner isStatic isSmallGray className={styles.spinner} />}
          {files?.map((file, i) => (
            <StatusElement
              key={file.id}
              status="success"
              text={file.name}
              onClick={() => {
                setFileViewerIndex(i);
              }}
            />
          ))}
          <FileViewer
            files={files as any}
            isOpen={fileViewerIndex > -1}
            onClose={() => setFileViewerIndex(-1)}
            startIndex={fileViewerIndex}
          />
        </div>
      )}
      <CommonFilesModal
        isPending={isLoading || isPending || isDeletePending}
        type="expenditure"
        expenditureType={ExpenditureTypeEnum.material}
        onSubmit={onSubmit}
        onUpload={onUpload}
        fileTypeForFiltering="Файл"
        isOpen={isModal}
        onDirectlyDelete={deleteDocument}
        onClose={() => setIsModal(false)}
        name={data?.name}
        attachments={files as any}
        renderFileRow={(file) => (
          <div className={styles.tableRow} key={file.id}>
            <TableReusableCell isNoBreak>{file.type} </TableReusableCell>
            <TableReusableCell isNoBreak>{file.name} </TableReusableCell>
            <TableReusableCell className={styles.files} onClick={() => downloadHandler(file)}>
              <DownloadIcon />
            </TableReusableCell>
            <TableReusableCell>
              <div onClick={() => setIsDelete(file)} className={styles.delete}>
                <CrossInCircle />
              </div>
            </TableReusableCell>
          </div>
        )}
        customTableHead={
          <div className={styles.tableHead}>
            <div>Тип</div>
            <div>Имя</div>
          </div>
        }
      />
      <ConfirmModal
        isOpen={!!isDelete}
        onClose={() => setIsDelete(null)}
        action={() => deleteDocument()}
        variant="secondary"
        title="Подтвердите удаление"
      />
    </div>
  );
};

export default ExpenseProduct;
