import $ from "jquery";
import React, { FC, Fragment, useEffect } from "react";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";

import { LandingPagesRoutesEnum } from "../../lib/landingPagesRoutesEnum";

/* @ts-ignore */
export async function sendLead(data) {
  const url = encodeURI(
    `https://osla-ru.bitrix24.ru/rest/1/urr21jmhs44q901i/crm.lead.add.json?FIELDS[TITLE]=OSLA.RU | ${data.company}&FIELDS[NAME]=${data.name}&FIELDS[PHONE][0][VALUE]=${data.phone}`
  );
  fetch(url).then((response) => {});
}

export function showSendLeadSuccess() {
  $("#lead-success").removeClass("out");
  $("#lead-success").fadeIn();

  setTimeout(() => {
    $("#lead-success").addClass("out");
    setTimeout(() => {
      $("#lead-success").fadeOut();
    }, 100);
  }, 3000);
}

export const RequestModal: FC = () => {
  useEffect(() => {
    // TODO: Выпилить jquery
    $(".open_modal").on("click", function () {
      var attr = $(this).attr("data-val");
      var modal = $("#" + attr);
      modal.removeClass("out");
      modal.fadeIn();
    });
    $(".close").on("click", function () {
      var prt = $(this).parents(".modal");
      prt.addClass("out");
      setTimeout(function () {
        prt.fadeOut();
      }, 100);
    });
    $(window).on("click", function (event) {
      $(".modal").each(function () {
        var gtattr = $(this).attr("id");
        var new_mod = $("#" + gtattr);
        var md_cnt = $(new_mod).find(".modal-content"); /* @ts-ignore */
        if (event.target === $(md_cnt)[0]) {
          setTimeout(function () {
            $(new_mod).addClass("out");
            $(new_mod).fadeOut();
          }, 100);
        } /* @ts-ignore */
        if (event.target === this) {
          setTimeout(function () {
            $(new_mod).addClass("out");
            $(new_mod).fadeOut();
          }, 100);
        }
      });
    });
  }, []);
  /* @ts-ignore */
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      company: $("#modal-form-company").val(),
      name: $("#modal-form-name").val(),
      phone: $("#modal-form-phone").val(),
    };

    if (data.company && data.name && data.phone) {
      sendLead(data).then(() => {
        $("#modal").addClass("out");
        setTimeout(function () {
          $("#modal").fadeOut();
          showSendLeadSuccess();
          $("#form-modal").trigger("reset");
        }, 100);
      });
    }
  };

  return (
    <Fragment>
      <div id="modal" className="modal out">
        <div className="modal-content">
          <div className="modal__reg">
            <div className="close"></div>
            <h3>Получите демонстрацию работы платформы</h3>
            <p>Свяжитесь с нами, и мы ответим на ваши вопросы и проведем демонстрацию платформы</p>
            <form onSubmit={handleSubmit} id="form-modal">
              <div className="modal__form">
                <div className="platform__inp">
                  <label>
                    <input type="text" placeholder="Компания" id="modal-form-company" />
                  </label>
                </div>
                <div className="platform__inp">
                  <label>
                    <input type="text" placeholder="Ваше имя" id="modal-form-name" />
                  </label>
                </div>
                <div className="platform__inp">
                  <label>
                    <InputMask
                      className="phone"
                      mask="+7 (999) 999-99-99" /* @ts-ignore */
                      maskChar={null}
                      placeholder="+7 (. . .) . . . - . . - . ."
                      id="modal-form-phone"
                    />
                  </label>
                </div>
                <div className="modal__check">
                  <p>
                    Направляя форму, Вы даете согласие на обработку персональных данных в соответствии с{" "}
                    <Link to={LandingPagesRoutesEnum.privacyPolicy} target="_blank">
                      Политикой обработки персональных данных
                    </Link>
                  </p>
                </div>
                <button className="button">Свяжитесь со мной</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div id="lead-success" className="lead-success">
        <span>
          Спасибо,&nbsp;Ваш&nbsp;запрос&nbsp;отправлен!
          <br />
          Мы&nbsp;скоро&nbsp;свяжемся&nbsp;с&nbsp;Вами.
        </span>
      </div>
    </Fragment>
  );
};
