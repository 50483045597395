export interface IPassportsState {
  invalidateKey: number;
  isPending: boolean;
  documents: Record<
    number,
    {
      isLoading: boolean;
      documents: IPassport[];
    }
  >;
  expenditureAttachments: Record<string, { results: IAttachmentInExpenditure[]; isLoading?: boolean }>;
  ks2Attachments: Record<number, { results: IAttachmentInExpenditure[]; isLoading?: boolean }>;
  sectionAttachments: Record<string, { results: IAttachmentInExpenditure[]; isLoading?: boolean }>;
  ks2ExpenditureAttachments: Record<string, IAttachmentInExpenditure[]>;
}

export interface Iks2ExpenditureDocsRecord {
  work: IAttachmentInExpenditure[];
  resource: IAttachmentInExpenditure[];
  aosr: IAosrInKs2Expenditure[];
  isLoading?: boolean;
}

export interface IAttachmentInExpenditureResponse {
  id: number;
  type: DocumentsTypesEnum;
  type_display: string;
  expenditure: number;
  date: null | string;
  number: null | string;
  originalname: string;
  file_path: string;
  start_at: null | string;
  end_at: null | string;
  title?: string;
}

export type IAttachmentExpenditureInJournal = IAttachmentInExpenditureResponse & {
  act_ks2: number;
  year: number;
  month: number;
};

export interface IAttachmentInExpenditure extends Omit<IAttachmentInExpenditureResponse, "file_path"> {
  file: string;
  name: string;
}

export interface ICreatePassport {
  number: string;
  start_at?: string;
  end_at?: string;
  file: File;
  packing_item: number;
  originalname: string;
  type: DocumentsTypesEnum;
  date?: string;
  range?: number;
}

export interface IPassport {
  id: number;
  file_type: "passport";
  number: string;
  created_at: string;
  start_at: string;
  end_at: string;
  file: string;
  originalname: string;
}

export interface ICreateCertificate {
  number: string;
  start_at: string;
  end_at: string;
  file: string;
  packing_item: number;
  originalname: string;
  issue_date: string;
}

export interface IFileAttachingToExpenditureParams {
  file: string;
  originalname: string;
  type: DocumentsTypesEnum;
}

export enum DocumentsTypesEnum {
  file = "file",
  test_report = "test_report",
  quality_passport = "quality_passport",
  certificate_of_conformity = "certificate_of_conformity",
  passport = "passport",
  installation_and_operating_instruction = "installation_and_operating_instruction",
  warranty_card = "warranty_card",
  //////////////////////////////////////ks2
  executive_scheme = "executive_scheme",
  executive_drawing = "executive_drawing",
  special_journal = "special_journal",
  act_of_test_and_acceptance = "act_of_test_and_acceptance",
  statement = "statement",
  confirmation_of_specialists_qualifications = "confirmation_of_specialists_qualifications",
  /////////////////////////////////////section
  aook = "aook",
  non_destructive_test_protocol = "non_destructive_test_protocol",
}

export enum DocumentsTypesNamesEnum {
  file = "Прочее",
  test_report = "Протокол испытаний",
  quality_passport = "Паспорт качества",
  certificate_of_conformity = "Сертификат соответствия",
  passport = "Паспорт",
  installation_and_operating_instruction = "Инструкция по монтажу и эксплуатации",
  warranty_card = "Гарантийный талон",
  //////////////////////////////////////ks2
  executive_scheme = "Исполнительная схема",
  executive_drawing = "Исполнительный чертеж",
  special_journal = "Спецжурнал",
  act_of_test_and_acceptance = "Акт испытаний и приемки",
  statement = "Ведомость",
  confirmation_of_specialists_qualifications = "Подтверждение квалификации специалистов",
  /////////////////////////////////////section
  aook = "Акт освидетельствования ответственных конструкций",
  non_destructive_test_protocol = "Протокол неразрушающих испытаний",
}

export const DocumentsTypesValues = Object.keys(DocumentsTypesEnum);

export interface IAosrInKs2Expenditure {
  id: number;
  docs: IAosrInKs2ExpenditureDocs[];
}

export interface IAosrInKs2ExpenditureDocs {
  id: number;
  hiddenwork: number;
  title: string;
  doc_date: string;
  doc_type: string;
  listfiles: IAosrInKs2ExpenditureListFiles[];
  isHidden?: boolean;
}

export interface IAosrInKs2ExpenditureListFiles {
  file: string;
  id: number;
  originalname: string;
  item_id: number;
}

export interface IAttachFileInWorkInJournalRequest {
  month?: number;
  year?: number;
  expenditure: number;
  act_ks2?: number;
  type: string;
  file: string;
  originalname: string;
}
