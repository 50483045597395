import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import { BASE_ROW_MARGIN } from "./constants";

export const getRowMargin = (depth: number) => {
  let margin = BASE_ROW_MARGIN * depth + 22;
  return `${margin}px`;
};

//TODO_V2_FINANCE
export const getIsControlsVisible = (section: IFinanceTreeItem, isLoading?: boolean) => {
  if (isLoading) {
    return false;
  }

  //TODO_V2_FINANCE
  return Boolean(
    section.type === "object" || !!section.children?.length // &&
    //(section.children?.length || section.subsection_count || section.expenditure_count || section.costs?.length))
  );
};
