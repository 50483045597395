import { IServiceActsList } from "types/interfaces/ServiceAct";

export const SET_SERVICE_ACTS_LIST = "serviceActs/SET_SERVICE_ACTS_LIST";
export const SET_SERVICE_ACT = "serviceActs/SET_SERVICE_ACT";
export const SET_SERVICE_ACTS_LIST_LOADING = "serviceActs/SET_SERVICE_ACTS_LIST_LOADING";
export const SET_SERVICE_ACT_LOADING = "serviceActs/SET_SERVICE_ACT_LOADING";
export const SET_IS_SERVICE_ACT_NOT_FOUND = "serviceActs/SET_IS_SERVICE_ACT_NOT_FOUND";
export const SET_SERVICE_ACT_FILES = "serviceActs/SET_SERVICE_ACT_FILES";
export const ADD_MORE_SERVICE_ACT_FILES = "serviceActs/ADD_MORE_SERVICE_ACT_FILES";
export const CUT_OFF_SERVICE_ACT_FILE = "serviceActs/CUT_OFF_SERVICE_ACT_FILE";
export const LOAD_MORE_SERVICE_ACTS_LIST = "serviceActs/LOAD_MORE_SERVICE_ACTS_LIST";
export const SET_IS_ACCEPTING_SERVICE_ACT = "serviceActs/SET_IS_ACCEPTING_SERVICE_ACT";

export const setServiceActsList = (data: IServiceActsList | null) => ({ type: SET_SERVICE_ACTS_LIST, payload: data });
export const setIsAcceptingServiceAct = (isAccepting: boolean) => ({
  type: SET_IS_ACCEPTING_SERVICE_ACT,
  payload: isAccepting,
});
export const setMoreServiceActsList = (data: IServiceActsList | null) => ({
  type: LOAD_MORE_SERVICE_ACTS_LIST,
  payload: data,
});
