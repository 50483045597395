import React from "react";

import styles from "./ExpandTrigger.module.scss";

interface IProps {
  expandedText: string;
  collapsedText: string;
  onExpand?: () => void;
  textPlacement?: "left" | "right";
  isExpanded: boolean;
}

const ExpandTrigger: React.FC<IProps> = ({
  expandedText,
  collapsedText,
  onExpand,
  textPlacement = "left",
  isExpanded,
}) => {
  const isLeft = textPlacement === "left";
  const text = isExpanded ? expandedText : collapsedText;

  return (
    <div className={styles.container} onClick={onExpand}>
      {isLeft && text}
      <svg
        className={isExpanded ? styles.expanded : undefined}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.5 3.23245L6 8.77344L10.5 3.23245" fill="#696969" />
      </svg>
      {!isLeft && text}
    </div>
  );
};

export default ExpandTrigger;
