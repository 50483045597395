import React from 'react';

export default function PlusBoxIcon({ width = 15 , height, color='#d1d1d1', fill='#7c7c7c'}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={width}
      height="15" 
      viewBox="0 0 15 15"
    >
      <g fill='none' stroke={color}>
        <rect 
          stroke='none'
          width="15"
          height="15" 
          rx="2"
        />
          <rect 
            fill='none'
            x="0.5" 
            y="0.5" 
            width="14" 
            height="14" 
            rx="1.5"
          />
      </g>
      <path 
        fill={fill}
        d="M-3806.291-17212c-.238,0-.354-.143-.354-.424v-2.93h-2.93c-.281,0-.424-.117-.424-.355v-.613c0-.232.143-.35.424-.35h2.93v-2.93c0-.285.117-.428.354-.428h.614c.233,0,.354.143.354.428v2.93h2.926c.285,0,.428.117.428.35v.613c0,.238-.143.355-.428.355h-2.926v2.93c0,.281-.121.424-.354.424Z" 
        transform="translate(3813.6 17223.732)"
      />
    </svg>
  )
}