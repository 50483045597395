import cn from "classnames";
import React, { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";

import { ChartCheckpointsMarks } from "redux/modules/common/chart/types";

import { ChartContext } from "components/pages/Chart/Chart";
import { useChartUnitMultiplier } from "components/pages/Chart/hooks/useChartUnitMultiplier";

import { openViewCheckpointModalList } from "widgets/ViewCheckpoint/model/thunks";

import { generateDaysForLine } from "../ChartDateLine.utils/generateDaysForLine";

import styles from "./ChartDateLineDays.module.scss";

export interface IDaysLineProps {
  year: number;
  unitOffset: number;
  checkpointsMarks: ChartCheckpointsMarks["days"];
}

export const ChartDateLineDays = React.forwardRef(({ year, unitOffset, checkpointsMarks }: IDaysLineProps, ref) => {
  const dispatch = useDispatch();
  const days = useMemo(() => generateDaysForLine(year), [year]);
  const unitMultiplier = useChartUnitMultiplier();

  const { projectId } = useContext(ChartContext);

  const openCheckpointModal = (date: string) => () => {
    dispatch(openViewCheckpointModalList({ startDate: date, endDate: date, objectId: projectId }));
  };

  return (
    <>
      <div
        className={styles.daysWrapper}
        ref={(r) => {
          // @ts-ignore
          if (!ref.current) return;
          // @ts-ignore
          ref.current[year] = r;
        }}
        style={{ left: `${!!unitOffset ? unitOffset * unitMultiplier : 0}rem` }}
      >
        {days.map((day, index) => {
          return (
            <div
              key={index}
              className={cn(
                {
                  startMonth: day.day === 1,
                  [styles.weekend]: day.weekend,
                  [styles.today]: day.today,
                  [styles.hasCheckpoint]: checkpointsMarks[day.fullDate],
                  // [styles.critical]: day.isCritical
                },
                styles.dayWrapper
              )}
              style={{
                left: `${index * unitMultiplier}rem`,
                width: `${unitMultiplier}rem`,
              }}
              data-year={year}
              data-month={day.month}
              onClick={checkpointsMarks[day.fullDate] ? openCheckpointModal(day.fullDate) : undefined}
            >
              <div className={styles.day}>{day.day}</div>
            </div>
          );
        })}
      </div>
    </>
  );
});
