import { message } from "antd";
import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";

import Select, { IOption } from "../../../../components/UI/atoms/Select";

import InputBase from "../../../../shared/ui/inputs/InputBase";
import { IExecutor } from "../../useExecutors";

import intToRoman from "../../../../utils/formatters/intToRoman";

import CheckCircle from "../../../../images/icons/CheckCircle";
import CrossInCircleRounded from "../../../../images/icons/CrossInCircleRounded";

import styles from "./ExecutorsRow.module.scss";

export interface IExecutorsRowProps {
  post: number;
  rank: number;
  count: number;
  onDelete: () => void;
  onApprove: (_: Partial<IExecutor>) => void;
  postOptions: IOption[];
  rankOptionsByPost: Record<number, IOption[]>;
  index: number;
  isNew?: boolean;
  canEdit: boolean;
  postTitle?: string;
  rankDisplay?: string;
}

const ExecutorsRow: React.FC<IExecutorsRowProps> = ({
  post,
  rank,
  count,
  onDelete,
  onApprove,
  postOptions,
  rankOptionsByPost,
  canEdit,
  index,
  isNew,
  postTitle,
  rankDisplay,
}) => {
  const [executorDraft, setExecutorDraft] = useState<Partial<IExecutor>>({ post, rank, count });

  const handleChangeDraft = useCallback((fieldName: keyof IExecutor, value: string | number) => {
    setExecutorDraft((prevState) => ({ ...prevState, [fieldName]: value }));
  }, []);

  const handleApprove = () => {
    if (!executorDraft.post) {
      message.warn("Укажите должность");
      return;
    }
    if (!executorDraft.rank) {
      message.warn("Укажите разряд");
      return;
    }
    if (!executorDraft.count) {
      message.warn("Укажите количество");
      return;
    }
    if (+executorDraft.count <= 0) {
      message.warn("Количество не может быть меньше единицы");
      return;
    }
    onApprove({ ...executorDraft, isNew: false });
  };

  const handleDelete = () => {
    onDelete();
  };

  const postName = useMemo(
    () => postTitle || postOptions?.find((option) => option.id === executorDraft.post)?.name || "",
    [postTitle, postOptions, executorDraft.post]
  );

  return (
    <div className={cn(styles.row, { [styles.lightblueBg]: index !== undefined ? index % 2 === 0 : false })}>
      {isNew ? (
        <Select options={postOptions} value={executorDraft.post} onChange={(v) => handleChangeDraft("post", v)} />
      ) : (
        <span>{postName}</span>
      )}
      {isNew ? (
        <Select
          disabled={!executorDraft.post}
          options={(!!executorDraft.post && rankOptionsByPost[executorDraft.post!]) || []}
          value={executorDraft.rank}
          onChange={(v) => handleChangeDraft("rank", v)}
        />
      ) : (
        <div className={styles.rankDisplay}>{rankDisplay || intToRoman(rank)}</div>
      )}
      {isNew ? (
        <InputBase
          value={executorDraft.count as any}
          onChange={(e) => handleChangeDraft("count", e.target.value)}
          //@ts-ignore
          variant={"tertiary"}
          classNameInput={styles.input}
        />
      ) : (
        <span className={styles.centered}>{count}</span>
      )}
      <div className={styles.icon}>
        {isNew && (
          <div className={styles.controls}>
            <CheckCircle onClick={handleApprove} color={"#000"} />
            <CrossInCircleRounded onClick={handleDelete} fill={"#000"} />
          </div>
        )}
        {!isNew && canEdit && (
          <div className={styles.singleControl}>
            <CrossInCircleRounded onClick={handleDelete} fill={"#000"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ExecutorsRow);
