import React, { useMemo } from "react";

import { DOCUMENTATION } from "components/pages/Building/constants";
import Blueprints from "pages/Estimate/Blueprints/Blueprints";

import { useLocationArray } from "utils/hooks/useLocationArray";

const perms = {
  canAddDrawings: true,
  canAddDrawingSets: true,
  canAddFiles: true,
  canAddPlan: true,
  canAddProgress: true,
  canDeleteDrawings: true,
  canDeleteDrawingSets: true,
  canDeleteFiles: true,
  canEditDrawings: true,
  canEditDrawingSets: true,
  canSharePlan: true,
  canShareProgress: true,
  canViewDrawings: true,
  canViewPlan: true,
  canViewProgress: true,
};

const ConstructionControlDocumentation = () => {
  const locationsArr = useLocationArray();
  const blueprintsSetId = useMemo(() => {
    const param = locationsArr.at(-1);
    if (param === DOCUMENTATION) {
      return "";
    }
    return param;
  }, [locationsArr]);

  return (
    <Blueprints
      activeId="1"
      permissions={{
        canAddDrawings: true,
        canAddDrawingSets: true,
        canAddFiles: true,
        canAddPlan: true,
        canAddProgress: true,
        canDeleteDrawings: true,
        canDeleteDrawingSets: true,
        canDeleteFiles: true,
        canEditDrawings: true,
        canEditDrawingSets: true,
        canSharePlan: true,
        canShareProgress: true,
        canViewDrawings: true,
        canViewPlan: true,
        canViewProgress: true,
      }}
    />
  );
};

export default ConstructionControlDocumentation;
