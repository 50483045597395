import axios from "axios";

export const contractorsApi = {
  loadList: (params: Record<string, any>) => {
    return axios.get("/contractors/", { params });
  },
  delete: (id: number) => {
    return axios.delete(`/contractors/${id}/`);
  },
  invite: (data: any) => {
    return axios.post("/partnership/invitations/", data);
  },
  loadDetail: (id: string) => {
    return axios.get(`/contractors/${id}/`);
  },
  changeManager: (contractorId: string | number, managerId: number | null) => {
    return axios.post(`/contractors/${contractorId}/change_manager/`, {
      manager_id: managerId,
    });
  },
};
