import { useDispatch, useSelector } from "react-redux";
import {
  ks6aTableScrollPositionSelector,
  ks6aTableViewWidthPercentSelector,
  ks6aYearSelector,
  setTableScrollPositionAction,
  setYear
} from '../../../redux/modules/common/ks6a/ks6a'
import {useCallback, useEffect} from 'react'
import {compose} from 'redux'
import {getTableScrollPercentByMonth, MONTH_PERCENTS} from './components/Ks6a/constants'

export const useKs6aMonthScrollBar = (initialYear: number) => {
  const dispatch = useDispatch();
  const year = useSelector(ks6aYearSelector);
  const tableViewWidthPercent = useSelector(ks6aTableViewWidthPercentSelector);
  const tableScrollPosition = useSelector(ks6aTableScrollPositionSelector);

  useEffect(() => {
    dispatch(setYear(initialYear));
  }, [])

  const handleYearChange = (year: number) => {
    dispatch(setYear(year));
  };

  const onArrowClick = useCallback((percent: number) => {
    let percentCandidate = Math.round(percent)
    const percentSearchArray = Array.from(new Set([...MONTH_PERCENTS, percentCandidate])).sort((a, b) => a - b)
    let monthId = percentSearchArray.findIndex(x => percentCandidate === x) + 1
    compose(dispatch, setTableScrollPositionAction)({
      eventFrom: "slider",
      percent: getTableScrollPercentByMonth(monthId),
    })
  }, [])

  const setTableScrollPosition = useCallback((x: number, sliderElement: HTMLElement) => {
    compose(dispatch, setTableScrollPositionAction)({
      eventFrom: "slider",
      percent: (x / sliderElement.clientWidth) * 100,
    })
  }, [])


  return {
    year,
    handleYearChange,
    setTableScrollPosition,
    onArrowClick,
    tableViewWidthPercent,
    tableScrollPosition
  };
};
