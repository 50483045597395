import cn from "classnames";
import React from "react";

import styles from "./InfoItem.module.scss";

type propsType = {
  label: string;
  value: React.ReactNode;
  className?: string;
};

function InfoItem({ label, value, className }: propsType) {
  return (
    <div className={cn(styles.info, className)}>
      <b>{label}</b>
      <span>{value}</span>
    </div>
  );
}

export default InfoItem;
