import React from "react";

import { useTranslation } from "react-i18next";
import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";

import styles from "./ProjectsTable.module.scss";

interface IProps {
  onChangeFilter: (name: string, value: string) => void;
  isSticky?: boolean;
}

const ProjectsTableHeader: React.FC<IProps> = ({ onChangeFilter, isSticky = true }) => {
  const { t } = useTranslation();

  return (
    <TableReusableHead isSticky={isSticky} className={styles.row}>
      <TableReusableHeaderCell filterType="text" onChangeFilter={onChangeFilter} filterField="name">
        {t("Наименование проекта")}
      </TableReusableHeaderCell>
      <TableReusableHeaderCell filterType="text" onChangeFilter={onChangeFilter} filterField="entity">
        {t("Организация")}
      </TableReusableHeaderCell>
      <TableReusableHeaderCell filterType="text" onChangeFilter={onChangeFilter} filterField="customer">
        {t("Заказчик")}
      </TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default ProjectsTableHeader;
