import { duration } from "moment";
import moment from "moment";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { chartScrollMarkersSelector, chartViewModeSelector } from "redux/modules/common/chart/selectors";
import { CHART_VIEW_MODE } from "redux/modules/common/chart/types";

import { IChartIntervalProps } from "./ChartInterval.typings";

import { useChartUnitMultiplier } from "../../hooks/useChartUnitMultiplier";

import { daysInYear } from "../../utils/daysInYear";
import { weeksInYear } from "../../utils/weeksInYear";

export interface IUseIntervalSize extends Pick<IChartIntervalProps, "start" | "end"> {
  disabled?: boolean;
}

export const useChartIntervalSize = ({ start, end, disabled = false }: IUseIntervalSize) => {
  const unitMultiplier = useChartUnitMultiplier();
  const monthMarkers = useSelector(chartScrollMarkersSelector);
  const chartViewMode = useSelector(chartViewModeSelector);

  const workStartMoment = useMemo(() => moment(start), [start]);
  const workEndMoment = useMemo(() => moment(end), [end]);

  const isInvisible = !monthMarkers[workStartMoment.year()];

  const leftRem = useMemo(() => {
    if (disabled) return;
    const wYear = workStartMoment.year();
    if (chartViewMode === CHART_VIEW_MODE.MONTHS) {
      const weeksOffset =
        Object.keys(monthMarkers).reduce((acc, y) => {
          if (+y < wYear) {
            return acc + weeksInYear(+y);
          }
          return acc;
        }, 0) +
        workStartMoment.week() -
        1;
      return weeksOffset * unitMultiplier;
    }
    const daysOffset =
      Object.keys(monthMarkers).reduce((acc, y) => {
        if (+y < wYear) {
          return acc + daysInYear(+y);
        }
        return acc;
      }, 0) +
      workStartMoment.dayOfYear() -
      1;
    return daysOffset * unitMultiplier;
  }, [workStartMoment, monthMarkers, disabled, unitMultiplier, chartViewMode]);

  const widthRem = useMemo(() => {
    if (disabled) return;
    if (chartViewMode === CHART_VIEW_MODE.MONTHS) {
      return (
        Math.max(1, Math.ceil(duration(+workEndMoment - +workStartMoment, "ms").asDays() / 7) + 1) * unitMultiplier
      );
    }
    return Math.max(1, duration(+workEndMoment - +workStartMoment, "ms").asDays() + 1) * unitMultiplier;
  }, [workStartMoment, workEndMoment, disabled, unitMultiplier, chartViewMode]);

  return { leftRem, widthRem, isInvisible };
};
