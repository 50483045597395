import React, { MouseEventHandler, useCallback } from "react";

import Checkbox from "_LEGACY/UI/__TODO/Checkbox/Checkbox";

import IContractor from "types/interfaces/Contractor";

import styles from "./AddModal.module.scss";

export interface IAddContractorRowProps {
  contractor: IContractor;
  onSelect: (contractorId: IContractor["id"], isChecked: boolean) => void;
  onActiveDetail: (contractor: IContractor) => void;
  number: string | number;
  isSelected: boolean;
}

const AddContractorRow = ({ contractor, onSelect, onActiveDetail, number, isSelected }: IAddContractorRowProps) => {
  const onClickByRow: MouseEventHandler = useCallback(
    () => onSelect(contractor.id, !isSelected),
    [contractor.id, isSelected, onSelect]
  );

  const onClickByName: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onActiveDetail(contractor);
    },
    [onActiveDetail, contractor]
  );

  return (
    <div className={`${isSelected ? styles.selected : ""} ${styles.row}`} onClick={onClickByRow}>
      <Checkbox checked={isSelected} checkboxClassName={styles.checkbox}>
        <span className={styles.labelText}>{number}</span>
      </Checkbox>
      <div className={styles.name} title={contractor.name} onClick={onClickByName}>
        {contractor.name}
      </div>
      <div>{contractor.itn}</div>
    </div>
  );
};

export default React.memo(AddContractorRow);
