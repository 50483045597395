import { createSelector } from "reselect";

import { RootState } from "app/store/rootReducer";

const stateSelector = (state: RootState) => state.passports;

export const passportsAndCertificatesSelector = createSelector(stateSelector, (state) => state.documents);

export const passportsInvalidateKeySelector = createSelector(stateSelector, (state) => state.invalidateKey);

export const passportsPendingSelector = createSelector(stateSelector, (state) => state.isPending);

export const expenditureAttachmentsSelector = createSelector(stateSelector, (state) => state.expenditureAttachments);

export const ks2ExpendituresAttachmentsSelector = createSelector(
  stateSelector,
  (state) => state.ks2ExpenditureAttachments
);

export const ks2AttachmentsSelector = createSelector(stateSelector, (state) => state.ks2Attachments);

export const ks2SectionsAttachmentsSelector = createSelector(stateSelector, (state) => state.sectionAttachments);
