import React from "react";

import ButtonBase from "shared/ui/controls/ButtonBase";
import Modal from "shared/ui/modal/Modal";

import styles from "./SplitModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  acceptPackingList: () => void;
  acceptPackingListWithSplitting: () => void;
}

const SplitModal: React.FC<IProps> = ({ isOpen, onClose, acceptPackingList, acceptPackingListWithSplitting }) => {
  const handleAcceptList = () => {
    acceptPackingList();
  };

  return (
    /* @ts-ignore */
    <Modal isOpen={isOpen} onClose={onClose} modalType="small">
      <div className={styles.modalContent}>
        <p>Сформировать новую УПД на остаток?</p>
        <footer className={styles.footer}>
          <ButtonBase onClick={handleAcceptList} secondary medium>
            Нет
          </ButtonBase>
          {/* @ts-ignore */}
          <ButtonBase onClick={acceptPackingListWithSplitting} medium>
            Да
          </ButtonBase>
        </footer>
      </div>
    </Modal>
  );
};

export default React.memo(SplitModal);
