import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMeasureState, MeasureType } from "./types";
import { MEASURE_KEY } from "./const";

const initialMeasure = localStorage.getItem(MEASURE_KEY);

const initialState: IMeasureState = {
  measure: (initialMeasure ? +initialMeasure : 1) as MeasureType
}

const financeMeasureSlice = createSlice({
  name: "financeMeasure",
  initialState,
  reducers: {
    setMeasure: (state, action: PayloadAction<MeasureType>) => {
      localStorage.setItem(MEASURE_KEY, action.payload.toString());
      state.measure = action.payload;
    }
  }
})

export const financeMeasureActions = financeMeasureSlice.actions;
export const financeMeasureReducer = financeMeasureSlice.reducer;
