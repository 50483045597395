import { message } from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  createOrganization,
  editOrganization,
  getContactFace,
  getEntity,
  getTaxes,
} from "../../../../../redux/modules/common/settings/organization";
import { loadingStateSelector as taxesLoadSelector } from "../../../../../redux/modules/common/settings/ourCompanyTabs/profile";

import { useUrlModule } from "../../../../../utils/hooks/useUrlModule";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const useOrganizationData = (urlId, isNew) => {
  const history = useHistory();
  const { objectId } = useParams();
  const urlModule = useUrlModule();
  const fullUrlModule = () => {
    if (urlModule !== "objects") return urlModule;
    return `objects/${objectId}`;
  };
  const dispatch = useDispatch();
  const taxesLoading = useSelector(taxesLoadSelector);
  const {
    contactFace,
    contactFaceLoading,
    currentEntityLoading: orgDataLoading,
    taxes,
    currentEntity: organizationData,
  } = useSelector((state) => state.organization);
  const [requisits, setRequisits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getTaxes());
    if (!urlId || isNew) return;
    setIsLoading(true);
    dispatch(getEntity(urlId, () => setIsLoading(false)));
    dispatch(getContactFace(urlId));
  }, [urlId, isNew]);

  useEffect(() => {
    Axios.get(`/entities/${urlId}/requisites`)
      .then((resp) => {
        setRequisits(resp.data?.results || []);
      })
      .catch(errorCatcher);
  }, [organizationData]);

  const getInitialForm = (organization, contactFace, bankDetails) => {
    if (!organization || isNew) return {};
    const fullName = organization?.contact_manager?.first_name
      ? organization?.contact_manager?.last_name +
        " " +
        organization?.contact_manager?.first_name +
        " " +
        organization?.contact_manager?.middle_name
      : "";
    const contactManager = organization?.contact_manager || {};
    return {
      ...organization,
      including_vat: organization.including_vat === true ? "YES" : "NO",
      contact_manager: {
        ...contactManager,
        name: fullName,
      },
      general_entity: {
        requisite_set: bankDetails || [],
      },
    };
  };

  const filterSubmittingData = (value) => {
    if (!value) return null;
    const contactManager = value?.contact_manager || {};
    const namesArr = value.contact_manager?.name ? value.contact_manager?.name?.trim().split(" ") : [];
    const namesObj = {
      last_name: namesArr[0] || "",
      first_name: namesArr[1] || "",
      middle_name: namesArr[2] || "",
    };
    return {
      ...value,
      including_vat: value.including_vat === "YES",
      contact_manager: {
        ...contactManager,
        ...namesObj,
      },
    };
  };

  const addbankDetails = () => {
    setRequisits([...requisits, {}]);
  };

  const deleteEmptyHandler = () => {
    const tempArr = [...requisits];
    tempArr.pop();
    setRequisits(tempArr);
  };

  const specialDeleteBankDetailHandler = (id) => {
    const tempArr = [...requisits];
    const index = tempArr.findIndex((el) => el.id === id);
    if (index < 0) return;
    Axios.delete(`/requisites/${id}`).catch((err) => console.error(err));
    tempArr.splice(index, 1);
    setRequisits(tempArr);
  };

  const [isPending, setIsPending] = useState(false);

  const submitFormHandler = (vals) => {
    if (!requisits?.length) {
      message.error("Добавьте банковские реквизиты");
      return;
    }
    if (isNew) {
      setIsPending(true);
      dispatch(
        createOrganization(vals, requisits, (id) =>
          history.push(`/${fullUrlModule()}/settings/organization/${id}/?edit=true`)
        ),
        () => setIsPending(false)
      );
    } else {
      const requisites = vals.general_entity?.requisite_set || [];
      if (!urlId) return;

      dispatch(editOrganization(urlId, filterSubmittingData(vals), { finallyCallback: () => setIsPending(false) }));
      requisites.forEach((requisit) => {
        if (requisit.id) {
          Axios.patch(`/requisites/${requisit.id}/`, requisit).catch((err) => console.error(err));
        } else {
          Axios.post(`/entities/${urlId}/requisites/`, requisit).catch((err) => console.error(err));
        }
      });
    }
  };

  return {
    organizationData,
    taxes,
    isLoaded: !orgDataLoading && !taxesLoading && !contactFaceLoading,
    initialForm: getInitialForm(organizationData, contactFace, requisits),
    submitHandler: submitFormHandler,
    bankDetails: requisits,
    addbankDetails,
    deleteEmptyHandler,
    specialDeleteBankDetailHandler,
    isPending,
    isLoading,
  };
};
