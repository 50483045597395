import cn from "classnames";
import React, { FC, ReactNode, useMemo } from "react";

import { hexToRGB } from "../../../../../../utils/formatters/hexToRGB";

import styles from "./HandlerIndicatorItem.module.scss";

interface IProps {
  color?: string;
  children?: ReactNode;
  isActive?: boolean;
  isDisabled?: boolean
  isDropTarget?: boolean;
  isFullWidth?: boolean;
}

const HandlerIndicatorItem: FC<IProps> = ({
  color = "#4FB2ED",
  isDropTarget,
  children,
  isActive,
  isDisabled = false,
  isFullWidth = false
}) => {
  const inlineStyles = useMemo(() => {
    return { borderColor: color, background: isDropTarget ? hexToRGB(color, 0.25) : "none" };
  }, [color, isDropTarget]);

  return (
    <div
      className={cn(styles.container, {
        [styles.active]: isActive,
        [styles.disabled]: isDisabled,
        [styles.fullWidth]: isFullWidth
      })}
      style={inlineStyles}
    >
      {children}
    </div>
  );
}

export default React.memo(HandlerIndicatorItem);
