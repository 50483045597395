import { useMemo } from "react";

import { userSelector } from "../../../../../../../redux/modules/_TODO/auth";

import { useTypedSelector } from "../../../../../../../app/store/typedUseSelector";

import { ITicketApproval } from "../../../../../../../types/interfaces/Tickets";

export const useCanApproveFact = (approvals: ITicketApproval[]) => {
  const userInfo = useTypedSelector(userSelector);

  const userInApproval = useMemo(() => {
    return approvals?.find((approval) => approval.user.id === userInfo.id);
  }, [approvals, userInfo]);

  if (!userInApproval) return false;
  if (userInApproval.is_confirmed) return false;

  if (!!userInApproval.position && userInApproval.position > 1) {
    return approvals?.find((approval) => approval.position === (userInApproval.position as number) - 1)?.is_confirmed;
  }

  return true;
};
